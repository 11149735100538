import React from "react";
import { employeeService } from "../../../_services";
import CollateralData from "./ApplicationData/CollateralData";
import PaymentData from "./ApplicationData/PaymentData";

export default function PaymentDetails({ cust }) {
  const user = employeeService.userValue;
  console.log(cust);
  return (
    <div>
      <br></br>
      {cust && (
      <PaymentData customer={cust}></PaymentData>
      )}

    </div>
  );
}
