import React from "react";
import BusinessDetails from "./ApplicationData/BusinessDetails";
import PropertyDetails from "./ApplicationData/PropertyDetails";
import LiabilitiesDetails from "./ApplicationData/LiabilitiesDetails";
import ApplicationFees from "./ApplicationData/ApplicationFees";

import TextField from "@material-ui/core/TextField";
import SaleAppStatus from "./SaleAppStatus";
import StageAssignModal from "./StageAssignModal";
import { employeeService } from "../../../_services";
import roleChecker from "../../../_helpers/rolechecker";
import workflowhelper from "../../../_helpers/workflowhelper"
import { LoanStages } from "../../../constants/loanstages";

export default function LeadDetails({ cust }) {
  const user = employeeService.userValue;
  return (
    <div>
      <SaleAppStatus applicant={cust}></SaleAppStatus>
      <div className="row justify-content-md-center">
        <TextField
          disabled
          id="appid"
          label="Application ID"
          defaultValue={cust.application_id}
          variant="outlined"
        />
        <TextField
          disabled
          id="branchid"
          label="Branch ID"
          defaultValue={cust.branch_id}
          variant="outlined"
        />
        {cust.employees && cust.employees.length>0 && (  
        <TextField
        disabled
        id="ro_id"
        label="RO/Sales Agent"
        defaultValue={cust.employees[0].first_name+ " "+cust.employees[0].last_name+" /ID: "+cust.employees[0].emp_id+" "}
        variant="outlined"
        /> )}
      </div>
      <br></br>
      {cust.application_fees && (
        <ApplicationFees customer={cust}></ApplicationFees>
      )}
      
      {cust.business_details && (
      <BusinessDetails data={cust.business_details}></BusinessDetails>
      )}

      <br></br>
      {cust.property_details && (
        <PropertyDetails data={cust.property_details}></PropertyDetails>
      )}
      <br></br>

      {cust.liabilities_details && (
      <LiabilitiesDetails data={cust.liabilities_details}></LiabilitiesDetails>
      )}
      <br></br>
       <br></br>
      {(workflowhelper.isOTPVerified(cust) && workflowhelper.isLoginFeesReceived(cust))  && roleChecker.isBranchLevelAccess(user.role) && cust.stage===LoanStages.Sales && (

          <div class="row  justify-content-md-center" style={{"border":"1px solid"}}>
          <div class="justify-content-md-center">
          <p style={{padding:"4px"}}>I have verified all the loan details and this loan is now ready for credit check.</p>  
          </div>
          <br/>
          <div class="row justify-content-md-left" style={{margin:"15px"}}>
          <StageAssignModal loan_data={cust}/>
          </div>
          </div>
      )}
       {!workflowhelper.isOTPVerified(cust) && cust.stage===LoanStages.Sales && (

            <div class="row  justify-content-md-center" style={{"border":"1px solid"}}>
            <div class="justify-content-md-center">
            <p style={{padding:"4px",color:"red"}}>Please Verify OTP to move loan to Credit Stage</p>  
            </div>
            </div>
     )}
     {workflowhelper.isOTPVerified(cust) && ! workflowhelper.isLoginFeesReceived(cust) && cust.stage===LoanStages.Sales && (

        <div class="row  justify-content-md-center" style={{"border":"1px solid"}}>
        <div class="justify-content-md-center">
        <p style={{padding:"4px",color:"red"}}>Please take Login Fees to move file to credit</p>  
        </div>
        </div>
    )}
    </div>
  );
}
