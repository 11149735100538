import React from 'react'
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import datehelper from '../../../_helpers/datehelper';
export const UpcommingEvents = (props) => {
  console.log('Test rpos', props)
  let ni138 = props.location.state.ni138
  let arbitration = props.location.state.arbitration
  let execution=props.location.state.execution
  console.log("test case", ni138)
  console.log("test arbitration", arbitration)
  const currentDate = new Date();
  const today_date = new Date();
  return (
    <div style={{ margin: '120px' }}>
      <div>
        <br />
        {ni138 &&
          <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'blue', height: '50px', borderRadius: '4px' }} >
              <h4 style={{ color: 'white' }}>Upcomming Events of NI-138</h4>
            </div>
            <Table>
              <thead style={{ background: 'blue', color: 'white' }}>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Sr.NO</Th>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Loan No</Th>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Party Name</Th>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Previous Hearing Date</Th>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Advocate Assigned</Th>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Judgement</Th>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Next Hearing Date</Th>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Next Hearing Purpose</Th>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Court Name</Th>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Jurisdiction Name</Th>
              </thead>
              <Tbody>
                {ni138.map((x, idx) =>
                  <Tr key={idx}>
                          <Td style={{ border: "1px solid " }}>{idx + 1}</Td>
                          <Td style={{ border: "1px solid " }}>{x.loan_no}</Td>
                          <Td style={{ border: "1px solid " }}>{x.party_name}</Td>
                          {
                            x.hearings.length >=2? <>
                              <Td style={{ border: "1px solid " }}>{datehelper.displayDate(new Date(x.hearings[x.hearings.length-2].hearing_date))}</Td>
                              <Td style={{ border: "1px solid " }}>{x.hearings[x.hearings.length - 2].lawyer_name}</Td>
                              <Td style={{ border: "1px solid " }}>{x.hearings[x.hearings.length - 2].judgement}</Td>
                            </> : <>
                              <Td style={{ border: "1px solid " }}>----</Td>
                              <Td style={{ border: "1px solid " }}>----</Td>
                              <Td style={{ border: "1px solid " }}>----</Td>
                            </>
                          }
                          <Td style={{ border: "1px solid " }}>{datehelper.displayDate(new Date(x.hearings[x.hearings.length-1].hearing_date))}</Td>
                          <Td style={{ border: "1px solid " }}>{x.hearings[x.hearings.length-1].judgement}</Td>
                          <Td style={{ border: "1px solid " }}>{x.court_name}</Td>
                          <Td style={{ border: "1px solid " }}>{x.jurisdiction_name}</Td>
                  </Tr>)
                }
              </Tbody>
            </Table>
          </div>
        }
        <br />
      </div>
      <div>
        <br />
        {arbitration &&
          <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'blue', height: '50px', borderRadius: '4px' }} >
              <h4 style={{ color: 'white' }}>Upcomming Events of Arbitration</h4>
            </div>
            <Table>
              <thead style={{ background: 'blue', color: 'white' }}>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Sr.NO</Th>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Loan No</Th>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Party Name</Th>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Previous Hearing Date</Th>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Advocate Assigned</Th>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Judgement</Th>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Next Hearing Date</Th>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Next Hearing Purpose</Th>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Court Name</Th>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Jurisdiction Name</Th>
              </thead>
              <Tbody>
                {arbitration.map((x, idx) =>
                  <Tr key={idx}>
                    <Td style={{ border: "1px solid " }}>{idx + 1}</Td>
                          <Td style={{ border: "1px solid " }}>{x.loan_no}</Td>
                          <Td style={{ border: "1px solid " }}>{x.party_name}</Td>
                          {
                            x.hearings.length >=2? <>
                              <Td style={{ border: "1px solid " }}>{datehelper.displayDate(new Date(x.hearings[x.hearings.length-2].hearing_date))}</Td>
                              <Td style={{ border: "1px solid " }}>{x.hearings[x.hearings.length - 2].lawyer_name}</Td>
                              <Td style={{ border: "1px solid " }}>{x.hearings[x.hearings.length - 2].judgement}</Td>
                            </> : <>
                              <Td style={{ border: "1px solid " }}>----</Td>
                              <Td style={{ border: "1px solid " }}>----</Td>
                              <Td style={{ border: "1px solid " }}>----</Td>
                            </>
                          }
                          <Td style={{ border: "1px solid " }}>{datehelper.displayDate(new Date(x.hearings[x.hearings.length-1].hearing_date))}</Td>
                          <Td style={{ border: "1px solid " }}>{x.hearings[x.hearings.length-1].judgement}</Td>
                          <Td style={{ border: "1px solid " }}>{x.court_name}</Td>
                          <Td style={{ border: "1px solid " }}>{x.jurisdiction_name}</Td>
                  </Tr>)
                }
              </Tbody>
            </Table>
          </div>
        }
        <br />
      </div>
      <div>
        <br />
        {execution &&
          <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'blue', height: '50px', borderRadius: '4px' }} >
              <h4 style={{ color: 'white' }}>Upcomming Events of Execution</h4>
            </div>
            <Table>
              <thead style={{ background: 'blue', color: 'white' }}>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Sr.NO</Th>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Loan No</Th>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Party Name</Th>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Previous Hearing Date</Th>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Advocate Assigned</Th>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Judgement</Th>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Next Hearing Date</Th>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Next Hearing Purpose</Th>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Court Name</Th>
                <Th style={{ border: "1px solid ", textAlign: 'center' }}>Jurisdiction Name</Th>
              </thead>
              <Tbody>
                {execution.map((x, idx) =>
                  <Tr key={idx}>
                    <Td style={{ border: "1px solid " }}>{idx + 1}</Td>
                          <Td style={{ border: "1px solid " }}>{x.loan_no}</Td>
                          <Td style={{ border: "1px solid " }}>{x.party_name}</Td>
                          {
                            x.hearings.length >=2? <>
                              <Td style={{ border: "1px solid " }}>{datehelper.displayDate(new Date(x.hearings[x.hearings.length-2].hearing_date))}</Td>
                              <Td style={{ border: "1px solid " }}>{x.hearings[x.hearings.length - 2].lawyer_name}</Td>
                              <Td style={{ border: "1px solid " }}>{x.hearings[x.hearings.length - 2].judgement}</Td>
                            </> : <>
                              <Td style={{ border: "1px solid " }}>----</Td>
                              <Td style={{ border: "1px solid " }}>----</Td>
                              <Td style={{ border: "1px solid " }}>----</Td>
                            </>
                          }
                          <Td style={{ border: "1px solid " }}>{datehelper.displayDate(new Date(x.hearings[x.hearings.length-1].hearing_date))}</Td>
                          <Td style={{ border: "1px solid " }}>{x.hearings[x.hearings.length-1].judgement}</Td>
                          <Td style={{ border: "1px solid " }}>{x.court_name}</Td>
                          <Td style={{ border: "1px solid " }}>{x.jurisdiction_name}</Td>
                  </Tr>)
                }
              </Tbody>
            </Table>
          </div>
        }
        <br />
      </div>
    </div>
  )
}



// export const UpcommingEvents = (props) => {
//   console.log('Test rpos', props)
//   let ni138 = props.location.state.ni138
//   let arbitration = props.location.state.arbitration
//   console.log("test case", ni138)
//   console.log("test arbitration", arbitration)
//   const currentDate = new Date();
//   const today_date = new Date();
//   const sortedNi138 = ni138&&ni138.sort((a, b) => {
//     const aNextHearing = a.next_hearing.find(({ hearing_date }) => new Date(hearing_date) >= new Date(today_date.getFullYear(), today_date.getMonth(), today_date.getDate()));
//     const bNextHearing = b.next_hearing.find(({ hearing_date }) => new Date(hearing_date) >= new Date(today_date.getFullYear(), today_date.getMonth(), today_date.getDate()));
//     return aNextHearing && bNextHearing ? new Date(aNextHearing.hearing_date) - new Date(bNextHearing.hearing_date) : aNextHearing ? -1 : bNextHearing ? 1 : 0;
//   });  
//   const sortedarbitration = arbitration&&arbitration.sort((a, b) => {
//     const aNextStage = a.next_stage.find(({ date }) => new Date(date) >= new Date(today_date.getFullYear(), today_date.getMonth(), today_date.getDate()));
//     const bNextStage = b.next_stage.find(({ date }) => new Date(date) >= new Date(today_date.getFullYear(), today_date.getMonth(), today_date.getDate()));
//     return aNextStage && bNextStage ? new Date(aNextStage.date) - new Date(bNextStage.date) : aNextStage ? -1 : bNextStage ? 1 : 0;
//   });  
//   return (
//     <div style={{ margin: '120px' }}>
//       <div>
//         <br />
//         {ni138 &&
//           <div>
//             <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'blue', height: '50px', borderRadius: '4px' }} >
//               <h4 style={{ color: 'white' }}>Upcomming Events of NI-138</h4>
//             </div>
//             <Table>
//               <thead style={{ background: 'blue', color: 'white' }}>
//                 <Th style={{ border: "1px solid ", textAlign: 'center' }}>Sr.NO</Th>
//                 <Th style={{ border: "1px solid ", textAlign: 'center' }}>Loan No</Th>
//                 <Th style={{ border: "1px solid ", textAlign: 'center' }}>Party Name</Th>
//                 <Th style={{ border: "1px solid ", textAlign: 'center' }}>Previous Hearing Date</Th>
//                 <Th style={{ border: "1px solid ", textAlign: 'center' }}>Advocate Assigned</Th>
//                 <Th style={{ border: "1px solid ", textAlign: 'center' }}>Judgement</Th>
//                 <Th style={{ border: "1px solid ", textAlign: 'center' }}>Next Hearing Date</Th>
//                 <Th style={{ border: "1px solid ", textAlign: 'center' }}>Next Hearing Purpose</Th>
//                 <Th style={{ border: "1px solid ", textAlign: 'center' }}>Court Name</Th>
//                 <Th style={{ border: "1px solid ", textAlign: 'center' }}>Jurisdiction Name</Th>
//               </thead>
//               <Tbody>
//                 {sortedNi138.map((x, idx) =>
//                   <Tr key={idx}>
//                     <Td style={{ border: "1px solid " }}>{idx + 1}</Td>
//                     <Td style={{ border: "1px solid " }}>{x.loan_no}</Td>
//                     <Td style={{ border: "1px solid " }}>{x.party_name}</Td>
//                     {
//                       x.hearings.length > 0 ? <>
//                         <Td style={{ border: "1px solid " }}>{datehelper.displayDate(new Date(x.hearings[x.hearings.length - 1].hearing_date))}</Td>
//                         <Td style={{ border: "1px solid " }}>{x.hearings[x.hearings.length - 1].lawyer_name}</Td>
//                         <Td style={{ border: "1px solid " }}>{x.hearings[x.hearings.length - 1].judgement}</Td>
//                       </> : <>
//                         <Td style={{ border: "1px solid " }}>----</Td>
//                         <Td style={{ border: "1px solid " }}>----</Td>
//                         <Td style={{ border: "1px solid " }}>----</Td>
//                       </>
//                     }
//                     {x.next_hearing.filter((nextHearing) => new Date(nextHearing.hearing_date) >= new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())).length > 0 ?
//                  (
//                    <>
//                      <Td style={{ border: "1px solid " }}>
//                        {new Date(x.next_hearing.filter((nextHearing) => new Date(nextHearing.hearing_date) >= new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()))[0].hearing_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/\//g, '-')}
//                      </Td>
//                      <Td style={{ border: "1px solid " }}>
//                        {x.next_hearing.filter((nextHearing) => new Date(nextHearing.hearing_date) >= new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()))[0].task}
//                      </Td>
//                    </>
//                  ) :
//                  (
//                    <>
//                      <Td style={{ border: "1px solid " }}>---</Td>
//                      <Td style={{ border: "1px solid " }}>---</Td>
//                    </>
//                  )
//                }
//                     {/* {<>
//                       <Td style={{ border: "1px solid " }}>{x.next_hearing.map((y, i) =>
//                         <p>{datehelper.displayDate(new Date(y.hearing_date))}</p>)}</Td>
//                       <Td style={{ border: "1px solid " }}>{x.next_hearing.map((y, i) =>
//                         <p>{y.task}</p>)}</Td>
//                     </>
//                     } */}
//                     <Td style={{ border: "1px solid " }}>{x.court_name}</Td>
//                     <Td style={{ border: "1px solid " }}>{x.jurisdiction_name}</Td>
//                   </Tr>)
//                 }
//               </Tbody>
//             </Table>
//           </div>
//         }
//         <br />
//         {arbitration &&
//           <div>
//             <div style={{ display: 'flex', alignItems: 'center', background: 'blue', height: '50px', justifyContent: 'center', borderRadius: '4px' }}>
//               <h4 style={{ color: 'white' }}>Upcomming Events of Arbitration</h4>
//             </div>
//             <Table>
//               <thead style={{ background: 'blue', color: 'white' }}>
//                 <Th style={{ border: "1px solid ", textAlign: 'center' }}>Sr.NO</Th>
//                 <Th style={{ border: "1px solid ", textAlign: 'center' }}>Loan No</Th>
//                 <Th style={{ border: "1px solid ", textAlign: 'center' }}>Party Name</Th>
//                 <Th style={{ border: "1px solid ", textAlign: 'center' }}>Previous Stage Date</Th>
//                 <Th style={{ border: "1px solid ", textAlign: 'center' }}>Previous Stage</Th>
//                 <Th style={{ border: "1px solid ", textAlign: 'center' }}>Next Stage Date</Th>
//                 <Th style={{ border: "1px solid ", textAlign: 'center' }}>Next Stgae</Th>
//                 <Th style={{ border: "1px solid ", textAlign: 'center' }}>Court Name</Th>
//                 <Th style={{ border: "1px solid ", textAlign: 'center' }}>Jurisdiction Name</Th>
//               </thead>
//               <Tbody>
//                 {sortedarbitration.map((x, idx) =>
//                   <Tr key={idx}>
//                     <Td style={{ border: "1px solid " }}>{idx + 1}</Td>
//                     <Td style={{ border: "1px solid " }}>{x.loan_no}</Td>
//                     <Td style={{ border: "1px solid " }}>{x.party_name}</Td>
//                     {
//                       x.stages.length > 0 ? <>
//                         <Td style={{ border: "1px solid " }}>{datehelper.displayDate(new Date(x.stages[x.stages.length - 1].date))}</Td>
//                         <Td style={{ border: "1px solid " }}>{x.stages[x.stages.length - 1].stage}</Td>
//                       </> : <>
//                         <Td style={{ border: "1px solid " }}>----</Td>
//                         <Td style={{ border: "1px solid " }}>----</Td>
//                       </>
//                     }
//                     {x.next_stage.filter((nextStage) => new Date(nextStage.date) >= new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())).length > 0 ?
//                       (
//                         <>
//                           <Td style={{ border: "1px solid " }}>
//                             {new Date(x.next_stage.filter((nextStage) => new Date(nextStage.date) >= new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()))[0].date).toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/\//g, '-')}
//                           </Td>
//                           <Td style={{ border: "1px solid " }}>
//                             {x.next_stage.filter((nextStage) => new Date(nextStage.date) >= new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()))[0].stage}
//                           </Td>
//                         </>
//                       ) :
//                       (
//                         <>
//                           <Td style={{ border: "1px solid " }}>---</Td>
//                           <Td style={{ border: "1px solid " }}>---</Td>
//                         </>
//                       )
//                     }
//                     <Td style={{ border: "1px solid " }}>{x.court_name}</Td>
//                     <Td style={{ border: "1px solid " }}>{x.jurisdiction_name}</Td>
//                   </Tr>)
//                 }
//               </Tbody>
//             </Table>
//           </div>
//         }
//       </div>
//     </div>
//   )
// }
