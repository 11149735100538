import { fetchWrapper } from "../_helpers/fetch-wrapper";

const apiUrl = process.env.REACT_APP_API_URL;

const baseUrl = `${apiUrl}/customers`;

export const loanService = {
  getAll,
  getAllByStage,
  getById,
  getByAadharId,
  changeStage,
  changeColendingStage,
  getAllCredit,
  create,
  getAllSummary,
  getMySummary,
  getAllCollection,
  getAllPendingSummary,
  getAllStageSummary,
  update,
  startpdlocation,
  updateColender,
  updateDisbursement,
  getColenderLoans,
  getColenderPayments,
  addApplicationForm,
  applicationFormData,
  singleApplicationFormData,
  addApplicationFees,
  addEmdFees,
  generateRepaymentSchedule,
  getAllColenderLoans,
  getBySearch,
  getInsAmount,
  getRecalculate,
  saveInsAmount,
  saveDocumentCheckList,
  getDocumentCheckList,
  getDocumentCheckListfromCustomers,
  finalSaveDocumentCheckList,
  addDocumentsToReceived,
  getlistedInsuranceData,
  sendOtp,
  sendOtpOnForgotPassword,
  getUpdateColenderRepaymentSchedule,
  verifyBankDetails,
  individualPaymentDetails,
  generateColenderRepaymentSchedule,
  movetoCollection,
  getAssignedFiles,
  colenderDocuments,
  getColenderDocuments,
  searchColenderPayment,
  addEditCoApplicant,
  addEditGuarantor,
  findLoanByRegex,
  addKarzaVerified,
  kycVerification,
  voterkycVerification,
  colenderPaymentStatusUpdate,
  updateInsurance,
  addCersaiReport,
  updateCersaiStatus,
  copyEmiDetails,
  createReference,
  archiveDetails,
  resetDsibursementVerificationDetails,
  getAllFunds,
  valuationReport,
  verifyValuationReport,
  resetValuationReport,
  
  searchLoginByUtr,
  paymentFees,
  emdPaymentFees,
  cancelColender,
  // watsappMessageForLoanCreation,
  loginOtp,
  initiateValuation,
  initiateRcu,
  initiateLegal,
  rcuReport,
  verifyRcuReport,
  resetRcuReport,
  legalReport,
  verifyLegalReport,
  resetLegalReport,
  pdSuccessWatsapp,
  docPendingWatsapp,
  insuranceDetailsWastsapp,
  getAllBranch,
  emailInitiateForCashReceipt,
  vendorData,
  vendorApplicantData,
  postDisbursalLoansData,
  createCersaiSiRegistration,
  getCityAndStateCode,
  saveCersaiSiRegistrationData,
  getRegisteredSiData,
  sendNotification,
  getEmpIdRoleWise
 };
function valuationReport(id,params){
  console.log("params",params)
    return fetchWrapper.post(`${baseUrl}/valuationreport/${id}`,params)
}
function rcuReport(id,params){
  console.log("params",params)
    return fetchWrapper.post(`${baseUrl}/rcureport/${id}`,params)
}
function legalReport(id,params){
  console.log("params",params)
    return fetchWrapper.post(`${baseUrl}/legalreport/${id}`,params)
}
function verifyValuationReport(params){
  return fetchWrapper.post(`${baseUrl}/verifyvaluationreport`,params)
} 
function resetValuationReport(params){
  return fetchWrapper.post(`${baseUrl}/resetvaluationreport`,params)
}
function archiveDetails(id,params){
  return fetchWrapper.post(`${baseUrl}/archivepaymentdetails/${id}`,params)
}

function resetDsibursementVerificationDetails(id){
  console.log("unverified id loan service",id)
  return fetchWrapper.post(`${baseUrl}/resetdsibursementderificationdetails/${id}`)
}

function changeStage(id,params) {
  
  return fetchWrapper.post(`${baseUrl}/stage/${id}`, params);
}
function changeColendingStage(id,params) {
  
  return fetchWrapper.post(`${baseUrl}/colendingstage/${id}`, params);
}

function getAll() {
  return fetchWrapper.get(baseUrl);
}


function getAllCredit() {
  return fetchWrapper.post(`${baseUrl}/credit`,{});
}
function getAllByStage(stage) {
  return fetchWrapper.post(`${baseUrl}/getbystage`,{'stage':stage});
}
function getById(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`);
}

function getByAadharId(id) {
  return fetchWrapper.post(`${baseUrl}/aadhar/${id}`);
}


function create(fields) {
  return fetchWrapper.post(`${baseUrl}`,fields);
}
function getAllSummary() {
  return fetchWrapper.post(`${baseUrl}/summary`,{});
}

function getMySummary() {
  return fetchWrapper.post(`${baseUrl}/mysummary`,{});
}

function getAllCollection() {
  return fetchWrapper.post(`${baseUrl}/collection`,{});
}

function getAllPendingSummary() {
  return fetchWrapper.post(`${baseUrl}/pendingsummary`,{});
}

function getAllStageSummary(stage) {
  return fetchWrapper.post(`${baseUrl}/stagesummary`,{'stage':stage});
}

function update(id,params) {
  return fetchWrapper.put(`${baseUrl}/${id}`,params);
}

function startpdlocation(id, params) {
  return fetchWrapper.post(`${baseUrl}/updatepddata/${id}`, params);
}


function updateColender(id,params) {
  console.log('id,params,iscolender',id,params)
  return fetchWrapper.put(`${baseUrl}/colenderupdate/${id}`,params);
}
function cancelColender(params) {
  return fetchWrapper.post(`${baseUrl}/canclecolender`,params);
}
function updateDisbursement(id,params) {
  return fetchWrapper.put(`${baseUrl}/disbursementupdate/${id}`,params);
}


function addEditCoApplicant(id,params) {
  return fetchWrapper.post(`${baseUrl}/addeditcoapplicant/${id}`,params);
}



function addEditGuarantor(id,params) {
  return fetchWrapper.post(`${baseUrl}/addeditguarantor/${id}`,params);
}

function getColenderLoans(colender) {
  return fetchWrapper.post(`${baseUrl}/colenderloans`,{'colender':colender});
}

function getColenderPayments(){
  return fetchWrapper.post(`${baseUrl}/colenderpayments`,{});
}

function addApplicationForm(params){
  console.log(params)
  return fetchWrapper.post(`${baseUrl}/addform`, params)
}

function applicationFormData(){
  return fetchWrapper.get(`${baseUrl}/list/applicationform`)
}

function singleApplicationFormData(params){
  return fetchWrapper.post(`${baseUrl}/detail/applicationform`, params)
}

function addApplicationFees(id,params) {
  return fetchWrapper.post(`${baseUrl}/addapplicationfees/${id}`,params);
}

function addEmdFees(id,params) {
  return fetchWrapper.post(`${baseUrl}/addemdfees/${id}`,params);
}


function generateRepaymentSchedule(loanId){
  return fetchWrapper.post(`${baseUrl}/generaterepaymentschedule/${loanId}`, {})
}

function getAllColenderLoans() {
  return fetchWrapper.post(`${baseUrl}/allcolenderloans`,{});
}

function getBySearch(params) {
  console.log(params)
  return fetchWrapper.post(`${baseUrl}/getbysearch`, params)
}

function getInsAmount(params) {
  console.log("params", params)
  return fetchWrapper.post(`${baseUrl}/getinsamount`,params)

}
function getRecalculate(params) {
  console.log("params", params)
  return fetchWrapper.post(`${baseUrl}/get_sbl_insurance_amount_gm`,params)

}
function saveInsAmount(id,params) {
  console.log("params",id, params)
  return fetchWrapper.post(`${baseUrl}/saveinsamount`,params)

}

function saveDocumentCheckList(params) {
  console.log("params",params)
  return fetchWrapper.post(`${baseUrl}/savedocumentdhecklist`,params)

}
function getDocumentCheckList() {
  return fetchWrapper.get(`${baseUrl}/getdocumentdhecklist`)

}
function getlistedInsuranceData() {
  return fetchWrapper.get(`${baseUrl}/getlistedinsurancedata`)

}
function getDocumentCheckListfromCustomers(params) {
  return fetchWrapper.post(`${baseUrl}/getdocumentdhecklistfromcustomers`,params)

}
function finalSaveDocumentCheckList(id,params) {
  console.log("params:::::",'id::',id,'params::',params)
  return fetchWrapper.post(`${baseUrl}/finalsavedocumentchecklist`,params)

}
function addDocumentsToReceived(params) {
  console.log("params",params)
  return fetchWrapper.post(`${baseUrl}/adddocumentstoreceived`,params)

}

function sendOtp(params) {
  return fetchWrapper.post(`${baseUrl}/otp`, params)
}
function loginOtp(params) {
  return fetchWrapper.post(`${baseUrl}/loginotp`, params)
}
function sendOtpOnForgotPassword(params) {
  return fetchWrapper.post(`${baseUrl}/sendotponforgotpassword`, params)
}
function verifyBankDetails(params) {
  return fetchWrapper.post(`${baseUrl}/verifybankdetails`, params)
}

function getUpdateColenderRepaymentSchedule(loanId,colender_name){
  return fetchWrapper.post(`${baseUrl}/getupdatecolenderrepaymentschedule/`, {'loan_id':loanId,'co_lender_name':colender_name})
}

function  individualPaymentDetails(params){
  return fetchWrapper.post(`${baseUrl}/payments`, params)
}
function generateColenderRepaymentSchedule(loanId){
  return fetchWrapper.post(`${baseUrl}/colender/generaterepaymentschedule/${loanId}`,{})
}

function movetoCollection(params){
  return fetchWrapper.post(`${baseUrl}/movetocollection`, params)
}

function getAssignedFiles(){
  return fetchWrapper.get(`${baseUrl}/getassignedfiles`)
}

function colenderDocuments(params){
  return fetchWrapper.post(`${baseUrl}/colenderdocuments`, params)
}

function getColenderDocuments(params){
  return fetchWrapper.post(`${baseUrl}/getcolenderdocuments`, params)
}

function searchColenderPayment(params){
  return fetchWrapper.post(`${baseUrl}/searchcolenderpayment`, params)
}

function findLoanByRegex(params){
  return fetchWrapper.post(`${baseUrl}/searchbyregex`, params)
}

function addKarzaVerified(params){
  return fetchWrapper.post(`${baseUrl}/addkarzaverified`, params)
}

function kycVerification(params){
  console.log(params,"paramsrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrparamsparams")
  return fetchWrapper.post(`${baseUrl}/kycVerification`, params)
}

function voterkycVerification(params){
  console.log(params,"voterkycVerificationvoterkycVerification")
  return fetchWrapper.post(`${baseUrl}/voterkycVerification`, params)
}

function colenderPaymentStatusUpdate(id, params){
  console.log(params, "params")
  return fetchWrapper.post(`${baseUrl}/updatecolenderpaymentstatus`, params)
}

function updateInsurance(id, params){
  console.log(params, "params")
  return fetchWrapper.post(`${baseUrl}/updateinsurance/${id}`, params)
}

function addCersaiReport(params){
  return fetchWrapper.post(`${baseUrl}/add-cersai-report`, params)
}

function updateCersaiStatus(params){
  return fetchWrapper.post(`${baseUrl}/update-cersai-report`, params)
}

function copyEmiDetails(params){
  return fetchWrapper.post(`${baseUrl}/copy-emi-details`, params)
}

function createReference(params){
  return fetchWrapper.post(`${baseUrl}/add-reference`, params)
}
function getAllFunds(){
  return fetchWrapper.post(`${baseUrl}/getallfunds`)
}  

function searchLoginByUtr(params){
  
  return fetchWrapper.post(`${baseUrl}/searchloginbyutr`,params)
}

function paymentFees(id,params){
  return fetchWrapper.post(`${baseUrl}/paymentfees/${id}`,params)
}
function emdPaymentFees(id,params){
  return fetchWrapper.post(`${baseUrl}/emdpaymentfees/${id}`,params)
}
function initiateValuation( params){
  return fetchWrapper.post(`${baseUrl}/initiatevaluation`,params)
}
function initiateRcu( params){
  return fetchWrapper.post(`${baseUrl}/initiatercu`,params)
}
function initiateLegal( params){
  return fetchWrapper.post(`${baseUrl}/initiatelegal`,params)
}
function verifyRcuReport(params){
  return fetchWrapper.post(`${baseUrl}/verifyrcureport`,params)
} 
function resetRcuReport(params){
  return fetchWrapper.post(`${baseUrl}/resetrcureport`,params)
}
function verifyLegalReport(params){
  return fetchWrapper.post(`${baseUrl}/verifylegalreport`,params)
} 
function resetLegalReport(params){
  return fetchWrapper.post(`${baseUrl}/resetlegalreport`,params)
}
// function watsappMessageForLoanCreation(params){
//   return fetchWrapper.post(`${baseUrl}/watsappmessageforloancreation`,params)
// }
function pdSuccessWatsapp(params){
  console.log('params',params)
  return fetchWrapper.post(`${baseUrl}/pdsuccesswatsapp`,params)
}
function docPendingWatsapp(params){
  console.log('params',params)
  return fetchWrapper.post(`${baseUrl}/docpendingwatsapp`,params)
}
function insuranceDetailsWastsapp(params){
  console.log('params',params)
  return fetchWrapper.post(`${baseUrl}/insurancedetailswastsapp`,params)
}
function getAllBranch(){
   return fetchWrapper.post(`${baseUrl}/getallbranch`)
}
function emailInitiateForCashReceipt(params){
  return fetchWrapper.post(`${baseUrl}/emailinitiateforcashreceipt`,params)
}
function vendorData(params){
  return fetchWrapper.post(`${baseUrl}/vendordata`,params)
}
function vendorApplicantData(params){
  return fetchWrapper.post(`${baseUrl}/vendorapplicantdata`,params)
}

function postDisbursalLoansData() {
  return fetchWrapper.get(`${baseUrl}/postdisbursalloansdata`)

}

function createCersaiSiRegistration(params) {
  console.log('params::::::',params)
  return fetchWrapper.post(`${baseUrl}/cersai_si_registration`,params)

}

function saveCersaiSiRegistrationData(params) {
  console.log('params::::::',params)
  return fetchWrapper.post(`${baseUrl}/save_cersai_si_registration_data`,params)

}

function getCityAndStateCode(params) {
  console.log('params::::::',params)
  return fetchWrapper.post(`${baseUrl}/getcityandstatecode`,params)

}

function getRegisteredSiData(params) {
  return fetchWrapper.post(`${baseUrl}/getregisteredsidata`,params)

}

function sendNotification(params) {
  console.log('params::::::',params)
  return fetchWrapper.post(`${baseUrl}/sendnotification`,params)

}
function getEmpIdRoleWise(params) {
  console.log('params::::::',params)
  return fetchWrapper.post(`${baseUrl}/getempidrolewise`,params)

}