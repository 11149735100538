import React from 'react';
import { useState } from 'react';
import { Modal, Box, Button, Paper, Typography } from '@material-ui/core';
import { Table, Thead, Tbody, Tr, Td, Th } from 'react-super-responsive-table';
import urlhelper from '../../_helpers/urlhelper';
import { history } from '../../_helpers';
import Save from '@material-ui/icons/Save';
import { uploadFileToBucket } from '../../_helpers/upload';
import { CircularProgress } from '@material-ui/core'
import { Field, Form, Formik } from 'formik';
import { termLoans } from '../../_services/termloans.service';

export const TermLoansDocumentModal = (props) => {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "1133px",
        height: "481px",
        bgcolor: "#F3F3F3",
        border: "2px solid #000",
        overflow: "scroll",
        boxShadow: 24,
        p: 4,
    };
    const [open, setOpen] = useState(false)
    const [show, setShow] = useState(false)
    const date = new Date()
    const bucket = process.env.REACT_APP_s3docsBucket
    const termLoan = props.loan
    console.log("Test loan", termLoan)
    const initialValues = {
        documents: '',
        document_type: '',
        document_name:''
    }
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const handleImage = async (e, formik) => {
        let file = e.target.files[0];
        let name = file.name.split('.')[0];
        let num1 = Math.floor(Math.random() * 1000);
        let num2 = Math.floor(Math.random() * 100);
        let fileName = `${name}_${num1}${num2}.pdf`
        console.log("tset file name", fileName)
        let link = await uploadFileToBucket(bucket, 'application/pdf', `${bucket}/termloans/${termLoan._id}/${fileName}`, file);
        formik.setFieldValue('documents', link)
    }

    const onSubmit = async (values) => {
        console.log("Test Values", values)
        await termLoans.addDocument({document_name:values.document_name,document_type:values.document_type, url: values.documents, _id: termLoan._id, date: date, })
            .then(res => {
                window.alert('Details saved successfully')
                window.location.reload()
            })
            .catch(err => {
                window.alert('Some error occurred')
                console.log(err)
            })
    }
    const deleteDocument =async(doc_id)=>{
    await termLoans.deleteDocument({_id:termLoan._id,document_id:doc_id})
    .then((res)=>{
        window.alert('Deleted successfully')
        window.location.reload()
    }).catch(err => {
        window.alert('Some error occurred')
        console.log(err)
    })
    }
    const handleFile = () => {
        setShow(true)
    }
    return (
        <div>
            <Button onClick={handleOpen} style={{ color: '#0C2294', border: "2px solid #0C2294", height: '25px', background: '9990FF' }} >Documents</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box id="modal-modal-title" variant="h6" component="h2" style={{ display: 'flex', flexDirection: 'column', alignItem: 'center' }}>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                        >
                            {(formik) => (
                                <Form>
                                    <div>
                                        <span style={{ marginTop: '30px', marginBottom: '30px', textDecoration: "underLine", textAlign: 'center' }}>
                                            <h5>Documents</h5>
                                        </span>
                                        <hr />
                                        {termLoan.documents.length > 0 && show === false && <div>
                                            <Table style={{ fontSize: 'medium', width: '100%', margin: 'auto', textAlign: 'center' }}>
                                                <Thead>
                                                    <Tr>
                                                        <Th style={{ width: '10%', textAlign: 'center' }}>Sr.No.</Th>
                                                        <Th style={{ width: '25%', textAlign: 'center' }}>Document Name</Th>
                                                        <Th style={{ width: '25%', textAlign: 'center' }}>Document Type</Th>
                                                        <Th style={{ width: '40%', textAlign: 'center' }} colSpan="2">Action</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {
                                                        termLoan.documents && termLoan.documents.length > 0 && termLoan.documents.map((document, idx) => {
                                                            return <> <Tr key={idx} style={{background:"#D9D9D9"}}>
                                                                {/* return <Tr key={document._id ? document._id : document.date}></Tr> */}
                                                                <Td>
                                                                    {idx + 1}
                                                                </Td>
                                                                <Td>{document.document_name}</Td>
                                                                <Td>{document.document_type}</Td>
                                                                <Td><Button
                                                                    color='primary'
                                                                    variant='outlined'
                                                                    onClick={() => history.push(`/home/pdfviewer?pdf_url=` + urlhelper.encodeToBase64(document.url) + "&download=1")}
                                                                >View</Button>
                                                                <Button
                                                                    color='secondary'
                                                                    variant='outlined'
                                                                    style={{ marginLeft: '15px' }}
                                                                    onClick={() =>deleteDocument(document._id)}
                                                                >Delete</Button></Td>
                                                                 <Td style={{textAlign:"center",}}>
                                                       </Td>         
                                                            </Tr>
                                                             <br/>
                                                             </>
                                                        })
                                                    }
                                                </Tbody>
                                            </Table>
                                            <br />
                                            <Button style={{ background: "#406AFF", color: "white", marginLeft: '420px' }} onClick={() => handleFile()}>Upload Document</Button>
                                        </div>}
                                        {(show === true || termLoan.documents.length == 0) && 
                                        <div>
                                            <div  style={{ display: "flex", justifyContent: "space-around"}}>
                                            <div style={{ display: "flex", flexDirection: "column", }}>    
                                            <label style={{ fontSize: '20px' }}>Documet Name</label> 
                                            <Field
                                                style={{ width: "260px", height: "30px",fontSize:'17px' }}
                                                type='text'
                                                name='document_name'
                                                required
                                            />
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "column", }} >
                                            <label style={{ fontSize: '20px' }}>Documet Type</label>
                                            <Field
                                                style={{ width: "260px", height: "30px",fontSize:'20px' }}
                                                name='document_type'
                                                as='select'
                                                required
                                            >
                                                <option value=''>Choose Document</option>
                                                <option value='Agreement'>Agreement</option>
                                                <option value='Supporting Documents'>Supporting Documents</option>
                                                <option value='Term Sheet'>Term Sheet</option>
                                                <option value='Financial Data Sheet'>Financial Data Sheet</option>
                                            </Field>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "column", }}>
                                            <label style={{ fontSize: '20px'}}>Upload Document</label>
                                            <input
                                                style={{ width: "260px", height: "30px",fontSize:'20px' }}
                                                accept=".pdf"
                                                type="file"
                                                name="documents"
                                                onChange={(e) => handleImage(e, formik)}
                                                required
                                            />
                                            </div>
                                            </div>
                                            <button type='submit' style={{ color: 'white', background: "#406AFF", marginTop: '70px', marginLeft: '420px' }}>
                                                <Save /> Save
                                            </button>
                                        </div>}
                                        <br />
                                        <br />
                                    </div>
                                </Form>)}
                        </Formik>
                    </Box>
                </Box>
            </Modal>
            {
                console.log(termLoan)
            }
        </div>
    )
}