import React, { useState, useEffect } from 'react'
import { Table, Thead, Tbody, Tr, Th, Td  } from 'react-super-responsive-table';
import { misService } from '../../_services';
import { useReactToPrint } from "react-to-print";
import { CSVLink } from "react-csv";

const PaymentList = () => {

  const PaymentListRef = React.useRef();

  const handleSubmissionPrint = useReactToPrint({
    content: () => PaymentListRef.current,
    pageStyle: "print"
  });
  
    const [payments, setPayments] = useState([]);
    const [field, setField] = useState({});
    
    const headers = [
      { label: "Name",            key: "name"},
      { label: "Account Number",  key: "loan_account" },
      { label: "Payment Date",    key: "payment_details.payment_date" },
      { label: "Payment Type",    key: "payment_details.payment_type" },
      { label: "Payment Mode",    key: "payment_details.payment_mode" },
      { label: "Refrence Number", key: "payment_details.payment_ref" },
      { label: "Payment Amount",  key: "payment_details.payment_amount" }
    ];


  const handleChange = (fieldKey,fieldVal) => {
    field[fieldKey]=fieldVal;
    setField(field);
  }

  const getList = async () =>{
    await misService.getAllPayments(field).then((x) => {
      const min = Date.parse(field.loan_from_date);
      const max = Date.parse(field.loan_to_date);
        let paymentList=[];
        setPayments([]);
        if(!min && !max){
          x.map((y)=> {
            y.payment_details.map((z) => {
              if(z.payment_type==="collection"){
                let paymentItem={};
                paymentItem['name']=y.name;
                paymentItem['loan_account']=y.loan_account;
                paymentItem['payment_details']=z;
                paymentList.push(paymentItem);
              }
              else{
                console.log("not matched")
              }
            })
          })
          setPayments(paymentList)
        }
        x.map((y)=> {
          y.payment_details.map((z)=> {
            if(z.payment_type==="collection" && Date.parse(z.payment_date) >= min && (Date.parse(z.payment_date)) <= max){
              let paymentItem={};
              paymentItem['name']=y.name;
              paymentItem['loan_account']=y.loan_account;
              paymentItem['payment_details']=z;
              paymentList.push(paymentItem);
            }
            else{
              console.log("not matched")
            }
          })
        })
        setPayments(paymentList);
    })
    .catch((err)=> {
      console.log(err);
    })
    
  }

    return (
    <>
    <div style={{ textAlign: "center" }}>
      <button onClick={handleSubmissionPrint}>Print List</button>
    </div>

      <div >
        <span>From Date: </span>
        <input
        label=" Date"
        type="date"
        name="loan_date"
        onChange={(e)=>handleChange('loan_from_date',e.target.value)}
        />

        <span style={{marginLeft: "5px"}}>To Date:</span>
        <input
        label=" Date"
        style={{marginLeft: "5px"}}
        type="date"
        name="loan_date"
        onChange={(e)=>handleChange('loan_to_date',e.target.value)}
        />
        <br/>
    </div><br/>
  
    <CSVLink  style={{float: "right", marginRight: "80px", border: "none", color: "white", backgroundColor: "green", borderRadius: "5px", padding: "5px", textStyle: "none"}}
     filename='Gold Payment List'
     data={payments} headers={headers}>
     Download LIst</CSVLink>

    <div style = {{textAlign: "center"}}><button className='btn btn-primary' onClick={()=>getList()} >Load Payment</button></div>
    <span style={{fontSize: "medium"}}>Loan Account Number</span>
        <input
        label="Loan Number"
        type='text'
        style={{marginLeft: "5px"}}
        name="loan_account"
        onChange={(e)=>handleChange('loan_account', e.target.value)}
        />
      <div ref = {PaymentListRef} >
      <h1 className='container my-3'>Payment List</h1> 

      <Table className="table table-striped">
        <Thead>
          <Tr>
            <Th style={{ width: "10%", textAlign: "center" }}>Loan Account</Th>
            <Th style={{ width: "10%", textAlign: "center" }}>Name</Th>
            <Th style={{ width: "20%", textAlign: "center" }}>Payment Date</Th>
            <Th style={{ width: "10%", textAlign: "center" }}>Payment Type</Th>
            <Th style={{ width: "20%", textAlign: "center" }}>Payment Mode</Th>
            <Th style={{ width: "20%", textAlign: "center" }}>Refrence Number</Th>
            <Th style={{ width: "10%", textAlign: "center" }}>Payment Amount</Th>
          </Tr>
        </Thead>
        <Tbody>
        {payments && payments.map((details,idx) => {
              return <React.Fragment key={idx}>
              <Tr>
              <Td className="table-light" style={{width: "10%", textAlign: "center"}}>{details.loan_account}</Td>    
             <Td className="table-light" style={{width: "10%", textAlign: "center"}}>{details.name}</Td>
             <Td className="table-light" style={{width: "20%", textAlign: "center"}}>{details.payment_details.payment_date}</Td>
             <Td className="table-light" style={{width: "10%", textAlign: "center"}}>{details.payment_details.payment_type}</Td>
             <Td className='table-light' style={{width: "20%", textAlign: "center"}}>{details.payment_details.payment_mode}</Td>
             <Td className="table-light" style={{width: "20%", textAlign: "center"}}>{details.payment_details.payment_ref}</Td>
             <Td className="table-light" style={{width: "10%", textAlign: "center"}}>{details.payment_details.payment_amount}</Td>
             </Tr>
             </React.Fragment>
      })}         
        </Tbody>
        </Table>      
       </div>
    </>
  )
}
export default PaymentList
