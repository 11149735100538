import React, { useEffect, useState } from "react";
import { CheckInModal } from "./CheckInModal";
import { ApproveCheckInModal } from "./ApproveCheckInModal";
import { ledgerService } from "../../_services/ledger.service";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import roleChecker from "../../_helpers/rolechecker";
import { employeeService } from "../../_services";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Button } from "@material-ui/core";
import datehelper from "../../_helpers/datehelper"

export const CashCheckOutList = () => {
    const [pendingTransactions, setPendingTransactions] = useState([])
    const [approvedTransactions, setApprovedTransactions] = useState([])
    const [employees, setEmployees] = useState({})
    const [ledgers, setLedgers] = useState({})
    const user = employeeService.userValue

    const deleteLedgerTransaction = (id) => {
        let yes = window.confirm('Are you sure! You want to delete this')
        if (yes) {
            ledgerService.deleteLedgerTransaction(id)
                .then(res => {
                    window.alert('Request deleted')
                    window.location.reload()
                })
                .catch(err => {
                    window.alert('Some error occurred')
                    console.log(err)
                })
        }
    }

    const pendingLedgerTransactions = () => {
        ledgerService.pendingCheckOutLedgerTransactions()
            .then(res => {
               setPendingTransactions(res)
            })
            .catch(err => console.log(err))
    }

    const approvedLedgerTransactions = () => {
        ledgerService.approvedCheckOutLedgerTransactions()
            .then(res => setApprovedTransactions(res))
    }

    function getLedgers() {
        let list = {}
        ledgerService.getLedgerList().then((ldrs) => {
            if (ldrs.length > 0) {
                ldrs.map(ldr => {
                    list[`${ldr._id}`] = ldr.branch_id
                })
            }
            setLedgers(list)
        });
    }

    const getEmployees = () => {
        employeeService.getAll()
            .then(res => {
                let list = {}
                if (res.length > 0) {
                    res.map(emp => {
                        list[`${emp.emp_id}`] = emp.first_name + ' ' + emp.middle_name + ' ' + emp.last_name
                    })
                }
                setEmployees(list)
            })
    }

    useEffect(() => {
        pendingLedgerTransactions()
        getEmployees()
        getLedgers()
        approvedLedgerTransactions()
    }, [])
    return (
        <div style={{ padding: '20px', padding: 'auto' }}>
            <h2>Cash Management <ArrowForwardIcon />Check Out</h2>
            <div style={{ width: '100%', display: 'flex', border: '1px solid black', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                <div style={{width:'100%', display:'flex', justifyContent:'flex-end' }}>                 
                    {roleChecker.isBMLevelAccess(user.role) && <CheckInModal debit='hoLedgers' credit='otherLedgers' />}
                    &nbsp;&nbsp;{(roleChecker.isGoldRider(user.role)||roleChecker.isBMLevelAccess(user.role)) && (pendingTransactions.length > 0) && <ApproveCheckInModal ledgers={ledgers} transaction_type='CheckOut' employees={employees} pendingTransactions={pendingTransactions.filter(trxn=> {return (trxn.verification_details && trxn.verification_details.handed_over_to_emp_id===user.emp_id)&&(trxn.verification_details.requester_emp_id!==user.emp_id)})} />}
                </div>
                <div style={{ width: '100%', border: '1px solid black', margin: '20px', padding: '20px' }} >
                    <Table style={{ textAlign: 'center' }}>
                        <Thead>
                            <Tr>
                                <Th style={{ width: '10%', textAlign: 'center' }}>Amount</Th>
                                <Th style={{ width: '10%', textAlign: 'center' }}>From Ledger</Th>
                                <Th style={{ width: '10%', textAlign: 'center' }}>To Ledger</Th>
                                <Th style={{ width: '20%', textAlign: 'center' }}>Check-Out Date</Th>
                                <Th style={{ width: '20%', textAlign: 'center' }}>Approval Date</Th>
                                <Th style={{ width: '20%', textAlign: 'center' }}>Check-Out By</Th>
                                <Th style={{ width: '20%', textAlign: 'center' }}>Handed Over To</Th>
                                <Th style={{ width: '10%', textAlign: 'center' }}>Status</Th>
                                <Th style={{ width: '20%', textAlign: 'center' }}>Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {(pendingTransactions && pendingTransactions.length > 0) || (approvedTransactions && approvedTransactions.length > 0) ?
                                <React.Fragment>
                                    {pendingTransactions.length > 0 && pendingTransactions.map(transaction => {
                                        return <Tr key={transaction._id}>
                                            <Td>
                                                {transaction.cash_summary &&
                                                    <>{transaction.cash_summary.total_amount.toLocaleString("en-IN", {
                                                        // maximumFractionDigits: 2,
                                                        style: "currency",
                                                        currency: "INR",
                                                    })}</>}
                                            </Td>
                                            <Td>
                                                {transaction.debit_ledger.length>0 && transaction.debit_ledger[0].ledger_name}
                                            </Td>

                                            <Td>
                                                {transaction.credit_ledger.length>0 && transaction.credit_ledger[0].ledger_name}
                                            </Td>
                                            <Td>
                                                {datehelper.displayDateTime(new Date(transaction.createdAt))}
                                            </Td>
                                            <Td>
                                            </Td>
                                            <Td>
                                                {transaction.verification_details && <>{employees[transaction.verification_details.requester_emp_id]}</>}
                                            </Td>
                                            <Td>
                                                {transaction.verification_details&& transaction.verification_details.handed_over_to_emp_id&& <>{employees[transaction.verification_details.handed_over_to_emp_id]}</>}
                                            </Td>
                                            <Td style={{ color: 'rgb(238,206,36)' }}>
                                                Pending
                                            </Td>
                                            <Td style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                <CheckInModal viewOnly={true} transaction={transaction} />
                                                {transaction && transaction.verification_details && (transaction.verification_details.requester_emp_id === user.emp_id) && <Button color="secondary" onClick={() => deleteLedgerTransaction(transaction._id)}>Delete</Button>}
                                            </Td>
                                        </Tr>
                                    })}
                                    {approvedTransactions.length > 0 && approvedTransactions.map(transaction => {
                                        return <Tr key={transaction._id}>
                                            <Td>
                                                {transaction.cash_summary &&
                                                    <>{transaction.cash_summary.total_amount.toLocaleString("en-IN", {
                                                        // maximumFractionDigits: 2,
                                                        style: "currency",
                                                        currency: "INR",
                                                    })}</>}
                                            </Td>
                                            <Td>
                                                {transaction.debit_ledger.length>0 && transaction.debit_ledger[0].ledger_name}
                                            </Td>

                                            <Td>
                                                {transaction.credit_ledger.length>0 && transaction.credit_ledger[0].ledger_name}
                                            </Td>
                                            
                                            <Td>
                                                {datehelper.displayDateTime(new Date(transaction.createdAt))}
                                            </Td>
                                            <Td>
                                                {datehelper.displayDateTime(new Date(transaction.updatedAt))}
                                            </Td>
                                            <Td>
                                                {transaction.verification_details && <>{employees[transaction.verification_details.requester_emp_id]}</>}
                                            </Td>
                                            <Td>
                                                {transaction.verification_details&& transaction.verification_details.handed_over_to_emp_id&& <>{employees[transaction.verification_details.handed_over_to_emp_id]}</>}
                                            </Td>
                                            <Td style={{ color: 'green' }}>
                                                Approved
                                            </Td>
                                            <Td style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                <CheckInModal viewOnly={true} transaction={transaction} />
                                            </Td>
                                        </Tr>
                                    })}
                                </React.Fragment>
                                :
                                <Tr>
                                    <Td align='center' colSpan='8'>Data not available</Td>
                                </Tr>
                            }
                        </Tbody>
                    </Table>
                    {console.log('Pending Transactions',pendingTransactions)}
                </div>
            </div>
        </div>
    )
}