import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { Button } from "@material-ui/core";
import { employeeService, loanService } from "../../../../_services";
import { OperationSubStages } from "../../../../constants/operationsubstages";
import { CreditSubStages} from "../../../../constants/creditsubstages"
import datehelper from "../../../../_helpers/datehelper";
import { DoneOutline,SaveRounded} from "@material-ui/icons";

export default function StageSteps(props)
{
    const customer =props.customer;
    const filterId=props.filterId;
    const user = employeeService.userValue;
    const maxDate = datehelper.getMaxPastDate("Y-m-d")
    const id = customer._id;
    let doneDates = {}
    let stageSteps={}
    let allStageSteps={}
    if( filterId=="Collateral")
    {
      allStageSteps[OperationSubStages.FileReceived]={'Name':'Docket/Files received from Branch'}
      allStageSteps[OperationSubStages.PropertyPapersAtHO]={'Name':'Original Property papers received at Branch'}
    }
    if( filterId=="KYC")
    {
      allStageSteps[CreditSubStages.KYCPending]={'Name':'KYC Verification done'}
    }
    
    if(customer.stage_steps)
    {
        console.log(customer.stage_steps);
        customer.stage_steps.filter(ss=> {
            console.log(ss.stage_step_name)
            if(ss.stage_step_name===OperationSubStages.FileReceived || ss.stage_step_name===OperationSubStages.PropertyPapersAtHO )
            {
             if(filterId=="Collateral")
             {
                stageSteps[ss.stage_step_name]=ss;
                return true;
             }
            }
            if(ss.stage_step_name===CreditSubStages.KYCPending)
            {
             if(filterId=="KYC")
             {
                stageSteps[ss.stage_step_name]=ss;
                return true;
             }
            }
             return false;
        })
    }

    console.log(stageSteps);

    const handleDateChange=(key,e) =>{
             console.log(key);
             doneDates[key]= e.target.value
             console.log("date for "+key+" is "+e.target.value);
         
      }

    const submitDoneDate=(key)=> {
        if(!(key in doneDates) )
        {
             alert(" Please enter valid dates");
             return;
        }
        let tmp_stage_steps=[]
        if(customer.stage_steps)
        {
           tmp_stage_steps=customer.stage_steps;
        }
        tmp_stage_steps=tmp_stage_steps.filter(ss=> {
            if(ss.stage_step_name==key)
            {
              return false;
            }
            return true;
        })
        let step_details={
          'stage_step_name': key,
          'stage_step_owner_emp_id': user.emp_id,
          "stage_step_done_date": new Date(doneDates[key])
        }
        tmp_stage_steps.push(step_details)
        console.log(step_details)
        console.log(tmp_stage_steps);
        loanService.update(id, {stage_steps:tmp_stage_steps})
        .then((data)=>{
          alert("Data added Successfully");
          window.location.reload();
        })
        .catch((err)=>{
          console.log(err)
        })
      
    }  

    return (
       <div>
        <table   style={{border: "2px solid black"}}>
            <thead>
                <tr><td></td><td>Items</td><td>Done date</td><td style={{'width':'150px'}}>By Employee ID</td><td style={{'width':'50px'}}></td></tr>
            </thead>
          <tbody style={{border: "2px solid black"}}>
            {Object.keys(allStageSteps).map(ss=> {
                return (
                    <tr key={ss}>
                        {ss in stageSteps && (
                            <>
                            <td style={{'color':'green','width':'50px'}}><DoneOutline/></td><td>{allStageSteps[ss]['Name']}</td><td>{datehelper.displayDate(new Date(stageSteps[ss]['stage_step_done_date']))}</td><td>{stageSteps[ss]['stage_step_owner_emp_id']}</td>
                            </>
                        )}
                        { !(ss in stageSteps ) && (
                            <>
                            <td ></td><td>{allStageSteps[ss]['Name']}</td>
                           <td><label style={{ width: "350px" }}> 
            <input
              type={"date"}
              name="done_date"
              max= {maxDate}
              required
              onChange={(e)=> handleDateChange(ss,e)}
              onKeyDown="return false"
            />
          </label></td><td></td>
                    <td><Button color="primary" variant="contained" onClick={()=> submitDoneDate(ss)} >
                       <SaveRounded/>
                       </Button></td>
                            </>
                        )}
                        
                    </tr>
                )
            })}

          </tbody>

        </table>
       </div>
    );
}

