import React from "react";
import { Route, Switch } from "react-router-dom";
import { CashCheckInList } from "./CashCheckInList";
import { CashCheckOutList } from "./CashCheckOutList";
import { CashLedgerList } from "../Accounting/ledger/CashLedgerList";
import { LedgerTransactionHistory } from "../Accounting/ledger/LedgerTransactionHistory";
import CashRequestList from "./CashRequestList";
import ViewCashRequest from "./ViewCashRequest";
import CashBankDeposit from "./CashBankDepositList";
import  BankLedgerList  from "../Accounting/ledger/BankLedgerList";
import  BankLedgerTransactionHistory from "../Accounting/ledger/BankLedgerTransactionHistory";
function CashManagementIndex({ match }) {
  const { path } = match;

  return (
    <Switch>
      <Route path={`${path}/cashcheckinlist`} component={CashCheckInList} /> 
      <Route path={`${path}/cashcheckoutlist`} component={CashCheckOutList} /> 
      <Route path={`${path}/cashledgerlist`} component={CashLedgerList} /> 
      <Route path={`${path}/transactionhistory`} component={LedgerTransactionHistory} /> 
      <Route path={`${path}/cashrequest`} component={CashRequestList}/>
      <Route path={`${path}/cashrequestview`} component={ViewCashRequest}/>
      <Route path={`${path}/cashbankdepositlist`} component={CashBankDeposit}/>
      <Route path={`${path}/bankledgerlist`} component={BankLedgerList} />
      <Route path={`${path}/banktransactionhistory`} component={BankLedgerTransactionHistory} /> 
    </Switch>
  );
}

export { CashManagementIndex };
