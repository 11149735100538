import React from "react";
import { Route, Switch } from "react-router-dom";

import { ReportsList } from "./ReportsList";

function Reports({ match }) {
  const { path } = match;

  return (
    <Switch>
      <Route exact path={path} component={ReportsList} />
    </Switch>
  );
}

export { Reports };