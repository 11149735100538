import { Button, Modal, Box } from '@material-ui/core';
import { Field } from 'formik';
import React, { useState } from 'react'
import AddEditCaseModal from './AddEditCaseModal';
import { history } from '../../../_helpers';
import { legalServices } from '../../../_services/legal_cases.services';

const ActionModal = (props) => {
    console.log("Test props",props)
    let courtCase = props.courtCases
    let customerData = props.customerData
    let id=customerData.case_info[0]._id
    console.log('Test customer Data & courtCases', courtCase, customerData)
    const style = {
        position: "absolute",
        top: "22.5%",
        left: "90%",
        transform: "translate(-50%, -50%)",
        width: 250,
        height: 220,
        bgcolor: "background.paper",
        border: "3px solid #3760F1",
        boxShadow: 24,
        p: 4,
        borderRadius: '5px',
        background: '#FFD2D2'
    };
    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const createNewCases=()=>{
        history.push("/home/legalcases/legalcaseslist")
    }
    const CloseCourtCase=async()=>{
        await legalServices.closeCase(id)
        .then((res)=>{alert("Case Closed")
         createNewCases()})
        .catch((err)=>console.log(err))
    }
    return (
        <div>
            <Button
                onClick={handleOpen}
                style={{ float: 'right', marginRight: '30px', background: '#DF2D2D', color: 'white', width: '120px' }}>Action</Button>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <ul style={{ fontSize: '25px', fontWeight: 'bold', color: 'black',marginTop:"-25px",marginLeft:"-30px" }}>
                        <li><AddEditCaseModal btn={"arbitration"} courtCase={courtCase} customerData={customerData} /></li>
                        <li><AddEditCaseModal btn={"ni138"} courtCase={courtCase} customerData={customerData} /></li>
                        <li><AddEditCaseModal btn={"execution"} courtCase={courtCase} customerData={customerData} /></li>
                        <li><Button onClick={createNewCases} style={{ fontWeight: 'bold' }}>Create New cases</Button></li>
                        <li><Button onClick={CloseCourtCase} style={{ fontWeight: 'bold' }}>Close Case</Button></li>
                    </ul>
                </Box>
            </Modal>
        </div>
    )
}

export default ActionModal
