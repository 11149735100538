import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CloseIcon from '@material-ui/icons/Close';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Button,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Badge,
    Snackbar,
} from '@material-ui/core';
import { loanService, employeeService } from "../../_services";

// Connect to the Socket.IO server
// const socket = io(process.env.REACT_APP_API_URL);
const socket = io("wss://api.kaabilfinance.com", {
  transports: ["websocket"], // Ensure WebSocket is used
  reconnection: true,        // Enable reconnection attempts
  reconnectionAttempts: 5,   // Max attempts
  reconnectionDelay: 1000,   // Delay between attempts (1 second)
  timeout: 5000,             // Set a timeout for connection
});
const user = employeeService.userValue;

function NotificationComponent() {
    const [notifications, setNotifications] = useState([]);
    const [messageCount, setMessageCount] = useState(0);
    const [open, setOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const showNotification = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setSnackbarMessage("");
    };

    useEffect(() => {
        socket.emit('register', user.emp_id);

        // Listen for new individual notifications
        socket.on('notification', (data) => {
            showNotification(`Hello 👋 ${user.emp_id}, You have a new message waiting for you: ${data.message}`);
            setNotifications((prevNotifications) => [data, ...prevNotifications]);
            setMessageCount((prevCount) => prevCount + 1);
        });

        // Listen for batch notifications on initial load
        socket.on('notificationBatch', (unreadNotifications) => {
            setNotifications(unreadNotifications);
            const unreadCount = unreadNotifications.filter((notif) => !notif.read).length;
            setMessageCount(unreadCount);
        });

        // Clean up socket listeners on component unmount
        return () => {
            socket.off('notification');
            socket.off('notificationBatch');
        };
      
        // return () => {
        //     if (socket.readyState === 1) { // <-- This is important
        //         socket.close();
        //     }
        // }
    }, []);

    const markAsRead = (index, notificationId) => {
        setNotifications((prevNotifications) => {
            const newNotifications = [...prevNotifications];
            newNotifications[index].read = true;
            return newNotifications;
        });
        setMessageCount((prevCount) => Math.max(0, prevCount - 1));
        socket.emit('markAsRead', notificationId);
    };

    const markAllAsRead = () => {
        const unreadNotifications = notifications.filter((n) => !n.read);
        if (unreadNotifications.length > 0) {
            setNotifications((prevNotifications) =>
                prevNotifications.map((notification) => ({ ...notification, read: true }))
            );
            setMessageCount(0);
            socket.emit('markAllAsRead', {
                emp_id: user.emp_id,
                notificationIds: unreadNotifications.map((n) => n._id),
            });
        }
    };

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Badge badgeContent={messageCount} color="error" overlap="circular">
                    <NotificationsIcon
                        style={{ width: '35px', height: '35px', cursor: 'pointer' }}
                        onClick={handleOpen}
                    />
                </Badge>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            position: 'absolute',
                            top: '4%',
                            right: '1px',
                            maxWidth: '500px',
                            width: '90%',
                        },
                    }}
                >
                    <DialogTitle
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                    >
                        Notifications
                        <Button onClick={markAllAsRead}>Mark All as Read</Button>
                        <IconButton onClick={handleClose} size="small">
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent style={{ maxHeight: '400px', overflowY: 'auto' }}>
                        <List>
                            {[...notifications].reverse().map((notification, index) => (
                                <ListItem key={notification._id || index} divider>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant="body2"
                                                style={{
                                                    whiteSpace: 'normal',
                                                    wordBreak: 'break-word',
                                                    marginRight: '60px',
                                                    backgroundColor: notification.read ? 'lightgrey' : 'white',
                                                }}
                                                onClick={() => markAsRead(index, notification._id)}
                                            >
                                                {notification.message}
                                            </Typography>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <Button
                                            component="a"
                                            variant="contained"
                                            color="primary"
                                            href={notification.action_link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            size="small"
                                            style={{ marginLeft: '10px' }}
                                        >
                                            View Details
                                        </Button>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </DialogContent>
                </Dialog>
            </div>

            {/* Snackbar for displaying real-time notification */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            />
        </>
    );
}

export default NotificationComponent;
