// import React from 'react'
// import Box from '@material-ui/core/Box';
// import Typography from '@material-ui/core/Typography';
// import Modal from '@material-ui/core/Modal';
// import { Button } from '@material-ui/core';
// import { kycService } from '../../../../_services/kyc.service';
// import { loanService } from '../../../../_services';
// import OtpInput from 'react-otp-input';

// const AadharOtpSendModal = (props) => {
//     const masked_aadhar=props.customer && props.customer.general_information.masked_aadhar;
//     const [open, setOpen] = React.useState(false);
//     const [otpModal, setOtpModal] = React.useState(false);
//     const [enteredOtp, setEnteredOtp] = React.useState('');
//     const [value, setValue] = React.useState('');
//     const [response, setResponse] = React.useState({})
//     const [spinner, setSpinner] = React.useState(false);


//     const customer = props.customer

//     const handleOpen = () => setOpen(true);
//     const handleClose = () => setOpen(false);

//     const handleOtpModal = () => setOtpModal(false);


//     const style = {
//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         transform: 'translate(-50%, -50%)',
//         width: 450,
//         height: 340,
//         bgcolor: 'rgb(224,224,224)',
//         border: '2px solid #000',
//         boxShadow: 24,
//         p: 4,
//         borderColor: "blue",
//         borderRadius: "10px"
//         // overflow: 'scroll',
//         // paddingBottom: '30px',
//     };
//     const style2 = {
//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         transform: 'translate(-50%, -50%)',
//         width: 480,
//         height: 350,
//         bgcolor: 'rgb(224,224,224)',
//         border: '2px solid #000',
//         boxShadow: 24,
//         p: 4,
//         borderColor: "blue",
//         borderRadius: "10px"
//         // overflow: 'scroll',
//         // paddingBottom: '30px',
//     };

//     const sendOtp = (e) => {
//         e.preventDefault()

//         // setOtpModal(true)
//         // handleClose()
//         if(value.slice(-4)===masked_aadhar.slice(-4)){
//         setOtpModal(true)
//         handleClose()
//         kycService.requestAadhaarOTP({ aadhar_no: value })
//             .then((resp) => {
//                 setResponse(resp.response)
//                 console.log(resp.response)
//                 alert("OTP sent")
//             })
//             .catch((err) => {
//                 console.log(err)
//             })
//         }else{
//             alert("Aadhar Number is not matching")
//         }
//     }

//     const addKarzaVerified = () => {
//         loanService.addKarzaVerified({ status: true, application_id: props.application_id, _id: customer._id, customer_id: customer.customer_id, role: props.role })
//             .then((resp) => {
//                 console.log(resp)
//                 alert("Aadhar Verified Success")
//                 window.location.reload()
//             })
//             .catch((err) => {
//                 console.log(err)
//             })
//     }

//     const verifyOtp = () => {
//         if (enteredOtp.length == 6) {
//             setSpinner(true)
//             kycService.requestAadhaarXML({ aadhar_no: `${value}`, otp: `${enteredOtp}`, request_id: response.requestId })
//                 .then((data) => {
//                     console.log(data)
//                     setSpinner(false)
//                     if (data.response.statusCode === 101) {
//                         addKarzaVerified()
//                         setOtpModal(false)
//                     } else {
//                         alert("Invalid OTP. Please try again!")
//                     }
//                 })
//                 .catch((err) => {
//                     setSpinner(false)
//                     console.log(err)
//                 })
//         } else {
//             setSpinner(false)
//             alert("Enter the valid otp")
//         }
//     }

//     return (
//         <div>
//             {customer.general_information.karza_aadhar_verified ? 
//              <span title="Verified">
//                 <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-patch-check" viewBox="0 0 16 16" style={{color:"green",paddingLeft:"3px",cursor:"pointer"}}>
//              <path fillRule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0"/>
//              <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622-.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911z"/>
//          </svg>
//                 </span>
//             :
//             <span onClick={() => handleOpen()}   title="Verify">  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-patch-plus" viewBox="0 0 16 16" style={{ transform: 'rotate(45deg)' ,color:"red",paddingLeft:"3px",cursor:"pointer"}}>
//             <path fillRule="evenodd" d="M8 5.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 .5-.5"/>
//             <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622-.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911z"/>
//         </svg></span>

//             }
//             <Modal
//                 open={open}
//                 onClose={handleClose}
//                 aria-labelledby="modal-modal-title"
//                 aria-describedby="modal-modal-description"
//             >
//                 <Box sx={style}>
//                     <Typography id="modal-modal-title" variant="h6" component="h2">
//                         <h4 style={{ textAlign: "center", color: "blue" }}>KYC-Aadhar Verification</h4><br />
//                         <h6 style={{ textAlign: "center", color: "red" }}>Adhaar Number must match with {masked_aadhar}</h6><br />

//                         <form style={{ marginLeft: "20px" }} onSubmit={(e) => sendOtp(e)}>
//                             <label style={{ textAlign: "center", color: "blue", marginLeft: "50px" }}>Enter Aadhar Number</label>
//                             <input type='text' style={{ width: "300px", borderColor: "blue", marginLeft: "20px", color: "blue" }} required onChange={(e) => setValue(e.target.value)} pattern="[0-9]{12}" oninvalid="setCustomValidity('Plz enter on Alphabets ')" /> <br /><br />
//                             <button style={{ marginLeft: "90px", backgroundColor: "blue", height: "35px" }}><p style={{ color: "white" }}>Send OTP</p></button>
//                         </form>
//                     </Typography>
//                 </Box>
//             </Modal>

//             <Modal
//                 open={otpModal}
//                 onClose={handleOtpModal}
//                 aria-labelledby="modal-modal-title"
//                 aria-describedby="modal-modal-description"
//             >
//                 <Box sx={style2}>
//                     <Typography id="modal-modal-title" variant="h6" component="h2">
//                     {spinner ? <div style={{marginLeft:"45%", marginTop:"30%"}}><span className="spinner-border spinner-border-lg align-center"></span></div> :<>
//                         <h4 style={{ textAlign: "center", color: "blue" }}>KYC-Aadhar Verification</h4>

//                         <p style={{ color: "red", textAlign: "center", fontSize: "16px" }}>A 6 digit code has been sent to your registered mobile number</p>

//                         <label style={{ marginLeft: "36%", color: "blue" }}>Enter OTP</label><br />
//                         <OtpInput
//                             value={enteredOtp}
//                             inputStyle={{ width: "50px", color: "blue" }}
//                             inputType='number'
//                             containerStyle={{ height: "50px", marginBottom: "-40px", marginLeft: "10px", borderColor: "rgb(52,117,92)" }}
//                             onChange={(text) => setEnteredOtp(text)}
//                             numInputs={6}
//                             renderSeparator={<span>&nbsp; &nbsp;</span>}
//                             renderInput={(props) => <input {...props} />}
//                         /> <br /><br />

//                         <button style={{ backgroundColor: "blue", height: "35px", marginLeft: "40%" }} onClick={() => verifyOtp()}><p style={{ color: "white" }}>Submit</p></button> <br />
//                         <Button style={{ color: "blue", marginLeft: "34%", marginTop: "8px", fontSize: "14px" }} onClick={(e) => sendOtp(e)} >Resend code</Button>
//                         </>}
//                     </Typography>
//                 </Box>
//             </Modal>
//         </div>
//     )
// }

// export default AadharOtpSendModal




import React,{useEffect} from 'react'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import { Button } from '@material-ui/core';
import { kycService } from '../../../../_services/kyc.service';
import { loanService,customerService } from '../../../../_services';
import OtpInput from 'react-otp-input';


const AadharOtpSendModal = (props) => {
    const masked_aadhar=props.customer && props.customer.general_information.masked_aadhar;
    const [open, setOpen] = React.useState(false);
    const [otpModal, setOtpModal] = React.useState(false);
    const [enteredOtp, setEnteredOtp] = React.useState('');
    const [value, setValue] = React.useState('');
    const [response, setResponse] = React.useState({})
    const [spinner, setSpinner] = React.useState(false);


    const customer = props.customer
    const Customer_ID = props.customer && props.customer.customer_id
    console.log(Customer_ID,"Customer_IDCustomer_IDCustomer_IDCustomer_ID")
    // const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleOtpModal = () => setOtpModal(false);


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        height: 340,
        bgcolor: 'rgb(224,224,224)',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        borderColor: "blue",
        borderRadius: "10px"
        // overflow: 'scroll',
        // paddingBottom: '30px',
    };
    const style2 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 480,
        height: 350,
        bgcolor: 'rgb(224,224,224)',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        borderColor: "blue",
        borderRadius: "10px"
        // overflow: 'scroll',
        // paddingBottom: '30px',
    };

    const sendOtp = (e) => {
        e.preventDefault()
        setOpen(true)
        // setOtpModal(true)
        // handleClose()
        if(aadharverification.slice(-4)===masked_aadhar.slice(-4)){
        setOtpModal(true)
        handleClose()
        kycService.requestAadhaarOTP({ aadhar_no: aadharverification })
            .then((resp) => {
                setResponse(resp.response)
                console.log(resp.response)
                alert("OTP sent")
            })
            .catch((err) => {
                console.log(err)
            })
        }else{
            alert("Aadhar Number is not matching")
        }
    }

    const addKarzaVerified = () => {
        loanService.addKarzaVerified({ status: true, application_id: props.application_id, _id: customer._id, customer_id: customer.customer_id, role: props.role })
            .then((resp) => {
                console.log(resp)
                alert("Aadhar Verified Success")
                window.location.reload()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const verifyOtp = () => {
        if (enteredOtp.length == 6) {
            setSpinner(true)
            kycService.requestAadhaarXML({ aadhar_no: `${aadharverification}`, otp: `${enteredOtp}`, request_id: response.requestId })
                .then((data) => {
                    console.log(data)
                    setSpinner(false)
                    if (data.response.statusCode === 101) {
                        addKarzaVerified()
                        setOtpModal(false)
                    } else {
                        alert("Invalid OTP. Please try again!")
                    }
                })
                .catch((err) => {
                    setSpinner(false)
                    console.log(err)
                })
        } else {
            setSpinner(false)
            alert("Enter the valid otp")
        }
    }




    
    const [aadharverification, setAadharverification] = React.useState({})
    async function getApplicant() {
      // console.log({customer_id:props.applicant.customer_id},"hhh")
      await customerService.searchCustomer({ customer_id: Customer_ID })
          .then((res) => {
            setAadharverification(res[0].verification_details.aadhaar_no)
          })
          .catch(err => console.log(err,"iiiii"))
  }

  useEffect(() => {
      getApplicant()
  }, [])

  console.log(aadharverification,"aadharverification")
    return (
        <div>
            {customer.general_information.karza_aadhar_verified ? 
             <span title="Verified">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-patch-check" viewBox="0 0 16 16" style={{color:"green",paddingLeft:"3px",cursor:"pointer"}}>
             <path fillRule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0"/>
             <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622-.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911z"/>
         </svg>
                </span>
            :
            <span onClick={(e) => sendOtp(e)}  title="Verify">  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-patch-plus" viewBox="0 0 16 16" style={{ transform: 'rotate(45deg)' ,color:"red",paddingLeft:"3px",cursor:"pointer"}}>
            <path fillRule="evenodd" d="M8 5.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 .5-.5"/>
            <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622-.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911z"/>
        </svg></span>

            }
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <h4 style={{ textAlign: "center", color: "blue" }}>KYC-Aadhar Verification</h4><br />
                        <h6 style={{ textAlign: "center", color: "red" }}>Adhaar Number must match with {masked_aadhar}</h6><br />

                        <form style={{ marginLeft: "20px" }}>
                            <label style={{ textAlign: "center", color: "blue", marginLeft: "50px" }}>Enter Aadhar Number</label>
                            <input type='text' style={{ width: "300px", borderColor: "blue", marginLeft: "20px", color: "blue" }} required onChange={(e) => setValue(e.target.value)} pattern="[0-9]{12}" oninvalid="setCustomValidity('Plz enter on Alphabets ')" /> <br /><br />
                            <button style={{ marginLeft: "90px", backgroundColor: "blue", height: "35px" }}><p style={{ color: "white" }}>Send OTP</p></button>
                        </form>
                    </Typography>
                </Box>
            </Modal>

            <Modal
                open={otpModal}
                onClose={handleOtpModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style2}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                    {spinner ? <div style={{marginLeft:"45%", marginTop:"30%"}}><span className="spinner-border spinner-border-lg align-center"></span></div> :<>
                        <h4 style={{ textAlign: "center", color: "blue" }}>KYC-Aadhar Verification</h4>

                        <p style={{ color: "red", textAlign: "center", fontSize: "16px" }}>A 6 digit code has been sent to your registered mobile number</p>

                        <label style={{ marginLeft: "36%", color: "blue" }}>Enter OTP</label><br />
                        <OtpInput
                            value={enteredOtp}
                            inputStyle={{ width: "50px", color: "blue" }}
                            inputType='number'
                            containerStyle={{ height: "50px", marginBottom: "-40px", marginLeft: "10px", borderColor: "rgb(52,117,92)" }}
                            onChange={(text) => setEnteredOtp(text)}
                            numInputs={6}
                            renderSeparator={<span>&nbsp; &nbsp;</span>}
                            renderInput={(props) => <input {...props} />}
                        /> <br /><br />

                        <button style={{ backgroundColor: "blue", height: "35px", marginLeft: "40%" }} onClick={() => verifyOtp()}><p style={{ color: "white" }}>Submit</p></button> <br />
                        <Button style={{ color: "blue", marginLeft: "34%", marginTop: "8px", fontSize: "14px" }} onClick={(e) => sendOtp(e)} >Resend code</Button>
                        </>}
                    </Typography>
                </Box>
            </Modal>
        </div>
    )
}

export default AadharOtpSendModal