import React from "react";
import { Box, Button, Modal, Table } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { authPersonService } from "../../../_services/auth_person.service"
import { Add, Minimize, Search } from "@material-ui/icons";
import { useState } from "react";
import { misService } from "../../../_services";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { history } from "../../../_helpers"
export const BulkSubmissionModal = (props) => {
    const loan = props.loan
    const [colendingBank, setColendingBank] = React.useState(null);
    const [authPersonList, setAuthPersonList] = React.useState([]);
    const [authPerson, setAuthPerson] = React.useState({
        name: "",
        empId: "",
    });

    React.useEffect(() => {
        authPersonService.getAll().then((x) => {
            setAuthPersonList(x);
        });
    }, []);
    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false);
    }
    const [loans, setLoans] = useState('')
    const handleChange = (loans) => {
        setLoans(loans)
    }

    const [updateLoans, setUpdateLoans] = useState([])
    const removeLoans = (index) => {
        const temp = [...updateLoans]
        temp.splice(index, 1);
        setUpdateLoans(temp);
    };

    const AddLoanAccount = () => {
        console.log("array", updateLoans)
        misService.getLoanByAccount(loans)
            .then((res) => {
                if (res.length > 0) {
                    setUpdateLoans(updateLoans => [...updateLoans, res[0]])
                }
                else {
                    window.alert("Loan number not exist")
                }
            })
            .catch((err) => console.log(err))
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        height: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'scroll',
    }
    const submissionPrint = () => {
        history.push("/home/bulksubmission", {
            updateLoans,
            authPerson,
            colendingBank,
        });
    };
    return (
        <div>
            <div>
                <div>
                    <Button
                        onClick={() => handleOpen()}
                        style={{ marginTop: "100px", border: "1px solid", backgroundColor: "blue", color:"white"}}
                    >
                        Generate Letter
                    </Button>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <form onSubmit={(e) => submissionPrint(e)}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        margin: "20px",
                                    }}
                                >
                                    <div>
                                        <InputLabel
                                            id="demo-simple-select-outlined-label"
                                            style={{ color: "black", marginRight: "500px" }}
                                        >
                                            Auth Person
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={authPerson.name}
                                            onChange={(e) =>
                                                setAuthPerson({
                                                    name: e.target.value,
                                                    empId: e.currentTarget.getAttribute("data-key"),
                                                })
                                            }
                                            label="Auth Person"
                                            required
                                        >
                                            {authPersonList.map((item, idx) => (
                                                <MenuItem
                                                    value={item.name}
                                                    key={idx}
                                                    data-key={item.id}
                                                >
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {/* {console.log("test auth",authPersonList)} */}
                                    </div>
                                    <div>
                                        <InputLabel
                                            style={{ color: "black" }}
                                            id="demo-simple-select-outlined-label"
                                        >
                                            Co-Lending Bank
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={colendingBank}
                                            onChange={(e) => setColendingBank(e.target.value)}
                                            label="Co-lending Banks"
                                            required
                                        >
                                            {/* <MenuItem
                                                value="CSB"
                                                key="CSB"
                                                data-key="CSB"
                                            >
                                                CSB
                                            </MenuItem> */}
                                            <MenuItem
                                                value="Fed"
                                                key="Fed"
                                                data-key="Fed"
                                            >
                                                Federal Bank
                                            </MenuItem>

                                        </Select>
                                    </div>
                                </div>
                                <div>
                                    <InputLabel
                                        id="demo-simple-select-outlined-label"
                                        style={{ textAlign: "center", color: 'black' }}
                                    >
                                        Select Loan No
                                    </InputLabel>
                                    <div>
                                        <input
                                            style={{
                                                outline: "none",
                                                border: "none",
                                                borderBottom: "2px solid black",
                                                marginLeft: "270px",
                                                marginBottom: "30px"
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}

                                            value={loans}
                                            type="text"
                                            placeholder="Enter Loan no"
                                            onChange={(e) => handleChange(e.target.value)}
                                        />
                                        <Button>
                                            {
                                                <Add
                                                    onClick={() => AddLoanAccount()}
                                                />
                                            }
                                        </Button>
                                        {
                                            <div>
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>Added Loans</th>
                                                            <th>Remove Loans</th>
                                                        </tr>
                                                    </thead>
                                                    <Tbody style={{ backgroundColor: "white", border: '2px solid black' }}>
                                                        {
                                                            updateLoans.length > 0 ?
                                                                updateLoans.map((item, index) => <Tr key={index}>
                                                                    <Td>{item.loan_account}</Td>
                                                                    <Td>
                                                                        <Button onClick={() => removeLoans(index)}>
                                                                            <Minimize />
                                                                        </Button>
                                                                    </Td>
                                                                </Tr>) : null
                                                        }
                                                    </Tbody>
                                                </Table>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <br />
                                <br />
                                <button
                                    style={{ color: 'white', backgroundColor: '#0066cc', marginLeft: "250px", width: "260px", height: '40px' }}
                                >
                                    Open Bulk Submission Letter
                                </button>
                            </form>
                        </Box>
                    </Modal>
                </div>
            </div>
        </div>
    );
}
