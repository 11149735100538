import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Table, Tbody, Thead, Tr, Th, Td } from 'react-super-responsive-table';
import { Paper } from '@material-ui/core';
import { employeeService } from '../../_services';
import { makerCheckerServices } from '../../_services/makerchecker.service';
import datehelper from '../../_helpers/datehelper';
import { DataModal } from './ApprovalDataModal';
import { CircularProgress } from '@material-ui/core';


export const MyRequests = () => {
    const [requests, setRequests] = useState([]);
    const [employeeList, setEmployeeList] = useState({});
    const [isLoading, setIsLoading] = useState(false)
    const user = employeeService.userValue;

    async function getRequests(fields) {
        setIsLoading(true)
        await makerCheckerServices.getRequestsByEmpid(fields)
            .then(res => {
                setRequests(res)
                setIsLoading(false)
            })
            .catch(err => console.log(err))
    }

    async function getEmployees() {
        await employeeService.getAll()
            .then(res => {
                if (res.length > 0) {
                    let list = {}
                    res.map(e => {
                        list[e.emp_id] = e.first_name + ' ' + e.last_name
                    }
                    )
                    setEmployeeList(list)
                }
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        getRequests({ emp_id: user.emp_id });
        getEmployees()
    }
        , [])




    return (
        <div>
            <Paper style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px', paddingBottom: '50px', paddingTop: '50px' }}>
                <h4><b>{employeeList[user.emp_id]}'s Requests</b></h4>
            </Paper>
            {isLoading ?
                <Paper style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px', paddingBottom: '50px', paddingTop: '50px' }}>
                    <CircularProgress />
                </Paper> :
                <Paper style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px', paddingBottom: '50px', paddingTop: '50px' }}>
                    <div style={{ display: 'flex', color: '#6D67E4', justifyContent: 'flex-start', width: '80%' }}>
                        <b>Pending Requests</b>
                    </div>
                    <hr style={{ width: '90%' }} />
                    <Table style={{ width: '90%', margin: 'auto', textAlign: 'center' }}>
                        <Thead>
                            <Tr>
                                <Th style={{ width: '25%', textAlign: 'center' }}></Th>
                                <Th style={{ width: '25%', textAlign: 'center' }}>Date</Th>
                                <Th style={{ width: '25%', textAlign: 'center' }}>Details</Th>
                                <Th style={{ width: '25%', textAlign: 'center' }}>Task</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                requests && requests.pendingRequests && requests.pendingRequests.length > 0 ?
                                    requests.pendingRequests.map((req) => {
                                        return <Tr key={req._id}>
                                            <Td><b>{req.identification_key}</b><br />
                                                {req.identification_value}</Td>
                                            <Td>{datehelper.displayDate(new Date(req.date))}</Td>
                                            <Td><DataModal data={req} /></Td>
                                            <Td>{req.task}</Td>
                                        </Tr>
                                    }) :
                                    <Tr>
                                        <Td align='center' colSpan='6'>No request pending</Td>
                                    </Tr>
                            }
                        </Tbody>
                    </Table>
                </Paper>}
            {requests && requests.approvedRequests && requests.approvedRequests.length > 0 &&
                <Paper style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px', paddingBottom: '50px', paddingTop: '50px' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', width: '80%', color: '#355E3B' }}>
                        <b>Approved Requests</b>
                    </div>
                    <hr style={{ width: '90%' }} />
                    <Table style={{ width: '90%', margin: 'auto', textAlign: 'center' }}>
                        <Thead>
                            <Tr>
                                <Th style={{ width: '20%', textAlign: 'center' }}></Th>
                                <Th style={{ width: '20%', textAlign: 'center' }}>Date</Th>
                                <Th style={{ width: '20%', textAlign: 'center' }}>Details</Th>
                                <Th style={{ width: '20%', textAlign: 'center' }}>Task</Th>
                                <Th style={{ width: '20%', textAlign: 'center' }}>Approved By</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {requests.approvedRequests.map((req) => {
                                return <Tr key={req._id}>
                                    <Td><b>{req.identification_key}</b><br />
                                        {req.identification_value}</Td>
                                    <Td>{datehelper.displayDate(new Date(req.date))}</Td>
                                    <Td><DataModal data={req} /></Td>
                                    <Td>{req.task}</Td>
                                    <Td>{employeeList[req.approved_by]}</Td>
                                </Tr>
                            })}
                        </Tbody>
                    </Table>
                </Paper>}
            {requests && requests.rejectedRequests && requests.rejectedRequests.length > 0 &&
                <Paper style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px', paddingBottom: '50px', paddingTop: '50px' }}>
                    <div style={{ display: 'flex', color: '#922724', justifyContent: 'flex-start', width: '80%' }}>
                        <b>Rejected Requests</b>
                    </div>
                    <hr style={{ width: '90%' }} />
                    <Table style={{ width: '90%', margin: 'auto', textAlign: 'center' }}>
                        <Thead>
                            <Tr>
                                <Th style={{ width: '20%', textAlign: 'center' }}></Th>
                                <Th style={{ width: '20%', textAlign: 'center' }}>Date</Th>
                                <Th style={{ width: '20%', textAlign: 'center' }}>Details</Th>
                                <Th style={{ width: '20%', textAlign: 'center' }}>Task</Th>
                                <Th style={{ width: '20%', textAlign: 'center' }}>Rejected By</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {requests.rejectedRequests.map((req) => {
                                return <Tr key={req._id}>
                                    <Td><b>{req.identification_key}</b><br />
                                        {req.identification_value}</Td>
                                    <Td>{datehelper.displayDate(new Date(req.date))}</Td>
                                    <Td><DataModal data={req} /></Td>
                                    <Td>{req.task}</Td>
                                    <Td>{employeeList[req.rejected_by]}</Td>
                                </Tr>
                            })}
                        </Tbody>
                    </Table>
                </Paper>}
            {
                console.log(requests)
            }
        </div>
    )
}