import React from "react";
import { Route, Switch } from "react-router-dom";

import AddBranchOwner from "./AddBranchOwner"
 import BranchOwnersList from "./BranchOwnerList"

function BranchOwners({ match }) {
  const { path } = match;
  console.log('path',path)

  return (
    <Switch>
      <Route exact path={path} component={ BranchOwnersList } />
      <Route path={`${path}/add`} component={AddBranchOwner} />
      <Route path={`${path}/edit/:id`} component={AddBranchOwner} />

    </Switch>
  );
}

export { BranchOwners };
