import { Button, Modal, Table } from '@material-ui/core'
import React from 'react'
import { useState } from 'react'
import { Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table'
import logo from "../../../assets/kaabil.png";
import { employeeService } from '../../../_services';
import datehelper from '../../../_helpers/datehelper';
import { useReactToPrint } from "react-to-print";
import { BulkReleaseLetter } from "../../../_services/bulkrelease.service"
import { useEffect } from 'react';
export const BulkReleaseDoc = (props) => {
  const user = employeeService.userValue;
  console.log(" test props", props)
  const data = props.location.state.updateLoans
  const data1 = props.location.state
  const bank = props.location.state.colendingBank
  const authPerson = props.location.state.authPerson
  console.log("data", data)
  const date = new Date()
  const [count, setCount] = useState()
  useEffect(() => {
    async function getTotal() {
      await BulkReleaseLetter.countLetter()
        .then((res) => {
          setCount(res)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    getTotal()
  })
  const num = `KFPL/GL/BULK/${data1.colendingBank === "Fed" ? "FED" : "CSB"}/${date.toLocaleString("default", { month: "long" })}/${count + 1}`;

  const BulkReleaseDocRef = React.useRef();
  const handleBulkReleaseDocPrint = useReactToPrint({
    content: () => BulkReleaseDocRef.current,
    pageStyle: "print",
  });
  const handleSave = async () => {
    const bulkReleaseLetterData = data.map((x) => {
      return {
        loan_account: x.loan_account,
        name: x.name,
        gross_weight: x.gross_weight,
        net_weight: x.net_weight,
        ornaments: x.ornaments,
        date:x.date,
        gold_packet:x.gold_packet,
        bank_account_no:x.bankDetails[0].loan_account
      }
    }
    )
    await BulkReleaseLetter.saveBulkreleaseLetterDetails({
      bulkReleaseLetterData: [...bulkReleaseLetterData],
      date: new Date(),
      letter_number: num,
      emp_id: user.emp_id,
      bank: bank,
      authPerson: authPerson
    })
      .then((res) => handleBulkReleaseDocPrint())
      .catch((err) => window.alert("error  occurs"))
  }
  return (
    <>
    <div style={{display:"flex",justifyContent:'center',alignItems:"center"}}>
        {
          bank ? <button onClick={() => { handleSave() }}>Print Release  Letter</button>
            : <button onClick={() => { handleBulkReleaseDocPrint() }}>Print Release preview Letter</button>
        }
      </div>
    <div style={{display:"flex",justifyContent:'center',alignItems:"center"}}>
      <div ref={BulkReleaseDocRef} className='letter' style={{ fontSize: '18px' }}>
        <div style={{
          width: "260mm",
          height: "400mm",
          // marginLeft: "auto",
          // marginRight: "auto",
          backgroundColor: "white",
          padding: 44,
        }}
          className="d-flex flex-column p-4"
        >
          <b style={{ marginTop: '30px', textAlign: "center", marginBottom: "50px" }}>RELEASE LETTER /ACKNOWLEDGEMENT (DigiBiz)</b>
          <div>
            <b style={{ float: "right", marginTop: "30px" }}>
              {/* KFPL/GL/{data1.colendingBank === "Fed" ? "FED" : "CSB"}/  
                {date.toLocaleString("default", { month: "long" })}/ */}
              {bank ? num : props.location.state.letter_number}
            </b>
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: "30px", marginBottom: "50px", }}>
            <div>
              <p >Branch:</p>
              <p >The Federal Bank Ltd.</p>
              <p>Br Gopalbari Branch, Jaipur</p>
            </div>
            <div style={{ marginLeft: "480px" }}>
              <p>Place:Jaipur</p>
              <p>Date:{bank?datehelper.displayDate(date):datehelper.displayDate(new Date(data1.date))}</p>
            </div>
          </div>
          <p>Release of gold ornaments/coins pledged as security for Digi Biz Gold Loan</p>
          <p style={{ marginBottom: "40px" }}>Received back the gold ornaments/coins mentioned below:</p>
          <Table>
            <Tr>
              <Th style={{ border: "1px solid " }}>Sr.No</Th>
              <Th style={{ border: "1px solid " }}>KFPL Account Number</Th>
              <Th style={{ border: "1px solid " }}>Bank's A/C Number</Th>
              <Th style={{ border: "1px solid " }}>Sanction Date</Th>
              <Th style={{ border: "1px solid " }}>Ornament's Name</Th>
              <Th style={{ border: "1px solid " }}>Quantity</Th>
              <Th style={{ border: "1px solid " }}>Gross Weight (gm.)</Th>
              <Th style={{ border: "1px solid " }}>Net Weight(gm.)</Th>
              <Th style={{ border: "1px solid " }}> Release Amount</Th>
              <Th style={{ border: "1px solid " }}>Sealed in packet number</Th>
            </Tr>
            <Tbody>
              {
                data.length > 0 ?
                  data.map((x, idx) => <Tr key={idx}>
                    <Td style={{ border: "1px solid " }}>{idx + 1}</Td>
                    <Td style={{ border: "1px solid " }}>{x.loan_account}</Td>
                    <Td style={{border: "1px solid "}}>{x.bankDetails&&x.bankDetails.length>0?x.bankDetails[0].loan_account:x.bank_account_no}</Td>
                    <Td style={{border: "1px solid "}}>{datehelper.displayDate(new Date(x.date))}</Td>
                    <Td style={{ border: "1px solid " }}>
                    {
                      x.ornaments.map((y,i) =>
                        <div key={i}>
                          <p>{y.desc}</p>
                        </div>)
                    }
                    </Td>
                    <Td style={{ border: "1px solid " }}>
                    {
                      x.ornaments.map((y,i) =>
                        <div key={i}>
                          <p>{y.quantity}</p>
                        </div>)
                    }
                    </Td>
                    <Td style={{ border: "1px solid " }}>{x.gross_weight}</Td>
                    <Td style={{ border: "1px solid " }}>{x.net_weight}</Td>
                    <Td style={{ border: "1px solid " }}>{}</Td>
                    <Td style={{ border: "1px solid " }}>{x.gold_packet&&x.gold_packet}</Td>
                  </Tr>
                  )
                  : null
              }
              <br/>
            </Tbody>
            <Td colSpan="5">
            Name and signature of the borrower
            <br/>
            <br/>
            <br/>
            <br/>
            <b>For Office use only</b> <br/>
            The said ornaments/coins are released <br/>
            Joint Custodians<br/>
            1.
            <br />
            2.
            </Td>
          </Table>
        </div>
      </div>
    </div>
    </>
  )
}
