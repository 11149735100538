import React from "react";
import PropTypes from "prop-types";
import {
    makeStyles,
    AppBar,
    Tabs,
    Tab,
    Typography,
    Box,
} from "@material-ui/core";
import { ApplicantForm } from "./ApplicantForm";
import { useContext } from "react";
import { TabContext } from "../TabBar";
import { useEffect } from "react";
import { customerService, employeeService } from "../../../../_services";
import CustomersTabBar from "./CustomersTabBar";
import ApplicationFormDetails from "../../Applications/ApplicationData/ApplicationFormDetails";
import Switch from '@material-ui/core/Switch';
import { history } from "../../../../_helpers";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function ApplicantTabBar() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [applicant, setApplicant] = React.useState();
    let TabContextValue = useContext(TabContext)
    let cust = TabContextValue.cust


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function getApplicant() {
        await customerService.searchCustomer({ customer_id: cust.applicant.customer_id })
            .then(res => setApplicant(res[0]))
            .catch(err => console.log(err))
    }

    useEffect(() => {
        getApplicant()
    }, [])




    const [state, setState] = React.useState({
        switch_panel: true
    });
      const handlePanel = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    
        if(!event.target.checked){
            history.push(`/home/sblloans/details/${cust._id}?isold_view=1`)
            window.location.reload()
        }
    
        console.log(event.target.checked)
    };
    return (
        <div className={classes.root}>
            <div style={{ display: "flex", justifyContent: "flex-end", flexDirection: "row" ,marginRight:"3rem"}} >
                <label  >LMS</label> <div style={{ marginTop: "-10px" }} >
                    <Switch
                        checked={state.switch_panel}
                        onChange={handlePanel}
                        color="primary"
                        name="switch_panel"
                    // inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </div><label style={{ color:'blue' }} >LOS</label>
            </div>
            <AppBar position="static">
                <>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="simple tabs example"
                        style={{ backgroundColor: "#D4F1F4", color: "black" }}
                    >
                        <Tab label="Applicant" {...a11yProps(0)} style={{ minWidth: "30%" }} />
                        <Tab label={`Co-Applicants- ${cust && cust.co_applicants && cust.co_applicants.length}`} {...a11yProps(1)} style={{ minWidth: "30%" }} />
                        <Tab label={`Guarantors- ${cust && cust.guarantors && cust.guarantors.length}`} {...a11yProps(2)} style={{ minWidth: "30%" }} />
                    </Tabs>
                </>
            </AppBar>
            <TabPanel value={value} index={0}>
                <CustomersTabBar role="Applicant" />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <CustomersTabBar role="CoApplicant" />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <CustomersTabBar role="Guarantor" />
            </TabPanel>
        </div>
    );
}