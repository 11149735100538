import React from "react";
import { employeeService } from "../../../_services";
import ColenderData from "./ApplicationData/ColenderData";


export default function ColenderDetails({ cust }) {
  const user = employeeService.userValue;
  console.log(cust);
  return (
    <div>
      <br></br>
      {cust && (
      <ColenderData customer={cust}></ColenderData>
      )}

    </div>
  );
}
