import { fetchWrapper } from "../_helpers";
const apiUrl = process.env.REACT_APP_API_URL;
const baseUrl = `${apiUrl}/pettycash`

export const pettyCashService = {
    createPettyCash,
    getPettycash,
    approvePettyRequest
}
function createPettyCash(params) {
   return  fetchWrapper.post(`${baseUrl}/createpettycash`, params)
}
function getPettycash() {
   return fetchWrapper.get(`${baseUrl}/getpettycash`)
}
function approvePettyRequest(params){
    console.log('test ids',params)
    return fetchWrapper.post(`${baseUrl}/approvepettyrequest`,params)
}