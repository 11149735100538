import React, { useEffect, useState } from 'react'
import './ViewLoanDetails.css';
import { Formik, Field, Form } from "formik";
import { employeeService, misService } from '../../../_services';
import roleChecker from '../../../_helpers/rolechecker';
import BankModal from '../BankModal';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import ColenderPaymentModal from './ColenderPaymentModel';
import datehelper from '../../../_helpers/datehelper';

export default function ColenderDetailsTab(props) {
    const user = employeeService.userValue;
    const loan = props.loan
    const customer=props.customer
    console.log("Test customer",customer)
    const decimalRoundOff = (num) => {
        return Math.round(num * 100) / 100;
    };
    return (
        <div>
            <h3><b>&nbsp;&nbsp; Co-lender Details</b></h3>
            <br />
            <div className='personal-details'>
                <Formik>
                    <Form className='form'>
                        <div className="form-row">
                            <div className="form-group-col">
                                <label >Bank Name</label>
                                <Field
                                    value={customer && customer.bank_details && customer.bank_details.bank}
                                    className='input-field'
                                    disabled
                                />
                            </div>
                            <div className="form-group-col">
                                <label >Bank Loan Number</label>
                                <Field
                                    value={customer && customer.bank_details && customer.bank_details.loan_account}
                                    className='input-field'
                                    disabled
                                />
                            </div>
                            <div className="form-group-col">
                                <label >Sanction Amount(₹)</label>
                                <Field
                                    value={customer && customer.bank_details && customer.bank_details.sanction_amount}
                                    className='input-field'
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group-col">
                                <label >PF Charges(₹)</label>
                                <Field
                                    value={customer && customer.bank_details && customer.bank_details.pf}
                                    className='input-field'
                                    disabled
                                />
                            </div>
                            <div className="form-group-col">
                                <label >ROI(%)</label>
                                <Field
                                    value={customer && customer.bank_details && customer.bank_details.roi}
                                    className='input-field'
                                    disabled
                                />
                            </div>
                            <div className="form-group-col">
                                <label >Disbursement Amount(₹)</label>
                                <Field
                                    value={customer && customer.bank_details && customer.bank_details.disburse_amount}
                                    className='input-field'
                                    disabled
                                />
                            </div>
                        </div>
                    </Form>
                </Formik>
            </div>
            <br />
            <br />
            <div>
                <div style={{ display: "flex", float: 'right' }}>
                    {((roleChecker.isHOGoldAccess(user.role) && loan.loan_status !== 'Locked') || roleChecker.hasAccountsAccess(user.role)) && loan.bank_details && (
                        <ColenderPaymentModal loan={loan} history={props.history} user={user} />
                    )}
                </div>
                <h3><b>&nbsp;&nbsp; Co-lender Payment</b></h3>
                <div className='ornaments-details'>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th className='table-row-th'>S.N</Th>
                                <Th className='table-row-th'>Payment Date</Th>
                                {/* <Th>Bank Name</Th> */}
                                <Th className='table-row-th'>Payment Type</Th>
                                <Th className='table-row-th'>Payment Mode</Th>
                                <Th className='table-row-th'>Reference Number</Th>
                                <Th className='table-row-th'>Payment Amount</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {loan && loan.bank_payment_details.length > 0 ? loan.bank_payment_details.map((col, idx) =>
                                <Tr key={idx}>
                                    <Td className='table-row-td'>{idx + 1}</Td>
                                    <Td className='table-row-td'>{datehelper.displayDate(new Date(col.payment_date))}</Td>
                                    {/* <Td>{}</Td> */}
                                    <Td className='table-row-td'>{col.payment_type}</Td>
                                    <Td className='table-row-td'>{col.payment_mode}</Td>
                                    <Td className='table-row-td'>{col.payment_ref}</Td>
                                    <Td className='table-row-td'>{col.payment_amount}</Td>
                                </Tr>) : <Tr>
                                <Td align="center" colSpan={6}>Data Not found</Td>
                            </Tr>}
                        </Tbody>
                    </Table>
                </div>
            </div>
            <br />
            <br />
            <br />
            <div>
                <div style={{ display: "flex", float: 'right' }}>
                    {((roleChecker.isHOGoldAccess(user.role) && loan.loan_status !== 'Locked') || roleChecker.hasAccountsAccess(user.role)) && (
                        <BankModal
                            user={user}
                            loan={loan}
                            history={props.history}
                        />
                    )}
                </div>
                <h3><b>&nbsp;&nbsp; Co-lending Details</b></h3>
                <div className='ornaments-details'>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th className='table-row-th'>Description</Th>
                                <Th className='table-row-th'>No of Items</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {loan.details && loan.details.gold && loan.details.gold.ornaments.map((row, idx) => (
                                <Tr key={idx}>
                                    <Td className='table-row-td'>
                                        {row.desc}
                                    </Td>
                                    <Td className='table-row-td'>{row.quantity}</Td>

                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </div>
            </div>
            <br />
            <div className='ornaments-details'>
                <Table>
                    <Thead>
                        <Tr>
                            <Th className='table-row-th'>KFPL Gross Weight(gm)</Th>
                            <Th className='table-row-th'>KFPL  Net Weight(gm)</Th>
                            <Th className='table-row-th'>Difference Gross Weight(gm)</Th>
                            <Th className='table-row-th'>Difference Net Weight(gm)</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td className='table-row-td'>{loan.gross_weight}</Td>
                            <Td className='table-row-td'>{loan.net_weight}</Td>
                            <Td className='table-row-td'> {decimalRoundOff(
                                customer && customer.bank_details && customer.bank_details.gold.gross_weight - loan.gross_weight
                            )}</Td>
                            <Td className='table-row-td'>{decimalRoundOff(customer && customer.bank_details && customer.bank_details.gold.net_weight - loan.net_weight)}</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </div>
        </div>
    )
}
