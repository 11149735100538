import React from "react";
import { useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { count } from "rxjs";
import kaabillogo from "../../../assets/kaabil.png";
import datehelper from '../../../_helpers/datehelper';
import kaabilfooter from "../../../assets/kaabil_footer2.png";
import EmailIcon from '@material-ui/icons/Email';
import CallIcon from '@material-ui/icons/Call';
import LanguageIcon from '@material-ui/icons/Language';
import './Noc.css'
const NocLetter = (props) => {
  const NocLetterRef = React.useRef();

  const handleSubmissionPrint = useReactToPrint({
    content: () => NocLetterRef.current,
    pageStyle: "print",
  });

  let date = new Date();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const yy = date.getFullYear();
  const mm = date.getMonth();
  const dd = date.getDate();

  date = months[mm] + " " + dd + "," + " " + yy;

  // date = dd + "/" + mm + "/" + yy

  const data = props.location.state;

  let temp = new Date();
  console.log(props);

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <button onClick={handleSubmissionPrint}>Print Letter</button>
      </div>
      .
      <div ref={NocLetterRef}>
        <img
          src={kaabillogo}
          style={{ height: "150px", marginLeft: "30%" }}
          alt="KFPL Logo"
        />

        <div
          style={{
            marginTop: "200px",
            fontSize: "22px",
            textAlign: "justify",
            marginLeft: "53px",
          }}
        >
          <div>
          <span>Letter Id: {data.letter_id}</span><br/>
            <span>Dated: { datehelper.displayDate(new Date(date)) }</span>
            
          </div>
          <br />
          <br />

          <div>
            To,<br/>
            {data.name} S/o {data.sonof}
            <br />
            {data.address}
          </div>
          <br />

          <div>
            <b>
              Subject: No dues Certificate || Secured Business Loan || A/c.
              {data.application_id}
            </b>
          </div>
          <br />

          <div>Dear Sir/Madam,</div>
          <br />

          <div style={{margin:"auto"}}>
            This is to certify that {data.name} S/o {data.sonof } has been Maintaining a loan account no. <b>{data.application_id}</b> amount INR.
            <b>{data.amount}</b>/- with Kaabil Finance Private Limited. There is no
            outstanding liability and the said loan has been fully paid.
            <br />
            <br />
            {data.colender === "" ? (
    null
  ) : (
    <div>
      This is to certify that the loan has been sanctioned under co-lending
      with <b>{data.colender}</b> and the same has been cleared with the respective co-lender.
    </div>
  )
}

            
          </div>
        
          <div>Warm Regards</div>  <br /> <br /> <br /> <br />

          <div>Authorized Signature<br/>
Kaabil Finance Private Limited<br/>
Address :Income Tax Colony, Model Town, Jagatpura Road, Jaipur, Rajasthan 302017<br/>
Phone No. :</div>
          
          <p style={{ fontSize: "16px", marginTop: "150px" }}>
            “This document is computer generated and does not require the
            Registrar's signature or the Company's stamp in order to be
            considered valid”
          </p>
          <div className="header-infos">
     <div className="header-top">
       <div>
         <span>
           <strong><EmailIcon/>  care@kaabilfinance.com</strong>
         </span>
       </div>
       <div>
         <span>
           <strong><CallIcon/> 0141-4005012</strong>
         </span>
       </div>
       <div>
         <strong><LanguageIcon/> www.kaabilfinance.com</strong>
       </div>
     </div>
     <div className="header-bottoms" style={{fontSize:"18px"}}>
      
         <p> <strong>Registered & Corporate Office </strong> :- Kaabil Towers, Plot No. 1, Scheme No. 1, Income Tax Colony, Model Town, Jagatpura, Jaipur Rajasthan 302017 CIN No. U65929RJ2017PTC058138 ,GSTIN : 08AAGCK6104N1ZA</p>
         
     </div>
     {/* Fixed bottom footer */}
     
   </div>
          {/* <img
            src={kaabilfooter}
            style={{
              position: "absolute",
              width: "300mm",
              marginTop: "150px",
            }}
            alt="KFPL Footer"
          /> */}
        </div>
      </div>
    </>
  );
};

export default NocLetter;
