import React, { useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import './MapView.css'

const LocationPin = ({ text, color }) => (
  <div className="pin">
    <LocationOnIcon style={{color: `${color}`}} className="pin-icon" />
    <p className="pin-text" style={{color:color}}>{text}</p>
  </div>
)

const MapView = (props) => {

  const visits = props.location.state
  const [points, setPoints] = useState([])
  const [colors, setColors] = useState([])

  const locations = [
    {
      address: 'Kaabil Finance Pvt. Ltd.',
      lat: 26.887236,
      lng: 75.802772,
    },
  ]

  const api_key = process.env.REACT_APP_GOOGLE_MAP_API_KEY


  const setLocations = () => {
    let temp = []
    visits && visits.map((x) => {
      x.visit_images.map((y) => {
        // console.log({address:x.emp_id,
        //     lat:y.location.lat,
        //     lng:y.location.long})
        temp.push({
          address: x.application_id,
          lat: y.location.lat,
          lng: y.location.long
        })
      })
    })
    setPoints([...locations, ...points, ...temp])
    getRandomColor(temp.length)
  }

  useEffect(() => {
    setLocations()
  }, [])

  const totalColor = []

  function getRandomColor(length) {
    let letters = '0123456789ABCDEF';

    for (let i = 0; i < length; i++) {
      let color = '#';
      for (let j = 0; j < 6; j++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      totalColor.push(color);
    }
    // console.log(totalColor);
    setColors([...totalColor])
  }



  return (
    <div className="map">
      <h2 className="map-h2">Map View Of Field Visits</h2>

      <div className="google-map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: api_key }}
          defaultCenter={locations[0]}
          defaultZoom={9}
        >
          {points && points.map((location, idx) => {
            return <LocationPin
              lat={location.lat}
              lng={location.lng}
              text={location.address}
              color={colors[idx]}
            />
          })}

        </GoogleMapReact>
      </div>
    </div>
  )
}

export default MapView