import React from "react";
import Modal from "@material-ui/core/Modal";
import { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { loanService } from "../../../../_services/loan.service";

import DisbursementAccountList from "./DisbursementAccountList";

const AddDisbursementAccount = (props) => {
  const customer = props.cust;
  console.log(props)
  let contact = customer.applicant.general_information.contact;
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    account_holder_name: "",
    account_holder_role:"",
    bank_account_number: "",
    ifsc_code: "",
    bank_name: "",
    account_holder_mobile: contact,
    confirm_account_number: "",
  });

  const style = {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
    //paddingBottom: '30px',
  };

  let names = [];

  if (customer) {
    if (customer.applicant && customer.applicant.general_information) {
      names.push({name:customer.applicant.general_information.name, role: "Applicant"});
    }
    if (customer.co_applicants && customer.co_applicants.length) {
      customer.co_applicants.forEach((co_app) => {
        names.push({name: co_app.general_information.name, role: "Co-Applicant"});
      });
    }
    if (customer.guarantors && customer.guarantors.length) {
      customer.guarantors.forEach((guar) => {
        names.push({name : guar.general_information.name, role: "Guarantor"});
      });
    }
  }

  const handleChange = (e) => {
       setValues({ ...values, [e.target.name]: e.target.value });
    if(e.target.name === "customer_name"){
      let temp = JSON.parse(e.target.value)
      setValues({ ...values, ["account_holder_name"]: temp.name, ["account_holder_role"]: temp.role });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let existingAccount = false
    let temp=[];

    if(customer && customer.disbursement && customer.disbursement.disbursement_accounts){
      temp = customer.disbursement.disbursement_accounts;

      temp.map((e)=>{
        if(values.bank_account_number === e.bank_account_number){
          existingAccount = true;
        }
      })

      if (existingAccount) {
        window.alert("Bank Account Already Exist");
        return;
      }
    }

    if (values.bank_account_number === values.confirm_account_number) {
      let disbursement_details = [];
      if(customer && customer.disbursement && customer.disbursement.disbursement_details){
      disbursement_details = customer.disbursement.disbursement_details;
      }else{
        disbursement_details = [];
      }
      let params = [];
      if(customer && customer.disbursement && customer.disbursement.disbursement_accounts){
        params = customer.disbursement.disbursement_accounts;
        params.push({ ...values });  
      }else{
        params = []
        params.push({ ...values });  
      }
      let disbursement_memo = []
      if(customer && customer.disbursement && customer.disbursement.disbursement_memo){
        disbursement_memo = customer.disbursement.disbursement_memo;
      }else{
        disbursement_memo = []
      }
     
      let updateObject = {
        disbursement: {
          disbursement_details:[...disbursement_details],
          disbursement_accounts: [...params],
          disbursement_memo:[...disbursement_memo]
        },
      };
      console.log(values);
      loanService
        .update(customer._id, updateObject)
        .then((x) => {
          console.log(x);
          window.alert("Details Added");
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      window.alert("Account Number must be same");
    }
  };

  const verifyBankAccount = (loan_account, bank_account_num) => {
    console.log("verifying bank details");
    let params = { loan_account, bank_account_num };
    loanService
      .verifyBankDetails(params)
      .then((res) => {
        console.log(res);
        window.alert(
          "verification request complete. Please check details again."
        );
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        window.alert("Error verifying account. Try again");
      });
  };

  const handleOpen = () => {
    setOpen(true);
    editAccount()
  };

  const handleClose = () => {
    setOpen(false);
  };

  const editAccount = () =>{
    if(props.isEdit === true){
      setValues(customer.disbursement.disbursement_accounts[props.index])
    }
  }

  const updateAccount = () => {

    if (values.bank_account_number === values.confirm_account_number) {
      let disbursement_details = [];
      if(customer && customer.disbursement && customer.disbursement.disbursement_details){
      disbursement_details = customer.disbursement.disbursement_details;
      }else{
        disbursement_details = [];
      }
      let params = [];
      if(customer && customer.disbursement && customer.disbursement.disbursement_accounts){
        params = customer.disbursement.disbursement_accounts;
        params.splice(props.index,1)
        params.push({ ...values });  
      }else{
        params = []
        params.push({ ...values });  
      }
      let disbursement_memo = []
      if(customer && customer.disbursement && customer.disbursement.disbursement_memo){
        disbursement_memo = customer.disbursement.disbursement_memo;
      }else{
        disbursement_memo = []
      }
     
      let updateObject = {
        disbursement: {
          disbursement_details:[...disbursement_details],
          disbursement_accounts: [...params],
          disbursement_memo:[...disbursement_memo]
        },
      };
      console.log(values);
      
      loanService
        .update(customer._id, updateObject)
        .then((x) => {
          console.log(x);
          window.alert("Details Added");
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      window.alert("Account Number must be same");
    }
  }  
  

  return (
    <div>
      <Button onClick={handleOpen} variant="contained" color="primary">
       {props.button}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <form
              style={{ display: "flex", flexDirection: "column" }}
              onSubmit={(e) => handleSubmit(e)}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <label style={{ width: "200px" }}>
                  Customer Name
                  <select
                    className="form-select form-select-sm"
                    required
                    name="customer_name"
                    style={{ width: "200px" }}
                    aria-label=".form-select-sm example"
                    onChange={(e) => handleChange(e)}
                  >
                    <option selected disabled>Select the Name</option>
                    {/* {console.log(names)} */}
                    {names && names.length>0 && names.map((e) => (
                      <option value={JSON.stringify(e)}>{e.name} / {e.role}</option>
                    ))}
                  </select>
                </label>
              </div>
              {    console.log(values)}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label style={{ width: "200px" }}>
                  Account Number{" "}
                  <input
                    type={"text"}
                    name="bank_account_number"
                    value={values.bank_account_number}
                    required
                    onChange={(e) => handleChange(e)}
                  />
                </label>
                <label style={{ width: "200px" }}>
                  Confirm Account{" "}
                  <input
                    type={"text"}
                    onpaste="return false"
                    name="confirm_account_number"
                    required
                    onChange={(e) => handleChange(e)}
                  />
                </label>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label style={{ width: "200px" }}>
                  Account Holder Name
                  <input
                    type="text"
                    name="account_holder_name"
                    required
                    value={values.account_holder_name}
                    onChange={(e) => handleChange(e)}
                  />
                </label>
                <label style={{ width: "200px" }}>
                  IFSC Code
                  <input
                    type={"text"}
                    name="ifsc_code"
                    value={values.ifsc_code}
                    required
                    onChange={(e) => handleChange(e)}
                  />
                </label>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label style={{ width: "200px" }}>
                  Bank Name{" "}
                  <input
                    type="text"
                    name="bank_name"
                    value={values.bank_name}
                    required
                    onChange={(e) => handleChange(e)}
                  />
                </label>
                <label style={{ width: "200px" }}>
                  Contact Number{" "}
                  <input
                    type="text"
                    name="account_holder_mobile"
                    required
                    value={values.account_holder_mobile}
                    title="Number must be 10 digit long"
                    pattern="[1-9]{1}[0-9]{9}"
                    onChange={(e) => handleChange(e)}
                  />
                </label>
              </div>
              {props.isEdit ? <button type="button" onClick={()=>updateAccount()}>Update</button> : <button>Submit</button> }


            </form>
          </Typography>
        </Box>
      </Modal>
      <br />
      <br />
      
    </div>
  );
};

export default AddDisbursementAccount;