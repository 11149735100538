import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { icicienachService } from "../../../_services/icicienach.service";
import { Link } from "@material-ui/core";
import { CodeStarNotifications } from "aws-sdk";
import { CSVLink } from "react-csv";
import datehelper from "../../../_helpers/datehelper"


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "loan_number",
    label: "Loan No",
  },
  { id: "emi_amount", label: "Amount" },
  { id: "date", label: "Date" },
  { id:'enach_status',label:'Enach Status'},
  { id: "name", label: "Customer" },
  {id: "bank_name",label:"Bank Name"},
  {id: "start_date",label:"EMI Start"},
  {id: "end_date",label:"EMI End"},
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="right"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = ({ quantity }) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Showing {quantity} eNACH
      </Typography>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function ICICINachTable({ rows }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("amount");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const resendEnach=(refCode)=> {
    icicienachService.resend(refCode)
    .then((result)=> {
      console.log("got resend result");
     // console.log(result);
      const encodeditem = btoa(JSON.stringify(result));
      window.open('https://s3.ap-south-1.amazonaws.com/download.kaabilfinance.com/htmls/enachload.html?'+`${encodeditem}`);
      //console.log('http://localhost:3001/icicienach.html?'+`${encodeditem}`);
      // window.alert("resend successful");
      //window.location.reload();
    })
    .catch((err)=> {
      console.log(err);
      window.alert("error resending Enach request");
    })
  }

  const headers = [
    { label: "Loan Number",       key: "loan_number"},
    { label: "Amount",            key: "emi_amount" },
    { label: "Date",              key: "updated" },
    { label: "Enach Status",      key: "enach_status" },
    { label: "Refrence Code",     key: "reference_code" },
    { label: "Mandate Refrence",  key: "mandate_reference" },
    { label: "Customer",          key: "name" },
    { label: "Bank Name",         key: "bank_name" },
    {label: "Bank Account",       key: "bank_account"},
  ];

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}
      
      >

        <EnhancedTableToolbar quantity={rows.length} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="checkbox"
                      >
                        {row.loan_number}
                      </TableCell>
                      <TableCell align="right">{row.emi_amount}</TableCell>
                      <TableCell align="right">{datehelper.displayDate(new Date(row.updated))}</TableCell>
                      <TableCell align="right">{row.enach_status=='Active'  && (
                           <p  style={{color:"green"}}>Active</p>
                      )}
                      {row.enach_status=='Initiated'  && (
                           <p  style={{color:"blue"}}>Initiated</p>
                      )}
                      {row.enach_status=='Failed'  && (
                           <p  style={{color:"red"}}>Failed</p>
                      )}
                      {row.enach_status=='Pending'  && (
                           <p>Pending</p>
                      )}
                      <br/>
                      {
                        ((row.enach_status =='Pending' || row.enach_status =='Failed'|| row.enach_status =='Initiated') && (
                          <p>{row.enach_form_id}<br/>
                          {row.reference_code}</p>
                        ))
                      }
                       {
                        (row.enach_status =='Failed' && row.enach_status_comment  && (
                          <p>Fail Reason Msg: {row.enach_status_comment}<br/>
                          </p>
                        ))
                      }

                      {
                        (row.enach_status =='Active' && (
                          <>
                          <p>Request Id: {row.reference_code}</p><br/>
                          <p>Bank Ref: {row.mandate_reference}</p>
                          <br/>
                          <p>Mandate Token: {row.mandate_token}</p>
                          </>
                        ))
                      }
                      {
                        (row.enach_status =='Initiated' && (
                          <>
                          <p>Request Id: {row.reference_code}</p><br/>
                          <p>Mandate Token: {row.mandate_token}</p>
                          </>
                        ))
                      }
                      
                      {
                        ((row.enach_status =='Pending' ||row.enach_status =='Failed' ) && (
                          <Link onClick={()=>resendEnach(row.reference_code)}>Request Again</Link>
                        ))
                      }
                      </TableCell>
                      <TableCell align="right">{row.name}<br/>{row.contact}</TableCell>
                      <TableCell align="right">{row.bank_name}</TableCell>
                      <TableCell align="right">{row.start_date}</TableCell>
                      <TableCell align="right">{row.end_date}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
