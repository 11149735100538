import React, { useState } from 'react'
import { Avatar, Button } from "@material-ui/core";
import { Modal } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { customerService } from '../../../../_services';
import NewCustomer from './NewCustomer';


const SearchCustomer = (props) => {


    console.log(props.application_id)
    const [open, setOpen] = useState(false);
    const [showNew, setShowNew] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setApplicant([])
    }
    const [value, setValue] = useState('');
    const [fieldValue, setFieldValue] = useState('')
    const [applicant, setApplicant] = useState([])
    let image = ''


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        height: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: "scroll"
    };

    const alreadyExist = (values) => {
        const coApps = props.co_applicants;
        const guars = props.guarantors;
        let isCoApp = false;
        let isGuar = false;

        coApps && coApps.map((x)=>{
            console.log(x, "x here", values)
            if(x.customer_id === values.customer_id){
                isCoApp = true
            }
        })

        guars && guars.map((x)=>{
            if(x.customer_id === values.customer_id){
                isGuar = true
            }
        })

        return {isCoApp, isGuar}
    }

    const addCustomer = async (values) => {
        console.log({...values,application_id: props.application_id, role: props.role})
        const resp = await alreadyExist(values)
         console.log(resp)
        if(resp.isCoApp || resp.isGuar) return alert("Customer alraedy exist")
        customerService.addToLoan({ ...values, application_id: props.application_id, role: props.role })
            .then((data) => {
                console.log(data)
                if(data && data.message){
                    alert(`${data.message}`)
                }else{
                    alert("Added Successfully")
                    window.location.reload()
                }
            })
            .catch((err) => {
                console.log(err,"dddddddtttttt")
                alert("Something went wrong")
            })
    }

    const searchCustomer = async () => {
        await customerService.searchCustomer(value === "Aadhar Number" ? { adhaar_no: fieldValue } : { customer_mobile_num: fieldValue })
            .then((res) => {
                console.log(res)
                setShowNew(true)
                setApplicant(res)
            })
            .catch(err => console.log(err))
    }

    // const addToLoan = () => {
    //     customerService.addToLoan({})
    // }

    return (
        <div>
            <Button style={{ display: "flex", justifyContent: "flex-end" }} variant='contained' color='primary' onClick={handleOpen}>+ Add More</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" style={{ textAlign: "center" }} component="h2">
                        Add Co-Applicant
                    </Typography>
                    <Typography  >
                        <div style={{ display: "flex", flexDirection: "row", marginTop: "30px" }}>
                            <label >
                                Search By:
                            </label>

                            <select
                                style={{ marginLeft: "30px", width: "150px" }}
                                onChange={(e) => setValue(e.target.value)}
                                displayEmpty
                            >
                                <option disabled selected>Select</option>
                                <option>Aadhar Number</option>
                                <option>Mobile Number</option>
                            </select>
                        </div>

                        {value !== "" && (
                            <div style={{ marginTop: "20px" }}>
                                <label>Enter {value}</label>
                                <input type='number' onChange={(e) => setFieldValue(e.target.value)} />
                                <button style={{ marginLeft: "40px" }} color='primary' onClick={() => searchCustomer()} >Search</button>
                            </div>
                        )}
                    </Typography>

                    <Typography style={{ marginTop: "50px" }}>
                        {applicant && applicant.length > 0 && applicant.map((x, idx) => {
                            return <Card style={{ border: "1px solid blue", marginTop: "10px" }} key={idx} >
                                <CardContent>
                                    {x && x.kyc_images && x.kyc_images.map((y) => {
                                        if (y.tag === "Customer Photo") {
                                            image = y.uri
                                        } else {
                                            image = ''
                                        }
                                    })}
                                    <Typography style={{ display: "flex", flexDirection: "row" }}>
                                        <Avatar src={image} alt='' style={{ width: "65px", height: "65px" }} />
                                        <div style={{ marginLeft: "20px" }}>
                                            <span><b>Name:</b>    {x.general_details.customer_firstname}  {x.general_details.customer_lastname}</span><br />
                                            <span><b>CIF ID:</b>  {x.customer_id}</span><br />
                                            <span><b>Mobile:</b>    {x.general_details.customer_mobile_num}</span>
                                        </div>
                                    </Typography><br/>
                                    <button style={{ backgroundColor: "blue", color: "white", marginLeft:"90px" }} onClick={() => addCustomer(x)} >Add Customer</button>   
                                </CardContent>
                            </Card>
                        })

                        }
                    </Typography>
                    {applicant && applicant.length > 0 && <Typography style={{ marginTop: "50px", display: "flex", justifyContent: "space-around" }}>
                        <NewCustomer handleClose={handleClose} role={props.role} application_id={props.application_id} value={value} aadharOrNumValue={fieldValue}/>
                    </Typography>}

                    {applicant && applicant.length <= 0 && showNew && <Typography style={{ marginTop: "110px" }}>
                        <span style={{ marginLeft: "30%" }}>No Result Found!!</span>
                    </Typography>}

                    {applicant && applicant.length <= 0 && showNew && <Typography style={{ marginLeft: "30%", marginTop: "50px" }}>
                        <NewCustomer handleClose={handleClose} role={props.role} application_id={props.application_id} value={value} aadharOrNumValue={fieldValue}/>
                    </Typography>}

                    {applicant && applicant.length <= 0 && !showNew && <Typography style={{ marginTop: "110px" }}>
                        <span style={{ display: "flex", textAlign: "center" }} >Please search with Mobile Number or Aadhaar Number to continue.</span>
                    </Typography>}
                </Box>
            </Modal>
        </div>
    )
}

export default SearchCustomer