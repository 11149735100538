import { fetchWrapper } from "../_helpers/fetch-wrapper";
const apiUrl = process.env.REACT_APP_API_URL;

const baseUrl = `${apiUrl}/sblletters`;

const getNoc = () => {
    return fetchWrapper.get(`${baseUrl}/getnoc`)
}

const getDoc = () => {
    return fetchWrapper.get(`${baseUrl}/getdoc`)
}
const getPphandover = () => {
    return fetchWrapper.get(`${baseUrl}/getPphandover`)
}
const getFor = () => {
    return fetchWrapper.get(`${baseUrl}/getfor`)
}

const getCam = () => {
    return fetchWrapper.get(`${baseUrl}/getcamletter`)
}

const nocLetter =(params) => {
    console.log(params)
    return fetchWrapper.post(`${baseUrl}/noc`, params)
}

const forLetter = (id,params) => {
    console.log(params)
    return fetchWrapper.post(`${baseUrl}/for`,params)
}

const docLetter = (params) => {
    console.log(params)
    return fetchWrapper.post(`${baseUrl}/doc`, params)
}

const pphandoverLetter = (params) => {
    console.log(params,"ddddddddddddeeeeeeeeeee")
    return fetchWrapper.post(`${baseUrl}/pphandover`, params)
}

const totalCount = (params) =>{
    return fetchWrapper.post(`${baseUrl}/count`, params)
}

const updateNoc = (params) =>{
    return fetchWrapper.post(`${baseUrl}/edit/noc`, params)
}

const updateForClosure = (params) =>{
    return fetchWrapper.post(`${baseUrl}/edit/forclosuer`, params)
}

const updateDocumentList = (params) =>{
    return fetchWrapper.post(`${baseUrl}/edit/doclist`, params)
}

const createCamLetter = (params) =>{
    return fetchWrapper.post(`${baseUrl}/camletter`, params)
}

const updateCamLetter = (params) =>{
    return fetchWrapper.post(`${baseUrl}/edit/camletter`, params)
}
const verifyCamLetter=(id)=>{
    console.log("cam letter id ",id)
    return fetchWrapper.post(`${baseUrl}/verifycamletter/${id}`)
}
const resetCamLetter=(id)=>{
    console.log("cam letter id ",id)
    return fetchWrapper.post(`${baseUrl}/resetcamletter/${id}`)
}
const deleteCamLetter=(id)=>{
    console.log("cam letter id ",id)
    return fetchWrapper.post(`${baseUrl}/deletecamletter/${id}`)
}
const getSingleCam = (id) => {
    console.log(id.id)
    return fetchWrapper.post(`${baseUrl}/singlecam/${id.id}`)
}
const getDisbursementMemoList=()=>{
    return fetchWrapper.get(`${baseUrl}/getdisbursementmemolist`)
}

const getForeclosureData = (params) => {
    return fetchWrapper.post(`${baseUrl}/foreclosuredata`, params)
}
export const sblLetter = {
    nocLetter,
    forLetter,
    docLetter,
    pphandoverLetter,
    getNoc,
    getDoc,
    getPphandover,
    getFor,
    totalCount,
    updateNoc,
    updateForClosure,
    updateDocumentList,
    createCamLetter,
    getCam,
    updateCamLetter,
    verifyCamLetter,
    resetCamLetter,
    deleteCamLetter,
    getSingleCam,
    getDisbursementMemoList,
    getForeclosureData
}