import React from "react";
import {
  Button,
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { employeeService } from "../../_services/employee.service";
import PeopleIcon from "@material-ui/icons/People";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import MenuIcon from "@material-ui/icons/Menu";
import LocationCityIcon from '@material-ui/icons/LocationCity';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Route, Switch } from "react-router-dom";
import { Users } from "../Users/Index";
import { AccountBalance, Dashboard, InfoRounded, VerifiedUser } from "@material-ui/icons";
import AssignmentIcon from '@material-ui/icons/Assignment';
import SettingsIcon from '@material-ui/icons/Settings';
import { DashboardTab } from "./AdminDashboard";
// import sideBack from "../assets/sidebarBackground.jpg";
// import blueBack from "../assets/blueBackground.jpg";
import {AssessmentOutlined} from "@material-ui/icons";
import { misService } from "../../_services";
import { Branches } from "./Branches/BranchIndex";
import {Cities} from "./Cities/CityIndex"
import {Providers} from "./Provider/ProviderIndex"
import  {BranchOwners} from "./BranchOwner/BranchOnwerIndex.jsx"
import roleChecker from "../../_helpers/rolechecker";
import {BankIndex} from "./BankAccount/BankIndex"
import { Ledger } from "../Accounting/ledger/ledger";
import AllCloudToken from "./AllCloudTokens/AllCloudToken";
import AdminSettings from "./AdminSettings";
import AddCheckList from "./CheckListDocuments/AddCheckList"
import AllCategories from "./Categories/AllCategories"

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
}));

function AdminHome(props) {
  const user = employeeService.userValue;
  const { path } = props.match;
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [loanList, setLoanList] = React.useState([]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  React.useEffect(() => {
    misService.getAll().then((loans) => {
      setLoanList(loans);
    });
  }, []);

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <a href="/">
          <img
            className="offset-1 col-md-10"
            src={require("../../assets/kaabil.png")}
            alt=""
          />
        </a>
      </div>
      <Divider />
      <div
        style={{
          // backgroundImage: `url(${sideBack})`,
          // backgroundSize: "cover",
          height: "100vh",
        }}
      >
        <List>
          <ListItem button key="dashboard" component="a" href={`/home`}>
            <ListItemIcon>
              <Dashboard></Dashboard>
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem
            hidden={!(roleChecker.isAdminAccess(user.role) || roleChecker.isITAccess(user.role) || roleChecker.isHRDept(user.role))}
            button
            key="users"
            component="a"
            href={`${path}/users`}
          >
            <ListItemIcon>
              <PeopleIcon></PeopleIcon>
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
          <ListItem
            hidden={!(roleChecker.isAdminAccess(user.role) || roleChecker.isITAccess(user.role) || roleChecker.isHRDept(user.role))}
            button
            key="branches"
            component="a"
            href={`${path}/branches`}
          >
            <ListItemIcon>
              <AcUnitIcon/>
            </ListItemIcon>
            <ListItemText primary="Branches" />
          </ListItem>

          <ListItem
            hidden={!(roleChecker.isAdminAccess(user.role) || roleChecker.isITAccess(user.role) || roleChecker.isHRDept(user.role))}
            button
            key="Cities"
            component="a"
            href={`${path}/cities`}
          >
            <ListItemIcon>
            
            <LocationCityIcon></LocationCityIcon>
            </ListItemIcon>
            <ListItemText primary="Cities" />
          </ListItem>
 <ListItem
            hidden={!(roleChecker.isAdminAccess(user.role) || roleChecker.isITAccess(user.role) || roleChecker.isHRDept(user.role))}
            button
            key="valuators"
            component="a"
            href={`${path}/providers`}
          >
            <ListItemIcon>
            
            <AccessibilityNewIcon></AccessibilityNewIcon>
            </ListItemIcon>
            <ListItemText primary="valuators" />
          </ListItem>
          <ListItem
            hidden={!(roleChecker.isCFOAccess(user.role)  )}
            button
            key="bankaccount"
            component="a"
            href={`${path}/bankaccount`}
          >
            <ListItemIcon>
            <AccountBalance/>
            </ListItemIcon>
            <ListItemText primary="Bank Account" />
          </ListItem>
          <ListItem
            hidden={!(roleChecker.isAdminAccess(user.role) || roleChecker.isITAccess(user.role) || roleChecker.isHRDept(user.role))}
            button
            key="ledger"
            component="a"
            href={`${path}/ledger`}
          >
            <ListItemIcon>
            <AssignmentIcon/>
            </ListItemIcon>
            <ListItemText primary="Ledger" />
          </ListItem>
          <ListItem
            hidden={!(roleChecker.isAdminAccess(user.role))}
            button
            key="AllCloud Tokens"
            component="a"
            href={`${path}/allcloudtokens`}
          >
            <ListItemIcon>
            <AssignmentIcon/>
            </ListItemIcon>
            <ListItemText primary="AllCloud Tokens" />
          </ListItem>
          <ListItem
            hidden={
              !roleChecker.hasAccountsAccess(user.role)
            }
            button
            key="allcloudledger"
            component="a"
            href={`${path}/allcloudledger`}
          >
            <ListItemIcon style={{ marginLeft: 2 }}>
              <AssessmentOutlined />
            </ListItemIcon>
            <ListItemText primary="All Cloud Ledger" />
          </ListItem>
          
          <ListItem
            hidden={!(roleChecker.isAdminAccess(user.role) ||  roleChecker.isITAccess(user.role))}
            button
            key="Settings"
            component="a"
            href={`${path}/adminsettings`}
          >
            <ListItemIcon>
            <SettingsIcon/>
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>

          <ListItem
            button
            key="Documents Check List"
            component="a"
            href={`${path}/addchecklist`}
          >
            <ListItemIcon>
            <SettingsIcon/>
            </ListItemIcon>
            <ListItemText primary="Documents Check List" />
          </ListItem>
          <ListItem
            hidden={!(roleChecker.isAdminAccess(user.role) ||  roleChecker.isITAccess(user.role))}
            button
            key="Branch Owners"
            component="a"
            href={`${path}/branchowners`}
          >
            <ListItemIcon>
            <SettingsIcon/>
            </ListItemIcon>
            <ListItemText primary="Branch Owners" />
          </ListItem>
          <ListItem
            hidden={!(roleChecker.isAdminAccess(user.role) ||  roleChecker.isITAccess(user.role))}
            button
            key="Categories"
            component="a"
            href={`${path}/allcategories`}
          >
            <ListItemIcon>
            <SettingsIcon/>
            </ListItemIcon>
            <ListItemText primary="Categories" />
          </ListItem>
        </List>

        
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            style={{ flexGrow: 1, color: "white" }}
            align="center"
            variant="h5"
          >
            Admin Panel
          </Typography>
          <Button
            onClick={employeeService.logout}
            variant="contained"
            color="secondary"
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main
        style={{
          width: "100%",
          // backgroundImage: `url(${blueBack})`,
          // backgroundSize: "cover",
          height: "100%",
        }}
      >
        <div className={classes.toolbar} />
        <div style={{ padding: 10 }}>
          <Switch>
            {(roleChecker.isCFOAccess(user.role) || roleChecker.isAdminAccess(user.role)||roleChecker.isITAccess(user.role) || roleChecker.isHRDept(user.role) ) && (
              <>
            <Route exact path={path} component={DashboardTab} />
            <Route path={`${path}/users`} component={Users} />
            <Route path={`${path}/branches`} component={Branches} />
            <Route path={`${path}/cities`} component={Cities} />
            <Route path={`${path}/providers`} component={Providers} />
            <Route path={`${path}/branchowners`} component={BranchOwners} />

            <Route path={`${path}/bankaccount`} component={BankIndex}/>
            <Route path={`${path}/ledger`} component={Ledger}/>
            <Route path={`${path}/allcloudtokens`} component={AllCloudToken}/>
            <Route path={`${path}/adminsettings`} component={AdminSettings}/>
            <Route path={`${path}/allcategories`} component={AllCategories}/>
            <Route path={`${path}/addchecklist`} component={AddCheckList}/>

              </>
            )}
          </Switch>
        </div>
      </main>
    </div>
  );
}

export default AdminHome;