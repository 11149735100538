import React, { useState,useEffect } from 'react'
 import { employeeService } from '../../../../../_services';
import { Button } from '@material-ui/core';
 import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { loanService } from '../../../../../_services';
import { uploadFileToBucket } from '../../../../../_helpers/upload';
 import { Edit } from "@material-ui/icons";
import { Modal, Box, Typography } from "@material-ui/core";
import roleChecker from '../../../../../_helpers/rolechecker';

const RcuReport = (props) => {
 
  const [providerList,setProviderList]=useState([])
const [open,setOpen]=useState(false)
 
  
  let cust = props.cust;
  let objId = props.objId
  const rcu_data =props.val
  console.log("cust is",cust)
    const user = employeeService.userValue;
    const bucket = process.env.REACT_APP_s3docsBucket

     const validationSchema = yup.object({
  provider: yup.string().required('Provider is required'),
  rcu_date: yup.date().required('Rcu date is required'),
  response_status: yup.string().required('Rcu amount is required'),
  rcu_charges: yup.number().required('Rcu charges is required'),
  rcu_report_doc: yup.string().required('Rcu report PDF is required'),
  property_type: yup.string().required('property type is required'),
});

  
  const initialValues = {
    provider: '',
    rcu_date: '',
    rcu_charges:'',
    rcu_report_doc: '',
    property_type:'',
    response_status:''
  }
    
 
  if(rcu_data){

    const date = rcu_data?.rcu_report?.rcu_date
    const formattedDate = date ? (new Date(date).toISOString().split('T')[0]) : '' ;
    initialValues.provider = rcu_data?.rcu_report?.provider || '';
    initialValues.rcu_date = formattedDate;
     initialValues.rcu_charges = rcu_data?.rcu_report?.rcu_charges || '';
    initialValues.rcu_report_doc = rcu_data?.rcu_report?.rcu_report_doc || '';
    initialValues.property_type = rcu_data?.rcu_report?.property_type || '';
    initialValues.response_status = rcu_data?.rcu_report?.response_status || '';
  }


     
   
 
    const onSubmit = async (values) => {
      console.log("Submitting form values:", values);
    
      try {
        // Your asynchronous operations here
        await loanService.valuationReport(objId,{ values,"type":"RCU"});
        console.log("Submission successful");
        window.alert('data added successfully')

        window.location.reload()
      } catch (error) {
        console.error("Error during submission:", error);
      }
    };
    
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 800,
      height: 600,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
      overflow: 'scroll',
      //paddingBottom: '30px',
  };

 
    const propertyTypes = [
      'Commercial Properties',
      'Industrial Properties',
      'Mix Used',
      'Vacant',
      'Residential Properties',
      'Shop',
      'Open Plot',
      'Rented Residencial Properties',
      'Rented Commercial Properties'

      // Add more property types as needed
    ];
  const handleRcuReport = async (e, formik,setFieldValue,values) => {
    let file = e.target.files[0];
    let name = file.name.split('.')[0];
    let num1 = Math.floor(Math.random() * 1000);
    let num2 = Math.floor(Math.random() * 100);
    let fileName = `${name}_${num1}${num2}.pdf`
   await uploadFileToBucket(bucket, 'application/pdf', `${bucket}/loan/rcu_report/${cust._id}/${fileName}`, file)
    .then(link => {
      console.log('link',link)
      setFieldValue('rcu_report_doc', link);
       console.log(values)
    })
    .catch(error => {
      console.error(error);
    });
}

 const handleClose=()=>{
  setOpen(false)
}

 
  return (
    <div>
     
            <Button onClick={()=>{setOpen(true);console.log('open')}}><Edit/></Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
  <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, isSubmitting, values, setFieldValue,formik }) => (
         
        <Form>
        <div  style={{display:'flex',justifyContent:'space-evenly',alignItems:'center',flexWrap:'wrap',gap:'1rem'}}>

          <div className="form-group col-md-5">
            <h6 className="required">Provider Name</h6>
            <Field
              label="provider"
              type="text"
              name="provider"
              className={
                'form-control' +
                (errors.provider && touched.provider ? ' is-invalid' : '')
              }
              disabled
            />
           
           
            
            <ErrorMessage
              name="provider"
              component="div"
              className="invalid-feedback"
            />
          </div>

          <div className="form-group col-md-5">
            <h6 className="required">Rcu Date</h6>
            <Field
              type="date"
              name="rcu_date"
              className={
                'form-control' +
                (errors && errors.rcu_date && touched && touched.rcu_date
                  ? ' is-invalid'
                  : '')
              }
            />
            <ErrorMessage
              name="rcu_date"
              component="div"
              className="invalid-feedback"
            />
          </div>

          <div className="form-group col-md-5">
            <h6 className="required">Response Status</h6>
            <Field
              type="text"
              as = "select"
              name="response_status"
              className={
                'form-control' +
                (errors.response_status && touched.response_status
                  ? ' is-invalid'
                  : '')
              }
            > 
            <option value='' disabled selected>Select</option>
            <option value='Positive'  >Positive</option>
            <option value='Negative'  >Negative</option>
            <option value='Refer To Credit'  >Refer To Credit</option>

            </Field>
            <ErrorMessage
              name="response_status"
              component="div"
              className="invalid-feedback"
            />
          </div>
          {/* <div className="form-group col-md-5">
            <h6 className="required">Rcu Charges</h6>
            <Field
              type="number"
              name="rcu_charges"
              disabled
              className={
                'form-control' +
                (errors.rcu_charges && touched.rcu_charges
                  ? ' is-invalid'
                  : '')
              }
            />
            <ErrorMessage
              name="rcu_charges"
              component="div"
              className="invalid-feedback"
            />
          </div> */}
          <div className="form-group col-md-5">
  <h6 className="required">Property Type</h6>
  <Field
    as="select"
    name="property_type"
    className={
      'form-control' +
      (errors.property_type && touched.property_type
        ? ' is-invalid'
        : '')
    }
  >
    <option value="">Select Property Type</option>
    {/* Map the list of property types here */}
    {propertyTypes.map((type, idx) => (
      <option value={type} key={idx}>
        {type}
      </option>
    ))}
  </Field>
  <ErrorMessage
    name="property_type"
    component="div"
    className="invalid-feedback"
  />
</div>

         {roleChecker.isGMLevelAccess(user.role) && <div className="form-group col-md-5">
            <h6 className="required">Rcu Report PDF</h6>
            <input
              type="file"
              name="rcu_report_doc"
              accept=".pdf"
              onChange={(e) => handleRcuReport(e, formik,setFieldValue,values)}
              className={
                'form-control-file' +
                (errors && errors.rcu_report_doc && touched && touched.rcu_report_doc
                  ? ' is-invalid'
                  : '')
              }
            />
            <ErrorMessage
              name="rcu_report"
              component="div"
              className="invalid-feedback"
            />
          </div>
}
          <div className="form-group col-md-5" style={{display:'flex',justifyContent:'center'}}>

          <Button type='submit'  color='primary' variant='contained' disabled={isSubmitting}>Submit</Button>
          </div> 
        </div>
        </Form>
       
      )}
    </Formik>
    </Typography>
                </Box>
            </Modal>
  </div>
  )
}

export default RcuReport