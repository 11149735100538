import React from 'react'
import './ViewLoanDetails.css';
import { Formik, Field, Form } from "formik";
import { employeeService } from '../../../_services';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
export default function GoldDetailsTab(props) {
    const user = employeeService.userValue;
    const loan = props.loan
    return (
        <div>
            <h3><b>&nbsp;&nbsp; Ornaments Details</b></h3>
            <br />
            <div className='ornaments-details'>
                <Table>
                    <Thead>
                        <Tr>
                            <Th className='table-row-th'>Description Of<br />Ornaments Pledged</Th>
                            <Th className='table-row-th'>Number of Units </Th>
                            <Th className='table-row-th'>Gross Weight <br />(Grams)</Th>
                            <Th className='table-row-th'>Net Weight<br />(Grams)</Th>
                            <Th className='table-row-th'> Other Deductions/<br />Margins, if any<br />(Grams) </Th>
                            <Th className='table-row-th'>Advance Value <br />Per Gram</Th>
                            <Th className='table-row-th'>Total Advance<br /> Value</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {loan.ornaments && loan.ornaments.map((x, idx) => <Tr key={idx}>
                            <Td className='table-row-td'>{x.desc}</Td>
                            <Td className='table-row-td'>{x.quantity}</Td>
                            <Td className='table-row-td'>{x.gross_weight}</Td>
                            <Td className='table-row-td'>{x.net_weight}</Td>
                            <Td className='table-row-td'>{x.deduction}</Td>
                            <Td className='table-row-td'>{x.advance_value}</Td>
                            <Td className='table-row-td'>{x.total_advance}</Td>
                        </Tr>)}
                    </Tbody>
                </Table>
            </div>
            <br />
            <div className='personal-details'>
                <Formik>
                    <Form className='form'>
                        <div className="form-row">
                            <div className="form-group-col">
                                <label >Market Value of Gold Pledged</label>
                                <Field
                                    value={loan.details && loan.details.gold && loan.details.gold.valuation}
                                    className='input-field'
                                    disabled
                                />
                            </div>
                            <div className="form-group-col">
                                <label >Loan Amount Requested</label>
                                <Field
                                    value={loan.details && loan.details.loan_request}
                                    className='input-field'
                                    disabled
                                />
                            </div>
                            <div className="form-group-col">
                                <label >Sanctioned Loan Amount</label>
                                <Field
                                    value={loan.loan_amount}
                                    className='input-field'
                                    disabled
                                />
                            </div>
                            <div className="form-group-col">
                                <label >Packet Details</label>
                                <Field
                                    value={loan.details && loan.details.gold_packet}
                                    className='input-field'
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group-col">
                                <label >External Appraisal Done By</label>
                                <Field
                                    value={loan.details && loan.details.external_appraisal}
                                    className='input-field'
                                    disabled
                                />
                            </div>
                        </div>
                    </Form>
                </Formik>
            </div>
            <br />
            <div>
                <br />
                <h3><b>&nbsp;&nbsp;Charges Details</b></h3>
                <br />
                <div className='personal-details'>
                    <Formik>
                        <Form className='form'>
                            <div className="form-row">
                                <div className="form-group-col">
                                    <label >PF Charges</label>
                                    <Field
                                        value={loan.details && loan.details.pf_charge}
                                        className='input-field'
                                        disabled
                                    />
                                </div>
                                <div className="form-group-col">
                                    <label >EMI</label>
                                    <Field
                                        value={loan.emi}
                                        className='input-field'
                                        disabled
                                    />
                                </div>
                                <div className="form-group-col">
                                    <label >Payment Frequency</label>
                                    <Field
                                        value={loan.payment_frequency}
                                        className='input-field'
                                        disabled
                                    />
                                </div>
                                <div className="form-group-col">
                                    <label >Disbursement Amount</label>
                                    <Field
                                        value={loan.disburse_amount}
                                        className='input-field'
                                        disabled
                                    />
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
            <br />
            <div>
                <h3><b>&nbsp;&nbsp;Ornament Image/s</b></h3>
                <div className='image'>
                    {loan.details && loan.details.gold_pics && loan.details.gold_pics.map((img, idx) => (
                        <img
                            className='gold-image'
                            key={idx}
                            src={img.image}
                        />
                    ))}
                        <img
                        className='gold-image'
                        alt="gold pic"
                        src={loan.details && loan.details.gold_pic}
                    />
                </div>
            </div>
        </div>
    )
}
