import React from "react";
import { useReactToPrint } from "react-to-print";
import { Table } from "@material-ui/core";
import { Tbody, Td, Th, Tr } from "react-super-responsive-table";
import datehelper from "../../_helpers/datehelper";
import logo from "../../assets/kaabil.png"
export const DailyReportPrint = (props) => {
    const data = props.history.location.state
    console.log("data", data)
    const branchDetails = props.history.location.state.branchDetails
    const openingBalance = props.history.location.state.openingBalance
    const topUp_transaction = props.history.location.state.topUp_transaction
    const payment = props.history.location.state.payment;
    const dailyReportId=props.history.location.state.dailyReportId
    let cas = 0;
    let onlin = 0;
    let disbOnline = 0;
    let disbCash = 0
    let i = 1;
    let branchOnlineReceived = 0
    let branchOnlinePaid = 0
    let branchCashReceived = 0
    let branchCashPaid = 0
    const date = new Date();
    const dailyReportRef = React.useRef();
    const handleDailyReportPrint = useReactToPrint({
        content: () => dailyReportRef.current,
    });

    return (
        <div>
            <div style={{ textAlign: "center" }}>
                <button onClick={() => { handleDailyReportPrint() }}>
                    Print Branch Summary
                </button>
            </div>
            <div
                ref={dailyReportRef}
                style={{
                    width: "248mm",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: '50px',
                    bgcolor: "background.paper",
                    padding: 45,
                }}
            >
                <div>
                    <div style={{ textAlign: "center" }}>
                        <img style={{ width: 350, height: 100 }} src={logo}></img>
                    </div>
                    <br/>
                    <h5 style={{ fontSize: "25px",textAlign:"center" }}>Daily Report</h5>
                    <br/>
                    <p><b style={{ fontSize: "20px", marginRight: "40px" }}>{branchDetails[0].branch_name}</b> BRANCH CODE :{branchDetails[0].branch_id}</p>
                    <b style={{float:"right"}}>{dailyReportId}</b>
                    <b style={{ float: 'left' }}>{datehelper.displayDate(date)}</b>
                    <br/>
                    <br />
                    {payment || openingBalance || topUp_transaction ? (
                        <div>
                            <Table>
                                <thead>
                                    <Tr>
                                        <Th style={{ border: "1px solid " }}>S.N</Th>
                                        <Th style={{ border: "1px solid " }}>Opening Balance</Th>
                                        <Th style={{ border: "1px solid " }}>Loan No</Th>
                                        <Th style={{ border: "1px solid " }}>Online Receive</Th>
                                        <Th style={{ border: "1px solid " }}>Online Paid</Th>
                                        <Th style={{ border: "1px solid " }}>Cash receive</Th>
                                        <Th style={{ border: "1px solid " }}>Cash Paid</Th>
                                        <Th style={{ border: "1px solid " }}>Closing Balance</Th>
                                        <Th style={{ border: "1px solid " }}>Cheque</Th>
                                        <Th style={{ border: "1px solid " }}>Remark</Th>
                                    </Tr>
                                </thead>
                                <Tbody>
                                    {
                                        openingBalance ?
                                            <Tr>
                                                <Td style={{ border: "1px solid " }}>{i}</Td>
                                                <Td style={{ border: "1px solid " }}>{`Cash RECEIVE FROM HO `}</Td>
                                                <Td style={{ border: "1px solid " }}>{ }</Td>
                                                <Td style={{ border: "1px solid " }}>{ }</Td>
                                                <Td style={{ border: "1px solid " }}>{ }</Td>
                                                <Td style={{ border: "1px solid " }}>{openingBalance}</Td>
                                                <Td style={{ border: "1px solid " }}>{ }</Td>
                                                <Td style={{ border: "1px solid " }}>{ }</Td>
                                                <Td style={{ border: "1px solid " }}>{ }</Td>
                                                <Td style={{ border: "1px solid " }}>{ }</Td>
                                            </Tr> : null
                                    }
                                    {
                                        topUp_transaction ?
                                            topUp_transaction.map((x, index) => {
                                                x.payment_mode === "Online" ? (x.payment_type === "Received" ? branchOnlineReceived = branchOnlineReceived + parseInt(x.amount) : branchOnlinePaid = branchCashReceived + parseInt(x.amount))
                                                    : (x.payment_type === "Received" ? branchCashReceived = branchCashReceived + parseInt(x.amount) : branchCashPaid = branchCashPaid + parseInt(x.amount))

                                                i = i + 1
                                                return (
                                                    <Tr key={index}>
                                                        <Td style={{ border: "1px solid " }}>{i}</Td>
                                                        <Td style={{ border: "1px solid " }}>{x.payment_type === "Received" ? `${x.payment_type} from ${x.from_or_to}` : `${x.payment_type} to ${x.from_or_to}`}</Td>
                                                        <Td style={{ border: "1px solid " }}>{ }</Td>
                                                        <Td style={{ border: "1px solid " }}>{x.payment_type === "Received" && x.payment_mode === "Online" ? x.amount : null}</Td>
                                                        <Td style={{ border: "1px solid " }}>{x.payment_type === "Paid" && x.payment_mode === "Online" ? x.amount : null}</Td>
                                                        <Td style={{ border: "1px solid " }}>{x.payment_type === "Received" && x.payment_mode === "Cash" ? x.amount : null}</Td>
                                                        <Td style={{ border: "1px solid " }}>{x.payment_type === "Paid" && x.payment_mode === "Cash" ? x.amount : null}</Td>
                                                        <Td style={{ border: "1px solid " }}>{ }</Td>
                                                        <Td style={{ border: "1px solid " }}>{ }</Td>
                                                        <Td style={{ border: "1px solid " }}>{x.remark}</Td>
                                                    </Tr>
                                                );
                                            })
                                            : null}
                                    {payment && payment.cash_payments && payment.cash_payments.length > 0 ?
                                        payment.cash_payments.map((cash, index) => {
                                            cas = cas + cash.payment;
                                            i = i + 1
                                            return (
                                                <Tr key={index}>
                                                    <Td style={{ border: "1px solid " }}>{i}</Td>
                                                    <Td style={{ border: "1px solid " }}>{cash.cust_name}</Td>
                                                    <Td style={{ border: "1px solid " }}>{cash.loan_no}</Td>
                                                    <Td style={{ border: "1px solid " }}>{ }</Td>
                                                    <Td style={{ border: "1px solid " }}>{ }</Td>
                                                    <Td style={{ border: "1px solid " }}> {cash.payment}</Td>
                                                    <Td style={{ border: "1px solid " }}>{ }</Td>
                                                    <Td style={{ border: "1px solid " }}>{ }</Td>
                                                    <Td style={{ border: "1px solid " }}>{ }</Td>
                                                    <Td style={{ border: "1px solid " }}>{cash.comment}</Td>
                                                </Tr>
                                            );
                                        })
                                        : null}
                                    {payment && payment.online_payments && payment.online_payments.length > 0 ?
                                        payment.online_payments.map((online, index) => {
                                            onlin = onlin + Number(online.txn_amt);
                                            i = i + 1
                                            return (
                                                <Tr key={index}>
                                                    <Td style={{ border: "1px solid " }}> {i}</Td>
                                                    <Td style={{ border: "1px solid " }}>{online.remitter_name} </Td>
                                                    <Td style={{ border: "1px solid " }}>{online.vaccountnum} </Td>
                                                    <Td style={{ border: "1px solid " }}>{online.txn_amt} </Td>
                                                    <Td style={{ border: "1px solid " }}>{ }</Td>
                                                    <Td style={{ border: "1px solid " }}>{ }</Td>
                                                    <Td style={{ border: "1px solid " }}>{ }</Td>
                                                    <Td style={{ border: "1px solid " }}>{ }</Td>
                                                    <Td style={{ border: "1px solid " }}>{ }</Td>
                                                    <Td style={{ border: "1px solid " }}>{online.sender_to_receiver_info}</Td>
                                                </Tr>
                                            );
                                        })
                                        : null}
                                    {payment && payment.disbursement_details && payment.disbursement_details.length > 0 ?
                                        payment.disbursement_details.map(
                                            (disburse, index) => {
                                                disburse.payment_mode === "online" ? (disbOnline = disbOnline + disburse.payment_amount) : (disbCash = disbCash + disburse.payment_amount)
                                                i = i + 1
                                                return (
                                                    <Tr key={index}>
                                                        {console.log("test disburse paymet", disburse.disbursement_details)}
                                                        <Td style={{ border: "1px solid " }}> {i} </Td>
                                                        <Td style={{ border: "1px solid " }}>{disburse.name}</Td>
                                                        <Td style={{ border: "1px solid " }}>{disburse.loan_account}</Td>
                                                        <Td style={{ border: "1px solid " }}>{ }</Td>
                                                        <Td style={{ border: "1px solid " }}>{disburse.payment_mode === "online" ? disburse.payment_amount : null}</Td>
                                                        <Td style={{ border: "1px solid " }}>{ }</Td>
                                                        <Td style={{ border: "1px solid " }}>{disburse.payment_mode === "cash" ? disburse.payment_amount : null}</Td>
                                                        <Td style={{ border: "1px solid " }}>{ }</Td>
                                                        <Td style={{ border: "1px solid " }}>{ }</Td>
                                                        <Td style={{ border: "1px solid " }}>{disburse.payment_ref}</Td>
                                                    </Tr>
                                                );
                                            }
                                        )
                                        : null}
                                </Tbody>
                                <Tr style={{ border: "1px solid " }}>
                                    <Td colSpan="2" style={{ textAlign: "center", fontWeight: "bold" }}> Total</Td>
                                    <Td style={{ fontWeight: "bold", border: "1px solid " }} >{ }</Td>
                                    <Td style={{ border: "1px solid " }}>{onlin + branchOnlineReceived}</Td>
                                    <Td style={{ border: "1px solid " }}>{disbOnline + branchOnlinePaid}</Td>
                                    <Td style={{ border: "1px solid " }}>{cas + branchCashReceived + parseInt(openingBalance)}</Td>
                                    <Td style={{ border: "1px solid" }}>{disbCash + branchCashPaid}</Td>
                                    <Td style={{ border: "1px solid" }}>{(cas + branchCashReceived + parseInt(openingBalance)) - (disbCash + branchCashPaid)}</Td>
                                    <Td style={{ border: "1px solid" }}>{ }</Td>
                                    <Td style={{ border: "1px solid " }}>{ }</Td>
                                </Tr>
                            </Table>
                        </div>
                    ) : (
                        <Tr>
                            <Td></Td>
                            <Td>Daily Report not available</Td>
                            <Td></Td>
                        </Tr>
                    )}
                </div>
            </div>
        </div>
    )
}
