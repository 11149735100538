import React, { useState,useEffect } from "react";
import { branchService } from "../../../_services/branch.service";
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from "yup";
import { adminservice } from "../../../_services/admin.services";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddIcon from '@material-ui/icons/Add';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { notify } from "../../../_helpers/notify";

export default function AddProvider({ history, match }) {

    const [submitButton, setSubmitButton] = React.useState(false)
    const [areaList, setAreaList] = React.useState({});
    const [selectedAreas, setSelectedAreas] = useState([]);
    const {id} = match.params 
    const formikRef = React.useRef();

    const isAddMode = !id;

 
    const getCityDetails = () => {
        console.log("getting cities list");
        branchService.getAll()
            .then((res) => {
             
                let allarea = {};
                res.map((c) => {
                     
                    if (c.branch_area) {

                        allarea[c.branch_area] = c.branch_area;
                    }
                     
                });
               
                setAreaList(allarea);
               
            })
            .catch((err) => console.log(err))
    }
     React.useEffect(() => {
        getCityDetails()
    }, [])

    let initialValues;

 
    initialValues = {
        contact: "",
        email: "",
        area: [],
        provider_name: "",
        charges:"",
        type:"",
        emp_id:""
    };
 
     


    const validationSchema = Yup.object().shape({

        email: Yup.string().email('Invalid email address').required('Email is required'),

        contact: Yup.string().length(10).matches(/^[0-9]+$/, "Must be only digits").required('This field is required'),
        provider_name: Yup.string().required("This field is required"),
        charges:Yup.number().required('charges is required'),
        type:Yup.string().required('provide type is required')
    });




    async function onSubmit(values, { setSubmitting }) {
        values.area = selectedAreas;
        setSubmitButton(true);
           console.log('values is',values)
           if(isAddMode){

               adminservice.createProvider(values).then(() => {
                    
       
                notify(`${values.provider_name} Added Successfully`)
                setTimeout(() => {
                   history.push('.')
                }, 2000);
               })
                   .catch((err) => {
                       window.alert("error creating city");
                       console.log(err);
                       setSubmitButton(false);
                   })
           }
           else if(!isAddMode){
            adminservice.updateProvider({id,"value":values}).then(() => {
                    
       
                notify(`${values.provider_name} updated Successfully`)
                setTimeout(() => {
                    history.push('..')
                 }, 2000);
            })
                .catch((err) => {
                    window.alert("error creating city");
                    console.log(err);
                    setSubmitButton(false);
                })
           }





    }
    const handleAreaClick = (area) => {
        if (!selectedAreas.includes(area)) {
            setSelectedAreas([...selectedAreas, area]);
        }
    };
    const handleRemoveArea = (area) => {
        setSelectedAreas(selectedAreas.filter((selectedArea) => selectedArea !== area));
    };



    useEffect(() => {
         
         if (!isAddMode) {
          // get user and set form fields
    
          adminservice.getProviderById(id).then((provider) => {
            console.log('provider data',provider)
            const fields = [
                "contact" ,
                "email" ,
                "area" ,
                "provider_name" ,
                "charges" ,
                "type" ,
                "emp_id"
            ];
            fields.forEach((field) => {
              if (formikRef.current) {
                formikRef.current.setFieldValue(field, provider[field], false);
              }
            });
            setSelectedAreas(provider.area || []);
          });
        }
      }, [id, isAddMode, formikRef]);

    console.log('selectedAreas', selectedAreas)
    return (
        <div>
 
            <h2 id="transition-modal-title">Add Provider</h2>



            <Formik
                  innerRef={formikRef}

                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                id="transition-modal-description"
            >
                {({ errors, touched, isSubmitting, values,setFieldValue }) => {
                    return (<Form>
                        <ToastContainer />
                        <div className="form-row">
                            <div className="form-group col">
                                <label>Provider Name:</label>
                                <Field name="provider_name" type="text" className={
                                    "form-control" +
                                    (errors.provider_name && touched.provider_name ? " is-invalid" : "")
                                } />
                                <ErrorMessage
                                    name="provider_name"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>
                            <div className="form-group col">
                                <label>E-mail:</label>
                                <Field name="email" type="email" className={
                                    "form-control" +
                                    (errors.email && touched.email ? " is-invalid" : "")
                                } />
                                <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>
                           
                        </div>
                        <div className="form-row">
                        <div className="form-group col">
                                <label>Contact</label>
                                <Field name="contact" type="number" className={
                                    "form-control" +
                                    (errors.contact && touched.contact ? " is-invalid" : "")
                                } />
                                <ErrorMessage
                                    name="contact"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>
                            <div className="form-group col">
                                <label>Charges</label>
                                <Field name="charges" type="number" className={
                                    "form-control" +
                                    (errors.charges && touched.charges ? " is-invalid" : "")
                                } />
                                <ErrorMessage
                                    name="charges"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>
                            <div className="form-group col">
                                <label>Emp ID</label>
                                <Field name="emp_id" type="number" className={
                                    "form-control" +
                                    (errors.emp_id && touched.emp_id ? " is-invalid" : "")
                                } />
                                <ErrorMessage
                                    name="emp_id"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>
                            <div className="form-group col">
                                <label>Type Of Report</label>
                                <Field name="type" as='select'   className={
                                    "form-control" +
                                    (errors.type && touched.type ? " is-invalid" : "")
                                } >
                                    <option value="">Select </option>
                                  <option value="RCU">RCU</option>
                                  <option value="Valuation">Valuation</option>
                                  <option value="Legal">Legal</option>
                                </Field>
                                
                            </div>
                        </div>
                        <div className="form-col">

                            <div className="form-group row p-4" style={{ gap: '2rem' }}>
                                <h3>Select Area Code:</h3>
                                {Object.keys(areaList).map((cul, idx) => (
                                    <div key={idx}>
                                        <button
                                            type="button"
                                            onClick={() => handleAreaClick(areaList[cul])}
                                            className="btn btn-primary"
                                            style={{ marginRight: '10px', position: 'relative', width: '4rem' }}
                                        >
                                            {areaList[cul]}
                                            <AddIcon
                                                onClick={() => handleAreaClick(areaList[cul])}
                                                style={{
                                                    position: 'absolute',
                                                    top: '-4px',
                                                    right: '-5px',
                                                    borderRadius: '50%',
                                                    cursor: 'pointer'
                                                }}
                                            />
                                        </button>
                                    </div>
                                ))}
                                <ErrorMessage
                                    name="area"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>

                            {selectedAreas.length > 0 &&
                                <div >
                                    <h3>Selected Area Code</h3>
                                    <div className="form-group row p-4" style={{ gap: '2rem' }}>
                                        {selectedAreas.map((area, idx) => (
                                            <div key={idx}>

                                                <button
                                                    type="button"
                                                    onClick={() => handleRemoveArea(area)}
                                                    className="btn btn-danger btn-sm"
                                                    style={{ marginLeft: '5px', position: 'relative', width: '4rem', height: '40px' }}
                                                >
                                                    {area}
                                                    <HighlightOffIcon
                                                        onClick={() => handleRemoveArea(area)}
                                                        style={{
                                                            position: 'absolute',
                                                            top: '-4px',
                                                            right: '-5px',
                                                            borderRadius: '50%',
                                                            cursor: 'pointer'
                                                        }}
                                                    />
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>}




                        </div>

                        <hr />
                        <button

                            type="submit"
                            className="btn btn-primary"
                            disabled={submitButton}
                        >
                            Submit
                        </button>

                    </Form>)
                }}
            </Formik>
        </div>
    );
}
