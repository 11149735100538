import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { NavLink } from "react-router-dom";
import SwitchAccountModal from './SwitchAccountModal';
import { employeeService } from '../../_services';
import roleChecker from '../../_helpers/rolechecker';

const options = [
  'Change Password',
];

export default function LongMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const user = employeeService.userValue
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <AccountCircleIcon fontSize="large"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        >

        </AccountCircleIcon>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          
        }}
      >
        {options.map((option) => (
          <MenuItem key={option}  onClick={handleClose}>
            <NavLink to= {props.link} style={{color:'#3F51B5',marginLeft:'-10px'}}>{option}</NavLink>
          </MenuItem>
        ))}
         <SwitchAccountModal/>
      </Menu>
    </div>
  );
}