import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { employeeService } from "../../_services/employee.service";
import { Button } from "@material-ui/core";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { Edit, Delete, Add, BlockOutlined } from "@material-ui/icons";
import ReportRequestModal from "./ReportRequestModal";
import { reportsService } from "../../_services/reports.service";

function ReportsList({ match }) {
  const { path } = match;
  const [reports, setReports] = useState(null);
  const user = employeeService.userValue;
  
  useEffect(() => {
    reportsService.getAll().then((x) => {
      console.log(x);
      x=x.filter((y)=> {
        if(y.emp_id===user.emp_id)
        {
          return true;
        }
        return false;
      });
      setReports(x);
    });
  }, []);

  return (
    <div>
        <ReportRequestModal/>
      <h2>Reports List</h2>
      <Table className="table table-striped">
        <Thead>
          <Tr>
            <Th style={{ width: "10%" }}>Report Date </Th>
            <Th style={{ width: "20%" }}>Business Division</Th>
            <Th style={{ width: "20%" }}>Report Type</Th>
            <Th style={{ width: "20%" }}>Creator</Th>
            <Th style={{ width: "20%" }}>Status</Th>
            <Th style={{ width: "10%" }}>Report Link</Th>
          </Tr>
        </Thead>
        <Tbody>
          {reports &&
            reports.map((report) => (
              <Tr key={report._id}>
                <Td>
                  {report.updated.split("T")[0]}
                </Td>
                <Td>
                  {report.business_div}
                </Td>
                <Td>{report.report_type}</Td>
                <Td>{report.employee && report.employee.length>0 && (
                  <>
                  {report.employee[0].first_name} {report.employee[0].last_name}
                  </>         
                )}  
                </Td>
                <Td>{report.report_status}
                {report.report_status !=='Ready' && (
                            <p><br/>{report._id}</p>
                  )}
                </Td>
                <Td style={{ whiteSpace: "nowrap" }}>
                  {report.report_status ==='Ready' && (
                            <a href={report.report_link} >
                              Download </a>
                            
                  )}
                </Td>
              </Tr>
            ))}
          {!reports && (
            <Tr>
              <Td colSpan="4" className="text-center">
                <span className="spinner-border spinner-border-lg align-center"></span>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </div>
  );
}

export { ReportsList };
