import React, { useEffect, useState } from "react";
import { Button, Paper, Typography, TextField } from "@material-ui/core";
import AddEnachModal from "./AddEnachModal";
import NachTable from "./NachTable";
import { enachService } from "../../../_services/enach.service";

// function createData(loanNo, amount, date, name, phone, bank, enachRef, action) {
//   return { loanNo, amount, date, name, phone, bank, enachRef, action };
// }

export default function AuEnach() {
  const [enachData, setEnachData] = useState([]);
  const [filteredEnachData, setFilteredEnachData] = useState([]);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [enachRef, setEnachRef] = useState("");
  const [loanNo, setLoanNo] = useState("");
  const [bank, setBank] = useState("");

  useEffect(() => {
    setFilteredEnachData(
      enachData.filter((data) => {
        return (
          data.name.toLowerCase().includes(name.toLowerCase()) &&
          data.loan_number.toLowerCase().includes(loanNo.toLowerCase()) &&
          data.contact.toString().toLowerCase().includes(phone.toLowerCase())
        );
      })
    );
  }, [name, phone, loanNo,  enachData]);

  useEffect(()=> {
    enachService.getAll()
    .then((nachList)=> {
      setEnachData(nachList)
      console.log(nachList);
    })
    .catch((err)=> {
      console.log(err);
    })
  },[])

  return (
    <div className="App">
      <header className="App-header">
        <h1>AU Enach</h1>
        <Paper
          className="col-md-12"
          style={{
            padding: 10,
          }}
        >
          <div className="d-flex justify-content-between">
            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="Mobile"
              variant="outlined"
              onChange={(e) => setPhone(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="eNach Ref"
              variant="outlined"
              onChange={(e) => setEnachRef(e.target.value)}
            />
            <TextField
              id="date"
              label="From"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="date"
              label="To"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <br />
          <div className="d-flex justify-content-between">
            <TextField
              id="outlined-basic"
              label="Loan Number"
              variant="outlined"
              onChange={(e) => setLoanNo(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="Bank"
              variant="outlined"
              onChange={(e) => setBank(e.target.value)}
            />
            <AddEnachModal enachData={enachData} setEnachData={setEnachData} />
          </div>
        </Paper>
        <br />
        <Paper>
          <NachTable rows={filteredEnachData} />
        </Paper>
      </header>
    </div>
  );
}
