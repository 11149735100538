import React, { useEffect, useState } from 'react'
import AddCallDetails from './AddCallDetails'
import { voiceSchedule } from '../../../_services/voice_schedule_system.service'
import ContactModal from "./ContactModal"
import datehelper from "../../../_helpers/datehelper"
import { Button } from '@material-ui/core'

const CallDetailsList = () => {

  const [data, setData] = useState()

  const getData = () =>{
    voiceSchedule.getAll()
    .then((x)=>{
      console.log(x)
      setData(x)
    })
    .catch((err)=>{console.log(err)})
  }

  useEffect(() => {
     getData()
  }, [])

  // const handleStatus = (temp) => {
  //   let status = !temp.status;
  //   console.log(status);
  //   voiceSchedule
  //     .update({ ...temp, status })
  //     .then((data) => {
  //       alert("Status Updated");
  //       window.location.reload();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  

  return (
    <div>
      <h1 style={{textAlign:"center"}}>Call Details</h1>
        <h4>Add Contact Details</h4><AddCallDetails></AddCallDetails>
        <hr style={{border:"1px solid"}}></hr><br/>
        {data ? <table>
          <thead>
            <tr>
              <th>Contacts</th>
              <th>Date</th>
              <th>Time</th>
              <th>Call Type</th>
              <th>Employee</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody> 
            {data && data.length >0 && data.map((e, idx)=>{
             return <tr key={idx}>
              <td><ContactModal contacts={e.contacts}/></td>
              <td>{e.date ? (datehelper.displayDate(new Date(e.date))) : <td>-----</td>}</td>
              <td>{e.time ? e.time : <td>-----</td>}</td>
              <td>{e.call_type}</td>
              {e.employee ? <td>{e.employee.first_name}</td> : <td>-----</td>}
              <td>{e.status}</td>
              {/* {e.status === true ? (
                        <td>
                          <Button
                            onClick={() => handleStatus(e)}
                            style={{ color: "green" }}
                          >
                            Completed
                          </Button>
                        </td>
                      ) : (
                        <td>
                          <Button
                            onClick={() => handleStatus(e)}
                            style={{ color: "red" }}
                          >
                            Pending
                          </Button>
                        </td>
                      )} */}
            </tr>
            })}
            
          </tbody>
        </table> : <h1 style={{textAlign:"center", color:"red"}}>***No Data found***</h1>}
    </div>
  )
}

export default CallDetailsList
