import { fetchWrapper} from "../_helpers";

const apiUrl = process.env.REACT_APP_API_URL;

const baseUrl = `${apiUrl}/branches`;

export const branchService = {
  getByCode,
  getById,
  getAll,
  create,
  update,
  delete: _delete,
};

function getByCode(branch_code) {
  return fetchWrapper.get(`${baseUrl}/code/${branch_code}`);
}
function getById(branch_id) {
  return fetchWrapper.get(`${baseUrl}/${branch_id}`);
}

function getAll() {
  return fetchWrapper.get(baseUrl);
}

function create(params) {
  return fetchWrapper.post(baseUrl, params)
}

function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`).then((x) => {
    return x;
  });
}

function update(branchId,branchObj) {
  return fetchWrapper.put(`${baseUrl}/${branchId}`,branchObj);
}

