import { fetchWrapper } from "../_helpers";

const apiUrl = process.env.REACT_APP_API_URL;
const baseUrl = `${apiUrl}/ledger`
export const ledgerService ={
    addLedger,
    getLedgerList,
    addLedgerTransaction,
    pendingLedgerTransactions,
    approveLedgerTransactions,
    deleteLedgerTransaction,
    approvedLedgerTransactions,
    pendingCheckOutLedgerTransactions,
    approvedCheckOutLedgerTransactions,
    getGoldCashLedgers,
    getLedgerTransactions,
    getCashLedgersByProduct,
    resetLedger,
    revertLedger,
    createCashRequest,
    getCashRequestList,
    approveChequeRequest,
    deleteCashRequest,
    rejectCashRequest,
    getBranchTransaction,
    getLedgerCurrectBalance,
    getBanks,
    createBankDepositRequest,
    getBankDepositList,
    approveBankDeposit,
    getBankLedgers,
    getBankLedgerTransactions,
    searchByDateForStatement
}
 

function addLedger(params){
    return fetchWrapper.post(`${baseUrl}/add`, params)
}

function getLedgerList(){
    return fetchWrapper.get(`${baseUrl}/ledgerlist`)
}

function addLedgerTransaction(params){
    return fetchWrapper.post(`${baseUrl}/addledgertransaction`, params)
}

function pendingLedgerTransactions(){
    return fetchWrapper.get(`${baseUrl}/pendingledgertransactions`)
}

function approvedLedgerTransactions(){
    return fetchWrapper.get(`${baseUrl}/approvedledgertransactions`)
}

function approveLedgerTransactions(params){
    return fetchWrapper.post(`${baseUrl}/approveledgertransactions`, params)
}

function deleteLedgerTransaction(id){
    return fetchWrapper.delete(`${baseUrl}/deleteledgertransaction/${id}`)
}
 
function pendingCheckOutLedgerTransactions(){
    return fetchWrapper.get(`${baseUrl}/pendingcheckoutledgertransactions`)
}

function approvedCheckOutLedgerTransactions(){
    return fetchWrapper.get(`${baseUrl}/approvedcheckoutledgertransactions`)
}

function getGoldCashLedgers(){
    return fetchWrapper.get(`${baseUrl}/getgoldcashledgers`)
}

function getLedgerTransactions(id){
    return fetchWrapper.put(`${baseUrl}/getledgertransactions/${id}`)
}

function getCashLedgersByProduct(params){
    return fetchWrapper.post(`${baseUrl}/getcashledgersbyproduct`, params)
}

function resetLedger(id,params){
    return fetchWrapper.post(`${baseUrl}/resetledger/${id}`,params)
}
function revertLedger(id,params){
    return fetchWrapper.post(`${baseUrl}/revertledger/${id}`,params)
}
function createCashRequest(params){
    //console.log('console is ',params)
    return fetchWrapper.post(`${baseUrl}/createcashrequest`,params)
}
function getCashRequestList(){
    return fetchWrapper.get(`${baseUrl}/getcashrequestlist`)
}
 function approveChequeRequest(id,params){
    return fetchWrapper.post(`${baseUrl}/approvechequerequest/${id}`,params)
 }
function deleteCashRequest(id){
    return fetchWrapper.delete(`${baseUrl}/deletecashrequest/${id}`)
}
function rejectCashRequest(id){
    return fetchWrapper.put(`${baseUrl}/rejectcashrequest/${id}`)
}
function getBranchTransaction(){
    return fetchWrapper.get(`${baseUrl}/getbranchtransaction`)
}
function getLedgerCurrectBalance(){
    return fetchWrapper.get(`${baseUrl}/getledgercurrectbalance`)
}
function getBanks(){
    return fetchWrapper.get(`${baseUrl}/getbanks`)
}
function createBankDepositRequest(params){
    return fetchWrapper.post(`${baseUrl}/createbankdepositrequest`,params)
}
function getBankDepositList(){
    return fetchWrapper.get(`${baseUrl}/getbankdepositlist`)
}
function approveBankDeposit(params){
    return fetchWrapper.post(`${baseUrl}/approvebankdeposit`,params)
}
function getBankLedgers(){
    return fetchWrapper.get(`${baseUrl}/getbankledgers`)
}
function getBankLedgerTransactions(id){
    console.log('id is',id)
    return fetchWrapper.get(`${baseUrl}/getbankledgertransactions/${id}`)
}
function searchByDateForStatement(params){
    console.log('params is',params)
    return fetchWrapper.post(`${baseUrl}/getbankledgerbydate`,params)
}