// import { withWidth } from '@material-ui/core'
// import React, { useState, useEffect } from 'react'
// import EmiReminderLetter from './EmiReminderLetter'
// import { Button, TextField, Paper } from "@material-ui/core";
// import { makeStyles } from '@material-ui/core/styles';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
// import InputLabel from '@material-ui/core/InputLabel';
// import { letter } from "../../../_services/lettertracking.service"


// const useStyles = makeStyles((theme) => ({
//     formControl: {
//         margin: theme.spacing(1),
//         minWidth: 120,
//     },
//     selectEmpty: {
//         marginTop: theme.spacing(2),
//     },
// }));

// const EmiDetail = () => {

//     const [value, setValue] = useState({
//         letter_number: "",
//         account_number: "",
//         loan_amt: "",
//         loan_amt_hindi: "",
//         emi_amt: "",
//         total_emi: "",
//         pending_emi: "",
//         pending_amt: "",
//         pending_amt_hindi: "",
//     })

//     const [item, setItem] = useState([])

//     const [show, setShow] = useState(false)

//     const handleChange = (e) => {
//         setValue({ ...value, [e.target.name]: e.target.value })
//     }

//     let id = window.location.pathname.split("/")
//     id = id[4]
//     console.log(id)

//     const getById = () => {
//         letter.singleEmiLetter(id)
//             .then((data) => {
//                 setItem(data.item)
//                 setValue(data)
//             })
//             .catch((err) => {
//                 console.log(err)
//             })
//     }

//     useEffect(() => {
//         if (id && id.length > 0) {
//             getById()
//         }

//     }, [])

//     const handleItem = (e, index) => {
//         const { name, value } = e.target
//         const list = [...item]
//         list[index][name] = value
//         setItem(list)
//     }

//     const classes = useStyles();

//     const addData = () => {
//         setItem([...item, { title: "", name: "", role: "", relation: "", relation_title: "", father_name: "", address: "" }])
//     }

//     const removeData = (index) => {
//         const list = [...item]
//         list.splice(index, 1)
//         setItem(list)
//     }

//     const handleSubmit = () => {
//         letter.createEmiLetter({ ...value, item })
//             .then((data) => {
//                 alert("Data Added Successfully")
//                 console.log(data)
//             })
//             .catch((err) => {
//                 console.log(err)
//             })
//         setShow(true)
//         // console.log(item, value, "test")
//     }

//     const updateData = () => {
//         letter.updateEmiLetter(id, {...value, item})
//             .then((data) => {
//                 alert("Data Added Successfully")
//                 setShow(true)
//             })
//             .catch((err) => {
//                 console.log(err)
//             })
//     }


//     return (
//         <>
//             {show === true ? <EmiReminderLetter value={value} item={item} />
//                 : <div>
//                     <Paper style={{ width: "80%", margin: "auto" }}>
//                         <h1 style={{ textAlign: "center" }} >EMI Reminder Letter</h1>
//                         <div style={{ marginTop: "20px" }}>
//                             {item && item.map((item, index) => {
//                                 return <div key={index} style={{ textAlign: "center", margin: "auto" }}>
//                                     <div>
//                                         <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: "40px", width: "20px" }}>
//                                             <InputLabel id="demo-simple-select-outlined-label">Title</InputLabel>
//                                             <Select
//                                                 labelId="demo-simple-select-outlined-label"
//                                                 id="demo-simple-select-outlined"
//                                                 value={item.title}
//                                                 name="title"
//                                                 onChange={(e) => handleItem(e, index)}
//                                                 label="Title"
//                                             >
//                                                 <MenuItem value="">
//                                                     <em>None</em>
//                                                 </MenuItem>
//                                                 <MenuItem value="श्री">श्री</MenuItem>
//                                                 <MenuItem value="श्रीमती">श्रीमती</MenuItem>
//                                                 <MenuItem value="कुमारी">कुमारी</MenuItem>
//                                             </Select>
//                                         </FormControl>
//                                         <TextField type='text' required placeholder='Enter the  Name ' name="name" value={item.name} style={{ marginTop: "50px", marginLeft: "30px", width: "60%" }} onChange={(e) => handleItem(e, index)} />
//                                         <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: "40px", width: "20px" }}>
//                                             <InputLabel id="demo-simple-select-outlined-label">Role</InputLabel>
//                                             <Select
//                                                 labelId="demo-simple-select-outlined-label"
//                                                 id="demo-simple-select-outlined"
//                                                  value={item.role}
//                                                 name="role"
//                                                 onChange={(e) => handleItem(e, index)}
//                                                 label="Role"
//                                             >
//                                                 <MenuItem value="">
//                                                     <em>None</em>
//                                                 </MenuItem>
//                                                 <MenuItem value="(मुख्य आवेदक)">(मुख्य आवेदक)</MenuItem>
//                                                 <MenuItem value="(सह आवेदक)">(सह आवेदक)</MenuItem>
//                                                 <MenuItem value="(गारंटर)">(गारंटर)</MenuItem>
//                                                 <MenuItem value="(आवेदक)">(आवेदक)</MenuItem>
//                                             </Select>
//                                         </FormControl>
//                                         <br />
//                                     </div>
//                                     <div>
//                                         <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: "40px", width: "20px" }}>
//                                             <InputLabel id="demo-simple-select-outlined-label">Relation</InputLabel>
//                                             <Select
//                                                 labelId="demo-simple-select-outlined-label"
//                                                 id="demo-simple-select-outlined"
//                                                 value={item.relation}
//                                                 name="relation"
//                                                 onChange={(e) => handleItem(e, index)}
//                                                 label="Relation"
//                                             >
//                                                 <MenuItem value="">
//                                                     <em>None</em>
//                                                 </MenuItem>
//                                                 <MenuItem value="पुत्र">पुत्र</MenuItem>
//                                                 <MenuItem value="पत्नी">पत्नी</MenuItem>
//                                                 <MenuItem value="पुत्री">पुत्री</MenuItem>
//                                             </Select>
//                                         </FormControl>
//                                         <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: "40px", width: "20px" }}>
//                                             <InputLabel id="demo-simple-select-outlined-label">Title</InputLabel>
//                                             <Select
//                                                 labelId="demo-simple-select-outlined-label"
//                                                 id="demo-simple-select-outlined"
//                                                 value={item.relation_title}
//                                                 name="relation_title"
//                                                 onChange={(e) => handleItem(e, index)}
//                                                 label="Title"
//                                             >
//                                                 <MenuItem value="">
//                                                     <em>None</em>
//                                                 </MenuItem>
//                                                 <MenuItem value="श्री">श्री</MenuItem>
//                                                 <MenuItem value="पत्नी">श्रीमती</MenuItem>
//                                             </Select>
//                                         </FormControl>
//                                         <TextField type='text' required placeholder='Enter the Father Name' name='father_name' value={item.father_name} style={{ marginTop: "50px", marginLeft: "30px", width: "60%" }} onChange={(e) => handleItem(e, index)} /><br />
//                                     </div>
//                                     <TextField type='text' required placeholder='Enter the  Address ' name="address" value={item.address} style={{ marginTop: "50px", marginLeft: "30px", width: "90%" }} onChange={(e) => handleItem(e, index)} /><br />
//                                     <Button type='button' style={{ marginLeft: "65px", marginTop: "10px" }} onClick={() => removeData(index)} >Remove</Button><br />
//                                 </div>
//                             })}
//                         </div><br />
//                         <Button style={{ border: "1px solid grey", marginLeft: "40%" }} onClick={() => addData()} >Add Name</Button>

//                         <div style={{ textAlign: "center", margin: "auto" }}>
//                             <TextField style={{ marginTop: "50px", marginLeft: "30px", width: "90%" }} type="text" className="form-control" placeholder='Enter the Account Number' name='account_number' value={value.account_number} onChange={handleChange} />

//                             <TextField style={{ marginTop: "50px", marginLeft: "30px", width: "90%" }} type="text" className="form-control" placeholder='Enter the Loan Amount' name='loan_amt' value={value.loan_amt} onChange={handleChange} />

//                             <TextField style={{ marginTop: "50px", marginLeft: "30px", width: "90%" }} type="text" className="form-control" placeholder='Enter the Loan Amount in hindi' name='loan_amt_hindi' value={value.loan_amt_hindi} onChange={handleChange} />

//                             <TextField style={{ marginTop: "50px", marginLeft: "30px", width: "90%" }} type="text" className="form-control" placeholder='Enter the Emi Amount' name='emi_amt' value={value.emi_amt} onChange={handleChange} />

//                             <TextField style={{ marginTop: "50px", marginLeft: "30px", width: "90%" }} type="text" className="form-control" placeholder='Enter the Total Number of Emi' name='total_emi' value={value.total_emi} onChange={handleChange} />

//                             <TextField style={{ marginTop: "50px", marginLeft: "30px", width: "90%" }} type="text" className="form-control" placeholder='Enter the Pending Emi' name='pending_emi' value={value.pending_emi} onChange={handleChange} />

//                             <TextField style={{ marginTop: "50px", marginLeft: "30px", width: "90%" }} type="text" className="form-control" placeholder='Enter the Pending Amount' name='pending_amt' value={value.pending_amt} onChange={handleChange} />

//                             <TextField style={{ marginTop: "50px", marginLeft: "30px", width: "90%" }} type="text" className="form-control" placeholder='Enter the Pending Amount in hindi' name='pending_amt_hindi' value={value.pending_amt_hindi} onChange={handleChange} />
//                         </div><br />
//                         <div className='container my-3'>
//                             {
//                                 id && id === "emiletter"  ?   <button className='btn btn-primary' style={{ marginLeft: "40%", width: "200px" }} onClick={handleSubmit} >Create Letter</button> : <button className='btn btn-primary' style={{ marginLeft: "40%", width: "200px" }} onClick={updateData} >Update Letter</button>

//                             }
//                         </div><br />
//                     </Paper>
//                 </div>}

//         </>
//     )
// }

// export default EmiDetail


import React from 'react'
import { useState, useEffect } from 'react'
import {letter} from "../../../_services/lettertracking.service"
import {useParams,useHistory} from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import {loanService} from "../../../_services"
import swal from 'sweetalert';

const Details = () => {
  const history = useHistory();
  // const isCreate = !id;
  const [formData, setFormData] = useState({
    title_name: "",
    name: "",
    relation: "",
    title_father: "",
    father_name: "",
    address: "",
    contact: "",
    pending_emi: "",
    account_number: "",
    sanction_amount: "",
    emi_amount: "",
    tenure: "",
    addCharge: "",
    coapplicant_guarantor: []
  });
  const [applicationId, setApplicationId] = useState('');
  const[checkCosigner, setCheckCosigner]= useState('');
  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }
  const handleSubmit =(e) => {
    e.preventDefault();
    letter.createEmiLetter(formData)
    .then((data) => {
      console.log(data, "Data added successfully");
      swal({
        title: "Success",
        text: "Data added successfully",
        icon: "success",
        button: "Okay"
      }).then(() => {
        // After user clicks "Okay" button, navigate to '/home/huee/wwww'
        history.push('/home/letters/emireminderletter');
      });
    })
    .catch((err) => {
      console.error("Error while saving data:", err);
      swal({
        title: "Error",
        text: "Emi Reminder with this account number already exists",
        icon: "error",
        button: "Okay"
      });
    });
}

  const handleApplicationId = (event) => {
    setApplicationId(event.target.value);
}

const getcustomer = () => {
  if (!applicationId) {
    swal({
      title: "Error",
      text: "Please Enter Account Number",
      icon: "error",
      button: "Okay"
    });
      return; // Stop execution if any required field is empty
  }

  loanService.getBySearch({ application_id: applicationId })
      .then((data) => {
        setFormData({
          title_name:"",
          name: data[0]?.applicant?.general_information?.name || "",
          relation: "",
          title_father:"",
          father_name: data[0]?.applicant?.general_information?.sonof || "",
          address: data[0]?.applicant?.general_information?.address || "",
          contact: data[0]?.applicant?.general_information?.contact || "",
          pending_emi:"",
          account_number:  data[0]?.application_id || "",
          sanction_amount: data[0]?.loan?.sanction_amount || "",
          emi_amount: data[0]?.loan?.emi || "",
          tenure: data[0]?.loan?.tenure || "",
          addCharge: "",
        });    
        setCheckCosigner(data[0])
      })
      .catch((err) => {
          console.log(err);
      });
};

const {id}  = useParams();
console.log(id,"idddddddddddddddddddddddddd")
const getLetter = async () => {
  const details = await letter.singleEmiLetter(id);
  setFormData(details);
  console.log(details,"gggggghhhjjjjjjyyyyyyyy");
}

useEffect(() => {
  if(id){
    getLetter()
  }
}, [])

  const handleEdit =  () => {
     letter.updateEmiLetter(id, formData)
     .then(() => {
      swal({
        title: "Success",
        text: "Updated Successfully",
        icon: "success",
        button: "Okay"
      }).then(() => {
        window.history.back(); // Go back to the previous page
      });
    })
     .catch((err) => {
       console.log(err)
     })

  }

  useEffect(() => {
    let tempArr = [];
    let arr = [];
    if (checkCosigner && checkCosigner.co_applicants && checkCosigner.co_applicants.length > 0) {
      checkCosigner.co_applicants.forEach((x) => {
        arr.push({
          name: x.general_information.name,
          sonof: x.general_information.sonof,
          address: x.general_information.address,
          contact: x.general_information.contact
        });
      });
    }
    if (checkCosigner && checkCosigner.guarantors && checkCosigner.guarantors.length > 0) {
      checkCosigner.guarantors.forEach((x) => {
        arr.push({
          name: x.general_information.name,
          sonof: x.general_information.sonof,
          address: x.general_information.address,
          contact: x.general_information.contact
        });
      });
    }
  
    const combinedArray = [...arr, ...tempArr];
  
    setFormData(prevState => ({
      ...prevState,
      coapplicant_guarantor: combinedArray.map(item => ({
        title_name: "",
        name: item.name || "",
        relation: "",
        title_father: "",
        father_name: item.sonof || "",
        cosigner: "",
        address: item.address || "",
        contact: item.contact || "",
      }))
    }));
  }, [checkCosigner]);
  
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedFormData = [...formData.coapplicant_guarantor];
    updatedFormData[index] = {
      ...updatedFormData[index],
      [name]: value
    };
    setFormData({
      ...formData,
      coapplicant_guarantor: updatedFormData
    });
  };
  
  return (
    <>
       <h1 className='my-3'>EMI Reminder Letter</h1>
       {!id && (
  <div>
       <div className='container my-3'>
            <TextField type="text" className="form-control" required placeholder='Enter the Account Number' name='Loan' value={applicationId} onChange={handleApplicationId} />
            </div>
            <button onClick={getcustomer}>Submit</button>
            </div>
          )}

        <form onSubmit= {handleSubmit}>
    
        <div>
             <div className='my-3' style={{display: "flex", flexDirection: "row", width: "50vw"}}>
            <select className="form-select form-select-sm" required name='title_name' aria-label=".form-select-sm example" value={formData.title_name}   onChange={handleChange}>
              <option selected></option>
              <option >श्री</option>
              <option >श्रीमती</option>
              <option >कुमारी</option>
             </select> <TextField type="text" className="form-control" placeholder='Enter the Name' name='name' value={formData.name}  onChange={handleChange} />
            </div>
            
            <div className='my-3' style={{display: "flex", flexDirection: "row", width: "50vw"}}>
             <select className="form-select form-select-sm" required name='relation' aria-label=".form-select-sm example" value={formData.relation}   onChange={handleChange}>
              <option selected></option>
              <option >पुत्र</option>
              <option >पत्नी</option>
              <option >पुत्री</option>
             </select>
            
            <select className="form-select form-select-sm" required name='title_father' aria-label=".form-select-sm example" value={formData.title_father} onChange={handleChange}>
              <option selected></option>
              <option  >श्री</option>
              <option >श्रीमती</option>
            </select>
             <TextField type="text" className="form-control" required placeholder='Enter the Father or Husband  Name' name='father_name'  value={formData.father_name} onChange={handleChange} />
            </div>
 
            <div className='container my-3'>
            <TextField type="text" className="form-control" required placeholder='Enter the Address' name='address' value={formData.address}  onChange={handleChange} />
            </div>
            <div className='container my-3'>
            <TextField type="text" className="form-control" required placeholder='Enter the Mobile' name='contact'  value={formData.contact}  onChange={handleChange} />
            </div>

            <div className='container my-3'>
            <TextField type="text" className="form-control" required placeholder='Enter the Total Pending Emi' name='pending_emi' value={formData.pending_emi} onChange={handleChange} />
            </div>

            <div className='container my-3'>
            <TextField type="text" className="form-control" required placeholder='Enter the Account Number' name='account_number'  value={formData.account_number} onChange={handleChange} />
            </div>
            <div className='container my-3'>
            <TextField type="number" className="form-control" required placeholder='Enter the Total sanction Amount' name='sanction_amount'  value={formData.sanction_amount} onChange={handleChange} />
            </div>

            <div className='container my-3'>
            <TextField type="number" className="form-control" required placeholder='Enter the Total Emi' name='emi_amount'  value={formData.emi_amount} onChange={handleChange} />
            </div>
            
            <div className='container my-3'>
            <TextField type="number" className="form-control" required placeholder='Enter the Total Tenure' name='tenure'  value={formData.tenure} onChange={handleChange} />
            </div>
            <div className='container my-3'>
            <TextField type="number" className="form-control" required placeholder='Enter the Total Add charge' name='addCharge'  value={formData.addCharge} onChange={handleChange} />
            </div>
            {formData.coapplicant_guarantor && formData.coapplicant_guarantor.map((coapplicant, index) => (
  <div key={index}>
            <div style={{display: "flex"}} >
       <div className='my-3' style={{display: "flex", flexDirection: "row", width: "37vw"}}>  
             <select className="form-select form-select-sm" required name='title_name' value={coapplicant.title_name}  aria-label=".form-select-sm example" onChange={(e) => handleInputChange(e, index)}>
        <option value="">Select Title</option>
        <option value="श्री">श्री</option>
        <option value="श्रीमती">श्रीमती</option>
        <option value="कुमारी">कुमारी</option>
      </select>
            
             <TextField type="text" className="form-control" required placeholder='Enter the Name' name='name' value={coapplicant.name} onChange={(e) => handleInputChange(e, index)} />
            </div>
            <div className='my-3' style={{display: "flex", flexDirection: "row", width: "37vw"}}>
            <select className="form-select form-select-sm" required aria-label=".form-select-sm example" name='relation' value={coapplicant.relation} onChange={(e) => handleInputChange(e, index)}>
        <option value="">Select Relation</option>
        <option value="पुत्र">पुत्र</option>
        <option value="पत्नी">पत्नी</option>
        <option value="पुत्री">पुत्री</option>
      </select>



         <select className="form-select form-select-sm" required name='title_father'value={coapplicant.title_father}   aria-label=".form-select-sm example" onChange={(e) => handleInputChange(e, index)}>
        <option value="">Select Title</option>
        <option value="श्री">श्री</option>
        <option value="श्रीमती">श्रीमती</option>
      </select>
          
             <TextField type="text" className="form-control" required placeholder='Enter the Father or Husband  Name' name='father_name'   value={coapplicant.father_name} onChange={(e) => handleInputChange(e, index)}/>
             <select className="form-select form-select-sm" required name="cosigner" aria-label=".form-select-sm example"  value={coapplicant.cosigner} onChange={(e) => handleInputChange(e, index)}>
                        <option selected></option>
                        <option >(सह आवेदक)</option>
                        <option >(गारंटर)</option>
                        </select>
            </div>
 
       </div>
       <div style={{display: "flex"}}>
       <div className='container my-3' style={{width: "55vw"}}>
            <TextField type="text" className="form-control" required placeholder='Enter the Address' name='address' value={coapplicant.address} onChange={(e) => handleInputChange(e, index)}/>
            </div>
            <div className='container my-3' style={{width: "20vw"}}>
            <TextField type="text" className="form-control" required placeholder='Enter the Mobile' name='contact'value={coapplicant.contact} onChange={(e) => handleInputChange(e, index)} />
            </div>
            </div>
            </div>
        ))}
            <div className='container my-3'>

            {!id && (  <button className='btn btn-warning' type='submit' > Create Letter</button>  )}

              {id===undefined?"":<button className='btn btn-danger mx-5' type='button' onClick={handleEdit}>Update Letter</button>}
            
            </div>
        </div>
        </form>
    </>
  )
}

export default Details