import React from "react";
import { Grid, Card, Button, CardActions, Select, MenuItem, FormControl,InputLabel,Box } from "@material-ui/core";
import { Edit,Add } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { loanService } from "../../_services/loan.service";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { employeeService } from "../../_services";
import roleChecker from "../../_helpers/rolechecker";
import { Role } from "../../_helpers";
import { Input } from "antd";
import { LoanStages } from "../../constants/loanstages";
import datehelper from "../../_helpers/datehelper";
import { Colenders } from "../../constants/colenders";
import EditStageModal from "./EditStageModal";
import { ColendingSubStages } from "../../constants/colendingsubstages";
import { ColenderDocumentModal } from "./ColenderDocumentModal";
const { Search } = Input;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexGrow: 1,
  },
  card: {
    minHeight: 250,
    minWidth: 250,
  },
}));

export default function CommonColendingList({ history, match }) {
  const { path } = match;
  const { colender } = match.params;
  const classes = useStyles();
  const user= employeeService.userValue;
  const [applicants, setApplicants] = React.useState(null);
  const [spinner,setSpinner]= React.useState(true);
  const [page, setPage] = React.useState(0);
  const [searchFilter,setSearchFilter]=React.useState("");
  const [stageList,setStageList]= React.useState([]);
  const [filterStage,setFilterStage]= React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  let colender_name=""
  let colender_id=""
  let colenderList=[{id:Colenders.AshvFinance,name:"Ashv Finance"},
                    {id:Colenders.BhalaFinance,name:"Bhala Finance"},
                    {id:Colenders.Ugro,name:"Ugro"},
                    {id:Colenders.CapitalIndia,name:"Capital India"},
                    {id:Colenders.CIFL,name:"CIFL"},
                    {id:Colenders.CIHL,name:"CIHL"},
                    {id:Colenders.KamalAutoFinance,name:"Kamal Auto Finance"},
                    {id:Colenders.VistaarFinance,name:"Vistaar Finance"}];
  colenderList.map((ci)=> {
    if(ci.id.toLowerCase()=== colender)
    {
      colender_id=ci.id;
      colender_name=ci.name
    }
  })

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  React.useEffect(() => {
    async function getApplicants() {
      setSpinner(true);
      var cust = await loanService.getColenderLoans(colender_id)
      .catch((err)=> {
        console.log("err0r "+ err);
      });
      console.log(cust);
      let tempLoans=cust;
      let tmpStageList=[];
      tempLoans=cust.filter((l)=> {
        if(l.stage === "Discarded" || !l.stage)
        {
          return false;
        }
        if(!tmpStageList.includes(l.co_lender_data.co_lender_status))
        {
          tmpStageList.push(l.co_lender_data.co_lender_status);
        }
        if(searchFilter!="")
        {
          if(l.applicant_name && l.applicant_name.toLowerCase().includes(searchFilter.toLowerCase()))
          {
              return true;
          }
          if(l.application_id === searchFilter || l.application_id === parseInt(searchFilter) )
          {
              return true;
          }
          return false;
        }
        return true;
      });
      setStageList(tmpStageList);
      
      if(filterStage)
       {
        tempLoans=tempLoans.filter((l)=> {
          if(l.co_lender_data.co_lender_status===filterStage)
          {
            return true;
          }
          return false;
        });
       }
      // cust = cust.filter((entry) => entry.submitted == true);
      if (roleChecker.isHOLevelAccess(user.role))
      { 
           tempLoans=tempLoans;
      }
      else{
          tempLoans=null;
      }

      setApplicants(tempLoans);
      setSpinner(false);
     
    }

    getApplicants();
  }, [searchFilter,filterStage]);

  return (
    <div>
      <h1>{colender_name} Loans</h1>
      <div className="d-flex justify-content-between">
      
       
      <Search
       onChange={(e) => setSearchFilter(e.target.value)}
       placeholder="Name/Application Id"
       enterButton
       style={{
         position: "sticky",
         top: "0",
         left: "0",
         width: "200px",
         marginTop: "2vh",
       }}
     />

<Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
      <InputLabel id="filter_stage_label">Filter Stage</InputLabel>
      <Select
      labelId="filter_stage_label"
      name="stagefilter"
      label="Filter Stages"
      value={filterStage}
      onChange={(e) => setFilterStage(e.target.value)}
      >
        <MenuItem value="" disabled>Filter Stage</MenuItem>
        {stageList && stageList.map((s)=> {
          return <MenuItem value={s}>{s}</MenuItem>
        })}

      </Select>
      </FormControl>
      </Box>

     </div>
      <br></br>
      <TableContainer>
      <Table>
          <Thead>
            <Tr>
              <Th style={{ width: "10%" }}>
                  Date
              </Th>
               
              <Th style={{ width: "10%" }}>Kaabil Loan #</Th> 
              <Th style={{ width: "10%" }}>CoLender Ref #</Th> 
              <Th style={{ width: "10%" }}>Customer Name</Th>
              
              <Th style={{ width: "15%" }}>CoLending Stage</Th>
              <Th style={{ width: "10%" }}>Stage Owner</Th>
              
              <Th style={{ width: "5%" }}>Branch Id</Th>
              <Th style={{ width: "10%" }}>Kaabil Sanction Amount</Th>
              <Th style={{ width: "10%" }}>Colender Sanction Amount</Th>
              <Th style={{ width: "10%" }}>Amount Receive Date</Th>
              <Th style={{ width: "10%" }}>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {applicants && applicants.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((application,idx)=>(
              <Tr key={idx}><Td>{application.co_lender_data && datehelper.displayDate(new Date(application.co_lender_data.co_lender_status_date))}</Td>
              <Td>{application.application_id}</Td>
              <Td>{application.co_lender_data && application.co_lender_data.co_lender_account_num}</Td>
    
              <Td>{application.applicant_name}
              </Td>
             
              <Td>{application.co_lender_data && application.co_lender_data.co_lender_status}
               {(application.co_lender_data.co_lender_status!= ColendingSubStages.AmountReceivedFromColender && application.co_lender_data.co_lender_status!= ColendingSubStages.ColenderLoanClosed) && (
                <EditStageModal application={application} colender={colender_id}/>
               )}
              </Td>
              <Td>{application.stage_owners && application.stage_owners.length>0 && (
                <>{application.stage_owners[0].first_name} {application.stage_owners[0].last_name} </>
              ) }
            
              </Td>
              <Td>{application.branch_id}</Td>
              <Td>{application.loan_amount}</Td>
              <Td>{application.co_lender_data.co_lender_sanction_amount}</Td>
              <Td>{application.co_lender_data.co_lender_received_date && datehelper.displayDate(new Date(application.co_lender_data.co_lender_received_date))}</Td>
              
              <Td>
                  <Button component="a"
                  href={`/home/sblloans/details/${application._id}`}
                  size="small"
                  color="primary"
                  >
                  View Details</Button>
                  {roleChecker.isHOLevelAccess(user.role)&&<ColenderDocumentModal colenderPayment={application}/>}
              </Td>
            </Tr>
            ))}
            {(!applicants || spinner) && (
              <Tr>
                <Td colSpan="9" className="text-center">
                  <span className="spinner-border spinner-border-lg align-center"></span>
                </Td>
              </Tr>
            )}
          </Tbody> 
          </Table>
      </TableContainer>
      {applicants ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={applicants.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        <div />
      )}
    </div>
  );
}
