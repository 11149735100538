import React from "react";
import { employeeService } from "../../../_services";
import KycData from "./ApplicationData/KycData";

export default function KycDetails({ cust }) {
  const user = employeeService.userValue;
  console.log(cust);
  return (
    <div>
      <br></br>
      {cust && (
      <KycData customer={cust}></KycData>
      )}

    </div>
  );
}
