import React from "react";
import { useReactToPrint } from "react-to-print";
import { Button, Modal } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Table, Thead, Th, Tr, Td, Tbody } from "react-super-responsive-table";
import { useLocation } from "react-router-dom";
import { Typography } from "@material-ui/core";
import datehelper from "../../../_helpers/datehelper";
import { history, Role } from "../../../_helpers";
import roleChecker from "../../../_helpers/rolechecker";
import { employeeService } from "../../../_services";
const useStyles = makeStyles((theme) => ({
  modal: {
    width: "50%",
    overflow: "scroll",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    minWidth: 650,
  },
}));

export default function InterestCalculatorV2(props) {
  const user =employeeService.userValue
  const location = useLocation();
  const [fcAmount, setFcAmount] = React.useState(0)
  const [loan, setLoan] = React.useState(null);
  const [loanCalculations, setLoanCalculations] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [payDetails, setPayDetails] = React.useState([]);

  const handleOpen = (details) => {
    console.log(details)
    setOpen(true)
    setPayDetails(details)
  }
  const handleClose = () => {
    setOpen(false)
    setPayDetails([])
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
    //paddingBottom: '30px',
  };

  React.useEffect(() => {
    let loan = location.loan;
    //console.log(loan);
    setLoan(loan);
    if (loan) {
      setCalculations(loan);
    }

  }, [location]);

  function setCalculations(loanObj) {
    console.log(loanObj)
    try {
      //num month last emi paid
      let tmpCalc = {};
      // console.log(loanObj);
      if (!loanObj.date)
        return
      let loanDate = new Date(loanObj.date);
      loanDate.setHours(0, 0, 0, 0);
      console.log("loan date is "+loanDate);
      let todayDate = new Date()
      if (loanObj.closure_date) {
        todayDate = new Date(loanObj.closure_date);
      }
      todayDate.setHours(23, 59, 59, 999);
      // console.log(todayDate);
      let dateDiff = todayDate - loanDate;
     
      let differenceDays = parseInt(Math.ceil(dateDiff / (1000 * 3600 * 24)));
      console.log(differenceDays);
      let postTenureThreshold = (30 * loanObj.period);
      let postTenureDays = differenceDays - (30 * loanObj.period);
      tmpCalc['day_age'] = differenceDays;
      tmpCalc['next_emi_due'] = "";
      // console.log("day elapsed="+differenceDays);
      tmpCalc['to_date'] = datehelper.displayDate(new Date(todayDate));

      tmpCalc['from_date'] = datehelper.displayDate(new Date(loanDate));
      
      let monthData={}
      let effectiveRoi=loanObj.roi
      let day_wise_collection={}
      let total_collection=0;
      let total_interest_paid=0;
      let total_interest_due=0;
      let total_prinicipal_due=0;
    
      if (loanObj.payment_details) {
        loanObj.payment_details.map(pi => {
          if (pi.payment_type == "collection") {
            let paymentDate = new Date(Date.parse(pi.payment_date));
            paymentDate.setHours(23, 59, 59, 999);
            let pd_diff_day = parseInt((paymentDate - loanDate) / (1000 * 3600 * 24));
            let pd_day_index=pd_diff_day
            if(pd_day_index<0)
            {
                pd_day_index=0;
            }
            total_collection=total_collection+pi.payment_amount;
           
            if (day_wise_collection[pd_day_index])
            {
              day_wise_collection[pd_day_index]['total_received']=day_wise_collection[pd_day_index]['total_received']+pi.payment_amount;
            }
            else
            {
              day_wise_collection[pd_day_index]={'total_received':pi.payment_amount,'payment_items':[]}
            }
            day_wise_collection[pd_day_index]['payment_items'].push(pi)

          }
        })

      }
      console.log("day wise collection");
      console.log(day_wise_collection);
     
      if(differenceDays<=15)
      {
         console.log("below 15 days case");
         monthData[0]={}
         let pos=monthData[0]['pos_begin']=loanObj.loan_amount
         let interest_due=parseInt(Math.round((15 * pos * effectiveRoi) / 3000))
         let total_received=0
         let prinicipal_paid=0
         let payment_items=[]
         for(let i=0;i<15;i++)
         {
             if(day_wise_collection[i])
             {
               total_received=total_received+day_wise_collection[i]['total_received']
               payment_items=[...day_wise_collection[i]['payment_items'],...payment_items]
             }
         }
         let interest_paid=0
         if(total_received<=interest_due)
         {
           interest_paid=total_received;
         }
         else
         {
           interest_paid=interest_due
           prinicipal_paid=total_received-interest_paid
         }
         total_interest_paid=total_interest_paid+interest_paid;
         let prinicipal_due=pos;
         if(prinicipal_paid>0)
         {
           prinicipal_due=prinicipal_due-prinicipal_paid
         }
         monthData[0]['interest_amount']=interest_due
         if(interest_paid >= interest_due)
         {
          monthData[0]['interest_due']=0
         }
         else
         {
          monthData[0]['interest_due']=interest_due-interest_paid
        
         }
         monthData[0]['interest_paid']=interest_paid
         monthData[0]['principal_paid']=prinicipal_paid
         monthData[0]['pos_end']=prinicipal_due
         monthData[0]['pos_start']=pos
         monthData[0]['effective_roi'] = effectiveRoi
         monthData[0]['amount_received'] = total_received
         monthData[0]['payment_items']=payment_items
         monthData[0]['days']=15
         total_interest_due=monthData[0]['interest_due']
         total_prinicipal_due=prinicipal_due
         console.log(monthData)
      }
      else
      {
         let interest_due=0;
         let lastDueCounterDays=0;
         let pos_start=loanObj.loan_amount;
         let pos_end=loanObj.loan_amount;
         let effectiveRoi=loanObj.roi;
         let month_count=0;
         let m_interest_amount=0;
         let principal_due=0
         let principal_paid=0;
         let m_total_received=0
         let mavg_roi=0;
         let advanceEmi=0;
         let d_received=0
         let payment_items=[]
        
         monthData[0]={}
         monthData[0]['pos_begin']=pos_start
         for(let i=0;i<differenceDays && pos_end>0;i++)
         {
            let day_count=i%30+1
            if(i%30==0)
            {
              month_count=Math.floor(i/30);
              console.log("i=="+i+" month count="+month_count)
              if(month_count>0)
              {

                let prev_month=month_count-1;
                if(loanObj.payment_scheme=="AEMPP")
                {
                  if(advanceEmi>=interest_due)
                  {
                    total_interest_paid=total_interest_paid+interest_due;
                    pos_end=pos_end-(advanceEmi-interest_due)
                    interest_due=0
                    lastDueCounterDays=0
                  }
                  else
                  {
                    total_interest_paid=total_interest_paid+advanceEmi;
                    interest_due=interest_due-advanceEmi
                  }

                }
                monthData[prev_month]={};
                monthData[prev_month]['interest_amount']=Math.round(m_interest_amount)
                monthData[prev_month]['interest_due']=Math.round(interest_due)
                monthData[prev_month]['pos_end']=Math.round(pos_end)
                monthData[prev_month]['pos_start']=Math.round(pos_start)
                monthData[prev_month]['effective_roi'] = mavg_roi
                monthData[prev_month]['amount_received'] = m_total_received
                monthData[prev_month]['payment_items'] = payment_items
                monthData[prev_month]['days']=30
                m_total_received=0
                pos_start=pos_end
                m_interest_amount=0
                mavg_roi=0;
                advanceEmi=0
                total_interest_due=interest_due
                total_prinicipal_due=pos_end
                payment_items=[]
                

              }

            }
               if(i>=postTenureThreshold)
               {
                 effectiveRoi=loanObj.roi+1;
               }
               else
               {
                if(interest_due<=2)
                {
                  effectiveRoi=loanObj.roi;
                  lastDueCounterDays=0;
                 }
                 else
                 {
                   console.log("interest due is "+interest_due);
                   console.log("last due counter days"+lastDueCounterDays);
                   
                  if(lastDueCounterDays <30)
                  {
                     effectiveRoi = loanObj.roi;
                  }
                  else if (lastDueCounterDays < 60 ) {
                    effectiveRoi = loanObj.roi + 0.25
                  }
                  else if (lastDueCounterDays < 180) {
                    effectiveRoi = loanObj.roi + 0.5
                  }
                  else if (lastDueCounterDays >= 180) {
                    effectiveRoi = loanObj.roi + 1
                  }   
                 }
               }
            if(mavg_roi==0)
            {
              mavg_roi=effectiveRoi;
            }
            else
            {
                mavg_roi=(day_count*mavg_roi+effectiveRoi)/(day_count+1);
            }
            interest_due=interest_due+((pos_end * effectiveRoi) / 3000)
            console.log("i=="+i+" interest="+interest_due+" roi=="+effectiveRoi+" pos end=="+pos_end)
            m_interest_amount=m_interest_amount+((pos_end * effectiveRoi) / 3000)
            if(day_wise_collection[i])
            {
              console.log("i="+i+" amount received");
              console.log("received amount "+day_wise_collection[i]['total_received']);
              console.log("interest due "+interest_due)
              m_total_received=m_total_received+day_wise_collection[i]['total_received'];
              payment_items=[...day_wise_collection[i]['payment_items'],...payment_items]
              let d_amount_received=day_wise_collection[i]['total_received'];
              if(d_amount_received>0)
              {
                 if(d_amount_received>=interest_due)
                 {
                    console.log("amount greater than interest");
                    if(loanObj.payment_scheme=="DPP")
                    {
                      pos_end=pos_end-(d_amount_received-interest_due)
                    }
                    if(loanObj.payment_scheme=="AEMPP")
                    {
                      advanceEmi=advanceEmi+(d_amount_received-interest_due)
                    }
                    total_interest_paid=total_interest_paid+interest_due
                    interest_due=0
                   
                    console.log("pos end"+pos_end);
                    
                 }
                 else
                 {
                   console.log("amount less than interest");
                   interest_due=interest_due-d_amount_received;
                   total_interest_paid=total_interest_paid+d_amount_received;   
                 }
              }
              
            }
            else
            {
              console.log("i="+i+" amount NOT received");
            }
            if(interest_due>2)
            {
                 lastDueCounterDays=lastDueCounterDays+1;
            }
            else
            {
                 lastDueCounterDays=0;
            }
           
         }
         let last_counter=differenceDays
         
          let prev_month=Math.ceil(last_counter/30)-1;
          console.log("prev month"+prev_month);
          if(loanObj.payment_scheme=="AEMPP")
          {
            if(advanceEmi>interest_due)
            {
              total_interest_paid=total_interest_paid+interest_due;
              pos_end=pos_end-(advanceEmi-interest_due)
              interest_due=0
            }
            else
            {
              total_interest_paid=total_interest_paid+advanceEmi;
              interest_due=interest_due-advanceEmi
            }

          }
          
          monthData[prev_month]={}
          monthData[prev_month]['interest_amount']=Math.round(m_interest_amount)
          monthData[prev_month]['interest_due']=Math.round(interest_due)
          monthData[prev_month]['pos_end']=Math.round(pos_end)
          monthData[prev_month]['pos_start']=Math.round(pos_start)
          monthData[prev_month]['effective_roi'] = mavg_roi
          monthData[prev_month]['amount_received'] = m_total_received
          monthData[prev_month]['payment_items'] = payment_items
          monthData[prev_month]['days']=differenceDays%30==0?30:differenceDays%30;
          m_total_received=0
          pos_start=pos_end
          m_interest_amount=0
          mavg_roi=0;
          total_interest_due=interest_due
          total_prinicipal_due=pos_end
          payment_items=[]
          if(interest_due>2)
          {
                lastDueCounterDays=0;
          }
      }

      // console.log(tmpCalc);
      tmpCalc['month_data']=monthData
      tmpCalc['total_collection']=total_collection;
      tmpCalc['total_interest_paid']=Math.round(total_interest_paid);
      tmpCalc['total_interest_due']=Math.round(total_interest_due);
      tmpCalc['total_principal_due']=Math.round(total_prinicipal_due);
      tmpCalc['total_due']=parseInt(total_prinicipal_due)+parseInt(total_interest_due);
      tmpCalc['next_emi_due'] = "";
      if (loanObj['loan_status'] != "Closed" && tmpCalc['total_principal_due']>0) {
        // console.log("calculating emi date");
        if (postTenureDays > 0) {
          // console.log("it is post tenure days");
          tmpCalc['next_emi_due'] = datehelper.displayDate(todayDate);
        }
        else {
          let emi_found = false;
          let next_due_date = loanDate;
          while (!emi_found) {
            next_due_date = next_due_date.setDate(next_due_date.getDate() + 29);
             console.log("next Emi due" );
             console.log(new Date(next_due_date));
            let dateDiff = next_due_date - todayDate;
            //  console.log("date diff="+dateDiff);
            if (dateDiff > 0) {
              emi_found = true;
              tmpCalc['next_emi_due'] = datehelper.displayDate(new Date(next_due_date));

            }
            next_due_date = new Date(next_due_date);
          }
        }

        tmpCalc['next_emi_amount'] = parseInt(Math.round((30 * tmpCalc['total_principal_due'] * effectiveRoi) / 3000))

      }

      
      
      
      console.log(tmpCalc);
      setLoanCalculations(tmpCalc);
    }
    catch (exc) {
      console.log(exc)
    }
  }
  const IntrestCalculatorRef = React.useRef();

  const handleSubmissionPrint = useReactToPrint({
    content: () => IntrestCalculatorRef.current,
    pageStyle: "print"
  });

  return (
    <div style={{ textAlign: "justify" }}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <p><b>Payment Details</b></p>
            <Table style={{ width: "90%", margin:'auto' }}>
              <Thead>
                <Tr>
                  <Th style={{width:'40%'}} >Amount</Th>
                  <Th style={{width:'30%'}} >Date</Th>
                  <Th style={{width:'30%'}} >Mode</Th>
                </Tr>
              </Thead>
              <Tbody>
              {
              payDetails.length > 0 && payDetails.map(pay =>
                <Tr>
                  <Td>Rs. {pay.payment_amount}/-</Td>
                  <Td>{pay.payment_date}</Td>
                  <Td>{pay.payment_mode}</Td>
                </Tr>)}
              </Tbody>
            </Table>
          </Typography>
        </Box>
      </Modal>
      {(loan && (
        <>
          <div className="row">
            <div className="col-md-2">
              <button onClick={handleSubmissionPrint}>Print Letter</button>
            </div>
            <div className="col-md-8">
              <h3><b>Interest Calculator for Gold Loan</b></h3>
            </div>

          </div>
          <hr />
          <div ref={IntrestCalculatorRef} className="row justify-content-around">
            <div className="col-md-4">
              <Table>
                <Tbody>
                  <Tr><Td>Loan Account:</Td><Td>{loan.loan_account}</Td></Tr>
                  <Tr><Td>Name:</Td><Td>{loan.name}</Td></Tr>
                  <Tr><Td>Loan Date:</Td><Td>{loanCalculations['from_date']}</Td></Tr>
                  <Tr><Td>Today/Close Date:</Td><Td>{loanCalculations['to_date']}</Td></Tr>
                  <Tr><Td>Loan Days:</Td><Td>{loanCalculations["day_age"]} days</Td></Tr>
                  <Tr><Td>Loan Amount:</Td><Td>Rs {loan.loan_amount}</Td></Tr>
                  <Tr><Td>Roi:</Td><Td>{loan.roi}% pm</Td></Tr>
                  <Tr><Td>Loan Tenure (Months):</Td><Td>{loan.period} Months</Td></Tr>
                  {loanCalculations['next_emi_amount']>0 && (
                    <>
                    <Tr><Td>Next Emi due date:</Td><Td>{loanCalculations['next_emi_due']}</Td></Tr>   
                    <Tr><Td>Next Emi Amount:</Td><Td>{loanCalculations['next_emi_amount']}</Td></Tr>      
                    </>
                  )}
                  <Tr><Td>Total Collection: </Td><Td>{loanCalculations['total_collection']}</Td></Tr>
                  <Tr><Td>Total Interest Paid: </Td><Td>{loanCalculations['total_interest_paid']}</Td></Tr>
                  <Tr><Td>Total Interest Due: </Td><Td>{loanCalculations['total_interest_due']}</Td></Tr>
                  <Tr><Td>Total Principal Due: </Td><Td>{loanCalculations['total_principal_due']>0?loanCalculations['total_principal_due']:"No dues"}</Td></Tr>
                  <Tr><Td>Total Due: </Td><Td>{loanCalculations['total_due']>0?loanCalculations['total_due']:"No Dues"}</Td></Tr>
                  <Tr><Td>Repayment Scheme: </Td><Td>{loan.payment_scheme}</Td></Tr>
                 
                </Tbody>
              </Table>
            </div>
            <div className="col-md-8">
              <p><b>Monthwise Interest Calculation:</b></p>
              <Table style={{ width: "80%" }}>
                <Thead>
                  <Tr><Th>Month/Days
                  </Th><Th>Principal Outstanding Start</Th><Th>Effective ROI</Th><Th>Interest Amount</Th><Th>Amount Received</Th><Th>Interest Due</Th><Th>Principal Due</Th></Tr>
                </Thead>
                <Tbody>
                  {loanCalculations && Object.keys(loanCalculations['month_data']).map((m, idx) => {
                    console.log(loanCalculations)
                    return (loanCalculations['month_data'][m] && (<Tr kry={idx}>
                      <Td>Month #{(parseInt(m)+1)} / {loanCalculations['month_data'][m]['days']} days</Td>
                      <Td>{loanCalculations['month_data'][m]['pos_start']}</Td>
                      <Td>{parseFloat(loanCalculations['month_data'][m]['effective_roi']?.toFixed(2))}%</Td>
                      <Td>Rs {loanCalculations['month_data'][m]['interest_amount']}</Td>
                      <Td><button style={{border:'none',color:'blue'}} onClick={() => handleOpen(loanCalculations['month_data'][m]['payment_items'])} >Rs {loanCalculations['month_data'][m]['amount_received']}</button></Td>
                      <Td>{loanCalculations['month_data'][m]['interest_due']}</Td>
                      <Td>{loanCalculations['month_data'][m]['pos_end']>0?loanCalculations['month_data'][m]['pos_end']:"No Dues"}</Td>
                      

                    </Tr>))
                  })
                  }
                </Tbody>
              </Table>
            </div>
          </div>
          <div style={{display:'flex'}}>
         { location.loan.loan_status !== "Closed" && !roleChecker.hasAccountsAccess(user.role) && <div style={{ marginTop: '50px', marginLeft: "100px" }}>
            <Button style={{ color: "white", background: 'red'}}
              onClick={() => history.push({ pathname: `/home/goldloans/closeloan`, loan: loan, loanCalculations: loanCalculations })
              }>
                Loan Close Request
             </Button>
          </div>
          }
            <div style={{ marginTop: '50px', marginLeft: "100px" }}>
            {roleChecker.isBranchLevelAccess(user.role) && (location.loan.loan_status==='Disbursed'||location.loan.loan_status==='Suspense')&& <Button style={{ color: "white", background: 'green'}}
              onClick={() => history.push({ pathname: `/home/goldloans/loanrenewform`, loan: loan, loanCalculations: loanCalculations })
              }>
                Renew Loan
             </Button>}
          </div>
         </div> 
          <br/>
          <span>Calculator Version: 2.2</span>
        </>
      ))}
    </div>
  );
}
