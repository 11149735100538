import React from "react";
import logo from "../../assets/kaabil.png";
import "../GoldLoan/pledgeletter.css";

export default class SanctionLetter extends React.Component {
  render() {
    const data = this.props.dataFill;
    console.log(data);

    return (
      <div className="pledge">
        <style type="text/css" media="print">
          {"\
   @page { size: A4; }\
"}
        </style>
        <br />
        <div
          style={{
            width: "290mm",
            height: "400mm",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: "white",
            padding: 40,
          }}
          className="d-flex flex-column"
        >
          <div>
            <div style={{ textAlign: "center" }}>
              <img style={{ width: 350, height: 100 }} src={logo}></img>
            </div>
          </div>
          <hr />
          <h5 style={{ textAlign: "center" }}>
            <p>CREDIT ARRANGEMENT LETTER</p>
          </h5>
          <p style={{ textAlign: "center" }}>
            KFPL/GL/{data.branch_name}/2020-2021
          </p>
          <p style={{ width: "100%" }}>Date: {data.date.split("T")[0]}</p>
          <p>
            {data.name} S/O {data.sonof}
          </p>
          <p>Address - {data.address}</p>
          <h6 style={{ textAlign: "center" }}>
            <b>{data.name}</b>
          </h6>
          <p>Dear Sir/Madam,</p>
          <p>
            <b>
              Sub: Offer letter for loan facility vide Application No.{" "}
              {data.loan_account}
            </b>
          </p>
          <p>
            Thank you for choosing Kaabil Finance Private Limited “KFPL” for
            Gold loan. We are pleased to inform you that with reference to the
            above application, we have in-principle sanctioned you a loan
            facility, the details of which are given below.
          </p>
          <div>
            <br />
            <table style={{ width: "100%" }}>
              <tr>
                <td>Lender</td>
                <td>Kaabil Finance Private Limited </td>
              </tr>
              <tr>
                <td>Type of Loan</td>
                <td>Gold Loan</td>
              </tr>
              <tr>
                <td>Loan Amount Sanctioned</td>
                <td>Rs.{data.loan_amount}/-</td>
              </tr>
              <tr>
                <td>Term of Loan/tenure</td>
                <td>{data.period} Months</td>
              </tr>
              <tr>
                <td>Interest Rate applicable and type</td>
                <td>Interest @ {data.roi} % p.a. & Floating</td>
              </tr>
              <tr>
                <td>Amount of each installment/Interest</td>
                <td>Rs.{data.emi}/- Per Month</td>
              </tr>
              <tr>
                <td>Processing Fees</td>
                <td>Rs.{data.pf}/-</td>
              </tr>
              <tr>
                <td>Security</td>
                <td>As Per Annexure-A</td>
              </tr>
              <tr>
                <td>Mode of Repayment</td>
                <td>ECS/NACH/Cheque/Cash</td>
              </tr>
            </table>
          </div>
          <p>We look forward to a long lasting relationship with you.</p>
          <p>
            Thanking You,
            <br />
            Yours Sincerely,
          </p>
          <br />
          <br />
          <p>
            Authorized Signatory
            <br />
            (On behalf of Kaabil Finance Pvt. Ltd)
          </p>
          <p>I / We accept the above terms and conditions</p>
          <table>
            <tr>
              <th>Borrower</th>
              <th>Name</th>
              <th>Sign</th>
            </tr>
            <tr>
              <td>Applicant</td>
              <td>{data.name}</td>
              <td></td>
            </tr>
          </table>
        </div>
      </div>
    );
  }
}
