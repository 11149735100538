import React from "react";
import { useState, useEffect } from "react";
import roleChecker from "../../_helpers/rolechecker";
import { employeeService, misService } from "../../_services";
import { BarChart, PieChart, Pie, Cell, Bar, CartesianGrid, XAxis, YAxis, Legend, Tooltip, LabelList, ResponsiveContainer, Label } from "recharts";
import { reportsService } from "../../_services/reports.service";
import Product from "../../_helpers/product";
import { loanService } from "../../_services";
import { Button } from "@material-ui/core";
import { history } from "../../_helpers";
import { Paper } from "@material-ui/core";
import { makerCheckerServices } from "../../_services/makerchecker.service";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import CourtCaseServices from "../../_services/courtcase.service";
import datehelper from "../../_helpers/datehelper";
import { ledgerService } from "../../_services/ledger.service";



function LegalDashboard(){
  const user = employeeService.userValue;
  const [ni138, setNi138] = useState([])
  const [arbitration, setArbitration] = useState([])
  const [execution,setExecution]=useState([])
  const [upcomingHearings,setUpcomingHearings]=useState([])
  let date = new Date()
  const currentDate = new Date();
  const today_date = new Date();
  const getUpcomingHearings=async()=>{
    await CourtCaseServices.getUpcomingHearings()
    .then((res)=>setUpcomingHearings(res))
    .catch((err) => console.log(err))     
  }
  useEffect(() => {
    getUpcomingHearings();
  }, []);  
  useEffect(() => {
    upcomingHearings.sort((a, b) => {
      const lastHearingA = a.hearings[a.hearings.length - 1];
      const lastHearingB = b.hearings[b.hearings.length - 1];
      const dateA = new Date(lastHearingA.hearing_date);
      const dateB = new Date(lastHearingB.hearing_date);
      return dateA - dateB;
    })
    setNi138(upcomingHearings.filter((x) => x.case_type === "ni138"));
    setArbitration(upcomingHearings.filter((x) => x.case_type === "arbitration"));
    setExecution(upcomingHearings.filter((x) => x.case_type === "execution"));
  }, [upcomingHearings]);

  console.log("Test upcomming hearing",upcomingHearings)
        return <>
             {  <div>
            <br />
            <br />
            <div>
              {ni138.length > 0 &&
                <div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'blue', height: '50px', borderRadius: '4px' }} >
                    <h4 style={{ color: 'white' }}>Upcomming Events of NI-138</h4>
                  </div>
                  <Table>
                    <thead style={{ background: 'blue', color: 'white' }}>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Sr.NO</Th>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Loan No</Th>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Party Name</Th>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Previous Hearing Date</Th>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Advocate Assigned</Th>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Judgement</Th>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Next Hearing Date</Th>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Next Hearing Purpose</Th>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Court Name</Th>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Jurisdiction Name</Th>
                    </thead>
                    <Tbody>
                      {ni138.slice(0, 5).map((x, idx) =>
                        <Tr key={idx}>
                          <Td style={{ border: "1px solid " }}>{idx + 1}</Td>
                          <Td style={{ border: "1px solid " }}>{x.loan_no}</Td>
                          <Td style={{ border: "1px solid " }}>{x.party_name}</Td>
                          {
                            x.hearings.length >=2? <>
                              <Td style={{ border: "1px solid " }}>{datehelper.displayDate(new Date(x.hearings[x.hearings.length-2].hearing_date))}</Td>
                              <Td style={{ border: "1px solid " }}>{x.hearings[x.hearings.length - 2].lawyer_name}</Td>
                              <Td style={{ border: "1px solid " }}>{x.hearings[x.hearings.length - 2].judgement}</Td>
                            </> : <>
                              <Td style={{ border: "1px solid " }}>----</Td>
                              <Td style={{ border: "1px solid " }}>----</Td>
                              <Td style={{ border: "1px solid " }}>----</Td>
                            </>
                          }
                          <Td style={{ border: "1px solid " }}>{datehelper.displayDate(new Date(x.hearings[x.hearings.length-1].hearing_date))}</Td>
                          <Td style={{ border: "1px solid " }}>{x.hearings[x.hearings.length-1].judgement}</Td>
                          <Td style={{ border: "1px solid " }}>{x.court_name}</Td>
                          <Td style={{ border: "1px solid " }}>{x.jurisdiction_name}</Td>
                        </Tr>)
                      }
                    </Tbody>
                  </Table>
                  <Button size="small" style={{ float: 'right', fontSize: '15px' }} onClick={() => history.push('/home/letters/upcommingevent', { ni138: ni138 })}>...See more</Button>
                </div>
              }
            </div>
            <br />
            <div>
              <br />
              <br />
              {arbitration.length > 0 &&
                <div>
                  <div style={{ display: 'flex', alignItems: 'center', background: 'blue', height: '50px', justifyContent: 'center', borderRadius: '4px' }}>
                    <h4 style={{ color: 'white' }}>Upcomming Events of Arbitration</h4>
                  </div>
                  <Table>
                    <thead style={{ background: 'blue', color: 'white' }}>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Sr.NO</Th>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Loan No</Th>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Party Name</Th>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Previous Hearing Date</Th>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Advocate Assigned</Th>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Judgement</Th>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Next Hearing Date</Th>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Next Hearing Purpose</Th>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Court Name</Th>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Jurisdiction Name</Th>
                    </thead>
                    <Tbody>
                      {arbitration.slice(0, 5).map((x, idx) =>
                        <Tr key={idx}>
                          <Td style={{ border: "1px solid " }}>{idx + 1}</Td>
                          <Td style={{ border: "1px solid " }}>{x.loan_no}</Td>
                          <Td style={{ border: "1px solid " }}>{x.party_name}</Td>
                          {
                            x.hearings.length >=2? <>
                              <Td style={{ border: "1px solid " }}>{datehelper.displayDate(new Date(x.hearings[x.hearings.length-2].hearing_date))}</Td>
                              <Td style={{ border: "1px solid " }}>{x.hearings[x.hearings.length - 2].lawyer_name}</Td>
                              <Td style={{ border: "1px solid " }}>{x.hearings[x.hearings.length-2].judgement}</Td>
                            </> : <>
                              <Td style={{ border: "1px solid " }}>----</Td>
                              <Td style={{ border: "1px solid " }}>----</Td>
                              <Td style={{ border: "1px solid " }}>----</Td>
                            </>
                          }
                          <Td style={{ border: "1px solid " }}>{datehelper.displayDate(new Date(x.hearings[x.hearings.length-1].hearing_date))}</Td>
                          <Td style={{ border: "1px solid " }}>{x.hearings[x.hearings.length-1].judgement}</Td>
                          <Td style={{ border: "1px solid " }}>{x.court_name}</Td>
                          <Td style={{ border: "1px solid " }}>{x.jurisdiction_name}</Td>
                        </Tr>)
                      }
                    </Tbody>
                  </Table>
                  <Button size="small" style={{ float: 'right', fontSize: '17px' }} onClick={() => history.push('/home/letters/upcommingevent', { arbitration: arbitration })}>...See more</Button>
                </div>
              }
            </div>
            <br/>
            <div>
              <br />
              <br />
              {execution.length > 0 &&
                <div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'blue', height: '50px', borderRadius: '4px' }} >
                    <h4 style={{ color: 'white' }}>Upcomming Events of Execution</h4>
                  </div>
                  <Table>
                    <thead style={{ background: 'blue', color: 'white' }}>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Sr.NO</Th>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Loan No</Th>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Party Name</Th>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Previous Hearing Date</Th>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Advocate Assigned</Th>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Judgement</Th>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Next Hearing Date</Th>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Next Hearing Purpose</Th>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Court Name</Th>
                      <Th style={{ border: "1px solid ", textAlign: 'center' }}>Jurisdiction Name</Th>
                    </thead>
                    <Tbody>
                      {execution.slice(0, 5).map((x, idx) =>
                        <Tr key={idx}>
                          <Td style={{ border: "1px solid " }}>{idx + 1}</Td>
                          <Td style={{ border: "1px solid " }}>{x.loan_no}</Td>
                          <Td style={{ border: "1px solid " }}>{x.party_name}</Td>
                          {
                            x.hearings.length >=2? <>
                              <Td style={{ border: "1px solid " }}>{datehelper.displayDate(new Date(x.hearings[x.hearings.length-2].hearing_date))}</Td>
                              <Td style={{ border: "1px solid " }}>{x.hearings[x.hearings.length - 2].lawyer_name}</Td>
                              <Td style={{ border: "1px solid " }}>{x.hearings[x.hearings.length - 2].judgement}</Td>
                            </> : <>
                              <Td style={{ border: "1px solid " }}>----</Td>
                              <Td style={{ border: "1px solid " }}>----</Td>
                              <Td style={{ border: "1px solid " }}>----</Td>
                            </>
                          }
                          <Td style={{ border: "1px solid " }}>{datehelper.displayDate(new Date(x.hearings[x.hearings.length-1].hearing_date))}</Td>
                          <Td style={{ border: "1px solid " }}>{x.hearings[x.hearings.length-1].judgement}</Td>
                          <Td style={{ border: "1px solid " }}>{x.court_name}</Td>
                          <Td style={{ border: "1px solid " }}>{x.jurisdiction_name}</Td>
                        </Tr>)
                      }
                    </Tbody>
                  </Table>
                  <Button size="small" style={{ float: 'right', fontSize: '15px' }} onClick={() => history.push('/home/letters/upcommingevent', { execution: execution })}>...See more</Button>
                </div>
              }
            </div>
          </div>}
        </>
}
export default LegalDashboard;


// function LegalDashboard(){
//   const user = employeeService.userValue;
//   const [ni138, setNi138] = useState([])
//   const [arbitration, setArbitration] = useState([])
//   let date = new Date()
//   const currentDate = new Date();
//   const today_date = new Date();
//   const sortedNi138 = ni138.sort((a, b) => {
//     const aNextHearing = a.next_hearing.find(({ hearing_date }) => new Date(hearing_date) >= new Date(today_date.getFullYear(), today_date.getMonth(), today_date.getDate()));
//     const bNextHearing = b.next_hearing.find(({ hearing_date }) => new Date(hearing_date) >= new Date(today_date.getFullYear(), today_date.getMonth(), today_date.getDate()));
//     return aNextHearing && bNextHearing ? new Date(aNextHearing.hearing_date) - new Date(bNextHearing.hearing_date) : aNextHearing ? -1 : bNextHearing ? 1 : 0;
//   });

//   const sortedarbitration = arbitration.sort((a, b) => {
//     const aNextStage = a.next_stage.find(({ date }) => new Date(date) >= new Date(today_date.getFullYear(), today_date.getMonth(), today_date.getDate()));
//     const bNextStage = b.next_stage.find(({ date }) => new Date(date) >= new Date(today_date.getFullYear(), today_date.getMonth(), today_date.getDate()));
//     return aNextStage && bNextStage ? new Date(aNextStage.date) - new Date(bNextStage.date) : aNextStage ? -1 : bNextStage ? 1 : 0;
//   });

//   const getupcomingNiCases = async () => {
//     await CourtCaseServices.getupcomingNiCases()
//       .then((res) => setNi138(res))
//       .catch((err) => console.log(err))
//   }
//   const getupcomingArbitrationCases = async () => {
//     await CourtCaseServices.getupcomingArbitrationCases()
//       .then((res) => setArbitration(res))
//       .catch((err) => console.log(err))
//   }
//   useEffect(() => {
//     getupcomingNiCases()
//     getupcomingArbitrationCases()
//   }, [])


//         return <>
//              {  <div>
//             <br />
//             <br />
//             <div>
//               {ni138.length > 0 &&
//                 <div>
//                   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'blue', height: '50px', borderRadius: '4px' }} >
//                     <h4 style={{ color: 'white' }}>Upcomming Events of NI-138</h4>
//                   </div>
//                   <Table>
//                     <thead style={{ background: 'blue', color: 'white' }}>
//                       <Th style={{ border: "1px solid ", textAlign: 'center' }}>Sr.NO</Th>
//                       <Th style={{ border: "1px solid ", textAlign: 'center' }}>Loan No</Th>
//                       <Th style={{ border: "1px solid ", textAlign: 'center' }}>Party Name</Th>
//                       <Th style={{ border: "1px solid ", textAlign: 'center' }}>Previous Hearing Date</Th>
//                       <Th style={{ border: "1px solid ", textAlign: 'center' }}>Advocate Assigned</Th>
//                       <Th style={{ border: "1px solid ", textAlign: 'center' }}>Judgement</Th>
//                       <Th style={{ border: "1px solid ", textAlign: 'center' }}>Next Hearing Date</Th>
//                       <Th style={{ border: "1px solid ", textAlign: 'center' }}>Next Hearing Purpose</Th>
//                       <Th style={{ border: "1px solid ", textAlign: 'center' }}>Court Name</Th>
//                       <Th style={{ border: "1px solid ", textAlign: 'center' }}>Jurisdiction Name</Th>
//                     </thead>
//                     <Tbody>
//                       {sortedNi138.slice(0, 5).map((x, idx) =>
//                         <Tr key={idx}>
//                           <Td style={{ border: "1px solid " }}>{idx + 1}</Td>
//                           <Td style={{ border: "1px solid " }}>{x.loan_no}</Td>
//                           <Td style={{ border: "1px solid " }}>{x.party_name}</Td>
//                           {
//                             x.hearings.length > 0 ? <>
//                               <Td style={{ border: "1px solid " }}>{datehelper.displayDate(new Date(x.hearings[x.hearings.length - 1].hearing_date))}</Td>
//                               <Td style={{ border: "1px solid " }}>{x.hearings[x.hearings.length - 1].lawyer_name}</Td>
//                               <Td style={{ border: "1px solid " }}>{x.hearings[x.hearings.length - 1].judgement}</Td>
//                             </> : <>
//                               <Td style={{ border: "1px solid " }}>----</Td>
//                               <Td style={{ border: "1px solid " }}>----</Td>
//                               <Td style={{ border: "1px solid " }}>----</Td>
//                             </>
//                           }
//                           {x.next_hearing.filter((nextHearing) => new Date(nextHearing.hearing_date) >= new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())).length > 0 ?
//                             (
//                               <>
//                                 <Td style={{ border: "1px solid " }}>
//                                   {new Date(x.next_hearing.filter((nextHearing) => new Date(nextHearing.hearing_date) >= new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()))[0].hearing_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/\//g, '-')}
//                                 </Td>
//                                 <Td style={{ border: "1px solid " }}>
//                                   {x.next_hearing.filter((nextHearing) => new Date(nextHearing.hearing_date) >= new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()))[0].task}
//                                 </Td>
//                               </>
//                             ) :
//                             (
//                               <>
//                                 <Td style={{ border: "1px solid " }}>---</Td>
//                                 <Td style={{ border: "1px solid " }}>---</Td>
//                               </>
//                             )
//                           }
//                           <Td style={{ border: "1px solid " }}>{x.court_name}</Td>
//                           <Td style={{ border: "1px solid " }}>{x.jurisdiction_name}</Td>
//                         </Tr>)
//                       }
//                     </Tbody>
//                   </Table>
//                   <Button size="small" style={{ float: 'right', fontSize: '15px' }} onClick={() => history.push('/home/letters/upcommingevent', { ni138: ni138 })}>...See more</Button>
//                 </div>
//               }
//             </div>
//             <br />
//             <div>
//               <br />
//               <br />
//               {arbitration.length > 0 &&
//                 <div>
//                   <div style={{ display: 'flex', alignItems: 'center', background: 'blue', height: '50px', justifyContent: 'center', borderRadius: '4px' }}>
//                     <h4 style={{ color: 'white' }}>Upcomming Events of Arbitration</h4>
//                   </div>
//                   <Table>
//                     <thead style={{ background: 'blue', color: 'white' }}>
//                       <Th style={{ border: "1px solid ", textAlign: 'center' }}>Sr.NO</Th>
//                       <Th style={{ border: "1px solid ", textAlign: 'center' }}>Loan No</Th>
//                       <Th style={{ border: "1px solid ", textAlign: 'center' }}>Party Name</Th>
//                       <Th style={{ border: "1px solid ", textAlign: 'center' }}>Previous Stage Date</Th>
//                       <Th style={{ border: "1px solid ", textAlign: 'center' }}>Previous Stage</Th>
//                       <Th style={{ border: "1px solid ", textAlign: 'center' }}>Next Stage Date</Th>
//                       <Th style={{ border: "1px solid ", textAlign: 'center' }}>Next Stgae</Th>
//                       <Th style={{ border: "1px solid ", textAlign: 'center' }}>Court Name</Th>
//                       <Th style={{ border: "1px solid ", textAlign: 'center' }}>Jurisdiction Name</Th>
//                     </thead>
//                     <Tbody>
//                       {sortedarbitration.slice(0, 5).map((x, idx) =>
//                         <Tr key={idx}>
//                           <Td style={{ border: "1px solid " }}>{idx + 1}</Td>
//                           <Td style={{ border: "1px solid " }}>{x.loan_no}</Td>
//                           <Td style={{ border: "1px solid " }}>{x.party_name}</Td>
//                           {
//                             x.stages.length > 0 ? <>
//                               <Td style={{ border: "1px solid " }}>{datehelper.displayDate(new Date(x.stages[x.stages.length - 1].date))}</Td>
//                               <Td style={{ border: "1px solid " }}>{x.stages[x.stages.length - 1].stage}</Td>
//                             </> : <>
//                               <Td style={{ border: "1px solid " }}>----</Td>
//                               <Td style={{ border: "1px solid " }}>----</Td>
//                             </>
//                           }
//                           {x.next_stage.filter((nextStage) => new Date(nextStage.date) >= new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())).length > 0 ?
//                             (
//                               <>
//                                 <Td style={{ border: "1px solid " }}>
//                                   {new Date(x.next_stage.filter((nextStage) => new Date(nextStage.date) >= new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()))[0].date).toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/\//g, '-')}
//                                 </Td>
//                                 <Td style={{ border: "1px solid " }}>
//                                   {x.next_stage.filter((nextStage) => new Date(nextStage.date) >= new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()))[0].stage}
//                                 </Td>
//                               </>
//                             ) :
//                             (
//                               <>
//                                 <Td style={{ border: "1px solid " }}>---</Td>
//                                 <Td style={{ border: "1px solid " }}>---</Td>
//                               </>
//                             )
//                           }
//                           <Td style={{ border: "1px solid " }}>{x.court_name}</Td>
//                           <Td style={{ border: "1px solid " }}>{x.jurisdiction_name}</Td>
//                         </Tr>)
//                       }
//                     </Tbody>
//                   </Table>
//                   <Button size="small" style={{ float: 'right', fontSize: '17px' }} onClick={() => history.push('/home/letters/upcommingevent', { arbitration: arbitration })}>...See more</Button>
//                 </div>
//               }
//             </div>
//           </div>}
//         </>
// }

// export default LegalDashboard;