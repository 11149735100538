import { ToastContainer, toast } from 'react-toastify';

export  const notify = (message) => toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    style: {
        background: 'green',
        color: '#fff',
        fontSize: '20px',
        width: '30vw',
        margin:'2rem',
        padding: '20px 30px',
    }
});
export  const notifyFailed = (message) => toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    style: {
        background: 'red',
        color: '#fff',
        fontSize: '20px',
        width: '30vw',
        margin:'2rem',
        padding: '20px 30px',
    }
});
export const notifyInfo = (message)=>{
    notify(message)
    setTimeout(() => {
        window.location.reload()
    }, 2000);
}
