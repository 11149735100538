import { BehaviorSubject } from "rxjs";
import { fetchWrapper, history } from "../_helpers";

const apiUrl = process.env.REACT_APP_API_URL;

// const userSubject = new BehaviorSubject(null);

const userSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("user"))
);
const productSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("product"))
);



const baseUrl = `${apiUrl}/employees`;

export const employeeService = {
  login,
  logout,
  setProduct,
  refreshToken,
  register,
  verifyEmail,
  forgotPassword,
  validateResetToken,
  resetPasswordbyOtp,
  getAll,
  getById,
  create,
  update,
  delete: _delete,
  updatePassword,
  getBranchAll,
  checkDate,
  unblock,
  resetPassword,
  updatePasswordOnForgotPassword,
  apistate:true,
  user: userSubject.asObservable(),
  product: productSubject.asObservable(),
  get userValue() {
    return userSubject.value;
  },
  get productValue()
  {
    return productSubject.value;
  }
 
};


function setProduct(product) {
  localStorage.setItem("product", JSON.stringify(product));
  productSubject.next(product)
  history.push("/employee/login");
}

function login(empid, password) {
  return fetchWrapper
    .post(`${baseUrl}/authenticate`, { emp_id: empid, password, source: "Web", version:0 })
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("user", JSON.stringify(user));
      // console.log(user);
      // publish user to subscribers and start timer to refresh token
      userSubject.next(user);
      // startRefreshTokenTimer();
      return user;
    });
}

function logout() {
  // revoke token, stop refresh timer, publish null to user subscribers and redirect to login page
  fetchWrapper.post(`${baseUrl}/revoke-token`, {});
  stopRefreshTokenTimer();
  localStorage.removeItem("user");
  localStorage.removeItem("product");
  userSubject.next(null);
  productSubject.next(null);
  history.push("/employee/login");
}

function refreshToken() {
  return fetchWrapper.post(`${baseUrl}/refresh-token`, {}).then((user) => {
    // publish user to subscribers and start timer to refresh token
    userSubject.next(user);
    startRefreshTokenTimer();
    return user;
  });
}

function register(params) {
  return fetchWrapper.post(`${baseUrl}/register`, params);
}

function verifyEmail(token) {
  return fetchWrapper.post(`${baseUrl}/verify-email`, { token });
}

function forgotPassword(email) {
  return fetchWrapper.post(`${baseUrl}/forgot-password`, { email });
}
function resetPasswordbyOtp(id) {
  // console.log('id is',id)
  return fetchWrapper.put(`${baseUrl}/resetpasswordbyotp/${id}`);
}

function validateResetToken(token) {
  return fetchWrapper.post(`${baseUrl}/validate-reset-token`, { token });
}

function resetPassword({ token, password, confirmPassword }) {
  return fetchWrapper.post(`${baseUrl}/reset-password`, {
    token,
    password,
    confirmPassword,
  });
}

function getAll() {
  return fetchWrapper.get(baseUrl);
}

function getById(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`);
}


function getBranchAll(branchId) {
  return fetchWrapper.get(`${baseUrl}/getbranchall/${branchId}`);
}

function create(params) {
  return fetchWrapper.post(baseUrl, params);
}

function updatePassword(params){
  return fetchWrapper.put(`${baseUrl}/update/password`, params)
}
function updatePasswordOnForgotPassword(id,params){
  // console.log('employservice ',id,params)
  return fetchWrapper.put(`${baseUrl}/updatepasswordonforgotpassword/${id}`, params)
}

function update(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}`, params).then((user) => {
    // update stored user if the logged in user updated their own record
    if (user.id === userSubject.value.id) {
      // publish updated user to subscribers
      user = { ...userSubject.value, ...user };
      userSubject.next(user);
    }
    return user;
  });
}

// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`).then((x) => {
    // auto logout if the logged in user deleted their own record
    // if (id === userSubject.value.id) {
    //   logout();
    // }
    return x;
  });
}

// helper functions

let refreshTokenTimeout;

function startRefreshTokenTimer() {
  // parse json object from base64 encoded jwt token
  const jwtToken = JSON.parse(atob(userSubject.value.jwtToken.split(".")[1]));

  // set a timeout to refresh the token a minute before it expires
  const expires = new Date(jwtToken.exp * 1000);
  const timeout = expires.getTime() - Date.now() - 60 * 1000;
  refreshTokenTimeout = setTimeout(refreshToken, timeout);
}

function stopRefreshTokenTimer() {
  clearTimeout(refreshTokenTimeout);
}

function checkDate(params) {
  // console.log(params)
  return fetchWrapper.post(`${baseUrl}/date`, params)
}

function unblock(id) {
  return fetchWrapper.put(`${baseUrl}/unblock/${id}`);
}
