import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Select, MenuItem, Button, TextField } from "@material-ui/core";
import { employeeService, loanService } from '../../../../src/_services';
import { ApprovalRequestFilter } from "../../../../src/_components/MakerCheckerFramework/ApprovalRequestFilter";
import swal from 'sweetalert';

function AddCheckList() {
    const [bucketModalVisible, setBucketModalVisible] = useState(false);
    const [docname, setDocname] = useState('');
    const [selectedBucket, setSelectedBucket] = useState('');
    const [documents, setDocuments] = useState([]);
    const [employee, setEmployee] = useState([]);

    const user = employeeService.userValue;
    const emp_id = user.emp_id;

    const handleBucketSelect = (e) => {
        setSelectedBucket(e.target.value);
    };

    const handleDocnameChange = (e) => {
        setDocname(e.target.value);
    };

    const handleBucketModalSubmit = () => {
        const requestData = {
            docname: docname,
            selectedBucket: selectedBucket,
            emp_id: emp_id
        };

        loanService.saveDocumentCheckList(requestData)
            .then(data => {
                console.log('call saveDocumentCheckList response:', data);
                alert(data.message);
                if (data && data.status === "success") {
                    setBucketModalVisible(false);
                    listedDocumentCheckList();
                    setSelectedBucket()
                    setDocname()
                } else if (data && data.status === "error") {
                    alert(data.message);
                }
            })
            .catch(error => {
                console.error('Error saving document checklist:', error);
                alert(error.message);
            });
    };

    const listedDocumentCheckList = () => {
        loanService.getDocumentCheckList()
            .then(data => {
                setDocuments(data);
                console.log('call getDocumentCheckList response:', data);
                if (data && data.status === "success") {
                    // Use data.data to set documents
                } else if (data && data.status === "error") {
                    alert(data.message);
                }
            })
            .catch(error => {
                console.error('Error getting document checklist:', error);
                alert(error.message);
            });
    };

    useEffect(() => {
        listedDocumentCheckList();
    }, []);

    console.log('filteredDocuments::', documents);
    
    const styles = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        height: 300,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleOpenBucketModal = () => {
        setBucketModalVisible(true);
    };

    const handleClose = () => {
        setBucketModalVisible(false);
    };

    return (
        <div>
            <div className="top-row">
                <button className="bucket-button" onClick={handleOpenBucketModal}>Add New Document in Check List</button>
            </div>
            <Modal
                open={bucketModalVisible}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styles}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ marginBottom: '20px' }}>
                        Enter Document Name <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <form>
                        <TextField
                            style={{ width: '100%', marginBottom: '10px' }}
                            placeholder="Enter Document Name"
                            type="text"
                            value={docname}
                            onChange={handleDocnameChange}
                        />
                    </form>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ marginBottom: '10px' }}>
                        Select Bucket <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedBucket}
                        onChange={handleBucketSelect}
                        style={{ width: '100%', marginBottom: '20px' }}
                    >
                        <MenuItem value="credit_bucket">Credit Bucket</MenuItem>
                        <MenuItem value="operation_bucket">Operation Bucket</MenuItem>
                        <MenuItem value="accounts_bucket">Accounts Bucket</MenuItem>
                        <MenuItem value="other_bucket">Other Bucket</MenuItem>
                    </Select>
                    <div className="modal-actions">
                        <Button onClick={handleBucketModalSubmit} variant="contained" color="primary" style={{ marginRight: '10px' }}>Save</Button>
                    </div>
                </Box>
            </Modal>

            {/* Table to display documents */}
            <table>
                <thead>
                    <tr>
                        <th>Document Name</th>
                        <th>Bucket Name</th>
                        <th>Added By</th>
                    </tr>
                </thead>
                <tbody>
                    {documents.map((doc, index) => (
                        <tr key={index}>
                            <td>{doc.document_name}</td>
                            <td>{doc.document_bucket_name}</td>
                            <td>{doc.document_added_by}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default AddCheckList;
