import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Grid, Card, Button, CardActions } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import "../../css/common.css"
import datehelper from "../../_helpers/datehelper";
import { loanService } from "../../_services";

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "75%",
    overflow: "scroll",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    minWidth: 700,
  },
}));

export default function SBLRepaymentScheduleModal(props) {
  const customer= props.customer;
  const repayment_schedule=customer.loan.repayment_schedule
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);


  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  async function generateRepaymentSchedule(loanId){
    console.log("generating repayment schedule for "+loanId);
    loanService.generateRepaymentSchedule(loanId).then(()=> {
       window.alert("Repayment schedule generated");
       window.location.reload();
    })
  }

  return (
    <div>
       
     <Button
        onClick={handleOpen}
        variant="outlined"
        color="primary"
      >
        Repayment Schedule
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
        <div className={classes.paper}>
          <h2>Loan Number - {customer.application_id}  Name: {customer.applicant.general_information.name}</h2>
      <table class="btable stretch">
              <tr>
                <th>Payment Number</th>
                <th>Date</th>
                <th>Start Balance</th>
                <th>Payment Amount</th>
                <th>Capital Paid</th>
                <th>Interest Paid</th>
                <th>Remaining Balance</th>
              </tr>
              {repayment_schedule.map(
                (item, idx) => (
                    <tr>
                      <td style={{ width: "10%" }}>{idx + 1}</td>{" "}
                      <td>
                        {datehelper.displayDate(new Date(item.due_date))}
                      </td>
                      <td>{Math.round(item.start_balance)}</td>
                      <td>{Math.round(item.payment_amount)}</td>
                      <td>{Math.round(item.capital_paid)}</td>
                      <td>{Math.round(item.interest_paid)}</td>
                      <td>{Math.round(item.balance_remaining)}</td>
                    </tr>
                  )
              )}
            </table>
            <Button
                     variant="contained"
                     color="primary"
                     onClick={()=> generateRepaymentSchedule(customer._id)}>Re-Generate Schedule</Button>
            </div>
            </Fade>
            </Modal>
    </div>
  );
}

