import React, { useState, useEffect } from 'react';
import { loanService } from '../../_services';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { CircularProgress } from '@material-ui/core'

const FundUsages = () => {
    const [funds, setFunds] = useState([]);
    const [filteredFunds, setFilteredFunds] = useState([]);
    const [selectedValue, setSelectedValue] = useState("All");
    const [show, setShow] = useState(false)
    useEffect(() => {
        getAllFunds();
    }, []);

    const getAllFunds = async () => {
        try {
            const res = await loanService.getAllFunds();
            setFunds(res);
            setFilteredFunds(res);
            setShow(true)
        } catch (err) {
            console.log(err);
        }
    }

    const handleSelectChange = (event) => {
        const value = event.target.value;
        setSelectedValue(value);
        if (value === "All") {
            setFilteredFunds(funds);
        } else {
            const filtered = funds.filter(fund => fund.loan.fund_source === value);
            setFilteredFunds(filtered);
        }
    };

    return (
        <div style={{ margin: "60px 20px" }}>
            <div style={{ display: 'flex', justifyContent: 'center', float: 'left' }}>
                <h5 style={{ fontWeight: 'bold', textAlign: 'center' }}>View Fund:&nbsp;</h5>
                <select onChange={handleSelectChange} value={selectedValue} style={{ height: "35px", border: '2px solid blue', borderRadius: '4px', width: "15rem" }}>
                    <option value="All" style={{ fontSize: '19px' }}>All</option>
                    <option value="SBI" style={{ fontSize: '19px' }}>SBI fund</option>
                    <option value="AU" style={{ fontSize: '19px' }}>AU fund</option>
                </select>
            </div>
            <br />
            <br />
            <Table style={{ border: '2px solid blue', marginTop: '50px' }}>
                <Thead style={{ background: "#2949B9", color: 'white', height: '40px', border: '1px solid blue' }}>
                    <Tr>
                        <Th>Loan No</Th>
                        <Th>Name</Th>
                        <Th>Fund Bank</Th>
                        <Th>Branch</Th>
                        <Th>Colender</Th>
                        {/* <Th>Stage</Th>
                        <Th>Mobile No</Th> */}
                    </Tr>
                </Thead>
                <Tbody>
                    {filteredFunds.length > 0 ? (
                        filteredFunds?.map((f, idx) =>
                            <Tr key={idx}>
                                {console.log(f?.application_id === "D0103291" && f, "f?.co_lending")}
                                {console.log(f?.application_id === "D0103291" && f?.co_lending?.length, "f?.co_lendinglenght")}
                                <Td>{f?.application_id}</Td>
                                <Td>{f?.applicant && f?.applicant?.general_information && f?.applicant?.general_information?.name}</Td>
                                <Td>{f?.loan.fund_source}</Td>
                                <Td>{f?.branch_id}</Td>
                                <Td>
                                    {f?.co_lending?.length > 0 ? (
                                        f.co_lending.map((coLender, idx) => (
                                            <div key={idx}>
                                                {coLender.co_lender_status==="ColendingCancelled"?"": coLender.co_lender_name}
                                            </div>
                                        ))
                                    ) : (
                                        ''
                                    )}
                                </Td>

                                {/* <Td>{f?.co_lending[0]?.co_lender_received_date && f?.co_lending[0]?.co_lender_name}</Td> */}
                            </Tr>)
                    ) : (
                        <Tr>
                            {(show && filteredFunds.length === 0) ? <Td align="center" colSpan="5">Data not found</Td> : <Td align="center" colSpan="5" ><CircularProgress /></Td>}
                        </Tr>
                    )}
                </Tbody>
            </Table>
        </div>
    );
}
export default FundUsages;
