import React from "react";
import { useReactToPrint } from "react-to-print";
import AuthorityDoc from "./AuthorityDoc";

export default function AuthorityPrint(props) {
  const compRef = React.useRef();

  // console.log(props.location.state);

  const handlePrint = useReactToPrint({
    content: () => compRef.current,
  });

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <button onClick={handlePrint}>Print Authority Letter</button>
      </div>
      <AuthorityDoc
        ref={compRef}
        // dataFill={props.location.state}
      ></AuthorityDoc>
    </div>
  );
}
