import React from 'react'
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse
  } from "@material-ui/core";
  import AssignmentIcon from '@material-ui/icons/Assignment';
  import { ExpandMore,ExpandLess} from "@material-ui/icons";
import roleChecker from '../../../_helpers/rolechecker';

const LetterMenuList = (props) => {

    const [open, setOpen] = React.useState(false);
  const { path } = props.match;
  const user =props.user;


  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  
  return (
    <React.Fragment>
    <ListItem button onClick={handleClick}>
        <ListItemIcon><AssignmentIcon/></ListItemIcon>
        <ListItemText primary="Letters" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" style={{paddingLeft:"50px"}}>
        <ListItem
            hidden={!(roleChecker.isLegalTeamAccess(user.role))}
            button
            key="valist"
            component="a"
            href={`${path}/letters/showlrn`}
          >
            <ListItemText primary="Lrn Letter" />
          </ListItem>

          <ListItem
            hidden={!(roleChecker.isLegalTeamAccess(user.role))}
            button
            key="vap"
            component="a"
            href={`${path}/letters/emireminderletter`}
          >
            <ListItemText primary="Emi Reminder" />
          </ListItem>

          <ListItem
            hidden={!(roleChecker.isLegalTeamAccess(user.role)) }
            button
            key="vap"
            component="a"
            href={`${path}/letters/show`}
          >
            <ListItemText primary="Letter Tracking" />
          </ListItem>

          <ListItem
            hidden={!(roleChecker.isLegalTeamAccess(user.role))}
            button
            key="vap"
            component="a"
            href={`${path}/letters/list/legalletters`}
          >
            <ListItemText primary="138 Legal Notice" />
          </ListItem>
        </List>
      </Collapse>
      </React.Fragment>
  )
}

export default LetterMenuList