import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal, Box } from '@material-ui/core'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { ledgerService } from '../../_services/ledger.service';
import { employeeService } from '../../_services';
import { CheckInModal } from './CheckInModal';
const ApproveCashRequestModal = (props) => {
    const formikRef = useRef(null);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 900,
        height: 330,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        borderRadius: '5px',
        overflow: "scroll"
    };
    console.log("test props", props)
    const id = props.selected && props.selected._id
    const user = employeeService.userValue.emp_id
    // const branch_id = employeeService.userValue.branch_id
    let branch_id=props.selected && props.selected.branch
    const amount = props.selected && props.selected.amount
    let requested_by_emp_id = props.selected && props.selected.requested_by_emp_id
    let requested_date = props.selected && props.selected.requested_date
    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    let initialValues = {
        status: 'Approved',
        transaction_mode: '',
        approved_cheque_no: '',
        approved_by: user,
        requested_by_emp_id: requested_by_emp_id,
        requested_date: requested_date,
        request_approval_date: new Date(),
        approval_amount: amount,
        credit_ledger_id: '',
        branch_id: branch_id
    }
    let validationSchema = Yup.object().shape({
        transaction_mode: Yup.string().required("This is Required"),
    })
    const onSubmit = (values) => {
        ledgerService.approveChequeRequest(id, values)
            .then((res) => {
                alert("Cash Request Approved !!")
                window.location.reload()
            })
            .catch((err) => console.log(err))
    }
    return (
        <div>
            <Button onClick={handleOpen} style={{ display: 'block', margin: '0 auto', background: "#3251F5", color: 'white', marginTop: '20px' }}>
                Next
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Formik
                        initialValues={initialValues}
                        innerRef={formikRef}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ errors, touched, isSubmitting, setFieldValue, values }) => {
                            return (
                                <Form>
                                    <h2 style={{ textAlign: "center", marginBottom: "30px" }}>Approve Cash Request</h2>
                                    <div style={{ display: "flex", justifyContent: "space-around", fontSize: '20px' }}>
                                        <div style={{ display: "flex", flexDirection: "column", color: "blue" }}>
                                            <label>Transaction Mode</label>
                                            <Field
                                                as="select"
                                                name="transaction_mode"
                                                required
                                                style={{ width: "200px", height: "37px", border: "2px solid #3251F5", borderRadius: '2px' }}
                                                className={
                                                    "form-control" +
                                                    (errors.transaction_mode && touched.transaction_mode ? " is-invalid" : "")
                                                } >
                                                <option>Choose Mode</option>
                                                <option value="Cash">Cash</option>
                                                <option values="Cheque">Cheque</option>
                                            </Field>
                                            <ErrorMessage
                                                name="transaction_mode"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        {values.transaction_mode === "Cheque" && <div style={{ display: "flex", flexDirection: "column", }}>
                                            <label style={{ color: "blue" }}>Cheque Number</label>
                                            <Field
                                                name="approved_cheque_no"
                                                placeholder="Enter Cheque No"
                                                required
                                                style={{ width: "200px", height: "37px", border: "2px solid #3251F5", borderRadius: '2px' }}
                                            />
                                        </div>}
                                        <div style={{ display: "flex", flexDirection: "column", }}>
                                            <label style={{ color: "blue" }}>Amount</label>
                                            <Field
                                                name="approval_amount"
                                                type="number"
                                                placeholder="Enter approval amount"
                                                required
                                                style={{ width: "200px", height: "37px", border: "2px solid #3251F5", borderRadius: '2px' }}
                                            />
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    {values.transaction_mode === "Cheque" && <button
                                        type='submit'
                                        disabled={isSubmitting}
                                        style={{
                                            display: 'flex', justifyContent: 'center', background: '#16C007',
                                            alignItems: "center",
                                            width: '120px',
                                            margin: 'auto',
                                            color: "white",
                                            border: "1px solid #16C007",
                                            fontSize: "22px"
                                        }}
                                    >
                                        {isSubmitting && (
                                            <span className="spinner-border spinner-border-sm mr-1"></span>
                                        )}
                                        Approve
                                    </button>}
                                    {values.transaction_mode === "Cash" && <span style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}> <CheckInModal debit='hoLedgers' credit='otherLedgers' id={id} approval_amount={values.approval_amount}/></span>}
                                </Form>)
                        }}
                    </Formik>
                </Box>
            </Modal>
        </div>
    )
}
export default ApproveCashRequestModal;
