import React from 'react'
import { history } from '../_helpers';
import img1 from './pngtree-elements-of-modern-maintenance-construction-image_2288091-removebg-preview.png'
import { Button } from '@material-ui/core';
function Errors() {
    window.onload = () => {
        history.push('/home')
      };
    const  reload= () => {
      window.location.reload()
        history.push('/home')
      };
  return (
    <div style={{display: 'flex',
        flexWrap: 'nowrap',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'}}>
        <h1 style={{color:'red'}}>503 Error Found!</h1>
        <h3>System Under Maintenance!!</h3>
        <img src={img1}/>
        <h5>This site is under maintenance.</h5>
        <p>We are preparing to serve you better</p>
        <p>Be right back!!</p>
        <Button style={{color:'white',backgroundColor:'blue'}} onClick={reload}>Refresh</Button>
    </div>
  )
}

export default Errors