import React from "react";
import logo from "../../assets/kaabil.png";
import AuthorityDoc from "./AuthorityDoc";
import { virtualAccountService } from "../../_services/virtualaccount.service";
import datehelper from "../../_helpers/datehelper";
import "./pledgeletter.css";

export default class ApplicationDoc extends React.Component {

  constructor() {
    super()
    this.state = {
      vaDetails: null
    }
  }

  componentDidMount() {
    console.log("on component mount");
    var fields = {
      'loan_account': this.props.dataFill.loan_account
    }
    virtualAccountService.getByLoanAccount(fields).then((va) => {
      console.log("got va details");
      console.log(va);
      this.setState({ vaDetails: va });
    })
    .catch((err) => {
        console.log(err);
      })


  }

  render() {
    const data = this.props.dataFill;
    console.log(data.date);
    const options = [];
    let loan_date = new Date(data.date);

    let first_date = new Date (loan_date)
    first_date.setDate(first_date.getDate()-1)
   
    console.log("loan date is ");
    console.log(loan_date);

    for (let i = 1; i <= data.period; i++) {
      //  date = date + 2592000000
      //  console.log(date)
      options.push(i)
    }

    const date = `${new Date(data.date).getDate()}-${
      new Date(data.date).getMonth() + 1
    }-${new Date(data.date).getFullYear()}`;

    function getAge(DOB) {
      var today = new Date();
      var birthDate = new Date(DOB);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age = age - 1;
      }

      return age;
    }

    return (
      <div className="pledge">
        <style type="text/css" media="print">
          {"\
   @page { size: A4; }\
"}
        </style>
        <br />
        <div
          style={{
            width: "290mm",
            height: "410mm",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: "white",
            padding: 40,
          }}
          className="d-flex flex-column"
        >
          <div>
            <p style={{ float: "left" }}>{this.props.owner}</p>
            <p style={{ float: "right" }}>
              Source Code/ Name:&nbsp;{data.source_code}
            </p>
          </div>
          <div style={{ textAlign: "center" }}>
            <div>
              <img
                style={{
                  width: 150,
                  height: 60,
                  width: "50%",
                  marginTop: "1.5%",
                  float: "left",
                }}
                src="https://upload.wikimedia.org/wikipedia/commons/9/9f/CSB_Bank_New_Logo-02.svg"
              ></img>
              <img
                style={{
                  width: 200,
                  height: 80,
                }}
                src={logo}
              ></img>
            </div>
            <br />
            <h4>Customer Profile Form</h4>
          </div>
          <br></br>
          <div style={{ width: "100%" }}>
            <p>
              <b>Name of Individual:</b>
              &nbsp;{data.name.title}
              &nbsp;{data.name.first_name}
              &nbsp;{data.name.last_name}
            </p>
            <p>
              <b>Gender:</b>
              &nbsp;{data.gender}
            </p>
            <p>
              <b>Father's Name:</b>
              &nbsp;{data.father}
            </p>
            <p>
              <b>Mother's Name:</b>
              &nbsp;{data.mother}
            </p>
            <p>
              <b>Date of Birth:</b>
              &nbsp;{data.dob}
            </p>
            <div>
              <p
                style={{
                  width: "50%",
                  float: "left",
                }}
              >
                <b>Citizenship:</b>&nbsp;{data.citizenship}
              </p>
              <p>
                <b>Place of Birth:</b>
                &nbsp;
                {data.birth_place}
              </p>
            </div>
            <div>
              <p
                style={{
                  width: "50%",
                  float: "left",
                }}
              >
                <b>Adhaar No.:</b>&nbsp;
                {"XXXXXXXX" + data.adhaar_no.substring(8)}
              </p>
              <p>
                <b>Form 60:</b>&nbsp;{data.form60}
              </p>
            </div>
            <p>
              <b>Other PID(Type and Number):</b>&nbsp;{data.other_pid}
            </p>
            <p>
              <b>Address/ID Proof:</b>&nbsp;{data.kyc}
            </p>
            <p>
              <b>Marital Status:</b>&nbsp;{data.marital_status}
            </p>
            <div>
              <p
                style={{
                  width: "50%",
                  float: "left",
                }}
              >
                <b>Name of Spouse:</b>&nbsp;{data.spouse}
              </p>
              <p>
                <b>Wedding Date:</b>&nbsp;
                {data.wedding_date}
              </p>
            </div>
            <div>
              <p
                style={{
                  width: "50%",
                  float: "left",
                }}
              >
                <b>Mother Tounge:</b>&nbsp;{data.mother_tounge}
              </p>
              <p>
                <b>Preferred Language for Communication:</b>
                &nbsp;{data.communication_lang}
              </p>
            </div>
            <br />
            <h5>Permanent Address</h5>
            <br />
            <p>
              <b>Address Type:</b>&nbsp;{data.permanent_address.address_type}
            </p>
            <p>
              <b>Address:</b>&nbsp;{data.permanent_address.address}
            </p>
            <div>
              <p
                style={{
                  width: "50%",
                  float: "left",
                }}
              >
                <b>City:</b>&nbsp;{data.permanent_address.city}
              </p>
              <p>
                <b>District:</b>&nbsp;
                {data.permanent_address.district}
              </p>
            </div>
            <div>
              <p
                style={{
                  width: "50%",
                  float: "left",
                }}
              >
                <b>State/UT:</b>&nbsp;{data.permanent_address.state}
              </p>
              <p>
                <b>Pin Code:</b>&nbsp;
                {data.permanent_address.pin_code}
              </p>
            </div>
            <br />
            <h5>Communication Address</h5>
            <br />
            <p>
              <b>Address Type:</b>&nbsp;
              {data.correspondence_address.address_type}
            </p>
            <p>
              <b>Address:</b>&nbsp;{data.correspondence_address.address}
            </p>
            <div>
              <p
                style={{
                  width: "50%",
                  float: "left",
                }}
              >
                <b>City:</b>&nbsp;{data.correspondence_address.city}
              </p>
              <p>
                <b>District:</b>&nbsp;
                {data.correspondence_address.district}
              </p>
            </div>
            <div>
              <p
                style={{
                  width: "50%",
                  float: "left",
                }}
              >
                <b>State/UT:</b>&nbsp;{data.correspondence_address.state}
              </p>
              <p>
                <b>Pin Code:</b>&nbsp;
                {data.correspondence_address.pin_code}
              </p>
            </div>
            <br />
            <p>
              <b>Religion:</b>&nbsp;{data.religion}
            </p>
            <p>
              <b>Category:</b>&nbsp;{data.category}
            </p>
            <p>
              <b>Educational Qualification:</b>&nbsp;{data.education}
            </p>
            <div>
              <p
                style={{
                  width: "50%",
                  float: "left",
                }}
              >
                <b>Physically Challenged:</b>&nbsp;{data.physically_challenged}
              </p>
              <p>
                <b>Mentally Challenged:</b>&nbsp;
                {data.mentally_challenged}
              </p>
            </div>
            <p>
              <b>Annual Income:</b>&nbsp;{data.annual_income}&nbsp;Rupees
            </p>
          </div>
        </div>
        <div
          style={{
            width: "290mm",
            height: "410mm",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: "white",
            padding: 40,
          }}
          className="d-flex flex-column"
        >
          <div style={{ width: "100%" }}>
            <p>
              <b>Occupation Type:</b>
              &nbsp;{data.occupation_type}
            </p>
            <p>
              <b>Source of Income:</b>
              &nbsp;{data.income_source}
            </p>
            <p>
              <b>Name of Organization:</b>&nbsp;{data.organisation}
              <b style={{ marginLeft: 300 }}>Designation:</b>&nbsp;
              {data.designation}
            </p>
            <p>
              <b>Net Worth:</b>
              &nbsp;₹{data.net_worth}
            </p>

            <h5 style={{ textAlign: "center" }}>
              <b>Assets</b>
            </h5>
            <div>
              <br />
              <table style={{ width: "100%" }}>
                <tr>
                  <th>
                    Land and Building (property wise value to be provided)
                  </th>
                  <td style={{ width: "50%" }}>{data.assets.land}</td>
                </tr>
                <tr>
                  <th>Plant and Machinery</th>
                  <td>{data.assets.machinery}</td>
                </tr>
                <tr>
                  <th>
                    Investment in shares (Institution and amount wise reqd)
                  </th>
                  <td>{data.assets.investment}</td>
                </tr>
                <tr>
                  <th>Stock in Trade (Mandatory in case of Traders)</th>
                  <td>{data.assets.stock}</td>
                </tr>
                <tr>
                  <th>Value of Gold (Wt and Amt)</th>
                  <td>{data.assets.gold}</td>
                </tr>
                <tr>
                  <th>Income</th>
                  <td>{data.assets.income}</td>
                </tr>
                <tr>
                  <th>Term Deposits</th>
                  <td>{data.assets.term_deposits}</td>
                </tr>
                <tr>
                  <th>Other Assets</th>
                  <td>{data.assets.other_assets}</td>
                </tr>
                <tr>
                  <th>Total Assets(₹)</th>
                  <td>{data.assets.total_assets}</td>
                </tr>
              </table>
            </div>
            <br />
            <h5 style={{ textAlign: "center" }}>
              <b>Liabilities</b>
            </h5>
            <div>
              <br />
              <table style={{ width: "100%" }}>
                <tr>
                  <th>Borrowings from CSB</th>
                  <td style={{ width: "50%" }}>
                    {data.liabilities.csb_borrowings}
                  </td>
                </tr>
                <tr>
                  <th>
                    Borrowings from Other Banks/FI (Name of the Institution and
                    Amt is to be specified seperately)
                  </th>
                  <td>{data.liabilities.other_borrowings}</td>
                </tr>
                <tr>
                  <th>Other Liabilities (Pls Specify)</th>
                  <td>{data.liabilities.other_liabilities}</td>
                </tr>
                <tr>
                  <th>Total Liabilities(₹)</th>
                  <td>{data.liabilities.total_liabilities}</td>
                </tr>
                <tr>
                  <th>Total Net Worth(₹)</th>
                  <td>{data.liabilities.total_net_worth}</td>
                </tr>
              </table>
            </div>
            <br />
            <div>
              <div
                style={{
                  width: "80%",
                  float: "left",
                }}
              >
                <b>Declaration by Applicant</b>
                <br />
                <br />
                <ul>
                  <li style={{ fontSize: 15 }}>
                    I hereby declare that the details furnished above are true
                    and correct to the best of my knowledge and belief and I
                    undertake to inform you of any changes therein, immediately.
                    In case any of the above information is found to be false or
                    untrue or misleading or misrepresenting, I am aware that I
                    may be held liable for it.
                  </li>
                  <li style={{ fontSize: 15 }}>
                    I hereby also affirm and declare that my address for
                    correspondence is as mentioned overleaf. I understand that
                    the address (positive) confirmation letter sent by the bank
                    to that address, if returned undelivered, will result in the
                    bank stopping all operations of my account, without further
                    notice.
                  </li>
                  <li style={{ fontSize: 15 }}>
                    I would like to share my personal/KYC details with Central
                    KYC registry.
                  </li>
                  <li style={{ fontSize: 15 }}>
                    I hereby consent to receiving information from Central KYC
                    registry through SMS /E-mail on the above registered mobile
                    number / E-mail ID.
                  </li>
                  <li style={{ fontSize: 15 }}>
                    In respects of all assets mentioned in the Networth column,
                    I have kept documentary evidences and will be produced for
                    verification of the same as and when called for.
                  </li>
                  <li style={{ fontSize: 15 }}>
                    Valuation of the assets are made in consistent with the
                    existing minimum market rate prevailing in the locality for
                    similar ones and
                  </li>
                  <li style={{ fontSize: 15 }}>
                    I have neither adjudged insolvent, not compounded by any of
                    my creditors so far.
                  </li>
                  <li style={{ color: "red", fontSize: 15 }}>
                    I, holder of the Aadhaar number, hereby voluntarily give my
                    consent to CSB Bank to obtain and use my Aadhaar number,
                    Name and Fingerprint/Iris and my Aadhaar details for
                    authentication with UIDAI as per Aadhaar Act, 2016 for the
                    aforesaid purpose.
                  </li>
                  <li style={{ color: "red", fontSize: 15 }}>
                    CSB Bank has informed me that basis the voluntary consent
                    given by me while submitting my Aadhaar number to CSB Bank,
                    my Aadhaar details and identity information would only be
                    used for e-KYC purpose, demographic authentication,
                    validation OTP authentication, as the case may be for the
                    aforesaid purpose
                  </li>
                </ul>
              </div>
              <div
                style={{
                  marginLeft: "20%",
                }}
              >
                <div
                  style={{
                    width: "50mm",
                    height: "50mm",
                    marginLeft: "76%",
                  }}
                >
                  <img
                    style={{
                      width: "50mm",
                      height: "50mm",
                    }}
                    src={data.customer_pic}
                  ></img>
                </div>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                  }}
                >
                  Customer Photo
                </p>
                <div
                  style={{
                    width: "50mm",
                    height: "30mm",
                    marginLeft: "76%",
                    marginTop: 50,
                    border: "1px solid black",
                  }}
                ></div>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                  }}
                >
                  Customer Signature
                </p>
              </div>
            </div>
          </div>
          <div>
            <b>Declaration by the Branch</b>
            <br />
            <br />
            <ul>
              <li style={{ fontSize: 15 }}>
                Certified copies of documents received. Verified the attached
                documents with the originals as per KYC/AML guidelines
              </li>
              <li style={{ fontSize: 15 }}>
                Have made independent enquiries about the constituent’s assets,
                liabilities, standing in the society, creditworthiness,
                respectability, etc., obtained reports and verified recordical
                evidences of assets mentioned.
              </li>
              <li style={{ fontSize: 15 }}>
                In the light of foregoing, I am satisfied with the credit
                worthiness of the constituent and the net means Mr/Miss/Mrs……………
                as per my assessment comes to Rs……………………
              </li>
            </ul>
            <p>
              <b>Risk Categorisation:</b>&nbsp;Profile of the customer based on
              risk categorisation: High/Medium/Low
            </p>
            <br />
            <p>Name of the Branch, Branch Code, Date, Place</p>
            <br />
            <p>
              Name of the Bank Official, Designation, EMP Code, Signature with
              seal
            </p>
          </div>
        </div>
        <div
          style={{
            width: "290mm",
            height: "410mm",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: "white",
            padding: 40,
          }}
          className="d-flex flex-column"
        >
          <div>
            <p style={{ float: "left" }}>{this.props.owner}</p>
            <p style={{ float: "right" }}>
              Source Code/ Name:&nbsp;{data.source_code}
            </p>
          </div>
          <div style={{ textAlign: "center" }}>
            <div>
              <img
                style={{
                  width: 150,
                  height: 60,
                  width: "50%",
                  marginTop: "1.5%",
                  float: "left",
                }}
                src="https://upload.wikimedia.org/wikipedia/commons/9/9f/CSB_Bank_New_Logo-02.svg"
              ></img>
              <img
                style={{
                  width: 200,
                  height: 80,
                }}
                src={logo}
              ></img>
            </div>
            <br />
            <h4>Gold Loan Application cum Ledger Form</h4>
          </div>
          <br></br>
          <br></br>
          <div>
            <div
              style={{
                width: "70%",
                float: "left",
              }}
            >
              <div>
                <p
                  style={{
                    width: "50%",
                    float: "left",
                  }}
                >
                  <b>Branch Name:</b>&nbsp;{data.branch_name}
                </p>
                <p>
                  <b>Br Code:</b>&nbsp;
                  {data.branch_code}
                </p>
              </div>
              <div>
                <p
                  style={{
                    width: "50%",
                    float: "left",
                  }}
                >
                  <b>Account Number:</b>&nbsp;
                  {data.loan_account}
                </p>
                <p>
                  <b>Date:</b>&nbsp;
                  {date}
                </p>
              </div>
              <p>
                <b>Name of the Individual:</b>&nbsp;{data.name.title}
                &nbsp;{data.name.first_name}
                &nbsp;{data.name.last_name}
              </p>
              <div>
                <p
                  style={{
                    width: "50%",
                    float: "left",
                  }}
                >
                  <b>Client ID:</b>&nbsp;
                  {data.client_id}
                </p>
                <p>
                  <b>Gender:</b>&nbsp;
                  {data.gender}
                </p>
              </div>
              <div>
                <p
                  style={{
                    width: "50%",
                    float: "left",
                  }}
                >
                  <b>Adhaar No:</b>&nbsp;
                  {"XXXXXXXX" + data.adhaar_no.substring(8)}
                </p>
                <p>
                  <b>PAN No:</b>&nbsp;
                  {data.pan_no.slice(0, -4) + "XXXX"}
                </p>
              </div>
              <div>
                <p
                  style={{
                    width: "50%",
                    float: "left",
                  }}
                >
                  <b>Other PID:</b>&nbsp;
                  {data.other_pid}
                </p>
                <p>
                  <b>Contact:</b>&nbsp;
                  {data.contact.mobile.slice(0, -4) + "XXXX"}
                </p>
              </div>
              <div>
                <p
                  style={{
                    width: "50%",
                    float: "left",
                  }}
                >
                  <b>Age:</b>&nbsp;
                  {getAge(data.dob)}
                </p>
                <p>
                  <b>Occupation:</b>&nbsp;
                  {data.occupation_type}
                </p>
              </div>
            </div>
            <div>
              <img src={data.customer_pic} height="240" width="240"></img>
            </div>
          </div>
          <div>
            <p
              style={{
                width: "35%",
                float: "left",
              }}
            >
              <b>Product:</b>&nbsp;
              {data.product}
            </p>
            <p>
              <b>Tenure:</b>&nbsp;
              {data.period}&nbsp;Months
            </p>
          </div>
          <div>
            <p
              style={{
                width: "35%",
                float: "left",
              }}
            >
              <b>Interest Rate:</b>&nbsp;
              {data.roi}%
            </p>
            <div>
              <p
                style={{
                  width: "35%",
                  float: "left",
                }}
              >
                <b>Penal Interest Rate:</b>&nbsp;
                {data.penal_roi}%
              </p>
              <p>
                <b>Interest Servicing/Repayment:</b>
                &nbsp;
                {data.emi}
              </p>
            </div>
          </div>
          <p></p>
          <p>
            <b>Mode of Disbursement:</b>&nbsp;
            {data.disbursement_mode}
          </p>
          <p>
            <b>Bank Details:</b>
            <br />
            Bank Name - &nbsp;{data.bank_account.bank_name}
            <br />
            Account Number - &nbsp;{data.bank_account.account_no}
            <br />
            IFSC Code - &nbsp;{data.bank_account.ifsc_code}
            <br />
            Account Holder - &nbsp;{data.bank_account.acc_holder}
          </p>
          <p>
            <b>Purpose of Loan:</b>&nbsp;
            {data.purpose}
          </p>
          <p>
            <b>In case of Agri Loans, Details of activity undertaken:</b>&nbsp;
            {data.agri_purpose}
          </p>
          <p>
            <b>Details of Ornaments Pledged:</b>
          </p>
          <div>
            <table style={{ width: "100%" }}>
              <tr>
                <th>Description of ornaments pledged</th>
                <th>No of Units</th>
                <th>Gross weight(Grams)</th>
                {this.props.owner === "Branch Copy" && (
                  <React.Fragment>
                    <th>Net weight(Grams)</th>
                    <th>Other Deductions/ Margins, if any (Grams)</th>
                    <th>Advance Value per gram</th>
                  </React.Fragment>
                )}
                <th>Total Advance Value</th>
              </tr>
              {data.gold.ornaments.map((item) => (
                <tr>
                  <td>{item.desc}</td>
                  <td>{item.quantity}</td>
                  <td>{item.gross_weight}</td>
                  {this.props.owner === "Branch Copy" && (
                    <React.Fragment>
                      <td>{item.net_weight}</td>
                      <td>{item.deduction}</td>
                      <td>{item.advance_value}</td>
                    </React.Fragment>
                  )}
                  <td>{item.total_advance}</td>
                </tr>
              ))}
              <tr>
                <td>&nbsp;</td>
                <td></td>
                <td></td>
                {this.props.owner === "Branch Copy" && (
                  <React.Fragment>
                    <td></td>
                    <td></td>
                  </React.Fragment>
                )}
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Totals</td>
                <td>{data.gold.total_items}</td>
                <td>{data.gold.gross_weight}</td>
                {this.props.owner === "Branch Copy" && (
                  <React.Fragment>
                    <td>{data.gold.net_weight}</td>
                    <td>{data.gold.deduction}</td>
                  </React.Fragment>
                )}
                <td></td>
                <td>{data.gold.valuation}</td>
              </tr>
            </table>
          </div>
          <br />
          <div className="container">
            <div
              style={{
                alignSelf: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="row"
            >
              {data.gold_pics.map((item) => (
                <img
                  style={{ width: 170, height: 170, margin: 10 }}
                  src={item.image}
                ></img>
              ))}
            </div>
          </div>
        </div>
        <div
          style={{
            width: "290mm",
            height: "410mm",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: "white",
            padding: 40,
          }}
          className="d-flex flex-column"
        >
          <br />
          <p>
            <b>Market Value of Gold Pledged:</b>&nbsp;{data.gold.valuation}
          </p>
          <p>
            <b>
              Eligible Value of Advance (In case of Agri, eligibility varies):
            </b>
            &nbsp;{data.gold.eligible_advance}
          </p>
          <p>
            <b>Loan Amount requested:</b>&nbsp;₹{data.loan_request}
            <b style={{ marginLeft: 300 }}>Sanctioned Loan Amt:</b>&nbsp; ₹
            {data.loan_amount}
          </p>
          <p>
            <b>Existing Loans with CSB (Under Different Products):</b>&nbsp;
            {data.external_loans}
          </p>
          <p>
            <b>External appraisal done by: (Name and Code)</b>&nbsp;
            {data.external_appraisal}
          </p>
          <p>
            <b>Packet Details:</b>&nbsp;{data.gold_packet}
          </p>
          <br />
          <br />
          <br />
          <div>
            <span
              style={{
                width: "80%",
                float: "left",
              }}
            >
              Signature of Pawner
            </span>
            <span>Value and quantity certified</span>
          </div>

          <div
            style={{
              marginTop: 80,
            }}
          >
            <span
              style={{
                width: "45%",
                float: "left",
              }}
            >
              Branch Appraiser
            </span>
            <div>
              <span
                style={{
                  width: "40%",
                  float: "left",
                }}
              >
                Joint Custodian
              </span>
              <span>Joint Custodian</span>
            </div>
          </div>
          <p>…………………………………………………………………………………………………………………………………………………………</p>
          <b style={{ textAlign: "center" }}>For Branch Use</b>
          <br />
          <p>
            Sanctioned a loan of Rs {data.loan_amount} to Mr/Miss/Mrs{" "}
            {data.name.first_name}
            &nbsp;{data.name.last_name}
          </p>
          <p style={{ marginLeft: 650, marginTop: 100 }}>
            (Signature and Name of the Principal Officer)
          </p>
          <p style={{ marginLeft: 890 }}>Designation</p>
          <p>………………………………………………………………………………………………………………………………………………………………… </p>
          <p>Received the ornaments pledged</p>
          <p style={{ marginLeft: 810, marginTop: 100 }}>
            (Signature of Pawner){" "}
          </p>
          <p>………………………………………………………………………………………………………………………………………………………………… </p>
          <p>The pledged ornaments have been returned to the pawnor</p>
          <p>Joint Custodian 1)</p>
          <p style={{ marginLeft: 115 }}>2)</p>
          <p>………………………………………………………………………………………………………………………………………………………………… </p>
          <p>Particulars of Auction (if any)</p>
          <div style={{ marginTop: 80 }}>
            <p
              style={{
                width: "77%",
                float: "left",
              }}
            >
              Date:&nbsp;{date}
            </p>
            <p>Signature of Officer in Charge</p>
          </div>
          <p>………………………………………………………………………………………………………………………………………………………………… </p>
          <p>
            Details regarding adjustments of surplus net sale proceeds/deficits
            (if any)
          </p>
        </div>
        <div
          style={{
            width: "290mm",
            height: "410mm",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: "white",
            marginTop: 47,
            padding: 40,
          }}
          className="d-flex flex-column"
        >
          <p>
            On Demand I promise to pay The Catholic Syrian Bank
            Ltd............................................................. or
            order the sum of
            Rs............................Rupees...........................................................................................................
            only with interest at.........................................
            percent per annum or at such rates as may be prescribed/revised by
            the bank from time to time for value received in cash.
          </p>
          <div
            style={{
              width: "50mm",
              height: "30mm",
              marginLeft: "76%",
              border: "1px solid black",
            }}
          ></div>
          <p
            style={{
              fontSize: 10,
              marginLeft: "81%",
            }}
          >
            Stamp with Signature
          </p>
          <br />
          <b style={{ textAlign: "center" }}>
            TERMS AND CONDITIONS OF LOAN SECURED BY PLEDGE OF GOLD ORNAMENTS
          </b>
          <br />
          <ol>
            <li>
              The loan shall be for a period of {data.period} month/year and
              will carry interest at the rate of {data.roi}% p.m with monthly
              rests and service charges and other charges are prescribed by the
              bank from time to time. Interest and such charges shall be
              remitted on due date/s or at the time of closure along with the
              principal, as the case may be. Overdue interest @ {data.penal_roi}
              % or at the rates prescribed by the bank from time to time will be
              charged from the due date onwards. In case of Agriculture Gold
              Loans, interest will be compounded from the date of overdue.
            </li>
            <li>
              The pawnor acknowledges and agrees that the rate per gram of gold
              fixed by the Bank as on date is Rs {data.gold.rpg}/- and in case
              of any downward fluctuations in the market price of the gold or
              upward movement in the rate of interest, the pawnor shall make
              part payment/s to maintain the required minimum margin of
              25%/required minimum loan to value ratio of 75% throughout the
              tenor of the loan, failing which the Bank shall be entitled to
              initiate legal action for recovery of dues and to sell the pledged
              articles through public auction or private sale even before the
              due date of the loan and appropriate the proceeds towards the dues
              under this loan or any other loan in the name of the pawnor with
              the Bank.
            </li>
            <li>
              The rate of interest is liable for change from time to time
              without any reference to the borrower as to the variation in the
              rate of interest. Notice of change in rate of interest if any, to
              the pawnor is hereby waived.
            </li>
            <li>
              Minimum of 7 days interest will be charged on the loan amount.
            </li>
            <li>
              The bank will have the power to sell the required quantity or
              ornaments pledged in case the pawnor fails to clear the dues on
              due date or fails to maintain sufficient margin during the term of
              the loan. The sale will be at the risk of the pawnor, by public
              auction or private sale, on or after two weeks from the date of
              the notice of sale to the pawnor. From the net proceeds of such
              sale, all amounts due to the bank and statutory dues, if any, in
              respect of the loan will be appropriated. Should there be any
              balance due to the bank after adjustment, the pawnor is still
              liable personally to pay the balance. Should there be any surplus,
              the bank shall have the right to appropriate such surplus, towards
              any other liability of the pawnor, by himself or jointly or
              severally with others on any account whatsoever, to the bank in
              any of its offices.
            </li>
            <li>
              Any change in the address shall be informed by the pawnor to the
              bank. A communication, either a letter or e- mail, sent by the
              bank to the pawnor in his/her last known residential/ e-mail
              address shall be treated as sufficient notice for all purposes.
            </li>
            <li>
              The bank has the right to retain the ornaments pledged as security
              for any amounts that may be due to the bank in any of its offices
              by the pawnor himself or jointly or severally with others, on any
              account whatsoever, even before or after the loan for which the
              ornaments are pledged as security has been discharged.
            </li>
            <li>
              On failure of the pawnor to remit the dues on due date, the bank
              is at liberty to sell such item/s of ornaments pledged as security
              sufficient enough to liquidate the entire dues under gold loan
              account/s at the discretion of the bank either by public auction
              or private sale.
            </li>
            <li>
              The pawnor hereby declare that the ornaments being pledged with
              the bank are his/her bonafide property and that he/she has the
              right to pledge the same and that no other person has any claim
              against it/them.
            </li>
            <li>
              In the event of any failure on the part of the pawnor, to pay the
              defaulted amount so demanded, without prejudice to the right of
              the bank to proceed against the pawnor in a court of law or to
              initiate any other remedial measures the bank and / or the Reserve
              Bank of India will have an unqualified right to disclose or
              publish the pawnor’s name as defaulter in such manner and through
              such medium as bank/ Reserve Bank of India may think fit.
            </li>
            <li>
              The pawnor hereby declares that his/her net worth, including the
              market value of gold ornaments now offered for pledge is Rs{" "}
              {data.gold.valuation}
            </li>
            <li>
              The pawnor hereby declares that he/she has read and understood the
              terms and conditions of the loan and agree to abide by them and
              any amendments thereto.
            </li>
            <li>
              The pawnor agrees that if any of the credit facilities in his/her
              name turns overdue/NPA, the Bank is entitled to initiate legal
              action for recovery of dues including in this loan.
            </li>
            <li>
              In the case of gold loan quarterly products the pawnor agrees to
              service the interest and to bring the balance equal to the limit
              sanctioned, on the last day of every quarter namely June,
              September, December and March, failing which Bank is entitled to
              initiate legal action for recovery of dues, as detailed in clauses
              2 and 5 above.
            </li>
          </ol>
          <p>
            Please take delivery of the items listed overleaf and grant me a
            loan of Rs {data.loan_amount}
          </p>
          <p style={{ marginLeft: 850 }}>Yours faithfully,</p>
          <p>Place: Jaipur</p>
          <div>
            <p
              style={{
                width: "76%",
                float: "left",
              }}
            >
              Date:&nbsp;{date}
            </p>
            <p>Signature & Name of Pawner</p>
          </div>
        </div>
        <div
          style={{
            width: "290mm",
            height: "410mm",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: "white",
            padding: 40,
          }}
          className="d-flex flex-column"
        >
          <div style={{ textAlign: "center" }}>
            <div>
              <img
                style={{
                  width: 150,
                  height: 60,
                  width: "50%",
                  marginTop: "1.5%",
                  float: "left",
                }}
                src="https://upload.wikimedia.org/wikipedia/commons/9/9f/CSB_Bank_New_Logo-02.svg"
              ></img>
              <img
                style={{
                  width: 200,
                  height: 80,
                }}
                src={logo}
              ></img>
            </div>
            <br />
            <h4>Gold Loan Token Format</h4>
          </div>
          <br />
          <br />
          <div>
            <p
              style={{
                width: "85%",
                float: "left",
              }}
            >
              Br Name:&nbsp;{data.branch_name}
            </p>
            <p>Date:&nbsp;{date}</p>
          </div>
          <div>
            <div
              style={{
                width: "50%",
                float: "left",
              }}
            >
              <p></p>
              <p></p>
              <p></p>
              <p>Account No:&nbsp;{data.loan_account}</p>
              <p>
                Customer Name:&nbsp;{data.name.title}
                &nbsp;{data.name.first_name}
                &nbsp;{data.name.last_name}
              </p>
              <p>Customer ID:&nbsp;{data.client_id}</p>
              <p>Total Gross Weight(gm):&nbsp;{data.gold.gross_weight}</p>
              <p>Loan Amount:&nbsp;{data.loan_amount}</p>
              <p>Product:&nbsp;{data.product}</p>
            </div>
            <div>
              <img
                style={{
                  height: 220,
                  width: 220,
                  margin: 15,
                }}
                src={data.customer_pic}
              ></img>
              <img
                style={{
                  height: 220,
                  width: 220,
                }}
                src={data.gold_pic}
              ></img>
            </div>
          </div>
          <br />
          <br />
          <b>Details of Ornaments Pledged:</b>
          <div>
            <br />
            <table style={{ width: "100%" }}>
              <tr>
                <th>Description of ornaments pledged</th>
                <th>No of Units</th>
                <th>Gross weight(Grams)</th>
                {this.props.owner === "Branch Copy" && (
                  <th>Net weight(Grams)</th>
                )}
              </tr>
              {data.gold.ornaments.length > 0 ? (
                data.gold.ornaments.map((item) => (
                  <tr>
                    <td>{item.desc}</td>
                    <td>{item.quantity}</td>
                    <td>{item.gross_weight}</td>
                    {this.props.owner === "Branch Copy" && (
                      <td>{item.net_weight}</td>
                    )}
                  </tr>
                ))
              ) : (
                <div></div>
              )}
              <tr>
                <td>&nbsp;</td>
                <td></td>
                <td></td>
                {this.props.owner === "Branch Copy" && <td></td>}
              </tr>
              <tr>
                <td>Totals</td>
                <td>{data.gold.total_items}</td>
                <td>{data.gold.gross_weight}</td>
                {this.props.owner === "Branch Copy" && (
                  <td>{data.gold.net_weight}</td>
                )}
              </tr>
            </table>
          </div>
          <div
            style={{
              marginTop: 100,
            }}
          >
            <p
              style={{
                width: "80%",
                float: "left",
              }}
            >
              Authorised Signatory
            </p>
            <p>Signature of customer</p>
          </div>
          <br />
          GSTIN/UIN : 08AAGCK6104N1ZA
        </div>
        {this.props.owner === "Branch Copy" && (
          <div>
            <div
              style={{
                width: "290mm",
                height: "400mm",
                marginLeft: "auto",
                marginRight: "auto",
                backgroundColor: "white",
                padding: 40,
              }}
              className="d-flex flex-column"
            >
              <div style={{ textAlign: "center" }}>
                <div>
                  <img
                    style={{
                      width: 150,
                      height: 60,
                      width: "50%",
                      marginTop: "1.5%",
                      float: "left",
                    }}
                    src="https://upload.wikimedia.org/wikipedia/commons/9/9f/CSB_Bank_New_Logo-02.svg"
                  ></img>
                  <img
                    style={{
                      width: 200,
                      height: 80,
                    }}
                    src={logo}
                  ></img>
                </div>
                <br />
                <h4>Charge Details (for office use)</h4>
              </div>
              <br />
              <br />
              <div>
                <div
                  style={{
                    width: "50%",
                    float: "left",
                  }}
                >
                  <p></p>
                  <p></p>
                  <p></p>
                  <p>PF Charge(₹):&nbsp;{data.pf}</p>
                  <p>EMI(₹):&nbsp;{data.emi}</p>
                  <p>Payment Frequency:&nbsp;{data.payment_frequency}</p>
                  <p>Disbursement Amount(₹):&nbsp;{data.disburse_amount}</p>
                </div>
                <div>
                  <p></p>
                  <p></p>
                  <p></p>
                  <p>Auction Date:&nbsp;{data.auction_date}</p>
                  <p>Closure Due Date:&nbsp;{data.closure_due_date}</p>
                  <p>EMI Due Date:&nbsp;{data.emi_due_date}</p>
                </div>
              </div>
              <br />
              <br />
            </div>
            {/* <AuthorityDoc dataFill={data}></AuthorityDoc> */}
           
             {
              console.log('test',data)
             }
          </div>
        )}
         <div
          style={{
            width: "290mm",
            height: "400mm",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: "white",
            // border: "1px solid black",
            padding: 40,
          }}
          className="d-flex flex-column"
        >
          <div style={{ textAlign: "center" }}>
              <img alt="" style={{ width: 350, height: 100 }} src={logo}></img>
            </div>
          <hr />
          <h2>Repayment Schedule List</h2><br />
          <div className="container my-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <span> Loan Account: {data.loan_account}</span>   
            <span style={{ display: "flex", justifyContent: "flex-end" }}> Name:- &nbsp;{data.name.title}&nbsp;{data.name.first_name}&nbsp;{data.name.middle_name}&nbsp;{data.name.last_name}</span>
          </div>
          <div className="container my-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <span>ROI: {data.roi}% </span>    <span>Loan Amount: {data.loan_amount}</span>
          </div> <br />
          <h4>Interest Table</h4>
          <table style={{ borderBottom: "2px solid black" }} >
            <thead>
              <tr>
                <td>Month</td>
                <td  >EMI Amount</td>
                <td  >Date</td>
              </tr>
            </thead>
            <tbody>
              {options && options.map((element) => {
                return <React.Fragment>
                  <tr>
                    <td  >Month {element}</td>

                    <td >{Math.round(data.loan_amount * data.roi / 100)}</td>
                    <td>{new Date(first_date.setDate(first_date.getDate() +30)).toDateString()}</td>
                  </tr>
                </React.Fragment>
              })}
            </tbody>
          </table>
          {this.state.vaDetails && (
            <div>
              <h2>Payment QR Code</h2>
              <div
                style={{ paddingLeft: '50px', paddingTop: '50px' }}
              >
                <p><b>Account Payee:</b> Kaabil Finance Private Ltd</p>
                <p><b>Bank Name:</b> ICICI Bank</p>
                <p><b>Account Number:</b> {this.state.vaDetails && this.state.vaDetails.va_num}</p>
                <p><b>IFSC Code:</b> ICIC0000106</p>
                {
                  this.state.vaDetails && 
                    <img src={this.state.vaDetails.qrcode_url} alt='QR Code'></img>
                  
                }
              </div>
            </div>
          )}
          
        </div>
      </div>
    );
  }
}
