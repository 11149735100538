import React, { useState } from 'react'
import { Button, Modal, Box } from '@material-ui/core'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { pettyCashService } from '../../_services/pettycash.service';
import { Add } from '@material-ui/icons';
import { employeeService } from '../../_services';
import datehelper from '../../_helpers/datehelper';
const CreatePettyCashModal = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 900,
        height: 415,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        border: '2px solid blue',
        borderRadius: '5px'
    };
    const user = employeeService.userValue.emp_id
    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    let initialValues = {
        amount: '',
        // requested_date:new Date(),
        paying_to: '',
        requested_by: user,
        status: "Pending",
        remarks: ''
    }
    let validationSchema = Yup.object().shape({
        amount: Yup.number().required('This is Required'),
        paying_to: Yup.string().required('This field is required')
            .matches(/^[A-Za-z][A-Za-z\s]*$/, 'Enter only alphabetical letters')
        ,
        remarks: Yup.string().required('This is Required')
    })
    const onSubmit = (values) => {
        pettyCashService.createPettyCash(values)
            .then((res) => {
                alert("Petty Cash Request Created !!")
                window.location.reload()
            })
            .catch((err) => console.log(err))
    }
    return (
        <div>
            <Button onClick={handleOpen}
                style={{ display: 'flex', float: "right", marginBottom: "15px", background: "#3251F5", color: 'white' }}>
                <Add /> Create Request</Button>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ errors, touched, isSubmitting }) => {
                            return (
                                <Form>
                                    <h3 style={{ textAlign: "center", marginBottom: "30px" }}>Petty Cash Request</h3>
                                    <div style={{ display: "flex", justifyContent: "space-around", fontSize: '20px' }}>
                                        <div style={{ display: "flex", flexDirection: "column", color: "blue" }}>
                                            <label>Amount</label>
                                            <Field
                                                name="amount"
                                                type="number"
                                                placeholder="Enter Amount"
                                                style={{ width: "200px", height: "37px", border: "2px solid #3251F5", borderRadius: '2px' }}
                                                className={
                                                    "form-control" +
                                                    (errors.amount && touched.amount ? " is-invalid" : "")
                                                } />
                                            <ErrorMessage
                                                name="amount"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", }}>
                                            <label style={{ color: "blue" }}>Date</label>
                                            <Field
                                                name="requested_date"
                                                value={datehelper.displayDate(new Date())}
                                                disabled
                                                style={{ width: "200px", height: "37px", border: "2px solid #3251F5", borderRadius: '2px' }}
                                            />
                                        </div>

                                        <div style={{ display: "flex", flexDirection: "column", color: "blue", }}>
                                            <label>Paying To</label>
                                            <Field
                                                name="paying_to"
                                                placeholder="Enter Name"
                                                style={{ width: "200px", height: "37px", border: "2px solid #3251F5", borderRadius: '2px' }}
                                                className={
                                                    "form-control" +
                                                    (errors.paying_to && touched.paying_to ? " is-invalid" : "")
                                                } />
                                            <ErrorMessage
                                                name="paying_to"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <div style={{ display: "flex", fontSize: '20px', flexDirection: "column", color: 'blue', marginLeft: "45px" }}>
                                        <label>Remarks</label>
                                        <Field
                                            name="remarks"
                                            placeholder='Enter Remark'
                                            style={{ width: "200px", height: "37px", border: "2px solid #3251F5", borderRadius: '2px' }}
                                            className={
                                                "form-control" +
                                                (errors.remarks && touched.remarks ? " is-invalid" : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name="remarks"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <br />
                                    <button
                                        type='submit'
                                        disabled={isSubmitting}
                                        style={{
                                            display: 'flex', justifyContent: 'center',
                                            alignItems: 'center', background: "#3251F5",
                                            width: '120px', marginLeft: 'auto',
                                            marginRight: 'auto', color: 'white',
                                            border: '1px solid white', fontSize: '22px',
                                            borderRadius: '5px'
                                        }}
                                    >
                                        {isSubmitting && (
                                            <span className="spinner-border spinner-border-sm mr-1"></span>
                                        )}
                                        Create
                                    </button>
                                </Form>)
                        }}
                    </Formik>
                </Box>
            </Modal>
        </div>
    )
}

export default CreatePettyCashModal
