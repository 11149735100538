import React, { useEffect, useState } from 'react'
import { useReactToPrint } from "react-to-print";
import { loanService } from '../../../_services';


const DisbursementRequestForm = (props) => {

    const data = props.data
    const branches = props.branches

    // const DisbursementRequestFormRef = React.useRef();



    // const handleSubmissionPrint = useReactToPrint({
    //     content: () => DisbursementRequestFormRef.current,
    //     pageStyle: "print",
    // });



    return (
        <>

            {/* <div style={{ textAlign: "center" }}>
                <button onClick={handleSubmissionPrint}>Print Letter</button>
            </div> */}

            <div
                style={{
                    width: "290mm",
                    height: "410mm",
                    backgroundColor: "white",
                    padding: 40,
                    position: "relative",
                    fontSize: "20px"
                }}
            >
                <span style={{ marginLeft: "35%" }}><b>DISBURSEMENT REQUEST FORM</b></span><br /><br />
                <div style={{ marginLeft: "80%", display: "flex", flexDirection: "column" }}>
                    <span>Place: {branches.branch_city}</span><br />
                    <span>Date: _______________</span>
                </div>
                <div style={{ textAlign: "justify" }}>

                    <span>To</span><br />
                    <span>Kaabil Finance Private Limited,<br />
                        328C Kusum Vihar, Ramnagaria Road,<br />
                        Jagatpura, Jaipur – 302025, (“Lender 1”)</span><br /><br />

                    <span>Capital India Finance Limited<br />
                        2nd Floor, DLF Centre, Sansad Marg<br />
                        New Delhi, Central Delhi – 110001, (“Lender 2”).</span><br /><br />

                    <span>(Collectively “Lenders”)</span><br /><br />

                    <span>Ref: Term loan of Rs. {data && data.loan && data.loan.sanction_amount}/- approved by the Lenders, vide the Sanction Letter dated __________
                        (“Loan”)</span><br /><br />

                    <span>Dear Sir / Madam,</span><br /><br />

                    <span>With reference to the Loan, I/we, the Borrower/s, hereby request you to disburse the following amounts, as per <br />
                        the details given below:</span><br /><br />

                    <table>
                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>Sr.No</td>
                            <td style={{ border: "1px solid" }}>Amount to be Disbursed</td>
                            <td style={{ border: "1px solid" }}>Mode of payment</td>
                            <td style={{ border: "1px solid" }}>Name of the payee</td>
                            <td style={{ border: "1px solid" }}>Bank account number of the payee</td>
                            <td style={{ border: "1px solid" }}>Name & IFSC code of payee’s bank</td>
                        </tr>
                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>&nbsp;</td>
                            <td style={{ border: "1px solid" }}>&nbsp;</td>
                            <td style={{ border: "1px solid" }}>RTGS</td>
                            <td style={{ border: "1px solid" }}>KAABIL FINANCE PRIVATE LIMITED</td>
                            <td style={{ border: "1px solid" }}>678705600547</td>
                            <td style={{ border: "1px solid" }}>ICIC0006787</td>
                        </tr>
                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>&nbsp;</td>
                            <td style={{ border: "1px solid" }}>&nbsp;</td>
                            <td style={{ border: "1px solid" }}>&nbsp;</td>
                            <td style={{ border: "1px solid" }}>&nbsp;</td>
                            <td style={{ border: "1px solid" }}>&nbsp;</td>
                            <td style={{ border: "1px solid" }}>&nbsp;</td>
                        </tr>

                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>&nbsp;</td>
                            <td style={{ border: "1px solid" }}>&nbsp;</td>
                            <td style={{ border: "1px solid" }}>&nbsp;</td>
                            <td style={{ border: "1px solid" }}>&nbsp;</td>
                            <td style={{ border: "1px solid" }}>&nbsp;</td>
                            <td style={{ border: "1px solid" }}>&nbsp;</td>
                        </tr>
                    </table><br />

                    <span>
                        In case of disbursement by cheque / pay order / demand draft, I/we authorize the Lender to handover the said
                        cheque / pay order / demand draft, directly to the payee, and also to collect original receipts of such payment.
                    </span><br /><br />

                    <span>Further, I/we understand and acknowledge that the interest shall be payable by me/us at the agreed interest rate
                        on the total amount disbursed, in terms of loan agreement executed in relation to the Loan. Thanking You,</span><br />

                    <span>Yours Faithfully,</span><br />

                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span style={{ width: "300px" }}>Name of the Borrower/s</span>
                        <span style={{ width: "300px" }}>Signature/ Thumb Impression</span>
                    </div><br />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span span style={{ width: "300px" }}>{data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</span><br />
                        <span span style={{ width: "200px" }}>________________________</span><br /><br />
                    </div>
                    {data && data.co_applicants && data.co_applicants.length > 0 && data.co_applicants.map((e, idx) => {
                        return <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span span style={{ width: "300px" }}>{e.general_information.name}</span><br />
                            <span span style={{ width: "200px" }}>________________________</span><br /><br />
                        </div>
                    })}
                </div>
            </div>
        </>
    )
}

export default DisbursementRequestForm