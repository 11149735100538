import React from "react";
import SanctionLetter from "./SanctionLetter";
import { useReactToPrint } from "react-to-print";

export default function SanctionPrint(props) {
  const kfplRef = React.useRef();
  const capitalRef = React.useRef();
  const ugroRef = React.useRef();

  const provider = props.location.state.provider;

  const handleKfplPrint = useReactToPrint({
    content: () => kfplRef.current,
  });

  const handleCapitalPrint = useReactToPrint({
    content: () => capitalRef.current,
  });

  const handleUgroPrint = useReactToPrint({
    content: () => ugroRef.current,
  })

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <button onClick={handleKfplPrint}>Print KFPL Letter</button>
        {provider === "ugro" ? <button onClick={handleUgroPrint} style={{ marginLeft: "10px" }}>
          Print Ugro Letter
        </button>:  <button onClick={handleCapitalPrint} style={{ marginLeft: "10px" }}>
          Print Capital Letter
        </button> }
         
      </div>
      <SanctionLetter
        ref={kfplRef}
        dataFill={{ ...props.location.state, bank: "KFPL" }}
      ></SanctionLetter>
      <SanctionLetter
        ref={ugroRef}
        dataFill={{...props.location.state, bank: "ugro"}} 
      ></SanctionLetter>
      <SanctionLetter
        ref={capitalRef}
        dataFill={{ ...props.location.state, bank: "Capital" }}
      ></SanctionLetter>
    </div>
  );
}
