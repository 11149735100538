import React, { useState, useEffect } from "react";
import { loanService } from "../../_services";
import TablePagination from "@material-ui/core/TablePagination";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import datehelper from "../../_helpers/datehelper";
import * as XLSX from 'xlsx';

// Helper function to parse and format the date
const formatDate = (dateString) => {
  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      console.warn('Invalid date format:', dateString);
      return 'Invalid Date';
    }
    return date.toLocaleDateString('en-GB');  // British format for "dd/mm/yyyy"
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Error';
  }
};

function PostDisbursalList() {
  const [documents, setDocuments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchTerm, setSearchTerm] = useState("");

  const transformData = (data) => {
    const tranchMap = new Map();  // To keep track of Tranch numbers
    const flattenedData = data.reduce((acc, item) => {
      const disbursementDetailsList = item.disbursement && Array.isArray(item.disbursement.disbursement_details)
        ? item.disbursement.disbursement_details
        : [];

      disbursementDetailsList.forEach(detail => {
        if (["paid", "pending"].includes(detail.payment_status)) {
          const loanNumber = item.application_id;
          const sanctionAmount = item.loan.sanction_amount;
          const disbursementDeduction = item.loan.disbursement_deduction;
          const currentTranch = (tranchMap.get(loanNumber) || 0) + 1;
          tranchMap.set(loanNumber, currentTranch);
          acc.push({
            loan_number: loanNumber,
            sanction_amount: sanctionAmount,
            disbursement_deduction: disbursementDeduction,
            account_holder_name: detail.account_holder_name,
            added_by: detail.added_by,
            disbursed_account_number: detail.disbursed_account_number,
            disbursed_amount: detail.disbursed_amount,
            disbursed_date: datehelper.displayDate(new Date(detail.disbursed_date)),
            disbursed_utr_number: detail.disbursed_utr_number,
            disbursement_memo_id: detail.disbursement_memo_id.$oid,
            kaabil_account_number: detail.kaabil_account_number,
            kaabil_bank_name: detail.kaabil_bank_name,
            payment_status: detail.payment_status,
            tranch: currentTranch,
          });
        }
      });

      return acc;
    }, []);

    return flattenedData;
  };

  useEffect(() => {
    const postDisbursalLoansData = async () => {
      try {
        const response = await loanService.postDisbursalLoansData();
        const disbursementDetails = response || [];
        const transformedData = transformData(disbursementDetails);
        setDocuments(transformedData);
      } catch (error) {
        console.error('Error getting document checklist:', error);
        alert('An error occurred while fetching the data.');
      }
    };

    postDisbursalLoansData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);  // Reset to the first page when search term changes
  };

  const filteredDocuments = documents.filter(doc =>
    doc.loan_number.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const exportToExcel = () => {
    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert the documents data to a worksheet
    const ws = XLSX.utils.json_to_sheet(filteredDocuments);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Loans Data");

    // Export the workbook to Excel file
    XLSX.writeFile(wb, "PostDisbursalLoans.xlsx");
  };

  return (
    <div>
      <h1 style={{ textAlign: 'center', color: "Red" }}>
        Part Disbursed Loans &nbsp;
      </h1>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}>
        <input
          type="text"
          placeholder="Search by Loan Number"
          value={searchTerm}
          onChange={handleSearchChange}
          style={{ padding: '8px', width: '250px', marginRight: '10px' }}
        />
        <button
          onClick={exportToExcel}
          style={{
            padding: '8px 16px',
            backgroundColor: 'green',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          Download Excel
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th>Disbursed Date</th>
            <th>Tranch</th>
            <th>Loan Number</th>
            <th>Account Holder Name</th>
            <th>Sanction Amount</th>
            <th>Disbursement Deduction</th>
            <th>Disbursed Amount</th>
            <th>Disbursed Account Number</th>
            <th>Disbursed UTR Number</th>
            <th>Payment Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredDocuments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((doc, index) => (
            <tr key={index}>
              <td>{doc.disbursed_date}</td>
              <td>{doc.tranch}</td>
              <td>{doc.loan_number}</td>
              <td>{doc.account_holder_name}</td>
              <td>{doc.sanction_amount}</td>
              <td>{doc.disbursement_deduction}</td>
              <td>
                {doc.tranch === 1
                  ? (Number(doc.disbursed_amount) + (Number(doc.disbursement_deduction) || 0)).toFixed(2)
                  : Number(doc.disbursed_amount).toFixed(2)}
              </td>
              <td>{doc.disbursed_account_number}</td>
              <td>{doc.disbursed_utr_number}</td>
              <td>{doc.payment_status}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={filteredDocuments.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}

export { PostDisbursalList };
