import React from 'react';
import { useState } from 'react';
import { Modal } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { employeeService } from '../../_services';
import { reconcile } from '../../_services/reconcile.service';

export const ReconcilationVerificationModal = (params) => {
    const [open, setOpen] = useState(false)
    const [comment, setComment] = useState("")
    const data = params.loan;
    const date = new Date();
    const user = employeeService.userValue;

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        height: 300,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'scroll',
        //paddingBottom: '30px',
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let id = data._id;
        let value = { verification_details: { comment: comment, verified_by: user.emp_id, date: date } };
        await reconcile.reconcileVerification(id, value)
            .then((response) =>{ 
                window.alert('Verified successfully');
                window.location.reload()
            })
            .catch((err) =>{
                console.log(err);
                window.alert('Some error occured')
            })
    }

    const handleChange = (e) => {
        setComment(e.target.value)
    }

    return (
        <>
            <button onClick={handleOpen} style={{ color: "white", backgroundColor: 'green', borderRadius: "5px", padding: "5px", width: '125px', paddingTop: '8px', paddingBottom: '8px', border: 'none' }} >Verify</button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <form style={{ display: 'flex', flexDirection: 'column' }} onSubmit={(e) => handleSubmit(e)} >
                            <label style={{ display: 'flex', flexDirection: 'column' }}>Comment <input type="textarea" onChange={(e) => handleChange(e)} required /></label>
                            <button style={{ color: "white", backgroundColor: 'green', borderRadius: "5px", padding: "5px", width: '125px', paddingTop: '8px', paddingBottom: '8px', border: 'none' }} >Submit</button>
                        </form>
                    </Typography>
                    {/* {
                        console.log(data)
                    } */}
                </Box>
            </Modal>
        </>
    )
}