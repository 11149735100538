import React, { useEffect, useState } from 'react'
import { ArrowForward, VerifiedUser } from '@material-ui/icons'
import { Button, Modal, Box } from '@material-ui/core'
import { ledgerService } from '../../_services/ledger.service';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import datehelper from '../../_helpers/datehelper';
import ApproveCashRequestModal from './ApproveCashRequestModal';

const CashRequestListModal = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        height: 620,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        borderRadius: '5px',
        overflow: "scroll"
    };
    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const [cashRequestList, setCashRequestList] = useState([])
    const getCashRequestList = () => {
        ledgerService.getCashRequestList()
            .then((res) => setCashRequestList(res))
            .catch((err) => console.log(err))
    }
    useEffect(() => {
        getCashRequestList()
    }, [])

    const [selectedValue, setSelectedValue] = useState();
    const handleCheckboxChange = (event) => {
        const selectedId = event.target.value;
        const cashRequest = cashRequestList.find((request) => request._id === selectedId);
        setSelectedValue(cashRequest);
      };  
      const rejectCashRequest=(id)=>{
        ledgerService.rejectCashRequest(id)
        .then((res)=>{alert("Cash Request Rejected !!")
          window.location.reload()
    })
        .catch((err)=>console.log(err))
    }    
    console.log("selected value", selectedValue)
    return (
        <div>
            <Button onClick={handleOpen}
                style={{ display: 'flex', float: "right", marginBottom: "15px", background: "#079904", color: 'white' }}>
                <VerifiedUser />&nbsp;Approve Request</Button>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <h2 style={{ textAlign: "center", marginBottom: "30px" }}>Approve Cash Request</h2>
                    <Table style={{ border: '2px solid blue' }}>
                        <Thead style={{ background: "#2949B9", color: 'white', height: '35px' }}>
                            <Tr>
                                <Th>Amount</Th>
                                <Th>Branch</Th>
                                <Th>Request Creation Date</Th>
                                <Th>Requested By</Th>
                                <Th colSpan="2" style={{textAlign:'center'}}>Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {cashRequestList.length > 0 && cashRequestList.filter(cash => cash.status === "Pending").map((x, idx) =>
                                <>
                                    <Tr key={idx} style={{ background: '#D9DDF5', height: "25px", fontWeight: 'bold', fontSize: "18px" }}>
                                        <Td>{x.amount.toLocaleString("en-IN", { style: 'currency', currency: 'INR' })}</Td>
                                        <Td>{x.branch}</Td>
                                        <Td>{datehelper.displayDateTime(new Date(x.requested_date))}</Td>
                                        <Td>{x.requested_by}</Td>
                                        <Td>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <label>
                                                <input
                                                    style={{ width: "20px", height: "25px",marginRight:"30px" }}
                                                    type="checkbox"
                                                    value={x._id}
                                                    onChange={handleCheckboxChange}
                                                    checked={selectedValue?._id === x._id}
                                                />
                                            </label>
                                            <Button onClick={() => rejectCashRequest(x._id)} style={{ color: 'red'}}>Reject</Button>
                                            </div>
                                        </Td>
                                    </Tr>
                                    <br />
                                </>)}
                        </Tbody>
                    </Table>
                    {selectedValue && <ApproveCashRequestModal selected={selectedValue} />}
                </Box>
            </Modal>
        </div>
    )
}
export default CashRequestListModal
