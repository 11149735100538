import React from "react";
import { Route, Switch } from "react-router-dom";
import PettyCashList from "./PettyCashList";

const PettyCashIndex = ({ match }) => {
  const { path } = match;
  return (
    <div>
      <Switch>
        <Route exact path={path} component={PettyCashList} />
      </Switch>
    </div>
  )
}

export default PettyCashIndex
