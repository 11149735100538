import React, { useState } from 'react'
import AadharOtpSendModal from './AadharOtpSendModal';
import { Button } from '@material-ui/core';
import { MobileOtpVerificationModel } from './MobileOtpVerificationModel';
import {PanOtpSendModal} from './PanOtpSendModal'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import {VoterOtpSendModal} from './VoterOtpSendModal'
import Aadharcheck from './Aadharcheck'
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import {employeeService} from '../../../../_services';
const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: "500px",
        maxHeight: "90vh", // Set maximum height
        overflowY: "auto"
    },
}));

const KycVerificationSystem = (props) => {

    const customer = props.customer;
    console.log("customer details is", customer)
    const user = employeeService.userValue;
    const [id, setId] = React.useState("pan");
  const [inp, setInp] = React.useState({});
  const [verificationSuccessMessage, setVerificationSuccessMessage] = React.useState("");
  const [verificationFailedMessage, setVerificationFailedMessage] = React.useState("");

  function changeId(idValue)
  {
     setVerificationFailedMessage("");
     setVerificationSuccessMessage("");
     setId(idValue);
  }

  function printDocument()
  {
     window.print();
  }
  function verifyid(itemid, token) {
    var info;

    if (id === "pan")
      info = {
        name: inp.name,
        number: inp.number,
        fuzzy: "true",
      };
    else if( id ==="dl")
      info = {
        number: inp.number,
        dob: inp.dob.toString().split("-").reverse().join("/"),
        issueDate: inp.issueDate.toString().split("-").reverse().join("/"),
      };
    else if ( id =="vid")
        info={
          epicNumber: inp.number,
          name: inp.name,
          state: inp.state,
        };

    fetch("https://signzy.tech/api/v2/snoops", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        service: "Identity",
        itemId: itemid,
        accessToken: token,
        task: "verification",
        essentials: info,
      }),
    })
      .then(async (response) => {
        const data = await response.json();
        console.log(response);
        let verificationMessage="Request id:"+data.response.instance.id+" . "+data.response.result.message+".  ";
        setVerificationSuccessMessage(verificationMessage);
        if((id==='vid' && !data.response.result.verification) || ((id ==='pan' || id ==='dl') && !data.response.result.verified))
           setVerificationFailedMessage(" Negative result. Please check carefully");
        else
           setVerificationFailedMessage("");
      })
      .catch((err) => {
        console.log(err);
        setVerificationSuccessMessage("");
        setVerificationFailedMessage("Verification Failed");
      });
  }

  const fetchIdentity = async () => {
    // console.log('process.env.REACT_APP_SIGNZY_API_KEY',process.env.REACT_APP_SIGNZY_API_KEY)
    var idname = "";
    var imgarr = [];

    if (id === "pan") {
      idname = "individualPan";
    } else if ( id ==="dl")  {
      idname = "drivingLicence";
    }
    else if ( id ==="vid")  {
      idname = "voterid";
    }

    fetch(
      "https://signzy.tech/api/v2/patrons/5f432a53a748d43a72a10fed/identities",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization:
            process.env.REACT_APP_SIGNZY_API_KEY,
        },
        body: JSON.stringify({
          type: idname,
          callbackUrl: "https://samruddhi.kaabilfinance.com",
          email: "dewanshu@kaabilfinance.com",
          images: imgarr,
        }),
      }
    )
      .then(async (response) => {
        const data = await response.json();
        verifyid(data.id, data.accessToken);
      })
      .catch((err) => {
        console.log(err);
      });
  };

    return (
        <div >

            <div>
                <h3>KYC VERIFICATION DETAILS</h3><br />

                <table>
                    <thead>
                        <tr>
                            <th style={{width:"20%"}}>Customer Name</th>
                            <th style={{width:"10%"}}>Role</th>
                            <th style={{width:"15%"}}>Aadhar No.</th>
                            <th style={{width:"15%"}}>Pan Card</th>
                            <th style={{width:"15%"}}>Voter Id</th>
                            {/* <th style={{width:"15%"}}>Driving Licence</th> */}
                            <th style={{width:"15%"}}>Mobile No.</th>
                          
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{customer.applicant.general_information.name}</td>
                            <td>Applicant</td>
                            <td>{customer.applicant.general_information.masked_aadhar === undefined|| customer.applicant.general_information.masked_aadhar=== ""? "" :(<>{customer.applicant.general_information.masked_aadhar} <Aadharcheck application_id={customer.application_id} customer={customer.applicant} role="applicant"/></>)}</td>
                            <td>{customer.applicant.general_information.pan_no === undefined|| customer.applicant.general_information.pan_no=== ""? "" :(<>{customer.applicant.general_information.pan_no} <PanOtpSendModal  application_id={customer.application_id} customer={customer.applicant} role="applicant" /></>)}</td>
                            <td>{customer.applicant.general_information.voter_id === undefined|| customer.applicant.general_information.voter_id=== ""? "" :(<>{customer.applicant.general_information.voter_id} <VoterOtpSendModal application_id={customer.application_id} customer={customer.applicant} role="applicant" /></>)}</td>
                            <td>{customer.applicant.general_information.contact}  {customer && customer.personal_details && customer.personal_details.otp_verified ? <span title="Verified">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-patch-check" viewBox="0 0 16 16" style={{color:"green",paddingLeft:"3px",cursor:"pointer"}}>
             <path fillRule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0"/>
             <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622-.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911z"/>
         </svg> </span>: <MobileOtpVerificationModel application_id={customer.application_id} customer={customer.applicant} role="applicant" />}</td>
                            {/* <td> <AadharOtpSendModal application_id={customer.application_id} customer={customer.applicant} role="applicant" /> </td> */}
                        </tr>

                        {customer && customer.co_applicants && customer.co_applicants.length > 0 && customer.co_applicants.map((x, idx) => {
                            return <tr key={idx}>
                                <td>{x.general_information.name}</td>
                                <td>Co-Applicant</td>
                                <td >{x.general_information.masked_aadhar === undefined|| x.general_information.masked_aadhar === ""? "" :(<>{x.general_information.masked_aadhar} <Aadharcheck application_id={customer.application_id} customer={x} role="co_applicant"/></>)}</td>
                                <td >{x.general_information.pan_no === undefined|| x.general_information.pan_no === ""? "" :(<>{x.general_information.pan_no} <PanOtpSendModal  application_id={customer.application_id} customer={x} role="co_applicant" index={idx} /></>)}</td>
                                <td>{x.general_information.voter_id ===undefined || x.general_information.voter_id ===""? "" :(<>{x.general_information.voter_id} <VoterOtpSendModal application_id={customer.application_id} customer={x} role="co_applicant" index={idx}/></>)}</td>
                                <td >{x.general_information.contact ===undefined || x.general_information.contact===""? "" :(<>{x.general_information.contact} <MobileOtpVerificationModel application_id={customer.application_id} customer={x} role="co_applicant" index={idx}/></>)} </td>
                                {/* <td> <AadharOtpSendModal application_id={customer.application_id} customer={x} role="co_applicant" /> </td> */}
                            </tr>
                        })}


                        {customer && customer.guarantors && customer.guarantors.length > 0 && customer.guarantors.map((x, idx) => {
                            return <tr key={idx}>
                                <td>{x.general_information.name}</td>
                                <td>Guarantor</td>
                                <td >{x.general_information.masked_aadhar === undefined|| x.general_information.masked_aadhar === ""? "" :(<>{x.general_information.masked_aadhar}<Aadharcheck application_id={customer.application_id} customer={x} role="guarantor"/></>)} </td>
                                <td >{x.general_information.pan_no === undefined|| x.general_information.pan_no === ""? "" :(<>{x.general_information.pan_no}  <PanOtpSendModal  application_id={customer.application_id} customer={x} role="guarantor" index={idx} /></>)}</td>
                                <td >{x.general_information.voter_id === undefined|| x.general_information.voter_id === ""?"":(<>{x.general_information.voter_id} <VoterOtpSendModal application_id={customer.application_id} customer={x} role="guarantor" index={idx}/></>)} </td>
                                <td >{x.general_information.contact === undefined|| x.general_information.contact === ""?"":(<>{x.general_information.contact} <MobileOtpVerificationModel application_id={customer.application_id} customer={x} role="guarantor" index={idx} /></>)}</td>
                                {/* <td> <AadharOtpSendModal application_id={customer.application_id} customer={x} role="guarantor" /> </td> */}
                            </tr>
                        })}
                    </tbody>
                </table>

            </div>

      
            {/* <div>
                <h3>MOBILE NUMBER VERIFICATION DETAILS</h3><br />

                <table>
                    <thead>
                        <tr>
                            <th>Customer Name</th>
                            <th>Customer Role</th>
                            <th>Mobile Number</th>
                            <th>Verification Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{customer.applicant.general_information.name}</td>
                            <td>Applicant</td>
                            <td>{customer.applicant.general_information.contact}</td>
                            <td>
                           
                                {customer && customer.personal_details && customer.personal_details.otp_verified ? <Button style={{ color: "green" }}
                                    variant="outlined" disabled>Verified <VerifiedUserIcon /></Button> : <MobileOtpVerificationModel application_id={customer.application_id} customer={customer.applicant} role="applicant" />}
                            </td>
                        </tr>
                        {customer && customer.co_applicants && customer.co_applicants.length > 0 && customer.co_applicants.map((x, idx) => {
                            return <tr key={idx}>
                                <td>{x.general_information.name}</td>
                                <td>Co-Applicant</td>
                                <td>{x.general_information.contact}</td>
                                <td> <MobileOtpVerificationModel application_id={customer.application_id} customer={x} role="co_applicant" index={idx} /> </td>
                            </tr>
                        })}
                        {customer && customer.guarantors && customer.guarantors.length > 0 && customer.guarantors.map((x, idx) => {
                            return <tr key={idx}>
                                <td>{x.general_information.name}</td>
                                <td>Guarantor</td>
                                <td>{x.general_information.contact}</td>
                                <td> <MobileOtpVerificationModel application_id={customer.application_id} customer={x} role="guarantor" index={idx} /> </td>
                            </tr>
                        })}
                    </tbody>
                </table>

            </div> */}

        </div>
    )
}

export default KycVerificationSystem