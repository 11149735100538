import React, { useState } from "react";
import PaymentSheetColumns from "./PaymentSheetColumns";
import PaymentSheetList from "./PaymentSheetList";
import EmptySheet from "./EmptySheet";
import { Button, Typography } from "@material-ui/core";
import { array, date } from "yup";
import { employeeService } from "../../../../_services";
import roleChecker from "../../../../_helpers/rolechecker";

export default function PaymentSheet({
  setFieldValue, pd, loan
}) {
  const assignRef = React.useRef(null);

  const [values, setValues] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [sum, setSum] = React.useState(0)

  // console.log(pd)

  const user = employeeService.userValue;


  const initializeValues = (pd) => {
    setFieldValue("payment_details", [...pd]);
    setValues([...pd]);
    setOpen(true)
  }

  const payment = [];

  const decimalRoundOff = (num) => {
    return Math.round(num * 100) / 100;
  };

  const handleChange = (idx) => (e) => {
    const { name, value } = e.target;

    const newvalues = [...values];
    if (name == "payment_amount") {
      newvalues[idx][name] = parseInt(value);

    }
    else {
      newvalues[idx][name] = value;
    }
    setFieldValue("payment_details", newvalues);
    // setFieldValue("rpg", 0);
    setValues(newvalues);
  };

  const handleAddRow = () => {
    const item = {
      payment_date: new Date().toISOString().substr(0, 10),
      payment_type: "collection",
      payment_mode: "cash",
      payment_ref: "",
      payment_amount: 0
    };
    console.log(item);
    setFieldValue("payment_details", [...values, item]);
    setValues([...values, item]);
  };

  const handleRemoveSpecificRow = (idx) => () => {
    const newvalues = [...values];

    newvalues.splice(idx, 1);
    setFieldValue("payment_details", newvalues);
    setValues(newvalues);
  };

  pd && !open && initializeValues(pd);

  if (values.length > 0) {
    return (
      <div>
        <div className="col-10 mx-auto text-center text-title">
          <Typography variant="h5">Payment List</Typography>
        </div>
        <div className="row clearfix">
          <div className="col-md-12 column">
            <table className="table table-bordered table-hover" id="tab_logic">
              <thead>
                <PaymentSheetColumns />
              </thead>
              <PaymentSheetList
                handleRemoveSpecificRow={handleRemoveSpecificRow}
                handleChange={handleChange}
                values={values}
              />
            </table>
            <div style={{ textAlign: "center", margin: 20 }}>
              {pd && pd.map((pa) => {
                if(pa.payment_type==="Collection")
                {
                  payment.push(pa.payment_amount)
                }
                
              })}
              <div style={{ float: "right" }}>
                <label>Total Collection</label>
                <input
                  disabled value={eval(payment.join('+'))}
                >
                </input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <EmptySheet />
        <div style={{ textAlign: "center", margin: 20 }}>
          {roleChecker.isAdminAccess(user.role) || (roleChecker.isAccountChecker(user.role) && loan.loan_status !== "Closed" && loan.loan_status !== "Auctioned") ? (<Button variant="outlined" onClick={handleAddRow}>
            Add Item
          </Button>) : null}
        </div>
      </div>
    );
  }
}
