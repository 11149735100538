export const LoanStages= {
    Sales: "Sales",
    Credit: "Credit",
    Operation: "Operation",
    Sanction: "Sanction",
    Disbursal: "Disbursal",
    Colending: "Colending",
    Collection:"Collection",
    Closed: "Closed",
    Rejected: "Rejected",
    Discarded:'Discarded',
    Suspense:'Suspense',
  };
  