import { fetchWrapper } from "../_helpers/fetch-wrapper";
const apiUrl = process.env.REACT_APP_API_URL;
const baseUrl = `${apiUrl}/legalcases`;

const createLegalCase =(params)=>{
    console.log("Test prams",params)
 return fetchWrapper.post(`${baseUrl}/createlegalcase`,params)   
}
const getAllLegalCases =()=>{
    return fetchWrapper.post(`${baseUrl}/getalllegalcases`)
}
const addNi138Document =(id,params)=>{
    return fetchWrapper.post(`${baseUrl}/addni138document/${id}`,params)
}
const deleteDocument=(params)=>{
    return fetchWrapper.post(`${baseUrl}/deletedocument`,params)
}
const getLegalCase =(id)=>{
    return fetchWrapper.get(`${baseUrl}/getlegalcase/${id}`)
}
const getCourtCases=(params)=>{
    return fetchWrapper.post(`${baseUrl}/getcourtcases`,params)
}
const addNewCase=(params)=>{
return fetchWrapper.post(`${baseUrl}/addnewcase`,params)
}
const addHearing=(id,params)=>{
    console.log("Test",id,params)
    return fetchWrapper.post(`${baseUrl}/addhearing/${id}`,params)
}
const addStage=(id,params)=>{
    return fetchWrapper.post(`${baseUrl}/addstage/${id}`,params)
}
const updateCase=(id,params)=>{
    return fetchWrapper.put(`${baseUrl}/updatecase/${id}`,params)
}
const closeCase=(id)=>{
    return fetchWrapper.put(`${baseUrl}/closecase/${id}`)
}
const deleteHearing=(params)=>{
    return fetchWrapper.post(`${baseUrl}/deletehearing`,params)
}
const deleteStage=(params)=>{
    return fetchWrapper.post(`${baseUrl}/deletestage`,params)
}
export const legalServices={
    createLegalCase,
    getAllLegalCases,
    addNi138Document,
    deleteDocument,
    getLegalCase,
    addNewCase,
    addHearing,
    addStage,
    getCourtCases,
    updateCase,
    closeCase,
    deleteHearing,
    deleteStage
} 
