import React from 'react'
import kaabilLogo from "../assets/kaabil.png"
import { Button } from '@material-ui/core'
import { history } from '../_helpers'

const AccessDenied = () => {

    const backToHome = () =>{
        history.push("/home")
    }
    return (
        <>
            <h1 style={{ marginTop: "30px", marginLeft: "35%", color: "red" }}>Access Denied!!!</h1>
            <div style={{ marginTop: "10%" }}>
                <h5 style={{ marginLeft: "40%", marginTop: "30px", color: "blue" }}>We are Sorry...</h5>
                <h5 style={{ marginLeft: "30%", marginTop: "30px", color: "blue" }}>The page you are trying has restricted access. </h5>
                <h6 style={{ marginLeft: "34%", color: "blue" }}>Please refer to your system administrator</h6>
            </div>
            <Button style={{marginLeft:"42%", marginTop:"50px"}} variant="contained" color="primary" onClick={()=>backToHome()}>
               Go Back
            </Button>
        </>
    )
}

export default AccessDenied