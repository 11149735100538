import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import KycData from '../../Applications/ApplicationData/KycData';
import { TabContext } from '../TabBar';
import { useContext } from "react";
import CibilData from './CibilData';
import CersaiReport from './CersaiReport';
import CamLetter from './CamLetter';
import PdReports from './PdReports';
import ApplicationFormDetails from '../../Applications/ApplicationData/ApplicationFormDetails';
import ApplicationForms from './ApplicationForms';
// import ValuationReport from './ValuationReport';
import Switch from '@material-ui/core/Switch';
import { history } from "../../../../_helpers";
import ValuationRcuLegalReport from "./ValuationRcuLegalReport"

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


const CreditTabBar = () => {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [applicant, setApplicant] = React.useState();
    let TabContextValue = useContext(TabContext)
    let cust = TabContextValue.cust
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    // async function getApplicant() {
    //     await customerService.searchCustomer({ customer_id: cust.applicant.customer_id })
    //         .then(res => setApplicant(res[0]))
    //         .catch(err => console.log(err))
    // }

    // useEffect(() => {
    //     getApplicant()
    // }, [])

    const [state, setState] = React.useState({
      switch_panel: true
  });
    const handlePanel = (event) => {
      setState({ ...state, [event.target.name]: event.target.checked });
  
      if(!event.target.checked){
          history.push(`/home/sblloans/details/${cust._id}?isold_view=1`)
          window.location.reload()
      }
  
      console.log(event.target.checked)
  };
  return (
     <div className={classes.root}>
         <div style={{ display: "flex", justifyContent: "flex-end", flexDirection: "row" ,marginRight:"3rem"}} >
                <label  >LMS</label> <div style={{ marginTop: "-10px" }} >
                    <Switch
                        checked={state.switch_panel}
                        onChange={handlePanel}
                        color="primary"
                        name="switch_panel"
                    // inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </div><label style={{ color:'blue' }} >LOS</label>
            </div>
      <AppBar position="static" style={{backgroundColor:"#1E90FF"}}>
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="KYC Details" {...a11yProps(0)} />
          <Tab label="Cibil Details" {...a11yProps(1)} />
          <Tab label="PD Report" {...a11yProps(2)} />
          <Tab label="Cersai Report" {...a11yProps(3)} />
          <Tab label="CAM Letter" {...a11yProps(4)} />
          <Tab label="Reports" {...a11yProps(5)} />
          <Tab label="Application Form" {...a11yProps(6)} />

        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <KycData customer={cust} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CibilData />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PdReports />
      </TabPanel>
      <TabPanel value={value} index={3}>
      <CersaiReport />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <CamLetter/>
      </TabPanel>
      <TabPanel value={value} index={5}>
        {/* <ValuationReport/>  */}
        <ValuationRcuLegalReport/>
       </TabPanel>
      <TabPanel value={value} index={6}>
        <ApplicationForms />
      </TabPanel>
    </div>
  )
}

export default CreditTabBar