import { Modal, Box, Typography } from "@material-ui/core"
import { useState } from "react";
import { Button } from "@material-ui/core";
import { Table, Tbody, Thead, Tr, Td, Th } from "react-super-responsive-table";
import { ledgerService } from "../../_services/ledger.service";
import { OtpVerificationModal } from "./OtpVerificationModal";

export const ApproveCheckInModal = (props) => {
    const [open, setOpen] = useState(false)
    const [pendingTransactions, setPendingTransactions] = useState(props.pendingTransactions)
    const ledgers = props.ledgers
    const employees = props.employees
    const transaction_type = props.transaction_type
 

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleCheck = (idx) => {
        let transactions = [...pendingTransactions]
        if (transactions[idx].checked) {
            transactions[idx].checked = !transactions[idx].checked
            setPendingTransactions(transactions)
        } else {
            transactions[idx].checked = true
            setPendingTransactions(transactions)
        }
    }


    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "750px",
        minHeight: "350px",
        maxHeight: "580px",
        bgcolor: "background.paper",
        border: "2px solid #000",
        overflow: "scroll",
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Button onClick={handleOpen} variant='contained' style={{ backgroundColor: 'green', color: 'white' }}>{transaction_type==='CheckOut'?'Approve Check Out':'Approve Check In'}</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box style={{ fontSize: 'medium' }} id="modal-modal-title" variant="h6" component="h2">
                        <div style={{ padding: 'auto' }}>
                            <div style={{ width: '100%', display: 'flex', border: '1px solid black', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                                <h4>Approve Check-In Requests</h4>
                                <div style={{ width: '100%', border: '1px solid black', margin: '20px', padding: '20px' }} >
                                    <Table style={{ textAlign: 'center' }}>
                                        <Thead>
                                            <Tr>
                                                <Th style={{ width: '20%', textAlign: 'center' }} >Amount</Th>
                                                <Th style={{ width: '20%', textAlign: 'center' }} >Branch</Th>
                                                <Th style={{ width: '20%', textAlign: 'center' }} >Check-In By</Th>
                                                <Th style={{ width: '20%', textAlign: 'center' }} >Status</Th>
                                                <Th style={{ width: '20%', textAlign: 'center' }} ></Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {pendingTransactions && pendingTransactions.length > 0 ?
                                                pendingTransactions.map((transaction, idx) => {
                                                    return <Tr key={transaction._id}>
                                                        <Td>
                                                            {transaction.cash_summary &&
                                                                <>{
                                                                    transaction.cash_summary.total_amount.toLocaleString("en-IN", {
                                                                        // maximumFractionDigits: 2,
                                                                        style: "currency",
                                                                        currency: "INR",
                                                                    })}
                                                                </>}
                                                        </Td>
                                                        <Td>
                                                            {ledgers[`${transaction.debit_ledger_id}`]}
                                                        </Td>
                                                        <Td>
                                                            {transaction.verification_details && <>{employees[`${transaction.verification_details.requester_emp_id}`]}</>}
                                                        </Td>
                                                        <Td>
                                                            Approval Pending
                                                        </Td>
                                                        <Td>
                                                            <input type="checkbox" onClick={() => handleCheck(idx)} />
                                                        </Td>
                                                    </Tr>
                                                })
                                                :
                                                <Tr>
                                                    <Td align='center' colSpan='5'>Data not available</Td>
                                                </Tr>
                                            }
                                        </Tbody>
                                    </Table>
                                </div>
                                {/* <Button
                                    variant="outlined"
                                    color="primary"
                                    onKeyDown={handleKeypress}
                                    onClick={handleSubmit}
                                >
                                    Approve
                                </Button> */}
                                <OtpVerificationModal pendingTransactions={pendingTransactions} />
                            </div>
                        </div>
                        {
                            console.log(pendingTransactions)
                        }
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}