import React from 'react'
import { letter } from "../../../_services/lettertracking.service";
import { useState, useEffect } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td  } from 'react-super-responsive-table';
import { Link } from "react-router-dom";
import { Add, Edit } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import datehelper from "../../../_helpers/datehelper"


const LegalLetterList = () => {


    const [letters, setLetters] = useState([])

    const getLetter = () =>{
        letter.showLegalLetter()
        .then((data)=> {
            setLetters(data)
        })
        .catch((err)=> {
            console.log(err, "Something Went Wrong")
        })
    }

    useEffect(() => {
        getLetter()
  
     }, [])

  return (
    <>
      <h2>138 Legal Letters</h2><br/><hr></hr><br/>

      <Button style={{ marginLeft: "30px" }} variant="outlined" aria-label="increase" href={`/home/letters/legalnotice`}>
        <Add fontSize="small" />  Generate Legal Letter
      </Button><br/>
       
       <Table>
        <Thead>
            <Tr>
                <Th style={{width:"20%"}}>Date</Th>
                <Th style={{width:"20%"}}>Name</Th>
                <Th style={{width:"20%"}}>Account Number</Th>
                <Th style={{width:"20%"}}>Action</Th>
            </Tr>
        </Thead>
        <Tbody>
            {letters && letters.map((e)=> {
                return <Tr>
                    <Td>{datehelper.displayDate(new Date(e.createdAt))}</Td>
                    <Td>{e.name}</Td>
                    <Td>{e.acc_no}</Td>
                    <Td style={{width: "10%"}}><Link to={`/home/letters/legalnotice/${e._id}`} className="btn btn-sm btn-primary mr-1"><Edit /> </Link></Td>
                </Tr>
            })}
        </Tbody>
       </Table>
      
    </>
  )
}

export default LegalLetterList