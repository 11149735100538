import React from 'react'
import { Paper } from "@material-ui/core";
import { useState, useEffect } from 'react';
import {letter} from "../../../_services/lettertracking.service"
import LegalNoticeLetter from "./LegalNoticeLetter"
import { useParams } from "react-router-dom";

const LegalNotice = () => {

    let id = window.location.pathname.split("/")
     id = id[4]

    const [values, setValues] = useState({
        name: "",
        father_name: "",
        relation:"",
        title: "",
        head: "",
        address: "",
        acc_no:"",
        loan_amt:"",
        received_date:"",
        loan_type:"",
        cheque_no:"",
        cheque_amt:"",
        cheque_date:"",
        cheque_bank_name:"",
        cheque_branch_name:"",
        bank_name:"",
        branch_name:"",
        response_date:"",
        reason:"",

    })

    const {title, name, head, relation, father_name, address, acc_no, loan_amt, received_date, loan_type, cheque_no,cheque_amt, cheque_date, cheque_bank_name, cheque_branch_name, bank_name, branch_name, response_date, reason} = values

    const [show, setShow] = useState(false)

    const handleChange = (e) => {
        setValues({...values, [e.target.name]: e.target.value})    
    }

    const getById = () => {
        letter.findLegalLetterById(id)
        .then((data)=>{
            console.log(data)
            setValues(data)
        })
        .catch((err)=> {
            console.log(err)
        })
    }

    useEffect(() => {
        if(id){
            getById()
        }

    }, [])
    

    const handleSubmit = () => {
        letter.createLegalLetter(values)
        .then((data)=> {
            console.log(data)
            setShow(true)
            alert("Added Successfully")
        })
        .catch((err)=> {
            alert("Something went wrong")
        })

    }

    const handleUpdate = () => {
        letter.updateLegalLetter(id, values)
        .then((data) => {
            setShow(true)
            alert("Updated Successfully")
        })
        .catch((err)=> {
            console.log(err)
            alert("Please Try Again")
        })
    }

    function convert(str) {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
      }


    return (
        <>
        {show === true ? <LegalNoticeLetter title={title} name={name} head={head} relation={relation} father_name={father_name}
                                            address={address} acc_no={acc_no} loan_amt={loan_amt} received_date={received_date}
                                            loan_type={loan_type} cheque_no={cheque_no} cheque_amt={cheque_amt} cheque_date={cheque_date}
                                            cheque_bank_name={cheque_bank_name} cheque_branch_name={cheque_branch_name} bank_name={bank_name}
                                            branch_name={branch_name} response_date={response_date} reason={reason} /> :
          <>
            <Paper
                style={{
                    padding: 20,
                }}
            >
                <h2>Person's Details :-</h2><br />

                <div>
                    <label>Title <select className="form-select form-select-sm" required value={values.title} name='title' aria-label=".form-select-sm example" onChange={handleChange}>
                        <option selected></option>
                        <option >श्री</option>
                        <option >श्रीमती</option>
                        <option >कुमारी</option>
                    </select></label>
                    <label style={{ marginLeft: "30px" }}>Name <input type='text' onChange={handleChange} value={values.name} name='name' /> </label>
                    <label style={{ marginLeft: "30px" }}> Relation <select className="form-select form-select-sm" required value={values.relation} name='relation' aria-label=".form-select-sm example" onChange={handleChange}>
                        <option selected></option>
                        <option >पुत्र</option>
                        <option >पत्नी</option>
                        <option >पुत्री</option>
                    </select></label>
                    <label style={{ marginLeft: "30px" }}> Head <select className="form-select form-select-sm" required value={values.head} name='head' aria-label=".form-select-sm example" onChange={handleChange}>
                        <option selected></option>
                        <option >श्री</option>
                        <option >श्रीमती</option>
                    </select>
                    </label>
                    <label style={{ marginLeft: "30px" }}>Father Name <input type='text' value={values.father_name} onChange={handleChange} name='father_name' /> </label>
                    <label style={{ marginLeft: "30px" }}>Address <input type='text' required value={values.address} onChange={handleChange} name='address' /> </label>
                </div>
            </Paper><br />

            <Paper
                style={{
                    padding: 20,
                }}
            >
                <h2>Loan Details</h2><br />

                <div>
                    <label style={{ marginLeft: "30px" }} >  Account Number <input type='text' value={values.acc_no} name='acc_no' onChange={handleChange} /></label>
                    <label style={{ marginLeft: "30px" }} >  Loan Amount <input type='number' name='loan_amt' value={values.loan_amt} onChange={handleChange} /></label>
                    <label style={{ marginLeft: "30px" }} >  Amount Received Date <input type='date' name='received_date' value={convert(values.received_date)} onChange={handleChange} /></label>
                    <label style={{ marginLeft: "30px" }} >  Loan Type <input type='text' name='loan_type' value={values.loan_type} onChange={handleChange} /></label>
                </div>
            </Paper><br />

            <Paper
                style={{
                    padding: 20,
                }}
            >
                <h2>Cheque Details</h2><br />

                <div>
                    <label style={{ marginLeft: "30px" }} >  Cheque Number <input type='text' value={values.cheque_no} name='cheque_no' onChange={handleChange} /></label>
                    <label style={{ marginLeft: "30px" }} >  Cheque Amount <input type='number' value={values.cheque_amt} name='cheque_amt' onChange={handleChange} /></label>
                    <label style={{ marginLeft: "30px" }} >  Cheque Date <input type='date' name='cheque_date' value={convert(values.cheque_date)} onChange={handleChange} /></label>
                    <label style={{ marginLeft: "30px" }} >  Bank Name <input type='text' name='cheque_bank_name' value={values.cheque_bank_name} onChange={handleChange} /></label>
                    <label style={{ marginLeft: "30px" }} >  Branch Name <input type='text' name='cheque_branch_name' value={values.cheque_branch_name} onChange={handleChange} /></label>
                </div>
            </Paper><br/>

            <Paper
                style={{
                    padding: 20,
                }}
            >
                <h2> Cheque Submit Details</h2><br />

                <div>
                    <label style={{ marginLeft: "30px" }} >  Bank Name <input type='text' value={values.bank_name} name='bank_name' onChange={handleChange} /></label>
                    <label style={{ marginLeft: "30px" }} >  Branch Name <input type='text' name='branch_name' value={values.branch_name} onChange={handleChange} /></label>
                    <label style={{ marginLeft: "30px" }} >  Response Date <input type='date' name='response_date' value={convert(values.response_date)} onChange={handleChange} /></label>
                    <label style={{ marginLeft: "30px" }} >  Reason <input type='text' name='reason' value={values.reason} onChange={handleChange} /></label> 
                </div>
            </Paper><br/>
          {
            id ? <button className='btn btn-primary' style={{marginLeft:"40%", width:"200px"}} onClick={handleUpdate} >Update Letter</button> :   <button className='btn btn-primary' style={{marginLeft:"40%", width:"200px"}} onClick={handleSubmit} >Create Letter</button>


          }

          </>
        }
            
        
        </>
    )
}

export default LegalNotice