import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
  withStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

import * as Yup from "yup";
import { uploadFileToBucket } from "../../../../_helpers/upload"
import swal from 'sweetalert';

import { Formik, Field, Form, ErrorMessage } from "formik";
import { ApprovalRequestFilter } from '../../../MakerCheckerFramework/ApprovalRequestFilter';
import Fab from "@material-ui/core/Fab";
import SaveIcon from "@material-ui/icons/Save";
import { Edit } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import {loanService}  from "../../../../_services/loan.service";
import { employeeService } from "../../../../_services";
import FormElement from "../../../uielements/FormElement";
import { ColendingSubStages } from "../../../../constants/colendingsubstages";
import workflowHelper from "../../../../_helpers/workflowhelper";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "60%",
    overflow: "scroll",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    minWidth: 450,
  },
}));

export default function EditColenderModal(props) {
  const customer = props.customer;
  const rows=props.rows;
  console.log(rows,"ddddddddwwwwwwwwwwwww")
  const applicant_type=props.applicant_type;
  const classes = useStyles();
  const [url, setUrl] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const user = employeeService.userValue;
  const formikRef = React.useRef();
  
  const inputFields=['tcolender.co_lender_name',
                    'tcolender.co_lender_account_num',
                  'tcolender.co_lender_status',
                'tcolender.co_lender_status_date',
              'tcolender.co_lender_sanction_amount',
            'tcolender.co_lender_sanction_date',
              'tcolender.co_lender_interest_rate',
              'tcolender.co_lender_tenure',
              'tcolender.co_lender_emi_day',
              'tcolender.co_lender_received_amount',
              'tcolender.co_lender_received_date',
              'tcolender.co_lender_stage_emp_id',
              'tcolender.co_lender_insurance_amount',
              'tcolender.co_lender_rps_report',
            'tcolender.co_lender_pf_amount']
  
  

  const initialValues = {'tcolender':{

  }};
  rows.map((r)=>{
          inputFields.map((ip)=> {
            //console.log(ip)
            let splitFields=ip.split(".")
            if(r.key===ip)
            {
              if(splitFields.length==2)
              {
                 initialValues[splitFields[0]][splitFields[1]]=r.value;
              }
       
            }
          })
  });

  console.log(initialValues);

let colender_name = initialValues.tcolender.co_lender_name;

const maxDate = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); 
  const yyyy = today.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
}

  const validationSchema = Yup.object().shape({
    tcolender:Yup.object().shape({
      co_lender_name: Yup.string().required("This field is required"),
      co_lender_account_num:Yup.string().required("This field is required"),
      co_lender_status:Yup.string().required("This field is required"),

    }),
  });


  
  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  function handleChange(fieldName,value,setFieldValue)
  {
      setFieldValue(fieldName,value);
  }

  const bucket = process.env.REACT_APP_s3docsBucket

    const upload = async (e) =>{
      console.log("uploading...")
      let selectedFile = e.target.files[0]
      let filename = selectedFile.name.split(".")
      filename = filename[0]
      console.log("filename")
      await uploadFileToBucket(bucket, "application/pdf", `${colender_name}/insurance_report/${customer.application_id}_${filename}.pdf`, selectedFile)
      .then((x)=>{
        console.log(x) 
         setUrl(x)
      })
      .catch((err)=>{console.log(err)})
    }

  console.log(url)

  async function onSubmit(fields, { setSubmitting }) {
    fields = {
      tcolender:{
      co_lender_name: fields["tcolender"]["co_lender_name"] ,
      co_lender_account_num: fields["tcolender"]["co_lender_account_num"] ,
      co_lender_status: fields["tcolender"]["co_lender_status"],
      co_lender_status_date: Date.now(),
      co_lender_sanction_amount: fields["tcolender"]["co_lender_sanction_amount"],
      co_lender_sanction_date: fields["tcolender"]["co_lender_sanction_date"],
      co_lender_interest_rate: fields["tcolender"]["co_lender_interest_rate"],
      co_lender_tenure: fields["tcolender"]["co_lender_tenure"],
      co_lender_emi_day: fields["tcolender"]["co_lender_emi_day"],
      co_lender_received_amount: fields["tcolender"]["co_lender_received_amount"],
      co_lender_received_date: fields["tcolender"]["co_lender_received_date"],
      co_lender_stage_emp_id: fields["tcolender"]["co_lender_stage_emp_id"],
      co_lender_insurance_amount: fields["tcolender"]["co_lender_insurance_amount"],
      co_lender_rps_report: url,
      co_lender_pf_amount: fields["tcolender"]["co_lender_pf_amount"]
      }
    }
     // check for Amount Recieved status
     if(fields["tcolender"]["co_lender_status"] == ColendingSubStages.AmountReceivedFromColender)
     {
        //check sanction amount. it should be lower than loan sanction amount
        //amount received should be lower than sanction amount
        //receive date should be less than tomorrow
        //roi should be there
        //tenure should be there and greater than 12
 
        let sanctionAmount=fields["tcolender"]["co_lender_sanction_amount"];
        let receivedAmount=fields["tcolender"]["co_lender_received_amount"];
        let receiveDate=fields["tcolender"]["co_lender_received_date"];
        let roi=fields["tcolender"]["co_lender_interest_rate"];
        let tenure=fields["tcolender"]["co_lender_tenure"];
        let emiDay=fields["tcolender"]["co_lender_emi_day"];
        let receiveDateObject= new Date(receiveDate);
        console.log(receiveDateObject);
        let maxDateObject = new Date(maxDate());
        console.log(maxDate());
        console.log(maxDateObject);
        let dateDiff = maxDateObject - receiveDateObject;
        let differenceDays = parseInt(Math.ceil(dateDiff / (1000 * 3600 * 24)));
        console.log("difference days="+differenceDays);
        if(!receiveDate || differenceDays <-1 || differenceDays >365)
        {
            window.alert("please fill valid receive date");
            return;
        }
        console.log(receiveDate);
        if(!sanctionAmount || sanctionAmount > customer.loan.sanction_amount)
        {
            window.alert("please fill valid sanction amount");
            return;
        }
        if(!receivedAmount || receivedAmount >sanctionAmount)
        {
           window.alert("Please fill valid receive amount");
           return;
        }
        if(!roi || roi >100)
        {
         window.alert("Please fill valid ROI");
         return;
        }
        if(!tenure || tenure <12)
        {
         window.alert("Please fill valid tenure");
         return;
        }
        if(!emiDay || emiDay <1 || emiDay >31)
        {
         window.alert("Please fill valid emiDay");
         return;
        }
 
     }
    let params={};
    // console.log(fields);
    let newColender={}
    inputFields.map((ip)=> {
      //console.log(ip)
      let splitFields=ip.split(".")
      if(!newColender[splitFields[1]])
      {
        newColender[splitFields[1]]={}
      }
      newColender[splitFields[1]]=fields[splitFields[0]][splitFields[1]]      
    });
   
    //console.log(newApplicant);
    //return;
  
      params['co_lending']=[];
      customer.co_lending.map((c)=> {
         if(c.co_lender_name && c.co_lender_name===initialValues["tcolender"]['co_lender_name'])
         {
            Object.keys(c).map((y)=> {
               console.log("Y =="+y); 
               if(!newColender[y])
               {
                 newColender[y]=c[y];
               }
            });
            params['co_lending'].push(newColender);
         }
         else
         {
          params['co_lending'].push(c);
         }
      })
    
    console.log(params);   

 const id = customer._id
 const index = customer.co_lending.findIndex(c => c._id === rows._id);
  let {_id, ...paramsdata} = params.co_lending[index];
// const paramsdata = params.co_lending[0]
    if (fields["tcolender"]["co_lender_status"] === "ApprovedFromCredit") {
      ApprovalRequestFilter.RequestFilter({
        service: 'loanService',
              api: 'updateColender',
              id: customer._id,
              task: 'Approved From Credit',
              updated_fields:['co_lender_name', 'co_lender_account_num', 'co_lender_status', 'co_lender_status_date', 'co_lender_sanction_amount', 'co_lender_sanction_date', 'co_lender_interest_rate', 'co_lender_tenure', 'co_lender_emi_day', 'co_lender_received_amount', 'co_lender_received_date', 'co_lender_stage_emp_id', 'co_lender_insurance_amount', 'co_lender_rps_report', 'co_lender_pf_amount'],
              sensitive_fields: [],
              new_values: {id, ...params, ...paramsdata },
              old_values: {},
              identification_key: 'Loan Number',
              identification_value: customer.application_id,
              emp_id: user.emp_id,
              approval_needed: true,
              approved: false,
              approval_access: 'isColendingManagerAccess',
              product: 'sbl'
      }).then(async(ns) => {
        console.log('resp is',ns)
        const acUserData = await loanService.getEmpIdRoleWise({ role: 'AC' }); 
        console.log("AC User Data:", acUserData);
        if (Array.isArray(acUserData) && acUserData.length > 0) { // Ensure acUserData is an array and not empty
          console.log("Calling Redis Helper for sending Notifications");
          await Promise.all(
              acUserData.map((employee) => {
                  const emp_id = employee.emp_id; // Extract emp_id for each user
      
                  return workflowHelper.isRedisNotifications({
                    emp_id: user.emp_id,
                    message: `You have an approval request from ${user.emp_id} ,Please review and take necessary action.`,
                    action_link: `https://samruddhi.kaabilfinance.com/home/colending/dashboard`,
                    action_taken_by: emp_id
                });
              })
            );
          }
        console.log("Colender Updated",ns);
        swal({
          title: "Success",
          text: "Request Sent to concerned Authority",
          icon: "success",
          button: "Okay"
        }).then(() => {
          window.location.reload();
        });
      }).catch((err)=> {
        console.log(err,"errorerrorerror");
        swal({
          title: "Error",
          text: err.toString(), // Displaying the error message
          icon: "error",
          button: "Okay"
        });
      });
    }
    else{
      await loanService.updateColender(customer._id,params)
      .then((ns)=> {
        console.log("Colender Updated");
        window.alert("Details added");
        window.location.reload();
      }).catch((err)=> {
        console.log(err);
        window.alert("error adding details");
      })
    }
    //return;
    
    setSubmitting(false);
  }

  return (
    <div>
     
     <Button
        onClick={()=>handleOpen()}
        variant="contained"
        color="primary"
      >
        <Edit></Edit>
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Edit Details</h2>
      
              <Formik
                innerRef={formikRef}
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize={true}
                id="transition-modal-description"
              >
              {({ errors, touched, isSubmitting, setFieldValue,values }) => {
                  return (
                      <Form>
                            <TableContainer>
                                <Table className={classes.table} aria-label="customized table">
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell>
                                        {applicant_type==="co_applicant" && ("Co-Applicant Details")}
                                        {applicant_type==="guarantor" && ("Guarantor Details")}
                                      </StyledTableCell>
                                      <StyledTableCell align="right">
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                          {rows.map((row) => (
                              <StyledTableRow key={row.label}>
                                <StyledTableCell component="th" scope="row">
                                  {row.label}
                                </StyledTableCell>
                                {row.editable && (row.type=== "text" || row.type==="number" || row.type==="date") && (
                                  <StyledTableCell align="right">
                                    <FormElement
                                    type={row.type}
                                    name={row.key}
                                    label={row.label}
                                    errors={errors}
                                    touched={touched}
                                  />
                                  </StyledTableCell>
                                )}
                                {row.editable &&  (row.type === "file") &&(
                                  <input
                                  type={row.type}
                                  name={row.key}
                                  onChange={(e)=>upload(e)}
                                  // label={row.label}
                                  // errors={errors}
                                  // touched={touched}
                                />
                                )}
                                {row.editable && row.type=== "select" && (
                                  <StyledTableCell align="right">
                                    <Field
                                        as="select"
                                        name={row.key}
                                        label={row.label}
                                        errors={errors}
                                        touched={touched}
                                      >
                                      <option value='' disabled>select option</option>  
                                      {row.options && row.options.map((o)=> {
                                        return <option value={o}>{o}</option>
                                      })}
                                    </Field>
                                  </StyledTableCell>
                                )}

                                {!row.editable && (
                                  <StyledTableCell align="right">
                                    <FormElement
                                    type="text"
                                    disabled={true}
                                    name={row.key}
                                    label={row.label}
                                    errors={errors}
                                    touched={touched}
                                  />
                                  </StyledTableCell>
                                )}
                                
                                
                              </StyledTableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                                              
                  <hr/>
                        <div className="row justify-content-around">
                        <Fab
                          type="submit"
                          disabled={isSubmitting}
                          color="primary"
                          aria-label="save"
                        >
                          {isSubmitting && (
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                          )}
                          <SaveIcon />
                        </Fab>
                      </div>
                      </Form>
                  );
                 }}
              </Formik>         
          </div>
        </Fade>
      </Modal>
    </div>
  );
}