import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse
} from "@material-ui/core";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
// import { SmsOutlined } from "@material-ui/icons";
import GavelOutlinedIcon from '@material-ui/icons/GavelOutlined';
import MailIcon from '@material-ui/icons/Mail';
import roleChecker from "../../../_helpers/rolechecker";

export default function CourtCaseMenu(props) {
  const [open, setOpen] = React.useState(false);
  const { path } = props.match;
  const user = props.user;


  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  return (
    <React.Fragment>
      <ListItem button onClick={handleClick}>
        <ListItemIcon><GavelOutlinedIcon /></ListItemIcon>
        <ListItemText primary="Court Cases" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" style={{ paddingLeft: "50px" }}>
          <ListItem
            hidden={
              !roleChecker.isLegalTeamAccess(user.role)
            }
            button
            key="one"
            component="a"
            href={`/home/courtcases/ni138`}
          >
            <ListItemText primary="NI 138" />
          </ListItem>
          <ListItem
            hidden={
              !roleChecker.isLegalTeamAccess(user.role)
            }
            button
            key="one"
            component="a"
            href={`/home/courtcases/arbitration`}
          >
            <ListItemText primary="Arbitration" />
          </ListItem>
          <ListItem
            hidden={
              !roleChecker.isLegalTeamAccess(user.role)
            }
            button
            key="one"
            component="a"
            href={`/home/courtcases/execution`}
          >
            <ListItemText primary="Execution" />
          </ListItem>
        </List>
      </Collapse>
    </React.Fragment>
  )
}
