import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import * as Yup from "yup";

import { Formik, Field, Form, ErrorMessage } from "formik";

import Fab from "@material-ui/core/Fab";
import SaveIcon from "@material-ui/icons/Save";
import { Button } from "@material-ui/core";
import {loanService}  from "../../../_services/loan.service";
import { LoanStages } from "../../../constants/loanstages";
import { employeeService } from "../../../_services";
import SendRedisNotifications from '../../../_components/RedisNotifications/SendRedisNotifications'
import workflowHelper from "../../../_helpers/workflowhelper";


const useStyles = makeStyles((theme) => ({
  modal: {
    width: "50%",
    overflow: "scroll",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    minWidth: 650,
  },
}));

export default function StageAssignModal(props) {
  const loanData = props.loan_data;
  const classes = useStyles();
  const user = employeeService.userValue;
  const [open, setOpen] = React.useState(false);
  const [nextStage, setNextStage] = React.useState("");
  const [employeeList,setEmployeeList] =React.useState([]);


  React.useEffect(() => {
    let stage=loanData.stage;
    console.log(loanData);
    if ( stage === LoanStages.Sales) setNextStage(LoanStages.Credit);
    else if (stage === LoanStages.Credit) setNextStage(LoanStages.Sanction);
    else if (stage === LoanStages.Sanction) setNextStage(LoanStages.Disbursal);
    else  setNextStage(LoanStages.Collection);
   
    employeeService.getAll()
    .then((el)=> {
      console.log(el);
      let fel=el;
      if(stage === LoanStages.Sales)
      {
         fel=el.filter((ei)=> {
           return (ei.role=="CCM" && ei.branch.branch_cluster == loanData.branches[0].branch_cluster)
          })
      
      }
      setEmployeeList(fel);
    })
    .catch((err)=> {
      console.log(err);
    });
  }, []);



  const initialValues = {
    next_stage_emp_id: "",
  };


  const validationSchema = Yup.object().shape({
    next_stage_emp_id: Yup.string().required("This field is required")
  });
  
  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  function handleChange(fieldName,value,setFieldValue)
  {
    if(fieldName=="next_stage_emp_id"){
      let newValue=parseInt(value);
      setFieldValue(fieldName,newValue);
    }
  }

  
  async function onSubmit(fields, { setSubmitting }) {
    let params={...fields,
                 stage: nextStage,
                 updated_by_emp_id:user.emp_id,
                }
    console.log(params);            
    await loanService.changeStage(loanData._id,params)
    .then((ns)=> 
     {
      console.log("Calling Redis Helper for sending Notifications")
      workflowHelper.isRedisNotifications({emp_id: user.emp_id, message:"Stage change", action_link:'https://samruddhi.kaabilfinance.com/home/sblloans/details/loanData._id', action_taken_by: params.next_stage_emp_id})
      console.log("params.next_stage_emp_id",params.next_stage_emp_id);
      console.log("stage changed");
      window.alert("Stage changed");
      window.location.reload();
    })
    .catch((err)=> {
      console.log(err);
      window.alert("error changing stage");
    })
    setSubmitting(false);
  }

  return (
    <div>
     
     <Button
        onClick={handleOpen}
        variant="contained"
        color="primary"
      >
        SEND FOR {nextStage}
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Change Loan Status</h2>
      
              <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize={true}
                id="transition-modal-description"
              >
              {({ errors, touched, isSubmitting, setFieldValue }) => {
                  return (
                    <Form>
                      <div className="form-row">
                        <div className="form-group col">
                          <label>Select Next Stage Owner</label>
                          <Field
                            name="next_stage_emp_id"
                            as="select"
                            onChange={(e)=>handleChange("next_stage_emp_id",e.target.value,setFieldValue)}
                            className={
                              "form-control" +
                              (errors.next_stage_emp_id && touched.next_stage_emp_id ? " is-invalid" : "")
                            }
                          >
                            <option value="" disabled></option>
                            {
                               employeeList && employeeList.map((ei,idx)=> 
                                (
                                  <option value={ei.emp_id} key={idx}>{ei.emp_id} - {ei.first_name} {ei.last_name} </option>
                                  
                                )
                              )
                            }
                          </Field>
                          <ErrorMessage
                            name="next_stage_emp_id"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        </div>
                        <hr/>
                        <div className="row justify-content-around">
                        <Fab
                          type="submit"
                          disabled={isSubmitting}
                          color="primary"
                          aria-label="save"
                        >
                          {isSubmitting && (
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                          )}
                          <SaveIcon />
                        </Fab>
                      </div>
                      </Form>
                  );
                 }}
              </Formik>
          
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
