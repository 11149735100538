import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import * as Yup from "yup";

import { Formik, Field, Form, ErrorMessage } from "formik";

import Fab from "@material-ui/core/Fab";
import SaveIcon from "@material-ui/icons/Save";

import { Button } from "@material-ui/core";
import { employeeService, misService } from "../../_services";
import FormElement from "../uielements/FormElement";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import roleChecker from "../../_helpers/rolechecker";

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "50%",
    overflow: "scroll",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    minWidth: 650,
  },
}));

export default function LoanCloseModal(props) {
  const loan = props.loan;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const user= employeeService.userValue;

  const initialValues = {
    closure_date: new Date().toISOString().substring(0, 10),
  };

  const disablePastDate = () => {
    let today = new Date();
    let minDateAllowed=2;
    if(roleChecker.isAccountChecker(user.role) )
    {
       minDateAllowed=730;
    }
    today.setDate(today.getDate() - minDateAllowed);
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); 
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
};

const maxDate = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); 
  const yyyy = today.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
}

  const validationSchema = Yup.object().shape({
    closure_date: Yup.date()
    .min (disablePastDate(), "Date not Allowed")
    .max (maxDate(), "Future date not allowed")
    .required("This field is required")
  });
  
  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  
  async function onSubmit(fields, { setSubmitting }) {
    misService.closeLoan(loan._id, fields).then((res) => {
      alert("Loan closed.");
      
      window.location.reload();
    });
    setSubmitting(false);
  }


  return (
    <div>
     
      <Button style={{ width: "83%", border: '2px solid #3760F1',marginBottom:'8px' }} onClick={handleOpen} color="primary">
        Close&nbsp;Loan
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Loan Status</h2>
           
              <Formik
                // innerRef={formikRef}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                id="transition-modal-description"
              >
              {({ errors, touched, isSubmitting, setFieldValue }) => {
                  return (
                    <Form>
                      <div className="form-row">
                        <div className="form-group col">
                          <label>Loan Closure Date</label>
                          <FormElement
                            name="closure_date"
                            type="date"
                            className={
                              "form-control" +
                              (errors.em && touched.em ? " is-invalid" : "")
                            }
                          >
                          </FormElement>
                          <ErrorMessage
                            name="em"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        </div>
                        <hr/>
                        <div className="row justify-content-around">
                        <Fab
                          type="submit"
                          disabled={isSubmitting}
                          color="primary"
                          aria-label="save"
                        >
                          {isSubmitting && (
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                          )}
                          <SaveIcon />
                        </Fab>
                      </div>
                      </Form>
                  );
                 }}
              </Formik>
          
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
