import React, {useRef, useState, useEffect } from 'react'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import { Paper,Button, TextField } from "@material-ui/core";
import { uploadFileToBucket } from '../../../_helpers/upload';
import urlhelper from '../../../_helpers/urlhelper'
import { employeeService, loanService } from '../../../_services';
import datehelper from '../../../_helpers/datehelper';
import roleChecker from '../../../_helpers/rolechecker';
import logo from "../../../../src/assets/kaabil.png";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "../../../css/common.css"
// import { textAlign } from '@material-ui/system';
import { useReactToPrint } from "react-to-print";



const CerSaiUpload = (props) => {
    const reportRef = useRef();
    const [open, setOpen] = useState(false);
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [employeeList, setEmployeeList] = useState({});
    const [documents, setDocuments] = useState([]);
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false);
    const toggleReport = () => setShowReport(!showReport);
    const [showReport, setShowReport] = useState(false);
    const application_id = props.application_id
    console.log('application_id:::::',application_id)
    const cust = props.cust
    const user = employeeService.userValue
    const onChangeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
      };
    const data = new FormData();
    data.append("file", selectedFile);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        height: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        // overflow: 'scroll',
        // paddingBottom: '30px',
    };
    const style2 ={
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center", // Align items vertically centered
        margin: '1rem',
        width: "50%",
        margin: 'auto',
        padding: '0.5rem 0', // Add padding for better spacing
        // borderBottom: '1px solid #ccc'
    }
    const bucket = process.env.REACT_APP_s3docsBucket
    const onClickHandler = async () => {
        const length = cust && cust.property_details && cust.property_details.cersai_reports && cust.property_details.cersai_reports.length +1
        const report = await uploadFileToBucket(bucket, "application/pdf", `cersai_reports/${application_id}_${length}_cersai.pdf`, selectedFile);
        console.log(report, "report here")
        loanService.addCersaiReport({application_id:application_id, document_url:report, requested_by:user.emp_id, requested_date:new Date()})
        .then((data)=>{
            console.log(data, "data here")
            window.location.reload()
        })
        .catch((err)=>console.log(err))
    }
    async function getAllEmployees() {
        await employeeService.getAll()
            .then((res) => {
                let list = {};
                res.map(e => {
                    list[e.emp_id] = e.first_name + ' ' + e.last_name
                })
                setEmployeeList(list)
            })
    }

    useEffect(() => {
      getAllEmployees();
    }, [])
    const statusUpdate = async (value) => {
        let obj
        if(value.status === "Approve"){
            obj={
                status:"approved",
                approved_by: user.emp_id,
                approverd_date: new Date(),
            }
        }else{
            obj={
                status:"rejected",
                rejected_by: user.emp_id,
                rejected_date: new Date(),
            }
        }
        const yes = window.confirm(`Are you sure you want to ${value.status.toUpperCase()} the report!!` )
        if(yes){
            loanService.updateCersaiStatus({...obj, application_id:application_id, _id:value._id})
        .then((data)=>{
            console.log(data)
            window.location.reload()
        })
        .catch((err)=>{
            console.log(err)
        })
        }
    }
    const getRegisteredSiDatas = ({ application_id }) => {
        console.log('application_id:::',application_id)
        loanService.getRegisteredSiData({ application_id })
            .then(data => {
                console.log('Registered Si Data', data);
                setDocuments(data); // Assuming you want to set data.data
                
            })
            .catch(error => {
                console.error('Error getting document checklist:', error);
                alert(error.message);
            });
    };
    
    useEffect(() => {
        if (application_id) {
            getRegisteredSiDatas({ application_id }); // Make sure to pass an object
        }
    }, [application_id]); // Add application_id as a dependency
    const handlePrint = useReactToPrint({
        content: () => reportRef.current,
        documentTitle: "Cersai_Report",
    });
    const buttonStyle = {
        backgroundColor: 'blue',
        border: 'none',
        color: 'white',
        padding: '15px 32px',
        textDecoration: 'none',
        fontSize: '16px',
        fontWeight: 'bold',
        borderRadius: '8px',
        cursor: 'pointer',
        transition: 'background-color 0.4s, color 0.4s, border 0.4s',
      };
//     return (
//         <div>
//             {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
//                 <Button style={{ color: "white", backgroundColor: "blue", border: "1px solid" }} onClick={handleOpen}>Upload File</Button>
//             </div>
//             <Modal
//                 open={open}
//                 onClose={handleClose}
//                 aria-labelledby="modal-modal-title"
//                 aria-describedby="modal-modal-description"
//             >
//                 <Box>
//                     <Typography style={{ margin: "auto" }}>
//                         <div className="offset-md-3 col-md-6">
//                             <form method="post" action="#" id="#">
//                                 <div className="form-group files">
//                                     <label>Upload Your File</label>
//                                     <input
//                                         accept=".pdf"
//                                         type="file"
//                                         className="form-control"
//                                         onChange={onChangeHandler}
//                                     />
//                                 </div>
//                                 <button
//                                     type="button"
//                                     className="btn btn-success btn-block"
//                                     onClick={onClickHandler}
//                                 >
//                                     Upload
//                                 </button>
//                             </form>
//                         </div>
//                     </Typography>
//                 </Box>
//             </Modal><br/> */}

//             <h4 style={{ textAlign: 'center', margin: '20px' }}>Cersai Security Interest (SI) Details</h4>
//             <div className='table-border'>
//                 <Table>
//                     <thead>
//                         <tr>
//                             <th>Application ID</th>
//                             <th>Cersai Si Message</th>
//                             <th>Status</th>
//                             <th>Action</th>
//                             <th>Download Cersai PDF</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         <tr>
//                             <td>G0416998</td>
//                             <td>Residential plot</td>
//                             <td>Success</td>
//                             <td>
//                                 <Button onClick={toggleReport}>Open Report</Button>
//                             </td>
//                         </tr>
//                     </tbody>
//                 </Table>

//                 {showReport && (
//                     <div id="fullReport">
//                         <h2>Detailed Report Table</h2>
//                         <h3>Request Data</h3>
//                         <Table>
//                             <thead>
//                                 <tr>
//                                     <th>Field</th>
//                                     <th>Value</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 <tr><td>Asset Description</td><td>Residential plot</td></tr>
//                                 <tr><td>Asset Type</td><td>Residential</td></tr>
//                                 <tr><td>Area</td><td>111</td></tr>
//                                 <tr><td>Pincode</td><td>302017</td></tr>
//                                 <tr><td>District</td><td>Jaipur</td></tr>
//                                 <tr><td>State</td><td>Rajasthan</td></tr>
//                                 <tr><td>Borrower Name</td><td>Ram</td></tr>
//                                 <tr><td>Total Secured Amount</td><td>300000</td></tr>
//                                 <tr><td>User Identification Number</td><td>123456</td></tr>
//                             </tbody>
//                         </Table>

//                         <h3>Response Data</h3>
//                         <Table>
//                             <thead>
//                                 <tr>
//                                     <th>Field</th>
//                                     <th>Value</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 <tr><td>Status</td><td>Success</td></tr>
//                                 <tr><td>Message</td><td>Successful SI Registration</td></tr>
//                                 <tr><td>Asset ID</td><td>200050251135</td></tr>
//                                 <tr><td>Transaction ID</td><td>200234399842</td></tr>
//                                 <tr><td>Created At</td><td>2024-10-22 10:42:15</td></tr>
//                             </tbody>
//                         </Table>
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// }

// export default CerSaiUpload;
// Correcting existingCersaiReport initialization





console.log('documents::::::',documents)
return (

    <div>
       <div>
        {cust && cust.property_details && cust.property_details.cersai_reports && cust.property_details.cersai_reports.length > 0 ? (
            <div>
                {cust.property_details.cersai_reports.map((e, idx) => (
                    <div key={e._id} style={{ display: "right", justifyContent: "space-between", alignItems: "center", padding: "8px 0", borderBottom: "1px solid #ddd" }}>
                        {e.document_url && e.document_url !== "" && (
                        <button style={{...buttonStyle}} onClick={() => window.open(`/home/pdfviewer?pdf_url=${urlhelper.encodeToBase64(e.document_url)}&download=1`, "_blank")}
                        >View Report</button>
                        )}
                    </div>
                ))}
            </div>
        ) : (
            <div className='table-border'>
            <Table>
                <thead>
                    <tr>
                        <th>Application ID</th>
                        <th>Cersai SI Message</th>
                        <th>Status</th>
                        <th>Action</th>
                        <th>Download Cersai PDF</th>
                    </tr>
                </thead>
                <tbody>
                    {documents && documents.length > 0 ? (
                        documents.map((doc) => (
                            <tr key={doc._id.$oid}>
                                <td>{doc.application_id}</td>
                                <td>{doc.cersai_reports[0].response_data.message}</td>
                                <td>{doc.cersai_reports[0].response_data.status}</td>
                                <td>
                                    <Button onClick={() => setShowReport(!showReport)}>View Report</Button>
                                </td>
                                <td>
                                    <Button onClick={handlePrint}>Download PDF</Button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5" style={{ textAlign: 'center', color: 'gray' }}>
                                No records found. please create CERSAi.
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>

            {showReport && (
                <div id="fullReport" ref={reportRef}>
                    {/* <h3 style={{textAlign:'centre'}}>Request Data</h3> */}
                    {/* <h2>Asset(Property) Details</h2> */}
                    {documents.map((doc) => (
                        <div key={doc._id.$oid}>
                            <Paper style={{ display: 'flex', flexDirection: 'column', margin: '10px', paddingBottom: '10px', paddingTop: '10px' }}>
                        <div  >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                <img src={logo} style={{ height: "100px", marginRight: "20px" }} alt="KFPL Logo" />
                                <div style={{ textAlign: 'left' }}>
                                    <h3>CERSAI</h3>
                                    <h5>Central Registry of Securitisation Asset</h5>
                                    <h5>Reconstruction and Security Interest of India</h5>
                                </div>
                            </div>
                            

                            <hr style={{height:"5px"}}></hr>
                            <h4 style={{textAlign:'center' ,paddingBottom: '20px', paddingTop: '30px' }}>Registration of Security Interest Acknowledgement Report </h4>
                            <h3 style={{textAlign:'center'}}>Institution Details</h3>
                            <p style={style2}><b>Name of the Institution</b> :KAABIL FINANCE PRIVATE LIMITED</p>
                            <p style={style2}><b>Institution GSTIN</b> :08AAGCK6104N1ZA</p>
                            <br/>
                            <h3 style={{textAlign:'center'}} >Transaction Details</h3>
                            <p style={style2} ><b>Type of Transaction</b>: Registration Of Security Interest</p>
                            <p style={style2} ><b>Transaction ID / QRF NO</b>: {doc.cersai_reports[0].response_data.transactionId}</p>
                            <p style={style2} ><b>Created At</b>: {datehelper.displayDate(new Date(doc.cersai_reports[0].created_at))}</p>
                            {/* <p><b>Transaction ID</b>: {doc.cersai_reports[0].response_data.transactionId.$numberLong}</p>
        
                            <p><b>Asset ID</b>: {doc.cersai_reports[0].response_data.assetId.$numberLong}</p> */}
                            <br/>
                            <h3 style={{textAlign:'center'}} >Security Interest Details</h3>
                            <p style={style2} ><b>Type Of Security Interest:</b> Equitable Mortgage</p>
                            <p style={style2} ><b>SI Creation Date In Bank:</b> {datehelper.displayDate(new Date(doc.cersai_reports[0].request_data.securityInterest.securityInterestCreationDate))}</p>
                            <p style={style2} ><b>SI Registration Date In CERSAI Portal</b>: {datehelper.displayDateTime(new Date(doc.cersai_reports[0].created_at))}</p>
                            <p style={style2} ><b>Security Interest ID</b>: {doc.cersai_reports[0].response_data.securityInterestId}</p>
                            <p style={style2} ><b>Type Of Finance</b>:{doc.cersai_reports[0].request_data.securityInterest.financingTypeId===1 ? "Sole" :"Consortium" }</p>
                            <p style={style2} ><b>Details Of Charge:</b>{doc.cersai_reports[0].request_data.securityInterest.typeOfCharge}</p>
                            <p style={style2} ><b>Total Secured Amount:</b>{doc.cersai_reports[0].request_data.securityInterest.totalSecuredAmount}</p>
                            <p style={style2} ><b>Entity Identification Number:</b>{doc.cersai_reports[0].request_data.securityInterest.userIdentificationNumber}</p>
                            <br/>
                            <h3 style={{textAlign:'center'}} >Asset Details</h3>
                            <p style={style2} ><b>Asset ID:</b> {doc.cersai_reports[0].response_data.assetId}</p>
                            <p style={style2} ><b>Asset Category:</b> {doc.cersai_reports[0].request_data.asset.assetCategoryId===1 ? "Immovable": "Movable"}</p>
                            <p style={style2} ><b>Asset Type:</b> {doc.cersai_reports[0].request_data.asset.assetTypeDesc}</p>
                            <p style={style2} ><b>Description Of Assete:</b> {doc.cersai_reports[0].request_data.asset.assetDesc}</p>
                            <p style={style2} ><b>Survey Number / Municipal Number:</b> {doc.cersai_reports[0].request_data.asset.genericAsset.srvyMncplNumber}</p>
                            <p style={style2} ><b>Area:</b> {doc.cersai_reports[0].request_data.asset.genericAsset.area}</p>
                            <p style={style2} ><b>Area Unit:</b> SQFT.</p>
                            <p style={style2} ><b>Name of the Project / Scheme / Society / Zone:</b>{doc.cersai_reports[0].request_data.asset.genericAsset.lcltySector}</p>
                            {/* <p><b>City / Town / Village :</b>{doc.cersai_reports[0].request_data.asset.genericAsset.lcltySector}</p> */}
                            <p style={style2} ><b>State / UT:</b>{doc.cersai_reports[0].request_data.asset.genericAsset.stateName}</p>
                            <p style={style2} ><b>Pin Code / Post Code:</b>{doc.cersai_reports[0].request_data.asset.genericAsset.pincode}</p>

                            <p style={style2} ><b>Status:</b> {doc.cersai_reports[0].response_data.status}</p>
                            <p style={style2} ><b>Message:</b> {doc.cersai_reports[0].response_data.message}</p>


                            <p style={style2} ><b>Asset Type:</b> {doc.cersai_reports[0].request_data.asset.assetTypeId === 1 ? 'Residential Properties' : 'Other Properties'}</p>
                            <br/>
                            {/* <p style={style2} ><b>Borrower Name:</b> {doc.cersai_reports[0].request_data.borrowers[0].individualName}</p> */}
                            <p style={style2} ><b>Total Secured Amount:</b> {doc.cersai_reports[0].request_data.securityInterest.totalSecuredAmount}</p>

                            <p style={style2} ><b>User Identification Number:</b> {doc.cersai_reports[0].request_data.securityInterest.userIdentificationNumber}</p>

                            <h3 style={{textAlign:'center'}} >Borrower(s) Details</h3>
                            <p style={style2} ><b> Borrower Type:</b> Individual</p>
                            <p style={style2} ><b> Name Of the Borrower:</b> {doc.cersai_reports[0].request_data.borrowers[0].individualName}</p>
                            <p style={style2} ><b> Is Borrower Owner Of the Asset:</b> {doc.cersai_reports[0].request_data.borrowers.isAssetOwner===true ? "Yes" :"NO"}</p>
                            <p style={style2} ><b> Gender:</b> {doc.cersai_reports[0].request_data.borrowers[0].gender}</p>
                            <p style={style2} ><b> Father / Mother Name:</b> {doc.cersai_reports[0].request_data.borrowers[0].fatherMotherName}</p>
                            <p style={style2} ><b> Date Of Birth:</b> {doc.cersai_reports[0].request_data.borrowers[0].dob}</p>
                            
                            <p style={style2} ><b> City / Town / Village:</b> {doc.cersai_reports[0].request_data.asset.genericAsset.lcltySector}</p>
                            <p style={style2} ><b> Address Line 1:</b> {doc.cersai_reports[0].request_data.borrowers[0].address.addressLine1}</p>
                            <p style={style2} ><b> Address Line 2:</b> {doc.cersai_reports[0].request_data.borrowers[0].address && doc.cersai_reports[0].request_data.borrowers[0].address.addressLine2}</p>
                            
                            
                            {/* <p>District: {doc.cersai_reports[0].request_data.asset.genericAsset.district}</p>
                            <p>State: {doc.cersai_reports[0].request_data.asset.genericAsset.stateName}</p>
                            <p>Pincode: {doc.cersai_reports[0].request_data.asset.genericAsset.pincode}</p>
                            
                            <p>Status: {doc.cersai_reports[0].response_data.status}</p>
                            <p>Message: {doc.cersai_reports[0].response_data.message}</p> */}
                            


                            
                            <hr />
                        </div></Paper>
                        </div>
                    ))}
                </div>
            )}
        </div>
        )}
        </div>
                    
       
    </div>
);
};

export default CerSaiUpload;