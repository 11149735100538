import React, { useState } from 'react'
import {
    withStyles,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
} from "@material-ui/core";
import { employeeService, loanService } from '../../../../_services';
import roleChecker from '../../../../_helpers/rolechecker';
import DisbursementData from './DisbursementData';
import datehelper from '../../../../_helpers/datehelper';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import DisbursementDetailVerificationModal from './DisbursementDetailVerificationModal';
import DisbursementDetailViewModal from './DisbursementDetailViewModal';
import displayhelper from '../../../../_helpers/displayhelper';
import { BuildTwoTone } from '@material-ui/icons';


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});


const DisbursementDataList = (props) => {

    const customer = props.cust;
    const user = employeeService.userValue;
    const classes = useStyles();
console.log("customer data is",customer)

const resetDsibursementVerificationDetails =(id)=>{
console.log("props is unverified ",id)
loanService.resetDsibursementVerificationDetails(id)
.then((res)=>{
                window.location.reload();
})
.catch(err=>{
    console.log(err)
window.alert("Some error occurred while reseting verification details")})
}

    return (
        <div>
 
            {customer && customer.loan && customer.loan.disbursement_date && customer.loan.disbursement_date !=="" ?  (
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Disbursement Data</StyledTableCell>
                                <StyledTableCell>
                                    {customer && customer.loan && !customer.loan.verification && (<DisbursementData customer={customer} />)}
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {customer && customer.loan && customer.loan.verification && customer.loan.disbursement_date ? <><TableRow>
                                <StyledTableCell>
                                    <span style={{ fontSize: "20px"}}>Verified by:- Emp Id - <b>{customer.loan.verified_by}</b> on <b>{datehelper.displayDate (new Date(customer.loan.verified_date))}</b> </span>
                                </StyledTableCell>
                                <StyledTableCell>
                                <div style={{display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'}}>

                                    <span style={{ fontSize: "20px", fontWeight: "bold", color:"green" }}><VerifiedUserIcon/>Verified</span>
                                  { roleChecker.isHOOpsApproverAccess(user.role) && <Button  style={{ background:"red",color:"white"}} variant='contained' onClick={()=>{if (window.confirm("Are you sure, you want to reset disbursement Verification details ?")) {resetDsibursementVerificationDetails(customer._id)}}} >reset</Button>}
                                </div>
                                </StyledTableCell>
                                 
                                
                            </TableRow> 
                            <TableRow>
                               <StyledTableCell>
                                <DisbursementDetailViewModal customer={customer}/>
                                </StyledTableCell>
                            </TableRow></>
                            : <TableRow>
                                <StyledTableCell>
                                    <span style={{ fontSize: "20px", fontWeight: "bold" }}> Please Verify the Details!!</span>
                                </StyledTableCell>
                                <StyledTableCell>
                                    {roleChecker.isHOOpsApproverAccess(user.role) && user.emp_id !== customer.loan.disbursement_date_saved_by && <DisbursementDetailVerificationModal customer={customer}/>}
                                </StyledTableCell>
                            </TableRow>}                            
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Disburse Amount
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    {customer.loan.disbursed_amount && displayhelper.displayNumberAccounting(customer.loan.disbursed_amount)}
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Disbursement Booking Date
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    { datehelper.displayDate(new Date(customer.loan.disbursement_date))}
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Insurance Amount
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    {customer.loan.insurance_amount}
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Stamp Duty
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    {customer.loan.stamp_duty}
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Processing Fee
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    {customer.loan.processing_fees}
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    PF(%)
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    {customer.loan.pf_rate}
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Pre Emi
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    {customer.loan.pre_emi_amount}
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    EMI
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    {customer.loan.emi}
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    IMD Deduction
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    {customer.loan.imd_deduction}
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Other Charges
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    {customer.loan.other_charges}
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : <div style={{alignItems:"center", display:"flex"}}><span style={{fontSize:"20px", color:"blue"}}>Please Add Disbursement Details to Verify!! </span> &nbsp; &nbsp; &nbsp; <DisbursementData customer={customer} /></div>}
        </div>
    )
}

export default DisbursementDataList