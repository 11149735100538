import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "@material-ui/core";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { allcloudService } from "../../_services/allcloud.service";
import { employeeService } from "../../_services";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "600px",
    height: "450px",
  },
}));

export function AllCloudLedgerModal() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const user = employeeService.userValue.emp_id;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
 const initialValues={
    bank_name: "",
    ledger_code: "",
    mode: "",
    emp_id: user,
  }
  const validationSchema=Yup.object({
    bank_name: Yup.string().required("This field is required"),
    ledger_code: Yup.string().required("This field is required"),
    mode: Yup.string()
      .oneOf(["Cash", "Bank"], "Please select either 'cash' or 'bank'")
      .required("This field is required"),
  })
 const onSubmit=(values, { setSubmitting }) => {
    allcloudService
      .allCloudLedgerCreate(values)
      .then((res) => {
        console.log("res",res)
        window.alert("New Ledger Added");
        window.location.reload();
      })
      .catch((err) => {
        window.alert("Some error occurred");
        console.log(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }
  return (
    <div>
      <div
        style={{ marginTop: "3%" }}
        className="d-flex justify-content-around"
        onClick={handleOpen}
      >
        <Button style={{ background: "blue", width: "30px", color: "white" }}>
          <AddIcon />
        </Button>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Add New Ledger</h2>
            <Formik
              initialValues={ initialValues}
              validationSchema={validationSchema }
              onSubmit={onSubmit}
            >
              <Form>
              <div className="form-group">
                  <label htmlFor="mode">Mode</label>
                  <Field
                    className="form-control"
                    as="select"
                    name="mode"
                  >
                    <option value="">Select Mode</option>
                    <option value="Cash">Cash</option>
                    <option value="Bank">Bank</option>
                  </Field>
                  <ErrorMessage
                    name="mode"
                    component="span"
                    style={{ color: "red" }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="bank_name">Bank Name</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="bank_name"
                  />
                  <ErrorMessage
                    name="bank_name"
                    component="span"
                    style={{ color: "red", marginBottom: "100px" }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="ledger_code">Ledger Code</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="ledger_code"
                  />
                  <ErrorMessage
                    name="ledger_code"
                    component="span"
                    style={{ color: "red" }}
                  />
                </div>
               
                <hr />
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </Form>
            </Formik>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
