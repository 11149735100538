const displayhelper={
    displayNumberAccounting,
}

function displayNumberAccounting(number){
    if(number==0)
    {
        return 0;
    }
    if(!number)
    {
        return "";
    }
    return number.toLocaleString("en-IN");
}

export default displayhelper; 