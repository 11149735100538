import { fetchWrapper} from "../_helpers";
import { misService } from "./mis.service";

const apiUrl = process.env.REACT_APP_API_URL;

const baseUrl = `${apiUrl}/kaabil`;

export const kaabilService = {
  getAll,
  create,
  getByLoanAccount,
};

function getAll() {
  return fetchWrapper.get(baseUrl);
}

function getByLoanAccount(loan_account) {
  console.log(`${baseUrl}/loan_account/${loan_account}`);
  return fetchWrapper.get(`${baseUrl}/loan_account/${loan_account}`);
}


async function create(params) {
  return fetchWrapper.post(baseUrl, params)
          .then(() => {
            return misService
                .create(params)
          })
}