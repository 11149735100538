import React, { useEffect, useState } from 'react'
import datehelper from "../../../_helpers/datehelper"
import { Avatar, Button } from '@material-ui/core'
import { history } from '../../../_helpers'
import AddDocumnetModal from './AddDocumnetModal'
import urlhelper from '../../../_helpers/urlhelper'
import { legalServices } from '../../../_services/legal_cases.services'
import AddEditCaseModal from './AddEditCaseModal'
import AddStageModal from './AddStageModal'
import AddHearingModal from './AddHearingModal'
import ShowTimelineModal from './ShowTimelineModal'
import ActionModal from './ActionModal'

const LegalCasesPersonalInfo = (props) => {
  console.log("Test cases", props)
  let data = props.history.location.state
  let loanNo = data.case_info[0].loan_no
  const [showDocuments, setShowDocumnets] = useState(false)
  const [personalinfo, setPersonalinfo] = useState(true)
  const [viewProperty, setViewProperty] = useState(false)
  const [showNi138, setShowNi138] = useState(false)
  const [showArbitration, setShowArbitration] = useState(false)
  const [showExecution, setShowExecution] = useState()
  const [courtCases, setCourtCases] = useState()
  const getCourtCases = async () => {
    await legalServices.getCourtCases({ loan_no: loanNo })
      .then((res) => setCourtCases(res))
      .catch((err) => alert(err))
  }
  useEffect(() => {
    getCourtCases()
  }, [])
  const handleDocument = () => {
    setShowDocumnets(true)
    setPersonalinfo(false)
    setShowNi138(false)
    setShowExecution(false)
    setShowArbitration(false)
  }
  const handlePersonalInfo = () => {
    setPersonalinfo(true)
    setShowDocumnets(false)
    setShowNi138(false)
    setShowExecution(false)
    setShowArbitration(false)
  }
  const handleNi138 = () => {
    setShowNi138(true)
    setPersonalinfo(false)
    setShowDocumnets(false)
    setShowArbitration(false)
    setShowExecution(false)
    var filterFirst = courtCases && courtCases.filter(casetype => casetype.case_type === "ni138")
    setSelected(filterFirst && filterFirst[0])

  }
  const handleArbitration = () => {
    setShowNi138(false)
    setPersonalinfo(false)
    setShowDocumnets(false)
    setShowArbitration(true)
    setShowExecution(false)
    var filterFirst = courtCases && courtCases.filter(casetype => casetype.case_type === "arbitration")
    setSelected(filterFirst && filterFirst[0])
  }
  const handleExecution = () => {
    setShowNi138(false)
    setPersonalinfo(false)
    setShowDocumnets(false)
    setShowArbitration(false)
    setShowExecution(true)
    var filterFirst = courtCases && courtCases.filter(casetype => casetype.case_type === "execution")
    setSelected(filterFirst && filterFirst[0])
  }

  const handleViewProperty = () => {
    setViewProperty(!viewProperty)
  }
  const [selected, setSelected] = useState(null)
  const handleSelectedParty = (x) => {
    setSelected(x)
  }
  console.log("tEDT courtCases", courtCases)
  console.log("Test Selected cases", selected)
  let selectedId = selected && selected._id
  const deleteDocument = async (doc_id) => {
    await legalServices.deleteDocument({ id: selectedId, doc_id: doc_id })
      .then((res) => {
        window.alert('Document deleted successfully')
        window.location.reload()
      }).catch(err => {
        window.alert('Some error occurred')
        console.log(err)
      })
  }
  const deleteHearing=async(hearing_id)=>{
      await legalServices.deleteHearing({id:selectedId,hearing_id:hearing_id})
      .then((res)=>{
        window.alert("Hearing deleted successfully !!")
        window.location.reload()
      }).catch((err)=>console.log(err))
  }
  const deleteStage=async(stage_id)=>{
    await legalServices.deleteStage({id:selectedId,stage_id:stage_id})
    .then((res)=>{
      window.alert("Stage deleted successfully !!")
      window.location.reload()
    }).catch((err)=>console.log(err))
}
  const currentDate = new Date();
  console.log("Teste selected", selected)

  console.log("Test data", data)
  return (
    <div>
      <div>
        <ActionModal courtCases={courtCases} customerData={data} />
        {/* <Button style={{float:'right',marginRight:'30px',background:'#DF2D2D',color:'white',width:'120px'}}>Action</Button> */}
      </div>
      <br />
      <div style={{ borderRadius: '4px', height: "220px", marginTop: '40px', background: '#E5E5E5' }}>
        <br /> 
        {data.applicant.kyc_images && data.applicant.kyc_images.length>0 && data.applicant.kyc_images[0].uri ?<img
          src={data.applicant.kyc_images && data.applicant.kyc_images.length>0 && data.applicant.kyc_images[0].uri}
          alt="customer image"
          style={{
            width: '150px',
            height: '170px',
            borderRadius: '50%',
            objectFit: 'cover',
            border: "3px solid #3760F1",
            marginLeft: '30px',
            float: 'left',
          }}
        />:<Avatar style={{
          background:'black',
          width: '150px',
          height: '170px',
          borderRadius: '50%',
          objectFit: 'cover',
          border: "3px solid #3760F1",
          marginLeft: '30px',
          float: 'left',
        }}/>}
        <table style={{ width: "60%", marginLeft: '400px' }}>
          <tr>
            <td style={{ fontSize: '22px', fontWeight: 'bold' }}>Customer Name</td>
            <td style={{ color: 'bold', fontWeight: 'bold' }}>:</td>
            <td style={{ fontSize: '18px', paddingLeft: '100px' }}>{data.applicant.general_information && data.applicant.general_information.name}</td>
          </tr>
          <tr>
            <td style={{ fontSize: '22px', fontWeight: 'bold' }}>Loan Account Number</td>
            <td style={{ color: 'bold', fontWeight: 'bold' }}>:</td>
            <td style={{ fontSize: '18px', paddingLeft: '100px' }}>{data.application_id}</td>
          </tr>
          <tr>
            <td style={{ fontSize: '22px', fontWeight: 'bold' }}>Loan Amount</td>
            <td style={{ color: 'bold', fontWeight: 'bold' }}>:</td>
            <td style={{ fontSize: '18px', paddingLeft: '100px' }}>{data.loan.sanction_amount && data.loan.sanction_amount.toLocaleString("en-IN", {
                        maximumFractionDigits: 2,
                        style: "currency",
                        currency: "INR",
                      })}</td>
          </tr>
          <tr>
            <td style={{ fontSize: '22px', fontWeight: 'bold' }}>Branch</td>
            <td style={{ color: 'bold', fontWeight: 'bold' }}>:</td>
            <td style={{ fontSize: '18px', paddingLeft: '100px' }}>{data.branch_id}</td>
          </tr>

          <tr>
            <td style={{ fontSize: '22px', fontWeight: 'bold' }}>Legal initiation Date</td>
            <td style={{ color: 'bold', fontWeight: 'bold' }}>:</td>
            <td style={{ fontSize: '18px', paddingLeft: '100px' }}>{datehelper.displayDate(new Date(data.case_info[0].createdAt))}</td>
          </tr>
        </table>
      </div>
      <br />
      <div style={{ display: "flex", justifyContent: "space-around", background: '#FFFFFF' }}>
        <Button style={{ color: 'white', background: '#7893F2', width: ' 240px', borderRadius: '4px', height: "50px", fontWeight: "bold", fontSize: '18px' }}
          onClick={handlePersonalInfo}>Personal Info</Button>
        <Button style={{ color: 'white', background: '#7893F2', width: ' 240px', borderRadius: '4px', fontWeight: "bold", fontSize: '18px' }}
          onClick={handleDocument}>Documents</Button>
        {courtCases && courtCases.some(obj => obj.case_type === "arbitration") && <Button style={{ color: 'white', background: '#7893F2', width: ' 240px', borderRadius: '4px', fontSize: '18px', fontWeight: "bold" }}
          onClick={handleArbitration}>Arbitration</Button>}
        {courtCases && courtCases.some(obj => obj.case_type === "ni138") && <Button
          onClick={handleNi138}
          style={{ color: 'white', background: '#7893F2', width: ' 240px', borderRadius: '4px', fontWeight: "bold", fontSize: '18px' }}>NI-138 </Button>}
        {courtCases && courtCases.some(obj => obj.case_type === "execution") && <Button style={{ color: 'white', background: '#7893F2', width: ' 240px', borderRadius: '4px', fontWeight: "bold", fontSize: '18px' }}
          onClick={handleExecution}>Execution</Button>}
      </div>
      {personalinfo && <div style={{ borderRadius: '5px', marginTop: '30px', background: '#E5E5E5' }}>
        <br />
        <h3 style={{ color: '#000000', marginLeft: "10px", fontWeight: 'bold' }}>Personal Information</h3>
        <br />
        <table style={{ width: "100%", marginLeft: '40px' }}>
          <tr>
            <td style={{ fontSize: "30px" }}>&bull;</td>
            <td style={{ fontSize: '22px', fontWeight: "bold" }}>Customer Name</td>
            <td style={{ color: 'bold', fontWeight: 'bold' }}>:</td>
            <td style={{ fontSize: '18px', paddingLeft: '100px' }}>{data.applicant.general_information.name}</td>
          </tr>
          <tr>
            <td style={{ fontSize: "30px" }}>&bull;</td>
            <td style={{ fontSize: '22px', fontWeight: "bold" }}>Father's Name</td>
            <td style={{ color: 'bold', fontWeight: 'bold' }}>:</td>
            <td style={{ fontSize: '18px', paddingLeft: '100px' }}>{data.applicant.general_information.sonof}</td>
          </tr>
          <tr>
            <td style={{ fontSize: "30px" }}>&bull;</td>
            <td style={{ fontSize: '22px', fontWeight: "bold" }}>Address </td>
            <td style={{ color: 'bold', fontWeight: 'bold' }}>:</td>
            <td style={{ fontSize: '18px', paddingLeft: '100px' }}>{data.applicant.general_information && data.applicant.general_information.address}</td>
          </tr>
          <tr>
            <td style={{ fontSize: "30px" }}>&bull;</td>
            <td style={{ fontSize: '22px', fontWeight: "bold" }}>Phone Number</td>
            <td style={{ color: 'bold', fontWeight: 'bold' }}>:</td>
            <td style={{ fontSize: '18px', paddingLeft: '100px' }}>{data.applicant.general_information && data.applicant.general_information.contact}</td>
          </tr>
          {data.co_applicants && data.co_applicants.map((x, idx) => <><tr key={idx}>
            <td style={{ fontSize: "30px" }}>&bull;</td>
            <td style={{ fontSize: '22px', fontWeight: "bold" }}>Co-Applicant(s) {idx + 1} Name</td>
            <td style={{ color: 'bold', fontWeight: 'bold' }}>:</td>
            <td style={{ fontSize: '18px', paddingLeft: '100px' }}>{x.general_information.name}</td>
          </tr>
            <tr>
              <td style={{ fontSize: "30px" }}>&bull;</td>
              <td style={{ fontSize: '22px', fontWeight: "bold" }}>Co-Applicant(s)  {idx + 1} Phone Number</td>
              <td style={{ color: 'bold', fontWeight: 'bold' }}>:</td>
              <td style={{ fontSize: '18px', paddingLeft: '100px' }}>{x.general_information.contact}</td>
            </tr> </>)}
          {data.guarantors && data.guarantors.map((y, idx) => <><tr>
            <td style={{ fontSize: "30px" }}>&bull;</td>
            <td style={{ fontSize: '22px', fontWeight: "bold" }}>Guarantor's Name {idx + 1}</td>
            <td style={{ color: 'bold', fontWeight: 'bold' }}>:</td>
            <td style={{ fontSize: '18px', paddingLeft: '100px' }}>{y.general_information.name}</td>
          </tr>
            <tr>
              <td style={{ fontSize: "30px" }}>&bull;</td>
              <td style={{ fontSize: '22px', fontWeight: "bold" }}>Guarantor's Phone Number {idx + 1}</td>
              <td style={{ color: 'bold', fontWeight: 'bold' }}>:</td>
              <td style={{ fontSize: '18px', paddingLeft: '100px' }}>{y.general_information.contact}</td>
            </tr></>)}
        </table>
      </div>}
      {
        showDocuments && <div style={{ background: '#E5E5E5', marginTop: "25px", borderRadius: '5px' }}>
          <br />
          <h3 style={{ color: '#000000', marginLeft: "15px", fontWeight: 'bold' }}>Kyc Documents</h3>
          <h5 style={{ color: '#000000', marginLeft: "23px", fontWeight: 'bold' }}>Applicant-{data.applicant.general_information.name}</h5>
          <br />
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            {data.applicant.kyc_images && data.applicant.kyc_images.filter(adhar => adhar.ref_id == "aadhar_front" || adhar.ref_id == "aadhar_back").map((x, idx) => <>
              {x.ref_id == "aadhar_front" && <div style={{ display: "flex", flexDirection: "column" }}>
                <label style={{ color: '#000000', fontSize: "22px", fontWeight: 'bold' }}>&bull; Addhar Card (Front)</label>
                <img
                  src={x.uri}
                  alt="photo"
                  style={{ width: "620px", height: '300px' }}
                />
              </div>}
              {x.ref_id == "aadhar_back" && <div style={{ display: "flex", flexDirection: "column" }}>
                <label style={{ color: '#000000', fontSize: "22px", fontWeight: 'bold' }}>&bull; Addhar Card (Back)</label>
                <img
                  src={x.uri}
                  alt="photo"
                  style={{ width: "620px", height: '300px' }}
                />
              </div>}</>)}
          </div>
          <br />
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            {data.applicant.kyc_images && data.applicant.kyc_images.filter(adhar => adhar.category !== "Aadhar" && adhar.category !== "Customer Photo").map((x, idx) => <>
              {<div style={{ display: "flex", flexDirection: "column" }}>
                <label style={{ color: '#000000', fontSize: "22px", fontWeight: 'bold' }}>&bull;{x.category}</label>
                <img
                  src={x.uri}
                  alt="photo"
                  style={{ width: "600px", height: '300px' }}
                />
              </div>}
            </>)}
          </div>
          <br />
          <br />
          <div style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }}>
            {data.co_applicants && data.co_applicants.map((x, idx) => (
              <div style={{ display: "flex", flexDirection: "column", alignItems: 'center' }}>
                <h5 style={{ color: '#000000', marginLeft: "40px", alignSelf: 'flex-start', fontWeight: "bold" }}>Co-applicant(s) {idx + 1}: {x.general_information.name}</h5>
                <div style={{ display: "flex", flexWrap: "wrap", marginLeft: "50px" }}>
                  {x.kyc_images && x.kyc_images.map((y) => (
                    <div style={{ margin: "10px" }}>
                      <label style={{ color: '#000000', fontSize: "22px", fontWeight: 'bold' }}>&bull; {y.category}</label>
                      <br />
                      <img
                        src={y.uri}
                        alt="photo"
                        style={{ width: "500px", height: '300px', margin: '0 20px' }}
                      />
                    </div>
                  ))}
                </div>
                <br />
              </div>
            ))}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }}>
            {data.guarantors && data.guarantors.map((x, idx) => (
              <div style={{ display: "flex", flexDirection: "column", alignItems: 'center' }}>
                <h5 style={{ color: '#000000', marginLeft: "40px", alignSelf: 'flex-start', fontWeight: "bold" }}>Guarantor's {idx + 1}: {x.general_information.name}</h5>
                <div style={{ display: "flex", flexWrap: "wrap", marginLeft: '50px' }}>
                  {x.kyc_images && x.kyc_images.map((y) => (
                    <div style={{ margin: "10px" }}>
                      <label style={{ color: '#000000', fontSize: "22px", fontWeight: 'bold' }}>&bull; {y.category}</label>
                      <br />
                      <img
                        src={y.uri}
                        alt="photo"
                        style={{ width: "500px", height: '300px', margin: '0 20px' }}
                      />
                    </div>
                  ))}
                </div>
                <br />
              </div>
            ))}
          </div>

          <div>
            <h3>Property / Collateral Documents</h3>
            <Button
              onClick={handleViewProperty}
              style={{ background: "#3760F1", marginLeft: '40px', textTransform: 'capitalize', width: "100px" }}>View</Button>
            {viewProperty && <div style={{ display: "flex", flexWrap: "wrap", maxWidth: "1200px", marginLeft: '50px' }}>
              {data.images && data.images.map((x, idx) => (
                <div key={idx} style={{ width: "50%" }}>
                  <div style={{ display: "flex", flexDirection: "column", margin: '10px' }}>
                    <label style={{ color: '#000000', fontSize: "25px" }}>&bull; {x.tag}</label>
                    <img
                      src={x.uri}
                      alt="photo"
                      style={{ width: "100%", height: '300px' }}
                    />
                  </div>
                </div>
              ))}
            </div>
            }
            <br />
          </div>
        </div>
      }
      {(showNi138 || showArbitration || showExecution) && <div>
        <br />
        <br />
        <div style={{
          display: 'flex',
          justifyContent: 'space-around',
          color: "#3760F1",
        }}>
          {showNi138 && courtCases && courtCases.filter(casetype => casetype.case_type === "ni138").map((x) =>
            <Button
              key={x.party_name}
              onClick={() => handleSelectedParty(x)}
              style={{
                fontSize: "20px",
                height: "30px",
                color: "#3760F1",
                marginBottom: '5px',
                textDecoration: selected === x ? 'underline' : 'none',
                // textDecorationColor: 'red',
                // textDecorationThickness: '8px'
              }}
            >
              {x.party_name}
            </Button>
          )}
          {showArbitration && courtCases && courtCases.filter(casetype => casetype.case_type === "arbitration").map((x) =>
            <Button
              onClick={() => handleSelectedParty(x)}
              style={{
                fontSize: "20px",
                height: "30px",
                color: "#3760F1",
                marginBottom: '5px',
                textDecoration: selected === x ? 'underline' : 'none',
              }}
            >
              {x.party_name}
            </Button>
          )}
          {showExecution && courtCases && courtCases.filter(casetype => casetype.case_type === "execution").map((x) =>
            <Button
              onClick={() => handleSelectedParty(x)}
              style={{
                fontSize: "20px",
                height: "30px",
                color: "#3760F1",
                marginBottom: '5px',
                textDecoration: selected === x ? 'underline' : 'none',
              }}
            >
              {x.party_name}
            </Button>
          )}
        </div>
        <br />
        {selected && <div style={{ background: '#E5E5E5', borderRadius: '5px' }}>
          <br />
          <AddEditCaseModal caseInfo={selected} courtCases={courtCases} customerData={data} />
          <table style={{ width: "100%", marginLeft: '40px' }}>
            <tr>
              <td style={{ fontSize: "30px" }}>&bull;</td>
              <td style={{ fontSize: '22px', fontWeight: 'bold' }}>Case Type</td>
              <td style={{ color: 'bold', fontWeight: 'bold' }}>:</td>
              <td style={{ fontSize: '18px', paddingLeft: '100px' }}>{selected.case_type}</td>
            </tr>
            <tr>
              <td style={{ fontSize: "30px" }}>&bull;</td>
              <td style={{ fontSize: '22px', fontWeight: 'bold' }}>Loan Number</td>
              <td style={{ color: 'bold', fontWeight: 'bold' }}>:</td>
              <td style={{ fontSize: '18px', paddingLeft: '100px' }}>{selected.loan_no}</td>
            </tr>
            <tr>
              <td style={{ fontSize: "30px" }}>&bull;</td>
              <td style={{ fontSize: '22px', fontWeight: 'bold' }}>Branch</td>
              <td style={{ color: 'bold', fontWeight: 'bold' }}>:</td>
              <td style={{ fontSize: '18px', paddingLeft: '100px' }}>{selected.branch}</td>
            </tr>
            <tr>
              <td style={{ fontSize: "30px" }}>&bull;</td>
              <td style={{ fontSize: '22px', fontWeight: 'bold' }}>Case Open Date</td>
              <td style={{ color: 'bold', fontWeight: 'bold' }}>:</td>
              <td style={{ fontSize: '18px', paddingLeft: '100px' }}>{datehelper.displayDate(new Date(selected.case_open_date))}</td>
            </tr>
            <tr>
              <td style={{ fontSize: "30px" }}>&bull;</td>
              <td style={{ fontSize: '22px', fontWeight: 'bold' }}>Party Type</td>
              <td style={{ color: 'bold', fontWeight: 'bold' }}>:</td>
              <td style={{ fontSize: '18px', paddingLeft: '100px' }}>{selected.party_type}</td>
            </tr>
            <tr>
              <td style={{ fontSize: "30px" }}>&bull;</td>
              <td style={{ fontSize: '22px', fontWeight: 'bold' }}>Party Name</td>
              <td style={{ color: 'bold', fontWeight: 'bold' }}>:</td>
              <td style={{ fontSize: '18px', paddingLeft: '100px' }}>{selected.party_name}</td>
            </tr>
            <tr>
              <td style={{ fontSize: "30px" }}>&bull;</td>
              <td style={{ fontSize: '22px', fontWeight: 'bold' }}>Case Number</td>
              <td style={{ color: 'bold', fontWeight: 'bold' }}>:</td>
              <td style={{ fontSize: '18px', paddingLeft: '100px' }}>{selected.case_no}</td>
            </tr>
            <tr>
              <td style={{ fontSize: "30px" }}>&bull;</td>
              <td style={{ fontSize: '22px', fontWeight: 'bold' }}>Jurisdiction Name</td>
              <td style={{ color: 'bold', fontWeight: 'bold' }}>:</td>
              <td style={{ fontSize: '18px', paddingLeft: '100px' }}>{selected.jurisdiction_name}</td>
            </tr>
            <tr>
              <td style={{ fontSize: "30px" }}>&bull;</td>
              <td style={{ fontSize: '22px', fontWeight: 'bold' }}>Loan Amount</td>
              <td style={{ color: 'bold', fontWeight: 'bold' }}>:</td>
              <td style={{ fontSize: '18px', paddingLeft: '100px' }}>{selected.loan && selected.loan.toLocaleString("en-IN", {
                        maximumFractionDigits: 2,
                        style: "currency",
                        currency: "INR",
                      })}</td>
            </tr>
            <tr>
              <td style={{ fontSize: "30px" }}>&bull;</td>
              <td style={{ fontSize: '22px', fontWeight: 'bold' }}>Claim Amount</td>
              <td style={{ color: 'bold', fontWeight: 'bold' }}>:</td>
              <td style={{ fontSize: '18px', paddingLeft: '100px' }}>{selected.claim_amount && selected.claim_amount.toLocaleString("en-IN", {
                        maximumFractionDigits: 2,
                        style: "currency",
                        currency: "INR",
                      })}</td>
            </tr>
            <tr>
              <td style={{ fontSize: "30px" }}>&bull;</td>
              <td style={{ fontSize: '22px', fontWeight: 'bold' }}>Court Name</td>
              <td style={{ color: 'bold', fontWeight: 'bold' }}>:</td>
              <td style={{ fontSize: '18px', paddingLeft: '100px' }}>{selected.court_name}</td>
            </tr>
          </table>
          <br />
        </div>}
        {selected && <div>
          <h3 style={{ marginBottom: '10px', marginTop: "20px" }}>Documents</h3>
          <div>
            <AddDocumnetModal caseInfo={selected} />
          </div>
          <table> 
            <thead style={{ background: "#3760F1", height: '30px', color: 'white', borderRadius: '20px' }}>
              <tr>
                <th style={{ width: '30%' }}>&nbsp;&nbsp;Sr.No</th>
                <th style={{ width: '40%' }}>Document Name</th>
                {/* <th style={{ width: '20%' }}>File</th> */}
                <th style={{ width: '30%', textAlign: 'center' }} colSpan={2}>Action</th>
              </tr>
            </thead>
            <tbody>
              {selected.documents && selected.documents.length > 0 ? selected.documents.map((x, idx) => <tr key={idx} style={{ background: '#E5E5E5', width: "100px", fontSize: '20px', color: "00000" }}>
                <td>{idx + 1}</td>
                <td>{x.document_type}</td>
                {/* <td>{x.file_name}</td> */}
                <td style={{ textAlign: 'center' }}><Button
                  color='primary'
                  variant='outlined'
                  onClick={() => history.push(`/home/pdfviewer?pdf_url=` + urlhelper.encodeToBase64(x.url) + "&download=1")}
                >View</Button>
                  <Button
                    color='secondary'
                    variant='outlined'
                    style={{ marginLeft: '15px' }}
                    onClick={() => deleteDocument(x._id)}
                  >Delete</Button></td>
              </tr>) :
                <tr>
                  <td></td>
                  <td>No Dcument Available !!</td>
                  <td></td>
                  <td></td>
                </tr>}
            </tbody>
          </table>
        </div>}
        {selected && <div>
          <h3 style={{ marginBottom: '-10px', marginTop: "20px" }}> {showNi138 && "NI-138 Stages" || showArbitration && "Arbitration Stages" || showExecution && "Execution Stages"}</h3>
          <div>
            <ShowTimelineModal caseInfo={selected} /> <AddStageModal caseInfo={selected} />
          </div>
          <table >
            <thead style={{ background: "#3760F1", borderRadius: "4px", height: '30px', color: 'white' }}>
              <tr>
                <th >&nbsp;&nbsp;Sr.No</th>
                <th >Stage</th>
                <th >Issued Against</th>
                <th>Issued Date</th>
                <th>Issued By</th>
                <th>&nbsp;&nbsp;&nbsp;Action</th>
              </tr>
            </thead>
            <tbody>
              {selected.case_stages && selected.case_stages.length > 0 ? selected.case_stages.map((x, idx) => <tr key={idx} style={{ background: '#E5E5E5', width: "100px", fontSize: '20px', color: "00000" }}>
                <td>{idx + 1}</td>
                <td>{x.stage =="other"?x.other_stage:x.stage}</td>
                <td>{x.issued_against}</td>
                <td>{datehelper.displayDate(new Date(x.issued_date))}</td>
                <td>{x.issued_by}</td>
                <td><Button
                    color='secondary'
                    variant='outlined'
                    style={{ marginLeft: '15px' }}
                    onClick={() => deleteStage(x._id)}
                  >Delete</Button></td>
              </tr>) :
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td> No Stage Availabe</td>
                  <td></td>
                </tr>}
            </tbody>
          </table>
        </div>}
        {selected && <div>
          <h3 style={{ marginBottom: '-10px', marginTop: "20px" }}>Hearings</h3>
          <div>
            <AddHearingModal caseInfo={selected} />
          </div>
          <table>
            <thead style={{ background: "#3760F1", borderRadius: "4px", height: '30px', color: 'white' }}>
              <tr>
                <th >&nbsp;&nbsp;Hearing Date</th>
                <th >Lawyer Name</th>
                <th >Judge Name</th>
                <th>Party Lawyer</th>
                <th>Case Manager</th>
                <th>Penal Code </th>
                <th>Judgement</th>
                <th>&nbsp;&nbsp;&nbsp;Action</th>
              </tr>
            </thead>
            <tbody>
              {selected.hearings.length > 0 ? selected.hearings.map((x, idx) =>
                <tr key={idx} style={{ background: '#E5E5E5', width: "100px", fontSize: '20px', color: "00000" }}>
                  <td>{datehelper.displayDate(new Date(x.hearing_date))}</td>
                  <td>{x.lawyer_name}</td>
                  <td>{x.judge_name}</td>
                  <td>{x.party_lawyer}</td> 
                  <td>{x.case_manager_name}</td>
                  <td>{x.penal_codes}</td>
                  <td>{x.judgement}</td>
                  <td><Button
                    color='secondary'
                    variant='outlined'
                    style={{ marginLeft: '15px' }}
                    onClick={() => deleteHearing(x._id)}
                  >Delete</Button></td>
                </tr>) : <tr>
                <td></td>
                <td></td>
                <td></td>
                <td style={{ fontSize: '16px' }}>No Hearing availbe</td>
                <td></td>
                <td></td>
                <td></td></tr>}
            </tbody>
          </table>
        </div>}

        {selected && <div>
          <h3 style={{ marginBottom: '10px', marginTop: "20px" }}>Up-Coming Hearings</h3>
          <table style={{ width: "" }}>
            <thead style={{ background: "#3760F1", borderRadius: "4px", height: '30px', color: 'white' }}>
              <tr>
                <th >&nbsp;&nbsp;Hearing Date</th>
                <th >Lawyer Name</th>
                <th >Judge Name</th>
                <th>Party Lawyer</th>
                <th>Case Manager</th>
                <th>Penal Code </th>
                <th>Judgement</th>
              </tr>
            </thead>
            <tbody>
              {selected.hearings.length > 0 ? selected.hearings.filter(upcomingDate=>(new Date(upcomingDate.hearing_date))>=new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())).map((x, idx) =>
                <tr key={idx} style={{ background: '#E5E5E5', width: "100px", fontSize: '20px', color: "00000" }}>
                  <td>{datehelper.displayDate(new Date(x.hearing_date))}</td>
                  <td>{x.lawyer_name}</td>
                  <td>{x.judge_name}</td>
                  <td>{x.party_lawyer}</td>
                  <td>{x.case_manager_name}</td>
                  <td>{x.penal_codes}</td>
                  <td>{x.judgement}</td>
                </tr>) : <tr>
                <td></td>
                <td></td>
                <td></td>
                <td style={{ fontSize: '16px' }}>No Hearing availbe</td>
                <td></td>
                <td></td>
                <td></td></tr>}
            </tbody>
          </table>
        </div>
        }
      </div>}
    </div>
  )
}

export default LegalCasesPersonalInfo