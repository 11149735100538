import React from "react";
import { TableContainer, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { adminservice } from "../../../_services/admin.services";
import { Add, Edit } from "@material-ui/icons";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 1000,
  },
});

const BranchOwnersList = ({ match }) => {
  const { path } = match;
  const [providerList, setBranchOwnersList] = React.useState([]);

  React.useEffect(() => {
    adminservice.getBranchOwnerDetails().then((x) => {
      setBranchOwnersList(x);
    });
  }, []);

  const classes = useStyles();

  return (
    <div className="justify-content-between">
      <div className="row d-flex" style={{ margin: "10px" }}>
        <h1>
          Branches Owners &nbsp;
          <Button variant="outlined" aria-label="increase" href={`${path}/add`}>
            <Add fontSize="small" />
          </Button>
        </h1>
      </div>
      <div className="row d-flex" style={{ margin: "10px" }}>
        <TableContainer className={classes.container}>
          <Table>
            <Thead>
              <Tr>
                <Th style={{ width: "10%" }}>Branch ID</Th>
                <Th style={{ width: "10%" }}>Name</Th>
                <Th style={{ width: "10%" }}>Contact</Th>
                <Th style={{ width: "10%" }}>Account Details</Th>
                <Th style={{ width: "10%" }}>IFSC</Th>
                <Th style={{ width: "15%" }}>K.No.</Th>
                <Th style={{ width: "15%" }}>Discom</Th>
                <Th style={{ width: "10%" }}>Status</Th>
                <Th style={{ width: "10%" }}>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {providerList.map((item, idx) => (
                <Tr key={idx}>
                  <Td>{item.branch_id}</Td>
                  <Td>{item.name}</Td>
                  <Td>{item.mobile}</Td>
                  <Td>{item.account_no}</Td>
                  <Td>{item.ifsc}</Td>
                  <Td>{item.kNo}</Td>
                  <Td>{item.Electricity_Distribution_Company}</Td>
                  <Td>{item.status ? "Active" : "Inactive"}</Td>
                  <Td style={{ whiteSpace: "nowrap" }}>
                    <Link
                      to={`${path}/edit/${item._id}`}
                      className="btn btn-sm btn-primary mr-1"
                    >
                      <Edit />
                    </Link>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default BranchOwnersList;
