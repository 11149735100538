import { fetchWrapper } from "../_helpers";

const apiUrl = process.env.REACT_APP_API_URL;

const baseUrl = `${apiUrl}/ms/payments/icicienach`;

export const icicienachService = {
  addEnach,
  getAll,
  resend,
  getEnach,
  getAllActive,
  getEnachBySearch,
  handleInActive,
  getEnachByAU
};

function addEnach(params) {
  return fetchWrapper.post(baseUrl, params);
}

function getAll(){
  return fetchWrapper.get(baseUrl);
}
function resend(refCode){
  let params=
  {'reference_code': refCode};
  return fetchWrapper.post(`${baseUrl}/resend`, params);
}

function getEnach(params){
  console.log(params)
  return fetchWrapper.post(`${baseUrl}/getenach`, params)
}
function getEnachByAU(params){
  console.log(params)
  return fetchWrapper.post(`${baseUrl}/getenachbyau`, params)
}
function getAllActive() {
  return fetchWrapper.post(`${baseUrl}/getenachactive`, {});
}

function getEnachBySearch(params){
  console.log(params)
  return fetchWrapper.post(`${baseUrl}/getenach`, params)
}

function handleInActive(id){
  console.log(id)
  return fetchWrapper.post(`${baseUrl}/inactive/${id}`)
}