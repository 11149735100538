import React from 'react';
import { useState } from 'react';
import './AddCourtCase.css';
import CourtCaseServices from '../../../_services/courtcase.service';
import { employeeService } from '../../../_services';
import { Paper } from '@material-ui/core';
import datehelper from '../../../_helpers/datehelper';
import { useEffect } from 'react';

export const AddCourtCase = (props) => {
    let case_type = props.location.state.case_type;
    let case_value = props.location.state&&props.location.state.case
    const [value, setValue] = useState({})
    const ndate = new Date()
    const user = employeeService.userValue

    const handleChange = (fieldKey, fieldValue) => {
        value[fieldKey] = fieldValue;
        setValue(value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        await CourtCaseServices.AddCase({ ...value, case_type: case_type, emp_id: user.emp_id })
            .then(res => {
                window.alert('Case detail saved successfully');
                window.history.back();
            })
            .catch(err => {
                window.alert('Some error occurred')
                console.log(err.response)
            })
    }

    const handleEdit =(e)=>{
        e.preventDefault();
        CourtCaseServices.UpdateCase(value._id,value)
        .then(res => {
            window.alert('Case details updated successfully');
            window.history.back();
        })
        .catch(err => {
            window.alert('Some error occurred')
            console.log(err.response)
        })
    }

    function fillCaseDetails() {
        if (props.location.state.case) {
            setValue(props.location.state.case)
        }
    }

    useEffect(() => {
        fillCaseDetails()
    }, [])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h3 style={{ marginTop: '30px' }}>{case_value?'Edit Case':'Add Case'}</h3>
            <form onSubmit={case_value?handleEdit:handleSubmit} style={{ maxWidth: '900px', marginTop: '30px' }}>
                <Paper style={{ padding: '20px' }}>
                    <div className='formfields'>
                        <label className='label'>
                            <span>Case Category</span>
                            <input type="text" className='field' name='case_category_name' defaultValue={case_type} onChange={(e) => { handleChange([e.target.name], e.target.value) }} disabled required />
                        </label>
                        <label className='label'>
                            <span>Loan No</span>
                            <input type="text" className='field' name='loan_no' defaultValue={value.loan_no} onChange={(e) => { handleChange([e.target.name], e.target.value) }} required />
                        </label>
                        <label className='label'>
                            <span>Branch</span>
                            <input type="text" className='field' name='branch' defaultValue={value.branch} onChange={(e) => { handleChange([e.target.name], e.target.value) }} required />
                        </label>
                        <label className='label'>
                            <span>Case Open Date</span>
                            <input type="date" className='field' name='case_open_date' defaultValue={value.case_open_date && datehelper.getDateInputString(new Date(value.case_open_date))} max={datehelper.getDateInputString(ndate)} onChange={(e) => { handleChange([e.target.name], e.target.value) }} required />
                        </label>
                        <label className='label'>
                            <span>Raised by</span>
                            <input type="text" className='field' name='raised_by' defaultValue={value.raised_by} onChange={(e) => { handleChange([e.target.name], e.target.value) }} required />
                        </label>
                        <label className='label'>
                            <span>Party Type</span>
                            <input type="text" className='field' name='party_type' defaultValue={value.party_type} onChange={(e) => { handleChange([e.target.name], e.target.value) }} required />
                        </label>
                        <label className='label'>
                            <span>Party Name</span>
                            <input type="text" className='field' name='party_name' defaultValue={value.party_name} onChange={(e) => { handleChange([e.target.name], e.target.value) }} required />
                        </label>
                        <label className='label'>
                            <span>Case No</span>
                            <input type="text" className='field' name='case_no' defaultValue={value.case_no} onChange={(e) => { handleChange([e.target.name], e.target.value) }} required />
                        </label>
                        <label className='label'>
                            <span>Jurisdiction Name</span>
                            <input type="text" className='field' name='jurisdiction_name' defaultValue={value.jurisdiction_name} onChange={(e) => { handleChange([e.target.name], e.target.value) }} required />
                        </label>
                        <label className='label'>
                            <span>Loan</span>
                            <input type="number" className='field' name='loan' defaultValue={value.loan} onChange={(e) => { handleChange([e.target.name], e.target.value) }} required />
                        </label>
                        <label className='label'>
                            <span>Claim Amount</span>
                            <input type="number" className='field' name='claim_amount' defaultValue={value.claim_amount} onChange={(e) => { handleChange([e.target.name], e.target.value) }} required />
                        </label>
                        <label className='label'>
                            <span>Court Name</span>
                            <input type="text" className='field' name='court_name' defaultValue={value.court_name} onChange={(e) => { handleChange([e.target.name], e.target.value) }} required />
                        </label>
                    </div>
                </Paper>
                <div className='btncontainer'>
                    <button type="submit" className="btnblue">
                        {" "}
                        Submit{" "}
                    </button>
                </div>
            </form>
        </div>
    )
}