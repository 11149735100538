import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NI138CaseList } from './NI138CaseList';
import { ArbitrationCaseList } from './ArbitrationCaseList';
import { AddCourtCase } from './AddCourtCase';
import ExecutionCaseList from './ExecutionCaseList';

export const CourtCaseIndex =({match})=>{
    const {path}=match;
    
    return (
        <div>
            <Switch>
                <Route exact path={`${path}/ni138`} component={NI138CaseList}/>
                <Route path={`${path}/addnew`} component={AddCourtCase}/>
                <Route path={`${path}/arbitration`} component={ArbitrationCaseList}/>
                <Route path={`${path}/execution`} component={ExecutionCaseList}/>
            </Switch>
        </div>
    )
}