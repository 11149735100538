import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
  withStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

import * as Yup from "yup";

import { Formik, Field, Form, ErrorMessage } from "formik";

import Fab from "@material-ui/core/Fab";
import SaveIcon from "@material-ui/icons/Save";
import { Edit } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import {loanService}  from "../../../../_services/loan.service";
import { employeeService } from "../../../../_services";
import FormElement from "../../../uielements/FormElement";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "60%",
    overflow: "scroll",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    minWidth: 450,
  },
}));

export default function EditApplicantModal(props) {
  const customer = props.customer;
  const rows=props.rows;
  const applicant_type=props.applicant_type;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const user = employeeService.userValue;
  const formikRef = React.useRef();
  
  const inputFields=['tapplicant.general_information.name',
          'tapplicant.customer_id',
          'tapplicant.general_information.title',
          'tapplicant.general_information.sonof',
          'tapplicant.general_information.wifeof',
          'tapplicant.general_information.kyc_relation_type',
          'tapplicant.general_information.kyc_relation',
          'tapplicant.general_information.contact',
          'tapplicant.general_information.masked_aadhar',
          'tapplicant.general_information.pan_no',
          'tapplicant.general_information.address',
          'tapplicant.general_information.age',
          'tapplicant.general_information.gender',
          'tapplicant.general_information.education'
         ];
  

  const initialValues = {'tapplicant':{'general_information':{

  }}};
  rows.map((r)=>{
          inputFields.map((ip)=> {
            //console.log(ip)
            let splitFields=ip.split(".")
            if(r.key===ip)
            {
              if(splitFields.length==3)
              {
                 initialValues[splitFields[0]][splitFields[1]][splitFields[2]]=r.value;
              }
              if(splitFields.length==2)
              {
                 initialValues[splitFields[0]][splitFields[1]]=r.value;
              }

       
            }
          })
  });
  
  console.log(initialValues);



  const validationSchema = Yup.object().shape({
    tapplicant:Yup.object().shape({
      general_information:Yup.object().shape({
        name: Yup.string().required("This field is required"),
        sonof:Yup.string().required("This field is required"),
        contact:Yup.string().matches(/^[0-9]{10}$/,"Valid number").required("This field is required"),
        pan_no:Yup.string().matches(/^[a-zA-Z0-9]{10}$/,"Valid PAN number"),
        address:Yup.string().required("Address is required"),
      }),
    }),
     });
  
  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  function handleChange(fieldName,value,setFieldValue)
  {
      setFieldValue(fieldName,value);
  }

  
  async function onSubmit(fields, { setSubmitting }) {
    let params={};
    //console.log(fields);
    let newApplicant={}
    inputFields.map((ip)=> {
      //console.log(ip)
      let splitFields=ip.split(".")
      if(!newApplicant[splitFields[1]])
      {
        newApplicant[splitFields[1]]={}
      }
      if(splitFields.length==3)
      {
        newApplicant[splitFields[1]][splitFields[2]]=fields[splitFields[0]][splitFields[1]][splitFields[2]] 
      }
      if(splitFields.length==2)
      {
        newApplicant[splitFields[1]]=fields[splitFields[0]][splitFields[1]] 
      }

           
    });
   
    //console.log(newApplicant);
    //return;
  
    let target_field="";
    if(applicant_type==="co_applicant")
    {
      target_field="co_applicants";
      params[target_field]=[];
      customer.co_applicants.map((c)=> {
         if(c.customer_id ===initialValues["tapplicant"]["customer_id"])
         {
            newApplicant={...c,...newApplicant}
            params[target_field].push(newApplicant);
         }
         else
         {
          params[target_field].push(c);
         }
      })
      
    }
    if(applicant_type==="guarantor")
    {
      target_field="guarantors";
      params[target_field]=[];
      customer.guarantors.map((c)=> {
        if(c.customer_id===newApplicant.customer_id)
        {
          newApplicant={...c,...newApplicant}
         params[target_field].push(newApplicant);
        }
        else
        {
         params[target_field].push(c);
        }
     })
   
    }
    if(target_field==="")
      return;
    console.log(params);   
    //return;
    await loanService.update(customer._id,params)
    .then((ns)=> {
      console.log("Applicant added");
      window.alert("Details added");
      window.location.reload();
    })
    .catch((err)=> {
      console.log(err);
      window.alert("error adding details");
    })
    setSubmitting(false);
  }

  const updateKycRelation = (value, values, setFieldValue) => {
    // console.log(values.tapplicant.general_information.kyc_relation_type, "oo")
  if(values && values.tapplicant && values.tapplicant.general_information){
    console.log(value, "enter here")
    if(value === "S/o" || value === "D/o"){
      setFieldValue('tapplicant.general_information.kyc_relation_type', value)
      setFieldValue('tapplicant.general_information.kyc_relation', values.tapplicant.general_information.sonof)
    }else if(value === "W/o" ){
      setFieldValue('tapplicant.general_information.kyc_relation_type', value)
      setFieldValue('tapplicant.general_information.kyc_relation', values.tapplicant.general_information.wifeof)
    }else{
      setFieldValue('tapplicant.general_information.kyc_relation_type', value)
      setFieldValue('tapplicant.general_information.kyc_relation', "")
    }
  }
  }

  const updateRelation = (key, setFieldValue, value) => {
    setFieldValue(`${key}`, value)
  }

  return (
    <div>
     
     <Button
        onClick={()=>handleOpen()}
        variant="contained"
        color="primary"
      >
        <Edit></Edit>
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Edit Details</h2>
      
              <Formik
                innerRef={formikRef}
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize={true}
                id="transition-modal-description"
              >
              {({ errors, touched, isSubmitting, setFieldValue,values }) => {
                  return (
                      <Form>
                            <TableContainer>
                                <Table className={classes.table} aria-label="customized table">
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell>
                                        {applicant_type==="co_applicant" && ("Co-Applicant Details")}
                                        {applicant_type==="guarantor" && ("Guarantor Details")}
                                      </StyledTableCell>
                                      <StyledTableCell align="right">
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                          {rows.map((row) => (
                              !row.editable && row.type ==='hidden' ?"":                            <StyledTableRow key={row.label}>
                                <StyledTableCell component="th" scope="row">
                                  {row.label}
                                </StyledTableCell>
                                {row.editable && (row.type=== "text" || row.type==="number") && (
                                  <StyledTableCell align="right">
                                    <FormElement
                                    type={row.type}
                                    name={row.key}
                                    label={row.label}
                                    errors={errors}
                                    touched={touched}
                                  />
                                  </StyledTableCell>
                                )}
                                {row.editable && (row.type=== "hidden") && (
                                  <StyledTableCell align="right">
                                    <FormElement
                                    type="password"
                                    name={row.key}
                                    label={row.label}
                                    errors={errors}
                                    touched={touched}
                                  />
                                  </StyledTableCell>
                                )}
                                {row.editable && row.type=== "select" && (
                                  <StyledTableCell align="right">
                                    <Field
                                        as="select"
                                        name={row.key}
                                        label={row.label}
                                        errors={errors}
                                        touched={touched}
                                        onChange={(e)=> row.key === "tapplicant.general_information.kyc_relation_type" ? updateKycRelation(e.target.value, values, setFieldValue): updateRelation(row.key, setFieldValue, e.target.value)}
                                      >
                                      <option value=''>select option</option>  
                                      {row.options && row.options.map((o)=> {
                                        return <option value={o}>{o}</option>
                                      })}
                                    </Field>
                                  </StyledTableCell>
                                )}

                                {!row.editable && row.type !=="hidden" && (
                                  <StyledTableCell align="right">
                                    <FormElement
                                    type="text"
                                    disabled={true}
                                    name={row.key}
                                    label={row.label}
                                    errors={errors}
                                    touched={touched}
                                  />
                                  </StyledTableCell>
                                )}
                                
                                
                              </StyledTableRow>
                            )
                          
                            )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                                              
                  <hr/>
                        <div className="row justify-content-around">
                        <Fab
                          type="submit"
                          disabled={isSubmitting}
                          color="primary"
                          aria-label="save"
                        >
                          {isSubmitting && (
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                          )}
                          <SaveIcon />
                        </Fab>
                      </div>
                      </Form>
                  );
                 }}
              </Formik>         
          </div>
        </Fade>
      </Modal>
    </div>
  );
}