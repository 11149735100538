import React from "react";
import { loanService } from "../../../../_services/loan.service";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import datehelper from "../../../../_helpers/datehelper";
import AddDisbursementDetails from "./AddDisbursementDetails";
import roleChecker from "../../../../_helpers/rolechecker";
import { employeeService } from "../../../../_services";
import AddDisbursementAccount from "./AddDisbursementAccount";
import DisbursementDetailMemo from "./DisbursementDetailMemo";

const DisbursementAccountList = (props) => {
  const customer = props.customer;
  const user = employeeService.userValue;
  console.log(props);

  const verifyBankAccount = (loan_account, bank_account_num) => {
    console.log("verifying bank details");
    let params = { loan_account, bank_account_num };
    loanService
      .verifyBankDetails(params)
      .then((res) => {
        console.log(res);
        window.alert(
          "verification request complete. Please check details again."
        );
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        window.alert("Error verifying account. Try again");
      });
  };

  const deleteVerifiedAccount = (idx) => {

    let disbursement_details = [];
      if(customer && customer.disbursement && customer.disbursement.disbursement_details){
      disbursement_details = customer.disbursement.disbursement_details;
      }else{
        disbursement_details = [];
      }
      let params = [];
      if(customer && customer.disbursement && customer.disbursement.disbursement_accounts){
        params = customer.disbursement.disbursement_accounts;
        params.splice(idx,1)
      }else{
        params = []
      }
      let disbursement_memo = []
      if(customer && customer.disbursement && customer.disbursement.disbursement_memo){
        disbursement_memo = customer.disbursement.disbursement_memo;
      }else{
        disbursement_memo = []
      }

      let updateObject = {
        disbursement: {
          disbursement_details:[...disbursement_details],
          disbursement_accounts: [...params],
          disbursement_memo:[...disbursement_memo]
        },
      };

      console.log(updateObject)
      
      loanService
        .update(customer._id, updateObject)
        .then((x) => {
          console.log(x);
          window.alert("Entry Deleted");
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
      
  }

  return (
    <div>
      <h2
        style={{
          color: "wheat",
          backgroundColor: "black",
          textAlign: "center",
        }}
      >
        Bank Account List
      </h2>
      <table style={{ marginTop: "10px" }}>
        <tr style={{ border: "1px solid" }}>
          <th style={{ border: "1px solid" }}>Account Holder Name</th>
          <th style={{ border: "1px solid" }}>Account Number</th>
          <th style={{ border: "1px solid" }}>IFSC Code</th>
          <th style={{ border: "1px solid" }}>Bank Name</th>
          <th style={{ border: "1px solid" }}>Account Mobile</th>
          <th style={{ border: "1px solid" }}>Verification</th>
          <th style={{ border: "1px solid" }}>Action</th>
        </tr>
        {customer &&
          customer.disbursement &&
          customer.disbursement.disbursement_accounts &&
          customer.disbursement.disbursement_accounts.map((e, idx) => {
            return (
              <tr style={{ border: "1px solid" }}>
                <td style={{ border: "1px solid", textAlign: "center" }}>
                  {e.account_holder_name}<br/>
                  Role:- {e.account_holder_role}
                </td>
                <td style={{ border: "1px solid", textAlign: "center" }}>
                  {e.bank_account_number}
                </td>
                <td style={{ border: "1px solid", textAlign: "center" }}>
                  {e.ifsc_code}
                </td>
                <td style={{ border: "1px solid", textAlign: "center" }}>
                  {e.bank_name}
                </td>
                <td style={{ border: "1px solid", textAlign: "center" }}>
                  {e.account_holder_mobile}
                </td>
                <td style={{ border: "1px solid", textAlign: "center" }}>
                  {e.validation_record &&
                    e.validation_record.status == "Success" && (
                      <div>
                        <span style={{ color: "green", marginLeft: "10px" }}>
                          Verified <VerifiedUserIcon />
                        </span>
                        <br />
                        Verified Beneficiary:{" "}
                        {e.validation_record.real_beneficiary_name}
                        <br />
                        Verification Date:{" "}
                        {datehelper.displayDate(
                          new Date(e.validation_record.validation_date)
                        )}
                      </div>
                    )}
                  {e.validation_record &&
                    e.validation_record.status == "Failed" && (
                      <div>
                        <span style={{ color: "red", marginLeft: "10px" }}>
                          Failed Verification
                        </span>
                        <br />
                        Fail Reason: {e.validation_record.reason}<br/>
                        <AddDisbursementAccount  cust = {customer} isEdit = {true} button = "Edit" index = {idx} />
                        {roleChecker.isHOOpsApproverAccess(user.role) && (
                            <button
                            onClick={() =>
                              verifyBankAccount(
                                customer.application_id,
                                e.bank_account_number
                              )
                            }
                            >
                            Re-Verify
                            </button>
                        )}
                        {!roleChecker.isHOOpsApproverAccess(user.role) && (
                            <span>Contact Admin/GM/HO Approver for re-verification !!</span>
                        )}
                      </div>
                    )}
                  {!e.validation_record && (
                    <div>
                      <span style={{ marginLeft: "10px" }}>Not Verified</span>
                    </div>
                  )}
                </td>

                <td
                  style={{
                    border: "1px solid",
                    textAlign: "center",
                    padding: "10px",
                  }}
                >
                  {!e.validation_record && (
                    <>
                    <button
                      onClick={() =>
                        verifyBankAccount(
                          customer.application_id,
                          e.bank_account_number
                        )
                      }
                    >
                      Verify
                    </button><br/><br/>
                    <AddDisbursementAccount  cust = {customer} isEdit = {true} button = "Edit" index = {idx} />
                    </>
                  )}
                  { <div>
                        {e.account_holder_role && e.account_holder_role === "Guarantor" ?  null : <>{customer && customer.loan && customer.loan.verification ? <DisbursementDetailMemo cust = {customer}  account_data={e} button={"Add Memo"} isEdit={false} />: <span style={{color:"red"}}>Please Verify the Disbursement Details!!</span>} </> }                
                       
                        <br /> 

                        {roleChecker.isHOOpsApproverAccess(user.role) && (<button style={{backgroundColor:"red", color:"white"}} onClick={()=>{deleteVerifiedAccount(idx)}}>Delete</button>)}                          
                        
                        <br /><br/>
                    
                        <button>Add ENach</button>
                      </div>
                    }
                    {e.validation_record &&
                    e.validation_record.status == "Failed" && (
                      <div>
                        <p>edit</p>
                          <AddDisbursementAccount  cust = {customer} isEdit = {true} button = "Edit" index = {idx} />
                      </div>
                    )}
                </td>
              </tr>
            );
          })}
      </table>
    </div>
  );
};

export default DisbursementAccountList;