import React from 'react'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import { Button } from '@material-ui/core';
import { kycService } from '../../_services/kyc.service';
import OtpInput from 'react-otp-input';
const AadharOtpVerifyModal = ({ customer, setVerificationDetails,
    setGeneralDetails, setPersonalDetails, onVerificationSuccess }) => {
    let verification_data = customer
    const adhar_no = customer.aadhaar_no
    const [open, setOpen] = React.useState(false);
    const [enteredOtp, setEnteredOtp] = React.useState('');
    const [response, setResponse] = React.useState({})
    const [spinner, setSpinner] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const style2 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 480,
        height: 400,
        bgcolor: 'rgb(224,224,224)',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        borderColor: "blue",
        borderRadius: "10px"
    };
    const handleAadharVerificationSuccess = (resp) => {
        const aadharData = resp;
        setVerificationDetails({
            // aadhaar_mask_no: aadharData.maskedAadhaarNumber,
            karza_aadhar_verified: true,
            karza_aadhar_verified_date: new Date()
        });
        setGeneralDetails({
            customer_title: aadharData.gender === "M" ? "Male" : "Female",
            customer_firstname: aadharData.name.split(' ')[0],
            customer_middlename: aadharData.name.split(' ').length > 2 ? aadharData.name.split(' ')[1] : "",
            customer_lastname: aadharData.name.split(' ').length > 2 ? aadharData.name.split(' ')[2] : aadharData.name.split(' ')[1],
            fathername: aadharData.fatherName,
            customer_dob: aadharData.dob,
            birthplace: aadharData.address.splitAddress.location,
            mobile_no_verified: true
        });
        setPersonalDetails({
            // address_line_1: aadharData.address.combinedAddress,
            address_line_1:( aadharData.address.splitAddress.landmark??"") + " " + (aadharData.address.splitAddress.location??"") + " " + (aadharData.address.splitAddress.postOffice??""),
            city: aadharData.address.splitAddress.subdistrict,
            pin_code: aadharData.address.splitAddress.pincode
        });
        handleClose();
        onVerificationSuccess()
        // alert("Aadhar Verified Success");
    };
    const sendOtp = () => {
        kycService.requestAadhaarOTP({ aadhar_no: adhar_no })
            .then((resp) => {
                console.log("Test response", resp)
                if (resp.response.statusCode === 202) {
                    handleAadharVerificationSuccess(resp.response.aadhar_data)
                }
                else {
                    setResponse(resp.response)
                    alert("OTP sent")
                }
            })
            .catch((err) => {
                onVerificationSuccess()
                console.log(err)
            })
    }
    const verifyOtp = () => {
        if (enteredOtp.length == 6) {
            setSpinner(true)
            kycService.requestAadhaarXML({ aadhar_no: `${adhar_no}`, otp: `${enteredOtp}`, request_id: response.requestId })
                .then((resp) => {
                    setSpinner(false)
                    if (resp.response.statusCode === 101) {
                        handleAadharVerificationSuccess(resp.response.result.dataFromAadhaar);
                    } else {
                        alert("Invalid OTP. Please try again!")
                    }
                })
                .catch((err) => {
                    setSpinner(false)
                    onVerificationSuccess()
                    console.log(err)
                })
        } else {
            setSpinner(false)
            alert("Enter the valid otp")
        }
    }
    return (
        <div>
            {
                <div>
                    {(verification_data && verification_data.aadhaarFront &&
                        verification_data.aadhaarBack && verification_data.aadhaar_no.length === 12) ?
                        <Button style={{ fontSize: "20px", borderRadius: "5px", color: 'black', border: '1px solid black' }}
                            onClick={() => { handleOpen(); sendOtp() }} >Fetch Details</Button> :
                        <Button style={{ fontSize: "20px", borderRadius: "5px", color: 'black', border: '1px solid black' }}
                            onClick={() => {
                                !(verification_data && verification_data.aadhaarFront &&
                                    verification_data.aadhaarBack) ? alert("Photo is Required") : alert('Please enter valid adhar no')
                            }}>Fetch Details</Button>}
                </div>
            }
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style2}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {spinner ? <div style={{ marginLeft: "45%", marginTop: "30%" }}><span className="spinner-border spinner-border-lg align-center"></span></div> : <>
                            <h4 style={{ textAlign: "center", color: "blue" }}>KYC-Aadhar Verification</h4>
                            <p style={{ color: "red", textAlign: "center", fontSize: "16px" }}>A 6 digit code has been sent to your aadhar registered mobile number</p>
                            <label style={{ marginLeft: "36%", color: "blue" }}>Enter OTP</label><br />
                            <OtpInput
                                value={enteredOtp}
                                inputStyle={{ width: "50px", color: "blue" }}
                                inputType='number'
                                containerStyle={{ height: "50px", marginBottom: "-40px", marginLeft: "10px", borderColor: "rgb(52,117,92)" }}
                                onChange={(text) => setEnteredOtp(text)}
                                numInputs={6}
                                renderSeparator={<span>&nbsp; &nbsp;</span>}
                                renderInput={(props) => <input {...props} />}
                            /> <br /><br />
                            <button type='submit' style={{ backgroundColor: "blue", height: "35px", marginLeft: "40%" }} onClick={() => verifyOtp()}><p style={{ color: "white" }}>Submit</p></button> <br />
                            <Button style={{ color: "blue", marginLeft: "34%", marginTop: "8px", fontSize: "14px" }} onClick={(e) => sendOtp(e)} >Resend code</Button>
                            <br />
                            <Button style={{ backgroundColor: "green", marginLeft: "30%", color: "white", fontSize: "14px", borderRadius: "5px", cursor: "pointer" }}
                                onClick={() => onVerificationSuccess()}>Continue without OTP</Button>
                        </>}
                    </Typography>
                </Box>
            </Modal>
        </div>
    )
}

export default AadharOtpVerifyModal
