 import React, { useState ,useEffect} from 'react';
import { useParams,useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { letter } from "../../../_services/lettertracking.service";
import { loanService } from "../../../_services";
import swal from 'sweetalert';
import { ApprovalRequestFilter } from '../../MakerCheckerFramework/ApprovalRequestFilter';
import { employeeService } from '../../../_services';
import workflowHelper from "../../../_helpers/workflowhelper";
const Settlement = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({
    name: "",
    relation: "",
    father_name: "",
    address: "",
    account_number: "",
    deposite_amount: "",
    discount_amount:""
  });
  const [applicationId, setApplicationId] = useState('');
 // Assuming you have accountId in your URL params
 const user= employeeService.userValue
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    ApprovalRequestFilter.RequestFilter({
      service: 'letter',
            api: 'saveSettlementDetails',
            id: formData.account_number,
            task: 'Create Settlement Cretificate Letter',
            updated_fields:['name', 'relation', 'father_name', 'address', 'account_number', 'deposite_amount',"discount_amount"],
            sensitive_fields: [],
            new_values: {...formData},
            old_values: formData,
            identification_key: 'Loan Number',
            identification_value: formData.account_number,
            emp_id: user.emp_id,
            approval_needed: true,
            approved: false,
            approval_access: 'isAccountChecker',
            product: 'sbl'
    })

    .then(async(x) => {
      console.log('resp is',x)
      const acUserData = await loanService.getEmpIdRoleWise({ role: 'AC' }); 
      console.log("AC User Data:", acUserData);
      if (Array.isArray(acUserData) && acUserData.length > 0) { // Ensure acUserData is an array and not empty
        console.log("Calling Redis Helper for sending Notifications");
        await Promise.all(
            acUserData.map((employee) => {
                const emp_id = employee.emp_id; // Extract emp_id for each user
    
                return workflowHelper.isRedisNotifications({
                  emp_id: user.emp_id,
                  message: `You have an approval request from ${user.emp_id} ,Please review and take necessary action.`,
                  action_link: `https://samruddhi.kaabilfinance.com/home/approvals/list`,
                  action_taken_by: emp_id
              });
            })
          );
        }
      console.log("test x", x)
      swal({
        title: "Success",
        text: "Request Sent to concerned Authority",
        icon: "success",
        button: "Okay"
      }).then(() => {
        // After user clicks "Okay" button, navigate to '/home/huee/wwww'
        history.push('/home/sblloans');
      });
      // alert("")
      // history.push('')
  })
  .catch((err) => {
      window.alert(err)
      console.log(err)
  })

  
    // letter.saveSettlementDetails(formData)
    //   .then((data) => {
    //     console.log(data, "Data added successfully");
    //     swal({
    //       title: "Success",
    //       text: "Data added successfully",
    //       icon: "success",
    //       button: "Okay"
    //     }).then(() => {
    //       // After user clicks "Okay" button, navigate to '/home/huee/wwww'
    //       history.push('/home/letters/list/settlementcertificate');
    //     });
    //   })
   
    //   .catch((err) => {
    //     console.error("Error while saving data:", err);
    //     swal({
    //       title: "Error",
    //       text: "Settlement with this account number already exists",
    //       icon: "error",
    //       button: "Okay"
    //     });
    //   });
  }

  const handleApplicationId = (event) => {
    setApplicationId(event.target.value);
  }


  const getCustomerData = () => {
    if (!applicationId) {
      swal({
        title: "Error",
        text: "Please Enter Account Number",
        icon: "error",
        button: "Okay"
      });
      return;
    }
    loanService.getBySearch({ application_id: applicationId })
      .then((data) => {
        setFormData({
          name: data[0]?.applicant?.general_information.name || "",
          relation: data[0]?.applicant?.general_information.kyc_relation_type || "",
          father_name: data[0]?.applicant?.general_information.sonof || "",
          address: data[0]?.applicant?.general_information.address || "",
          account_number: data[0]?.application_id || "",
          deposite_amount: data[0]?.loan?.sanction_amount || ""
        });
      })
      .catch((err) => {
        console.error("Error while fetching customer data:", err);
        alert("Error while fetching customer data");
      });
  };


//   const handleEdit =  () => {
//     letter.updateLrn(id, value)
//     .then(() => {window.alert("Updated Successfully"); window.history.back()})
//     .catch((err) => {
//       console.log(err)
//     })

//  }

 const {id}  = useParams();
 const getSingleSettlement = async () => {
  const details = await letter.getSingleSettlement(id);
  setFormData(details)
  console.log(details,"ssssssssssssswwwwwwwwwww");

}

useEffect(() => {
  if(id){
    getSingleSettlement()
  }
}, [])


const handleEdit =  () => {
  letter.updateSettlement(id, formData)
  .then(() => {
    swal({
      title: "Success",
      text: "Updated Successfully",
      icon: "success",
      button: "Okay"
    }).then(() => {
      window.history.back(); // Go back to the previous page
    });
  })
  .catch((err) => {
    console.log(err,"errerrrrrrrerererererere")
  })

}


console.log(typeof(id),"idididididididididid")
  return (
    <>
      <div>
      <h3 className='my-3' style={{ textAlign: "center" }}>Enter the Details to Create Settlement Certificate Letter</h3>
              {!id && (
  <div>
    
    <div className='container my-3'>
      <TextField type="text" className="form-control" required placeholder='Enter the Account Number' name='Loan' value={applicationId} onChange={handleApplicationId} />
    </div>
    <button onClick={getCustomerData}>Submit</button>
  </div>
)}
        
        <form onSubmit={handleSubmit}>

          <div className='my-3' style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <TextField type="text" className="form-control" required placeholder='Enter the Name' name='name' value={formData.name} onChange={handleChange} />
            <TextField type="text" className="form-control" required placeholder='Enter the Relation' name='relation' value={formData.relation} onChange={handleChange} />
            <TextField type="text" className="form-control" required placeholder='Enter the Father or Husband Name' name='father_name' value={formData.father_name} onChange={handleChange} />
          </div>

          <div className='container my-3'>
            <TextField type="text" className="form-control" required placeholder='Enter the Address' name='address' value={formData.address} onChange={handleChange} />
          </div>

          <div className='container my-3'>
            <TextField type="text" className="form-control" required placeholder='Enter the Account Number' name='account_number' value={formData.account_number} onChange={handleChange} />
          </div>

          <div className='container my-3'>
            <TextField type="number" className="form-control" required placeholder='Enter the Total sanction Amount' name='deposite_amount' value={formData.deposite_amount} onChange={handleChange} />
          </div>
          <div className='container my-3'>
            <TextField type="number" className="form-control" required placeholder='Enter the Total Discount Amount' name='discount_amount' value={formData.discount_amount} onChange={handleChange} />
          </div>
          <div className='container my-3'>
          {!id && ( <button className='btn btn-warning' type='submit'>Create Letter</button>)}
          {id===undefined?"":<button className='btn btn-danger mx-5' type='button' onClick={handleEdit}>Update Letter</button>}
          </div>
        </form>
        </div>
    </>
  );
}

export default Settlement;
