import React, { useState } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { loanService } from "../../../../_services";
import { employeeService } from "../../../../_services";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalContent: {
        backgroundColor: '#fff',
        padding: theme.spacing(2),
        width: 1000,
        height: 600,
        outline: 'none',
        borderRadius: 4,
        overflowY: 'auto',
    },
    modalTitle: {
        color: 'blue',
        textAlign: 'center',
        marginBottom: theme.spacing(2),
    },
}));

const AddLogInFeesModal = (props) => {
    const customer = props.customer;
    const user = employeeService.userValue;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const [login, setLogin] = useState([]);
    const [selectedValue, setSelectedValue] = useState();
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleChange = (event) => {
        setValue(event.target.value.trim());
    };
    // const handleSearch = () => {
    //     if (value.length >= 5) {
    //         loanService.searchLoginByUtr({ remark: value })
    //             .then((res) => setLogin(res))
    //             .catch((err) => console.log(err));
    //     }
    // };
    const handleCheckboxChange = (event) => {
        const selectedId = event.target.value;
        const loginFess = login.find((x) => x._id === selectedId);
        setSelectedValue(loginFess);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        loanService.searchLoginByUtr({ remark: value })
            .then((res) => setLogin(res))
            .catch((err) => console.log(err));
    };

    async function AddLogIn(fields) {
        if (fields.transaction_amount === 1200 || fields.transaction_amount === 2500) {
            let newApplicant = { 'application_fees': {} }
            newApplicant['application_fees']['payment_date'] = new Date();
            newApplicant['application_fees']['payment_mode'] = 'Online';
            newApplicant['application_fees']['payment_amount'] = fields.transaction_amount;
            newApplicant['application_fees']['payment_comment'] = "Payment manual from bank ledger";
            newApplicant['application_fees']['payment_received_employee_id'] = user.emp_id;
            console.log(newApplicant);
            await loanService.addApplicationFees(customer._id, newApplicant)
                .then((ns) => {
                    console.log("Applicant added");
                    window.alert("Details added");
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    window.alert("error adding details");
                })
        } else {
            alert('Amount Should be equal to 1200 or 2500')
        }
    }

    return (
        <div>
            <Button variant="contained" onClick={handleOpen} style={{ marginTop: "2.3rem", color: 'red' }}>
                Add Manual LogIn Fees
            </Button>
            <Modal open={open} onClose={handleClose} className={classes.modal}>
                <div className={classes.modalContent}>
                    <Typography variant="h5" className={classes.modalTitle}>
                        <h3 style={{ textAlign: 'center', marginBottom: '3rem', marginTop: '1.5rem' }}>
                            Add Manual LogIn Fees
                        </h3>
                        <form onSubmit={handleSubmit}>
                            <div style={{ fontSize: '17px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <label style={{ marginRight: '10px' }}>
                                    <b>Enter Utr Number:</b>
                                </label>
                                <input
                                    type="text"
                                    name="utr_no"
                                    value={value}
                                    onChange={handleChange}
                                    minLength={5}
                                    required
                                    style={{ marginRight: '10px', width: '250px' }}
                                />
                                <Button
                                    type="submit"
                                    // onClick={handleSearch}
                                    style={{
                                        background: '#3760F1',
                                        color: 'white',
                                        height: '30px',
                                        border: 'none',
                                        cursor: 'pointer',
                                    }}>
                                    Search
                                </Button>
                            </div>
                        </form>
                    </Typography>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th style={{ width: '25%' }}>Date</Th>
                                <Th style={{ width: '15%' }}>Amount</Th>
                                <Th style={{ width: '15%' }}>Ref.id</Th>
                                <Th style={{ width: '30%' }}>Remark</Th>
                                <Th style={{ width: '15%' }}>Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {login.length > 0 ? (
                                login.map((x, idx) => (
                                    <Tr
                                        key={idx}
                                        style={{ background: '#EFECEC', marginBottom: '80px', height: '50px', fontSize: '17px' }}>
                                        <Td>{new Date(x.date).toLocaleString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true, timeZone: 'UTC' }).replace(',', '').replace(/\//g, '-')}</Td>
                                        <Td>{x.transaction_amount}</Td>
                                        <Td>{x.transaction_ref_id}</Td>
                                        <Td>{x.remark}</Td>
                                        <Td>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <label>
                                                    <input
                                                        style={{ width: "20px", height: "25px", marginRight: "30px" }}
                                                        type="checkbox"
                                                        value={x._id}
                                                        onChange={handleCheckboxChange}
                                                        checked={selectedValue?._id === x._id}
                                                    />
                                                </label>
                                            </div>
                                        </Td>
                                    </Tr>
                                ))
                            ) : (
                                <Tr>
                                    <Td colSpan="5" align="center">Data Not Found</Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                    <br />
                    {selectedValue &&
                        <Button
                            onClick={() => AddLogIn(selectedValue)}
                            style={{
                                display: 'block',
                                margin: '0 auto',
                                background: "#3251F5",
                                color: 'white',
                                marginTop: '20px',
                                border: 'none',
                                cursor: 'pointer',
                            }}>
                            Add Login fees
                        </Button>}
                </div>
            </Modal>
        </div>
    );
};
export default AddLogInFeesModal;
