import React from "react";
import JobSheetItem from "./JobSheetItem";

export default function JobSheetList({
  values,
  handleChange,
  handleRemoveSpecificRow,
}) {
  return (
    <tbody>
      {values.map((item, idx) => {
        return (
          <JobSheetItem
            key={idx}
            idx={idx}
            item={item}
            value={values}
            handleChange={handleChange}
            handleRemoveSpecificRow={handleRemoveSpecificRow}
          />
        );
      })}
    </tbody>
  );
}
