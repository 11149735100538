import React from "react";
import PropTypes from "prop-types";
import {
    makeStyles,
    AppBar,
    Tabs,
    Tab,
    Typography,
    Box,
    Avatar,
} from "@material-ui/core";
import { ApplicantForm } from "./ApplicantForm";
import { useContext } from "react";
import { TabContext } from "../TabBar";
import { useEffect, useState } from "react";
import { customerService, employeeService } from "../../../../_services";
import SearchCustomer from "./SearchCustomer";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function CustomersTabBar(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    // const [image, setImage] = useState('')
    let TabContextValue = useContext(TabContext)
    let cust = TabContextValue.cust


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // async function getApplicant() {
    //     await customerService.searchCustomer({ customer_id: cust.applicant.customer_id })
    //         .then((res) =>{
    //              setApplicant(res[0])
    //              getImage()
    //         } 
    //              )
    //         .catch(err => console.log(err))
    // }

    // useEffect(() => {
    //     getApplicant()
    // }, [])

    // const getImage = () => {
    //     applicant && applicant.kyc_images.map((x, idx) => {
    //         if (x.tag === "Customer Photo") {
    //            setImage(x)
    //         }
    //     })
    // }
    let image = ''

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <>
                    {console.log(image)}
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="simple tabs example"
                        style={{ color: "blue", backgroundColor: "white" }}
                    >
                         {cust && cust.applicant && cust.applicant.kyc_images && cust.applicant.kyc_images.map((x)=>{
                                if(x.tag === "Customer Photo"){
                                    image = x.uri
                                }
                                else{
                                    image = ''
                                }
                            })}
                            {console.log(image, "applicant image")}
                        {props.role === "Applicant" && (
                            <Tab label={cust.applicant.general_information.name} icon={<Avatar style={{ width: "80px", height: "80px" }} src={image} />} {...a11yProps(0)} style={{ minWidth: "10%" }} />
                        )}
                        {props.role === "CoApplicant" && cust && cust.co_applicants && cust.co_applicants.length > 0 && cust.co_applicants.map((x, idx) => {
                            x && x.kyc_images && x.kyc_images.map((y) => {
                                if (y.tag === "Customer Photo") {
                                    image = y.uri
                                } else {
                                    image = ''
                                }
                            })
                            return <Tab label={x.general_information.name} icon={<Avatar style={{ width: "80px", height: "80px" }} src={image} />} {...a11yProps(idx)} style={{ minWidth: "10%" }} />
                        })}

                        {props.role === "Guarantor" && cust && cust.guarantors && cust.guarantors.length > 0 && cust.guarantors.map((x, idx) => {
                            x && x.kyc_images && x.kyc_images.map((y) => {
                                if (y.tag === "Customer Photo") {
                                    image = y.uri
                                } else {
                                    image = ''
                                }
                            })
                            return <Tab label={x.general_information.name} icon={<Avatar style={{ width: "80px", height: "80px" }} src={image} />} {...a11yProps(idx)} style={{ minWidth: "10%" }} />
                        })}
                        {props.role === "CoApplicant" && !["Operation","Disbursal","Collection"].includes(cust.stage) && (<SearchCustomer role={"CoApplicant"} application_id={cust.application_id} co_applicants={cust && cust.co_applicants && cust.co_applicants && cust.co_applicants.length >0 && cust.co_applicants} guarantors={cust && cust.guarantors && cust.guarantors.length > 0 && cust.guarantors} />)}
                        {props.role === "Guarantor" && !["Operation","Disbursal","Collection"].includes(cust.stage) && (<SearchCustomer role={"Guarantor"} application_id={cust.application_id} co_applicants={cust && cust.co_applicants && cust.co_applicants && cust.co_applicants.length >0 && cust.co_applicants} guarantors={cust && cust.guarantors && cust.guarantors.length > 0 && cust.guarantors} /> )}
                    </Tabs>
                </>
            </AppBar>
            {props.role === "Applicant" && (
                <TabPanel value={value} index={0}>
                    {cust && cust.applicant && <ApplicantForm applicant={cust.applicant} role={props.role} />}
                </TabPanel>
            )}

            {props.role === "CoApplicant" && cust && cust.co_applicants && cust.co_applicants.length > 0 && cust.co_applicants.map((x, idx) => {
                return <TabPanel value={value} index={idx}>
                    {<ApplicantForm applicant={x} role={props.role} />}
                </TabPanel>
            })}

            {props.role === "Guarantor" && cust && cust.guarantors && cust.guarantors.length > 0 && cust.guarantors.map((x, idx) => {
                return <TabPanel value={value} index={idx}>
                    {<ApplicantForm applicant={x} role={props.role} />}
                </TabPanel>
            })}

        </div>
    );
}
