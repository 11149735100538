import React from "react";
import { Route, Switch } from "react-router-dom";

import AddProvider from "./AddProvider"
import ProviderList from "./ProviderList"

function Providers({ match }) {
  const { path } = match;
  console.log('path',path)

  return (
    <Switch>
      <Route exact path={path} component={ProviderList} />
      <Route path={`${path}/add`} component={AddProvider} />
      <Route path={`${path}/edit/:id`} component={AddProvider} />

    </Switch>
  );
}

export { Providers };
