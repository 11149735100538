import React from "react";
import { useReactToPrint } from "react-to-print";
import AuthorityLetter from "./AuthorityLetter";
import { useRef } from "react";

export default function AuthorityLetterPrint(props) {
  const authorityRef = useRef();

  const handleAuthorityPrint = useReactToPrint({
    content: () => authorityRef.current,
  });

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <button onClick={handleAuthorityPrint}>Print Authority Letter</button>
      </div>
      <AuthorityLetter ref={authorityRef} dataFill={props.location.state} />
    </div>
  );
}
