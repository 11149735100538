import React ,{useEffect,useState}from "react";
import * as Yup from "yup";
import { Formik,Form, Field } from "formik";
import FormElement from "../../../uielements/FormElement";
import { loanService } from "../../../../_services";
import { history } from "../../../../_helpers";
import {urlhhelper} from "../../../../_helpers/urlhelper"
import roleChecker from "../../../../_helpers/rolechecker";
import { employeeService,customerService } from "../../../../_services";
import {
  withStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import AddApplicantModal from "./AddApplicantModal";
import EditApplicantModal from "./EditApplicantModal";
import { Delete } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import AddColenderModal from "./AddColenderModal";
import EditColenderModal from "./EditColenderModal";
import { ColendingSubStages } from "../../../../constants/colendingsubstages";
import urlhelper from "../../../../_helpers/urlhelper";

const colenderStatusOptions = [
  ColendingSubStages.FilePrepared,
  ColendingSubStages.FileLoggedIn,
  ColendingSubStages.QueryPendingAtKaabil,
  ColendingSubStages.QueryPendingAtColender,
  ColendingSubStages.ApprovedFromCredit,
  ColendingSubStages.ApprovalForDisbursement,
  ColendingSubStages.RepaymentToColender];
  
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(key,label,value,editable,type,options=[]) {
  return { key,label, value,editable,type,options};
}

function getColenderAllowedStatus(currentStatus)
{
     if(currentStatus==ColendingSubStages.AmountReceivedFromColender)
     {
      return [ColendingSubStages.AmountReceivedFromColender];
     }
     if(currentStatus==ColendingSubStages.ColenderLoanClosed)
     {
       return [ColendingSubStages.ColenderLoanClosed];
     }
     return colenderStatusOptions;
}

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function ColenderData({ customer }) {

  const user = employeeService.userValue;

  const classes = useStyles();
  const colenderNameOptions = ["CapitalIndia","Ugro","BhalaFinance","KamalAutoFinance","AshvFinance","VistaarFinance"];
  console.log('customer colending data is',customer)
  const mainrows=[];
  if(customer.co_lending )
  {
    customer.co_lending.map((colender)=> {
      console.log("colender colendercolender",colender)
      if((!["ColendingCancelled"].includes(colender.co_lender_status) || ["COLA","COLM","Admin"].includes(user.role) || roleChecker.isGMLevelAccess(user.role) )){ 
      const rows=[]; 
      rows.push(createData("tcolender.co_lender_name","Co-Lender Name", colender.co_lender_name,false,"select",colenderNameOptions)); 
      rows.push(createData("tcolender.co_lender_account_num","Co-Lender Account", colender.co_lender_account_num,true,"text")); 
      let colenderStatusOptions=getColenderAllowedStatus(colender.co_lender_status);
      if(colender.co_lender_status==ColendingSubStages.AmountReceivedFromColender || colender.co_lender_status == ColendingSubStages.ColenderLoanClosed )
      {
        rows.push(createData("tcolender.co_lender_status","Co-Lender Status", colender.co_lender_status,true,"select",colenderStatusOptions)); 
        rows.push(createData("tcolender.co_lender_sanction_amount","Sanction Amount", colender.co_lender_sanction_amount,false,"number"));
        rows.push(createData("tcolender.co_lender_interest_rate","Interest Rate", colender.co_lender_interest_rate,false,"number"));
        rows.push(createData("tcolender.co_lender_tenure","Tenure", colender.co_lender_tenure,false,"number"));
        rows.push(createData("tcolender.co_lender_emi_day","Emi Day", colender.co_lender_emi_day,false,"number"));
        rows.push(createData("tcolender.co_lender_received_amount","Received Amount", colender.co_lender_received_amount,false,"number"));
        rows.push(createData("tcolender.co_lender_received_date","Disbursement Date", colender.co_lender_received_date,false,"date"));
        
      }
      else
      {
        rows.push(createData("tcolender.co_lender_status","Co-Lender Status", colender.co_lender_status,true,"select",colenderStatusOptions)); 
        rows.push(createData("tcolender.co_lender_sanction_amount","Sanction Amount", colender.co_lender_sanction_amount,true,"number"));
      rows.push(createData("tcolender.co_lender_interest_rate","Interest Rate", colender.co_lender_interest_rate,true,"number"));
      rows.push(createData("tcolender.co_lender_tenure","Tenure", colender.co_lender_tenure,true,"number"));
      rows.push(createData("tcolender.co_lender_emi_day","Emi Day", colender.co_lender_emi_day,true,"number"));
      rows.push(createData("tcolender.co_lender_received_amount","Received Amount", colender.co_lender_received_amount,true,"number"));
      rows.push(createData("tcolender.co_lender_received_date","Disbursement Date", colender.co_lender_received_date,true,"date"));
      
      
      }
      rows.push(createData("tcolender.co_lender_stage_emp_id","Assigned to", colender.co_lender_stage_emp_id,false,"number"));
      if(colender.co_lender_insurance_amount)
      {
        rows.push(createData("tcolender.co_lender_insurance_amount", "Insurance Amount", colender.co_lender_insurance_amount, true, "number"));
      }
      else{
        rows.push(createData("tcolender.co_lender_insurance_amount", "Insurance Amount", 0, true, "number"));
   
      }
      if(colender.co_lender_rps_report)
      {
        rows.push(createData("tcolender.co_lender_rps_report", "RPS Report", colender.co_lender_rps_report,  true, "file"));
      
      }
      else
      {
        rows.push(createData("tcolender.co_lender_rps_report", "RPS Report", "",  true, "file"));
      }
       
      if(colender.co_lender_pf_amount){
        rows.push(createData("tcolender.co_lender_pf_amount", "PF Amount", colender.co_lender_pf_amount, true, "number"))
      }else{
        rows.push(createData("tcolender.co_lender_pf_amount", "PF Amount", 0, true, "number"))
      }
      rows._id = colender._id
      mainrows.push(rows);
    }})
    // console.log("colender main rows");
    console.log("colender main rows",mainrows);
  }

const  cancelColender=( customerId, colenderId)=>{
  console.log('customerId, colenderId',customerId, colenderId)
  loanService.cancelColender({customerId, colenderId})
  .then((res)=>{console.log('res is',res);window.location.reload()})
  .catch((err)=>console.log('err',err))
}







const [ugrosheetResponse, setUgrosheetResponse] = useState(null);
const ugrosheet = async () => {
  try {
    const data = await customerService.getugrosheet({ loan_no: customer.application_id });
    setUgrosheetResponse(data);
    if (data && data.report_link) {
      // Create an anchor element
      const link = document.createElement('a');
      link.href = data.report_link;
      link.target = '_blank'; // Open in a new tab
      link.download = 'ugro_sheet.xlsx'; // Name of the file to download
      link.click(); // Trigger a click event on the anchor element
    }else {
      alert('Request Failed');
    }
  } catch (err) {
    console.log(err);
  }
};

const handleButtonClick = () => {
  ugrosheet();
};

  return (   <div>


<Button variant="contained" color="primary" style={{float:"right"}} onClick={handleButtonClick}>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
</svg>&nbsp;Co-Lender Excel
      </Button>
  <div style={{"padding":10}}> 
                        <AddColenderModal
                      customer={customer} /> 
                    </div>
                    <div style={{"padding":10}}>
                    {/* {mainrows && mainrows.length!==0 &&  <h5>To change the Co-Lender , Delete the current colender added</h5>  } */}
                    </div> 
    {mainrows.map((rows)=> { 
                     console.log("mainrows",rows)
    return <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell   >Co-Lender Details</StyledTableCell>
            <StyledTableCell  style={{ "textAlign":"center"}}>
            <div style={{"display":"flex","align":"right"}}
            >
              <EditColenderModal
              customer={customer}
              rows={rows}></EditColenderModal>
              </div>
            </StyledTableCell>
            <StyledTableCell  style={{ "textAlign":"center"}}>
           {roleChecker.isColendingAccess(user.role) && <Button style={{color:'red',background:'white'}} onClick={()=>{
              console.log('row data is',rows)
              if(window.confirm("are you sure you want to delete this colender , you can not add this colender again")){

              cancelColender(customer._id,rows._id);
              }
            }} >

            <Delete/>
            </Button>}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        

          {rows.map((row) => (
            <StyledTableRow key={row.label}>
              <StyledTableCell component="th" scope="row">
                {row.label}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
               </StyledTableCell>
              {console.log(row)}
              {row.label === "RPS Report" && row.value !== ""  ? <StyledTableCell><a href={`/home/pdfviewer?pdf_url=` + urlhelper.encodeToBase64(row.value) + "&download=1"} ><b>View</b></a>
</StyledTableCell> : <StyledTableCell align="right">{row.value}</StyledTableCell>}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

})} 
    </div>
  );
}
