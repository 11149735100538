import React from 'react'
import { Table } from '@material-ui/core';
import { Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import datehelper from "../../../_helpers/datehelper";
import logo from "../../../assets/kaabil.png";

export default class SibReleaseLetter extends React.Component {
    render() {
        // console.log("test", this.props)
        const data = this.props.dataFill
        const date = new Date();
        const contact = data.contact
        return (
            <div style={{ fontSize: '18px' }}>
                <div style={{
                    width: "290mm",
                    height: "410mm",
                    marginLeft: "auto",
                    marginRight: "auto",
                    backgroundColor: "white",
                    padding: 40,
                }}
                    className="d-flex flex-column"
                >
                    <div style={{ textAlign: "center" }}>
                        <img style={{ width: 350, height: 100, marginTop: "50px" }} src={logo}></img>
                    </div>
                    <br />
                    <br />
                    <p style={{ border: "1px solid black", textAlign: "center", width: "100px", height: "30px", marginTop: "20px" }}>
                        LD/2135
                    </p>
                    <br />
                    <b style={{ textAlign: "right" }}>{data.letter_number}</b>
                    <p style={{ textAlign: "center", textDecoration: "underLine" }}>APPLICATION FOR RELEASE OF GOLD ORNAMENTS</p>
                    <p style={{ textAlign: "center" }}>(Gold Power Scheme)</p>
                    <p style={{ textAlign: "right" }}>Place: Jaipur</p><br />
                    <b style={{ textAlign: "right" }}> Date: {contact ? datehelper.displayDate(date) : datehelper.displayDate(new Date(data.current_date))}</b>
                    <br />
                    <p>SIB GOLD POWER A/C NO._______    A/c Name :<b style={{ marginLeft: "50px" }}>M/s. Kaabil Finance P.Ltd.</b></p>
                    <p>
                        To
                        <br />
                        <b>THE SOUTH INDIAN BANK LTD</b> <br />
                        Jaipur
                        <br />
                        <br />
                    </p>
                    <p>
                        Dear Sir,<br />
                        I/We hereby request you to release the under mentioned gold ornaments held by you
                        as security for the limit extended to me/us/in the account of M/s. Kaabil Finance P. Ltd.
                        and deliver the same tome/us/ Kaabil finance private limited. In consideration of
                        your delivering the ornaments to me/us/my/our representative, I/We hereby undertake to
                        absolve The South Indian Bank Ltd, from all liabilities in the event of any dispute arising
                        out of such delivery and also undertake to indemnify you from and against any or all
                        claims which may arise by reason of such delivery. I/We also hereby guarantee and
                        agree to hold The South Indian Bank Ltd., their successors, and assigns, harmless and
                        indemnified from and against all consequence in the event of any dispute arising out of
                        such delivery to me/us/my/our representative and from against all losses, charges and
                        expenses in connection therewith.
                    </p>
                    <Table style={{ width: "100%", marginRight: "60px" }}>
                        <thead>
                            <Tr>
                                <Th style={{ border: "1px solid " }}>Name</Th>
                                <Th style={{ border: "1px solid " }}>KFPL Account Number</Th>
                                <Th style={{ border: "1px solid " }}>Bank's A/C Number</Th>
                                <Th style={{ border: "1px solid " }}>Sanction Date</Th>
                                <Th style={{ border: "1px solid " }}>Ornament's Name</Th>
                                <Th style={{ border: "1px solid " }}>GW</Th>
                                <Th style={{ border: "1px solid " }}>NW</Th>
                                <Th style={{ border: "1px solid " }}>Release Amount</Th>
                                <Th style={{ border: "1px solid " }}>Sealed in Packet Number</Th>
                            </Tr>
                        </thead>
                        <Tbody >
                            <Tr>
                                <Td style={{ border: "1px solid " }}>{data.name}</Td>
                                <Td style={{ border: "1px solid " }}>{data.details.loan_account && data.details.loan_account}</Td>
                                <Td style={{ border: "1px solid " }}>{data.loan_account && data.loan_account}</Td>
                                <Td style={{ border: "1px solid " }}>
                                    {datehelper.displayDate(new Date(data.date))}
                                </Td>
                                <Td style={{ border: "1px solid " }}>
                                    {data.details.gold.ornaments.map((item) => (
                                        <p>
                                            {item.desc} - {item.quantity}
                                        </p>
                                    ))}
                                </Td >
                                <Td style={{ border: "1px solid " }}>{data.gold && data.gold.gross_weight}</Td>
                                <Td style={{ border: "1px solid " }}>{data.gold && data.gold.net_weight}</Td>
                                <Td style={{ border: "1px solid " }}>{data.releaseAmount}</Td>
                                <Td style={{ border: "1px solid " }}>{data.packetNumber}</Td>
                            </Tr>
                        </Tbody>
                        <br />
                        <br />
                        <br />
                    </Table>
                    <p>Yours faithfully,</p>
                    <br />
                    <p>
                        (Signature)
                        <br />
                        Name and full address of the representative
                        <br />
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . ,
                        <br />
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . ,
                        <br />
                        ----------------------------------------
                        <br />
                        (Signature of the representative)
                    </p>
                </div>
                <div style={{
                    width: "290mm",
                    height: "410mm",
                    marginLeft: "auto",
                    marginRight: "auto",
                    backgroundColor: "white",
                    padding: 40,
                }}
                    className="d-flex flex-column"
                >
                    <div style={{ textAlign: "center" }}>
                        <img style={{ width: 350, height: 100, marginTop: "50px" }} src={logo}></img>
                    </div>
                    <br />
                    <br />
                    <p style={{ border: "1px solid black", textAlign: "center", width: "100px", height: "30px", }}>
                        LD/2135
                    </p>
                    <br />
                    <br />
                    <p style={{ textAlign: "left" }}>
                        Signature attested.
                        <br />
                        ----------------------------------------
                        <br />
                        (Signature of the borrower).
                    </p>
                    <br />
                    <br />
                    <p style={{ textAlign: "right" }}>Contd…2</p>
                    <br />
                    <p style={{ textAlign: "center" }}>: 2 :</p>
                    <br />
                    <br />
                    <b style={{ textAlign: "left", textDecoration: "underLine" }}>(FOR OFFICE USE)</b>
                    <br />
                    <p>SIB GOLD POWER A/C NO:  <b style={{ marginLeft: "60px" }}>A/c Name : M/s. Kaabil Finance P. Ltd.</b></p>
                    <br />
                    <br />
                    <table>
                        <tr>
                            <td>Present DP</td>
                            <td>:Rs………………..</td>
                        </tr>

                        <tr>
                            <td>Less: DP of requested release </td>
                            <td>:Rs………………..</td>
                        </tr>
                        <tr>
                            <td>Reduced Cumulative DP in the a/c</td>
                            <td>:Rs.……………….</td>
                        </tr>
                        {/* <br /> */}
                        <tr>
                            <td>Account Balance</td>
                            <td>:Rs.........................</td>
                        </tr>
                        <br />
                        <br />
                        <tr>
                            <td> Verified and release requested may be sanctioned</td>
                            <td>Sanctioned</td>
                        </tr>
                        <br />
                        <br />
                        <tr>
                            <td> ASST. MANAGER </td>
                            <td>MANAGER</td>
                        </tr>
                    </table>
                    <br />
                    <br />
                    <br />
                    <p style={{ fontWeight: "bold", textDecoration: "underLine" }}>RECEIPT FOR GOLD ORNAMENTS.</p>
                    <br />
                    <p> Received from THE SOUTH INDIAN BANK LTD, the aforementioned gold ornaments.</p>
                    <br />
                    <p style={{ textAlign: "left" }}>Date:</p>
                    <p style={{ textAlign: "right" }}>Signature</p>
                </div>

            </div>
        )
    }
}