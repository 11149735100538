import React, { useState, useEffect } from 'react'
import { Button, Modal } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { virtualAccountService } from "../../../_services/virtualaccount.service";

const VAQRModel = (props) => {

    const [open, setOpen] = useState(false)
    const [url, setUrl] = useState('')
    const [vpaUrl, setVPAUrl] = useState('')
    

    console.log(props)


    const handleOpen = () => {
        setOpen(true)
        getQRcode()
    }

    const handleClose = () => {
        setOpen(false)
    }

    const getQRcode = () => {
        if(open)
        {
            console.log("getting virtual account data for "+props.loan)
            virtualAccountService.getByLoanAccount({ loan_account: props.loan })
                .then((data) => {
                    console.log(data)
                    console.log("QR code type=="+process.env.REACT_APP_QR_CODE_TYPE)
                    if(process.env.REACT_APP_QR_CODE_TYPE==="VPA"  && data.vpa_qrcode_url && data.vpa_qrcode_url!=="" )
                    {
                        setVPAUrl(data.vpa_qrcode_url)
                    }
                    else
                    {
                        setVPAUrl('')
                    }
                    setUrl(data.qrcode_url)
                    
                    
                })
                .catch((err) => {
                    console.log(err)
                    setUrl('')
                })
        }
    }

    useEffect(() => { 
            getQRcode()
    }, [open,props])


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        height: 580,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        //overflow: 'scroll',
        //paddingBottom: '30px',
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>

          {props.loan == '' ? null : <Button style={{backgroundColor:"#F4B400", border:"1px solid"}}  onClick={handleOpen}>Show QR</Button>}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                            <div>
                            <p>QR code</p> 
                            </div>
                            {(!vpaUrl || vpaUrl ==='') && (
                            
                            <div>
                            <img style={{ width: "250px", height: "250px" }} src={url} alt="QR Code" />
                            
                            </div>
                             )}
                            {vpaUrl && vpaUrl !=='' && (
                                <div>
                                  <p>QR code</p> 
                                  <img style={{ width: "250px", height: "250px" }} src={vpaUrl} alt="New QR Code" />
                          
                                </div>
                            )}
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <span style={{ fontWeight: "bold", fontSize: 14 }}> Account No:- {`KAABIL${props.loan}`} </span>
                                <span style={{ fontWeight: "bold", fontSize: 15 }}> IFSC Code:-  ICIC0000106</span>
                            </div>
                            
                      </div>      

                    </Typography>
                </Box>
            </Modal>
        </div>
    )
}

export default VAQRModel