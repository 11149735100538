import React from 'react'
import { useReactToPrint } from "react-to-print";

const LegalNoticeLetter = (props) => {

    const LegalNoticeLetterRef = React.useRef();
    
    const handleSubmissionPrint = useReactToPrint({
        content: () => LegalNoticeLetterRef.current,
        pageStyle: "print"
      });

      const today = new Date()

      function convert(str) {
        let date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [day, mnth, date.getFullYear()].join("-");
        }
  return (
    <>
    <div style={{ textAlign: "center" }}>
      <button onClick={handleSubmissionPrint}>Print Letter</button>
    </div>
    <div ref = {LegalNoticeLetterRef} style={{marginTop:"20px",  textAlign: "justify", padding:"auto"}}>
    <div style={{display:'flex', justifyContent:"space-between"}}>
     <span style={{fontSize:"15px"}} >
      <h3>Vinod Kumar,</h3><br/>
      <b>Advocate</b><br/>
      <b>Rajasthan High Court,Jaipur ,Rajasthan</b><br/>
     </span> 

     <span style={{fontSize:"18px"}}>
        <h3>Reg.& Office</h3>
        <b>250, Maruti Nagar,Airport-1</b><br/>
        <b>Sanganer,Jaipur-302029</b><br/>
        <b>Mob. No-9461203525,7976141575</b><br/>
     </span>
     </div>
     <br/>
     <hr style={{borderTop: "3px solid"}}></hr>

     <b> <div style={{ fontSize:"18px"}}>
         <span>Ref No. KFPL/2022/{today.getMinutes()}</span>       
         <span style={{marginLeft:"60%"}}>Date</span><br/><br/>

                                            <span style={{marginLeft:"40%"}}><b>नोटिस रजिस्टर्ड ए.डी</b></span><br/><br/>
        <span>बनाम</span><br/>
        {props.title} {props.name} {props.relation} {props.head} {props.father_name}<br/>
        {props.address}<br/>
        <span>महोदय,</span><br/><br/>

        <span>
          मैं, मेरी अभिभाष्या कम्पनी काबिल फाइनेंस प्राइवेट लिमिटेड, रजिस्टर्ड आफिस 328- सी, कुसुम विहार, रामनगरिया रोड़,जगतपुरा, 
          जयपुर एवं कॉर्पोरेट आफिस - 404,408, SB, 157, माईल स्टोन, टोंक रोड जयपुर जरिये प्रबंधक की ओर से एवं उनके निर्देशनुसार एवं
          उपलब्ध करवाई गई जानकारी के आधार पर आपको निम्न आशय का कानूनी सूचना पत्र प्रेषित करता हूं कि,
        </span><br/><br/>
        <span>
        1. यह कि मेरी अभिभाष्या कंपनी काबिल फाइनेंस प्राइवेट लिमिटेड जो की रिजर्व बैंक लिमिटेड से रजिस्टर्ड एवं गैर बैंकिंग संस्था है जो कंपनी एक्ट,
           2013 के तहत कार्य करती है जिसका मुख्य कार्य अपने ग्राहकों को वित्तीय सुविधा प्रदान करना है।
        </span><br/><br/>
        <span>
        2. यह कि बतौर ऋणी आपने मेरी मूवक्किला कंपनी से दिनांक {convert(props.received_date)} को निष्पादित ऋण के लिए अनुबंध संख्या/खाता संख्या
           {props.acc_no} में अंकित शर्तों एवं निबंधनों के अधीन {props.loan_amt} /रूपये का {props.loan_type} लोन प्राप्त किया था इस ऋण की राशि की
           अदायगी का नियमित भुगतान बार-बार तकाजा करने के बावजूद आपके द्वारा मेरी अभिभाष्या कंपनी को नहीं किया ।
        </span><br/><br/>
        <span>
        3. यह कि आप द्वारा बतौर ऋणी की हैसियत से विधिक रूप से प्रवर्तनीय बकाया राशि की अदायगी पर मेरी अभिभाष्या कंपनी के पक्ष में निम्न
           वर्णित चेक जारी किया-
        </span><br/><br/> 
        <table style={{width: "80%", border: "1px solid black", margin: "auto"}}>
            <thead>
                <tr>
                    <th style={{border: "1px solid black"}}>चैक नंबर</th>
                    <th style={{border: "1px solid black"}}>चैक राशि</th>
                    <th style={{border: "1px solid black"}}>चैक दिनांक</th>
                    <th style={{border: "1px solid black"}}>बैंक का नाम</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={{border: "1px solid black"}}>{props.cheque_no}</td>
                    <td style={{border: "1px solid black"}}>{props.cheque_amt}</td>
                    <td style={{border: "1px solid black"}}>{convert(props.cheque_date)}</td>
                    <td style={{border: "1px solid black"}}>{props.cheque_bank_name} {props.cheque_branch_name}</td>
                </tr>
            </tbody>
        </table><br/>

        <span>
        4. यह कि मेरी मुवक्किल कंपनी ने उक्त चेक को समाशोधन हेतु अपनी बैंक {props.bank_name} शाखा- {props.branch_name}
           में प्रस्तुत किया तो उक्त चैक आपकी बैंक द्वारा दिनांक {convert(props.response_date)} को {props.reason} के रिमार्क के
           साथ अनादरित कर दिया गया आपका उक्त कृत्य अंतर्गत धारा 138 एन आई एक्ट, 1881 के तहत दंडनीय अपराध की श्रेणी में आता है ।
        </span><br/><br/>

        <span>
          अतः आप को नोटिस प्रेषित कर सूचित किया जाता है कि आप नोटिस प्राप्ति के अंदर मियाद 15 दिवस के अंदर अंदर मेरी अभिभाष्या कंपनी
          के चैक की राशि अदा करवा जाब्ता रशीद एवं चैक मय रिटर्न मेमो प्राप्त कर लेवे अन्यथा बाद गुजरने मियाद मेरी अभिभाष्या कंपनी को आपके
          विरुद्ध सक्षम न्यायालय में अंतर्गत धारा 138 एन आई एक्ट, 1881 के तहत कानूनी कार्रवाई करने को विवश होगा जिसमें होने वाले समस्त हजे खर्चे व
          परिणामों के लिए आप स्वयं जिम्मेदार होंगे । सूचित रहे।
        </span><br/><br/>
          <span>जयपुर</span><br/>                                                                      
          <span style={{float:"right"}}>भवदीय</span><br/><br/><br/>
        <span>‌दिनांक</span><br/> 

         <span style={{float:"right"}}>(विनोद कुमार)</span><br/>
         <span style={{float:"right"}}>एडवोकेट</span><br/><br/><br/>
         <span style={{marginLeft:"8%"}}>नोट– इस नोटिस की एक प्रति मेरे कार्यालय में सुरक्षित रख ली है, जो की वर वक्त समय काम आवे ।</span>                                                                                        

     </div></b>

     </div>
    </>
  )
}

export default LegalNoticeLetter