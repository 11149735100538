import React from "react";

import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Collapse
} from "@material-ui/core";
import { ExpandMore, ExpandLess, AccountBalanceWallet, MonetizationOn, Payment } from "@material-ui/icons";
import roleChecker from "../../../_helpers/rolechecker";
export default function DisbursementMenuList(props) {
    const [open, setOpen] = React.useState(false);
    const { path } = props.match;
    const user = props.user;

    const handleClick = () => {
        setOpen((prev) => !prev);
    };
    return (
        <React.Fragment>
            <ListItem button onClick={handleClick}>
                <ListItemIcon>
                    <Payment/>
                </ListItemIcon>
                <ListItemText primary="Disbursement" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" style={{ paddingLeft: "50px" }}>
                    <ListItem
                        hidden={
                            !(roleChecker.isHOLevelAccess(user.role))
                        }
                        button
                        key="disbursalpending"
                        component="a"
                        href={`${path}/sblloans/disbursallist`}
                    >
                        <ListItemText primary="Disbursal Pending" />
                    </ListItem>
                    <ListItem
                        button
                        key="vap"
                        component="a"
                        href={`${path}/letterlist/disbursementlist`}
                    >
                        <ListItemText primary="Pending Memo" />
                    </ListItem>
                    <ListItem
                        button
                        key="vap"
                        component="a"
                        href={`${path}/sblloans/postdisbursallist`}
                    >
                        <ListItemText primary="Post Disbursed Loans" />
                    </ListItem>

                </List>
            </Collapse>
        </React.Fragment>
    )
}
