import React from "react";
import { employeeService } from "../../../_services";
import CollateralData from "./ApplicationData/CollateralData";

export default function CollateralDetails({ cust }) {
  const user = employeeService.userValue;
  console.log(cust);
  return (
    <div>
      <br></br>
      {cust && (
      <CollateralData customer={cust}></CollateralData>
      )}

    </div>
  );
}
