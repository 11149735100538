import { Box, Button, Checkbox, Modal, Table } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Tbody, Td, Th, Tr } from 'react-super-responsive-table';
import { employeeService } from '../../../_services';
import { OtpVerificationModal } from './OtpVerificationModal';

export const CheckInAcceptModal = (props) => {
    const style = {
        position: "absolute",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 700,
        height: 500,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
        margin: "30px",
        overflow: 'scroll',
    };
    const data = props.packet
    const [open, setOpen] = useState(false)
    const [selectPacket, setSelectPacket] = useState([])
    const [employeeList, setEmployeeList] = useState({})
    const [acceptPacket,setAcceptPacket]=useState([])
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    async function getAllEmployees() {
        await employeeService.getAll()
            .then((res) => {
                let list = {};
                res.map(e => {
                    list[e.emp_id] = e.first_name + ' ' + e.last_name
                })
                setEmployeeList(list)
            })
    }
    useEffect(() => {
        getAllEmployees();
    }, []);

    const handleCheckboxChange = (event) => {
        const selectedId = event.target.value;
        let packet=data.find(accept=>accept._id===selectedId)
        if (event.target.checked) {
            setSelectPacket([...selectPacket, selectedId]);
            setAcceptPacket([...acceptPacket,packet])
        } else {
            setSelectPacket(selectPacket.filter(id => id !== selectedId));
            setAcceptPacket(acceptPacket.filter(acceptId=> acceptId !== packet))
        }
    }
    console.log("selected packet", selectPacket)
    return (
        <div>
            <Button onClick={handleOpen} style={{ background: "green", color: 'white', height: '33px', width: "180px" }}>
                Approve Check In
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h5 style={{ display: "flex", justifyContent: "center", marginBottom: "40px" }}>
                        Accept Check In Requests
                    </h5>
                    <Table>
                        <thead>
                            <Tr>
                                <Th>S.N</Th>
                                <Th>Name</Th>
                                <Th>Loan No</Th>
                                <Th>Packet No</Th>
                                <Th>Check In By</Th>
                                <Th>Accept</Th>
                            </Tr>
                        </thead>
                        <Tbody>
                            { 
                                data.length > 0 ?
                                    data.filter(checkIn => checkIn.packet_state === "In" && checkIn.checkIn_details && checkIn.checkIn_details.checkin_status !== "Accepted").map((x, i) => <Tr key={i}>
                                        <Td>{i + 1}</Td>
                                        <Td>{x.name}</Td>
                                        <Td>{x.loan_no}</Td>
                                        <Td>{x.packet_no}</Td>
                                        <Td>{employeeList[x.checkIn_details.checkin_by_emp_id]}</Td>
                                        <Td>
                                            <label>
                                                <input style={{ width: "20px", height: "25px" }} type="checkbox" value={x._id} onChange={handleCheckboxChange} />
                                            </label>
                                        </Td>
                                    </Tr>) : null
                            }
                        </Tbody>
                    </Table>
                    <br />
                    <br />
                    {selectPacket.length > 0 ? <OtpVerificationModal selectPacket={selectPacket} acceptPackets={acceptPacket}/> : null}
                </Box>
            </Modal>
        </div>
    )
}



