import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import { Button, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { dashboardService } from '../../_services/dashboard.service';
import TableData from './TableData';


const TableModal = (props) => {
  const [tableData,setTableData]=useState();

  console.log("props------------------>>", props)
  console.log("data keys ------------>",props.chartType)
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false); 
    props.setIsModalOpen(false);
  };
  

  const [open, setOpen] = useState(false);

   let tableHeaders = [];

   if(tableData && tableData.length>0){
   tableHeaders = ['Branch', ...Array.from(
    new Set(tableData.flatMap(item => Object.keys(item)))
  ).filter(key => key !== 'Branch')];
   }



  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
    paddingBottom: '30px',
  };

  useEffect(() => {
    if (props.isModalOpen) {
      setOpen(true);
    }
  }, [props.isModalOpen]);


  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
  }));

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            style={{ position: 'absolute', top: '5px', right: '5px' }}
          >
            <CloseIcon />
          </IconButton>

          <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
           <Tab label="Region" {...a11yProps(0)} />
            <Tab label="Area" {...a11yProps(1)} />
            <Tab label="Cluster" {...a11yProps(2)} />
            <Tab label="Branch" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
      <h3>Region-Wise Summary {props.chartType === "disburse_month_amount" ? "( Amount )": "( Count )" }</h3> 
        <Typography>
        <TableData chartType={props.chartType} field={'region'} />
          </Typography>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <h3>Area-Wise Summary {props.chartType === "disburse_month_amount" ? "( Amount )": "( Count )" }</h3> 
       <Typography>
        {console.log("tableData areawise --------------->>>", tableData)}
        <TableData chartType={props.chartType} field={'area'} />
          </Typography>
      </TabPanel>
      <TabPanel value={value} index={2}>
          <h3> Cluster-Wise Summary {props.chartType === "disburse_month_amount" ? "( Amount )": "( Count )" }</h3> 
        <Typography>
        <TableData chartType={props.chartType} field={'cluster'} />
          </Typography>
          
      </TabPanel>
      <TabPanel value={value} index={3}>
      <h3>Branch-Wise Summary {props.chartType === "disburse_month_amount" ? "( Amount )": "( Count )" } </h3>
        <Typography>
          <TableData chartType={props.chartType} field={'branch'} />
          {console.log("tableData here", tableData)}
          </Typography>
      </TabPanel>
    </div>
        </Box>
      </Modal>
    </div>
  );
};

export default TableModal;
