// Filename: src/BankStatementData.jsx
import React from 'react';
import datehelper from '../../../../_helpers/datehelper'; 
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Paper,Button } from "@material-ui/core";
import { useReactToPrint } from "react-to-print";

const BankStatementData = ({ data ,value}) => {
    // Helper function to remove unwanted fields
console.log('value is',value)
    const bankStatements = React.useRef();
    const handlePrintStatement = useReactToPrint({
        content: () => bankStatements.current,
    });
    const style = {
        textAlign:'center',
        width: "10%"
    }

     
    const renderTransactions = (transactions) => {
        const sortedTransactions = transactions.sort((a, b) => new Date(b.valueDate) - new Date(a.valueDate));
        return (
            <div className="transactions">
                <div  >
                    <Table   style={{ fontSize: 'medium', width: '100%', margin: 'auto', textAlign: 'center' }} >
        <Thead>
          <Tr>
            <Th style={style}>Date</Th>
             <Th style={style}>Narration</Th>
            <Th style={style}>Credit</Th>
            <Th style={style}>Debit</Th>
            <Th style={{width:"15%",textAlign:'center'}}>Current Balance</Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedTransactions &&
            sortedTransactions.map((transaction) => (
              <Tr key={transaction._id}>
                {/* {dateCompare(payment.date)} */}
                <Td>{transaction.valueDate}</Td>
                <Td>{transaction.narration}</Td>
                <Td>{transaction.type=="CREDIT" ? transaction.amount:null}</Td>
                <Td>{transaction.type=="DEBIT" ? transaction.amount:null}</Td>               
                 <Td>{transaction.currentBalance}</Td>
              </Tr>
            ))}
           
        </Tbody>
      </Table>
                </div>
              
            </div>
        );
    };

    // Helper function to render bank statements
    const renderBankStatements = (statements) => (
        <div className="bank-statements">
            {statements && statements.map((statement, index) => (
                <div key={index} className="bank-statement">
                {/* <Paper style={{width:'100%', borderRadius: '10px', margin: '10px', padding: '10px' }}> */}
                <h4 style={{textAlign:'center',marginTop:'10px'}}><b>Bank Name:</b> {statement.bank_name}</h4>
                    {statement && statement.bank_transactions && statement.bank_transactions.map((transactionData, tIndex) => (
                        <Paper style={{width:'100%', borderRadius: '10px', margin: '10px', padding: '10px' }}>
                        <div key={tIndex} className="bank-transaction">
                         <div>

                            <h6>Current Balance In this Account : <b>{transactionData.
current_balance
}</b></h6>
                            <h6 style={{textAlign:'center',marginTop:'10px'}}>Transactions from {transactionData?.trasactions?.startDate} to {transactionData?.trasactions?.endDate}</h6>
                         </div>
                            <div style={{margin:'10px'}}>

                            {transactionData?.trasactions?.Transaction && renderTransactions(transactionData.trasactions.Transaction)}
                            </div>
                            
                        </div>
                        </Paper>
                    ))}
                    {/* </Paper> */}
                </div>
            ))}
        </div>
    );
    return (
        <>
{data.bank_statements && <Button style={{    float: 'inline-end' }} variant='outlined' type='button' onClick={()=>handlePrintStatement()}>Print Statement</Button>}
        {data.bank_statements && <div ref={bankStatements} className="nested-data-display">
        <h3 style={{textAlign:"center",marginTop:'10px'}}>Customer Name: {value.general_details.customer_title + " " + value.general_details.customer_firstname + " " + (value.general_details.customer_middlename ? value.general_details.customer_middlename + " " : "") + value.general_details.customer_lastname}</h3>

            {renderBankStatements(data.bank_statements)}
        </div>}
        </>
    );
};

export default BankStatementData;
