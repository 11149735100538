import React, { useRef, useState, useEffect } from 'react'
import { Table } from '@material-ui/core'
import { Tbody, Td, Th, Tr } from 'react-super-responsive-table'
import { useReactToPrint } from 'react-to-print'
import logo from "../../../assets/kaabil.png";
import datehelper from '../../../_helpers/datehelper';
import { employeeService } from '../../../_services';

export const CheckInAcceptPrint = (props) => {
    console.log("Tewst props", props)
    const user = employeeService.userValue.emp_id
    const [employeeList, setEmployeeList] = useState({})
    let data = props.history.location.state
    console.log("Test Data", data)
    const checkInRef = useRef()
    const handleCheckInPrint = useReactToPrint({
        content: () => checkInRef.current
    })
    async function getAllEmployees() {
        await employeeService.getAll()   
            .then((res) => {
                let list = {};
                res.map(e => {
                    list[e.emp_id] = e.first_name + ' ' + e.last_name
                })
                setEmployeeList(list)
            })
    }
    useEffect(() => {
        getAllEmployees();
    }, []);
    console.log("Test all packet", data)
    return (
        <div>
            <div style={{ textAlign: "center" }}>
                <button onClick={() => { handleCheckInPrint() }}>
                    Print Check In Accepted Packet
                </button>
            </div>
            <div
                ref={checkInRef}
                style={{
                    width: "278mm",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: '50px',
                    bgcolor: "background.paper",
                    padding: 45,
                    background: "white"
                }}
            >
                <div style={{ textAlign: "center" }}>
                    <img style={{ width: 350, height: 100, marginTop: "30px" }} src={logo}></img>
                </div>
                <br />
                <h3 style={{ textAlign: "center", marginTop: "40px" }}>Check In Accepted Packet</h3>
                <br />
                <hr />
                <span style={{ float: "left" }}>
                    <h6>Check In Accepted By:{employeeList[user]}</h6>
                    <h6>Mobile No:{data.emp_contact}</h6>
                    <h6> Accepted Otp:{data.otpResponse}</h6>
                </span>
                <h6 style={{ float: "right" }}>Date:{datehelper.displayDate(new Date(Date.now()))}</h6>
                <br />
                <br />
                <br />
                <br />
                <hr />
                <br />
                <Table>
                    <thead>
                        <Tr>
                            <Th style={{ width: "5%" }}>S.N</Th>
                            <Th style={{ width: "15%" }}>Name</Th>
                            <Th style={{ width: "10%" }}>Loan No</Th>
                            <Th style={{ width: "10%" }}>Packet No</Th>
                            <Th style={{ width: "15%" }}>Check In By</Th>
                            <Th style={{ width: "15%" }}>Check In Date</Th>
                        </Tr>
                    </thead>
                    <Tbody>
                        {
                            data.acceptedPacket.map((x, i) => <Tr key={i}>
                                <Td>{i + 1}</Td>
                                <Td>{x.name}</Td>
                                <Td>{x.loan_no}</Td>
                                <Td>{x.packet_no}</Td>
                                <Td>{x.checkIn_details && employeeList[x.checkIn_details.checkin_by_emp_id]}</Td>
                                <Td>{datehelper.displayDate(new Date(x.createdAt))}</Td>
                            </Tr>)}
                    </Tbody>
                </Table>
                <br />
                <br />
                <h5 style={{ float: "right", marginRight: "70px" }}>Signature</h5>
                <br />
                <br />
            </div>
        </div>
    )
}
