import React from "react"
import { useEffect, useState } from "react"
import { ledgerService } from "../../../_services/ledger.service"
import { Table, Tbody, Thead, Tr, Td, Th } from "react-super-responsive-table"
import datehelper from '../../../_helpers/datehelper'
import { Paper } from "@material-ui/core"
import { CircularProgress } from "@material-ui/core"
import { employeeService,loanService } from "../../../_services"
import roleChecker from "../../../_helpers/rolechecker"
import { ApprovalRequestFilter } from '../../MakerCheckerFramework/ApprovalRequestFilter'
import { history } from "../../../_helpers"
import { Modal, Box, Typography, Button } from "@material-ui/core";
import { AddOutlined } from "@material-ui/icons";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { branchService } from "../../../_services/branch.service";
import * as Yup from "yup";
import { RemarkModal } from "./RemarkModal"
import workflowHelper from "../../../_helpers/workflowhelper";
export const LedgerTransactionHistory = (props) => {
    console.log('props is', props)
    const user = employeeService.userValue
    console.log('user', user)
    const ledgerDetails = props.location.state.ledgerDetails
    console.log('ledger details is', ledgerDetails)
    const ledger_id = ledgerDetails._id
    const [ledgerTransaction, setLedgerTransaction] = useState({})
    const [ledgers, setLedgers] = useState({})
    const [show, setShow] = useState(false)
    const [open, setOpen] = useState(false)
    const [branches, setBranches] = useState([])
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const [showMoreCount, setShowMoreCount] = useState(100);

    const handleShowMore = () => {
        setShowMoreCount((prevCount) => prevCount + 100);
    };

    const fetchTransactions = (id) => {
        setShow(true)
        ledgerService.getLedgerTransactions(id)
            .then(res => {
                console.log('res is ', res)
                setLedgerTransaction(res)
                setShow(false)
            })
            .catch(err => {
                console.log(err)
                setShow(false)
            })
    }

    const filterTransactions = () => {
        if (startDate && endDate) {
            const rangeLimit = new Date(startDate);
            rangeLimit.setDate(rangeLimit.getDate() + 31); // Add 31 days to the start date

            if (endDate <= rangeLimit) {
                const filtered = ledgerTransaction.transactions.filter((transaction) => {
                    const transactionDate = new Date(transaction.createdAt);
                    return (
                        transactionDate >= startDate &&
                        transactionDate <= endDate
                    );
                });
                setFilteredTransactions(filtered);
            } else {
                // Reset the end date to the maximum 31-day range
                setEndDate(rangeLimit);
                setFilteredTransactions([]);
            }
        } else {
            setFilteredTransactions(ledgerTransaction.transactions);
        }
    };

    useEffect(() => {
        filterTransactions();
    }, [startDate, endDate, ledgerTransaction]);

    // const handleClose = () => setOpen(false)
    // const handleOpen=(props)=>{
    //     setOpen(true);
    // }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 640,
        height: 370,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'scroll',
        //paddingBottom: '30px',
    };

    // const initialValues = {
    //    remarks:''
    // }




    // const validationSchema = Yup.object().shape({
    //     remarks: Yup.string().required("This field is required"),

    // })

    const getLedgers = () => {
        ledgerService.getLedgerList()
            .then(ledgers => {
                let list = {}
                if (ledgers.length > 0) {
                    ledgers.map(ledger => {
                        list[ledger._id] = ledger.ledger_name + ' - ' + ledger.branch_id
                    })
                    setLedgers(list)
                }
            })
    }

    async function revert(id, params, values) {
        console.log('params is  valuss', values.remarks)
        console.log('params is thihhiih', params)
        await ApprovalRequestFilter.RequestFilter({
            service: 'ledgerService',
            api: 'revertLedger',
            id: id,
            task: 'revert  Amount',
            updated_fields: ["transactionType", "total_amount", "user", "ledger", "remark"],
            sensitive_fields: [],
            // new_values: [{debitLedgerId:debitLedgerId,creditLedgerId:creditLedgerId,transactionType:transactionType,totalAmount:totalAmount,user:user}],
            new_values: { transactionType: params.transactionType, total_amount: params.cash_summary.total_amount, user: user.emp_id, ledger: ledgers[params.credit_ledger_id], remark: values.remarks },
            old_values: {},
            identification_key: 'Total Amount',
            identification_value: params.cash_summary.total_amount,
            emp_id: user.emp_id,
            approval_needed: true,
            approved: false,
            approval_access: 'isAccountChecker',
            product: 'goldloans',
            remarks: values.remarks
        }).then(async(x) => {
            console.log('resp is',x)
            const acUserData = await loanService.getEmpIdRoleWise({ role: 'AC' }); 
            console.log("AC User Data:", acUserData);
            if (Array.isArray(acUserData) && acUserData.length > 0) { // Ensure acUserData is an array and not empty
              console.log("Calling Redis Helper for sending Notifications");
              await Promise.all(
                  acUserData.map((employee) => {
                      const emp_id = employee.emp_id; // Extract emp_id for each user
          
                      return workflowHelper.isRedisNotifications({
                        emp_id: user.emp_id,
                        message: `You have an approval request from ${user.emp_id} for a ledger transaction. Please review and take the necessary action`,
                        action_link: `https://samruddhi.kaabilfinance.com/home/cashmanagement`,
                        action_taken_by: emp_id
                    });
                  })
                );
              }
                console.log(x)
                
                alert("Request Sent to concerned Authority")
                window.location.reload()
            })
            .catch((err) => {
                window.alert(err)
                console.log(err)
            })
    }
function isTodayOrYesterday(targetDate) {
    if (!targetDate) {
      return false; 
    }
  const targetDateTime = new Date(targetDate);
  
    if (isNaN(targetDateTime)) {
      return false;
    }
  
    const currentDate = new Date();
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);
  
    const currentDateStr = currentDate.toISOString().split('T')[0];
    const yesterdayStr = yesterday.toISOString().split('T')[0];
    const targetDateStr = targetDateTime.toISOString().split('T')[0];
  
    return targetDateStr === currentDateStr || targetDateStr === yesterdayStr;
  }
  
   
  
    useEffect(() => {
        fetchTransactions(ledger_id)
        getLedgers()
        filterTransactions();
    }, [])
    console.log('pending transaction are ...', ledgerTransaction)
    return (
        <div style={{ margin: '40px' }}>
            <Table style={{ width: '400px', margin: 'auto', marginTop: '20px', marginBottom: '40px' }}>
                <Thead>
                    <Tr>
                        <Th style={{ border: '1px solid black' }}>Ledger Name</Th>
                        <Th style={{ border: '1px solid black' }}>Branch Id</Th>
                        <Th style={{ border: '1px solid black' }}>Current ledeger Balance</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr>
                        <Td style={{ border: '1px solid black' }}>{ledgerDetails.ledger_name}</Td>
                        <Td style={{ border: '1px solid black' }}>{ledgerDetails.branch_id}</Td>
                        <Td style={{ border: '1px solid black' }}>{ledgerDetails.current_balance.toLocaleString("en-IN", {
                            // maximumFractionDigits: 2,
                            style: "currency",
                            currency: "INR",
                        })}</Td>
                    </Tr>
                </Tbody>
            </Table>
            <hr style={{ width: '100%' }} />
            {show &&
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </div>}
            {ledgerTransaction && ledgerTransaction.transactions && (ledgerTransaction.transactions.length > 0) &&
                <div style={{ marginBottom: '20px' }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>


                        <h5>Transactions</h5>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center', gap: '2rem'
                        }} >
                            <div>

                                <label htmlFor="startDate">Start Date: &nbsp;</label>
                                <input
                                    type="date"
                                    id="startDate"
                                    value={startDate ? startDate.toISOString().substr(0, 10) : ''}
                                    onChange={(e) => setStartDate(new Date(e.target.value))}
                                    style={{
                                        border: '2px solid navy',
                                        fontFamily: 'inherit',
                                        fontVariant: 'all-petite-caps',
                                    }}
                                />
                            </div>
                            <div>

                                <label htmlFor="endDate">End Date: &nbsp;</label>
                                <input
                                    type="date"
                                    id="endDate"
                                    value={endDate ? endDate.toISOString().substr(0, 10) : ''}
                                    onChange={(e) => setEndDate(new Date(e.target.value))}
                                    min={startDate ? startDate.toISOString().substr(0, 10) : ''}
                                    max={
                                        startDate
                                            ? new Date(
                                                startDate.getFullYear(),
                                                startDate.getMonth(),
                                                startDate.getDate() + 31
                                            ).toISOString().substr(0, 10)
                                            : ''
                                    }
                                    disabled={!startDate} // Disable the end date input until a start date is selected
                                    style={{
                                        border: '2px solid navy',
                                        fontFamily: 'inherit',
                                        fontVariant: 'all-petite-caps',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <hr style={{ width: '100%' }} />
                    <Table style={{ textAlign: 'center' }}>
                        <Thead>
                            <Tr>
                                <Th style={{ textAlign: 'center' }}>Sr. No.</Th>
                                <Th style={{ textAlign: 'center' }}>Date</Th>
                                <Th style={{ textAlign: 'center' }}>Debited From/ Credited To</Th>
                                <Th style={{ textAlign: 'center' }}>Transaction Type</Th>
                                <Th style={{ textAlign: 'center' }}>Status</Th>
                                <Th style={{ textAlign: 'center' }}>Amount</Th>

                            </Tr>
                        </Thead>
                        <Tbody>
                            {filteredTransactions && filteredTransactions.length > 0 ?
                            <>
                               { ( startDate &&  endDate) ? 
                               <>

                               {
                                        filteredTransactions.map((trxn, idx) => {
                                            console.log('trxn is', trxn)
                                            return <Tr key={trxn._id}>
                                                <Td>{idx + 1}</Td>
                                                <Td>{datehelper.displayDateTime(new Date(trxn.createdAt))}</Td>
                                                {trxn.transactionType === 'Debit' ?
                                                    <React.Fragment>
                                                        {/* <Td>{ledgers[trxn.debit_ledger_id]} </Td> */}
                                                        <Td> <p style={{ display: 'inline', color: "red" }}>{ledgers[trxn.debit_ledger_id]}</p> / <p style={{ display: 'inline', color: "green" }}>{ledgers[trxn.credit_ledger_id]}</p> </Td>
                                                        <Td style={{ color: 'red' }}>{trxn.transactionType}</Td>
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        {/* <Td>{ledgers[trxn.credit_ledger_id]} </Td> */}
                                                        {/* <Td style={{color:'green'}}><p style={{display: 'inline',color:"green"}}>{ledgers[trxn.credit_ledger_id]}</p> / <p style={{display: 'inline',color:"red"}}>{ledgers[trxn.debit_ledger_id]}</p></Td> */}
                                                        <Td style={{ color: 'green' }}><p style={{ display: 'inline', color: "green" }}>{ledgers[trxn.debit_ledger_id]}</p> / <p style={{ display: 'inline', color: "red" }}>{ledgers[trxn.credit_ledger_id]}</p></Td>
                                                        <Td style={{ color: 'green' }}>{trxn.transactionType}</Td>
                                                    </React.Fragment>
                                                }
                                                <Td>{trxn.verification_details && trxn.verification_details.requester_emp_id && !trxn.verification_details.approver_emp_id ? "Pending" : null}</Td>
                                                <Td>{trxn.cash_summary && trxn.cash_summary.total_amount && trxn.cash_summary.total_amount.toLocaleString("en-IN", {
                                                    // maximumFractionDigits: 2,
                                                    style: "currency",
                                                    currency: "INR",
                                                })}</Td>
                                                {
                                                   ( user.role==="Admin" || roleChecker.isCFOAccess(user.role) ||  roleChecker.hasAccountsAccess(user.role) && (trxn &&  trxn.updatedAt && isTodayOrYesterday(trxn.updatedAt)))
                                                        && trxn.transactionType == 'Credit' && (!trxn.verification_details || (trxn.verification_details && trxn.verification_details.approver_emp_id)) ? <Td> {(!trxn.reverted_details || (trxn.reverted_details && !(trxn.reverted_details.is_revert_request))) ? <RemarkModal revert={revert} id={trxn._id} trxn={trxn} /> : <Button disabled>Reverted</Button>}</Td> : null
                                                }


                                            </Tr>
                                        })
                                    }
                                    </>
                               : <>

                                    {
                                        filteredTransactions.slice(0, showMoreCount).map((trxn, idx) => {
                                            console.log('trxn is', trxn)
                                            return <Tr key={trxn._id}>
                                                <Td>{idx + 1}</Td>
                                                <Td>{datehelper.displayDateTime(new Date(trxn.createdAt))}</Td>
                                                {trxn.transactionType === 'Debit' ?
                                                    <React.Fragment>
                                                        {/* <Td>{ledgers[trxn.debit_ledger_id]} </Td> */}
                                                        <Td> <p style={{ display: 'inline', color: "red" }}>{ledgers[trxn.debit_ledger_id]}</p> / <p style={{ display: 'inline', color: "green" }}>{ledgers[trxn.credit_ledger_id]}</p> </Td>
                                                        <Td style={{ color: 'red' }}>{trxn.transactionType}</Td>
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        {/* <Td>{ledgers[trxn.credit_ledger_id]} </Td> */}
                                                        {/* <Td style={{color:'green'}}><p style={{display: 'inline',color:"green"}}>{ledgers[trxn.credit_ledger_id]}</p> / <p style={{display: 'inline',color:"red"}}>{ledgers[trxn.debit_ledger_id]}</p></Td> */}
                                                        <Td style={{ color: 'green' }}><p style={{ display: 'inline', color: "green" }}>{ledgers[trxn.debit_ledger_id]}</p> / <p style={{ display: 'inline', color: "red" }}>{ledgers[trxn.credit_ledger_id]}</p></Td>
                                                        <Td style={{ color: 'green' }}>{trxn.transactionType}</Td>
                                                    </React.Fragment>
                                                }
                                                <Td>{trxn.verification_details && trxn.verification_details.requester_emp_id && !trxn.verification_details.approver_emp_id ? "Pending" : null}</Td>
                                                <Td>{trxn.cash_summary && trxn.cash_summary.total_amount && trxn.cash_summary.total_amount.toLocaleString("en-IN", {
                                                    // maximumFractionDigits: 2,
                                                    style: "currency",
                                                    currency: "INR",
                                                })}</Td> 
                                                {
                                                    ( user.role==="Admin" || roleChecker.isCFOAccess(user.role) || roleChecker.hasAccountsAccess(user.role) && (trxn &&  trxn.updatedAt && isTodayOrYesterday(trxn.updatedAt)))
                                                        && trxn.transactionType == 'Credit' && (!trxn.verification_details || (trxn.verification_details && trxn.verification_details.approver_emp_id)) ? <Td> {(!trxn.reverted_details || (trxn.reverted_details && !(trxn.reverted_details.is_revert_request))) ? <RemarkModal revert={revert} id={trxn._id} trxn={trxn} /> : <Button disabled>Reverted</Button>}</Td> : null
                                                }


                                            </Tr>
                                        })
                                    }
                                    {filteredTransactions.length > showMoreCount && (
                                        <Tr>
                                            <Td colSpan={6}>
                                                <Button onClick={handleShowMore}>Show More...</Button>
                                            </Td>
                                        </Tr>
                                    )}
                                </>
                               }
                               </>
                                : <Tr>
                                    <Td colSpan={6}>No transactions found.</Td>
                                </Tr>
                            }
                            
                        </Tbody>
                    </Table>

                </div>

            }
            {
                console.log(ledgerTransaction)
            }
        </div>
    )
}