import { Box, Button, Checkbox, Modal, Table } from '@material-ui/core'
import { Field, Form, Formik } from 'formik';
import React, { useState, useEffect } from 'react'
import { Tbody, Td, Th, Tr } from 'react-super-responsive-table';
import { employeeService } from '../../../_services';
import { goldPacketServices } from '../../../_services/gold_packet.service';
export const CheckOutRequestModal = (props) => {
    const style = {
        position: "absolute",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 730,
        height: 500,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
        margin: "30px",
        overflow: 'scroll',
    };
    console.log("Test props", props)
    const data = props.packet
    const user = employeeService.userValue.emp_id
    const [open, setOpen] = useState(false)
    const [selectPacket, setSelectPacket] = useState([])
    const [employeeList, setEmployeeList] = useState({})
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    console.log("selected packet", selectPacket)
    async function getAllEmployees() {
        await employeeService.getAll()
            .then((res) => {
                let list = {};
                res.map(e => {
                    list[e.emp_id] = e.first_name + ' ' + e.last_name
                })
                setEmployeeList(list)
            })
    }
    useEffect(() => {
        getAllEmployees();
    }, []);
    const handleCheckboxChange = (event) => {
        const selectedId = event.target.value
        if (event.target.checked) {
            setSelectPacket([...selectPacket, selectedId])
        } else {
            setSelectPacket(selectPacket.filter(id => id !== selectedId))
        }
    }
    let initialValues={remark:'',otherRemark:''}
    const [isOtherSelected, setIsOtherSelected] = useState(false);

    const handleSelectChange = (event) => {
      if (event.target.value === 'other') {
        setIsOtherSelected(true);
      } else {
        setIsOtherSelected(false);
      }
    };
    const checkOut = async (values) => {
        console.log('Test values',values)
        await goldPacketServices.checkOutPacket({ checkOut: { ids: selectPacket, packet_state: 'Out', checkout_requested_by: user ,remark:values.remark==="other"?values.otherRemark:values.remark} })
            .then((res) => {
                alert("Check Out Request Sent")
                window.location.reload()
            }).catch((err) => console.log(err))
    }
    return (
        <div>
            <Button onClick={handleOpen} style={{ background: "red", color: 'white', height: '33px' }}>
                Check Out
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                 <Formik
                  initialValues={initialValues}
                  onSubmit={checkOut}
                  >  
                 {({setFieldValue }) => (<Form>
                    <h5 style={{ display: "flex", justifyContent: "center", marginBottom: "40px" }}>
                        <b>Send Check Out Requests</b>
                    </h5>
                    <Table>
                        <thead>
                            <Tr>
                                <Th>S.N</Th>
                                <Th>Name</Th>
                                <Th>Loan No</Th>
                                <Th>Packet No</Th>
                                <Th>Check In By</Th>
                                <Th>Accept</Th>
                            </Tr>
                        </thead>
                        <Tbody>
                            {
                                data.length > 0 ?
                                    data.filter(checkIn => checkIn.packet_state === "In" && checkIn.checkIn_details && checkIn.checkIn_details.
                                    checkin_status==="Accepted").map((x, i) => <Tr key={i}>
                                        <Td>{i + 1}</Td>
                                        <Td>{x.name}</Td>
                                        <Td>{x.loan_no}</Td>
                                        <Td>{x.packet_no}</Td>
                                        <Td>{employeeList[x.checkIn_details.checkin_by_emp_id]}</Td>
                                        <Td>
                                            <label>
                                                <input style={{ width: "20px", height: "25px" }} type="checkbox" value={x._id} onChange={handleCheckboxChange} />
                                            </label>
                                        </Td>
                                    </Tr>) : (
                                        <Tr>
                                            <Td></Td>
                                            <Td></Td>
                                            <Td></Td>
                                            <Td style={{ textAlign: "center", color: "red" }}>Data Not Found</Td>
                                            <Td></Td>
                                            <Td></Td>
                                        </Tr>)
                            }
                        </Tbody>
                    </Table>
                            <br />
                          {selectPacket.length > 0  && <div style={{ marginTop: "50px", marginLeft: "30px" }}>
                                <Field
                                    style={{ height: '30px', border: '1px solid black', marginRight: '10px' }}
                                    name="remark"
                                    as='select'
                                    onChange={(event) => {
                                        handleSelectChange(event);
                                        const { value } = event.target;
                                        setFieldValue('remark', value);
                                    }}
                                    required
                                >
                                    <option value="">Select Purpose</option>
                                    <option value="For Branch Release">For Branch Release</option>
                                    <option value="To Deposit In Bank">To Deposit In Bank</option>
                                    <option value="other">Other</option>
                                </Field>
                                {isOtherSelected && <span>
                                    <label>Remark:</label>
                                    <Field
                                        type="text"
                                        name="otherRemark"
                                        placeholder="Enter Remark"
                                        required
                                    />
                                </span>}
                                {/* {selectPacket.length > 0 ? <Button style={{ textAlign: "center", color: "white", background: "blue", marginTop: "50px", marginLeft: '250px' }} onClick={checkOut}>Submit</Button> : null} */}
                                 <button type='submit' style={{ textAlign: "center", color: "white", background: "blue", height: '30px', width: "80px", marginLeft: "40px" }}>Submit</button>
                            </div>}
                        </Form>)}
                 </Formik> 
                </Box>
            </Modal>
        </div>
    )
}
