import { fetchWrapper } from "../_helpers";

const apiUrl = process.env.REACT_APP_API_URL;

const baseUrl = `${apiUrl}/ms/payments/emailnach`;

export const emailNachService = {
  getAll,
  getPaymentCollections,
  uploadEmailnachCollectionCsv,
  addCollectEmailnach,
  getAllActive,
};

function getAll(){
  return fetchWrapper.get(baseUrl);
}

function getPaymentCollections() {
  console.log("getting payment collections");
  return fetchWrapper.post(`${baseUrl}/getpaymentcollections`, {});
}

function uploadEmailnachCollectionCsv(data,emp_id) {
  for (var key of data.entries()) {
    console.log(key[0] + ', ' + key[1]);
  }  
  return fetch(`${baseUrl}/collectemailnachcsv/${emp_id}`,{
    method: "POST",
    body: data,
    headers:fetchWrapper.authHeader(`${baseUrl}/collectemailnachcsv/${emp_id}`),
  })
}



function addCollectEmailnach(params) {
  return fetchWrapper.post(`${baseUrl}/collectemailnachrequest`, params);
}

function getAllActive() {
  return fetchWrapper.post(`${baseUrl}/getemailnachactive`, {});
}


