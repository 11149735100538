import { Avatar, Box, Button, Modal } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import React, { useState } from 'react'
import { loanService } from '../../../_services/loan.service';
import { legalServices } from '../../../_services/legal_cases.services';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { employeeService } from '../../../_services';

const CreateCasesModal = () => {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 800,
        height: 610,
        bgcolor: "background.paper",
        border: "3px solid #3760F1",
        overflow: "scroll",
        boxShadow: 24,
        p: 4,
        borderRadius: '4px'
    };
    const user = employeeService.userValue.emp_id;
    const [open, setOpen] = useState(false)
    const [cases, setCases] = useState()
    const [show, setShow] = useState(false)
    const [value, setValue] = useState({
        loan_no: '',
        // case_type: '',
        // claim_amount: 0
    })
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const handleChange = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value
        })
    }
    const validationSchema = Yup.object().shape({
        case_type: Yup.string().required("Legal Stage is required"),
        claim_amount: Yup.number(),
    });

    const initialValues = {
        case_type: "",
        claim_amount: '',
        loan_no: value.loan_no,
        emp_id:user
    };
    const createForm = () => {
        setShow(true)
    }
    const handleSearchLoan = async (loan_no) => {
        await loanService.getBySearch({ application_id: loan_no })
            .then((res) => {
                if (res.length > 0) {
                    setCases(res)
                }
                else {
                    window.alert("Loan number not exist")
                }
            })
            .catch((err) => console.log(err))
    }
    const createLegalCase = (values) => {
        console.log("Tes form ddat", values)
        legalServices.createLegalCase(values)
            .then((res) => {
                if (res.success === true) {
                    alert("User Created !!")
                    window.location.reload()
                }
                else{
                   alert(res.message)
                }
            }
            )
            .catch((err) => alert(err))
    }
    console.log("Test Cases", cases)
    return (
        <div>
            <Button onClick={handleOpen} style={{ border: '1.5px solid blue', color: 'blue', width: '120px' }}><Add />Create</Button>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <h3 style={{ textAlign: 'center', marginBottom: '30px' }}>Create Legal Case</h3>
                    {show === false && <div style={{ fontSize: '17px', display: 'flex', justifyContent: 'center', justifyItems: 'center' }}>
                        <label style={{ marginRight: '30px' }}><b>Enter Loan Application Number:</b></label>
                        <input
                            type="text"
                            name="loan_no"
                            onChange={(e) => handleChange(e)}
                            value={value.loan_no.toLocaleUpperCase().trim()}
                            placeholder='Search Loan no'
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearchLoan(value.loan_no);
                                }
                            }}
                            style={{ background: '#D9D9D9', border: '1px white', height: '29px', borderRadius: '4px', marginRight: '10px' }} />
                        <Button
                            onClick={() => handleSearchLoan(value.loan_no)}
                            style={{ background: '#3760F1', color: 'white' ,height:"30px"}}>
                            Search
                        </Button>
                    </div>}
                    {cases && cases.length > 0 && <div style={{ border: "3px solid #3760F1", marginTop: '40px', borderRadius: '4px', height: "400px" }}>
                        <div style={{display: 'flex',flexWrap: 'wrap',justifyContent: 'space-between',alignItems: 'center', overflow: 'hidden',
                         border: "3px solid #3760F1", borderRadius: '4px', width: '400px', height: "155px", marginTop: '30px', marginLeft: '150px' }}>
                            <br />
                            {cases[0].applicant.kyc_images && cases[0].applicant.kyc_images.length>0&& cases[0].applicant.kyc_images[0].uri?
                                <img
                                src={cases[0].applicant.kyc_images && cases[0].applicant.kyc_images.length>0&& cases[0].applicant.kyc_images[0].uri}
                                alt="customer image"
                                style={{
                                    width: '100px',
                                    height: '100px',
                                    borderRadius: '50%',
                                    objectFit: 'cover',
                                    border: "3px solid #3760F1",
                                    marginLeft: '30px',
                                    float: 'left'
                                }}
                            />:<Avatar style={{
                                background:"black",
                                width: '100px',
                                height: '100px',
                                borderRadius: '50%',
                                objectFit: 'cover',
                                border: "3px solid #3760F1",
                                marginLeft: '30px',
                                float: 'left'
                            }}/>}
                            <span style={{ display: 'flex', flexDirection: "column", float: 'right', marginRight: "30px",marginTop:"10px" }}>
                                <p><b>Name:</b>{cases[0].applicant.general_information.name}</p>
                                <p><b>LAN:</b>{cases[0].application_id}</p>
                                <p><b>Mobile No:</b>{cases[0].applicant.general_information.contact}</p>
                                <p><b>Gender:</b>{cases[0].applicant.general_information.gender?
                                cases[0].applicant.general_information.gender:"---"}</p>
                            </span>
                        </div>
                        <br />
                        {show == false && <div style={{ display: 'flex', flexDirection: 'row', marginLeft: "210px" }}>
                            <Button onClick={createForm} style={{ color: "white", background: 'green', marginRight: '25px', borderRadius: '4px' }}>Create Legal</Button>
                            <Button onClick={handleClose} style={{ color: "white", background: 'red', borderRadius: '4px' }}>Cancel</Button>
                        </div>}
                        {show && <div>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={createLegalCase}
                            >
                                {({ isSubmitting }) => (
                                    <Form>
                                        <div style={{ display: "flex", margin: "20px" }}>
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <label htmlFor="case_type" style={{ marginLeft: "60px" }}>
                                                    Legal Stage<span style={{ color: "red", marginLeft: "5px" }}>*</span>
                                                </label>
                                                <Field
                                                    name="case_type"
                                                    as="select"
                                                    style={{ width: "250px", height: "30px", borderRadius: "4px", marginRight: "20px", marginLeft: "60px" }}
                                                >
                                                    <option value="">Choose Stage</option>
                                                    <option value="NI138">NI138</option>
                                                    <option value="Arbitration">Arbitration</option>
                                                    <option value="Execution">Execution</option>
                                                </Field>
                                                <ErrorMessage name="case_type" component="div" style={{ color: "red", marginLeft: "60px" }} />
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <label htmlFor="claim_amount">Claim Amount</label>
                                                <Field
                                                    type="number"
                                                    name="claim_amount"
                                                    placeholder="Enter amount"
                                                    style={{ width: "250px", height: "30px", borderRadius: "4px" }}
                                                />
                                                <ErrorMessage name="claim_amount" component="div" style={{ color: "red" }} />
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", marginLeft: "200px", marginTop: "40px" }}>
                                            <button type="submit" disabled={isSubmitting} style={{ color: "white", width: "120px", background: "green", marginRight: "25px", borderRadius: "4px" }}>
                                                Create Legal
                                            </button>
                                            <Button onClick={handleClose} style={{ color: "white", background: "red", borderRadius: "4px", width: "100px" }}>
                                                Cancel
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>}
                    </div>}
                </Box>
            </Modal>
        </div>
    )
}

export default CreateCasesModal
