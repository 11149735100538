import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { Table, Thead, Tbody, Tr, Td, Th } from 'react-super-responsive-table';
import { Add, Search } from "@material-ui/icons";
import CreateCasesModal from './CreateCasesModal';
import { legalServices } from '../../../_services/legal_cases.services';
import datehelper from "../../../_helpers/datehelper"
import { history } from '../../../_helpers';
const LegalCasesList = () => {
  const [cases, setCases] = useState([])
  const [searchValue, setSearchValue] = useState('');
  const handleSearch = (e) => {
    if (e.key === 'Enter' || e.type === 'click') {
      const filteredData = cases.filter((caseObj) =>
        Object.values(caseObj).some((prop) =>
          String(prop).includes(searchValue)
        )
      );
      setCases(filteredData)
    }
  };

  useEffect(() => {
    async function getAllCases() {
      await legalServices.getAllLegalCases().
        then((res) => {
          setCases(res);
        })
        .catch((err) => {
          console.log(err);
        })
    }
    getAllCases();
  }, []);
  console.log("Test cases", cases)
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '20px' }}>
        <span style={{ float: 'left', fontSize: '18px' }}>
           <b>Search a Loan&nbsp;</b>
          <input
            type="text"
            placeholder='Enter Loan No....'
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value.trim().toUpperCase())}
            onKeyPress={handleSearch}
            style={{ borderRadius: '4px' }}
          />
          <Button onClick={handleSearch} style={{width: "44px",background: '#7893F2',
              height: "32px",
              color: 'white',
              marginLeft: "8px"
            }}
          >
            <Search />
          </Button></span>
        <CreateCasesModal />
      </div>
      <Table>
        <Thead>
          <Tr style={{ background: '#7893F2', height: "45px", color: 'white' }}>
            <Th>&nbsp;&nbsp;Customer Name</Th>
            <Th>Loan Number</Th>
            <Th>Loan Amount</Th>
            <Th>Branch</Th>
            <Th>Legal-Stage</Th>
            <Th>Legal-Initiation Date</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {cases.length > 0 ?
            cases.map((x, idx) => {
              return (<>
                <Tr key={idx} style={{ background: "#EFECEC", marginBottom: "80px", height: '50px', fontSize: '15px' }}>
                  <Td>{x.applicant.general_information.name}</Td>
                  <Td>{x.application_id}</Td>
                  <Td>{x.loan.sanction_amount && x.loan.sanction_amount.toLocaleString("en-IN", {
                        maximumFractionDigits: 2,
                        style: "currency",
                        currency: "INR",
                      })}</Td>
                  <Td>{x.branch_id}</Td>
                  <Td>{ x.case_info[0].case_close_status && x.case_info[0].case_close_status ==="Closed"?x.case_info[0].case_close_status:x.case_info[0].case_type}</Td>
                  <Td>{datehelper.displayDate(new Date(x.case_info[0].createdAt))}</Td>
                  <Td><Button
                    onClick={() => history.push("/home/legalcases/personalinfo", x)}
                    style={{ color: '#3760F1' }}>View Details</Button></Td>
                </Tr>
                <br />
              </>)
            }) :
            <Tr>
              <Td colSpan={7} style={{ fontSize: '20px',textAlign:"center" }}>Data Not Found</Td>
            </Tr>
          }
        </Tbody>
      </Table>
    </div>
  )
}
export default LegalCasesList
