import React, { useEffect, useState } from 'react'
import { Button, Modal, Box } from '@material-ui/core'
import datehelper from '../../_helpers/datehelper';
import { Table, Th, Thead, Tr, Tbody, Td } from 'react-super-responsive-table'
import { employeeService } from '../../_services'
import { VerifiedUser } from '@material-ui/icons';
import { pettyCashService } from '../../_services/pettycash.service';
const PettyCashApproveModal = (props) => {
    const style = {
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1100,
        height: 450,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        border: '2px solid blue',
        borderRadius: '5px',
        overflow: 'scroll'
    };
    const pettyCashPendingList = props.pettyCashPendingList
    const user = employeeService.userValue.emp_id
    const [employeeList, setEmployeeList] = useState({})
    const [open, setOpen] = useState(false)
    const [selectedValue, setSelectedValue] = useState();

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    async function getAllEmployees() {
        await employeeService.getAll()
            .then((res) => {
                let list = {};
                res.map(e => {
                    list[e.emp_id] = e.first_name + ' ' + e.last_name
                })
                setEmployeeList(list)
            })
    }

    const handleApprove = async () => {
        await pettyCashService.approvePettyRequest(selectedValue)
            .then((res) => {
                alert("Petty Cash Request Approved !!")
                window.location.reload()
            }).catch((err) => console.log(err))
    }
    console.log("Test console", selectedValue)
    const handleCheckboxChange = (event) => {
        const selectedId = event.target.value;
        const pettyCashRequest = pettyCashPendingList.find((request) => request._id === selectedId);
        setSelectedValue(pettyCashRequest);
    };
    useEffect(() => {
        getAllEmployees()
    }, [])
    return (
        <div>
            <Button onClick={handleOpen}
                style={{ display: 'flex', float: "right", marginBottom: "15px", background: "#079904", color: 'white' }}>
                <VerifiedUser />&nbsp;Approve Request</Button>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <h3 style={{ textAlign: 'center', marginTop: '20px', marginBottom: '40px' }}>Approve Petty Cash Request</h3>
                    <Table>
                        <Thead>
                            <Tr style={{ background: "#3251F5", color: 'white', height: '50px' }}>
                                <Th>&nbsp;S.No</Th>
                                <Th>Requested By</Th>
                                <Th>Paying To</Th>
                                <Th>Amount</Th>
                                <Th>Transaction Date</Th>
                                <Th>Remark</Th>
                                <Th>Status</Th>
                                <Th>Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {pettyCashPendingList && pettyCashPendingList.length > 0 ? pettyCashPendingList.map((pending, idx) => <Tr key={idx} style={{ background: '#EFF2FF', height: '50px', fontSize: '18px' }}>
                                <Td>{idx + 1}</Td>
                                <Td>{employeeList[pending.requested_by]}</Td>
                                <Td>{pending.paying_to}</Td>
                                <Td>{pending.amount.toLocaleString("en-IN", { style: 'currency', currency: 'INR' })}</Td>
                                <Td>{datehelper.displayDateTime(new Date(pending.requested_date))}</Td>
                                <Td>{pending.remarks}</Td>
                                <Td style={{ color: "red" }}>{pending.status}</Td>
                                <Td>
                                    <label>
                                        <input
                                            style={{ width: "20px", height: "25px", marginRight: "30px" }}
                                            type="checkbox"
                                            value={pending._id}
                                            onChange={handleCheckboxChange}
                                            checked={selectedValue?._id === pending._id}
                                        />
                                    </label>
                                </Td>
                            </Tr>) : <Tr><Td align="center" colSpan={8}>Data Not Found</Td></Tr>}
                        </Tbody>
                    </Table>
                    <br />
                    <br />
                    {selectedValue && <Button style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 'auto',
                        background: "#3251F5",
                        color: 'white'
                    }}
                        onClick={() => handleApprove()}
                    >Approve</Button>}
                </Box>
            </Modal>
        </div>

    )
}

export default PettyCashApproveModal
