import React, { useState } from 'react';
import {
  Modal, Button, Typography, Grid, Paper, Divider, Box, makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 600,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
  },
  fieldContainer: {
    marginBottom: theme.spacing(2),
  },
  fieldLabel: {
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  fieldValue: {
    fontWeight: 600,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  button: {
    marginTop: theme.spacing(3),
  },
}));

function OwnerData(params) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
const { data  } =params
console.log('params is',params)
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ownerData = data;

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleOpen}>
        Owner 
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="owner-data-modal-title"
        aria-describedby="owner-data-modal-description"
      >
        <div className={classes.paper}>
          <Typography variant="h5" className={classes.title} id="owner-data-modal-title">
            Branch Information
          </Typography>

          <Grid container spacing={2}>
            {(  params.isSubmittering===false ) ? (
              <>
                <Grid item xs={6} className={classes.fieldContainer}>
                  <Typography variant="body2" className={classes.fieldLabel}>Branch ID:</Typography>
                  <Typography variant="body1" className={classes.fieldValue}>{ownerData.branch_id}</Typography>
                </Grid>
                <Grid item xs={6} className={classes.fieldContainer}>
                  <Typography variant="body2" className={classes.fieldLabel}>K.No.:</Typography>
                  <Typography variant="body1" className={classes.fieldValue}>{ownerData.kNo}</Typography>
                </Grid>
                <Grid item xs={12} className={classes.fieldContainer}>
                  <Typography variant="body2" className={classes.fieldLabel}>Discom:</Typography>
                  <Typography variant="body1" className={classes.fieldValue}>{ownerData.Electricity_Distribution_Company}</Typography>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={6} className={classes.fieldContainer}>
                  <Typography variant="body2" className={classes.fieldLabel}>Branch ID:</Typography>
                  <Typography variant="body1" className={classes.fieldValue}>{ownerData.branch_id}</Typography>
                </Grid>
                <Grid item xs={6} className={classes.fieldContainer}>
                  <Typography variant="body2" className={classes.fieldLabel}>Name:</Typography>
                  <Typography variant="body1" className={classes.fieldValue}>{ownerData.name}</Typography>
                </Grid>
                <Grid item xs={6} className={classes.fieldContainer}>
                  <Typography variant="body2" className={classes.fieldLabel}>Account No.:</Typography>
                  <Typography variant="body1" className={classes.fieldValue}>{ownerData.account_no}</Typography>
                </Grid>
                <Grid item xs={6} className={classes.fieldContainer}>
                  <Typography variant="body2" className={classes.fieldLabel}>IFSC:</Typography>
                  <Typography variant="body1" className={classes.fieldValue}>{ownerData.ifsc}</Typography>
                </Grid>
                <Grid item xs={12} className={classes.fieldContainer}>
                  <Typography variant="body2" className={classes.fieldLabel}>Mobile:</Typography>
                  <Typography variant="body1" className={classes.fieldValue}>{ownerData.mobile}</Typography>
                </Grid>
              </>
            )}
          </Grid>

          <Divider className={classes.divider} />

          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" color="secondary" onClick={handleClose} className={classes.button}>
              Close
            </Button>
          </Box>
        </div>
      </Modal>
    </>
  );
}

export default OwnerData;
