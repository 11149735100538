import React from 'react';
import { useState } from 'react';
import { Modal, Box, Button } from '@material-ui/core';
import { Table, Thead, Tbody, Tr, Td, Th } from 'react-super-responsive-table';
import urlhelper from '../../../_helpers/urlhelper';
import { history } from '../../../_helpers';
import datehelper from '../../../_helpers/datehelper';
const ViewExecutionDocument = (props) => {
    let data = props.documents
    console.log("Test file", props)
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "1133px",
        height: "481px",
        bgcolor: "#F3F3F3",
        border: "2px solid #000",
        overflow: "scroll",
        boxShadow: 24,
        p: 4,
    };
    return (
        <div>
            <Button onClick={handleOpen} style={{ color: '#0C2294', border: "2px solid #0C2294", height: '25px', background: '9990FF' }} >Documents</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h3 style={{ textAlign: 'center', margin: '40px' }}>Documents</h3>
                    <Table style={{ fontSize: 'medium', width: '100%', margin: 'auto', textAlign: 'center' }}>
                        <Thead>
                            <Tr>
                                <Th style={{ width: '10%', textAlign: 'center' }}>Sr.No.</Th>
                                <Th style={{ width: '25%', textAlign: 'center' }}>Document Type</Th>
                                <Th style={{ width: '25%', textAlign: 'center' }}>Date</Th>
                                <Th style={{ width: '40%', textAlign: 'center' }}>View</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {data && data.length>0?
                            data.map((document, idx) =>
                                <Tr>
                                    <Td>
                                        {idx + 1}
                                    </Td>
                                    <Td>{document.document_type}</Td>
                                    <Td>{datehelper.displayDate(new Date(document.date))}</Td>
                                    <Td><Button
                                        color='primary'
                                        variant='outlined'
                                        onClick={() => history.push(`/home/pdfviewer?pdf_url=` + urlhelper.encodeToBase64(document.url) + "&download=1")}
                                    >View</Button>
                                    </Td>
                                </Tr>):
                                 <Tr>
                                 <Td align='center' colSpan='8'>Data not available</Td>
                             </Tr>
                                }
                        </Tbody>
                    </Table>
                </Box>
            </Modal>
        </div>
    )
}
export default ViewExecutionDocument
