import React from "react";

export default function DocumentListInputSheetColumns() {
  return (
    <tr>
      <th style={{ width: "5%" }} className="text-center">
        #
      </th>
      <th style={{ width: "40%" }} className="text-center">
        Description of Document
      </th>
      <th style={{ width: "10%" }} className="text-center">
        Original/Photocopy
      </th>
      <th>Upload Document</th>  
      <th>LOD Letter</th>
      <th style={{ width: "15%" }} />
    </tr>
  );
}
