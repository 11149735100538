import { fetchWrapper } from "../_helpers/fetch-wrapper";
const apiUrl = process.env.REACT_APP_API_URL;
const baseUrl = `${apiUrl}/request_table_data`;
export const dashboardService = {
    requestForTableData
}


async function requestForTableData(params){
    console.log("params data is",params)
    return fetchWrapper.post(`${baseUrl}/dashboard/table_data`, params);
}

