import React, { useState, useEffect } from "react";
import { employeeService } from "../../_services/employee.service";
import { Grid, Card, Button, CardActions } from "@material-ui/core";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { loanService } from "../../_services";
import {LoanStages} from "../../constants/loanstages"
import TablePagination from "@material-ui/core/TablePagination";
import displayhelper from "../../_helpers/displayhelper";

function SanctionList({ match }) {
  const { path } = match;
  const [loans, setLoans] = useState(null);
  const user = employeeService.userValue;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
      loanService.getAllByStage(LoanStages.Sanction).then((x) => {
        let tempLoans=x;
        console.log(tempLoans);
        setLoans(tempLoans);
      });
      }, [user]);

  return (
    <div>
      <h1>
        Sanction Pending Loans &nbsp;
      </h1>
      <Table className="table table-striped">
        <Thead>
          <Tr>
            <Th style={{ width: "10%" }}>Loan Application Number</Th>
            <Th style={{ width: "10%" }}>Applicant Name</Th>
            <Th style={{ width: "10%" }}>Sanction Amount</Th>
            <Th style={{ width: "10%" }}>Branch</Th>
            <Th style={{ width: "10%" }}>Stage Owner </Th>
            <Th style={{ width: "15%" }}>Next Step </Th>
            <Th style={{ width: "10%" }}>Pending Since</Th>
            <Th style={{ width: "10%" }}>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {loans && loans.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((loan) => (
              <Tr key={loan._id}>
                <Td>
                  {loan.application_id}
                </Td>
                <Td>
                  {loan.applicant && loan.applicant.general_information && loan.applicant.general_information.name }
                </Td>
                <Td>
                  Rs. {loan.loan.amount && displayhelper.displayNumberAccounting(loan.loan.sanction_amount)}
                </Td>
                <Td>
                  {loan.employees && loan.employees.length>0 &&  loan.employees[0].branch_id }
                </Td>
                
                <Td>
                  {loan.next_stage_employees && loan.next_stage_employees.length>0 &&  loan.next_stage_employees[0].first_name } <br/>
                  Emp Id: {loan.next_stage_emp_id}
                </Td>
                <Td>{loan.sub_stage}</Td>
                
                <Td>{loan.stage_assign_date && loan.stage_assign_date.split("T")[0]}</Td>
                <Td>
                <Button component="a"
              href={`/home/sblloans/details/${loan._id}`}
              size="small"
              color="primary"
            >
              View Details</Button>
                </Td>
              </Tr>
            ))}
          {!loans && (
            <Tr>
              <Td colSpan="4" className="text-center">
                <span className="spinner-border spinner-border-lg align-center"></span>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      {loans ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={loans.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        <div />
      )}
    </div>
  );
}

export { SanctionList };
