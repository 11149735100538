import React, { useState, useEffect } from 'react';
import {
    Modal,
    Backdrop,
    Fade,
    Button,
    TextField,
    MenuItem,
    IconButton,
    Typography,
    Paper,
  Checkbox,

} from '@material-ui/core';
import { Table, Thead, Tbody, Tr, Td, Th } from 'react-super-responsive-table';

import datehelper from "../../../_helpers/datehelper";

import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, FieldArray, Field } from 'formik';
import * as Yup from 'yup';
import { Add, Delete, Edit } from '@material-ui/icons';
import { adminservice } from "../../../_services/admin.services";
import { uploadFileToBucket } from '../../../_helpers/upload';
import { employeeService } from "../../../_services/employee.service";
import EmployeeSelectModal from "./EmployeeSelectModal"
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { employeeExpenseService } from "../../../_services/employeeExpense.service"
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: '#F2F0EF',
        padding: theme.spacing(2),
        borderRadius: '10px',
        width: '80%',
        // maxWidth: '1000px',
        maxHeight: "600px",
        overflow: 'auto',
    },
    formField: {
        marginBottom: theme.spacing(2),
    },
    formRow: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
        gap: '1rem',
    },
    entityWrapper: {
        marginBottom: theme.spacing(2),
        position: 'relative',
    },
    deleteButton: {
        position: 'absolute',
        top: '0',
        right: '0',
    },
}));



const EmployeeViewTable = (id) => {
    const classes = useStyles();
    const user = employeeService.userValue
    const [open, setOpen] = useState(false);
    const [initialValues, setInitialValues] = useState({
        travel: [
            {
                travelby: '',
                traveltype: '',
                startDate: '',
                invoice: '',
                ticket: '',
                amount: '',
                purpose: '',
                status: '',
                approvedAmount: ''
            },
        ],
        meal: [
            {
                mealtype: '',
                date: '',
                invoice: '',
                amount: '',
                purpose: '',
                status: '',
                approvedAmount: ''
            },
        ],
        stay: [
            {
                citytype: '',
                cityname: '',
                startDate: '',
                duration: '',
                invoice: '',
                amount: '',
                purpose: '',
                status: '',
                approvedAmount: ''
            },
        ],
        AddedEmployees: [],
        comment: '',
        status: ""
    })


    const expenseId = id.id;

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);





    useEffect(() => {
        if (expenseId && open) {
            employeeExpenseService.getById({ id: expenseId })
                .then((res) => {
                    console.log('edit employee  expense data', res);

                    setInitialValues({ ...initialValues, ...res })


                })
                .catch((err) => console.log('err of edit expense', err));
        }
    }, [expenseId, open]);

    console.log('gsf rwfewref0,', initialValues)








    return (
        <>
            <Button variant="outlined" color="primary" onClick={handleOpen}>
            ViewInfo
            </Button>

            <Modal
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <Button style={{ float: 'right', }} variant='outlined' onClick={() => handleClose()}> <CloseIcon /></Button>

                        <Formik
                            initialValues={initialValues}
                            enableReinitialize={true}

                        >
                            {({ values, setFieldValue }) => (
                                <Form>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        gap: '2rem'
                                    }}>
                                        <Typography variant="h6" gutterBottom>
                                            Expense Information
                                        </Typography>

                                        <div className="form-group row"  >
                                            <Typography variant="h6" gutterBottom>
                                                Employees Added :
                                            </Typography>
                                            {values?.AddedEmployees?.map((emp, idx) => (
                                                <div key={idx}>

                                                    <button
                                                        type="button"
                                                        className="btn btn-danger btn-sm"
                                                        style={{
                                                            marginLeft: '5px', position: 'relative', height: '30px'

                                                        }}
                                                    >
                                                        {emp.emp_id - emp.category}


                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {/* Travel Expenses Table */}
                                   {values?.travel?.length>0 &&  <Paper style={{ margin: '20px', padding: '20px' }}>

                                        <Typography variant="h6" gutterBottom>
                                            Travel Expenses
                                        </Typography>
                                        <Table>
                                            <Thead>
                                                <Tr>

                                                    <Th>Travel By</Th>
                                                    <Th>Travel Type</Th>
                                                    <Th>Start Date</Th>
                                                    <Th>Amount</Th>
                                                    <Th>Purpose</Th>
                                                    <Th>Invoice</Th>
                                                    <Th>Approved Amount</Th>

                                                    <Th>Status</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {values?.travel?.map((item, index) => {
                                                    const color = item.status === "Verified"
                                                        ? 'green'
                                                        : item.status === "Rejected"
                                                            ? 'red'
                                                            : item.status === "Pending"
                                                                ? 'orange'
                                                                : 'defaultColor';
                                                    return <Tr key={index}>

                                                        <Td>{item.travelby}</Td>
                                                        <Td>{item.traveltype}</Td>
                                                        <Td>{datehelper.displayDate(new Date(item.startDate))}</Td>
                                                        <Td>{item.amount}</Td>
                                                        <Td>{item.purpose}</Td>
                                                        <Td>
                                                            <a href={item.invoice} target="_blank" rel="noopener noreferrer">
                                                                Invoice Link
                                                            </a>
                                                        </Td>
                                                        <Td>{item.approvedAmount}</Td>
                                                        <Td style={{ color: color }}>{item.status}</Td>

                                                    </Tr>
                                                })}
                                            </Tbody>
                                        </Table>
                                    </Paper>}
                                    {/* Meal Expenses Table */}
                                {values?.meal?.length>0 &&     <Paper style={{ margin: '20px', padding: '20px' }}>
                                        <Typography variant="h6" gutterBottom>
                                            Meal Expenses
                                        </Typography>
                                        <Table>
                                            <Thead>
                                                <Tr>

                                                    <Th>Meal Type</Th>
                                                    <Th>Date</Th>
                                                    <Th>Amount</Th>
                                                    <Th>Purpose</Th>
                                                    <Th>Invoice</Th>

                                                    <Th>Approved Amount</Th>
                                                    <Th>Status</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {values.meal.map((item, index) => {
                                                    const color = item.status === "Verified"
                                                        ? 'green'
                                                        : item.status === "Rejected"
                                                            ? 'red'
                                                            : item.status === "Pending"
                                                                ? 'orange'
                                                                : 'defaultColor';
                                                    return <Tr key={index}>

                                                        <Td>{item.mealtype}</Td>
                                                        <Td>{datehelper.displayDate(new Date(item.date))}</Td>
                                                        <Td>{item.amount}</Td>
                                                        <Td>{item.purpose}</Td>
                                                        <Td>
                                                            <a href={item.invoice} target="_blank" rel="noopener noreferrer">
                                                                Invoice Link
                                                            </a>
                                                        </Td>
                                                        <Td>{item.approvedAmount}</Td>
                                                        <Td style={{ color: color }}>{item.status}</Td>


                                                    </Tr>
                                                })}
                                            </Tbody>
                                        </Table>
                                    </Paper>}
                                    {/* Stay Expenses Table */}
                                  {values?.stay?.length>0 &&   <Paper style={{ margin: '20px', padding: '20px' }}>
                                        <Typography variant="h6" gutterBottom>
                                            Stay Expenses
                                        </Typography>
                                        <Table>
                                            <Thead>
                                                <Tr>

                                                    <Th>City Type</Th>
                                                    <Th>City Name</Th>
                                                    <Th>Start Date</Th>
                                                    <Th>Duration</Th>
                                                    <Th>Amount</Th>
                                                    <Th>Purpose</Th>
                                                    <Th>Invoice</Th>

                                                    <Th>Approved Amount</Th>

                                                    <Th>Status</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {values.stay.map((item, index) => {
                                                    const color = item.status === "Verified"
                                                        ? 'green'
                                                        : item.status === "Rejected"
                                                            ? 'red'
                                                            : item.status === "Pending"
                                                                ? 'orange'
                                                                : 'defaultColor';
                                                    return <Tr key={index}>

                                                        <Td>{item.citytype}</Td>
                                                        <Td>{item.cityname}</Td>
                                                        <Td>{datehelper.displayDate(new Date(item.startDate))}</Td>
                                                        <Td>{item.duration}</Td>
                                                        <Td>{item.amount}</Td>
                                                        <Td>{item.purpose}</Td>
                                                        <Td>
                                                            <a href={item.invoice} target="_blank" rel="noopener noreferrer">
                                                                Invoice Link
                                                            </a>
                                                        </Td>
                                                        <Td>{item.approvedAmount}</Td>

                                                        <Td style={{ color: color }}>{item.status}</Td>


                                                    </Tr>
                                                })}
                                            </Tbody>
                                        </Table>
                                    </Paper>}

                                </Form>
                            )}
                        </Formik>
                    </div>
                </Fade>
            </Modal>
        </>
    );
};

export default EmployeeViewTable;
