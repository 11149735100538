import React from "react";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Collapse
} from "@material-ui/core";

import { ExpandMore, ExpandLess, AccountBalanceWallet, MonetizationOn } from "@material-ui/icons";
import roleChecker from "../../_helpers/rolechecker";
import product from "../../_helpers/product";


import { employeeService } from "../../_services";

export default function CashManagementMenuList(props) {
  const [open, setOpen] = React.useState(false);
  const { path } = props.match;
  const user = props.user;

  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  return (
    <React.Fragment>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <AccountBalanceWallet></AccountBalanceWallet>
        </ListItemIcon>
        <ListItemText primary="Cash Management" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" style={{ paddingLeft: "50px" }}>
          <ListItem
            hidden={!( roleChecker.isGMLevelAccess(user.role) ||  roleChecker.hasAccountsAccess(user.role) || roleChecker.isGoldRider(user.role) ||user.role ==="ZCCM" ||user.role ==="CCCM"||user.role ==="RCCM"||user.role ==="ACCM"||user.role ==="BCCM"|| (roleChecker.isBMLevelAccess(user.role) && employeeService.productValue==product.GoldLoan))}
            button
            key="prl"
            component="a"
            href={`${path}/cashmanagement/cashcheckinlist`}
          >
            <ListItemText primary="Check-In" />
          </ListItem>
          <ListItem
            hidden={!(roleChecker.isGMLevelAccess(user.role) || roleChecker.hasAccountsAccess(user.role) || roleChecker.isGoldRider(user.role) ||user.role ==="ZCCM" ||user.role ==="CCCM"||user.role ==="RCCM"||user.role ==="ACCM"||user.role ==="BCCM" || (roleChecker.isBMLevelAccess(user.role) && employeeService.productValue==product.GoldLoan))}
            button
            key="prl"
            component="a"
            href={`${path}/cashmanagement/cashcheckoutlist`}
          >
            <ListItemText primary="Check-Out" />
          </ListItem>
          <ListItem
            hidden={!roleChecker.hasAccountsAccess(user.role)}
            button
            key="prl"
            component="a"
            href={`${path}/cashmanagement/cashledgerlist`}
          >
            <ListItemText primary="Cash Ledgers" />
          </ListItem>
          <ListItem
            hidden={!(roleChecker.hasAccountsAccess(user.role) && !["GM"].includes(user.role))  } 
            button
            key="prl"
            component="a"
            href={`${path}/cashmanagement/bankledgerlist`}
          >
            <ListItemText primary="Bank Ledgers" />
          </ListItem>
         {employeeService.productValue === product.GoldLoan && <ListItem
            hidden={!(roleChecker.isBMLevelAccess(user.role) || roleChecker.hasAccountsAccess(user.role) ||roleChecker.isHOGoldMaker(user.role))}
            button
            key="prl"
            component="a"
            href={`${path}/cashmanagement/cashrequest`}
          >
            <ListItemText primary="Cash Request" />
          </ListItem>}
          { employeeService.productValue == product.SBL &&(<ListItem
            hidden={
              !(
                roleChecker.hasAccountsAccess(user.role) ||
                roleChecker.isHOLevelAccess(user.role) ||
                roleChecker.isBMLevelAccess(user.role) 
              )
            }
            button
            key="depositslipdashboard"
            component="a"
            href={`${path}/depositslipdashboard`}
          >
            <ListItemText primary="Deposit Memo" />
          </ListItem>)}
          {employeeService.productValue === product.GoldLoan && <ListItem
            hidden={!(roleChecker.hasAccountsAccess(user.role) || roleChecker.isBMLevelAccess(user.role))}
            button
            key="prl"
            component="a"
            href={`${path}/cashmanagement/cashbankdepositlist`}
          >
            <ListItemText primary="Cash Bank Deposit" />
          </ListItem>}

          {/* {employeeService.productValue == Product.Accounting && <ListItem
             button
             key="pettycash"
             component='a'
             href={`${path}/pettycashlist`}
             >
              <ListItemIcon style={{ marginLeft: 2 }}>
              <Money/> 
              </ListItemIcon>
            <ListItemText primary="Petty Cash" />
            </ListItem>} */}
             {employeeService.productValue == product.Accounting && <ListItem
            button
            key="pettycash"
            component="a"
            href={`${path}/pettycashlist`}
          >
             <ListItemText primary="Petty Cash" />
          </ListItem>}
        </List>
      </Collapse>
    </React.Fragment>
  )
}
