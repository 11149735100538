import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { employeeService } from "../../_services/employee.service";
import { Button } from "@material-ui/core";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { Edit, Delete, Add, BlockOutlined } from "@material-ui/icons";
import { UserUnblockModal } from "./UserUnblockModal";
import roleChecker from "../../_helpers/rolechecker";

function List({ match }) {
  const { path } = match;
  const [users, setUsers] = useState(null);
  const user = employeeService.userValue;

  React.useEffect(() => {
    async function getusers() {
      await employeeService.getAll().then((x) => {
        console.log(x);
        setUsers(x);
      });

      if (roleChecker.isCollectionTeam(user.role))
        setUsers((users) => users.filter((x) => x.role === "Collections"));
    }

    getusers();
  }, [user]);

  function deleteUser(id) {
    setUsers(
      users.map((x) => {
        if (x.id === id) {
          x.isDeleting = true;
        }
        return x;
      })
    );
    employeeService.delete(id).then(() => {
      setUsers((users) => users.filter((x) => x.id !== id));
    });
  }

  return (
    <div>
      <h1 style={{display:'flex'}}>
        Users &nbsp;
        <Button variant="outlined" aria-label="increase" href={`${path}/add`}>
          <Add fontSize="small" />
        </Button> &nbsp;
        <UserUnblockModal/>
      </h1>
      <Table className="table table-striped">
        <Thead>
          <Tr>
            <Th style={{ width: "10%" }}>Status</Th>
            <Th style={{ width: "20%" }}>Name</Th>
            <Th style={{ width: "10%" }}>Employee ID</Th>
            <Th style={{ width: "10%" }}>Branch ID</Th>
            <Th style={{ width: "10%" }}>Phone</Th>
            <Th style={{ width: "20%" }}>Email</Th>
            <Th style={{ width: "10%" }}>Manager Id</Th>
            <Th style={{ width: "20%" }}>Role</Th>
            <Th style={{ width: "10%" }}>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {users &&
            users.map((user) => (
              <Tr key={user.id}>
                <Td>
                  { (user.blocked && (
                    <BlockOutlined/>
                  ))}
                </Td>
                <Td>
                  {user.title} {user.first_name} {user.last_name}
                </Td>
                <Td>{user.emp_id}</Td>
                <Td>{user.branch_id}</Td>
                <Td>{user.contact}</Td>
                <Td>{user.email}</Td>
                <Td>{user.manager_emp_id}</Td>
                <Td>{user.role}</Td>
                <Td style={{ whiteSpace: "nowrap" }}>
                  <Link
                    to={`${path}/edit/${user.id}`}
                    className="btn btn-sm btn-primary mr-1"
                  >
                    <Edit />
                  </Link>
                </Td>
              </Tr>
            ))}
          {!users && (
            <Tr>
              <Td colSpan="4" className="text-center">
                <span className="spinner-border spinner-border-lg align-center"></span>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </div>
  );
}

export { List };
