import React, { useState, useEffect } from 'react';
import datehelper from "../../../_helpers/datehelper";
import Comments from "../BranchPettyCash/Comments";
import { Button, TextField, Box, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import roleChecker from "../../../_helpers/rolechecker";
import { employeeService } from "../../../_services/employee.service";
import { employeeExpenseService } from "../../../_services/employeeExpense.service";
import EmployeeRequestModal from "./EmployeeRequestModal";
import VerifyEmployeeExpense from "./VerifyEmployeeExpense";
import EmployeeViewTable from "./EmployeeViewTable.modal";
import AccountVerificationBox from "./AccountVerificationBox";

function ViewEmployeeExpense() {
  const [allExpenses, setAllExpenses] = useState([]);
  const [employeeList, setEmployeeList] = useState({});
  const [filteredExpenses, setFilteredExpenses] = useState([]);
  const [filterStatus, setFilterStatus] = useState("");
  const [filterEmployee, setFilterEmployee] = useState("");

  const user = employeeService.userValue;

  useEffect(() => {
    employeeExpenseService.getAll()
      .then(res => {
        setAllExpenses(res);
        setFilteredExpenses(res); // Initially, all expenses are shown
        console.log('res of view Employee expense is', res);
      })
      .catch(err => console.log('err is', err));

    getAllEmployees();
  }, []);

  async function getAllEmployees() {
    await employeeService.getAll().then((res) => {
      let list = {};
      res.map((e) => {
        list[e.emp_id] = e.first_name + " " + e.last_name;
      });
      setEmployeeList(list);
    });
  }

  const handleFilterChange = () => {
    let filtered = allExpenses;

    // Filter by status if provided
    if (filterStatus) {
        filtered = filtered.filter(expense => expense.status === filterStatus);
    }

    // Filter by Employee ID or name if provided
    if (filterEmployee) {
        filtered = filtered.filter(expense =>
            expense.requested_by?.toString().includes(filterEmployee) || 
            (employeeList[expense.requested_by]?.toLowerCase().includes(filterEmployee.toLowerCase()))
        );
    }

    // Update the state with filtered results
    setFilteredExpenses(filtered);
};

useEffect(() => {
    handleFilterChange();
}, [filterStatus, filterEmployee, allExpenses]);

  const colorMap = {
    Verified: 'green',
    Rejected: 'red',
    Pending: 'orange',
    Paid: 'blue',
    ReferToAccount: 'purple',
    Requested: 'teal',
  };

  return (
    <>
      <div>
        {/* Filter Section */}
        <Box display="flex" justifyContent="flex-end" gap='2rem' marginBottom="20px" style={{gap:'2rem'}}>
          {/* Employee Name/ID Filter */}
          <TextField
            label="Filter by Employee"
            variant="outlined"
            value={filterEmployee}
            onChange={(e) => setFilterEmployee(e.target.value)}
          />

          {/* Status Filter */}
          <FormControl variant="outlined" style={{    width: '200px'
}}>
            <InputLabel>Status</InputLabel>
            <Select
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              label="Status"
            >
              <MenuItem value=""><em>All</em></MenuItem>
              <MenuItem value="Requested">Requested</MenuItem>
              <MenuItem value="Verified">Verified</MenuItem>
              <MenuItem value="ReferToAccount">Refer to Account</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Paid">Paid</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <div>
          <hr />
          <table className="table">
            <thead>
              <tr >
                <th style={{ textAlign: 'center' }}>S.NO.</th>
                <th style={{ textAlign: 'center' }}>Initiated Date</th>
                <th style={{ textAlign: 'center' }}>Requested By</th>
                <th style={{ textAlign: 'center' }}>Emp. Role</th>
                <th style={{ textAlign: 'center' }}>status</th>
                <th style={{ textAlign: 'center' }}>ViewInfo</th>
                <th style={{ textAlign: 'center' }}>Req. Amount (₹)</th>
                <th style={{ textAlign: 'center' }}>App. Amount (₹)</th>
                <th style={{ textAlign: 'center' }}>Comments</th>
                <th style={{ textAlign: 'center' }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredExpenses.map((expense, index) => {
                const color = colorMap[expense.status] || 'defaultColor';

                return (
                  <tr key={index} style={{ fontSize: 'larger' }}>
                    <td style={{ textAlign: 'center' }}>{index + 1}</td>
                    <td style={{ textAlign: 'center' }}>
                      {expense.createdAt && datehelper.displayDate(new Date(expense.createdAt))}
                    </td>

                    <td style={{ textAlign: 'center' }}>
                      {employeeList[expense.requested_by]}-{expense.requested_by}
                    </td>
                    <td style={{ textAlign: 'center' }}> {expense.role} </td>

                    <td style={{ textAlign: 'center' }}>
                      <>
                        {user.emp_id !== expense.requested_by && !["Pending","Paid"].includes(expense.status)   ? (
                          <>
                            {expense.status === "Requested" ? (
                              roleChecker.isHRDept(user.role) || roleChecker.isZonalLevelAccess(user.role) ? (
                                <p><VerifyEmployeeExpense id={expense._id} /></p>
                              ) : (
                                <p style={{ color: color }}>{expense.status}</p>
                              )
                            ) : null}

                            {expense.status === "Verified" ? (
                              roleChecker.isHRDept(user.role) || roleChecker.isZonalLevelAccess(user.role) ? (
                                <p><AccountVerificationBox data={expense} /></p>
                              ) : (
                                <p style={{ color: color }}>{expense.status}</p>
                              )
                            ) : null}

                            {expense.status === "ReferToAccount" ? (
                              roleChecker.isAccountChecker(user.role) ? (
                                <p><AccountVerificationBox data={expense} /></p>
                              ) : (
                                <p style={{ color: color }}>{expense.status}</p>
                              )
                            ) : null}
                          </>
                        ) : (
                          <p style={{ color: color }}>{expense.status}</p>
                        )}
                      </>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <EmployeeViewTable id={expense._id} />
                    </td>
                    <td style={{ textAlign: 'center' }}>{expense.totalRequestedAmount}</td>
                    <td style={{ textAlign: 'center' }}>{expense.totalApprovedAmount}</td>
                    <td style={{ textAlign: 'center' }}>
                      <Comments data={expense.comments} />
                    </td>

                    <td style={{ textAlign: 'center' }}>
                      {expense.status === "Pending" && user.emp_id === expense.requested_by && (
                        <EmployeeRequestModal id={expense._id} />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default ViewEmployeeExpense;
