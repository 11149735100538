import React from 'react';
import { useState } from 'react';
import { Modal, Box, Typography, Button } from '@material-ui/core';
import { Table, Tbody, Thead, Th, Tr, Td } from 'react-super-responsive-table';
import { Paper } from '@material-ui/core';

export const DataModal = (props) => {
    let data = props.data
    let old_values = data.old_values
    let new_values = data.new_values
    console.log('data',data)
    console.log("old_values",old_values,new_values)
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    function getFieldValue(array, collection) {
        console.log('array,collect',array,collection)
        return array.reduce((acc, curr) => { return acc[curr] }, collection)
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1100,
        minHeight: 200,
        maxHeight: 480,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        // p: 4,
        overflow: 'scroll',
        //paddingBottom: '30px',
    }

    return ( 
        <div>
            <Button style={{ color: 'blue' }} onClick={handleOpen}>Details</Button>
            <Modal 
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                            <h3>Details</h3>
                        </div>
                        <hr style={{ width: '80%' }} />
                        {data && data.new_values_data_details && data.new_values_data_details.data_type && data.new_values_data_details.data_type === 'array' ?
                            <Table style={{ width: '90%', margin: 'auto', marginTop: '30px', marginBottom: '30px' }}>
                                <Thead>
                                    <Tr>
                                        {
                                            data && data.updated_fields && data.updated_fields.map((field, idx) => {
                                                return <Th key={idx}>{field}</Th>
                                            })
                                        }
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        new_values && new_values[`${data.new_values_data_details.array_name}`] && new_values[`${data.new_values_data_details.array_name}`].map((value, idx) => {
                                            return <Tr key={idx}>
                                                {
                                                    data && data.updated_fields && data.updated_fields.map((field, idxn) => {
                                                        return <Td key={idxn}>{value[`${field}`]}</Td>
                                                    })
                                                }
                                            </Tr>
                                        })
                                    }
                                </Tbody>
                            </Table>
                            :
                            <Table style={{ width: '80%', margin: 'auto', marginTop: '30px', marginBottom: '30px' }}>
                                <Thead>
                                    <Tr>
                                        <Th style={{ width: '30%' }}></Th>
                                        <Th style={{ width: '35%' }}>Old value</Th>
                                        <Th style={{ width: '35%' }}>New value</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        data && data.updated_fields && data.updated_fields.length > 0 ?
                                            data.updated_fields.map((e, idx) => {
                                                let val = e.split(/\[|\]|\./).filter(Boolean);
                                                console.log("val",val)
                                                return <Tr key={idx}>
                                                    <Td>{e.split('.').pop()}</Td>
                                                    <Td>{<>{old_values && old_values !== {} && old_values.payment_collection
 ? getFieldValue(val, old_values) : null}</>}</Td>
                                                    <Td>{<>{new_values && new_values !== {} ? getFieldValue(val, new_values) : null}</>}</Td>
                                                    {/* <Td>{<>{old_values&&old_values!=={}?eval(`old_values.${e}`):null}</>}</Td>
                                                <Td>{<>{new_values&&new_values!=={}?eval(`new_values.${e}`):null}</>}</Td> */}
                                                </Tr>
                                            }) :
                                            <Tr>
                                                <Td align='center' colspan='2' >No data available</Td>
                                            </Tr>
                                    }
                                </Tbody>
                            </Table>
                        }
                    </Typography>
                </Box>
            </Modal>
        </div>
    )
}
