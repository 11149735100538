import React from 'react'
import Modal from "@material-ui/core/Modal";
import { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormElement from '../../../uielements/FormElement';
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Fab from "@material-ui/core/Fab";
import SaveIcon from "@material-ui/icons/Save";
import { loanService } from "../../../../_services/loan.service";
import { Paper } from '@material-ui/core';
import { employeeService } from '../../../../_services';
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';



const useStyles = makeStyles((theme) => ({
    modal: {
        width: "50%",
        overflow: "scroll",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    table: {
        minWidth: 650,
    },
}));

const DisbursementDetailMemo = (props) => {

    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const [selectedPaymentType, setSelectedPaymentType] = useState(props.isEdit ? props.account_data.payment_type:"")
    const formikRef = React.useRef();
    console.log(props)

    const user = employeeService.userValue;


    const customer = props.cust;

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let pending_disbursal = Number(customer.loan.sanction_amount);
    if (customer.loan.insurance_amount) {
        pending_disbursal = pending_disbursal - Number(customer.loan.insurance_amount)
    }
    if (customer.loan.processing_fees) {
        pending_disbursal = pending_disbursal - Number(customer.loan.processing_fees)
    }
    if (customer.loan.processing_fees) {
        let gst = Number(customer.loan.processing_fees) * 18 / 100
        pending_disbursal = Math.ceil(pending_disbursal - Number(gst))
    }
    if (customer.loan.stamp_duty) {
        pending_disbursal = pending_disbursal - Number(customer.loan.stamp_duty)
    }
    if (customer.loan.imd_deduction) {
        pending_disbursal = pending_disbursal - Number(customer.loan.imd_deduction)
    }
    // if (customer.loan.cheque_amount) {
    //     pending_disbursal = pending_disbursal - Number(customer.loan.cheque_amount)
    // }
    // if (customer.loan.imd_deduction) {
    //     let gst = Number(customer.loan.imd_deduction) * 18 / 100
    //     pending_disbursal = Math.ceil(pending_disbursal - Number(gst))
    // }
    if (customer.loan.other_charges) {
        pending_disbursal = pending_disbursal - Number(customer.loan.other_charges)
    }
    if (customer.loan.internal_bt_deduction) {
        pending_disbursal = pending_disbursal - Number(customer.loan.internal_bt_deduction)
    }
    if (customer.loan.pre_emi_amount) {
        pending_disbursal = pending_disbursal - Number(customer.loan.pre_emi_amount)
    }
    if (customer.loan.disbursed_amount) {
        pending_disbursal = pending_disbursal - Number(customer.loan.disbursed_payment_amount)
    }

    const disablePastDate = () => {
        let today = new Date();
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0");
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };

    const dateFormat = (value) => {
        let max_date = new Date(value)
        let dd = ("00" + max_date.getDate()).slice(-2)
        let mm = ("00" + (max_date.getMonth() + 1)).slice(-2);
        let yy = max_date.getFullYear();
        max_date = yy + "-" + mm + "-" + dd;
        return max_date
    }

    let initialValues = {
        customer_acc_no: "",
        customer_name: "",
        payment_amount: "",
        payment_date: "",
        payment_remark: ""
    }

    if (!props.isEdit) {
        initialValues = {
            customer_acc_no: props.account_data.bank_account_number,
            customer_name: props.account_data.account_holder_name,
            customer_bank_ifsc_code: props.account_data.ifsc_code,
            customer_bank_name: props.account_data.bank_name,
            payment_amount: "",
            payment_date: "",
            payment_remark: "",
            tranche_percent: "",
            payment_type:""
        }
    } else {
        initialValues = {
            customer_acc_no: props && props.account_data && props.account_data.customer_acc_no,
            customer_name: props && props.account_data && props.account_data.customer_name,
            customer_bank_ifsc_code: props && props.account_data && props.account_data.customer_bank_ifsc_code,
            customer_bank_name: props && props.account_data && props.account_data.customer_bank_name,
            payment_amount: props && props.account_data && props.account_data.payment_amount,
            payment_date: props && props.account_data && dateFormat(new Date(props.account_data.payment_date)),
            payment_remark: props && props.account_data && props.account_data.payment_remark,
            tranche_percent: props && props.account_data && props.account_data.tranche_percent,
            payment_type: props && props.account_data && props.account_data.payment_type,

        }
    }

    const validationSchema = Yup.object().shape({
        customer_name: Yup.string().required("This filled is required"),
        customer_acc_no: Yup.string().required("This filled is required"),
        payment_amount: Yup.number().max(pending_disbursal, "Amount can not exceed the sanction amount").min(1, "Amount cannot be  zero").required("This filled is required"),
        // payment_date: Yup.date().min(disablePastDate(), "Date equal or greater than today").required("This filled is required"),
        // payment_date: Yup.date().required("This filled is required"),
        // tranche_percent: Yup.number().max(100, "Cannot be more than 100%").required("This filled is required")
    })


    const onSubmit = async (fields) => {
        let params = [];
        let account_details = [];

        if (customer && customer.disbursement && customer.disbursement.disbursement_accounts) {
            account_details = customer.disbursement.disbursement_accounts;
        } else {
            account_details = [];
        }
        if (customer && customer.disbursement && customer.disbursement.disbursement_details) {
            params = customer.disbursement.disbursement_details;
        } else {
            params = [];
        }
        let disbursement_memo = [];
        if (customer && customer.disbursement && customer.disbursement.disbursement_memo) {
            disbursement_memo = customer.disbursement.disbursement_memo;
            disbursement_memo.push({ ...fields, added_by: user.emp_id, payment_type:selectedPaymentType })
        } else {
            disbursement_memo = []
            disbursement_memo.push({ ...fields, added_by: user.emp_id, payment_type:selectedPaymentType })
        }

        let updateObject = {
            disbursement: {
                disbursement_details: [...params],
                disbursement_accounts: [...account_details],
                disbursement_memo: [...disbursement_memo]
            }
        }
        loanService
            .updateDisbursement(customer._id, updateObject)
            .then((x) => {
                console.log(x);
                window.alert("Details Added");
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
            });

    }



    const update = async () => {
        console.log({ ...formikRef.current.values })
        let params = [];
        let account_details = [];

        if (customer && customer.disbursement && customer.disbursement.disbursement_accounts) {
            account_details = customer.disbursement.disbursement_accounts;
        } else {
            account_details = [];
        }
        if (customer && customer.disbursement && customer.disbursement.disbursement_details) {
            params = customer.disbursement.disbursement_details;
        } else {
            params = [];
        }
        let disbursement_memo = [];
        if (customer && customer.disbursement && customer.disbursement.disbursement_memo) {
            disbursement_memo = customer.disbursement.disbursement_memo;
            disbursement_memo.splice(props.idx, 1)
            disbursement_memo.push({ ...formikRef.current.values,payment_type:selectedPaymentType })
        } else {
            disbursement_memo = []
            disbursement_memo.push({ ...formikRef.current.values,payment_type:selectedPaymentType })
        }

        let updateObject = {
            disbursement: {
                disbursement_details: [...params],
                disbursement_accounts: [...account_details],
                disbursement_memo: [...disbursement_memo]
            }
        }
        loanService
            .updateDisbursement(customer._id, updateObject)
            .then((x) => {
                console.log(x);
                window.alert("Details Added");
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
            });

    }

    const paymentType = ['Demand Draft', 'Cheque', 'Online']

    // const handleTranche = (e) => {
    //     setSelectedPaymentType(e)
    //     if(e === "Online"){
    //     formikRef.current.setFieldValue('payment_amount', '')
    //     }else{
    //         if(customer && customer.loan && customer.loan.cheque_amount){
    //         formikRef.current.setFieldValue('payment_amount', customer.loan.cheque_amount)
    //         }
    //     }
    // }

    // const calculateTranche = () => {
    //     console.log("running")

    //     let amount = Math.round(pending_disbursal * formikRef.current.values.tranche_percent / 100)
    //     formikRef.current.setFieldValue('payment_amount', amount)
    // }

   const calculatePercentage = () => {

    const percent = Math.round((100* formikRef.current.values.payment_amount)/pending_disbursal)
    formikRef.current.setFieldValue('tranche_percent', percent)

   }

    return (

        <div>
            <Button onClick={handleOpen} variant="contained" color="primary">
                {props.button}
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <Paper >
                            <table>
                                <tr colspan><th style={{ textAlign: "center", fontSize: 18 }} colspan="9">Payment Structure</th></tr><br />
                                <tr style={{ border: "1px solid", fontSize: 12 }}>
                                    <th style={{ border: "1px solid", fontSize: 12 }}>Sanction Amt</th>
                                    <th style={{ border: "1px solid", fontSize: 12 }}>Insurance Amt</th>
                                    <th style={{ border: "1px solid", fontSize: 12 }}>Processing Fee & 18%GST</th>
                                    <th style={{ border: "1px solid", fontSize: 12 }}>Stamp Duty</th>
                                    <th style={{ border: "1px solid", fontSize: 12 }}>IMD Deduction&nbsp;<br />(including GST)</th>
                                    <th style={{ border: "1px solid", fontSize: 12 }}>Other charges</th>
                                    <th style={{ border: "1px solid", fontSize: 12 }}>Internal BT Deduction</th>
                                    {customer.loan.cheque_amount && customer.loan.cheque_amount !== "" && (
                                        <th style={{ border: "1px solid", fontSize: 12 }}>Cheque Amount Deduction</th>
                                    )}
                                    <th style={{ border: "1px solid", fontSize: 12 }}>Disbursed Amount</th>
                                    <th style={{ border: "1px solid", fontSize: 12 }}>Pending Disbursal</th>
                                </tr>
                                <tr style={{ border: "1px solid" }}>
                                    <th style={{ border: "1px solid", fontSize: 12 }}>{customer.loan.sanction_amount || 0}</th>
                                    <th style={{ border: "1px solid", fontSize: 12 }}>{customer.loan.insurance_amount || 0}</th>
                                    <th style={{ border: "1px solid", fontSize: 12 }}>{Math.ceil(Number(customer.loan.processing_fees) + Number(customer.loan.processing_fees) * 18 / 100) || 0}</th>
                                    <th style={{ border: "1px solid", fontSize: 12 }}>{customer.loan.stamp_duty || 0}</th>
                                    <th style={{ border: "1px solid", fontSize: 12 }}>{customer.loan.imd_deduction || 0}</th>
                                    <th style={{ border: "1px solid", fontSize: 12 }}>{customer.loan.other_charges}</th>
                                    <th style={{ border: "1px solid", fontSize: 12 }}>{customer.loan.internal_bt_deduction || 0}</th>
                                    {customer.loan.cheque_amount && customer.loan.cheque_amount !== "" && (
                                        <th style={{ border: "1px solid", fontSize: 12 }}>{customer.loan.cheque_amount || 0}</th>
                                    )}
                                    <th style={{ border: "1px solid", fontSize: 12 }}>{customer.loan.disbursed_payment_amount || 0}</th>
                                    <th style={{ border: "1px solid", fontSize: 12, color: "red" }}>{pending_disbursal}</th>

                                </tr>
                            </table>

                        </Paper>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                            innerRef={formikRef}
                        >
                            {({ errors, touched, isSubmitting, setFieldValue }) => {
                                return (
                                    <Form>
                                        <div className={classes.paper}>
                                            <h2 style={{ marginTop: "10px" }}>Disbursement Memo</h2><br />
                                            <div className="form-row">
                                                <div className="form-group col">
                                                    <FormElement
                                                        label="Customer Name"
                                                        name="customer_name"
                                                        type="text"
                                                        disabled
                                                        errors={errors}
                                                        touched={touched}
                                                    ></FormElement>
                                                        {/* <FormElement
                                                        label="Tranche Percent"
                                                        name="tranche_percent"
                                                        type="text"
                                                        onKeyUp={() => { calculateTranche() }}
                                                        errors={errors}
                                                        touched={touched}
                                                    ></FormElement>  */}
                                                    <FormElement
                                                        label="Payment Amount"
                                                        name="payment_amount"
                                                        type="text"
                                                       
                                                        errors={errors}
                                                        touched={touched}
                                                    ></FormElement> 
                                                </div>
                                                <div className="form-group col">
                                                    <FormElement
                                                        label="Customer Account No."
                                                        name="customer_acc_no"
                                                        type="text"
                                                        disabled
                                                        errors={errors}
                                                        touched={touched}
                                                    ></FormElement>

                                                    <FormElement
                                                        label="Payment Date"
                                                        name="payment_date"
                                                        type="date"
                                                        errors={errors}
                                                        touched={touched}
                                                    ></FormElement>
                                                </div>
                                                <div className="form-group col">
                                                    
                                                <label className="required">Payment Type</label>
                                                    <Select style={{ width: "200px", height: "35px"}}
                                                        name="payment_type"
                                                        required
                                                        value={selectedPaymentType}
                                                        onChange={(e) => setSelectedPaymentType(e.target.value)}
                                                        className={
                                                            "form-control" +
                                                            (errors.payment_type && touched.payment_type ? " is-invalid" : "")
                                                        } >
                                                        <MenuItem defaultChecked disabled >Choose Name</MenuItem>
                                                        {paymentType && paymentType.map((x) => {
                                                            return <MenuItem value={x}>{x}</MenuItem>
                                                        })}

                                                    </Select>
                                                    <ErrorMessage
                                                        name="payment_type"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                    
                                                    <FormElement
                                                        label="Payment Remark"
                                                        name="payment_remark"
                                                        type="text"
                                                        errors={errors}
                                                        touched={touched}
                                                    ></FormElement>
                                                </div>
                                            </div>

                                            <div className="row justify-content-around">
                                                {props.isEdit ? <Fab
                                                    type="button"
                                                    color="primary"
                                                    aria-label="save"
                                                    onClick={() => update()}
                                                >
                                                    <SaveIcon />
                                                </Fab> : <Fab
                                                    type="submit"
                                                    color="primary"
                                                    aria-label="save"
                                                >
                                                    <SaveIcon />
                                                </Fab>}

                                            </div>
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </Typography>
                </Fade>
            </Modal>
        </div>
    )
}

export default DisbursementDetailMemo