import React from 'react'
import { Table, Thead, Tbody, Tr, Th, Td  } from 'react-super-responsive-table';
import { letter } from "../../../_services/lettertracking.service";
import { useState, useEffect } from 'react';
import { Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";


const List = (props) => {
      
  const { path } = props.match;


    const [letters, setLetters] = useState(null);

    const getLetter = async () => {
      const data = await letter.getAll()
     //console.log(data)
     setLetters(data);
    }

    useEffect(() => {
       getLetter()
 
    }, [])

  return (

    <>
    <div>
    <h1>
        Letter Details <br/>
        <Button variant="outlined" aria-label="increase" href={`${path}/createletter`}>
          <Add fontSize="small" />
        </Button>

      <Button style={{marginLeft: "30px"}}  variant="outlined" aria-label="increase" href={`/home/letters/emireminderletter`}>
          <Add fontSize="small" />  Emi Reminder 
      </Button>
      </h1>
    </div>
    
    <div>
      <Table className="table table-striped">
        <Thead>
          <Tr>
            <Th style={{ width: "20%" }}>Date</Th>
            <Th style={{ width: "20%" }}>Letter No.</Th>
            <Th style={{ width: "20%" }}>Type</Th>
            <Th style={{ width: "20%" }}>Letter Handler</Th>
            <Th style={{ width: "20%" }}>Comments</Th>
          </Tr>
        </Thead>
      </Table>
      {letters && letters.map(element => {
       return <div className="container" key={element._id}> 
        <Table className="table table-hover">
          <Tbody>
           <Tr>
             <Td className="table-light" style={{width: "20%"}}>{element.createdAt}</Td>
             <Td className="table-light" style={{width: "20%"}}>{element.letter_number}</Td>
             <Td className='table-light' style={{width: "20%"}}>{element.letter_type}</Td>
             <Td className="table-light" style={{width: "20%"}}>{element.letter_handler}</Td>
             <Td className="table-light" style={{width: "20%"}}>{element.letter_comment}</Td>
            </Tr>
          </Tbody>
        </Table>
       </div> 
     })}
    </div>
    </>
  )
}

export default List