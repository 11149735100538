import React from "react";
import "./pledgeletter.css";

export default class AuthorityDoc extends React.Component {
  render() {
    const data = this.props.dataFill;
    console.log(data);

    return (
      <div className="pledge">
        <style type="text/css" media="print">
          {"\
   @page { size: A4; }\
"}
        </style>
        <br />
        <div
          style={{
            width: "290mm",
            height: "400mm",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: "white",
            padding: 60,
          }}
          className="d-flex flex-column"
        >
          <h6>LETTER OF AUTHORITY FROM BORROWER</h6>
          <br />
          <br />
          <div>
            <p
              style={{
                width: "70%",
                float: "left",
              }}
            >
              From
              <br />
              {data.name.title +
                " " +
                data.name.first_name +
                " " +
                data.name.middle_name +
                " " +
                data.name.last_name}
            </p>
            <p>
              Place: Jaipur
              <br />
              Date:
            </p>
          </div>
          <br />
          <br />
          <p>
            To
            <br />
            Name of Bank
            <br />
            Name of Bank Branch
          </p>
          <br />
          <br />
          <p>
            Sir,
            <br />
            Sub: Letter of Authority authorising disbursal, creation and release
            of pledge and operations in the Gold Loan through the Business
            Correspondent of the Bank M/s Kaabil Finance Pvt Ltd as part of door
            step banking services.
          </p>
          <br />
          <p>
            Name of Borrower :<br />
            Gold Loans sanctioned{" "}
          </p>
          <table>
            <tr>
              <th>#Sr</th>
              <th>Gold Loan details</th>
              <th>Loan Amount (Rs.)</th>
            </tr>
            <tr>
              <td>1</td>
              <td>&nbsp;</td>
              <td>Rs. {data.loan_amount}</td>
            </tr>
          </table>
          <br />
          <br />
          <p>
            At my/our request, CSB Bank Ltd. has agreed to grant me/us Gold
            Ioans as detailed above through the Business Correspondent of the
            Bank M/s Kaabil Finance Pvt Ltd as part of Bank’s door step banking
            services. In this regard I hereby specifically authorise the bank to
            facilitate disbursal, creation of pledge of the gold ornaments,
            operations and release of pledge of gold ornaments in respect of the
            loans aforementioned through the Business Correspondent of the Bank
            M/s Kaabil Finance Pvt Ltd. We are aware of and accept the risks
            involved in transportation of gold ornaments from my/our
            house/office to Bank and back. I hereby authorise and empower the
            Business Correspondent of the Bank, Kaabil Finance Pvt. Ltd Or its
            authorised representatives, to handover to the Bank/receive the Gold
            ornaments / other documents deposited by me with the bank after the
            entire / part outstanding liability with the bank is repaid by me.
            All the risks associated with transportation and pursuant custody is
            undertaken by Kaabil Finance Pvt. Ltd. and I/we shall have no claim
            against the Bank in this respect.
          </p>
          <p>
            I/we am/are aware that the disbursal by the Bank is subject to
            satisfaction of the Bank as to the purity of gold ornaments pledged
            and subject to compliance of lending norms of the Bank and for any
            reasons including if it turns out that such ornaments are of
            inferior quality/spurious Bank is at liberty to reject the loan and
            in such an event I/we undertake thereupon to return forthwith the
            loan amount/s if any received from the Business Correspondent of the
            Bank, Kaabil Finance Pvt. Ltd.{" "}
          </p>
          <br />
          <br />
          <br />
          <br />
          <p>Name of the Borrower and signature</p>
        </div>
        <div
          style={{
            width: "290mm",
            height: "400mm",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: "white",
            padding: 60,
          }}
          className="d-flex flex-column"
        >
          <b>CONFIRMATION LETTER</b>
          <br />
          <br />
          <p>
            TO
            <br />
            Branch Head
            <br />
            CSB Bank Ltd.
            <br />
            …………………..
          </p>
          <br />
          <br />
          <p>Dear Sir</p>
          <br />
          <br />
          <p>
            I/We hereby confirm that the following Gold loans are availed by
            me/us through the Business Correspondent of the Bank M/s Kaabil
            Finance Pvt Ltd (Kaabil).
          </p>
          <br />
          <br />
          <table>
            <tr>
              <th>#Sr</th>
              <th>Gold Loan details</th>
              <th>Loan Amount (Rs.)</th>
              <th>Date of Loan</th>
            </tr>
            <tr>
              <td>1</td>
              <td>&nbsp;</td>
              <td>{data.loan_amount}</td>
              <td>&nbsp;</td>
            </tr>
          </table>
          <br />
          <br />
          <p>
            I hereby confirm the receipt of loan amount/s of Rs.{" "}
            {data.loan_amount}
            &nbsp;as detailed above from the Business Correspondent of the Bank
            M/s Kaabil Finance Pvt Ltd (Kaabil) on behalf of the Bank.
          </p>
          <br />
          <br />
          <br />
          <br />
          <p>
            Name of the Borrower and signature
            <br />
            Place : Jaipur
            <br />
            Date
          </p>
        </div>
      </div>
    );
  }
}
