import { Button, Table } from '@material-ui/core'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Tbody, Td, Th, Tr } from 'react-super-responsive-table'
import { misService } from '../../../_services'
import { employeeService } from '../../../_services'
import datehelper from "../../../_helpers/datehelper"
import { NonReconcilePaymentModal } from './NonReconcilePaymentModal'
export const LoanCloserRequest = () => {
    const [requestedCloserLoan, setRequestedCloserLoan] = useState([])
    const [employeeList, setEmployeeList] = useState({})
    const user = employeeService.userValue.emp_id;
    const date = new Date()
    const closureDate={closure_date:date}
    useEffect(() => {
        async function getAllCloserRequestedLoans() {
            await misService.getAllCloserRequestedLoans()
                .then((res) => setRequestedCloserLoan(res))
                .catch((err) => console.log(err))
        }
        getAllCloserRequestedLoans()
        getAllEmployees();
    }, [])
    async function getAllEmployees() {
        await employeeService.getAll()
            .then((res) => {
                let list = {};
                res.map(e => {
                    list[e.emp_id] = e.first_name + ' ' + e.last_name
                })
                setEmployeeList(list)
            })
    }
    console.log("requestd loans", requestedCloserLoan)
    const ApproveRequest = async(id)=>{
        await misService.approveAndRejectRequest(id, { loan_status: { status: "APPROVED", status_updated_by: user } })
        .then((res) => {
            alert("Loan closer request approved and closed")
            window.location.reload()
        })
        .catch((err) => console.log(err))
    } 
       
    const ApproveAndClose = async(id, loanId, closureDate) => {
        await misService.closeLoan(loanId, closureDate)
            .then((res) =>ApproveRequest(id))
            .catch((err) => console.log(err))
    } 
    const RejectRequest = async(id) => {
        await misService.approveAndRejectRequest(id, { loan_status: { status: "REJECTED", status_updated_by: user }})
            .then((res) => {
                alert("Loan Closer request Rejected")
                window.location.reload()
            })
            .catch((err) => alert(err))
    }

    return (
        <div style={{
            margin: "100px"
        }}>
            <h3 style={{ textAlign: "center", marginBottom: "40px" }}>Loan Closer Requests</h3>
            <Table style={{ marginLeft: "auto", width: "90%", marginRight: "auto" }}>
                <thead>
                    <Tr>
                        <Th >S.N</Th>
                        <Th >Loan No</Th>
                        <Th>Requested Date</Th>
                        <Th>Employee</Th>
                        <Th >Customer Name</Th>
                        <Th>Payment</Th>
                        <Th colSpan="2" style={{textAlign:'center'}}>Action</Th>
                    </Tr>
                </thead>
                <Tbody>
                    {
                        requestedCloserLoan.length > 0 ?
                            requestedCloserLoan.map((x, idx) => <Tr key={idx}>
                                <Td >{idx + 1}</Td>
                                <Td >{x.loan_account}</Td>
                                <Td>{datehelper.displayDate(new Date(x.createdAt))}</Td>
                                <Td>{employeeList[x.request_emp_id]}</Td>
                                <Td >{x.name}</Td>
                                <Td><Button style={{ border: "none" }}>
                                    <NonReconcilePaymentModal loanData={x} />
                                </Button></Td>
                               {x.status != "APPROVED" && x.status != "REJECTED" ?
                                    <Td style={{textAlign:"center",}}>
                                        <Button style={{ color: "green", border: '.5px solid',height:"30px",marginRight:"5px" }}
                                            onClick={() => {ApproveAndClose(x._id,x.id,closureDate)}}>
                                            Approve
                                        </Button>
                                        <Button style={{ color: "red", border: '0.5px solid' ,height:"30px" }}
                                            onClick={() => { RejectRequest(x._id) }}>
                                            Reject
                                        </Button>
                                    </Td> :
                                    <Td colSpan="2" style={{ textAlign:"center" }}>
                                        {x.status}
                                    </Td>
                                }
                            </Tr>
                            )
                            : <Tr>
                                <Td colSpan={9} style={{color:"red",fontSize:"15px",textAlign:'center'}}>Data Not Found</Td>
                            </Tr>
                    }
                </Tbody>
            </Table>

        </div>
    )
}