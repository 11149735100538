import React from "react";

export default function AddPaymentDetailsColumns() {
  return (
    <tr>
      <th style={{ width: "5%" }} className="text-center">
        S.N
      </th>
      <th style={{ width: "25%" }} className="text-center">
        Payment Date
      </th>
      <th style={{ width: "15%" }} className="text-center">
        Payment Type
      </th>
      <th style={{ width: "15%" }} className="text-center">
        Payment Mode
      </th>
      <th style={{ width: "15%" }} className="text-center">
        Reference Number
      </th>
      <th style={{ width: "20%" }} className="text-center">
        Payment Amount
      </th>
      <th style={{ width: "5%" }} />
    </tr>
  );
}
