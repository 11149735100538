import React, { useState, useEffect } from 'react'
import Modal from "@material-ui/core/Modal";
import { Typography } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { Edit } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import * as Yup from "yup";
import { customerService, loanService } from '../../../../_services';
import Fab from "@material-ui/core/Fab";
import SaveIcon from "@material-ui/icons/Save";
import { uploadFileToBucket } from '../../../../_helpers/upload';
import urlhelper from '../../../../_helpers/urlhelper';
import { history } from '../../../../_helpers/history'
import "../../../../css/common.css"


const useStyles = makeStyles((theme) => ({
    modal: {
        width: "75%",
        overflow: "scroll",
        // textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    table: {
        minWidth: 650,
    },
}));



const EditInsuranceDetailsModal = (props) => {

    const bucket = process.env.REACT_APP_s3docsBucket


    const formikRef = React.useRef();

    const cust = props.cust
    const [links, setLinks] = useState([])
    const [details, setDetails] = useState([])
    const [insuredPersonDetails, setInsuredPersonDetails] = useState([])
    const [nomineeDetails, setNomineeDetails] = useState([])



    const dateFormat = (value) => {
        let max_date = new Date(value)
        let dd = ("00" + max_date.getDate()).slice(-2)
        let mm = ("00" + (max_date.getMonth() + 1)).slice(-2);
        let yy = max_date.getFullYear();
        max_date = yy + "-" + mm + "-" + dd;
        return max_date
    }

    let current_date = new Date()
    let minAge = new Date(
        current_date.getFullYear() - 18,
        current_date.getMonth(),
        current_date.getDate(),
        current_date.getHours(),
        current_date.getMinutes()
    )
    let maxAge = new Date(
        current_date.getFullYear() - 100,
        current_date.getMonth(),
        current_date.getDate(),
        current_date.getHours(),
        current_date.getMinutes()
    )




    let initialValues = {}

    if (cust && cust.insurance_details) {
        initialValues = {
            loan_number: cust.application_id,
            insured_person_name: cust.insurance_details.insured_person_name,
            insured_person_dob: dateFormat(cust.insurance_details.insured_person_dob),
            insured_person_gender: cust.insurance_details.insured_person_gender,
            nominee_name: cust.insurance_details.nominee_name,
            nominee_dob: dateFormat(cust.insurance_details.nominee_dob),
            nominee_gender: cust.insurance_details.nominee_gender,
            nominee_relationship: cust.insurance_details.nominee_relationship,
            colender_name: cust.insurance_details.colender_name,
            amount_insured: cust.loan.sanction_amount,  
        //    policy_number: cust.insurance_details.policy_number,
            //policy_institute: cust.insurance_details.policy_institute,
            premium_amount: cust.loan.insurance_amount,
            loan_disbursement_date: dateFormat(cust.loan.disbursement_date),
            // insurance_date: dateFormat(cust.insurance_details.insurance_date),
            policy_term: cust.insurance_details.policy_term,
            loan_amount: cust.loan.sanction_amount,
            annual_income: cust.insurance_details.annual_income,
            occupation_type: cust?.insurance_details?.occupation_type,
            PDR: cust?.insurance_details?.PDR,
            calcuate_tag:cust?.insurance_details?.calcuate_tag,
            insurances: cust.insurance_details.insurances || []
        }
    } else {
        initialValues = {
            loan_number: cust.application_id,
            insured_person_name: "",
            insured_person_dob: "",
            insured_person_gender: "",
            nominee_name: "",
            nominee_dob: "",
            nominee_gender: "",
            nominee_relationship:"",
            colender_name: "",
            amount_insured: cust.loan.sanction_amount,
            // policy_number: "",
            // policy_institute: "",
            premium_amount: cust.loan.insurance_amount,
            loan_disbursement_date: dateFormat(cust.loan.disbursement_date),
            // insurance_date: "",
            policy_term: "",
            loan_amount: cust.loan.sanction_amount,
            annual_income: "",
            occupation_type: "",
            PDR: "",
            calcuate_tag:"",
            insurances: []
        }
       
    }


    const [open, setOpen] = useState(false);
    const classes = useStyles();


    const handleOpen = () => {
        setOpen(true);
        setNames()
        if (cust && cust.insurance_details) {
            setLinks([...cust.insurance_details.reports])
        }
    };

    const handleClose = () => {
        setOpen(false);
        setDetails([])
        setLinks([])
    };


    let validationSchema = Yup.object().shape({
        // loan_number: Yup.string().required("This is Required"),
        insured_person_name: Yup.string().required("This is Required"),
        insured_person_dob: Yup.date().max(dateFormat(minAge), "Age must be greater than or equal to 18 years old").min(dateFormat(maxAge), "Age can not be grater than 100 years").required("This is Required"),
        insured_person_gender: Yup.string().required("This is Required"),
        nominee_name: Yup.string().required("This is Required"),
        nominee_dob: Yup.date().max(dateFormat(minAge), "Age must be greater than or equal to 18 years old").min(dateFormat(maxAge), "Age can not be grater than 100 years").required("This is Required"),
        nominee_gender: Yup.string().required("This is Required"),
        colender_name: Yup.string().required("This is Required"),
        amount_insured: Yup.number().required("This is Required"),
        // policy_number: Yup.string().required("This is Required"),
        // policy_institute: Yup.string().required("This is Required"),
        premium_amount: Yup.number().required("This is Required"),
        loan_disbursement_date: Yup.date().required("This is Required"),
        // insurance_date: Yup.date().required("This is Required"),
        policy_term: Yup.string().required("This is Required"),
        loan_amount: Yup.number().required("This is Required"),
        // annual_income: Yup.number().required("Annual Income is required"),
        occupation_type: Yup.string().required("Occupation is required"),
        nominee_relationship: Yup.string().required("Nominee Relationship is required"), // Add validation for nominee_relationship
        // insurance_report: Yup.string().required("This is Required"),
        
    
    })

    const detailCheck=(obj)=>{
        
         if(cust.insurance_details.insured_person_name!=obj.insurance_details.insured_person_name ||
             cust.insurance_details.policy_number!=obj.insurance_details.policy_number || cust.insurance_details.premium_amount!=obj.insurance_details.premium_amount || cust.insurance_details.amount_insured!=obj.insurance_details.amount_insured || cust.insurance_details.policy_term!=obj.insurance_details.policy_term ){
            return true;
         }
         return false;
    }

   let payment_details=  {
        bene_name:cust?.insurance_details?.payment_details?.bene_name, 
        bene_account_num:cust?.insurance_details?.payment_details?.bene_account_num, 
        bene_ifsc:cust?.insurance_details?.payment_details?.bene_ifsc, 
        amount: cust?.insurance_details?.payment_details?.amount, 
        status: cust?.insurance_details?.payment_details?.status, 
        payment_resp:cust?.insurance_details?.payment_details?.payment_resp,
        request_id:cust?.insurance_details?.payment_details?.request_id,
        request_date:cust?.insurance_details?.payment_details?.request_date,
        batch_no:cust?.insurance_details?.payment_details?.batch_no,
        utr_number:cust?.insurance_details?.payment_details?.utr_number,
      }
    const onSubmit = async (values) => {
      
        
        let obj = {
            insurance_details: { ...values, reports: [...links],"payment_details":payment_details, insurance_paid: cust?.insurance_details?.insurance_paid }
        }
        let startDate = new Date(obj.insurance_details.insurance_date);
        console.log('startDate', startDate);
        
        let endDate = new Date(obj.insurance_details.insurance_date);
        endDate.setFullYear(endDate.getFullYear() + parseInt(obj?.insurance_details?.policy_term));
        
        // Subtract one day to get the last_date
        let lastDate = new Date(endDate);
        lastDate.setDate(lastDate.getDate() - 1);
        
        console.log("startDate:", startDate.toLocaleDateString('en-GB'));
        console.log("lastDate:", lastDate.toLocaleDateString('en-GB'));

       if(cust && cust.insurance_details){
          if(detailCheck(obj)){
           
             let insuranceData={};
             insuranceData["params1"]=cust.applicant.general_information.contact;
             insuranceData["params2"]="insurance_charges";
             insuranceData["params3"]=cust.applicant.general_information.name
             insuranceData["params4"]=cust.application_id
             insuranceData["params5"]=obj.insurance_details.insured_person_name

             insuranceData["params6"]=obj.insurance_details.policy_number
             insuranceData["params7"]=obj.insurance_details.premium_amount
             insuranceData["params8"]=obj.insurance_details.amount_insured
             insuranceData["params9"]=startDate.toLocaleDateString('en-GB')
             insuranceData["params10"]=lastDate.toLocaleDateString('en-GB')
              console.log('insuranceData',insuranceData,"hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh")

             loanService.insuranceDetailsWastsapp({insuranceData:insuranceData})
             .then(res=>console.log('res',res))
             .catch(err=>console.log(err))
          }
       }
       
        await loanService.update(cust._id, obj)
            .then((data) => {
                console.log(data,"wwwwwwww")
                updateCustomerCollection(values)

                alert("Data Added Successfully")
                window.location.reload()
            })
            .catch((err) => {
                console.log(err)
                alert("Something went wrong!! Please try again")
            })

        console.log(obj)
    }

    const updateCustomerCollection = async (values) => {

        let obj2 = {
            general_details: { ...insuredPersonDetails.general_details, customer_dob: values.insured_person_dob }
        }

        let obj3 = {
            general_details: { ...nomineeDetails.general_details, customer_dob: values.nominee_dob }
        }

        if (insuredPersonDetails && insuredPersonDetails.general_details && !insuredPersonDetails.general_details.customer_dob) {
            console.log(insuredPersonDetails._id, obj2)
            await customerService.updateCustomer(insuredPersonDetails._id, obj2)
                .then((data) => { console.log("updated customer collection") })
                .catch((err) => { console.log(err) })
        }

        if (nomineeDetails && nomineeDetails.general_details && !nomineeDetails.general_details.customer_dob) {
            await customerService.updateCustomer(nomineeDetails._id, obj3)
                .then((data) => { console.log("updated customer collection") })
                .catch((err) => { console.log(err) })
        }
    }

    const handleFile = async (e) => {
        let tempUrl = []
        const randomNum = Math.floor(100 + Math.random() * 900);
        let file = e.currentTarget.files[0];
        let url = await uploadFileToBucket(bucket, 'application/pdf', `${bucket}/insurancereport/${cust.application_id}_${randomNum}.pdf`, file);
        let name = file.name.split(".")
        name = name[0]
        tempUrl.push({ url: url, name: name })
        setLinks([...links, ...tempUrl]);
        // console.log("Test link", file.name)
        // formikRef.current.setFieldValue('repaymentSchedule', link);
    }


    const setNames = () => {
        let tempArr = []
        let arr = []
        if (cust && cust.applicant && cust.applicant.general_information) {
            tempArr.push({
                name: cust.applicant.general_information.name,
                customer_id: cust.applicant.customer_id
            })
            // setDetails([...details, ...tempArr])
        }
        if (cust && cust.co_applicants && cust.co_applicants.length > 0) {
            cust.co_applicants.map((x) => {
                arr.push({
                    name: x.general_information.name,
                    customer_id: x.customer_id
                })
            })
        }
        setDetails([...details, ...arr, ...tempArr])
    }

console.log(details,"detailsdetails")
    // const fetchInsuredPerson = (value) => {
    //     console.log(value)
    //     console.log(value.split("-")[0])
    //     formikRef.current.setFieldValue('insured_person_name', value.split("-")[0])
    //     customerService.searchCustomer({ customer_id: value.split("-")[1] })
    //         .then((data) => {
    //             setInsuredPersonDetails(data[0])
    //             formikRef.current.setFieldValue('insured_person_gender', data[0].general_details.gender)
    //             formikRef.current.setFieldValue('insured_person_dob', data[0].general_details.customer_dob)
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }
  
    const fetchInsuredPerson = (value) => {
        const selectedName = value
        console.log(selectedName);
        formikRef.current.setFieldValue('insured_person_name', selectedName);
    
        // Find the corresponding customer_id from details array based on selectedName
        const selectedCustomer = details.find(item => item.name === selectedName);
        console.log(selectedCustomer,"selectedCustomer")
        if (selectedCustomer) {
            const customerId = selectedCustomer.customer_id;
            customerService.searchCustomer({ customer_id: customerId })
                .then((data) => {
                    setInsuredPersonDetails(data[0]);
                    formikRef.current.setFieldValue('insured_person_gender', data[0].general_details.gender);
                    formikRef.current.setFieldValue('insured_person_dob', data[0].general_details.customer_dob);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            console.log("Customer not found in details array");
            // Handle accordingly, perhaps reset fields or show an error
        }
    };
    

    // const fetchNominee = (value) => {
    //     console.log(value.split("-")[0])
    //     formikRef.current.setFieldValue('nominee_name', value.split("-")[0])
    //     customerService.searchCustomer({ customer_id: value.split("-")[1] })
    //         .then((data) => {
    //             setNomineeDetails(data[0])
    //             formikRef.current.setFieldValue('nominee_gender', data[0].general_details.gender)
    //             formikRef.current.setFieldValue('nominee_dob', data[0].general_details.customer_dob)
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }


    const fetchNominee = (value) => {
        const selectedName = value
        console.log(selectedName);
        formikRef.current.setFieldValue('nominee_name', selectedName);
    
        // Find the corresponding customer_id from details array based on selectedName
        const selectedCustomer = details.find(item => item.name === selectedName);
        console.log(selectedCustomer,"selectedCustomer")
        if (selectedCustomer) {
            const customerId = selectedCustomer.customer_id;
            customerService.searchCustomer({ customer_id: customerId })
                .then((data) => {
                    setNomineeDetails(data[0]);
                    formikRef.current.setFieldValue('nominee_gender', data[0].general_details.gender);
                    formikRef.current.setFieldValue('nominee_dob', data[0].general_details.customer_dob);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            console.log("Customer not found in details array");
            // Handle accordingly, perhaps reset fields or show an error
        }
    }


    const tableStyle = {
        border: '1x solid black',
        borderCollapse: 'collapse',
        width: '100%',
        margin:"2rem 0px"
      };
    
      const cellStyle = {
        border: '1px solid black',
        // padding: '10px',
        textAlign: 'center'
      };
    return (
        <>

            <Button style={{ color: "blue" }} onClick={handleOpen} ><Edit />Edit</Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">

                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                            innerRef={formikRef}
                        >
                            {({ errors, touched, isSubmitting, setFieldValue }) => {
                                return (
                                    <Form>
                                        <div className={classes.paper}>
                                            <div className="form-row">
                                                <div className="form-group col">
                                                    <label className="required" style={{ color: "rgb(41,73,185)" }}>Loan Number</label>
                                                    <Field style={{ width: "200px", backgroundColor: "white", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="loan_number" disabled className={
                                                        "form-control" +
                                                        (errors.loan_number && touched.loan_number ? " is-invalid" : "")
                                                    } />
                                                    <ErrorMessage
                                                        name="loan_number"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>

                                                <div className="form-group col">
                                                    <label className="required" style={{ color: "rgb(41,73,185)" }}>Insured Person Name</label>
                                                    <Field style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="insured_person_name"  as='select' required    onChange={(e) => fetchInsuredPerson(e.target.value)}  className={
                                                        "form-control" +
                                                        (errors.insured_person_name && touched.insured_person_name ? " is-invalid" : "")
                                                    } >
                                                    {details && details.map((x) => {
                                                            return <option value={`${x.name}`}>{x.name}</option>
                                                        })}
                                                    </Field>
                                                    <ErrorMessage
                                                        name="insured_person_name"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />

                                                    
                                                    {/* <Select
                                                        style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }}
                                                        name="insured_person_name"
                                                        required
                                                        onChange={(e) => fetchInsuredPerson(e.target.value)}
                                                        className={
                                                            "form-control" +
                                                            (errors.insured_person_name && touched.insured_person_name ? " is-invalid" : "")
                                                        } >

                                                        <MenuItem defaultChecked  >Choose Name</MenuItem>
                                                        {details && details.map((x) => {
                                                            return <MenuItem value={`${x.name}-${x.customer_id}`}>{x.name}</MenuItem>
                                                        })}

                                                    </Select>
                                                    <ErrorMessage
                                                        name="insured_person_name"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    /> */}
                                                </div>

                                                <div className="form-group col">
                                                    <label className="required" style={{ color: "rgb(41,73,185)" }}>Insured Person DOB</label>
                                                    <Field style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="insured_person_dob" type="date" className={
                                                        "form-control" +
                                                        (errors.insured_person_dob && touched.insured_person_dob ? " is-invalid" : "")
                                                    } />
                                                    <ErrorMessage
                                                        name="insured_person_dob"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>

                                                <div className="form-group col">
                                                    <label className="required" style={{ color: "rgb(41,73,185)" }}>Insured Person Gender</label>
                                                    <Field style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="insured_person_gender" className={
                                                        "form-control" +
                                                        (errors.insured_person_gender && touched.insured_person_gender ? " is-invalid" : "")
                                                    } />
                                                    <ErrorMessage
                                                        name="insured_person_gender"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col">
                                                    <label className="required" style={{ color: "rgb(41,73,185)" }}>Nominee Name</label>
                                                    {/* <Select style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }}
                                                        name="nominee_name"
                                                        required
                                                        onChange={(e) => fetchNominee(e.target.value)}
                                                        className={
                                                            "form-control" +
                                                            (errors.nominee_name && touched.nominee_name ? " is-invalid" : "")
                                                        } >
                                                        <MenuItem defaultChecked disabled >Choose Name</MenuItem>
                                                        {details && details.map((x) => {
                                                            return <MenuItem value={`${x.name}-${x.customer_id}`}>{x.name}</MenuItem>
                                                        })}

                                                    </Select>
                                                    <ErrorMessage
                                                        name="nominee_name"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    /> */}
                                                    <Field style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="nominee_name"  as='select' required    onChange={(e) => fetchNominee(e.target.value)}  className={
                                                        "form-control" +
                                                        (errors.nominee_name && touched.nominee_name ? " is-invalid" : "")
                                                    } >
                                                    {details && details.map((x) => {
                                                            return <option value={`${x.name}`}>{x.name}</option>
                                                        })}
                                                    </Field>
                                                    <ErrorMessage
                                                        name="nominee_name"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>

                                                <div className="form-group col">
                                                    <label className="required" style={{ color: "rgb(41,73,185)" }}>Nominee DOB</label>
                                                    <Field style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="nominee_dob" type="date" className={
                                                        "form-control" +
                                                        (errors.nominee_dob && touched.nominee_dob ? " is-invalid" : "")
                                                    } />
                                                    <ErrorMessage
                                                        name="nominee_dob"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>

                                                <div className="form-group col">
                                                    <label className="required" style={{ color: "rgb(41,73,185)" }}>Nominee Gender</label>
                                                    <Field style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="nominee_gender" className={
                                                        "form-control" +
                                                        (errors.nominee_gender && touched.nominee_gender ? " is-invalid" : "")
                                                    } />
                                                    <ErrorMessage
                                                        name="nominee_gender"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                                <div className="form-group col">
                                                    <label className="required" style={{ color: "rgb(41,73,185)" }}>Nominee Relationship</label>
                                                    <Field style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="nominee_relationship" type="text"   required // Add the required attribute
    className={"form-control" + (errors.nominee_relationship && touched.nominee_relationship ? " is-invalid" : "")}/>
                                                    <ErrorMessage
                                                        name="nominee_relationship"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                               
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col">
                                                    <label className="required" style={{ color: "rgb(41,73,185)" }}>Amount Insured</label>
                                                    <Field style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="amount_insured" type="number" className={
                                                        "form-control" +
                                                        (errors.amount_insured && touched.amount_insured ? " is-invalid" : "")
                                                    } />
                                                    <ErrorMessage
                                                        name="amount_insured"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                                <div className="form-group col">
                                                    <label className="required" style={{ color: "rgb(41,73,185)" }}>Co-Lender Name</label>
                                                    <Field style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="colender_name" as='select' required className={
                                                        "form-control" +
                                                        (errors.colender_name && touched.colender_name ? " is-invalid" : "")
                                                    }
                                                    >
                                                        <option  selected >Choose Name of Lender</option>
                                                        <option value='Ugro'>Ugro</option>
                                                        <option value='Capital'>Capital</option>
                                                        <option value='Vistaar'>Vistaar</option>
                                                    </Field>
                                                    <ErrorMessage
                                                        name="colender_name"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>

                                                   
                                                <div className="form-group col">
                                                    <label className="required" style={{ color: "rgb(41,73,185)" }}>Occupation</label>
                                                    <Field      style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }}name="occupation_type" as='select' required // Add the required attribute
    className={"form-control" + (errors.occupation_type && touched.occupation_type ? " is-invalid" : "")}
                                                    >
                                                        
                                                        <option value="salaried">Salaried</option>
                                                        <option value="self_employed">Self Employed</option>
                                                        <option value="self_employed_professional">Self Employed Professional</option>
                                                    </Field>
                                                    <ErrorMessage
                                                        name="occupation_type "
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                                {/* <div className="form-group col">
                                                    <label style={{ color: "rgb(41,73,185)" }}>Policy Number</label>
                                                    <Field style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="policy_number" className={
                                                        "form-control" +
                                                        (errors.policy_number && touched.policy_number ? " is-invalid" : "")
                                                    } />
                                                    <ErrorMessage
                                                        name="policy_number"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div> */}

                                                {/* <div className="form-group col">
                                                    <label className="required" style={{ color: "rgb(41,73,185)" }}>Policy Institute</label>
                                                    <Field style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="policy_institute"  as='select' required  className={
                                                        "form-control" +
                                                        (errors.policy_institute && touched.policy_institute ? " is-invalid" : "")
                                                    } >
                                                    <option value="CARE">CARE</option>
                                                    <option value="ICICI PRU">ICICI PRU</option>
                                                    </Field>
                                                    <ErrorMessage
                                                        name="loan_number"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div> */}

                                                <div className="form-group col">
                                                    <label className="required" style={{ color: "rgb(41,73,185)" }}>Premium Amount</label>
                                                    <Field style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} type="number" name="premium_amount" className={
                                                        "form-control" +
                                                        (errors.premium_amount && touched.premium_amount ? " is-invalid" : "")
                                                    } />
                                                    <ErrorMessage
                                                        name="premium_amount"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col">
                                                    <label className="required" style={{ color: "rgb(41,73,185)" }}>Disbursement Date</label>
                                                    <Field style={{ width: "200px", height: "35px", backgroundColor: "white", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} disabled name="loan_disbursement_date" type="date" className={
                                                        "form-control" +
                                                        (errors.loan_disbursement_date && touched.loan_disbursement_date ? " is-invalid" : "")
                                                    } />
                                                    <ErrorMessage
                                                        name="loan_number"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                                <div className="form-group col">
                                                    <label  style={{ color: "rgb(41,73,185)" }}>Annual Income</label>
                                                    <Field style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="annual_income" type="number"  // Add the required attribute
    className={"form-control" + (errors.annual_income && touched.annual_income ? " is-invalid" : "")} />
                                                    <ErrorMessage
                                                        name="annual_income"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                                {/* <div className="form-group col">
                                                    <label className="required" style={{ color: "rgb(41,73,185)" }}>Insurance Date</label>
                                                    <Field style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)", color: "rgb(41,73,185)" }} type="date" name="insurance_date" className={
                                                        "form-control" +
                                                        (errors.insurance_date && touched.insurance_date ? " is-invalid" : "")
                                                    } />
                                                    <ErrorMessage
                                                        name="insurance_date"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div> */}

                                                <div className="form-group col">
                                                    <label className="required" style={{ color: "rgb(41,73,185)" }}>Policy Term</label>
                                                    <Field style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="policy_term" className={
                                                        "form-control" +
                                                        (errors.policy_term && touched.policy_term ? " is-invalid" : "")
                                                    } />
                                                    <ErrorMessage
                                                        name="policy_term"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>

                                                <div className="form-group col">
                                                    <label className="required" style={{ color: "rgb(41,73,185)" }}>Loan Amount</label>
                                                    <Field style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="loan_amount" type="number" className={
                                                        "form-control" +
                                                        (errors.loan_amount && touched.loan_amount ? " is-invalid" : "")
                                                    } />
                                                    <ErrorMessage
                                                        name="loan_amount"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-row" >
                                                <div className="form-group col" >
                                                    <label style={{ color: "rgb(41,73,185)", }}>Insurance Report</label>
                                                    <Field style={{ width: "100%", height: "35px", borderColor: "rgb(41,73,185)", color: "rgb(41,73,185)" }}
                                                        name="insurance_report"
                                                        type="file" accept=".pdf"
                                                        onChange={(e) => handleFile(e)}
                                                        className={
                                                            "form-control" +
                                                            (errors.insurance_report && touched.insurance_report ? " is-invalid" : "")
                                                        } />
                                                    <ErrorMessage
                                                        name="insurance_report"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                             
                                          


                                           
                                           
                                            </div>

                                            <table style={{ marginTop: "20px", marginBottom: "20px", alignItems: "center", width: "90%" }} >
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>File</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {links && links.length > 0 && links.map((link) => {
                                                        return <tr>
                                                            <td>{link.name}</td>
                                                            <td>
                                                                <Button
                                                                    color='primary'
                                                                    variant='outlined'
                                                                    onClick={() => history.push(`/home/pdfviewer?pdf_url=` + urlhelper.encodeToBase64(link.url) + "&download=1")}
                                                                >View</Button>
                                                            </td>
                                                        </tr>

                                                    })}
                                                </tbody>
                                            </table>

                                            <table style={tableStyle} class="table-success" >
  <thead>
    <tr>
      <th style={cellStyle} scope="col">Policy Institute</th>
      <th style={cellStyle} scope="col">Insurance Type</th>
      <th style={cellStyle} scope="col">Insurance Amount</th>
      <th style={cellStyle} scope="col">Insurance Date</th>
      <th style={cellStyle} scope="col">Policy Number</th>
    </tr>

  </thead>
  <tbody>
  {cust && cust.insurance_details && cust.insurance_details?.insurances?.map((insurance, index) => {
        return  <tr key={index}>
        <td style={cellStyle}>{insurance.insurance_partner}</td>
        <td style={cellStyle}>{insurance.insurance_type}</td>
        <td style={cellStyle}>{insurance.total_ins_amt}</td>
      {/* <td style={cellStyle}> <Field style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)", color: "rgb(41,73,185)" }} type="date" name="insurance_date" className={"form-control" + (errors.insurance_date && touched.insurance_date ? " is-invalid" : "") } />
            <ErrorMessage name="insurance_date" component="div" className="invalid-feedback" /></td>
      <td style={cellStyle}><Field style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="policy_number" placeholder="Enter Policy Number" className={"form-control" + (errors.policy_number && touched.policy_number ? " is-invalid" : "")} />
            <ErrorMessage name="policy_number" component="div" className="invalid-feedback"/></td> */}
              <td style={cellStyle}>
                    <Field
                      style={{ width: '200px', height: '35px', color: 'rgb(41,73,185)', borderColor: 'rgb(41,73,185)' }}
                      type="date"
                      name={`insurances[${index}].insurance_date`}
                      className={`form-control${errors.insurances?.[index]?.insurance_date && touched.insurances?.[index]?.insurance_date ? ' is-invalid' : ''}`}
                    />
                    <ErrorMessage name={`insurances[${index}].insurance_date`} component="div" className="invalid-feedback" />
                  </td>
                                <td style={cellStyle}>
                                  <Field
                                    style={{ width: '200px', height: '35px', color: 'rgb(41,73,185)', borderColor: 'rgb(41,73,185)' }}
                                    name={`insurances[${index}].policy_number`}
                                    placeholder="Enter Policy Number"
                                    className={`form-control${errors.insurances?.[index]?.policy_number && touched.insurances?.[index]?.policy_number ? ' is-invalid' : ''}`}
                                  />
                                  <ErrorMessage name={`insurances[${index}].policy_number`} component="div" className="invalid-feedback" />
                                </td>
    </tr>
       })}
  </tbody>
</table>


                                            <div className="row justify-content-around">
                                                <Fab
                                                    type="submit"
                                                    color="primary"
                                                    aria-label="save"
                                                >
                                                    <SaveIcon />
                                                </Fab>
                                            </div>
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </Typography>
                </Fade>

            </Modal>
        </>
    )
}

export default EditInsuranceDetailsModal