import React from "react";
import { Route, Switch } from "react-router-dom";

import { CustomerAccountList } from "./CustomerAccountList";
import { CustomerAddEdit } from "./CustomerAddEdit";
import Loan from './Loan.jsx';
import FederalLoan from "./FederalLoan";

function Customers({ match }) {
  const { path } = match;

  return (
    <Switch>
      <Route exact path={path} component={CustomerAccountList} />
      <Route path={`${path}/add`} component={CustomerAddEdit} />
      <Route path={`${path}/edit/:aadhaar`} component={CustomerAddEdit} />
      <Route path={'/home/customers/loan/:id'} component={Loan} />
      <Route path={'/home/customers/fedloan/:id'} component={FederalLoan} />
    </Switch>
  );
}

export { Customers };
