import React, { useState,useEffect } from 'react';
import { Modal, Button, Typography, Box } from '@material-ui/core';
import {adminservice} from "../../_services/admin.services"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { TableContainer } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';

export default function AdminSettings() {
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
const [isTrue,SetIsTrue]=useState(false)
    const [values, setValues] = useState({
        key_type: "",
        key_value: ""
    });
 const [keyValues,setKeyValues]=useState({
  keys:"",
  isUnique:false
 })
 const [allKeys,setAllKeys]=useState([])
 const [allKeyPair,setAllKeyPair] = useState([])

    const handleClose2 = () => {
        setOpen2(false);
    }
    const handleClose1 = () => {
      setOpen1(false);
  }

    const handleSubmit2 = (e) => {
        e.preventDefault();
        console.log("Form values:", values);
        // Add your form submission logic here
        adminservice.adminsettings(values)
        .then(res=>{
            console.log('res',res)
            if(res.success==true){
                alert("Key Value added successfully")
                window.location.reload();
            }else{
                alert(res.message)
                window.location.reload();
            }
        })
        .catch(err=>console.log("err is",err))
    }
    const handleSubmit1 = (e) => {
      e.preventDefault();
      console.log("Form values:", keyValues);
    adminservice.pairKeys(keyValues)
    .then(res=>{
        console.log('res',res)
        if(res.success==true){
            alert("key add successfully")
            window.location.reload();
        }else{
            alert("Unable to add ")
            window.location.reload();
        }
    })
    .catch(err=>console.log('error ',err))
      // Add your form submission logic here
  }

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 600,
      height: 300,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };

    useEffect(()=>{
       adminservice.getKeys()
       .then(res=>{
        setAllKeys(res)
        console.log('ressss',res)
    })
       .catch(err=>console.log('errr',err))

       adminservice.getAllKeyPair()
       .then(res=>{
        setAllKeyPair(res)
        console.log('ressss',res)
    })
       .catch(err=>console.log('errr',err))

    },[])

const DeleteKeyPair=(id)=>{
    console.log("id is",id)
    adminservice.deleteKeyPair(id)
    .then(res=>{
        console.log('res',res)
        if(res.success==true){
            alert('Document deleted successfully !')
            window.location.reload();
        }else{
            alert('Document not found !')
            window.location.reload();
        }
    })
    .catch(err=>console.log('err',err))
}

    return (
        <div className="admin-settings-container">
            <div className="admin-settings-content">
                <div className="admin-settings-header">
                    <h3>Admin Settings</h3>
                </div>
                <div style={{padding:"1rem"}}>

<Button color="primary" variant="contained" onClick={() => setOpen1(true)}>Add Key</Button>
<Modal
    open={open1}
    onClose={handleClose1}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
>
    <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
            <form className="modal-form" onSubmit={(e) => handleSubmit1(e)} >
            <div>
              <h3 style={{textAlign:'center',padding: '1rem'}}>Enter a unique key</h3>
            </div>
                <div className="form-group" style={{display:'flex',justifyContent:'space-evenly'}}>
                    
                    <div >
                    Unique <input type="checkbox" onChange={(e) => { setKeyValues({ ...keyValues, isUnique: e.target.checked }) }} />
                    </div>
                    <input placeholder="Enter the value" onChange={(e) => setKeyValues({ ...keyValues, keys: e.target.value })} required/>
                </div>
                <div style={{display:"flex",justifyContent:'center'}}>

                <Button type='submit' color="primary" variant="outlined" className="submit-button">Submit</Button>
                </div>
            </form>
        </Typography>
    </Box>
</Modal>
</div>
  
              <div className="row d-flex" style={{"margin":"5px",height:'250px',overflow:'auto',scrollBehavior:"smooth"}}>
              <TableContainer  >
                <Table>
                <Thead>
                    <Tr>
                    
                     
                    <Th style={{ width: "15%" }}>iS Unique</Th>
                    <Th style={{ width: "10%" }}>Keys</Th>
                    <Th style={{ width: "10%" }}>Created By</Th>
                    
                    
                    </Tr>
                </Thead>
                <Tbody>
                {allKeys && allKeys.map((item, idx) => (
                  <tr key={idx}>
                  <td>{item.isUnique?"true":"false"}</td>
                  <td>{item.keys}</td>
                  <td>{item.emp_id}</td>
                  
                  </tr>
              ))}
                    </Tbody>
                </Table>
              </TableContainer>
              </div>
                <div style={{padding:"1rem"}}>

                <Button color="primary" variant="contained" onClick={() => setOpen2(true)}>Add Key-Pair</Button>
                <Modal
                    open={open2}
                    onClose={handleClose2}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            <form className="modal-form" onSubmit={(e) => handleSubmit2(e)} >
                            <div>
              <h3 style={{textAlign:'center',padding: '1rem'}}>Enter  a valid value</h3>
            </div>
                                <div className="form-group" style={{display:'flex',justifyContent:'space-evenly'}}>
                                   
                                    <select required onChange={(e) => setValues({ ...values, key_type: e.target.value })} >

                                        <option value="" disabled selected>Select</option>
                                        {
                                            allKeys && allKeys.map((val,ind)=>{
                                                 return                                         <option value= {val.keys}    >{val.keys}</option>

                                            })
                                        }
                                     </select>
                                    <input placeholder="Enter the value" onChange={(e) => setValues({ ...values, key_value: e.target.value })} required/>
                                </div>
                                <div  style={{display:"flex",justifyContent:'center'}}>

                                <Button type='submit' color="primary" variant="outlined" className="submit-button">Submit</Button>
                                </div>
                            </form>
                        </Typography>
                    </Box>
                </Modal>
                </div>
                <div className="row d-flex"  style={{"margin":"5px",height:'250px',overflow:'auto',scrollBehavior:"smooth"}}>
              <TableContainer  >
                <Table>
                <Thead>
                    <Tr>
                    
                     
                    <Th style={{ width: "15%" }}>key</Th>
                    <Th style={{ width: "10%" }}>value</Th>
                    <Th style={{ width: "10%" }}>Created By</Th>
                    <Th style={{ width: "10%" }}>Action</Th>

                    
                    </Tr>
                </Thead>
                <Tbody>
                {allKeyPair && allKeyPair.map((item, idx) => (
                  <tr key={idx}>
                  <td>{item.key_type}</td>
                  <td>{item.key_value}</td>
                  <td>{item.emp_id}</td>
                  
                  <td> 
                  <button onClick={()=>{
                    if(window.confirm('Are you sure you want to delete this document')){
                        DeleteKeyPair(item._id)}
                  }
                    }>
<DeleteIcon/>
                  </button>
                  </td>
                  </tr>
              ))}
                    </Tbody>
                </Table>
              </TableContainer>
              </div>
            </div>
        </div>
    );
}
