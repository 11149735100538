import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import * as Yup from "yup";
import { Button } from "@material-ui/core";
import { misService } from "../../../_services";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { authPersonService } from "../../../_services/auth_person.service";
import { releaseServices } from "../../../_services/releaseletter.service";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "75%",
    overflow: "scroll",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    minWidth: 650,
  },
}));

export default function ReleaseLetterModal(props) {
  const loan = props.loan;
  const user = props.user;
  console.log("test props", props)
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [bank, setBank] = React.useState(null);
  const current_date = new Date();
  const [authPersonList, setAuthPersonList] = React.useState([]);

  const [authPerson, setAuthPerson] = React.useState("");
  // const [letterNumber, setLetterNumber] = React.useState("");
  const [releaseAmount, setReleaseAmount] = React.useState("");
  const [packetNumber, setPacketNumber] = React.useState("");

  React.useEffect(() => {
    authPersonService.getAll().then((x) => {
      setAuthPersonList(x);
    });
  }, []);

  const initialValues = {
    gold: {
      // ornaments: [],
      gross_weight: "",
      net_weight: "",
      // total_items: "",
    },
    loan_account: "",
    disburse_amount: "",
    approved: false,
    maker: user._id,
    bank: "Federal",
    disburse_date: new Date(),
  };
  const [letterNumber, setLetterNumber] = React.useState();
  useEffect(() => {
    async function getTotal() {
      await releaseServices.countLetter()
        .then((res) => {
          setLetterNumber(res)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    getTotal()
  })

  const decimalRoundOff = (num) => {
    return Math.round(num * 100) / 100;
  };

  const getLoanDetails = async (id) => {
    const bank_details = await misService.getBankAccount(id);
    setBank(bank_details);
  };

  const handleOpen = async () => {
    if (loan.bank_details) {
      await getLoanDetails(loan.bank_details);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const letter_number = `KFPL/GL/${bank && bank.bank ? (bank.bank === "Federal" && "FED" || bank.bank) : null}/${current_date.toLocaleString("default", { month: "long" })}/${letterNumber + 1}`;
  const releasePrint = () => {
    props.history.push("/home/release", {
      ...loan,
      ...bank,
      letter_number,
      authPerson,
      // letterNumber,
      releaseAmount,
      packetNumber,
      current_date
    });
  };
  return (
    <div>
      <Button
        disabled={loan.bank_details ? false : true}
        onClick={handleOpen}
        color="primary"
        compact="true"
      >
        Release Letter
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Release Letter Details</h2>
            {
              <div>
                <div className="row justify-content-around">
                  <div>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Auth Person
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={authPerson}
                      onChange={(e) => setAuthPerson(e.target.value)}
                      label="Auth Person"
                    >
                      {authPersonList.map((item) => (
                        <MenuItem value={item.name}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </div>
                  {/* <div>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Letter Number
                    </InputLabel>
                    <TextField
                      value={letterNumber}
                      onChange={(e) => setLetterNumber(e.target.value)}
                      id="outlined-number"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div> */}
                  <div>
                    <TextField
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={releaseAmount}
                      type="number"
                      onChange={(e) => setReleaseAmount(e.target.value)}
                      label="Release Amount"
                    ></TextField>
                  </div>
                  <div>
                    <TextField
                      value={packetNumber}
                      onChange={(e) => setPacketNumber(e.target.value)}
                      id="outlined-number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Packet Number"
                    />
                  </div>
                </div>
                <br />
                {/* <div className="row justify-content-around">
                  <div>
                    <TextField
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={releaseAmount}
                      type="number"
                      onChange={(e) => setReleaseAmount(e.target.value)}
                      label="Release Amount"
                    ></TextField>
                  </div>
                  <div>
                    <TextField
                      value={packetNumber}
                      onChange={(e) => setPacketNumber(e.target.value)}
                      id="outlined-number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Packet Number"
                    />
                  </div>
                </div> */}
                <br />
                <br />
                <Button
                  disabled={authPerson === ""}
                  onClick={() => releasePrint()}
                  variant="contained"
                  color="primary"
                >
                  Open Release Letter
                </Button>
              </div>
            }
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
