import React, { useState } from 'react';
import { Modal, Backdrop, Fade, TextField, Button, Typography, List, ListItem, IconButton } from '@material-ui/core';
import { Search } from '@material-ui/icons';

function EmployeeSelectModal ({ allEmployees, onSelectEmployee }) {
  console.log('allEmployees, onSelectEmployee',allEmployees, onSelectEmployee)
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredEmployees, setFilteredEmployees] = useState(allEmployees);
  const [modalOpen, setModalOpen] = useState(false);

  const handleSearchChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    // Filter employees based on search term
    const filtered = allEmployees.filter(employee =>
      employee.first_name.toLowerCase().includes(term.toLowerCase()) ||
      employee.emp_id.toString().includes(term.toLowerCase())  // Convert emp_id (number) to string
    );
    
    
    setFilteredEmployees(filtered);
  };

  const handleSelectEmployee = (employee) => {
    onSelectEmployee(employee);
    handleCloseModal();
  };
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  return (
    <>
    <Button
    variant="contained"
    color="primary"
    onClick={handleOpenModal}
  >
    Select Employee
  </Button>
    <Modal
      open={modalOpen}
      onClose={handleCloseModal}
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={modalOpen}>
        <div style={{ padding: '20px', backgroundColor: '#F2F0EF', margin: '20px auto', width: '50%',overflow:'scroll',    height: '500px' }}>
          <Typography variant="h6">Select Employee</Typography>
          <TextField
            label="Search Employee"
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: <Search />
            }}
          />
          <List>
            {filteredEmployees.length > 0 ? (
              filteredEmployees.map(employee => (
                <ListItem key={employee.id}>
                  <Typography variant="body1" style={{ flexGrow: 1 }}>
                {employee.emp_id} -  {employee.first_name} 
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSelectEmployee(employee)}
                  >
                    Select
                  </Button>
                </ListItem>
              ))
            ) : (
              <Typography>No employees found</Typography>
            )}
          </List>
        </div>
      </Fade>
    </Modal>
    </>
  );
};
export default EmployeeSelectModal;