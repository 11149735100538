import React from 'react'
import MobileHistory from './MobileHistory'
import { Route, Switch } from "react-router-dom";


const Index = ({match}) => {

    const {path} = match;

  return (
    <>
      <Switch>
          <Route path={`${path}/mobilehistory`} component={MobileHistory}/>
      </Switch>
    </>
  )
}

export default Index