import React from 'react'
import NewEditCategory from "./NewEditCategory"
import CategoryDetails from "./CategoryDetails"

function AllCategories(){
    return (
        <>
         <NewEditCategory/>
            <h3>All Categories are</h3>
            <CategoryDetails/>
        </>
    )
}

export default AllCategories;