import React from 'react';
import { useState } from 'react';
import { Modal, Box, Button, Paper, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import Save from '@material-ui/icons/Save';
import datehelper from '../../../_helpers/datehelper';
import CourtCaseServices from '../../../_services/courtcase.service';


export const ArbitrationMarkDoneModal = (props) => {
    const dataFill = props
    const court_case = props.court_case
    let ndate = new Date()
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const formik = useFormik({
        initialValues:{
            stageDetails:{...dataFill.stageDetails}
        },
        onSubmit
    })

    async function onSubmit() {
        let next_stage = court_case.next_stage.filter(h => { return h._id !== dataFill.next_stage_id })
        let stages = [...court_case.stages, formik.values.stageDetails]
        await CourtCaseServices.UpdateCase(dataFill.court_case._id, { stages: stages, next_stage: next_stage })
            .then(res => {
                window.alert('Details saved successfully')
                window.location.reload()
            })
            .catch(err => {
                window.alert('Some error occurred')
                console.log(err)
            })
    }
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "800px",
        height: "300px",
        bgcolor: "background.paper",
        border: "2px solid #000",
        overflow: "scroll",
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Button onClick={handleOpen} style={{ color: 'blue' }} >Mark Done</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box id="modal-modal-title" variant="h6" component="h2" style={{ display: 'flex', flexDirection: 'column', alignItem: 'center' }}>
                        <Paper>
                            <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <h4 style={{ margin: 'auto', marginTop: '10px', marginBottom: '10px' }}>Details</h4>
                            </Box>
                        </Paper>
                        <form onSubmit={(e) => { e.stopPropagation(); formik.handleSubmit(e) }} >
                            <Paper style={{ marginTop: '20px' }}>
                                <Typography style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                                    <label style={{ width: '200px', display: 'flex', flexDirection: 'column' }}>
                                        Stage
                                        <select name="stageDetails.stage" style={{ border: '.25px solid grey', height: '28px' }} onChange={formik.handleChange} defaultValue={formik.values.stageDetails.stage} disabled >
                                            <option value="LRN Notice">LRN Notice</option>
                                            <option value="Arbitrator Appoint">Arbitrator Appoint</option>
                                            <option value="Claim & Interim Application">Claim & Interim Application</option>
                                            <option value="1st Notice">1st Notice</option>
                                            <option value="2nd Notice">2nd Notice</option>
                                            <option value="Evidance">Evidance</option>
                                            <option value="Argument">Argument</option>
                                            <option value="Award">Award</option>
                                            <option value="Execution">Execution</option>
                                        </select>
                                    </label>

                                    <label style={{ width: '200px', display: 'flex', flexDirection: 'column' }}>
                                        Date
                                        <input type="date" name='stageDetails.date' style={{ border: '.25px solid grey' }} max={datehelper.getDateInputString(ndate)} onChange={formik.handleChange} defaultValue={datehelper.getDateInputString(new Date(formik.values.stageDetails.date))} required disabled />
                                    </label>

                                    {formik.values.stageDetails &&formik.values.stageDetails.stage && formik.values.stageDetails.stage === "Arbitrator Appoint" &&
                                        <label style={{ width: '200px', display: 'flex', flexDirection: 'column' }}>
                                            Name
                                            <input type="text" name='stageDetails.name' style={{ border: '.25px solid grey' }} onChange={formik.handleChange} defaultValue={formik.values.stageDetails.name} required />
                                        </label>}

                                    {formik.values.stageDetails && formik.values.stageDetails.stage && formik.values.stageDetails.stage === 'Claim & Interim Application' &&
                                        <label style={{ width: '200px', display: 'flex', flexDirection: 'column' }}>
                                            claim
                                            <input type="number" name='stageDetails.claim' style={{ border: '.25px solid grey' }} defaultValue={formik.values.stageDetails.claim} onChange={formik.handleChange} required />
                                        </label>}
                                    {formik.values.stageDetails &&formik.values.stageDetails.stage && (formik.values.stageDetails.stage !== 'Claim & Interim Application' && formik.values.stageDetails.stage !== "LRN Notice" && formik.values.stageDetails.stage !== "Arbitrator Appoint") &&
                                        <label style={{ width: '200px', display: 'flex', flexDirection: 'column' }}>
                                            Comment
                                            <input type="text" name='stageDetails.comment' style={{ border: '.25px solid grey' }} defaultValue={formik.values.stageDetails.comment} onChange={formik.handleChange} required />
                                        </label>}
                                </Typography>
                            </Paper>
                            <hr style={{width:'100%'}} />
                            <Paper style={{ width: '63px', height: '63px', margin: 'auto', marginTop: '10px', paddingTop: '12.5px', borderRadius: '70px' }}>
                                <Button type='submit' style={{ color: 'blue' }}>
                                    <Save />
                                </Button>
                            </Paper>
                        </form>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}