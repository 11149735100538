import React from 'react'
import { letter } from "../../../_services/lettertracking.service";
import { useState, useEffect } from 'react';
import { Button } from "@material-ui/core";
import { Add, Edit } from "@material-ui/icons";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Link } from "react-router-dom";


const ShowLrn = () => {

  const [lrn, setLrn] = useState(null)
  const getLetter = async () => {
    const data = await letter.getLrn()
    setLrn(data)
  }

  useEffect(() => {
    getLetter()

  }, [])

  return (

    <>
      <h2>Legal LRN Letters</h2><br />
      <Button style={{ marginLeft: "30px" }} variant="outlined" aria-label="increase" href={`/home/letters/createlegalletter`}>
        <Add fontSize="small" />  Generate Legal Letter
      </Button>
      <hr></hr><br />
      <div>
        <Table className="table table-striped">
          <Thead>
            <Tr>
              <Th style={{ width: "20%" }}>Name</Th>
              <Th style={{ width: "20%" }}>Account Number</Th>
              <Th style={{ width: "20%" }}>Sanction Amount</Th>

              <Th style={{ width: "30%" }}>Action</Th>
            </Tr>
          </Thead>
        </Table>
        {lrn && lrn.reverse().map((element) => {
          return <div key={element.account_number}>

<Table className="table table-hover">
          <Tbody>
           <Tr>
             <Td className="table-light" style={{width: "20%"}}>{element.head} {element.name}</Td>
             <Td className="table-light" style={{width: "20%"}}>{element.account_number}</Td>
             <Td className='table-light' style={{width: "20%"}}>{element.sanction_amount}</Td>

             <Td className="table-light" style={{width: "30%"}}><Link to={`/home/letters/lrnLetterPrint/${element._id}`}><button className='btn btn-success' >Print Letter</button></Link> <Link to={`/home/letters/edit/${element._id}`} className="btn btn-sm btn-primary mr-1"><Edit /> </Link></Td>
       
            </Tr>
          </Tbody>
        </Table>
        
          </div>
        })}

      </div>


    </>
  )
}

export default ShowLrn