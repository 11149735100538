import { fetchWrapper } from "../_helpers/fetch-wrapper";

const apiUrl = process.env.REACT_APP_API_URL;

const baseUrl = `${apiUrl}/voiceschedule`;


function getAll() {
    return fetchWrapper.get(baseUrl)
}

function create(params){
    return fetchWrapper.post(`${baseUrl}/adddetails`, params)
}

function update(params){
    return fetchWrapper.post(`${baseUrl}/updatedetails`, params)
}


export const voiceSchedule = {
    getAll,
    create,
    update,
  };