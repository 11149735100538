export const RejectSubStages= {
    KycFraud: "KycFraud",
    DocumentNotAvailable: "DocumentNotAvailable",
    PropertyPaperFraud:"PropertyPaperFraud",
    CustomerNotInterested:"CustomerNotInterested",
    CaseNotSuitable:"CaseNotSuitable",
    DuplicateFile:"DuplicateFile",
    LoginFeesNotReceived:"LoginFeesNotReceived",
    DemoLoan:"DemoLoan",
    Other:"Other",
  };
  