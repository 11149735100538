import { fetchWrapper } from "../_helpers";
const apiUrl = process.env.REACT_APP_API_URL;
const baseUrl = `${apiUrl}/courtcases`


const CourtCaseServices = {
    AddCase,
    GetAllCase,
    UpdateCase,
    getupcomingNiCases,
    getupcomingArbitrationCases,
    getUpcomingHearings
}

function AddCase(fields){
  return fetchWrapper.post(`${baseUrl}/add`, fields)
}

function GetAllCase(fields){
  return fetchWrapper.post(`${baseUrl}/get`, fields)
}

function UpdateCase(id,params){
  return fetchWrapper.put(`${baseUrl}/update/${id}`, params)
}
 function getupcomingNiCases(){
  return fetchWrapper.get(`${baseUrl}/getupcomingnicases`)
 }
 function getupcomingArbitrationCases(){
  return fetchWrapper.get(`${baseUrl}/getupcomingarbitrationcases`)
 }
 function getUpcomingHearings(){
  return fetchWrapper.get(`${baseUrl}/getupcominghearings`)
 }
export default CourtCaseServices;