import React from "react";
import { useReactToPrint } from "react-to-print";
import { virtualAccountService } from "../../../_services/virtualaccount.service";

export default function VADetailsPrint(props) {
  const hoRef = React.useRef();
  const va_details=props.location.state;
  const [qrcodeUrl,setQrcodeUrl]=React.useState(null);
  const handlePrint = useReactToPrint({
    content: () => hoRef.current,
  });
  React.useEffect(()=>{
    if(va_details.qrcode_url)
    {
      setQrcodeUrl(va_details.qrcode_url);
    }
    else if(!qrcodeUrl)
    {
      let fields={'va_num':va_details.va_num}
      virtualAccountService.saveQRCode(fields).then((url)=> {
        console.log(url);
        setQrcodeUrl(url);
      })
      .catch((err)=> {
        console.log(err);
      })
    }
  },[qrcodeUrl])


  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <button onClick={handlePrint} style={{ marginLeft: 10 }}>
          Print Customer Copy
        </button>
      </div>
      <div
        ref={hoRef}
        owner="Customer Copy"
        style={{paddingLeft:'50px',paddingTop:'50px'}}
      >
          <p><b>Account Payee:</b> Kaabil Finance Private Ltd</p>
          <p><b>Bank Name:</b> ICICI Bank</p>
          <p><b>Account Number:</b> {va_details.va_num}</p>
          <p><b>IFSC Code:</b> ICIC0000106</p>
          {
            (qrcodeUrl&& (
              <div><img src={qrcodeUrl}></img></div>
            ))
          }
      </div>
    </div>
  );
}
