import { fetchWrapper } from "../_helpers/fetch-wrapper";
const apiUrl = process.env.REACT_APP_API_URL;
const baseUrl = `${apiUrl}/allcloud_data`;
export const allcloudService = {
    allCloudProcessingRequest,
    requestAllCloud,
    verifyAllCloudloan,
    allCloudPreview,
    saveRepaymentInAllCloud,
    allCloudLedgerCreate,
    getAllCloudLedgers,
    getAllCloudFinanceId
}


async function allCloudPreview(params){
    console.log("params data is",params)
    return fetchWrapper.post(`${baseUrl}/allcloudpreview`, params);
}
async function allCloudProcessingRequest(params){
    console.log("params data is",params)
    return fetchWrapper.post(`${baseUrl}/allcloudprocessingrequesting`, params);
}
async function requestAllCloud(application_id){
    console.log("params data is",application_id)
    return fetchWrapper.post(`${baseUrl}/allcloudrequest/${application_id}`);
}
async function verifyAllCloudloan(application_id){
    console.log("params data is",application_id)
    return fetchWrapper.post(`${baseUrl}/verifyallcloudloan/${application_id}`);
}

async function saveRepaymentInAllCloud(params){
    console.log("params is ",params);
    return fetchWrapper.post(`${baseUrl}/saverepaymentinallcloud`,params)
}

async function allCloudLedgerCreate(params){
    console.log("params is ",params);
    return fetchWrapper.post(`${baseUrl}/allcloudledger`,params)
}
async function getAllCloudLedgers( ){
     return fetchWrapper.get(`${baseUrl}/getallcloudledgers`)
}
async function getAllCloudFinanceId( params){
    return fetchWrapper.post(`${baseUrl}/getallcloudfinanceid`,params)
}
