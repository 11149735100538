import React from 'react'

const MobileHistory = () => {

    const handleChange = () => {

    }

  return (
    <>
      <h1>Check Mobile Number Details</h1>
      
      <div style={{fontSize: "large", display: "flex", flexDirection: "column", margin: "auto", marginTop: "200px", paddingBottom: "30px", marginLeft: "270px", border: "3px solid grey", maxWidth: "500px", maxHeight: "400px"}}>
      <div className='my-3' style={{marginLeft: "30px"}}>
          
          <input style={{marginLeft: "95px"}} type='text' placeholder='Enter the Mobile Number' onChange={handleChange} />
      </div>
     
     <div className='my-3' style={{marginLeft: "30px"}}>
         
         <input style={{marginLeft: "95px"}} type='text' placeholder='Enter the Name' onChange={handleChange}/>
     </div>

     <div>
         <button style={{marginLeft: "190px"}} className='btn btn-primary'>Search</button>
     </div>
     </div>

    </>
  )
}

export default MobileHistory