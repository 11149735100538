import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button } from "@material-ui/core";
import "../../css/common.css"
import { loanService } from "../../_services";
import datehelper from "../../_helpers/datehelper";

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "75%",
    overflow: "scroll",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    minWidth: 700,
  },
}));

export default function RepaymentScheduleModal(props) {
  const colender_data= props.colender_data;
  const loan_id=props.loan_id;
  const customer = props.customer;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [emiList,setEmiList] = React.useState([]);
  const [preEmiData,setPreEmiData] = React.useState(null);
   
  var emi_table = [];

  React.useEffect(() => {
    if(open){
      fetchData();
   }
  },[open])

  const fetchData = async () => {
     
    loanService.getUpdateColenderRepaymentSchedule(loan_id,colender_data.co_lender_name).
    then((el)=> {
    
      console.log(el);
      if(el && el.repayment_schedule)
      {
        setEmiList(el.repayment_schedule);
      }
      if(el && el.pre_emi_data)
      {
        setPreEmiData(el.pre_emi_data);
      }
      
    })
    .catch((err)=> {
      console.log(err);
    })
  }

  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const generateRepaymentSchedule= async (loanNumber)=> {
     console.log("generating repayment schedule for "+loanNumber);
     loanService.generateColenderRepaymentSchedule(loanNumber).then((res)=> {
        fetchData();
     })
     .catch((err)=> {
       window.alert("Error generating schedule");
     })
  }

  return (
    <div>
       
     <Button
        onClick={handleOpen}
        variant="outlined"
        color="primary"
      >
        Repayment Schedule
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
        <div className={classes.paper}>
          {preEmiData && (
            <div>
              <b>Pre Emi Days</b>:{preEmiData.pre_emi_days} days <br/>
              <b>Pre Emi Amount</b>:Rs. {preEmiData.pre_emi_amount}    
            </div>
          )}
      <table class="btable stretch">
              <tr>
                <th>Payment Number</th>
                <th>Date</th>
                <th>Start Balance</th>
                <th>Payment Amount</th>
                <th>Capital Paid</th>
                <th>Interest Paid</th>
                <th>Remaining Balance</th>
              </tr>
              {emiList && emiList.map(
                (item, idx) => (
                    <tr>
                      <td style={{ width: "10%" }}>{idx + 1}</td>{" "}
                      <td>
                        {datehelper.displayDate(new Date(item.due_date))}
                      </td>
                      <td>{Math.round(item.start_balance)}</td>
                      <td>{Math.round(item.payment_amount)}</td>
                      <td>{Math.round(item.capital_paid)}</td>
                      <td>{Math.round(item.interest_paid)}</td>
                      <td>{Math.round(item.balance_remaining)}</td>
                    </tr>
                  )
              )}
            </table>
            <Button
                     variant="contained"
                     color="primary"
                     onClick={()=> generateRepaymentSchedule(customer.application_id)}>Re-Generate Schedule</Button>
        
            </div>
            </Fade>
            </Modal>
    </div>
  );
}

