import React, { useState, useEffect } from "react";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { history } from "../../_helpers";
import { Edit, Delete, Add, Print } from "@material-ui/icons";
import { customerService } from "../../_services";
import SearchIcon from "@material-ui/icons/Search";
import { employeeService } from "../../_services/employee.service.js";
import { BankMenuModal } from "./BankMenuModal";
import Product from "../../_helpers/product";
import roleChecker from "../../_helpers/rolechecker";
import { ledgerService } from "../../_services/ledger.service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreateCustomerModal from "./CreateCustomerModal";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  height: "70vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function CustomerAccountList(props) {
  const user = employeeService.userValue;
  
  const { path } = props.match;
  const [customers, setCustomers] = useState([]);
  const [value, setValue] = useState({});
  const [field, setField] = useState({});
  const [open, setOpen] = React.useState();
  const [ledegerCurrentBalance,setLedgerCurrentBalance]=useState()
  const getLedgerCurrectBalance=async()=>{
    await ledgerService.getLedgerCurrectBalance()
    .then((res)=>setLedgerCurrentBalance(res))
    .catch((err)=>console.log(err))
  } 
  const notify = () => toast.warning(` You can't create new loan,because your ledger balance is negative:${ledegerCurrentBalance && ledegerCurrentBalance.current_balance.toLocaleString("en-IN", { style: 'currency', currency: 'INR' })}`,
  {  position: toast.POSITION.TOP_RIGHT,
    style: {
    background: 'red',
    color: '#fff',
    fontSize: '20px',
    width: '30vw',
    padding: '20px 30px',
  }});
  const handleOpen = (customer) => {
    setOpen(true);
    setValue(customer)

  };
  const handleClose = () => {
    setOpen(false);
    setValue({});
  };

  useEffect(() => {
    async function getCustomers() {
      await customerService.getAll().then((x) => {
        // console.log(x);
        setCustomers(x);
      });
    }
    getCustomers();
    getLedgerCurrectBalance();
  }, []);

  const handleChange = (fieldKey, fieldValue) => {
    field[fieldKey] = fieldValue;
    setField(field);
  };
  const handleSubmit = async () => {
    field.adhaar_no.length === 12
      ? await customerService
        .searchCustomer(field)
        .then((res) => setCustomers(res))
      : alert("Adhaar number must be of 12 digit");
  };
  const SearchByMobileNoOrFirstName = async () => {
    await customerService
      .searchCustomer(field)
      .then((res) => setCustomers(res))
      .catch((err) => console.log(err))
  };

  return (
    <div>
      {/* <h1>
        Customers &nbsp;
        <Button variant="outlined" aria-label="increase" href={`${path}/add`}>
          <Add fontSize="small" />
        </Button>
      </h1> */}
      {console.log(user)}
    <CreateCustomerModal customer={value}/>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div>
              <div style={{ display: "flex" }}>
                Loan List
               {(employeeService.productValue==Product.GoldLoan && roleChecker.isBMLevelAccess(user.role)) && 
                <button
                  style={{
                    display: "flex",
                    border: "1px solid grey",
                    width: "50px",
                    borderRadius: "8px",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                  onClick={() =>(ledegerCurrentBalance && ledegerCurrentBalance.current_balance>0)?history.push(`/home/customers/fedloan/${value._id}`, value)
                  :notify()}
                >
                  <AddOutlinedIcon />
                </button>}
                <ToastContainer />
                {/* {(employeeService.productValue==Product.GoldLoan && roleChecker.isBMLevelAccess(user.role)) && (<BankMenuModal value={value} />)} */}

              </div>
              <hr />
              <Table className="table table-striped">
                <Thead>
                  <Tr>
                    <Th style={{ width: "30%" }}>Loan Number</Th>
                    <Th style={{ width: "20%" }}>Loan Type</Th>
                    <Th style={{ width: "30%" }}>Loan Relation</Th>
                    <Th style={{ width: "20%" }}></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {value && value.linked_loan && value.linked_loan.length > 0 && value.linked_loan.map((loan, idx) => {
                    return <Tr key={idx}>
                      <Td>{loan.loan_account}</Td>
                      <Td>{loan.loan_type}</Td>
                      <Td>{!loan.loan_relation?"Applicant":loan.loan_relation}</Td>
                      <Td>
                        {loan.loan_type=="Gold" && (
                         <button style={{ borderRadius: '8px', border: 'none', color: 'white', backgroundColor: 'blue' }} onClick={() => props.history.push(`/home/goldloans?loan_account=` + loan.loan_account)}>More</button>
                        )}
                        {loan.loan_type=="SBL" && (
                         <button style={{ borderRadius: '8px', border: 'none', color: 'white', backgroundColor: 'blue' }} onClick={() => props.history.push(`/home/sblloans/searchloans?loan_account=` + loan.loan_account)}>More</button>
                        )}

                        </Td>
                    </Tr>
                  })
                  }
                </Tbody>
              </Table>

            </div>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}></Typography>
        </Box>
      </Modal>
      <div 
      style={{
        float:"left",
        marginTop: "70px",
        marginBottom: "50px",
      }}>
         <input
          type="text"
          placeholder="Search by CIF Number"
          onChange={(e) => handleChange("customer_id", e.target.value)}
          style={{
            border: "1px solid grey",
            borderRadius: "5px",
            height: "30px",
          }}
        />
        <button
          onClick={SearchByMobileNoOrFirstName}
          style={{
            backgroundColor: "#0066cc",
            color: "white",
            border: "none",
            width: "80px",
            height: "30px",
            borderRadius: "5px",
            marginRight:'40px',
            marginLeft:'10px'
          }}
        >
          <SearchIcon />
        </button>
      {/* </div> */}
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "60px",
          marginBottom: "40px",
        }}
      > */}
        {/* Adhaar no.{" "} */}
        <input
          type="text"
          placeholder="Search by Govt ID"
          onChange={(e) => handleChange("adhaar_no", e.target.value)}
          style={{
            border: "1px solid grey",
            borderRadius: "5px",
            height: "30px",
          }}
        />
        <button
          onClick={handleSubmit}
          style={{
            backgroundColor: "#0066cc",
            color: "white",
            border: "none",
            width: "80px",
            height: "30px",
            borderRadius: "5px",
            marginLeft:'10px'
          }}
        >
          <SearchIcon />
        </button>
      </div>
      <div
        style={{
          float:"right",
          marginTop: "70px",
          marginBottom: "50px",
          marginRight: "40px"
        }}
      >
        <input
          type="text"
          placeholder="Search by First name "
          onChange={(e) => handleChange("customer_firstname", e.target.value)}
          style={{
            border: "1px solid grey",
            borderRadius: "5px",
            height: "30px",
          }}
        />
        <input
          type="text"
          placeholder="Search by Mobile"
          onChange={(e) => handleChange("customer_mobile_num", e.target.value)}
          style={{
            border: "1px solid grey",
            borderRadius: "5px",
            height: "30px",
            marginLeft: "30px",
            marginRight: "10px",
          }}
        />
        <button
          onClick={SearchByMobileNoOrFirstName}
          style={{
            backgroundColor: "#0066cc",
            color: "white",
            border: "none",
            width: "80px",
            height: "30px",
            borderRadius: "5px",
          }}
        >
          Search
        </button>
      </div>
      {customers.length !== 0 || field.customer_firstname || field.customer_mobile_num || field.customer_id? (
        <Table className="table table-striped">
          <Thead>
            <Tr>
              <Th style={{ width: "25%" }}>CIF</Th>
              <Th style={{ width: "20%" }}>Customer Name</Th>
              {/* <Th style={{ width: "20%" }}>Aadhaar Number</Th> */}
              <Th style={{ width: "15%" }}>Loan List</Th>
              <Th style={{ width: "10%" }}>Action</Th>
            </Tr>
          </Thead>
          {customers.length !== 0 ?
            <Tbody>
              {customers.filter(customer =>
                (customer.linked_loan.length == 0 || employeeService.productValue === "sbl" && customer.linked_loan.some(loan => loan.loan_type === "SBL")) ||
                (customer.linked_loan.length == 0 || employeeService.productValue === "goldloans" && customer.linked_loan.some(loan => loan.loan_type === "Gold"))
              ).map((customer) => (
                <Tr key={customer.customer_id}>
                  <Td>{customer.customer_id}</Td>
                  <Td>{`${customer.general_details.customer_firstname} ${customer.general_details.customer_middlename} ${customer.general_details.customer_lastname}`}</Td>
                  {/* <Td>{customer.verification_details.aadhaar_no}</Td> */}
                  <Td>
                    {" "}
                    {
                      <button
                        type="button"
                        style={{
                          border: "none",
                          color: "blue",
                          background: "none",
                        }}
                        onClick={() => {
                          handleOpen(customer);
                        }}
                      >
                        Loan list
                      </button>
                    }
                  </Td>
                  <Td>
                    {/* <Link
                      to={`${path}/edit/${customer._id}`}
                      style={{ color: "blue" }}
                    >
                      Edit
                    </Link> */}
                  </Td>
                </Tr>
              ))}
            </Tbody> : 
            <Tr>
              <Td></Td>
              <Td style={{color:"red"}}>*Customer not found</Td>
              <Td><Button
              style={{
                margin: "auto",
                backgroundColor: "orange",
                color: "black",
                border: "none",
                width: "270px",
                height: "30px",
                marginTop: "40px",
                borderRadius: "5px",
              }}
              href={`${path}/add`}
            >
              Add New Customer
            </Button></Td>
              <Td></Td>
            </Tr>
          }
        </Table>
      ) : (
        <div style={{ display: "flex", flexDirection: "column"}}>
           <hr style={{width:"100%",color:"solid black"}}></hr>
          <span style={{ color: "red", textAlign: "center" }}>*Customer not found</span>{" "}
            <Button
              style={{
                margin: "auto",
                backgroundColor: "orange",
                color: "black",
                border: "none",
                width: "270px",
                height: "30px",
                marginTop: "40px",
                borderRadius: "5px",
              }}
              href={`${path}/add`}
            >
              Add New Customer
            </Button>
          
        </div>
      )}
      {console.log(customers)}
    </div>
  );
}

export { CustomerAccountList };

