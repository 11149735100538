import { fetchWrapper } from "../_helpers";

const apiUrl = process.env.REACT_APP_API_URL;

const baseUrl = `${apiUrl}/mis`;

export const misService = {
  increaseLoanNo,
  getLoan,
  getLoanByAccount,
  sendOtp,
  approvebank,
  getCkyc,
  addBankAccount,
  addICICIAccount,
  getBankAccount,
  getBankList,
  getLoanNo,
  approveloan,
  updateLoanStatus,
  closeLoan,
  updatePaymentDetails,
  updateColenderPayments,
  getAll,
  getAllFilter,
  update,
  uploadColendingPaymentsCsv,
  create,
  getExcel,
  getAllPayments,
  delete: _delete,
  saveDetails,
  renewBankDetails,
  addPaymentDetails,
  getBankPaymentDetails,
  addDisbursementDetails,
  getColendingDetails,
  uploadColendingCsv,
  getBranchSummary,
  auctionLoan,
  saveDailyReportData,
  LoanCloseRequest,
  approveAndRejectRequest,
  getAllCloserRequestedLoans,
  getOnlineAndCashPayments,
  getDailyReportCount,
  lockLoan,
  unlockLoan,
  addPaymentsDetails,
  generateReleaseLetter,
  getBySearch,
  findLoanByRegex,
  renewLoanDetails,
  getBankAndCustomerDetails,
  getLoanById,
  searchByloanOrMobileNo,
  getLoanByColendingLoanNo,
  updatePersonalDetails,
  getAllRenewLoanRequest,
  approveRenewRequest
};
function findLoanByRegex(params){
  return fetchWrapper.post(`${baseUrl}/searchbyregex`, params)
}

function getBySearch(params) {
  console.log('service',params)
  return fetchWrapper.post(`${baseUrl}/getbysearch`, params)
}
function update(loanId) {
  return fetchWrapper.put(`${baseUrl}/loan/${loanId}`);
}

function getLoan(loanId) {
  console.log("getting loan");
  return fetchWrapper.get(`${baseUrl}/loan/${loanId}`);
}

function getLoanByAccount(loanAccount) {
  //console.log("getting loan by account", loanAccount);
  return fetchWrapper.get(`${baseUrl}/loanbyaccount/${loanAccount}`);
}
 
function increaseLoanNo(code) {
  return fetchWrapper.put(`${baseUrl}/loanno/increase/${code}`);
}

function sendOtp(otp, number) {
  return fetchWrapper.get(
    `http://bulksms365.com/api/mt/SendSMS?user=kaabil&password=123123&senderid=KFPLGL&channel=Trans&DCS=0&flashsms=0&number=${number}&text=${otp}%20is%20OTP%20for%20releasing%20the%20gold%20packet%20from%20ICICI%20Bank.%20Do%20not%20share%20this%20OTP%20with%20anyone.%20Kaabil%20Finance`
  );
}

function getBankList(bankName) {
  return fetchWrapper.get(`${baseUrl}/bank/${bankName}`).then((x) => {
    return x;
  });
}

function addBankAccount(id, params) {
  return fetchWrapper.put(`${baseUrl}/addbank/${id}`, params).then((x) => {
    return x;
  });
}
function auctionLoan(id, params) {
  return fetchWrapper.put(`${baseUrl}/auctionloan/${id}`, params) 
}

function updateLoanStatus(id, params) {
  return fetchWrapper.put(`${baseUrl}/updateloanstatus/${id}`, params).then((x) => {
    return x;
  });
}

function closeLoan(id, params) {
  return fetchWrapper.put(`${baseUrl}/closeloan/${id}`, params).then((x) => {
    return x;
  });
}


async function lockLoan(id, params) {
  return fetchWrapper.put(`${baseUrl}/lockloan/${id}`, params).then((x) => {
    return x;
  });
}


async function unlockLoan(id, params) {
  return fetchWrapper.put(`${baseUrl}/unlockloan/${id}`, params).then((x) => {
    return x;
  });
}


function updatePaymentDetails(id, params) {
  return fetchWrapper.put(`${baseUrl}/updatepaymentdetails/${id}`, params).then((x) => {
    return x;
  });
}
function addPaymentsDetails(id,params){
  return fetchWrapper.put(`${baseUrl}/addpaymentsdetails/${id}`,params)
}
function updateColenderPayments(id, params) {
  // console.log(params)
  return fetchWrapper.put(`${baseUrl}/updatecolenderpayments/${id}`, params).then((x) => {
    return x;
  });
}

function addICICIAccount(params) {
  return fetchWrapper.put(`${baseUrl}/addICICI`, params).then((x) => {
    return x;
  });
}

function getBankAccount(id) {
  return fetchWrapper.get(`${baseUrl}/getbank/${id}`).then((x) => {
    return x;
  });
}

function getCkyc(params) {
  return fetchWrapper.post(`${baseUrl}/generateCkyc`, params).then((x) => {
    return x;
  });
}

function getLoanNo(code) {
  return fetchWrapper.get(`${baseUrl}/loanno/${code}`).then((x) => {
    return x;
  });
}

function approveloan(id) {
  return fetchWrapper.get(`${baseUrl}/approve/${id}`).then((x) => {
    return x;
  });
}

function approvebank(id, checker) {
  return fetchWrapper
    .put(`${baseUrl}/approvebank/${id}`, { checker })
    .then((x) => {
      return x;
    });
}

function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/delete/${id}`).then((x) => {
    return x;
  });
}
function getAll() {
  return fetchWrapper.get(baseUrl);
}
function getColendingDetails() {
  return fetchWrapper.get(`${baseUrl}/colendingdetails`);
}

function getAllPayments(params) {
  console.log(params)
  return fetchWrapper.post(`${baseUrl}/loanpayments`, params).then((x) => {
    return x;
  });
}

function addPaymentDetails(params){
  console.log(params)
  return fetchWrapper.post(`${baseUrl}/addpayment`, params)
}

function getAllFilter(params) {
  return fetchWrapper.post(`${baseUrl}/filter`, params)
}

function create(params) {
  return fetchWrapper.post(baseUrl, params);
}

function uploadColendingPaymentsCsv(data) {
  for (var key of data.entries()) {
    console.log(key[0] + ', ' + key[1]);
  }  
  return fetch(`${baseUrl}/colendingpayments`,{
    method: "POST",
    body: data,
    headers:fetchWrapper.authHeader(`${baseUrl}/colendingpayments`),
  })
}

function saveDetails(params) {
  return fetchWrapper.post(`${baseUrl}`)
}

function getExcel(params) {
  return fetchWrapper.post(`${baseUrl}/excel`,params);
}

function renewBankDetails(id, params) {
  return fetchWrapper.post(`${baseUrl}/renewbankdetails/${id}`, params)
}

function getBankPaymentDetails() {
  return fetchWrapper.get(`${baseUrl}/getbankpaymentdetails`)
}

function addDisbursementDetails(params){
  return fetchWrapper.post(`${baseUrl}/adddisbursement`, params)
}

function uploadColendingCsv(data){
  return fetch(`${baseUrl}/colendingcsv`,{
    method:"POST",
    body:data,
    headers:fetchWrapper.authHeader(`${baseUrl}/colendingcsv`),
  })
}
 
function getBranchSummary(params){
  return fetchWrapper.post(`${baseUrl}/branchsummary/${params.branch}`, params)
}
 
// const saveDailyReportData = async (params) => {
  function saveDailyReportData(params){
  console.log("test service params",params)
  return fetchWrapper.post(`${baseUrl}/dailyreportgold`, params)
}

function LoanCloseRequest(params){
  return fetchWrapper.post(`${baseUrl}/closeloanrequest`,params)
}
 
function getOnlineAndCashPayments(loan_account){
  return fetchWrapper.get(`${baseUrl}/getonlineandcashpayments/${loan_account}`)
}
function getAllCloserRequestedLoans(){
  return fetchWrapper.get(`${baseUrl}/getallcloserrequestedloans`)
}
function  approveAndRejectRequest(id,params){
  return fetchWrapper.put(`${baseUrl}/approverequest/${id}`,params)
} 
function getDailyReportCount(){
  return fetchWrapper.get(`${baseUrl}/dailyreportcount`)
}
function generateReleaseLetter(loanAccount){
  return fetchWrapper.get(`${baseUrl}/generatereleaseletter/${loanAccount}`)
}
function renewLoanDetails(params){
  return fetchWrapper.post(`${baseUrl}/renewloandetails`,params)
}
function getBankAndCustomerDetails(id){
  return fetchWrapper.get(`${baseUrl}/getbankandcustomerdetails/${id}`)
}
function getLoanById(id){
 return fetchWrapper.get(`${baseUrl}/getloanbyid/${id}`)
}
function searchByloanOrMobileNo(params){
  return fetchWrapper.post(`${baseUrl}/searchbyloanormobileno`,params)
}
function getLoanByColendingLoanNo(params){
  return fetchWrapper.post(`${baseUrl}/getloanbycolendingloanno`,params)
}
function updatePersonalDetails(id,params){
 return fetchWrapper.post(`${baseUrl}/updatepersonaldetails/${id}`,params)
}
function getAllRenewLoanRequest(){
  return fetchWrapper.get(`${baseUrl}/getallrenewloanrequest`)
}
function approveRenewRequest(id){
  return fetchWrapper.post(`${baseUrl}/approverenewrequest/${id}`)
}