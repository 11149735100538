export const OperationSubStages= {
    FileReceived: "FileReceived",
    FileChecked: "FileChecked",
    PendencySentToBranch: "PendencySentToBranch",
    PendencyReceivedFromBranch: "PendencyReceivedFromBranch",
    NonBranchOtherPendency: "NonBranchOtherPendency",
    PendencyClear: "PendencyClear",
    ColendingPending: "ColendingPending",
    SentToOpsTeam:"SentToOpsTeam",
    PropertyPapersAtHO:"PropertyPapersAtHO",
  };
  