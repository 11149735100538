import React from "react";
import Modal from "@material-ui/core/Modal";
import { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Button } from "@material-ui/core";
import AddIcCallIcon from '@material-ui/icons/AddIcCall';

const AddCallDetails = () => {

    const [open, setOpen] = useState(false);
    const [item, setItem] = useState([])
    const [values, setValues] = useState({
      date:"",
      time:"",
      call_type:""
    })



    const style = {
        position: "absolute",
        top: "30%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 600,
        height: 500,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
        overflow: "scroll",
        //paddingBottom: '30px',
      };

      const addData = () => {
        setItem([...item, {contact: ""}]);
      }
      
      const removeData = (index) => {
        const list = [...item]
        list.splice(index, 1)
        setItem(list)
      }

      const handleChange = (e) =>{
        setValues({...values, [e.target.name]:e.target.value})
      }

      const handleArea = (e) =>{
         let {test} = {[e.target.name]:e.target.value}
         let count =  test.split(/\r?\n|\r|\n/g)

         console.log(count)
      }

      const handleItem = (e, index) => {
        const {name, value} = e.target
        const list =[...item]
        list[index][name] = value
        setItem(list)
      }


      const handleOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

      const handleSubmit=(e)=>{
        e.preventDefault();
        // console.log(data)
        // let contacts = [];
        // contacts = [...item]
        // voiceSchedule.create({contacts, ...values})
        // .then((data)=>{
        //   console.log(data)
        //   alert("Data added successfully")
        //   window.location.reload();
        // })
        // .catch((err)=>{
        //   alert("Please try again")
        //   console.log(err)
        // })
      }


  return (
    <div>
      <Button onClick={handleOpen}><AddIcCallIcon/></Button>
         <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h2 style={{textAlign:"center"}}>Add Details</h2><br/>
            <form
              style={{ display: "flex", flexDirection: "column" }}
               onSubmit={(e) => handleSubmit(e)}
            >
              <span style={{marginLeft:"30%"}}>
                <label style={{ width: "300px" }}><b>Select Date and Time of Call</b><input type={"date"} required name='date' onChange={(e)=>handleChange(e)}/> <input type={"time"} name='time' required onChange={(e)=>handleChange(e)}/></label>
                <label style={{ width: "300px" }}>
                      Call Type
                      <select
                        className="form-select form-select-sm"
                        name="call_type"
                        style={{ width: "270px", height: "38px" }}
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleChange(e)}
                        required
                      >
                        <option selected></option>
                        <option>5th day Emi Reminder</option>
                        <option>8th day Emi Reminder</option>
                        <option>General Emi Reminder</option>
                      </select>
                    </label>
              </span><br/>
              <label style={{width:"300px"}}>Enter the Contact Numbers</label>
              <textarea type="number" name= "test" onChange={(e) => handleArea(e)}/>
              <table>
              {item && item.map((item, index)=>(
                <tbody>
              <tr key={index}>
                <td style={{marginTop: "10px"}}><input type='number' pattern="[0-9]{10}" title="Must be 10 digits" name='contact' value={item.contact}  onChange={(e)=>handleItem(e, index)} /></td>
                <td> <button type='button' onClick={()=> removeData(index)} >Remove</button></td><br/>
              </tr>
              </tbody>
            ))} <br/>
            <button type="button" onClick={()=> addData()}>Add New</button>
              </table>
              <br/> 
              <button>Submit</button> 
            </form>
            </Typography>
            </Box>
            </Modal>
    </div>
  )
}

export default AddCallDetails