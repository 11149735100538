import React from 'react';
import QRCode from 'qrcode.react';

const InvoiceQRCode = ({ invoiceNumber, amount }) => {
  // Combine invoiceNumber and paymentId into a single string or use JSON format
  const qrValue = JSON.stringify({ invoiceNumber, amount });

  return (
    <div>
      <QRCode value={qrValue} size={200} bgColor="#ffffff" fgColor="#000000" />
    </div>
  );
};

export default InvoiceQRCode;
