import React, { useEffect, useState } from 'react'
import { Table, Th, Thead, Tr, Tbody, Td } from 'react-super-responsive-table'
import { pettyCashService } from '../../_services/pettycash.service'
import CreatePettyCashModal from './CreatePettyCashModal'
import datehelper from '../../_helpers/datehelper'
import { employeeService } from '../../_services'
import PettyCashApproveModal from './PettyCashApproveModal'
import roleChecker from '../../_helpers/rolechecker'
const PettyCashList = () => {
    const user = employeeService.userValue;
    const [pettyCashList, setPettyCashList] = useState([])
    const [employeeList, setEmployeeList] = useState({})
    const getPettyCashList = async () => {
        await pettyCashService.getPettycash()
            .then((res) => setPettyCashList(res))
            .catch((err) => console.log(err))
    }
    async function getAllEmployees() {
        await employeeService.getAll()
            .then((res) => {
                let list = {};
                res.map(e => {
                    list[e.emp_id] = e.first_name + ' ' + e.last_name
                })
                setEmployeeList(list)
            })
    }
    useEffect(() => {
        getPettyCashList()
        getAllEmployees()
    }, [])
    let pettyCashPendingList = pettyCashList.filter(x => x.status === "Pending")
    console.log("test pettyCashList ", pettyCashList)
    return (
        <div style={{ margin: '20px' }}>
            <h3 style={{ textAlign: 'center', marginTop: '50px' }}>Petty Cash Request</h3>
            {roleChecker.hasAccountsAccess(user.role)&& <CreatePettyCashModal />}
            {roleChecker.isAccountChecker(user.role) && <PettyCashApproveModal pettyCashPendingList={pettyCashPendingList} />}
            <Table>
                <Thead>
                    <Tr style={{ background: "#3251F5", color: 'white', height: '50px' }}>
                        <Th>&nbsp;Requested By</Th>
                        <Th>Paying To</Th>
                        <Th>Amount</Th>
                        <Th>Transaction Date</Th>
                        <Th>Status</Th>
                        <Th>Approved By</Th>
                        <Th>Remark</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {pettyCashList.length > 0 ? pettyCashList.map((x, idx) => <Tr key={idx} style={{ background: '#EFF2FF', height: '50px', fontSize: '18px' }}>
                        <Td>{employeeList[x.requested_by]}</Td>
                        <Td>{x.paying_to}</Td>
                        <Td>{x.amount.toLocaleString("en-IN", { style: 'currency', currency: 'INR' })}</Td>
                        <Td>{datehelper.displayDateTime(new Date(x.requested_date))}</Td>
                        <Td style={{ color: x.status === "Pending" ? "red" : "green" }}>{x.status}</Td>
                        <Td>{employeeList[x.approved_by]}</Td>
                        <Td>{x.remarks}</Td>
                    </Tr>) : <Tr><Td align="center" colSpan={7}>Data Not Found</Td></Tr>}
                </Tbody>
            </Table>
        </div>
    )
}

export default PettyCashList
