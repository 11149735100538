import { Hidden } from "@material-ui/core";
import React from "react";
import { useReactToPrint } from "react-to-print";
import kycstyle from "./KycPrint.css";
export default function KycPrint(props) {
   const customer=props.customer;
   const custRef = React.useRef();
   const handleKycPrint = useReactToPrint({
    content: () => custRef.current,
  });
   
  return (
    <div
    >
         <style type="text/css" media="print">
          {"\
   @page { size: A4; }\
"}
        </style>
      
      <div style={{ textAlign: "center" }}>
        <button onClick={handleKycPrint}>Print Kyc</button>
      </div>
      <div
      ref={custRef}
      className="printContent" style={{padding:"20px"}}>
         
      {customer.applicant && customer.applicant.kyc_images && (
        <div>
        <h3>KYC for Applicant - {customer.applicant.general_information.name}</h3>
        {customer.applicant.kyc_images.map((ki)=> {
           return (<div style={{margin:"20px"}}><img src={ki.uri} width="500" /></div>);   
        })}
        {customer.co_applicants && customer.co_applicants.length>0 && customer.co_applicants.map((ca)=> {
            return (
                <div>
                <h3>KYC for Co Applicant - {ca.general_information.name}</h3>
                 {ca.kyc_images && ca.kyc_images.map((ki)=> {
                    return (<div style={{margin:"20px"}}><img src={ki.uri} width="500" /></div>)
                 })}
                </div>
            )
        })}
        {customer.guarantors && customer.guarantors.length>0 && customer.guarantors.map((g)=> {
            return (
                <div>
                <h3>KYC for Guarantor - {g.general_information.name}</h3>
                 {g.kyc_images && g.kyc_images.map((ki)=> {
                    return (<div style={{margin:"20px"}}><img src={ki.uri} width="500" /></div>)
                 })}
                </div>
            )
        })}
        </div>
      )}
      </div>
    </div>
  );
}