import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { Button } from "@material-ui/core";
import { history } from "../../../_helpers";
import WelcomeLetterForm from "./WelcomeLetterForm";
import { employeeService } from "../../../_services";
import { LoanStages } from "../../../constants/loanstages";
import roleChecker from "../../../_helpers/rolechecker";
import ApplicationModal from "./SelectApplicationForm";

const SBLLetterList = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const user = employeeService.userValue;

  const field = { id: props.id };
  const application_id= props.id;
  const stage = props.stage;
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 250,
    height: 300,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    // overflow: 'scroll',
    // paddingBottom: '30px',
  };

  return (
    <>
      <Button color="primary" onClick={handleOpen}>
        Forms/Letters
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography>
          
            {(roleChecker.isHOLevelAccess(user.role) && stage === LoanStages.Closed) && (
              <Button
                color="primary"
                onClick={() =>
                  history.push(`/home/sblletters/letter/nocform`, field)
                }
              >
                NOC Letter
              </Button>
            )}
            {(roleChecker.isHOLevelAccess(user.role) && stage === LoanStages.Collection) && (
            <Button
              color="primary"
              onClick={() =>
                history.push(`/home/sblletters/letter/forform`, field)
              }
            >
              ForClosure Letter
            </Button>)}
            <br />
            {(roleChecker.isHOLevelAccess(user.role) && stage === LoanStages.Collection) && (
            <Button
              color="primary"
              onClick={() =>
                history.push(`/home/sblletters/letter/docform`, field)
              }
            >
              LOD Letter
            </Button> )}
            <br/>
            {(roleChecker.isHOLevelAccess(user.role) && (stage === LoanStages.Closed || stage === LoanStages.Rejected)) && (
            <Button
              color="primary"
              onClick={() =>
                history.push(`/home/sblletters/letter/pphform`, field)
              }
            >
              Property Paper Document Letter
            </Button> 
              )}
            <br />
            {(roleChecker.isHOLevelAccess(user.role) && stage === LoanStages.Sanction) && ( 
            <Button
              color="primary"
              onClick={() => history.push(`/home/sbl/${props.id}`)}
            >
              Sanction Letter
            </Button>)}
            <br />
            {(roleChecker.isHOLevelAccess(user.role) && stage === LoanStages.Collection) && (
              <Button
                color="primary"
                onClick={() =>
                  history.push(`/home/sblletters/letter/welcomeletter`, field) 
                }
              >
                Welcome Letter
              </Button>
              // <WelcomeLetterForm data = {field}/>
            )}

{(   ["HOCL","AC"].includes(user.role)) && (
 <Button
 color="primary"
 onClick={() =>
   history.push(`/home/letters/list/settlementcertificate`, field) 
 }
>
 Settlement Certificate
</Button>
 )} 
               
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default SBLLetterList;
