
export const TabSteps = ['General', 'Communication', 'Kyc', "Economic", 'Social','Dedupe','Bank Statement']

export const FormFields = [
    [
        //     {
        //     label:'Customer Photo',
        //     type:'file',
        //     name:'',
        //     options:[],
        // },
        {
            label: 'CIF ID',
            type: 'string',
            name: 'customer_id',
            options: [],
        },
        {
            label: 'Title',
            type: 'string',
            name: 'general_details.customer_title',
            options: [],
        },
        {
            label: 'First Name',
            type: 'string',
            name: 'general_details.customer_firstname',
            options: [],
        },
        {
            label: 'Middle Name',
            type: 'string',
            name: 'general_details.customer_middlename',
            options: [],
        },
        {
            label: 'Last Name',
            type: 'string',
            name: 'general_details.customer_lastname',
            options: [],
        },
        {
            label: 'DOB',
            type: 'date',
            name: 'general_details.fathername',
            options: [],
        },
        {
            label: 'Father Name',
            type: 'string',
            name: 'general_details.customer_dob',
            options: [],
        },
        {
            label: 'Gender',
            type: 'select',
            name: 'general_details.gender',
            options: ['Male', 'Female', 'Other'],
        },
        {
            label: 'Religion',
            type: 'select',
            name: 'personal_details.religion',
            options: ['Male', 'Female', 'Other', 'Hindu', 'Muslim', 'Sikh', 'Christian', 'Other'],
        },
        {
            label: 'Category',
            type: 'select',
            name: 'personal_details.category',
            options: ['General', 'SC', 'ST', 'OBC', 'DT/NT', 'Other'],
        },
    ],
    [
        {
            label: 'Mobile Number',
            type: 'number',
            name: 'general_details.customer_mobile_num',
            options: [],
        },
        {
            label: 'Alternate Mobile Number',
            type: 'number',
            name: 'general_details.alternate_mobile_num',
            options: [],
        },
        {
            label: 'Email id',
            type: 'string',
            name: 'general_details.email',
            options: [],
        },
        {
            label: 'Current Residence Address',
            type: 'string',
            name: 'personal_details.correspondence_address.address_line_1',
            options: [],
        },
        {
            label: 'Current Residence is',
            type: 'select',
            name: 'personal_details.correspondence_address.address_type',
            options: ['', 'Self Owned', 'Family', 'Rented', 'Company'],
        },
        {
            label: 'Landmark',
            type: 'string',
            name: 'x',
            options: [],
        },
        {
            label: 'Tehsil',
            type: 'string',
            name: 'x',
            options: [],
        },
        {
            label: 'Pin code',
            type: 'number',
            name: 'personal_details.correspondence_address.pin_code',
            options: [],
        },
        {
            label: 'City',
            type: 'string',
            name: 'personal_details.correspondence_address.city',
            options: [],
        },
        {
            label: 'State',
            type: 'string',
            name: 'personal_details.correspondence_address.state',
            options: [],
        },
        {
            label: 'No. of Years at Current Residence',
            type: 'number',
            name: 'x',
            options: [],
        },
        {
            label: 'Permanent Address',
            type: 'string',
            name: 'personal_details.permanent_address.address_line_1',
            options: [],
        },
        {
            label: 'Permanent Residence is',
            type: 'select',
            name: 'personal_details.permanent_address.address_type',
            options: ['', 'Self Owned', 'Family', 'Rented', 'Company'],
        },
        {
            label: 'Landmark',
            type: 'string',
            name: 'x',
            options: [],
        },
        {
            label: 'Tehsil',
            type: 'string',
            name: 'x',
            options: [],
        },
        {
            label: 'Pin code',
            type: 'number',
            name: 'personal_details.permanent_address.pin_code',
            options: [],
        },
        {
            label: 'City',
            type: 'string',
            name: 'personal_details.permanent_address.city',
            options: [],
        },
        {
            label: 'State',
            type: 'string',
            name: 'personal_details.permanent_address.state',
            options: [],
        },
        {
            label: 'No. of Years at Permanent Residence',
            type: 'number',
            name: 'x',
            options: [],
        },
    ],
    [
        {
            label: 'Aadhaar Number',
            type: 'string',
            name: 'verification_details.aadhaar_mask_no',
            options: [],
        },
        {
            label: 'Virtual aadhaar',
            type: 'string',
            name: 'verification_details.virtual_aadhaar',
            options: [],
        },
    ],
    [
        {
            label: 'Annual Income',
            type: 'number',
            name: 'x',
            options: [],
        }, 
        {
            label: 'Occupation Type',
            type: 'select',
            name: 'x',
            options: ['Salaried', 'Self Employed', 'Self Employed Professional'],
        }, 
        {
            label: 'Nature of business/employment',
            type: 'number',
            name: 'x',
            options: [],
        }, 
        {
            label: 'Designation',
            type: 'number',
            name: 'x',
            options: [],
        }, 
        {
            label: 'Annual Income',
            type: 'number',
            name: 'x',
            options: [],
        }, 
        {
            label: 'Annual Income',
            type: 'number',
            name: 'x',
            options: [],
        }, 
        {
            label: 'Annual Income',
            type: 'number',
            name: 'x',
            options: [],
        }, 
        {
            label: 'Annual Income',
            type: 'number',
            name: 'x',
            options: [],
        }, 
        {
            label: 'Annual Income',
            type: 'number',
            name: 'x',
            options: [],
        }, 
        {
            label: 'Annual Income',
            type: 'number',
            name: 'x',
            options: [],
        }, 
        {
            label: 'Annual Income',
            type: 'number',
            name: 'x',
            options: [],
        }, 
        {
            label: 'Annual Income',
            type: 'number',
            name: 'x',
            options: [],
        }, 
        {
            label: 'Annual Income',
            type: 'number',
            name: 'x',
            options: [],
        }, 
        {
            label: 'Annual Income',
            type: 'number',
            name: 'x',
            options: [],
        }, 
        {
            label: 'Annual Income',
            type: 'number',
            name: 'x',
            options: [],
        }, 
        {
            label: 'Annual Income',
            type: 'number',
            name: 'x',
            options: [],
        }, 
        {
            label: 'Annual Income',
            type: 'number',
            name: 'x',
            options: [],
        }, 
        {
            label: 'Annual Income',
            type: 'number',
            name: 'x',
            options: [],
        }, 
        {
            label: 'Annual Income',
            type: 'number',
            name: 'x',
            options: [],
        }, 
    ],
    [
        {
            label: 'No. of Years at Permanent Residence',
            type: 'number',
            name: 'x',
            options: [],
        },
        {
            label: 'No. of Years at Permanent Residence',
            type: 'number',
            name: 'x',
            options: [],
        },
        {
            label: 'No. of Years at Permanent Residence',
            type: 'number',
            name: 'x',
            options: [],
        },
        {
            label: 'No. of Years at Permanent Residence',
            type: 'number',
            name: 'x',
            options: [],
        },
        {
            label: 'No. of Years at Permanent Residence',
            type: 'number',
            name: 'x',
            options: [],
        },
        {
            label: 'No. of Years at Permanent Residence',
            type: 'number',
            name: 'x',
            options: [],
        },
    ]
]
