import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse
} from "@material-ui/core";
import { ExpandMore,ExpandLess} from "@material-ui/icons";
import { SmsOutlined } from "@material-ui/icons";
import roleChecker from "../../../_helpers/rolechecker";

export default function SmsMenu(props) {
  const [open, setOpen] = React.useState(false);
  const { path } = props.match;
  const user =props.user;


  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  return (
    <React.Fragment>
    <ListItem button onClick={handleClick}>
        <ListItemIcon><SmsOutlined /></ListItemIcon>
        <ListItemText primary="Sms" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" style={{paddingLeft:"50px"}}>
        <ListItem
             hidden={
              !(roleChecker.isCustomerCareTeam(user.role)) 
            }
            button
            key="single"
            component="a"
            href={`${path}/singlesms`}
          >
            <ListItemText primary="Single Sms" />
          </ListItem>

          <ListItem
            button
            key="bulksms"
            component="a"
            href={`${path}/bulksms`}
          >
            <ListItemText primary="Bulk Sms" />
          </ListItem>
          <ListItem
            button
            key="paymentlink"
            component="a"
            href={`${path}/paymentlink`}
          >
            <ListItemText primary="Payment Link" />
          </ListItem>
        </List>
      </Collapse>
      </React.Fragment>
  )
}
