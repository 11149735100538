import { Button, Modal, Table } from '@material-ui/core'
import React from 'react'
import { useState } from 'react'
import { Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table'
import logo from "../../../assets/kaabil.png";
import { employeeService } from '../../../_services';
import datehelper from '../../../_helpers/datehelper';
import { useReactToPrint } from "react-to-print";
import { BulkSubmissionLetter } from '../../../_services/bulksubmission.service';
import { useEffect } from 'react';
export const BulkSubmissionDoc = (props) => {
  const user = employeeService.userValue;
  console.log("test props",props)
  const data = props.location.state.updateLoans
  console.log("Test data",data)
  const bank = props.location.state.colendingBank

  const data1 = props.location.state
  console.log("Test data1",data1)
  const authPerson = props.location.state.authPerson
  console.log("authPRr",authPerson)
  const date = new Date()
  const [count, setCount] = useState()
  const total = data.reduce((
    la, next) => la + next.loan_amount, 0)
  console.log("Total Amount", total)

  useEffect(() => {
    async function getTotal() {
      await BulkSubmissionLetter.countLetter()
        .then((res) => {
          setCount(res)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    getTotal()
  })
  const num = `KFPL/GL/BULK/${data1.colendingBank === "Fed" ? "FED" : "CSB"}/${date.toLocaleString("default", { month: "long" })}/${count + 1}`;
  
  const BulkSubmissionDocRef = React.useRef();
  const handleBulkSubmissionDocPrint = useReactToPrint({
    content: () => BulkSubmissionDocRef.current,
    pageStyle: "print",
  });
  const handleSave = async () => {
    const bulkSubmissionData = data.map((x) => {
      return {
        loan_account: x.loan_account,
        name: x.name,
        gross_weight: x.gross_weight,
        net_weight: x.net_weight,
        loan_amount: x.loan_amount,
        ornaments:x.ornaments
      }
    }
    )
    await BulkSubmissionLetter.saveBulksubmissionLetterDetails({ bulkSubmissionData: [...bulkSubmissionData], 
      date: new Date(), 
      letter_number: num ,
      emp_id:user.emp_id,
      bank:bank,
      authPerson:authPerson


    })
      .then((res) => handleBulkSubmissionDocPrint())
      .catch((err) => window.alert("error  occurs"))
  }
  return (
    <div className='pledge'>
      <div style={{ textAlign: "center" }}>
        {
          bank ? <button onClick={() => { handleSave() }}>Print Bulk Submission Letter</button>
            : <button onClick={() => { handleBulkSubmissionDocPrint() }}>Print Bulk Submission preview Letter</button>
        }
      </div>
      <link rel='stylesheet' media='print' />

      <div ref={BulkSubmissionDocRef} className='letter' style={{ fontSize: '18px' }}>
        <div style={{
          width: "270mm",
          height: "400mm",
          marginLeft: "auto",
          marginRight: "auto",
          backgroundColor: "white",
          padding: 35,
        }}
          className="d-flex flex-column"
        >
          {/* <div style={{ textAlign: "center" }}>
            <img style={{ width: 350, height: 100 }} src={logo}></img>
          </div> */}

          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: "40px" }}>
            <b style={{ marginTop: '10px', textAlign: "center", marginBottom: "20px" }}>DIGI BIZ- STATEMENT OF RECEIVABLES</b>
            <div className="right">

              <b style={{ float: "right", marginTop: "30px" }}>
                {/* KFPL/GL/{data1.colendingBank === "Fed" ? "FED" : "CSB"}/  
                {date.toLocaleString("default", { month: "long" })}/ */}
                {bank ? num : props.location.state.letter_number}
              </b>
            </div>
            <b style={{ textAlign: "left", textDecoration: "underLine", marginBottom: "20px" }}>The Federal Bank Ltd.</b>
            <b style={{ textAlign: "left", textDecoration: " solid black underLine", marginBottom: "40px" }}>Br Gopalbari Branch, Jaipur</b>
            <p style={{ marginBottom: "30px", textAlign: "right" }}>Date:{bank?datehelper.displayDate(date):datehelper.displayDate(new Date(data1.date))}</p>
            <p style={{ marginBottom: "30px" }}>
              This has reference to the Digi- Biz facility availed by me/us for an amount of Rs.
              7.50 crore (“Digi Biz Facility”) from Gopalbari Branch of the
              and the Credit Facility Agreement dated 03-11-2022 executed by me/us
              in favor of the Bank .I /We hereby declare and confirm that the following re-pledges in
              respect of the Obligor/s mentioned in the Table below are outstanding as on this date.
              The Gross and Net Weight of the gold Items and the Gold Loan amounts outstanding
              in respect of each of the Obligor/s are as stated in the Table hereunder.<br />
            </p>
            <b style={{ textAlign: "center", marginBottom: "20px" }}>Table.</b>

            <Table style={{ width: "100%" }}>
              <Tr>
                <Th style={{ border: "1px solid " }}>S.No</Th>
                <Th style={{ border: "1px solid " }}>Loan No</Th>
                <Th style={{ border: "1px solid " }}>Name of the Obligors</Th>
                <Th style={{ border: "1px solid " }}>Ornaments Description </Th>
                <Th style={{ border: "1px solid " }}>Quantity</Th>
                <Th style={{ border: "1px solid " }}>G. Wt.</Th>
                <Th style={{ border: "1px solid " }}>Net Wt.</Th>
                <Th style={{ border: "1px solid " }}>Total amount Lent(Receivables)</Th>
              </Tr>
              <Tbody style={{ size: "80px" }}>
                {
                  data.length > 0 ?
                    data.map((x, idx) => <Tr key={idx}>
                      <Td style={{ border: "1px solid " }}>{idx + 1}</Td>
                      <Td style={{ border: "1px solid " }}>{x.loan_account}</Td>
                      <Td style={{ border: "1px solid " }}>{x.name}</Td>
                      {x.ornaments && <><Td style={{ border: "1px solid " }}>{
                        x.ornaments.map((y,i)=><div key={i}>
                          <p>{y.desc}</p>
                        </div>)
                      }</Td>
                      <Td style={{ border: "1px solid " }}>{
                        x.ornaments.map((y,i)=><div key={i}>
                        <p>{y.quantity}</p>
                        </div>)
                      }</Td></>}
                      <Td style={{ border: "1px solid " }}>{x.gross_weight}</Td>
                      <Td style={{ border: "1px solid " }}>{x.net_weight}</Td>
                      <Td style={{ border: "1px solid " }}>{x.loan_amount}</Td>
                    </Tr>
                    )
                    : null
                }
              </Tbody>
              <Tr style={{ border: "1px solid " }}>
                <Td colspan="7" style={{ textAlign: "center", fontWeight: "bold" }}>Total</Td>
                <Td style={{ fontWeight: "bold", border: "1px solid " }}>{total}</Td>
              </Tr>
            </Table>
          </div>
          <p >
            The gold items having total weight as described above are available as continuing
            security towards all the Individual Demand Loans availed/ to be availed by the
            Borrower/s under the Digi Biz facility granted by the bank. The Receivables under
            Pledge/s above-referred stands hypothecated to the Bank towards security for all the
            Individual Demand Loans availed/ to be availed by the Borrower/s under the Digi Biz
            Facility .<br />
            <p style={{ textDecoration: "underLine", marginTop: "50px" }}>Name & Signature of the Borrower.</p>
          </p>
        </div>
      </div>
    </div>
  )
}
