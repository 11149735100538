import React from 'react';
import bulkSmsDataSheet from '../../../assets/BulkSmsData.csv'
import {bulkSmsData} from '../../../_services/sms.service';

const BulkSms = () => {
  const [selectedFile, setSelectedFile] = React.useState(null);
   

  const sendData = async(e) => {
    e.preventDefault()
    const data = new FormData();
    data.append('file', selectedFile);
    
    
    
    for (var key of data.entries()) {
      console.log(key[0] + ', ' + key[1]);
  }
 

    await bulkSmsData(data).then((response) => {
      if (!response.ok) {
        alert("An Error Occurred, Please try again");
      } else {
        alert("Data Uploaded");
        window.location.reload();
      }
    });
  }

  const handleFileSelect = (e) => {
    setSelectedFile(e.target.files[0])
  }

  return (
    <>
    <a href={bulkSmsDataSheet} download style={{width:'300px', marginTop:'150px',border:'1px solid blue', padding:'5px',borderRadius:'5px', textDecoration:'none'}}  >Download Csv Template</a>
      <form  id="#"  style={{marginTop:'70px', marginLeft:'30px', display:"flex", flexDirection:'column', alignItems:'center' }} > 
      <input type="file" name='formData' accept='.csv' onChange={handleFileSelect}/>
      <input type="submit"  onClick={sendData} value="Upload File" style={{width:'300px', marginTop:'30px'}} />
    </form>
    </>  
  )
};

export default BulkSms;