import { fetchWrapper } from "../_helpers/fetch-wrapper";
const apiUrl = process.env.REACT_APP_API_URL;

const baseUrl = `${apiUrl}/kyc`;

export const kycService = {
    requestAadhaarOTP,
    requestAadhaarXML
}

async function requestAadhaarOTP(params){
    console.log(params)
    return fetchWrapper.post(`${baseUrl}/request_aadhaar_otp`, params);
}

async function requestAadhaarXML(params){
    return fetchWrapper.post(`${baseUrl}/request_aadhaar_xml`, params);
}
