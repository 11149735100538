import React from 'react';
import { useState } from 'react';
import { Modal, Box, Typography, Button } from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { history } from '../../_helpers';


export const BankMenuModal = (props) => {
    const value=props.value;
    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false)
    }

    const handleChange=(e)=>{
        if(e.target.value==='CSB'){
            history.push(`/home/customers/loan/${value._id}`, value)
        }else if(e.target.value==='Federal'){
            history.push(`/home/customers/fedloan/${value._id}`, value)
        }
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 200,
        height: 100,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        // p: 4,
        // overflow: 'scroll',
        //paddingBottom: '30px',
    }

    return (
        <div>
            <button
                style={{
                    display: "flex",
                    border: "1px solid grey",
                    width: "50px",
                    borderRadius: "8px",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "10px",
                }}
                onClick={handleOpen}
            >
                <AddOutlinedIcon />
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography style={{display:'flex',flexDirection:'column', alignItems:'center', marginTop:'14px'}}>
                    <label style={{display:'flex', flexDirection:'column'}}>
                        Select Bank
                    <select style={{width:'150px',borderRadius:'5px'}} onChange={(e)=>handleChange(e)}>
                        <option value=""></option>
                        <option value="CSB">CSB</option>
                        <option value="Federal">Federal</option>   
                    </select>
                    </label>
                    </Typography>
                </Box>
            </Modal>
        </div>
    )
}