import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ApprovalList } from './ApprovalList';
import { MyRequests } from './MyRequests';

export const ApprovalIndex =({match})=>{
    const {path}=match
    return (
        <Switch>
            <Route path={`${path}/list`} component={ApprovalList}/>
            <Route path={`${path}/myrequests`} component={MyRequests}/>
        </Switch>
    )

}