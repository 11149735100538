import React, { useEffect, useState } from 'react';
import { branchService } from '../_services/branch.service';
import { customerService } from '../_services';
import { Table, Tbody, Thead, Tr, Th, Td } from 'react-super-responsive-table';
import { Paper, Button } from '@material-ui/core';

function ApprovalTarget() {
  const [filteredData, setFilteredData] = useState([]);
  const [branchMap, setBranchMap] = useState({});
  // Fetch all branches on component mount
  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const res = await branchService.getAll();
        const map = {};
        res.forEach(branch => {
          map[branch.branch_id] = branch.branch_name;
        });
        setBranchMap(map);

        const uniqueBranchZones = new Set(res.map(branch => branch.branch_zone));
        const uniqueBranchZonesArray = [...uniqueBranchZones];
        console.log(uniqueBranchZonesArray, "Unique Branch Zones");

        // Call searchTarget for each unique branch zone
        uniqueBranchZonesArray.forEach(branchZone => {
          searchTarget(branchZone);
        });
      } catch (err) {
        console.error('Error fetching branches:', err);
      }
    };

    fetchBranches();
  }, []);

  const searchTarget = async (branchZone) => {
    try {
      const ress = await customerService.searchTarget({ branch_key: `zone-${branchZone}` });
      let parsedResponses = typeof ress === 'string' ? JSON.parse(ress) : ress;

      if (Array.isArray(parsedResponses)) {
        console.log("Parsed Responses:", parsedResponses);

        // Filter data based on conditions
        const filtered = parsedResponses.filter(item => item.approval_status === 'pending');
        setFilteredData(prevData => [...prevData, ...filtered]);
      } else {
        console.error('Data fetched is not an array:', parsedResponses);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  // Group data by zone and month_key and combine targets
  const groupedData = filteredData.reduce((acc, item) => {
    const { zone, key, monthly_target, month_key } = item;
    const zoneMonthKey = `${zone}-${month_key}`;
    if (!acc[zoneMonthKey]) {
      acc[zoneMonthKey] = { zone, month_key, login_target: '-', disbursement_target: '-' };
    }
    if (key === 'login_target') {
      acc[zoneMonthKey].login_target = monthly_target;
    } else if (key === 'disbursement_target') {
      acc[zoneMonthKey].disbursement_target = monthly_target;
    }
    return acc;
  }, {});

  const tableData = Object.values(groupedData);
  const handleApprove =  (zone, month, approval_status) => {
        customerService.updateTarget({zone: zone,month: month,approval_status: approval_status}) .then(res => {
            console.log(res, "res here")
            window.alert(`Successful ${approval_status} for this zone: ${zone}`);
            window.location.reload();
        })
        .catch(err => {
            // window.alert('Some error occurred')
            console.log(err)
        })
};
  return (
    <div>
      <Paper style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px', paddingBottom: '10px', paddingTop: '10px' }}>
        <h3 style={{ textAlign: 'center' }}>Target Approval List</h3>
      </Paper>

      <Table style={{ width: '100%', margin: 'auto', textAlign: 'center' }} className="table-secondary">
        <Thead>
          <Tr>
            <Th style={{ width: '10%', textAlign: 'center', padding: '1rem' }}>S No.</Th>
            <Th style={{ width: '10%', textAlign: 'center', padding: '1rem' }}>Zone Name</Th>
            <Th style={{ width: '10%', textAlign: 'center', padding: '1rem' }}>Zone ID</Th>
            <Th style={{ width: '10%', textAlign: 'center', padding: '1rem' }}>Month</Th>
            <Th style={{ width: '20%', textAlign: 'center', padding: '1rem' }}>Monthly Login Target</Th>
            <Th style={{ width: '20%', textAlign: 'center', padding: '1rem' }}>Monthly Disbursement Target (in Lakhs)</Th>
            <Th style={{ width: '20%', textAlign: 'center', padding: '1rem' }}>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {tableData.map((item, index) => (
            <Tr key={index}>
              <Td>{index + 1}</Td>
              <Td>
        {(() => {
            console.log('Branch Map:', branchMap);
            console.log('Item Zone:', item.zone);
            return branchMap[item.zone] || 'N/A';
        })()}
      </Td>
              <Td>{item.zone}</Td>
              <Td>{item.month_key}</Td>
              <Td>{item.login_target}</Td>
              <Td>{item.disbursement_target}</Td>
              <Td>
              <Button 
  style={{ color: 'white', backgroundColor: 'blue' }} 
  onClick={() => handleApprove(item.zone, item.month_key, "approved")}
>
  Approve
</Button>
                <Button style={{ color: 'white', backgroundColor: 'red', marginLeft: '5%' }}  onClick={() => handleApprove(item.zone, item.month_key, "rejected")}>Reject</Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  )
}

export default ApprovalTarget;