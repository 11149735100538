import { Button, Paper } from "@material-ui/core";
import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { branchService } from "../../_services/branch.service";
import { employeeService } from "../../_services";
import { useEffect, useState } from "react";
import { accountService } from "../../_services/accounts.service";
import CircularProgress from "@material-ui/core/CircularProgress";
import datehelper from "../../_helpers/datehelper";
import TextField from '@material-ui/core/TextField';
import roleChecker from "../../_helpers/rolechecker";


const SblBranchSummary = () => {
  const [branches, setBranches] = useState([]);
  const [cashBranchName, setCashBranchName] = useState({});
  const [onlineBranchName, setOnlineBranchName] = useState({});
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [loading, setLoading] = useState(false);
  const [cashSummary, setCashSummary] = useState([]);
  const [onlineSummary, setOnlineSummary] = useState([]);
  const [discardedSummary, setDiscardedSummary] = useState([])
  const [employeeList, setEmployeeList] = useState({});
  const [selectedDate, setSelectedDate] = useState({from_date:"", to_date:""})

  let current_date = new Date();
  // console.log(employeeList[1])
  const dd = current_date.getDate();
  const bdd = dd - 1;
  const mm = current_date.getMonth() + 1;
  const yy = current_date.getFullYear();

  current_date = yy + "-" + mm + "-" + dd;
  let back_date = yy + "-" + mm + "-" + bdd

  console.log(back_date)

  let user = employeeService.userValue;

  let cash_total = 0;
  let online_total = 0;
  let discarded_total = 0;


  const handleChange = (fieldKey, fieldValue) => {
    selectedDate[fieldKey] = fieldValue
    setSelectedDate(selectedDate)
    console.log(selectedDate)
  }

  async function getAllEmployees() {
    await employeeService.getAll().then((res) => {
      let list = {};
      res.map((e) => {
        list[e.emp_id] = e.first_name + " " + e.last_name;
      });
      setEmployeeList(list);
    });
  }

  useEffect(() => {
    branchService
      .getAll()
      .then((x) => {
        let y = x.filter((b) => {
          if (roleChecker.isBMLevelAccess(user.role)) {
            if (b.branch_id == user.branch_id) {
              return true
            } else {
              return false
            }
          }
          return true
        })
        setBranches(y);
        if (roleChecker.isBMLevelAccess(user.role)) {
          setSelectedBranch(user.branch_id)
        }
        console.log(x);
        let cash_list = {};
        let online_list = {};

        y.map((e) => {
          cash_list[e.branch_id] = e.branch_name;
          online_list[e.branch_id.slice(0, 2)] = e.branch_name;
        });
        setCashBranchName(cash_list);
        setOnlineBranchName(online_list);
        console.log(online_list)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getAllEmployees();
  }, []);

  let code;

  const getData = () => {
    setLoading(true);
    if (selectedBranch === "All") {
      code = selectedBranch;
    } else {
      code = selectedBranch.slice(0, 2);
    }
    console.log({ code: code, date: selectedDate })
    accountService
      .branchSummary({ code: code, date: selectedDate })
      .then((x) => {
        console.log(x);
        setCashSummary(x.cash_payments);
        setOnlineSummary(x.online_payments);
        setDiscardedSummary(x.discarded_payments)
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  useEffect(() => {

    if(roleChecker.isBranchLevelAccess(user.role))
    {
       if(selectedBranch==='All')
       {
         return;
       }
    }
    if(selectedBranch!="")
    {
      getData();
    }
    
  }, [selectedBranch]);

  return (
    <div>
      <Paper style={{ textAlign: "center" }}>
        <h2>SBL Branch Summary</h2>
      </Paper>
      <br />

      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "row",
          margin: "auto",
        }}
      >
        <InputLabel id="demo-simple-select-label">Select Branch</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedBranch}
          onChange={(e) => setSelectedBranch(e.target.value)}
          style={{ width: "200px" }}
        >
          {roleChecker.hasAccountsAccess(user.role) ? <MenuItem value="All">
            All Branches
          </MenuItem> : null}

          {branches.map((branch) => (
            <MenuItem key={branch._id} value={branch.branch_id}>
              {branch.branch_id} - {branch.branch_name}
            </MenuItem>
          ))}
        </Select>
        <TextField
          id="date"
          type="date"
          name="from_date"
          onChange={(e)=>handleChange([e.target.name], e.target.value)}          
          style={{ marginLeft: "80px" }}
          // defaultValue={current_date}
        // inputProps={{ min: back_date, max: current_date }}
        /><label>From Date</label>
        
         <TextField
          id="date"
          type="date"
          name="to_date"
          onChange={(e)=>handleChange([e.target.name], e.target.value)}          
          style={{ marginLeft: "80px" }}
          // defaultValue={current_date}
        // inputProps={{ min: back_date, max: current_date }}
        /> <label>To Date</label>

        <Button style={{marginLeft:"80px"}} variant="outlined" color="primary" onClick = {()=>getData()}>Search</Button>

      </div>
      <hr style={{ border: "1px solid" }} />

      {loading ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <h2>Cash Received</h2>
          <hr style={{ border: "1px solid" }} /> <br />
          {cashSummary && cashSummary.length > 0 ? (
            <table>
              <thead>
                <th>Date</th>
                <th>Customer Name</th>
                <th>Branch Name</th>
                <th>Loan Number</th>
                <th>Amount</th>
                <th>Reconciled By</th>
              </thead>
              <tbody>
                {cashSummary.map((e) => {
                  {
                    cash_total += e.payment;
                  }
                  return (
                    <tr key={e._id}>
                      <td>{datehelper.displayDate(new Date(e.date))}</td>
                      <td>
                        {e.customer &&
                          e.customer.length > 0 &&
                          e.customer[0].applicant &&
                          e.customer[0].applicant.general_information && (
                            <>
                              {e.customer[0].applicant.general_information.name}
                            </>
                          )}
                      </td>
                      <td>
                        {e.customer && e.customer.length > 0 && (
                          <>{cashBranchName[e.customer[0].branch_id]}</>
                        )}
                      </td>
                      <td>{e.loan_no}</td>
                      <td>{e.payment}</td>
                      <td>
                        {e.reconcilelist &&
                          e.reconcilelist.length > 0 &&
                          e.reconcilelist[0].reconcile_by && (
                            <>{employeeList[e.reconcilelist[0].reconcile_by]}</>
                          )}
                      </td>
                    </tr>
                  );
                })}
                <br />
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <b>Total:-</b>
                  </td>
                  <td>
                    <b>
                      {cash_total.toLocaleString("en-IN", {
                        maximumFractionDigits: 2,
                        style: "currency",
                        currency: "INR",
                      })}
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <h4 style={{ textAlign: "center" }}>NO Data Found</h4>
          )}
        </>
      )}
      <br />

      {loading ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <h2>QR Payment Collected</h2>
          <hr style={{ border: "1px solid" }} /> <br />
          {onlineSummary && onlineSummary.length > 0 ? (
            <table>
              <thead>
                <th>Date</th>
                <th>Customer Name</th>
                <th>Branch Name</th>
                <th>Loan Number</th>
                <th>Amount</th>
                <th>Reconciled By</th>
              </thead>
              <tbody>
                {onlineSummary.map((e) => {
                  {
                    online_total += Number(e.txn_amt);
                  }
                  return (
                    <tr key={e._id}>
                      <td>{datehelper.displayDate(new Date(e.updated))}</td>
                      <td>
                        {e.virtual_accounts &&
                          e.virtual_accounts.length > 0 &&
                          e.virtual_accounts[0].customer_name && (
                            <>{e.virtual_accounts[0].customer_name}</>
                          )}
                      </td>
                      <td>
                        {e.virtual_accounts &&
                          e.virtual_accounts.length > 0 && e.virtual_accounts[0].loan_account && (
                            <>
                              {
                                onlineBranchName[
                                e.virtual_accounts[0].loan_account.slice(0, 2)
                                ]
                              }
                            </>
                          )}
                      </td>
                      <td>
                        {e.virtual_accounts &&
                          e.virtual_accounts.length > 0 &&
                          e.virtual_accounts[0].loan_account && (
                            <>{e.virtual_accounts[0].loan_account}</>
                          )}
                      </td>
                      <td>{e.txn_amt}</td>
                      <td>
                        {e.reconcilelist &&
                          e.reconcilelist.length > 0 &&
                          e.reconcilelist[0].reconcile_by && (
                            <>{employeeList[e.reconcilelist[0].reconcile_by]}</>
                          )}
                      </td>
                    </tr>
                  );
                })}
                <br />
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <b>Total:-</b>
                  </td>
                  <td>
                    <b>
                      {online_total.toLocaleString("en-IN", {
                        maximumFractionDigits: 2,
                        style: "currency",
                        currency: "INR",
                      })}
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <h4 style={{ textAlign: "center" }}>NO Data Found</h4>
          )}
        </>
      )}

      {loading ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <h2>Discarded Cash Payments</h2>
          <hr style={{ border: "1px solid" }} /> <br />
          {discardedSummary && discardedSummary.length > 0 ? (
            <table>
              <thead>
                <th>Date</th>
                <th>Customer Name</th>
                <th>Branch Name</th>
                <th>Loan Number</th>
                <th>Amount</th>
                <th>Discarded By</th>
              </thead>
              <tbody>
                {discardedSummary.map((e) => {
                  {
                    discarded_total += e.payment;
                  }
                  return (
                    <tr key={e._id}>
                      <td>{datehelper.displayDate(new Date(e.date))}</td>
                      <td>
                        {e.customer &&
                          e.customer.length > 0 &&
                          e.customer[0].applicant &&
                          e.customer[0].applicant.general_information && (
                            <>
                              {e.customer[0].applicant.general_information.name}
                            </>
                          )}
                      </td>
                      <td>
                        {e.customer && e.customer.length > 0 && (
                          <>{cashBranchName[e.customer[0].branch_id]}</>
                        )}
                      </td>
                      <td>{e.loan_no}</td>
                      <td>{e.payment}</td>
                      <td>
                        {employeeList[e.discarded_by]}
                      </td>
                    </tr>
                  );
                })}
                <br />
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <b>Total:-</b>
                  </td>
                  <td>
                    <b>
                      {discarded_total.toLocaleString("en-IN", {
                        maximumFractionDigits: 2,
                        style: "currency",
                        currency: "INR",
                      })}
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <h4 style={{ textAlign: "center" }}>NO Data Found</h4>
          )}
        </>
      )}
      <br />

    </div>
  );
};

export default SblBranchSummary;
