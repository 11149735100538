import React from 'react'
import { useReactToPrint } from "react-to-print";
import datehelper from '../../../_helpers/datehelper';
import kaabilLogo from "../../../assets/kaabil.png"
import EmailIcon from '@material-ui/icons/Email';
import CallIcon from '@material-ui/icons/Call';
import LanguageIcon from '@material-ui/icons/Language';
const DocumentListLetter = (props) => {

    const DocumentListLetterRef = React.useRef();

    const handleSubmissionPrint = useReactToPrint({
        content: () => DocumentListLetterRef.current,
        pageStyle: "print"
    });


    const data = props.location.state;
    console.log(data)

    let date = new Date();
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct",
        "Nov", "Dec"];
    const yy = date.getFullYear();
    const mm = date.getMonth();
    const dd = date.getDate();

    date = months[mm] + " " + dd + "," + " " + yy;

    return (
        <>

            <div style={{ textAlign: "center" }}>
                <button onClick={handleSubmissionPrint}>Print Letter</button>
            </div>

            <div ref={DocumentListLetterRef} style={{ fontSize: "20px", textAlign: "justify"}}>
            <img
            src={kaabilLogo}
            style={{ height: "130px" }}
            alt="KFPL Logo"
          />
                <div style={{marginTop:"140px"}}>
                <span>Letter Id: {data.letter_id}</span> <br/>
                    <span>Date: { datehelper.displayDate(new Date(date))} </span>
                
                </div><br />

                <div>
                    To,<br />
                    <b>{data.title}. {data.name}</b>
                    <b> {data.relation_type} {data.relation}</b><br />
                    Address:- {data.address}<br />
                </div><br /><br />

                <div>
                    Dear Sir/Madam,<br /><br />
                    Reg: List of Documents
                </div>
                <br />
                <div>
                    With reference to the above, we here by confirm that <b>{data.title}. {data.name} {data.relation_type} {data.relation}</b> has
                    availed secured business loan (SBL) of INR. <b>{data.amount}/-</b>. <br/> 
                    Loan Account no: <b>{data.application_id}</b> From us.
                    <br/><br />
                    The list of documents submitted for securing the Loan as under.
                </div><br />

                <div>
                    <table style={{ border: "1px solid black" }}>
                        <tbody>
                            <tr>
                            <td style={{ border: "1px solid black",width:"10%" }}>
                                   S. No
                                </td>
                                <td style={{ border: "1px solid black",width:"80%" }}>
                                    Documents Type
                                </td>
                                <td style={{ border: "1px solid black",width:"10p%" }}>
                                    Original/PhotoCopy
                                </td>
                            </tr>
                            {data.documents && data.documents.map((x ,index) => {
                                return <tr>
                                    <td style={{ border: "1px solid black" ,width:"10%"}}>
                                        {index+1}
                                    </td>
                                    <td style={{ border: "1px solid black",width:"80%" }}>
                                        {x.documentData}
                                    </td>
                                    <td style={{ border: "1px solid black",width:"10%" }}>
                                        {x.papertype}
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div><br />

                <div><br />
                </div><br />

                <div>
                    We assure you our best services at all times<br/><br/>
                    Thanks & Regards<br/>
                    Kaabil Finance Pvt. Ltd.
                </div><br /><br /><br />

                <div>
                    Authorised signatory
                </div>
                <div className="header-infos" >
     <div className="header-top"  style={{ textAlign: "center" }}>
       <div>
         <span>
           <strong><EmailIcon/>  care@kaabilfinance.com</strong>
         </span>
       </div>
       <div>
         <span>
           <strong><CallIcon/> 0141-4005012</strong>
         </span>
       </div>
       <div>
         <strong><LanguageIcon/> www.kaabilfinance.com</strong>
       </div>
     </div>
     <div className="header-bottoms" style={{fontSize:"18px"}}>
      
         <p> <strong>Registered & Corporate Office </strong> :- Kaabil Towers, Plot No. 1, Scheme No. 1, Income Tax Colony, Model Town, Jagatpura, Jaipur Rajasthan 302017 CIN No. U65929RJ2017PTC058138 ,GSTIN : 08AAGCK6104N1ZA</p>
         
     </div>
     {/* Fixed bottom footer */}
     
   </div>
            </div>
        </>
    )
}

export default DocumentListLetter