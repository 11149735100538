import { fetchWrapper } from '../_helpers';
 
const apiUrl= process.env.REACT_APP_API_URL;
const path =`${apiUrl}/goldauction`

const GoldAuctionServices ={
   CreateNotice,
   FindByLoan,
   UpdateNoticeDetails,
   getAuctionDetails,
   getNoticeDetails,
   publicationCsv,
}

function CreateNotice(params){
   return fetchWrapper.post(`${path}/createnotice`, params)
}

function FindByLoan(params){
    console.log(params)
    return fetchWrapper.post(`${path}/findbyloan`, params)
}

function UpdateNoticeDetails(id,params){
   return fetchWrapper.put(`${path}/update/${id}`,params)
}

function getAuctionDetails(){
   return fetchWrapper.get(`${path}/auctiondetails`)
}

function getNoticeDetails(params){
   return fetchWrapper.post(`${path}/noticedetails`, params)
}

function publicationCsv(data){
   console.log(data)
   return fetch(`${path}/publicationcsv`, {
     method: "POST",
     body: data,
     headers:fetchWrapper.authHeader(`${path}/publicationcsv`),
   });
 }

export default GoldAuctionServices;