import React from "react";
import { useState, useEffect } from "react";
import roleChecker from "../_helpers/rolechecker";
import { employeeService, misService ,customerService} from "../_services";
import { branchService } from "../_services/branch.service";
import { reportsService } from "../_services/reports.service";
import displayhelper from "../_helpers/displayhelper";
import datehelper from "../_helpers/datehelper";
import usericon from "../assets/user.png"

function Dashboard2(){
  const user = employeeService.userValue;
  const [metricsData, setMetricsData] = useState({})
  const [metricsData1, setMetricsData1] = useState({})
  const [metricsZoneNameData1, setMetricsZoneNameData1] = useState({})
  const [time, setTime] = useState(Date.now());
  let todayDate= new Date()
  let currentDate= new Date();
  let todayEnd= datehelper.getDateEnd(todayDate);
  let hours=Math.floor(datehelper.getDateDiffSeconds(todayEnd,currentDate)/3600);
  let minutes=Math.floor((datehelper.getDateDiffSeconds(todayEnd,currentDate)-hours*3600)/60);
  let seconds=Math.floor((datehelper.getDateDiffSeconds(todayEnd,currentDate)-hours*3600-minutes*60));
  const [hourLeft,setHourLeft]= useState(hours);
  const [minuteLeft,setMinuteLeft]= useState(minutes);
  const [secondLeft,setSecondLeft]= useState(seconds);
  const [loading,setLoading]=useState(true);
  const [allBranch,setAllBranch]=useState([])
  const [zoneWiseData,setZoneWiseData]=useState([])
  let branchId='';
  let matrix_key='';
  const now = new Date();
  const month = now.getMonth() + 1; // getMonth() is zero-based
  const year = now.getFullYear();
  const currentMonthYearss = `${month}-${year}`;
  const [chartData, setChartData] = useState({})
  
  useEffect(() => {
    loadMetrics()

    branchService.getAll()
    .then((res)=>{setAllBranch(res); console.log('all branches',res)})
    .catch(err=>console.log(err))
  }, [])

  async function loadMetrics()
  {
    setLoading(true);
    if (roleChecker.isHOLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isASMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isBCMLevelAccess(user.role) || roleChecker.isCSMLevelAccess(user.role) || roleChecker.isRSMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) || roleChecker.isSalesTeamAccess(user.role)) {

      if (roleChecker.isHOLevelAccess(user.role)) {
        // console.log('User role ---->>> ', user.role, user.branch_id)
        branchId = 'head_office-HO'
        matrix_key = 'all'
      } 
      else if (roleChecker.isZCMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) ) {
        // console.log('User role ---->>> ', user.role, user.branch_id)
        branchId = 'zone-' + user.branch_id
        matrix_key = user.branch_id
      } 

      else if (roleChecker.isRSMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role)) {
        // console.log('User role ---->>> ', user.role, user.branch_id).
        
        branchId = 'region-' + user.branch_id
        matrix_key = user.branch_id
      }
      else if (roleChecker.isAreaLevelAccess(user.role)) {
        // console.log('User role ---->>> ', user.role, user.branch_id)
        branchId = 'area-' + user.branch_id
        matrix_key = user.branch_id
      }
      else if (roleChecker.isCSMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role)) {
        console.log('User role ---->>> ', user.role, user.branch_id)
        branchId = 'cluster-' + user.branch_id
        matrix_key = user.branch_id
      }
      else if (roleChecker.isBMLevelAccess(user.role)) {
        branchId = 'branch-' + user.branch_id
        matrix_key = user.branch_id
        console.log('User role ---->>> ', user.role, user.branch_id,matrix_key)
      }

      else if (roleChecker.isBCMLevelAccess(user.role)) {
        branchId = 'branch-' + user.branch_id
        matrix_key = user.branch_id
        console.log('User role ---->>> ', user.role, user.branch_id,matrix_key)
      }

      else if (roleChecker.isSalesTeamAccess(user.role)) {
        branchId = 'branch-' + user.branch_id
        matrix_key = user.branch_id
        console.log('User role ---->>> ', user.role, user.branch_id,matrix_key)
      }


      else {
        branchId = user.branch_id
         console.log('User role ---->>> ', user.role, user.branch_id, branchId)
      }

      reportsService.getRealMetricsData({ 'key':'login_count', 'branch_key':branchId}).
      then((metricListObj) => {
        console.log('alllllllllllllllllllllllll',metricListObj);

        let tmpMetricsData={}
        metricListObj.map((mo)=> {
          tmpMetricsData[mo.key]=mo.display_data
        })
        console.log(tmpMetricsData);
        setMetricsData1(tmpMetricsData);
      })
      .catch((err)=> {
        console.log(err);
      })
      reportsService.getMetricsData({ 'metric_key': "all" }).
      then((metricListObj) => {
        console.log('metricListObjmetricListObjmetricListObjmetricListObj',metricListObj);
        let tmpMetricsData={}
        metricListObj.map((mo)=> {
          tmpMetricsData[mo.key]=mo.display_data
        })
        console.log('tmpMetricsDatatmpMetricsDatatmpMetricsDatatmpMetricsData',tmpMetricsData);
        setMetricsData(tmpMetricsData);
      })
      .catch((err)=> {
        console.log(err);
      })
    }
      
    }
     
  
function reloadPage()
{
   setTime(new Date());
   console.log("reloading ...");
   window.location.reload(true);
}


function reloadTime()
{
  todayDate= new Date()
  currentDate= new Date();
  todayEnd= datehelper.getDateEnd(todayDate);
  let secondsTotal=datehelper.getDateDiffSeconds(todayEnd,currentDate)
  console.log("total seconds"+secondsTotal);
  hours=Math.floor(secondsTotal/3600);
  let minuteSeconds=secondsTotal-(hours*3600)
  console.log("total seconds in minutes"+minuteSeconds);
  minutes=Math.floor(minuteSeconds/60);
  seconds=Math.floor(secondsTotal-hours*3600-minutes*60);
  setHourLeft(hours)
  setMinuteLeft(minutes)
  setSecondLeft(seconds)
}

useEffect(() => {
  const interval = setInterval(() => reloadPage(), 120000);
  return () => {
    clearInterval(interval);
  };
}, []);

useEffect(() => {
  const interval = setInterval(() => reloadTime(), 1000);
  return () => {
    clearInterval(interval);
  };
}, []);

  const percentageLabelFormatter = (value) => {
    return value + '%';
  };

  let asmData;

  if(metricsData && metricsData.SG26_area_login_count){
      asmData = JSON.parse(metricsData.SG26_area_login_count)
  }

  let regionData;

  if(metricsData && metricsData.region_login_count){
    regionData = JSON.parse(metricsData.region_login_count)
  }
  let zoneData = [];
  let m = new Map();
  
  if (metricsData && metricsData.zone_login_count) {
      const parsedData = JSON.parse(metricsData.zone_login_count);
      zoneData = Object.entries(parsedData);
      for (let i = 0; i < zoneData.length; i++) {
          for (let j = 0; j < allBranch.length; j++) {
              if (zoneData[i][0]== allBranch[j].branch_id) {
                  m[zoneData[i][0]] =  allBranch[j].branch_name;
                  break; // Exit the inner loop once a match is found
              }
          }
      }
  }

  var countDetailsData;

  if(metricsData && metricsData.total_count_details){
    countDetailsData = JSON.parse(metricsData.total_count_details)
  }

  var rostarData;

  if(metricsData && metricsData.star_ro_login){
    rostarData = JSON.parse(metricsData.star_ro_login)
  }

  var collectionData;

  if(metricsData && metricsData.collection_receive_emis){
    collectionData = JSON.parse(metricsData.collection_receive_emis)
  }

  var weekstarData;

  if(metricsData && metricsData.week_ro_star){
    weekstarData = JSON.parse(metricsData.week_ro_star)
  }


 if(rostarData){
  console.log(rostarData)
 }



  
  const styles = `
    .dashboard {
      background-color: #f4f4f4;
      border-radius: 10px;
      width: 85%;
      margin: auto;
    }

    .top-row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      color: white;
      width: 100%;
    }

    .middle-row {
      display: flex;
      justify-content: space-between;
    }
    
    .left-column {
      width: calc(100% - 5px);
    }
    .right-column {
      width: calc(100% - 5px);
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap:10px;
      margin-top: 10px;
      align-content: flex-start;
      
    }
    .cart {
      width: calc(20% - 10px);
      height: 65px;
      background-color: #3F51B5;
      border-radius: 5px;
      display: flex;
      flex-direction: column; /* Align label and value vertically */
      justify-content: space-evenly; /* Evenly distribute space between label and value */
      padding: 5px;
      font-weight: bold;
    }

    .carts {
      width: calc(33% - 10px);
      height: 65px;
      background-color: #bb4848;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;  
      padding: 5px;
      margin-top: 10px;
      box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.5);
      color: #ffffff;
      font-weight: bold;
      // text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.5); 
       
    }
    .label {
      font-weight: bold;
      font-size: 1.5em;
      text-align: center; 
    }
    .value {
      font-size: 1.5em;
      text-align: center;
      font-weight: bold;}

    .cart2 {
      // width: calc(100% - 5px); /* Adjust width to occupy 50% of container width with 20px margin between items */
      height: 80px;
      background-color: #E5E4E2;
      border-radius: 5px;
      display: flex;
      justify-content:space-between;
      align-items: center;
      text-aling:center;
      padding:10px;
      font-weight: bold;
      font-size:20px;
    }
    .top-row2 {
      display: flex;
      justify-content: space-between;
    }
    
    .cart3 {
      position: relative;
      width: calc(20% - 10px);
      height: 180px; 
      background-color: #E5E4E2;
      border-radius: 20px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 10px;
      transition: transform 0.3s ease-in-out;
    }
    .cart4 {
      width: calc(26% - 5px);
      height:50px;
      background-color: #bb4848;
      border-radius: 5px;
      display: flex;
      flex-direction: column; /* Align label and value vertically */
      justify-content: space-evenly; /* Evenly distribute space between label and value */
      padding: 10px;
      text-aling:center;
      color:white;
    }
    
    .stars-table {
      width: 100%;
      min-height:80px;
      border-collapse: collapse;
     
    }
    
    .stars-table caption {
      font-weight: bold;
    }
    
    .stars-table th,
    .stars-table td {
      border: 1px solid #000; /* Change border color */
      font-weight: bold; /* Make text bold */
      padding: 0.1rem;
      font-size:18px;
      text-align: center;
    
    }
    
    .stars-table th {
      background-color: #FFA000; /* Light red background color for header row */
      color:white;
      
    }
    
    .stars-table tr:hover {
      background-color: #f5f5f5; /* Change background color on hover to light gray */
    }
    
    
    .cart3:hover {
      transform: translateY(-5px);
    }
    
    .round-image {
      width: 80px; /* Adjust image size as needed */
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 10px; /* Add some space between the image and user details */
      margin:auto;
    }
    
    .round-image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    .user-details {
      display: flex;
      flex-direction: column;
      align-items: center; /* Align items in the center */
      text-align: center; /* Align text in the center */
    }
    
    .user-name {
      font-weight: bold;
      font-size: 20px;
    }
    
    .emi-id {
      color: #666;
      // font-size: 20px;
    }
    
    .line-chart-container {
      width: 100%;
      padding: 20px 0;
    }

    .line-chart-heading {
      text-align: center;
      font-size: 1.5rem;
      margin-bottom: 10px;
    }

    .grid-container {
      display: grid;
      grid-template-columns:30% 35% 35%;
      padding: 5px;
      column-gap: 5px;
    }
    .grid-container2 {
      display: grid;
      grid-template-columns: 90%;
      padding: 5px;
      column-gap: 5px;
      justify-content: space-around;
    }
    
    @media screen and (min-width: 250px) and (max-width: 600px)  {
      /* Mobile and small screens */
      .dashboard {
        background-color: #f4f4f4;
        border-radius: 10px;
        font-family: 'Roboto Slab';
        width: 100%;
        margin: auto;
      }
      .top-row2{
        flex-direction: column;
        align-items: center;
      }
      .middle-row {
        flex-direction: column;
      }
    
      .left-column {
        width: calc(100% - 0px);
        text-align: left;
      }
    
      .right-column {
        width: calc(100% - 0px);
      }
    
      .cart2{
        width: calc(100% - 10px);
        text-align: center;
        font-size:16px;
        column-gap: 5px;
      }

      .cart3,
      .cart4{
        width: calc(100% - 0px);
        text-align: center;
        margin-top: 10px;
        
         
      }
      .stars-table {
        width: 100%;
      }
    }
    @media screen and (min-width: 601px) and (max-width: 900px) {
      /* Mobile and small screens */
      .dashboard {
        background-color: #f4f4f4;
        border-radius: 10px;
        font-family: 'Roboto Slab';
        width: 100%;
        margin: auto;
      }
      .top-row2{
        flex-direction: column;
        align-items: center;
      }
      .middle-row {
        flex-direction: column;
        width: calc(100% - 0px);
      }
    
      .left-column {
        width: calc(100% - 10px);
        text-align: left;
      }
    
      .right-column {
        width: calc(100% - 10px);
        
      }
    
      .cart2{
        width: calc(100% - 10px);
        text-align: center;
        column-gap: 5px;
        font-size:18px;
       
      }

      .cart3,
      .cart4{
        width: calc(100% - 10px);
        text-align: center;
        margin-top: 10px;
         
      }
      .stars-table {
        width: 100%;
      }
    }
    
    @media screen and (min-width: 900px) and (max-width: 1150px) {
      /* Tablet screens */
     
      .left-column {
        width: calc(100% - 0px);
      }
      .cart2{
        width: calc(100% - 5px);
        text-align: center;
        padding:1px;
        font-size:20px;
      }
    
      .cart3 {
        width: calc(20% - 10px);
      }
    
      .cart4 {
        width: calc(25% - 10px);
      }
    }
    
    // @media screen and (min-width: 1150px){
    //   .left-column {
    //     width: calc(100% - 0px);
    //   }
    
    //   .cart2{
    //     width: calc(100% - 5px);
    //     text-align: center;
    //     padding:10px;
    //     font-weight: bold;
    //     font-size:20px;
    //   }

    //   .cart3 {
    //     width: calc(20% - 10px);
    //   }
    
    //   .cart4 {
    //     width: calc(25% - 10px);
    //   }
    // }
    @media screen and (max-width: 900px) {
      h4 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 18px; /* Adjust the font size as needed */
      }
    }
    @media screen and (min-width: 901px){
      h4 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
         
      }
    }
  
  `;
  const [targets, setTargets] = useState([]);
  const [currentWeekData, setCurrentWeekData] = useState(null);
  const searchTarget = async () => {
    setLoading(true);
    let branchId;

    if (roleChecker.isHOLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isASMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isBCMLevelAccess(user.role) || roleChecker.isCSMLevelAccess(user.role) || roleChecker.isRSMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) || roleChecker.isSalesTeamAccess(user.role)) {
      if (roleChecker.isHOLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role)) {
        branchId = 'zone-' + user.branch_id;
      } else if (roleChecker.isRSMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role)) {
        branchId = 'region-' + user.branch_id;
      } else if (roleChecker.isAreaLevelAccess(user.role)) {
        branchId = 'area-' + user.branch_id;
      } else if (roleChecker.isCSMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role)) {
        branchId = 'cluster-' + user.branch_id;
      } else if (roleChecker.isBMLevelAccess(user.role) || roleChecker.isBCMLevelAccess(user.role) || roleChecker.isSalesTeamAccess(user.role)) {
        branchId = 'branch-' + user.branch_id;
      } else {
        branchId = user.branch_id;
      }
      try {
        let res;
        if (user.branch_id === 'HO001') {
          res = await customerService.searchTarget({ branch_key: user.branch_id });
        } else {
         res = await customerService.searchTarget({ branch_key: branchId });
        }
        // const res = await customerService.searchTarget({ branch_key: branchId });
        let parsedResponse = typeof res === 'string' ? JSON.parse(res) : res;

        if (Array.isArray(parsedResponse)) {
          setTargets(parsedResponse);
          // Calculate and set current week's data
          const today = new Date();
          const currentWeek = getCurrentWeek(today.getDate());
          const currentWeekData = parsedResponse.map(target => ({
            ...target,
            currentWeek: target.weekly_target.find(week => week.week === `Week-${currentWeek}`)
          }));
          setCurrentWeekData(currentWeekData);
        } else {
          console.error('Data fetched is not an array:', parsedResponse);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    }

    setLoading(false);
  };
  const getCurrentWeek = (day) => {
    if (day >= 1 && day <= 7) return 1;
    if (day >= 8 && day <= 14) return 2;
    if (day >= 15 && day <= 21) return 3;
    if (day >= 22 && day <= 28) return 4;
    if (day >= 29) return 5;
    return null;
  };

  useEffect(() => {
    searchTarget();
  }, []);
  console.log('metricsData1:::',metricsData1)
  const onlineAmount = Number(metricsData1['online_collection_payment_amount_daily']);
  const cashAmount = Number(metricsData1['cash_collection_payment_amount_daily']);
  const loginAmount = Number(metricsData1['login_amount']);
  const loginCount = Number(metricsData1['login_count']);
  const sanction_count = Number(metricsData1['sanction_count']);
  const sanction_amount = Number(metricsData1['sanction_amount']);
  const disbursement_count = Number(metricsData1['disbursement_count']);
  const disbursement_amount = Number(metricsData1['disbursement_amount']);
  // console.log('loginAmount:::',loginAmount,loginCount,sanction_count,sanction_amount,disbursement_count,disbursement_amount)
  const totalAmount = onlineAmount + cashAmount;
  const resultString = totalAmount.toString();
  return (
    <div className="dashboard">
      
      {/* <h4 style={{textAlign:'center',fontWeight:'bold'}}>Today's Team Achievements</h4> */}
            <h4 style={{textAlign:'center',fontWeight:'bold'}}>Today's Team Achievements<br/>Time Left: <span style={{color:"red",fontSize:"1.5rem"}}>{hourLeft} Hours : {minuteLeft} Minutes: {secondLeft} Seconds </span></h4>
      <style>{styles}</style>
      <div className="top-row">
          <div className="cart">
            <div className="label">Zone</div>
            <div className="value">{countDetailsData && countDetailsData.branch_zone}</div>
          </div>
          <div className="cart">
            <div className="label">Region</div>
            <div className="value">{countDetailsData && countDetailsData.branch_region}</div>
          </div>
          <div className="cart">
            <div className="label">Area</div>
            <div className="value">{countDetailsData && countDetailsData.branch_area}</div>  
          </div>
          <div className="cart">
            <div className="label">Cluster</div>
            <div className="value">{countDetailsData && countDetailsData.branch_cluster}</div> 
          </div>
        
          <div className="cart">
            <div className="label">Branches</div>
            <div className="value">{countDetailsData && countDetailsData.branches}</div>
          </div>
      </div>

      <div className="top-row">
          {/* <div className="carts">
            <div className="label">Jaipur </div>
            <div className="value">{zoneData &&  zoneData.J03}</div>
          </div>
          <div className="carts">
            <div className="label">Ajmer</div>
            <div className="value">{zoneData && zoneData.AJ13}</div>
          </div>
          <div className="carts">
            <div className="label">Dausa</div>
            <div className="value">{zoneData && zoneData.D01}</div>  
          </div>
          <div className="carts">
            <div className="label">Sriganganagar</div>
            <div className="value">{zoneData && zoneData.SG26}</div> 
          </div> */}
          {zoneData?.map((cart, index) => (
        <div className="carts" key={index}>
          <div className="label">{m[cart[0]]}</div>
          <div className="value">{cart && cart[1]}</div>
        </div>
      ))}
      </div>
<div className="middle-row">
  <div className="left-column">
  <div class="grid-container">
    <div className="cart2">
    {loading ? (
  <p>Loading...</p>
) : targets.length > 0 ? (
  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
    {currentWeekData && currentWeekData.map((target) => (
      <div key={target._id}>
        {(target.key === "login_target" && currentMonthYearss===target.month_key) && (
          <b style={{ fontSize: '18px' }}>Login Target :</b>
        )}
        {target.currentWeek && (
          <>
            {user.branch_id === 'HO001' ? (
              (target.branch_key === user.branch_id && currentMonthYearss===target.month_key && target.key === "login_target") && (
                <span>&nbsp;{Math.floor(target.currentWeek.target / 6)}</span>
              )
            ) : (
              (target.approval_status === "approved" && currentMonthYearss===target.month_key && target.key === "login_target") && (
                <span>&nbsp;{Math.floor(target.currentWeek.target / 6)}</span>
              )
            )}
          </>
        )}
      </div>
    ))}
  </div>
) : (
  <p>Login Target: 0</p>
)}

    </div>
    <div className="cart2">
    
    </div>
    <div className="cart2">
     {loading ? (
  <p>Loading...</p>
) : targets.length > 0 ? (
  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
    {currentWeekData && currentWeekData.map((target) => (
      <div key={target._id}>
        {(target.key === "disbursement_target" && currentMonthYearss===target.month_key) && (
          <b style={{ fontSize: '18px' }}>Disbursement Target :</b>
        )}
        {target.currentWeek && (
          <>
            {user.branch_id === 'HO001' ? (
              (target.branch_key === user.branch_id && currentMonthYearss===target.month_key && target.key === "disbursement_target") && (
                <span>&nbsp;{Math.floor(target.currentWeek.target / 6)}</span>
              )
            ) : (
              (target.approval_status === "approved" && currentMonthYearss===target.month_key && target.key === "disbursement_target") && (
                <span>&nbsp;{Math.floor(target.currentWeek.target / 6)}</span>
              )
            )}
          </>
        )}
      </div>
    ))}
  </div>
) : (
  <p>Disbursement Target : 0 </p>
)}
    </div>
  </div>
  {roleChecker && roleChecker.isHOLevelAccess(user.role) ? (
  <>
    <div className="grid-container">
      <div className="cart2">
        Total Logins: {metricsData['today_login_count']}
      </div>
      <div className="cart2">
        Sanction Count: {metricsData['sanction_letter_count']}
      </div>
      <div className="cart2">
        Disbursement Count: {metricsData['disbursement_memo_count']}
      </div>
    </div>
    <div className="grid-container">
      <div className="cart2">
        Login Amount: ₹{metricsData['today_login_amount']}
      </div>
      <div className="cart2">
        Sanction Amount: ₹{displayhelper.displayNumberAccounting(Number(metricsData['sanction_letter_amount']))}
      </div>
      <div className="cart2">
        Disbursement Amount: ₹{displayhelper.displayNumberAccounting(Number(metricsData['disbursement_memo_amount']))}
      </div>
    </div>
  </>
) : (
  <>
    <div className="grid-container">
      <div className="cart2">
        Total Logins: {loginCount}
      </div>
      <div className="cart2">
        Sanction Count: {sanction_count}
      </div>
      <div className="cart2">
        Disbursement Count: {disbursement_count}
      </div>
    </div>
    <div className="grid-container">
      <div className="cart2">
        Login Amount: ₹{loginAmount}
      </div>
      <div className="cart2">
        Sanction Amount: ₹{sanction_amount}
      </div>
      <div className="cart2">
        Disbursement Amount: ₹{disbursement_amount}
      </div>
    </div>
  </>
)}

  {/* {roleChecker.isHOLevelAccess(user.role) &&
  <div class="grid-container">
 
    <div className="cart2">
      Colender Count:   {metricsData['colender_receive_count']}
    </div>
    <div className="cart2">
      Scheduled EMI's:     {collectionData && collectionData.schedule_emis}
    </div>
    <div className="cart2">
      Collected EMI's:    {collectionData && collectionData.collectd_emi_count}
    </div> 
  </div> 
} */}
{/* {roleChecker.isHOLevelAccess(user.role) &&
  <div class="grid-container">
 
 <div className="cart2">
   Colender Amount: ₹ { displayhelper.displayNumberAccounting(Number(metricsData['colender_receive_amount']))}
 </div>
 
 <div className="cart2">
   Scheduled EMI's Amount:  ₹{collectionData && collectionData.schedule_emis_amount}<br/>
 </div>
 <div className="cart2">
  Reconcile EMI's Amount: ₹{collectionData && collectionData.collected_emi_amount}
   
    
 </div> 
</div> } */}

{(roleChecker.isCFOAccess(user.role)) &&(

  <div class="grid-container">
 
 <div className="cart2">
  Online Collection Amount: ₹ { displayhelper.displayNumberAccounting(Number(metricsData1['online_collection_payment_amount_daily']))}
 </div>

 <div className="cart2">
  Cash Collection Amount:  ₹{displayhelper.displayNumberAccounting(Number(metricsData1['cash_collection_payment_amount_daily']))}<br/>
 </div>
 <div className="cart2">
  Net Collection Amount:  ₹ {resultString}
 </div> 
</div> )}

  </div>
</div>
 <div class="grid-container2">
    <div>
    <h4 style={{ fontWeight: 'bold',textAlign:'center'}}>⭐⭐        TODAY'S STAR RO       ⭐⭐</h4>
    <table className="stars-table">
      <thead>
        <tr>
          <th>RO Name</th>
          {/* <th>RO ID</th> */}
          <th>Branch ID</th>
          <th>Login Count</th>
        </tr>
      </thead>
      <tbody>
        {rostarData && rostarData.map((item, index) => (
          <tr key={index}>
            <td>{item.emp_name}</td>
            {/* <td>{item.emp_id}</td> */}
            <td>{item.branch_id}</td>
            <td>{item.login_count}</td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
  </div>

{weekstarData && <div>
      <h5 style={{textAlign:'center',fontWeight:'bold'}}>⭐⭐⭐⭐⭐ RO STAR'S OF THE WEEK ⭐⭐⭐⭐⭐</h5>
      <div className="top-row2">
        {weekstarData.map((item, index) => (
          <div className="cart3" key={index}>
            <div className="cart-content">
              <div className="round-image">
                <img src={usericon} alt="User" />
              </div>
              <div className="user-details">
                <div className="user-name">{item.emp_name}</div>
                {/* <div className="emi-id">Employee ID: {item.emp_id}</div> */}
                <div className="emi-id">Branch ID: {item.branch_id}</div>
                <div className="emi-id">Login Count: <b>{item.login_count}</b></div>
                
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>}
       
        
      
    </div>
  );
}

export default Dashboard2;
