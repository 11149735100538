import React, { useState, useEffect } from 'react';
import './LegalLetter.css'
import { useReactToPrint } from "react-to-print";
import logo from "../../../assets/kaabil.png"
import LrnSingnature from "../../../assets/LrnSingnature.png"
import {useParams} from 'react-router-dom'
import {letter} from "../../../_services/lettertracking.service"
const LegalLetter = (props) => {

  const LegalLetterRef = React.useRef();

  const handleSubmissionPrint = useReactToPrint({
    content: () => LegalLetterRef.current,
    pageStyle: "print"
  });

  const {id}  = useParams();
  const [formData, setFormData] = useState("")
const getLetter = async () => {
  const details = await letter.getSingleLrn(id);
  setFormData(details);
}

useEffect(() => {
  if(id){
    getLetter()
  }
}, [])
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // January is 0, so we add 1
  const date = currentDate.getDate();


  const disbursementDate = new Date(formData?.disbursement_date);

  // Extract the date parts (year, month, day)
  const years = disbursementDate.getFullYear();
  const months = (disbursementDate.getMonth() + 1).toString().padStart(2, '0'); // Ensure 2 digits
  const days = disbursementDate.getDate().toString().padStart(2, '0'); // Ensure 2 digits
  
  // Format the date as "YYYY-MM-DD"
  const formattedDate = `${days}-${months}-${years}`;
  return (
    <>
      <div className='page' style={{ marginLeft: "20px", marginRight: "20px", maxHeight: "fit-content" }}>
      <div style={{ display:"flex",justifyContent:"center"}}>
          <button onClick={handleSubmissionPrint} > Print LRN Letter</button>
        </div>
        <link rel='stylesheet' media='print' href='/LegalLetter.css' />
        <div ref={LegalLetterRef} className='letter' style={{ fontSize: '23px' }}>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <img src={logo} alt="KFPL Logo" style={{ width: "30vw", float: "left" }} />
            <span style={{ float: "right", width: "40%" }}><b>Office Address</b> : Kaabil Towers, Plot No. 1, Scheme No. 1, Income Tax Colony, Model Town, Jagatpura, Jaipur Rajasthan 302017</span>
          </div>

          <div className='start' style={{ marginTop: "85px" }} >
            <span>क्रमांक:-KFPL/{date}/LEGAL</span>                        <span >दिनांक:-{date}/{month}/{year}</span>

          </div>
          <br />
          <h1 style={{ margin: "auto", borderBottom: "5px solid", width: "42%" }}>LOAN RECALL NOTICE</h1>
          <br />
          <div className='content'>
            <b>बनाम :</b>
          </div>

          <div className='content'>
            {formData?.title_name} <b>{formData?.name}</b> {formData?.relation} {formData?.title_father} <b>{formData?.father_name}</b> (आवेदक)
          </div>

          <div className='content'>
            पता:- {formData?.address}
          </div>
          <div className='content'>
            मो:- <b>{formData?.contact}</b>
          </div>
          <br />
          <div style={{ textAlign: "justify" }}>
            <div className='content'>
              महोदय,
            </div>
            <div className='content' style={{ marginTop: "5px" }}>
              कंपनी काबिल फाइनेंस प्राइवेट लिमिटेड रजिस्टर्ड कार्यालय 328 सी, कुसुम विहार, रामनगरिया रोड, जगतपुरा, जयपुर ,राजस्थान- 302025, जो कि कंपनीज एक्ट 2013 के तहत कार्य करती है तथा रिज़र्व बैंक ऑफ इंडिया से रजिस्टर्ड एक गैर बैंकिंग वित्तीय संस्था हैं, जिसका मुख्य कार्य अपने ग्राहकों को वित्तीय सुविधा प्रदान करना है।
            </div>
            <br />

            <div className='content'>
              1.	यह  कि आप द्वारा दिनांक- <b>{formattedDate}</b> को ऋण अनुबंध संख्या- <b>{formData?.account_number}</b> निष्पादित कर इस कंपनी से रु. <b>{formData?.sanction_amount}</b>/-  की वित्तीय सुविधा प्राप्त की गई हैं। ऋण अनुबंध की शर्तों के अनुसार इस ऋण एवम इस पर देय ब्याज का पुनर्भुगतान  आपके द्वारा रु <b>{formData?.emi_amount}</b>/- की <b>{formData?.tenure}</b> मासिक किस्तों में किया जाना तय हुआ ।
            </div>
            <br />
            <div className='content'>
              2.	यह  कि उक्त ऋण आपके द्वारा दिए गए इस आश्वाशन पर आपको उपलब्ध कराया गया था कि आप निर्धारित की गई सभी मासिक किस्तों की बकाया देय तिथियों/माँग पर नियमित रूप से पुनर्भुगतान करेंगे। आप द्वारा ऋण अनुबंध में किस्तों के नियमित भुगतान के संबंध में की गई प्रतिबद्धता की पालना नहीं की गई हैं। इस नोटिस के जारी होने के दिनांक तक आपके विरुद्ध देय कुल बकाया किस्तों  (overdue EMI) तथा अन्य प्रभार शुल्क खर्चे आदि की कुल राशि <b>{formData?.addCharge}</b>/- हैं।
            </div>
            <br />
            <div className='content'>
              3.	इस प्रकार आप अपने विरुद्ध सक्षम न्यायालय में उचित कानूनी कार्यवाही हेतु उतरदायी हैं। लिहाजा जरिए नोटिस आपको सूचित किया जाता है कि इस नोटिस प्राप्ति के 7 दिवस में इस कंपनी के रजिस्टर्ड   कार्यालय/ शाखा   कार्यालय में पहुंच कर आपके जिम्मे समस्त बकाया राशि का भुगतान कर निर्धारित रसीद हासिल कर अपने खाते को बंद कर दें अन्यथा आपके विरुद्ध इस कंपनी द्वारा निष्पादित ऋण अनुबंध की शर्तों के अनुसार कानूनी कार्यवाही की जाएगी जिस के समस्त हर्जे खर्चे के लिए आप खुद जिम्मेदार होंगे।
            </div>
            <div className='content'>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; आशा हैं कि आप उपरोक्त बकाया राशि की निर्धारित अवधि में अदायगी कर देंगे।
            </div>
          </div>
          <br />
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <div style={{ textAlign: 'right', marginRight: '10px' }}>
              <div className='content'>
                भवदीय
              </div>
              <div>
                <img src={LrnSingnature} alt="Signature" style={{ height: "130px", marginLeft: "233px", transform: "rotate(-85deg)"}} />
              </div>
              <div className='content'>
                लीगल अधिकारी
              </div>
              <div className='content'>
                काबिल फाइनेंस प्राइवेट लिमिटेड
              </div>
            </div>

          </div>

          <div className='content' style={{pageBreakBefore:"always"}}>
            प्रतिलिपि :- <br />
          </div>
  {formData &&  formData?.coapplicant_guarantor.map((item, i) => (
    <div key={i}>
      <div className='content'>
        {i + 1}) {item?.title_name} {item?.name} {item?.relation} {item?.title_father} {item?.father_name} {item?.cosigner}
      </div>
      <div className='content'>
        पता:- {item?.address} , मो:- {item?.contact}
      </div><br/>
    </div>
  ))
}
        </div>
      </div>

    </>
  )
}

export default LegalLetter