import React from "react";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";
import { PrivateRoute } from "../helperComponents";
import { employeeService } from "../_services";
import { Employee } from "../employee";
import Intro from "../_components/Intro"
import Home from "../_components/Home";
import AdminHome from "../_components/Admin/AdminHome";
import ResetPassword from "../employee/ForgetPassword.jsx" ;

function App() {
  const { pathname } = useLocation();
  const [user, setUser] = React.useState({});

  var inactivityTime = function () {
    var time;
    window.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onkeydown = resetTimer;

    async function logout() {
      await employeeService.logout()
    }

    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(logout, 10800000)
      // console.log('Timer has been reset')
    }
  };

  window.onload = function () {
    inactivityTime();
  }

  React.useEffect(() => {
    const subscription = employeeService.user.subscribe((x) => setUser(x));
    return subscription.unsubscribe;
  }, []);

  return (
    <div className={"app-container" + (user && " bg-light")}>
      {/* <Nav />
      <Alert /> */}
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        <Redirect exact from="/" to="/employee/login" />
        <PrivateRoute path="/home/admin" component={AdminHome} />
        <PrivateRoute path="/home" component={Home} />
        <Route path="/intro" component={Intro} />
        <Route path="/employee" component={Employee} />
        <Redirect from="*" to="/" />
      </Switch>
    </div>
  );
}

export { App };
