import React from 'react';
import { useState } from 'react';
import { Modal, Box, Button, Paper, Typography } from '@material-ui/core';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import { useEffect } from 'react';
import * as Yup from "yup";
import { ledgerService } from '../../_services/ledger.service';
import { AddOutlined } from '@material-ui/icons';
import { employeeService } from '../../_services';
import { Table, Thead, Tbody, Tr, Td, Th } from 'react-super-responsive-table';
import { CircularProgress } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import convertToWords from './NumToWordConverter';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

export const CheckInModal = (props) => {
   let cashRequestId=props.id
   let approval_amount=props.approval_amount
   console.log("Test values",cashRequestId,approval_amount)
    const [open, setOpen] = useState(false)
    const [ledgers, setLedgers] = useState([])
    const [debitLedgers, setDebitLedgers] = useState([])
    const [loading, setLoading] = useState(false)
    const [employees, setEmployees] = useState({})
    let debitLedger = props.debit
    // let creditLedger = props.credit
    const user = employeeService.userValue;
    let transaction = props.transaction
    let current_balance


    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const onSubmit = (fields, { setSubmitting }) => {
        setLoading(true)
        if (current_balance >= fields.cash_summary.total_amount) {
            ledgerService.addLedgerTransaction({
                ...fields,
                product_type: 'goldloans',
                transaction_source: 'Manual',
                transaction_type: 'Debit',
                verification_details: {
                    requester_emp_id: user.emp_id,
                    handed_over_to_emp_id: fields.handed_over_to_emp_id
                }
            })
                .then((res) => {
                    window.alert('Ledger Transaction Saved')
                    setLoading(false)
                    window.location.reload()
                    console.log(res)
                })
                .catch(err => {
                    window.alert('Some error occurred ')
                    console.log(err)
                    setLoading(false)
                })
        } else {
            window.alert('Amount can not be greator than ledger balance')
            setLoading(false)
        }
        setSubmitting(false)
    }

    let initialValues

    if (props.viewOnly && props.transaction) {
        initialValues = {
            emp_name: user.first_name,
            emp_id: transaction && transaction.verification_details && transaction.verification_details.approver_emp_id,
            debit_ledger_id: '',
            credit_ledger_id: '',
            cash_summary: {
                count_of_2000_rupee_note: transaction && transaction.cash_summary && transaction.cash_summary.count_of_2000_rupee_note,
                count_of_500_rupee_note: transaction && transaction.cash_summary && transaction.cash_summary.count_of_500_rupee_note,
                count_of_200_rupee_note: transaction && transaction.cash_summary && transaction.cash_summary.count_of_200_rupee_note,
                count_of_100_rupee_note: transaction && transaction.cash_summary && transaction.cash_summary.count_of_100_rupee_note,
                count_of_50_rupee_note: transaction && transaction.cash_summary && transaction.cash_summary.count_of_50_rupee_note,
                count_of_20_rupee_note: transaction && transaction.cash_summary && transaction.cash_summary.count_of_20_rupee_note,
                count_of_10_rupee_note: transaction && transaction.cash_summary && transaction.cash_summary.count_of_10_rupee_note,
                count_of_5_rupee_coin: transaction && transaction.cash_summary && transaction.cash_summary.count_of_5_rupee_coin,
                count_of_2_rupee_coin: transaction && transaction.cash_summary && transaction.cash_summary.count_of_2_rupee_coin,
                count_of_1_rupee_coin: transaction && transaction.cash_summary && transaction.cash_summary.count_of_1_rupee_coin,
                total_amount: transaction && transaction.cash_summary && transaction.cash_summary.total_amount,
            },
            handed_over_to_emp_id: null,
            remark: transaction.remark
        }
    } else {
        initialValues = {
            emp_name: user.first_name,
            emp_id: user.emp_id,
            debit_ledger_id: '',
            credit_ledger_id: '',
            cash_summary: {
                count_of_2000_rupee_note: null,
                count_of_500_rupee_note: null,
                count_of_200_rupee_note: null,
                count_of_100_rupee_note: null,
                count_of_50_rupee_note: null,
                count_of_20_rupee_note: null,
                count_of_10_rupee_note: null,
                count_of_5_rupee_coin: null,
                count_of_2_rupee_coin: null,
                count_of_1_rupee_coin: null,
                total_amount: 0,
            },
            remark: '',
            cashRequestId:cashRequestId,
            approval_amount:approval_amount
        }
    }

    let validationSchema = Yup.object().shape({
        debit_ledger_id: Yup.string().notOneOf([Yup.ref('credit_ledger_id')], 'Debit and credit ledger can not be same').required('This field is required'),
        credit_ledger_id: Yup.string().notOneOf([Yup.ref('debit_ledger_id')], 'Credit and debit ledger can not be same').required('This field is required'),
        cash_summary: Yup.object().shape({
            count_of_2000_rupee_note: Yup.number().nullable(true).min(0, 'count can not be negative'),
            count_of_500_rupee_note: Yup.number().nullable(true).min(0, 'count can not be negative'),
            count_of_200_rupee_note: Yup.number().nullable(true).min(0, 'count can not be negative'),
            count_of_100_rupee_note: Yup.number().nullable(true).min(0, 'count can not be negative'),
            count_of_50_rupee_note: Yup.number().nullable(true).min(0, 'count can not be negative'),
            count_of_20_rupee_note: Yup.number().nullable(true).min(0, 'count can not be negative'),
            count_of_10_rupee_note: Yup.number().nullable(true).min(0, 'count can not be negative'),
            count_of_5_rupee_coin: Yup.number().nullable(true).min(0, 'count can not be negative'),
            count_of_2_rupee_coin: Yup.number().nullable(true).min(0, 'count can not be negative'),
            count_of_1_rupee_coin: Yup.number().nullable(true).min(0, 'count can not be negative'),
            total_amount: Yup.number().min(1, 'Total amount must be greater than zero'),
        }),
        handed_over_to_emp_id: Yup.number().notOneOf([user.emp_id]).required((debitLedger === 'hoLedgers'), 'This field is required'),
    })

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "850px",
        height: "580px",
        bgcolor: "background.paper",
        border: "2px solid #000",
        overflow: "scroll",
        boxShadow: 24,
        p: 4,
    };

    function getCurrentBalance(values) {
        let ledg = ledgers.filter(led => { return led._id === values.debit_ledger_id })
        current_balance = ledg[0].current_balance
        return current_balance.toLocaleString("en-IN", {
            // maximumFractionDigits: 2,
            style: "currency",
            currency: "INR",
        })
    }

    function GetTotalAmount() {
        const { values, setFieldValue } = useFormikContext();
        useEffect(() => {
            let total_amount = (values.cash_summary.count_of_2000_rupee_note) * 2000 +
                (values.cash_summary.count_of_500_rupee_note) * 500 +
                (values.cash_summary.count_of_200_rupee_note) * 200 +
                (values.cash_summary.count_of_100_rupee_note) * 100 +
                (values.cash_summary.count_of_50_rupee_note) * 50 +
                (values.cash_summary.count_of_20_rupee_note) * 20 +
                (values.cash_summary.count_of_10_rupee_note) * 10 +
                (values.cash_summary.count_of_5_rupee_coin) * 5 +
                (values.cash_summary.count_of_2_rupee_coin) * 2 +
                (values.cash_summary.count_of_1_rupee_coin) * 1
            setFieldValue('cash_summary.total_amount', total_amount)
        }, [values.cash_summary])
        return null;
    }

    function getLedgers() {
        let ho_ledgers = []
        let other_ledgers = []
        ledgerService.getLedgerList().then((ledgers) => {
            let debit_led
            if (ledgers.length > 0) {
                setLedgers([...ledgers]);
                {
                    user.role === 'BM' ?
                    debit_led = ledgers.filter(led => {
                        return led.branch_id === user.branch_id
                    })
                    :
                    debit_led = ledgers
                }
                setDebitLedgers(debit_led)
            }
        });
    }

    const getEmployees = () => {
        employeeService.getAll()
            .then(res => {
                if (res.length > 0) {
                    let list = res.sort(function (a, b) {
                        if (a.first_name.toLowerCase() < b.first_name.toLowerCase()) {
                            return -1;
                        }
                        if (a.first_name.toLowerCase() > b.first_name.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    })
                    setEmployees(list)
                }
            })
    }

    useEffect(() => {
        if(open){
            getLedgers()
            getEmployees()
        }
    }, [open])

    return (
        <div>
            <Button
                onClick={handleOpen}
                variant={!props.viewOnly ? 'outlined' : null}
                style={{ color: 'blue' }}>
                {!props.viewOnly ?
                    <React.Fragment>{!cashRequestId?<><AddOutlined />add</>:"Approve"}</React.Fragment> :
                    <React.Fragment>View</React.Fragment>}
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box id="modal-modal-title" variant="h6" component="h2" style={{ display: 'flex', flexDirection: 'column', alignItem: 'center' }}>
                        <Box style={{ fontSize: 'medium' }} id="modal-modal-title" variant="h6" component="h2">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                            >
                                {({ isSubmitting, values, setFieldValue, errors, touched }) => {
                                    return (
                                        <fieldset disabled={props.viewOnly}>
                                            <Form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px' }}>
                                                <GetTotalAmount />
                                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                                    <h4 style={{ width: '65%' }}>Cash Management <ArrowForwardIcon /> {!props.viewOnly ? <React.Fragment>{debitLedger === 'hoLedgers' ? 'Check Out' : 'Check In'}<ArrowForwardIcon /> Add</React.Fragment> : <React.Fragment>Details</React.Fragment>}</h4>
                                                    {values && values.debit_ledger_id && (values.debit_ledger_id !== '') && <span><AccountBalanceWalletIcon /> Current Balance : {getCurrentBalance(values)}</span>}
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid black', width: '100%', alignItems: 'center', margin: '20px', padding: '20px' }}>
                                                    {!props.viewOnly &&
                                                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                                            <div className="form-group col-6">
                                                                <h6 style={{ display: 'flex' }}><div style={{ color: 'red', width: '10px' }}>*</div>Debit Ledger:</h6>
                                                                <Field
                                                                    name="debit_ledger_id"
                                                                    as="select"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.debit_ledger_id && touched.debit_ledger_id ? " is-invalid" : "")
                                                                    }
                                                                >
                                                                    <option value=""></option>
                                                                    {debitLedgers && debitLedgers.map((ledger) => (
                                                                        <option key={ledger._id} value={ledger._id}>
                                                                            {ledger.ledger_name} - {ledger.branch_id}
                                                                        </option>
                                                                    ))}
                                                                </Field>
                                                                <ErrorMessage
                                                                    name="debit_ledger_id"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </div>
                                                            <div className="form-group col-6">
                                                                <h6 style={{ display: 'flex' }}><div style={{ color: 'red', width: '10px' }}>*</div> Credit Ledger :</h6>
                                                                <Field
                                                                    name="credit_ledger_id"
                                                                    as="select"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.credit_ledger_id && touched.credit_ledger_id ? " is-invalid" : "")
                                                                    }
                                                                >
                                                                    <option value=""></option>
                                                                    {ledgers && ledgers.map((ledger) => (
                                                                        <option key={ledger._id} value={ledger._id}>
                                                                            {ledger.ledger_name} - {ledger.branch_id}
                                                                        </option>
                                                                    ))}
                                                                </Field>
                                                                <ErrorMessage
                                                                    name="credit_ledger_id"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </div>
                                                            {debitLedger === 'hoLedgers' && <div className="form-group col-6">
                                                                <h6 style={{ display: 'flex' }}><div style={{ color: 'red', width: '10px' }}>*</div>Handed Over To :</h6>
                                                                <Field
                                                                    name="handed_over_to_emp_id"
                                                                    as="select"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.handed_over_to_emp_id && touched.handed_over_to_emp_id ? " is-invalid" : "")
                                                                    }
                                                                >
                                                                    <option value=""></option>
                                                                    {employees.length>0 && employees.map((emp) => (
                                                                        <option key={emp._id} value={emp.emp_id}>
                                                                            {emp.emp_id} - {emp.first_name}
                                                                        </option>
                                                                    ))}
                                                                </Field>
                                                                <ErrorMessage
                                                                    name="handed_over_to_emp_id"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </div>}
                                                            <div className="form-group col-6">
                                                                <h6>Employee Name :</h6>
                                                                <Field
                                                                    type="text"
                                                                    name="emp_name"
                                                                    className="form-control"
                                                                    disabled
                                                                />
                                                            </div>
                                                            <div className="form-group col-6">
                                                                <h6>Employee Id :</h6>
                                                                <Field
                                                                    type="number"
                                                                    name="emp_id"
                                                                    className="form-control"
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>}
                                                    <Table style={{ textAlign: 'center' }}>
                                                        <Thead >
                                                            <Tr>
                                                                <Th style={{ width: '33%', textAlign: 'center', border: '1px solid black', padding: '5px' }}>Amount</Th>
                                                                <Th style={{ width: '33%', textAlign: 'center', border: '1px solid black', padding: '5px' }}>Count of Notes</Th>
                                                                <Th style={{ width: '33%', textAlign: 'center', border: '1px solid black', padding: '5px' }}>Total Amount</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            <Tr>
                                                                <Td style={{ border: '1px solid black' }}>₹ 2000</Td>
                                                                <Td style={{ border: '1px solid black' }}>
                                                                    <Field
                                                                        type="number"
                                                                        name="cash_summary.count_of_2000_rupee_note"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors && errors.cash_summary && errors.cash_summary.count_of_2000_rupee_note && touched && touched.cash_summary && touched.cash_summary && touched.cash_summary.count_of_2000_rupee_note
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="cash_summary.count_of_2000_rupee_note"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </Td>
                                                                <Td style={{ border: '1px solid black' }}>
                                                                    {((values.cash_summary.count_of_2000_rupee_note) * 2000).toLocaleString("en-IN", {
                                                                        // maximumFractionDigits: 2,
                                                                        style: "currency",
                                                                        currency: "INR",
                                                                    })}/-
                                                                </Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td style={{ border: '1px solid black' }}>₹ 500</Td>
                                                                <Td style={{ border: '1px solid black' }}>
                                                                    <Field
                                                                        type="number"
                                                                        name="cash_summary.count_of_500_rupee_note"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors && errors.cash_summary && errors.cash_summary.count_of_500_rupee_note && touched && touched.cash_summary && touched.cash_summary.count_of_500_rupee_note
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="cash_summary.count_of_500_rupee_note"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </Td>
                                                                <Td style={{ border: '1px solid black' }}>
                                                                    {((values.cash_summary.count_of_500_rupee_note) * 500).toLocaleString("en-IN", {
                                                                        // maximumFractionDigits: 2,
                                                                        style: "currency",
                                                                        currency: "INR",
                                                                    })}/-
                                                                </Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td style={{ border: '1px solid black' }}>₹ 200</Td>
                                                                <Td style={{ border: '1px solid black' }}>
                                                                    <Field
                                                                        type="number"
                                                                        name="cash_summary.count_of_200_rupee_note"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors && errors.cash_summary && errors.cash_summary.count_of_200_rupee_note && touched && touched.cash_summary && touched.cash_summary.count_of_200_rupee_note
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="cash_summary.count_of_200_rupee_note"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </Td>
                                                                <Td style={{ border: '1px solid black' }}>
                                                                    {((values.cash_summary.count_of_200_rupee_note) * 200).toLocaleString("en-IN", {
                                                                        // maximumFractionDigits: 2,
                                                                        style: "currency",
                                                                        currency: "INR",
                                                                    })}/-
                                                                </Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td style={{ border: '1px solid black' }}>₹ 100</Td>
                                                                <Td style={{ border: '1px solid black' }}>
                                                                    <Field
                                                                        type="number"
                                                                        name="cash_summary.count_of_100_rupee_note"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors && errors.cash_summary && errors.cash_summary.count_of_100_rupee_note && touched && touched.cash_summary && touched.cash_summary.count_of_100_rupee_note
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="cash_summary.count_of_100_rupee_note"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </Td>
                                                                <Td style={{ border: '1px solid black' }}>
                                                                    {((values.cash_summary.count_of_100_rupee_note) * 100).toLocaleString("en-IN", {
                                                                        // maximumFractionDigits: 2,
                                                                        style: "currency",
                                                                        currency: "INR",
                                                                    })}/-
                                                                </Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td style={{ border: '1px solid black' }}>₹ 50</Td>
                                                                <Td style={{ border: '1px solid black' }}>
                                                                    <Field
                                                                        type="number"
                                                                        name="cash_summary.count_of_50_rupee_note"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors && errors.cash_summary && errors.cash_summary.count_of_50_rupee_note && touched && touched.cash_summary && touched.cash_summary.count_of_50_rupee_note
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="cash_summary.count_of_50_rupee_note"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </Td>
                                                                <Td style={{ border: '1px solid black' }}>
                                                                    {((values.cash_summary.count_of_50_rupee_note) * 50).toLocaleString("en-IN", {
                                                                        // maximumFractionDigits: 2,
                                                                        style: "currency",
                                                                        currency: "INR",
                                                                    })}/-
                                                                </Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td style={{ border: '1px solid black' }}>₹ 20</Td>
                                                                <Td style={{ border: '1px solid black' }}>
                                                                    <Field
                                                                        type="number"
                                                                        name="cash_summary.count_of_20_rupee_note"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors && errors.cash_summary && errors.cash_summary.count_of_20_rupee_note && touched && touched.cash_summary && touched.cash_summary.count_of_20_rupee_note
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="cash_summary.count_of_20_rupee_note"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </Td>
                                                                <Td style={{ border: '1px solid black' }}>
                                                                    {((values.cash_summary.count_of_20_rupee_note) * 20).toLocaleString("en-IN", {
                                                                        // maximumFractionDigits: 2,
                                                                        style: "currency",
                                                                        currency: "INR",
                                                                    })}/-
                                                                </Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td style={{ border: '1px solid black' }}>₹ 10</Td>
                                                                <Td style={{ border: '1px solid black' }}>
                                                                    <Field
                                                                        type="number"
                                                                        name="cash_summary.count_of_10_rupee_note"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors && errors.cash_summary && errors.cash_summary.count_of_10_rupee_note && touched && touched.cash_summary && touched.cash_summary.count_of_10_rupee_note
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="cash_summary.count_of_10_rupee_note"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </Td>
                                                                <Td style={{ border: '1px solid black' }}>
                                                                    {((values.cash_summary.count_of_10_rupee_note) * 10).toLocaleString("en-IN", {
                                                                        // maximumFractionDigits: 2,
                                                                        style: "currency",
                                                                        currency: "INR",
                                                                    })}/-
                                                                </Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td style={{ border: '1px solid black' }}>₹ 5</Td>
                                                                <Td style={{ border: '1px solid black' }}>
                                                                    <Field
                                                                        type="number"
                                                                        name="cash_summary.count_of_5_rupee_coin"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors && errors.cash_summary && errors.cash_summary.count_of_5_rupee_coin && touched && touched.cash_summary && touched.cash_summary.count_of_5_rupee_coin
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="cash_summary.count_of_5_rupee_coin"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </Td>
                                                                <Td style={{ border: '1px solid black' }}>
                                                                    {((values.cash_summary.count_of_5_rupee_coin) * 5).toLocaleString("en-IN", {
                                                                        // maximumFractionDigits: 2,
                                                                        style: "currency",
                                                                        currency: "INR",
                                                                    })}/-
                                                                </Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td style={{ border: '1px solid black' }}>₹ 2</Td>
                                                                <Td style={{ border: '1px solid black' }}>
                                                                    <Field
                                                                        type="number"
                                                                        name="cash_summary.count_of_2_rupee_coin"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors && errors.cash_summary && errors.cash_summary.count_of_2_rupee_coin && touched && touched.cash_summary && touched.cash_summary.count_of_2_rupee_coin
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="cash_summary.count_of_2_rupee_coin"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </Td>
                                                                <Td style={{ border: '1px solid black' }}>
                                                                    {((values.cash_summary.count_of_2_rupee_coin) * 2).toLocaleString("en-IN", {
                                                                        // maximumFractionDigits: 2,
                                                                        style: "currency",
                                                                        currency: "INR",
                                                                    })}/-
                                                                </Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td style={{ border: '1px solid black' }}>₹ 1</Td>
                                                                <Td style={{ border: '1px solid black' }}>
                                                                    <Field
                                                                        type="number"
                                                                        name="cash_summary.count_of_1_rupee_coin"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors && errors.cash_summary && errors.cash_summary.count_of_1_rupee_coin && touched && touched.cash_summary && touched.cash_summary.count_of_1_rupee_coin
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="cash_summary.count_of_1_rupee_coin"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </Td>
                                                                <Td style={{ border: '1px solid black' }}>
                                                                    {((values.cash_summary.count_of_1_rupee_coin) * 1).toLocaleString("en-IN", {
                                                                        // maximumFractionDigits: 2,
                                                                        style: "currency",
                                                                        currency: "INR",
                                                                    })}/-
                                                                </Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td align='end' colSpan='2' style={{ border: '1px solid black' }}>
                                                                    <b>Grand Total</b>
                                                                </Td>
                                                                <Td style={{ border: '1px solid black' }}>
                                                                    <b>{values.cash_summary.total_amount.toLocaleString("en-IN", {
                                                                        // maximumFractionDigits: 2,
                                                                        style: "currency",
                                                                        currency: "INR",
                                                                    })}/-</b>
                                                                    {errors && errors.cash_summary && errors.cash_summary.total_amount ? <div style={{ color: 'red', fontSize: 'small' }}>{errors.cash_summary.total_amount}</div> : <div>{convertToWords(values.cash_summary.total_amount)}</div>}
                                                                </Td>
                                                            </Tr>
                                                        </Tbody>
                                                    </Table>
                                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', margin: '20px', alignItems: 'center' }}>
                                                        <div className="form-group col-7" style={{ paddingLeft: '0px' }}>
                                                            <h6>Remark :</h6>
                                                            <Field
                                                                type="text"
                                                                name="remark"
                                                                placeholder='Remark ...'
                                                                className={
                                                                    "form-control" +
                                                                    (errors.remark && touched.remark
                                                                        ? " is-invalid"
                                                                        : "")
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="remark"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                        {!props.viewOnly &&
                                                            <React.Fragment>
                                                                {loading === false ?
                                                                    <Button
                                                                        type='submit'
                                                                        style={{ height: '30px' }} variant='outlined' color='primary' size="small"
                                                                    // className="btn btn-primary"
                                                                    >
                                                                        {debitLedger === 'hoLedgers' ? 'Check Out' : 'Check In'}
                                                                    </Button> :
                                                                    <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                            </React.Fragment>}
                                                    </div>
                                                    {
                                                        console.log(ledgers, user)
                                                    }
                                                </div>
                                            </Form>
                                        </fieldset>)
                                }}
                            </Formik>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}