import React from 'react';
import { misService } from '../../_services';
import { useState } from 'react';
import { useEffect } from 'react';
import datehelper from '../../_helpers/datehelper';
import { ColendingModal } from './GoldColendingModal';
import { Button} from "@material-ui/core";
import { history } from '../../_helpers';
import { CircularProgress } from '@material-ui/core'

export const GoldColendingList = () => {
    const [datas, setDatas]=useState([])
    const [searchValue, setSearchValue] = useState('');
    const [show,setShow]=useState(false)
    useEffect(
        ()=>{
            async function getColending () {
                await misService.getColendingDetails()
                .then(response=> {
                  setDatas(response)
                  setShow(true)
                })
            }
            getColending()
        },[])
  const getLoanByColendingLoanNo = async (e) => {
    if (e.key === 'Enter' || e.type === 'click') {
      await misService.getLoanByColendingLoanNo({ loan_account: searchValue })
        .then(response => {
          setDatas(response)
          setShow(true)
        })
        .catch((err) => console.log(err))
    }
  }
        
    return (
        <div>
            <div style={{display:'flex'}}><h1>Co-Lending List</h1> <ColendingModal/></div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <input
            style={{ border: '1px solid #3760F1', borderRadius: "5px", height: '40px', width: "16rem"}}
            placeholder="Enter Colending Bank Number"
            label="Search by Colending Bank Num"
            name="colending_loan_account"
            type="text" value={searchValue}
            onChange={(e) => setSearchValue(e.target.value.trim().toUpperCase())}
            onKeyPress={getLoanByColendingLoanNo}
          />
          <Button style={{ background: '#3760F1', color: 'white', marginLeft: '7px' ,height:"40px",marginRight:"3rem"}}
            onClick={getLoanByColendingLoanNo}
          >Search</Button>.
          </div>
          <br/>
          <br/>
            <table>
                <thead>
                    <tr>
                        <th style={{width:'20%'}} >Loan Account</th>
                        <th style={{width:'20%'}} >Bank Account</th>
                        <th style={{width:'20%'}} >Date</th>
                        <th style={{width:'15%'}} >Disbursement Amount</th>
                        <th style={{width:'15%'}} >PF Charges</th>
                        <th style={{width:'15%'}} >Sanction Amount</th>
                        <th style={{width:'20%'}} >View Loan</th>
                    </tr>
                </thead>
                <tbody>
                {
                    datas.length>0?datas.slice(0,24).map((data,idx)=>
                        <tr key={idx}>
                        <td>{data.loan&&data.loan.length>0&&<>{data.loan[0].loan_account}</>}</td>
                        <td>{data.loan_account}</td>
                        <td>{datehelper.displayDate(new Date(data.disburse_date))}</td>
                        <td>{data.disburse_amount}</td>
                        <td>{data.pf}</td>
                        <td>{data.sanction_amount}</td>
                        <td><Button onClick={()=>history.push(`/home/goldloans?loan_account=${data.loan&&data.loan.length>0&&data.loan[0].loan_account}`,data)}
                        style={{color:"blue"}}
                        >View&nbsp;Loan</Button></td>
                    </tr>
                    ):
                <tr>
                  <td colSpan={7} style={{ textAlign: 'center', fontSize: "20px" }}>
                    {(datas.length>0&&show)?<div style={{ display: 'flex', justifyContent: 'space-around' }}><CircularProgress /></div>:"Data Not Found"}
                  </td>
              </tr>
                }
                </tbody>
            </table>
           {
             console.log(datas)
           }
        </div>
    )
}