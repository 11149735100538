import { fetchWrapper } from "../_helpers/fetch-wrapper";
const baseUrl = process.env.REACT_APP_API_URL;

export const uploadSmsData=(data)=> {
    return fetchWrapper.post(`${baseUrl}/sms/singlesms`, data);
}

export const bulkSmsData = (data) =>{
        return fetch(`${baseUrl}/sms/bulksms`, {
          method: "POST",
          body: data,
          headers:fetchWrapper.authHeader(`${baseUrl}/sms/bulksms`),
        });
      }



