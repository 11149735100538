import { fetchWrapper } from "../_helpers/fetch-wrapper";
const apiUrl = process.env.REACT_APP_API_URL;

const baseUrl = `${apiUrl}/cibils`;


const getAll = () => {
    return fetchWrapper.get(`${baseUrl}`)
}

const create = (params) => {
    return fetchWrapper.post(`${baseUrl}/request`, params)
}

const upload = (params) => {
    console.log(params)
    return fetchWrapper.post(`${baseUrl}/report`, params)
}

const cibilRequests = (params)=>{
    console.log(params)
    return fetchWrapper.post(`${baseUrl}/requests`, params)
}

const getById = (params) => {
    console.log(params, "id front here")
    return fetchWrapper.post(`${baseUrl}/request-by-id`, params)
}
const highMarkCreate = (params) => {
    return fetchWrapper.post(`${baseUrl}/highmarkrequest`, params)
}
const checkByAadharAndId=(params)=>{
    return fetchWrapper.post(`${baseUrl}/checkbyaadharandid`, params)
}
export const cibil = {
    getAll,
    create,
    upload,
    cibilRequests,
    getById,
    highMarkCreate,
    checkByAadharAndId
}