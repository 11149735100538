import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { employeeService, loanService } from '../../../../_services';
import { Button } from '@material-ui/core';
import datehelper from '../../../../_helpers/datehelper';
import displayhelper from '../../../../_helpers/displayhelper';


const DisbursementDetailVerificationModal = (props) => {

    const user = employeeService.userValue;
    const customer = props.customer;

    const [open, setOpen] = React.useState(false);
    const [deduction, setDeduction] = useState(0)


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const addVerification = () => {
        handleClose()
        let params = customer.loan;
        let updateObj = { loan: { ...params, verification: true, verified_by: user.emp_id, verified_date: new Date(), disbursement_deduction:deduction } }
        console.log(updateObj)
        loanService.update(customer._id, updateObj)
            .then((data) => {
                alert("Successfully Verified")
                window.location.reload();
            })
            .catch((err) => {
                console.log(err)
                alert("Something went wrong")
            })
    }

    useEffect(() => {
      deductionAmount()
    }, [open])
    

    const deductionAmount = () => {
        let deductionAmount = 0
        if (customer && customer.loan && customer.loan.processing_fees) {
            deductionAmount = deductionAmount + Number(customer.loan.processing_fees)
        }
        if (customer && customer.loan && customer.loan.processing_fees) {
            let gst = Number(customer.loan.processing_fees) * 18 / 100
            deductionAmount = deductionAmount + gst
        }
        if (customer && customer.loan && customer.loan.stamp_duty) {
            deductionAmount = deductionAmount + Number(customer.loan.stamp_duty)
        }
        if (customer && customer.loan && customer.loan.insurance_amount) {
            deductionAmount = deductionAmount + Number(customer.loan.insurance_amount)
        }
        if (customer && customer.loan && customer.loan.imd_deduction) {
            deductionAmount = deductionAmount + Number(customer.loan.imd_deduction)
        }
        if (customer && customer.loan && customer.loan.other_charges) {
            deductionAmount = deductionAmount + Number(customer.loan.other_charges)
        }
        if (customer && customer.loan && customer.loan.internal_bt_deduction) {
            deductionAmount = deductionAmount + Number(customer.loan.internal_bt_deduction)
        }
        
        if (customer && customer.loan && customer.loan.pre_emi_amount) {
            deductionAmount = deductionAmount + Number(customer.loan.pre_emi_amount)
        }
        setDeduction(deductionAmount)
    }

    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen}>Verify</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>Do you really want to confirm and verify the details ?</DialogTitle>
                <DialogContentText>
                    <table style={{ fontSize: "15px", width: "70%", marginLeft: "15%" }}>
                    <tr style={{ border: "1px solid" }}>
                            <th style={{ border: "1px solid", textAlign: "left" }}>Disbursement Date</th>
                            <th style={{ border: "1px solid", textAlign: "right" }}>{customer && customer.loan && (datehelper.displayDate(new Date(customer.loan.disbursement_date)))}</th>
                        </tr>
                        <tr style={{ border: "1px solid" }}>
                            <th style={{ border: "1px solid", textAlign: "left" }}>Sanction Amount</th>
                            <th style={{ border: "1px solid", textAlign: "right" }}>{customer && customer.loan && displayhelper.displayNumberAccounting(customer.loan.sanction_amount)}</th>
                        </tr>
                        <tr style={{ border: "1px solid" }}>
                            <th style={{ border: "1px solid", textAlign: "left" }}>ROI(%)</th>
                            <th style={{ border: "1px solid", textAlign: "right" }}>{customer && customer.loan && (customer.loan.roi)}%</th>
                        </tr>
                        <tr style={{ border: "1px solid" }}>
                            <th style={{ border: "1px solid", textAlign: "left" }}>PF Rate(%)</th>
                            <th style={{ border: "1px solid", textAlign: "right" }}>{customer && customer.loan && (customer.loan.pf_rate)}%</th>
                        </tr>
                        <tr style={{ border: "1px solid" }}>
                            <th style={{ border: "1px solid", textAlign: "left" }}>Processing Fee</th>
                            <th style={{ border: "1px solid", textAlign: "right" }}>{customer && customer.loan && (customer.loan.processing_fees)}</th>
                        </tr>
                        <tr style={{ border: "1px solid" }}>
                            <th style={{ border: "1px solid", color: "blue", fontSize: "13px", textAlign: "left" }}>GST@18%</th>
                            <th style={{ border: "1px solid", textAlign: "right" }}>{customer && customer.loan && customer.loan.processing_fees && (Math.ceil(Number(customer.loan.processing_fees) * 18 / 100))}</th>
                        </tr>
                        <tr style={{ border: "1px solid" }}>
                            <th style={{ border: "1px solid", textAlign: "left" }}>Stamp Duty</th>
                            <th style={{ border: "1px solid", textAlign: "right" }}>{customer && customer.loan && (customer.loan.stamp_duty)}</th>
                        </tr>
                        <tr style={{ border: "1px solid" }}>
                            <th style={{ border: "1px solid", textAlign: "left" }}>Insurance Amount</th>
                            <th style={{ border: "1px solid", textAlign: "right" }}>{customer && customer.loan && (customer.loan.insurance_amount)}</th>
                        </tr>
                        <tr style={{ border: "1px solid" }}>
                            <th style={{ border: "1px solid", textAlign: "left" }}>Pre EMI</th>
                            <th style={{ border: "1px solid", textAlign: "right" }}>{customer && customer.loan && (customer.loan.pre_emi_amount)}</th>
                        </tr>
                        <tr style={{ border: "1px solid" }}>
                            <th style={{ border: "1px solid", textAlign: "left" }}>IMD Charge Deduction&nbsp;(including GST)</th>
                            <th style={{ border: "1px solid", textAlign: "right" }}>{customer && customer.loan && (customer.loan.imd_deduction) || 0}</th>
                        </tr>
                        <tr style={{ border: "1px solid" }}>
                            <th style={{ border: "1px solid", textAlign: "left" }}>Internal BT Deduction</th>
                            <th style={{ border: "1px solid", textAlign: "right" }}>{customer && customer.loan && (customer.loan.internal_bt_deduction) || 0}</th>
                        </tr>
                        {customer && customer.loan && customer.loan.cheque_amount && (
                            <tr style={{ border: "1px solid" }}>
                            <th style={{ border: "1px solid", textAlign: "left" }}>Cheque Amount Deduction</th>
                            <th style={{ border: "1px solid", textAlign: "right" }}>{customer && customer.loan && (customer.loan.cheque_amount) || 0}</th>
                        </tr>
                        )}
                        <tr style={{ border: "1px solid" }}>
                            <th style={{ border: "1px solid", textAlign: "left" }}>Other Charges <br /> <span style={{ fontSize: "18px" }}>(Comment: {customer && customer.loan && (customer.loan.other_charges_comment)})</span></th>
                            <th style={{ border: "1px solid", textAlign: "right" }}>{customer && customer.loan && (customer.loan.other_charges) || 0}</th>
                        </tr>
                        <tr style={{ border: "1px solid" }}>
                            <th style={{ border: "1px solid", textAlign: "left" }}>Deduction</th>
                            <th style={{ border: "1px solid", textAlign: "right" }}>{displayhelper.displayNumberAccounting(Math.ceil(deduction))}</th>
                        </tr>
                        <tr style={{ border: "1px solid" }}>
                            <th style={{ border: "1px solid", textAlign: "left", color: "red" }}> Pending Disbursement Amount</th>
                            <th style={{ border: "1px solid", textAlign: "right", color: "red" }}>{customer && customer.loan && customer.loan.sanction_amount && displayhelper.displayNumberAccounting((Math.ceil(Number(customer.loan.sanction_amount) - deduction)))}</th>
                        </tr>
                    </table>
                </DialogContentText>
                <DialogActions style={{alignItems:"center", marginTop:"20px"}}>
                    <Button style={{color:"red"}} onClick={handleClose} >
                        Cancel
                    </Button>
                    <Button style={{color:"green"}} onClick={addVerification}  autoFocus>
                        Approve
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default DisbursementDetailVerificationModal