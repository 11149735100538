import React, { useState, useEffect } from 'react'
import { cibil } from "../../../_services/cibil.service"
import { employeeService } from "../../../_services/employee.service";
import datehelper from "../../../_helpers/datehelper"
import Upload from "../Upload"
import urlhelper from '../../../_helpers/urlhelper'
import AddPDSummary from './ApplicationData/AddPDSummary';
import roleChecker from '../../../_helpers/rolechecker';
import Cibil from '../Cibil';
import { LoanStages } from '../../../constants/loanstages';
import { Button } from '@material-ui/core';
import { Edit } from "@material-ui/icons";
import PrintIcon from '@material-ui/icons/Print';
import { history } from '../../../_helpers';
import PdImages from './PdImages';
import CerSaiUpload from './CerSaiUpload';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "../../../css/common.css"

const LoanCreditDetails = (props) => {

  const [data, setData] = useState({})

  const user = employeeService.userValue;

  const application_id = props.cust.application_id
  const cust = props.cust;

  console.log({ application_id: application_id })

  const getData = () => {
    cibil.cibilRequests({ application_id: application_id })
      .then((x) => {
        console.log(x)
        setData(x)
      })
      .catch((err) => {
        console.log(err, "err here")
      })
  }

  useEffect(() => {
    getData();
  }, [])

  const print = (element) => {
    history.push(`/home/sblletters/letter/show/camletter`, element)
  }

  const edit = (element) => {
    history.push(`/home/sblletters/letter/edit/camletter`, { id: element._id })
  }


  return (
    <>
      <h2>PD Summary</h2>
      {cust.pd_details && (
        <table>
          <tbody>
            <tr><td><b>PD Visit Date: </b></td><td>{datehelper.displayDate(new Date(cust.pd_details.pd_date))}</td></tr>
            <tr><td><b>PD Status: </b> </td><td>{cust.pd_details.pd_status}<br /></td></tr>
            {cust.pd_details.pd_status === "ApprovedNextStage" && (
              <tr>
                <td><b>PD Approved Amount: </b></td><td> {cust.pd_details.loan_amount}<br /></td></tr>

            )}
            {cust.pd_details.pd_status === "Rejected" && (
              <tr>
                <td><b>PD Rejection Reason: </b> </td><td>{cust.pd_details.rejection_reason}<br /></td></tr>
            )}
            <tr>
              <td><b>PD Done by Employee ID:</b></td><td>{cust.pd_employees && cust.pd_employees.length > 0 && (cust.pd_employees[0].first_name + " " + cust.pd_employees[0].last_name)} {"/ID: " + cust.pd_details.pd_emp_id} </td></tr>
          </tbody>
        </table>
      )}
      {(!cust.pd_details || !cust.pd_details.pd_status || cust.pd_details.pd_status === '') && (
        <AddPDSummary customer={cust} />
      )}
      <br />
      {cust.pd_images && cust.pd_images.length > 0 && (
        <>
          <h3>PD Visit Images</h3>
          <PdImages images={cust.pd_images} />
        </>
      )}
      <h2>Cibil Request List</h2>
      {![LoanStages.Closed, LoanStages.Rejected, LoanStages.Discarded].includes(cust.stage) && (
        <Cibil id={cust._id} isEdit={false} />
      )}
      <div>
        {data && data.length > 0 && data[0].cibil && data[0].cibil.length > 0 ?
          <div className='table-border'>
            <Table>
              <Thead style={{ borderBottom: "2px solid black" }}>
                <Tr>
                  <Th className='table-row-th' style={{ width: "10%" }}>Date</Th>
                  <Th className='table-row-th' style={{ width: "10%" }}>Name</Th>
                  <Th className='table-row-th' style={{ width: "10%" }}>Role</Th>
                  <Th className='table-row-th' style={{ width: "10%" }}>AADHAR</Th>
                  <Th className='table-row-th' style={{ width: "10%" }}>Other ID</Th>
                  <Th className='table-row-th' style={{ width: "10%" }}>Other Id Number</Th>
                  {/* <Th className='table-row-th' style={{ width: "10%" }}>Branch ID</Th> */}
                  <Th className='table-row-th' style={{ width: "10%" }}>Requested _by</Th>
                  <Th className='table-row-th' style={{ width: "10%" }}>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data && data.length > 0 && data[0].cibil && data[0].cibil.map((e) => {
                  return <Tr key={e._id}>
                    <Td className='table-row-td'>{datehelper.displayDate(new Date(e.date))}</Td>
                    <Td className='table-row-td'>{e.name || `${e.first_name} ${e.middle_name} ${e.last_name}`}</Td>
                    <Td className='table-row-td'>{e.role}</Td>
                    <Td className='table-row-td'>{e.aadhar}</Td>
                    <Td className='table-row-td'>{e.verification_id_type}</Td>
                    <Td className='table-row-td'>{e.pan || e.verification_id}</Td>
                    {/* <Td className='table-row-td'>{data[0].branch_id}</Td> */}
                    {/* <Td className='table-row-td'>{data[0].application_id}</Td> */}
                    <Td className='table-row-td'>{e.requested_by}</Td>
                    <Td className='table-row-td'>
                      {e.report && e.report != "" ? (
                       <> <a href={`/home/pdfviewer?pdf_url=` + urlhelper.encodeToBase64(e.report) + "&download=1"}>View Report</a> <Upload aadhar={e.aadhar} id={e._id} /> <Cibil id={cust._id} isEdit ={true} request_id={e._id} />  </>
                      ) : (roleChecker.isBMLevelAccess(user.role) || roleChecker.isCreditTeamAccess(user.role) || roleChecker.isHOLevelAccess(user.role) || roleChecker.isColendingAccess(user.role)) && 
                      (<> <Upload aadhar={e.aadhar} id={e._id} />
                          <Cibil id={cust._id} isEdit ={true} request_id={e._id} />
                      </>)}
                    </Td>
                  </Tr>
                })}
              </Tbody>
            </Table>
          </div> : <span style={{ color: "red" }}> No CIBIL Requests made.</span>
        }
      </div><br /><br />

      <div>
        <h2>CERSAI Report</h2>
        <CerSaiUpload application_id={application_id} cust={cust} />
      </div>

      <div>
        <br />
        <h3>CAM Letter List</h3>
        {(roleChecker.isHOLevelAccess(user.role) || roleChecker.isCreditTeamAccess(user.role) || roleChecker.isColendingAccess(user.role)) && (
          <Button
            color="primary"
            onClick={() =>
              history.push(`/home/sblletters/letter/camletterform`, cust._id)
            }
          >
            Fill CAM Letter
          </Button>)}
        <br />
        {cust.cam_letters && cust.cam_letters.length > 0 ?
          <div className='table-border'>
            <Table className="table table-hover">
              <Thead>
                <Tr>
                  <Th className='table-row-th' style={{ width: "25%" }}>Date</Th>
                  <Th className='table-row-th' style={{ width: "25%" }}>Name</Th>
                  <Th className='table-row-th' style={{ width: "25%" }}>Loan No</Th>
                  <Th className='table-row-th' style={{ width: "15%" }}>Sanction Amount</Th>
                  <Th className='table-row-th' style={{ width: "10%" }}>Edit</Th>
                  <Th className='table-row-th' style={{ width: "10%" }}>Print</Th>
                </Tr>
              </Thead>
              <Tbody>
                {cust.cam_letters && cust.cam_letters.map((element) => {
                  return <Tr key={element._id}>
                    <Td className='table-row-td' style={{ width: "25%" }}>{datehelper.displayDate(new Date(element.createdAt))}</Td>
                    <Td className='table-row-td' style={{ width: "25%" }}>{element.applicant}</Td>
                    <Td className='table-row-td' style={{ width: "25%" }}>{element.application_id}</Td>
                    <Td className='table-row-td' style={{ width: "15%" }}>{element.sanction_amount}</Td>
                    <Td className='table-row-td' style={{ width: "10%" }}><Button className='btn btn-primary' onClick={() => edit(element)} ><Edit /> </Button></Td>
                    <Td className='table-row-td' style={{ width: "10%" }}><Button className='btn btn-primary' onClick={() => print(element)} ><PrintIcon /> </Button></Td>

                  </Tr>
                })}

              </Tbody>
            </Table>
          </div> : <span style={{ color: "red" }}> No CAM letter generated.</span>
        }
      </div>

    </>
  )
}

export default LoanCreditDetails