const Product={
    GoldLoan:"goldloans",
    SBL:"sbl",
    Collection:"collection",
    CustomerCare:"customercare",
    Accounting:"accounting",
    Legal:"legal",
    HR:"HR",
    CoLending:"colending",
    VendorPanel:"vendorpanel"
};
export default Product;