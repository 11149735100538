import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import { Button, TextField } from "@material-ui/core";
import { loanService } from '../../../_services';
import { history } from "../../../_helpers/history"


function SelectApplicationForm(props) {

    const [open, setOpen] = useState(false);
    const [data, setData] = useState([])
    const [list, setList] = useState({})
    const [values, setValues] = useState(null)

    const id = props.id;
    
    const handleOpen = () => {
        setOpen(true)
        getData();
      };    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 200,
        height: 200,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        // overflow: 'scroll',
        // paddingBottom: '30px',
      };

      const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
      }

      const getData = () => {
        loanService.getById(id)
          .then((x) => {
            console.log(x)
            setList(x)
            // setData( x.applicant.general_information.name)
            let tempArr = [];
            if (x) {
              tempArr.push({name:x.applicant.general_information.name, customer_id:x.applicant.customer_id})
              if (x.co_applicants && x.co_applicants.length>0) {
                x.co_applicants.forEach(co_app => {
                  tempArr.push({name:co_app.general_information.name, customer_id:co_app.customer_id})
                })
    
              }
    
              if (x.guarantors && x.guarantors.length) {
                x.guarantors.forEach(guar => {
                  tempArr.push({name:guar.general_information.name, customer_id:guar.customer_id})
                })
    
              }
            }
            console.log(...tempArr);
            setData([...tempArr])
          })
          .catch((err) => {
            console.log("Something went Wrong", err)
          })
      }

         const handleSubmit = () => {
           history.push(`/home/sblloans/applicationform`, {list:list, customer_id:values.customer})
         }

      return (
        <div>
          <Button color='primary' onClick={handleOpen}>Fill Application Form</Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
    
              <Typography style={{ margin: "auto", }}>
              <div className='my-3' style={{ display: "flex", justifyContent: "center" }}>
              <label>Select the Name <select className="form-select form-select-sm" required  name='customer' style={{ width: "100px" }} onChange={handleChange} aria-label=".form-select-sm example" >
                <option selected>Select the Name</option>
                {data.map(e => <option value={e.customer_id}>{e.name}</option>)}
              </select> </label><br/>
            </div>
            <div style={{margin:"auto"}}>
              <button className='btn btn-primary' onClick={()=>{handleSubmit()}}>Submit</button>
            </div> 
              </Typography>
            </Box>
          </Modal>
        </div>
      )
}

export default SelectApplicationForm