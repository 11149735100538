import React from 'react'
import AddDisbursementAccount from './AddDisbursementAccount'
import DisbursementAccountList from "./DisbursementAccountList"
import DisbursementDetailsList from './DisbursementDetailsList'
import LoanClosureRequest from '../LoanClosureRequest'
import { Paper } from '@material-ui/core'
import { employeeService } from '../../../../_services'
import roleChecker from '../../../../_helpers/rolechecker'
import DisbursementMemoList from './DisbursementMemoList'
import DisbursementData from './DisbursementData'
import DisbursementDataList from './DisbursementDataList'
import AllCloudLoan from './AllCloudLoan'


const DisburseTab = (props) => {
  const user = employeeService.userValue;
  const cust = props.cust
  console.log("cutts is",cust )
  return (
    <div> 
   { ["Operation","Disburse","Collection"].includes(cust.stage) && <Paper style={{ width: '100%', borderRadius:'10px', marginTop:'40px' , marginBottom:'40px'  }}>

     <AllCloudLoan cust={cust} />
    </Paper>}
      <DisbursementDataList cust={cust} /><br/>
      <AddDisbursementAccount cust={cust} idEdit={false} button="Add Account"></AddDisbursementAccount>
      <DisbursementAccountList customer={cust}></DisbursementAccountList><br />
      {cust.loan?.verification&& <DisbursementMemoList customer={cust}></DisbursementMemoList>}
      <br/>
      {cust.loan?.verification&&<DisbursementDetailsList customer={cust} ></DisbursementDetailsList>}
      {cust && cust.stage === 'Collection' && roleChecker.isHOLevelAccess(user.role) && (
      <Paper style={{ width: '100%', height: '100px', borderRadius:'10px', marginTop:'40px', display:'flex', justifyContent:'center',alignItems:'center' }}>
         <LoanClosureRequest loan_data={cust} />
      </Paper>
     )}
    </div>
  )
}

export default DisburseTab