import React from "react";
import { LoanStages } from "../../../../constants/loanstages";
import datehelper from "../../../../_helpers/datehelper";
import roleChecker from "../../../../_helpers/rolechecker";
import { loanService, employeeService } from "../../../../_services";
import MoveToCollection from "../MoveToCollection";
import AddDisbursementDetails from "./AddDisbursementDetails";
// import { loanService } from "../../../../_services/loan.service";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { Button } from "@material-ui/core";
import DisbursementPaymentReceipt from "./DisbursementPaymentReceipt";
import { history } from "../../../../_helpers";
import displayhelper from "../../../../_helpers/displayhelper";


const DisbursementDetailsList = (props) => {
  console.log("props is value",props)
  const customer = props.customer;
  const user =employeeService.userValue;
   console.log("location",window.location.pathname)
  const printDetail = (element) => {
    history.push('/home/sblloans/disbursement/receipt', {...element, application_id:customer.application_id})
  }

   const archiveDetail=(id,params)=>{
    console.log("archive details is",id,params)
    loanService.archiveDetails(id,params)
    .then((res)=>{
      console.log("output is",res)
      window.location.reload();
    })
    .catch((err)=>{
      console.log("err is",err)
    })
   }
  return (
    <div>
      <h2
        style={{
          color: "wheat",
          backgroundColor: "black",
          textAlign: "center",
        }}
      >
        Loan Payment Details
      </h2>
      <table style={{ margintop: "20px" }}>
        <tr style={{ border: "1px solid" }}>
          <th style={{ border: "1px solid" }}>Kaabil Bank Name</th>
          <th style={{ border: "1px solid" }}>Kaabil Bank Account</th>
          <th style={{ border: "1px solid" }}>Payment Amount</th>
          <th style={{ border: "1px solid" }}>Payment Date</th>
          <th style={{ border: "1px solid" }}>Automated Payment</th>
          <th style={{ border: "1px solid" }}>UTR Number</th>
          <th style={{ border: "1px solid" }}>Customer Bank Account</th>
          <th style={{ border: "1px solid" }}>Payment Type</th>
          <th style={{ border: "1px solid" }}>Cheque/DD Number</th>
          <th style={{ border: "1px solid" }}>Status</th>
          <th style={{ border: "1px solid" }}>Receipt</th>
        </tr>
        {customer &&
          customer.disbursement &&
          customer.disbursement.disbursement_details &&
          customer.disbursement.disbursement_details.map((e, idx) => {
            return (
              <tr style={{ border: "1px solid" }}>
                {console.log(e,"ii")}
                <td style={{ border: "1px solid", textAlign: "center" }}>
                  {e.kaabil_bank_name}
                </td>
                <td style={{ border: "1px solid", textAlign: "center" }}>
                  {e.kaabil_account_number}
                </td>
                <td style={{ border: "1px solid", textAlign: "center" }}>
                  {e.disbursed_amount && displayhelper.displayNumberAccounting(e.disbursed_amount)}
                </td>
                <td style={{ border: "1px solid", textAlign: "center" }}>
                {datehelper.displayDate(
                          new Date(e.disbursed_date)
                        )}
                </td> 
                <td style={{ border: "1px solid", textAlign: "center" }}>
                {e.automated_payments && e.automated_payments.bene_addition && e.automated_payments.bene_addition.status !=="SUCCESS" && (
                <> <span>Beneficiary Addition - Status: {e.automated_payments.bene_addition.status} </span>
               
                </> 
                 )}
                {e.automated_payments && e.automated_payments.payment_transfer && (
                  <span>Payment Transfer - Status: {e.automated_payments.payment_transfer.status} Request id: {e.automated_payments.payment_transfer.request_id} </span>

                 )}
                 
                 {e.automated_payments && e.automated_payments.payment_transfer && e.automated_payments.payment_transfer.status=='SUCCESS' && (
                  <span> UTR: {e.automated_payments.payment_transfer.utr_number} </span>
                 )}
                 {( (e.automated_payments && e.automated_payments.bene_addition && ["ERROR","REJECTED","FAILED","REVERSED"].includes(e.automated_payments.bene_addition.status))||( e.automated_payments && e.automated_payments.payment_transfer && ["ERROR","REJECTED","FAILED","REVERSED"].includes(e.automated_payments.payment_transfer.status) )) && ( <Button color='secondary'
                                                                    variant='outlined'  onClick={() => {
                    if (window.confirm("Are you sure you want to archive?")) {
                      archiveDetail(customer._id, e);
                    }}}>Archive</Button>)
                }
                </td> 
                
                <td style={{ border: "1px solid", textAlign: "center" }}>
                  {e.disbursed_utr_number ? e.disbursed_utr_number : "---"}
                </td>
                <td style={{ border: "1px solid", textAlign: "center" }}>
                  {e.disbursed_account_number}
                </td>
                <td style={{ border: "1px solid", textAlign: "center" }}>
                  {e.payment_type}
                </td>
                <td style={{ border: "1px solid", textAlign: "center" }}>
                  {e.cheque_dd_number}
                </td>
                <td style={{ border: "1px solid", textAlign: "center" }}>{e.disbursed_utr_number && e.disbursed_utr_number.length > 5 ? <>Paid <VerifiedUserIcon /></> : <span style={{color:"red"}}>pending <AddDisbursementDetails idx={idx} account_data={e} customer={customer} isEdit={true} button={"Add UTR"}/></span> }</td>
                <td style={{ border: "1px solid", textAlign: "center" }}>{e.disbursed_utr_number && e.disbursed_utr_number.length > 5 ? <Button onClick={()=>printDetail(e)} style={{color:"blue"}} >Print</Button> : null}</td>
              </tr>
            );
          })}
      </table>
      {customer &&
          customer.disbursement &&
          customer.disbursement.disbursement_details && customer.disbursement.disbursement_details.length>0 && (roleChecker.isHOOpsApproverAccess(user.role) || roleChecker.isAccountChecker(user.role)) && (customer.stage != LoanStages.Collection && customer.stage != LoanStages.Closed) && (
        <>
        <br/><br/>
       <h2>Ready for Collection</h2>
       <div class="row  justify-content-md-center" style={{"border":"1px solid","margin":"5px"}}>
          <div class="justify-content-md-center">
          
              <p style={{padding:"4px"}}>I have verified all the loan details and this loan is now ready for Collection.</p>
              {customer &&
          customer.disbursement &&
          customer.disbursement.disbursement_details &&
          customer.disbursement.disbursement_details.filter((e)=>{
            if(!e.disbursed_utr_number || e.disbursed_utr_number.length < 5 ){
              return e
            }
          }).length === 0 &&  <MoveToCollection application = {customer} />}
          </div>
      </div>  
        </>
      )}
    </div>
  );
};

export default DisbursementDetailsList;