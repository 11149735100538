import React from "react";
import { useEffect, useState } from "react";
import GoldAuctionServices from "../../../_services/goldauction.service";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import datehelper from "../../../_helpers/datehelper"
import { SearchOutlined } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";


export const AuctionList = () => {
    const [value, setValue] = useState([])
    const [loan, setLoan] = useState({})
    const [show, setShow] = useState(true)

    useEffect(() => {
        async function getAuctionDetails() {
            await GoldAuctionServices.getAuctionDetails()
                .then((res) => {
                    setValue(res);
                    setShow(false);
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        getAuctionDetails()
    }, [])

    const handleChange = (fieldKey, fieldValue) => {
        loan[fieldKey] = fieldValue.toUpperCase()
        setLoan(loan)
    }

    const handleSubmit = async () => {
        setShow(true)
        await GoldAuctionServices.FindByLoan(loan)
            .then(res => {
                setValue(res);
                setShow(false);
            })
            .catch(err => console.log(err))
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h1>Auction List</h1>
            <div style={{ display: 'flex', marginLeft: '70%' }}>
                <input type="text" style={{ borderRadius: '5px' }} name="loan_no" onChange={(e) => handleChange([e.target.name], e.target.value)} placeholder='Loan Number' />
                <button style={{ borderRadius: '5px', color: 'white', backgroundColor: '#0066cc', border: 'none', width: "70px", marginLeft: '5px' }} onClick={() => handleSubmit()} ><SearchOutlined /></button>
            </div>
            <hr/>
            {show === false ?
                <Table>
                    <Thead>
                        <Tr>
                            <Th style={{width:"30%"}}>Loan No</Th>
                            <Th style={{width:"40%"}}>Auction Date</Th>
                            <Th style={{width:"30%"}}>Auction Amount</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            value.length > 0 ?
                                value.map((x, idx) => <Tr key={idx}>
                                    <Td>{x.loan_no}</Td>
                                    <Td>{datehelper.displayDate(new Date(x.auction_details.auction_date))}</Td>
                                    <Td>{x.auction_details.auction_amount}</Td>
                                </Tr>) :
                                <Tr>
                                    <Td></Td>
                                    <Td>Data not available</Td>
                                    <Td></Td>
                                </Tr>
                        }
                    </Tbody>
                </Table> :
                <div style={{ display: 'flex', justifyContent: 'space-around' }}  ><CircularProgress /></div>
            }
        </div>
    )
}