import React, { useEffect } from "react";
import kaabilLogo from "../../../assets/kaabil.png";
import { loanService } from "../../../_services";
import { useReactToPrint } from "react-to-print";
import datehelper from "../../../_helpers/datehelper";
import documentlist from "../../../assets/documentlist.png";


function PreviewApplicationForm(props) {
  const value = props.location.state.value;
  console.log(props);

  const temp = value.aadhar_no;

  const ApplicationFormRef = React.useRef();

  const handleSubmissionPrint = useReactToPrint({
    content: () => ApplicationFormRef.current,
    pageStyle: "print",
  });

  return (
    <>
      <button style={{ marginLeft: "50%" }} onClick={handleSubmissionPrint}>
        Print Letter
      </button>
      <div ref={ApplicationFormRef}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h1>Application Form</h1>
          <img
            style={{ width: "300px" }}
            src={kaabilLogo}
            alt="Kaabil Finance Pvt.Lth"
          />
        </div>
        <br />
        <br />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <span>Application No.: &nbsp;{value.application_id} </span>
          <span>Role : &nbsp; {value.role}</span>
          <span>
            Application Date: &nbsp;{" "}
            {datehelper.displayDate(new Date(value.application_date))}
          </span>
        </div>
        <br />

        <div style={{ height: "1500px", width: "794px", margin: "auto" }}>
          <h1>Personal Details</h1>
          <br />
          <table style={{ width: "80%", border: "1px solid grey" }}>
            <thead>
              <tr>
                <th style={{ border: "1px solid grey", width: "20%" }}>Name</th>
                <th style={{ border: "1px solid grey", width: "20%" }}>
                  {value.title} {value.first_name} {value.middle_name}{" "}
                  {value.last_name}{" "}
                </th>
              </tr>
              <tr>
                <th style={{ border: "1px solid grey", width: "20%" }}>
                  Gender
                </th>
                <th style={{ border: "1px solid grey", width: "20%" }}>
                  {value.gender}
                </th>
              </tr>
              <tr>
                <th style={{ border: "1px solid grey", width: "20%" }}>
                  Father's / Spouse Name
                </th>
                <th style={{ border: "1px solid grey", width: "20%" }}>
                  {value.father_name}
                </th>
              </tr>
              <tr> 
                <th style={{ border: "1px solid grey", width: "20%" }}>
                  Mother's Maiden Name
                </th>
                <th style={{ border: "1px solid grey", width: "20%" }}>
                  {value.mother_name}
                </th>
              </tr>
              <tr> 
                <th style={{ border: "1px solid grey", width: "20%" }}>
                  Current Residence Address
                </th>
                <th style={{ border: "1px solid grey", width: "20%" }}>
                  {value.address}
                </th>
              </tr>
              <tr>
                <th style={{ border: "none" }}>
                  Landmark :- &nbsp; &nbsp; {value.landmark}{" "}
                </th>
                <th style={{ border: "none" }}>
                  Tehsil:- &nbsp; &nbsp; &nbsp; &nbsp; {value.tehsil}{" "}
                </th>
              </tr>
              <tr>
                <th style={{ border: "none" }}>
                  City :- &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {value.city}
                </th>
                <th style={{ border: "none" }}>
                  State :- &nbsp; &nbsp; &nbsp; &nbsp; {value.state}
                </th>
              </tr>
              <tr>
                <th style={{ border: "none" }}>
                  Pin Code :- &nbsp; &nbsp; {value.pincode}{" "}
                </th>
                <th style={{ border: "none" }}>
                  District :- &nbsp; &nbsp; {value.district}{" "}
                </th>
              </tr>
              <tr>
                <th style={{ border: "1px solid grey", width: "20%" }}>
                  No. of yrs. at current residence
                </th>
                <th style={{ border: "1px solid grey", width: "20%" }}>
                  {value.no_of_years}
                </th>
              </tr>
              <tr>
                <th style={{ border: "1px solid grey" }}>
                  Current residence is
                </th>
                <th style={{ border: "1px solid grey" }}>
                  {value.current_residenceStatus}
                </th>
              </tr>

              <tr>
                <th style={{ border: "1px solid grey", width: "20%" }}>
                  Permanent Address
                </th>
                <th style={{ border: "1px solid grey", width: "20%" }}>
                  {value.permanent_address}
                </th>
              </tr>
              <tr>
                <th style={{ border: "none" }}>
                  Landmark :- &nbsp; &nbsp; {value.permanent_landmark}{" "}
                </th>
                <th style={{ border: "none" }}>
                  Tehsil:- &nbsp; &nbsp; &nbsp; &nbsp; {value.permanent_tehsil}{" "}
                </th>
              </tr>
              <tr>
                <th style={{ border: "none" }}>
                  City :- &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  {value.permanent_city}
                </th>
                <th style={{ border: "none" }}>
                  State :- &nbsp; &nbsp; &nbsp; &nbsp; {value.permanent_state}
                </th>
              </tr>
              <tr>
                <th style={{ border: "none" }}>
                  Pin Code :- &nbsp; &nbsp; {value.permanent_pincode}{" "}
                </th>
                <th style={{ border: "none" }}>
                  Pin Code :- &nbsp; &nbsp; &nbsp; {value.permanent_district}{" "}
                </th>
              </tr>

              <tr>
                <th style={{ border: "1px solid grey" }}>Mobile No.</th>
                <th style={{ border: "1px solid grey" }}>{value.mobile}</th>
              </tr>
              <tr>
                <th style={{ border: "1px solid grey" }}>Email ID </th>
                <th style={{ border: "1px solid grey" }}>{value.email}</th>
              </tr>
              <tr>
                <th style={{ border: "1px solid grey" }}>Date of Birth</th>
                <th style={{ border: "1px solid grey" }}>
                  {datehelper.displayDate(new Date(value.dob))}
                </th>
              </tr>
              <tr>
                <th style={{ border: "1px solid grey" }}> Marital Status </th>
                <th style={{ border: "1px solid grey" }}>
                  {value.marital_status}
                </th>
              </tr>
              <tr>
                <th style={{ border: "1px solid grey" }}>Qualification</th>
                <th style={{ border: "1px solid grey" }}>
                  {value.qualification}
                </th>
              </tr>
              <tr>
                <th style={{ border: "1px solid grey" }}>
                  Other Qualification{" "}
                </th>
                <th style={{ border: "1px solid grey" }}>
                  {value.other_qualification}
                </th>
              </tr>
              <tr>
                <th style={{ border: "1px solid grey" }}>Pan Card number </th>
                <th style={{ border: "1px solid grey" }}>{value.pan_no}</th>
              </tr>
              <tr>
                <th style={{ border: "1px solid grey" }}>
                  Aadhar Card Number{" "}
                </th>
                <th style={{ border: "1px solid grey" }}>
                  XXXXXXXX{temp[10]}
                  {temp[11]}
                  {temp[12]}
                  {temp[13]}
                </th>
              </tr>
              <tr>
                <th style={{ border: "1px solid grey" }}>religion </th>
                <th style={{ border: "1px solid grey" }}>{value.religion}</th>
              </tr>
              <tr>
                <th style={{ border: "1px solid grey" }}>Category </th>
                <th style={{ border: "1px solid grey" }}>{value.category}</th>
              </tr>
            </thead>
          </table>
          <br />
          <br />
          <h1>Business / Employment Details</h1>
          <p>
            To be Filled to know about the applicant / co-applicant / guarantor
            earning status{" "}
          </p>
          <table style={{ width: "80%", border: "1px solid grey" }}>
            <thead>
              <tr>
                <th style={{ border: "1px solid grey", width: "30%" }}>
                  Occupation{" "}
                </th>
                <th style={{ border: "1px solid grey", width: "30%" }}>
                  {value.occupation}
                </th>
              </tr>
              <tr>
                <th style={{ border: "1px solid grey" }}>Salaried </th>
                <th style={{ border: "1px solid grey" }}>
                  {value.salaried_type}
                </th>
              </tr>
              <tr>
                <th style={{ border: "1px solid grey" }}>
                  Nature of Business{" "}
                </th>
                <th style={{ border: "1px solid grey" }}>
                  {value.nature_of_business}
                </th>
              </tr>
              <tr>
                <th style={{ border: "1px solid grey" }}>Nature of Concern </th>
                <th style={{ border: "1px solid grey" }}>
                  {value.nature_of_concern}
                </th>
              </tr>
              <tr>
                <th style={{ border: "1px solid grey" }}>
                  Business / Employment Add.{" "}
                </th>
                <th style={{ border: "1px solid grey" }}>
                  {value.work_address}
                </th>
              </tr>
              <tr>
                <th style={{ border: "none" }}>
                  City &nbsp; &nbsp; &nbsp; &nbsp; {value.work_city}
                </th>
                <th style={{ border: "none" }}>
                  State &nbsp; &nbsp; &nbsp; &nbsp; {value.work_state}
                </th>
              </tr>
              <tr>
                <th style={{ border: "none" }}>
                  Pin Code &nbsp; &nbsp; &nbsp; &nbsp; {value.work_pincode}
                </th>
              </tr>
            </thead>
          </table>
          <br />
          <br />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              className="card"
              style={{ width: "20rem", border: "1px solid grey" }}
            >
              <div className="card-body">
                <h5 className="card-title">Reference (From relative only)</h5>
                <p className="card-text">
                  Name: &nbsp; &nbsp; &nbsp; {value.reference_name}
                </p>
                <p className="card-text">
                  Relationship: &nbsp; &nbsp; {value.reference_relationship}
                </p>
                <p className="card-text">
                  Address: &nbsp; &nbsp; {value.reference_address}
                </p>
                <p className="card-text">
                  Mobile: &nbsp; &nbsp; {value.reference_mobile}
                </p>
              </div>
            </div>{" "}
            &nbsp; &nbsp;
            <div
              className="card"
              style={{ width: "20rem", border: "1px solid grey" }}
            >
              <div className="card-body">
                <h5 className="card-title">Reference (From relative only)</h5>
                <p className="card-text">
                  Name: &nbsp; &nbsp; &nbsp; {value.reference_name2}
                </p>
                <p className="card-text">
                  Relationship: &nbsp; &nbsp; {value.reference_relationship2}
                </p>
                <p className="card-text">
                  Address: &nbsp; &nbsp; {value.reference_address2}
                </p>
                <p className="card-text">
                  Mobile: &nbsp; &nbsp; {value.reference_mobile2}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div style={{ height: "1500px", width: "794px", margin: "auto" }}>
          <img
            src={kaabilLogo}
            style={{ height: "150px", marginLeft: "20%" }}
            alt="KFPL Logo"
          />
          <h2 style={{ marginTop: "100px" }}>Bank Account Details</h2>
          <table style={{ border: "1px solid grey", width: "80%" }}>
            <thead>
              <tr>
                <th>Account Holder</th>
                <th>Bank Name</th>
                <th>Branch</th>
                <th>Account No.</th>
                <th>Account Type</th>
              </tr>
            </thead>
            <tbody>
              <td>{value.account_holder}</td>
              <td>{value.bank_name}</td>
              <td>{value.branch}</td>
              <td>{value.account_number}</td>
              <td>{value.account_type}</td>
            </tbody>
          </table>
          <br />
          {/* <br />
          <h1>Property Details</h1>
          <br />
          <table style={{ border: "1px solid grey", width: "80%" }}>
            <tr>
              <th style={{ border: "1px solid grey", width: "30%" }}>
                Property Address
              </th>
              <th style={{ border: "1px solid grey", width: "20%" }}>
                Area Of Land
              </th>
              <th style={{ border: "1px solid grey", width: "30%" }}>&nbsp;</th>
            </tr>
            <tr>
              <th style={{ border: "1px solid grey" }}>
                {value.property_address}
              </th>
              <th style={{ border: "1px solid grey" }}>
                Area of flat/house/office
              </th>
              <th style={{ border: "1px solid grey" }}>{value.house_area}</th>
            </tr>
            <tr>
              <th>&nbsp;</th>
              <th style={{ border: "1px solid grey" }}>Property Type</th>
              <th style={{ border: "1px solid grey" }}>
                {value.property_type}
              </th>
            </tr>
            <tr>
              <th>&nbsp;</th>
              <th style={{ border: "1px solid grey" }}>OwnerShip Type</th>
              <th style={{ border: "1px solid grey" }}>
                {value.ownership_type}
              </th>
            </tr>
            <tr>
              <th style={{ border: "1px solid grey" }}>Nearest Landmark</th>
              <th style={{ border: "1px solid grey" }}>Construction</th>
              <th style={{ border: "1px solid grey" }}>{value.construction}</th>
            </tr>
            <tr>
              <th style={{ border: "1px solid grey" }}>
                {value.nearest_landmark}
              </th>
              <th style={{ border: "1px solid grey" }}>Approx. Market Value</th>
              <th>{value.market_value}</th>
            </tr>
            <tr>
              <th>&nbsp;</th>
              <th style={{ border: "1px solid grey" }}>Present Owner</th>
              <th style={{ border: "1px solid grey" }}>
                {value.present_owner}
              </th>
            </tr>
          </table>
          <br /> */}
          <br />
          <h1>Loan Details</h1>
          <table style={{ border: "1px solid grey" }}>
            <tr>
              <th style={{ border: "1px solid grey" }}>
                Loan Amount: &nbsp; {value.loan_amount}
              </th>
              <th style={{ border: "1px solid grey" }}>
                Loan Tenure: &nbsp; {value.loan_tenure} (years / months)
              </th>
              <th style={{ border: "1px solid grey" }}>
                Interest Rate: &nbsp; {value.interest_ratetype}
              </th>
            </tr>
            <tr>
              <th>Loan Purpose:-</th>
            </tr>
            <tr>
              <th>{value.loan_purpose}</th>
            </tr>
          </table>
          <br />
          <br />
          <h2>Processing Fee Details</h2>
          <table style={{ border: "1px solid grey", width: "80%" }}>
            <tr>
              <th>
                Processing fee(non-refundable): &nbsp;{value.processing_fee}
              </th>
            </tr>
            <br />
            <tr>
              <th>Cheque / DD Number: &nbsp; {value.cheque_number}</th>
            </tr>
            <br />
            <tr>
              <th>Dated: &nbsp; {value.cheque_date}</th> &nbsp; &nbsp;
            </tr>
            <br />
            <tr>
              <td>
                Draw on <b>{value.cheque_purpose}</b> in favour of Kaabil
                Finance Private Limited
              </td>
            </tr>
          </table>
        </div>
        <div style={{ height: "1500px", width: "794px", margin: "auto" }}>
          <img
            src={kaabilLogo}
            style={{ height: "150px", marginLeft: "20%" }}
            alt="KFPL Logo"
          />
          <div
            className="card"
            style={{
              width: "16rem",
              border: "1px solid grey",
              marginLeft: "30%",
              marginTop: "100px",
            }}
          >
            <img
              src={value.link}
              style={{ height: "200px", width: "256px" }}
              className="card-img-top"
              alt="Customer Image"
            />

            <div className="card-body">
              <p>Name: &nbsp; {value.first_name}</p>
              <p>Date: &nbsp;{datehelper.displayDate(new Date(new Date()))}</p>
              <p>
                <label>
                  Signature: &nbsp;
                  <input type="textarea" style={{ height: "100px" }} />
                </label>
              </p>
            </div>
          </div>
          <br />
          <h2>Declaration</h2>
          <div style={{ fontSize: "12px" }}>
            <p>
              1. I / We declare that all the particulars and information and
              details given / filled in this application form are true,correct
              and complete and up to date in aìl respects and that I / we have
              not withheld any information whatsoever. Kaabil Finance Private
              Limited (the “KFPL") has the right to reject the application in
              case any of the information provided in this application form is
              found to be false / incorrect / misleading.
            </p>
            <p>
              2. I / We confirm that no insolvency proceedings or suits for
              every of outstanding dues or monies whatsoever or for attachment
              of my/our assets or properties and / or any criminal proceedings
              have been initiated and/or are pending against me / us and that I
              / we have never been adjudicated insolvent by any court or other
              authority.
            </p>
            <p>
              3. No action nor other steps have been taken or legal proceedings
              started by or against me / us in any court of law/other
              authorities for winding up, dissolution, administration or
              re-organisation or for the appointment of a receiver,
              administrator, administrative receiver, trustee or similar office
              or for my / our assets.
            </p>
            <p>
              4. I / We authorise KFPL to exchange, share or part with all the
              information / data or documents and details relating to my / our
              application and / or existing loans and / or repayment history to
              other banks, financial institutions, agencies, statutory /
              regulatory bodies, court, forum, etc as KFPL may deem necessary or
              appropriate as may be required for use of processing of the. said
              information / data by such person(s).
            </p>
            <p>
              5. I / We understand and acknowledge that KFPL shall have the
              absolute discretion, without assigning any reasons (unless
              required by applicable law), to reject my / our app application
              that KFPL shall not be responsible / liable in any manner
              whatsoever to me / us for such rejection or any delay in notifying
              me / us of such rejection and any costs, losses, damages,
              expenses, other consequences caused by reason of such rejection or
              any delay in notifying me / us of such rejection of my / our
              application.
            </p>
            <p>
              6. KFPL reserves the right to retain the photographs and documents
              submitted and will nol return the same to the applicant,
              co-applicant or guarantor.
            </p>
            <p>
              7. I am / We are neither related to any of the Directors of KFPL
              nor I am / We are his / her relatives as defined under section 6 r
              / w schedule lA of the Companies Act, 1956.
            </p>
            <p>
              8. I / We have been explained the product features of the loan I /
              we have applied for. I / We have understood the terms and
              conditions of Loan product and I / we agree to adibe by them.
            </p>
          </div>
        </div>
        <div style={{ height: "1500px", width: "794px", margin: "auto" }}>
            <img
              src={kaabilLogo}
              style={{ height: "150px", marginLeft: "20%" }}
              alt="KFPL Logo"
            />
            <div style={{fontSize:"12px", marginTop:"100px"}}>
              <p>
                9. From time to time, KFPL communicates various features /
                products / promotional offers which offer significant benefits
                to its customers and may use the services of third party
                agencies to do so.
                <br />
                Do you wish to be informed about such benefits?
                <br />
                • I / We expressly authorise KFPL to use information or data
                relating to me / us, for communicating marketing offers as
                outlined above.
                <br />• I / We do not wish to receive offers as outlined above.
              </p>
              <p>
                10. I / We understand that Processing Fee and Administrative Fee
                are non-refundable and my / our application being rejected by
                KFPL for any reason or same being withdrawn by me / us, I / we
                shall not be entitled to refund or same.
              </p>
              <p>
                11. कंपनी द्वारा दर्शाई गई उक्त नियम एवं शर्तों को मैंने समझ
                लिया है और मैं इनसे पूर्णतः सहमत हूँ।
              </p>
            </div><br/>
            <h2>List Of Document</h2><br/>
            <img
              src={documentlist}
              style={{ height: "600px", width:"800px"}}
              alt="KFPL Logo"
            />
          </div>
      </div>
    </>
  );
}

export default PreviewApplicationForm;
