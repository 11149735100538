import React from "react";
import { Grid, Card, Button, CardActions, Box, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { Edit,Add } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { loanService } from "../../_services/loan.service";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { employeeService } from "../../_services";
import roleChecker from "../../_helpers/rolechecker";
import { Role } from "../../_helpers";
import { Input } from "antd";
import { LoanStages } from "../../constants/loanstages";
import datehelper from "../../_helpers/datehelper";
import EditStageModal from "./EditStageModal";
import { ColendingSubStages } from "../../constants/colendingsubstages";
const { Search } = Input;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexGrow: 1,
  },
  card: {
    minHeight: 250,
    minWidth: 250,
  },
}));

export default function MyColendingTasksList({ match }) {
  const { path } = match;
  const { colender } = match.params;
  const classes = useStyles();
  const user= employeeService.userValue;
  const [applicants, setApplicants] = React.useState(null);
  const [spinner,setSpinner]= React.useState(true);
  const [page, setPage] = React.useState(0);
  const [searchFilter,setSearchFilter]=React.useState("");
  const [colenderFilter,setColenderFilter]=React.useState(colender);
  const [colenderStageFilter,setColenderStageFilter]=React.useState("");
  
  const [colenderNameList,setColenderNameList] = React.useState([]);
  const [colenderStageList,setColenderStageList] = React.useState([]);
  
  
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  React.useEffect(() => {
    async function getApplicants() {
      setSpinner(true);
      var cust = await loanService.getAllColenderLoans();
      var colenderNames=[];
      var colenderStages=[];
      let tempLoans=cust.filter((l)=> {

        if([ColendingSubStages.AmountReceivedFromColender,ColendingSubStages.ColenderLoanClosed,ColendingSubStages.RejectedByColender,ColendingSubStages.ColendingCancelled].includes(l.co_lender_data.co_lender_status))
        {
           return false;
        }
        console.log("colender status is "+l.co_lender_data.co_lender_status);
        if(!l.stage_owners || l.stage_owners.length==0 ||l.stage_owners[0].emp_id !== user.emp_id)
        {
          return false;
        }

        if(!colenderNames.includes(l.co_lender_data.co_lender_name))
        {
          colenderNames.push(l.co_lender_data.co_lender_name);
        }
        if(!colenderStages.includes(l.co_lender_data.co_lender_status))
        {
          colenderStages.push(l.co_lender_data.co_lender_status);
        } 

        if(searchFilter!="")
        {
          if(l.applicant_name && l.applicant_name.toLowerCase().includes(searchFilter.toLowerCase()))
          {
              return true;
          }
          if(l.applicant_contact && l.applicant_contact===parseInt(searchFilter))
          {
              return true;
          }
          if(l.application_id === parseInt(searchFilter))
          {
              return true;
          }


          return false;
        }
        if(colenderFilter && colenderFilter!="" && colenderFilter!="All" )
        {
          if(l.co_lender_data && l.co_lender_data.co_lender_name.toLowerCase()===colenderFilter.toLowerCase())
          {
              return true;
          }
          else {
            return false;
          }
          
        }
        if(colenderFilter && colenderFilter!="" && colenderFilter!="All" )
        {
          if(l.co_lender_data && l.co_lender_data.co_lender_status.toLowerCase()===colenderStageFilter.toLowerCase())
          {
              return true;
          }
          else
          {
            return false;
          }
          
        }
        return true;
        

       });
      // cust = cust.filter((entry) => entry.submitted == true);
      if (roleChecker.isHOLevelAccess(user.role))
      { 
           tempLoans=tempLoans;
           setColenderNameList(colenderNames);
           setColenderStageList(colenderStages);
      }
      else{
          tempLoans=null;
      }
      setApplicants(tempLoans);
      setSpinner(false);
     
    }

    getApplicants();
  }, [searchFilter,colenderFilter]);

  return (
    <div>
      <h1>CoLending Tasks for me</h1>
      
      <div className="d-flex justify-content-between">
   
        <Search
        onChange={(e) => setSearchFilter(e.target.value)}
        placeholder="Search"
        enterButton
        style={{
          position: "sticky",
          top: "0",
          left: "0",
          width: "200px",
          marginTop: "2vh",
        }}
      />
      <Box sx={{ minWidth: 120 }}>
       <FormControl fullWidth>

       <InputLabel id="filter_emp_label">Filter Colender</InputLabel>
       <Select
       labelId="filter_cln_label"
       name="colenderfilter"
       label="Filter Colender"
       value={colenderFilter}
       onChange={(e) => setColenderFilter(e.target.value)}
       >
         <MenuItem value="All" >All Colender</MenuItem>
         {colenderNameList && colenderNameList.map((s)=> {
           return <MenuItem value={s}>{s}</MenuItem>
         })}

       </Select>
       </FormControl>
       </Box>
       <Box sx={{ minWidth: 120 }}>
       <FormControl fullWidth>

       <InputLabel id="filter_emp_label">Filter Stages</InputLabel>
       <Select
       labelId="filter_stage_label"
       name="colenderstagefilter"
       label="Filter Colender Stage"
       value={colenderStageFilter}
       onChange={(e) => setColenderStageFilter(e.target.value)}
       >
         <MenuItem value="All" >All Stages</MenuItem>
         {colenderStageList && colenderStageList.map((s)=> {
           return <MenuItem value={s}>{s}</MenuItem>
         })}

       </Select>
       </FormControl>
       </Box>
      </div>
      <br></br>
      <TableContainer><Table>
          <Thead>
            <Tr>
              <Th style={{ width: "10%" }}>
                  Date
              </Th>
               
              <Th style={{ width: "10%" }}>Kaabil Loan #</Th> 
              <Th style={{ width: "10%" }}>CoLender Ref #</Th> 
              <Th style={{ width: "10%" }}>Customer Name</Th>
              
              <Th style={{ width: "15%" }}>CoLending Stage</Th>
              <Th style={{ width: "10%" }}>Stage Owner</Th>
              
              <Th style={{ width: "5%" }}>Branch Id</Th>
              <Th style={{ width: "10%" }}>Kaabil Sanction Amount</Th>
              <Th style={{ width: "10%" }}>Colender Sanction Amount</Th>
              <Th style={{ width: "10%" }}>Amount Receive Date</Th>
              <Th style={{ width: "10%" }}>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {applicants && applicants.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((application,idx)=>(
              <Tr key={idx}><Td>{application.co_lender_data && datehelper.displayDate(new Date(application.co_lender_data.co_lender_status_date))}</Td>
              <Td>{application.application_id}</Td>
              <Td>{application.co_lender_data && application.co_lender_data.co_lender_name} <br/>{application.co_lender_data && application.co_lender_data.co_lender_account_num}</Td>
    
              <Td>{application.applicant_name}
              </Td>
             
              <Td>{application.co_lender_data && application.co_lender_data.co_lender_status}
              <EditStageModal application={application} colender={application.co_lender_data.co_lender_name}/>
              </Td>
              <Td>{application.stage_owners && application.stage_owners.length>0 && (
                <>{application.stage_owners[0].first_name} {application.stage_owners[0].last_name} </>
              ) }
            
              </Td>
              <Td>{application.branch_id}</Td>
              <Td>{application.loan_amount}</Td>
              <Td>{application.co_lender_data.co_lender_sanction_amount}</Td>
              <Td>{application.co_lender_data.co_lender_received_date && datehelper.displayDate(new Date(application.co_lender_data.co_lender_received_date))}</Td>
              
              <Td>
                  <Button component="a"
                  href={`/home/sblloans/details/${application._id}`}
                  size="small"
                  color="primary"
                  >
                  View Details</Button>
              </Td>
            </Tr>
            ))}
            {(!applicants || spinner) && (
              <Tr>
                <Td colSpan="9" className="text-center">
                  <span className="spinner-border spinner-border-lg align-center"></span>
                </Td>
              </Tr>
            )}
          </Tbody> 
          </Table>
      </TableContainer>
      {applicants ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={applicants.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        <div />
      )}
    </div>
  );
}
