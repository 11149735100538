// import React from "react";
// import {
//   Card,
//   CardActionArea,
//   CardActions,
//   CardMedia,
//   Button,
//   Grid,
//   makeStyles,
// } from "@material-ui/core";

// import Drawer from "@material-ui/core/Drawer";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from "@material-ui/core/ListItemText";
// import InboxIcon from "@material-ui/icons/MoveToInbox";

// const drawerWidth = 240;

// const useStyles = makeStyles((theme) => ({
//   card: {
//     maxWidth: 500,
//   },
//   media: {
//     width: 150,
//     height: 150,
//   },
//   root: {
//     display: "flex",
//     flexGrow: 1,
//   },
//   drawer: {
//     width: drawerWidth,
//     flexShrink: 0,
//   },
//   drawerPaper: {
//     width: drawerWidth,
//     marginTop: "11%",
//   },
//   drawerContainer: {
//     overflow: "auto",
//   },
//   // necessary for content to be below app bar
//   content: {
//     flexGrow: 1,
//     backgroundColor: theme.palette.background.default,
//     padding: theme.spacing(3),
//   },
// }));

// export default function VisitPhotos({ images }) {
//   const classes = useStyles();
//   const [currentImages, setCurrentImages] = React.useState([]);

//   React.useEffect(() => {
//     setCurrentImages(images);
//   }, [images]);

//   function filterImages(category) {
//     setCurrentImages(images.filter((image) => image.category === category));
//   }

//   return (
//     <div className={classes.root}>
//       <main className={classes.content}>
//         <Grid container className={classes.root} spacing={2}>
//           <Grid item xs={12}>
//             <Grid container justify="center" spacing={2}>
//               {currentImages.map((image) => (
//                 <Grid key={image.uri} item>
//                   <Card className={classes.card}>
//                     <span style={{padding:'10px'}}>{image.tag}</span>
//                     <CardActionArea>
//                       <CardMedia className={classes.media} image={image.uri} />
//                     </CardActionArea>
//                     <CardActions>
//                       <Button
//                         component="a"
//                         target="_blank"
//                         href={`https://maps.google.com/?q=${image.location.lat},${image.location.long}`}
//                         size="small"
//                         color="primary"
//                       >
//                         Go To Location
//                       </Button>
//                       <Button
//                         component="a"
//                         target="_blank"
//                         href={image.uri}
//                         size="small"
//                         color="primary"
//                       >
//                         View Full Image
//                       </Button>
//                     </CardActions>
//                   </Card>
//                 </Grid>
//               ))}
//             </Grid>
//           </Grid>
//         </Grid>
//       </main>
//     </div>
//   );
// }
import React from "react";
import {
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  Button,
  Grid,
  makeStyles,
} from "@material-ui/core";

import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 500,
  },
  media: {
    width: 150,
    height: 150,
  },
  root: {
    display: "flex",
    flexGrow: 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: "11%",
  },
  drawerContainer: {
    overflow: "auto",
  },
  // necessary for content to be below app bar
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

export default function VisitPhotos({ images }) {
  const classes = useStyles();

  if (!images || images.length === 0) {
    return <p style={{textAlign:"center"}}>Image Not Available</p>;
  }
  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Grid container justify="center" spacing={2}>
            {images.map((image) => (
                <Grid key={image.uri} item>
                  <Card className={classes.card}>
                    <span style={{padding:'10px'}}>{image.tag}</span>
                    <CardActionArea>
                      <CardMedia className={classes.media} image={image.uri} />
                    </CardActionArea>
                    <CardActions>
                      <Button
                        component="a"
                        target="_blank"
                        href={`https://maps.google.com/?q=${image.location.lat},${image.location.long}`}
                        size="small"
                        color="primary"
                      >
                        Go To Location
                      </Button>
                      <Button
                        component="a"
                        target="_blank"
                        href={image.uri}
                        size="small"
                        color="primary"
                      >
                        View Full Image
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </main>
    </div>
  );
}
