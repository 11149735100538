import React from "react";
import { useReactToPrint } from "react-to-print";
import { Modal } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Table, Thead, Th, Tr, Td, Tbody } from "react-super-responsive-table";
import { useLocation } from "react-router-dom";
import { Typography } from "@material-ui/core";
import datehelper from "../../../_helpers/datehelper";

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "50%",
    overflow: "scroll",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    minWidth: 650,
  },
}));

export default function InterestCalculator(props) {
  const location = useLocation();
  const [interestDue, setInterestDue] = React.useState(0)
  const [fcAmount, setFcAmount] = React.useState(0)
  const [loan, setLoan] = React.useState(null);
  const [loanCalculations, setLoanCalculations] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [payDetails, setPayDetails] = React.useState([]);

  const handleOpen = (details) => {
    console.log(details)
    setOpen(true)
    setPayDetails(details)
  }
  const handleClose = () => {
    setOpen(false)
    setPayDetails([])
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
    //paddingBottom: '30px',
  };

  React.useEffect(() => {
    let loan = location.loan;
    //console.log(loan);
    setLoan(loan);
    if (loan) {
      setCalculations(loan);
    }

  }, [location]);

  function setCalculations(loanObj) {
    console.log(loanObj)
    try {
      //num month last emi paid
      let tmpCalc = {};
      // console.log(loanObj);
      if (!loanObj.date)
        return
      let loanDate = new Date(loanObj.date);
      loanDate.setHours(0, 0, 0, 0);
      // console.log("loan date is "+loanDate);
      let todayDate = new Date()
      if (loanObj.closure_date) {
        todayDate = new Date(loanObj.closure_date);
      }
      todayDate.setHours(23, 59, 59, 999);
      // console.log(todayDate);
      let dateDiff = todayDate - loanDate;
      // console.log(dateDiff);
      let differenceDays = parseInt(Math.ceil(dateDiff / (1000 * 3600 * 24)));
      let trailingDays = differenceDays % 30;
      // console.log("trailing diff days="+trailingDays);
      tmpCalc['day_age'] = differenceDays;
      tmpCalc['next_emi_due'] = "";
      // console.log("day elapsed="+differenceDays);
      tmpCalc['to_date'] = datehelper.displayDate(new Date(todayDate));

      tmpCalc['from_date'] = datehelper.displayDate(new Date(loanDate));
      let monthBlock = Math.floor(differenceDays / 30);
      if (monthBlock == 0) {
        monthBlock = 1;
      }
      let preTenureBlock = monthBlock
      if (preTenureBlock > loanObj.period) {
        preTenureBlock = loanObj.period
      }
      let postTenureDays = differenceDays - (30 * loanObj.period);
      // console.log("pre tenure block =%s",preTenureBlock);
      let monthData = {}
      let counter = 1;
      let lastDueCounter = 1;
      let balanceAmount = 0;
      let interestAmount = 0
      let amountReceived = 0;
      let interestDue = 0;
      let total_payment_received = 0;
      let emi_due_last_month = 0;
      if (differenceDays < 30) {

        monthData[0] = {}
        if (loanObj.payment_details) {
          amountReceived = 0
          loanObj.payment_details.map(pi => {
            if (pi.payment_type == "collection") {
              let paymentDate = Date.parse(pi.payment_date);
              let pd_diff = parseInt((paymentDate - loanDate) / (1000 * 3600 * 24));
              let pd_month = Math.floor(pd_diff / 30)
              if (pd_month == 0) {
                amountReceived = amountReceived + parseInt(pi.payment_amount);
              }

            }
          })

        }
        if (differenceDays < 15) {
          interestDue = parseInt(Math.round((15 * loanObj.loan_amount * loanObj.roi) / 3000))
        }
        else {
          interestDue = parseInt(Math.round((differenceDays * loanObj.loan_amount * loanObj.roi) / 3000))
        }

        interestAmount = monthData[0]['interest_amount'] = interestDue;
        balanceAmount = -1 * interestAmount + amountReceived;
        monthData[0]['effective_roi'] = loanObj.roi;
        monthData[0]['balance_amount'] = balanceAmount;
        monthData[0]['amount_received'] = amountReceived;


        emi_due_last_month = parseInt(Math.round((loanObj.loan_amount * loanObj.roi) / 100))
        //  console.log(monthData);
      }

      if (differenceDays >= 30 && preTenureBlock > 0) {
        counter = 1;
        while (counter <= preTenureBlock) {
          monthData[counter] = {}
          let payment_details = []
          if (loanObj.payment_details) {
            amountReceived = 0
            loanObj.payment_details.map(pi => {
              console.log(pi);
              if (pi.payment_type == "collection") {
                let paymentDate = Date.parse(pi.payment_date);
                let pd_diff = parseInt((paymentDate - loanDate) / (1000 * 3600 * 24));
                let pd_month = Math.ceil(pd_diff / 30)
                if (pd_month == counter) {
                  amountReceived = amountReceived + parseInt(pi.payment_amount);
                  payment_details = [...payment_details, pi]
                }

              }
            })

          }
          monthData[counter]['amount_received'] = amountReceived;
          total_payment_received = total_payment_received + amountReceived;
          if (balanceAmount >= 0) {
            interestDue = parseInt(Math.round((loanObj.loan_amount * loanObj.roi) / 100))
            interestAmount = monthData[counter]['interest_amount'] = interestDue;
            monthData[counter]['effective_roi'] = loanObj.roi;
            lastDueCounter = 1
          }
          else {
            let effectiveRoi = parseFloat(loanObj.roi)
            if (lastDueCounter < 2) {
              effectiveRoi = effectiveRoi + 0.25
            }
            else if (lastDueCounter < 3) {
              effectiveRoi = effectiveRoi + 0.5
            }
            else if (lastDueCounter >= 3) {
              effectiveRoi = effectiveRoi + 1
            }
            interestDue = parseInt(Math.round(loanObj.loan_amount * effectiveRoi / 100))
            interestAmount = monthData[counter]['interest_amount'] = interestDue;
            monthData[counter]['effective_roi'] = effectiveRoi;
            lastDueCounter = lastDueCounter + 1
          }
          balanceAmount = balanceAmount + amountReceived - interestDue;

          monthData[counter]['balance_amount'] = balanceAmount
          monthData[counter]['payment_details'] = payment_details
          counter = counter + 1;
        }
        tmpCalc['isPostTenure'] = false;
        if (monthBlock > loanObj.period) {
          if (balanceAmount < loanObj.loan_amount) {
            let pending_principal = loanObj.loan_amount;
            if (balanceAmount > 0) {
              pending_principal = loanObj.loan_amount - balanceAmount;
            }
            // console.log("last due counter is = "+lastDueCounter)
            let post_tenure_amount_received = 0;
            tmpCalc['isPostTenure'] = true;
            tmpCalc['post_tenure_days'] = postTenureDays;

            if (loanObj.payment_details) {
              loanObj.payment_details.map(pi => {
                if (pi.payment_type == "collection") {
                  let paymentDate = Date.parse(pi.payment_date);
                  let pd_diff = parseInt((paymentDate - loanDate) / (1000 * 3600 * 24));
                  let pd_month = Math.ceil(pd_diff / 30)
                  if (pd_month > loanObj.period) {
                    let amountReceived = parseInt(pi.payment_amount);
                    post_tenure_amount_received = post_tenure_amount_received + amountReceived
                  }
                }
              })
            }

            lastDueCounter = lastDueCounter + monthBlock - parseInt(loanObj.period)
            let effectiveRoi = parseFloat(loanObj.roi)
            effectiveRoi = effectiveRoi + 1
            let monthInterestNeeded = lastDueCounter + 1;
            // console.log("month interest needed"+monthInterestNeeded)
            interestAmount = parseInt(Math.round((pending_principal * postTenureDays * effectiveRoi) / 3000))
            tmpCalc['post_tenure_roi'] = effectiveRoi;
            tmpCalc['post_tenure_interest_amount'] = interestAmount;
            tmpCalc['post_tenure_amount_received'] = post_tenure_amount_received;
            balanceAmount = balanceAmount + post_tenure_amount_received - interestAmount
            total_payment_received = total_payment_received + post_tenure_amount_received;
            tmpCalc['post_tenure_balance'] = balanceAmount;
          }
        }
        else if (differenceDays >= 30) {
          //  console.log("calculating trailing days");
          monthData[counter] = {}
          let payment_details = []
          if (loanObj.payment_details) {
            amountReceived = 0
            loanObj.payment_details.map(pi => {
              if (pi.payment_type == "collection") {
                let paymentDate = Date.parse(pi.payment_date);
                let pd_diff = parseInt((paymentDate - loanDate) / (1000 * 3600 * 24));
                let pd_month = Math.ceil(pd_diff / 30)
                if (pd_month == counter) {
                  amountReceived = amountReceived + parseInt(pi.payment_amount);
                  payment_details = [...payment_details, pi]
                }

              }
            })

          }
          monthData[counter]['amount_received'] = amountReceived;
          monthData[counter]['payment_details'] = payment_details;
          // console.log("amount received=="+amountReceived);
          total_payment_received = total_payment_received + amountReceived;

          if (trailingDays > 0) {
            let effectiveRoi = parseFloat(loanObj.roi)
            if (balanceAmount >= 0) {
              effectiveRoi = effectiveRoi;
            }
            else if (lastDueCounter < 2) {
              effectiveRoi = effectiveRoi + 0.25
            }
            else if (lastDueCounter < 3) {
              effectiveRoi = effectiveRoi + 0.5
            }
            else if (lastDueCounter >= 3) {
              effectiveRoi = effectiveRoi + 1
            }
            interestDue = parseInt(Math.round((loanObj.loan_amount * trailingDays * effectiveRoi) / 3000))
            interestAmount = monthData[counter]['interest_amount'] = interestDue;
            monthData[counter]['effective_roi'] = effectiveRoi;
            balanceAmount = balanceAmount - interestAmount + amountReceived;
            monthData[counter]['balance_amount'] = balanceAmount;
            // console.log(monthData[counter]);
            emi_due_last_month = parseInt(Math.round(loanObj.loan_amount * effectiveRoi / 100))
            emi_due_last_month = -1 * (balanceAmount - emi_due_last_month + amountReceived)
          }
        }
      }
      // console.log(monthData);
      tmpCalc['month_data'] = monthData
      tmpCalc['interest_amount'] = (-1 * balanceAmount)
      tmpCalc['foreclosure_amount'] = loanObj['loan_amount'] - balanceAmount;
      tmpCalc['month_end_emi_due'] = emi_due_last_month;
      tmpCalc['next_emi_due'] = "";
      if (loanObj['loan_status'] != "Closed") {
        // console.log("calculating emi date");
        if (postTenureDays > 0) {
          // console.log("it is post tenure days");
          tmpCalc['next_emi_due'] = datehelper.displayDate(todayDate);
        }
        else {
          let emi_found = false;
          let next_due_date = loanDate;
          next_due_date=next_due_date.setDate(loanDate.getDate() -1);
          while (!emi_found) {
            next_due_date = next_due_date.setDate(loanDate.getDate() + 30);
            //  console.log("next Emi due" );
            //  console.log(new Date(next_due_date));
            let dateDiff = next_due_date - todayDate;
            //  console.log("date diff="+dateDiff);
            if (dateDiff > 0) {
              emi_found = true;
              tmpCalc['next_emi_due'] = datehelper.displayDate(new Date(next_due_date));

            }
            next_due_date = new Date(next_due_date);
          }
        }
      }
      // console.log(tmpCalc);
      setLoanCalculations(tmpCalc);
    }
    catch (exc) {
      // console.log(exc)
    }
  }
  const IntrestCalculatorRef = React.useRef();

  const handleSubmissionPrint = useReactToPrint({
    content: () => IntrestCalculatorRef.current,
    pageStyle: "print"
  });

  return (
    <div style={{ textAlign: "justify" }}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <p><b>Payment Details</b></p>
            <Table style={{ width: "90%", margin:'auto' }}>
              <Thead>
                <Tr>
                  <Th style={{width:'40%'}} >Amount</Th>
                  <Th style={{width:'30%'}} >Date</Th>
                  <Th style={{width:'30%'}} >Mode</Th>
                </Tr>
              </Thead>
              <Tbody>
              {
              payDetails.length > 0 && payDetails.map(pay =>
                <Tr>
                  <Td>Rs. {pay.payment_amount}/-</Td>
                  <Td>{pay.payment_date}</Td>
                  <Td>{pay.payment_mode}</Td>
                </Tr>)}
              </Tbody>
            </Table>
          </Typography>
        </Box>
      </Modal>
      {(loan && (
        <>
          <div className="row">
            <div className="col-md-2">
              <button onClick={handleSubmissionPrint}>Print Letter</button>
            </div>
            <div className="col-md-8">
              <h3><b>Interest Calculator for Gold Loan</b></h3>
            </div>

          </div>
          <hr />
          <div ref={IntrestCalculatorRef} className="row justify-content-around">
            <div className="col-md-4">
              <Table>
                <Tbody>
                  <Tr><Td>Loan Account:</Td><Td>{loan.loan_account}</Td></Tr>
                  <Tr><Td>Name:</Td><Td>{loan.name}</Td></Tr>
                  <Tr><Td>Loan Date:</Td><Td>{loanCalculations['from_date']}</Td></Tr>
                  <Tr><Td>Today/Close Date:</Td><Td>{loanCalculations['to_date']}</Td></Tr>
                  <Tr><Td>Loan Days:</Td><Td>{loanCalculations["day_age"]} days</Td></Tr>

                  <Tr><Td>Loan Amount:</Td><Td>Rs {loan.loan_amount}</Td></Tr>
                  <Tr><Td>Roi:</Td><Td>{loan.roi}% pm</Td></Tr>
                  <Tr><Td>Loan Tenure (Months):</Td><Td>{loan.period} Months</Td></Tr>
                  <Tr><Td>Next EMI Date: </Td><Td>{loanCalculations['next_emi_due']}</Td></Tr>

                  <Tr><Td><b>Interest Due</b>:</Td><Td>Rs {loanCalculations['interest_amount']}</Td></Tr>
                  <Tr><Td><b>Closure Amount (Today) </b>:</Td><Td>Rs {loanCalculations['foreclosure_amount']}</Td></Tr>

                </Tbody>
              </Table>
            </div>
            <div className="col-md-8">
              <p><b>Monthwise Interest Calculation:</b></p>
              <Table style={{ width: "70%" }}>
                <Thead>
                  <Tr><Th>Month
                  </Th><Th>Effective ROI</Th><Th>Interest Amount</Th><Th>Amount Received</Th><Th>Balance Amount</Th></Tr>
                </Thead>
                <Tbody>
                  {loanCalculations && Object.keys(loanCalculations['month_data']).map((m, idx) => {
                    console.log(loanCalculations)
                    return (loanCalculations['month_data'][m] && (<Tr kry={idx}>
                      <Td>Month #{m}</Td>
                      <Td>{loanCalculations['month_data'][m]['effective_roi']}</Td>
                      <Td>Rs {loanCalculations['month_data'][m]['interest_amount']}</Td>
                      <Td><button style={{border:'none',color:'blue'}} onClick={() => handleOpen(loanCalculations['month_data'][m]['payment_details'])} >Rs {loanCalculations['month_data'][m]['amount_received']}</button></Td>
                      <Td>Rs {loanCalculations['month_data'][m]['balance_amount']}</Td>

                    </Tr>))
                  })
                  }
                </Tbody>
              </Table>

              {loanCalculations && loanCalculations['isPostTenure'] && (
                <>
                  <p><b>Post Tenure Interest Calculation:</b></p>
                  <Table style={{ width: "70%" }}>
                    <Thead>
                      <Tr><Th>Post Tenure days
                      </Th><Th>Effective ROI</Th><Th>Interest Amount</Th><Th>Amount Received</Th><Th>Balance Amount</Th></Tr>
                    </Thead>
                    <Tbody>
                      <Tr><Td>{loanCalculations['post_tenure_days']} days</Td>

                        <Td>{loanCalculations['post_tenure_roi']}% pm</Td>
                        <Td>Rs. {loanCalculations['post_tenure_interest_amount']}</Td>
                        <Td>{loanCalculations['post_tenure_amount_received']}</Td>
                        <Td>Rs {loanCalculations['post_tenure_balance']}</Td></Tr>
                    </Tbody>
                  </Table>
                </>
              )}

              {loanCalculations && loanCalculations['month_end_emi_due'] > 0 && (
                <div style={{ marginTop: "30px" }}>
                  <p><b>Month End EMI Due:</b>Rs. {loanCalculations['month_end_emi_due']}</p>
                </div>
              )}


            </div>
          </div>
        </>
      ))}
    </div>
  );
}
