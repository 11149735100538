import React, {useEffect, useState} from 'react'
import { loanService } from '../../../_services';
import { Edit } from "@material-ui/icons";
import { history } from "../../../_helpers/history"

function ApplicationFormList() {

    const [data, setData] = useState([])

    const getData = () => {
        loanService.applicationFormData()
        .then((x)=> {
         setData(x)
          console.log(x)
        })
        .catch((err)=> {console.log(err)})
      }

      useEffect(() => {
        getData()
      }, [])

      const editForm = (params) =>{
        // console.log(params)
        history.push(`/home/sblloans/edit/applicationform`, params)
      }
      
  return (
    <div>
        <h1>Application Forms</h1>
        <table>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Aadhar</th>
                    <th>Pan</th>
                    <th>Applicatin Id</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {data && data.map((x)=>{
                    return <tr>
                        <td>{x.title}{x.first_name}{x.last_name}</td>
                        <td>{x.aadhar_no}</td>
                        <td>{x.pan_no}</td>
                        <td>{x.application_id}</td>
                        <td><button className="btn btn-sm btn-primary mr-1" onClick={()=>{editForm(x)}}><Edit /> </button></td>
                    </tr>
                })}
            </tbody>
        </table>
    </div>
  )
}

export default ApplicationFormList