import React, { useState, useEffect } from "react";
import { employeeService } from "../../_services/employee.service";
import {
  Grid,
  Card,
  Select,
  Button,
  CardActions,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { loanService } from "../../_services";
import SBLRepaymentScheduleModal from "./SBLRepaymentScheduleModal";
import LetterList from "./Letters/SBLLetterList";
import { Input } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import datehelper from "../../_helpers/datehelper";

const { Search } = Input;
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexGrow: 1,
  },
  card: {
    minHeight: 250,
    minWidth: 250,
  },
}));

function CollectionList({ match }) {
  const { path } = match;
  const [loans, setLoans] = useState(null);
  const user = employeeService.userValue;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchFilter, setSearchFilter] = React.useState("");
  const [contactFilter, setContactFilter] = React.useState("");
  const [branchList, setBranchList] = React.useState([]);
  const [filterBranch, setFilterBranch] = React.useState(null);

  const [value, setValue] = useState({
    name: "",
    contact: "",
    aadhar: "",
    application_id: "",
  });

  const handleChange = (e) => {
    let fieldKey = [e.target.name];
    let fieldValue = e.target.value;
    value[fieldKey] = fieldValue;
    console.log(value);
    setValue(value);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  async function generateRepaymentSchedule(loanId) {
    console.log("generating repayment schedule for " + loanId);
    loanService.generateRepaymentSchedule(loanId).then(() => {
      window.alert("Repayment schedule generated");
      window.location.reload();
    });
  }

  const getData = () => {
    loanService.getBySearch(value).then((x) => {
      let tmpBranchList = [];
      let tempLoans = x.filter((l) => {
        if (!tmpBranchList.includes(l.branch_id)) {
          tmpBranchList.push(l.branch_id);
        }
        if (searchFilter != "") {
          if (
            l.applicant_name &&
            l.applicant_name.toLowerCase().includes(searchFilter.toLowerCase())
          ) {
            return true;
          }
          if (
            l.application_id === searchFilter ||
            l.application_id === parseInt(searchFilter)
          ) {
            return true;
          }
          return false;
        } else if (contactFilter != "") {
          if (
            l.applicant_contact &&
            (l.applicant_contact === contactFilter ||
              l.applicant_contact === parseInt(contactFilter))
          ) {
            return true;
          }
          if (
            l.applicant_aadhar &&
            (l.applicant_aadhar === contactFilter ||
              l.applicant_aadhar === parseInt(contactFilter))
          ) {
            return true;
          }
          return false;
        } else {
          return true;
        }
      });
      if (filterBranch) {
        tempLoans = tempLoans.filter((l) => {
          if (l.branch_id === filterBranch) {
            return true;
          }
          return false;
        });
      }

      console.log(tempLoans);
      setBranchList(tmpBranchList);
      setLoans(tempLoans);
    });
  }

  return (

    <div>
    <h1>Collection Pending Loans</h1><br/>
    <div style={{margin:"auto", display:"flex"}}>
        <label> Search By Application Id<input type='text' name="application_id" onChange={handleChange} /> </label>
        <label> Search By Aadhar Number<input type='text' name="aadhar" onChange={handleChange} /> </label>
        <label> Search By Name<input type='text' name="name" onChange={handleChange} /> </label>
        <label> Search By Contact<input type='text' name="contact" onChange={handleChange} /> </label>
    </div><br/>
    <button style={{textAlign:"center", marginLeft:"40%", width:"150px"}} className="btn btn-primary" onClick={getData} >Search</button>
    <hr style={{border:"1px solid"}}></hr><br/>

{loans ? <TableContainer>
<Table>
  <Thead>
  <Tr>
            <Th style={{ width: "10%" }}>Loan Application Number</Th>
            <Th style={{ width: "15%" }}>Applicant Name</Th>
            <Th style={{ width: "20%" }}>EMI</Th>
            <Th style={{ width: "10%" }}>Sanction Amount</Th>
            <Th style={{ width: "10%" }}>Branch</Th>
            <Th style={{ width: "10%" }}>Disburse Amount</Th>
            <Th style={{ width: "10%" }}>Disburse Date </Th>
            <Th style={{ width: "10%" }}>Action</Th>
          </Tr>
  </Thead>
  <Tbody>
          {loans &&
            loans.map((loan) => (
              <Tr key={loan._id}>
                <Td>{loan.application_id}</Td>
                <Td>
                  {loan.applicant &&
                    loan.applicant.general_information &&
                    loan.applicant.general_information.name}
                </Td>
                <Td>
                </Td>
                <Td>Rs. {loan.loan.sanction_amount}</Td>
                <Td>{loan.branch_id}</Td>

                <Td>{loan.loan.disbursed_amount}</Td>
                <Td>
                  {loan.loan.disbursement_date &&
                    datehelper.displayDate(
                      new Date(loan.loan.disbursement_date)
                    )}
                </Td>
                <Td>
                  <Button
                    component="a"
                    href={`/home/sblloans/details/${loan._id}`}
                    size="small"
                    color="primary"
                  >
                    View Details
                  </Button>
                  <br />
                  <LetterList id={loan._id} stage={loan.stage} />
                </Td>
              </Tr>
            ))}
          {!loans && (
            <Tr>
              <Td colSpan="4" className="text-center">
                <span className="spinner-border spinner-border-lg align-center"></span>
              </Td>
            </Tr>
          )}
        </Tbody>
  </Table>
</TableContainer>
: <h4 style={{textAlign:"center"}}>***Search for Loan Details***</h4> }
</div>
  );
}

export { CollectionList };
