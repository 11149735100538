const Buffer = require("buffer").Buffer;
const urlhelper={
    encodeToBase64,
    decodeFromBase64
}

function encodeToBase64(str)
{
    let buff=Buffer.from(str);
    return buff.toString("base64");
}

function decodeFromBase64(str)
{
    let buff=Buffer.from(str,"base64");
    return buff.toString();
}
export default urlhelper; 