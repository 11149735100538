import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { employeeService } from "../_services";

import { Login } from "./Login";
import ForgetPassword from "./ForgetPassword";

function Employee({ history, match }) {
  const { path } = match;
   console.log(path)
  useEffect(() => {
    // redirect to home if already logged in
    if (employeeService.userValue ) {
      if(employeeService.productValue!="colending"){

        history.push("/home");
      }else{
        history.push("/home/colending/dashboard");
      }
    }
  }, [history]);

  return (
    <div
     
    >
      <div  >
         
            <div >
              <Switch>
                <Route path={`${path}/login`} component={Login} />
                <Route path={`${path}/forgetpassword`} component={ForgetPassword}/>
              </Switch>
           
        </div>
      </div>
    </div>
  );
}

export { Employee };
