import React from 'react'
import { DisbursementDetailsModal } from './DisbursementDetailsModal'
import datehelper from '../../../_helpers/datehelper'
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import './ViewLoanDetails.css';
import { employeeService } from '../../../_services';
import roleChecker from '../../../_helpers/rolechecker';

const DisbursementDetailsTab = (props) => {
    console.log("Test disburese", props)
    const data = props.loan
    // const id =props.loan._id 
    // console.log("Test id",id)
    const user = employeeService.userValue;
    const payments = [];
    const pf = [];
    console.log("Test payment and pf", payments, pf)
    return (
        <div>
            <div style={{ display: 'flex', float: 'right' }}>
                {(roleChecker.hasAccountsAccess(user.role) || data.loan_status !== 'Locked' || data.loan_status === 'Locked') && <DisbursementDetailsModal loan={data} />}
            </div>
            {data && data.payment_details.length > 0 && data.payment_details.map(payment => <>{payment.payment_type === 'disbursement' && console.log(payments.push(payment))}{payment.payment_type === 'pf' && console.log(pf.push(payment))}</>)}
            {
                payments.length > 0 ?
                    <div>
                         <h1>Disbursement Details</h1>
                        <div  className='ornaments-details'>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th className='table-row-th'>Date</Th>
                                    <Th className='table-row-th'>Type</Th>
                                    <Th className='table-row-th'>Mode</Th>
                                    <Th className='table-row-th'>Reference</Th>
                                    <Th className='table-row-th'>Amount</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {payments.map(payment =>
                                    <Tr>
                                        <Td className='table-row-td'>{datehelper.displayDate(new Date(payment.payment_date))}</Td>
                                        <Td className='table-row-td'>{payment.payment_type}</Td>
                                        <Td className='table-row-td'>{payment.payment_mode}</Td>
                                        <Td className='table-row-td'>{payment.payment_ref}</Td>
                                        <Td className='table-row-td'>{payment.payment_amount}</Td>
                                    </Tr>)}

                            </Tbody>
                        </Table>
                        </div>
                    </div>
                    : null}
            <br />
            <br/>
            {
                pf.length > 0 ?
                    <div>
                           <b style={{ fontSize: '30px' }}>PF Details</b><br />
                           <div className='ornaments-details'>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th className='table-row-th'>Date</Th>
                                    <Th className='table-row-th'>Type</Th>
                                    <Th className='table-row-th'>Mode</Th>
                                    <Th className='table-row-th'>Reference</Th>
                                    <Th className='table-row-th'>Amount</Th>
                                </Tr>
                            </Thead>
                            <Tbody>

                                {pf.map(payment =>
                                    <Tr>
                                        <Td className='table-row-td'>{datehelper.displayDate(new Date(payment.payment_date))}</Td>
                                        <Td className='table-row-td'>{payment.payment_type}</Td>
                                        <Td className='table-row-td'>{payment.payment_mode}</Td>
                                        <Td className='table-row-td'>{payment.payment_ref}</Td>
                                        <Td className='table-row-td'>{payment.payment_amount}</Td>
                                    </Tr>)}

                            </Tbody>
                        </Table>
                        </div>
                    </div>
                    : null
            }
            <br />
            {data.insurance_amount ? <h3>Insurance Amount:₹{data.insurance_amount}</h3> : null}
        </div>
    )
}

export default DisbursementDetailsTab
