import React from "react";
import { history } from "../../../../_helpers/history"
import { Edit } from "@material-ui/icons";
import SelectApplicationForm from "../../Letters/SelectApplicationForm";
import roleChecker from "../../../../_helpers/rolechecker";
import { employeeService } from "../../../../_services";
import { LoanStages } from "../../../../constants/loanstages";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Button } from "@material-ui/core";

export default function ApplicationFormDetails({ customer }) {
    const user = employeeService.userValue;
    const editForm = (params) =>{
        // console.log(params)
        history.push(`/home/sblloans/edit/applicationform`, params)
      }
    return (
 
        (roleChecker.isHOLevelAccess(user.role) || roleChecker.isBranchLevelAccess(user.role) || roleChecker.isClusterSaleLevelAccess(user.role) || roleChecker.isSalesTeamAccess(user.role) || roleChecker.isColendingAccess(user.role)) && (

            <div>
            <h2>Application Forms</h2>
            {![LoanStages.Closed,LoanStages.Collection,LoanStages.Rejected,LoanStages.Discarded].includes(customer.stage) && (
                <SelectApplicationForm id={customer._id} />
            )}
            <br/>
            List of Application Forms:<br/>
            {customer.application_form && customer.application_form.length>0 ? 
                <table style = {{border: "2px solid black"}}>
                <tr style = {{borderBottom: "2px solid black"}}><th>Name</th>
                    <th>Aadhar</th>
                    <th>Pan</th>
                    <th>Application Id</th>
                    <th>Edit</th>
                    <th>Preview</th>
                    </tr>
                {customer.application_form.map((x,idx)=> {
                    return (
                <tr key={idx} style = {{borderBottom: "2px solid black"}}>
                <td>{x.title}{x.first_name}{x.last_name}</td>
                <td>{x.aadhar_no}</td>
                <td>{x.pan_no}</td>
                <td>{x.application_id}</td>
                <td><button className="btn btn-sm btn-primary mr-1" onClick={()=>{editForm(x)}}><Edit /> </button></td>
                <td><Button style={{color:"black"}} onClick={()=>{history.push('/home/sblloans/preview/applicationform', {value:x})}}><VisibilityIcon /> </Button></td>
                </tr>
                    )

                })}
                </table> 
                :<h4 style={{color:'red'}}>No application form created</h4> 
            }   
            <br/>    
            </div>
        )              
    );
}  