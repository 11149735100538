import React, {useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { uploadFileToBucket } from "../../../../_helpers/upload"
import urlhelper from '../../../../_helpers/urlhelper';

import * as Yup from "yup";

import { Formik, Field,  useFormikContext, Form, ErrorMessage } from "formik";

import Fab from "@material-ui/core/Fab";
import SaveIcon from "@material-ui/icons/Save";
import { Button } from "@material-ui/core";
import { loanService } from "../../../../_services/loan.service";
import { employeeService } from "../../../../_services";
import FormElement from "../../../uielements/FormElement";
import { ColendingSubStages } from "../../../../constants/colendingsubstages";
import { history } from "../../../../_helpers";
import roleChecker from "../../../../_helpers/rolechecker";

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "50%",
    overflow: "scroll",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    minWidth: 650,
  },
}));

export default function AddColenderModal(props) {
  const customer = props.customer;
  console.log("customer",customer)
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [url, setUrl] = React.useState("")
  const [employeeList, setEmployeeList] = React.useState([]);
  const user = employeeService.userValue;
  const colenderNameOptions = ["CIFL", "CIHL", "Ugro", "KamalAutoFinance","VistaarFinance"];
  const colenderStatusOptions = [
    ColendingSubStages.FilePrepared,
    ColendingSubStages.FileLoggedIn,
    ColendingSubStages.QueryPendingAtKaabil,
    ColendingSubStages.QueryPendingAtColender,
    ColendingSubStages.ApprovedFromCredit,
    ColendingSubStages.ApprovalForDisbursement,
  ];

  const inputFields = [
    "co_lender_name",
    "co_lender_account_num",
    "co_lender_status",
    "co_lender_status_date",
    "co_lender_sanction_amount",
    "co_lender_sanction_date",
    "co_lender_interest_rate",
    "co_lender_tenure",
    "co_lender_emi_day",
    "co_lender_received_amount",
    "co_lender_received_date",
    "co_lender_stage_emp_id",
    "co_lender_insurance_amount",
    "co_lender_rps_report",
    "co_lender_pf_amount"
  ];
  const initialValues = {
    tcolender: {
      co_lender_name: "",
      co_lender_account_num: "",
      co_lender_status: "",
      co_lender_status_date: Date.now(),
      co_lender_sanction_amount: "",
      co_lender_sanction_date: "",
      co_lender_interest_rate: "",
      co_lender_tenure: customer.loan.tenure,
      co_lender_emi_day: "",
      co_lender_received_amount: "",
      co_lender_received_date: "",
      co_lender_stage_emp_id: "",
      co_lender_insurance_amount: "",
      co_lender_rps_report: "",
      co_lender_pf_amount:"",
    },
  };

  useEffect(() => {
    employeeService
      .getAll()
      .then((el) => {
        console.log(el);
        let fel = el.filter((e) => {
          if (roleChecker.isColendingAccess(e.role)) {
            return true;
          } else {
            return false;
          }
        });
        setEmployeeList(fel);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const maxDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); 
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  }

  const validationSchema = Yup.object().shape({
    tcolender: Yup.object().shape({
      co_lender_name: Yup.string().required("This field is required"),
      co_lender_account_num: Yup.string().required("This field is required"),
      co_lender_status: Yup.string().required("This field is required"),
      co_lender_stage_emp_id: Yup.number().required("Employee name is needed"),
      co_lender_received_date: Yup.date().max(maxDate(), "Future date not allowed")
    }),
  });

  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const bucket = process.env.REACT_APP_s3docsBucket

    const upload = async (e) =>{
      console.log("uploaded")
      let selectedFile = e.target.files[0]
     let filename = selectedFile.name.split(".")
     filename = filename[0]
     console.log(filename)
      await uploadFileToBucket(bucket, "application/pdf", `${colender_name}/insurance_report/${customer.application_id}_${filename}.pdf`, selectedFile)
      .then((x)=>{
        console.log(x) 
         setUrl(x)
      })
      .catch((err)=>{console.log(err)})
    } 

    console.log(url) 


 let colender_name;
  function handleChange(fieldName, value, setFieldValue) {
    setFieldValue(fieldName, value);
  }

  async function onNewColenderSubmit(fields, { setSubmitting }) {
    fields = {
      tcolender:{
      co_lender_name: fields["tcolender"]["co_lender_name"] ,
      co_lender_account_num: fields["tcolender"]["co_lender_account_num"] ,
      co_lender_status: fields["tcolender"]["co_lender_status"],
      co_lender_status_date: Date.now(),
      co_lender_sanction_amount: fields["tcolender"]["co_lender_sanction_amount"],
      co_lender_sanction_date: fields["tcolender"]["co_lender_sanction_date"],
      co_lender_interest_rate: fields["tcolender"]["co_lender_interest_rate"],
      co_lender_tenure: fields["tcolender"]["co_lender_tenure"],
      co_lender_emi_day: fields["tcolender"]["co_lender_emi_day"],
      co_lender_received_amount: fields["tcolender"]["co_lender_received_amount"],
      co_lender_received_date: fields["tcolender"]["co_lender_received_date"],
      co_lender_stage_emp_id: fields["tcolender"]["co_lender_stage_emp_id"],
      co_lender_insurance_amount: fields["tcolender"]["co_lender_insurance_amount"],
      co_lender_rps_report: url,
      co_lender_pf_amount: fields["tcolender"]["co_lender_pf_amount"]
      }
    }

    // check for Amount Recieved status
    if(fields["tcolender"]["co_lender_status"] == ColendingSubStages.AmountReceivedFromColender)
    {
       //check sanction amount. it should be lower than loan sanction amount
       //amount received should be lower than sanction amount
       //receive date should be less than tomorrow
       //roi should be there
       //tenure should be there and greater than 12

       let sanctionAmount=fields["tcolender"]["co_lender_sanction_amount"];
       let receivedAmount=fields["tcolender"]["co_lender_received_amount"];
       let receiveDate=fields["tcolender"]["co_lender_received_date"];
       let roi=fields["tcolender"]["co_lender_interest_rate"];
       let tenure=fields["tcolender"]["co_lender_tenure"];
       let emiDay=fields["tcolender"]["co_lender_emi_day"];
       if(!sanctionAmount || sanctionAmount > customer.loan.sanction_amount)
       {
           window.alert("please fill valid sanction amount");
           return;
       }
       if(!receivedAmount || receivedAmount >sanctionAmount)
       {
          window.alert("Please fill valid receive amount");
          return;
       }
       if(!roi || roi >100)
       {
        window.alert("Please fill valid ROI");
        return;
       }
       if(!tenure || tenure <12)
       {
        window.alert("Please fill valid tenure");
        return;
       }
       if(!emiDay || emiDay <1 || emiDay >31)
       {
        window.alert("Please fill valid emiDay");
        return;
       }

    }
    console.log("new colender submit called");
    let params = {};
    console.log(fields);
    if (!customer.co_lending) {
      params["co_lending"] = [];
    } else {
      params["co_lending"] = customer.co_lending;
    }

   colender_name = fields["tcolender"]["co_lender_name"];
    let existingColender = false;
    params["co_lending"].map((l) => {
      if (l.co_lender_name === colender_name) {
        existingColender = true;
      }
    });
    if (existingColender) {
      window.alert("Colender already added");
      return;
    }

    let newCoLender = {};
    inputFields.map((ip) => {
      newCoLender[ip] = fields["tcolender"][ip];
    });
    console.log(newCoLender);

    params["co_lending"].push(newCoLender);
    console.log(params);
    // console.log({co_lending :[{...params.co_lending.co_lending[0], rps_report : url}]})
    await loanService
      .updateColender(customer._id, params)
      .then((ns) => {
        console.log("Colender added");
        window.alert("Details added");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        window.alert("error adding details");
      });
    setSubmitting(false);
  }

  return (
    <div>
     {customer && !["Collection"].includes(customer.stage) && roleChecker.isColendingTeamAccess(user.role)  && <Button onClick={handleOpen} variant="contained" color="primary">
        Add Co-Lender
      </Button>}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Colender Details</h2>

            <Formik
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={onNewColenderSubmit}
              enableReinitialize={true}
              id="transition-modal-description"
            >
              {({ errors, touched, isSubmitting, setFieldValue, values }) => {
                return (
                  <Form>
                   {/* <SetFile/> */}
                    <div className="form-row">
                      <div className="form-group col">
                        <label>Co-Lender Name:</label>
                        <br />
                        <Field
                          as="select"
                          style={{ marginTop: "10px" }}
                          name="tcolender.co_lender_name"
                          label="Co lender name"
                          errors={errors}
                          touched={touched}
                        >
                          <option value="" disabled selected>
                            Select Colender
                          </option>
                          {colenderNameOptions.map((g) => {
                            return <option value={g}>{g}</option>;
                          })}
                        </Field>
                      </div>
                      <div className="form-group col">
                        <FormElement
                          type="text"
                          name="tcolender.co_lender_account_num"
                          label="Co lender Account Number"
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col">
                        <Field
                          as="select"
                          style={{ marginTop: "10px" }}
                          name="tcolender.co_lender_status"
                          label="Co lender Status"
                          errors={errors}
                          touched={touched}
                        >
                          <option value="" disabled selected>
                            Select Status
                          </option>
                          {colenderStatusOptions.map((g) => {
                            return <option value={g}>{g}</option>;
                          })}
                        </Field>
                      </div>

                      <div className="form-group col">
                        <Field
                          as="select"
                          style={{ marginTop: "10px" }}
                          name="tcolender.co_lender_stage_emp_id"
                          label="Stage Owner"
                          errors={errors}
                          touched={touched}
                        >
                          <option value="" disabled selected>
                            Select Employee
                          </option>
                          {employeeList &&
                            employeeList.map((e) => {
                              return (
                                <option value={e.emp_id}>
                                  {e.emp_id} - {e.first_name} {e.last_name}{" "}
                                </option>
                              );
                            })}
                        </Field>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col">
                        <FormElement
                          type="number"
                          name="tcolender.co_lender_tenure"
                          label="Tenure (Months)"
                          errors={errors}
                          touched={touched}
                        />
                      </div>

                      <div className="form-group col">
                        <FormElement
                          type="number"
                          name="tcolender.co_lender_emi_day"
                          label="EMI Day"
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col">
                        <FormElement
                          type="number"
                          name="tcolender.co_lender_sanction_amount"
                          label="Colender sanction amount"
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                      <div className="form-group col">
                        <FormElement
                          type="number"
                          name="tcolender.co_lender_interest_rate"
                          label="Colender Interest Rate "
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col">
                        <FormElement
                          type="number"
                          name="tcolender.co_lender_received_amount"
                          label="Received Amount"
                          errors={errors}
                          touched={touched}
                        />
                      </div>

                      <div className="form-group col">
                        <FormElement
                          type="date"
                          name="tcolender.co_lender_received_date"
                          label="Amount Receipt Date"
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col">
                        <FormElement
                          type="number"
                          name="tcolender.co_lender_insurance_amount"
                          label="Insurance Amount"
                          errors={errors}
                          touched={touched}
                        />
                      </div>

                      <div className="form-group col">
                      <input
                          type="file"
                          accept=".pdf"
                          name="tcolender.co_lender_rps_report"
                          onChange={(e) => upload(e)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                    <div className="form-group col">
                        <FormElement
                          type="number"
                          name="tcolender.co_lender_pf_amount"
                          label="PF"
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </div>

                    <hr />

                    <div className="row justify-content-around">
                      <Fab
                        type="submit"
                        disabled={isSubmitting}
                        color="primary"
                        aria-label="save"
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-1"></span>
                        )}
                        <SaveIcon />
                      </Fab>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
