import React, { useEffect } from 'react';
import { useState } from 'react';
import { Modal, Box, Typography, Button } from "@material-ui/core";
import datehelper from '../../../_helpers/datehelper';
import { CircularProgress } from '@material-ui/core';
import { employeeService, misService } from '../../../_services';
import GoldAuctionServices from '../../../_services/goldauction.service';

export const AuctionDetailsTab = (props) => {
    const user = employeeService.userValue;
    console.log(props)
    // const user = props.user;
    const loan = props.loan;
    let ndate = new Date()
    const [goldAuction, setGoldAuction] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [value, setValue] = useState({
        auction_details: {
            auction_date: '',
            auction_amount: null,
            bidder_name: '',
            payment_mode: 'cash',
            bidder_aadhaar_no: null,
            bidder_mobile_no: null,
            emp_id: user.emp_id,
        }
    })
    const handleChange = (e) => {
        setValue({ ...value, auction_details: { ...value.auction_details, [e.target.name]: e.target.value } })
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        let arr = loan.payment_details
        let list = { payment_type: 'collection', payment_mode: value.auction_details.payment_mode, payment_ref: 'Auction Details', payment_amount: value.auction_details.auction_amount, payment_date: value.auction_details.auction_date }
        arr.push(list)
        await GoldAuctionServices.UpdateNoticeDetails(goldAuction._id, value)
            .then(res => {
                misService.auctionLoan(loan._id, { loan_status: 'Closed', closure_date: value.auction_details.auction_date, closed_by_emp_id: user.emp_id, auction_date: value.auction_details.auction_date, payment_details: arr })
                    .then(res => {
                        window.alert('Auction Details Saved');
                        window.location.reload();
                    })
                    .catch(err => {
                        console.log(err);
                        window.alert('Some Error Occured')
                    })
            })
            .catch(err => {
                console.log(err);
                window.alert('Some Error Occured')
            })
        setIsLoading(false)
    }
    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setIsLoading(true)
        GoldAuctionServices.FindByLoan({ loan_no: loan.loan_account })
            .then(res => {
                setGoldAuction(res[0])
                if (res[0].auction_details) {
                    setValue({
                        ...value,
                        auction_details: {
                            ...value.auction_details,
                            auction_amount: res[0].auction_details.auction_amount,
                            auction_date: res[0].auction_details.auction_date,
                            bidder_name: res[0].auction_details.bidder_name,
                            bidder_aadhaar_no: res[0].auction_details.bidder_aadhaar_no,
                            bidder_mobile_no: res[0].auction_details.bidder_mobile_no,
                        }
                    })
                }
                setIsLoading(false)
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
            })
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        handleOpen()
    }, [])

    return (
        <div>
            {
                goldAuction && goldAuction.publication && goldAuction.publication.publication1 && goldAuction.publication.publication2 ?
                    <Typography style={{ margin: '0px 70px 0px 70px' }}>
                        <h1 style={{ textAlign: 'center' }}>Auction Details</h1>
                        <br/>
                        <br/>
                        <form onSubmit={(e) => handleSubmit(e)} autoComplete='off'>
                            <fieldset disabled={goldAuction.auction_details} >
                                <div style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row' }}>
                                    <label style={{ width: '250px', display: 'flex', flexDirection: 'column' }}>Auction Date
                                        {value.auction_details.auction_date !== '' ?
                                            <input type="Date" style={{ borderRadius: "5px", height: "50px" }} name='auction_date' max={datehelper.getDateInputString(ndate)} onChange={(e) => handleChange(e)} defaultValue={datehelper.getDateInputString(new Date(value.auction_details.auction_date))} disabled={goldAuction.auction_details} required />
                                            : <input type="Date" style={{ borderRadius: "5px", height: "50px" }} name='auction_date' max={datehelper.getDateInputString(ndate)} onChange={(e) => handleChange(e)} disabled={goldAuction.auction_details} required />}
                                    </label>
                                    <label style={{ width: '250px', display: 'flex', flexDirection: 'column' }}>Payment Mode
                                        <select name="payment_mode" style={{ borderRadius: "5px", height: "50px" }} onChange={(e) => handleChange(e)} required>
                                            <option value="cash">Cash</option>
                                            <option value="online">Online</option>
                                            <option value="cheque">Cheque</option>
                                            <option value="bank_transfer">Bank Transfer</option>
                                        </select>
                                    </label>
                                    <label style={{ width: '250px', display: 'flex', flexDirection: 'column' }}>Auction Amount
                                        <input type="Number" style={{ borderRadius: "5px", height: "50px" }} name='auction_amount' onChange={(e) => handleChange(e)} defaultValue={value.auction_details.auction_amount} placeholder="Auction Amount" min={1} required />
                                    </label>
                                </div>
                                <br />
                                <br />
                                <div style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row' }}>
                                    <label style={{ width: '250px', display: 'flex', flexDirection: 'column' }}>Bidder Name
                                        <input type="text" style={{ borderRadius: "5px", height: "50px" }} name='bidder_name' onChange={(e) => handleChange(e)} defaultValue={value.auction_details.bidder_name} placeholder="Bidder Name" required />
                                    </label>
                                    <label style={{ width: '250px', display: 'flex', flexDirection: 'column' }}>Bidder Aadhaar
                                        <input pattern='[0-9]{12}' title='Mobile number should be of 12 alphanumeric digits' style={{ borderRadius: "5px", height: "50px" }} name='bidder_aadhaar_no' onChange={(e) => handleChange(e)} defaultValue={value.auction_details.bidder_aadhaar_no} placeholder="Aadhaar No" required />
                                    </label>
                                    <label style={{ width: '250px', display: 'flex', flexDirection: 'column' }}>Bidder Mobile No.
                                        <input pattern='[0-9]{10}' title='Mobile number should be of 10 alphanumeric digits' style={{ borderRadius: "5px", height: "50px" }} name='bidder_mobile_no' onChange={(e) => handleChange(e)} defaultValue={value.auction_details.bidder_mobile_no} placeholder="Mobile No" required />
                                    </label>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                    {isLoading ?
                                        <CircularProgress style={{ width: '50px', margin: '30px' }} /> :
                                        <Button type='submit' style={goldAuction.auction_details ? { backgroundColor: 'grey', color: 'white', margin: '20px' } : { backgroundColor: '#0066cc', color: 'white', margin: '20px' }} variant='contained' >Submit</Button>}
                                </div>
                            </fieldset>
                        </form>
                    </Typography> :
                    <Typography style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '40px', marginTop: '160px', color: 'red' }}>{isLoading ? <CircularProgress /> : <div>**First upload publication details**</div>}</Typography>
            }
            {console.log(value.auction_details)}
        </div>
    )
}