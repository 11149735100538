import React from 'react'
import { Route, Switch } from "react-router-dom";

import  List  from  "./List";
import  AddLetter  from "./AddLetter"
import Details from './Details';
import EmiDetail from './EmiDetail';
import ShowLrn from './ShowLrn';
import LegalNotice from './LegalNotice';
import LegalLetterList from './LegalLetterList';
import EmiLetterList from './EmiLetterList';
import { UpcommingEvents } from './UpcommingEvents';
import SettlementCretificate from './SettlementCretificate'
import Settlement from './Settlement';
import SettlementLetterPrint from './SettlementLetterPrint';
import LegalLetter from './LegalLetter'
import EmiReminderLetter from './EmiReminderLetter'
const LetterTracking = ({match}) => {
    const {path} = match;
  return (

    <Switch>
      <Route path={`${path}/show/createletter`} component={AddLetter} /> 
      <Route path={`${path}/show`} component={List} />
      <Route path={`${path}/createlegalletter`} component={Details} />
      <Route path={`${path}/emireminderletter`} component={EmiLetterList} />
      <Route path={`${path}/emireminderletterPrint/:id`} component={EmiReminderLetter} />
      <Route path={`${path}/showlrn`} component={ShowLrn} />
      <Route path={`${path}/edit/:id`} component={Details} />
      <Route path={`${path}/SettlementEdit/:id`} component={Settlement} />
      <Route path={`${path}/SettlementLetterPrint/:id`} component={SettlementLetterPrint} />
      <Route path={`${path}/lrnLetterPrint/:id`} component={LegalLetter} />
      <Route path={`${path}/editemiletter/:id`} component={EmiDetail} />
      <Route path={`${path}/legalnotice`} component={LegalNotice}/>
      <Route path={`${path}/legalnotice/:id`} component={LegalNotice}/>
      <Route path={`${path}/list/legalletters`} component={LegalLetterList}/>
      <Route path={`${path}/list/settlementcertificate`} component={SettlementCretificate}/>
      <Route path={`${path}/settlement`} component={Settlement} />
      <Route path={`${path}/create/emiletter`} component={EmiDetail}/>
      <Route path={`${path}/upcommingevent`} component={UpcommingEvents}/>
    </Switch>
  )
}

export { LetterTracking }

