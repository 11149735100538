import React, { useState,useEffect } from 'react'
import "../../../../css/common.css"
import { employeeService } from '../../../../_services';
import { Button } from '@material-ui/core';
import { TabContext } from '../TabBar';
import { useContext } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { loanService } from '../../../../_services';
import { uploadFileToBucket } from '../../../../_helpers/upload';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import datehelper from '../../../../_helpers/datehelper';
import urlhelper from  '../../../../_helpers/urlhelper'
import { Edit } from "@material-ui/icons";
import { adminservice } from '../../../../_services/admin.services'; 
import roleChecker from '../../../../_helpers/rolechecker';
import Valuationdata from './ValuationData/valuationdata.modal'
import ValuationReport from './ValuationData/ValuationReport.modal'
import ValuationList from './ValuationData/ValuationList'
import RcuData from './RcuData/RcuData.modal'
import LegalData from './LegalData/LegalData.modal';
import RcuList from './RcuData/RcuList'
import LegalList from './LegalData/LegalList'
import { Paper } from "@material-ui/core";


const ValuationRcuLegalReport = () => {
  const [openValuation,setOpenValuation]=useState(false)
const [isPropertyPaperExists,setIsPropertyPaperExists]=useState(false)
  let TabContextValue = useContext(TabContext)
  let cust = TabContextValue.cust
  console.log("cust is",cust)
    const user = employeeService.userValue;

    //  const document_list = cust?.property_details?.document_list;
    // if (document_list) {
    //     document_list.forEach(element => {
    //         if (element.document_type === "Property Paper") {
    //           setIsPropertyPaperExists(true);
    //          }
    //     });
    // }

    useEffect(() => {
      if (cust?.property_details?.document_list) {
        const propertyPaper = cust.property_details.document_list.some(
          element => element.document_type === "Property Paper"
        );
        setIsPropertyPaperExists(propertyPaper);
      }
    }, [cust]);
  return (
    <div>
    <h3>All Reports</h3>
    <div>
    <div>
{isPropertyPaperExists && (roleChecker.isCreditTeamAccess(user.role) || roleChecker.isHOCreditTeamAccess(user.role) || roleChecker.isAdminAccess(user.role) )  && <Paper  style={{  borderRadius: '10px', margin: '3px', padding: '30px',display:'flex',gap:'2rem' }}>
             <Valuationdata cust={cust}/>
             <RcuData cust={cust}/>
             <LegalData cust={cust} />
             </Paper>}
    </div>
          {
            cust && cust.valuation_data && <ValuationList  cust={cust} />
          }
          {
            cust && cust.rcu_data &&  <RcuList cust={cust} />
          }
          {
            cust && cust.legal_data && <LegalList  cust={cust} />
          }
              
            
 


              


        </div>
        
  </div>
  )
}

export default ValuationRcuLegalReport