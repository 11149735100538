import { fetchWrapper} from "../_helpers";

const apiUrl = process.env.REACT_APP_API_URL;

const baseUrl = `${apiUrl}/auth_persons`;

export const authPersonService = {
  getAll,
  create,
  delete: _delete,
};

function getAll() {
  return fetchWrapper.get(baseUrl);
}

function create(params) {
  return fetchWrapper.post(baseUrl, params)
}

function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`).then((x) => {
    return x;
  });
}

