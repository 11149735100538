import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { PercentageCircle } from './PieChart';
import { Overview } from './Overview';
import { Sales } from './Sales'
import { loanService } from '../../../_services';
import workflowHelper from '../../../_helpers/workflowhelper';
import { CircularProgress } from '@material-ui/core';
import { ApplicantStage } from './ApplicantStage/ApplicantStage';
import CreditStage from './CreditStage/CreditStage';

export const TabContext = createContext()


const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);


const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#0B8508',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(53, 60, 233) 0%,rgb(53, 60, 233) 50%,rgb(53, 60, 233) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(53, 60, 233) 0%,rgb(53, 60, 233) 50%,rgb(53, 60, 233) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <PercentageCircle percentage={100} />,
    2: <PercentageCircle percentage={this.salesPercent} />,
    3: <PercentageCircle percentage={this.applicantPercentage} />,
    4: <PercentageCircle percentage={this.creditPercentage} />,
  };


  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps(setActiveStep, id) {
  return [<Button onClick={() => setActiveStep(0)}>Overview</Button>, <Button onClick={() => setActiveStep(1)}>Sales</Button>, <Button onClick={() => setActiveStep(2)}>Applicant</Button>, <Button onClick={() => setActiveStep(3)}>Credit</Button>];
}

function getStepContent({ step, cust, setActiveStep, setSalesPercent, salesPercent,creditPercentage }) {
  switch (step) {
    case 0:
      return <Overview cust={cust} />;
    case 1:
      return <TabContext.Provider value={{ setActiveStep: setActiveStep, setSalesPercent: setSalesPercent, salesPercent: salesPercent,creditPercentage:creditPercentage}}>
        <Sales cust={cust} />
      </TabContext.Provider>;
    case 2:
      return <TabContext.Provider value={{ cust }}>
        <ApplicantStage />
      </TabContext.Provider>;
    case 3:
      return <TabContext.Provider value={{ cust }}>
        <CreditStage />
      </TabContext.Provider>;
    default:
      return 'Unknown step';
  }
}

export default function TabBar(props) {

  function getsalesPercent(cust, setSalesPercent) {
    let n = 0
    if (workflowHelper.isOTPVerified(cust)) {
      n++
    }
    if (workflowHelper.isLoginFeesReceived(cust)) {
      n++
    }
    if (workflowHelper.isReferenceAdded(cust)) {
      n++
    }
    setSalesPercent(Math.round(n / .03))
  }

  function getApplicantPercentage(cust, setApplicantPercentage) {
    let n = 0
    if (workflowHelper.isOneCoApplicant(cust)) {
      n++
    }
    if (workflowHelper.isOneFemaleCoApplicant(cust)) {
      n++
    }
    if (workflowHelper.isOneGuarantor(cust)) {
      n++
    }
    setApplicantPercentage(Math.round(n / .03))
  }

  function getCreditPercentage(cust,setCreditPercentage){
   let n=0
   if(workflowHelper.isOneAdharVerified(cust)){
     n++;
   }
   if(workflowHelper.isOneMobileNoVerified(cust)){
     n++;
   }
   if(workflowHelper.isApplicantKyc(cust)){
    n++;
   }
   if(workflowHelper.isOneCoApplicantKyc(cust)){
    n++;
   }
   if(workflowHelper.isCibilDone(cust)){
    n++;
   }
   if(workflowHelper.isImdFeesCollected(cust)){
    n++;
   }
   if(workflowHelper.isCersiReport(cust)){
    n++;
   }
   if(workflowHelper.isCamLetterGenerated(cust)){
    n++
   }if(workflowHelper.isApplicationFormCompleted(cust)){
    n++;
   }
   if(workflowHelper.isValuationReportGenerated(cust)){
    n++;
   }
    setCreditPercentage(Math.round(n / 0.10))
  } 
  const id = props.id
  console.log(id, "id here")
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [salesPercent, setSalesPercent] = useState(0)
  const [applicantPercentage, setApplicantPercentage] = useState(0)
  const [creditPercentage,setCreditPercentage]=useState(0)
  const [cust, setCust] = useState();
  const steps = getSteps(setActiveStep, id);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  React.useEffect(() => {
    if (props && props.tab && props.tab === 'applicant')
      setActiveStep(2)
  }, [props.tab])

  React.useEffect(() => {
    async function getApplicant() {
      const data = await loanService.getById(id);
      await getsalesPercent(data, setSalesPercent)
      await getApplicantPercentage(data, setApplicantPercentage)
      await getCreditPercentage(data,setCreditPercentage)
      setCust(data);
    }

    getApplicant();
  }, [id]);

  return (
    <div className={classes.root}>
      {console.log("percent here", applicantPercentage)}
      {cust && cust.application_id ? <React.Fragment><Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            {console.log(label, "label here")}
            <StepLabel StepIconComponent={ColorlibStepIcon.bind({ salesPercent: salesPercent, applicantPercentage:applicantPercentage,creditPercentage:creditPercentage })}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed - you&apos;re finished
              </Typography>
              <Button onClick={handleReset} className={classes.button}>
                Reset
              </Button>
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions}>{getStepContent({ step: activeStep, cust, setActiveStep, setSalesPercent, salesPercent })}</Typography>
              {activeStep!==0 && activeStep !== 1 && activeStep !== 2 && <div style={{ display: 'flex', justifyContent: 'center' }}>
                {activeStep !== 0 && <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                  Back
                </Button>}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </div>}
            </div>
          )}
        </div></React.Fragment> :
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>}
      {
        console.log(cust)
      }
    </div>
  );
}