import React, { useEffect, useState } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { makeStyles, withStyles } from '@material-ui/core/styles';
// import roleChecker from "../../_helpers/rolechecker";
import { loanService,employeeService} from "../../_services";
import { adminservice} from "../../_services/admin.services";
import { useLocation } from 'react-router-dom';
import CerSaiUpload from '../../_components/SBL/Applications/CerSaiUpload'
// Mock API function
// const submitFormApi = async (formData) => {
//   console.log("Form Data Submitted:", formData);
//   return { status: 'success', message: 'Form submitted successfully!' };
// };

// Custom StepConnector for styling
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

// Styles for the Stepper labels
const useStyles = makeStyles((theme) => ({
  stepLabel: {
    fontSize: '2.5rem', // Adjusted font size
    fontWeight: 'bold',
  },
}));

const steps = ['Security Interest Details', 'Asset Details', 'Borrower(s)', 'Holder Details'];
// const colenderNameOptions = ["UGRO CAPITAL LIMITED","Vistaar Financial Services Pvt. Ltd."];
// let colenderShare =0;
const SIRegistration = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [cityCodeData, setCityCodeData] = useState();
  const [cersaiApiResponseData, setCersaiApiResponseData] = useState();

  const [colenderShare, setColenderShare] = useState(() => {
    // Try to load share value from localStorage on component mount
    const savedShare = localStorage.getItem('colenderShare');
    return savedShare ? JSON.parse(savedShare) : null;
  });
  const isSuccess = cersaiApiResponseData && cersaiApiResponseData.status === 'success';
  const isDuplicate = cersaiApiResponseData && cersaiApiResponseData.message === 'Duplicate';
  const [formData, setFormData] = useState({
    assetDesc: 'Residential plot',
    assetTypeId:'',
    srvyMncplNumber:'',
    lcltySector:'',
    area:'',
    areaUnit:'6',
    pincode:'',
    district:'', 
    stateCode:'',
    stateName:'',
    countryCode:'IND',
    districtCode:'',
    borrowerdistrictCode:'',
    prjctSchmSociety:'',
    cityTownVillageCode:'',
    assetTypeDesc:'Residential',
    assetSubTypeId:'1',
    assetCategoryId:1,
    assetSubTypeDesc:'Structure With Land',
    assetSubTypeOtherDesc:'Structure With Land',
    transactionTypeId:8,
    processId:'1',
    transactionSubTypeId:17,
    transactionMode:"A",
    financingTypeId:'',
    isRegistrationDedupBypassed:false,
    chargeType: 'First',
    entityCode:'',
    isActive: 0,
    share:0,
    userIdentificationNumber:'JC99800003',
    entityIdentificationNumber:'',
    securityInterestCreationDate:'',
    totalSecuredAmount:'',
    securityInterestSubTypeId:17,
    kaabilShareDetails:'',
    kaabilDetailsOfCharge:'First Charge',
    borroweraddressLine1:'',
    borroweraddressLine2:'',
    borroweraddressLine3:'',
    // borrowerborrowerdistrictCode:'',
    borrowerstateCode:'',
    borrowerStateName:'',
    borrowerpincode:'',
    borrowercountryCode:'IND',
    borrowercityTownVillageCode:'',
    borrowerGender:'',
    borrowerdob:'',
    // borrowerindividualName:'',
    borrowerfatherMotherName:'',
    borrowerisAssetOwner:'',
    typeOfBorrower:'IND',
    officeName:'Kaabil Finance Pvt Ltd',
    addressLine1:'328 C Kusum Vihar Ramnagaria Road Jagatpura Jaipur Rajasthan 302025',
    holderdistrictCode: 483,
    holderstateCode: 29,
    holderpincode: 302015,
    holdercountryCode: "IND",
    holdercityTownVillageCode:5762,
    // typeOfLending: '',
    // sourceEntityName: '',
    borrowerName: '',
    // assetType: '',
    plotNumber:'',
    assetOwnerName:'',
    assetOwnerFatherMotherName:'',
    assetOwnerGender:'',
    assetOwnerDob:'',
    assetOwnerStateName:'',
    assetOwnerStateCode:'',
    assetOwnerDistrictCode:'',
    assetOwnerAddressLine1:'',
    assetOwnerAddressLine2:'',
    assetOwnerAddressLine3:'',
  });

  const [errors, setErrors] = useState({});
  const classes = useStyles();

  const getCityAndStateCode = async ({ pincode, permanent_city }) => {
    if (pincode && permanent_city) {
      try {
        const response = await loanService.getCityAndStateCode({ pincode, city: permanent_city });
        setCityCodeData(response);
      } catch (error) {
        console.error('Error getting city and state code:', error);
      }
    }
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`; // Return formatted date
  };
  const location = useLocation();
  const cersaiData = location.state?.cersaiData;
  const application_id = cersaiData && cersaiData.application_id;
  // const IsApplicatispropertyOwner = cersaiData && cersaiData.IsApplicatispropertyOwner;
  const matchingPropertyOwner = cersaiData && cersaiData.matchingPropertyOwner;
  console.log('matchingPropertyOwner::::',matchingPropertyOwner)
  const user = employeeService.userValue;
  let allValuationData = [];
  console.log('cersaiDatacersaiDatacersaiDatacersaiData:::::::::::::::::',cersaiData)
  // If hasRequiredData is true, find all matching data and store the entire report in allValuationData
  if (cersaiData) {
    const matchedData = cersaiData.valuationrculegaldata.filter(data =>
      data.valuation_report &&
      data.type === "Valuation" &&
      data.valuation_report.request_status === "Received"
    );
    allValuationData = matchedData.map(data => data.valuation_report);
  }
  console.log('allValuationData:::::',allValuationData)
  
  const normalizeName = (name) => {
    return name.replace(/\s+/g, '').trim().toLowerCase();
  };
  const removeSpecialCharAndExtraSpaces = (str) => {
    if (typeof str !== 'string') {
      console.error("Invalid input: expected a string");
      return '';
    }
    const cleanedString = str.replace(/[^\w\s]/g, ' ');
    return cleanedString.replace(/\s+/g, ' ').trim();
  };
  
  const findMatchingApplicant = (application_form, applicantName) => {
    const normalizedApplicantName = normalizeName(applicantName);
    console.log('normalizedApplicantName::::',normalizedApplicantName)
    for (const applicant of application_form) {
      const fullName = normalizeName(applicant.first_name + (applicant.middle_name || '') + applicant.last_name);
      console.log('fullName::::::',fullName)
      if (fullName === normalizedApplicantName) {
        return applicant;
      }
    }
    for (const applicant of application_form) {
      const shortName = normalizeName(applicant.first_name + applicant.last_name);
      if (shortName === normalizedApplicantName) {
        return applicant; // Return the whole applicant object for easy access
      }
    }

    return null; // Return null if no match is found
  };

  useEffect(() => {
    if (cersaiData) {
    console.log('cersaiData before USeeffect running:::',cersaiData)

      const {
        applicant_name,
        applicant_sonof,
        application_id,
        co_lending,
        disbursement_date,
        property_owner,
        sanction_amount,
        application_form,
        IsApplicatispropertyOwner,
        matchingPropertyOwner
      } = cersaiData;
  
      const missingFields = [];
      if (!applicant_name) missingFields.push('Applicant Name');
      if (!applicant_sonof) missingFields.push('Applicant Son of');
      if (!application_id) missingFields.push('Application ID');
      if (!co_lending) missingFields.push('Co-Lending');
      if (!disbursement_date) missingFields.push('Disbursement Date');
      if (sanction_amount == null) missingFields.push('Sanction Amount');
  
      // Only alert for missing critical fields, allow users to proceed for the rest
      if (missingFields.length > 0) {
        console.warn(`Missing values for: ${missingFields.join(', ')}`);
      }
  
      const storedData = {
        applicant_name: applicant_name || '', // Fallback if missing
        applicant_sonof: applicant_sonof || '',
        application_id: application_id || '',
        co_lending: co_lending || '',
        disbursement_date: disbursement_date || '',
        property_owner: property_owner || '',
        sanction_amount: sanction_amount || 0, // Default to 0 if missing
        application_form: application_form || [],
        IsApplicatispropertyOwner: IsApplicatispropertyOwner,
        matchingPropertyOwner: matchingPropertyOwner ? matchingPropertyOwner : applicant_name

        // entityIdentificationNumber:
      };
      console.log('storedDatastoredDatastoredDatastoredData:::',storedData)
      let flagAnotherColender = false; // Flag for another colender
      let colenderName=null;
      if (storedData.co_lending === 'Ugro') {
        colenderName = 'JC596';
        } else if (storedData.co_lending === 'VistaarFinance') {
            colenderName = 'J0464';
        } else {
            // colenderName = 'Another';
            flagAnotherColender = true; // Set the flag for another colender
        }
      if (colenderName) {
        adminservice.getPairByKey({ key_type: 'colender_share' })
          .then(res => {
            let shareValue =0;

            res.forEach(item => {
              const [name, value] = item.split(':');
              if (name.trim().toLowerCase() === storedData.co_lending.toLowerCase()) {
                shareValue = parseFloat(value.trim());
              }
            });
            if (shareValue !==0) {
              console.log('shareValue:::::',shareValue)
              setColenderShare(shareValue);
            }
            if (flagAnotherColender){
              console.log('shareValue:::::',shareValue)
              setColenderShare(shareValue=0);
            }
          })
          .catch(err => {
            console.error('Error fetching colender_share:', err);
          });
      }
      if (flagAnotherColender) {
        setColenderShare(0);
      }
      console.log('colenderShare:::::',colenderShare)
      setFormData((prevFormData) => ({
        ...prevFormData,
        securityInterestCreationDate: storedData.disbursement_date ? formatDate(storedData.disbursement_date) : prevFormData.securityInterestCreationDate || '', // Use user-entered value if missing
        entityIdentificationNumber: storedData.application_id, // Default if missing
        totalSecuredAmount: (storedData.sanction_amount) ? storedData.sanction_amount : prevFormData.totalSecuredAmount || '', // Keep it empty if both are missing
        entityCode: colenderName || '',
        // entityCode: colenderName || '',
        financingTypeId: colenderName ? 2 : 1,
        share: colenderShare !== null ? colenderShare : null,
        kaabilShareDetails: colenderShare !== null ? 100 - colenderShare : null,
        prjctSchmSociety: 'GRAM PANCHAYAT',
        borrowerisAssetOwner: storedData.IsApplicatispropertyOwner,
        matchingPropertyOwner: storedData.matchingPropertyOwner,
      }));
      console.log('formData::::::::::::::::::::::::::::::::::::::::::::::::::::::',formData)
      const matchedApplicant = findMatchingApplicant(application_form, storedData.applicant_name);
      const matchingPropertyOwnerData=findMatchingApplicant(application_form, storedData.matchingPropertyOwner);
      console.log('matchedApplicant::::',matchedApplicant)
      console.log('colendermatchingPropertyOwnerDataShare::::',matchingPropertyOwnerData)
      if (!matchedApplicant) {
        console.warn('No matching applicant found.');
      }
      
      
      const splitAddress = (address, maxLength = 100) => {
        const lines = [];
        let remainingAddress = removeSpecialCharAndExtraSpaces(address);
      
        while (remainingAddress.length > maxLength) {
          let splitIndex = remainingAddress.lastIndexOf(' ', maxLength); // Find the last space within the limit
      
          if (splitIndex === -1) {
            // If no space is found (shouldn't happen with addresses), force-split
            splitIndex = maxLength;
          }
      
          lines.push(remainingAddress.substring(0, splitIndex)); // Add the part to lines
          remainingAddress = remainingAddress.substring(splitIndex + 1); // Remove the used part
        }
      
        lines.push(remainingAddress); // Push the last remaining part
        return lines;
      };
      let addressLines = '';
      if (matchedApplicant) {
          addressLines = splitAddress(matchedApplicant.address);
      }
      
      console.log('DFGHJKLKJHGFDSFGHJKLLKJHGFDZXFCGHJKK',allValuationData)
      console.log('fromdat is ',formData,formData.pincode, formData.lcltySector)
      if (allValuationData && allValuationData.length > 0 && allValuationData[0].panchayatSamiti_tehsil && allValuationData[0].pincode) {
        console.log('data   fetched')
        const firstValuation = allValuationData[0] || {};
        setFormData(prevFormData => ({
          ...prevFormData,
          borrowerdob: matchedApplicant?.dob || '', // Optional field
          borrowerStateName: firstValuation.stateName || '', // Default empty if missing
          borroweraddressLine1: addressLines[0] || '',
          borroweraddressLine2: addressLines[1] || '',
          borroweraddressLine3: addressLines[2] || '',
          borrowerGender: matchedApplicant?.gender ? (['male', 'Male'].includes(matchedApplicant.gender) ? "M" : "F") : '',
          assetTypeId: ['Residential Properties', 'Rented Residential Properties'].includes(firstValuation.property_type) ? 1 : 2,
          plotNumber: firstValuation.plotHouseFlatnumber || '',
          pincode: firstValuation.pincode || '',
          srvyMncplNumber: firstValuation.srvyMncplNumber || '',
          district: firstValuation.district || '',
          area: firstValuation.landSize || '',
          stateName: firstValuation.stateName || '',
          lcltySector: removeSpecialCharAndExtraSpaces(firstValuation.panchayatSamiti_tehsil) || '',
          share: colenderShare || null,
          borrowerName: matchedApplicant ? `${matchedApplicant.first_name} ${(matchedApplicant.middle_name || '')} ${matchedApplicant.last_name}` : '',
          borrowerfatherMotherName: matchedApplicant?.father_name || ''
        }));
  
        getCityAndStateCode({
          pincode: firstValuation.pincode || '',
          permanent_city: firstValuation.panchayatSamiti_tehsil || ''
        });
      }
      else if (allValuationData && allValuationData.length > 0) {
        console.log('data fetched in else con................................')
        const firstValuation = allValuationData[0] || {};
        setFormData(prevFormData => ({
          ...prevFormData,
          borrowerdob: matchedApplicant?.dob || '', // Optional field
  
          // stateName: firstValuation.stateName || '',
          stateName: matchedApplicant?.state,
          borrowerStateName: matchedApplicant?.state || '',
          share: colenderShare || null,
          borrowerGender: matchedApplicant?.gender ? (['male', 'Male'].includes(matchedApplicant.gender) ? "M" : "F") : '',
          assetTypeId: ['Residential Properties', 'Rented Residential Properties'].includes(firstValuation.property_type) ? 1 : 2,
          borrowerName: matchedApplicant ? 
          `${matchedApplicant.first_name}${matchedApplicant.middle_name ? ' ' + matchedApplicant.middle_name : ''}${matchedApplicant.last_name ? ' ' + matchedApplicant.last_name : ''}`.trim() 
          : '',


          borrowerfatherMotherName: `${matchedApplicant?.father_name}`?.trim() || '',
          assetOwnerName: matchedApplicant && matchingPropertyOwnerData ? 
          (matchingPropertyOwnerData.middle_name || matchingPropertyOwnerData.last_name 
              ? `${matchingPropertyOwnerData.first_name}${matchingPropertyOwnerData.middle_name ? ' ' + matchingPropertyOwnerData.middle_name : ''}${matchingPropertyOwnerData.last_name ? ' ' + matchingPropertyOwnerData.last_name : ''}`
              : matchingPropertyOwnerData.first_name).trim() 
          : '',

          assetOwnerFatherMotherName:matchingPropertyOwnerData?.father_name?.trim() || matchedApplicant?.father_name?.trim(),

          assetOwnerGender: matchingPropertyOwnerData?.gender ? (['male', 'Male'].includes(matchingPropertyOwnerData.gender) ? "M" : "F") : '',
          assetOwnerDob: matchingPropertyOwnerData?.dob,
        }));
  
        getCityAndStateCode({
          pincode: matchedApplicant?.pincode || '',
          permanent_city: matchedApplicant?.tehsil || ''
        });
      }
      else {
        console.log('data  not  fetched')
  
        // console.warn('CERSAI data is not available.');
         // Check if FormData has the necessary data to call the API
      const { pincode, lcltySector } = formData;

      if (pincode || lcltySector) {
        console.log('Calling else conditions..................')
        getCityAndStateCode({
          pincode: pincode || '',
          permanent_city: lcltySector || ''
        });
      }
      }
    } 
  }, [cersaiData, colenderShare,activeStep]);
  console.log('cityCodeData:::',cityCodeData)
  console.log('colenderShare:::',colenderShare)

  useEffect(() => {
    if (cityCodeData) {
      const {
        city_code,
        city_name,
        district_code,
        district_name,
        pincode,
        state_code,
        state_name,
      } = cityCodeData || {}; // Allow for empty cityCodeData
  
      setFormData(prevFormData => ({
        ...prevFormData,
        stateCode: state_code || '', // Fallbacks if missing
        borrowerstateCode: state_code || '',
        assetOwnerStateCode: state_code || '',
        cityCode: city_code || '',
        borrowercityTownVillageCode: city_code || '',
        cityTownVillageCode: city_code || '',
        borrowerdistrictCode: district_code || '',
        assetOwnerDistrictCode: district_code || '',
        borrowerpincode: pincode || '',
        assetOwnerPincode: pincode || '',
        districtCode: district_code || ''
      }));
    }
  }, [cityCodeData]);
  
  // useEffect(() => {
  //   // Save the colenderShare value to localStorage whenever it changes
  //   if (colenderShare !== null) {
  //     localStorage.setItem('colenderShare', JSON.stringify(colenderShare));
  //   }
  // }, [colenderShare]);



    // Function to handle input changes
    const handleChange = (event) => {
      const { name, value } = event.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value || '', // Ensure the value is a string
      }));
    };

  const calculateCompletion = () => {
    const totalFields =74; // Total number of input fields
    const filledFields = Object.values(formData).length;
    return Math.round((filledFields / totalFields) * 100);
  };
  

  // Function to validate the form
  const validateForm = () => {
    const newErrors = {};
    if (activeStep === 0 && (!formData.assetCategoryId || !formData.transactionTypeId || !formData.transactionSubTypeId   || !formData.totalSecuredAmount  || !formData.kaabilShareDetails  || !formData.securityInterestCreationDate  )) {
      newErrors['si-details'] = ['Type of Transaction', 'Type of Security Interest','Type Of Finance','Name of Lender ','Lender Share','Details Of Charge','Total Secured Amount','Entity Identification Number','Kaabil Share','Kaabil Details Of Charge','Security Interest Creation Date','Security Interest Sub Type'].filter(field => !formData[field.replace(' ', '').toLowerCase()]);
    }
    
    if (activeStep === 1 && (!formData.assetTypeId || !formData.assetCategoryId || !formData.assetDesc || !formData.lcltySector || !formData.pincode || !formData.stateName)) {
      newErrors['asset-details'] = ['Immovable Category', 'Asset Type', 'Description of the Asset','Survey Number / Municipal Number','City / Sector','Plot Number','Pincode','State Name'].filter(field => !formData[field.replace(' ', '').toLowerCase()]);
    }
    if (activeStep === 2 && (!formData.borrowerName || !formData.borroweraddressLine1)) {
      newErrors['borrower-details'] = ['Borrower Name', 'Borrower Address'].filter(field => !formData[field.replace(' ', '').toLowerCase()]);
    }
    // if (activeStep === 3 && (!formData.officeName || !formData.addressLine1)) {
    //   newErrors['holder-details'] = ['Office Name', 'Address'].filter(field => !formData[field.replace(' ', '').toLowerCase()]);
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
    if(isSuccess && cersaiApiResponseData && cersaiApiResponseData.transactionId){
      const data = loanService.saveCersaiSiRegistrationData({
        transactionId: cersaiApiResponseData.transactionId,
        assetId: cersaiApiResponseData.assetId,
        application_id: application_id,
        user: user.emp_id
      });
      if (data.status) {
        alert(data.status);
      }
    };
  
  // Handle form submission
  const handleSubmit = async () => {
    if (validateForm()) {
      console.log('formData::::::::', formData);
      try {
        const response = await loanService.createCersaiSiRegistration(formData);
        setCersaiApiResponseData(response);
        console.log('cersaiApiResponseData:::', response);
        
        // if (response && response.status === 'success') {
        //   // Only call saveCersaiData if the response indicates success
        //   console.log('CERSAI data saved successfully.');
        // }
      } catch (err) {
        console.error('Error:', err);
        alert('An error occurred while submitting the form. Please try again.');
      }
    } else {
      alert('Please fill all required fields.');
    }
  };
  

 

  
    // Handle step change
    const handleStepChange = (step) => {
      if (validateForm() || step < activeStep) {
        setActiveStep(step);
      }
    };

  // Function to render error messages
  const renderError = (tab) => {
    if (errors[tab]) {
      return (
        <div style={styles.error}>
          Please fill the following fields in {tab.replace('-', ' ')}: {errors[tab].join(', ')}
        </div>
      );
    }
    return null;
  };

  // Helper function to render labels with red asterisk for required fields
  const renderLabel = (label, required = false) => (
    <label style={styles.label}>
      {label}
      {required && <span style={{ color: 'red' }}> *</span>}
    </label>
  );
  const CersaiErrorResponse = ({ cersaiApiResponseData }) => {
    let parsedErrorDetails;

    try {
        // Parse error_details if it is a JSON string
        parsedErrorDetails = JSON.parse(cersaiApiResponseData.error_details);
    } catch (error) {
        console.error("Failed to parse error_details:", error);
        parsedErrorDetails = cersaiApiResponseData.error_details; // Fallback to raw string if parsing fails
    }
  };

  // TemporaryMessage Component to handle the failure message
  const TemporaryMessage = ({ message }) => {
    const [displayMessage, setDisplayMessage] = React.useState(message);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setDisplayMessage("Please Try Again");
        }, 3000);

        // Clean up the timer if the component unmounts before 5 seconds
        return () => clearTimeout(timer);
    }, [message]);

    return <h4 style={{ textAlign: 'center',color:'red' }}>{displayMessage}</h4>;
  };


  return (
    <>
      <h1 style={{ textAlign: 'center', marginTop: '10px' }}>Create SI Registration</h1>
      <div style={styles.container}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<ColorlibConnector />}
          style={styles.stepper}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel className={classes.stepLabel}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <div style={styles.formContainer}>
          {activeStep === 0 && (
            <div style={styles.formGroup}>
              {renderError('si-details')}
              <h2>Security Interest Details</h2>
              <div style={styles.flexContainer}>
              <div style={styles.flexItem}>
                {renderLabel('Type of Transaction', true)}
                <select name="transactionTypeId" value={formData.transactionTypeId} onChange={handleChange} style={styles.input} disabled >
                  {/* <option value="">Select</option> */}
                  <option value="8">Security Interest</option>
                  <option value="3">Attachment Order</option>
                </select>
              </div>

              <div style={styles.flexItem}>
                {renderLabel('Type of Security Interest', true)}
                <select name="transactionSubTypeId" value={formData.transactionSubTypeId} onChange={handleChange} style={styles.input} disabled>
                  {/* <option value="">Select</option> */}
                  <option value="17">Equity Mortgage</option>
                  {/* <option value="18">Registered Mortgage</option>
                  <option value="19">Agreement To Create Mortgage</option>
                  <option value="20">Conditional Sale</option>
                  <option value="21">Other</option> */}
                </select>
              </div>

                {/* Constant Value: “A” */}
                  <div hidden style={styles.flexItem}>
                    {renderLabel('Transaction Mode', true)}
                    <input type="text" name="transactionMode" placeholder="Share" value={formData.transactionMode} onChange={handleChange} style={styles.input}/>
                  </div>
                  {/* processId: '1' */}
                  <div hidden  style={styles.flexItem}>
                    {renderLabel('Process Id', true)}
                    <input type="text" name="processId" placeholder="Share" value={formData.processId} onChange={handleChange} style={styles.input}/>
                  </div>
                <div style={styles.flexItem}>
                    {renderLabel('Type Of Finance', true)}
                    <select name="financingTypeId" value={formData.financingTypeId} onChange={handleChange} style={styles.input}>
                      {/* <option value="">Select</option> */}
                      <option value="1">Sole</option>
                      <option value="2">Consortium</option>
                      {/* <option value="3">Multiple</option> */}
                    </select>
                  </div>

                  {/* Conditionally render the additional input fields when 'Consortium' is selected */}
                  {formData.financingTypeId===2 && (
                    <>
                      <div style={styles.flexItem}>
                        {renderLabel('Name of Lender',true)}
                        <select name="entityCode" value={formData.entityCode} onChange={handleChange} style={styles.input} readOnly>
                          <option value="">Select</option>
                          <option value="JC596">UGRO CAPITAL LIMITED</option>
                          <option value="J0464">Vistaar Financial Services Pvt. Ltd.</option>
                        </select>
                      </div>
                      <div style={styles.flexItem}>
                        {renderLabel('Lender Share', true)}
                        <input type="number" name="share" placeholder="Please Enter Share of Co-Lender" value={formData.share} onChange={handleChange} style={styles.input}/>
                      </div>
                    </>
                  )}

                <div style={styles.flexItem}>
                  {renderLabel('Details Of Charge',true)}
                  <select name="chargeType" value={formData.chargeType} onChange={handleChange} style={styles.input} disabled>
                    <option value="">Select</option>
                    <option value="First">First Charge</option>
                    <option value="Second">Second Charge</option>
                  </select>
                </div>
                <div style={styles.flexItem}>
                  {renderLabel('Total Secured Amount', true)}
                  <input 
                    type="number" 
                    name="totalSecuredAmount" 
                    value={formData.totalSecuredAmount || ''} // Set to an empty string if it's falsy
                    onChange={handleChange} 
                    style={styles.input} 
                    placeholder='Please Enter Sanction Amount'
                  />
                </div>

                <div style={styles.flexItem}>
                  {renderLabel('Entity Identification Number',true)}
                  <input type="text" name="entityIdentificationNumber" value={formData.entityIdentificationNumber} onChange={handleChange} style={styles.input} placeholder='Please Enter Loan Number' />
                </div>
                <div style={styles.flexItem}>
                        {renderLabel('Kaabil Share', true)}
                        <input type="number" name="kaabilShareDetails" placeholder="Please Enter Share of Kaabil" value={formData.kaabilShareDetails} onChange={handleChange} style={styles.input}/>
                      </div>
                  
                <div style={styles.flexItem}>
                  {renderLabel('Kaabil Details Of Charge',true)}
                  <select name="kaabilDetailsOfCharge" value={formData.kaabilDetailsOfCharge} onChange={handleChange} style={styles.input} disabled>
                    <option value="">Select</option>
                    <option value="First Charge">First Charge</option>
                    <option value="Second Charge">Second Charge</option>
                  </select>
                </div>
                <div style={styles.flexItem}>
                  {renderLabel('Security Interest Creation Date',true)}
                  <input type="date" name="securityInterestCreationDate" value={formData.securityInterestCreationDate} onChange={handleChange} style={styles.input} placeholder='Please Enter Date(YYYY-MM-DD)'/>
                </div>
                <div style={styles.flexItem}>
                  {renderLabel('Security Interest Sub Type',true)}
                  <select name="securityInterestSubTypeId" value={formData.securityInterestSubTypeId} onChange={handleChange} style={styles.input} disabled>
                    <option value="">Select</option>
                    <option value="17">Equity Mortgage</option>
                    <option value="18">Registered Mortgage</option>
                    <option value="19">Agreement To Create Mortgage</option>
                    <option value="20">Conditional Sale</option>
                    <option value="21">Other</option>
                  </select>
                </div>
                
                
                <div hidden style={styles.flexItem}>
                  {renderLabel('Registration Dedup By passed',true)}
                  <select name="isRegistrationDedupBypassed" value={formData.isRegistrationDedupBypassed} onChange={handleChange} style={styles.input}>
                    <option value="">Select</option>
                    <option value="ture">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
              </div>
            </div>
          )}
           {activeStep === 1 && (
            <div style={styles.formGroup}>
              {renderError('asset-details')}
              <h2>Asset-Details</h2>
              <div style={styles.flexContainer}>
              <div style={styles.flexItem}>
                  {renderLabel('Asset Category',true)}
                  <select name="assetCategoryId" value={formData.assetCategoryId} onChange={handleChange} style={styles.input} disabled>
                    <option value="">Select</option>
                    <option value="1">Immovable</option>
                    <option value="2">Movable</option>
                    <option value="3">Intangible</option>
                  </select>
                </div>
                <div style={styles.flexItem}>
                  {renderLabel('Asset Type', true)}
                  <select name="assetTypeId" value={formData.assetTypeId} onChange={handleChange} style={styles.input} readOnly >
                    <option value="">Select</option>
                    <option value="1">Residential Properties</option>
                    <option value="1">Rented Residencial Properties</option>
                    <option value="2">Commercial Properties</option>
                    <option value="2">Rented Commercial Properties</option>
                    <option value="2">Shop</option>
                    <option value="3">Others</option>
                  </select>
                </div>
                <div style={styles.flexItem}>
                  {renderLabel('Asset Sub Type',true)}
                  <select name="assetSubTypeId" value={formData.assetSubTypeId} onChange={handleChange} style={styles.input} readOnly>
                    <option value="">Select</option>
                    <option value="1">Structure With Land</option>
                    <option value="1">Structure Without Land</option>
                    <option value="1">Land without Structure</option>
                  </select>
                </div>
                <div style={styles.flexItem}>
                  {renderLabel('Description of the Asset',true)}
                  <select name="assetDesc" value={formData.assetDesc} onChange={handleChange} style={styles.input} disabled>
                    <option value="">Select</option>
                    <option value="Residential plot">Residential Plot</option>
                    <option value="Flate">Flat</option>
                    <option value="House">House</option>
                  </select>
                </div>

                <div style={styles.flexItem}>
                  {renderLabel('Survey Number / Municipal Number',true)}
                  <input type="text" name="srvyMncplNumber" value={formData.srvyMncplNumber} onChange={handleChange} style={styles.input} />
                </div>

                <div style={styles.flexItem}>
                  {renderLabel('Name of the Project / Scheme / Society',true)}
                  <input type="text" name="prjctSchmSociety" value={formData.prjctSchmSociety} onChange={handleChange} style={styles.input} />
                </div>
                <div style={styles.flexItem}>
                  {renderLabel('Plot Number',true)}
                  <input type="text" name="plotNumber" value={formData.plotNumber} onChange={handleChange} style={styles.input} />
                </div>
                <div style={styles.flexItem}>
                  {renderLabel('City / Sector',true)}
                  <input type="text" name="lcltySector" value={formData.lcltySector} onChange={handleChange} style={styles.input} />
                </div>

                <div style={styles.flexItem}>
                  {renderLabel('Land Size',true)}
                  <input type="number" name="area" value={formData.area} onChange={handleChange} style={styles.input} />
                </div>

                <div hidden style={styles.flexItem}>
                  {renderLabel('Size Unit',true)}
                  <select name="areaUnit" value={formData.areaUnit} onChange={handleChange} style={styles.input}>
                    <option value="">Select</option>
                    <option value="1">Acre</option>
                    <option value="2">Ares</option>
                    <option value="3">Gunta</option>
                    <option value="4">Cents</option>
                    <option value="5">Hectare</option>
                    <option value="6">Square Feet</option>
                    <option value="7">Square Metre</option>
                    <option value="8">Square Yard</option>
                  </select>
                </div>
                <div style={styles.flexItem}>
                  {renderLabel('State Name',true)}
                  <input type="text" name="stateName" value={formData.stateName} onChange={handleChange} style={styles.input} />
                </div>
                <div style={styles.flexItem}>
                  {renderLabel('District Name',true)}
                  <input type="text" name="district" value={formData.district} onChange={handleChange} style={styles.input} />
                </div>

                <div hidden style={styles.flexItem}>
                  {renderLabel('City/Town/Village/Code',true)}
                  <input type="number" name="cityTownVillageCode" value={formData.cityTownVillageCode} onChange={handleChange} style={styles.input} />
                </div> 

                <div hidden style={styles.flexItem}>
                  {renderLabel('District Code',true)}
                  <input type="number" name="districtCode" value={formData.districtCode} onChange={handleChange} style={styles.input} />
                </div>

                <div hidden style={styles.flexItem}>
                  {renderLabel('State Code',true)}
                  <input type="number" name="stateCode" value={formData.stateCode} onChange={handleChange} style={styles.input} />
                </div>

                <div style={styles.flexItem}>
                  {renderLabel('Pincode',true)}
                  <input type="number" name="pincode" value={formData.pincode} onChange={handleChange} style={styles.input} />
                </div>

                <div hidden style={styles.flexItem}>
                  {renderLabel('Country Code',true)}
                  <input type="text" name="countryCode" value={formData.countryCode} onChange={handleChange} style={styles.input} />
                </div>
              </div>
            </div>
                
          )}

          {/* {activeStep === 2 && (
            <div style={styles.formGroup}>
              {renderError('borrower-details')}
              <h2>Borrower Details</h2>
              <div style={styles.flexContainer}>
              
                <div style={styles.flexItem}>
                  {renderLabel('Type Of Borrower', true)}
                  <input type="text" name="typeOfBorrower" value={formData.typeOfBorrower} onChange={handleChange} style={styles.input} />
                </div>

                <div style={styles.flexItem}>
                  {renderLabel('Gender',true)}
                  <select name="borrowerGender" value={formData.borrowerGender} onChange={handleChange} style={styles.input}>
                    <option value="">Select</option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                    <option value="T">Transgender</option>
                  </select>
                </div>

                <div style={styles.flexItem}>
                  {renderLabel('Name of the Borrower', true)}
                  <input type="text" name="borrowerName" value={formData.borrowerName} onChange={handleChange} style={styles.input} />
                </div>

                <div style={styles.flexItem}>
                  {renderLabel('Father / Mother Name', true)}
                  <input type="text" name="borrowerfatherMotherName" 
                  value={formData.borrowerfatherMotherName} onChange={handleChange} style={styles.input} />
                </div>

                <div style={styles.flexItem}>
                  {renderLabel('Date Of Birth(dd/mm/yyyy)', true)}
                  <input type="date" name="borrowerdob" value={formData.borrowerdob} onChange={handleChange} style={styles.input} />
                </div>

                <div style={styles.flexItem}>
                  {renderLabel('State', true)}
                  <input type="text" name="borrowerStateName" value={formData.borrowerStateName} onChange={handleChange} style={styles.input} />
                </div>
                <div hidden style={styles.flexItem}>
                  {renderLabel('State', true)}
                  <input type="text" name="borrowerstateCode" value={formData.borrowerstateCode} onChange={handleChange} style={styles.input} />
                </div>
                <div hidden style={styles.flexItem}>
                  {renderLabel('District', true)}
                  <input type="text" name="borrowerdistrictCode" value={formData.borrowerdistrictCode} onChange={handleChange} style={styles.input} />
                </div>

                <div style={styles.flexItem}>
                  {renderLabel('Address Line 1',true)}
                  <input type="text" name="borroweraddressLine1" value={formData.borroweraddressLine1} onChange={handleChange} style={styles.input} />
                </div>
                <div style={styles.flexItem}>
                  {renderLabel('Address Line 2')}
                  <input type="text" name="borroweraddressLine2" value={formData.borroweraddressLine2} onChange={handleChange} style={styles.input} />
                </div>
                <div style={styles.flexItem}>
                  {renderLabel('Address Line 3')}
                  <input type="text" name="borroweraddressLine2" value={formData.borroweraddressLine3} onChange={handleChange} style={styles.input} />
                </div>

              </div>
            </div>
          )} */}
          {activeStep === 2 && (
            <div style={styles.formGroup}>
              {renderError('borrower-details')}
              <h2>Borrower Details</h2>
              <div style={styles.flexContainer}>
                {/* Existing form fields */}
                <div style={styles.flexItem}>
                  {renderLabel('Type Of Borrower', true)}
                  <input type="text" name="typeOfBorrower" value={formData.typeOfBorrower} onChange={handleChange} style={styles.input} />
                </div>

                <div style={styles.flexItem}>
                  {renderLabel('Gender', true)}
                  <select name="borrowerGender" value={formData.borrowerGender} onChange={handleChange} style={styles.input}>
                    <option value="">Select</option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                    <option value="T">Transgender</option>
                  </select>
                </div>
                <div style={styles.flexItem}>
                      {renderLabel('Borrower Name', true)}
                      <input type="text" name="borrowerName" value={formData.borrowerName} onChange={handleChange} style={styles.input} />
                    </div>

                    <div style={styles.flexItem}>
                      {renderLabel('Borrower Father Name', true)}
                      <input type="text" name="borrowerfatherMotherName" value={formData.borrowerfatherMotherName} onChange={handleChange} style={styles.input} />
                    </div>

                    <div style={styles.flexItem}>
                      {renderLabel('Date Of Birth (dd/mm/yyyy)', true)}
                      <input type="date" name="borrowerdob" value={formData.borrowerdob} onChange={handleChange} style={styles.input} />
                    </div>

                    <div style={styles.flexItem}>
                      {renderLabel('State Name', true)}
                      <input type="text" name="borrowerStateName" value={formData.borrowerStateName} onChange={handleChange} style={styles.input} />
                    </div>

                    <div hidden style={styles.flexItem}>
                      {renderLabel('State Code', true)}
                      <input type="text" name="borrowerstateCode" value={formData.borrowerstateCode} onChange={handleChange} style={styles.input} />
                    </div>

                    <div hidden style={styles.flexItem}>
                      {renderLabel('District', true)}
                      <input type="text" name="borrowerdistrictCode" value={formData.borrowerdistrictCode} onChange={handleChange} style={styles.input} />
                    </div>

                    <div style={styles.flexItem}>
                      {renderLabel('Address Line 1', true)}
                      <input type="text" name="borroweraddressLine1" value={formData.borroweraddressLine1} onChange={handleChange} style={styles.input} />
                    </div>

                    <div style={styles.flexItem}>
                      {renderLabel('Address Line 2')}
                      <input type="text" name="borroweraddressLine2" value={formData.borroweraddressLine2} onChange={handleChange} style={styles.input} />
                    </div>

                    <div style={styles.flexItem}>
                      {renderLabel('Address Line 3')}
                      <input type="text" name="borroweraddressLine3" value={formData.borroweraddressLine3} onChange={handleChange} style={styles.input} />
                    </div>
                </div>

                  {/* <div style={styles.flexItem}>
                    <label>
                    <input type="checkbox" checked={formData.borrowerisAssetOwner} onChange={e => handleChange({ target: { name: 'borrowerisAssetOwner', value: e.target.checked } })} />  Is Borrower and Asset Owner  are the Same?
                    </label>
                  </div> */}
              {formData && !formData.borrowerisAssetOwner && (
                    <>
                    <h3 style={{textAlign:'center'}}>Asset Owner Details</h3>
              <br/>
              <div style={styles.flexContainer}>
            
               
                  <>           
                    <div style={styles.flexItem}>
                      {renderLabel('Asset Owner Name', true)}
                      <input type="text" name="assetOwnerName" value={formData.assetOwnerName} onChange={handleChange} style={styles.input} />
                    </div>

                    <div style={styles.flexItem}>
                      {renderLabel('Father / Mother Name', true)}
                      <input type="text" name="assetOwnerFatherMotherName" value={formData.assetOwnerFatherMotherName} onChange={handleChange} style={styles.input} />
                    </div>
                    <div style={styles.flexItem}>
                  {renderLabel('Gender', true)}
                  <select name="assetOwnerGender" value={formData.assetOwnerGender} onChange={handleChange} style={styles.input}>
                    <option value="">Select</option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                    <option value="T">Transgender</option>
                  </select>
                </div>

                    <div style={styles.flexItem}>
                      {renderLabel('Date Of Birth (dd/mm/yyyy)', true)}
                      <input type="date" name="assetOwnerDob" value={formData.assetOwnerDob} onChange={handleChange} style={styles.input} />
                    </div>

                    <div style={styles.flexItem}>
                      {renderLabel('State', true)}
                      <input type="text" name="assetOwnerStateName" value={formData.assetOwnerStateName} onChange={handleChange} style={styles.input} />
                    </div>

                    <div hidden style={styles.flexItem}>
                      {renderLabel('State Code', true)}
                      <input type="text" name="assetOwnerStateCode" value={formData.assetOwnerStateCode} onChange={handleChange} style={styles.input} />
                    </div>

                    <div hidden style={styles.flexItem}>
                      {renderLabel('District', true)}
                      <input type="text" name="assetOwnerDistrictCode" value={formData.assetOwnerDistrictCode} onChange={handleChange} style={styles.input} />
                    </div>

                    <div style={styles.flexItem}>
                      {renderLabel('Address Line 1', true)}
                      <input type="text" name="assetOwnerAddressLine1" value={formData.assetOwnerAddressLine1} onChange={handleChange} style={styles.input} />
                    </div>

                    <div style={styles.flexItem}>
                      {renderLabel('Address Line 2')}
                      <input type="text" name="assetOwnerAddressLine2" value={formData.assetOwnerAddressLine2} onChange={handleChange} style={styles.input} />
                    </div>

                    <div style={styles.flexItem}>
                      {renderLabel('Address Line 3')}
                      <input type="text" name="assetOwnerAddressLine3" value={formData.assetOwnerAddressLine3} onChange={handleChange} style={styles.input} />
                    </div>
                  </>
               
              </div> </>
                )}
              </div>
            
          )}

          {/* {activeStep === 3 && (
            <div style={styles.formGroup}>
              {renderError('holder-details')}
              <h2>Holder Details</h2>
              <div style={styles.flexContainer}>
                <div style={styles.flexItem}>
                  {renderLabel('Office Name', true)}
                  <input type="text" name="officeName" value={formData.officeName} onChange={handleChange} style={styles.input} disabled/>
                </div>
                <div style={styles.flexItem}>
                  {renderLabel('Address',true)}
                  <select name="addressLine1" value={formData.addressLine1} onChange={handleChange} style={styles.input} disabled>
                    <option value="328 C Kusum Vihar Ramnagaria Road Jagatpura Jaipur Rajasthan 302025">328 C Kusum Vihar Ramnagaria Road Jagatpura Jaipur Rajasthan 302025</option>
                  </select>
                </div>
              
                <div style={styles.flexItem}>
                  {renderLabel('Date Of Birth(dd/mm/yyyy)', true)}
                  <input type="date" name="dOB" value={formData.dOB} onChange={handleChange} style={styles.input} />
                </div>

                <div style={styles.flexItem}>
                  {renderLabel('State', true)}
                  <input type="text" name="borrowerStateName" value={formData.borrowerStateName} onChange={handleChange} style={styles.input} />
                </div>

                <div style={styles.flexItem}>
                  {renderLabel('Address', true)}
                  <input type="text" name="borrowerAddress" value={formData.borrowerAddress} onChange={handleChange} style={styles.input} />
                </div>

              </div>
            </div>
          )} */}

          {/* Other steps' forms... */}

          <div style={styles.buttonContainer}>
            <button
              onMouseEnter={(e) => (e.target.style.backgroundColor = '#0056b3')} // Hover effect for nav button
              onMouseLeave={(e) => (e.target.style.backgroundColor = '#007bff')} // Reset to default color
              onClick={() => handleStepChange(activeStep - 1)}
              style={styles.navButton}
              disabled={activeStep === 0}
            >
              Back
            </button>
            
            {!isSuccess && activeStep === steps.length - 1 ? (
              <button
                onMouseEnter={(e) => (e.target.style.backgroundColor = '#218838')} // Hover effect for submit button
                onMouseLeave={(e) => (e.target.style.backgroundColor = '#28a745')} // Reset to default color
                onClick={handleSubmit}
                style={styles.submitButton}
              >
                Submit
              </button>
            ) : (
              <button
                onMouseEnter={(e) => (e.target.style.backgroundColor = '#0056b3')} // Hover effect for nav button
                onMouseLeave={(e) => (e.target.style.backgroundColor = '#007bff')} // Reset to default color
                onClick={() => handleStepChange(activeStep + 1)}
                style={styles.navButton}
              >
                Next
              </button>
            )}
            
          
          </div>
          

            <p>Form Completion: {calculateCompletion()}%</p>
            <div style={styles.progressBarContainer}>
              <div style={{ ...styles.progressBar, width: `${calculateCompletion()}%` }}>
                {calculateCompletion()}%
              </div>
            </div> 
            {/* Conditionally render the response data below the button */}
            {cersaiApiResponseData && (
        <div style={styles.responseContainer}>
            <h3 style={{ color: "Red", textAlign: 'center' }}>Cersai SI Registration Status</h3>

            {/* Check if status is 'Failed' or 'faield' */}
            {cersaiApiResponseData.status.toLowerCase() === "failed" ? (
                <>
                <h4 style={{ textAlign: 'center' }}>Cersai Message: {cersaiApiResponseData.message}</h4>
                <h4 style={{ textAlign: 'center' }}>Cersai Status: {cersaiApiResponseData.status}</h4>
                <h3 style={{ textAlign: 'center' }}>Error Details : {cersaiApiResponseData.error_details}</h3>
                <br/>
                <TemporaryMessage/>
              </>
            ) : (
                <>
                    {isSuccess && (
                        <>
                            <h4 style={{ textAlign: 'center' }}>Cersai Asset ID: {cersaiApiResponseData.assetId}</h4>
                            <h4 style={{ textAlign: 'center' }}>Cersai Transaction ID: {cersaiApiResponseData.transactionId}</h4>
                        </>
                    )}
                    {isDuplicate && (
                        <button
                            onMouseEnter={(e) => (e.target.style.backgroundColor = '#0056b3')}
                            onMouseLeave={(e) => (e.target.style.backgroundColor = '#007bff')}
                            onClick={() => handleStepChange(activeStep + 1)}
                            style={styles.navButton}
                        >
                            Forcefully create SI registration
                        </button>
                    )}
                </>
            )}
        </div>
)}

        </div>
      </div>
      
    </>
  );
};

// Styles for the component
// Styles for the component
const styles = {
  container: {
    maxWidth: '800px',
    margin: 'auto',
  },
  formContainer: {
    padding: '20px',
  },
  formGroup: {
    marginBottom: '20px',
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
  },
  flexItem: {
    flex: '1 1 calc(50% - 20px)',
  },
  input: {
    width: '100%',
    padding: '10px',
    margin: '5px 0',
  },
  label: {
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  error: {
    color: 'red',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  stepper: {
    marginBottom: '20px',
  },
  progressBarContainer: {
    height: '30px',
    backgroundColor: '#e0e0e0', // Light grey background for the progress bar
    borderRadius: '4px',
    overflow: 'hidden',
    marginBottom: '20px',
    width: '100%', // Ensure progress bar spans across the container
  },
  progressBar: {
    height: '100%',
    backgroundColor: '#4caf50', // Green background for the progress indicator
    textAlign: 'center',
    color: 'white',
    lineHeight: '30px', // Align text in the center vertically
    fontWeight: 'bold',
    transition: 'width 0.4s ease-in-out', // Smooth width transition
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center', // Ensure buttons are spaced apart
    marginTop: '20px',
  },
  navButton: {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#007bff', // Blue background for navigation buttons
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    margin: '0 10px',
    transition: 'background-color 0.3s ease', // Smooth transition for hover effect
  },
  submitButton: {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#28a745', // Green background for the submit button
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    margin: '0 10px',
    transition: 'background-color 0.3s ease',
  },
  buttonHover: {
    backgroundColor: '#0056b3', // Darker blue for hover effect on navigation buttons
  },
  submitButtonHover: {
    backgroundColor: '#218838', // Darker green for hover effect on submit button
  },
  responseContainer: {
    marginTop: '20px',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9',
  },
};



export default SIRegistration;
