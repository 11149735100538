import React from "react";
import { useState, useEffect } from "react";
import roleChecker from "../_helpers/rolechecker";
import { employeeService, misService } from "../_services";
import { BarChart, PieChart ,Pie , Cell ,Bar, CartesianGrid, XAxis, YAxis, Legend, Tooltip, LabelList, ResponsiveContainer } from "recharts";
import { reportsService } from "../_services/reports.service";
import Product from "../_helpers/product";
import { Button } from "@material-ui/core";
import { history } from "../_helpers";
import { Paper } from "@material-ui/core";
import displayhelper from "../_helpers/displayhelper";
import datehelper from "../_helpers/datehelper";
function BranchAchievements() {
  const user = employeeService.userValue;
  const [metricsData, setMetricsData] = useState({})
  const [time, setTime] = useState(Date.now());
    let todayDate= new Date()
  let currentDate= new Date();
  let todayEnd= datehelper.getDateEnd(todayDate);
  let hours=Math.floor(datehelper.getDateDiffSeconds(todayEnd,currentDate)/3600);
  let minutes=Math.floor((datehelper.getDateDiffSeconds(todayEnd,currentDate)-hours*3600)/60);
  let seconds=Math.floor((datehelper.getDateDiffSeconds(todayEnd,currentDate)-hours*3600-minutes*60));
  
  console.log("hourse left="+hours);
  const [hourLeft,setHourLeft]= useState(hours);
  const [minuteLeft,setMinuteLeft]= useState(minutes);
  const [secondLeft,setSecondLeft]= useState(seconds);
  
  let colors = ['#3C2A21', '#6D67E4', '#5D8AA8', '#46C2CB', '#800000','#2D87BB','#64C2A6']
  
  useEffect(() => {
    loadMetrics()
  }, [])

  async function loadMetrics()
  {
      reportsService.getMetricsData({ 'metric_key': "all" }).
      then((metricListObj) => {
        console.log(metricListObj);
        let tmpMetricsData={}
        metricListObj.map((mo)=> {
          tmpMetricsData[mo.key]=mo.display_data
        })
        console.log(tmpMetricsData);
        setMetricsData(tmpMetricsData);
      })
      .catch((err)=> {
        console.log(err);
      })
  }

function reloadPage()
{
   setTime(new Date());
   console.log("reloading ...");
   window.location.reload(true);
}


function reloadTime()
{
  todayDate= new Date()
  currentDate= new Date();
  todayEnd= datehelper.getDateEnd(todayDate);
  let secondsTotal=datehelper.getDateDiffSeconds(todayEnd,currentDate)
  console.log("total seconds"+secondsTotal);
  hours=Math.floor(secondsTotal/3600);
  let minuteSeconds=secondsTotal-(hours*3600)
  console.log("total seconds in minutes"+minuteSeconds);
  minutes=Math.floor(minuteSeconds/60);
  seconds=Math.floor(secondsTotal-hours*3600-minutes*60);
  setHourLeft(hours)
  setMinuteLeft(minutes)
  setSecondLeft(seconds)
}

useEffect(() => {
  const interval = setInterval(() => reloadPage(), 120000);
  return () => {
    clearInterval(interval);
  };
}, []);

useEffect(() => {
  const interval = setInterval(() => reloadTime(), 1000);
  return () => {
    clearInterval(interval);
  };
}, []);

  const percentageLabelFormatter = (value) => {
    return value + '%';
  };

  let asmData;

  if(metricsData && metricsData.SG26_area_login_count){
      asmData = JSON.parse(metricsData.SG26_area_login_count)
  }


  return (
    <div className="p-4">
        <div className="container">
          {metricsData && Object.keys(metricsData).length>0 && (
            <Paper style={{width:'710px', borderRadius: '10px', margin: '5px', padding: '10px' }}>
            <h3>Today Team Achievements</h3>
            <h4>Time Left: <span style={{color:"red",fontSize:"2rem"}}>{hourLeft} Hours : {minuteLeft} Minutes: {secondLeft} Seconds </span></h4>
            <hr style={{ width: '100%' }} />
            
            {metricsData && metricsData.SG26_area_login_count && (<>
            <div style={{display:"flex", flexDirection:"row"}}>
            <b>ASM-SG26 Login Counts</b>
            </div>
            <ResponsiveContainer width="90%" height={250}>
            <BarChart width={600} height={600} data={asmData}>
            <XAxis dataKey="display_x" />
            <YAxis  type="number" domain={[0, 'dataMax + 5']} />
            <Tooltip />
            <Legend />
            <Bar isAnimationActive={false} dataKey="count" fill="#82ca9d" />
            <LabelList dataKey="count" position="top" />
        </BarChart>
        </ResponsiveContainer>
          </>)}
          <hr style={{ width: '100%' }} /><br/>

            </Paper>
          )} 
          </div>
    </div>
  );
}

export { BranchAchievements };