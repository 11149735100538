import React, { useEffect } from 'react'
import { useState } from 'react'
import { loanService } from '../../_services'
import { employeeService } from "../../_services/employee.service";
import TableContainer from "@material-ui/core/TableContainer";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import ApplicationModal from "./Letters/SelectApplicationForm";
import {Button} from "@material-ui/core";
import datehelper from "../../_helpers/datehelper";
import LetterList from "./Letters/SBLLetterList";
import roleChecker from '../../_helpers/rolechecker';
import { LoanStages } from '../../constants/loanstages';
import displayhelper from '../../_helpers/displayhelper';

const SearchLoans = (props) => {

    const [data, setData] = useState(null)
    const [spinner,setSpinner]= React.useState(false);
    const user= employeeService.userValue;
    const search=props.location.search;
    const searchParams = new URLSearchParams(search);
    const intitialLoanAccount=searchParams.get("loan_account");
    const [value, setValue] = useState({
        name:"",
        aadhar:"",
        contact:"",
        application_id:intitialLoanAccount,
    })
    
    const handleChange = (e) => {
      let  fieldKey=[e.target.name];
       let fieldValue=e.target.value;
        value[fieldKey]=fieldValue
        console.log(value)
        setValue(value)
    }

    const handleEnterKey=(e)=>{
      console.log("handle key ");
      if(e.key==="Enter"){
          getData();
      }
    }
    


    const getData = async() =>{
        setSpinner(true);
        console.log(value);
        loanService.getBySearch(value)
        .then((x)=>{
          console.log("output is",x)
            let y=x.filter((l)=> {
               if(roleChecker.isHOLevelAccess(user.role) || roleChecker.isCustomerCareTeam(user.role) || roleChecker.isCollectionManager(user.role) || ["AUDITOR"].includes(user.role))
               {
                  return true;
               }
               if(l.branch_id != "" && l.branch_id==user.branch_id)
               {
                  return true;
               }
               if(l.employees.length>0 && l.employees[0].branch_id ===user.branch_id)
               {
                  return true;
               }
               if(roleChecker.isASMLevelAccess(user.role) && (l.branches.length>0 && l.branches[0].branch_area ===user.branch_id) )
               {
                  return true;
               }
               
               if(roleChecker.isRSMLevelAccess(user.role) && (l.branches.length>0 && l.branches[0].branch_region ===user.branch_id) )
               {
                  return true;
               }
               if(roleChecker.isZSMLevelAccess(user.role) && (l.branches.length>0 && l.branches[0].branch_zone ===user.branch_id) )
               {
                  return true;
               }

               return false;
            })
            setData(y)
            setSpinner(false);
            console.log(y)
        })
        .catch((err)=>{console.log(err, "error")})
    }

   
    useEffect(()=> {
        if(intitialLoanAccount && intitialLoanAccount!="")
      {
        console.log("finding loan "+intitialLoanAccount);
        
        getData();
      }
    },[])

    const refreshPage=()=> {
        getData();
      }

    return (
        <div>
            <h1>Search SBL Loans</h1><br/>
            <div style={{margin:"auto", display:"flex"}}>
                <label> Search By Loan Number<input type='text' defaultValue={intitialLoanAccount} name="application_id" onChange={(e)=>handleChange(e)} onKeyPress={(e)=> handleEnterKey(e)} /> </label>
                <label> Search By Name<input type='text' name="name" onChange={(e)=>handleChange(e)} onKeyPress={(e)=> handleEnterKey(e)}  /> </label>
                <label> Search By Contact<input type='text' name="contact" onChange={(e)=>handleChange(e)} onKeyPress={(e)=> handleEnterKey(e)}  /> </label>
            </div><br/>
            <button style={{textAlign:"center", marginLeft:"40%", width:"150px"}} className="btn btn-primary" onClick={()=>getData()} >Search</button>
            <hr style={{border:"1px solid"}}></hr><br/>

       {data ? <TableContainer>
      <Table>
          <Thead>
            <Tr>
              <Th style={{ width: "5%" }}>
                  Date
              </Th>
               
              <Th style={{ width: "15%" }}>Loan #</Th> 
              <Th style={{ width: "15%" }}>Customer Name</Th>
              <Th style={{ width: "5%" }}>Sanction Amount</Th>
              <Th style={{ width: "5%" }}>Branch Id</Th>
              <Th style={{ width: "15%" }}>Loan Stage</Th>
              <Th style={{ width: "10%" }}>Next Step</Th>
              <Th style={{ width: "10%" }}>Assigned To</Th>
              <Th style={{ width: "10%" }}>Assigned Since</Th>
              <Th style={{ width: "15%" }}>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data && data.map((application,idx)=>(
              <Tr key={idx}><Td>{ datehelper.displayDate(new Date(application.created))}</Td><Td>{application.application_id}
                     
                  </Td>
              <Td>{application.applicant.general_information.name} 
              </Td>
             <Td>
             {application.loan.sanction_amount && displayhelper.displayNumberAccounting(application.loan.sanction_amount)}
             </Td>
             <Td>{application.branch_id}</Td>
             <Td>{application.stage}
               
              </Td>
              <Td>{application.sub_stage}</Td>
              <Td>{application.next_stage_employees && application.next_stage_employees.length >0 && (
                <span>{application.next_stage_employees[0].first_name} {application.next_stage_employees[0].last_name} </span>
              ) }</Td>
              <Td>{datehelper.displayDate(new Date(application.stage_assign_date))}</Td>
              
              <Td>
               <Button component="a"
              href={`/home/sblloans/details/${application._id}`}
              size="small"
              color="primary"
            >
              View Details</Button> 
              <br/>
              <LetterList id={application._id} stage={application.stage} />
              <br/>
              
             
              </Td></Tr>
            ))}
          </Tbody> 
          </Table>
      </TableContainer>
      : <div style={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center'}}> <h4 style={{textAlign:"center"}}>***Search for Loan Details***</h4> 
      {spinner && (  <span className="spinner-border spinner-border-lg align-center"></span>)}</div> }
        </div>
    )
}

export default SearchLoans