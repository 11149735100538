import React from "react";
import { useState, useEffect } from "react";
import roleChecker from "../_helpers/rolechecker";
import { employeeService, misService } from "../_services";
  
import { reportsService } from "../_services/reports.service";
import displayhelper from "../_helpers/displayhelper";
import datehelper from "../_helpers/datehelper";
import usericon from "../assets/user.png"

function RealTimeCreditDashboard(){
  const user = employeeService.userValue;
  const [metricsData, setMetricsData] = useState({})
  const [metricsData1, setMetricsData1] = useState({})
  const [time, setTime] = useState(Date.now());
  let todayDate= new Date()
  let currentDate= new Date();
  let todayEnd= datehelper.getDateEnd(todayDate);
  let hours=Math.floor(datehelper.getDateDiffSeconds(todayEnd,currentDate)/3600);
  let minutes=Math.floor((datehelper.getDateDiffSeconds(todayEnd,currentDate)-hours*3600)/60);
  let seconds=Math.floor((datehelper.getDateDiffSeconds(todayEnd,currentDate)-hours*3600-minutes*60));
  
  // console.log("hourse left="+hours);
  const [hourLeft,setHourLeft]= useState(hours);
  const [minuteLeft,setMinuteLeft]= useState(minutes);
  const [secondLeft,setSecondLeft]= useState(seconds);
  const [loading,setLoading]=useState(true);
  let branchId='';
  let matrix_key='';
  const [chartData, setChartData] = useState({})
  let colors = ['#3C2A21', '#FFA500', '#5D8AA8', '#46C2CB', '#800000','#2D87BB','#64C2A6',"#547ac4"]
  
  useEffect(() => {
    loadMetrics()
  }, [])

  async function loadMetrics()
  {

    setLoading(true);
    if (roleChecker.isHOLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isASMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isBCMLevelAccess(user.role) || roleChecker.isCSMLevelAccess(user.role) || roleChecker.isRSMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) || roleChecker.isSalesTeamAccess(user.role)) {

      if (roleChecker.isHOLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) ) {
        console.log('User role ---->>> ', user.role, user.branch_id)
        branchId = 'zone-' + user.branch_id
        matrix_key = user.branch_id
        // branchId = 'Zone-J03'
      } 

      else if (roleChecker.isRSMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role)) {
        console.log('User role ---->>> ', user.role, user.branch_id)
        branchId = 'region-' + user.branch_id
        matrix_key = user.branch_id
      }
      else if (roleChecker.isAreaLevelAccess(user.role)) {
        console.log('User role ---->>> ', user.role, user.branch_id)
        branchId = 'area-' + user.branch_id
        matrix_key = user.branch_id
      }
      else if (roleChecker.isCSMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role)) {
        console.log('User role ---->>> ', user.role, user.branch_id)
        branchId = 'cluster-' + user.branch_id
        matrix_key = user.branch_id
      }
      else if (roleChecker.isBMLevelAccess(user.role)) {
        branchId = 'branch-' + user.branch_id
        matrix_key = user.branch_id
        console.log('User role ---->>> ', user.role, user.branch_id,matrix_key)
      }

      else if (roleChecker.isBCMLevelAccess(user.role)) {
        branchId = 'branch-' + user.branch_id
        matrix_key = user.branch_id
        console.log('User role ---->>> ', user.role, user.branch_id,matrix_key)
      }

      else if (roleChecker.isSalesTeamAccess(user.role)) {
        branchId = 'branch-' + user.branch_id
        matrix_key = user.branch_id
        console.log('User role ---->>> ', user.role, user.branch_id,matrix_key)
      }


      else {
        branchId = user.branch_id
         console.log('User role ---->>> ', user.role, user.branch_id, branchId)
      }

      reportsService.getRealMetricsData({ 'key':'login_count', 'branch_key':branchId}).
      then((metricListObj) => {
        console.log(metricListObj);
        let tmpMetricsData={}
        metricListObj.map((mo)=> {
          tmpMetricsData[mo.key]=mo.display_data
        })
        console.log(tmpMetricsData);
        setMetricsData(tmpMetricsData);
      })
      .catch((err)=> {
        console.log(err);
      })
      reportsService.getMetricsData({ 'metric_key': "all" }).
      then((metricListObj) => {
        console.log(metricListObj);
        let tmpMetricsData={}
        metricListObj.map((mo)=> {
          tmpMetricsData[mo.key]=mo.display_data
        })
        console.log(tmpMetricsData);
        setMetricsData1(tmpMetricsData);
      })
      .catch((err)=> {
        console.log(err);
      })
    }
     
  }

function reloadPage()
{
   setTime(new Date());
   console.log("reloading ...");
   window.location.reload(true);
}

function reloadTime()
{
  todayDate= new Date()
  currentDate= new Date();
  todayEnd= datehelper.getDateEnd(todayDate);
  let secondsTotal=datehelper.getDateDiffSeconds(todayEnd,currentDate)
  // console.log("total seconds"+secondsTotal);
  hours=Math.floor(secondsTotal/3600);
  let minuteSeconds=secondsTotal-(hours*3600)
  // console.log("total seconds in minutes"+minuteSeconds);
  minutes=Math.floor(minuteSeconds/60);
  seconds=Math.floor(secondsTotal-hours*3600-minutes*60);
  setHourLeft(hours)
  setMinuteLeft(minutes)
  setSecondLeft(seconds)
}

useEffect(() => {
  const interval = setInterval(() => reloadPage(), 120000);
  return () => {
    clearInterval(interval);
  };
}, []);

useEffect(() => {
  const interval = setInterval(() => reloadTime(), 1000);
  return () => {
    clearInterval(interval);
  };
}, []);

  const percentageLabelFormatter = (value) => {
    return value + '%';
  };

   
  var zoneData;

  if(metricsData1 && metricsData1.zone_login_count){
    zoneData = JSON.parse(metricsData1.zone_login_count)
  }
  var creditData;

  if(metricsData1 && metricsData1.credit_dash_details){
    creditData = JSON.parse(metricsData1.credit_dash_details)
  }

  const styles = `
    .dashboard {
      border-radius: 10px;
      font-family: 'Roboto Slab';
      width: 88%;
      margin: auto;
    }
    .top-row {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      color: white;
      width: 100%;
    }
    .head-cart {
      width: calc(80% - 10px);
      height: 80px;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      font-weight: bold;
      margin-top:10px;
      box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.5);
      color: #ffffff;
      
    }
    .head-cart2 {
      width: calc(74% - 10px);
      height: 70px;
      background-color: #cb3c6b;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      font-weight: bold;
      margin-top:10px;
      box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.5);
    }
    .carts {
      width: calc(18.5% - 5px);
      height: 70px;
      background-color: #2280e3;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;  
      padding: 5px;
      margin-top: 10px;
      box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.5);
      color: #ffffff;
      font-weight: bold;  
    }
    .cart {
      width: calc(37% - 10px);
      height: 70px;
      background-color: #07238f;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      font-weight: bold;
      margin: 10px 10px 0px 0px;
      box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.5);
      color: #ffffff;
    }
    .cart1 {
      width: calc(37% - 10px);
      height: 70px;
      background-color: #547AC4;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      font-weight: bold;
      margin: 10px 10px 0px 0px;
      box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.5);
      color: #ffffff;

    }
    .cart2 {
      width: calc(24.6666% - 10px);
      height: 70px;
      background-color: #07238f;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      font-weight: bold;
      margin: 10px 10px 0px 0px;
      box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.5);
      color: #ffffff;
    }
    .cart3 {
      width: calc(18.5% - 10px);
      height: 80px;
      background-color: #07238f;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 5px;
      font-weight: bold;
      margin: 10px 10px 0px 0px;
      box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.5);
      color: #ffffff;
     
    }
 
    .label {
      font-weight: bold;
      font-size: 1.8em;
      text-align: center; 
    }
    .label1 {
      font-weight: bold;
      font-size: 3em;
      text-align: center;
      color:#070a18; 
    }
    .label2 {
      font-weight: bold;
      font-size: 1.5em;
      text-align: center;
      color:#ecedef; 
       
    }
    .value {
      font-size: 1.5em;
      text-align: center;
      font-weight: bold;
    }
  `;

  return (
    <div className="dashboard">
      <style>{styles}</style>
      <div className="top-row">
          <div className="head-cart">
            <div className="label1">Credit Dashboard</div>
            <h4  style={{textAlign:'center',fontWeight:'bold'}}>Time Left: <span style={{color:"red",fontSize:"1.5rem"}}>{hourLeft} Hours : {minuteLeft} Minutes: {secondLeft} Seconds </span></h4>
          </div>
      </div>
       <hr/>
       <hr/>
       { (roleChecker.isHOLevelAccess(user.role) || roleChecker.isGMLevelAccess(user.role) 
       || roleChecker.isCreditTeamAccess(user.role)|| roleChecker.isSalesTeamAccess(user.role)|| roleChecker.isCSMLevelAccess(user.role))&& 
       <div className="top-row">
          <div className="cart">
            <div className="label">Sanction Letter Count</div>
            <div className="value">
              {roleChecker.isGMLevelAccess(user.role) || roleChecker.isHOLevelAccess(user.role) ? metricsData1['sanction_letter_count'] : metricsData['sanction_count']}
            </div>

          </div>
          <div className="cart">
            <div className="label">Sanction Letter Amount</div>
            <div className="value">₹
              {roleChecker.isGMLevelAccess(user.role) || roleChecker.isHOLevelAccess(user.role) ? displayhelper.displayNumberAccounting(Number(metricsData1['sanction_letter_amount'])): displayhelper.displayNumberAccounting(Number(metricsData['sanction_amount']))}
            </div>
            
          </div>
      </div>}
      {(roleChecker.isHOLevelAccess(user.role) || roleChecker.isGMLevelAccess(user.role) 
       || roleChecker.isCreditTeamAccess(user.role))&& 
      <div className="top-row">
          <div className="cart">
            <div className="label">Number of Logins</div>
            <div className="value">
              {roleChecker.isGMLevelAccess(user.role) || roleChecker.isHOLevelAccess(user.role) ? metricsData1['today_login_count']:metricsData['login_count']}
            </div>
            
          </div>
          <div className="cart">
            <div className="label">Cases in Credit Bucket</div>
            <div className="value">
            {roleChecker.isGMLevelAccess(user.role) || roleChecker.isHOLevelAccess(user.role) ? (creditData && creditData.credit_bucket) : metricsData1['credit_bucket']}
          </div>

            {/* <div className="value">{metricsData['credit_bucket']}</div> */}
          </div>
           
      </div>}

      <div className="top-row">
          <div className="carts">
            <div className="label">Jaipur</div>
            <div className="value">{zoneData &&  zoneData.J03}</div>
          </div>
          <div className="carts">
            <div className="label">Ajmer</div>
            <div className="value">{zoneData && zoneData.AJ13}</div>
          </div>
          <div className="carts">
            <div className="label">Dausa</div>
            <div className="value">{zoneData && zoneData.D01}</div>  
          </div>
          <div className="carts">
            <div className="label">Sriganganagar</div>
            <div className="value">{zoneData && zoneData.SG26}</div> 
          </div>
      </div>
      <div className="top-row">
          <div className="head-cart2">
            <div className="label2">PD Details</div>
          </div>
      </div>
      <div className="top-row">
          <div className="cart">
            <div className="label">PD Done</div>
            <div className="value">
            {roleChecker.isGMLevelAccess(user.role) || roleChecker.isHOLevelAccess(user.role) ? (creditData && creditData.pd_done) : metricsData1['pd_done']}
          </div>  
          </div>
          <div className="cart">
            <div className="label">PD Pending</div>
            <div className="value">
            {roleChecker.isGMLevelAccess(user.role) || roleChecker.isHOLevelAccess(user.role) ? (creditData && creditData.pd_pending) : metricsData1['pd_pending']}
          </div>
          </div>
      </div>
      <div className="top-row">
          <div className="head-cart2">
            <div className="label2">Decision Details</div>
          </div>
      </div>
      <div className="top-row">
      <div className="cart2">
            <div className="label">Recommendation</div>
            {/* <div className="value">
            {roleChecker.isGMLevelAccess(user.role) || roleChecker.isHOLevelAccess(user.role) ? (creditData && creditData.pd_done) : metricsData1['pd_done']}
          </div> */}
            <div className="value">0</div>  
          </div>
          <div className="cart2">
            <div className="label">Hold</div>
            <div className="value">0</div> 
          
          </div>
          <div className="cart2">
            <div className="label">Rejected</div>
            <div className="value">
            {roleChecker.isGMLevelAccess(user.role) || roleChecker.isHOLevelAccess(user.role) ? (creditData && creditData.pd_rejected) : metricsData1['pd_rejected']}
          </div>
           
          </div>
         
      </div>

      <div className="top-row">
          <div className="head-cart2">
            <div className="label2">Sanction Details</div>
          </div>
      </div>

      <div className="top-row">
          <div className="cart3">
            <div className="label">Verified</div>
            <div className="value">
            {roleChecker.isGMLevelAccess(user.role) || roleChecker.isHOLevelAccess(user.role) ? (creditData && creditData.ver_done) : metricsData1['verification_done']}
          </div>
            <div className="value">{metricsData['verification_done']}</div>  
          </div>
          <div className="cart3">
            <div className="label">Verification pending</div>
            <div className="value">
            {roleChecker.isGMLevelAccess(user.role) || roleChecker.isHOLevelAccess(user.role) ? (creditData && creditData.ver_pending) : metricsData1['verification_pending']}
          </div>
             
          </div>
         
          <div className="cart3">
            <div className="label">Disbursed</div>
            <div className="value">
            {roleChecker.isGMLevelAccess(user.role) || roleChecker.isHOLevelAccess(user.role) ? (creditData && creditData.disbursal_bucket) : metricsData1['disbursed']}
          </div>
             
          </div>
          <div className="cart3">
            <div className="label">Rejected</div>
            <div className="value">
            {roleChecker.isGMLevelAccess(user.role) || roleChecker.isHOLevelAccess(user.role) ? (creditData && creditData.rejedted_bucket) : metricsData1['rejected']}
          </div>
            
          </div>
      </div>
      

       
      
    </div>
  );
}


export {RealTimeCreditDashboard};
