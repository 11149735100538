import React from "react";
import logo from "../../assets/kaabil.png";
import "./pledgeletter.css";

export default class ReminderLetter extends React.Component {
  render() {
    const data = this.props.dataFill;
    const todayDate=new Date();
    console.log("datadatadatadatadatadata",data);

    return (
      <div className="pledge">
        <style type="text/css" media="print">
          {"\
   @page { size: A4; }\
"}
        </style>
        <br />
        <div
          style={{
            width: "290mm",
            height: "400mm",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: "white",
            padding: 40,
          }}
          className="d-flex flex-column"
        >
          <div>
            <div style={{ textAlign: "center" }}>
              <img style={{ width: 350, height: 100 }} src={logo}></img>
            </div>
          </div>
          <hr />
          <h5 style={{ textAlign: "center" }}>
            <p><b>REMINDER LETTER</b></p>
          </h5>
          <p style={{ textAlign: "left" }}>Date: {("0"+todayDate.getDate()).slice(-2)}/{("0"+(todayDate.getMonth()+1)).slice(-2)}/{todayDate.getFullYear()}</p>
          <p style={{ textAlign:"left"}}>Letter No: {data.loan_account}/{todayDate.getFullYear()}/{todayDate.getMonth()+1}/{("0"+todayDate.getDate()).slice(-2)}/{"0"+ data.notice}</p>
          <p>
            To, 
            <br/>
            {data.name} S/O {data.sonof}
          </p>
          <p>Address - {data.address}{data.details?.permanent_address?.pin_code && ` - ${data.details.permanent_address.pin_code}`}</p>
          <p>Mob.No. {data.contact}</p>
          <p>Dear Sir/Madam,</p>
          <p>
            Reg: <b>Demand Notice</b>- Gold Loan account in your name with Kaabil Finance Private Limited “KFPL”
          </p>

          <div>
            <br />
            <table style={{ width: "80%" }}>
              <tr>
                <th>Gold Loan No.</th>
                <th>Loan Amount</th>
                <th>Date of Pledge <small>(YYYY-MM-DD)</small></th>
              </tr>
              <tr>
                <td>{data.loan_account}</td>
                <td>Rs.{data.loan_amount}</td>
                <td>{data.date.split("T")[0]}</td>
              </tr>
            </table>
          </div>
          <p>
          The above mentioned gold loan account/s is /are due for payment/redemption as per details
          </p>
          <p>
          You are hereby requested to remit the entire dues with interest and other charges including other liabilities due to the KFPL till date and redeem the items pledged. You are required to settle the existing liabilities within 7 days of receipt of this letter failing which we may be constrained to auction/private sale the items pledged with us for realizing our dues without further notice and you will be liable for the entire costs and consequences thereof.
          </p>
          <p>If you have already renewed the facility/settled the liabilities with the Kaabil Finance Private Limited, please ignore this communication.</p>
          <p>
          We seek your continued patronage and support.
          </p>
          <p>
          You’re faithfully,
          </p>
          <br />
          <br />
          <p>
            Authorized Signatory
            <br />
            (On behalf of Kaabil Finance Pvt. Ltd)
          </p>
          <p>For any query kindly call/whatsapp: 8690008269</p>
          <p>Kindly pay your dues in the following bank account and communicate the same on the aforesaid contact number:</p>
          <table style={{ width: "75%" }}>
            <tr>
              <td>Account Name</td>
              <td><b>Kaabil Finance Private Limited</b></td>
            </tr>

            <tr>
              <td>Bank and Branch</td>
              <td>ICICI Bank Limited, Mangal Marg, Jaipur</td>
            </tr>
            <tr>
              <td>Account Number</td>
              <td>361705000208</td>
            </tr>

            <tr>
              <td>IFSC Code</td>
              <td>ICIC0003617</td>
            </tr>
          </table>
        </div>
      </div>
    );
  }
}
