import React from "react";
import { useReactToPrint } from "react-to-print";
// import { misService } from "../_services";
// import ApplicationDoc from "./ApplicationDoc";
import ReleaseDoc from "./ReleaseDoc";
import { releaseServices } from "../../../_services/releaseletter.service";
import { employeeService } from "../../../_services";
import SibReleaseLetter from "./SibReleaseLetter";
export default function ReleasePrint(props) {
  // const compRef = React.useRef();
  const data = props.location.state
  const date = new Date()
  const contact = data.contact
  const user = employeeService.userValue;
  const ornamentData = data.details.gold.ornaments.map(item => (
    {
      desc: item.desc,
      quantity: item.quantity
    }))
  // console.log("our ornament", ornamentData)
  const releaseData = {
    current_date: data.current_date,
    letter_number: data.letter_number,
    emp_id: user.emp_id,
    bank: data.bank,
    name: data.name,
    loan_account: data.loan_account,
    gold: {
      gross_weight: data.gold.gross_weight,
      net_weight: data.gold.net_weight
    },
    details: {
      loan_account: data.details.loan_account,
      gold: {
        gross_weight: data.details.gold.gross_weight,
        net_weight: data.details.gold.net_weight,
        ornaments: ornamentData,
        total_items: data.details.gold.total_items,
      }
    },
    date: data.date,
    releaseAmount: data.releaseAmount,
    packetNumber: data.packetNumber,
    authPerson: data.authPerson,
  }
  const handleSave = async () => {
    await releaseServices.saveReleaseData({ releaseData: { ...releaseData } })
      .then((res) => handleReleasePrint())
      .catch((err) => window.alert("error  occurs"))
  }

  const releaseRef = React.useRef();
  // const [ckyc, setCkyc] = React.useState("");

  // React.useEffect(async () => {
  //   const kyclink = await misService.getCkyc({
  //     customer_pic: props.location.state.customer_pic,
  //     loan_account: props.location.state.loan_account,
  //   });
  //   setCkyc(kyclink);
  // }, []);

  // function openCkyc() {
  //   window.open(ckyc);
  // }

  const handleReleasePrint = useReactToPrint({
    content: () => releaseRef.current,
  });
  console.log("test bank ", data.bank)
  return (
    data.bank === "SIB" ?
      <div>
        <div style={{ textAlign: "center" }}>
          {
            contact ? <button onClick={() => handleSave()}>Print Release Letter</button>
              : <button onClick={() => handleReleasePrint()}>Print Preview Release Letter</button>
          }
        </div>
        < SibReleaseLetter ref={releaseRef} dataFill={props.location.state} />
      </div> : <div>
        <div style={{ textAlign: "center" }}>
          {
            contact ? <button onClick={() => handleSave()}>Print Release Letter</button>
              : <button onClick={() => handleReleasePrint()}>Print Preview Release Letter</button>
          }
        </div>
        <ReleaseDoc ref={releaseRef} dataFill={props.location.state} />
      </div>
  );
}
