import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
 import { employeeService } from "../../_services/employee.service";
import { alertService } from "../../_services/alert.service";
import { branchService } from "../../_services/branch.service";
import { Switch,Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  OutlinedInput } from "@material-ui/core";
import roleChecker from "../../_helpers/rolechecker";
import { receiptService } from "../../_services/receipt.service"; 

function AddEdit({ history, match }) {
  const user = employeeService.userValue;
  const [branches, setBranches] = React.useState([]);
  const [employees, setEmployees] = React.useState([]);

  const { id } = match.params;
  const isAddMode = !id;
  const formikRef = React.useRef();

  const randomPassword = Math.random().toString(36).slice(2, 10);

  let initialValues;
    
  if(isAddMode){
   initialValues = {
    emp_id: "",
    blocked: false,
    title: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    branch_id: "",
    role: "Collections",
    manager_emp_id:"",
    password: randomPassword,
    confirmPassword: randomPassword,
    contact: "",
    email: "",
    otp:"",
    whatsapp_number:"",
    operation_branch:[],
    threshold_amount:"",
    category_reference:""
  };
}else{
  initialValues = {
    emp_id: "",
    blocked: false,
    title: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    branch_id: "",
    role: "Collections",
    manager_emp_id:"",
    password: "",
    confirmPassword: "",
    contact: "",
    email: "",
    otp:"",
    whatsapp_number:"",
    operation_branch:[],
    threshold_amount:'',
    category_reference:""
  };
}

function getEmployees(){
  employeeService.getAll()
  .then(employees=>{ employees.sort((a, b) => {
    const nameA = a.first_name.trim().toLowerCase()  ;
    const nameB = b.first_name.trim().toLowerCase()  ;
      
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    
    return 0;
  });
     setEmployees(employees)})
}

  useEffect(() => {
    branchService.getAll().then((branches) => {
      setBranches(branches);
    });
   getEmployees()
    if (!isAddMode) {
      // get user and set form fields

      employeeService.getById(id).then((employee) => {
        const fields = [
          "emp_id",
          "blocked",
          "title",
          "first_name",
          "middle_name",
          "last_name",
          "branch_id",
          "manager_emp_id",
          "role",
          "contact",
          "email",
          "otp",
          "whatsapp_number",
          "operation_branch",
          "threshold_amount",
          "category_reference"
        ];
        fields.forEach((field) => {
          if (formikRef.current) {
            formikRef.current.setFieldValue(field, employee[field], false);
          }
        });
      });
    }
  }, [id, isAddMode, formikRef]);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"), 
    first_name: Yup.string().matches(/^[a-zA-Z]+$/,'First name must contain only alphabetic characters').required("First Name is required"),
    middle_name: Yup.string().matches(/^[a-zA-Z]+$/,'Middle name must contain only alphabetic characters'),
    last_name: Yup.string().matches(/^[a-zA-Z]+$/,'Last name must contain only alphabetic characters').required("Last Name is required"),
    role: Yup.string().required("Role is required"),
    manager_emp_id: Yup.number().notOneOf([Yup.ref('emp_id')],'Manager emp id can not be equal to emp id').required("Manager employee id is required"),
       // contact: Yup.string().matches(/^[0-9]{10}$/, 'Contact should be of 10 numeric digits and first digit shold be between 6 to 9').required("Contact is required"),
    contact: Yup.string().matches(/^[6-9]\d{9}$/, 'The phone number should be 10 digits, with the first digit is in between 6 to 9').required('Contact number is required'),
    email: Yup.string().email('Must be a valid email'),
    password: Yup.string()
      .concat(isAddMode ? Yup.string().required("Password is required") : null)
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .when("password", (password, schema) => {
        if (password) return schema.required("Confirm Password is required");
      })
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });

  function onSubmit(fields, { setStatus, setSubmitting }) {
    console.log("fields",fields)
    if(fields && fields.otp && (fields.otp.toString().length !== 4)){
      window.alert('OTP must be 4 digit only')
      setSubmitting(false);

      return;
    }
    setStatus();
    if (isAddMode) {
      createUser(fields, setSubmitting);
      alert(`Your password is ${randomPassword}`)
    } else {
      updateUser(id, fields, setSubmitting);
    }
  }

  function createUser(fields, setSubmitting) {
    employeeService
      .create(fields)
      .then(() => {
        alertService.success("User added successfully", {
          keepAfterRouteChange: true,
        });
        history.push(".");
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
  }

  function updateUser(id, fields, setSubmitting) {
    employeeService
      .update(id, fields)
      .then(() => {
        alertService.success("Update successful", {
          keepAfterRouteChange: true,
        });
        history.push("..");
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
  }
  const branchOptions = branches.map((branch) => ({
    value: branch.branch_id,
    label: `${branch.branch_id} - ${branch.branch_name}`,
  }));

  useEffect(() => {
    
    receiptService.allEmployeeRemainingData({emp_id:30003})
    .then(res=>console.log('response of employeee remaining ',res))
    .catch(err=>console.log('err of remaning is',err))
  }, [])
  

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, isSubmitting, setFieldValue, values }) => {
        return (
          <Form>
            <h1>{isAddMode ? "Add User" : "Edit User"}</h1>
            <div className="form-row">
              <div className="form-group col">
                <label>Emp ID</label>
                <Field
                  name="emp_id"
                  disabled={!isAddMode}
                  type="text"
                  className={
                    "form-control" +
                    (errors.emp_id && touched.emp_id ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="emp_id"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group col-1">
                <label>Title</label>
                <Field
                  name="title"
                  as="select"
                  className={
                    "form-control" +
                    (errors.title && touched.title ? " is-invalid" : "")
                  }
                >
                  <option value=""></option>
                  <option value="Mr.">Mr.</option>
                  <option value="Mrs.">Mrs.</option>
                </Field>
                <ErrorMessage
                  name="title"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group col-3">
                <label>First Name</label>
                <Field
                  name="first_name"
                  type="text"
                  className={
                    "form-control" +
                    (errors.first_name && touched.first_name
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name="first_name"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group col-3">
                <label>Middle Name</label>
                <Field
                  name="middle_name"
                  type="text"
                  className={
                    "form-control" +
                    (errors.middle_name && touched.middle_name
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name="middle_name"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group col-3">
                <label>Last Name</label>
                <Field
                  name="last_name"
                  type="text"
                  className={
                    "form-control" +
                    (errors.last_name && touched.last_name ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="last_name"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col">
                <label>Role</label>
                <Field
                  name="role"
                  as="select"
                  className={
                    "form-control" +
                    (errors.role && touched.role ? " is-invalid" : "")
                  }
                >
                  <option value=""></option>
                  {(roleChecker.isAdminAccess(user.role) || roleChecker.isITAccess(user.role) || roleChecker.isHRDept(user.role)) && (
                    <React.Fragment>
                      <option value="Sales">RO/Sales</option>
                      <option value="BM">BM/Branch Manager</option>
                      <option value="BCM">BCM/Branch Credit Manager</option>
                      <option value="BOP">BOP/Branch Operation Maker</option>
                      <option value="Collections">Collections</option>
                      <option value="HO">Head Office-Maker</option>
                      <option value="HOA">Head Office-Approver</option>
                      <option value="COL">Colending Maker</option>
                      <option value="COLA">Colending Approver</option>
                      <option value="COLM">Colending Manager</option>

                      <option value="HOC">HO Credit Maker</option>
                      <option value="HOCA">HO Credit Approver</option>
                      <option value="HOCL">HO Collection Maker</option>
                      <option value="HOCLA">HO Collection Approver</option>
                      <option value="HOO">HO Ops Maker</option>
                      <option value="HOOA">HO Ops Approver</option>
                      <option value="HOSA">HO Sales Approver</option>
                      <option value="CSM">Cluster Sale Manager</option>
                      <option value="CCM">Cluster Credit Manager</option>
                      <option value="RSM">RSM/Region Sale Manager</option>
                      <option value="ZSM">ZSM/Zonal Sale Manager</option>
                      <option value="EAZSM">Executive Assistance of ZSM </option> 
                      <option value="KTR">KTR/Kaabil KTR</option>
                      <option value="ASM">ASM/Area Sale Manager</option>
                      <option value="ACM">ACM/Area Credit Manager</option>
                      <option value="RCM">RCM/Region Credit Manager</option>

                      <option value="ZCM">Zonal Credit Manager</option>
                      <option value="CC">Customer Care</option>
                      <option value="AM">Account Maker</option>
                      <option value="AC">Account Checker</option>
                      <option value="LG">Legal Manager</option>
                      <option value="CLM">Collection Manager</option>
                      <option value="HOGM">HO Gold Maker</option>
                      <option value="HOGC">HO Gold Checker</option>
                      <option value="GLDR">Gold Rider</option>
                      <option value="HR">HR</option>
                      <option value="IT">IT Manager</option>
                      <option value="CP">Channel Partner</option>
                      <option value="SLG">Sale Lead Generator</option>
                      <option value="VPC">VP - Corporate Strategy</option>
                      <option value="PIVG">PIVG Manager</option>
                      <option value="AUDITOR">Auditor</option>
                      <option value="VENDOR">Vendor</option>
                      <option value="ZCCM">ZCCM/Zonal Collection Manager</option>
                      <option value="RCCM">RCCM/Region Collection Manager</option>
                      <option value="ACCM">ACCM/Area Collection Manager</option>
                      <option value="CCCM">CCCM/Cluster Collection Manager</option>
                      <option value="BCCM">BCCM/Branch Collection Manager</option>

                      <option value="Admin" disabled>Admin</option>
                      <option value="GM" disabled>GM</option>
                      <option value="CFO" disabled>CFO</option>
                      <option value="CEO" disabled>CEO</option>
                      
                      
                      
                    </React.Fragment>
                  )}

                  
                </Field>
                <ErrorMessage
                  name="role"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group col">
                <label>Branch ID</label>
                <Field
                  name="branch_id"
                  as="select"
                  className={
                    "form-control" +
                    (errors.branch_id && touched.branch_id ? " is-invalid" : "")
                  }
                >
                  <option value=""></option>
                  {branches.map((branch) => (
                    <option key={branch.branch_id} value={branch.branch_id}>
                      {branch.branch_id+" - "+branch.branch_name }
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="branch_id"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group col">
                <label>Manager Emp ID</label>
                <Field
                  name="manager_emp_id"
                  as="select"
                  className={
                    "form-control" +
                    (errors.manager_emp_id && touched.manager_emp_id ? " is-invalid" : "")
                  }
                >
                  <option value=""></option>
                  {employees.map((employee) => (
                    <option key={employee.emp_id} value={employee.emp_id}>
                      {employee.emp_id} - {employee.first_name} {employee.middle_name} {employee.last_name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="manager_emp_id"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group col">
                <label>OTP (4 digit) </label>
                <Field
                  name="otp"
                  type="number"
                   className={
                    "form-control" +
                    (errors.otp && touched.otp ? " is-invalid" : "")
                  }
                 />
                <ErrorMessage
                  name="otp"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col">
                <label>Contact Number</label>
                <Field
                  name="contact"
                  type="number"
                  className={
                    "form-control" +
                    (errors.contact && touched.contact ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="contact"
                  component="div"
                  className="invalid-feedback"
                />

              </div>
              <div className="form-group col">
                <label>Email</label>
                <Field
                  name="email"
                  type="text"
                  className={
                    "form-control" +
                    (errors.email && touched.email ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="invalid-feedback"
                />

              </div>
              <div className="form-group col">
                <label>WhatsApp Number</label>
                <Field
                  name="whatsapp_number"
                  type="text"
                  className={
                    "form-control" +
                    (errors.whatsapp_number && touched.whatsapp_number ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="whatsapp_number"
                  component="div"
                  className="invalid-feedback"
                />

              </div>
              <div className="form-group col">
              <label>Operational Branch</label>
              <FormControl fullWidth  >
                <Field
                  name="operation_branch"
                  as={Select}
                   labelId="operation-branch-label"
                  multiple
                  value={values.operation_branch}
                  onChange={(event) => {
                    const { value } = event.target;
                    setFieldValue("operation_branch", typeof value === "string" ? value.split(",") : value);
                  }}
                   renderValue={(selected) => selected.join(", ")}
                  className={"form-control" + (errors.operation_branch && touched.operation_branch ? " is-invalid" : "")}
                  style={{ width: 300 }}
                >
                  {branchOptions.map((branch) => (
                    <MenuItem key={branch.value} value={branch.value}>
                      <Checkbox checked={values.operation_branch.indexOf(branch.value) > -1} />
                      <ListItemText primary={branch.label} />
                    </MenuItem>
                  ))}
                </Field>
                <ErrorMessage name="operation_branch" component="div" className="invalid-feedback" />
              </FormControl>
            </div>
            </div>
            <div className="form-row">
              <div className="form-group col">
                <label>Block status</label>
                <Switch
                  name="blocked"
                  checked={values.blocked}
                  onChange={(event, checked) => {
                    setFieldValue("blocked", checked ? true : false);
                  }}
                />
              </div>
              <ErrorMessage
                name="blocked"
                component="div"
                className="invalid-feedback"
              />
              <div className="form-group col">
                <label>Threshold Amount</label>
                <Field
                  name="threshold_amount"
                  type="text"
                  className={
                    "form-control" +
                    (errors.threshold_amount && touched.threshold_amount ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="threshold_amount"
                  component="div"
                  className="invalid-feedback"
                />

            </div>
            <div className="form-group col">
                <label>Category Reference</label>
                <Field
                  name="category_reference"
                  type="number"
                  className={
                    "form-control" +
                    (errors.category_reference && touched.category_reference ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="category_reference"
                  component="div"
                  className="invalid-feedback"
                />

            </div>
            </div>
            {!isAddMode && (
              <div>
                <h3 className="pt-3">Change Password</h3>
                <p>Leave blank to keep the same password</p>
              </div>
            )}
            <div className="form-row">
              <div className="form-group col">
                <label>Password</label>
                <Field
                  name="password"
                  type="password"
                  className={
                    "form-control" +
                    (errors.password && touched.password ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group col">
                <label>Confirm Password</label>
                <Field
                  name="confirmPassword"
                  type="password"
                  className={
                    "form-control" +
                    (errors.confirmPassword && touched.confirmPassword
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>
            <div className="form-group">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-primary"
              >
                {isSubmitting && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                Save
              </button>
              <Link to={isAddMode ? "." : ".."} className="btn btn-link">
                Cancel
              </Link>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export { AddEdit };
