import React from "react";
import urlhelper from "../../../_helpers/urlhelper";
const Buffer = require("buffer").Buffer;
export default function PdfViewer(props) {
    const search = props.location.search;
    console.log("location search params "+search);
    const searchParams = new URLSearchParams(search);
    let pdfUrl = searchParams.get("pdf_url");
    let download=searchParams.get("download");
    pdfUrl=urlhelper.decodeFromBase64(pdfUrl);
    if(!download)
    {
        //pdfUrl=pdfUrl+"#toolbar=0"
    }
    download=true;
    return (
        <>
        {!download && (
            <object  data={pdfUrl} type="application/pdf" width="100%" height="600px"  style={{"pointer-events": "none"}}>
            </object>
        )
        }
        {download && (
            <object  data={pdfUrl} type="application/pdf" width="100%" height="600px">
            </object>
        )
        }
        </>
        
        )
}