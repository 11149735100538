import { customerService, misService } from "../../_services";
import { collectionService } from "../../_services";
import { makerCheckerServices } from "../../_services/makerchecker.service";
import { loanService } from "../../_services";
import { receiptService } from "../../_services/receipt.service";
import { termLoans } from "../../_services/termloans.service";
import { ledgerService } from "../../_services/ledger.service";
import { sblLetter } from "../../_services/letter.services";
import { letter } from "../../_services/lettertracking.service";
import  {branchExpenseService} from "../../_services/branchExpense.service"


export const AllServices ={
    customerService:customerService,
    collectionService:collectionService,
    makerCheckerServices:makerCheckerServices,
    loanService:loanService,
    receiptService:receiptService,
    misService:misService,
    termLoans:termLoans,
    ledgerService:ledgerService,
    sblLetter:sblLetter,
    letter:letter,
    branchExpenseService:branchExpenseService
}
