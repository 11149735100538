import React from 'react'
import { Route, Switch } from "react-router-dom";
import CallDetailsList from './CallDetailsList';

const CallDetailsIndex = () => {
  return (
    <>
        <Switch>
            <Route path={`/home/calldetails/list`} component= {CallDetailsList}/>
        </Switch>
    </>
  )
}

export default CallDetailsIndex;