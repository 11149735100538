import React, { useState } from 'react';
import { Formik, Field, Form, FieldArray } from 'formik';
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  Box
} from '@material-ui/core';
import * as Yup from 'yup';
 import RemoveIcon from '@material-ui/icons/Remove';
import { adminservice } from "../../../_services/admin.services";
import { Add, Edit } from "@material-ui/icons";
import { notifyInfo, notifyFailed } from "../../../_helpers/notify";
import { ToastContainer } from "react-toastify";
// Define the initial values for the form
const initialValues = {
  categoryName: '',
  travelOptions: [
    {
      travelOptionName: '',
      subOptions: ['']
    }
  ],
  mealEligibility: [
    {
      mealType: '',
      amount: 0
    }
  ],
  stayAllowance: [
    {
      cityType: '',
      amount: 0
    }
  ]
};

// Define validation schema
const validationSchema = Yup.object({
  categoryName: Yup.number().required('Category name is required'),
  travelOptions: Yup.array().of(
    Yup.object({
      travelOptionName: Yup.string().required('Travel option name is required'),
      subOptions: Yup.array().of(Yup.string())
    })
  ),
  mealEligibility: Yup.array().of(
    Yup.object({
      mealType: Yup.string().required('Meal type is required'),
      amount: Yup.number().required('Amount is required').positive('Amount must be positive')
    })
  ),
  stayAllowance: Yup.array().of(
    Yup.object({
      cityType: Yup.string().required('City type is required'),
      amount: Yup.number().required('Amount is required').positive('Amount must be positive')
    })
  )
});

const NewEditCategoryModal = () => {
  const [open, setOpen] = useState(false);
  const [spinner,setSpinner] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values) => {
    setSpinner(true)
    console.log('Form values:', values);
  
    adminservice.createCategory(values)
    .then((res) => {
      
        console.log('Category Added Successfully', res);
        notifyInfo(`Category Added Successfully`);
      
    })
    .catch((err) => {
      console.log(err);
      notifyFailed('An error occurred while  Adding new Category Successfully');
      setSpinner(false);
    });
  };

  return (
    <div>
     {false  ? <div style={{marginBottom:'1rem'}} >
     <label style={{fontSize: 'xx-large'}}>Edit  Category</label>
     <Button variant="outlined" color="primary" onClick={handleClickOpen}>
   <Edit/>
     </Button>
     </div> :<div style={{marginBottom: '1rem',
   display: 'flex',
   alignItems: 'center',
   alignContent: 'center',
   flexWrap: 'wrap',
   gap: '1rem',
}} >
   <label style={{fontSize: 'xx-large'}}>Add New Category</label>
     <Button variant="outlined" color="primary" onClick={handleClickOpen}>
   <Add/>
     </Button>
     </div>}
     
      
      <Dialog open={open} onClose={handleClose}   maxWidth="sm">
        <DialogTitle>Add/Edit Category</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values }) => (
              <Form>
              <ToastContainer/>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      name="categoryName"
                      as={TextField}
                      label="Category Name"
                      type="number"
                      margin="normal"
                      variant="outlined"
                      size="medium"
                      fullWidth
                    />
                  </Grid>

                  <FieldArray name="travelOptions">
                    {({ push, remove }) => (
                      <>
                        {values.travelOptions.map((option, index) => (
                          <Grid container spacing={2} key={index}>
                            <Grid item   >
                              <Field
                                name={`travelOptions[${index}].travelOptionName`}
                                as={TextField}
                                label="Travel Option Name"
                                margin="normal"
                                variant="outlined"
                                size="medium"
                              />
                            </Grid>
                            {/* <Grid item   >
                              <IconButton
                                type="button"
                                onClick={() => remove(index)}
                                color="secondary"
                                aria-label="Remove travel option"
                              >
                                <RemoveIcon />
                              </IconButton>
                            </Grid> */}

                            <Grid item  >
                              <FieldArray name={`travelOptions[${index}].subOptions`}>
                                {({ push, remove }) => (
                                  <Box display="flex" flexDirection="column">
                                    {option.subOptions.map((subOption, subIndex) => (
                                      <Grid container spacing={2} key={subIndex} alignItems="center">
                                        <Grid item  >
                                          <Field
                                            name={`travelOptions[${index}].subOptions[${subIndex}]`}
                                            as={TextField}
                                            label={`Sub Option ${subIndex + 1}`}
                                            margin="normal"
                                            variant="outlined"
                                            size="medium"
                                          />
                                        </Grid>
                                        <Grid item  >
                                          <IconButton
                                            type="button"
                                            onClick={() => remove(subIndex)}
                                            color="secondary"
                                            aria-label="Remove sub-option"
                                          >
                                            <RemoveIcon />
                                          </IconButton>
                                        </Grid>
                                      </Grid>
                                    ))}
                                    <Grid item  >
                                      <Box display="flex" justifyContent="flex-start">
                                        {option.subOptions.length > 0 && (
                                          <IconButton
                                            type="button"
                                            onClick={() => push('')}
                                            color="primary"
                                            aria-label="Add sub-option"
                                          >
                                            <Add />
                                          </IconButton>
                                        )}
                                      </Box>
                                    </Grid>
                                  </Box>
                                )}
                              </FieldArray>
                            </Grid>
                          </Grid>
                        ))}
                        <Button
                          type="button"
                          onClick={() => push({ travelOptionName: '', subOptions: [''] })}
                          variant="outlined"
                          color="primary"
                          startIcon={<Add />}
                        >
                          Add Travel Option
                        </Button>
                      </>
                    )}
                  </FieldArray>

                  <FieldArray name="mealEligibility">
                    {({ push, remove }) => (
                      <>
                        {values.mealEligibility.map((meal, index) => (
                          <Grid container spacing={2} key={index}>
                            <Grid item    >
                              <Field
                                name={`mealEligibility[${index}].mealType`}
                                as={TextField}
                                label="Meal Type"
                                margin="normal"
                                variant="outlined"
                                size="medium"
                              />
                            </Grid>

                            <Grid item  >
                              <Field
                                name={`mealEligibility[${index}].amount`}
                                as={TextField}
                                label="Amount"
                                type="number"
                                margin="normal"
                                variant="outlined"
                                size="medium"
                              />
                            </Grid>

                            <Grid item  >
                              <IconButton
                                type="button"
                                onClick={() => remove(index)}
                                color="secondary"
                                aria-label="Remove meal eligibility"
                              >
                                <RemoveIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ))}
                        <Button
                          type="button"
                          onClick={() => push({ mealType: '', amount: 0 })}
                          variant="outlined"
                          color="primary"
                          startIcon={<Add />}
                        >
                          Meal  
                        </Button>
                      </>
                    )}
                  </FieldArray>

                  <FieldArray name="stayAllowance">
                    {({ push, remove }) => (
                      <>
                        {values.stayAllowance.map((stay, index) => (
                          <Grid container spacing={2} key={index}>
                            <Grid item  >
                              <Field
                                name={`stayAllowance[${index}].cityType`}
                                as={TextField}
                                label="City Type"
                                margin="normal"
                                variant="outlined"
                                size="medium"
                              />
                            </Grid>

                            <Grid item  >
                              <Field
                                name={`stayAllowance[${index}].amount`}
                                as={TextField}
                                label="Amount"
                                type="number"
                                margin="normal"
                                variant="outlined"
                                size="medium"
                              />
                            </Grid>

                            <Grid item >
                              <IconButton
                                type="button"
                                onClick={() => remove(index)}
                                color="secondary"
                                aria-label="Remove stay allowance"
                              >
                                <RemoveIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ))}
                        <Button
                          type="button"
                          onClick={() => push({ cityType: '', amount: 0 })}
                          variant="outlined"
                          color="primary"
                          startIcon={<Add />}
                        >
                          Stay Allowance  
                        </Button>
                      </>
                    )}
                  </FieldArray>

                </Grid>
                <DialogActions>
                  <Button onClick={handleClose} color="default">
                    Cancel
                  </Button>
                  <Button type="submit" color="primary" disabled={spinner}>
                    Submit{spinner && <span className="spinner-border spinner-border-sm"></span>}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default NewEditCategoryModal;
