import React from 'react'
import './AllCloudAppCoAppDescription.css';

function AllCloudAdditionalInfo(props) {

    const value = props.value;
    console.log('value', value)
    return (
        <div className="allCloudDescriptionContainer">
        <div className="row" style={{marginTop:"1.5rem",width:"100%"}}>
  <div className="col-sm-6" id='loan_details' style={{maxHeight:"140vh"}}>
    <div className="card">
      <div className="card-body">
        <h4 className="card-title" style={{fontWeight:"bold",color:"#3F51B5"}}>Loan Details</h4>
        {value && <>
        <div className="Addition_flex" >
            <span>Agreement No.</span>
            <span className='Addition_label'>{value.AgreementNo}</span>
        </div>
        <hr className='headingline'/>
        <div className="Addition_flex" >
            <span>Duration </span>
            <span className='Addition_label'>{value.Duration}</span>
        </div>
        <hr className='headingline'/>
        <div className="Addition_flex" >
            <span>EMI</span>
            <span className='Addition_label'>{value.EMI}</span>
        </div>
        <hr className='headingline'/>
        <div className="Addition_flex" >
            <span >Sanction Amount</span>
            <span className='Addition_label'>{value.SanctionAmount}</span>
        </div>
        <hr className='headingline'/>
        <div className="Addition_flex" >
            <span >Total Amount</span>
            <span className='Addition_label'>{value.TotalAmount}</span>
        </div>
        <hr className='headingline'/>
        <div className="Addition_flex" >
            <span >EMI Start Date</span>
            <span className='Addition_label'>{ `${(new Date(value.EMIStartDate)).getDate()}/${(new Date(value.EMIStartDate)).getMonth() + 1}/${(new Date(value.EMIStartDate)).getFullYear().toString()}`  }</span>
        </div>
        <hr className='headingline'/>
        <div className="Addition_flex" >
            <span >Yearly Interest</span>
            <span className='Addition_label'>{value.YearlyInterest}</span>
        </div>
        <hr className='headingline'/>
        <div className="Addition_flex" >
            <span>Start Date</span>
            <span className='Addition_label'>{`${(new  Date(value.StartDate)).getDate()}/${(new  Date(value.StartDate)).getMonth() + 1}/${(new  Date(value.StartDate)).getFullYear().toString()}`}</span>
        </div>
</>}
       
      </div>
    </div>
  </div>
  <div className="col-sm-6">
    <div className="card" style={{minHeight:"100%"}}>
      <div className="card-body">
      <h4 className="card-title" style={{fontWeight:"bold",color:"#3F51B5"}}>Additional Charges</h4>
      {value && value.VASs && 
                        <>
                         <div className="Addition_flex1">
            <span>Name</span>
            <span >Amount</span>
        </div>
        <hr className='headingline'/>

        { value && value.VASs && value.VASs.map((val, index) => (
            <>
        <div className="Addition_flex" >
            <span >{val.Name}</span>
            <span className='Addition_label'>{val.Amount}</span>
        </div>
        <hr className='headingline'/>
         </>
                ))}            
                        </> 
                }
      </div>
    </div>
  </div>
</div>
        </div>

    )
}
export default AllCloudAdditionalInfo







