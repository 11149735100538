import React, { useState, useEffect, useRef } from "react";
import { branchService } from "../../../_services/branch.service";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { adminservice } from "../../../_services/admin.services";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notify } from "../../../_helpers/notify";

export default function AddBranchOwner({ history, match }) {
    const [submitButton, setSubmitButton] = useState(false);
    const [branches, setBranches] = useState([]);
    const { id } = match.params;
    const formikRef = useRef();

    const isAddMode = !id;

    useEffect(() => {
        branchService.getAll()
            .then((res) => {
                setBranches(res); // Assuming the API returns a list of branches
            })
            .catch((err) => console.log(err));
    }, []);

    const initialValues = {
        branch_id: "",
        name: "",
        account_no: "",
        ifsc: "",
        mobile: "",
        status: true, // Default to active
        kNo:'',
        Electricity_Distribution_Company:''
    };

    const validationSchema = Yup.object().shape({
        branch_id: Yup.string().required("Branch ID is required"),
        name: Yup.string().required("Name is required"),
        account_no: Yup.string().required("Account number is required"),
        ifsc: Yup.string().required("IFSC code is required"),
        mobile: Yup.string().matches(/^[0-9]{10}$/, "Must be a valid 10-digit mobile number").required("Mobile number is required"),
        status: Yup.boolean(),
    });

    async function onSubmit(values, { setSubmitting }) {
        setSubmitButton(true);
     console.log('values',values)
        if (isAddMode) {
            adminservice.createBranchOwner(values).then(() => {
                notify(`${values.name} Added Successfully`);
                setTimeout(() => {
                    history.push(".");
                }, 2000);
            }).catch((err) => {
                window.alert("Error creating branch owner");
                console.log(err);
                setSubmitButton(false);
            });
        } else {
            adminservice.updateBranchOwner({ id, value: values }).then(() => {
                notify(`${values.name} updated Successfully`);
                setTimeout(() => {
                    history.push("..");
                }, 2000);
            }).catch((err) => {
                window.alert("Error updating branch owner");
                console.log(err);
                setSubmitButton(false);
            });
        }
    }

    useEffect(() => {
        if (!isAddMode) {
            adminservice.getBranchOwnerById(id).then((owner) => {
                const fields = ["branch_id", "name", "account_no", "ifsc", "mobile", "status","kNo","Electricity_Distribution_Company"];
                fields.forEach((field) => {
                    if (formikRef.current) {
                        formikRef.current.setFieldValue(field, owner[field], false);
                    }
                });
            });
        }
    }, [id, isAddMode]);

    return (
        <div>
            <h2>{isAddMode ? "Add Branch Owner" : "Edit Branch Owner"}</h2>
            <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ errors, touched }) => (
                    <Form>
                        <ToastContainer />
                        <div className="form-row">
                            <div className="form-group col">
                                <label>Branch ID:</label>
                                <Field as="select" name="branch_id" className={`form-control ${errors.branch_id && touched.branch_id ? "is-invalid" : ""}`}>
                                    <option value="">Select Branch</option>
                                    {branches.map((branch) => (
                                        <option key={branch.branch_id} value={branch.branch_id}>
                                            {branch.branch_id}
                                        </option>
                                    ))}
                                </Field>
                                <ErrorMessage name="branch_id" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group col">
                                <label>Name:</label>
                                <Field name="name" type="text" className={`form-control ${errors.name && touched.name ? "is-invalid" : ""}`} />
                                <ErrorMessage name="name" component="div" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col">
                                <label>Account Number:</label>
                                <Field name="account_no" type="text" className={`form-control ${errors.account_no && touched.account_no ? "is-invalid" : ""}`} />
                                <ErrorMessage name="account_no" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group col">
                                <label>IFSC Code:</label>
                                <Field name="ifsc" type="text" className={`form-control ${errors.ifsc && touched.ifsc ? "is-invalid" : ""}`} />
                                <ErrorMessage name="ifsc" component="div" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col">
                                <label>Mobile:</label>
                                <Field name="mobile" type="number" className={`form-control ${errors.mobile && touched.mobile ? "is-invalid" : ""}`} />
                                <ErrorMessage name="mobile" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group col">
                                <label>Status:</label>
                                <Field as="select" name="status" className={`form-control ${errors.status && touched.status ? "is-invalid" : ""}`}>
                                    <option value={true}>Active</option>
                                    <option value={false}>Inactive</option>
                                </Field>
                                <ErrorMessage name="status" component="div" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col">
                                <label>K.No :</label>
                                <Field name="kNo" type="string" className={`form-control ${errors.kNo && touched.kNo ? "is-invalid" : ""}`} />
                                <ErrorMessage name="kNo" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group col">
  <label htmlFor="electricityDistributionCompany">Electricity Distribution Company:</label>
  <Field
    as="select"
    id="electricityDistributionCompany"
    name="Electricity_Distribution_Company"
    className={`form-control ${
      errors.Electricity_Distribution_Company && touched.Electricity_Distribution_Company
        ? "is-invalid"
        : ""
    }`}
  >
    <option value="">Select a company</option>
    <option value="Ajmer Vidyut Vitran Nigam Ltd">Ajmer Vidyut Vitran Nigam Ltd (AVVNL)</option>
    <option value="Jaipur Vidyut Vitran Nigam Ltd">Jaipur Vidyut Vitran Nigam Ltd (JVVNL)</option>
    <option value="Jodhpur Vidyut Vitran Nigam Ltd">Jodhpur Vidyut Vitran Nigam Ltd (JdVVNL)</option>
  </Field>
  <ErrorMessage name="Electricity_Distribution_Company" component="div" className="invalid-feedback" />
</div>

                        </div>
                       
                        <hr />
                        <button type="submit" className="btn btn-primary" disabled={submitButton}>
                            {isAddMode ? "Add Branch Owner" : "Update Branch Owner"}
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
