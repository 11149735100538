import { Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Table, Tbody, Td, Th, Tr } from 'react-super-responsive-table'
import { goldPacketServices } from '../../../_services/gold_packet.service';
import { CheckOutRequestModal } from './CheckOutRequestModal';
import { history } from '../../../_helpers';
import urlhelper from '../../../_helpers/urlhelper';
import {CheckoutApproveModal} from "./CheckoutApproveModal"
import roleChecker from '../../../_helpers/rolechecker';
import { employeeService } from '../../../_services';
export const CheckOutRequest = () => {
  const [packet, setPacket] = useState([])
  const [employeeList, setEmployeeList] = useState({})
  const user = employeeService.userValue;
  async function getAllEmployees() {
    await employeeService.getAll()
        .then((res) => {
            let list = {};
            res.map(e => {
                list[e.emp_id] = e.first_name + ' ' + e.last_name
            })
            setEmployeeList(list)
        })
}
  useEffect(() => {
    async function getAllPackets() {
      goldPacketServices.getAllPackets()
        .then((res) => setPacket(res))
    }
    getAllPackets()
    getAllEmployees()
  }, [])
  const cancelCheckoutRequest=(id)=>{
    goldPacketServices.cancelCheckoutRequest(id)
    .then((res)=>{
          alert("Check Out Request Cancelled")
          window.location.reload()
    })
    .catch((err)=>alert(err))
  }
  console.log("data", packet)
  return (
    <div>
      <div style={{ margin: "100px", background: 'white' }}>
        <div style={{ margin: '100px' }}>
          <br />
          <br />
          <h2 style={{ textAlign: "center" }}>Check Out</h2>
          <hr />
          <br />
           { roleChecker.isGoldRider(user.role)?<div style={{ float: 'right' }}>
            <CheckoutApproveModal packet={packet} />
          </div>:null}
          {roleChecker.isHOGoldChecker(user.role) || roleChecker.hasAccountsAccess(user.role)?<div style={{ float: "left", marginRight: '40px' }}>
            <CheckOutRequestModal packet={packet} />
          </div>:null}
          <br />
          <br />
          <br/>
          <hr />
          <br />
          <Table>
            <thead>
              <Tr>
                <Th>S.N</Th>
                <Th>Name</Th>
                <Th>Loan No</Th>
                <Th>Packet Number</Th>
                <Th>Image</Th>
                <Th>Check Out Requested By </Th>
                <Th>Check Out Status</Th>
                <Th>Cancel Request</Th>
              </Tr>
            </thead>
            <Tbody>
              {
                packet.length > 0 ?
                  packet.filter(checkOut => checkOut.packet_state === "Out").map((x, i) => <Tr key={i}>
                    <Td>{i + 1}</Td>
                    <Td>{x.name}</Td>
                    <Td>{x.loan_no}</Td>
                    <Td>{x.packet_no}</Td>
                    <Td><Button style={{ color: "blue" }}
                      onClick={() => history.push(`/home/pdfviewer?pdf_url=` + urlhelper.encodeToBase64(x.photo) + "&download=1")}>
                      View
                    </Button></Td>
                    <Td>{employeeList[x.checkout_requested_by]}</Td>
                    <Td>Pending</Td>
                   {user.emp_id===x.checkout_requested_by && <Td><Button onClick={()=>cancelCheckoutRequest(x._id)} style={{color:'white',background:'red'}}>Cancel</Button></Td>}
                  </Tr>)
                  : <Tr>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td style={{ color: "red" }}><b>Data not found</b></Td>
                    <Td></Td>
                    <Td></Td>
                  </Tr>
              }
            </Tbody>
          </Table>
          <br />
          <br />
        </div>
      </div>
    </div>
  )
}
