import { fetchWrapper} from "../_helpers";

const apiUrl = process.env.REACT_APP_API_URL;

const baseUrl = `${apiUrl}/adminservice`;


function  getCityDetails() {
    return fetchWrapper.get(`${baseUrl}/getcitydetails`);
  }
  function create(params) {
    // console.log('params is',params)
    return fetchWrapper.post(`${baseUrl}/add`, params)
  }
  function getByIdCity(branch_id) {
    return fetchWrapper.get(`${baseUrl}/${branch_id}`);
  }
  function createProvider(params) {
     console.log('params is',params)
    return fetchWrapper.post(`${baseUrl}/addprovider`, params)
  }
  function  getProviderDetails() {
    return fetchWrapper.get(`${baseUrl}/getproviderdetails`);
  }
  function  getProviderById(id) {
    return fetchWrapper.get(`${baseUrl}/getproviderbyid/${id}`);
  }
  function  updateProvider( params) {
    return fetchWrapper.post(`${baseUrl}/updateprovider`,params);
  }
  function update(cityId,cityObj) {
    return fetchWrapper.put(`${baseUrl}/${cityId}`,cityObj);
  }
  function adminsettings(params) {
    return fetchWrapper.post(`${baseUrl}/adminsettings`,params);
  }
  function pairKeys(params) {
    return fetchWrapper.post(`${baseUrl}/pairkeys`,params);
  }
  function getKeys() {
    return fetchWrapper.get(`${baseUrl}/allkeys`);
  }
  function getAllKeyPair() {
    return fetchWrapper.get(`${baseUrl}/allkeypair`);
  }
  function deleteKeyPair(id) {
    return fetchWrapper.post(`${baseUrl}/pairkeys/${id}`);
  }
  function getNegativePinCode() {
    return fetchWrapper.get(`${baseUrl}/getnegativepincode`);
  }
  function getPairByKey(params) {
    return fetchWrapper.post(`${baseUrl}/getpairbykey`,params);
  }
  function createBranchOwner(params) {
    console.log('params is',params)
   return fetchWrapper.post(`${baseUrl}/addbranchowner`, params)
 }
 function  getBranchOwnerDetails() {
   return fetchWrapper.get(`${baseUrl}/getbranchownerdetails`);
 }
 function  getBranchOwnerByBranchId() {
  return fetchWrapper.get(`${baseUrl}/getbranchownerbybranchid`);
}
 function  getBranchOwnerById(id) {
   return fetchWrapper.get(`${baseUrl}/getbranchownerbyid/${id}`);
 }
 function  updateBranchOwner( params) {
   return fetchWrapper.post(`${baseUrl}/updatebranchowner`,params);
 }
 function  createCategory( params) {
  return fetchWrapper.post(`${baseUrl}/createcategory`,params);
}
function  getAllCategory( ) {
  return fetchWrapper.get(`${baseUrl}/getallcategory`);
}
function  getCategoryById( ) {
  return fetchWrapper.get(`${baseUrl}/getcategorybyid`);
}
function  getAllCities( ) {
  return fetchWrapper.get(`${baseUrl}/getallcities`);
}

  export const adminservice = {
    getCityDetails,
    create,
    getProviderDetails,
    createProvider,
    getByIdCity,
    update,
    adminsettings,
    getKeys,
    pairKeys,
    getAllKeyPair,
    deleteKeyPair,
    getNegativePinCode,
    getPairByKey,
    getProviderById,
    updateProvider,
    createBranchOwner,
    getBranchOwnerDetails,
    getBranchOwnerById,
    updateBranchOwner,
    getBranchOwnerByBranchId,
    createCategory,
    getAllCategory,
    getCategoryById,
    getAllCities
    }
