import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  Button,
  CardActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  TableContainer,
  TablePagination,
  Paper,Typography, List, ListItem, ListItemText 
} from "@material-ui/core";
import { Edit, Add } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { loanService } from "../../../_services/loan.service";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { employeeService } from "../../../_services";
import roleChecker from "../../../_helpers/rolechecker";
import { Role } from "../../../_helpers";
import { Input } from "antd";
import EditStageModal from "../Applications/EditStageModal";
import datehelper from "../../../_helpers/datehelper";
import LetterList from "../Letters/SBLLetterList";
import displayhelper from "../../../_helpers/displayhelper";
import { LoanStages } from "../../../constants/loanstages";
import ValuationAssignment from "./ValuationAssignment";
import LegalAssignment from "./LegalAssignment";
import RcuAssignment from "./RCUAssignment";
import urlhelper from  '../../../_helpers/urlhelper'
import VendorValuationReport from "./VendorValuationReport"
import VendorRcuReport from "./VendorRcuReport"
import VendorLegalReport from "./VendorLegalReport"
 
const { Search } = Input;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexGrow: 1,
  },
  card: {
    minHeight: 250,
    minWidth: 250,
  },
}));

export default function VendorApplication({ match }) {
  const { path } = match;
  const classes = useStyles();
  const {id} = match.params
// console.log('id is ',id)
  const user = employeeService.userValue;
  const [applicants, setApplicants] = useState(null);
  const [spinner, setSpinner] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [propertPapers,setPropertyPapers] = useState([])
  
   
  useEffect(() => {
    getApplicants();
  }, []);

  async function getApplicants() {
    setSpinner(true);
    const cust = await loanService.vendorApplicantData({id:id});
    // console.log("vendor data is", cust);
    setApplicants(cust);
    if(cust && cust.length>0 && cust[0].applicantdata && cust[0].applicantdata.length>0 && cust[0].applicantdata[0].property_details && cust[0].applicantdata[0].property_details.document_list  ){
           const propertyPapers = cust[0].applicantdata[0].property_details.document_list.filter((val,ind)=>(
               val.document_type =="Property Paper"             
           ))
           setPropertyPapers(propertyPapers)
    }
    setSpinner(false);
  }

// console.log(' propertPapers ',propertPapers)

  return (
    <div>
      <h1>Applicant Data</h1>
      <Paper style={{ borderRadius: '10px', margin: '3px', padding: '30px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}>
    
      <div>
        <Typography variant="body1" style={{ marginBottom: '10px' }}>
          <strong>Application ID:</strong> {applicants?.[0]?.applicantdata?.[0]?.application_id}
        </Typography>
        <Typography variant="body1" style={{ marginBottom: '10px' }}>
          <strong>Customer Name:</strong> {applicants?.[0]?.applicantdata?.[0]?.applicant?.general_information?.name}
        </Typography>

        <Typography variant="body1" style={{ marginBottom: '10px' }}>
          <strong>Property Papers:</strong>
        </Typography>
        <Table>
          <Thead>
            <Tr>
              <Th>Document Type</Th>
              <Th>Original Type</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {propertPapers && propertPapers.map((paper,ind) => (
              <Tr key={paper._id}>
                <Td>{`${paper.document_type} - ${ind+1}`}</Td>
                <Td>{paper.original_type}</Td>
                <Td>
                  <a href={paper.document_url} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>
                    View Document
                  </a>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
    </Paper>

      <br />
      {applicants && applicants.length>0 && applicants[0].type=="Valuation" && <VendorValuationReport val={applicants[0]} /> }
      {applicants && applicants.length>0 && applicants[0].type=="RCU" && <VendorRcuReport val={applicants[0]} /> }
      {applicants && applicants.length>0 && applicants[0].type=="Legal" && <VendorLegalReport val={applicants[0]} /> }
      
     </div>
  );
}
