import React from "react";
import { useState, useEffect } from "react";
import roleChecker from "../_helpers/rolechecker";
import { employeeService } from "../_services";
import Product from "../_helpers/product";
import { loanService } from "../_services";
import { Button } from "@material-ui/core";
import { history } from "../_helpers";
import { Paper } from "@material-ui/core";
import { makerCheckerServices } from "../_services/makerchecker.service";
import GoldDashboard from "./ProductDashboard/GoldDashboard";
import SBLDashboard from "./ProductDashboard/SBLDashboard";
import LegalDashboard from "./ProductDashboard/LegalDashboard";
import CommonDashboard from "./ProductDashboard/CommonDashboard";
import ColendingDashboard from "./CoLending/ColendingDashboard";
function DashboardTab() {
  const user = employeeService.userValue;
  const [check, setCheck] = useState(true)
  let date = new Date()
  const checkDate = () => {
    var checktrue = false;
    employeeService.checkDate({ date: date })
      .then((data) => {
        // console.log(data);
        employeeService.apistate = true;
        if (data === false) {
          setCheck(false);
        }
      })
      .catch((err) => {
        employeeService.apistate = false;
        history.push("/home/error");
        console.log("Error!!!", err);
      });
  }
  useEffect(() => {
    checkDate()
    // console.log('hhii')
  }, [user])
  return (
    <div className="p-4">
      {check === false ? <div>
        {
          window.alert("System Date does not match with the current date", employeeService.logout())

        }
      </div> :
        <div className="container">
        <CommonDashboard />
          {roleChecker.isGMLevelAccess(user.role)?<><h1>SBL</h1><SBLDashboard/> </> :(employeeService.productValue == Product.SBL) && <SBLDashboard />}
          {employeeService.productValue == Product.GoldLoan && <GoldDashboard />}
          {(employeeService.productValue == Product.Legal) && <LegalDashboard />}
        </div>
      }

    </div>
  );
}

export { DashboardTab };