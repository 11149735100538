import { fetchWrapper } from "../_helpers";

const apiUrl = process.env.REACT_APP_API_URL;

const baseUrl = `${apiUrl}/reports`;

export const reportsService = {
  create,
  getAll,
  getChartsData,
  getMetricsData,
  // getMetricsZoneNameData, 
  getRealMetricsData,
};

function create(params) {
  return fetchWrapper.post(baseUrl, params);
}

function getAll(id)
{
  return fetchWrapper.get(baseUrl);
}

async function getChartsData(params) {
  return fetchWrapper.post(baseUrl+"/charts", params);
}

async function getMetricsData(params) {
  return fetchWrapper.post(baseUrl+"/metrics", params);
}
// async function getMetricsZoneNameData(params) {
//   return fetchWrapper.post(baseUrl+"/metricszonename", params);
// }

// new update
async function getRealMetricsData(params) {
  return fetchWrapper.post(baseUrl+"/realmetrics", params);
}



