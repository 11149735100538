import { Paper } from "@material-ui/core";
import React from "react";
import {
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  Button,
  Grid,
  makeStyles,
} from "@material-ui/core";

import LocationOnIcon from '@material-ui/icons/LocationOn';
import ImageIcon from '@material-ui/icons/Image';
import { employeeService } from "../../_services";
import { fieldVisitService } from "../../_services/fieldvisit.service";
import datehelper from "../../_helpers/datehelper";
import { history } from "../../_helpers";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 500,
  },
  media: {
    width: 100,
    height: 100,
  },
  root: {
    display: "flex",
    flexGrow: 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: "11%",
  },
  drawerContainer: {
    overflow: "auto",
  },
  // necessary for content to be below app bar
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

export default function FieldVisitList() {
  const classes = useStyles();
  const [fieldVisits, setFieldVisits] = React.useState([]);
  const [spinner, setSpinner] = React.useState(true);
  const [searchValue, setSearchValue] = React.useState({})
  const [selectedDate, setSelectedDate] = React.useState('')
  const [selectedendDate, setSelectedendDate] = React.useState('')

  const user = employeeService.userValue;
  React.useEffect(() => {
    async function retrieveData() {
      await fieldVisitService.getAll().then((x) => {
        console.log(x);
        setFieldVisits(x);
        setSpinner(false)
      });
    }
    retrieveData();
  }, []);

  const handleSearch = (fieldKey, fieldValue) => {
    searchValue[fieldKey] = fieldValue
    setSearchValue(searchValue)
  }

  const searchFieldVisits = () => {
    setSpinner(true)
    fieldVisitService.search({ "search_data": {...searchValue, date:selectedDate, enddate:selectedendDate}})
      .then(res => {
        setFieldVisits(res.reverse())
        console.log(res)
        setSpinner(false)
      })
      .catch((err) => {
        console.log(err)
        setSpinner(false)
      })
  }

  const handleEnterKey = (e) => {
    console.log("handle key ");
    if (e.key === "Enter") {
      searchFieldVisits();
    }
  }

  const dateFormat = (value) => {
    let max_date = new Date(value)
    let dd = ("00" + max_date.getDate()).slice(-2)
    let mm = ("00" + (max_date.getMonth() + 1)).slice(-2);
    let yy = max_date.getFullYear();
    max_date = yy + "-" + mm + "-" + dd;
    return max_date
  }

  const mapView = () => {
    history.push('/home/mapview',fieldVisits)
  }


  function getImageDiv(fmg) {
    return (
      <Grid key={fmg.uri} item>
        {/* <Card className={classes.card}> */}
          {/* <CardActionArea> */}
            {/* <CardMedia className={classes.media} image={fmg.uri} /> */}
          {/* </CardActionArea> */}
          {/* <CardActions> */}
            {/* <Button
              component="a"
              target="_blank"
              href={`https://maps.google.com/?q=${fmg.location.lat},${fmg.location.long}`}
              size="small"
              color="primary"
              style={{fontSize:"13px"}}
            >
               Location <LocationOnIcon/>
            </Button> */}
            <Button
              component="a"
              target="_blank"
              href={fmg.uri}
              size="small"
              color="primary"
              style={{fontSize:"12px",padding:"1rem"}}
            >
            <ImageIcon/>
            </Button>
          {/* </CardActions> */}
        {/* </Card> */}
      </Grid>
    )
  }

  function getlocationDiv(fmg) {
    return (
      <Grid key={fmg.uri} item>
        {/* <Card className={classes.card}> */}
          {/* <CardActionArea> */}
            {/* <CardMedia className={classes.media} image={fmg.uri} /> */}
          {/* </CardActionArea> */}
          {/* <CardActions> */}
            <Button
              component="a"
              target="_blank"
              href={`https://maps.google.com/?q=${fmg.location.lat},${fmg.location.long}`}
              size="small"
              color="primary"
              style={{fontSize:"12px",padding:"1rem"}}
            >
           <LocationOnIcon/>
            </Button>
            {/* <Button
              component="a"
              target="_blank"
              href={fmg.uri}
              size="small"
              color="primary"
              style={{fontSize:"13px"}}
            >
              View Image
            </Button> */}
          {/* </CardActions> */}
        {/* </Card> */}
      </Grid>
    )
  }
  console.log("fieldvisits",fieldVisits)
  return (
    <Paper
      style={{
        padding: 20,
      }}
    >

      <div className="d-flex" style={{ padding: '2vh', display: "flex", justifyContent: "space-evenly" }}>
        <input style={{ height: '30px', borderRadius: '5px' }} name='application_id' onChange={(e) => handleSearch(e.target.name, e.target.value.toUpperCase())} placeholder='Loan Number' type="text" onKeyPress={(e) => handleEnterKey(e)} />
        <label>Start Date <input type="date" onChange={(e) => setSelectedDate(e.target.value)} value={dateFormat(selectedDate)} /></label>
        <label>End Date <input type="date" onChange={(e) => setSelectedendDate(e.target.value)} value={dateFormat(selectedendDate)} /></label>

        <Button variant="contained" style={{ height: '30px', marginLeft: "30px" }} onClick={searchFieldVisits} color="primary">Search</Button>
        <Button variant="contained" style={{ height: '30px', marginLeft: "30px" }} color="primary" onClick={() => mapView()} >
          Map View
        </Button>
      </div>
      <br>
      </br>
      <table className="table table-striped table-bordered" > 
        <thead>
          <tr>
            <th >Employee</th>
            <th >Branch</th>
            <th >Loan Number</th>
            <th >Visit Date</th>
            <th >Payment Collected</th>
            <th >Next Visit</th>
            <th >Visit Comments</th>
            <th >Property Visited ?</th>
            <th >Selfie</th>
            <th >Visit location</th>
            <th >Visit Proof</th>
          </tr>
        </thead>
        <tbody>
          {fieldVisits &&
            fieldVisits.map((fvi, idx) => (
              <tr key={fvi._id}>
                <td style={{padding:"5px",textAlign:"center"}}>{fvi.employee.length > 0 && (fvi.employee[0].first_name + " " + fvi.employee[0].last_name)}</td>
                <td style={{padding:"5px",textAlign:"center"}}>{fvi.employee.length > 0 && fvi.employee[0].branch_id}</td>
                <td style={{padding:"5px",textAlign:"center"}}>{fvi.application_id}</td>
                <td style={{padding:"5px",textAlign:"center"}}>{datehelper.displayDateTime(new Date(fvi.visit_date))}</td>
                <td style={{padding:"5px",textAlign:"center"}}>{fvi.payment_collect_status}</td>
                <td style={{padding:"5px",textAlign:"center"}}>{fvi.payment_collect_status == "No" && (
                  <span>{datehelper.displayDate(new Date(fvi.followup_date))}</span>
                )}</td>

                <td style={{padding:"5px",textAlign:"center"}}><span style={{display:"flex" ,justifyContent:"center"}}>{fvi.visit_comments}</span>
                 <span style={{fontWeight:"bold"}}>
                {fvi.selected_comment}
                </span></td>
                <td style={{padding:"0px",textAlign:"center"}}>{fvi.distance_visit===undefined?"":fvi.distance_visit==="NaN"?"Property Lat and long doesn't exist":fvi.distance_visit > 100 ?<p style={{color:"red",fontWeight:"bold"}}>NO ({fvi.distance_visit}) m</p>:<p style={{color:"green",fontWeight:"bold"}}>Yes ({fvi.distance_visit}) m</p>}</td>
                {/* <td>{fvi.distance_visit}</td> */}
                <td style={{padding:"0px",textAlign:"center"}}>
                  {fvi.visit_images && fvi.visit_images.map((fmg) => (
                    <>
                      {fmg.ref_id && fmg.ref_id == "employee_selfie" && getImageDiv(fmg)}
                    </>
                  )

                  )}

                </td>
                <td  style={{padding:"0px",textAlign:"center"}}>{fvi.visit_images && fvi.visit_images.map((fmg) => (
                  <>
                    {fmg.ref_id && fmg.ref_id == "visit_proof" && getlocationDiv(fmg)}
                  </>
                )

                )}
                </td>
                <td  style={{padding:"0px",textAlign:"center"}}>{fvi.visit_images && fvi.visit_images.map((fmg) => (
                  <>
                    {fmg.ref_id && fmg.ref_id == "visit_proof" && getImageDiv(fmg)}
                  </>
                )
                )}
                </td>
              </tr>
            ))}
            {
              fieldVisits.length==0 && ( <tr>
              <td colSpan="9" className="text-center"  style={{padding:"0px",textAlign:"center"}}>
                 No Field Visits 
              </td>
            </tr>)
            }
          {spinner && (
            <tr>
             
              <td colSpan="9" className="text-center"  style={{padding:"0px"}}>
                <span className="spinner-border spinner-border-lg align-center"></span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Paper>
  );
}
