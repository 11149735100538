import React from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import FormElement from "../../../uielements/FormElement";
import CustomerDetailModal from "../../../Payments/IciciEnach/CustomerDetailModal";
import { employeeService, loanService } from "../../../../_services";
import { Button } from "@material-ui/core";
import { virtualAccountService } from "../../../../_services/virtualaccount.service";
import { icicienachService } from "../../../../_services/icicienach.service"
import {enachService} from "../../../../_services/enach.service"
import roleChecker from '../../../../_helpers/rolechecker';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  withStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { Link } from "@material-ui/core";
import datehelper from "../../../../_helpers/datehelper";
import AddApplicationFeesModal from "./AddApplicationFeesModal";
import AddPaymentDetails from "./AddPaymentDetails";
import AddEmdFeesModal from "./AddEmdFeesModal";
import EnachCollectRequestModal from "../../../Payments/EnachCollect/EnachCollectRequestModal"
import AddLogInFeesModal from "./AddLogInFeesModal";
import AddEnachModal from "../../../Payments/Enach/AddEnachModal"

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(key, label, value, editable, type) {
  return { key, label, value, editable, type };
}

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const resendAuEnach=(refCode)=> {
  enachService.resend(refCode)
  .then((enachitem)=> {
    // Create a new window or tab
    console.log(enachitem)
    if( enachitem.status === 'Success'){
      const newWindow = window.open('', '_blank');
      const htmlContent = enachitem.success_html
      if (newWindow) {
        newWindow.document.write(htmlContent);
        newWindow.document.close(); //Close the document to finish writing
      } else {
        window.alert('Popup blocked. Please allow popups for this website.');
      }
    } else {
      window.alert("Enach Request creared with id "+enachitem.reference_code);
    }
    window.location.reload()
   })
  .catch((err)=> {
    console.log(err);
    window.alert("error resending Enach request");
  })
}


export default function PaymentData({ customer }) {
  console.log("customerr",customer)
  const classes = useStyles();
  const formikRef = React.useRef();
  const [editing, setEditing] = React.useState(false);
  const [va_details, setVADetails] = React.useState(null);
  const [data, setData] = React.useState([])
  const [auData,setAuData]= React.useState([])
  const user= employeeService.userValue;

  React.useEffect(() => {

    let fields = { 'loan_account': customer.application_id }
    virtualAccountService.getByLoanAccount(fields).then((va) => {
       console.log("va",va);
      setVADetails(va);
    })
      .catch((err) => {
        console.log(err);
      })

    if (customer.applicant_fees) {
      const fields = [
        'application_fees'
      ];
      fields.forEach((field) => {
        if (formikRef.current) {
          formikRef.current.setFieldValue(field, customer[field], false);
        }
      });
    }

  }, []);

  React.useEffect(() => {
    let field = { loan_account: customer.application_id }
    icicienachService.getEnach(field)
      .then((x) => {
        console.log("x",x)
        setData(x)
      })
      .catch((err) => { console.log(err) })
  }, [])
  React.useEffect(() => {
    let field = { loan_account: customer.application_id }
    icicienachService.getEnachByAU(field)
      .then((x) => {
        console.log("x",x)
        setAuData(x)
      })
      .catch((err) => { console.log(err) })
  }, [])
  console.log("data setAuData",data,auData)

  async function createQRCode() {
    let fields = {
      'loan_account': customer.application_id + "",
      'customer_name': customer.applicant.general_information.name,
      'customer_mobile': customer.applicant.general_information.contact + ""
    }
    console.log(fields);
    virtualAccountService.create(fields).then((va) => {
      console.log("va data")
      console.log(va);
      setVADetails(va);
    })
      .catch((err) => {
        console.log(err);
      })

  }


  const initialValues = {
    'application_fees': {
      'payment_method': "",
      "payment_amount": "",
      "payment_reference": ""
    },
  };


  const validationSchema = Yup.object().shape({
    property_details: Yup.object().shape({
      type: Yup.string().required("This field is required"),
      area: Yup.number().required("This field is required"),
      owner: Yup.string().required("This field is required"),
      valuation: Yup.number().required("This field is required"),

    }),
  });

  async function onSubmit(fields) {
    console.log("in submit function");
    console.log(fields);
    console.log("submitting form");
    await loanService.update(customer._id, fields).then((applicant) => {
      window.alert("Loan File Updated" + applicant.application_id);
      window.location.reload(false);
    })
      .catch((err) => {
        window.alert("error updating Loan Application");
        console.log("error" + err)
      })
  }

  const copyDetails = () => {
    console.log("here")
    if(customer && customer.loan && customer.loan.bt_loan_no){
    loanService.copyEmiDetails({old_loan:customer.loan.bt_loan_no, new_loan: customer.application_id})
    .then((data)=>{
      console.log(data)
      alert("Added Successfully")
      window.location.reload();
    })
    .catch((err)=>{
      console.log(err)
    })
    }else{
      alert("No BT Loan Number Present")
    }
  }

  const resendEnach=(refCode)=> {
    icicienachService.resend(refCode)
    .then((result)=> {
      console.log("got resend result");
     // console.log(result);
      const encodeditem = btoa(JSON.stringify(result));
      window.open('https://s3.ap-south-1.amazonaws.com/download.kaabilfinance.com/htmls/enachload.html?'+`${encodeditem}`);
      //console.log('http://localhost:3001/icicienach.html?'+`${encodeditem}`);
      // window.alert("resend successful");
      //window.location.reload();
    })
    .catch((err)=> {
      console.log(err);
      window.alert("error resending Enach request");
    })
  }

  async function saveEdits(fields) {
    setEditing(false);
    onSubmit(fields);
  }

const handleInactive=(id)=>{
  console.log(id)

 icicienachService.handleInActive(id)
    .then((result)=> {
      console.log("got resend result",result);
     window.location.reload()
      
    })
    .catch((err)=> {
      console.log(err);
      window.alert("error resending Enach request");
    })
}

  const rows = [];
  return (
    <div>
      <h2>Login Fees</h2>
      <table style={{ width: "100%", border:"1px solid",padding:"10px" }}><th>Payment Date</th><th>Payment Amount</th><th>Payment Mode</th><th>Payment Comment</th><th>Receipt Ref Id</th>
        {customer.application_fees && customer.application_fees.payment_amount > 0 && (
          <tr><td>{datehelper.displayDate(new Date(customer.application_fees.payment_date))}</td><td>{customer.application_fees.payment_amount}</td>
            <td>{customer.application_fees.payment_mode}</td>
            <td>{customer.application_fees.payment_comment}</td>
            <td>{customer.application_fees.payment_ref_id}</td></tr>
        )}
      </table>
      <div style={{display:'flex'}}>
      {(customer.application_fees.payment_amount < 2500 ) && (
        <AddApplicationFeesModal customer={customer} />
      )} 
     {roleChecker.hasAccountsAccess(user.role) && <AddLogInFeesModal customer={customer}/>}
      </div>
      <br/><br/>
      <h2>IMD Fees</h2>  
      <table style={{ width: "100%", border:"1px solid",padding:"10px" }}><th>Payment Date</th><th>Payment Amount</th><th>Payment Mode</th><th>Payment Comment</th><th>Receipt Ref Id</th>
        {customer.emd_fees && customer.emd_fees.payment_amount > 0 && (
          <tr><td>{datehelper.displayDate(new Date(customer.emd_fees.payment_date))}</td><td>{customer.emd_fees.payment_amount}</td>
            <td>{customer.emd_fees.payment_mode}</td>
            <td>{customer.emd_fees.payment_comment}</td>
            <td>{customer.emd_fees.payment_ref_id}</td>
            </tr>
        )}
      </table>
      {(!customer.emd_fees || customer.emd_fees.payment_amount == 0) && (
        <AddEmdFeesModal customer={customer} />
      )} 
      <br/><br/>
      <h2>EMI Collection Details</h2>
      <table style={{ width: "100%", border:"1px solid" }}>
        <th style={{border:"1px solid", padding:"5px"}}>Collection Mode</th>
        <th style={{border:"1px solid", padding:"5px"}}>Unique Ref No</th>
        <th style={{border:"1px solid", padding:"5px"}}>URMN No</th>
        <th style={{border:"1px solid", padding:"5px"}}>Emi Amount</th>
        <th style={{border:"1px solid", padding:"5px"}}>Emi Date</th>
        <th style={{border:"1px solid", padding:"5px"}}>Bank Customer Name</th>
        <th style={{border:"1px solid", padding:"5px"}}>Bank Name</th>
        <th style={{border:"1px solid", padding:"5px"}}>Bank Account Num</th>
        <th style={{border:"1px solid", padding:"5px"}}>IFSC Code</th>
        {customer.collection_data && (
          <tr style={{  border:"1px solid",padding:"10px" }}>
            <td style={{border:"1px solid", padding:"5px"}}>{customer.collection_data.collection_mode}</td>
            <td style={{border:"1px solid", padding:"5px"}}>{customer.collection_data.unique_ref_no}</td>
            <td style={{border:"1px solid", padding:"5px"}}>{customer.collection_data.urmn_no} <br/> <EnachCollectRequestModal code={customer && customer.collection_data && customer.collection_data.urmn_no && customer.collection_data.urmn_no.split("CODE")[0].split("KB")[1]}  /> </td>
            <td style={{border:"1px solid", padding:"5px"}}>{customer.collection_data.emi_amount}</td>
            <td style={{border:"1px solid", padding:"5px"}}>{customer.collection_data.emi_day}</td>
            <td style={{border:"1px solid", padding:"5px"}}>{customer.collection_data.bank_customer_name}</td>
            <td style={{border:"1px solid", padding:"5px"}}>{customer.collection_data.bank_name}</td>
            <td style={{border:"1px solid", padding:"5px"}}>{customer.collection_data.bank_account_num}</td>
            <td style={{border:"1px solid", padding:"5px"}}>{customer.collection_data.bank_ifsc_code}</td>
            </tr>
        )}
      </table><br/><br/>
      { roleChecker.isGMLevelAccess(user.role) && (<AddPaymentDetails customer={customer} data={data}/>)}<br/><br/>
       
      {customer && customer.loan && customer.loan.bt_type && customer.loan.bt_type === "Internal" &&  (!customer.collection_data || customer.collection_data === null) && (
         <Button style={{backgroundColor:"orange", color:"white"}} onClick={()=> copyDetails()} >Copy Data</Button>
      )}
      
      <br /><br />
      <h2>ICICI Enach Details</h2> 
      <table>
        <tr>
          <th style={{  padding:"5px", border:"1px solid"}} >Loan Number</th>
          <th style={{  padding:"5px", border:"1px solid"}}>Amount</th>
          <th style={{  border:"1px solid", padding:"5px"}}> Date</th>
          <th style={{  border:"1px solid", padding:"5px"}}> Enach Status</th>
          <th style={{  border:"1px solid", padding:"5px"}}> Customer</th>
          <th style={{  border:"1px solid", padding:"5px"}}>Bank Name</th>
          <th style={{  border:"1px solid", padding:"5px"}}>EMI Start</th>
          <th style={{  border:"1px solid", padding:"5px"}}>EMI End</th>
          <th style={{  border:"1px solid", padding:"5px"}}>Delete</th>

        </tr>
        {data && data.map((e) => {
          return ( e && e.enach_status && e.enach_status!="InActive" && <tr>
            <td style={{border:"1px solid", padding:"5px"}}>{e.loan_number}</td>
            <td style={{border:"1px solid", padding:"5px"}}>{e.emi_amount}</td>
            <td style={{border:"1px solid", padding:"5px"}}>{datehelper.displayDate(new Date(e.updated))}</td>
            <td style={{border:"1px solid", padding:"5px"}}><br />
              {e.enach_status == 'Active' && (
                <p style={{ color: "green" }}>Active</p>


              )}

              {e.enach_status == 'Initiated' && (
                <p style={{ color: "blue" }}>Initiated</p>
              )}
              {e.enach_status == 'Failed' && (
                <p style={{ color: "red" }}>Failed</p>
              )}
              {e.enach_status == 'Pending' && (
                <p>Pending</p>
              )}
              {
                ((e.enach_status == 'Pending' || e.enach_status == 'Failed' || e.enach_status == 'Initiated') && (
                  <p>{e.enach_form_id}<br />
                    {e.reference_code}</p>
                ))
              }
              {
                (e.enach_status == 'Failed' && e.enach_status_comment && (
                  <p>Fail Reason Msg: {e.enach_status_comment}<br />
                  </p>
                ))
              }

              {
                (e.enach_status == 'Active' && (
                  <>
                    <p>Request Id: {e.reference_code}</p><br />
                    <p>Bank Ref: {e.mandate_reference}</p>
                    <br />
                    <p>Mandate Token: {e.mandate_token}</p>
                  </>
                ))
              }
              {
                (e.enach_status == 'Initiated' && (
                  <>
                    <p>Request Id: {e.reference_code}</p><br />
                    <p>Mandate Token: {e.mandate_token}</p>
                  </>
                ))
              }
              {
                ((e.enach_status == 'Pending' || e.enach_status == 'Failed') && (
                  <Link onClick={() => resendEnach(e.reference_code)}>Request Again</Link>
                ))
              }
            </td>
            <td style={{border:"1px solid", padding:"5px"}}>{e.name}<br />{e.contact}</td>
            <td style={{border:"1px solid", padding:"5px"}}>{e.bank_name}</td>
            <td style={{border:"1px solid", padding:"5px"}}>{datehelper.displayDate(new Date(e.start_date))}</td>
            <td style={{border:"1px solid", padding:"5px"}}>{datehelper.displayDate(new Date(e.end_date))}</td>
           { roleChecker.isGMLevelAccess(user.role) && <td style={{border:"1px solid", padding:"5px"}}>
  <button onClick={() => {
    if (window.confirm("Are you sure you want to Inactivate this Enach status")) {
      handleInactive(e._id);
    }
  }}>
    <DeleteIcon/>
  </button>
</td> }
          </tr>)
        })}
      </table> <br /> 
      <CustomerDetailModal customer={customer} /><br />
<br/>
      <h2>AU Enach Details</h2> 
      <table>
        <tr>
          <th style={{  padding:"5px", border:"1px solid"}} >Loan Number</th>
          <th style={{  padding:"5px", border:"1px solid"}}>Amount</th>
          <th style={{  border:"1px solid", padding:"5px"}}> Date</th>
          <th style={{  border:"1px solid", padding:"5px"}}> Enach Status</th>
          <th style={{  border:"1px solid", padding:"5px"}}> Customer</th>
          <th style={{  border:"1px solid", padding:"5px"}}>Bank Name</th>
          <th style={{  border:"1px solid", padding:"5px"}}>EMI Start</th>
          <th style={{  border:"1px solid", padding:"5px"}}>EMI End</th>
          {/* <th style={{  border:"1px solid", padding:"5px"}}>Delete</th> */}

        </tr>
        {auData && auData.map((e) => {
          return ( e && e.enach_status && e.enach_status!="InActive" && <tr>
            <td style={{border:"1px solid", padding:"5px"}}>{e.loan_number}</td>
            <td style={{border:"1px solid", padding:"5px"}}>{e.emi_amount}</td>
            <td style={{border:"1px solid", padding:"5px"}}>{datehelper.displayDate(new Date(e.updated))}</td>
            <td style={{border:"1px solid", padding:"5px"}}><br />
              {e.enach_status == 'Active' && (
                <p style={{ color: "green" }}>Active</p>


              )}

              {e.enach_status == 'Initiated' && (
                <p style={{ color: "blue" }}>Initiated</p>
              )}
              {e.enach_status == 'Failed' && (
                <p style={{ color: "red" }}>Failed</p>
              )}
              {e.enach_status == 'Pending' && (
                <p>Pending</p>
              )}
              {
                ((e.enach_status == 'Pending' || e.enach_status == 'Failed' || e.enach_status == 'Initiated') && (
                  <p>{e.enach_form_id}<br />
                    {e.reference_code}</p>
                ))
              }
              {
                (e.enach_status == 'Failed' && e.enach_status_comment && (
                  <p>Fail Reason Msg: {e.enach_status_comment}<br />
                  </p>
                ))
              }

              {
                (e.enach_status == 'Active' && (
                  <>
                    <p>Request Id: {e.reference_code}</p><br />
                    <p>Bank Ref: {e.mandate_reference}</p>
                    <br />
                    <p>Mandate Token: {e.mandate_token}</p>
                  </>
                ))
              }
              {
                (e.enach_status == 'Initiated' && (
                  <>
                    <p>Request Id: {e.reference_code}</p><br />
                    <p>Mandate Token: {e.mandate_token}</p>
                  </>
                ))
              }
              {
                ((e.enach_status == 'Pending' || e.enach_status == 'Failed') && (
                  <Link onClick={() => resendAuEnach(e.reference_code)}>Request Again</Link>
                ))
              }
            </td>
            <td style={{border:"1px solid", padding:"5px"}}>{e.name}<br />{e.contact}</td>
            <td style={{border:"1px solid", padding:"5px"}}>{e.bank_name}</td>
            <td style={{border:"1px solid", padding:"5px"}}>{datehelper.displayDate(new Date(e.start_date))}</td>
            <td style={{border:"1px solid", padding:"5px"}}>{datehelper.displayDate(new Date(e.end_date))}</td>
           {/* { roleChecker.isGMLevelAccess(user.role) && <td style={{border:"1px solid", padding:"5px"}}>
  <button onClick={() => {
    if (window.confirm("Are you sure you want to Inactivate this Enach status")) {
      handleInactive(e._id);
    }
  }}>
    <DeleteIcon/>
  </button>
</td> } */}
          </tr>)
        })}
      </table> <br /> 
     <div style={{display:'flex',gap:'2rem'}}>

        <AddEnachModal  customer={customer} />
     </div>
        <button></button>
      
      
      {va_details && (
        <div>
          <h2>Payment QR Code</h2>
          <div
            style={{ paddingLeft: '50px', paddingTop: '50px' }}
          >
            <p><b>Account Payee:</b> Kaabil Finance Private Ltd</p>
            <p><b>Bank Name:</b> ICICI Bank</p>
            <p><b>Account Number:</b> {va_details && va_details.va_num}</p>
            <p><b>IFSC Code:</b> ICIC0000106</p>
            {
              va_details && process.env.REACT_APP_QR_CODE_TYPE === "VPA"  && va_details.vpa_qrcode_url && (
                <div><img src={va_details.vpa_qrcode_url}></img></div>
              )
              }
            { va_details && process.env.REACT_APP_QR_CODE_TYPE !=="VPA" && (
                <div><img src={va_details.qrcode_url}></img></div>
              )}
          </div>
        </div>
      )}
      {!va_details && (
        <div>
          <br />
          <Button
            color="primary"
            variant='contained'
            style={{ "margin": 10 }}
            onClick={() => createQRCode()}>Create QR Code</Button>
        </div>
      )}
      {rows.length > 0 && (
        <Formik
          innerRef={formikRef}
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          id="transition-modal-description"
        >
          {({ errors, touched, isSubmitting, setFieldValue, values }) => {
            return (<Form>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Payment Details</StyledTableCell>
                      <StyledTableCell align="right">
                        {!editing && (
                          <a
                            onClick={() => setEditing(true)}>Edit</a>
                        )}
                        {editing && (
                          <a
                            onClick={() => saveEdits(values)}>Save</a>
                        )}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>


                    {rows.map((row) => (
                      <StyledTableRow key={row.label}>
                        <StyledTableCell component="th" scope="row">
                          {row.label}
                        </StyledTableCell>
                        {!editing && (
                          <StyledTableCell align="right">{row.value}</StyledTableCell>
                        )}
                        {editing && row.editable && (row.type === "text" || row.type === "number") && (
                          <StyledTableCell align="right">
                            <FormElement
                              type={row.type}
                              name={row.key}
                              label={row.label}
                              errors={errors}
                              touched={touched}
                            />
                          </StyledTableCell>
                        )}
                        {editing && row.editable && row.type === "select" && (
                          <StyledTableCell align="right">
                            <Field
                              as="select"
                              name={row.key}
                              label={row.label}
                              errors={errors}
                              touched={touched}
                            />
                          </StyledTableCell>
                        )}

                        {editing && !row.editable && (
                          <StyledTableCell align="right">
                            <FormElement
                              type="text"
                              disabled={true}
                              name={row.key}
                              label={row.label}
                              errors={errors}
                              touched={touched}
                            />
                          </StyledTableCell>
                        )}


                      </StyledTableRow>
                    ))}



                  </TableBody>
                </Table>
              </TableContainer>

            </Form>)
          }}
        </Formik>
      )}
    </div>
  );
}
