import React, { useState } from "react";
import { branchService } from "../../../_services/branch.service";
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from "yup";
import { adminservice } from "../../../_services/admin.services";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function AddEditCities({ history, match }) {
    const formikRef = React.useRef();
    const { id } = match.params;
    const isAddMode = !id;
 const [submitButton,setSubmitButton]=React.useState(false)
    const [clusterList, setClusterList] = React.useState({})
    const [areaList, setAreaList] = React.useState({});
    const [zoneList, setZoneList] = React.useState({});
    const [regionList,setRegionList]=React.useState({});
const [city,setCity] = useState()

    const notify = () => toast.success("City Added Successfully", {
        position: toast.POSITION.TOP_RIGHT,
    style: {
    background: 'green',
    color: '#fff',
    fontSize: '20px',
    width: '30vw',
    padding: '20px 30px',
  }
    });

    React.useEffect(() => {
   
        if (!isAddMode) {
          // get user and set form fields
    
          adminservice.getByIdCity(id).then((cities) => {
            console.log("city",cities)
            const fields = [
             " state",
        "district" ,
        "city" ,
        "cluster" ,
        "area" ,
        "zone" ,
        "region" 
            ];
            fields.forEach((field) => {
                // console.log('field',field,cities[field])
                // console.log('rethry',formikRef.current)
              if (formikRef.current) {
                // console.log(formikRef.current)
                formikRef.current.setFieldValue(field, cities[field], false);
              }
             
    
            });
             setCity(cities)
          });
        }
      }, [id, isAddMode, formikRef]);
    const getCityDetails = () => {
        console.log("getting cities list");
        branchService.getAll()
            .then((res) => {
              console.log('show all clusters', res);
                let allcluster = {};
                let allarea = {};
                let allzone = {};
                let allregion={};
               const allBranch =  res.map((c) => {
                    return c.branch_id;
                });
                console.log("allBranch",allBranch)
                setClusterList(allBranch);
                setAreaList(allBranch);
                setZoneList(allBranch)
                setRegionList(allBranch)
            })
            .catch((err) => console.log(err))
    }
    // console.log('clusters are ', clusterList)
    React.useEffect(() => {
        getCityDetails()
    },[])


    const initialValues = {
        state: "Rajasthan",
        district: "",
        city: "",
        cluster:"",
        area:"",
        zone:"",
        region:""
    };


    const validationSchema = Yup.object().shape({

        city: Yup.string().required("This field is required"),
        state: Yup.string().required("This field is required"),
        district: Yup.string().max(40).required("This field is required"),
        // cluster: Yup.string().required("This field is required"),
        // area: Yup.string().required("This field is required"),
        // zone: Yup.string().required("This field is required"),
        // region:Yup.string().required("This field is required")
    });




    async function onSubmit(values, { setSubmitting }) {
       console.log('valuesvalues',values)
        setSubmitButton(true);
        //    console.log('values is',values)
        if(isAddMode){

        
        adminservice.create(values).then(() => {
            // window.alert("city added");
            notify();
            setTimeout(() => {
                history.push(".");
              }, 2000); 
        })
            .catch((err) => {
                window.alert("error creating city");
                console.log(err);
               setSubmitButton(false);
            })
           

        }else if(!isAddMode)
        {
            adminservice.update(city._id,values).then(()=>{
            console.log("updated branch");
           alert('city updated')
            history.push("..");
          })
          .catch((err)=> {
            window.alert("error creating branch");
            console.log(err);
          })
        }
     

    }


    console.log('intial',initialValues)
console.log("cities",city)
    return (
        <div>

            <h2 id="transition-modal-title">Add City</h2>



            <Formik
            innerRef={formikRef}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                id="transition-modal-description"
            >
                {({ errors, touched, isSubmitting, values }) => {
                    return (<Form>
                        <ToastContainer />
                        <div className="form-row">
                            <div className="form-group col">
                                <label>City:</label>
                                <Field name="city" type="text" className={
                                    "form-control" +
                                    (errors.city && touched.city ? " is-invalid" : "")
                                } />
                                <ErrorMessage
                                    name="city"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>
                            <div className="form-group col">
                                <label>District:</label>
                                <Field name="district" type="text" className={
                                    "form-control" +
                                    (errors.district && touched.district ? " is-invalid" : "")
                                } />
                                <ErrorMessage
                                    name="district"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col">
                                <p>State:</p>
                                <Field
                                    as="select"
                                    className={
                                    "form-control" +
                                    (errors.state && touched.state ? " is-invalid" : "")
                                } 
                                    name="state"
                                    label="State"
                                >
                                    <option value=""  >Select State</option>
                                    <option value="Rajasthan"  >Rajasthan</option>
                                </Field>
                                <ErrorMessage
                                    name="state"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>

                            <div className="form-group col">
                                <p>Branch Cluster:</p>
                                <Field
                                    as="select"
                                     
                                    name="cluster"
                                    value={values.cluster}
                                    label="Branch Cluster"
                                    className={
                                    "form-control" +
                                    (errors.cluster && touched.cluster ? " is-invalid" : "")
                                } 
                                >
                                    <option value="">Select Cluster</option>

                                    {Object.keys(clusterList).map((cul, idx) => (
                                        <option value={clusterList[cul]} key={idx}>{clusterList[cul]}</option>
                                    ))}
                                </Field>
                                <ErrorMessage
                                    name="cluster"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>



                        </div>
                        <div className="form-row">

                            <div className="form-group col">
                                <p>Area:</p>
                                <Field
                                    as="select"
                                    
                                    name="area"
                                    value={values.area}
                                    label="Area"
                                    className={
                                    "form-control" +
                                    (errors.area && touched.area ? " is-invalid" : "")
                                } 
                                >
                                    <option value="">Select Area</option>

                                    {Object.keys(areaList).map((cul, idx) => (
                                        <option value={areaList[cul]} key={idx}>{areaList[cul]}</option>
                                    ))}
                                </Field>
                                <ErrorMessage
                                    name="area"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>
                            <div className="form-group col">
                                <p>Zone:</p>
                                <Field
                                    as="select"
                                     
                                    name="zone"
                                    value={values.zone}
                                    label="Zone"
                                    className={
                                    "form-control" +
                                    (errors.zone && touched.zone ? " is-invalid" : "")
                                } 
                                >
                                    <option value="">Select Zone</option>

                                    {Object.keys(zoneList).map((cul, idx) => (
                                        <option value={zoneList[cul]} key={idx}>{zoneList[cul]}</option>
                                    ))}
                                </Field>
                                <ErrorMessage
                                    name="zone"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>



                        </div>
                        <div className="form-row">
                            <div className="form-group col">
                                <p>Region:</p>
                                {/* <Field
                                 style={{width:'50rem'}}
                                name="region" type="text" className={
                                    "form-control" +
                                    (errors.region && touched.region ? " is-invalid" : "")
                                } /> */}
                                <Field
                                    style={{width:'50rem'}}
                                    as="select"
                                    name="region"
                                    value={values.region}
                                    label="region"
                                    className={
                                    "form-control" +
                                    (errors.region && touched.region ? " is-invalid" : "")
                                } 
                                >
                                    <option value="">Select region</option>

                                    {Object.keys(regionList).map((reg, idx) => (
                                        <option value={regionList[reg]} key={idx}>{regionList[reg]}</option>
                                    ))}
                                </Field>
                                <ErrorMessage
                                    name="region"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>
                        </div>
                        <hr />
                        <button
                            
                            type="submit"
                            className="btn btn-primary"
                            disabled={submitButton}
                        >
                            Submit
                        </button>

                    </Form>)
                }}
            </Formik>
        </div>
    );
}
