import React, { useState, useEffect } from 'react'
import { Button, Modal } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { virtualAccountService } from "../../../_services/virtualaccount.service";

const ReceiptQRModel = (props) => {

    const [open, setOpen] = useState(false)
    const [url, setUrl] = useState('')
    const [vpaUrl, setVPAUrl] = useState('')
    const receipt=props.receipt;
    const loan_no = `RPT${receipt.receipt_no}`;
    const customer_name=receipt.cust_name;

    console.log(props)


    const handleOpen = () => {
        setOpen(true)
        getQRcode()
    }

    const handleClose = () => {
        setOpen(false)
    }


    async function createQRCode() {
        let fields = {
          'loan_account': loan_no+ "",
          'customer_name': customer_name,
          'customer_mobile': ""
        }
        console.log(fields);
        virtualAccountService.create(fields).then((data) => {
                console.log("va data")
                console.log(data);
                if(data.vpa_qrcode_url && data.vpa_qrcode_url!=="" )
                {
                    setVPAUrl(data.vpa_qrcode_url)
                }
                setUrl(data.qrcode_url)
        })
          .catch((err) => {
            console.log(err);
          })
    
      }
    

    const getQRcode = () => {
        if(open)
        {
            virtualAccountService.getByLoanAccount({ loan_account: loan_no })
                .then((data) => {
                    console.log(data)
                    if(data.vpa_qrcode_url && data.vpa_qrcode_url!=="" )
                    {
                        setVPAUrl(data.vpa_qrcode_url)
                    }
                    setUrl(data.qrcode_url)
                    
                    
                })
                .catch((err) => {
                    console.log(err)
                    setUrl('')
                })
        }
    }

    useEffect(() => { 
            getQRcode()
    }, [open])


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        height: 580,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        //overflow: 'scroll',
        //paddingBottom: '30px',
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>

          {<Button style={{backgroundColor:"#F4B400", border:"1px solid"}}  onClick={handleOpen}> Deposit QR </Button>}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                   
                           {(vpaUrl !==''|| url !=="") && (
                             <div>
                            <p>QR code</p> 
                            
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <span style={{ fontWeight: "bold", fontSize: 14 }}> Account No:- {`KAABIL${loan_no}`} </span>
                                <span style={{ fontWeight: "bold", fontSize: 15 }}> IFSC Code:-  ICIC0000106</span>
                            </div>
                            </div>
                            
                           )}
                            
                            { process.env.REACT_APP_QR_CODE_TYPE !=="VPA" && (!vpaUrl || vpaUrl ==='') && url  && (
                            
                            <div>
                            <img style={{ width: "250px", height: "250px" }} src={url} alt="QR Code" />
                            </div>
                             )}
                             {(!vpaUrl || vpaUrl ==='') && (
                            
                            <div>
                               <br />
                                    <Button
                                        color="primary"
                                        variant='contained'
                                        style={{ "margin": 10 }}
                                        onClick={() => createQRCode()}>Create QR Code</Button>
                               </div>
                             )}
                            {process.env.REACT_APP_QR_CODE_TYPE ==="VPA" && vpaUrl && vpaUrl !=='' && (
                                <div>
                                  <img style={{ width: "250px", height: "250px" }} src={vpaUrl} alt="New QR Code" />
                          
                                </div>
                            )}
                      </div>      

                    </Typography>
                </Box>
            </Modal>
        </div>
    )
}

export default ReceiptQRModel