import React, { useState, useEffect } from "react";
import {Select, MenuItem, FormControl,InputLabel,Box } from "@material-ui/core";

import { employeeService } from "../../../_services/employee.service";
import { Grid, Card, Button, CardActions, TablePagination } from "@material-ui/core";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { loanService } from "../../../_services";
import {LoanStages} from "../../../constants/loanstages"
import { Role } from "../../../_helpers";
import roleChecker from "../../../_helpers/rolechecker";
import datehelper from "../../../_helpers/datehelper";
import EditStageModal from "../Applications/EditStageModal";
import { makeStyles } from "@material-ui/core/styles";
import { Input } from "antd";
import LetterList from "../Letters/SBLLetterList";

const { Search } = Input;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexGrow: 1,
  },
  card: {
    minHeight: 250,
    minWidth: 250,
  },
}));

function CreditList({ match }) {
  const { path } = match;
  const [loans, setLoans] = useState(null);
  const user = employeeService.userValue;
  const [page, setPage] = React.useState(0);
  const [searchFilter,setSearchFilter]=React.useState("");
  const [contactFilter,setContactFilter]=React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [stageList,setStageList]= React.useState([]);
  const [filterStage,setFilterStage]= React.useState(null);
  const [branchList,setBranchList]= React.useState([]);
  const [filterBranch,setFilterBranch]= React.useState(null);
  const [empList,setEmpList]= React.useState([]);
  const [filterEmp,setFilterEmp]= React.useState(null);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  useEffect(() => {
             getCreditFiles();
      }, [user,searchFilter,contactFilter,filterStage,filterBranch,filterEmp]);

  async function getCreditFiles()
  {

    loanService.getAllCredit().then((x) => {
      console.log(x);
      let tmpStageList=[];
      let tmpBranchList=[];
      let tmpEmpList={};
      let tempLoans=x.filter((l)=> l.stage === LoanStages.Credit)
      tempLoans=tempLoans.filter((l)=> {
      if(!tmpStageList.includes(l.sub_stage))
      {
        tmpStageList.push(l.sub_stage);
      }
      if(!tmpBranchList.includes(l.branch_id))
      {
        tmpBranchList.push(l.branch_id);
      }
      if(l.next_stage_emp_id && !Object.keys(tmpEmpList).includes(l.next_stage_emp_id))
      {
        tmpEmpList[l.next_stage_emp_id]={'name':l.next_stage_employees[0].first_name+" "+l.next_stage_employees[0].last_name,'emp_id':l.next_stage_emp_id};
      }
        
      if(searchFilter!="")
      {
        if(l.applicant_name && l.applicant_name.toLowerCase().includes(searchFilter.toLowerCase()))
        {
            return true;
        }
        if(l.application_id === searchFilter || l.application_id === parseInt(searchFilter) )
        {
            return true;
        }
        return false;
      }
      else if(contactFilter!="")
      {
        
        if(l.applicant_contact && (l.applicant_contact === contactFilter || l.applicant_contact === parseInt(contactFilter)) )
        {
            return true;
        }
        if(l.applicant_aadhar && (l.applicant_aadhar === contactFilter || l.applicant_aadhar === parseInt(contactFilter) ) )
        {
            return true;
        }
        return false;
      }
      else
      {
        return true;
      }

      });


     if(filterStage)
     {
      tempLoans=tempLoans.filter((l)=> {
        if(l.sub_stage===filterStage)
        {
          return true;
        }
        return false;
      });
     }

     if(filterBranch)
     {
      tempLoans=tempLoans.filter((l)=> {
        if(l.branch_id===filterBranch)
        {
          return true;
        }
        return false;
      });
     }
     if(filterEmp)
     {
      tempLoans=tempLoans.filter((l)=> {
        if(l.next_stage_emp_id===filterEmp)
        {
          return true;
        }
        return false;
      });
     }


      console.log(tempLoans);
      if (roleChecker.isHOLevelAccess(user.role) ||user.role === Role.ZCM )
      { 
           setLoans(tempLoans);
      }
      else if(user.role === Role.RCM   )
        {
          setLoans(tempLoans.filter((applicant) => applicant.branches.length>0 && applicant.branches[0].branch_region === user.branch_id));  
        
        }
      else if(user.role === Role.ASM || user.role === Role.CCM || user.role === Role.ACM )
      {
        setLoans(tempLoans.filter((applicant) => applicant.branches.length>0 && applicant.branches[0].branch_area === user.branch_id));  
      
      }
      else if(user.role===Role.BCM || user.role === Role.BM)
      {
        setLoans(tempLoans.filter((applicant) => applicant.employees.length>0 && applicant.employees[0].branch_id === user.branch_id));  
      }    
      else if(user.role === Role.Sales) {
        setLoans(tempLoans.filter((applicant) => applicant.employees.length>0 && applicant.employees[0].emp_id === user.emp_id));
      }
      setStageList(tmpStageList);
      setBranchList(tmpBranchList);
      setEmpList(tmpEmpList);
    });

  }
  
  const refreshPage=()=> {
    getCreditFiles();
  }

  return (
    <div>
      <h1>
        Credit Pending Loans &nbsp;
      </h1>
      <div className="d-flex justify-content-between">
      
       
       <Search
        onChange={(e) => setSearchFilter(e.target.value)}
        placeholder="Name/Application Id"
        enterButton
        style={{
          position: "sticky",
          top: "0",
          left: "0",
          width: "200px",
          marginTop: "2vh",
        }}
      />

<Search
        onChange={(e) => setContactFilter(e.target.value)}
        placeholder="Phone"
        enterButton
        style={{
          position: "sticky",
          top: "0",
          left: "0",
          width: "200px",
          marginTop: "2vh",
        }}
      />


<Box sx={{ minWidth: 120 }}>
       <FormControl fullWidth>

       <InputLabel id="filter_emp_label">Filter Emp</InputLabel>
       <Select
       labelId="filter_emp_label"
       name="empfilter"
       label="Filter Emp"
       value={filterEmp}
       onChange={(e) => setFilterEmp(e.target.value)}
       >
         <MenuItem value="" disabled>Filter Emp</MenuItem>
         {empList && Object.keys(empList).map((s)=> {
           return <MenuItem value={empList[s].emp_id}>{empList[s].emp_id+" : "+empList[s].name}</MenuItem>
         })}

       </Select>
       </FormControl>
       </Box>

  <Box sx={{ minWidth: 120 }}>
       <FormControl fullWidth>

       <InputLabel id="filter_stage_label">Filter Branch</InputLabel>
       <Select
       labelId="filter_branch_label"
       name="branchfilter"
       label="Filter Branches"
       value={filterBranch}
       onChange={(e) => setFilterBranch(e.target.value)}
       >
         <MenuItem value="" disabled>Filter Branch</MenuItem>
         {branchList && branchList.map((s)=> {
           return <MenuItem value={s}>{s}</MenuItem>
         })}

       </Select>
       </FormControl>
       </Box>


    <Box sx={{ minWidth: 150 }}>
       <FormControl fullWidth>
       <InputLabel id="filter_stage_label">Filter Next Step</InputLabel>
       <Select
       labelId="filter_stage_label"
       name="stagefilter"
       label="Filter Next Step"
       value={filterStage}
       onChange={(e) => setFilterStage(e.target.value)}
       >
         <MenuItem value="" disabled>Filter Next Step</MenuItem>
         {stageList && stageList.map((s)=> {
           return <MenuItem value={s}>{s}</MenuItem>
         })}

       </Select>
       </FormControl>
       </Box>

      </div>
      <br></br>
      
      <Table className="table table-striped">
        <Thead>
          <Tr>
            <Th style={{ width: "10%" }}>Application Id</Th>
            <Th style={{ width: "10%" }}>Customer Name</Th>
            <Th style={{ width: "10%" }}>Loan Amount</Th>
            <Th style={{ width: "10%" }}>Branch</Th>
            <Th style={{ width: "10%" }}>PD Status</Th>
            <Th style={{ width: "15%" }}>File Stage</Th>
            <Th style={{ width: "15%" }}>Next Stage</Th>
            <Th style={{ width: "15%" }}>Pending On</Th>
            <Th style={{ width: "15%" }}>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {loans && loans.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((loan) => (
              <Tr key={loan._id}>
                <Td>
                  {loan.application_id}
                </Td>
                <Td>{loan.applicant && loan.applicant.general_information && loan.applicant.general_information.name}</Td>
                <Td>{loan.loan.amount}</Td> 
                <Td>{loan.branch_id}</Td> 
                <Td>{loan.pd_details && datehelper.displayDate(new Date(loan.pd_details.pd_date))}<br/>
                  {loan.pd_details && loan.pd_details.pd_status =="ApprovedNextStage" && (
                    <>
                    <p style={{color:"green"}}>PD Approved 
                    </p>
                    <br/>
                    Approved Amount: {loan.pd_details.loan_amount}
                    </>
                    )}
                      {(loan.pd_details && loan.pd_details.pd_status =="Rejected") && (
                    <>
                    <p style={{color:"red"}}>PD Rejected
                    </p>
                    <br/>
                    Reject Reason: {loan.pd_details.reject_reason}
                    </>
                    )}
                </Td>
                <Td>{loan.stage}
                </Td>
                <Td>{loan.sub_stage} 
                </Td>
                <Td>
                  {loan.next_stage_employees && loan.next_stage_employees.length>0 &&  loan.next_stage_employees[0].first_name } {loan.next_stage_employees && loan.next_stage_employees.length>0 &&  loan.next_stage_employees[0].last_name }<br/>
                  {loan.stage_assign_date && datehelper.displayDate(new Date(loan.stage_assign_date))}
                </Td>
                <Td>
                <Button component="a"
              href={`/home/sblloans/details/${loan._id}`}
              size="small"
              color="primary"
            >
              View Details</Button>
              <br/>
              <LetterList id={loan._id} stage={loan.stage} />
             
                </Td>
              </Tr>
            ))}
          {!loans && (
            <Tr>
              <Td colSpan="4" className="text-center">
                <span className="spinner-border spinner-border-lg align-center"></span>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      {loans ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={loans.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        <div />
      )}
    </div>
  );
}

export { CreditList };
