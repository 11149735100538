import React, { useState } from 'react'
import { Modal, Box, Typography, Button } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { Formik, Field, Form, ErrorMessage } from "formik";
import './ViewLoanDetails.css';
import { misService } from '../../../_services';
import * as Yup from 'yup';


const PersonalDetailsEditModal = (props) => {
  const loan = props.loan
  const loan_id = loan._id
  const customer = props.customer && props.customer
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    height: 520,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '5px',
    // overflow: "scroll",
    border: "2px solid blue",
    borderRadius: "8px"
  };
  const validationSchema = Yup.object({
    contact: Yup.string()
      .matches(/^\d{10}$/, 'Contact number must be exactly 10 digits')
      .required('Contact number is required'),
  });
  let initialValues = {
    customer_name: loan.name,
    cif_id: loan.customer_id && loan.customer_id,
    father_name: loan.sonof && loan.sonof ? loan.sonof : customer && customer.customer_details && customer && customer.customer_details.general_details && customer.customer_details.general_details.fathername,
    mother_name: loan.details && loan.details.mother ? loan.details && loan.details.mother : customer && customer.customer_details && customer.customer_details.general_details && customer.customer_details.general_details.mothername,
    address: loan.address && loan.address ? loan.address && loan.address
      : customer && customer.customer_details && customer.customer_details.personal_details && customer.customer_details.personal_details.permanent_address.address_line_1,
    city: loan.details && loan.details.permanent_address && loan.details.permanent_address.city ? loan.details && loan.details.permanent_address && loan.details.permanent_address.city
      : customer && customer.customer_details && customer.customer_details.personal_details && customer.customer_details.personal_details.permanent_address.city,
    district: loan.details && loan.details.permanent_address && loan.details.permanent_address.district ? loan.details && loan.details.permanent_address && loan.details.permanent_address.district
      : customer && customer.customer_details && customer.customer_details.personal_details && customer.customer_details.personal_details.permanent_address.district,
    pin_code: loan.details && loan.details.permanent_address && loan.details.permanent_address.pin_code ? loan.details && loan.details.permanent_address && loan.details.permanent_address.pin_code
      : customer && customer.customer_details && customer.customer_details.personal_details && customer.customer_details.personal_details.permanent_address.pin_code,
    contact: loan.contact && loan.contact ? loan.contact && loan.contact
      : customer && customer.customer_details && customer.customer_details.general_details && customer.customer_details.general_details.customer_mobile_num,
  }
  const handleSubmit = async (fields) => {
    await misService.updatePersonalDetails(loan_id, { contact: fields.contact, cif_id: fields.cif_id })
      .then((res) => {
        console.log("Test Response", res)
        if (res.update_loan === true) {
          alert("Details Updated successfully!!")
          window.location.reload()
        }
        else {
          alert("Some error occured")
        }
      })
      .catch((err) => console.log(err))
  }
  return (
    <div>
      <Button onClick={handleOpen} style={{ color: 'blue', float: 'right' }}><Edit /></Button>
      <Modal
        open={open}
        onClose={handleClose}

      >
        <div>
          <Box sx={style}>
            <h3 style={{ color: 'black', textAlign: "center", marginBottom: "30px", fontWeight: "bold" }}>Edit customer Details</h3>
            <div>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                <Form className='form'>
                  <div className="form-row">
                    <div className="form-group-col">
                      <label >Customer Name</label>
                      <Field
                        name='customer_name'
                        className='input-field'
                        disabled
                      />
                    </div>
                    <div className="form-group-col">
                      <label >CIF ID</label>
                      <Field
                        name="cif_id"
                        className='input-field'
                        disabled
                      />
                    </div>
                    <div className="form-group-col">
                      <label >Father's Name</label>
                      <Field
                        name="father_name"
                        className='input-field'
                        disabled
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group-col">
                      <label >Mother's Name</label>
                      <Field
                        name="mother_name"
                        className='input-field'
                        disabled
                      />
                    </div>
                    <div className="form-group-col">
                      <label >Contact Number</label>
                      <Field
                        name="contact"
                        type="number"
                        className='input-field'
                        required
                      />
                      <ErrorMessage name="contact" component="div" className="error-message" style={{ color: 'red' }} />
                    </div>
                    <div className="form-group-col">
                      <label >City</label>
                      <Field
                        name="city"
                        className='input-field'
                        disabled
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group-col">
                      <label >District</label>
                      <Field
                        name="district"
                        className='input-field'
                        disabled
                      />
                    </div>
                    <div className="form-group-col">
                      <label>Pin Code </label>
                      <Field
                        name="pin_code"
                        className='input-field'
                        disabled
                      />
                    </div>
                    <div className="form-group-col">
                      <label >Address</label>
                      <Field
                        name="address"
                        className='input-field'
                        disabled
                      />
                    </div>
                  </div>
                  <button
                    type='submit'
                    style={{
                      display: 'block',
                      margin: '60px auto 0',
                      borderRadius: '5px',
                      background: 'blue',
                      color: 'white',
                      height: '40px',
                      width: '200px',
                      border: "1px solid blue"
                    }}>
                    Update
                  </button>
                </Form>
              </Formik>
            </div>
          </Box>
        </div>
      </Modal>
    </div>
  )
}
export default PersonalDetailsEditModal
