import React from "react";
import {
  makeStyles,
} from "@material-ui/core";
import AddApplicationFeesModal from "./AddApplicationFeesModal";
import datehelper from "../../../../_helpers/datehelper";
import PaymentFeeModal from "./PaymentFeeModal"
import roleChecker from "../../../../_helpers/rolechecker";
import { employeeService } from "../../../../_services";

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function ApplicationFees({ customer }) {
  const classes = useStyles();
  const user = employeeService.userValue
  return (
    <div>
    <h2>Login Fees</h2>
    <table style={{ width: "100%", border:"1px solid",padding:"10px" }}><th>Payment Date</th><th>Payment Amount</th><th>Payment Mode</th><th>Payment Comment</th><th>Receipt Ref Id</th>
      {customer.application_fees && customer.application_fees.payment_amount > 0 && (
        <tr><td>{datehelper.displayDate(new Date(customer.application_fees.payment_date))}</td><td>{customer.application_fees.payment_amount}</td>
          <td>{customer.application_fees.payment_mode}</td>
          <td>{customer.application_fees.payment_comment}</td>
          <td>{customer.application_fees.payment_ref_id}</td></tr>
      )}
    </table>
    {(!customer.application_fees || customer.application_fees.payment_amount == 0) && (
      <AddApplicationFeesModal customer={customer} />
      
    )} 
    {/* {(!customer.application_fees || customer.application_fees.payment_amount == 0 || ) && ( */}
  { roleChecker.hasAccountsAccess(user.role) &&    <PaymentFeeModal customer={customer}/>}
      
    {/* )}  */}
    </div>

  );
}
