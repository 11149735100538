import React, { useState } from "react";
import { useReactToPrint } from "react-to-print";
// import { misService } from "../_services";
// import ApplicationDoc from "./ApplicationDoc";
import SubmissionDoc from "./SubmissionDoc";
import { submissionServices } from "../../../_services/submissionletter.service";
import { useEffect } from "react";
import { employeeService } from "../../../_services";
import  SibSubmissionLetter  from "./SibSubmissionLetter";
export default function SubmissionPrint(props) {
  // const compRef = React.useRef();
  const user = employeeService.userValue;
  const data = props.location.state
  const date = new Date()
  const contact = data.contact
  // const num = `KFPL/GL/${data.colendingBank.toUpperCase()}/${date.toLocaleString("default", { month: "long" })}/${(data.letterNumber) + 1}`
  const submissionData = {
    name: data.name,
    loan_account: data.loan_account,
    gold_packet: data.gold_packet,
    loan_amount: data.loan_amount,
    date: data.date,
    gross_weight: data.gross_weight,
    net_weight: data.net_weight,
    current_date: new Date,
    letter_number:data.letter_number,
    colendingBank: data.colendingBank,
    authPerson: data.authPerson,
    emp_id: user.emp_id,
    ornaments:data.ornaments
  }
  const handleSave = async () => {
    await submissionServices.saveSubmissionData({ submissionData: { ...submissionData } })
      .then((res) => handleSubmissionPrint())
      .catch((err) => window.alert("error  occurs"))
  }

  const submissionRef = React.useRef();
  // const [ckyc, setCkyc] = React.useState("");
  // React.useEffect(async () => {
  //   const kyclink = await misService.getCkyc({
  //     customer_pic: props.location.state.customer_pic,
  //     loan_account: props.location.state.loan_account,
  //   });
  //   setCkyc(kyclink);
  // }, []);

  // function openCkyc() {
  //   window.open(ckyc);
  // }

  const handleSubmissionPrint = useReactToPrint({
    content: () => submissionRef.current,
  });

  return (
    data.colendingBank === "SIB"?
    <div>
      <div style={{ textAlign: "center" }}>
        {
          contact ? <button onClick={() => { handleSave() }}>Print Submission Letter</button>
            : <button onClick={() => { handleSubmissionPrint() }}>Print Preview Submission Letter</button>
        }
      </div>
      <SibSubmissionLetter ref={submissionRef} dataFill={props.location.state} />
    </div>
    :<div>
    <div style={{ textAlign: "center" }}>
      {
        contact ? <button onClick={() => { handleSave() }}>Print Submission Letter</button>
          : <button onClick={() => { handleSubmissionPrint() }}>Print Preview Submission Letter</button>
      }
    </div>
    <SubmissionDoc ref={submissionRef} dataFill={props.location.state} />
  </div>
  );
}
