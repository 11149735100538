import React, { useState } from "react";
import AddPaymentDetailsColumns from "./AddPaymentDetailsColumns";
import { Box, Modal } from '@material-ui/core';
import AddPaymentDetailsList from "./AddPaymentDetailsList";
import { array, date } from "yup";
import { employeeService ,loanService} from "../../../../_services";
import roleChecker from "../../../../_helpers/rolechecker";
import { Button, Typography } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { Form, Formik } from "formik";
import { misService } from "../../../../_services";
import { ApprovalRequestFilter } from "../../../MakerCheckerFramework/ApprovalRequestFilter";
import { Add } from "@material-ui/icons";
import workflowHelper from "../../../../_helpers/workflowhelper";
export default function AddPaymentItemModal({loan }) {
    const style = {
        position: "absolute",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 1200,
        height: 500,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
        margin: "30px",
        overflow: 'scroll',
    };
    console.log('Test loan ', loan)
    const [values, setValues] = useState([]);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const user = employeeService.userValue;
    const initializeValues = {
        payment_date: new Date().toISOString().substr(0, 10),
        payment_type: "collection",
        payment_mode: "cash",
        payment_ref: "",
        payment_amount: 0
    };
    const payment = [];
    const handleChange = (idx) => (e) => {
        const { name, value } = e.target;
        const newvalues = [...values];
        if (name == "payment_amount") {
            newvalues[idx][name] = parseInt(value);
        }
        else {
            newvalues[idx][name] = value;
        }
        // setFieldValue("payment_details", newvalues);
        setValues(newvalues);
    };
    const handleAddRow = () => {
        const item = {
            payment_date: new Date().toISOString().substr(0, 10),
            payment_type: "collection",
            payment_mode: "cash",
            payment_ref: "",
            payment_amount: 0
        };
        console.log("test Item", item);
        // setFieldValue("payment_details", [...values, item]);
        setValues([...values, item]);
    };
    const handleRemoveSpecificRow = (idx) => () => {
        const newvalues = [...values];
        newvalues.splice(idx, 1);
        console.log("Test new row", newvalues)
        // setFieldValue("payment_details", newvalues);
        setValues(newvalues);
    };
    console.log('Test values', values)
    const addPaymentsDetails = async () => {
        await ApprovalRequestFilter.RequestFilter({
            service: 'misService',
            api: 'addPaymentsDetails',
            id: loan._id,
            task: 'Add Payment Details',
            updated_fields: ['payment_date','payment_type','payment_mode','payment_ref','payment_amount'],
            sensitive_fields: [],
            new_values: {values:values},
            old_values: {},
            new_values_data_details:{
                data_type: 'array',
                array_name: 'values'
            },
            identification_key: 'Loan Number',
            identification_value: loan.loan_account,
            emp_id: user.emp_id,
            approval_needed: true,
            approved: false,
            approval_access: 'isAccountChecker',
            product: 'goldloans'
        }).then(async(x) => {
            console.log('resp is',x)
            const acUserData = await loanService.getEmpIdRoleWise({ role: 'AC' }); 
            console.log("AC User Data:", acUserData);
            if (Array.isArray(acUserData) && acUserData.length > 0) { // Ensure acUserData is an array and not empty
              console.log("Calling Redis Helper for sending Notifications");
              await Promise.all(
                  acUserData.map((employee) => {
                      const emp_id = employee.emp_id; // Extract emp_id for each user
          
                      return workflowHelper.isRedisNotifications({
                        emp_id: user.emp_id,
                        message: `You have an approval request from ${user.emp_id} ,Please review and take necessary action.`,
                        action_link: `https://samruddhi.kaabilfinance.com/home/approvals/list`,
                        action_taken_by: emp_id
                    });
                  })
                );
              }
                console.log("test x", x)
                alert("Request Sent to concerned Authority")
                window.location.reload()
            })
            .catch((err) => {
                window.alert(err)
                console.log(err)
            })
    }
    console.log("Test value",values)
    let total_amount = values.filter(col=>col.payment_type==="collection").reduce((
        la, next) => la + next.payment_amount, 0)
        console.log("Total Amount", total_amount)
    let total_amount_disbursal = values.filter(dis=>dis.payment_type==="disbursement").reduce((
            la, next) => la + next.payment_amount, 0)
    return (
        <div>
            <Button onClick={handleOpen} variant="outlined" 
            style={{color: "#3760F1",border: '2px solid #3760F1'}}>
               <Add/>&nbsp;Add Payment
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Formik
                        initialValues={initializeValues}
                        onSubmit={() => { addPaymentsDetails() }}
                    >
                        <Form>
                            <div>
                                <div className="col-10 mx-auto text-center text-title">
                                    <Typography variant="h5">Payment List</Typography>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-md-12 column">
                                        <table className="table table-bordered table-hover" id="tab_logic">
                                            <thead>
                                                <AddPaymentDetailsColumns />
                                            </thead>
                                            <AddPaymentDetailsList
                                                handleRemoveSpecificRow={handleRemoveSpecificRow}
                                                handleChange={handleChange}
                                                values={values}
                                            />
                                        </table>
                                        <div style={{ textAlign: "center", margin: 20 }}>
                                            {(roleChecker.hasAccountsAccess(user.role) && loan.loan_status !== "Auctioned") ? (<Button variant="outlined" onClick={handleAddRow}>
                                                Add Payment
                                            </Button>) : null}
                                            <div style={{ float: "right" }}>
                                                {/* <label>Total Payment</label> */}
                                                <label>Total collection</label>
                                                <input
                                                    disabled value={total_amount}
                                                >
                                                </input>
                                            </div>
                                            <br/>
                                            <div style={{ float: "right" }}>
                                                <label>Total disbursal</label>
                                                <input
                                                    disabled value={total_amount_disbursal}
                                                >
                                                </input>
                                            </div>
                                            <br />
                                            <br/>
                                            <br/>
                                            {values.length > 0 ? <button type="submit" style={{
                                                color: "blue", textAlign: "center", border: "blue", width: '80px',
                                                marginLeft: "8rem", height: "37px", border: "2px solid blue", borderRadius: '4px'
                                            }}><SaveIcon style={{ color: "black" }} />SAVE</button> : null}
            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </Box>
            </Modal>
        </div>
    )
}
