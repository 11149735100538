import React,{useEffect} from 'react'
import EmployeeRequestModal from "./EmployeeRequestModal"
import {employeeExpenseService} from "../../../_services/employeeExpense.service"
import ViewEmployeeExpense from "./ViewEmployeeExpense"

function MyAllExpense(){


    

    return (
        <>
           
            <EmployeeRequestModal/>
             <ViewEmployeeExpense/>
        </>
    )
}

export default MyAllExpense;
