import React,{useEffect} from "react";
import { dashboardService } from "../../_services/dashboard.service";
import CircularProgress from "@material-ui/core/CircularProgress";



const TableData = (props) => {

    const [tableData,setTableData] = React.useState([])
    const [loading, setLoading] = React.useState(true);

    const showTableData = (params)=>{
        dashboardService.requestForTableData(params).then((res)=>{
          console.log("Test Response-------------------->",res)
          setTableData(res.table_data);
          setLoading(false); // Set loading 
          console.log("tabledata--------------------->>",tableData)
            }).catch(err=>console.log(err))
          }
    
          useEffect(() => {
            setLoading(true); // loading
            showTableData({
              'field':props.field,
              'data_key':props.chartType
            })
          }, [props.chartType])

          let tableHeaders =[];

          if (tableData && tableData.length > 0) {
            // Initialize an array to hold the ordered keys
            const orderedKeys = [];
          
            // Iterate through each item in tableData
            tableData.forEach(item => {
              // Iterate through each key in the item
              Object.keys(item).forEach(key => {
                // If the key is not already in orderedKeys, add it
                if (!orderedKeys.includes(key)) {
                  orderedKeys.push(key);
                }
              });
            });
          
            // Create tableHeaders by including 'Branch' first, then the rest of the keys excluding 'Branch'
            tableHeaders = ['Branch', ...orderedKeys.filter(key => key !== 'Branch')];
          }
          

  return (
    <div>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' , marginTop: '200px'}}>
          <CircularProgress style={{ color: "#ff00ff", width:'3rem' }}  />
        </div>
      ) : (
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
          <thead>
            {console.log(tableHeaders, tableData, "fields and headers here")}
            <tr>
              {tableHeaders &&
                tableHeaders.map((header) => <th style={{ border: '1px solid black', padding: '8px' }} key={header}>{header}</th>)}
            </tr>
          </thead>
          <tbody>
            {tableData &&
              tableData.map((row, index) => (
                <tr key={index}>
                  {tableHeaders.map((header) => (
                    <td key={header} style={{ border: '1px solid black', padding: '8px' }}>{row[header]}</td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TableData;
