import React from 'react';
import { useState } from 'react';
import Search from '@material-ui/icons/Search';
import GoldAuctionServices from '../../../_services/goldauction.service';
import { employeeService } from '../../../_services';
import { misService } from '../../../_services';
import { history } from '../../../_helpers';
import { NoticeUpdateModal } from './NoticeUpdateModal';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import datehelper from '../../../_helpers/datehelper';
import { Button } from '@material-ui/core';
import { useEffect } from 'react';
export const Notice2 = (props) => {
    const user = employeeService.userValue;
    const [loanNo, setLoanNo] = useState('')
    const [loan, setLoan] = useState({})
    const [auctionDetail, setAuctionDetail] = useState({})
    const [show, setShow] = useState(false)
    const [value, setValue] = useState([])
    const handleChange = (value) => {
        setLoanNo(value);
    }
    const handleSearch = async () => {
        setLoan({})
        setAuctionDetail({})
        setValue([])
        await GoldAuctionServices.FindByLoan({ loan_no: loanNo })
            .then((res) => {
                console.log("reser",res)
                if(res.length>0 && res[0].notice1  )
                {
                setAuctionDetail(res[0]);
                if(res[0].notice2  && res[0].notice2.dispatch_date){
                setValue(res)
                }
                setShow(true)
                    misService.getLoanByAccount(loanNo)
                        .then((res) => {
                            if (res.length > 0) {
                                setLoan(res[0])
                            } else {
                                window.alert('loan number does not exist')
                            }
                        })
                        .then((err) => console.log(err))
                }
                else{
                    window.alert('First update notice 1')
                }
            })
            .then((err) => console.log(err))
    }
    useEffect(() => {
        async function getNoticeDetails() {
            await GoldAuctionServices.getNoticeDetails({ element: 'notice2' })
                .then((res) => setValue(res))
                .catch((err) => console.log(err))
        }
        getNoticeDetails();
    }, [])

    return (
        <div style={{ display: 'flex', marginTop: '50px', flexDirection: 'column', alignItems: 'center' }} >
            <h3>Notice 2</h3>
            <form style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }} >
                <input type="text" placeholder='Loan Number' style={{ borderRadius: '5px' }} value={loanNo} onChange={(e) => handleChange(e.target.value.toUpperCase())} />
                <button type='button' style={{ borderRadius: '5px', color: 'white', backgroundColor: '#0066cc', border: 'none', width: "70px", marginLeft: '10px' }} onClick={() => handleSearch()} ><Search /></button>
            </form>
            {/* {
                show === true && auctionDetail && auctionDetail.notice1 && auctionDetail.notice2 ?
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                        <NoticeUpdateModal auction={auctionDetail} notice={2} />
                    </div>
                    : null
            } */}
            {
                show === true && auctionDetail && auctionDetail.notice1 && !auctionDetail.notice2?
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                        <button
                            onClick={() =>
                                history.push(`/home/goldloans/reminderletter`, { ...loan, notice: 2,id: auctionDetail._id })   //id: auctionDetail._id 
                            }
                            style={{ borderRadius: '5px', color: 'white', backgroundColor: '#0066cc', border: 'none', width: "200px", height: '40px' }}
                        >
                            Print Reminder Letter
                        </button>
                    </div> :
                    null
            }
            {
                console.log(loan)
            }
                 {value.length>0 &&
                <Table style={{marginTop:"35px"}}>
                    <Thead>
                        <Tr>
                            <Th>Loan No</Th>
                            <Th>Dispatch Date</Th>
                            <Th>Employee</Th>
                            <Th>Preview</Th>
                            <Th>Update</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {   
                        value.length > 0?
                            value.map((x, idx) => <Tr key={idx}>
                                <Td>{x.loan_no}</Td>
                                <Td>{datehelper.displayDate(new Date(x.notice2.dispatch_date))}</Td>
                                <Td>{x.notice2.emp_name}</Td>
                                <Td>
                                         <Button
                                            onClick={() =>
                                                history.push(`/home/goldloanletters/reminderletterpreview`, {...x.loan[0], notice: 2,dispatch_date:x.notice2.dispatch_date})
                                            }
                                            // style={{ borderRadius: '5px', color: 'white', backgroundColor: '#0066cc', border: 'none', width: "100px", height: '40px', marginRight: '30px' }}
                                              style={{color:"blue"}}
                                        >
                                            Preview
                                        </Button>
                                    </Td>
                                    <Td><NoticeUpdateModal auction={x} notice={2} /></Td>
                                </Tr>) :
                                <Tr>
                                    <Td></Td>
                                    <Td></Td>
                                    <Td>Data not available</Td>
                                    <Td></Td>
                                    <Td></Td>
                                </Tr>
                        }
                    </Tbody>
                </Table>
            }
            {
                console.log(auctionDetail)
            }
        </div>
    )
}
