import React from 'react';
import { useState } from 'react';
import { Add } from '@material-ui/icons'
import { Modal, Box, Button, Paper, Typography } from '@material-ui/core';
import urlhelper from '../../../_helpers/urlhelper';
import { history } from '../../../_helpers';
import Save from '@material-ui/icons/Save';
import { uploadFileToBucket } from '../../../_helpers/upload';
import { CircularProgress } from '@material-ui/core'
import { Field, Form, Formik } from 'formik';
import { legalServices } from '../../../_services/legal_cases.services';
const AddDocumnetModal = (props) => {
    console.log("Test Props",props)
  const Id=props.caseInfo._id
  let loanNo=props.caseInfo.loan_no
  console.log("Test Id ",Id)
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "800px",
        height: "300px",
        bgcolor: "#F3F3F3",
        border: "2px solid #000",
        // overflow: "scroll",
        boxShadow: 24,
        p: 4,
    };
    const [open, setOpen] = useState(false)
    const date = new Date()
    const bucket = process.env.REACT_APP_s3docsBucket
    const initialValues = {
        document_type: '',
        url: '',
        file_name:'',
        date:date
    }
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const handleImage = async (e, formik) => {
        let file = e.target.files[0];
        let name = file.name.split('.')[0];
        let randomNum = Math.floor(Math.random() * 1000);
        let fileName = `${name}_${randomNum}.pdf`
        console.log("tset file name", fileName)
        let link = await uploadFileToBucket(bucket, 'application/pdf', `${bucket}/legalcases/${loanNo}/${fileName}`, file);
        formik.setFieldValue('url', link)
        formik.setFieldValue('file_name',file.name)
    }

    const onSubmit = async (values) => {
        await legalServices.addNi138Document(Id,values)
            .then(res => {
                window.alert('Details saved successfully')
                window.location.reload()
            })
            .catch(err => {
                window.alert('Some error occurred')
                console.log(err)
            })
    }
    return (
        <div>
            <Button onClick={handleOpen} 
            style={{ color: '#2949B9',  height: '25px', background: '9990FF',float:'right',textTransform:'capitalize', }}>
            <Add style={{border:"2px solid #2949B9",marginBottom:'5px',height:"20px",textAlign:'center'}}/>&nbsp; Add</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box id="modal-modal-title" variant="h6" component="h2" style={{ display: 'flex', flexDirection: 'column', alignItem: 'center' }}>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                        >
                            {(formik) => (
                                <Form>
                                    <div>
                                        <span style={{ marginTop: '30px', marginBottom: '30px', textDecoration: "underLine", textAlign: 'center' }}>
                                            <h5> Upload Documents</h5>
                                        </span>
                                        <hr />
                                        <div>
                                            <div style={{ display: "flex", justifyContent: "space-around" }}>
                                                <div style={{ display: "flex", flexDirection: "column", }} >
                                                    <label style={{ fontSize: '20px' }}>Documet Type</label>
                                                    <Field
                                                        style={{ width: "260px", height: "30px", fontSize: '20px' }}
                                                        name='document_type'
                                                        type="text"
                                                        required
                                                        
                                                    />
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column", }}>
                                                    <label style={{ fontSize: '20px' }}>Upload Document</label>
                                                    <input
                                                        style={{ width: "260px", height: "30px", fontSize: '20px' }}
                                                        accept=".pdf"
                                                        type="file"
                                                        name="documents"
                                                        onChange={(e) => handleImage(e, formik)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <button type='submit' style={{ color: 'white', background: "#406AFF", marginTop: '70px', marginLeft: '320px',fontSize:"20px",borderRadius:"4px" }}>
                                                <Save /> Save
                                            </button>
                                        </div>
                                        <br />
                                        <br />
                                    </div>
                                </Form>)}
                        </Formik>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}
export default AddDocumnetModal
