import React from 'react';
import { filter } from 'rxjs';
import { makerCheckerServices } from '../../_services/makerchecker.service';
import { AllServices } from './AllServices';

export const ApprovalRequestFilter = {
    RequestFilter
}

function RequestFilter(data) {
    var approval_req = false
    // if approval_needed true then it will goes to pending request otherwise it will check whether approval needed or not
    if (data.approval_needed === true) {
        return makerCheckerServices.newRequest(data)
    } else {
        data.sensitive_fields.map(s => {
            if (data.updated_fields.includes(s)) {
                approval_req = true
            }
        })
    }

    if (approval_req === true) {
        return makerCheckerServices.newRequest(data)
    } else {
        return AllServices[data.service][data.api](data.id, data.new_values)
    }

}