import React from "react";
import { Route, Switch } from "react-router-dom";

// import AddEditCities from "./AddEditCities";
// import CityList from "./CityList";
import {AllCloudLedgerList} from "./AllCloudLedgerList"
import {AllCloudLedgerModal} from "./AllCloudLedgerModal"

function AllCloudLedgerIndex({ match }) {
    console.log("fwghtewretrh",match.path)
  const { path } = match;

  return (
    <Switch>
      <Route exact path={path} component={AllCloudLedgerList} />
      <Route path={`${path}/add`} component={AllCloudLedgerModal} />
     </Switch>
  );
}

export default AllCloudLedgerIndex ;
