import { Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Table, Tbody, Td, Th, Tr } from 'react-super-responsive-table'
import { history } from '../../../_helpers'
import { goldPacketServices } from '../../../_services/gold_packet.service'
import { CheckInAcceptModal } from './CheckInAcceptModal'
import urlhelper from '../../../_helpers/urlhelper';
import roleChecker from '../../../_helpers/rolechecker'
import { employeeService } from '../../../_services'
export const CheckInRequest = () => {
  const [packet, setPacket] = useState([])
  const [employeeList, setEmployeeList] = useState({})
  const user = employeeService.userValue;
  async function getAllEmployees() {
    await employeeService.getAll()
      .then((res) => {
        let list = {};
        res.map(e => {
          list[e.emp_id] = e.first_name + ' ' + e.last_name
        })
        setEmployeeList(list)
      })
  }
  useEffect(() => {
    async function getAllPackets() {
      await goldPacketServices.getAllPackets()
        .then((res) => setPacket(res))
        .catch((err) => console.log(err))
    }
    getAllPackets()
    getAllEmployees()
  }, [])
 const deleteCheckInRequest=async(id)=>{
   await goldPacketServices.delete(id)
   .then((res)=>{
    alert("Check In Request Deleted !!")
    window.location.reload()
  })
   .catch((err)=>alert(err))
 }
console.log("Test all check in packet",packet)
  return (
    <div style={{ margin: "80px", background: 'white' }}>
      <div style={{ margin: '70px' }}>
        <br />
        <h2 style={{ textAlign: "center" }}>Check In</h2>
        <hr />
        <br />
        <div>
          {roleChecker.isGoldRider(user.role)? <div style={{ float: "left", marginBottom: '40px' }}>
            <Button onClick={() => { history.push("/home/goldpacket/checkinform") }} style={{ color: "white", background: "red", width: "180px" }}>Check In Request</Button>
          </div> : null}
          {roleChecker.isHOGoldChecker(user.role) || roleChecker.hasAccountsAccess(user.role) ? <div style={{ float: 'right' }}>
            <CheckInAcceptModal packet={packet} />
          </div> : null}
        </div>
        <br />
        <br />
        <br />  
        <hr />
        <br />
        <Table>
          <thead>
            <Tr>
              <Th>S.N</Th>
              <Th>Name</Th>
              <Th>Loan No</Th>
              <Th>Packet Number</Th>
              <Th>Check In By</Th>
              <Th>Image</Th>
              <Th>Status</Th>
              <Th>Delete</Th>
            </Tr>
          </thead>
          <Tbody>
            {
              packet.length > 0 ?
                packet.filter(checkIn => checkIn.packet_state === "In" && checkIn.checkIn_details && checkIn.checkIn_details.
            checkin_status !=="Accepted").map((x, i) => <Tr key={i}>
                  <Td>{i + 1}</Td>
                  <Td>{x.name}</Td>
                  <Td>{x.loan_no}</Td>
                  <Td>{x.packet_no}</Td>
                  <Td>{x.checkIn_details && employeeList[x.checkIn_details.checkin_by_emp_id]}</Td>
                  <Td><Button style={{ color: "blue" }}
                    onClick={() => history.push(`/home/pdfviewer?pdf_url=` + urlhelper.encodeToBase64(x.photo) + "&download=1")}>
                    View
                  </Button></Td>
                  <Td>Pending</Td>
                 {user.emp_id===x.checkIn_details.checkin_by_emp_id  && <Td><Button onClick={()=>deleteCheckInRequest(x._id)} style={{background:'red',color:'white'}}>Delete</Button></Td>}
                </Tr>)
                : <Tr>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td style={{ color: "red" }}><b>Data not found</b></Td>
                  <Td></Td>
                  <Td></Td>
                </Tr>
            }
          </Tbody>
        </Table>
        <br />
        <br />
      </div>
    </div>
  )
}
