import React from "react";
import { useEffect } from "react";
import { Button, Table } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { useState } from "react";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import datehelper from "../../../_helpers/datehelper";
import { history } from "../../../_helpers"
import { releaseServices } from "../../../_services/releaseletter.service";
import { employeeService } from "../../../_services";
export const ReleaseLetterList = (props) => {
    const loan = props.loan
    const [letterNumber, setLetterNumber] = useState("")
    const [letter, setLetter] = useState([])
    const [employeeList, setEmployeeList] = useState({})
    const handleChangeLetterNumber = (value) => {
        setLetterNumber(value)
    }
    const handleSearchLetter = async () => {
        await releaseServices.searchByLetterNumber({ letter_number: letterNumber })
            .then((res) => {
                setLetter(res)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    console.log("Test List", letter)
    async function getAllEmployees() {
        await employeeService.getAll()
            .then((res) => {
                let list = {};
                res.map(e => {
                    list[e.emp_id] = e.first_name + ' ' + e.last_name
                })
                setEmployeeList(list)
            })
    }
    useEffect(() => {
        async function getAllLetter() {
            await releaseServices.getLetter()
                .then((res) => setLetter(res))
                .catch((err) => console.log(err))
        }
        getAllLetter();
        getAllEmployees();
    }, [])
    return (
        <div style={{ width: "100%" }}>
            <div>
                <h2 style={{ textAlign: "center", marginBottom: "30px", marginTop: "50px" }}> Release Letter </h2>
                <div>
                    <form style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: '40px' }} >
                        <input type="text" placeholder='Enter Letter Number' style={{ borderRadius: '5px' }}
                            value={letterNumber}
                            onChange={(e) => handleChangeLetterNumber(e.target.value)}
                        />
                        <button type='button' style={{ borderRadius: '5px', color: 'white', backgroundColor: '#0066cc', border: 'none', width: "70px", marginLeft: '10px' }}
                            onClick={() => handleSearchLetter()}
                        >
                            <Search />
                        </button>
                    </form>
                </div>
            </div>
            <div>
                {
                    <Table style={{ marginLeft: "auto", width: "90%", marginRight: "auto" }}>
                        <thead>
                            <Tr>
                                <Th>Date</Th>
                                <Th>Letter Number</Th>
                                <Th>Employee</Th>
                                <Th>Preview Letter</Th>
                            </Tr>
                        </thead>
                        <Tbody>
                            {
                                letter.length > 0 ?
                                    letter.map((x, index) => <Tr key={index}>
                                        <Td>{datehelper.displayDate(new Date(x.releaseData.current_date))}</Td>
                                        <Td>{x.releaseData.letter_number}</Td>
                                        {/* <Td>{x.releaseData.first_name}</Td> */}
                                        <Td>{employeeList[x.releaseData.emp_id]}</Td>
                                        <Td>
                                            <Button
                                                style={{ color: "blue" }}
                                                onClick={() =>
                                                    history.push("/home/release", x.releaseData)
                                                }>
                                                preview
                                            </Button>
                                        </Td>
                                    </Tr>) :
                                    <Tr>
                                        <Td></Td>
                                        <Td></Td>
                                        <Td>Data Not available</Td>
                                        <Td></Td>
                                        <Td></Td>
                                    </Tr>
                            }
                        </Tbody>
                    </Table>
                }
            </div>

        </div>
    );
}
