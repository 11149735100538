import React from 'react'
import { TabContext } from '../TabBar';
import { useContext } from "react";
import ApplicationFormDetails from '../../Applications/ApplicationData/ApplicationFormDetails';

const ApplicationForms = () => {

    let TabContextValue = useContext(TabContext)
    let cust = TabContextValue.cust

    return (
        <div>
            <ApplicationFormDetails customer={cust} />
        </div>
    )
}

export default ApplicationForms