import React from 'react'
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse
  } from "@material-ui/core";
  import { ExpandMore,ExpandLess} from "@material-ui/icons";
  import PhoneIcon from '@material-ui/icons/Phone';
import roleChecker from '../../../_helpers/rolechecker';

const CallDetailMenu = (props) => {

    const [open, setOpen] = React.useState(false);
    const { path } = props.match;
    const user =props.user;

    const handleClick = () => {
        setOpen((prev) => !prev);
      };

  return (
    <React.Fragment>
    <ListItem button onClick={handleClick}>
        <ListItemIcon><PhoneIcon /></ListItemIcon>
        <ListItemText primary="Voice Schedule" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" style={{paddingLeft:"50px"}}>
        <ListItem
             hidden={
              !(roleChecker.isCustomerCareTeam(user.role))
            }
            button
            key="list"
            component="a"
            href={`${path}/calldetails/list`}
          >
            <ListItemText primary="Add Call Details" />
          </ListItem>

          {/* <ListItem
            button
            key="bulksms"
            component="a"
            href={`${path}/bulksms`}
          >
            <ListItemText primary="Bulk Sms" />
          </ListItem>
          <ListItem
            button
            key="paymentlink"
            component="a"
            href={`${path}/paymentlink`}
          >
            <ListItemText primary="Payment Link" />
          </ListItem> */}
        </List>
      </Collapse>
      </React.Fragment>
  )
}

export default CallDetailMenu