import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import *as Yup from "yup";
import Alerts from "../_components/uielements/Alerts"
import { employeeService, loanService, alertService } from "../_services";
import ConfirmPassword from "./ConfirmPassword";
import "../css/common.css"
import "../css/login.css"
function ForgetPassword() {
  const [showError, setShowError] = React.useState(false);
  const [showemp_id, setShowemp_id] = useState('');
  const [showpage, setshowpage] = React.useState(true)
  const [showresetpage, setShowresetpage] = React.useState(true)
  const [registerednumber, setRegisterednumber] = React.useState();
  const [showotp, setShowotp] = useState();
  const [otpResponse, setOtpResponse] = useState('')
  const [remainingAccesses, setRemainingAccesses] = useState(null);
  const [otp, setOtp] = useState({ otp1: '', otp2: '', otp3: '', otp4: '' })

  const initialValues = {
    empid: "",
    otp: "",

  };
  const validationSchema = Yup.object().shape({
    empid: Yup.string().required("Empid is required"),
  }
  )

  const changeempid = (e) => {
    setShowemp_id(e.target.value);


  }
  const sendOtpOnForgotPassword = (emp_contact) => {
    alertService.clear();
    setShowError(false)
    let random_num = Math.floor(1000 + Math.random() * 9000);
    // console.log("test otp", random_num)
    loanService.sendOtpOnForgotPassword({ emp_id: showemp_id, contact: emp_contact, otp: random_num })
      .then((data) => {
         
        setOtpResponse(data.otp)
      
        // console.log('data is', data);
        setRemainingAccesses(data.remainingAccesses);
        if (data.remainingAccesses != 0) {

          setshowpage(false)
        }
        setShowError(true);
        // console.log(data.otp)
      })
      .catch((err) => console.log(err))
  }
  function onSubmit({ empid }, { setSubmitting }) {

    // console.log(showemp_id)
    employeeService
      .resetPasswordbyOtp(showemp_id)
      .then((user) => {
        // console.log('response is', user)
        setRegisterednumber(user.contact);
        if (user.blocked === false) {
          sendOtpOnForgotPassword(user.contact);
          //  showError(true)
        }
        
      })
      .catch((error) => {

        setShowError(true);
        setSubmitting(false);
        alertService.error(error);
      });

    // console.log(registerednumber)
  }
  const inputFocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
        const next = elmnt.target.tabIndex - 2;
        if (next > -1) {
            elmnt.target.form.elements[next].focus()
        }
    }
    else {
        // console.log("next");
        const next = elmnt.target.tabIndex;
        if (next < 4) {
            elmnt.target.form.elements[next].focus()
        }
    }
}

const handleChange = (e) => {
    let item = { ...otp }
    item[e.target.name] = e.target.value
    setOtp(item)
}

  const varifyOtp = () => {
    let input_otp = otp.otp1 + otp.otp2 + otp.otp3 + otp.otp4;
    // console.log('input_otp is',input_otp)
     
    // console.log('otp res is', otpResponse)
    if (input_otp.toString() === otpResponse.toString()) {
      setShowresetpage(false);
    } else {
      window.alert('Incorrect Otp')
      setshowpage(true)
      setOtp({ otp1: '', otp2: '', otp3: '', otp4: '' })
    }
  }
  
  return (
    <div className="loginpage">
    <div className="gradentImage">
    <h3 className="welcomeline">WELCOME TO KAABIL FINANCE!!</h3>
    <img
         className="loginvector"
          alt="Company Logo"
          src={require("../assets/Screenshot_2023-06-06_151231-removebg-preview.png")}
         
        ></img>
    </div>
    <div className="loginside">
    <img
         className="loginimage"
          alt="Company Logo"
          src={require("../assets/kaabil.png")}
         
        ></img>
        <div className="formvalue">

        <> 
      {showpage ? <Formik
        initialValues={initialValues}
        // validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <h2 style={{ textAlign: "center" }} className="card-header">
              Forgot Password
            </h2>
            {(showError && (<Alerts message="Unable to  fetch employee id details" />))}
            <div className="card-body">
              <h6>We'll send a 4-digit code to the mobile number registered with employee id </h6>
              <div className="form-group">
                <label style={{fontWeight: '900'}} className="required">Employee ID</label>
                <Field
                  name="empid"
                  type="text"
                  value={showemp_id}
                  onChange={changeempid}
                  placeholder='Enter Your Employee ID'
                  className={
                    "form-control" +
                    (errors.empid && touched.empid ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="empid"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="form-group">

              </div>
              <div className="form-row">
                <div className="form-group col d-flex justify-content-center">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-primary"
                  >
                    {isSubmitting && (
                      <span className="spinner-border spinner-border-sm mr-1"></span>
                    )}
                    Send OTP
                  </button>
                </div>
              </div>
            </div>
            {<h6 style={{ textAlign: "center" }}>You can send  OTPs 3 times per day {remainingAccesses != null ? <> {remainingAccesses > 0 ? <h6>{remainingAccesses - 1} attempt remaining</h6> : <p>You have used all attempts. Come back tomorrow</p>}</>:null} </h6>}
          </Form>
        )}
      </Formik> : <>
        {showresetpage ? <div>
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            onSubmit={varifyOtp}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <h2 style={{ textAlign: "center" }} className="card-header">
                Forgot Password
                </h2>
                {/* {(showError && (<Alerts message="Unable to Reset" />))} */}
                <div className="card-body">
                  <h6>A 4 digit code has been sent to the mobile number : xxxxxx{registerednumber && registerednumber.toString().slice(-4) && <>{registerednumber.toString().slice(-4)}</>} registered with emp id {showemp_id}</h6>
                
                  <div style={{ display:'flex',alignContent:'center',justifyContent:'center',marginTop:'2rem'}} className="form-group">
                  <div
                                        style={{
                                            width: '250px',
                                            display: 'flex',
                                            marginBottom: '20px',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <input
                                            style={{ border: '1px solid Black', width: '40px', height: '45px', borderRadius: '5px', textAlign: 'center' }}
                                            autoComplete='off'
                                            name='otp1'
                                            tabIndex='1'
                                            maxLength='1'
                                            type="text"
                                            value={otp.otp1}
                                            onKeyUp={e => inputFocus(e)}
                                            onChange={e => handleChange(e)}
                                            required />
                                        <input
                                            style={{ border: '1px solid Black', width: '40px', height: '45px', borderRadius: '5px', textAlign: 'center' }}
                                            autoComplete='off'
                                            name="otp2"
                                            tabIndex='2'
                                            maxLength='1'
                                            type="text"
                                            value={otp.otp2}
                                            onKeyUp={e => inputFocus(e)}
                                            onChange={e => handleChange(e)}
                                            required />
                                        <input
                                            style={{ border: '1px solid Black', width: '40px', height: '45px', borderRadius: '5px', textAlign: 'center' }}
                                            autoComplete='off'
                                            name='otp3'
                                            tabIndex='3'
                                            maxLength='1'
                                            type="text"
                                            value={otp.otp3}
                                            onKeyUp={e => inputFocus(e)}
                                            onChange={e => handleChange(e)}
                                            required />
                                        <input
                                            style={{ border: '1px solid Black', width: '40px', height: '45px', borderRadius: '5px', textAlign: 'center' }}
                                            autoComplete='off'
                                            name='otp4'
                                            tabIndex='4'
                                            maxLength='1'
                                            type="text"
                                            value={otp.otp4}
                                            onKeyUp={e => inputFocus(e)}
                                            onChange={e => handleChange(e)}
                                            required />
                                    </div>
                  </div>

                 
                  <div className="form-row">
                    <div className="form-group col d-flex justify-content-center">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-primary"
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-1"></span>
                        )}
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div> : <>
          <>
           <ConfirmPassword showemp_id={showemp_id}/>
          </>
        </>}</>}
    </>
        </div>
    </div>
      
    </div>
  );
}

export default ForgetPassword;
