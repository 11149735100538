import React, { useEffect, useState } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Button } from '@material-ui/core';
import { employeeService, misService } from '../../../_services';
import datehelper from '../../../_helpers/datehelper';
import roleChecker from "../../../_helpers/rolechecker";
const LoanRenewRequest = (props) => {
    const user = employeeService.userValue
    const [renewRequest, setRenewRequest] = useState([])
    const [employees, setEmployees] = useState({})
    const [employeeList, setEmployeeList] = useState({})
    const getAllRenewLoanRequest = async () => {
        await misService.getAllRenewLoanRequest()
            .then((res) => setRenewRequest(res))
            .catch((err) => console.log(err))
    }
    const approveRenewRequest = async (id) => {
    if (window.confirm("Are you sure want to Approve?")) {
        await misService.approveRenewRequest(id)
            .then((res) => {
                alert("Renew Request Approved !!")
                window.location.reload()
            })
            .catch((err) => console.log(err))
        }
    }

    async function getAllEmployees() {
        await employeeService.getAll()
            .then((res) => {
                let list = {};
                res.map(e => {
                    list[e.emp_id] = e.first_name + ' ' + e.last_name
                })
                setEmployeeList(list)
            })
    }

    console.log("Test", renewRequest)
    const renewLoan = (loan_account, net_due_amount) => {
        props.history.push(`/home/pledge?renew_of=${loan_account}&net_due_amount=${net_due_amount}`)
    }
    useEffect(() => {
        getAllRenewLoanRequest()
        getAllEmployees()
    }, [])
    return (
        <div style={{ margin: '30px' }}>
            <Table style={{ border: '2px solid blue' }}>
                <Thead style={{ background: "#2949B9", color: 'white', height: '50px' }}>
                    <Tr>
                        <Th>Loan No</Th>
                        <Th>Loan Status</Th>
                        <Th>Net Due amount</Th>
                        <Th>Branch</Th>
                        <Th>Request By</Th>
                        <Th>Approved By</Th>
                        <Th>Renew Status</Th>
                        <Th>Action</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {renewRequest.length > 0 ? renewRequest.filter(checkbranch =>
                        (checkbranch.branch_id === user.branch_id) || (user.branch_id === "HO001")).map((x, idx) =>
                            <>
                                <Tr key={idx} style={{ background: '#D9DDF5', height: "50px", fontWeight: 'bold', fontSize: "18px" }}>
                                    <Td>{x.loan_account}</Td>
                                    <Td>{x.loan && x.loan.loan_status}</Td>
                                    <Td>{x.net_due_amount.toLocaleString("en-IN", { style: 'currency', currency: 'INR' })}</Td>
                                    <Td>{x.branch_id}</Td>
                                    <Td>{employeeList[x.renew_request_by]}</Td>
                                    <Td>{x.renew_approve_by ? employeeList[x.renew_approve_by] : (roleChecker.hasAccountsAccess(user.role) && <Button onClick={() => approveRenewRequest(x._id)} style={{ color: 'blue' }}>Approve</Button>)}</Td>
                                    <Td style={{ color: x.renew_status === "Pending" ? "yellow" : "green" }}>{x.renew_status}</Td>
                                    <Td>{((x.renew_status === "Approved" && (x.loan && x.loan.loan_status !== "Closed")) && x.renew_request_by === user.emp_id) && <Button onClick={() => renewLoan(x.loan_account, x.net_due_amount)} style={{ color: 'red' }}>Renew Loan</Button>}</Td>
                                </Tr>
                                <br />
                            </>) : <Tr>
                        <Td colSpan="6" style={{ textAlign: "center", fontWeight: "bold" }}>Data Not Found</Td>
                    </Tr>}
                </Tbody>
            </Table>
        </div>
    )
}

export default LoanRenewRequest
