import React from "react";
import SanctionLetter from "../SBL/SanctionLetter";
import { useReactToPrint } from "react-to-print";

export default function GoldSanctionPrint(props) {
  const compRef = React.useRef();

  console.log(props.location.state);

  const handlePrint = useReactToPrint({
    content: () => compRef.current,
  });

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <button onClick={handlePrint}>Print Sanction Letter</button>
      </div>
      <SanctionLetter
        ref={compRef}
        dataFill={props.location.state}
      ></SanctionLetter>
    </div>
  );
}
