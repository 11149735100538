// import React from "react";
// import { useState, useEffect } from "react";
// import roleChecker from "../_helpers/rolechecker";
// import { employeeService, misService ,customerService} from "../_services";
// import { BarChart, PieChart ,Pie , Cell ,Bar, CartesianGrid, XAxis, YAxis, Legend, Tooltip, LabelList, ResponsiveContainer } from "recharts";
// import { reportsService } from "../_services/reports.service";
// import Product from "../_helpers/product";
// import { Button } from "@material-ui/core";
// import { history } from "../_helpers";
// import { Paper } from "@material-ui/core";
// import displayhelper from "../_helpers/displayhelper";
// import datehelper from "../_helpers/datehelper";
// function MonthlyTarget() {
//   const user = employeeService.userValue;
//   const [metricsData, setMetricsData] = useState({})
//   const [time, setTime] = useState(Date.now());
//   let todayDate= new Date()
//   let currentDate= new Date();
//   let todayEnd= datehelper.getDateEnd(todayDate);
//   let hours=Math.floor(datehelper.getDateDiffSeconds(todayEnd,currentDate)/3600);
//   let minutes=Math.floor((datehelper.getDateDiffSeconds(todayEnd,currentDate)-hours*3600)/60);
//   let seconds=Math.floor((datehelper.getDateDiffSeconds(todayEnd,currentDate)-hours*3600-minutes*60));
  
//   // console.log("hourse left="+hours);
//   const [hourLeft,setHourLeft]= useState(hours);
//   const [minuteLeft,setMinuteLeft]= useState(minutes);
//   const [secondLeft,setSecondLeft]= useState(seconds);
//   const [loading,setLoading]=useState(true);
//   let branchId='';
//   let matrix_key='';
//   const [chartData, setChartData] = useState({})
//   let colors = ['#3C2A21', '#FFA500', '#5D8AA8', '#46C2CB', '#800000','#2D87BB','#64C2A6',"#547ac4"]
  
//   useEffect(() => {
//     loadMetrics()
//   }, [])

//   async function loadMetrics()
//   {

//     setLoading(true);
//     if (roleChecker.isHOLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isASMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isBCMLevelAccess(user.role) || roleChecker.isCSMLevelAccess(user.role) || roleChecker.isRSMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) || roleChecker.isSalesTeamAccess(user.role)) {

//       if (roleChecker.isHOLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) ) {
//         console.log('User role ---->>> ', user.role, user.branch_id)
//         branchId = 'zone-' + user.branch_id
//         matrix_key = user.branch_id
//         // branchId = 'Zone-J03'
//       } 

//       else if (roleChecker.isRSMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role)) {
//         console.log('User role ---->>> ', user.role, user.branch_id)
//         branchId = 'region-' + user.branch_id
//         matrix_key = user.branch_id
//       }
//       else if (roleChecker.isAreaLevelAccess(user.role)) {
//         console.log('User role ---->>> ', user.role, user.branch_id)
//         branchId = 'area-' + user.branch_id
//         matrix_key = user.branch_id
//       }
//       else if (roleChecker.isCSMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role)) {
//         console.log('User role ---->>> ', user.role, user.branch_id)
//         branchId = 'cluster-' + user.branch_id
//         matrix_key = user.branch_id
//       }
//       else if (roleChecker.isBMLevelAccess(user.role)) {
//         branchId = 'branch-' + user.branch_id
//         matrix_key = user.branch_id
//         console.log('User role ---->>> ', user.role, user.branch_id,matrix_key)
//       }

//       else if (roleChecker.isBCMLevelAccess(user.role)) {
//         branchId = 'branch-' + user.branch_id
//         matrix_key = user.branch_id
//         console.log('User role ---->>> ', user.role, user.branch_id,matrix_key)
//       }

//       else if (roleChecker.isSalesTeamAccess(user.role)) {
//         branchId = 'branch-' + user.branch_id
//         matrix_key = user.branch_id
//         console.log('User role ---->>> ', user.role, user.branch_id,matrix_key)
//       }


//       else {
//         branchId = user.branch_id
//          console.log('User role ---->>> ', user.role, user.branch_id, branchId)
//       }

//       reportsService.getRealMetricsData({ 'key':'login_count', 'branch_key':branchId}).
//       then((metricListObj) => {
//         console.log(metricListObj);
//         let tmpMetricsData={}
//         metricListObj.map((mo)=> {
//           tmpMetricsData[mo.key]=mo.display_data
//         })
//         console.log(tmpMetricsData);
//         setMetricsData(tmpMetricsData);
//       })
//       .catch((err)=> {
//         console.log(err);
//       })
//     }
     
//   }

// function reloadPage()
// {
//    setTime(new Date());
//    console.log("reloading ...");
//    window.location.reload(true);
// }

// function reloadTime()
// {
//   todayDate= new Date()
//   currentDate= new Date();
//   todayEnd= datehelper.getDateEnd(todayDate);
//   let secondsTotal=datehelper.getDateDiffSeconds(todayEnd,currentDate)
//   // console.log("total seconds"+secondsTotal);
//   hours=Math.floor(secondsTotal/3600);
//   let minuteSeconds=secondsTotal-(hours*3600)
//   // console.log("total seconds in minutes"+minuteSeconds);
//   minutes=Math.floor(minuteSeconds/60);
//   seconds=Math.floor(secondsTotal-hours*3600-minutes*60);
//   setHourLeft(hours)
//   setMinuteLeft(minutes)
//   setSecondLeft(seconds)
// }

// useEffect(() => {
//   const interval = setInterval(() => reloadPage(), 120000);
//   return () => {
//     clearInterval(interval);
//   };
// }, []);

// useEffect(() => {
//   const interval = setInterval(() => reloadTime(), 1000);
//   return () => {
//     clearInterval(interval);
//   };
// }, []);

//   const percentageLabelFormatter = (value) => {
//     return value + '%';
//   };

//   const [targets, setTargets] = useState([]);
//   const [getTargets, setGetTargets] = useState({});
//   const [sanctionLetterCount, setSanctionLetterCount] = useState(null);
//   const [sanctionLetterAmount, setSanctionLetterAmount] = useState(null);
//   const [disburseMemoCount, setDisburseMemoCount] = useState(null);
//   const [disburseMemoAmount, setDisburseMemoAmount] = useState(null);
//   const [colenderReceiveCount, setColenderReceiveCount] = useState(null);
//   const [colenderReceiveAmount, setColenderReceiveAmount] = useState(null);
//   const [loginMonthCount, setLoginMonthCount] = useState(null);  
//   const searchTarget = async () => {
//     setLoading(true);
//     let branchId;

//     if (roleChecker.isHOLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isASMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isBCMLevelAccess(user.role) || roleChecker.isCSMLevelAccess(user.role) || roleChecker.isRSMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) || roleChecker.isSalesTeamAccess(user.role)) {
//       if (roleChecker.isHOLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role)) {
//         branchId = 'zone-' + user.branch_id;
//       } else if (roleChecker.isRSMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role)) {
//         branchId = 'region-' + user.branch_id;
//       } else if (roleChecker.isAreaLevelAccess(user.role)) {
//         branchId = 'area-' + user.branch_id;
//       } else if (roleChecker.isCSMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role)) {
//         branchId = 'cluster-' + user.branch_id;
//       } else if (roleChecker.isBMLevelAccess(user.role) || roleChecker.isBCMLevelAccess(user.role) || roleChecker.isSalesTeamAccess(user.role)) {
//         branchId = 'branch-' + user.branch_id;
//       } else {
//         branchId = user.branch_id;
//       }

//       try {
//         const res = await customerService.searchTarget({ branch_key: branchId });
//         console.log(res, "Raw API Response");

//         // Check if res is a JSON string and parse it
//         let parsedResponse;
//         try {
//           parsedResponse = typeof res === 'string' ? JSON.parse(res) : res;
//         } catch (e) {
//           console.error('Error parsing response:', e);
//           parsedResponse = res;
//         }

//         if (Array.isArray(parsedResponse)) {
//           console.log(parsedResponse, "Parsed Response");
//           setTargets(parsedResponse);
//         } else {
//           console.error('Data fetched is not an array:', parsedResponse);
//         }


//         const ress = await customerService.searchTargets({ branch_key: branchId });
//         let parsedResponses = typeof ress === 'string' ? JSON.parse(ress) : ress;

  
//         if (Array.isArray(parsedResponses)) {
//           console.log("Parsed Responses:", parsedResponses);
          
//           // Get current month and year dynamically
//           const now = new Date();
//           const month = now.getMonth() + 1; // getMonth() is zero-based
//           const year = now.getFullYear();
//           const currentMonthYear = `${month}-${year}`; // Format for other keys
//           const currentMonthYears = `${year}-${month.toString().padStart(2, '0')}`; // Format for login_month_count
        
//           // Extract relevant keys
//           const keysToExtract = ["disburse_month_amount", "sanction_letter_month_amount", "login_month_count", "colender_receive_monthly_data"];
        
//           parsedResponses.forEach(item => {
//             if (keysToExtract.includes(item.key)) {
//               try {
//                 const displayDatas = JSON.parse(item.display_data);
//                 console.log("Item Key:", item.key);
        
//                 // Determine the key to use based on item.key
//                 const keyToCheck = item.key === "login_month_count" ? currentMonthYears : currentMonthYear;
        
//                 if (displayDatas[keyToCheck]) {
//                   const { count, amount } = displayDatas[keyToCheck];
//                   setGetTargets(`Key: ${item.key}, Count: ${count}, Amount: ${amount}`);
        
//                   if (item.key === 'sanction_letter_month_amount') {
//                     setSanctionLetterCount(count);
//                     setSanctionLetterAmount(amount);
//                   } else if (item.key === 'login_month_count') {
//                     console.log("Login Month Count:", count);
//                     setLoginMonthCount(count);
//                   } else if (item.key === 'disburse_month_amount') {
//                     setDisburseMemoCount(count);
//                     setDisburseMemoAmount(amount);
//                   } else if (item.key === 'colender_receive_monthly_data') {
//                     setColenderReceiveCount(count);
//                     setColenderReceiveAmount(amount);
//                   }
//                 } else {
//                   console.log(`No data for ${keyToCheck} in key: ${item.key}`);
//                 }
//               } catch (e) {
//                 console.error('Error parsing display_data:', e);
//               }
//             }
//           });
//         } else {
//           console.error('Data fetched is not an array:', parsedResponses);
//         }
  
//       } catch (err) {
//         console.error('Error fetching data:', err);
//       }
//     }

//     setLoading(false);
//   };

//   useEffect(() => {
//     searchTarget();
//   }, []);

// console.log(getTargets,"getTargetsgetTargetsgetTargets")
  
//   return (
//     <div className="p-4">
//         <div className="container" style={{display: 'flex', justifyContent: 'center'}}>

//           {metricsData && Object.keys(metricsData).length>0 && (
//             <Paper style={{width:'710px', borderRadius: '10px', margin: '3px', padding: '3px' }}>
//             <h3 style={{textAlign:'center',fontWeight:'bold'}}>Monthly Achievements</h3>
//             <h4>Time Left: <span style={{color:"red",fontSize:"2rem"}}>{hourLeft} Hours : {minuteLeft} Minutes: {secondLeft} Seconds </span></h4>
//             <hr style={{ width: '100%' }} />
            
//           <hr style={{ width: '100%' }} />

//           { (roleChecker.isHOLevelAccess(user.role) || roleChecker.isGMLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isASMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isCSMLevelAccess(user.role) || roleChecker.isRSMLevelAccess(user.role) || roleChecker.isSalesTeamAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) )&&   
          
//           <div style={{ display: 'flex',marginBottom:"5px" }}>
//                   <div style={{ width: '620px', height: '50px', backgroundColor: colors[7], borderRadius: '10px', color: 'white', margin: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
//                       <b style={{fontSize:"30px"}}>Total Logins :  {loginMonthCount}</b>
//                     </div>
//             </div>}
//             {loading ? (
//         <p>Loading...</p>
//       ) : targets.length > 0 ? (
//         <div style={{ display: 'flex', flexWrap: 'wrap'}}>
//           {targets.map((target, index) => (
//             <div key={target._id} style={{
//  display: 'flex',
//  width: '300px',
//  height: '100px',
//  backgroundColor: colors[index % colors.length], // Cycle through colors
//  borderRadius: '10px',
//  color: 'white',
//  margin: '10px',
//  flexDirection: 'column',
//  alignItems: 'center',
//  justifyContent: 'space-around',
//  fontSize: '1.5rem',
//  padding: '10px'
//             }}>
//               <b style={{ fontSize: '18px' }}>
//                {target.key === "login_target" ? "Login Target" : target.key === "disbursement_target" ? "Disbursement Target (in Lakhs)" : ""}
//               </b>
//               {target.approval_status==="approved"? target.monthly_target:0}
//             </div>
//           ))}
//         </div>
//       ) : (
//         < div style={{ display: 'flex' }}>
//         <div style={{ width: '300px', height: '100px', backgroundColor: colors[0], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
//             <b style={{fontSize:'18px'}}>Disbursement Target (in Lakhs)</b>
//             0
//           </div>

//           <div style={{ width: '300px', height: '100px', backgroundColor: colors[1], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
//                      <b style={{fontSize:'18px'}}>Login Target</b>
//                     0
//                    </div>
//   </div>
//       )}
//         {  (roleChecker.isHOLevelAccess(user.role) || roleChecker.isGMLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isASMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isBCMLevelAccess(user.role) || roleChecker.isCSMLevelAccess(user.role) || roleChecker.isRSMLevelAccess(user.role)|| roleChecker.isZSMLevelAccess(user.role)  || roleChecker.isSalesTeamAccess(user.role))&&    
        
//         < div style={{ display: 'flex' }}>
//                  { <div style={{ width: '300px', height: '100px', backgroundColor: colors[0], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
//                       <b style={{fontSize:'18px'}}>Sanction Letter Count</b>
//                       {sanctionLetterCount !== null && <div>{sanctionLetterCount}</div>}
//                     </div>}

//                     { <div style={{ width: '300px', height: '100px', backgroundColor: colors[0], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
//                       <b style={{fontSize:'18px'}}>Sanction Letter Amount</b>
//                       {sanctionLetterAmount !== null && <div>{sanctionLetterAmount}</div>}
//                     </div>}
//             </div>}

//            { (roleChecker.isHOLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isASMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isCSMLevelAccess(user.role) || roleChecker.isRSMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) )&&
           
//            <div style={{ display: 'flex' }}>
//                  <div style={{ width: '300px', height: '100px', backgroundColor: colors[3], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
//                       <b style={{fontSize:'18px'}}>Disbursement Memo Count</b>
//                       {disburseMemoCount !== null && <div>{disburseMemoCount}</div>}
//                     </div>

//                   <div style={{ width: '300px', height: '100px', backgroundColor: colors[3], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
//                       <b style={{fontSize:'18px'}}>Disbursement Memo Amount</b>
//                       {disburseMemoAmount !== null && <div>{disburseMemoAmount}</div>}
//                     </div>


//             </div>}

//            {(roleChecker.isHOLevelAccess(user.role) || roleChecker.isASMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isCSMLevelAccess(user.role) || roleChecker.isRSMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) )&& 
           
//            <div style={{ display: 'flex' }}>
//                   <div style={{ width: '300px', height: '100px', backgroundColor: colors[1], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
//                       <b style={{fontSize:'18px'}}>Colender Receive Count</b>
//                       {colenderReceiveCount !== null && <div>{colenderReceiveCount}</div>}
//                     </div>

//                     <div style={{ width: '300px', height: '100px', backgroundColor: colors[1], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
//                       <b style={{fontSize:'18px'}}>Colender Receive Amount</b>
//                       {colenderReceiveAmount !== null && <div>{colenderReceiveAmount}</div>}
//                     </div>


//             </div>}
// {/* 
//             {(roleChecker.isHOLevelAccess(user.role) || roleChecker.isASMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isCSMLevelAccess(user.role) || roleChecker.isRSMLevelAccess(user.role))&& 
           
//            <div style={{ display: 'flex' }}>
//                   <div style={{ width: '300px', height: '100px', backgroundColor: colors[1], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
//                       <b style={{fontSize:'20px'}}>Collection Receive Count</b>
//                       {metricsData['colender_count']}
//                     </div>

//                     <div style={{ width: '300px', height: '100px', backgroundColor: colors[1], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
//                       <b style={{fontSize:'20px'}}>Collection Receive Amount</b>
//                       { displayhelper.displayNumberAccounting(Number(metricsData['colender_amount']))}
//                     </div>


//             </div>} */}
//             </Paper>
//           )} 
//           </div>
//     </div>
//   );
// }

// export default MonthlyTarget;














import React from "react";
import { useState, useEffect } from "react";
import roleChecker from "../_helpers/rolechecker";
import { employeeService, misService,customerService } from "../_services";
import { branchService } from "../_services/branch.service";
import { reportsService } from "../_services/reports.service";
import displayhelper from "../_helpers/displayhelper";
import datehelper from "../_helpers/datehelper";
import usericon from "../assets/user.png"

function Dashboard2(){
  const user = employeeService.userValue;
  const [metricsData, setMetricsData] = useState({})
  const [metricsData1, setMetricsData1] = useState({})
  const [metricsZoneNameData1, setMetricsZoneNameData1] = useState({})
  const [time, setTime] = useState(Date.now());
  let todayDate= new Date()
  let currentDate= new Date();
  let todayEnd= datehelper.getDateEnd(todayDate);
  let hours=Math.floor(datehelper.getDateDiffSeconds(todayEnd,currentDate)/3600);
  let minutes=Math.floor((datehelper.getDateDiffSeconds(todayEnd,currentDate)-hours*3600)/60);
  let seconds=Math.floor((datehelper.getDateDiffSeconds(todayEnd,currentDate)-hours*3600-minutes*60));
  const [hourLeft,setHourLeft]= useState(hours);
  const [minuteLeft,setMinuteLeft]= useState(minutes);
  const [secondLeft,setSecondLeft]= useState(seconds);
  const [loading,setLoading]=useState(true);
  const [allBranch,setAllBranch]=useState([])
  let branchId='';
  let matrix_key='';
  const [chartData, setChartData] = useState({})
  const now = new Date();
  const month = now.getMonth() + 1; // getMonth() is zero-based
  const year = now.getFullYear();
  const currentMonthYearss = `${month}-${year}`;
  console.log(currentMonthYearss,"currentMonthYearss")
  useEffect(() => {
    loadMetrics()

    branchService.getAll()
    .then((res)=>{setAllBranch(res); console.log('all branches',res)})
    .catch(err=>console.log(err))
  }, [])

  async function loadMetrics()
  {
    setLoading(true);
    if (roleChecker.isHOLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isASMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isBCMLevelAccess(user.role) || roleChecker.isCSMLevelAccess(user.role) || roleChecker.isRSMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) || roleChecker.isSalesTeamAccess(user.role)) {

      if (roleChecker.isHOLevelAccess(user.role)) {
        // console.log('User role ---->>> ', user.role, user.branch_id)
        branchId = 'head_office-HO'
        matrix_key = 'all'
      } 
      else if (roleChecker.isZCMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) ) {
        // console.log('User role ---->>> ', user.role, user.branch_id)
        branchId = 'zone-' + user.branch_id
        matrix_key = user.branch_id
      }  

      else if (roleChecker.isRSMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role)) {
        console.log('User role ---->>> ', user.role, user.branch_id)
        branchId = 'region-' + user.branch_id
        matrix_key = user.branch_id
      }
      else if (roleChecker.isAreaLevelAccess(user.role)) {
        console.log('User role ---->>> ', user.role, user.branch_id)
        branchId = 'area-' + user.branch_id
        matrix_key = user.branch_id
      }
      else if (roleChecker.isCSMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role)) {
        console.log('User role ---->>> ', user.role, user.branch_id)
        branchId = 'cluster-' + user.branch_id
        matrix_key = user.branch_id
      }
      else if (roleChecker.isBMLevelAccess(user.role)) {
        branchId = 'branch-' + user.branch_id
        matrix_key = user.branch_id
        console.log('User role ---->>> ', user.role, user.branch_id,matrix_key)
      }

      else if (roleChecker.isBCMLevelAccess(user.role)) {
        branchId = 'branch-' + user.branch_id
        matrix_key = user.branch_id
        console.log('User role ---->>> ', user.role, user.branch_id,matrix_key)
      }

      else if (roleChecker.isSalesTeamAccess(user.role)) {
        branchId = 'branch-' + user.branch_id
        matrix_key = user.branch_id
        console.log('User role ---->>> ', user.role, user.branch_id,matrix_key)
      }


      else {
        branchId = user.branch_id
         console.log('User role ---->>> ', user.role, user.branch_id, branchId)
      }

      reportsService.getRealMetricsData({ 'key':'login_count', 'branch_key':branchId}).
      then((metricListObj) => {
        console.log(metricListObj);
        let tmpMetricsData={}
        metricListObj.map((mo)=> {
          tmpMetricsData[mo.key]=mo.display_data
        })
        console.log(tmpMetricsData);
        setMetricsData1(tmpMetricsData);
      })
      .catch((err)=> {
        console.log(err);
      })
      reportsService.getMetricsData({ 'metric_key': "all" }).
      then((metricListObj) => {
        console.log('metricListObjmetricListObjmetricListObjmetricListObj',metricListObj);
        let tmpMetricsData={}
        metricListObj.map((mo)=> {
          tmpMetricsData[mo.key]=mo.display_data
        })
        console.log('tmpMetricsDatatmpMetricsDatatmpMetricsDatatmpMetricsData',tmpMetricsData);
        setMetricsData(tmpMetricsData);
      })
      .catch((err)=> {
        console.log(err);
      })
    }
      
    }
     
  
function reloadPage()
{
   setTime(new Date());
   console.log("reloading ...");
   window.location.reload(true);
}


function reloadTime()
{
  todayDate= new Date()
  currentDate= new Date();
  todayEnd= datehelper.getDateEnd(todayDate);
  let secondsTotal=datehelper.getDateDiffSeconds(todayEnd,currentDate)
  console.log("total seconds"+secondsTotal);
  hours=Math.floor(secondsTotal/3600);
  let minuteSeconds=secondsTotal-(hours*3600)
  console.log("total seconds in minutes"+minuteSeconds);
  minutes=Math.floor(minuteSeconds/60);
  seconds=Math.floor(secondsTotal-hours*3600-minutes*60);
  setHourLeft(hours)
  setMinuteLeft(minutes)
  setSecondLeft(seconds)
}

useEffect(() => {
  const interval = setInterval(() => reloadPage(), 120000);
  return () => {
    clearInterval(interval);
  };
}, []);

useEffect(() => {
  const interval = setInterval(() => reloadTime(), 1000);
  return () => {
    clearInterval(interval);
  };
}, []);

  const percentageLabelFormatter = (value) => {
    return value + '%';
  };

  let asmData;

  if(metricsData && metricsData.SG26_area_login_count){
      asmData = JSON.parse(metricsData.SG26_area_login_count)
  }

  let regionData;

  if(metricsData && metricsData.region_login_count){
    regionData = JSON.parse(metricsData.region_login_count)
  }
  let zoneData = [];
  let m = new Map();
  
  if (metricsData && metricsData.zone_login_count) {
      const parsedData = JSON.parse(metricsData.zone_login_count);
      zoneData = Object.entries(parsedData);
      for (let i = 0; i < zoneData.length; i++) {
          for (let j = 0; j < allBranch.length; j++) {
              if (zoneData[i][0]== allBranch[j].branch_id) {
                  m[zoneData[i][0]] =  allBranch[j].branch_name;
                  break; // Exit the inner loop once a match is found
              }
          }
      }
  }

  var countDetailsData;

  if(metricsData && metricsData.total_count_details){
    countDetailsData = JSON.parse(metricsData.total_count_details)
  }

  var rostarData;

  if(metricsData && metricsData.star_ro_login){
    rostarData = JSON.parse(metricsData.star_ro_login)
  }

  var collectionData;

  if(metricsData && metricsData.collection_receive_emis){
    collectionData = JSON.parse(metricsData.collection_receive_emis)
  }

  var weekstarData;

  if(metricsData && metricsData.week_ro_star){
    weekstarData = JSON.parse(metricsData.week_ro_star)
  }


 if(rostarData){
  console.log(rostarData)
 }


 const [targets, setTargets] = useState([]);
 const [zoneDetails, setZoneDetails] = useState([]);
  const [getTargets, setGetTargets] = useState({});
  const [sanctionLetterCount, setSanctionLetterCount] = useState(null);
  const [sanctionLetterAmount, setSanctionLetterAmount] = useState(null);
  const [disburseMemoCount, setDisburseMemoCount] = useState(null);
  const [disburseMemoAmount, setDisburseMemoAmount] = useState(null);
  const [loginMonthAmount, setLoginMonthAmount] = useState(null);
  const [loginMonthCount, setLoginMonthCount] = useState(null);  
  const [colenderMonthCount, setColenderMonthCount] = useState(null);  
  const [colenderMonthAmount, setColenderMonthAmount] = useState(null);  
  const zoneMapping = {
    'D01': 'DAUSA',
    'AJ13': 'AJMER',
    'J03': 'JAIPUR'
  };
  const predefinedZones = {
    'D01': { zone: 'D01', monthly_achievement: 0 },
    'AJ13': { zone: 'AJ13', monthly_achievement: 0 },
    'J03': { zone: 'J03', monthly_achievement: 0 }
  };
  const searchTarget = async () => {
    setLoading(true);
    let branchId;

    if (roleChecker.isHOLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isASMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isBCMLevelAccess(user.role) || roleChecker.isCSMLevelAccess(user.role) || roleChecker.isRSMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) || roleChecker.isSalesTeamAccess(user.role)) {
      if (roleChecker.isHOLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role)) {
        branchId = 'zone-' + user.branch_id;
      } else if (roleChecker.isRSMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role)) {
        branchId = 'region-' + user.branch_id;
      } else if (roleChecker.isAreaLevelAccess(user.role)) {
        branchId = 'area-' + user.branch_id;
      } else if (roleChecker.isCSMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role)) {
        branchId = 'cluster-' + user.branch_id;
      } else if (roleChecker.isBMLevelAccess(user.role) || roleChecker.isBCMLevelAccess(user.role) || roleChecker.isSalesTeamAccess(user.role)) {
        branchId = 'branch-' + user.branch_id;
      } else {
        branchId = user.branch_id;
      }
      const searchParams = [
        { branch_key: 'zone-AJ13', key: 'login_target',month_key:currentMonthYearss },
        { branch_key: 'zone-J03', key: 'login_target' ,month_key:currentMonthYearss},
        { branch_key: 'zone-D01', key: 'login_target' ,month_key:currentMonthYearss}
      ];
      try {
        // 1st api call
        let res;
        if (user.branch_id === 'HO001') {
          res = await customerService.searchTarget({ branch_key: user.branch_id });
        } else {
          res = await customerService.searchTarget({ branch_key: branchId });
        }
        // const res = await customerService.searchTarget({ branch_key: branchId });
        console.log(res, "Raw API Response");

        // Check if res is a JSON string and parse it
        let parsedResponse;
        try {
          parsedResponse = typeof res === 'string' ? JSON.parse(res) : res;
        } catch (e) {
          console.error('Error parsing response:', e);
          parsedResponse = res;
        }

        if (Array.isArray(parsedResponse)) {
          console.log(parsedResponse, "Parsed Response");
          setTargets(parsedResponse);
        } else {
          console.error('Data fetched is not an array:', parsedResponse);
        }

// 2nd api call
      
const results = [];


for (const params of searchParams) {
  const res = await customerService.searchTarget(params);
  const parsedResponse = typeof res === 'string' ? JSON.parse(res) : res;

  if (Array.isArray(parsedResponse)) {
    results.push(...parsedResponse);
  } else {
    console.error('Data fetched is not an array:', parsedResponse);
  }
}

// Update predefinedZones with actual data
results.forEach(result => {
  const zoneCode = result.zone;
  if (predefinedZones[zoneCode]) {
    predefinedZones[zoneCode].monthly_achievement = result.monthly_achievement;
  }
});

console.log(Object.values(predefinedZones),"results")
setZoneDetails(Object.values(predefinedZones)); // Assuming setTargets is a state setter for the results



// 3rd api call

        // const ress = await customerService.searchTargets({ branch_key: branchId });
        let ress;
      if (user.branch_id === 'HO001') {
        ress = await customerService.searchTargets({ branch_key: user.branch_id });
      } else {
        ress = await customerService.searchTargets({ branch_key: branchId });
      }
        let parsedResponses = typeof ress === 'string' ? JSON.parse(ress) : ress;

  
        if (Array.isArray(parsedResponses)) {
          console.log("Parsed Responses:", parsedResponses);
          
          // Get current month and year dynamically
          const now = new Date();
          const month = now.getMonth() + 1; // getMonth() is zero-based
          const year = now.getFullYear();
          const currentMonthYear = `${month}-${year}`; // Format for other keys
          const currentMonthYears = `${year}-${month.toString().padStart(2, '0')}`; // Format for login_month_count
        
          // Extract relevant keys
          const keysToExtract = ["disburse_month_amount", "sanction_letter_month_amount", "login_month_count","login_month_amount", "colender_month_amount"];
        
          parsedResponses.forEach(item => {
            if (keysToExtract.includes(item.key)) {
              try {
                const displayDatas = JSON.parse(item.display_data);
                console.log("Item Key:", item.key);
        
                // Determine the key to use based on item.key
                const keyToCheck = item.key === "login_month_count" || item.key === "login_month_amount" ||item.key === "colender_month_amount"? currentMonthYears : currentMonthYear;
        
                if (displayDatas[keyToCheck]) {
                  console.log(displayDatas[keyToCheck],"displayDatas[keyToCheck]")
                  const { count, display_y ,amount} = displayDatas[keyToCheck];
                  console.log(`Key: ${item.key}, Count: ${count}, Amount: ${display_y},${amount}`)
        
                  if (item.key === 'sanction_letter_month_amount') {
                    setSanctionLetterCount(count);
                    setSanctionLetterAmount(display_y);
                  } else if (item.key === 'login_month_count') {
                    console.log("Login Month Count:", count);
                    setLoginMonthCount(count);
                  } else if (item.key === 'disburse_month_amount') {
                    setDisburseMemoCount(count);
                    setDisburseMemoAmount(display_y);
                  } else if (item.key === 'login_month_amount') {
                    console.log(amount,"amountamount")
                    setLoginMonthAmount(amount);
                  } else if (item.key === 'colender_month_amount') {
                    setColenderMonthCount(count)
                    setColenderMonthAmount(display_y)
                    console.log(amount,"amountamount")
                  } 
                 
                } else {
                  console.log(`No data for ${keyToCheck} in key: ${item.key}`);
                }
              } catch (e) {
                console.error('Error parsing display_data:', e);
              }
            }
          });
        } else {
          console.error('Data fetched is not an array:', parsedResponses);
        }
  
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    searchTarget();
  }, []);

  const onlineAmount = Number(metricsData1['online_collection_payment_amount_monthly']);
  const cashAmount = Number(metricsData1['cash_collection_payment_amount_monthly']);
  const totalAmount = onlineAmount + cashAmount;
  const resultString = totalAmount.toString();
  
  const styles = `
    .dashboard {
      background-color: #f4f4f4;
      border-radius: 10px;
      width: 85%;
      margin: auto;
    }

    .top-row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      color: white;
      width: 100%;
    }

    .middle-row {
      display: flex;
      justify-content: space-between;
    }
    
    .left-column {
      width: calc(100% - 5px);
    }
    .right-column {
      width: calc(100% - 5px);
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap:10px;
      margin-top: 10px;
      align-content: flex-start;
      
    }
    .cart {
      width: calc(20% - 10px);
      height: 65px;
      background-color: #3F51B5;
      border-radius: 5px;
      display: flex;
      flex-direction: column; /* Align label and value vertically */
      justify-content: space-evenly; /* Evenly distribute space between label and value */
      padding: 5px;
      font-weight: bold;
    }

    .carts {
      width: calc(33% - 10px);
      height: 65px;
      background-color: #bb4848;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;  
      padding: 5px;
      margin-top: 10px;
      box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.5);
      color: #ffffff;
      font-weight: bold;
      // text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.5); 
       
    }
    .label {
      font-weight: bold;
      font-size: 1.2rem;
      text-align: center; 
    }
    .value {
      font-size: 1.5em;
      text-align: center;
      font-weight: bold;}

    .cart2 {
      // width: calc(100% - 5px); /* Adjust width to occupy 50% of container width with 20px margin between items */
      height: 80px;
      background-color: #E5E4E2;
      border-radius: 5px;
      display: flex;
      // justify-content:space-between;
      align-items: center;
      text-aling:center;
      padding:10px;
      font-weight: bold;
      font-size:20px;
    }
    .top-row2 {
      display: flex;
      justify-content: space-between;
    }
    
    .cart3 {
      position: relative;
      width: calc(20% - 10px);
      height: 180px; 
      background-color: #E5E4E2;
      border-radius: 20px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 10px;
      transition: transform 0.3s ease-in-out;
    }
    .cart4 {
      width: calc(26% - 5px);
      height:50px;
      background-color: #bb4848;
      border-radius: 5px;
      display: flex;
      flex-direction: column; /* Align label and value vertically */
      justify-content: space-evenly; /* Evenly distribute space between label and value */
      padding: 10px;
      text-aling:center;
      color:white;
    }
    
    .stars-table {
      width: 100%;
      min-height:80px;
      border-collapse: collapse;
     
    }
    
    .stars-table caption {
      font-weight: bold;
    }
    
    .stars-table th,
    .stars-table td {
      border: 1px solid #000; /* Change border color */
      font-weight: bold; /* Make text bold */
      padding: 0.1rem;
      font-size:18px;
      text-align: center;
    
    }
    
    .stars-table th {
      background-color: #FFA000; /* Light red background color for header row */
      color:white;
      
    }
    
    .stars-table tr:hover {
      background-color: #f5f5f5; /* Change background color on hover to light gray */
    }
    
    
    .cart3:hover {
      transform: translateY(-5px);
    }
    
    .round-image {
      width: 80px; /* Adjust image size as needed */
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 10px; /* Add some space between the image and user details */
      margin:auto;
    }
    
    .round-image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    .user-details {
      display: flex;
      flex-direction: column;
      align-items: center; /* Align items in the center */
      text-align: center; /* Align text in the center */
    }
    
    .user-name {
      font-weight: bold;
      font-size: 18px;
    }
    
    .emi-id {
      color: #666;
      // font-size: 20px;
    }
    
    .line-chart-container {
      width: 100%;
      padding: 20px 0;
    }

    .line-chart-heading {
      text-align: center;
      font-size: 1.5rem;
      margin-bottom: 10px;
    }

    .grid-container {
      display: grid;
      grid-template-columns:30% 35% 35%;
      padding: 5px;
      column-gap: 5px;
    }
    .grid-container2 {
      display: grid;
      grid-template-columns: 90%;
      padding: 5px;
      column-gap: 5px;
      justify-content: space-around;
    }
    
    @media screen and (min-width: 250px) and (max-width: 600px)  {
      /* Mobile and small screens */
      .dashboard {
        background-color: #f4f4f4;
        border-radius: 10px;
        font-family: 'Roboto Slab';
        width: 100%;
        margin: auto;
      }
      .top-row2{
        flex-direction: column;
        align-items: center;
      }
      .middle-row {
        flex-direction: column;
      }
    
      .left-column {
        width: calc(100% - 0px);
        text-align: left;
      }
    
      .right-column {
        width: calc(100% - 0px);
      }
    
      .cart2{
        width: calc(100% - 10px);
        text-align: center;
        font-size:16px;
        column-gap: 5px;
      }

      .cart3,
      .cart4{
        width: calc(100% - 0px);
        text-align: center;
        margin-top: 10px;
        
         
      }
      .stars-table {
        width: 100%;
      }
    }
    @media screen and (min-width: 601px) and (max-width: 900px) {
      /* Mobile and small screens */
      .dashboard {
        background-color: #f4f4f4;
        border-radius: 10px;
        font-family: 'Roboto Slab';
        width: 100%;
        margin: auto;
      }
      .top-row2{
        flex-direction: column;
        align-items: center;
      }
      .middle-row {
        flex-direction: column;
        width: calc(100% - 0px);
      }
    
      .left-column {
        width: calc(100% - 10px);
        text-align: left;
      }
    
      .right-column {
        width: calc(100% - 10px);
        
      }
    
      .cart2{
        width: calc(100% - 10px);
        text-align: center;
        column-gap: 5px;
        font-size:18px;
       
      }

      .cart3,
      .cart4{
        width: calc(100% - 10px);
        text-align: center;
        margin-top: 10px;
         
      }
      .stars-table {
        width: 100%;
      }
    }
    
    @media screen and (min-width: 900px) and (max-width: 1150px) {
      /* Tablet screens */
     
      .left-column {
        width: calc(100% - 0px);
      }
      .cart2{
        width: calc(100% - 5px);
        text-align: center;
        padding:1px;
        font-size:18px;
      }
    
      .cart3 {
        width: calc(20% - 10px);
      }
    
      .cart4 {
        width: calc(25% - 10px);
      }
    }
    
    // @media screen and (min-width: 1150px){
    //   .left-column {
    //     width: calc(100% - 0px);
    //   }
    
    //   .cart2{
    //     width: calc(100% - 5px);
    //     text-align: center;
    //     padding:10px;
    //     font-weight: bold;
    //     font-size:20px;
    //   }

    //   .cart3 {
    //     width: calc(20% - 10px);
    //   }
    
    //   .cart4 {
    //     width: calc(25% - 10px);
    //   }
    // }
    @media screen and (max-width: 900px) {
      h4 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 18px; /* Adjust the font size as needed */
      }
    }
    @media screen and (min-width: 901px){
      h4 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
         
      }
    }
  
  `;

  return (
    <div className="dashboard">
      
      {/* <h4 style={{textAlign:'center',fontWeight:'bold'}}>Today's Team Achievements</h4> */}
            <h4 style={{textAlign:'center',fontWeight:'bold'}}>Monthly Team Achievements<br/>Time Left: <span style={{color:"red",fontSize:"1.5rem"}}>{hourLeft} Hours : {minuteLeft} Minutes: {secondLeft} Seconds </span> </h4>
     
      <style>{styles}</style>
      <div className="top-row">
          <div className="cart">
            <div className="label">Zone</div>
            <div className="value">{countDetailsData && countDetailsData.branch_zone}</div>
          </div>
          <div className="cart">
            <div className="label">Region</div>
            <div className="value">{countDetailsData && countDetailsData.branch_region}</div>
          </div>
          <div className="cart">
            <div className="label">Area</div>
            <div className="value">{countDetailsData && countDetailsData.branch_area}</div>  
          </div>
          <div className="cart">
            <div className="label">Cluster</div>
            <div className="value">{countDetailsData && countDetailsData.branch_cluster}</div> 
          </div>
        
          <div className="cart">
            <div className="label">Branches</div>
            <div className="value">{countDetailsData && countDetailsData.branches}</div>
          </div>
      </div>

      <div className="top-row">
       
      {zoneDetails && zoneDetails.map((zone, index) => {
      const zoneName = zoneMapping[zone.zone];
      return (
        <div key={index} className="carts">
          <div className="label">{zoneName}</div>
          <div className="value">{zone.monthly_achievement}</div>
        </div>
      );
    })}
      
      </div>
<div className="middle-row">
  <div className="left-column">
 
  <div class="grid-container">
    <div className="cart2">
    {/* Login Target:   {metricsData['today_login_count']} */}
    {loading ? (
        <p>Loading...</p>
      ) : targets.length > 0 ? (
        <div style={{ display: 'flex', flexWrap: 'wrap'}}>
          {targets.map((target, index) => (
            <div key={target._id} >
              <b style={{ fontSize: '18px' }}>
               {(target.key === "login_target" && currentMonthYearss===target.month_key ) && "Login Target :" }
              
              </b> {
  user.branch_id === 'HO001'
    ? (user.branch_id === target.branch_key && currentMonthYearss===target.month_key && target.key === "login_target") && (
        <span>&nbsp;{target.monthly_target}</span>
      )
    : (target.approval_status === "approved" && currentMonthYearss===target.month_key&& target.key === "login_target") && (
        <span>&nbsp;{target.monthly_target}</span>
      )
}

            </div>
          ))}
        </div>
      ) : (
        <p>Login Target : 0</p>
      )}    
    </div>
    <div className="cart2">
      {/* Sanction Count:   {sanctionLetterCount !== null && <div>{sanctionLetterCount}</div>} */}
    </div>
    <div className="cart2">
      {/* Disbursement Count:    {disburseMemoCount !== null && <div>{disburseMemoCount}</div>} */}
      {loading ? (
        <p>Loading...</p>
      ) : targets.length > 0 ? (
        <div style={{ display: 'flex', flexWrap: 'wrap'}}>
          {targets.map((target, index) => (
            <div key={target._id} >
              <b style={{ fontSize: '18px' }}>
               {(target.key === "disbursement_target"  && currentMonthYearss===target.month_key) && "Disbursement Target :" }
              </b>
              {
  user.branch_id === 'HO001'
    ? (user.branch_id === target.branch_key  && currentMonthYearss===target.month_key &&  target.key === "disbursement_target") && (
        <span>&nbsp;{target.monthly_target} (lac)</span>
      )
    : (target.approval_status === "approved" && currentMonthYearss===target.month_key && target.key === "disbursement_target") && (
        <span>&nbsp;{target.monthly_target} (lac)</span>
      )
} 
              
              
              {/* {(target.approval_status==="approved" && target.key === "disbursement_target")  &&  (<span>&nbsp;₹{target.monthly_target} (lac) </span>)} */}
            </div>
          ))}
        </div>
      ) : (
        <p>Disbursement Target : 0</p>
      )}  
    </div>
  </div>
  <div class="grid-container">
    <div className="cart2">
    Total Logins :  {loginMonthCount}
    
    </div>
    <div className="cart2">
      Total Sanction :   {sanctionLetterCount !== null && <div> &nbsp;{sanctionLetterCount}</div>}
    </div>
    <div className="cart2">
      
      Total Disbursement:    {disburseMemoCount !== null && <div>&nbsp;{disburseMemoCount}</div>}
    
    </div>
  </div>
  <div class="grid-container">
    <div className="cart2">   
   Login Amt :       {loginMonthAmount !== null && <div> &nbsp;₹{loginMonthAmount}</div> }
    </div>
    <div className="cart2">
      Sanction Amt :   {sanctionLetterAmount !== null &&  <b> &nbsp;₹{sanctionLetterAmount} (lac)</b>}
    </div>
    <div className="cart2">
    {loading ? (
        <p>Loading...</p>
      ) : targets.length > 0 ? (
        <div style={{ display: 'flex', flexWrap: 'wrap'}}>
          {targets.map((target, index) => (
            <div key={target._id} >
              <b style={{ fontSize: '18px' }}>
               {(target.key === "disbursement_target"&& currentMonthYearss===target.month_key) && "Disbursement Amt :" }
              </b>
              
              {
  user.branch_id === 'HO001'
    ? (user.branch_id === target.branch_key && currentMonthYearss===target.month_key && target.key === "disbursement_target") && (
        <span> &nbsp;₹{target.monthly_achievement} (lac) </span>
      )
    : (target.approval_status === "approved" && currentMonthYearss===target.month_key && target.key === "disbursement_target") && (
        <span> &nbsp;₹{target.monthly_achievement} (lac) </span>
      )
} 
              
              {/* {(target.approval_status==="approved" && target.key === "disbursement_target")  &&  (<span> &nbsp;₹{target.monthly_achievement} (lac) </span>)} */}
            </div>
          ))}
        </div>
      ) : (
        <p>Disbursement Amt : ₹0</p>
      )}  
      {/* Disbursement Amt: {disburseMemoAmount !== null && <div>₹{disburseMemoAmount}</div>} */}
  
    </div>
  </div>
  {roleChecker.isHOLevelAccess(user.role) &&
  <div class="grid-container">
 
    <div className="cart2">
      Total Colender :  &nbsp; {colenderMonthCount !== null && <div>{colenderMonthCount}</div>}
    </div>
    <div className="cart2">
      Colender Amt :   {colenderMonthAmount !== null && <div> &nbsp;₹{colenderMonthAmount} (lac)</div>}
      </div>
    </div>}
  {
  <div class="grid-container">
 
    <div className="cart2">
    Online Collection Amount: ₹ { displayhelper.displayNumberAccounting(Number(metricsData1['online_collection_payment_amount_monthly']))}
    </div>
    <div className="cart2">
    Cash Collection Amount:  ₹ {displayhelper.displayNumberAccounting(Number(metricsData1['cash_collection_payment_amount_monthly']))}
      </div>
    <div className="cart2">
    Net Collection Amount:  ₹ {resultString}
      </div>
    </div>}
  </div>
</div>
 <div class="grid-container2">
    <div>
    <h4 style={{ fontWeight: 'bold',textAlign:'center'}}>⭐⭐        TODAY'S STAR RO       ⭐⭐</h4>
    <table className="stars-table">
      <thead>
        <tr>
          <th>RO Name</th>
          {/* <th>RO ID</th> */}
          <th>Branch ID</th>
          <th>Login Count</th>
        </tr>
      </thead>
      <tbody>
        {rostarData && rostarData.map((item, index) => (
          <tr key={index}>
            <td>{item.emp_name}</td>
            {/* <td>{item.emp_id}</td> */}
            <td>{item.branch_id}</td>
            <td>{item.login_count}</td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
  </div>

{weekstarData && <div>
      <h5 style={{textAlign:'center',fontWeight:'bold'}}>⭐⭐⭐⭐⭐ RO STAR'S OF THE WEEK ⭐⭐⭐⭐⭐</h5>
      <div className="top-row2">
        {weekstarData.map((item, index) => (
          <div className="cart3" key={index}>
            <div className="cart-content">
              <div className="round-image">
                <img src={usericon} alt="User" />
              </div>
              <div className="user-details">
                <div className="user-name">{item.emp_name}</div>
                {/* <div className="emi-id">Employee ID: {item.emp_id}</div> */}
                <div className="emi-id">Branch ID: {item.branch_id}</div>
                <div className="emi-id">Login Count: <b>{item.login_count}</b></div>
                
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>}
       
        
      
    </div>
  );
}

export default Dashboard2;
