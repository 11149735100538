import React from "react";
import { useState, useEffect } from "react";
import { Modal, Box, Typography, Button } from "@material-ui/core";
import { AddOutlined } from "@material-ui/icons";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { Paper } from "@material-ui/core";
import { branchService } from "../../../_services/branch.service";
import { employeeService } from "../../../_services";
import { ledgerService } from "../../../_services/ledger.service";
import * as Yup from "yup";

export const RemarkModal = (props) => {
    console.log('console is',props)
    const user=employeeService.userValue
    const [open, setOpen] = useState(false)
    const handleOpen = () => {setOpen(true); console.log('button click on ',props.id,props.trxn )}

    const handleClose = () => setOpen(false)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 640,
        height: 370,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'scroll',
        //paddingBottom: '30px',
    };

    const initialValues = {
        remarks:''
     }
   

    const onSubmit = (values) => {
        console.log('the value is',values)
       props.revert(props.id,props.trxn,values)
       setOpen(false)
    }

    const validationSchema = Yup.object().shape({
        remarks: Yup.string().required("This field is required"),
        
    })



    return (
        <div>
            <Button onClick={handleOpen} variant="outlined" color='secondary'>Revert</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <Formik
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            validationSchema={validationSchema}
                        >
                            {({ errors, touched, isSubmitting, values, setFieldValue }) => {
                                return <Form>
                                    <Paper style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '10px', padding: '10px' }}>
                                        <h3>Add Remark</h3>
                                    </Paper>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                                    <div  style={{width:'100%'}}>
                                            <h6>Remark</h6>
                                            <Field
                                                  as="textarea"
                                                  placeholder="Please Enter a Valid Reason"
                                                name="remarks"
                                                className={
                                                    "form-control" +
                                                    (errors.remarks && touched.remarks
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name="remarks"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        
                                         
                                        
                                         
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center',marginTop:'1rem' }}>
                                        <Button type="submit" variant="contained" color="primary">Submit</Button>
                                    </div>
                                </Form>
                            }}
                        </Formik>
                    </Typography>
                </Box>
            </Modal>

        </div>
    )
}