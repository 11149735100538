import { fetchWrapper } from "../_helpers";
const apiUrl = process.env.REACT_APP_API_URL;
const baseUrl = `${apiUrl}/bulksubmisssionletter`;

async function saveBulksubmissionLetterDetails(params) {
  return fetchWrapper.post(`${baseUrl}/savedata`, params)
}

async function getLetter() {
  return fetchWrapper.get(`${baseUrl}/getletter`)
}

async function countLetter() {
  return fetchWrapper.get(`${baseUrl}/countletter`)
}
async function searchByLetterNumber(params) {
  console.log("letter num", params)
  return fetchWrapper.post(`${baseUrl}/searchletter`, params)
}
export const BulkSubmissionLetter = {
  saveBulksubmissionLetterDetails,
  getLetter,
  countLetter,
  searchByLetterNumber,
}

