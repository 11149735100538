import { Box, Button, Modal } from '@material-ui/core';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import repaymentTable from "../../assets/RepaymentSchedule.xlsx"
import { termLoans } from "../../_services/termloans.service";
import urlhelper from '../../_helpers/urlhelper';
import { uploadFile, uploadFileToBucket } from '../../_helpers/upload';
import { employeeService } from '../../_services';
import { history } from '../../_helpers';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import datehelper from '../../_helpers/datehelper';

export const RepaymentSchedule = (props) => {
    console.log('Test props', props)
    let Repaymentdata = props.loan.final_repayment_schedule
    console.log("Test Repayment", Repaymentdata)
    let loan_no = props.loan.loan_number
    let id = props.loan._id
    const user = employeeService.userValue.emp_id;
    const [open, setOpen] = useState(false);
    const formikRef = React.useRef();
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const initialValues = {
        repaymentSchedule: '',
    };
    console.log("Test data ", loan_no)
    const style = {
        position: "absolute",
        top: "85%",
        left: "150%",
        transform: "translate(-50%, -50%)",
        // width: 1300,
        // height: 850,
        minWidth: 600,
        maxWidth: 1300,
        minHeight: 400,
        maxHeight: 700,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
        margin: "30px",
        overflow: 'scroll'
    };
    const validationSchema = Yup.object().shape({
        repaymentSchedule: Yup.mixed().required('Please select a file'),
    });
    const bucket = process.env.REACT_APP_s3sblBucket
    const handleSubmit = async(values) => {
        console.log("Test values", values);
        // actions.setSubmitting(false);
       await termLoans.repaymentScheduleUpload({ loan_ref_id: id, file_path: values.repaymentSchedule })
            .then((res) => {
                console.log("test ",res)
                if(res.success === true){
                    alert("Document Submitted")
                    window.location.reload()
                }
                else{
                    alert("Please Choose Correct file !!!")
                }
            })
            .catch((err) => (alert(err)))
    };
    const handleRepayment = async (e) => {
        const randomNum = Math.floor(100 + Math.random() * 900);
        let file = e.currentTarget.files[0];
        let link = await uploadFileToBucket(bucket, 'application/xlsx', `${bucket}/termloanrepaymentschedule/${loan_no}${randomNum}.xlsx`, file);
        console.log("Test link", link)
        formikRef.current.setFieldValue('repaymentSchedule', link);
    };
    return (
        <div>
            {Repaymentdata ? <Button onClick={handleOpen} style={{ color: 'green', border: "2px solid green", height: '25px', background: '9990FF', marginTop: '5px' }}>View&nbsp;Repayment</Button> :
                <Button onClick={handleOpen} style={{ color: '#0C2294', border: "2px solid #0C2294", height: '25px', background: '9990FF', marginTop: '5px' }}>Repayment&nbsp;Schedule</Button>}
            <Modal open={open} onClose={handleClose} style={{ width: '600px', height: '600px' }}>
                <Box sx={style}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        innerRef={formikRef}
                    >
                        {({ setFieldValue, touched, errors, isSubmitting }) => (
                            <Form style={{ marginTop: '30px', }}>
                                <h5 style={{ textAlign: 'center', textDecoration: 'underLine', marginBottom: '20px' }}> Repayment Schedule</h5>
                                {!Repaymentdata && <div style={{ marginLeft: '170px' }}>
                                    <a href="https://s3.ap-south-1.amazonaws.com/download.kaabilfinance.com/files/TermLoanRepaymentScheduleSample.xlsx" download style={{ color: 'red', float: 'right', fontSize: '16px', marginBottom: '50px' }}>Download sample excel </a>
                                    <input
                                        style={{ marginLeft: '40px' }}
                                        accept=".xlsx"
                                        type="file"
                                        name="repaymentSchedule"
                                        onChange={(e) => handleRepayment(e)}
                                        required
                                        className={`form-control-file ${touched.repaymentSchedule && errors.repaymentSchedule
                                            ? 'is-invalid'
                                            : ''
                                            }`}
                                    />
                                    <ErrorMessage
                                        style={{ marginLeft: '40px', }}
                                        name="repaymentSchedule"
                                        component="div"
                                        className="invalid-feedback"
                                    />
                                    {<Button
                                        style={{ marginTop: "60px", background: "#406AFF" }}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? 'Uploading...' : 'Upload Document'}
                                        {/* Upload Document */}
                                    </Button>
                                    }
                                </div>}
                                {Repaymentdata && <div style={{ marginTop: '50px' }}>
                                    <Table>
                                        <thead>
                                            <Th style={{ border: "1px solid " }}>Serial&nbsp;No</Th>
                                            <Th style={{ border: "1px solid " }}>Balance&nbsp;Remaining</Th>
                                            <Th style={{ border: "1px solid " }}>Capital&nbsp;Paid</Th>
                                            <Th style={{ border: "1px solid " }}>&nbsp;&nbsp;&nbsp;&nbsp;Due&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Th>
                                            <Th style={{ border: "1px solid " }}>Interest&nbsp;Paid</Th>
                                            <Th style={{ border: "1px solid " }}>Payment&nbsp;Amount</Th>
                                            <Th style={{ border: "1px solid " }}>Start&nbsp;Balance</Th>
                                        </thead>
                                        <Tbody>
                                            {
                                                Repaymentdata.map((x, idx) =>
                                                    <Tr key={idx} >
                                                        <Td style={{ border: "1px solid " }}>{idx + 1}</Td>
                                                        <Td style={{ border: "1px solid " }}>{x.balance_remaining}</Td>
                                                        <Td style={{ border: "1px solid " }}>{x.capital_paid}</Td>
                                                        <Td style={{ border: "1px solid " }}>{datehelper.displayDate(new Date(x.due_date))}</Td>
                                                        <Td style={{ border: "1px solid " }}>{x.interest_paid}</Td>
                                                        <Td style={{ border: "1px solid " }}>{x.payment_amount}</Td>
                                                        <Td style={{ border: "1px solid " }}>{x.start_balance}</Td>
                                                    </Tr>)
                                            }
                                        </Tbody>
                                    </Table>
                                </div>}
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Modal>
        </div>
    );
};