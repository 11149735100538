import { fetchWrapper } from "../_helpers/fetch-wrapper";
const apiUrl = process.env.REACT_APP_API_URL;

const baseUrl = `${apiUrl}/reconcile`;


const reconcileData = (params) => {
    return fetchWrapper.post(`${baseUrl}`, params)
}

const reconcileSblData = (params) => {
    return fetchWrapper.post(`${baseUrl}/sbl`, params)
}

const getAll = () => {
    return fetchWrapper.get(baseUrl);
}

const reconcileVerification =(id,params)=>{
    // console.log(id)
    return fetchWrapper.post(`${baseUrl}/verify/${id}`,params)
}

const getByDate = (params) => {
    return fetchWrapper.post(`${baseUrl}/listbydate`, params)
}

export const reconcile = {
    reconcileData,
    getAll,
    reconcileSblData,
    reconcileVerification,
    getByDate
}