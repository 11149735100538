import React from 'react';
import { useState } from 'react';
import { Modal, Box, Button, Paper, Typography } from '@material-ui/core';
import { Table, Thead, Tbody, Tr, Td, Th } from 'react-super-responsive-table';
import urlhelper from '../../_helpers/urlhelper';
import { history } from '../../_helpers';
import { useFormik } from 'formik';
import Save from '@material-ui/icons/Save';
import { uploadFileToBucket } from '../../_helpers/upload';
import { CircularProgress } from '@material-ui/core'
import { termLoans } from '../../_services/termloans.service';
import { SaveOutlined } from '@material-ui/icons';


export const TermLoansPaymentModal = (props) => {
    const [open, setOpen] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const termLoan = props.loan

    const formik = useFormik({
        initialValues: {
            payment_collection: {
                mode: termLoan && termLoan.payment_collection && termLoan.payment_collection.mode && (termLoan.payment_collection.mode) || '',
                bank: termLoan && termLoan.payment_collection && termLoan.payment_collection.bank && (termLoan.payment_collection.bank) || ''
            }
        },
        onSubmit
    })

    async function onSubmit() {
        await termLoans.update({ payment_collection: formik.values.payment_collection, _id: termLoan._id })
            .then(res => {
                window.alert('Details saved successfully')
                window.location.reload()
            })
            .catch(err => {
                window.alert('Some error occurred')
                console.log(err)
            })
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "700px",
        minHeight: '280px',
        maxHeight: "430px",
        bgcolor: "background.paper",
        border: "1px solid #000",
        // overflow: "scroll",
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Button onClick={handleOpen} style={{ color: 'red', border: "2px solid red", height: '25px', marginTop: '5px' }}>Payment&nbsp;Mode</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box id="modal-modal-title" variant="h6" component="h2" style={{ display: 'flex', flexDirection: 'column', alignItem: 'center' }}>
                        <form onSubmit={formik.handleSubmit}>
                            {/* <Paper> */}
                            <Box style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <span style={{ margin: 'auto', marginTop: '10px', marginBottom: '10px', fontSize: 'medium' }}>
                                    <h5 style={{ color: 'black', textDecoration: 'underLine' }}>Payment Collection</h5>
                                </span>
                            </Box>
                            {/* </Paper> */}
                            <Paper style={{ marginTop: '20px', padding: '20px', background: '#C9D5FD' }}>
                                <Table style={{ fontSize: 'medium', width: '95%', margin: 'auto', textAlign: 'center' }}>
                                    <Thead>
                                        <Tr>
                                            <Th style={{ width: '40%', textAlign: 'center' }}>Payment Mode</Th>
                                            <Th style={{ width: '40%', textAlign: 'center' }}>Payment Bank</Th>
                                            <Th style={{ width: '20%', textAlign: 'center' }}></Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        <Tr >
                                            <Td>
                                                <select
                                                    name="payment_collection.mode"
                                                    style={{ width: '90%', height: '32px', background: 'white', borderRadius: '5px' }}
                                                    onChange={formik.handleChange}
                                                    defaultValue={formik.values.payment_collection.mode}
                                                    disabled={disabled}
                                                    required>
                                                    <option value="">Select Payment Mode</option>
                                                    <option value="Nach">Nach</option>
                                                    <option value="Manual">Manual</option>
                                                </select>
                                            </Td>
                                            <Td>
                                                <input
                                                    type="text"
                                                    placeholder='Enter Payment Bank Name'
                                                    style={{ width: '90%', height: '32px', background: 'white', borderRadius: '5px' }}
                                                    name='payment_collection.bank'
                                                    defaultValue={formik.values.payment_collection.bank}
                                                    onChange={formik.handleChange}
                                                    disabled={disabled} />
                                            </Td>
                                            <Td>
                                                <Button color='secondary' type='button' variant='contained' style={{ backgroundColor: 'red', color: 'white' }} onClick={() => setDisabled(false)}>Edit</Button>
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                                <br />
                                <br />
                                {disabled === false && <Button type='submit' style={{ marginLeft: '43%' }} color='primary' variant='contained'><SaveOutlined /> Save</Button>}
                                <br />
                            </Paper>
                            {/* <hr style={{ width: '100%' }} /> 
                                {disabled===false&&<Button type='submit' style={{marginLeft:'43%'}} color='primary' variant='contained'>Save</Button>} */}
                        </form>
                    </Box>
                </Box>
            </Modal>
            {
                console.log(formik.values)
            }
        </div>
    )
}