import React, { useState, useRef } from 'react';
import { Button } from '@material-ui/core';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { uploadFileToBucket } from "../_helpers/upload";
import { v4 as uuidv4 } from 'uuid';
import { bulkUploadService } from '../_services/bulkUpload.services';
import swal from 'sweetalert';
import { employeeService } from "../_services";

const ReconciliationBulkUpload = () => {
  const user = employeeService.userValue;
  const [selectedFile, setSelectedFile] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showMessage, setShowMessage] = useState('');
  const fileInputRef = useRef(null);
  const random_uid = uuidv4();
  const bucket = process.env.REACT_APP_s3sblBucket;

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      setShowError(true);
      setShowMessage('No file selected');
      setTimeout(() => setShowError(false), 3000);
      return;
    }

    let fileExtension = selectedFile.name.split('.').pop().toLowerCase();
    if (fileExtension !== 'csv' && fileExtension !== 'xlsx') {
      setShowError(true);
      setShowMessage('Only CSV and XLSX files are allowed.');
      setTimeout(() => setShowError(false), 3000);
      return;
    }

    swal({
      title: "Please wait",
      text: "File uploading...",
      icon: "info",
      buttons: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
    });

    try {
      let uploadFile, uploadFileType, uploadFileName;

      if (fileExtension === 'csv') {
        // Step 1: Parse the CSV file
        const csvData = await new Promise((resolve, reject) => {
          Papa.parse(selectedFile, {
            complete: (results) => resolve(results.data),
            error: (error) => reject(error),
          });
        });

        // Step 2: Convert CSV data to XLSX format
        const ws = XLSX.utils.aoa_to_sheet(csvData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Step 3: Generate a Blob from the workbook
        const xlsxBlob = XLSX.write(wb, { bookType: 'xlsx', type: 'blob' });
        
        // Prepare for upload
        uploadFile = new File([xlsxBlob], `${random_uid}.xlsx`, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        uploadFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        uploadFileName = `${bucket}/rpsbulkupload/${random_uid}.xlsx`;
      } else {
        // If the file is already XLSX, just use it as is
        uploadFile = selectedFile;
        uploadFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        uploadFileName = `${bucket}/rpsbulkupload/${random_uid}.xlsx`;
      }

      // Step 4: Upload the file to S3
      const link = await uploadFileToBucket(bucket, uploadFileType, uploadFileName, uploadFile);

      if (link) {
        const rowsdata = {
          "csv_url": link,
          "filename": uploadFile.name,
          "emp_id": user.emp_id,
        };

        const res = await bulkUploadService.reconciliationUploadingDataSheet(rowsdata);
        if (res.status === "success") {
          swal({ title: res.message, icon: "success", button: 'Ok' });
          setSelectedFile(null);  // Clear selected file state
          fileInputRef.current.value = "";  // Reset file input field
        } else {
          swal({ title: res.message, icon: "error", button: 'Ok' });
          setSelectedFile(null);  // Clear selected file state
          fileInputRef.current.value = "";  // Reset file input field
        }
      }
    } catch (err) {
      console.error("Upload error", err);
      setShowError(true);
      setShowMessage('An error occurred while uploading the file.');
      setTimeout(() => setShowError(false), 3000);
    }
  };

  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
  const day = String(today.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  return (
    <div>
      <p style={{textAlign:"center",color:"red",fontWeight:"bold",fontSize:"18px"}}>Note: File name should be formatted as "Nach_today's date_random number", for example, Nach_{formattedDate}_3.xlsx</p>
      <div className='offset col-md-6 m-auto' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '1rem' }}>
        <div style={{ display: 'flex', width: '100%', height: '10rem', border: '2px solid #410202', alignItems: 'center', justifyContent: 'center', borderRadius: '0.5rem', borderStyle: 'dashed' }}>
          <input 
            type="file" 
            accept=".csv, .xlsx" 
            className="form-control" 
            style={{ width: '80%', padding: '4px' }} 
            onChange={handleFileChange} 
            ref={fileInputRef}  // Attach the ref to the input field
          />
        </div>
        {showError && <div style={{ color: 'red' }}>{showMessage}</div>}
        <Button color='primary' variant='contained' onClick={handleFileUpload} disabled={!selectedFile}>
          Upload
        </Button>
        <div>
          <a href="https://sbl-credit.s3.ap-south-1.amazonaws.com/sbl-credit/rpsbulkupload/4d0e9244-3818-4e8d-97ff-cb11caa41363.xlsx" download style={{ display: "flex", justifyContent: "right", textDecoration: "none", color: "red" }}>Download Sample File</a>
        </div>
      </div>
    </div>
  );
};

export default ReconciliationBulkUpload;
