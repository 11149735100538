import React from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import FormElement from "../../../uielements/FormElement";
import { employeeService, loanService } from "../../../../_services";

import {
  withStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import { Delete, SettingsOverscanSharp, SettingsRemoteSharp } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import datehelper from "../../../../_helpers/datehelper";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import KycImageAddModal from "./KycImageAddModal";
import KycPrint from "./KycPrint";
import StageSteps from "./StageSteps";
import KycVerificationSystem from "./KycVerificationSystem";
import workflowHelper from "../../../../_helpers/workflowhelper";
import roleChecker from "../../../../_helpers/rolechecker";
import { LoanStages } from "../../../../constants/loanstages";


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(key, label, value, editable, type, options = []) {
  return { key, label, value, editable, type, options };
}

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function KycData({ customer }) {
  const classes = useStyles();
  const [employeeList, setEmployeeList] = React.useState({});
  const [verify, setVerify] = React.useState(false);
  const [response, setResponse] = React.useState({})
  const [sent, setSent] = React.useState(false)
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const user = employeeService.userValue;
  React.useEffect(() => {
    employeeService.getAll()
      .then((el) => {
        let mel = {};
        el.map((e) => {
          mel[e.emp_id] = e.first_name + " " + e.last_name;
          return e;
        });
        setEmployeeList(mel);
      })
      .catch((err) => {
        console.log(err);
      });

  }, []);

  const contact = customer.applicant.general_information.contact;

  const otp = Math.floor(1000 + Math.random() * 9000);

  const sendOtp = () => {
    loanService.sendOtp({ contact: contact, otp: otp })
      .then((data) => {
        setResponse(data.otp)
      })
      .catch((err) => { console.log(err) })
    setVerify(true)
    setSent(true)
  }

  const [value, setValue] = React.useState({})

  const handleChange = (e) => {
    value[e.target.name] = e.target.value
    setValue(value)
  }

  const [fields, setFields] = React.useState({
    personal_details:{
      otp_verified:true
    }
  })

  // console.log(response)

  const verifyOtp = () => {
    console.log(value)
    if (value.otp == response) {
      loanService.update(customer._id, fields);
      alert("Otp verified")
      window.location.reload()
    } else {
      alert("Incorrect Otp")
    }
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 200,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    // overflow: 'scroll',
    // paddingBottom: '30px',
  };
  return (
    <div>
      <div>
        <span>Contact Details:-  {contact}</span>
        {customer.personal_details &&  customer.personal_details.otp_verified === false ? <Button style={{ marginLeft: "30px" }} color='primary' onClick={handleOpen}>Verify</Button> : <span style={{ color: "green", marginLeft: "30px" }}>Verified <VerifiedUserIcon /></span>}
      </div>
      <br/>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <input type='number' disabled value={customer.applicant.general_information.contact} />
            {sent === true ? <button className="btn btn-primary" style={{ marginLeft: "10px" }}>Resend Otp</button> : <button className="btn btn-primary" style={{ marginLeft: "10px", }} onClick={sendOtp} >Send Otp</button>}<br /><br />
            {verify === true ? <div><input type='number' name='otp' onChange={handleChange} placeholder="Enter the Otp" /> <button className="btn btn-primary" onClick={verifyOtp} style={{ marginLeft: "10px", }}>Verify Otp</button></div> : null}
          </Typography>
        </Box>
      </Modal><br/>

      <KycVerificationSystem customer={customer} /><br/>
      <h2>KYC CheckList</h2>
      {workflowHelper.isKarzaVerified(customer) && (
        <StageSteps customer={customer} filterId="KYC"/>
      )}
      {!workflowHelper.isKarzaVerified(customer) && (
        <p style={{"color":"red"}}>Atleast one Applicant/Co-applicant need to verify Aadhar.</p>
      )}
      
      <br/>

      <KycPrint customer={customer} />
      <div style={{ "padding": 10 }}>
        <h3><b>Kyc Details - Applicant - {customer.applicant.general_information.name}</b></h3>
      </div>
      { ![LoanStages.Closed,LoanStages.Collection].includes(customer.stage) && (roleChecker.isHOLevelAccess(user.role) || roleChecker.isCreditTeamAccess(user.role) || roleChecker.isBMLevelAccess(user.role)) && (
        <KycImageAddModal applicant_type="applicant" applicant_id={customer.applicant.general_information.aadhar} customer={customer} />
      )}
      
      <table style={{ width: "100%", border: "1px solid", padding: "10px" }}>
        <tr><th>Category</th><th>Tag</th><th>Photo</th></tr>
        {customer.applicant && customer.applicant.kyc_images && customer.applicant.kyc_images.map((img, idx) => {
          return (<tr key={idx} style={{ border: "1px solid", padding: "10px" }}><td>{img.category}</td><td>{img.tag}</td><td><img src={img.uri} width="250px" height="250px"></img></td></tr>)
        })}
      </table>

      {customer.co_applicants && customer.co_applicants.map((ca) => {
        return (
          <div>
            <div style={{ "padding": 10, "margin-top": 20 }}>
              <h3><b>Kyc Details - Co-Applicant - {ca.general_information.name}</b></h3>
            </div>
            {![LoanStages.Closed,LoanStages.Collection].includes(customer.stage) && (roleChecker.isHOLevelAccess(user.role) || roleChecker.isCreditTeamAccess(user.role) || roleChecker.isBMLevelAccess(user.role)) && (
              <KycImageAddModal applicant_type="co_applicant" applicant_id={ca.general_information.aadhar} customer={customer} />
            )}
            <table style={{ width: "100%", border: "1px solid", padding: "10px" }}>
              <tr><th>Category</th><th>Tag</th><th>Photo</th></tr>
              {ca && ca.kyc_images && ca.kyc_images.map((img, idx) => {
                return (<tr key={idx} style={{ border: "1px solid", padding: "10px" }}><td>{img.category}</td><td>{img.tag}</td><td><img src={img.uri} width="250px" height="250px" ></img></td></tr>)
              })}
            </table>
          </div>
        )
      })}

      {customer.guarantors && customer.guarantors.map((g) => {
        return (
          <div>
            <div style={{ "padding": 10, "margin-top": 20 }}>
              <h3><b>Kyc Details - Guarantor - {g.general_information.name}</b></h3>
            </div>
            {![LoanStages.Closed,LoanStages.Collection].includes(customer.stage) && (roleChecker.isHOLevelAccess(user.role) || roleChecker.isCreditTeamAccess(user.role) || roleChecker.isBMLevelAccess(user.role)) && (
              <KycImageAddModal applicant_type="guarantor" applicant_id={g.general_information.aadhar} customer={customer} />
            )}
            <table style={{ width: "100%", border: "1px solid", padding: "10px" }}>
              <tr><th>Category</th><th>Tag</th><th>Photo</th></tr>
              {g && g.kyc_images && g.kyc_images.map((img, idx) => {
                return (<tr key={idx} style={{ border: "1px solid", padding: "10px" }}><td>{img.category}</td><td>{img.tag}</td><td><img src={img.uri} width="250px" height="250px"></img></td></tr>)
              })}
            </table>
          </div>
        )
      })}
    </div>
  )
}

