import React, { useState } from 'react'
import Webcam from 'react-webcam';
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import { uploadFile } from '../../../_helpers/upload';
import { history } from '../../../_helpers';
import { employeeService, misService } from '../../../_services';
import { Button } from '@material-ui/core';
import SearchIcon from "@material-ui/icons/Search";
import { goldPacketServices } from '../../../_services/gold_packet.service';
import { Form, Formik,ErrorMessage } from 'formik';

export const CheckInForm = () => {
  const user = employeeService.userValue.emp_id
  const [shwo, setShow] = useState(false)
  const photoRef = React.useRef(null);
  const [pic, setPic] = useState([])
  const [response, setResponse] = useState([])
  let initialValues = {
    photo: "",
    packet_no: '',
    loan_no: '',
    name: "",                                               
    loan_no: '',
    packet_no: '',
    photo: '',
    packet_state: 'In',
    checkIn_details: {
    checkin_by_emp_id: user,
    accept_by_emp_id:'',
    accepter_mobile_no:'',
    otp:'',
    checkin_status: '',
    date:''
    }
  }
  const [value, setValue] = useState({
    name: "",                                               
    loan_no: '',
    packet_no: '',
    photo: '',
    packet_state: 'In',
    checkIn_details: {
    checkin_by_emp_id: user,
    accept_by_emp_id:'',
    accepter_mobile_no:'',
    otp:'',
    checkin_status: '',
    date:''
    }
  })
  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value
    })
  }
  function handleSubmit(values) {
    goldPacketServices.goldPackets(values)
      .then((res) => {
        alert("Check In Request Sent ")
        history.push("/home/goldpacket/checkin")
      })
      .catch((err) => {alert("Some error occured")
        console.log(err)})
  }
  const videoConstraints = {
    width: 390,
    height: 270,
    facingMode: "user",
  };
  const capturePhoto = React.useCallback(
    async () => {
      if (value.loan_no) {
        const randomNum = Math.floor(1000 + Math.random() * 900);
        let fileName = `gold_${value.loan_no}_${randomNum}.jpg`
        const photo = photoRef.current.getScreenshot();
        const link = await uploadFile(`goldpacket/${value.loan_no}/${fileName}`, photo);
        setValue({
          ...value,
          photo: link
        })
      }
    }
  );
  const discard = (id) => {
    if (id === "photo") {
      setValue({
        ...value,
        photo: ""
      })
    }
  }
  const searhLoanNo = async (loanno) => {
    await misService.getLoanByAccount(loanno)
      .then((res) => {
        setValue({ ...value, ['name']: res[0].name, ['loan_no']: res[0].loan_account, ['packet_no']: res[0].gold_packet })
        setShow(true)
        setResponse(res)
        res[0] && res[0].details && res[0].details.gold_pic && setPic(res[0].details.gold_pic)
      })
      .catch((err) => alert("Loan Number not exits"))
  }
  console.log("test value ", value)
  console.log("Test Response", response[0])
  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={validationSchema}
      onSubmit={() => {value.photo?handleSubmit(value):alert("Photo is Required")}}
    >
      <Form style={{ margin: "30px" }}>
        <div style={{ background: 'white' }}>
          <br />
          <h3 style={{ textAlign: "center", marginBottom: "50px" }}>Check In form</h3>
          <hr />
          <br />
          {shwo === false ?
            <div style={{ textAlign: "center", marginLeft: "auto", marginRight: "auto" }}>
              <label>
                <b>Loan Number:</b>
                <input
                  style={{ width: "200px", borderRadius: "5px", height: "30px", border: "1px solid grey", marginBottom: "50px" }}
                  name="loan_no"
                  onChange={(e) => { handleChange(e) }}
                  type="text"
                  value={value.loan_no.toUpperCase()}
                  placeholder="Search Loan"
                  required
                />
                <Button style={{ background: "blue", color: "white", border: "none", width: "80px", height: "30px", borderRadius: "5px" }}
                  onClick={() => searhLoanNo(value.loan_no)}
                >
                  <SearchIcon />
                </Button>
              </label>
              <br />
              <br />
            </div> :
            <div style={{ marginLeft: "60px", marginRight: "50px", marginTop: "30px", marginBottom: "130px" }}>
              <div style={{ float: "left" }}>
                <br />
                <label>
                  <b>Loan Number</b><br />
                  <input
                    style={{ width: "200px", borderRadius: "5px", height: "30px", border: "1px solid grey" }}
                    value={value.loan_no}
                    disabled
                  />
                </label>
                <br />
                <br />
                <label>
                  <b>Name</b><br />
                  <input
                    style={{ width: "200px", borderRadius: "5px", height: "30px", border: "1px solid grey" }}
                    value={value.name}
                    disabled
                  />
                </label>
                <br />
                <br />
                <div>
                <label>
                  <b>Packet Number</b><br />
                  <input
                    style={{ width: "200px", borderRadius: "5px", height: "30px", border: "1px solid grey" }}
                    name="packet_no"
                    onChange={(e) => { handleChange(e) }}
                    type="text"
                    value={value.packet_no}
                    maxLength={9}
                    minLength={6}
                    placeholder="Enter Packet No"
                    required
                  />
                </label>
                   </div> 
                <br />
              </div>
              <div style={{ float: "left", marginLeft: "150px" }}>
                <label>
                  <span style={{ fontSize: "25px", textAlign: "center" }}>Gold Photo </span>
                  <div style={{ width: "220px", height: '60px' }}>
                    <img
                      src={pic}
                      // src={response[0].details.gold_pic}
                      alt="img"
                    />
                  </div>
                </label>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {value.photo ?
                  (<div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ fontSize: "25px", textAlign: "center" }}>
                      Photo
                    </span>
                    <img
                      style={{ width: "390px", height: "270px" }}
                      src={value.photo}
                      alt="Id photo"
                      name="customerphoto"
                      required
                    />
                    <button
                      style={{ height: "40px", border: "none", width: "390px" }}
                      type="button"
                      onClick={() => discard("photo")}
                    >
                      <ClearOutlinedIcon />
                    </button>
                  </div>
                  ) : (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span style={{ fontSize: "25px", textAlign: "center" }}>
                        Gold Packet Photo
                      </span>
                      <Webcam
                        audio={false}
                        height={270}
                        screenshotFormat="image/jpeg"
                        width={390}
                        ref={photoRef}
                        videoConstraints={videoConstraints}
                        required
                      ></Webcam>
                      <button
                        style={{ height: "40px", border: "none", width: "390px" }}
                        type="button"
                        onClick={() =>
                          capturePhoto()
                        }
                      >
                        {<CameraAltOutlinedIcon />}
                      </button>
                    </div>)}
              </div>
              <br />
              <button type='submit'
                style={{ background: "blue", color: "white", width: "100px", height: "40px", marginTop: "50px", borderRadius: "4px", marginLeft: "460px", marginBottom: "80px" }}>
                Submit</button>
            </div>
          }
        </div>
      </Form>
    </Formik>
  );
}
