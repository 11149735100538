import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { virtualAccountService } from "../../../_services/virtualaccount.service";
import {alertService} from "../../../_services/alert.service";

function VAAddEdit({ history, match }) {
  const { id } = match.params;
  const isAddMode = !id;
  const formikRef = React.useRef();

  const initialValues = {
    customer_name: "",
    customer_mobile: "",
    loan_account: "",
  };

  const validationSchema = Yup.object().shape({
    customer_name: Yup.string().required("Customer Name is required"),
    customer_mobile: Yup.string().required("Customer Mobile is required"),
    loan_account: Yup.string().required("loan account number is required"),
  });

  function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    if (isAddMode) {
      createVirtualAccount(fields, setSubmitting);
    } 
  }

  function createVirtualAccount(fields, setSubmitting) {
    virtualAccountService
      .create(fields)
      .then(() => {
        alertService.success("Virtual Account added successfully", {
          keepAfterRouteChange: true,
        });
        history.push(".");
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
  }

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, isSubmitting, setFieldValue }) => {
        return (
          <Form>
            <h1>{isAddMode ? "Add Virtual Account" : "Edit Virtual Account"}</h1>
            <div className="form-row">
              <div className="form-group col-3">
                <label>Customer Name</label>
                <Field
                  name="customer_name"
                  type="text"
                  className={
                    "form-control" +
                    (errors.customer_name && touched.customer_name ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="customer_name"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group col-3">
                <label>Customer Mobile</label>
                <Field
                  name="customer_mobile"
                  type="text"
                  className={
                    "form-control" +
                    (errors.customer_mobile && touched.customer_mobile
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name="customer_mobile"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>
            <div className="form-row">
            <div className="form-group col-3">
                <label>Loan Account Number</label>
                <Field
                  name="loan_account"
                  type="text"
                  className={
                    "form-control" +
                    (errors.loan_account && touched.loan_account
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name="loan_account"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

            </div>
            <div className="form-group">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-primary"
              >
                {isSubmitting && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                Save
              </button>
              <Link to={isAddMode ? "." : ".."} className="btn btn-link">
                Cancel
              </Link>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export { VAAddEdit };
