import React from "react";
import {
  makeStyles,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
} from "@material-ui/core";
import ApplicantData from "./ApplicationData/ApplicantData";
import CoApplicantData from "./ApplicationData/CoApplicantData";
import TextField from "@material-ui/core/TextField";
import { employeeService } from "../../../_services";
import GuarantorData from "./ApplicationData/GuarantorData";
import EditStageModal from "./EditStageModal";
import roleChecker from "../../../_helpers/rolechecker";
import { LoanStages } from "../../../constants/loanstages";
import PropTypes from "prop-types";
import ApplicationFormDetails from "./ApplicationData/ApplicationFormDetails";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


export default function ApplicantDetails({ cust }) {
  const user = employeeService.userValue;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log(cust);
  const refreshPage=()=> {
    window.location.reload();
  }
  return (
    <div className={classes.root}>
       {cust && (
      <ApplicationFormDetails customer={cust}></ApplicationFormDetails>
      )}
      <AppBar position="static"    >
      {cust && (
        <>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Applicant tabs"
          style={{backgroundColor:"#D4F1F4",color:"black"}}
        >
        <Tab label="Applicant" {...a11yProps(0)} style={{ minWidth: "30%"}} />
        <Tab label="Co-Applicants" {...a11yProps(1)} style={{ minWidth: "30%" }} />
        <Tab label="Guarantors" {...a11yProps(2)} style={{ minWidth: "30%" }} />
        </Tabs>
        </>
     
      )}
      </AppBar>
      <TabPanel value={value} index={0}>
      {cust && (
      <ApplicantData customer={cust}></ApplicantData>
      )}
      </TabPanel>
      <TabPanel value={value} index={1}>
     
      {cust && (
      <CoApplicantData customer={cust}></CoApplicantData>
      )}
      </TabPanel>
      <TabPanel value={value} index={2}>
     
      {cust && (
      <GuarantorData customer={cust}></GuarantorData>
      )}
      </TabPanel>

    </div>
  );
}
