import React from "react";
import { Grid, Card, Button, CardActions } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { loanService } from "../../_services/loan.service";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { employeeService } from "../../_services";
import roleChecker from "../../_helpers/rolechecker";
import { Input } from "antd";
import datehelper from "../../_helpers/datehelper";
import LetterList from "./Letters/SBLLetterList";
import displayhelper from "../../_helpers/displayhelper";

const { Search } = Input;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexGrow: 1,
  },
  card: {
    minHeight: 250,
    minWidth: 250,
  },
}));

export default function MyTasksList({ match }) {
  const { path } = match;
  const classes = useStyles();
  const user= employeeService.userValue;
  const [applicants, setApplicants] = React.useState(null);
  const [spinner,setSpinner]= React.useState(true);
  const [page, setPage] = React.useState(0);
  const [searchFilter,setSearchFilter]=React.useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [custData,setCustData]=React.useState()
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(()=>{
       if(searchFilter || custData){
   console.log('searchFilter',searchFilter,custData)
    let tempLoans=custData.filter((l)=> {
      if(l.next_stage_emp_id !== user.emp_id)
      {
        return false;
      }
      if(searchFilter!="")
      {
        if(l.applicant_name && l.applicant_name.toLowerCase().includes(searchFilter.toLowerCase()))
        {
            return true;
        }
        if(l.applicant_contact && l.applicant_contact.includes(parseInt(searchFilter)))
        {
            return true;
        }
        if(l.application_id &&l.application_id.toLowerCase().includes(searchFilter.toLowerCase()))
        {
            return true;
        }


        return false;
      }
      else
      {
        return true;
      }
     
     });
     setApplicants(tempLoans)
    }
   },[searchFilter])

  React.useEffect(() => {
    async function getApplicants() {
      setSpinner(true);
      var cust = await loanService.getMySummary();
      console.log(cust);
      setCustData(cust)
      setApplicants(cust);
      setSpinner(false);
     
    }

    getApplicants();
  }, []);
console.log('searchFilter',searchFilter)
  return (
    <div>
      <h1>SBL Loans Tasks for me</h1>
      
      <div className="d-flex justify-content-between">
   
        <Search
        onChange={(e) => setSearchFilter(e.target.value)}
        placeholder="Search"
        enterButton
        style={{
          position: "sticky",
          top: "0",
          left: "0",
          width: "200px",
          marginTop: "2vh",
        }}
      />
      </div>
      <br></br>
      <TableContainer>
      <Table>
          <Thead>
            <Tr>
              <Th style={{ width: "10%" }}>
                  Date
              </Th>
               
              <Th style={{ width: "5%" }}>Application #</Th> 
              <Th style={{ width: "10%" }}>Customer Name</Th>
              <Th style={{ width: "5%" }}>Branch Id</Th>
              <Th style={{ width: "10%" }}>Sanction Amount</Th>
              <Th style={{ width: "20%" }}>Loan Stage</Th>
              <Th style={{ width: "10%" }}>Next Step</Th>
              <Th style={{ width: "5%" }}>Assigned To</Th>
              <Th style={{ width: "5%" }}>Assigned Since</Th>
              <Th style={{ width: "10%" }}>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {applicants && applicants.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((application,idx)=>(
              <Tr key={idx}><Td>{datehelper.displayDate(new Date(application.application_date))}</Td><Td>{application.application_id}</Td>
              <Td>{application.applicant_name}
              </Td>
              <Td>{application.branch_id}</Td>
              <Td>{application.sanction_amount && displayhelper.displayNumberAccounting(application.sanction_amount) }</Td>
              <Td>{application.stage}
              </Td>
              <Td>{application.sub_stage}</Td>
              <Td>{application.next_stage_employee}</Td>
              <Td>{datehelper.displayDate(new Date(application.stage_assign_date))}</Td>
              <Td>
              <Button component="a"
              href={`/home/sblloans/details/${application._id}`}
              size="small"
              color="primary"
            >
              View Details</Button>
              <br/>
              <LetterList id={application._id} stage={application.stage} />
             
              </Td></Tr>
            ))}
            {(!applicants || spinner) && (
              <Tr>
                <Td colSpan="9" className="text-center">
                  <span className="spinner-border spinner-border-lg align-center"></span>
                </Td>
              </Tr>
            )}
          </Tbody> 
          </Table>
      </TableContainer>
      {applicants ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={applicants.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        <div />
      )}
    </div>
  );
}
