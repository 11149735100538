import React, { useEffect, useState } from 'react'
import { Table, Th, Thead, Tr, Td, Tbody } from 'react-super-responsive-table'
import datehelper from '../../_helpers/datehelper'
import { goldLoanServices } from '../../_services/goldloan.service'
import { employeeService } from '../../_services'
const DueEmiList = () => {
    const user = employeeService.userValue
    const [emi, setEmi] = useState()
    console.log("Test user", emi)
    useEffect(() => {
        async function getEmi() {
            goldLoanServices.getUpcomingEmi()
                .then((res) => setEmi(res.last_week_data))
                .catch((err) => console.log(err))
        }
        getEmi()
    }, [])
    let EmiArray = emi && Object.keys(emi).map(key => emi[key]);

    console.log("Test emi", EmiArray)

    return (
        <div style={{ margin: "60px" }}>
            <h2 style={{ textAlign: "center" }}> Last week emi list</h2>
            <br />
            <br />
            <Table>
                <thead>
                    <Th>Loan No</Th>
                    <Th>Name</Th>
                    <Th>Branch</Th>
                    <Th>Emi Amount</Th>
                    <Th>Emi due Date</Th>
                    <Th>Loan Amount</Th>
                    <Th>Mobile No</Th>
                </thead>
                <Tbody>
                    {
                        EmiArray && EmiArray.length > 0 && EmiArray.filter(emiDetals =>
                            (emiDetals.branch_id === user.branch_id) || (user.branch_id === "HO001")).map((x, idx) =>
                                <>
                                    <Tr key={idx} style={{ background: "#EFECEC", marginBottom: "80px", height: '50px', fontSize: '17px' }}>
                                        <Td>{x.loan_account}</Td>
                                        <Td>{x.name}</Td>
                                        <Td>{x.branch_id}</Td>
                                        {/* <Td>{(x.branch_id===user.branch_id)?user.branch.branch_name:x.branch_id}</Td>  */}
                                        <Td>{x.emi_amount && x.emi_amount.toLocaleString("en-IN", {
                                            maximumFractionDigits: 2,
                                            style: "currency",
                                            currency: "INR",
                                        })}</Td>
                                        <Td>{datehelper.displayDate(new Date(x.emi_due_date))}</Td>
                                        <Td>{x.loan_amount && x.loan_amount.toLocaleString("en-IN", {
                                            maximumFractionDigits: 2,
                                            style: "currency",
                                            currency: "INR",
                                        })}</Td>
                                        <Td>{x.mobile_num}</Td>
                                    </Tr>
                                </>)
                    }
                </Tbody>
            </Table>
        </div>
    )
}

export default DueEmiList