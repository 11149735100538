import React from "react";
import { employeeService } from "../../../_services";
import TrackingData from "./ApplicationData/TrackingData";


export default function TrackingDetails({ cust }) {
  const user = employeeService.userValue;
  console.log(cust);
  return (
    <div>
      <br></br>
      {cust && (
      <TrackingData customer={cust}></TrackingData>
      )}

    </div>
  );
}
