import React from "react";
import { employeeService } from "../../../_services";
import workflowhelper from "../../../_helpers/workflowhelper"
import CheckListModal from "./CheckListModal";
import { useState } from "react";
import SalesTabBar from "./SalesTabBar";
import { TabContext } from "./TabBar";
import { useContext } from "react";
import Switch from '@material-ui/core/Switch';
import { history } from "../../../_helpers";
export const Sales = ({ cust }) => {
  const user = employeeService.userValue;
  const saleContextValue = useContext(TabContext)
  const [checkList, setCheckList] = useState([
    {
      checked:workflowhelper.isOTPVerified(cust),
      value: 'OTP Verification'
    },
    {
      checked: workflowhelper.isLoginFeesReceived(cust),
      value: 'Login Fees'
    },
    {
      checked: workflowhelper.isReferenceAdded(cust),
      value: 'Reference Added'
    }
  ])


  const [state, setState] = React.useState({
    switch_panel: true
});
  const handlePanel = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });

    if(!event.target.checked){
        history.push(`/home/sblloans/details/${cust._id}?isold_view=1`)
        window.location.reload()
    }

    console.log(event.target.checked)
};
  return (
    <div>
         <div style={{ display: "flex", justifyContent: "flex-end", flexDirection: "row" ,marginRight:"3rem"}} >
                <label  >LMS</label> <div style={{ marginTop: "-10px" }} >
                    <Switch
                        checked={state.switch_panel}
                        onChange={handlePanel}
                        color="primary"
                        name="switch_panel"
                    // inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </div><label style={{ color:'blue' }} >LOS</label>
            </div>

      <CheckListModal checkList={checkList} completionPercent={saleContextValue.salesPercent} nextStep={2}/>
      <SalesTabBar cust={cust}/>
      {/* <SaleAppStatus applicant={cust}></SaleAppStatus> */}
      {
       console.log(cust)
      }
    </div>
  );
}