import {Role} from "./role"

const isBranchLevelAccess=(role)=>{
    return [Role.ZSM,Role.RSM,Role.ASM,Role.CCM,Role.ACM,Role.ZCM,Role.BCM,Role.BM,Role.Sales,Role.BOP,Role.BCCM].includes(role)
}

const isAreaLevelAccess=(role)=>{
    return [Role.ZSM,Role.RSM,Role.ASM,Role.ACM,Role.ZCM,Role.ACCM].includes(role)
}

const isRegionLevelAccess=(role)=>{
    return [Role.RSM,Role.ZCM,Role.ZSM,Role.RCCM,Role.RCM].includes(role)
}
const isZonalLevelAccess=(role)=>{
    return [Role.ZCM,Role.ZSM,Role.ZCCM].includes(role)
}
const isClusterLevelAccess=(role)=>{
    return [Role.CSM,Role.CCM,Role.CCCM].includes(role)
}


const isHOLevelAccess = (role) => {
    if(isGMLevelAccess(role))
    {
        return true;
    }
    return [Role.HO,Role.HOA,Role.Admin,Role.GM,Role.AC,Role.CFO,Role.HOC,Role.HOCA,Role.HOCL,Role.HOCLA,Role.HOO,Role.HOOA,Role.HOSA,Role.VPC,Role.PIVG,Role.FH,Role.AM].includes(role)
} 

const isColendingAccess = (role) => {
    if(isGMLevelAccess(role))
    {
        return true;
    }
    return [Role.COL,Role.COLA,Role.COLM,Role.HOA,Role.Admin,Role.GM,Role.AC,Role.CFO,Role.HOCA].includes(role)
}
const isColendingTeamAccess=(role)=>{
    if(isGMLevelAccess(role))
        {
            return true;
        }
        return [Role.COL,Role.COLA,Role.COLM].includes(role)
}
const isColendingTeam = (role) => {

    return [Role.COL].includes(role)
}
const isColendingManagerAccess=(role)=>{
    return [Role.COLM].includes(role);
}

const isVPCLevelAccess=(role)=>{
    return [Role.VPC].includes(role)
}

const isHOApproverAccess = (role) => {
    if(isGMLevelAccess(role))
    {
        return true;
    }
    return [Role.Admin,Role.GM,Role.HOA,Role.COLA,Role.COLM,Role.HOOA,Role.HOCA,Role.HOCLA,Role.HOSA].includes(role)
}

const isHOCreditApproverAccess = (role) => {
    if(isGMLevelAccess(role))
    {
        return true;
    }
    return [Role.HOCA].includes(role)
}

const isHOCollectionApproverAccess = (role) => {
    if(isGMLevelAccess(role))
    {
        return true;
    }
    return [Role.HOCLA].includes(role)
}

const isHOOpsApproverAccess = (role) => {
    if(isGMLevelAccess(role))
    {
        return true;
    }
    return [Role.HOOA].includes(role)
}

const isHOSalesApproverAccess = (role) => {
    if(isGMLevelAccess(role))
    {
        return true;
    }
    return [Role.HOSA].includes(role)
}




const isGMLevelAccess = (role) => {
    return [Role.Admin,Role.GM,Role.CFO,Role.CEO].includes(role)
}

const isCFOAccess = (role) => {
    return [Role.CFO,Role.CEO,Role.Admin].includes(role)
}

const isCEOAccess = (role) => {
    return [Role.CEO,Role.Admin].includes(role)
}
const isPIVGAccess=(role)=>{
    return [Role.PIVG].includes(role)
}
const hasReportsAccess = (role) => {
    // console.log("role is",role)
    if(isGMLevelAccess(role))
    {
        return true;
    }
    if(isHOLevelAccess(role))
    {
        return true;
    }
    if(isITAccess(role))
        {
            return true;
        }
    return [Role.AC,Role.AM,Role.GM,Role.ASM,Role.KTR, Role.RSM,Role.ZSM, Role.HR, Role.LG,Role.ZCM,Role.FH,Role.BM,Role.CSM,Role.BCM,Role.ACM,Role.CCM,Role.COL,Role.COLA,Role.CC,Role.HOGM,Role.HOGC,Role.CLM,Role.VPC,Role.VIPG,Role.EAZSM,Role.COLM].includes(role)
}

const hasAccountsAccess = (role) => {
    if(isGMLevelAccess(role))
    {
        return true;
    }
    return [Role.Admin,Role.AC,Role.AM,Role.FH].includes(role)
}

const isAccountChecker = (role) => {
    if(isGMLevelAccess(role))
    {
        return true;
    }
    return [Role.Admin,Role.AC,Role.GM].includes(role)
}

const isAdminAccess = (role) => {
    return [Role.Admin].includes(role);
}

const isLegalTeamAccess = (role) => {
    if(isGMLevelAccess(role))
    {
        return true;
    }
    return [Role.LG,Role.GM,Role.Admin,Role.CC].includes(role);
}

const isBMLevelAccess= (role)=> {
    return [Role.BM].includes(role);
}

const isASMLevelAccess= (role)=> {
    return [Role.ASM].includes(role);
}

const isRSMLevelAccess= (role)=> {
    return [Role.RSM].includes(role);
}

const isZSMLevelAccess= (role)=> {
    return [Role.ZSM].includes(role);
}

const isCCMLevelAccess= (role)=> {
    return [Role.CCM].includes(role);
}

const isCSMLevelAccess= (role)=> {
    return [Role.CSM].includes(role);
}

const isZCMLevelAccess= (role)=> {
    return [Role.ZCM].includes(role);
}

const isBCMLevelAccess= (role)=> {
    return [Role.BCM].includes(role);
}

const isSalesTeamAccess= (role)=> {
    return [Role.ASM,Role.RSM,Role.ZSM,Role.BM,Role.Sales,Role.CSM].includes(role);
}

const isClusterSaleLevelAccess= (role)=> {
    return [Role.RSM,Role.ZSM,Role.ASM,Role.CSM].includes(role);
}



const isCreditTeamAccess= (role)=> {
    return [Role.ZCM,Role.CCM,Role.ACM,Role.BCM ,Role.RCM].includes(role);
}

const isOperationTeamAccess =(role)=>{
    return [Role.BOP].includes(role)
}
const isHOCreditTeamAccess= (role)=> {
    return [Role.HOC,Role.HOCA].includes(role);
}

const isHOOpsTeamAccess= (role)=> {
    return [Role.HOO,Role.HOOA,Role.BOP].includes(role);
}
const isHOCollectionTeamAccess= (role)=> {
    return [Role.HOCL,Role.HOCLA].includes(role);
}

const isCustomerCareTeam= (role)=> {
    return [Role.CC].includes(role);
}

const isCollectionTeam= (role)=> {
    return [Role.Collections,Role.CLM].includes(role);
}

const isCollectionManager= (role)=> {
    return [Role.CLM].includes(role);
}

const isITAccess = (role) => {
    return [Role.IT].includes(role);
}

const isHOGoldAccess = (role)=> {
    if(isGMLevelAccess(role))
    {
        return true;
    }
    return [Role.HOGM,Role.HOGC,Role.GM,Role.Admin].includes(role);
}

const isHOGoldChecker = (role)=> {
    if(isGMLevelAccess(role))
    {
        return true;
    }
    return [Role.HOGC,Role.GM,Role.Admin].includes(role);
}

const isHOGoldMaker = (role)=> {
    if(isGMLevelAccess(role))
    {
        return true;
    }
    return [Role.HOGM,Role.GM,Role.Admin].includes(role);
}

const isGoldDept = (role)=> {
    return [Role.HOGM,Role.HOGC].includes(role);
}

const isHRDept = (role)=> {
    return [Role.HR].includes(role);
}


const hasReceiptViewAccess = (role) => {
    if(hasAccountsAccess(role)){
        return true;
    }
    if(isSalesTeamAccess(role)){
        return true;
    }
    if(isHOLevelAccess(role)){
        return true;
    }
    if(isCreditTeamAccess(role)){
        return true;
    }
    if(isCollectionTeam(role)){
        return true;
    }
    if(isLegalTeamAccess(role)){
        return true;
    }
    if(isCustomerCareTeam(role)){
        return true;
    }
    if(isBMLevelAccess(role)){
        return true;
    }
    if(isHOGoldAccess(role))
    {
        return true;
    }
    if(isHOCollectionTeamAccess(role))
    {
        return true;
    }

    if(isHRDept(role)){
        return true;
    }
    return false;
}

const hasReceiptCreateAccess = (role) => {
    if(hasAccountsAccess(role)){
        return true;
    }
    if(isSalesTeamAccess(role)){
        return true;
    }
    if(isCreditTeamAccess(role)){
        return true;
    }
    if(isCollectionTeam(role)){
        return true;
    }
    if(isHOCollectionTeamAccess(role))
    {
        return true;
    }
    if(isBMLevelAccess(role)){
        return true;
    }
    return false;
}

const hasSwitchPanelAccess = (role) => {
    if(isGMLevelAccess(role))
    {
        return true;
    }
    if(isHOLevelAccess(role))
    {
        return true;
    }
    if([Role.AM,Role.AC].includes(role))
    {
        return true;
    }
    return false;
}

const isGoldRider = (role)=>{
    return [Role.GLDR].includes(role)
}
const roleChecker={
    isCEOAccess,
    isHOSalesApproverAccess,
    isHOCollectionTeamAccess,
    isHOCreditTeamAccess,
    isHOOpsTeamAccess,
    isHOCollectionApproverAccess,
    isHOCreditApproverAccess,
    isHOOpsApproverAccess,
    isBranchLevelAccess,
    isHOLevelAccess,
    isGMLevelAccess,
    hasReportsAccess,
    hasAccountsAccess,
    isAccountChecker,
    isAdminAccess,
    isLegalTeamAccess,
    isBMLevelAccess,
    isSalesTeamAccess,
    isCreditTeamAccess,
    isCustomerCareTeam,
    isCollectionTeam,
    isHOApproverAccess,
    isCollectionManager,
    isITAccess,
    isASMLevelAccess,
    isClusterSaleLevelAccess,
    isHOGoldAccess,
    isHOGoldChecker,
    isHOGoldMaker,
    isGoldDept,
    hasReceiptCreateAccess,
    hasReceiptViewAccess,
    isGoldRider,
    isHRDept,
    isCFOAccess,
    hasSwitchPanelAccess,
    isCCMLevelAccess,
    isZCMLevelAccess,
    isBCMLevelAccess,
    isCSMLevelAccess,
    isRSMLevelAccess,
    isZSMLevelAccess,
    isColendingAccess,
    isAreaLevelAccess,
    isClusterLevelAccess,
    isRegionLevelAccess,
    isColendingTeam,
    isOperationTeamAccess,
    isVPCLevelAccess,
    isZonalLevelAccess,
    isPIVGAccess,
    isColendingTeamAccess,
    isColendingManagerAccess
}


export default roleChecker 
