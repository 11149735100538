import React from 'react'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { useReactToPrint } from "react-to-print";
import { Button } from "@material-ui/core";
import { virtualAccountService } from "../../_services/virtualaccount.service";


const RepaymentSchedule = (props) => {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [va_details,setVADetails]=React.useState(null);
  const loan = props.loan;

  const RepaymentScheduleRef = React.useRef();
  React.useEffect(()=>{
    
      let fields={'loan_account':loan.loan_account}
      virtualAccountService.getByLoanAccount(fields).then((va)=> {
        //console.log(va);
        setVADetails(va);
      })
      .catch((err)=> {
        console.log(err);
      })
    
  },[])

  async function createQRCode()
  {
    let fields={'loan_account':loan.loan_account,
               'customer_name':loan.name,
              'customer_mobile':loan.contact}
      virtualAccountService.create(fields).then((va)=> {
        console.log(va);
        setVADetails(va);
      })
      .catch((err)=> {
        console.log(err);
      })

  }

  const handleSubmissionPrint = useReactToPrint({
    content: () => RepaymentScheduleRef.current,
    pageStyle: "print"
  });


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
    paddingBottom: '30px',
  };

  let date = new Date(loan.date)
  console.log(date)
  date.setHours(0,0,0,0)
 date.setDate(date.getDate()-1)


  const options = [];

  for(let i =1; i<= loan.period; i++){
    //  date = date + 2592000000
    //  console.log(date)
    options.push(i)
  }


  return (
    <div>
      <Button color='primary' onClick={handleOpen}>Repayment Schedule</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{textAlign: "end"}}>
            <button onClick={handleSubmissionPrint}>Print Copy</button>
          </div>
          <Typography  ref = {RepaymentScheduleRef}  id="modal-modal-title" variant="h6" component="h2">
            <h2>Repayment Schedule List</h2><br/>
            <div className="container my-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <span> Loan Account: {loan.loan_account}</span>   <span style={{ display: "flex", justifyContent: "flex-end" }}> Name:- {loan.name}</span>
            </div>
            <div className="container my-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <span>ROI: {loan.roi}% </span>    <span>Loan Amount: {loan.loan_amount}</span>
            </div> <br/>
            <h4>Interest Table</h4>
            <Table style = {{borderBottom: "2px solid black"}} >
            <Thead>
            <Tr>
            <Td>Month</Td>
               <Td  >EMI Amount</Td>
               <Td  >Date</Td>
            </Tr>
          </Thead>
          <Tbody>
               {options && options.map((element) => {
                 return <React.Fragment>
                <Tr> 
                <Td  >Month {element}</Td>
                <Td >{Math.round(loan.loan_amount*loan.roi/100)}</Td> 
                <Td>{new Date(date.setDate(date.getDate()+30)).toDateString()}</Td>
                </Tr>
                </React.Fragment>      
               })}
           </Tbody>    
            </Table>
            {va_details && (
              <div>
              <h2>Payment QR Code</h2>
              <div
                style={{paddingLeft:'50px',paddingTop:'50px'}}
              >
                  <p><b>Account Payee:</b> Kaabil Finance Private Ltd</p>
                  <p><b>Bank Name:</b> ICICI Bank</p>
                  <p><b>Account Number:</b> {va_details && va_details.va_num}</p>
                  <p><b>IFSC Code:</b> ICIC0000106</p>
                  {
                    va_details && process.env.REACT_APP_QR_CODE_TYPE==="VPA" && va_details.vpa_qrcode_url &&  (
                      <div><img src={va_details.vpa_qrcode_url}></img></div>
                    )
                  }
                  {
                    va_details && process.env.REACT_APP_QR_CODE_TYPE!=="VPA" && (
                      <div><img src={va_details.qrcode_url}></img></div>
                    )
                  }
              </div>
              </div>
            )}
            {!va_details && (
              <div>
                <br/>
                 <Button 
                 color="primary"
                 variant='contained'
                 style={{"margin":10}}
                 onClick={()=> createQRCode()}>Create QR Code</Button>
              </div>
            )}
          </Typography>
        </Box>
      </Modal>
    </div>
  )
}

export default RepaymentSchedule