import React, {useEffect} from 'react'
import LoadingOverlay from "react-loading-overlay";
import { Paper } from "@material-ui/core";
import email_nach_collect_payment from "../../../assets/email_nach_collect_payment.csv"
import { emailNachService } from '../../../_services/emailnach.service';
import { employeeService } from '../../../_services';
import datehelper from '../../../_helpers/datehelper';
import EmailNachCollectRequestModal from './EmailNachCollectRequestModal';

const EmailNachCollectPayments = () => {

  const [spinneractive, setSpinneractive] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [state, setState] = React.useState(false)
  const [data, setData] = React.useState([]);
  const user = employeeService.userValue;

  const arr = []


  const onChangeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const getAllData = () =>{
    emailNachService.getPaymentCollections()
    .then((x) => {
      console.log(x);
      setData(x)      
    })
    .catch((err) => {
      console.log("Data Not Found", err)
    })
  }



  useEffect(() => {
    getAllData()
  }, [])
  

  const onClickHandler = async () => {
    setSpinneractive(true);

    const data = new FormData();
    data.append("file", selectedFile);
    console.log(data.entries())
    for (var key of data.entries()) {
      console.log(key[0] + ', ' + key[1]);
  }  

    await emailNachService.uploadEmailnachCollectionCsv(data,user.emp_id).then((response) => {
      // setSpinneractive(false);
    
      if (!response.ok) {
        alert("An Error Occurred, Please try again");
      } else {
        alert("Data Uploaded");
        window.location.reload();
      }
    });
  };


  const show = () => {
    setState(!state)
  }
  
  return (
    <>
        <LoadingOverlay active={spinneractive} spinner text="Uploading Data...">

     <Paper  style={{
              padding: 20,
            }}>
              <h2 style={{textAlign: "center"}}>Email Payment Collection List</h2><hr></hr>
              <div style={{ display: "flex", justifyContent: "left" }}>
              <button onClick={show}>Upload CSV File</button>
            </div>
           { state === true ? <div className="container">
              <div className="offset-md-3 col-md-6">
                <form method="post" action="#" id="#">
                  <div className="form-group files">
                    <label>Upload Your File</label>
                    <input
                    style={{height: "30px"}}
                      accept=".csv"
                      type="file"
                      className="form-control"
                      onChange={(e) => onChangeHandler(e)}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-success btn-block"
                    onClick={() => onClickHandler()}
                  >
                    Upload
                  </button>
                </form>
                <div >
                  <a href={email_nach_collect_payment} download style={{ display: "flex", justifyContent: "right", textDecoration: "none", textStyling: "none", color: "red" }} >Download Sample File <b>"date format as dd/mmy/yyyy Very strict"</b></a>
                </div>
              </div>
            </div> : null}
            <div style={{ display: "flex", justifyContent: "right" }}>
              <EmailNachCollectRequestModal/>
            </div>
     </Paper>
     </LoadingOverlay>
     
      <table>
        <thead>
          <tr>
            <th>Loan Number</th>
            <th>UMRN Number</th>
            <th>Amount</th>
            <th>Collection Date</th>
            <th>Collection Status</th>
            <th>Collected By</th>
          </tr>
        </thead>
        <tbody>
        {data && data.map((element) => {
          return <tr key={element._id}>
            <td>{element.loan_number}</td>
            <td>{element.umrn_number}</td>
            <td>{element.amount}</td>
            <td>{datehelper.displayDate(new Date(element.collection_date))}</td>
            <td>{element.collection_status==='Failed' && (<span style={{'color':'red'}}>Failed</span>)}{element.collection_status!='Failed' && element.collection_status }</td>
            <td>{element.collection_comment}</td>
            <td>{element.collection_emp_id}</td>
          </tr>
        })}
        </tbody>
      </table>
     
    </>
  )
}

export default EmailNachCollectPayments