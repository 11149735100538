import { fetchWrapper } from "../_helpers";
const apiUrl = process.env.REACT_APP_API_URL;
const baseUrl = `${apiUrl}/releaseletter`;
async function saveReleaseData(params) {
    console.log("ser params", params)
    return fetchWrapper.post(`${baseUrl}/savedata`,params)
}

async function countLetter(){
    return fetchWrapper.get(`${baseUrl}/countletter`)
}

async function getLetter(){
  return fetchWrapper.get(`${baseUrl}/getletter`)
}
async function searchByLetterNumber(params) {
  return fetchWrapper.post(`${baseUrl}/searchletter`, params)
}
export const releaseServices = {
    saveReleaseData,
    countLetter,
    getLetter,
    searchByLetterNumber
}

