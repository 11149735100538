import React from "react";
import "./authority.css";
import logo from "../../../assets/kaabil.png";

export default class AuthorityLetter extends React.Component {
  render() {
    const data = this.props.dataFill;
    console.log(data);
    let date = new Date(data.date);

    const yy = date.getFullYear()
    const mm = date.getMonth() + 1;
    const dd = date.getDate()

    date = dd + "/" + mm + "/" + yy

    return (
      <div className="submission">
        <style type="text/css" media="print">
          {"\
       @page { size: A4; }\
    "}
        </style>
        <div className="main">
          <br />
          <br />
          <br />
          <br />
          <br />
          <h3>LETTER OF AUTHORITY FROM BORROWER </h3>
          <br />
          {/* <b>
                Date: {date.getDate()}-
                {date.toLocaleString("default", { month: "long" })}-
                {date.getFullYear()}
              </b>{" "}
              <br />
              <br /> */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }} >
            <div className="left">
              From <br />
              405 408 SB 157, Mile Stone Building,<br />
              Gandhi Nagar mode, Jaipur,<br />
              Rajasthan(302015)
            </div>
            <div className="right">
              Place: Jaipur       <br />
              Date: {date} <br />
            </div>
          </div>
          <br />
          <br />
          <div className="left">
            To <br />
            The Branch Manager <br />
            CSB Bank <br />
            Jaipur  <br />
          </div>
          <br />
          <br />
          <div>
            Sir, <br />
            Sub: Letter of Authority authorising disbursal, creation and release of pledge and operations in the Gold Loan through the Business Correspondent of the Bank M/s Kaabil Finance Pvt. Ltd. as part of door step banking services.
            <br />
            <br />
            Name of Borrower: {data.name}  <br />
            Gold Loans sanctioned <br />
            <table style={{ width: "100%" }}>
              <tr>
                <th>Sr.</th>
                <th>Gold Loan details</th>
                <th>Loan Amount (Rs.)</th>
              </tr>
              <tr>
                <td>1.</td>
                <td>{data.loan_account}</td>
                <td>{data.loan_amount}</td>
              </tr>
            </table>
            <br />
            At my/our request, CSB Bank Ltd. has agreed to grant me/us Gold Ioans as detailed above through the Business Correspondent of the Bank M/s Kaabil Finance Pvt. Ltd. as part of Bank’s door step banking services. In this regard I hereby specifically authorise the bank to facilitate disbursal, creation of pledge of the gold ornaments, operations and release of pledge of gold ornaments in respect of the loans aforementioned through the Business Correspondent of the Bank M/s Kaabil Finance Pvt. Ltd. We are aware of and accept the risks involved in transportation of gold ornaments from my/our house/office to Bank and back. <br />
            I hereby authorise and empower the Business Correspondent of the Bank, M/s Kaabil Finance Pvt. Ltd. Or its authorised representatives, to handover to the Bank/receive the Gold ornaments / other documents deposited by me with the bank after the entire / part outstanding liability with the bank is repaid by me. All the risks associated with transportation and pursuant custody is undertaken by M/s Kaabil Finance Pvt. Ltd.  and I/we shall have no claim against the Bank in this respect. <br />
            I/we am/are aware that the disbursal by the Bank is subject to satisfaction of the Bank as to the purity of gold ornaments pledged and subject to compliance of lending norms of the Bank and for any reasons including if it turns out that such ornaments are of inferior quality/spurious Bank is at liberty to reject the loan and in such an event I/we undertake thereupon to return forthwith the loan amount/s if any received from the Business Correspondent of the Bank,  M/s Kaabil Finance Pvt. Ltd. <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            Name of the Borrower and signature
          </div>
        </div>
        <div className="main">
          <h3 style={{marginTop:"480px"}}>
            CONFIRMATION LETTER
          </h3>
          <div>
            To <br />
            Branch Head <br />
            CSB Bank Ltd. <br />
            <br />
            Dear Sir <br />
            <br />

            I/We hereby confirm that the following Gold loans are availed by me/us through the Business Correspondent of the bank M/s Kaabil Finance Pvt. Ltd. <br />
            <br />
            <table style={{ width: "100%" }}>
              <tr>
                <th>Sr.</th>
                <th>Gold Loan details</th>
                <th>Loan Amount (Rs.)</th>
                <th>Date of Loan</th>
              </tr>
              <tr>
                <td>1.</td>
                <td>{data.loan_account}</td>
                <td>{data.loan_amount}</td>
                <td>{date}</td>

              </tr>
            </table>
            <br />
            I hereby confirm the receipt of loan amount/s of Rs. {data.loan_amount}/-  as detailed above from the Business Correspondent of the Bank M/s Kaabil Finance Pvt. Ltd. on behalf of the Bank. <br />
            <br />
            <br />
            <br />
            Name of the Borrower and signature    <br />
            <br />
            Place: Jaipur <br />
            <br />
            Date: {date} <br />
            <br/>
          </div>
        </div>
      </div>
    );
  }
}


