
const datehelper={
    displayDate,
    displayDateTime,
    displayDateTimeFromString,
    getPaymentDateString,
    getDateInputString,
    getMaxPastDate,
    getPastDate,
    getLastMonthNames,
    getDateEnd,
    getDateStart,
    getDateDiffSeconds,
    getDateDiffDays,
}

function displayDate(dateObj)
{
    const months=["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT",
    "NOV", "DEC"];
    let monthNumber=dateObj.getMonth();
    return dateObj.getDate()+"-"+months[monthNumber]+"-"+dateObj.getFullYear();
}

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

function displayDateTime(dateObj)
{
    const months=["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT",
    "NOV", "DEC"];
    let monthNumber=dateObj.getMonth();
    return dateObj.getDate()+"-"+months[monthNumber]+"-"+dateObj.getFullYear()+" "+formatAMPM(dateObj);
}

//case when +5:30 is added to IST
function displayDateTimeISTAdjustNegative(dateObj)
{
    dateObj.setHours(dateObj.getHours()-5)
    dateObj.setMinutes(dateObj.getMinutes()-30)
    const months=["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT",
    "NOV", "DEC"];
    let monthNumber=dateObj.getMonth();
    return dateObj.getDate()+"-"+months[monthNumber]+"-"+dateObj.getFullYear()+" "+formatAMPM(dateObj);
}

function displayDateTimeFromString(dateStr)
{
    //console.log(dateStr);
    let dateObj= new Date(Date.parse(dateStr));
    //console.log(dateObj);
    //dateObj.setHours(dateObj.getHours()-5)
    //dateObj.setMinutes(dateObj.getMinutes()-30)
    const months=["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT",
    "NOV", "DEC"];
    let monthNumber=dateObj.getMonth();
    return dateObj.getDate()+"-"+months[monthNumber]+"-"+dateObj.getFullYear()+" "+formatAMPM(dateObj);
}


function getPaymentDateString(dateObj)
{
  
    return dateObj.getFullYear()+"-"+("0"+(dateObj.getMonth()+1)).slice(-2)+"-"+("0"+dateObj.getDate()).slice(-2);
}

function getDateInputString(dateObj)
{
  
    return dateObj.toISOString().substr(0,10);
}

function getMaxPastDate(format)
{
  if(format=="Y-m-d" || format=="yyyy-mm-dd")
  {
    let max_date = new Date()
    let dd = ("00"+max_date.getDate()).slice(-2)
    let mm = ("00"+(max_date.getMonth()+1)).slice(-2);
    let yy = max_date.getFullYear();
    max_date =yy+ "-" + mm + "-" + dd;
    return max_date;
  }
}

function getPastDate(format,days)
{
  if(format=="Y-m-d")
  {
    let dateObj = new Date();
    dateObj.setDate(dateObj.getDate()-days);
    let dd = ("00"+dateObj.getDate()).slice(-2)
    let mm = ("00"+(dateObj.getMonth()+1)).slice(-2);
    let yy = dateObj.getFullYear();
    let dateStr =yy+ "-" + mm + "-" + dd;
    return dateStr;
  }
}

function getLastMonthNames(monthBack){
  const months=["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT",
  "NOV", "DEC"];
  const monthNames={}
  let counter=1;
  let dateObj = new Date();
  let currentMonth=dateObj.getMonth()
  let currentYear=dateObj.getFullYear()
  let monthCounter=currentMonth+1;
  let yearCounter=currentYear;
  while(counter <=monthBack)
  {
     let month_key=monthCounter+"-"+yearCounter
     monthNames[month_key]=months[monthCounter-1]+"-"+yearCounter
     monthCounter=monthCounter-1
     if(monthCounter==0)
     {
         monthCounter=12
         yearCounter=yearCounter-1
     }
     counter=counter+1
  }
  return monthNames

}

function getDateEnd(dateObj){
  if(dateObj)
  {
     dateObj.setHours(23, 59, 59, 999);
  }
  return dateObj;
}

function getDateStart(dateObj){
  if(dateObj)
  {
     dateObj.setHours(0, 0, 0, 0);
  }
  return dateObj;
}

function getDateDiffSeconds(date1,date2)
{
    let datediff=date1-date2;
    return datediff/1000;
}

function getDateDiffDays(date1,date2)
{
    let datediffSeconds=getDateDiffSeconds(date1,date2);
    return datediffSeconds/86400;
}


export default datehelper; 
