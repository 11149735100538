import React, { useState } from 'react'
import { Button, Modal, Box } from '@material-ui/core'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Add } from '@material-ui/icons';
import { employeeService } from '../../_services';
import * as Yup from "yup";
import datehelper from "../../_helpers/datehelper"
import { ledgerService } from '../../_services/ledger.service';
const CashRequestModal = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 900,
        height: 420,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        borderRadius: '5px'
    };
    const user = employeeService.userValue
    let date = datehelper.displayDate(new Date())
    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    let initialValues = {
        amount: '',
        branch: user.branch.branch_id,
        requested_by: user.first_name,
        requested_date: new Date(),
        status: 'Pending',
        requested_by_emp_id: user.emp_id
    }
    let validationSchema = Yup.object().shape({
        amount: Yup.number().required('This is Required').max(500000, 'Maximum amount limit is ₹5,00,000')
    })
    const onSubmit = (values) => {
        ledgerService.createCashRequest(values)
            .then((res) => {
                alert("Cash Request Created !!")
                window.location.reload()
            })
            .catch((err) => console.log(err))
    }
    return (
        <div>
            <Button onClick={handleOpen}
                style={{ display: 'flex', float: "right", marginBottom: "15px", background: "#3251F5", color: 'white' }}>
                <Add />Create Request</Button>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ errors, touched, isSubmitting, setFieldValue, values }) => {
                            return (
                                <Form>
                                    <h2 style={{ textAlign: "center" }}>Create Cash Request</h2>
                                    <div style={{ display: "flex", justifyContent: "space-around", fontSize: '20px' }}>
                                        <div style={{ display: "flex", flexDirection: "column", color: "blue" }}>
                                            <label>Amount</label>
                                            <Field
                                                name="amount"
                                                type="number"
                                                placeholder="Enter Amount"
                                                style={{ width: "200px", height: "37px", border: "2px solid #3251F5", borderRadius: '2px' }}
                                                className={
                                                    "form-control" +
                                                    (errors.amount && touched.amount ? " is-invalid" : "")
                                                } />
                                            <ErrorMessage
                                                name="amount"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", }}>
                                            <label style={{ color: "blue" }}>Branch</label>
                                            <Field
                                                name="branch"
                                                disabled
                                                style={{ width: "200px", height: "37px", border: "2px solid #3251F5", borderRadius: '2px' }}
                                            />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", }}>
                                            <label style={{ color: "blue" }}>Requested Date</label>
                                            <Field
                                                value={date}
                                                disabled
                                                style={{ width: "200px", height: "37px", border: "2px solid #3251F5", borderRadius: '2px' }}
                                            />
                                        </div>
                                    </div>
                                    <br />
                                    <div style={{ display: "flex", flexDirection: "column", marginLeft: '40px', fontSize: "20px" }}>
                                        <label style={{ color: "blue" }}>Requested By</label>
                                        <Field
                                            name="requested_by"
                                            disabled
                                            style={{ width: "200px", height: "37px", border: "2px solid #3251F5", borderRadius: '2px' }}
                                        />
                                    </div>
                                    <br />
                                    <br />
                                    <button
                                        type='submit'
                                        disabled={isSubmitting}
                                        style={{
                                            display: 'flex', justifyContent: 'center', background: '#16C007',
                                            width: '120px',
                                            margin: 'auto',
                                            color: "white",
                                            border: "1px solid #16C007",
                                            fontSize: "22px"
                                        }}
                                    >
                                        {isSubmitting && (
                                            <span className="spinner-border spinner-border-sm mr-1"></span>
                                        )}
                                        Create
                                    </button>
                                </Form>)
                        }}
                    </Formik>
                </Box>
            </Modal>

        </div>
    )
}

export default CashRequestModal;
