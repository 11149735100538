import React from "react";
import { useState, useEffect } from "react";
import roleChecker from "../_helpers/rolechecker";
import { employeeService, misService ,customerService} from "../_services";
import { BarChart, PieChart ,Pie , Cell ,Bar, CartesianGrid, XAxis, YAxis, Legend, Tooltip, LabelList, ResponsiveContainer } from "recharts";
import { reportsService } from "../_services/reports.service";
import Product from "../_helpers/product";
import { Button } from "@material-ui/core";
import { history } from "../_helpers";
import { Paper } from "@material-ui/core";
import displayhelper from "../_helpers/displayhelper";
import datehelper from "../_helpers/datehelper";
function RealTimeDashboardTab() {
  const user = employeeService.userValue;
  const [metricsData, setMetricsData] = useState({})
  const [time, setTime] = useState(Date.now());
  let todayDate= new Date()
  let currentDate= new Date();
  let todayEnd= datehelper.getDateEnd(todayDate);
  let hours=Math.floor(datehelper.getDateDiffSeconds(todayEnd,currentDate)/3600);
  let minutes=Math.floor((datehelper.getDateDiffSeconds(todayEnd,currentDate)-hours*3600)/60);
  let seconds=Math.floor((datehelper.getDateDiffSeconds(todayEnd,currentDate)-hours*3600-minutes*60));
  
  // console.log("hourse left="+hours);
  const [hourLeft,setHourLeft]= useState(hours);
  const [minuteLeft,setMinuteLeft]= useState(minutes);
  const [secondLeft,setSecondLeft]= useState(seconds);
  const [loading,setLoading]=useState(true);
  let branchId='';
  let matrix_key='';
  const [chartData, setChartData] = useState({})
  let colors = ['#3C2A21', '#FFA500', '#5D8AA8', '#46C2CB', '#800000','#2D87BB','#64C2A6',"#547ac4"]
  
  useEffect(() => {
    loadMetrics()
  }, [])

  async function loadMetrics()
  {

    setLoading(true);
    if (roleChecker.isHOLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isASMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isBCMLevelAccess(user.role) || roleChecker.isCSMLevelAccess(user.role) || roleChecker.isRSMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) || roleChecker.isSalesTeamAccess(user.role)) {

      if (roleChecker.isHOLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) ) {
        console.log('User role ---->>> ', user.role, user.branch_id)
        branchId = 'zone-' + user.branch_id
        matrix_key = user.branch_id
        // branchId = 'Zone-J03'
      } 

      else if (roleChecker.isRSMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role)) {
        console.log('User role ---->>> ', user.role, user.branch_id)
        branchId = 'region-' + user.branch_id
        matrix_key = user.branch_id
      }
      else if (roleChecker.isAreaLevelAccess(user.role)) {
        console.log('User role ---->>> ', user.role, user.branch_id)
        branchId = 'area-' + user.branch_id
        matrix_key = user.branch_id
      }
      else if (roleChecker.isCSMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role)) {
        console.log('User role ---->>> ', user.role, user.branch_id)
        branchId = 'cluster-' + user.branch_id
        matrix_key = user.branch_id
      }
      else if (roleChecker.isBMLevelAccess(user.role)) {
        branchId = 'branch-' + user.branch_id
        matrix_key = user.branch_id
        console.log('User role ---->>> ', user.role, user.branch_id,matrix_key)
      }

      else if (roleChecker.isBCMLevelAccess(user.role)) {
        branchId = 'branch-' + user.branch_id
        matrix_key = user.branch_id
        console.log('User role ---->>> ', user.role, user.branch_id,matrix_key)
      }

      else if (roleChecker.isSalesTeamAccess(user.role)) {
        branchId = 'branch-' + user.branch_id
        matrix_key = user.branch_id
        console.log('User role ---->>> ', user.role, user.branch_id,matrix_key)
      }


      else {
        branchId = user.branch_id
         console.log('User role ---->>> ', user.role, user.branch_id, branchId)
      }

      reportsService.getRealMetricsData({ 'key':'login_count', 'branch_key':branchId}).
      then((metricListObj) => {
        console.log(metricListObj);
        let tmpMetricsData={}
        metricListObj.map((mo)=> {
          tmpMetricsData[mo.key]=mo.display_data
        })
        console.log(tmpMetricsData);
        setMetricsData(tmpMetricsData);
      })
      .catch((err)=> {
        console.log(err);
      })
    }
     
  }

function reloadPage()
{
   setTime(new Date());
   console.log("reloading ...");
   window.location.reload(true);
}

function reloadTime()
{
  todayDate= new Date()
  currentDate= new Date();
  todayEnd= datehelper.getDateEnd(todayDate);
  let secondsTotal=datehelper.getDateDiffSeconds(todayEnd,currentDate)
  // console.log("total seconds"+secondsTotal);
  hours=Math.floor(secondsTotal/3600);
  let minuteSeconds=secondsTotal-(hours*3600)
  // console.log("total seconds in minutes"+minuteSeconds);
  minutes=Math.floor(minuteSeconds/60);
  seconds=Math.floor(secondsTotal-hours*3600-minutes*60);
  setHourLeft(hours)
  setMinuteLeft(minutes)
  setSecondLeft(seconds)
}

useEffect(() => {
  const interval = setInterval(() => reloadPage(), 120000);
  return () => {
    clearInterval(interval);
  };
}, []);

useEffect(() => {
  const interval = setInterval(() => reloadTime(), 1000);
  return () => {
    clearInterval(interval);
  };
}, []);

  const percentageLabelFormatter = (value) => {
    return value + '%';
  };

  const [targets, setTargets] = useState([]);
  const [currentWeekData, setCurrentWeekData] = useState(null);
  const searchTarget = async () => {
    setLoading(true);
    let branchId;

    if (roleChecker.isHOLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isASMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isBCMLevelAccess(user.role) || roleChecker.isCSMLevelAccess(user.role) || roleChecker.isRSMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) || roleChecker.isSalesTeamAccess(user.role)) {
      if (roleChecker.isHOLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role)) {
        branchId = 'zone-' + user.branch_id;
      } else if (roleChecker.isRSMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role)) {
        branchId = 'region-' + user.branch_id;
      } else if (roleChecker.isAreaLevelAccess(user.role)) {
        branchId = 'area-' + user.branch_id;
      } else if (roleChecker.isCSMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role)) {
        branchId = 'cluster-' + user.branch_id;
      } else if (roleChecker.isBMLevelAccess(user.role) || roleChecker.isBCMLevelAccess(user.role) || roleChecker.isSalesTeamAccess(user.role)) {
        branchId = 'branch-' + user.branch_id;
      } else {
        branchId = user.branch_id;
      }

      try {
        const res = await customerService.searchTarget({ branch_key: branchId });
        let parsedResponse = typeof res === 'string' ? JSON.parse(res) : res;

        if (Array.isArray(parsedResponse)) {
          setTargets(parsedResponse);
          // Calculate and set current week's data
          const today = new Date();
          const currentWeek = getCurrentWeek(today.getDate());
          const currentWeekData = parsedResponse.map(target => ({
            ...target,
            currentWeek: target.weekly_target.find(week => week.week === `Week-${currentWeek}`)
          }));
          setCurrentWeekData(currentWeekData);
        } else {
          console.error('Data fetched is not an array:', parsedResponse);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    }

    setLoading(false);
  };
  const getCurrentWeek = (day) => {
    if (day >= 1 && day <= 7) return 1;
    if (day >= 8 && day <= 14) return 2;
    if (day >= 15 && day <= 21) return 3;
    if (day >= 22 && day <= 28) return 4;
    if (day >= 29) return 5;
    return null;
  };

  useEffect(() => {
    searchTarget();
  }, []);
  return (
    <div className="p-4">
        <div className="container" style={{display: 'flex', justifyContent: 'center'}}>

          {metricsData && Object.keys(metricsData).length>0 && (
            <Paper style={{width:'710px', borderRadius: '10px', margin: '3px', padding: '3px' }}>
            <h3 style={{textAlign:'center',fontWeight:'bold'}}>Today's Team Achievements</h3>
            <h4>Time Left: <span style={{color:"red",fontSize:"2rem"}}>{hourLeft} Hours : {minuteLeft} Minutes: {secondLeft} Seconds </span></h4>
            <hr style={{ width: '100%' }} />
            
          <hr style={{ width: '100%' }} />

          { (roleChecker.isHOLevelAccess(user.role) || roleChecker.isGMLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isASMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isCSMLevelAccess(user.role) || roleChecker.isRSMLevelAccess(user.role) || roleChecker.isSalesTeamAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) )&&   
          
          <div style={{ display: 'flex',marginBottom:"5px" }}>
                  <div style={{ width: '620px', height: '50px', backgroundColor: colors[7], borderRadius: '10px', color: 'white', margin: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
                      <b style={{fontSize:"30px"}}>Total Logins : {metricsData['login_count']}</b>
                    </div>
            </div>}
            {loading ? (
        <p>Loading...</p>
      ) : targets.length > 0 ? (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {currentWeekData && currentWeekData.map((target, index) => (
            <div key={target._id} style={{
              display: 'flex',
              width: '300px',
              height: '100px',
              backgroundColor: colors[index % colors.length], // Cycle through colors
              borderRadius: '10px',
              color: 'white',
              margin: '10px',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-around',
              fontSize: '1.5rem',
              padding: '10px'
            }}>
              <b style={{ fontSize: '18px' }}>
                {target.key === "login_target" ? "Login Target" : target.key === "disbursement_target" ? "Disbursement Target (in Lakhs)" : ""}
              </b>
              {target.currentWeek && (
                <div>
               
                  <p>{target.approval_status==="approved"?(target.currentWeek.target / 7).toFixed(0):0}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        < div style={{ display: 'flex' }}>
         <div style={{ width: '300px', height: '100px', backgroundColor: colors[0], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
             <b style={{fontSize:'18px'}}>Disbursement Target (in Lakhs)</b>
             0
           </div>

           <div style={{ width: '300px', height: '100px', backgroundColor: colors[1], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
                      <b style={{fontSize:'18px'}}>Login Target</b>
                     0
                    </div>
   </div>
      )}
        {  (roleChecker.isHOLevelAccess(user.role) || roleChecker.isGMLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isASMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isBCMLevelAccess(user.role) || roleChecker.isCSMLevelAccess(user.role) || roleChecker.isRSMLevelAccess(user.role)|| roleChecker.isZSMLevelAccess(user.role)  || roleChecker.isSalesTeamAccess(user.role))&&    
        
        < div style={{ display: 'flex' }}>
                 { <div style={{ width: '300px', height: '100px', backgroundColor: colors[0], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
                      <b style={{fontSize:'20px'}}>Sanction Letter Count</b>
                      {metricsData['sanction_count']}
                    </div>}

                    { <div style={{ width: '300px', height: '100px', backgroundColor: colors[0], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
                      <b style={{fontSize:'20px'}}>Sanction Letter Amount</b>
                      { displayhelper.displayNumberAccounting(Number(metricsData['sanction_amount']))}
                    </div>}
            </div>}

           { (roleChecker.isHOLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isASMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isCSMLevelAccess(user.role) || roleChecker.isRSMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) )&&
           
           <div style={{ display: 'flex' }}>
                 <div style={{ width: '300px', height: '100px', backgroundColor: colors[3], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
                      <b style={{fontSize:'20px'}}>Disbursement Memo Count</b>
                      {metricsData['disbursement_count']}
                    </div>

                  <div style={{ width: '300px', height: '100px', backgroundColor: colors[3], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
                      <b style={{fontSize:'20px'}}>Disbursement Memo Amount</b>
                      { displayhelper.displayNumberAccounting(Number(metricsData['disbursement_amount']))}
                    </div>


            </div>}

           {(roleChecker.isHOLevelAccess(user.role) || roleChecker.isASMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isCSMLevelAccess(user.role) || roleChecker.isRSMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) )&& 
           
           <div style={{ display: 'flex' }}>
                  <div style={{ width: '300px', height: '100px', backgroundColor: colors[1], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
                      <b style={{fontSize:'20px'}}>Colender Receive Count</b>
                      {metricsData['colender_count']}
                    </div>

                    <div style={{ width: '300px', height: '100px', backgroundColor: colors[1], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
                      <b style={{fontSize:'20px'}}>Colender Receive Amount</b>
                      { displayhelper.displayNumberAccounting(Number(metricsData['colender_amount']))}
                    </div>


            </div>}

            {(roleChecker.isHOLevelAccess(user.role) || roleChecker.isASMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isCSMLevelAccess(user.role) || roleChecker.isRSMLevelAccess(user.role))&& 
           
           <div style={{ display: 'flex' }}>
                  <div style={{ width: '300px', height: '100px', backgroundColor: colors[1], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
                      <b style={{fontSize:'20px'}}>Collection Receive Count</b>
                      {metricsData['colender_count']}
                    </div>

                    <div style={{ width: '300px', height: '100px', backgroundColor: colors[1], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
                      <b style={{fontSize:'20px'}}>Collection Receive Amount</b>
                      { displayhelper.displayNumberAccounting(Number(metricsData['colender_amount']))}
                    </div>


            </div>}
            </Paper>
          )} 
          </div>
    </div>
  );
}

export {RealTimeDashboardTab};