import React from 'react'
import { useReactToPrint } from "react-to-print";
import datehelper from '../../../_helpers/datehelper';
import kaabilLogo from "../../../assets/kaabil.png"
import EmailIcon from '@material-ui/icons/Email';
import CallIcon from '@material-ui/icons/Call';
import LanguageIcon from '@material-ui/icons/Language';
const PPHandOverDocument = (props) => {

    const DocumentListLetterRef = React.useRef();

    const handleSubmissionPrint = useReactToPrint({
        content: () => DocumentListLetterRef.current,
        pageStyle: "print"
    });


    const data = props.location.state;
    console.log(data)

    let date = new Date();
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct",
        "Nov", "Dec"];
    const yy = date.getFullYear();
    const mm = date.getMonth();
    const dd = date.getDate();

    date = months[mm] + " " + dd + "," + " " + yy;

    return (
        <>

            <div style={{ textAlign: "center" }}>
                <button onClick={handleSubmissionPrint}>Print Letter</button>
            </div>

            <div ref={DocumentListLetterRef} style={{ fontSize: "20px", textAlign: "justify"}}>
            <img
            src={kaabilLogo}
            style={{ height: "130px" }}
            alt="KFPL Logo"
          />
          <p style={{textAlign:"center"}}><b>Acknowledgment</b></p>
                <div style={{marginTop:"60px"}}>
                <span>Letter Id: {data.letter_id}</span> <br/>
                    <span>Date: { datehelper.displayDate(new Date(date))} </span>
                
                </div><br />

                <div>
                    To,<br />
                    <b>The Manager</b><br />
                    <b>Kaabil Finance Pvt. Ltd.</b><br />
                    <b>Plot No. 1, Scheme No. 1, Income Tax Colony, <br />Model Town, Jagatpura, Jaipur Rajasthan 302017</b>
                  
                </div>
               {data.stage==="Closed" && <>
                <br />
                Closure of Loan Account Number <b>{data.application_id}</b> in favor of <b>{data.title}. {data.name} S/o {data.sonof}</b>
                </>} 
              
                <br /><br />
<b>Subject - Acknowledgment receipt for Property Paper and Cancel Cheques.</b><br /><br />
                <div>
                    Dear Sir/Madam,
                  
                </div>
                <br />
                <div>
                {data.stage==="Closed"?
                <p>I/we hereby confirm that I/we have submitted the following documents at the time of availing loan
in favor of <b>{data.title}. {data.name} S/o {data.sonof}</b> - vide Loan Account No. <b>{data.application_id}</b>.</p>:
<p>I/we hereby confirm that I/we have submitted the following documents, pertaining to property as
mentioned below, along with Loan application for <b>{data.title}. {data.name} S/o {data.sonof} – {data.application_id}</b>.</p>}
                </div><br />

                <div>
                    <table style={{ border: "1px solid black" }}>
                        <tbody>
                            <tr>
                            <td style={{ border: "1px solid black",width:"10%",textAlign:"center" }}>
                            S.NO
                                </td>
                                <td style={{ border: "1px solid black",width:"80%",textAlign:"center" }}>
                                DOCUMENT NAME
                                </td>
                                <td style={{ border: "1px solid black",width:"10p%",textAlign:"center" }}>
                                    COPY/ORIGNAL
                                </td>
                            </tr>
                            {data.documents && data.documents.map((x ,index) => {
                                return <tr>
                                    <td style={{ border: "1px solid black" ,width:"10%",textAlign:"center"}}>
                                        {index+1}
                                    </td>
                                    <td style={{ border: "1px solid black",width:"80%" }}>
                                        {x.documentData}
                                    </td>
                                    <td style={{ border: "1px solid black",width:"10%" ,textAlign:"center"}}>
                                        {x.papertype}
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div><br />
                {data.stage==="Closed"?<p>I/we hereby confirm that, I/we have received these documents from M/s Kaabil Finance Private
                    Limited (KFPL) today. No other documents is due from Kaabil Finance Private Limited now.<br/> Received all documents.
                    </p>:<p>  As I/we am/are not availing this loan, I/we hereby confirm that I/we received these documents from
                    M/s Kaabil Finance Private Limited</p>}
              
                <div><br />
                </div><br />
                {data.stage==="Closed"?
                  <div style={{display:"flex",justifyContent:"space-between"}}>
                  <div><p>(Property Owner’s Signature)<br/>Kaabil Rep – Handed over all documents.</p><br/><p>Witness : Rep of Finance Co taking Over Loan</p></div>
                  <div><p>Seal/Signature</p></div>
                  <div><p>Date</p></div>
              </div>:
                <div style={{display:"flex",justifyContent:"space-around"}}>
                    <div><p>Applicant/Co. Applicant/ GTR</p><br/><br/><br/><p>Signature</p></div>
                    <div><p>Branch Manager</p><br/><br/><br/><p>Signature</p></div>
                </div>}

              
                <div className="header-infos" >
     <div className="header-top"  style={{ textAlign: "center" }}>
       <div>
         <span>
           <strong><EmailIcon/>  care@kaabilfinance.com</strong>
         </span>
       </div>
       <div>
         <span>
           <strong><CallIcon/> 0141-4005012</strong>
         </span>
       </div>
       <div>
         <strong><LanguageIcon/> www.kaabilfinance.com</strong>
       </div>
     </div>
     <div className="header-bottoms" style={{fontSize:"18px"}}>
      
         <p> <strong>Registered & Corporate Office </strong> :- Kaabil Towers, Plot No. 1, Scheme No. 1, Income Tax Colony, Model Town, Jagatpura, Jaipur Rajasthan 302017 CIN No. U65929RJ2017PTC058138 ,GSTIN : 08AAGCK6104N1ZA</p>
         
     </div>
     {/* Fixed bottom footer */}
     
   </div>
            </div>
        </>
    )
}

export default PPHandOverDocument
