import React, { useState, useEffect } from "react";
import { collectionService } from "../../_services/collection.service";
import { employeeService } from "../../_services";
import { Delete } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { Modal } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { CSVLink } from "react-csv";
import { receiptService } from "../../_services/receipt.service";
import { Print } from "@material-ui/icons";
import datehelper from "../../_helpers/datehelper";
import { reconcile } from "../../_services/reconcile.service";
import { ReconcilationVerificationModal } from "./ReconcilationVerificationModal";


function ReconcilationList(props) {
  const [reconcileList, setReconcileList] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState({})
  const [recon, setRecon]=useState(false)
  const [dateValue, setDateValue]=useState({})
  const [customerPayment, setcustomerPayment] = useState()
  const [date, setDate] = useState()
  const user = employeeService.userValue;

 
  const handleClose = () => {
    setOpen(false)
  }



  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    height: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    //overflow: 'scroll',
    //paddingBottom: '30px',
  };
  

  const handleDate =(fieldKey, fieldValue)=>{
    dateValue[fieldKey]=fieldValue;
    setDateValue(dateValue)
  }
  const getByDate = async ()=>{
    console.log(dateValue);
    await reconcile.getByDate(dateValue).then((response)=>{
      console.log(response);
      setReconcileList(response);
    })
    .catch((err)=> {
      console.log(err);
    })
  }

  useEffect(() => {
    async function retrieveData() {
      await reconcile.getAll().then((x) => {
        console.log(x);
        setReconcileList(x)
      }).
      catch((err)=> {
        console.log(err);
      });
    }

    retrieveData();
  }, []);

  
  return (
    <div>
      <h1>Reconcile List &nbsp;</h1>
      <label>From Date: <input name="from_date" type="date" onChange={(e)=>handleDate([e.target.name], e.target.value)} /></label>
      <label>To Date: <input name="to_date" type="date" onChange={(e)=>handleDate([e.target.name], e.target.value)}  /></label>
      <button style={{ marginLeft: "80px", border: "none", color: "black", backgroundColor: "orange", borderRadius: "5px", padding: "5px", textStyle: "none" }} onClick={()=>getByDate()} >Load Reconcile</button>
      <Table className="table table-striped" style={{marginTop:'20px'}}>
        <Thead>
          <Tr>
            <Th style={{ width: "15%" }}>Reconcile Date</Th>
            <Th style={{ width: "15%" }}>Loan No.</Th>
            <Th style={{ width: "10%" }}>Loan Type</Th>
            <Th style={{ width: "10%" }}>Payment Amount</Th>
            <Th style={{ width: "10%" }}>Payment Mode</Th>
            <Th style={{ width: "15%" }}>Payment Date</Th>
            <Th style={{ width: "10%" }}>Reconcile Maker</Th>
            <Th style={{ width: "15%" }}></Th>
            {/* <Th style={{ width: "15%" }}></Th> */}
          </Tr>
        </Thead>
        {!reconcileList && (<span className="spinner-border spinner-border-sm"></span>)}
        <Tbody>
          {reconcileList &&
            reconcileList.map((ri) => (
              <Tr key={ri._id}>
                {/* {dateCompare(payment.date)} */}
                <Td>{datehelper.displayDate(new Date(ri.createdAt))}</Td>
                <Td>{ri.loan_account}</Td>
                <Td>{ri.loan_type}</Td>
                <Td>{ri.reconcile_amt}</Td>
                <Td>{ri.reconcile_mode}</Td>
                <Td>{ri.reconcile_date}</Td>
                <Td>{ri.employees[0].first_name}</Td>
                <Td>{ri.verification_details&&ri.verification_details.verified_by?'Verified':user.role==='AC'&&(<ReconcilationVerificationModal loan={ri} />)}</Td>
                
              </Tr>
            ))}
          {!reconcileList && (
            <Tr>
              <Td colSpan="8" className="text-center">
                <span className="spinner-border spinner-border-lg align-center"></span>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </div>
  );
}

export { ReconcilationList };
