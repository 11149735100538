import { fetchWrapper } from "../_helpers";
const apiUrl = process.env.REACT_APP_API_URL;
const baseUrl = `${apiUrl}/goldpacket`;

export const goldPacketServices = {
    goldPackets,
    getAllPackets,
    searchPacket,
    checkOutPacket,
    acceptCheckInPacket,
    saveCheckOutData,
    countExistsPacket,
    getAllCheckOutPacket,
    delete:_delete,
    cancelCheckoutRequest
}

function goldPackets(params) {
    return fetchWrapper.post(`${baseUrl}/goldpackets`, params)
}
function getAllPackets() {
    return fetchWrapper.get(`${baseUrl}/getallpackets`)
}
function searchPacket(field) {
    return fetchWrapper.post(`${baseUrl}/searchpacket`, field)
}
function acceptCheckInPacket(params) {
    return fetchWrapper.put(`${baseUrl}/acceptcheckinpacket`, params)
}
function checkOutPacket(params) {
    return fetchWrapper.put(`${baseUrl}/checkoutpacket`, params)
}
function saveCheckOutData(params){
    return fetchWrapper.post(`${baseUrl}/savecheckoutdata`,params)
}
function countExistsPacket(){
    return fetchWrapper.get(`${baseUrl}/countexistpacket`)
}
function getAllCheckOutPacket(){
    return fetchWrapper.get(`${baseUrl}/getallcheckoutpacket`)
}
function _delete(id){
    return fetchWrapper.delete(`${baseUrl}/deletecheckinrequest/${id}`)
}
function cancelCheckoutRequest(id){
    return fetchWrapper.put(`${baseUrl}/cancelcheckoutrequest/${id}`)
}