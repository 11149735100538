export const Colenders= {
    Ugro: "Ugro",
    CapitalIndia: "CapitalIndia",
    BhalaFinance:"BhalaFinance",
    KamalAutoFinance:"KamalAutoFinance",
    AshvFinance:"AshvFinance",
    CIFL:"CIFL",
    CIHL:"CIHL",
    VistaarFinance:"VistaarFinance"
  };
  