import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Search from '@material-ui/icons/Search';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import GoldAuctionServices from '../../../_services/goldauction.service';
import { employeeService } from '../../../_services';
import { misService } from '../../../_services';
import { history } from '../../../_helpers';
import { NoticeUpdateModal } from './NoticeUpdateModal';
import datehelper from '../../../_helpers/datehelper';
import { Button } from '@material-ui/core';

export const Notice1 = (props) => {
    const user = employeeService.userValue;
    const [loanNo, setLoanNo] = useState('')
    const [loan, setLoan] = useState({})
    const [auctionDetail, setAuctionDetail] = useState({})
    const [show, setShow] = useState(false)
    const [value, setValue] = useState([])
    const handleChange = (value) => {
        setLoanNo(value);
    }
    const handleSearch = async () => {
        setLoan({})
        setAuctionDetail({})
        setValue({})
        await GoldAuctionServices.FindByLoan({ loan_no: loanNo })
            .then((res) => {
                setAuctionDetail(res);
                setValue(res)
                setShow(true)
                misService.getLoanByAccount(loanNo)
                    .then((res) => {
                        if (res.length > 0) {
                            setLoan(res[0])
                        } else {
                            window.alert('loan number does not exist')
                        }
                    })
                    .then((err) => console.log(err))
            })
            .then((err) => console.log(err))
    }

    useEffect(() => {
        async function getNoticeDetails() {
            await GoldAuctionServices.getNoticeDetails({ element: 'notice1' })
                .then((res) => setValue(res))
                .catch((err) => console.log(err))
        }
        getNoticeDetails();
    }, [])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '50px' }}>
            <h3>Notice 1</h3>
            <form style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }} >
                <input type="text" placeholder='Loan Number' style={{ borderRadius: '5px' }} value={loanNo} onChange={(e) => handleChange(e.target.value.toUpperCase())} />
                <button type='button' style={{ borderRadius: '5px', color: 'white', backgroundColor: '#0066cc', border: 'none', width: "70px", marginLeft: '10px' }} onClick={() => handleSearch()} ><Search /></button>
            </form>
            <hr />
            {/* {
                show === true && auctionDetail && auctionDetail.length > 0 ?
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <button
                            onClick={() =>
                                history.push(`/home/goldloanletters/reminderletterpreview`, { ...loan, notice: 1 })
                            }
                            style={{ borderRadius: '5px', color: 'white', backgroundColor: '#0066cc', border: 'none', width: "200px", height: '40px', marginRight: '30px' }}
                        >
                            Preview Reminder Letter
                        </button>
                        <NoticeUpdateModal auction={auctionDetail[0]} notice={1} />
                    </div>
                    : null
            } */}
            {
                show === true && value.length === 0 && loan && loan.loan_account ?
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <button
                            onClick={() =>
                                history.push(`/home/goldloans/reminderletter`, { ...loan, notice: 1 })
                            }
                            style={{ borderRadius: '5px', color: 'white', backgroundColor: '#0066cc', border: 'none', width: "200px", height: '30px' }}
                        >
                            Print Reminder Letter
                        </button>
                    </div> :
                    null
            }
            {value.length>0 &&
                <Table>
                    <Thead>
                        <Tr>
                            <Th>Loan No</Th>
                            <Th>Dispatch Date</Th>
                            <Th>Employee</Th>
                            <Th>Preview</Th>
                            <Th>Update</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            value.length > 0 ?
                                value.map((x, idx) => <Tr key={idx}>
                                    <Td>{x.loan_no}</Td>
                                    <Td>{datehelper.displayDate(new Date(x.notice1.dispatch_date))}</Td>
                                    <Td>{x.notice1.emp_name}</Td>
                                    <Td>
                                        <Button
                                            onClick={() =>
                                                history.push(`/home/goldloanletters/reminderletterpreview`, {...x.loan[0], notice: 1,dispatch_date:x.notice1.dispatch_date })
                                            }
                                            style={{color:"blue"}}
                                        >
                                            Preview
                                        </Button>
                                    </Td>
                                    <Td><NoticeUpdateModal auction={x} notice={1} /></Td>
                                </Tr>) :
                                <Tr>
                                    <Td></Td>
                                    <Td></Td>
                                    <Td>Data not available</Td>
                                    <Td></Td>
                                    <Td></Td>
                                </Tr>
                        }
                    </Tbody>
                </Table>
            }
            {
                console.log(auctionDetail)
            }
        </div>
    )
}