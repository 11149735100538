import React, { useState, useEffect } from 'react';
import { uploadFileToBucket } from "../_helpers/upload";
import { v4 as uuidv4 } from 'uuid';
import {bulkUploadService} from '../_services/bulkUpload.services'
import BulkUploadCollection from "../assets/insurance_calculator.csv"
import success from "../assets/success.png"
import { Button,} from "@material-ui/core";
import {loanService } from '../_services';

const InsuranceBulkUpload = () => {
const [selectedFile, setSelectedFile] = useState(null);
const [showField,setShowField]=useState(false);
const [showError,setShowError] = useState(false);
const [showMessage,setShowMessage] = useState();
const [isSuccess,setIsSuccess]=useState(false)
const [documents, setDocuments] = useState([]);
const random_uid= uuidv4();
const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
const bucket = process.env.REACT_APP_s3sblBucket;

const handleFileUpload = async( ) => {
  

  let val = selectedFile.name.split('.').pop().toLowerCase();
console.log("random_uid",random_uid)
var link;
if(val==='csv'){
    link = await uploadFileToBucket(bucket, 'application/csv', `${bucket}/insurancebulkupload/${random_uid}.csv`, selectedFile);
  
}else{
    setShowError(true)
    setTimeout(() => {
        setShowError(false)
      }, 3000);
    
}
console.log("var link ",link)
if(link){
  console.log("link",link)

  bulkUploadService.insuranceUploadingDataSheet({link })
    .then((res)=>{
      // window.location.reload();
      if(res && res.status=="success"){
        setIsSuccess(true);
        setShowMessage(res.message);
        setSelectedFile("")
      }
      else{
        setShowMessage(res.message);
      } 
      console.log("reponse",res)
    })
    .catch((err)=>console.log("error",err))

     }
  }
  const listedInsuranceData = () => {
    loanService.getlistedInsuranceData()
        .then(data => {
            console.log('insurance Data:', data);
            setDocuments(data);
            if (data && data.status === "success") {
              
                // Use data.data to set documents
            } else if (data && data.status === "error") {
                alert(data.message);
            }
        })
        .catch(error => {
            console.error('Error getting document checklist:', error);
            alert(error.message);
        });
};

useEffect(() => {
  listedInsuranceData();
}, []);

console.log('filteredDocuments::', documents);
  return (
    <div>
      <Button onClick={()=>setShowField(!showField)} color='primary' variant='contained' style={{marginBottom:'1rem'}}>Upload CSV File</Button>
    <div >
    {showError && <h3 style={{color:"red"}}>please select CSV File Only</h3>}
    
    { showField && <div className='offset col-md-6 m-auto' style={{display:'flex',flexDirection:'column',justifyContent:'center',gap:'1rem'}}>
    <div style={{display:'flex',width:'100%',height:'10rem',border:'2px solid #410202',alignItems:'center',justifyContent:'center',borderRadius:'0.5rem',borderStyle:'dashed'}}>

          <input type="file" accept=".csv, .xlsx"  className="form-control" style={{width:'80%',padding:'4px'}} onChange={handleFileChange} />
    </div>
          <Button color='primary' variant='contained' onClick={handleFileUpload} disabled={!selectedFile}>
            Upload
          </Button>
          <div >
          <a href={BulkUploadCollection} download style={{ display: "flex", justifyContent: "right", textDecoration: "none", textStyling: "none", color: "red" }} >Download Sample File </a>
          </div>
          </div>
    }
      
    </div>
    <div style={{}}>
      
    {isSuccess && <h3 style={{display:'flex',width:'50%',height:'7rem',border:'2px solid rgb(165, 237, 165)',alignItems:'center',justifyContent:'center',borderRadius:'0.5rem',color:'green',marginLeft:'25%'}}>
    <img style={{display:'flex',width:'50px',height:'5rem'}} src={success}/>
    {showMessage}</h3>}
    </div>
    {/* Table to display documents */}
    <table>
      <thead>
        <tr>
          <th>Age EMI</th>
          <th>Insurance Partner</th>
          <th>Insurance Type</th>
          <th>Insurance Priority</th>
          <th>1 Year Amount</th>
          <th>2 Year Amount</th>
          <th>3 Year Amount</th>
          <th>4 Year Amount</th>
          <th>5 Year Amount</th>
          <th>6 Year Amount</th>
          <th>7 Year Amount</th>
          <th>Effective Date</th>
        </tr>
      </thead>
      <tbody>
        {documents &&
        documents.map((doc, index) => (
          <tr key={doc._id || index}>
            <td>{doc.age_emi}</td>
            <td>{doc.insurance_partner}</td>
            <td>{doc.insurance_type}</td>
            <td>{doc.insurance_priority}</td>
            <td>{doc['1_year_amount']}</td>
            <td>{doc['2_year_amount']}</td>
            <td>{doc['3_year_amount']}</td>
            <td>{doc['4_year_amount']}</td>
            <td>{doc['5_year_amount']}</td>
            <td>{doc['6_year_amount']}</td>
            <td>{doc['7_year_amount']}</td>
            <td>{doc.effective_date}</td>
          </tr>
        ))}
      </tbody>
    </table>

    </div>
    
  );
  
};

export  default InsuranceBulkUpload;




 
