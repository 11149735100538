import React, {useEffect} from 'react'
import Modal from "@material-ui/core/Modal";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Button } from "@material-ui/core";
import AddIciciEnachModal from './AddIciciEnachModal';
import { history } from '../../../_helpers';

const CustomerDetailModal = (props) => {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true)
    addcustomer()
  };
  const handleClose = () => {
    setOpen(false)
    window.location.reload()
  };
  const [details, setDetails] = React.useState([])
  const [role, setRole] = React.useState(null)
  const [aadhar, setAadhar] = React.useState(null)
  const [pan, setPan] = React.useState(null)
  const [mobile, setMobile] = React.useState(null)

  const {customer} = props;

  const [value, setValue] = React.useState({
    name: ""
  })

  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value })
  }

  const addcustomer = () =>{
    let tempArr = [];
    if (customer) {
      tempArr.push(customer.applicant.general_information.name)
      if (customer.co_applicants && customer.co_applicants.length) {  
        customer.co_applicants.forEach(co_app => {
          tempArr.push(co_app.general_information.name)
        })

      }

      if (customer.guarantors && customer.guarantors.length) {
        customer.guarantors.forEach(guar => {
          tempArr.push(guar.general_information.name)
        })

      }
    }
    setDetails(tempArr)
  }

  const validate = () => {
    if( value.name.toLowerCase() === customer.applicant.general_information.name.toLowerCase()){
       setAadhar(customer.applicant.general_information.aadhar)
       setMobile(customer.applicant.general_information.contact)
       setPan(customer.applicant.general_information.pan_no)
       setRole("Applicant")
    }

    if(customer && customer.co_applicants && customer.co_applicants.length){
      customer.co_applicants.map((e)=>{
        if(value.name.toLowerCase() === e.general_information.name.toLowerCase()){
          setAadhar(e.general_information.aadhar)
          setMobile(e.general_information.contact)
          setPan(e.general_information.pan_no)
          setRole("Co-Applicant")
        }
      })
    }

    if(customer && customer.guarantors && customer.guarantors.length){
      customer.guarantors.map((e)=>{
        if(value.name.toLowerCase() === e.general_information.name.toLowerCase()){
          setAadhar(e.general_information.aadhar)
          setMobile(e.general_information.contact)
          setPan(e.general_information.pan_no)
          setRole("Guarantor")
        }
      })
    }
    
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
    paddingBottom: '30px',
  };

  const temp =  [value.name, customer.application_id, mobile, customer.loan.tenure]
  console.log(temp)
  
  return (
    <>
            <Button onClick={handleOpen} variant="contained" color="primary">
                Add Enach By ICICI</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
         <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div className='my-3' style={{ display: "flex", justifyContent: "center" }}>
              <label>Name  <select className="form-select form-select-sm" required  name='name' style={{ width: "180px" }} aria-label=".form-select-sm example" onChange={handleChange} >
                <option selected>Select the Name</option>
                {details.map(e => <option value={e}>{e}</option>)}
              </select> <button type='button' className='btn btn-primary' style={{width:"100px"}} onClick={()=>validate()}>Load</button> </label>
            </div> 
            <div className='my-3' style={{ display: "flex", justifyContent: "center" }}>
              <label> Choose Role  <select className="form-select form-select-sm" style={{ width: "200px" }} disabled required name='role' value={role} aria-label=".form-select-sm example" onChange={handleChange} >
                <option selected></option>
                <option >Applicant</option>
                <option >Co-Applicant</option>
                <option >Guarantor</option>
              </select> </label>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <label>AADHAR  <input name='aadhar'  required type='number' disabled value={aadhar} onChange={handleChange} /></label>
            </div><br />
            <div style={{ display: "flex", justifyContent: "center"  }}>
              <label>PAN  <input name='pan' type='text' disabled maxlength="10" value={pan}  onChange={handleChange} /></label>
            </div><br />
            <div style={{ display: "flex", justifyContent: "center" }}>
             <button onClick={()=>{history.push('/home/icicienach',temp)}} >Add Enach</button>
            </div>
          </Typography>

         </Box>
      </Modal>

    </>
  )
}

export default CustomerDetailModal