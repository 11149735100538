import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Paper } from "@material-ui/core"
import { Button } from "@material-ui/core"
import { AddOutlined } from "@material-ui/icons"
import { AddLedgerModal } from "./AddLedgerModal"
import roleChecker from "../../../_helpers/rolechecker"
import { employeeService } from "../../../_services"
import { ledgerService } from "../../../_services/ledger.service"
import { useHistory } from "react-router-dom";

export const CashLedgerList = () => {
    const user = employeeService.userValue
    const [ledgers, setLedgers] = useState([])
    const history =useHistory()
    function getCashLedgerList(product) {
        ledgerService.getCashLedgersByProduct({product:product})
            .then(res => setLedgers(res))
            .catch(err => console.log(err))
    }

    useEffect(() => {
        getCashLedgerList(employeeService.productValue)
    }, [])
    return (
        <div style={{maxWidth:'900px', margin:'auto', marginTop:'30px'}}>
            <Paper style={{margin: '10px', padding: '40px' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                <h2 style={{ marginRight: '20px' }}>Ledger Details</h2>
                </div>
                <hr style={{width:'100%'}} />
            {/* </Paper>
            <Paper style={{margin:"10px", padding:"20px"}}> */}
                <Table>
                    <Thead>
                        <Tr>
                            <Th>Sr. No.</Th>
                            <Th>Branch Id</Th>
                            <Th>Ledger Name</Th>
                            <Th>Ledger Type</Th>
                            <Th>Product</Th>
                            <Th>Opening Balance</Th>
                            <Th>Current Balance</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            ledgers&&ledgers.length>0 ?
                            ledgers.map((ledger, idx)=>{
                                return(
                                    <Tr key={ledger._id}>
                                        <Td>{idx +1}</Td>
                                        <Td>{ledger.branch_id}</Td>
                                        <Td>{ledger.ledger_name}</Td>
                                        <Td>{ledger.ledger_type}</Td>
                                        <Td>{ledger.product_type}</Td>
                                        <Td>{ledger&&ledger.opening_balance&&<>{ledger.opening_balance.toLocaleString("en-IN", {
                                                        // maximumFractionDigits: 2,
                                                        style: "currency",
                                                        currency: "INR",
                                                    })}</>}</Td>
                                        <Td>{ledger&&ledger.current_balance&&<>{ledger.current_balance.toLocaleString("en-IN", {
                                                        // maximumFractionDigits: 2,
                                                        style: "currency",
                                                        currency: "INR",
                                                    })}</>}</Td>
                                        <Td><Button color="primary" onClick={()=>history.push('/home/cashmanagement/transactionhistory', {ledgerDetails:ledger})}>View Details</Button></Td>
                                    </Tr>
                                )
                            }) :
                            <Tr>
                                <Td align='center' colSpan='7'>Data not available</Td>
                            </Tr>
                        }
                    </Tbody>
                </Table>
            </Paper>
        </div>
    )
}