const AWS = require("aws-sdk");
var Buffer = require("buffer/").Buffer;

export async function uploadFile(name, file) {
  // Read content from the file
  // const base64 = await fs.readFile(file.uri, 'base64');
  const buf = Buffer.from(
    file.replace(/^data:image\/\w+;base64,/, ""),
    "base64"
  );

  // Setting up S3 upload parameters
  const params = {
    Bucket: process.env.REACT_APP_s3goldBucket,
    Key: name, // File name you want to save as in S3
    Body: buf,
    ContentEncoding: "base64",
    ContentType: "image/jpeg",
  };

  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_s3accessKeyId,
    secretAccessKey: process.env.REACT_APP_s3secretAccessKey,
    region: "ap-south-1",
  });

  // Uploading files to the bucket
  const uploadedFile = await s3.upload(params).promise();
  return uploadedFile.Location;
}

export async function uploadCumtomerImage(bucket_name, file_type, name, file) {
  // Read content from the file
  // const base64 = await fs.readFile(file.uri, 'base64');
  const buf = Buffer.from(
    file.replace(/^data:image\/\w+;base64,/, ""),
    "base64"
  );

  // Setting up S3 upload parameters
  const params = {
    Bucket: bucket_name,
    Key: name, // File name you want to save as in S3
    Body: buf,
    ContentType: file_type,
  };

  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_s3accessKeyId,
    secretAccessKey: process.env.REACT_APP_s3secretAccessKey,
    region: "ap-south-1",
  });

  // Uploading files to the bucket
  const uploadedFile = await s3.upload(params).promise();
  return uploadedFile.Location;
}

export async function uploadFileToBucket(bucket_name, file_type, name, file) {
  // Read content from the file
  // const base64 = await fs.readFile(file.uri, 'base64');

  // Setting up S3 upload parameters
  const params = {
    Bucket: bucket_name,
    Key: name, // File name you want to save as in S3
    Body: file,
    ContentType: file_type,
  };

  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_s3accessKeyId,
    secretAccessKey: process.env.REACT_APP_s3secretAccessKey,
    region: "ap-south-1",
  });

  // Uploading files to the bucket
  const uploadedFile = await s3.upload(params).promise();
  return uploadedFile.Location;
}
