import React from 'react'
import './ViewLoanDetails.css';
import { Formik, Field, Form } from "formik";
import datehelper from '../../../_helpers/datehelper';
import { employeeService } from '../../../_services';

export default function LoanDetailsTab(props) {
  const user = employeeService.userValue;
  const loan = props.loan
  return (
    <div>
      <h3><b>&nbsp;&nbsp; Loan Details</b></h3>
      <br />
      <div className='personal-details'>
        <Formik>
          <Form className='form'>
            <div className="form-row">
              <div className="form-group-col">
                <label >Branch</label>
                <Field
                  value={loan.details && loan.details.branch_name}
                  className='input-field'
                  disabled
                />
              </div>
              <div className="form-group-col">
                <label >Branch Code </label>
                <Field
                  value={loan.details && loan.details.branch_code}
                  className='input-field'
                  disabled
                />
              </div>
              <div className="form-group-col">
                <label >Loan Account Number</label>
                <Field
                  value={loan.loan_account}
                  className='input-field'
                  disabled
                />
              </div>
              <div className="form-group-col">
                <label >Loan Tenure</label>
                <Field
                  value={loan.period}
                  className='input-field'
                  disabled
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group-col">
                <label >Loan Creation Date</label>
                <Field
                  value={datehelper.displayDate(new Date(loan.date))}
                  className='input-field'
                  disabled
                />
              </div>
              <div className="form-group-col">
                <label >Loan Amount</label>
                <Field
                  value={loan.loan_amount}
                  className='input-field'
                  disabled
                />
              </div>
              <div className="form-group-col">
                <label >RPG</label>
                <Field
                  value={loan.rpg}
                  className='input-field'
                  disabled
                />
              </div>
              <div className="form-group-col">
                <label >ROI(%)</label>
                <Field
                  value={loan.roi}
                  className='input-field'
                  disabled
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group-col">
                <label >EMI</label>
                <Field
                  value={loan.emi}
                  className='input-field'
                  disabled
                />
              </div>
              <div className="form-group-col">
                <label >PF Charges</label>
                <Field
                  value={loan.pf}
                  className='input-field'
                  disabled
                />
              </div>
              <div className="form-group-col">
                <label >Purpose Of Loan</label>
                <Field
                  value={loan.purpose}
                  className='input-field'
                  disabled
                />
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  )
}
