import React, { useState, useEffect } from "react";
import { virtualAccountService } from "../../../_services/virtualaccount.service";
import { Button, Paper } from "@material-ui/core";
import { TextField } from "@material-ui/core"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { Edit, Delete, Add, Print } from "@material-ui/icons";

function VAAccountList(props) {
  const { path } = props.match;
  const [virtualaccounts, setVirtualAccounts] = useState(null);
  const [fields, setFields] = useState({});

  useEffect(() => {
    async function getVirtualAccounts() {
      await virtualAccountService.getAll().then((x) => {
        console.log(x);
        setVirtualAccounts(x);
      });
    }

    getVirtualAccounts();
  }, []);

  const handleChange = (e) =>{
    setFields({...fields, [e.target.name]: e.target.value})
  }

  console.log(fields)

  const handleClick = () =>{
    virtualAccountService.getBySearch(fields)
    .then((data)=>{
      console.log(data)
      setVirtualAccounts(data)
    })
    .catch((err)=>{
      console.log("Error", err)
    })
  }

  return (
    <>
      <Paper
        className="col-md-12"
        style={{
          padding: 20,
        }}
      >
        <h4>Search Accounts</h4><br /><br />

        <div style={{display:"flex", justifyContent:"space-between"}}>
          <TextField
            name="loan_account"
            id="outlined-basic"
            label="Loan Account"
            variant="outlined"
          onChange={handleChange}
          />
          <TextField
            name="virtual_account"
            id="outlined-basic"
            label="Virtual Account"
            variant="outlined"
          onChange={handleChange}
          />
          <Button variant="contained" color="primary" onClick={handleClick}>Search</Button>
        </div>
      </Paper><br /><br />
      <h1>
        Virtual Accounts &nbsp;
        <Button variant="outlined" aria-label="increase" href={`${path}/add`}>
          <Add fontSize="small" />
        </Button>
      </h1><br />
      <Table className="table table-striped">
        <Thead>
          <Tr>
            <Th style={{ width: "25%" }}>Virtual Account Number</Th>
            <Th style={{ width: "20%" }}>Customer Name</Th>
            <Th style={{ width: "20%" }}>Customer Mobile</Th>
            <Th style={{ width: "15%" }}>Loan Account</Th>
            <Th style={{ width: "10%" }}>Status</Th>
            <Th style={{ width: "10%" }}>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {virtualaccounts &&
            virtualaccounts.map((va) => (
              <Tr key={va.va_num}>
                <Td>
                  {va.va_num}
                </Td>
                <Td>{va.customer_name}</Td>
                <Td>{va.customer_mobile}</Td>
                <Td>{va.loan_account}</Td>
                <Td>{va.status}</Td>
                <Td> <Button variant="outlined" aria-label="increase"
                  onClick={() =>
                    props.history.push(`${path}/print`, va)
                  }>
                  <Print fontSize="small" />
                </Button></Td>
              </Tr>
            ))}
          {!virtualaccounts && (
            <Tr>
              <Td colSpan="4" className="text-center">
                <span className="spinner-border spinner-border-lg align-center"></span>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </>
  );
}

export { VAAccountList };
