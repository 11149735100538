import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { adminservice } from '../../../_services/admin.services';

const CategoryDetails = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await adminservice.getAllCategory();
        console.log('response is ', response);
        setCategories(response);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch category details');
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  if (categories.length === 0) {
    return null; // Return nothing if no categories are present
  }

  return (
    <Box p={3}>
      {categories.map((category) => (
        <Paper key={category._id.$oid} elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
          <Typography variant="h4" gutterBottom>
            Category: {category?.categoryName || 'N/A'}
          </Typography>

          {/* Travel Options Table with Sub-options */}
          <TableContainer component={Paper} style={{ padding: '20px',marginBottom: '20px',display:'flex' }}>
            <Typography variant="h5" gutterBottom>
              Travel Options
            </Typography>
            <Table aria-label="travel options table">
              <TableHead>
                <TableRow>
                  <TableCell>Option</TableCell>
                  {category?.travelOptions?.map((option) => (
                    <TableCell key={option._id.$oid}>
                      {option?.travelOptionName || 'N/A'}
                      
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Sub-options</TableCell>
                  {category?.travelOptions?.map((option) => (
                    <TableCell key={option._id.$oid}>
                    {option?.subOptions?.length > 0 && (
                        <Typography variant="body2" color="textSecondary">
                           {option?.subOptions.join(', ')}
                        </Typography>
                      )}

                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          {/* Meal Eligibility Table */}
          <TableContainer component={Paper} style={{ padding: '20px',marginBottom: '20px',display:'flex' }}>
            <Typography variant="h5" gutterBottom>
              Meal Eligibility
            </Typography>
            <Table aria-label="meal eligibility table">
              <TableHead>
                <TableRow>
                  <TableCell>Meal Type</TableCell>
                  {category?.mealEligibility?.map((meal) => (
                    <TableCell key={meal._id.$oid}>{meal?.mealType || 'N/A'}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Amount (₹)</TableCell>
                  {category?.mealEligibility?.map((meal) => (
                    <TableCell key={meal._id.$oid}>{meal?.amount || 'N/A'}</TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          {/* Stay Allowance Table */}
          <TableContainer component={Paper} style={{padding: '20px', marginBottom: '20px',display:'flex' }}>
            <Typography variant="h5" gutterBottom>
              Stay Allowance
            </Typography>
            <Table aria-label="stay allowance table">
              <TableHead>
                <TableRow>
                  <TableCell>City Type</TableCell>
                  {category?.stayAllowance?.map((stay) => (
                    <TableCell key={stay._id.$oid}>{stay?.cityType || 'N/A'}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Amount (₹)</TableCell>
                  {category?.stayAllowance?.map((stay) => (
                    <TableCell key={stay._id.$oid}>{stay?.amount || 'N/A'}</TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ))}
    </Box>
  );
};

export default CategoryDetails;
