import React from "react";
import roleChecker from "../../_helpers/rolechecker";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse
} from "@material-ui/core";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import { HourglassFullOutlined } from "@material-ui/icons";

export default function ApprovalMenu(props) {
  const [open, setOpen] = React.useState(false);
  const { path } = props.match;
  const user = props.user;


  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  return (
    <React.Fragment>
      <ListItem button onClick={handleClick}>
        <ListItemIcon><HourglassFullOutlined /></ListItemIcon>
        <ListItemText primary="Approvals" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" style={{ paddingLeft: "50px" }}>
          <ListItem
            hidden={
              !(roleChecker.isHOApproverAccess(user.role) || roleChecker.isGMLevelAccess(user.role) || roleChecker.isAccountChecker(user.role) || roleChecker.isHOGoldChecker(user.role)) 
            }
            button
            key="one"
            component="a"
            href={`/home/approvals/list`}
          >
            <ListItemText primary="Approval List" />
          </ListItem>
          <ListItem
            button
            key="one"
            component="a"
            href={`/home/approvals/myrequests`}
          >
            <ListItemText primary="My Requests" />
          </ListItem>
        </List>
      </Collapse>
    </React.Fragment>
  )
}
