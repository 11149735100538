import React from "react";
import * as Yup from "yup";
import { Formik,Form, Field } from "formik";
import FormElement from "../../../uielements/FormElement";
import { loanService } from "../../../../_services";
import {
  withStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import AddApplicantModal from "./AddApplicantModal";
import EditApplicantModal from "./EditApplicantModal";
import { Delete } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import maskhelper from "../../../../_helpers/maskhelper";
import { LoanStages } from "../../../../constants/loanstages";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(key,label,value,editable,type,options=[]) {
  return { key,label, value,editable,type,options};
}

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function CoApplicantData({ customer }) {
  const classes = useStyles();
  async function deleteCoApplicant(rows){
     if(window.confirm("Do you want to delete co-applicant"))
     {
      let customer_id=""
      rows.map((r)=> {
        if(r.key==="tapplicant.customer_id")
        {
            customer_id=r.value;
        }
      })
      if(customer_id==="")
      {
        return;
      }
      let params={}
      params['co_applicants']=[];
      customer.co_applicants.map((a)=> {
        if(a.customer_id !==customer_id)
        {
          params['co_applicants'].push(a);
           
        }
      })
      loanService.update(customer._id,params).then((r)=> {
          window.alert("coapplicant deleted");
          window.location.reload();
      })
      .catch((err)=> {
        window.alert("Deletion failed");
        console.log(err);
      }) 

     }
      
  }

  const mainrows=[];
  if(customer.co_applicants)
  {
    customer.co_applicants.map((applicant)=> {
      const rows=[];
      rows.push(createData("tapplicant.customer_id","CIF Id", applicant.customer_id,false,"text"));  
      rows.push(createData("tapplicant.general_information.title","Title", applicant.general_information.title,true,"select",["Mr","Mrs","Ms"]));  
      rows.push(createData("tapplicant.general_information.name","Co-Applicant Name", applicant.general_information.name,true,"text"));  
      rows.push(createData("tapplicant.general_information.sonof","Father Name", applicant.general_information.sonof,true,"text"));
      rows.push(createData("tapplicant.general_information.wifeof","Husband Name", applicant.general_information.wifeof,true,"text"));
      rows.push(createData("tapplicant.general_information.kyc_relation_type","KYC relation Type", applicant.general_information.kyc_relation_type,true,"select",["S/o","W/o","D/o","C/o"]));
      rows.push(createData("tapplicant.general_information.kyc_relation","KYC Relation", applicant.general_information.kyc_relation,true,"text"));
      
      rows.push(createData("tapplicant.general_information.age","Age", applicant.general_information.age,true,"number"));
      rows.push(createData("tapplicant.general_information.contact","Contact Number", applicant.general_information.contact,true,"number"));
      rows.push(createData("tapplicant.general_information.aadhar","Aadhar number", applicant.general_information.aadhar,false,"hidden"));
      if(applicant.general_information.masked_aadhar)
     {
         rows.push(createData("tapplicant.general_information.masked_aadhar","Masked Aadhar Number", applicant.general_information.masked_aadhar,false,"text"));
     }
     else
     {
       rows.push(createData("tapplicant.general_information.masked_aadhar","Masked Aadhar Number", maskhelper.maskAadhar(applicant.general_information.aadhar),false,"text"));   
     }
      rows.push(createData("tapplicant.general_information.pan_no","PAN number", applicant.general_information.pan_no,true,"text"));
      
      rows.push(createData("tapplicant.general_information.address","Address", applicant.general_information.address,true,"text"));
      rows.push(createData("tapplicant.general_information.gender","Gender", applicant.general_information.gender,true,"select",["Male","Female"]));
      rows.push(createData("tapplicant.general_information.education","Education", applicant.general_information.education,true,"select",['No_Education','10th','12th','Graduate']));
    
      mainrows.push(rows);
    })
    console.log(mainrows);
    
  }

  return (   <div>
  <div style={{"padding":10}}>
                      <AddApplicantModal
                      applicant_type="co_applicant"
                      customer={customer} />
                    </div>
    {mainrows.map((rows)=> {
                     
    return <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Co-Applicant Details</StyledTableCell>
            <StyledTableCell align="right">
            <div style={{"display":"flex","align":"right"}}
            >
              {(customer.stage===LoanStages.Sales ||customer.stage===LoanStages.Credit) && (
                <Button
                onClick={()=>deleteCoApplicant(rows)}
                variant="contained"
                style={{backgroundColor:"red"}}
                >
                <Delete></Delete>
                </Button>
              )}
              {(customer.stage!==LoanStages.Collection && customer.stage!==LoanStages.Closed) && (  
                <EditApplicantModal
                          applicant_type="co_applicant"
                          rows={rows}
                          customer={customer} />
              )}
              </div>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        

          {rows.map((row) => (
            row.type !='hidden' && <StyledTableRow key={row.label}>
              <StyledTableCell component="th" scope="row">
                {row.label}
              </StyledTableCell>
              <StyledTableCell align="right">{row.value}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

})} 
    </div>
  );
}
