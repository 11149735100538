import React from 'react'
import { Form, Formik } from 'formik'
import { Button } from 'antd';
import { useState } from 'react';
import Webcam from 'react-webcam';
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import { Table } from '@material-ui/core';
import { Tbody, Td, Th, Tr } from "react-super-responsive-table";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { loanService, misService } from '../../../_services';
import { uploadFile } from '../../../_helpers/upload';
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import { useLocation } from "react-router-dom";
import { employeeService } from '../../../_services';
import datehelper from '../../../_helpers/datehelper';
import * as Yup from "yup";

const LoanRenewForm = (props) => {
  const location = useLocation()
  const data = location.loan
  const loanCalculations = location.loanCalculations
  console.log("Test locatin data", data)
  const loan_account = data.loan_account;
  const user = employeeService.userValue.emp_id;
  const branch_id=employeeService.userValue.branch_id;
  const interest_due_amount = loanCalculations.total_due;
  // const [payment, setPayment] = useState()
  React.useEffect(() => {
    const getOnlineAndCashPayments = async () => {
      await misService.getOnlineAndCashPayments(loan_account)
        .then((res) => (getAllPayments(res)))
        .catch((err) => (console.log(err)))
    }
    getOnlineAndCashPayments()
  }, []);
  const [sumofOnlinePayment, setSumofOnlinePayment] = useState(0)
  const [sumofCashPayment, setSumofCashPayment] = useState(0)
  const [cash_payments, setCash_payments] = useState([])
  const [online_payments, setOnline_payments] = useState([])
  function getAllPayments(res) {
    let cashPayment = []
    let onlinePayment = []
    let onlineSum = 0
    let cashSum = 0
    if (res) {
      res.cash_payments && res.cash_payments.map((x) => {
        if (x.reconcilelist && x.reconcilelist.length === 0 && x.mode === 'Cash') {
          cashPayment.push(x)
          cashSum = cashSum + parseInt(x.payment)
        }
      })
      setCash_payments(cashPayment)
      setSumofCashPayment(cashSum)
      res.online_payments && res.online_payments.map((y) => {
        if (y.reconcilelist && y.reconcilelist.length === 0) {
          onlinePayment.push(y)
          onlineSum = onlineSum + parseInt(y.txn_amt)
        }
      }
      )
      setSumofOnlinePayment(onlineSum)
      setOnline_payments(onlinePayment)
      console.log("All payment", res)
    }
  }
  // console.log("Test Pyment cash  and online", payment)
  console.log("online_payments", online_payments, sumofOnlinePayment)
  console.log("all cash_payment", cash_payments, sumofCashPayment)
  const net_due_amount = interest_due_amount - (sumofCashPayment + sumofOnlinePayment)
  const total_non_reconcile_payment = sumofCashPayment + sumofOnlinePayment
  const [value, setValue] = useState({
    contact: data.contact,
    photo: "",
    otp_Verified: false,
  })

  const contact = data.contact
  let initialValues = { photo: "" }
  const videoConstraints = {
    width: 390,
    height: 270,
    facingMode: "user",
  };
  const randomNum = Math.floor(100 + Math.random() * 900);
  const photoRef = React.useRef(null);
  const capturePhoto = React.useCallback(
    async () => {
      if (loan_account) {
        const photo = photoRef.current.getScreenshot();
        const link = await uploadFile(`renewgoldloan/${loan_account}_${randomNum}.jpg`, photo);
        setValue({
          ...value,
          photo: link
        })
      }
    }
  );
  const discard = (id) => {
    if (id === "photo") {
      setValue({
        ...value,
        photo: ""
      })
    }
  }

  const saveData = {
    name: data.name,
    loan_account: data.loan_account,
    ornaments: data.ornaments,
    interest_due_amount: interest_due_amount,
    net_due_amount: net_due_amount,
    verification_details: value,
    online_payments: online_payments,
    cash_payments: cash_payments,
    renew_by: user,
    total_non_reconcile_payment: total_non_reconcile_payment,
    renew_status:"Pending",
    branch_id:branch_id,
    renew_request_by:user
  }
  const formSubmitted = () => {
    // props.history.push(`/home/pledge?renew_of=${loan_account}&net_due_amount=${net_due_amount}`)
    alert("Request Sent to concerned Authority !!")
    props.history.push('/home/goldloans/loanrenewrequest')
  }
  // const handleSubmit = async () => {
  //   verified === true && value.photo ? (
  //     await misService.renewLoanDetails(saveData)
  //       .then((res) => formSubmitted())
  //       .catch((err) => console.log(err)))
  //     : (verified === false ? alert("please Verify conatct") : alert("Photo is Required"))
  // }

  const handleSubmit = async () => {
    if (verified === true && value.photo) {
      if (window.confirm("Are you sure want to send Renew Request?")) {
        await misService.renewLoanDetails(saveData)
          .then(() => formSubmitted())
          .catch((err) => console.error(err));
      }
    } else {
      if (verified === false) {
        alert("Please Verify contact");
      } else {
        alert("Photo is Required");
      }
    }
  };

  const [OtpValue, setOtpValue] = React.useState({})
  const handleChangeOtp = (e) => {
    OtpValue[e.target.name] = e.target.value
    setOtpValue(OtpValue)
  }
  const [otpResponse, setOtpResponse] = useState({})
  const [verified, setVerified] = useState(false)
  const [sentOtp, setSentOtp] = useState(false)
  const otp = Math.floor(1000 + Math.random() * 9000);
  const sendOtp = () => {
    loanService.sendOtp({ contact: contact, otp: otp })
      .then((data) => {
        setOtpResponse(data.otp)
        setSentOtp(true)
      })
      .catch((err) => console.log(err))
  }
  const verifyOtp = () => {
    if (OtpValue.otp == otpResponse) {
      setValue({
        ...value,
        otp_Verified: true
      })
      alert("Otp verified")
      setVerified(true)
      setSentOtp(false)
    } else {
      alert("Incorrect Otp")
    }
  }

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <Form style={{ width: "75%", margin: "40px" }}>
          <h3 style={{ textAlign: "center", marginBottom: "30px", marginTop: "50px" }}>Loan Renew Form</h3>
          <hr style={{ background: "black", marginBottom: '40px' }} />
          <div style={{ display: "flex", justifyContent: 'space-between' }}>
            <div style={{ float: "left", marginTop: "60px" }}>
              <h6>Name:{data.name}</h6>
              <br />
              <br />
              <h6>Loan No:{data.loan_account}</h6>
              <br />
              <br />
              <h6>Due Amount:{loanCalculations.total_due - (sumofCashPayment + sumofOnlinePayment)}</h6>
              <br />
              <br />
              <b>Contact Number:</b>
              <input type="Number" name="mobilenumber" value={data.contact} style={{ width: "130px" }} />
              {(verified === true) ?
                <span style={{ color: "blue", height: "33px" }}><VerifiedUserIcon style={{ width: "40px", height: '30px' }} /></span> :
                <Button style={{ backgroundColor: "green", color: "white", width: "80px", height: "30px", borderRadius: "5px" }} onClick={() => (contact.length === 10 ? (sendOtp())
                  : alert("Please Enter valid Number"))}>Send Otp</Button>}
              {
                sentOtp === true ? <div>
                  <input type='number' name='otp' onChange={handleChangeOtp} placeholder="Enter the Otp"
                    style={{ width: "140px", borderRadius: "5px", marginTop: "30px" }} />
                  <Button style={{ marginLeft: "10px", width: "70px", height: "27px", backgroundColor: "blue", color: "white", border: "none", borderRadius: "5px" }} onClick={verifyOtp}>Verify</Button>
                  <Button style={{ marginLeft: "10px", width: "90px", height: "27px", backgroundColor: "red", color: "white", border: "none", borderRadius: "5px" }} onClick={sendOtp}>Resend Otp</Button>
                </div>
                  : null
              }
            </div>
            <div style={{ float: "right", marginBottom: "50px" }}>
              {value.photo ?
                (<div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ fontSize: "25px", textAlign: "center" }}>
                    Photo
                  </span>
                  <img
                    style={{ width: "390px", height: "270px" }}
                    src={value.photo}
                    alt="Id photo"
                    name="customerphoto"
                  />
                  <button
                    style={{ height: "40px", border: "none", width: "390px" }}
                    type="button"
                    onClick={() => discard("photo")}
                  >
                    <ClearOutlinedIcon />
                  </button>
                </div>
                ) : (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ fontSize: "25px", textAlign: "center" }}>
                      Customer photo  with ornaments
                    </span>
                    <Webcam
                      audio={false}
                      height={270}
                      screenshotFormat="image/jpeg"
                      width={390}
                      ref={photoRef}
                      videoConstraints={videoConstraints}
                    ></Webcam>
                    <button
                      style={{ height: "40px", border: "none", width: "390px" }}
                      type="button"
                      onClick={() =>
                        capturePhoto()
                      }
                    >
                      {<CameraAltOutlinedIcon />}
                    </button>
                  </div>)}
            </div>
          </div>
          <br />
          {online_payments.length > 0 || cash_payments.length > 0 ? <h5 style={{ color: 'black', textAlign: "center" }}> Non Reconcile Payments Details</h5> : null}
          {online_payments.length > 0 &&
            <div>
              <hr />
              <h5>Online Payment</h5>
              <Table style={{ width: "95%", marginBottom: '40px' }}>
                <thead>
                  <Tr>
                    <Th style={{ border: "1px solid " }}>S.N</Th>
                    <Th style={{ border: "1px solid " }}>Date</Th>
                    <Th style={{ border: "1px solid " }}>Utr No</Th>
                    <Th style={{ border: "1px solid " }}>Amount</Th>
                  </Tr>
                </thead>
                <Tbody >
                  {
                    online_payments.map((x, i) => <Tr key={i}>
                      <Td style={{ border: "1px solid " }}>{i + 1}</Td>
                      <Td style={{ border: "1px solid " }}>{datehelper.displayDate(new Date(x.updated))}</Td>
                      <Td style={{ border: "1px solid " }}>{x.utr_num}</Td>
                      <Td style={{ border: "1px solid " }}>{x.txn_amt}</Td>
                    </Tr>)
                  }
                </Tbody>
                <Tr style={{ border: "1px solid " }}>
                  <Td colspan="3" style={{ textAlign: "center", fontWeight: "bold" }}>Total</Td>
                  <Td style={{ fontWeight: "bold", border: "1px solid " }}>{sumofOnlinePayment}</Td>
                </Tr>
              </Table>
            </div>
          }
          {
            cash_payments.length > 0 &&
            <div>
              <hr />
              <h5 style={{ color: "black" }}>Cash Payment</h5>
              <Table style={{ width: "95%", marginBottom: '40px' }}>
                <thead>
                  <Tr>
                    <Th style={{ border: "1px solid " }}>S.N</Th>
                    <Th style={{ border: "1px solid " }}>Date</Th>
                    <Th style={{ border: "1px solid " }}>Receipt No</Th>
                    <Th style={{ border: "1px solid " }}>Amount</Th>
                  </Tr>
                </thead>
                <Tbody >
                  {
                    cash_payments.map((x, i) => <Tr key={i}>
                      <Td style={{ border: "1px solid " }}>{i + 1}</Td>
                      <Td style={{ border: "1px solid " }}>{datehelper.displayDate(new Date(x.date))}</Td>
                      <Td style={{ border: "1px solid " }}>{x.receipt_no}</Td>
                      <Td style={{ border: "1px solid " }}>{x.payment}</Td>
                    </Tr>)
                  }
                </Tbody>
                <Tr style={{ border: "1px solid " }}>
                  <Td colspan="3" style={{ textAlign: "center", fontWeight: "bold" }}>Total</Td>
                  <Td style={{ fontWeight: "bold", border: "1px solid " }}>{sumofCashPayment}</Td>
                </Tr>
              </Table>
              <hr />
            </div>}
          <h5 style={{ color: "black" }}>Interest Calculator Due Amount:₹{interest_due_amount}/-</h5>
          <hr />
          <h5 style={{ color: "black" }}>Non Reconcile Payment:₹{total_non_reconcile_payment}/-</h5>
          <hr />
          <h5 style={{ color: "black" }}>Net Due Amount:₹{net_due_amount}/-</h5>
          <hr />
          <button type="submit" style={{ color: "white", background: "blue", marginTop: "50px", borderRadius: '4px', marginLeft: "380px", marginBottom: '80px', width: "150px", height: '50px', border: "none" }}>
                Renew Loan</button>
          {/* {
            net_due_amount <= 5 ?
              <button type="submit" style={{ color: "white", background: "blue", marginTop: "50px", borderRadius: '4px', marginLeft: "380px", marginBottom: '80px', width: "150px", height: '50px', border: "none" }}>
                Renew Loan</button>
              : <h4 style={{ color: "red", marginTop: '50px', marginBottom: '80px' }}>This loan can't be Renew,First pay due amount:₹{net_due_amount}/-</h4>
          } */}
        </Form>
      </Formik>
    </div>
  )
}

export default LoanRenewForm
