import React from "react";
import ColenderPaymentSheetItem from "./ColenderPaymentSheetItem";

export default function ColenderPaymentSheetList({
  values,
  handleChange,
  handleRemoveSpecificRow,
}) {
  return (
    <tbody>
      {values.map((item, idx) => {
        return (
          <ColenderPaymentSheetItem
            key={idx}
            idx={idx}
            item={item}
            value={values}
            handleChange={handleChange}
            handleRemoveSpecificRow={handleRemoveSpecificRow}
            newAddition={(item.payment_amount==0)}
          />
        );
      })}
    </tbody>
  );
}
