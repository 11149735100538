import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { Add, Business } from '@material-ui/icons'
import React, { useState } from 'react'
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import roleChecker from '../../../_helpers/rolechecker';
export const GoldPacketMenu = (props) => {
    const [open, setOpen] = useState(false)
    const { path } = props.match;
    const user = props.user;
    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    return (
        <React.Fragment>
            <ListItem button onClick={handleClick}>
                <ListItemIcon><Business/> </ListItemIcon> 
                <ListItemText primary="Gold Packet" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" style={{ paddingLeft: "50px" }}>
                <ListItem
                        hidden={!(roleChecker.isGoldRider(user.role) || roleChecker.isHOGoldChecker(user.role) || roleChecker.hasAccountsAccess(user.role))}
                        button
                        key="bran"
                        component="a"
                        href={`/home/goldpacket/checkin`}
                    >
                        <ListItemText primary="Check In" />
                    </ListItem>
                    <ListItem
                        hidden={!(roleChecker.isGoldRider(user.role) || roleChecker.isHOGoldChecker(user.role)|| roleChecker.hasAccountsAccess(user.role))}
                        button
                        key="bran"
                        component="a"
                        href={`/home/goldpacket/checkout`}
                    >
                        <ListItemText primary="Check Out" />
                    </ListItem>
                    <ListItem
                        hidden={!(roleChecker.hasAccountsAccess(user.role))}
                        button
                        key="bran"
                        component="a"
                        href={`/home/goldpacket/packetlist`}
                    >
                        <ListItemText primary="Packet List" />
                    </ListItem>
                    <ListItem
                        hidden={!(roleChecker.isGMLevelAccess(user.role))}
                        button
                        key="bran"
                        component="a"
                        href={`/home/goldpacket/packethistory`}
                    >
                        <ListItemText primary="Packet History" />
                    </ListItem>
                </List>
            </Collapse>
        </React.Fragment>
    )
}
