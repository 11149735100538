import React, { useEffect } from "react";
import { Delete } from "@material-ui/icons";
import { employeeService } from "../../../../_services";
import datehelper from "../../../../_helpers/datehelper";
import roleChecker from "../../../../_helpers/rolechecker";

export default function ColenderPaymentSheetItem({
  item,
  idx,
  handleChange,
  handleRemoveSpecificRow
}) {
  const user = employeeService.userValue;
  const {
    payment_date,
    bank,
    payment_type,
    payment_mode,
    payment_ref,
    payment_amount,
  } = item; 

  
  return (
    <tr id="addr0" key={idx}>
      <td style={{ textAlign: "center" }}>{idx}</td>
      <td>
        <input
        disabled={!(roleChecker.hasAccountsAccess(user.role) || roleChecker.isHOLevelAccess(user.role))  }
          type="date"
          name="payment_date"
          value={datehelper.getPaymentDateString(new Date(payment_date))}
          onChange={handleChange(idx)}
          className="form-control"
        />
      </td>
      <td>
        <input
          disabled
          type="text"
          name="bank"
          value={bank}
          onChange={handleChange(idx)}
          className="form-control"
        />
      </td>
      <td>
        <select
        disabled={!(roleChecker.hasAccountsAccess(user.role) || roleChecker.isHOLevelAccess(user.role))}
          name="payment_type"
          value={payment_type}
          onChange={handleChange(idx)}
          className="form-control"
        >
          <option value="repayment">Repayment</option>
          <option value="disbursement">Disbursement</option>
          {/* <option value="pf">PF</option> */}
        </select>
      </td>
      <td>
        <select
        disabled={!(roleChecker.hasAccountsAccess(user.role) || roleChecker.isHOLevelAccess(user.role))}
          name="payment_mode"
          value={payment_mode}
          onChange={handleChange(idx)}
          className="form-control"
        >
          {/* <option value="cash">Cash</option> */}
          <option value="online">Online</option>
          {/* <option value="cheque">Cheque</option> */}
          {/* <option value="bank_transfer">Bank Transfer</option> */}
        </select>
      </td>

      <td>
      <input
      disabled={!(roleChecker.hasAccountsAccess(user.role) || roleChecker.isHOLevelAccess(user.role))}
          type="text"
          name="payment_ref"
          value={payment_ref}
          onChange={handleChange(idx)}
          className="form-control"
        />
      </td>
      <td>
        <input
        disabled={!(roleChecker.hasAccountsAccess(user.role) || roleChecker.isHOLevelAccess(user.role))}
          type="number"
          min="1"
          name="payment_amount"
          step=".01"
          value={payment_amount}
          onChange={handleChange(idx)}
          className="form-control"
          required
        />
      </td>
      <td>
        <a className="btn btn-sm" onClick={handleRemoveSpecificRow(idx)}>
          {((user.role==="Admin") && (
                <Delete />
          ))
          }
        </a>
      </td>
    </tr>
  );
}
