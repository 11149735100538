import React from "react";
import {
  Button,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse
} from "@material-ui/core";
import { employeeService } from "../_services/employee.service";
import PeopleIcon from "@material-ui/icons/People";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import GavelOutlinedIcon from '@material-ui/icons/GavelOutlined';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import StarsOutlinedIcon from '@material-ui/icons/StarsOutlined';

import {
  AssessmentOutlined,
  Dashboard,
  Money,
  SettingsApplicationsRounded,
  VerifiedUser,
} from "@material-ui/icons";

import Product from "../_helpers/product";

import PaymentMenuList from "./Payments/PaymentMenuList";
import SBLMenuList from "./SBL/SBLMenuList";
import SblLetterMenuList from "./SBL/SblLettersList/SblLetterMenuList"
import SmsMenu from "./CustomerCare/sms/smsnav";
import GoldMenuList from "./GoldLoan/GoldMenuList";
import LetterMenuList from "./Legal/LetterTracking/LetterMenuList";
import ColendingMenuList from "./CoLending/ColendingMenuList";
import AccountingMenuList from "./Accounting/AccountingMenuList";
import GoldLettersMenu from "./GoldLoan/GoldLoanLetters/GoldLettersMenu";
import CourtCaseMenu from "./Legal/CourtCases/CourtCaseMenu";
import CallDetailMenu from "./CustomerCare/VoiceSchedule/CallDetailMenu";
import ApprovalMenu from "./MakerCheckerFramework/ApprovalMenu";
import roleChecker from "../_helpers/rolechecker";
import { GoldPacketMenu } from "./GoldLoan/GoldPacket/GoldPacketMenu";
import CashManagementMenuList from "./GoldCashManagement/CashManagemetMenuList";
import AppBarHeader from "./AppBarHeader";
import Routes from "./Routes";
import SwitchAccountModal from "./Users/SwitchAccountModal";
import DisbursementMenuList from "./SBL/Disbursement/DisbursementMenuList";
import { ExpandMore, ExpandLess, AccountBalanceWallet, MonetizationOn } from "@material-ui/icons";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
}));

function Home(props) {
  // console.log('props is',props)
  const user = employeeService.userValue;
  const { path } = props.match;
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [apiState,setApiState]= React.useState(false);
  // console.log("the api state value is",apiState)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [open, setOpen] = React.useState(false);
  
  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  
  React.useEffect(() => {
    // console.log('employee ',employeeService.productValue)
    async function Logout() {
      await employeeService.logout()
    }
    if(!employeeService.productValue){
      Logout();
       
     }
     setApiState(employeeService.apistate);
    //  console.log("the api state from es is",employeeService.apistate)
    //  console.log("the api state now is",apiState)

  }, []);
  // console.log('the value is',Product)
    // if(!Product){
    //   history.pushState("/intro")
    // }
   
    let dashboardPath;
    if (roleChecker.isCreditTeamAccess(user.role)) {
        dashboardPath = `${path}/creditDashboard`;
    } else {
        dashboardPath = roleChecker.isGMLevelAccess(user.role) ? `${path}/dashboard` : `${path}/realtimedashboard`;
    }
    
  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <a href="/">
          <img
            className="offset-1 col-md-10"
            src={require("../assets/kaabil.png")}
            alt=""
          />
        </a>
      </div>
      <Divider />
      <div
        style={{
          // backgroundImage: `url(${sideBack})`,
          // backgroundSize: "cover",
          height: "100vh",
        }}
      >
        <List>
        {employeeService.productValue==Product.CoLending&& <ListItem button key="dashboard" component="a" href={`${path}/colending/dashboard`}>
            <ListItemIcon>
              <Dashboard></Dashboard>
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem> }
          
          {employeeService.productValue!=Product.CoLending&&<ListItem button key="common-dashboard" component="a" href={`${path}`}>
            <ListItemIcon>
              <Dashboard></Dashboard>
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          }
           
          <ListItem
            hidden={!(roleChecker.isCFOAccess(user.role) || roleChecker.isAdminAccess(user.role) || roleChecker.isITAccess(user.role) || roleChecker.isHRDept(user.role))}
            button
            key="admin"
            component="a"
            href={`${path}/admin`}
          >
            <ListItemIcon>
              <SettingsApplicationsRounded />
            </ListItemIcon>
            <ListItemText primary="Admin Settings"/>
          </ListItem>
          {((employeeService.productValue == Product.GoldLoan && (roleChecker.isBMLevelAccess(user.role) || roleChecker.isHOLevelAccess(user.role))) || ((employeeService.productValue == Product.SBL|| employeeService.productValue == Product.Collection) && roleChecker.isHOLevelAccess(user.role)))&&( 
          <ListItem
            button
            key="customers"
            component="a"
            href={`${path}/customers`}
          >
            <ListItemIcon>
              <PeopleIcon></PeopleIcon>
            </ListItemIcon>
            <ListItemText primary="Customers" />
          </ListItem>)
          }
          {(!["KTR","AUDITOR"].includes(user.role)  ) && <ApprovalMenu user={user} {...props}/>}
          {employeeService.productValue == Product.SBL && (roleChecker.hasAccountsAccess(user.role) || roleChecker.isHOOpsTeamAccess(user.role)) && <DisbursementMenuList user={user}  {...props}/>}
          {(employeeService.productValue == Product.SBL || employeeService.productValue == Product.CoLending) && (!roleChecker.isGoldDept(user.role) &&!roleChecker.isHRDept(user.role)  && !roleChecker.isHOCollectionTeamAccess(user.role) && user.role !=="KTR") && (
            <SBLMenuList user={user} {...props} />
          )}
{[20539,20113].includes(user.emp_id)&&
  <SBLMenuList user={user} {...props} />
}
          {(employeeService.productValue == Product.Collection && roleChecker.isHOCollectionTeamAccess(user.role) || [20464].includes(user.emp_id)) && (
            <SblLetterMenuList user={user} {...props} />
          )}
          {employeeService.productValue == Product.CoLending && roleChecker.isColendingAccess(user.role) && (
            <ColendingMenuList user={user} {...props} />
          )}  
           {employeeService.productValue == Product.Legal && (
            <ListItem
            hidden={
              !roleChecker.isLegalTeamAccess(user.role)
            }
            button
            key="one"
            component="a"
            href={`${path}/legalcases/legalcaseslist`}
          > 
          <ListItemIcon>
          <GavelOutlinedIcon/>
          </ListItemIcon>
            <ListItemText primary="Legal Cases" />
          </ListItem>
            )}
          {employeeService.productValue == Product.Legal && (
            <CourtCaseMenu user={user} {...props} />)}
         
          {(employeeService.productValue == Product.SBL ||
            employeeService.productValue == Product.Collection ||
            employeeService.productValue == Product.GoldLoan ||
            employeeService.productValue == Product.CustomerCare) && (!roleChecker.isHRDept(user.role) && !["AUDITOR"].includes(user.role)) && (
              <PaymentMenuList user={user} {...props} />
            )}

          {employeeService.productValue == Product.Accounting && (
            <AccountingMenuList user={user} {...props} />
          )}

          {(employeeService.productValue == Product.SBL || employeeService.productValue == Product.Accounting || employeeService.productValue == Product.GoldLoan || employeeService.productValue == Product.Collection ) &&(user.role !== "KTR") && !["AUDITOR"].includes(user.role) && (
            <CashManagementMenuList user={user} {...props} />
          )}

          {(employeeService.productValue == Product.GoldLoan) && (
            <GoldMenuList user={user} {...props} />
          )}
          {(employeeService.productValue == Product.GoldLoan) && (
            <GoldLettersMenu user={user} {...props} />
          )}
          {(employeeService.productValue === Product.GoldLoan)&&(
            <GoldPacketMenu user={user} {...props}/>
          )}

          {/* {(employeeService.productValue == Product.GoldLoan ||
            employeeService.productValue == Product.SBL ) && (roleChecker.isHOLevelAccess(user.role) || roleChecker.isHOGoldAccess(user.role)  || roleChecker.isColendingAccess(user.role) || roleChecker.isOperationTeamAccess(user.role) || user.role==="KTR") && (
              <ListItem
                button
                key="verification"
                component="a"
                href={`${path}/verification`}
              >
                <ListItemIcon style={{ marginLeft: 2 }}>
                  <VerifiedUser></VerifiedUser>
                </ListItemIcon>
                <ListItemText primary="Document Verification" />
              </ListItem>
            )} */}
            {/* {employeeService.productValue == Product.Accounting && <ListItem
             button
             key="pettycash"
             component='a'
             href={`${path}/pettycashlist`}
             >
              <ListItemIcon style={{ marginLeft: 2 }}>
              <Money/> 
              </ListItemIcon>
            <ListItemText primary="Petty Cash" />
            </ListItem>} */}
            {employeeService.productValue==Product.CoLending&& <ListItem button key="Search-Loans" component="a" href={`${path}/colending/payments`}>
            <ListItemIcon>
              <Dashboard></Dashboard>
            </ListItemIcon>
            <ListItemText primary="Search Loans" />
          </ListItem> }
          <ListItem
            hidden={
              !roleChecker.hasReportsAccess(user.role)
            }
            button
            key="reports"
            component="a"
            href={`${path}/reports`}
          >
            <ListItemIcon style={{ marginLeft: 2 }}>
              <AssessmentOutlined />
            </ListItemIcon>
            <ListItemText primary="Reports" />
          </ListItem>



          {/* <ListItem
            hidden={
              !roleChecker.hasAccountsAccess(user.role)
            }
            button
            key="allcloudledger"
            component="a"
            href={`${path}/allcloudledger`}
          >
            <ListItemIcon style={{ marginLeft: 2 }}>
              <AssessmentOutlined />
            </ListItemIcon>
            <ListItemText primary="All Cloud Ledger" />
          </ListItem> */}


          {/* <ListItem
          button
          key="realtimedashboard"
          component="a"
          // href={roleChecker.isGMLevelAccess(user.role) ? `${path}/teamdashboard` : `${path}/realtimedashboard`}
          href={dashboardPath}
        >
          <ListItemIcon style={{ marginLeft: 2 }}>
            <StarsOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Today's Performance" />
        </ListItem> */}
        {employeeService.productValue == Product.SBL && !["AUDITOR"].includes(user.role) &&
  <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <AccountBalanceWallet></AccountBalanceWallet>
        </ListItemIcon>
        <ListItemText primary="Performance" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>}
      
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" style={{ paddingLeft: "50px" }}>
        {(roleChecker.isZSMLevelAccess(user.role) || roleChecker.isAdminAccess(user.role))&& <ListItem
            button
            key="prl"
            component="a"
            href={`${path}/assignTarget`}
          >
         <ListItemText primary="Assign Target" />
          </ListItem>}  
        <ListItem
          button
          key="realtimedashboard"
          component="a"
          href={`${path}/dashboard`}
        >
          <ListItemText primary="Today's" />
        </ListItem>
       
         {![ "Sales"].includes(user.role) &&  <ListItem
            button
            key="prl"
            component="a"
            href={`${path}/weeklyTarget`}
          >
            <ListItemText primary="Weekly" />
          </ListItem>}
        
          {![ "Sales"].includes(user.role) && <ListItem
            button
            key="prl"
            component="a"
            href={`${path}/monthlyTarget`}
          >
            <ListItemText primary="Monthly" />
          </ListItem>}

          {roleChecker.isCEOAccess(user.role) && <ListItem
            button
            key="prl"
            component="a"
            href={`${path}/approvalTarget`}
          >
            <ListItemText primary="Target Approval List" />
          </ListItem>}
         
        </List>
      </Collapse>
        {/* <ListItem
          button
          key="cicdata"
          component="a"
          // href={roleChecker.isGMLevelAccess(user.role) ? `${path}/teamdashboard` : `${path}/realtimedashboard`}
          href={`${path}/cicdata`}
        >
          <ListItemIcon style={{ marginLeft: 2 }}>
            <AssessmentOutlined />
          </ListItemIcon>
          <ListItemText primary="CIC Report" />
        </ListItem> */}

           

         {employeeService.productValue == Product.Accounting && roleChecker.hasAccountsAccess(user.role) && <ListItem
            button
            key="rpsbulkuploade"
            component="a"
            href={`${path}/rpsbulkuploade`}
          >
            <ListItemIcon style={{ marginLeft: 2 }}>
              <FormatListBulletedIcon />
            </ListItemIcon>
            <ListItemText primary="Bulk EMI Sync" />
          </ListItem>}

          {employeeService.productValue == Product.Accounting &&(roleChecker.isGMLevelAccess(user.role) || roleChecker.isPIVGAccess(user.role)) && <ListItem
            button
            key="insurancebulkuploade"
            component="a"
            href={`${path}/insurancebulkuploade`}
          >
            <ListItemIcon style={{ marginLeft: 2 }}>
              <InsertDriveFileIcon />
            </ListItemIcon>
            <ListItemText primary="Insurance Bulk Upload" />
          </ListItem>} 
          
          {employeeService.productValue == Product.Accounting &&(roleChecker.isGMLevelAccess(user.role) || roleChecker.hasAccountsAccess(user.role)  || roleChecker.isAccountChecker(user.role) ) && <ListItem
            button
            key="reconciliationBulkUpload"
            component="a"
            href={`${path}/reconciliationBulkUpload`}
          >
            <ListItemIcon style={{ marginLeft: 2 }}>
              <InsertDriveFileIcon />
            </ListItemIcon>
            <ListItemText primary="Bulk Nach Reconciliation" />
          </ListItem>} 

          {employeeService.productValue == Product.Legal && (
            <LetterMenuList user={user} {...props} />
          )}
          {(employeeService.productValue == Product.CustomerCare) && (
            <SmsMenu user={user} {...props} />
          )}
          {(employeeService.productValue == Product.CustomerCare) && (
            <CallDetailMenu user={user} {...props}/>
          )}
          {(employeeService.productValue == Product.VendorPanel) && (
            <ListItem
            button
            key="vendor data"
            component="a"
            href={`${path}/vendorsdata`}
          >
            <ListItemIcon style={{ marginLeft: 2 }}>
              <InsertDriveFileIcon />
            </ListItemIcon>
            <ListItemText primary="Vendor Assigned Report" />
          </ListItem>
          )}
         {(employeeService.productValue == Product.SBL) && !["AUDITOR","Sales"].includes(user.role) &&  <ListItem
             
            button
            key="branchexpense"
            component="a"
            href={`${path}/branchexpense`}
          >
            <ListItemIcon>
              <SettingsApplicationsRounded />
            </ListItemIcon>
            <ListItemText primary="Branch Expense"/>
          </ListItem>}
          { !["AUDITOR","Sales"].includes(user.role) &&  <ListItem
             
             button
             key="Employee Expense"
             component="a"
             href={`${path}/employexpense`}
           >
             <ListItemIcon>
               <SettingsApplicationsRounded />
             </ListItemIcon>
             <ListItemText primary="Employee Expense"/>
           </ListItem>}
        </List>
        <List>
          <ListItem>
            <SwitchAccountModal/> 
          </ListItem>
        </List>
        <List>
          <ListItem>
            <Button
              onClick={employeeService.logout}
              variant="contained"
              color="secondary"
              align="center"
            >
              Logout
            </Button>
          </ListItem>
        </List>
      </div>
      {/* <Divider />
      <List>
        {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List> */}
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (

    <div className={classes.root}>
      <CssBaseline />
    {<AppBarHeader handleDrawerToggle={handleDrawerToggle} {...props}  />}
     {<nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>}
      <main
        style={{
          width: "100%",
          // backgroundImage: `url(${blueBack})`,
          // backgroundSize: "cover",
          height: "100%",
        }}
      >
        <div className={classes.toolbar} />
        <div style={{ padding: 10 }}>
          <Routes {...props} />
        </div>
      </main>
    </div>
  );
}

export default Home;
