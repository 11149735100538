import React from "react";
import { Field, ErrorMessage, getIn } from "formik";

export default function FormElement({
  disabled,
  label,
  name,
  type,
  errors,
  touched,
  onKeyUp,
  placeholder,
}) {
  return (
    <div>
      <label>{label}</label>
      <Field
        disabled={disabled}
        placeholder={placeholder}
        name={name}
        type={type}
        onKeyUp={onKeyUp}
        className={
          "form-control" +
          (getIn(errors, name) && getIn(touched, name) ? " is-invalid" : "")
        }
      />
      <ErrorMessage name={name} component="div" className="invalid-feedback" />
    </div>
  );
}
