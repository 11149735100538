import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse
} from "@material-ui/core";
import { ExpandMore,ExpandLess} from "@material-ui/icons";
// import { SmsOutlined } from "@material-ui/icons";
import MailIcon from '@material-ui/icons/Mail';
import roleChecker from "../../../_helpers/rolechecker";

export default function GoldLettersMenu(props) {
  const [open, setOpen] = React.useState(false);
  const { path } = props.match;
  const user =props.user;


  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  return (
    <React.Fragment>
    <ListItem button onClick={handleClick}>
        <ListItemIcon><MailIcon /></ListItemIcon>
        <ListItemText primary="Gold Loan Letters" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" style={{paddingLeft:"50px"}}>
        <ListItem
             hidden={
              !(roleChecker.hasAccountsAccess(user.role) || roleChecker.isHOGoldAccess(user.role))
            }
            button
            key="one"
            component="a"
            href={`/home/goldloanletters/notice1`}
          >
            <ListItemText primary="Notice 1" />
          </ListItem>

          <ListItem
            hidden={
              !(roleChecker.hasAccountsAccess(user.role) || roleChecker.isHOGoldAccess(user.role))
            }
            button
            key="two"
            component="a"
            href={`/home/goldloanletters/notice2`}
          >
            <ListItemText primary="Notice 2" />
          </ListItem>
          <ListItem
            hidden={
              !(roleChecker.hasAccountsAccess(user.role) || roleChecker.isHOGoldAccess(user.role))
            }
            button
            key="publication"
            component="a"
            href={`/home/goldloanletters/publication`}
          >
            <ListItemText primary="Publication" />
          </ListItem>
          <ListItem
            hidden={
              !(roleChecker.hasAccountsAccess(user.role))
            }
            button
            key="auction"
            component="a"
            href={`/home/goldloanletters/auctionlist`}
          >
            <ListItemText primary="Auction List" />
          </ListItem>
          <ListItem
            hidden={!(roleChecker.hasAccountsAccess(user.role) || roleChecker.isHOGoldAccess(user.role))}
            button
            key="bran"
            component="a"
            href={`/home/goldloanletters/bulksubmissionletter`}
          >
            <ListItemText primary="Bulk Submission Letter" />
          </ListItem>
          <ListItem
            hidden={!(roleChecker.hasAccountsAccess(user.role) || roleChecker.isHOGoldAccess(user.role))}
            button
            key="bran"
            component="a"
            href={`/home/goldloanletters/submissionletter`}
          >
            <ListItemText primary="Submission Letter" />
          </ListItem>
          <ListItem
            hidden={!(roleChecker.hasAccountsAccess(user.role) || roleChecker.isHOGoldAccess(user.role))}
            button
            key="bran"
            component="a"
            href={`/home/goldloanletters/bulkreleaseletter`}
          >
            <ListItemText primary="Bulk Release Letter" />
          </ListItem>
          <ListItem
            hidden={!(roleChecker.hasAccountsAccess(user.role) || roleChecker.isHOGoldAccess(user.role))}
            button
            key="bran"
            component="a"
            href={`/home/goldloanletters/releaseletter`}
          >
            <ListItemText primary="Release Letter" />
          </ListItem>
        </List>
      </Collapse>
      </React.Fragment>
  )  
}
