import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import *as Yup from "yup";
import { employeeService } from "../_services";
import { history } from "../_helpers";
import "../css/common.css"
function ConfirmPassword({ showemp_id }) {

    const initialValues = {
        new_password: "",
        confirm_password: "",

    };
    const validationSchema = Yup.object().shape({

        new_password: Yup.string()
            .required("Password is required")
            .min(6, "Password must be at least 6 characters long")
            .max(20, "Password must not exceed 20 characters"),
        confirm_password: Yup.string()
            .required("Please confirm your password")
            .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
    }
    );
    const onSubmit = (values) => {
        // console.log('values are', values)
        employeeService.updatePasswordOnForgotPassword(showemp_id, values)
            .then((val) => {
                // console.log(val)
                alert("Password Updated Successfully")
                history.push("/login")
            })
            .catch((err) => {
                window.alert("Data not added")
            })
    }


    return (


        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ values, errors, touched, isSubmitting }) => (
                <Form>
                    <h2 style={{ textAlign: "center" }} className="card-header">
                       Reset Password
                    </h2>
                    <div className="card-body">
                    <h6 style={{ textAlign: "center" }}>Enter a new password to reset the password on your account. We'll ask for this password whenever you log in.</h6>
                        <div className="form-group">
                            <label className="required">New Password</label>
                            <Field
                                type='password'
                                name='new_password'
                                placeholder='Enter Your New Password'
                                className={
                                    "form-control" +
                                    (errors.new_password && touched.new_password ? " is-invalid" : "")
                                }
                                autoComplete="new-password"
                            />
                            <ErrorMessage
                                name='new_password'
                                component='div'
                                className='invalid-feedback'
                            />
                        </div>

                        <div className="form-group">
                            <label className="required">Confirm Password</label>
                            <Field
                                type='password'
                                name='confirm_password'
                                placeholder='Enter the Password Again'
                                autoComplete="new-password"
                                className={
                                    "form-control" +
                                    (errors.confirm_password && touched.confirm_password ? " is-invalid" : "")
                                }
                            />
                            <ErrorMessage
                                name='confirm_password'
                                component='div'
                                className='invalid-feedback'
                            />
                        </div>

                        <div className="form-group col d-flex justify-content-center">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-primary"
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-1"></span>
                        )}
                        Submit
                      </button>
                    </div>
                    </div>
                </Form>
            )}
        </Formik>


    );
}

export default ConfirmPassword;
