import React from 'react'
import TextField from "@material-ui/core/TextField";
import datehelper from '../../../../_helpers/datehelper';
import { TabContext } from '../TabBar';
import { useContext } from "react";
import { employeeService } from '../../../../_services';
import AddPDSummary from '../../Applications/ApplicationData/AddPDSummary';
import PdImages from '../../Applications/PdImages';
import AddEmdFeesModal from '../../Applications/ApplicationData/AddEmdFeesModal';


const PdReports = (props) => {

    let TabContextValue = useContext(TabContext)
    let cust = TabContextValue.cust

    const user = employeeService.userValue;

    return (
        <div>
            {cust && cust.pd_details && (
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
                    <TextField
                        id="pd_date"
                        label="PD Date"
                        defaultValue={datehelper.displayDate(new Date(cust.pd_details.pd_date))}
                        variant="outlined"
                        className="input_field"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        id="pd_status"
                        label="PD Status"
                        defaultValue={cust.pd_details.pd_status}
                        variant="outlined"
                        className="input_field"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        id="pd_approved_amount"
                        label="PD Approved Amount"
                        defaultValue={cust.pd_details.loan_amount}
                        variant="outlined"
                        className="input_field"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        id="pd_done_by"
                        label="PD Done By"
                        defaultValue={cust.pd_details.pd_emp_id}
                        variant="outlined"
                        className="input_field"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </div>
            )}

            <div style={{ display: "flex", justifyContent: "flex-start", marginTop: "30px" }} >
                {(!cust.pd_details || !cust.pd_details.pd_status || cust.pd_details.pd_status === '') && (
                    <AddPDSummary customer={cust} />
                )}
            </div><br /><br />

            {cust.pd_images && cust.pd_images.length > 0 && (
                <>
                    <h3>PD Visit Images</h3>
                    <PdImages images={cust.pd_images} />
                </>
            )}

            <div style={{marginTop:"30px"}}>
                <h2>IMD Fees</h2>
                <table style={{ width: "100%", border: "1px solid", padding: "10px" }}><th>Payment Date</th><th>Payment Amount</th><th>Payment Mode</th><th>Payment Comment</th><th>Receipt Ref Id</th>
                    {cust.emd_fees && cust.emd_fees.payment_amount > 0 && (
                        <tr><td>{datehelper.displayDate(new Date(cust.emd_fees.payment_date))}</td><td>{cust.emd_fees.payment_amount}</td>
                            <td>{cust.emd_fees.payment_mode}</td>
                            <td>{cust.emd_fees.payment_comment}</td>
                            <td>{cust.emd_fees.payment_ref_id}</td>
                        </tr>
                    )}
                </table>
                {(!cust.emd_fees || cust.emd_fees.payment_amount == 0) && (
                    <AddEmdFeesModal customer={cust} />
                )}
            </div>
        </div>
    )
}

export default PdReports