import React from "react";

export default function JobSheetTotals({ totals }) {
  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-10 mt-2 ml-sm-5 ml-md-auto col-sm-8 text-capitalize text-right">
            <span className="text-title">Gross Weight: </span>
            <strong id="total">{totals.gross_weight}</strong> Grams
          </div>
          <div className="col-10 mt-2 ml-sm-5 ml-md-auto col-sm-8 text-capitalize text-right">
            <span className="text-title">Total Deduction: </span>
            <strong id="total">{totals.deduction}</strong> Grams
          </div>
          <div className="col-10 mt-2 ml-sm-5 ml-md-auto col-sm-8 text-capitalize text-right">
            <span className="text-title">Total Items: </span>
            <strong id="total">{totals.total_items}</strong>
          </div>
          <div className="col-10 mt-2 ml-sm-5 ml-md-auto col-sm-8 text-capitalize text-right">
            <span className="text-title">Net Weight: </span>
            <strong id="total">{totals.net_weight}</strong> Grams
          </div>
          <div className="col-10 mt-2 ml-sm-5 ml-md-auto col-sm-8 text-capitalize text-right">
            <span className="text-title">Valuation: </span>₹
            <strong id="total">{totals.valuation}</strong>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
