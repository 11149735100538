import React from "react";
// import logo from "../assets/kaabil.png";
// import AuthorityDoc from "./AuthorityDoc";
import "./release.css";
import logo from "../../../assets/kaabil.png";
import datehelper from "../../../_helpers/datehelper";
export default class ReleaseDoc extends React.Component {
  render() {
    const data = this.props.dataFill;
    const date = new Date();
    const contact = data.contact
    // console.log("test letter num",data.letter_number)
    // function getAge(DOB) {
    //   var today = new Date();
    //   var birthDate = new Date(DOB);
    //   var age = today.getFullYear() - birthDate.getFullYear();
    //   var m = today.getMonth() - birthDate.getMonth();
    //   if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    //     age = age - 1;
    //   }

    //   return age;
    // }

    return (
      <div className="release">
        <style type="text/css" media="print">
          {"\
   @page { size: A4; }\
"}
        </style>
        <br />
        <div class="main">
          <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginBottom:'50px'}}>
          <img src={logo} alt="KFPL Logo" /> <br />
          </div>
          {/* <b>
            {date.getDate()}-{date.toLocaleString("default", { month: "long" })}
            -{date.getFullYear()}
          </b>{" "} */}
         <h6 style={{display:'flex',float:'right'}}>Date: {contact ? datehelper.displayDate(date) : datehelper.displayDate(new Date(data.current_date))}</h6>
          <br />
          <div className="right" style={{marginLeft:'500px'}}>
            <b>
              {
                // contact ? `KFPL/GL/${data.bank === "Federal" ? "FED" : "CSB"}/
                //  ${date.toLocaleString("default", { month: "long" })}/
                //  ${data.letterNumber + 1}` : 
                data.letter_number
              }

            </b>
          </div>
          <p>
            To, <br />
            {data.bank === "Federal" && "FEDRAL BANK"}
            {data.bank === "CSB" && "CATHOLIC SYRIAN BANK"}
            {data.bank === "SIB" && "THE SOUTH INDIAN BANK LTD"}
            <br />
            {data.bank === "Federal" && "Malviya Nagar , Jaipur"}
            {data.bank === "CSB" && "Motilal Atal Road , Jaipur"}
            {data.bank === "SIB" && "Jaipur"}
            <br />
            <b>Subject: </b> Release of Gold Packets. <br />
            We have authorized the below mentioned KFPL staff to collect the
            Gold Packets as per details given in the table below. Please
            handover the sealed gold packets after verification of the gross and
            net weight and mention the gold packet number in the table below.{" "}
            <br />
            Please make sure to deliver the packets only if the letter is signed
            by the Authorized Person of KFPL and verify the Identity and
            signatures of the authorized person before handing over the packets.{" "}
            <br />
          </p>
          <table style={{ width: "100%" }}>
            <tr>
              <th>Name</th>
              <th>KFPL Account Number</th>
              <th>Bank's A/C Number</th>
              <th>Sanction Date</th>
              <th>Ornament's Name</th>
              <th>GW</th>
              <th>NW</th>
              <th>Release Amount</th>
              <th>Sealed in Packet Number</th>
            </tr>
            <tr>
              <td>{data.name}</td>
              <td>{data.details.loan_account && <div>{data.details.loan_account}</div>}</td>
              <td>{data.loan_account && <div>{data.loan_account}</div>}</td>
              <td>
                {new Date(data.date).getDate()}-
                {new Date(data.date).getMonth() + 1}-
                {new Date(data.date).getFullYear()}
              </td>
              <td>
                {data.details.gold.ornaments.map((item) => (
                  <p>
                    {item.desc} - {item.quantity}
                  </p>
                ))}
              </td>
              <td>{data.gold.gross_weight}</td>
              <td>{data.gold.net_weight}</td>
              <td>{data.releaseAmount}</td>
              <td>{data.packetNumber}</td>
            </tr>
          </table>
          <br />
          
          <div style={{marginLeft:"-60px",marginTop:'30px'}}>
          <b>Auth. person: {data.authPerson} </b>
            <b>Signature </b> <span style={{marginLeft:'250px'}}>OIC</span> <br />
            Sign <br />
            <br />
            <b>Time of Handover: ________</b>{" "}
            <span className="right">Authorized Person</span>
            <br />
            Sign
            <br />
            <br />
            <b>For Office Use Only</b> <br />
           <p style={{marginBottom:'80px'}}>The said ornaments/coins are released. <br />
            Joint Custodians
            </p>
          </div>
          <br />
          <br/>
        </div>
        {/* <div class="main">
          <h3>
            RELEASE LETTER/ACKNOWLEDGEMENT{" "}
            {data.bank === "Federal" ? "(DigiBiz)" : ""}
          </h3>
          <br />
          <br />
          <p>
            <span className="right">Place: JAIPUR</span>
            <br />
            Branch: <b>JAIPUR</b>{" "}
            <span className="right">
              Date: {contact ? datehelper.displayDate(date) : datehelper.displayDate(new Date(data.current_date))}
            </span>
            <br />
            <br />
            Release of gold ornaments/coins pledged as security for{" "}
            {data.bank === "Federal" ? "Digi Biz " : ""}
            Gold Loan
            <br />
            <br />
            Received back the gold ornaments/coins mentioned below:
          </p>
          <table style={{ width: "100%" }}>
            <tr>
              <th>SI No.</th>
              <th>Ornaments</th>
              <th>Description of the lot</th>
              <th>Number of items</th>
              <th>Gross Weight(gm.)</th>
              <th>Net Weight(gm.)</th>
            </tr>
            <tr>
              <td>
                {data.details.gold.ornaments.map((item, idx) => (
                  <p>{idx + 1}</p>
                ))}
              </td>
              <td>
                {data.details.gold.ornaments.map((item) => (
                  <p>{item.desc}</p>
                ))}
              </td>
              <td>
                {data.details.gold.ornaments.map((item) => (
                  <p>{item.quantity}</p>
                ))}
              </td>
              <td>{data.details.gold.total_items}</td>
              <td>{data.gold.gross_weight}</td>
              <td>{data.gold.net_weight}</td>
            </tr>
          </table>
          <br />
          <b>Name and signature of the borrower </b> <br />
          <br />
          <b>For Office Use Only</b> <br />
          The said ornaments/coins are released. <br />
          Joint Custodians
        </div> */}
       
        {
          console.log(data)
        }
      </div>
    );
  }
}
