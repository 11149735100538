import React from "react";
import { useState } from "react";
import GoldAuctionServices from "../../../_services/goldauction.service";
import ClearOutlined from "@material-ui/icons/ClearOutlined";
import urlhelper from "../../../_helpers/urlhelper";
import { uploadFileToBucket } from "../../../_helpers/upload";
import { Paper } from "@material-ui/core";
import publication_data from "../../../assets/publication_data.csv";
import { employeeService } from "../../../_services";

export const PublicationCsvUpload = (props) => {
    const [selectedFile, setSelectedFile] = useState()
    const [show, setShow] = useState(true)
    const user = employeeService.userValue;
    const bucket = process.env.REACT_APP_s3docsBucket
    const [publication, setPublication] = useState({
        publication: {
            publication1: '',
            publication2: ''
        }
    })

    const handleAppend = async (e) => {
        setShow(false)
        let file = e.target.files[0]; 
        let name = e.target.name; 
        let fileNam = file.name; 
        fileNam = fileNam.split('.')[0] 
        let num1 = Math.floor(Math.random()*1000) 
        let num2 = Math.floor(Math.random()*100) 
        let fileName = `${fileNam}_${num1}${num2}.pdf` 
        // console.log(fileName) 
        let link = await uploadFileToBucket(bucket, 'application/pdf', `${bucket}/publications/${fileName}`, file); 
        setPublication({
            ...publication,
            publication: {
                ...publication.publication,
                [name]: link
            }
        })
        setShow(true)
    }

    const discardPublication = (id) => {
        if (id === 'one') {
            setPublication({ ...publication, publication: { ...publication.publication, publication1: '' } })
        } else if (id === 'two') {
            setPublication({ ...publication, publication: { ...publication.publication, publication2: '' } })
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData()
        data.append('file', selectedFile)
        data.append('publication1', publication.publication.publication1)
        data.append('publication2', publication.publication.publication2)
        data.append('emp_id', user.emp_id)
        // for (var key of data.entries()) {
        //     console.log(key[0] + ', ' + key[1]);
        // }
        await GoldAuctionServices.publicationCsv(data)
            .then(res => {
                if (res.ok) {
                    window.alert('Publication Details saved');
                    window.history.back();
                }
            })
            .catch(err => {
                window.alert('Some error occurred');
                console.log(err)
            })
    }

    const handleSelect = (e) => {
        setSelectedFile(e.target.files[0])
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h1>Publications</h1>
            <hr style={{ border: '1px solid grey' }} />
            <form onSubmit={(e) => handleSubmit(e)} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <Paper style={{ width: '70%', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '30px', padding: '20px', paddingBottom: '30px', marginBottom: '30px' }}>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start' }}>
                        <a href={publication_data} download>Download CSV File</a>
                    </div>
                    <label style={{ display: 'flex' }}>
                        <input type="file" name='formData' onChange={(e) => handleSelect(e)} accept=".csv" required />
                    </label>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                        <span style={{ color: 'red' }}>***Date format in csv : DD-MM-YYYY</span>
                    </div>
                </Paper>
                <Paper style={{ width: '70%', display: 'flex', justifyContent: 'center', paddingTop: '30px', paddingBottom: '40px', marginBottom: '30px' }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
                                {publication.publication.publication1 === '' ?
                                    <div style={{ display: 'flex', width: '600px', flexDirection: 'column', marginTop: '40px', alignItems: 'center' }}>
                                        {/* <div className="offset-md-3 col-md-6"> */}
                                        <div className="form-group files">
                                            <h3>First Publication</h3>
                                            <input
                                                accept=".pdf"
                                                type="file"
                                                name="publication1"
                                                className="form-control"
                                                onChange={(e) => handleAppend(e)}
                                                required
                                            />
                                        </div>
                                        {/* </div> */}
                                    </div> :
                                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px', alignItems: "center" }}>
                                        <h3 style={{ display: "flex", textAlign: "center" }}>First Publication</h3>
                                        {/* <a href={publication.publication.publication1} target="_blank"><b>View Publication</b></a> */}
                                        <a href={`/home/pdfviewer?pdf_url=` + urlhelper.encodeToBase64(publication.publication.publication1) + "&download=1"}><b>View Publication</b></a>
                                        <button type='button' style={{ border: 'none', width: "300px", height: '60px' }} onClick={() => discardPublication("one")}><ClearOutlined /></button>
                                    </div>
                                }
                                {publication.publication.publication2 === '' ?
                                    <div style={{ display: 'flex', width: '600px', flexDirection: 'column', marginTop: '40px', marginLeft: '20px', alignItems: 'center' }}>
                                        {/* <div className="offset-md-3 col-md-6"> */}
                                        <div className="form-group files">
                                            <h3>Second Publication</h3>
                                            <input
                                                accept=".pdf"
                                                type="file"
                                                name='publication2'
                                                className="form-control"
                                                onChange={(e) => handleAppend(e)}
                                                required
                                            />
                                        </div>
                                        {/* </div> */}
                                    </div> :
                                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px', marginLeft: '20px', alignItems: 'center' }}>
                                        <h3>Second Publication</h3>
                                        <a href={`/home/pdfviewer?pdf_url=` + urlhelper.encodeToBase64(publication.publication.publication2) + "&download=1"} ><b>View Publication</b></a>
                                        <button type='button' style={{ border: 'none', width: "300px", height: '60px' }} onClick={() => discardPublication("two")}><ClearOutlined /></button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Paper>
                {show === true &&
                    <Paper style={{ width: '70%', display: 'flex', justifyContent: 'center', paddingTop: '40px', paddingBottom: '40px', marginBottom: '30px' }}>
                        <button type='submit' style={{ borderRadius: '5px', color: 'white', backgroundColor: '#0066cc', border: 'none', width: "300px", height: '40px' }}>Submit</button>
                    </Paper>}
            </form>
            {
                console.log(publication)
            }
        </div> 
    )
}