import React from 'react';
import { useState } from 'react';
import { Modal, Box, Typography, Button } from '@material-ui/core';
import { LockOpenOutlined } from '@material-ui/icons';
import { employeeService } from '../../_services';

export const UserUnblockModal = ( ) => {
    const [open, setOpen] = useState(false)
    const [emp_id, setEmp_id]=useState('')

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleSubmit=(e)=>{
        e.preventDefault();
          employeeService.unblock(emp_id)
          .then(res=>{
            window.alert('User unblocked');
            window.location.reload();
          })
          .catch(err=>{
            window.alert('Something went wrong');
            console.log(err)
          })
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        height: 200,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        // p: 4,
        overflow: 'scroll',
        //paddingBottom: '30px',
    }

    const handleChange =(e)=>{
            setEmp_id(e.target.value)
    }

    return (
        <div>
            <Button variant="outlined" aria-label="increase" style={{height:'50px'}} onClick={handleOpen}><LockOpenOutlined/></Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <form  onSubmit={(e)=>handleSubmit(e)} style={{display:'flex',flexDirection:'column', alignItems:'center', marginTop:'30px'}} >
                            <label style={{display:'flex',flexDirection:'column'}} >
                                Employee Id
                                <input type="text" placeholder='Employee Id' onChange={(e)=>handleChange(e)} required/>
                            </label>
                            <button style={{width:'100px',backgroundColor:'blue',color:'white', border:'none', borderRadius:'5px'}}>Unblock</button>
                        </form>
                    </Typography>
                </Box>
            </Modal>
        </div>
    )
}