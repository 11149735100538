import React, { useState } from "react";
import GuarantorListColumns from "./GuarantorListColumns";
import JobSheetMinimalList from "./GuarantorListRows";
import EmptyJobSheetMinimal from "./EmptyJobSheetMinimal";
import { Button, Typography } from "@material-ui/core";

export default function GuarantorList({list, setFieldValue }) {
  const [values, setValues] = useState([]);

  const handleChange = (idx) => (e) => {
    const { name, value } = e.target;

    const newvalues = [...values];
    newvalues[idx][name] = value;
    setFieldValue("guarantors", newvalues);
    setValues(newvalues);
  };

  const handleAddRow = () => {
    const item = {
      name: "",
    };
    setFieldValue("guarantors", [...values, item]);
    setValues([...values, item]);
  };

  const handleRemoveSpecificRow = (idx) => () => {
    const newvalues = [...values];

    newvalues.splice(idx, 1);
    setFieldValue("guarantors", newvalues);
    setValues(newvalues);
  };

  // if (values.length > 0) {
  //   return (
  //     <div>
  //       <div className="col-10 mx-auto text-center text-title">
  //         <Typography variant="h5">Guarantor List</Typography>
  //       </div>
  //       <div className="row clearfix">
  //         <div className="col-md-12 column">
  //           <table className="table table-bordered table-hover" id="tab_logic">
  //             <thead>
  //               <GuarantorListColumns />
  //             </thead>
  //             <JobSheetMinimalList
  //               handleRemoveSpecificRow={handleRemoveSpecificRow}
  //               handleChange={handleChange}
  //               values={values}
  //             />
  //           </table>
  //           <div style={{ textAlign: "center", margin: 20 }}>
  //             <Button variant="outlined" onClick={handleAddRow}>
  //               Add Item
  //             </Button> 
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // } else {}
    return (
      <div>
        <EmptyJobSheetMinimal />
        <div style={{ textAlign: "center", margin: 20 }}>
          <table>
            <thead>
              <tr>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
            {list && list.length>0 && list.map((x) => {
            return <tr>
              <td>
                {x.general_information && x.general_information.title} {x.general_information && x.general_information.name} {x.general_information.kyc_relation_type} {x.general_information.kyc_relation}
              </td>
                {console.log(x.general_information.sonof)}
              </tr>  
          })}
            </tbody>
          </table>
           {/* <Button variant="outlined" onClick={handleAddRow}>
            Add Item
          </Button>  */}
        </div>
      </div>
    );
  
}
