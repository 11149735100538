import React,{useState,useEffect} from "react";
import { Route, Switch } from "react-router-dom";
import VendorData from "./VendorData";
import VendorApplication from "./VendorApplication";
import { Paper ,Button} from "@material-ui/core";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import urlhelper from  '../../../_helpers/urlhelper'
import { employeeService } from "../../../_services";
import ValuationAssignment from "./ValuationAssignment"
import RcuAssignment from "./RCUAssignment";
function VendorRcuReport(params) {
    const [employeeList, setEmployeeList] = useState({});

  //  console.log("param data",params)
    let rcu_data = [params.val]


    async function getAllEmployees() {
        await employeeService.getAll().then((res) => {
          let list = {};
          res.map((e) => {
            list[e.emp_id] = e.first_name + " " + e.last_name;
          });
          setEmployeeList(list);
        });
      }
      useEffect(()=>{
        getAllEmployees()
      },[])


  return (
    <div>
       
        

       <Paper style={{  borderRadius: '10px', margin: '3px', padding: '30px' }}>

              <h1>RCU Report</h1>
              {
   rcu_data?.length > 0 ? (
    <Table>
      <Thead>
        <Tr>
          <Td className='table-row-th' style={{textAlign:"center"}}> Name</Td>
          <Td className='table-row-th' style={{textAlign:"center"}}>Request</Td>
          <Td className='table-row-th' style={{textAlign:"center"}}>Requested By</Td>
          <Td className='table-row-th' style={{textAlign:"center"}}>Response</Td>

 
          <Td className='table-row-th' style={{textAlign:"center"}}>Property Type	</Td>

          <Td className='table-row-th' style={{textAlign:"center"}}> Report	</Td>

          <Td className='table-row-th' style={{textAlign:"center"}}>Verify	</Td>

          <Td className='table-row-th' style={{textAlign:"center"}}>Edit	</Td>

          

        </Tr>
      </Thead>
      <Tbody>
        {rcu_data && rcu_data.map((val, ind) => (
          <Tr key={ind}>
            <Td className='table-row-td' style={{textAlign:"center"}}>{val?.rcu_report?.provider}</Td>

            <Td className='table-row-td' style={{textAlign:"center"}}>{val?.rcu_report?.request_status}</Td>
            <Td className='table-row-td' style={{textAlign:"center"}}>{val?.rcu_report?.requested_by   && employeeList[val.rcu_report.requested_by]}</Td>

            <Td className='table-row-td' style={{textAlign:"center"}}>{val?.rcu_report?.response_status}</Td>

             
            <Td className='table-row-td' style={{textAlign:"center"}}>{val.rcu_report.property_type}</Td>
            <Td className='table-row-td' style={{textAlign:"center"}}> 
                        {
                                 val.rcu_report && val.rcu_report.rcu_report_doc && val.rcu_report.rcu_report_doc!== ""  && <a href={ val.rcu_report.rcu_report_doc } target='_blank'>View Report</a>
                           } </Td>
                           <Td className='table-row-td'>{(val && val.rcu_report &&  val.rcu_report.rcu_report_doc &&  (!val.rcu_report_verify_details || !val.rcu_report_verify_details.is_verified))?<>
                                { <Button disabled>Pending</Button>}
                            </>:<>
                            {
                                (val.rcu_report_verify_details && val.rcu_report_verify_details.is_verified) ? <> 
                                <p style={{color:'green'}}>Verified</p> 
                                
                                </>:null
                            }
                               
                            </>} </Td>
                            <Td className='table-row-td'>
                             {(val && (!val.rcu_report_verify_details || !val.rcu_report_verify_details.is_verified) ) &&  <RcuAssignment  val = {val}  />}
                            </Td>
            {/* Add other TDs for other properties */}
          </Tr>
        ))}
      </Tbody>
    </Table>
  ) : (
    <p>No Data Available</p>
  )
}


              

</Paper>
    </div>
  );
}

export default VendorRcuReport ;
