import React from "react";
import { Router } from "react-router-dom";
import { history } from "./_helpers/history";
import ReactDOM from "react-dom";
import { App } from "./app";
import "bootstrap/dist/css/bootstrap.min.css";

// import { employeeService } from "./_services";

// attempt silent token refresh before startup
// employeeService.refreshToken();

ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>,
  document.getElementById("root")
);
