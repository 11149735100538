import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Typography, Paper, Button } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import datehelper from '../../../_helpers/datehelper';
import Save from '@material-ui/icons/Save';
import CourtCaseServices from '../../../_services/courtcase.service';
import { Table, Thead, Tbody, Tr, Td, Th } from 'react-super-responsive-table';
import { MarkDoneModal } from './MarkDoneModal';

export const NextTaskForm = (props) => {
    let CourtCase = props.case;
    let hearings = CourtCase.next_hearing
    let ndate =new Date()

    const [newHearing, setNewHearing] = useState([...hearings])

    const addArray = () => {
        let arr = [...newHearing, { hearing_date: new Date(), task: '' }]
        setNewHearing(arr)
    }

    const removeArray = (e, idx) => {
        let arr = [...newHearing]
        console.log(idx, arr)
        arr.splice(idx, 1)
        setNewHearing(arr)
    }

    const handleChange = (e, idx) => {
        newHearing[idx][e.target.name] = e.target.value
        setNewHearing(newHearing)
    }

    const UpdateCase = async (e) => {
        e.preventDefault();
        await CourtCaseServices.UpdateCase(CourtCase._id, { next_hearing: newHearing })
            .then(res => {
                window.alert('Details saved successfully')
                window.location.reload()
            })
            .catch(err => {
                window.alert('Some error occurred')
                console.log(err)
            })
    }

    return (
        <Paper style={{ marginTop: '20px', padding: '20px' }}>
            <form onSubmit={(e) => {e.stopPropagation(); UpdateCase(e)}} id='NextTaskForm'>
                <Table style={{fontSize:'large'}}>
                    <Thead>
                        <Tr>
                            <Th style={{ width: '20%' }} >Date</Th>
                            <Th style={{ width: '50%' }}>Task</Th>
                            <Th style={{ width: '20%' }}></Th>
                            <Th style={{ width: '10%' }}></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            newHearing && newHearing.length > 0 ?
                                newHearing.map((m, idx) => {
                                    return <Tr style={{ marginTop: '20px' }} key={m._id?m._id:m.hearing_date}>
                                        <Td>
                                            <input type="date" name='hearing_date' style={{ border: '.25px solid grey', width: '100%' }} onChange={(e) => handleChange(e, idx)} defaultValue={datehelper.getDateInputString(new Date(m.hearing_date))} required />
                                        </Td>
                                        <Td >
                                            <input type='text' name='task' style={{ border: '.25px solid grey', width: '100%' }} onChange={(e) => handleChange(e, idx)} defaultValue={m.task} required />
                                        </Td>
                                        <Td >
                                            {
                                                m && m._id&&(new Date(m.hearing_date)<=ndate)&& <MarkDoneModal hearing_details={{hearing_date: m.hearing_date, lawyer_name: '', judge_name: '', party_lawyer: '', case_manager_name: '', penal_codes: '', judgement: ''}} next_hearing_id={m._id} court_case={CourtCase} />
                                            }
                                        </Td>
                                        <Td>
                                            <Button type='button' onClick={(e) => removeArray(e, idx)}><Delete /></Button>
                                        </Td>
                                    </Tr>
                                }) :
                                null
                        }
                    </Tbody>
                </Table>

                <Paper style={{ width: '63px', margin: 'auto', marginTop: '20px' }}>
                    {/* <Button type='button' onClick={() => { addArray() }}>
                        Add
                    </Button> */}
                </Paper>
                <hr style={{ border: '1px solid grey' }} />
                <Paper style={{ width: '63px', height: '63px', margin: 'auto', marginTop: '10px', paddingTop: '12.5px', borderRadius: '70px' }}>
                    <Button type='submit' style={{ color: 'blue' }}>
                        <Save />
                    </Button>
                </Paper>
            </form>
            {
                console.log(newHearing)
            }
        </Paper>
    )

}