import React, { useContext } from 'react'
import { Box, Modal, Button, Checkbox} from '@material-ui/core'
import { useState } from 'react'
import { TabContext } from './TabBar'
import { useEffect } from 'react'

const CheckListModal = (props) => {
    const [open, setOpen] = useState(false)
    const setActiveStep= props.setActiveStep
    const checkList =props.checkList ||[]
    const completionPercent=props.completionPercent
    const checked = checkList.filter(cl=>cl.checked===true)
    const context=useContext(TabContext)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        // height: 430,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        //overflow: 'scroll',
        //paddingBottom: '30px',
    };

    return (
        <div>
            <Button variant='outlined' style={checkList.length===checked.length?{color:'green', margin:'40px', borderColor:"green"}:{margin:'40px'}} color='secondary' onClick={handleOpen}>Checklist {checked.length}/{checkList.length}</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box>
                        <div style={{textAlign:'center '}}>
                            <h3>CHECKLIST</h3>
                        </div>
                         {
                            checkList&&checkList.length>0&&checkList.map(cl=>{
                               return <div style={{color:'black'}}>
                                    <Checkbox style={{color:"green"}} checked={cl.checked} name="gilad" disabled/>  {cl.value}
                                </div>
                            })
                         }
                        {completionPercent===100 && <Button style={{margin:'22px'}} variant='contained' color='primary' onClick={()=>context.setActiveStep(props.nextStep)}>Move to next step</Button>}
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}

export default CheckListModal