import React from "react";
import { Route, Switch } from "react-router-dom";
import VendorData from "./VendorData";
import VendorApplication from "./VendorApplication";

function VendorIndex({ match }) {
  const { path } = match;

  return (
    <Switch>                             
      <Route exact path={path} component={VendorData} />
      <Route path={`${path}/details/:id`} component={VendorApplication} />
   


    </Switch>
  );
}

export { VendorIndex };
