import React from 'react'
import { useState, useEffect } from 'react'
import {letter} from "../../../_services/lettertracking.service"
import {useParams,useHistory} from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import {loanService} from "../../../_services"
import swal from 'sweetalert';

const Details = () => {
  const history = useHistory();
  // const isCreate = !id;
  const [formData, setFormData] = useState({
    title_name: "",
    name: "",
    relation: "",
    title_father: "",
    father_name: "",
    address: "",
    contact: "",
    disbursement_date: "",
    account_number: "",
    sanction_amount: "",
    emi_amount: "",
    tenure: "",
    addCharge: "",
    coapplicant_guarantor: []
  });
  const [applicationId, setApplicationId] = useState('');
  const[checkCosigner, setCheckCosigner]= useState('');
  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }
  const handleSubmit =(e) => {
    e.preventDefault();
    letter.saveDetails(formData)
    .then((data) => {
      console.log(data, "Data added successfully");
      swal({
        title: "Success",
        text: "Data added successfully",
        icon: "success",
        button: "Okay"
      }).then(() => {
        // After user clicks "Okay" button, navigate to '/home/huee/wwww'
        history.push('/home/letters/showlrn');
      });
    })
    .catch((err) => {
      console.error("Error while saving data:", err);
      swal({
        title: "Error",
        text: "Settlement with this account number already exists",
        icon: "error",
        button: "Okay"
      });
    });
}

  const handleApplicationId = (event) => {
    setApplicationId(event.target.value);
}

const getcustomer = () => {
  if (!applicationId) {
    swal({
      title: "Error",
      text: "Please Enter Account Number",
      icon: "error",
      button: "Okay"
    });
      return; // Stop execution if any required field is empty
  }

  loanService.getBySearch({ application_id: applicationId })
      .then((data) => {
        setFormData({
          title_name:"",
          name: data[0]?.applicant?.general_information?.name || "",
          relation: "",
          title_father:"",
          father_name: data[0]?.applicant?.general_information?.sonof || "",
          address: data[0]?.applicant?.general_information?.address || "",
          contact: data[0]?.applicant?.general_information?.contact || "",
          disbursement_date: data[0]?.loan?.disbursement_date || "",
          account_number:  data[0]?.application_id || "",
          sanction_amount: data[0]?.loan?.sanction_amount || "",
          emi_amount: data[0]?.loan?.emi || "",
          tenure: data[0]?.loan?.tenure || "",
          addCharge: "",
        });    
        setCheckCosigner(data[0])
      })
      .catch((err) => {
          console.log(err);
      });
};

const {id}  = useParams();

const getLetter = async () => {
  const details = await letter.getSingleLrn(id);
  setFormData(details);
  console.log(details,"gggggghhhjjjjjjyyyyyyyy");
}

useEffect(() => {
  if(id){
    getLetter()
  }
}, [])

  const handleEdit =  () => {
     letter.updateLrn(id, formData)
     .then(() => {
      swal({
        title: "Success",
        text: "Updated Successfully",
        icon: "success",
        button: "Okay"
      }).then(() => {
        window.history.back(); // Go back to the previous page
      });
    })
     .catch((err) => {
       console.log(err)
     })

  }

  useEffect(() => {
    let tempArr = [];
    let arr = [];
    if (checkCosigner && checkCosigner.co_applicants && checkCosigner.co_applicants.length > 0) {
      checkCosigner.co_applicants.forEach((x) => {
        arr.push({
          name: x.general_information.name,
          sonof: x.general_information.sonof,
          address: x.general_information.address,
          contact: x.general_information.contact
        });
      });
    }
    if (checkCosigner && checkCosigner.guarantors && checkCosigner.guarantors.length > 0) {
      checkCosigner.guarantors.forEach((x) => {
        arr.push({
          name: x.general_information.name,
          sonof: x.general_information.sonof,
          address: x.general_information.address,
          contact: x.general_information.contact
        });
      });
    }
  
    const combinedArray = [...arr, ...tempArr];
  
    setFormData(prevState => ({
      ...prevState,
      coapplicant_guarantor: combinedArray.map(item => ({
        title_name: "",
        name: item.name || "",
        relation: "",
        title_father: "",
        father_name: item.sonof || "",
        cosigner: "",
        address: item.address || "",
        contact: item.contact || "",
      }))
    }));
  }, [checkCosigner]);
  
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedFormData = [...formData.coapplicant_guarantor];
    updatedFormData[index] = {
      ...updatedFormData[index],
      [name]: value
    };
    setFormData({
      ...formData,
      coapplicant_guarantor: updatedFormData
    });
  };
  
  return (
    <>
       <h1 className='my-3'>Enter the Details to Create Legal Letter</h1>
       {!id && (
  <div>
       <div className='container my-3'>
            <TextField type="text" className="form-control" required placeholder='Enter the Account Number' name='Loan' value={applicationId} onChange={handleApplicationId} />
            </div>
            <button onClick={getcustomer}>Submit</button>
            </div>
          )}

        <form onSubmit= {handleSubmit}>
    
        <div>
             <div className='my-3' style={{display: "flex", flexDirection: "row", width: "50vw"}}>
            <select className="form-select form-select-sm" required name='title_name' aria-label=".form-select-sm example" value={formData.title_name}   onChange={handleChange}>
              <option selected></option>
              <option >श्री</option>
              <option >श्रीमती</option>
              <option >कुमारी</option>
             </select> <TextField type="text" className="form-control" placeholder='Enter the Name' name='name' value={formData.name}  onChange={handleChange} />
            </div>
            
            <div className='my-3' style={{display: "flex", flexDirection: "row", width: "50vw"}}>
             <select className="form-select form-select-sm" required name='relation' aria-label=".form-select-sm example" value={formData.relation}   onChange={handleChange}>
              <option selected></option>
              <option >पुत्र</option>
              <option >पत्नी</option>
              <option >पुत्री</option>
             </select>
            
            <select className="form-select form-select-sm" required name='title_father' aria-label=".form-select-sm example" value={formData.title_father} onChange={handleChange}>
              <option selected></option>
              <option  >श्री</option>
              <option >श्रीमती</option>
            </select>
             <TextField type="text" className="form-control" required placeholder='Enter the Father or Husband  Name' name='father_name'  value={formData.father_name} onChange={handleChange} />
            </div>
 
            <div className='container my-3'>
            <TextField type="text" className="form-control" required placeholder='Enter the Address' name='address' value={formData.address}  onChange={handleChange} />
            </div>
            <div className='container my-3'>
            <TextField type="text" className="form-control" required placeholder='Enter the Mobile' name='contact'  value={formData.contact}  onChange={handleChange} />
            </div>

            <div className='container my-3'>
            <TextField type="text" className="form-control" required placeholder='Enter the Total disbursement date' name='disbursement_date' value={formData.disbursement_date} onChange={handleChange} />
            </div>

            <div className='container my-3'>
            <TextField type="text" className="form-control" required placeholder='Enter the Account Number' name='account_number'  value={formData.account_number} onChange={handleChange} />
            </div>
            <div className='container my-3'>
            <TextField type="number" className="form-control" required placeholder='Enter the Total sanction Amount' name='sanction_amount'  value={formData.sanction_amount} onChange={handleChange} />
            </div>

            <div className='container my-3'>
            <TextField type="number" className="form-control" required placeholder='Enter the Total Emi' name='emi_amount'  value={formData.emi_amount} onChange={handleChange} />
            </div>
            
            <div className='container my-3'>
            <TextField type="number" className="form-control" required placeholder='Enter the Total Tenure' name='tenure'  value={formData.tenure} onChange={handleChange} />
            </div>
            <div className='container my-3'>
            <TextField type="number" className="form-control" required placeholder='Enter the Total Add charge' name='addCharge'  value={formData.addCharge} onChange={handleChange} />
            </div>
            {formData.coapplicant_guarantor && formData.coapplicant_guarantor.map((coapplicant, index) => (
  <div key={index}>
            <div style={{display: "flex"}} >
       <div className='my-3' style={{display: "flex", flexDirection: "row", width: "37vw"}}>  
             <select className="form-select form-select-sm" required name='title_name' value={coapplicant.title_name}  aria-label=".form-select-sm example" onChange={(e) => handleInputChange(e, index)}>
        <option value="">Select Title</option>
        <option value="श्री">श्री</option>
        <option value="श्रीमती">श्रीमती</option>
        <option value="कुमारी">कुमारी</option>
      </select>
            
             <TextField type="text" className="form-control" required placeholder='Enter the Name' name='name' value={coapplicant.name} onChange={(e) => handleInputChange(e, index)} />
            </div>
            <div className='my-3' style={{display: "flex", flexDirection: "row", width: "37vw"}}>
            <select className="form-select form-select-sm" required aria-label=".form-select-sm example" name='relation' value={coapplicant.relation} onChange={(e) => handleInputChange(e, index)}>
        <option value="">Select Relation</option>
        <option value="पुत्र">पुत्र</option>
        <option value="पत्नी">पत्नी</option>
        <option value="पुत्री">पुत्री</option>
      </select>



         <select className="form-select form-select-sm" required name='title_father'value={coapplicant.title_father}   aria-label=".form-select-sm example" onChange={(e) => handleInputChange(e, index)}>
        <option value="">Select Title</option>
        <option value="श्री">श्री</option>
        <option value="श्रीमती">श्रीमती</option>
      </select>
          
             <TextField type="text" className="form-control" required placeholder='Enter the Father or Husband  Name' name='father_name'   value={coapplicant.father_name} onChange={(e) => handleInputChange(e, index)}/>
             <select className="form-select form-select-sm" required name="cosigner" aria-label=".form-select-sm example"  value={coapplicant.cosigner} onChange={(e) => handleInputChange(e, index)}>
                        <option selected></option>
                        <option >(सह आवेदक)</option>
                        <option >(गारंटर)</option>
                        </select>
            </div>
 
       </div>
       <div style={{display: "flex"}}>
       <div className='container my-3' style={{width: "55vw"}}>
            <TextField type="text" className="form-control" required placeholder='Enter the Address' name='address' value={coapplicant.address} onChange={(e) => handleInputChange(e, index)}/>
            </div>
            <div className='container my-3' style={{width: "20vw"}}>
            <TextField type="text" className="form-control" required placeholder='Enter the Mobile' name='contact'value={coapplicant.contact} onChange={(e) => handleInputChange(e, index)} />
            </div>
            </div>
            </div>
        ))}
            <div className='container my-3'>

            {!id && (  <button className='btn btn-warning' type='submit' > Create Letter</button>  )}

              {id===undefined?"":<button className='btn btn-danger mx-5' type='button' onClick={handleEdit}>Update Letter</button>}
            
            </div>
        </div>
        </form>
    </>
  )
}

export default Details