import React from "react";
import { Route, Switch } from "react-router-dom";
import AddEditBranch from "./AddEditBranch";
import BranchList from "./BranchList";

function Branches({ match }) {
  const { path } = match;

  return (
    <Switch>
      <Route exact path={path} component={BranchList} />
      <Route path={`${path}/add`} component={AddEditBranch} />
      <Route path={`${path}/edit/:id`} component={AddEditBranch} />
    </Switch>
  );
}

export { Branches };
