import { fetchWrapper } from "../_helpers";
const apiUrl = process.env.REACT_APP_API_URL;
const baseUrl = `${apiUrl}/bulkreleaseletter`;

async function saveBulkreleaseLetterDetails(params) {
  console.log("test s post",params)
  return fetchWrapper.post(`${baseUrl}/savedata`, params)
}

async function getLetter() {
  return fetchWrapper.get(`${baseUrl}/getletter`)
}

async function countLetter() {
  return fetchWrapper.get(`${baseUrl}/countletter`)
}
async function searchByLetterNumber(params) {
  console.log("letter num", params)
  return fetchWrapper.post(`${baseUrl}/searchletter`, params)
}
export const BulkReleaseLetter = {
  saveBulkreleaseLetterDetails,
  getLetter,
  countLetter,
  searchByLetterNumber,
}

