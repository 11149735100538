import React from 'react'
import AuLogo from "../../../../assets/au_logo.png"
import { useReactToPrint } from "react-to-print";


const DisbursementPaymentReceipt = (props) => {

    const element = props.location.state
    console.log(props)

    const DisbursementReceiptRef = React.useRef();

    const handleSubmissionPrint = useReactToPrint({
        content: () => DisbursementReceiptRef.current,
        pageStyle: "print"
    });


    return (
        <div>
            <div style={{ textAlign: "center" }}>
                <button onClick={handleSubmissionPrint}>Print Letter</button>
            </div>
            <div
                style={{
                    width: "290mm",
                    height: "300mm",
                    backgroundColor: "white",
                    padding: 40,
                    position: "relative",
                }}
            >
                <div ref={DisbursementReceiptRef} style={{ display: "flex", flexDirection: "column", alignItems: "center", fontSize: "20px" }} >

                    {element.kaabil_bank_name === 'AU Bank' && (<div>
                        <img src={AuLogo} style={{ width: "80%" }} /><br /><br />

                        <h4 style={{ width: "100%", marginLeft: "20%" }}>Dear Customer,</h4><br />

                        <span style={{ textAlign: "justify", width: "80%" }}>
                            Please note that the transaction instruction given through AU Bank's Corporate Internet
                            Banking has been processed successfully.
                        </span><br /><br />
                    </div>)}

                    <h4 style={{ width: "100%", marginLeft: "20%" }}>Details of the transaction are given below for your ready reference.</h4><br /><br />

                    <table style={{ width: "80%" }}>

                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>Value Date</td>
                            <td style={{ border: "1px solid", textAlign: "right" }}>{element.disbursed_date && String(element.disbursed_date).split("T")[0]}</td>
                        </tr>

                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>Payment Method</td>
                            <td style={{ border: "1px solid", textAlign: "right" }}>{element.disbursed_amount > 200000 ? "RTGS" : "NEFT"}</td>
                        </tr>

                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>Debit Account Number</td>
                            <td style={{ border: "1px solid", textAlign: "right" }}>{element.kaabil_account_number}</td>
                        </tr>

                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>Beneficiary Account No</td>
                            <td style={{ border: "1px solid", textAlign: "right" }}>{element.disbursed_account_number}</td>
                        </tr>

                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>Beneficiary Name</td>
                            <td style={{ border: "1px solid", textAlign: "right" }}>{element.account_holder_name}</td>
                        </tr>

                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>Beneficiary IFSC</td>
                            <td style={{ border: "1px solid", textAlign: "right" }}>{"-----"}</td>
                        </tr>

                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>UTR Number/ Reference / RRN</td>
                            <td style={{ border: "1px solid", textAlign: "right" }}>{element.disbursed_utr_number}</td>
                        </tr>

                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>Transaction Amount</td>
                            <td style={{ border: "1px solid", textAlign: "right" }}>{element.disbursed_amount}</td>
                        </tr>

                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>Payment Instructions</td>
                            <td style={{ border: "1px solid", textAlign: "right" }}>LOAN FROM KAABIL FIN ({element.application_id})</td>
                        </tr>

                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>Channel</td>
                            <td style={{ border: "1px solid", textAlign: "right" }}>WEB</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default DisbursementPaymentReceipt