import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Fab from "@material-ui/core/Fab";
import SaveIcon from "@material-ui/icons/Save";

import { Button } from "@material-ui/core";
import { misService } from "../../../_services";

import PaymentSheet from "./PaymentSheet/PaymentSheet"
import roleChecker from "../../../_helpers/rolechecker";
import AddPaymentDetailsModal from "./PaymentSheet/AddPaymentDetailsModal";

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "75%",
    overflow: "scroll",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    minWidth: 650,
  },
}));

export default function PaymentDetailsModal(props) {
  const loan = props.loan;
  const user = props.user;
  console.log("test detailsmodel props", props)
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const initialValues = {
    payment_details: [],
  };

  const decimalRoundOff = (num) => {
    return Math.round(num * 100) / 100;
  };
  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function onSubmit(fields, { setSubmitting }) {

    misService.updatePaymentDetails(loan._id, fields).then((res) => {
      alert("Payment Details Updated. Awaiting Verification.");
      window.location.reload();
    });
    setSubmitting(false);
  }

  return (
    <div>
      <Button style={{ width: "100%", color: 'primary' }} onClick={handleOpen} color="primary">
        {
          <span
            style={{
              color: loan.payment_details && loan.payment_details.length > 0 ? "green" : "red",
            }}
            data-toggle="tooltip"
            data-placement="bottom"
          >
            &nbsp;●
          </span>
        }
        Payment Details
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Payment Details</h2>

            <Formik
              // innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={onSubmit}
              id="transition-modal-description"
            >
              {({ errors, touched, isSubmitting, setFieldValue }) => {
                return (
                  <Form>
                    <PaymentSheet setFieldValue={setFieldValue} pd={loan.payment_details} loan={loan} />
                    {(roleChecker.hasAccountsAccess(user.role) && loan.loan_status !== "Closed" && loan.loan_status !== "Auctioned") &&
                      <AddPaymentDetailsModal loan={loan} />}
                    <hr />
                    {(roleChecker.isAdminAccess(user.role) && loan.loan_status !== "Closed"  && loan.loan_status !== "Auctioned")?(
                      <div className="row justify-content-around">
                        <Fab
                          type="submit"
                          disabled={isSubmitting}
                          color="primary"
                          aria-label="save"
                        >
                          {isSubmitting && (
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                          )}
                          <SaveIcon />
                        </Fab>
                      </div>
                    ):null}
                    <hr />
                  </Form>
                );
              }}
            </Formik>

          </div>
        </Fade>
      </Modal>
    </div>
  );
}
