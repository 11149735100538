import React from "react";
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { employeeService, loanService, misService } from "../_services";
import roleChecker from "../_helpers/rolechecker";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Product from "../_helpers/product";
import { Input } from "antd";
import Profile from "./Users/Profile"
import NotificationComponent from "../_components/RedisNotifications/SendRedisNotifications"
import { history } from '../_helpers'
import "./AppBarHeader.css"
import { LoanStages } from "../constants/loanstages";

const { Search } = Input;
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  title: {
    flexGrow: 1,
  },
}));


export default function AppBarHeader(props) {
  const user = employeeService.userValue;
  const [searchQuery, setSearchQuery] = React.useState("");
  const [result, setResult] = React.useState([])
  const { path } = props.match;
  const classes = useStyles();
  const handleDrawerToggle = props.handleDrawerToggle;
const [allBranch,setAllBranch] = React.useState()
  const changeSearchQuery = async (query) => {
    console.log("Searching .." + query);
    setSearchQuery(query);
    if (query.length >= 3) {
      regexData(query)
    }
  }
  const changeSearchQuerygold = async (query) => {
    console.log("Searching .." + query);
    setSearchQuery(query);
    if(query.length>=3){
      regexDatagold(query)
    }
  }

  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      startSearch(searchQuery);
    }
    if (searchQuery && searchQuery.length >= 3) {
      regexData(searchQuery)
    }
  }
  const handleEnterKeygold = (e) => {
    if (e.key === "Enter") {
      startSearchgold(searchQuery);
    }
    if (searchQuery && searchQuery.length >= 3) {
      regexDatagold(searchQuery)
    }
  }

  const regexData = (searchQuery) => {
    console.log("running", searchQuery)
    loanService.findLoanByRegex({key:searchQuery})
    .then((data)=>{
      console.log(data)
      setResult(data)
    //  console.log('result on sbl',result)
    })
    .catch((err)=>{console.log(err)})
  }
  const regexDatagold= (searchQuery) => {
    console.log("running", searchQuery)
    misService.findLoanByRegex({key:searchQuery})
    .then((data)=>{
      // console.log('eretywaesrdfhgreds',data)
      setResult(data)
      // console.log('result is',result)
    })
    .catch((err)=>{console.log(err)})
  }

  async function getAllBranch() {
    try {
      const res = await loanService.getAllBranch();
      console.log("all branches are", res);
      setAllBranch(res);
      return res; // Return the branches to be used later
    } catch (err) {
      console.log('Error fetching branches', err);
      return null; // Return null in case of an error
    }
  }
  
  async function startSearch(value) {
    console.log("start search");
    console.log("search value..." + value);
    
    if (value && searchQuery && searchQuery.length >= 3) {
      try {
        const res = await loanService.getBySearch({ 'application_id': value,"stage_status":true });
        console.log('search result', res);
        
        const branches = await getAllBranch(); // Ensure branches are fetched before continuing
        if (branches && res && res.length === 1) {
          const loanObj = res[0];
          console.log(loanObj);
          console.log(user);
          
          // Check if the branch_id of the loan is in the user's allowed branches
          if (!branches.includes(loanObj.branch_id)) {
            window.alert("You don't have permission for this loan");
            return;
          }
  
          console.log("adding to history..");
          window.location.href = '/home/sblloans/details/' + loanObj._id;
        } else {
          window.alert("Loan not found: " + searchQuery);
        }
      } catch (err) {
        console.log('Error searching loan', err);
        window.alert("Error searching loan " + searchQuery);
      }
    }
  }
  
  async function startSearchgold(value) {
    console.log("start search");
    console.log("search value..."+value);
    if (value && searchQuery && searchQuery.length >= 3) {
      misService.getBySearch({ 'loan_account': value })
        .then((res) => {
          console.log('hii',res);
          if (res ) {
            let loanObj = res[0];
            
            console.log("adding to history..");
             window.location.href = '/home/goldloans?loan_account='+res.loan_account;
          }
          else {
            window.alert("Loan not found: " + searchQuery);
          }
        })
        .catch((err) => {
          console.log(err);
          window.alert("Error searching loan " + searchQuery);
        })
    }
  }

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Typography className="Appbar"
          style={{ flexGrow: 1, color: "white" }}
          align="center"
          variant="h5"
        >
          समृद्धि - {  employeeService.productValue?.toUpperCase()} Panel for {user.role}{" "} - Branch : {user.branch_id}
        </Typography>

        {(employeeService.productValue == Product.SBL || employeeService.productValue == Product.CoLending  || employeeService.productValue == Product.CustomerCare  || employeeService.productValue == Product.Collection ) && <div style={{ display: "flex", flexDirection: "column" }}>
          <Search
            onChange={(e) => changeSearchQuery(e.target.value)}
            placeholder="Loan Number"
            onKeyPress={(e) => handleEnterKey(e)}
            enterButton={true}
            onSearch={() => startSearch()}
            style={{
              position: "sticky",
              top: "0",
              left: "0",
              marginRight: "30px",
              width: "200px",
            }}
          />
          <div className="dropdown" style={{ width: "200px", position: "absolute", marginTop: "48px", paddingLeft: "10px" }}>
            {result && result.filter(item => {
              const searchItem = searchQuery.toLowerCase()
              const loanNo = item.application_id.toLowerCase()
              const name = item.applicant.general_information.name.toLowerCase()

              return searchItem && searchItem.length >= 3 && (loanNo.includes(searchItem) || name.includes(searchItem)) && (loanNo !== searchItem || name !== searchItem)
            })
              .map((item) => <div onClick={() => startSearch(item.application_id)} className="dropdown-row" style={{ color: "black" }} >
                {item.application_id} - {item.applicant.general_information.name}
              </div>)}
          </div>
        </div>
        }
        {employeeService.productValue == Product.GoldLoan && <div style={{ display: "flex", flexDirection: "column" }}>
          <Search
            onChange={(e) => changeSearchQuerygold(e.target.value)}
            placeholder="Loan Number"
            onKeyPress={(e) => handleEnterKeygold(e)}
            enterButton={true}
            onSearch={() => startSearchgold()}
            style={{
              position: "sticky",
              top: "0",
              left: "0",
              marginRight: "30px",
              width: "200px",
            }}
          />
           <div className="dropdown" style={{ width: "200px", position:"absolute", marginTop:"48px", paddingLeft:"10px"}}>
          {result && result.filter(item => {
            const searchItem = searchQuery.toLowerCase()
            const loanNo = item.loan_account.toLowerCase()
            const name = item.name.toLowerCase()
            const contact=item.contact;

            return searchItem && searchItem.length >=3 && (loanNo.includes(searchItem) || name.includes(searchItem) || contact.includes(searchItem))  && (loanNo !== searchItem || name !== searchItem || contact!==searchItem)
          })
            .map((item) => <div onClick={() => startSearchgold(item.loan_account)} className="dropdown-row" style={{ color: "black"}} >
              {item.loan_account} - {item.name} - {item.contact}
            </div>)}
        </div>
        </div>
        }
      
        <NotificationComponent  
        
        />

        {<Profile link={`${path}/users/update/password`} />}
      </Toolbar>

    </AppBar>

  );
}