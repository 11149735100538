// AllCloudAppCoAppDescription.js

import React from 'react';
import './AllCloudAppCoAppDescription.css';

const AllCloudAppCoAppDescription = (props) => {
    const value = props.value;
    const dynamicApplicants = props.dynamicApplicants;
    const dynamicguarantor = props.dynamicguarantor;
    const text = props.text;
    return (
        <div className="allCloudDescriptionContainer">
        {text==="Applicant"?<h6 className="card-title" style={{fontWeight:"bold",color:"#3F51B5"}}>Applicant</h6>:
        text==="Co-applicant"?<h6 style={{ fontWeight: "bold", color: "#3F51B5" }}>Co-applicant {dynamicApplicants===""?1:dynamicApplicants}</h6>:
        text==="Guarantor"?<h6 style={{ fontWeight: "bold", color: "#3F51B5" }}>Guarantor {dynamicguarantor===""?1:dynamicguarantor}</h6>:""}
   
            <div className="row" style={{width:"100%"}}>
  <div className="col-sm-4" id='loan_details'>
    <div className="card">
      <div className="card-body-sm-12">
        {value && <>
        <div className="Addition_flex" >
            <span >CIF ID</span>
            <span className='Addition_label'>{value.CustomerId}</span> 
        </div>
        <hr className='headingline'/>
        <div className="Addition_flex" >
            <span >Caste</span>
            <span className='Addition_label'>{value.Caste}</span>
        </div>
        <hr className='headingline'/>
        <div className="Addition_flex" >
            <span >Salutation</span>
            <span className='Addition_label'>{value.Salutation}</span>
        </div>
        <hr className='headingline'/>
        <div className="Addition_flex" >
            <span >First Name</span>
            <span className='Addition_label'>{value.FirstName}</span>
        </div>
    </>}
       
      </div>
    </div>
  </div>
  <div className="col-sm-4" id='loan_details'>
    <div className="card">
      <div className="card-body-sm-12">
        {value && <>
      
        <div className="Addition_flex" >
            <span >Middle Name</span>
            <span className='Addition_label'>{value.MiddleName}</span>
        </div>
        <hr className='headingline'/>
        <div className="Addition_flex" >
            <span >Last Name</span>
            <span className='Addition_label'>{value.LastName}</span>
        </div>
        <hr className='headingline'/>
        <div className="Addition_flex" >
            <span >Gender</span>
            <span className='Addition_label'>{value.Gender}</span>
        </div>
        <hr className='headingline'/>
        <div className="Addition_flex" >
            <span >DOB</span>
            <span className='Addition_label'>{new Date(value.DOB).toLocaleDateString('en-GB')}</span>
        </div>
       
    </>}
       
      </div>
    </div>
  </div>
  <div className="col-sm-4" id='loan_details'>
    <div className="card">
      <div className="card-body-sm-12">
        {value && <>
       
        <div className="Addition_flex" >
            <span >Contact Number</span>
            <span className='Addition_label'>{value.ContactNumber}</span>
        </div>
        <hr className='headingline'/>
        <div className="Addition_flex" >
            <span  style={{textAlign:"left"}}>Alternative Mobile Number</span>
            <span className='Addition_label'>{value.AlternateMobileNo}</span>
        </div>
        <hr className='headingline'/>
        <div className="Addition_flex" >
            <span >Occupation</span>
            <span className='Addition_label'>{value.Occupation}</span>
        </div>
        <hr className='headingline'/>
        <div className="Addition_flex" >
            <span >Marital Status</span>
            <span className='Addition_label'>{value.MaritalStatus}</span>
        </div>
    </>}
       
      </div>
    </div>
  </div>
</div>
<h6 style={{fontWeight:"bold",color:"#3F51B5"}}>Kyc Details</h6>
<div className="row" style={{width:"100%"}}>
  <div className="col-sm-12" id='loan_details'>
    <div className="card">
      <div className="card-body-sm-12">
        {value && <>
        <div style={{display:"flex",justifyContent:"space-between"}}>
        <div style={{display:"contents"}} >
        <span >Aadhar Number</span>
            <span className='Addition_label'>{value.MaskedAadarCard}</span>
        </div>
        <div style={{display:"contents"}}>
        <span >PAN Card</span>
            <span className='Addition_label'>{value.PANCard}</span>
        </div> 
        </div>
        
    </>}
       
      </div>
    </div>
  </div>
 
</div>



<div className="row" style={{width:"100%",marginTop:"1rem"}}>
  
  <div className="col-sm-6">
    
    <div className="card" >
      <div className="card-body-sm-12">
      <h6 style={{fontWeight:"bold",color:"#3F51B5"}}>Primary Address</h6>

      <div className="Addition_flex" >
            <span >Address</span>
            <span className='Addition_label'>{value.PrimaryAddressLine1}</span>
        </div>
        <hr className='headingline'/>
        <div className="Addition_flex" >
            <span >Landmark</span>
            <span className='Addition_label'>{value.PrimaryLandmark}</span>
        </div>
        <hr className='headingline'/>
        <div className="Addition_flex" >
            <span >Area</span>
            <span className='Addition_label'>{value.PrimaryArea}</span>
        </div>
        <hr className='headingline'/>
        <div className="Addition_flex" >
            <span >Town</span>
            <span className='Addition_label'>{value.PrimaryTown}</span>
        </div>
        <hr className='headingline'/>
        <div className="Addition_flex" >
            <span >State</span>
            <span className='Addition_label'>{value.PrimaryStateName}</span>
        </div>
        <hr className='headingline'/>
        <div className="Addition_flex" >
            <span >Postcode</span>
            <span className='Addition_label'>{value.PrimaryPostcode}</span>
        </div>
      </div>
    </div>
  </div>
  <div className="col-sm-6" id='loan_details'>
    <div className="card">
      <div className="card-body-sm-12">
      <h6  style={{fontWeight:"bold",color:"#3F51B5"}}>Secondary Address</h6>
        {value && <>
        <div className="Addition_flex" >
            <span >Address</span>
            <span className='Addition_label'>{value.SecondaryAddressLine1}</span>
        </div>
        <hr className='headingline'/>
        <div className="Addition_flex" >
            <span >Landmark</span>
            <span className='Addition_label'>{value.SecondaryLandmark}</span>
        </div>
        <hr className='headingline'/>
        <div className="Addition_flex" >
            <span >Area</span>
            <span className='Addition_label'>{value.SecondaryArea}</span>
        </div>
        <hr className='headingline'/>
        <div className="Addition_flex" >
            <span >Town</span>
            <span className='Addition_label'>{value.SecondaryTown}</span>
        </div>
        <hr className='headingline'/>
        <div className="Addition_flex" >
            <span >State</span>
            <span className='Addition_label'>{value.SecondaryStateName}</span>
        </div>
        <hr className='headingline'/>
        <div className="Addition_flex" >
            <span >Postcode</span>
            <span className='Addition_label'>{value.SecondaryPostcode}</span>
        </div>
       
    </>}
       
      </div>
    </div>
  </div>
</div>


<h6 style={{fontWeight:"bold",color:"#3F51B5"}}>Other Details</h6>
<div className="row" style={{width:"100%"}}>
  <div className="col-sm-6" id='loan_details'>
    <div className="card">
      <div className="card-body-sm-6">
        {value && <>
        <div className="Addition_flex" >
        
        <span >Family Background</span>
            <span className='Addition_label'>{value.FamilyBackground}</span>
        </div>
        <hr className='headingline'/>

        <div className="Addition_flex" >
      
        <span >Annual Income</span>
            <span className='Addition_label'>{value.AnnualIncome}</span>
        </div>
       
        
        <hr className='headingline'/>
        <div className="Addition_flex" >
        <span >Bank Account Type</span>
            <span className='Addition_label'>{value.BankAccountType}</span>
        </div>
       
     
        <hr className='headingline'/>
        <div className="Addition_flex" >
        <span >Bank Name</span>
            <span className='Addition_label'>{value.BankName}</span>
        </div>
        
       
        <hr className='headingline'/>
        <div className="Addition_flex" >
        <span>Branch Name</span>
            <span className='Addition_label'>{value.BranchName}</span>
        </div>
       
    </>}
      </div>
    </div>
  </div>
  <div className="col-sm-6" id='loan_details'>
    <div className="card">
      <div className="card-body-sm-6">
        {value && <>
          <div className="Addition_flex" >
        <span >Employment Type</span>
            <span className='Addition_label'>{value.EmploymentType}</span>
        </div> 
        
        <hr className='headingline'/>

        <div className="Addition_flex" >
        <span >Bank Account Name</span>
            <span className='Addition_label'>{value.BankAccountName}</span>
        </div> 
     
        <hr className='headingline'/>
        <div className="Addition_flex" >
        <span >Bank Detail ID</span>
            <span className='Addition_label'>{value.BankDetailId}</span>
        </div> 
        
        <hr className='headingline'/>
        <div className="Addition_flex" >
        <span >Bank No</span>
            <span className='Addition_label'>{value.BankNo}</span>
        </div> 
      
        <hr className='headingline'/>
        <div className="Addition_flex" >
        <span>IFSC Code</span>
            <span className='Addition_label'>{value.IFSCCode}</span>
        </div> 
        
    </>}
      </div>
    </div>
  </div>
</div>
        </div>
    );
};

export default AllCloudAppCoAppDescription;
