import React from 'react'
import { useFormik } from 'formik';
import TextField from '@material-ui/core/TextField';
import { employeeService } from '../../_services/employee.service';
import { history } from '../../_helpers';


const ChangePassword = () => {
     
    const formik = useFormik({
        initialValues: {
         new_password: '',
         confirm_password: '',
        },
        onSubmit: values => {
          employeeService.updatePassword(values)
          .then((employeeService) => {
            alert("Password Updated Successfully")
            history.push("/home")
          })
          .catch((err)=> {
            window.alert("Data not added")
          })
        }  
      });  
  
    return (
    <>
      <h1 className='my-3'>Update Password</h1>

      <form onSubmit={formik.handleSubmit}>
      <div className='container my-3'>
       <TextField
          fullWidth
          id="new_password"
          name="new_password"
          placeholder='Enter Your New Password'
          value={formik.values.new_password}
          onChange={formik.handleChange}
        />
        </div>

        <div className='container my-3'>
         <TextField
          fullWidth
          id="confirm_password"
          name="confirm_password"
          placeholder='Enter the Password Again'
          value={formik.values.confirm_password}
          onChange={formik.handleChange}
         />
      </div>

      <div>
        <button className='btn btn-primary my-3' type='submit'>Submit</button>
      </div>
        </form>
    </>
  )
}

export default ChangePassword
