import React, { useState } from 'react';
import {
  Modal, Button, Typography, Box, TextField, MenuItem, makeStyles
} from '@material-ui/core';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import  {branchExpenseService} from "../../../_services/branchExpense.service"
import {notifyInfo,notifyFailed}  from "../../../_helpers/notify"
import { ToastContainer } from "react-toastify";
import { ApprovalRequestFilter } from '../../MakerCheckerFramework/ApprovalRequestFilter'
import { employeeService , loanService} from "../../../_services";
import workflowHelper from "../../../_helpers/workflowhelper";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '90%',
    maxWidth: 600,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    maxHeight: '80vh',
    overflowY: 'auto',
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const validationSchema = Yup.object({
  status: Yup.string().required('Status is required'),
  comment: Yup.string().required('Comment is required'),
});

function AccountVerificationBox(params) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
console.log('params of Account verification details',params)
const id = params?.data?._id
const statusdata = params?.data?.status
const data = params?.data

const user = employeeService.userValue

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async(values) => {
    console.log(values);
    if(values.status=="Verified"){
      await ApprovalRequestFilter.RequestFilter({
        service: 'branchExpenseService',
        api: 'verifyBranchExpense',
        id: id,
        task: 'Branch Expense Vrification',
        updated_fields: ["branch_id", "status", "amount", "category","comment"],
        sensitive_fields: [],
        // new_values: [{debitLedgerId:debitLedgerId,creditLedgerId:creditLedgerId,transactionType:transactionType,totalAmount:totalAmount,user:user}],
        new_values: { branch_id: data.branch_id,status:values.status, amount:data.amount, category:data.category,comment:values.comment  },
        old_values: {},
        identification_key: 'category',
        identification_value:params.data.category,
        emp_id: user.emp_id,
        approval_needed: true,
        approved: false,
        approval_access: 'isAccountChecker',
        product: 'sbl',
        remarks: values.remarks
    }).then(async(x) => {
      console.log('resp is',x)
      const acUserData = await loanService.getEmpIdRoleWise({ role: 'AC' }); 
      console.log("AC User Data:", acUserData);
      if (Array.isArray(acUserData) && acUserData.length > 0) { // Ensure acUserData is an array and not empty
        console.log("Calling Redis Helper for sending Notifications");
        await Promise.all(
            acUserData.map((employee) => {
                const emp_id = employee.emp_id; // Extract emp_id for each user
    
                return workflowHelper.isRedisNotifications({
                  emp_id: user.emp_id,
                  message: `You have an Branch Expense Vrification approval request from ${user.emp_id} ,Please review and take necessary action.`,
                  action_link: `https://samruddhi.kaabilfinance.com/home/approvals/list`,
                  action_taken_by: emp_id
              });
            })
          );
        }
            console.log(x)
            alert("Request Sent to concerned Authority")
            window.location.reload()
        })
        .catch((err) => {
            window.alert(err)
            console.log(err)
        })
    }else{

      branchExpenseService.verifyBranchExpense(id,{ "status":values.status,"comment":values.comment})
      .then(res=>{
        console.log('res of verify branch expense is',res)
        if(res && res.status=='success'){
          notifyInfo(`${res.message}`)
        }else{
          notifyFailed(`${res.message}`)
        }
      })
      .catch(err=>{console.log('err of verify branch expense',err)
        notifyFailed(`${err.message}`)
      })
    }
   };

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleOpen}>
       {statusdata && statusdata=="Verified" ? "Paid" : "Verify"}
      </Button>
      <ToastContainer/>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="comments-modal-title"
      >
        <div className={classes.paper}>
          <Typography variant="h5" id="comments-modal-title" gutterBottom>
           Choose status with a comment
          </Typography>

          <Formik
            initialValues={{ status: '', comment: '' }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ handleChange, handleBlur, values }) => (
              <Form>
                <TextField
                  select
                  name="status"
                  label="Status"
                  value={values.status}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  variant="outlined"
                  className={classes.field}
                >
                
                {statusdata && statusdata!="Verified" &&  <MenuItem value="Pending">Pending</MenuItem>}
                 {statusdata && statusdata!="Verified" && <MenuItem value="Rejected">Reject</MenuItem>}
                 { statusdata && statusdata!="Verified" && <MenuItem value="Verified">Verified</MenuItem> }
                 { statusdata && statusdata=="Verified" &&
                   <MenuItem value="Paid">Paid</MenuItem> }
                </TextField>
                <ErrorMessage name="status" component="div" style={{ color: 'red' }} />

                <TextField
                  name="comment"
                  label="Comment"
                  value={values.comment}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  className={classes.field}
                  required
                />
                <ErrorMessage name="comment" component="div" style={{ color: 'red' }} />

                <Box display="flex" justifyContent="flex-end">
                  <Button variant="contained" color="primary" type="submit" className={classes.button}>
                    Submit
                  </Button>
                  <Button variant="contained" color="secondary" onClick={handleClose} className={classes.button}>
                    Close
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
}

export default AccountVerificationBox;
