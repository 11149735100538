import React, { useState, useEffect } from "react";
import { collectionService } from "../../_services/collection.service";
import { employeeService } from "../../_services";
import { Delete } from "@material-ui/icons";
import ProofModal from "./ProofModal";
import { Button } from "@material-ui/core";
import { Modal } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import Reconciliation from "./Reconciliation";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { CSVLink } from "react-csv";
import { receiptService } from "../../_services/receipt.service";
import { Print } from "@material-ui/icons";
import datehelper from "../../_helpers/datehelper";
import roleChecker from "../../_helpers/rolechecker";

function NachPaymentList(props) {
  const [payments, setPayments] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [open, setOpen] = useState(false)
  const [opens, setOpens] = useState(false)
  const [value, setValue] = useState({})
  const [valu, setValu] = useState('')
  const [recon, setRecon]=useState(false)
  const [dateValue, setDateValue]=useState({})
  const [customerPayment, setcustomerPayment] = useState()
  const [receiptNo, setReceiptNo] = useState('')
  const [date, setDate] = useState()
  const user = employeeService.userValue;

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = (fieldKey, fieldValue) => {
    value[fieldKey] = fieldValue
    setValue(value)
    if (fieldKey[0] === 'loan_no') {
      let dates = new Date();
      setDate(dates)
      let year = dates.getFullYear();
      let month = dates.getMonth() + 1;
      let day = dates.getDate();
      let rand = Math.random() * 1000
      let randNum = Math.floor(rand);
      let receipt_no = `${day}${month}${year % 100}${fieldValue}${randNum}`
      setReceiptNo(receipt_no)
    }
    // console.log(receiptNo)
    // console.log(date)
  }


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    height: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
    //paddingBottom: '30px',
  };

  function convert(dateStr) {
    let pDate=new Date(dateStr);
    return datehelper.getPaymentDateString(pDate);
 }

  const handleOpens = (payment) => {
    setOpens(true)
    setcustomerPayment(payment)
  }
  const handleCLoses = () => {
    setOpens(false)
    setcustomerPayment()
  }

  const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    height: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    //overflow: 'scroll',
    //paddingBottom: '30px',
  };

  const handleClick = (e) => {
    e.preventDefault()
    props.history.push(`/home/printreceipt`, { receipt: { ...customerPayment, value:valu } })
  }
  
  

  const handleCreate = (e) => {
    e.preventDefault();
    receiptService.Create({ ...value, date: date,emp_name:user.first_name,receipt_no: receiptNo, mode: 'Cash' }).then((response) => {
      alert("Receipt Generated");
      window.location.reload();
    })
      .catch((err) => {
        alert("An error occured")
        console.log(err)
      })
  }

  const handleDate =(fieldKey, fieldValue)=>{
    dateValue[fieldKey]=fieldValue;
    setDateValue(dateValue)
  }

  const dateCompare=()=>{
    const b= new Date()
    b.setTime(1654194600000)
    // b.setTime(1655317800000)
    // console.log(b)
    let c= b.getTime()
    return c
  }

  const getByDate =()=>{
    console.log(dateValue);
    receiptService.getByDate(dateValue).then((response)=>{
      console.log(response);
      let x=filterReceipts(response);
      setPayments(x)
    })
    .catch((err)=> {
      console.log(err);
    })
  }

  function filterReceipts(rl)
  {
    rl=rl.filter((y)=> {
      if(y.mode && y.mode==="Nach" )
      {
        return true;
      }
      return false;
    })
    if (roleChecker.isCollectionTeam(user.role))
    {
      rl=rl.filter((x) => x.emp_id === user.emp_id)
    }
    else if (roleChecker.isSalesTeamAccess(user.role)) {
      rl=rl.filter((x) => x.employees.length > 0 && x.employees[0].branch_id === user.branch_id);
    }
    return rl;
  }

  useEffect(() => {
    async function retrieveData() {
      await collectionService.fetchPayments().then((x) => {
        console.log(x);
        x=filterReceipts(x);
        setPayments(x);
      }).
      catch((err)=> {
        console.log(err);
      });
    }

    retrieveData();
  }, []);

  const headers = [
    { label: "Date", key: "date" },
    { label: "Receipt Number", key: "receipt_no" },
    { label: "Customer Name", key: "cust_name" },
    { label: "Loan Number", key: "loan_no" },
    { label: "Payment", key: "payment" },
    { label: "Employee", key: "emp_name" },
  ];


  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <form onSubmit={(e) => handleCreate(e)}>
              <label
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  margin: '20px'
                }}
              >
                Customer Name
                <input
                  type="text"
                  placeholder="Customer Name"
                  name="cust_name"
                  maxLength={25}
                  onChange={(e) => handleChange([e.target.name], e.target.value)}
                  style={{
                    width: '200px',
                    height: '30px',
                    borderRadius: '5px',
                    border: '1px solid grey'
                  }} required />
              </label>
              <label
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  margin: '20px'
                }}
              >
                Loan Number
                <input
                  type="text"
                  placeholder="Loan Number"
                  name="loan_no"
                  pattern='[A-Z,0-9,a-z]{1,20}'
                  title="Loan Number can consist maximum 20 alpha numeric digits"
                  onChange={(e) => handleChange([e.target.name], e.target.value)}
                  style={{
                    width: '200px',
                    height: '30px',
                    borderRadius: '5px',
                    border: '1px solid grey'
                  }} required />
              </label>
              <label
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  margin: '20px'
                }}
              >
                Payment
                <input
                  type="number"
                  placeholder="Payment"
                  name="payment"
                  onChange={(e) => handleChange([e.target.name], e.target.value)}
                  style={{
                    width: '200px',
                    height: '30px',
                    borderRadius: '5px',
                    border: '1px solid grey'
                  }} required />
              </label>
              <label
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  margin: '20px'
                }}
              >
                Comment
                <input
                  type="textarea"
                  placeholder="Comment"
                  rows='2'
                  name="comment"
                  onChange={(e) => handleChange([e.target.name], e.target.value)}
                  style={{
                    width: '200px',
                    height: '30px',
                    borderRadius: '5px',
                    border: '1px solid grey'
                  }} />
              </label>
              <button style={{ color: "white", margin: '20px', backgroundColor: '#0066cc', borderRadius: "5px", padding: "5px", border: 'none', width: '150px' }}  >Submit</button>
            </form>
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={opens}
        onClose={handleCLoses}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <form onSubmit={(e) => handleClick(e)} >
              <label style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '20px'
              }} > For
                <select style={{
                  width: '200px',
                  height: '30px',
                  borderRadius: '5px',
                  border: '1px solid grey'
                }} onChange={(e) => setValu(e.target.value)} required>
                  <option value=""></option>
                  <option value="EMI">EMI</option>
                  <option value="Loan Charges">Loan Charges</option>
                </select>
              </label>
              <button style={{ color: "white", margin: '20px', backgroundColor: '#0066cc', borderRadius: "5px", padding: "5px", border: 'none', width: '150px' }}  >Submit</button>
            </form>
          </Typography>
        </Box>
      </Modal>
      <h1>Nach Payments &nbsp;</h1>
      <label>From Date: <input name="from_date" type="date" onChange={(e)=>handleDate([e.target.name], e.target.value)} /></label>
      <label>To Date: <input name="to_date" type="date" onChange={(e)=>handleDate([e.target.name], e.target.value)}  /></label>
      <button style={{ marginLeft: "80px", border: "none", color: "black", backgroundColor: "orange", borderRadius: "5px", padding: "5px", textStyle: "none" }} onClick={()=>getByDate()} >Load Payments</button>
      <Table className="table table-striped" style={{marginTop:'20px'}}>
        <Thead>
          <Tr>
            <Th style={{ width: "10%" }}>Date</Th>
            <Th style={{ width: "20%" }}>Receipt Number</Th> 
            <Th style={{ width: "10%" }}>Customer</Th>
            <Th style={{ width: "10%" }}>Loan No.</Th>
            <Th style={{ width: "10%" }}>Payment</Th>
            <Th style={{ width: "10%" }}>Employee</Th>
            <Th style={{ width: "10%" }}>Comment</Th>
            <Th style={{ width: "15%" }}></Th>
            {/* <Th style={{ width: "10%" }}></Th> */}
            <Th style={{ width: "15%" }}></Th>
          </Tr>
        </Thead>
        {!payments && (<span className="spinner-border spinner-border-sm"></span>)}
        <Tbody>
          {payments &&
            payments.map((payment) => (
              <Tr key={payment._id}>
                {/* {dateCompare(payment.date)} */}
                <Td>{payment.date.split("T")[0]}</Td>
                <Td>{payment.receipt_no}</Td>
                <Td>{payment.cust_name}</Td>
                <Td>{payment.loan_no}</Td>
                <Td>{payment.payment}</Td>
                <Td>{payment.emp_name}</Td>
                <Td>{payment.comment&&<>{payment.comment}</>}</Td>
                {(roleChecker.isHOLevelAccess(user.role) || roleChecker.hasAccountsAccess(user.role) )? (
                <Td>{payment.reconcilelist.length > 0 ? (<b>Reconciled<br/>{payment.reconcilelist[0].loan_account}</b>) :
                new Date(payment.date).getTime()>=dateCompare()  &&<Reconciliation loan_no={payment.loan_no} txn_date={convert(payment.date)} txn_amt={payment.payment} payment_mode={"nach"} reference_id={payment._id} payment_ref={payment.receipt_no} />}</Td>
                ):null}
                <Td>
                  <button style={{ color: "white", backgroundColor: 'green', borderRadius: "5px", padding: "5px", width: '125px', paddingTop: '8px', paddingBottom: '8px', border: 'none' }} onClick={() => handleOpens(payment)}> <Print fontSize="small" /></button>
                </Td>
                {/* <Td style={{ whiteSpace: "nowrap" }}>
                  <ProofModal payment={payment}></ProofModal>
                </Td> */}
                <Td></Td>
              </Tr>
            ))}
          {!payments && (
            <Tr>
              <Td colSpan="8" className="text-center">
                <span className="spinner-border spinner-border-lg align-center"></span>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </div>
  );
}

export { NachPaymentList };
