import React, { useState } from "react";
import { useEffect } from "react";
import PaymentSheetColumns from "./PaymentSheetColumns";
// import PaymentSheetList from "./PaymentSheetList";
import ColenderPaymentSheetList from "./ColenderPaymentSheetList";
import EmptySheet from "./EmptySheet";
import { Button, Typography } from "@material-ui/core";
import { array, date } from "yup";
import { employeeService } from "../../../../_services";
import { misService } from "../../../../_services";
import roleChecker from "../../../../_helpers/rolechecker";
import ColenderSheetColumns from "./ColenderSheetColumns";

export default function ColenderPaymentSheet({
  setFieldValue,pd,loan
}) {
  const assignRef = React.useRef(null);

  const [values, setValues] = useState([]);
  const [open,setOpen] =React.useState(false);
  const [sum, setSum] = React.useState(0)
  const [bank,setBank]=React.useState({})

  // console.log(pd)

  const user = employeeService.userValue;

  useEffect(()=>{
    misService.getBankAccount(loan.bank_details)
    .then((res)=>setBank(res))
    .catch(err=>console.log(err))},
    [])
  const initializeValues = (pd)=>{
    setFieldValue("payment_details", [...pd]);
    setValues([...pd]);
    setOpen(true)
  }
  
  const payment = [];

  const decimalRoundOff = (num) => {
    return Math.round(num * 100) / 100;
  };

  const handleChange = (idx) => (e) => {
    const { name, value } = e.target;

    const newvalues = [...values];
    if(name=="payment_amount")
    {
      newvalues[idx][name] = value;
    
    }
    else
    {
      newvalues[idx][name] = value;
    }
    setFieldValue("payment_details", newvalues);
    // setFieldValue("rpg", 0);
    setValues(newvalues);
  };

  const handleAddRow = () => {
    const item = {
      payment_date: new Date().toISOString().substr(0,10),
      // payment_date: new Date(),
      gold_acc:loan.loan_account,
      customer_name:loan.name,
      bank :bank.bank,
      bank_account:bank.loan_account,
      payment_type: "repayment",
      payment_mode: "online",
      payment_ref: "",
      payment_amount: 0
    };
    console.log(item);
    setFieldValue("payment_details", [...values, item]);
    setValues([...values, item]);
  };

  const handleRemoveSpecificRow = (idx) => () => {
    const newvalues = [...values];

    newvalues.splice(idx, 1);
    setFieldValue("payment_details", newvalues);
    setValues(newvalues);
  };

  pd && !open && initializeValues(pd);

  if (values.length > 0) {
    return (
      <div>
         {
          console.log(bank)
        }
        <div className="col-10 mx-auto text-center text-title">
          <Typography variant="h5">Payment List</Typography>
        </div>
        <div className="row clearfix">
          <div className="col-md-12 column">
            <table className="table table-bordered table-hover" id="tab_logic">
              <thead>
                {/* <PaymentSheetColumns /> */}
                <ColenderSheetColumns/>
              </thead>
              <ColenderPaymentSheetList
                handleRemoveSpecificRow={handleRemoveSpecificRow}
                handleChange={handleChange}
                values={values}
              />
            </table>
            <div style={{ textAlign: "center", margin: 20 }}>
              
              {(roleChecker.hasAccountsAccess(user.role) || roleChecker.isHOLevelAccess(user.role) ) && (<Button variant="outlined" onClick={handleAddRow}>
              Add Item
            </Button>)}
              
             {pd && pd.map((pa) => {
              payment.push(pa.payment_amount)
             })}
            <div style={{float: "right"}}>
              <label>Total Payment</label> 
              <input
              disabled value={eval(payment.join('+'))}
              >     
              </input>
            </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <EmptySheet />
        <div style={{ textAlign: "center", margin: 20 }}>
        {(roleChecker.hasAccountsAccess(user.role) || roleChecker.isHOLevelAccess(user.role))  && (<Button variant="outlined" onClick={handleAddRow}>
              Add Item
            </Button>)}
        </div>
      </div>
    );
  }
}
