import React from 'react';
import { useState } from 'react';
import { Modal } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { reconcile } from '../../_services/reconcile.service';
import { receiptService } from '../../_services/receipt.service';
import { employeeService } from '../../_services';
import datehelper from '../../_helpers/datehelper';

export const ManualReconciliatiion = () => {
    const [open, setOpen] = useState(false)
    // const [receiptNo, setReceiptNo] = useState('')
    const [value, setValue] = useState({
        loan_type: 'Gold',
        reconcile_mode: 'online',
        reconcile_date: datehelper.getPaymentDateString(new Date())
    })
    const user = employeeService.userValue;

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width:650,
        height:500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'scroll',
        //paddingBottom: '30px',
    };

    const handleChange = (fieldKey, fieldValue) => {
        value[fieldKey] = fieldValue
        setValue(value)
    }
    const handleSubmit = (e) => {
        e.preventDefault();


        // const temp = { ...value, reference_id:'' , payment_ref: '' }
        // let total = Number(value.emi) + Number(value.other_charges) + Number(value.principle_amt)
        // console.log(total)


        // if (total == value.reconcile_amt) {
        const temp = { ...value, payment_type: 'collection' }
        console.log(temp);
        let dates = new Date(value.reconcile_date);
        let year = dates.getFullYear();
        let month = dates.getMonth() + 1;
        let day = dates.getDate();
        let rand = Math.random() * 1000
        let randNum = Math.floor(rand);
        let receipt_no = `${day}${month}${year % 100}${value.loan_account}${randNum}`

        reconcile.reconcileData(temp)
            .then((data) => {
                alert("Data Added Successfully")
                receiptService.Create({ cust_name: value.cust_name, loan_no: value.loan_account, payment: value.reconcile_amt, emp_name: user.first_name, date: value.reconcile_date, receipt_no: receipt_no, mode: 'Online' }).then((response) => {
                    alert("Receipt Generated");
                    window.location.reload();
                })
                    .catch((err) => {
                        alert("An error occured")
                        console.log(err)
                    })

            })
            .catch((err) => {
                alert("Something went Wrong", err)
            })

        // } else {
        //     window.alert("Sum of EMI, Principle and Other charges must be equal to Total Amount")
        // }
    }
    return (
        <>
            <button onClick={handleOpen} style={{ color: "white", backgroundColor: 'red', borderRadius: "5px", padding: "5px", width: '200px', paddingTop: '8px', paddingBottom: '8px', border: 'none' }} ><b>Manul Reconciliation</b></button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">

                        <h1>Reconciliation</h1> <hr />


                        <form style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around' }} onSubmit={(e) => handleSubmit(e)} >
                            <label style={{ display: 'flex', flexDirection: 'column' }}>
                                Business:
                                <select name='loan_type' style={{ borderRadius: '6px', height: "38px", width: '250px' }} onChange={(e) => handleChange([e.target.name], e.target.value)} required>
                                    {/* <option value=""></option> */}
                                    <option value="Gold">Gold</option>
                                </select>
                            </label>
                            <label style={{ display: 'flex', flexDirection: 'column' }}>
                                Loan Account:
                                <input type="text" style={{ borderRadius: '6px', width: '250px' }} name='loan_account' onChange={(e) => handleChange([e.target.name], e.target.value)} required />
                            </label>
                            <label style={{ display: 'flex', flexDirection: 'column' }}>
                                Name:
                                <input type="text" style={{ borderRadius: '6px', width: '250px' }} name='cust_name' onChange={(e) => handleChange([e.target.name], e.target.value)} required />
                            </label>

                            <label style={{ display: 'flex', flexDirection: 'column' }}>
                                Date:
                                {
                                    user.role === 'Admin' ?
                                        <input type="date" style={{ borderRadius: '6px', width: '250px' }} name='reconcile_date' onChange={(e) => handleChange([e.target.name], e.target.value)} required />
                                        : <input type="date" style={{ borderRadius: '6px', width: '250px' }} name='reconcile_date' value={value.reconcile_date} onChange={(e) => handleChange([e.target.name], e.target.value)} disabled required />


                                }
                            </label>

                            <label style={{ display: 'flex', flexDirection: 'column' }}>
                                Amount:
                                <input type="number" style={{ borderRadius: '6px', width: '250px' }} name='reconcile_amt' onChange={(e) => handleChange([e.target.name], e.target.value)} required />
                            </label>
                            {/* <label style={{ display: 'flex', flexDirection: 'column' }}>
                                EMI:
                                <input type="number" style={{ borderRadius: '6px', width: '250px' }} name='emi' onChange={(e) => handleChange([e.target.name], e.target.value)} required />
                            </label>
                            <label style={{ display: 'flex', flexDirection: 'column' }}>
                                Principal Amount:
                                <input type="number" style={{ borderRadius: '6px', width: '250px' }} name='principle_amt' onChange={(e) => handleChange([e.target.name], e.target.value)} required />
                            </label>
                            <label style={{ display: 'flex', flexDirection: 'column' }}>
                                Other Charges:
                                <input type="number" style={{ borderRadius: '6px', width: '250px' }} name='other_charges' onChange={(e) => handleChange([e.target.name], e.target.value)} required />
                            </label> */}


                            <div style={{ width: '100%' }}>
                                <button style={{ color: "white", marginLeft: '39%', backgroundColor: 'green', borderRadius: "5px", padding: "5px", width: '125px', paddingTop: '8px', marginTop: '30px', paddingBottom: '8px', border: 'none' }} ><b>Submit</b></button>
                            </div>

                        </form>
                    </Typography>
                </Box>
            </Modal>
            {
                console.log(value)
            }
        </>
    )
}