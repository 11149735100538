import React from "react";
import logo from "../../assets/kaabil.png";
import { virtualAccountService } from "../../_services/virtualaccount.service";
import "./pledgeletter.css";
import datehelper from "../../_helpers/datehelper"

export default class PledgeLetter extends React.Component {
  constructor() {
    super()
    this.state = {
      vaDetails: null
    }
  }

  componentDidMount() {
    console.log("on component mount");
    var fields = {
      'loan_account': this.props.dataFill.loan_account
    }
    virtualAccountService.getByLoanAccount(fields).then((va) => {
      console.log("got va details");
      console.log(va);
      this.setState({ vaDetails: va });
    })
    .catch((err) => {
        console.log(err);
      })


  }

  render() {
    const data = this.props.dataFill;
    const options = [];
    let loan_date = new Date(data.date);
    loan_date.setHours(0,0,0,0)

    let first_date = new Date (loan_date)
    first_date.setDate(first_date.getDate()-1)
   
    console.log("loan date is ");
    console.log(loan_date);

    for (let i = 1; i <= data.period; i++) {
      //  date = date + 2592000000
      //  console.log(date)
      options.push(i)
    }
    console.log("rendering pledge letter..");
    console.log(data);

    const display_date = `${loan_date.getDate()}-${loan_date.getMonth() + 1
      }-${loan_date.getFullYear()}`;

    return (
      <div className="pledge">
        <style type="text/css" media="print">
          {"\
   @page { size: A4; }\
"}
        </style>
        <br />
        <div
          style={{
            width: "290mm",
            height: "400mm",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: "white",
            border: "1px solid black",
            padding: 40,
          }}
          className="d-flex flex-column"
        >
          <div>
            <p style={{ float: "right" }}>{this.props.owner}</p>
            <div style={{ textAlign: "center", marginLeft: "10%" }}>
              <img alt="" style={{ width: 350, height: 100 }} src={logo}></img>
            </div>
          </div>
          <hr />
          <h4 style={{ textAlign: "center" }}>
            <b>Pledge Request Letter</b>
          </h4>
          <br />
          <div
            style={{ width: "100%" }}
            className="d-flex justify-content-between"
          >
            <p>
              To
              <br />
              Kaabil Finance Pvt. Ltd.
              <br />
              Jaipur
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date:{" "}
              {display_date} <br />
              &nbsp;&nbsp;GSTIN/UIN : 08AAGCK6104N1ZA
            </p>
          </div>
          <div>
            <p>
              I {data.name} s/o {data.sonof} residing at {data.address}
              hereby request for a loan of INR {data.loan_request} for a period
              of {data.period} Months against pledge of gold ornaments mentioned
              in the Schedule hereto with approximate value of INR{" "}
              {data.gold_approx} The ornaments described exclusively belong to
              me.
            </p>
            <p>
              The Gold ornaments pledge for the purpose of loan are belong to me
              by way of Self-deceleration (Self-deceleration/Self-possession of
              ornaments/by other means) The purpose of the loan is{" "}
              {data.purpose}.
            </p>
            <p>
              I agree to pay interest, service or other charges as may be fixed
              by the Kaabil Finance Pvt. Ltd. from time to time.
            </p>
          </div>
          <h5 style={{ textAlign: "center" }}>
            <b>Schedule of Gold Pledge</b>
          </h5>
          <div>
            <br />
            <table style={{ width: "100%" }}>
              <tr>
                <th>Description of gold ornaments</th>
                <th>No. of items</th>
                <th>Gross weight(Grams)</th>
                {this.props.owner === "Branch Copy" && (
                  <React.Fragment>
                    <th>Deduction(Grams)</th>
                    <th>Net weight(Grams)</th>
                  </React.Fragment>
                )}
              </tr>
              {data.ornaments.map((item) => (
                <tr>
                  <td>{item.desc}</td>
                  <td>{item.quantity}</td>
                  <td>{item.gross_weight}</td>
                  {this.props.owner === "Branch Copy" && (
                    <React.Fragment>
                      <td>{item.deduction}</td>
                      <td>{item.net_weight}</td>
                    </React.Fragment>
                  )}
                </tr>
              ))}
              <tr>
                <td>&nbsp;</td>
                <td></td>
                <td></td>
                {this.props.owner === "Branch Copy" && (
                  <React.Fragment>
                    <td></td>
                    <td></td>
                  </React.Fragment>
                )}
              </tr>
              <tr>
                <td>Totals</td>
                <td>{data.total_items}</td>
                <td>{data.gross_weight}</td>
                {this.props.owner === "Branch Copy" && (
                  <React.Fragment>
                    <td>{data.deduction}</td>
                    <td>{data.net_weight}</td>
                  </React.Fragment>
                )}
              </tr>
              <tr>
                <td>Valuation as per valuer(INR)</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{data.valuation}</td>
              </tr>
            </table>
          </div>
          <p style={{fontSize:'15.5px'}}>Kotak Group Smart Cash Policy Premium of INR 300 (inclusive GST) per person per year deductible. Refer policy documents for more details.</p>
          <h5 style={{ textAlign: "center", margin: 20 }}>
            <b>For Office Use</b>
          </h5>
          <div className="d-flex justify-content-between">
            <div>
              <p>
                Loan Account No: {data.loan_account}
                <br />
                Scheme Name: ...................
                <br />
                Loan Amount of Rs. {data.loan_amount} for {data.period} Months
              </p>
            </div>
            <div>
              <p>
                Interest Payment Frequency: EVERY_30_DAYS
                <br />
                Processing fees:Rs.{data.pf}
                <br/>
                Policy premium:Rs.{data.insurance_amount}
                <br/>
                Net Disbursement Amount: Rs. {data.disburse_amount}
                <br />
                Interest/EMI Amount: Rs. {data.emi}
              </p>
            </div>
          </div>
          {/* <p style={{ textAlign: "center" }}>
            <b>EMI/ Interest Due date {data.emi_due_date.split("T")[0]}</b>, Due
            date for closure: {data.closure_due_date.split("T")[0]}
          </p> */}
          <div className="d-flex justify-content-between">
            <p>Date:</p>
            <p>Customer Contact: {data.contact.slice(0, -4) + "XXXX"}</p>
          </div>
          <br />
          <br />
          <div className="d-flex justify-content-between">
            <p>Manager/Officer In charge</p>
            <p>
              Kaabil Finance Pvt. Ltd. Head Office Phone number :0141-4005012
            </p>
          </div>
          <div style={{ border: "1px solid black", padding: 5 }}>
            <h6>
              <b>Received Back The Gold Ornaments Pledge:</b>
            </h6>
            <br />
            <br />
            <p>Date:</p>
            <br />
            <br />
            <div className="d-flex justify-content-between">
              <p>Signature of Authorized Person </p>
              <p>Signature of Borrower/Nominee</p>
            </div>
          </div>
          <br />
          <br />
          <p style={{ fontSize: 15 }}>
            The loan shall carry interest @{data.roi}% per month with Penal
            interest upto 4% per month. Penal interest upto 4% per month will be
            charged if the loan/dues are not closed/ repaid within repayment
            frequency of 30 days from the date of pledge or the actual period
            for which the loan is allowed, whichever is earlier. All the terms
            and conditions are explained to me/us and are accepted by me/us.
          </p>
          <br />
          <br />
          <br />
          <h5 style={{ marginBottom: 5 }}>Signature of the Borrower</h5>
        </div>
        <br />
        <div
          style={{
            width: "290mm",
            height: "400mm",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: "white",
            border: "1px solid black",
            padding: 40,
          }}
          className="d-flex flex-column"
        >
          <div style={{ textAlign: "center" }}>
            <img alt="" style={{ width: 350, height: 100 }} src={logo}></img>
          </div>
          <hr />
          <br />
          <div className="d-flex justify-content-around">
            <div
              style={{
                border: "1px dashed black",
                height: 310,
                width: 310,
                padding: 5,
              }}
            >
              <img
                alt=""
                src={data.details.customer_pic}
                height="300"
                width="300"
              ></img>
            </div>
            <div
              style={{
                border: "1px dashed black",
                height: 310,
                width: 310,
                padding: 5,
              }}
            >
              <img
                alt=""
                src={data.details.gold_pic}
                height="300"
                width="300"
              ></img>
            </div>
          </div>
          <br />
          <br />
          <h5>
            <b>TERMS AND CONDITIONS OF GOLD LOAN </b>
          </h5>
          <br />
          <ol type="I">
            <li>
              The loan is repayable on demand within a maximum repayment period
              of 12 months or less as specified.{" "}
            </li>
            <li>
              Penal Interest will be charged as per defined scheme or defined by
              the Kaabil Finance Pvt. Ltd., Jaipur on principal outstanding and interest overdue on
              all overdue accounts.
            </li>
            <li>
              Foreclosure / Renewal changes will be: 1.00% on principal
              outstanding| Auction charges: Rs. 3000| Charges for notice: Rs. 50
              per notice.
            </li>
            <li>
              Gold ornaments and jewellery pledged with the Kaabil Finance Pvt.
              Ltd. shall be kept at the risk of the Borrower in the ordinary
              safes, the NBFC exercising only the ordinary care of the safe
              custody. The NBFC shall not be liable in case of any damage to the
              articles by reason of accident, force majure, act of God etc.
            </li>
            <li>
              Minimum interest for Seven days will be charged on the loan even
              if it is repaid within the 30 days period.
            </li>
            <li>
              The Borrower acknowledges that the rate per gram fixed by the
              Kaabil Finance Pvt. Ltd. for Gold Loan as on date of loan in case
              of downward fluctuations of price or upward movement of rate of
              interest, the Bank/NBFC may demand repayment to cover the margin
              on one week’s notice.
            </li>
            <li>
              Incidental charge, appraiser’s charge or other charges as fixed by
              the Kaabil Finance Pvt. Ltd. from time to time will be levied on
              the loan.
            </li>
            <li>
              If the Borrower has other dues to the Kaabil Finance Pvt. Ltd. as
              principal debtor, co-obligant, guarantor or in any other capacity
              by way of loans, overdrafts or other debts in any manner, unless
              and until such debts, or any other debts that may arise in future
              are fully repaid by the borrower, the jewels pledged under this
              loan will be taken as security for all such debt or debts and will
              be retained by the Kaabil Finance Pvt. Ltd. as security and will
              be dealt with in regard to sale etc, as a pledged commodity.
            </li>
            <li>
              If the loan is not repaid on demand, the jewels pledged will be
              sold in public auction or through private negotiation and the
              borrower will be personally liable for any deficit. If there is
              any surplus available, it will be appropriated by the Kaabil
              Finance Pvt. Ltd. towards any other loan, overdraft or debt due by
              the Borrower as debtor, guarantor or in any other capacity. Such
              sale made by the Kaabil Finance Pvt. Ltd. shall not be disputed by
              the Borrower in any manner whatsoever.
            </li>
            <li>
              Notice in respect of the loan shall be deemed to have been duly
              served if the letter containing the notice of demand is delivered
              to the Borrower in person or is posted properly addressed to the
              address given overleaf or if any change in address has been duly
              notified to the Kaabil Finance Pvt. Ltd., then to such address.
            </li>
            <li>
              The Company shall initiate the auction after giving borrower a
              time of 7 days’ to meet the margin.
            </li>
            <li>
              When the loan is closed and jewels redeemed, discharge should be
              endorsed by the Borrower in the Written application
            </li>
            <li>
              The Kaabil Finance Pvt. Ltd. shall have right to recall the loan
              at any time before the expiry of 12 months or before the
              expiry of the period for which the loan is allowed if it considers
              that interest of the Kaabil Finance Pvt. Ltd. is in jeopardy and
              the decision of the Kaabil Finance Pvt. Ltd. in this regard shall
              be final and binding on the Borrower.
            </li>
            <li>
              The Kaabil Finance Pvt. Ltd. shall have the right to make
              disclosure to Credit Information Bureau of India Ltd. (CIBIL) or
              such other similar agencies.
            </li>
            <li>
              The Kaabil Finance Pvt. Ltd. reserves to itself the right to
              alter, add or delete these rules at any time. For this purpose,
              the Kaabil Finance Pvt. Ltd. will not be required to give separate
              notice to each Borrower and any change in the rules displayed in
              the Notice Board of the Branch of the Kaabil Finance Pvt. Ltd.
              shall be sufficient notice to all the concerned persons.
            </li>
            <li>
            The Kaabil Finance Pvt. Ltd. reserves the right to re pledge the gold ornaments described under this Pledge Request letter to any other financial institution.
            </li>
            <li>
            The Kaabil Finance Pvt. Ltd. shall have the right to enter into co lending arrangement with any other financial institution with respect to gold ornaments pledged
          under this Pledge Request letter.
            </li>
            <li>Kotak Group Smart Cash policy (Hospital Daily Cash Benefit) – As per the policy,
               we will pay the Daily Cash Amount, subject to Deductible, 
               as specified in the Certificate of Insurance under this Benefit for 
               each and every completed day of the Insured Person's Hospitalisation for Inpatient Care during this Policy Period.
                Coverage days – 30; Hospital Daily Cash Benefit – INR 1000 per day; ICU Daily Cash Benefit – INR 2000 per day;
                Personal Accident Benefit (AD/PTD) INR 100000; Maternity Benefit – Included; Policy Type – Individual.</li>
          </ol>
          <br />
          <br />
          <br />
          <br />
          <br />
          <h5 style={{ marginLeft: 37 }}>Signature of the Borrower</h5>
        </div>
        <div
          style={{
            width: "290mm",
            height: "400mm",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: "white",
            border: "1px solid black",
            padding: 40,
          }}
          className="d-flex flex-column"
        >
          {/* <RepaymentSchedule loan={data}/> */}
          <div style={{ textAlign: "center" }}>
              <img alt="" style={{ width: 350, height: 100 }} src={logo}></img>
            </div>
          <hr />
          <h2>Repayment Schedule List</h2><br />
          <div className="container my-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <span> Loan Account: {data.loan_account}</span>   <span style={{ display: "flex", justifyContent: "flex-end" }}> Name:- {data.name}</span>
          </div>
          <div className="container my-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <span>ROI: {data.roi}% </span>    <span>Loan Amount: {data.loan_amount}</span>
          </div> <br />
          <h4>Interest Table</h4>
          <table style={{ borderBottom: "2px solid black" }} >
            <thead>
              <tr>
                <td>Month</td>
                <td  >EMI Amount</td>
                <td  >Date</td>
              </tr>
            </thead>
            <tbody>
              {options && options.map((element) => {
                return <React.Fragment>
                  <tr>
                    <td  >Month {element}</td>
                    <td >{Math.round(data.loan_amount * data.roi / 100)}</td>
                    <td>{new Date(first_date.setDate(first_date.getDate() +30 )).toDateString()}</td>
                  </tr>
                </React.Fragment>
              })}
            </tbody>
          </table>
          {this.state.vaDetails && (
            <div>
              <h2>Payment QR Code</h2>
              <div
                style={{ paddingLeft: '50px', paddingTop: '50px' }}
              >
                <p><b>Account Payee:</b> Kaabil Finance Private Ltd</p>
                <p><b>Bank Name:</b> ICICI Bank</p>
                <p><b>Account Number:</b> {this.state.vaDetails && this.state.vaDetails.va_num}</p>
                <p><b>IFSC Code:</b> ICIC0000106</p>
                {
                  (this.state.vaDetails && (
                    <div><img src={this.state.vaDetails.qrcode_url} alt='QR Code'></img></div>
                  ))
                }
              </div>
            </div>
          )}
          {/* {!this.state.vaDetails.length===0 && (
            <div>
              <br /> 
              {createQRCode()}
            </div>
          )} */}
        </div>
      </div>
    );
  }
}
