import React from 'react'
import { useReactToPrint } from "react-to-print";
import "./Letter.css"
import kaabilLogo from "../../../assets/kaabil.png"
import datehelper from '../../../_helpers/datehelper';
import EmailIcon from '@material-ui/icons/Email';
import CallIcon from '@material-ui/icons/Call';
import LanguageIcon from '@material-ui/icons/Language';
const ForClosureLetter = (props) => {

    const ForClosureLetterRef = React.useRef();

    const handleSubmissionPrint = useReactToPrint({
        content: () => ForClosureLetterRef.current,
        pageStyle: "print"
    });
console.log(props,"propsprops")
    const data = props.location.state;
    
    let total = Number(data.ledger_balance) + Number(data.lpi) + Number(data.interest) + Number(data.document_charges) + Number(data.bounce_charges) + Number(data.foreclosure_charges) + Number(data.gst); 

    let date = new Date();
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct",
        "Nov", "Dec"];
    const yy = date.getFullYear();
    const mm = date.getMonth();
    const dd = date.getDate();

    date = months[mm] + " " + dd + "," + " " + yy;

    return (
        <>

            <div style={{ textAlign: "center" }}>
                <button onClick={handleSubmissionPrint}>Print Letter</button>
            </div>


            <div ref={ForClosureLetterRef}  >
            <img
            src={kaabilLogo}
            style={{ height: "130px" }}
            alt="KFPL Logo"
          />
                <div style={{ marginTop: "50px", textAlign: "justify", marginLeft: "53px", fontSize:"18px" }}>
            
                <div>
                <span>Letter Id: {data.letter_id}</span><br/>
                    <span>Date: { datehelper.displayDate(new Date(data.createdAt)) }</span>
                  
                </div><br />

                <div>
                    To,<br/>
                    <b>{data.name}</b><br />
                    <b>S/o {data.sonof}</b><br />
                    Address:- {data.address}<br />
                    Contact No: {data.contact}<br />
                </div><br />

                <div>
                    <b>Subject: Foreclosure letter for Loan Account No- {data.application_id}</b>
                </div><br />

                <div>Dear Sir/Madam,</div><br />

                <div>
                    As of <b>{ datehelper.displayDate(new Date(data.createdAt)) }</b>, following are the foreclosure charges as per the Signed agreement.
                </div><br />

                <div>
                    <table style={{ width: "60%", border: "1px solid black" }}>
                        <tbody>
                            {data.ledger_balance === "" ? null :
                                <tr>
                                    <td style={{ border: "1px solid black", height:"20px" }}>
                                    <b>Remaining Balance</b>
                                    </td>
                                    <td style={{ border: "1px solid black", height:"20px" }}>
                                    INR. {data.ledger_balance}
                                    </td>
                                </tr>
                            }
                            {data.lpi === "" ? null :
                                <tr>
                                    <td style={{ border: "1px solid black" }}>
                                    <b>Late Payment Interest</b>
                                    </td>
                                    <td style={{ border: "1px solid black" }}>
                                    INR. {data.lpi}
                                    </td>
                                </tr>
                            }
                            {data.interest === "" ? null :
                                <tr>
                                    <td style={{ border: "1px solid black" }}>
                                        <b>Interest</b>
                                    </td>
                                    <td style={{ border: "1px solid black" }}>
                                        INR. {data.interest}
                                    </td>
                                </tr>
                            }
                            {data.document_charges === "" ? null :
                                <tr>
                                    <td style={{ border: "1px solid black" }}>
                                    <b>Documents Ret.Charges</b>
                                    </td>
                                    <td style={{ border: "1px solid black" }}>
                                    INR. {data.document_charges}
                                    </td>
                                </tr>
                            }
                            {data.bounce_charges === "" ? null :
                                <tr>
                                    <td style={{ border: "1px solid black" }}>
                                    <b>Bounce Charges</b>
                                    </td>
                                    <td style={{ border: "1px solid black" }}>
                                    INR. {data.bounce_charges}
                                    </td>
                                </tr>
                            }
                            {data.foreclosure_charges === "" ? null :
                                <tr>
                                    <td style={{ border: "1px solid black" }}>
                                    <b>Foreclosure Charges</b>
                                    </td>
                                    <td style={{ border: "1px solid black" }}>
                                    INR. {data.foreclosure_charges}
                                    </td>
                                </tr>
                            }
                            {data.gst === "" ? null :
                                <tr>
                                    <td style={{ border: "1px solid black" }}>
                                     <b>GST</b>
                                    </td>
                                    <td style={{ border: "1px solid black" }}>
                                    INR. {data.gst}
                                    </td>
                                </tr>
                            }
                           
                                <tr>
                                    <td style={{ border: "1px solid black" }}>
                                    <b>Total</b>
                                    </td>
                                    <td style={{ border: "1px solid black" }}>
                                    INR. {total}
                                    </td>
                                </tr>
                            
                            {data && data.item && data.item !== "" && data.item.itemKey !== "" && data.item.map((e) => (
                                <tr>
                                    <td style={{ border: "1px solid black" }}>{e.itemKey}</td>
                                    <td style={{ border: "1px solid black" }}>{e.itemValue}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
  


                </div><br />

                <div>
                    Please Note that Interest of INR. <b>{data.daily_basis_interest}</b>/- accrues on a daily basis till the date of receipt of full and final payment.
                    DD/Cheque/NEFT/RTGS/IMPS should be in favoring <b>"Kaabil Finance Private Limited "</b>. Once the total
                    outstanding amount is received in our bank account and settled there is TAT of 30 working days for
                    dispatch/handover of Property papers, No Dues letter and refund cheques if any. This letter is valid for 30
                    days from the date of issuance.
                </div><br />

                <div>
                    Should you require any other information/ assistance please call us on our Head office number 0141-4005012 or contact nearby branch head.
                </div><br />

                <div>
                    We look forward to your continued patronage and assure you of our best services at all times.
                </div><br />

                <div>
                    Your's Faithfully,
                </div><br />
                {/* <img src={signature} alt="Signature" style={{height: "130px", marginLeft: "0"}} /> */}
                <div>
                    Authorized Signatory
                </div>
            </div>
            <div className="header-infos">
     <div className="header-top">
       <div>
         <span>
           <strong><EmailIcon/>  care@kaabilfinance.com</strong>
         </span>
       </div>
       <div>
         <span>
           <strong><CallIcon/> 0141-4005012</strong>
         </span>
       </div>
       <div>
         <strong><LanguageIcon/> www.kaabilfinance.com</strong>
       </div>
     </div>
     <div className="header-bottoms" style={{fontSize:"18px"}}>
      
         <p> <strong>Registered & Corporate Office </strong> :- Kaabil Towers, Plot No. 1, Scheme No. 1, Income Tax Colony, Model Town, Jagatpura, Jaipur Rajasthan 302017 CIN No. U65929RJ2017PTC058138 ,GSTIN : 08AAGCK6104N1ZA</p>
         
     </div>
     {/* Fixed bottom footer */}
     
   </div>
            </div>
      
        </>
    )
}

export default ForClosureLetter