import { Paper } from "@material-ui/core";
import React from "react";
import { collectionService, employeeService } from "../../_services";

import { Delete } from "@material-ui/icons";
import PendingModal from "./PendingModal";
import roleChecker from "../../_helpers/rolechecker";


export default function CollectionList() {
  const [loans, setLoans] = React.useState(null);
  const user = employeeService.userValue;
  React.useEffect(() => {
    async function retrieveData() {
      await collectionService.getAll().then((x) => {
        console.log(x);
        if (roleChecker.isCollectionTeam(user.role)) {
          setLoans(x.filter((y) => y.emp_id === user.emp_id))
        }
        else if (roleChecker.isBranchLevelAccess() ) {
          setLoans(x.filter((y) => y.employees.length > 0 && y.employees[0].branch_id === user.branch_id))
        }
        else {
          setLoans(x);
        }

      });
    }

    retrieveData();
  }, []);

  function deleteLoan(id) {
    setLoans(
      loans.map((x) => {
        if (x._id === id) {
          x.isDeleting = true;
        }
        return x;
      })
    );
    collectionService.delete(id).then(() => {
      setLoans((loans) => loans.filter((x) => x._id !== id));
    });
  }

  function deleteAll() {
    if (window.confirm("Are you sure to expire last month collection?")) {
      setLoans(
        loans.map((x) => {
          x.isDeleting = true;
          return x;
        })
      );
      collectionService.deleteCollectionList().then(() => {
        window.alert("Loan Collection data deleted");
        setLoans(null);
      }
      ).catch(error => {
        window.alert("Error deleting list " + error);
      });
    }
  }

  return (
    <Paper
      style={{
        padding: 20,
      }}
    >

      <div className="d-flex justify-content-between"><h3>Current List</h3>
        {
          ((roleChecker.isAdminAccess(user.role)) && (

            <button onClick={() => deleteAll()}>Expire Last Month</button>
          ))}
      </div>
      <table className="table table-striped">
        <caption style={{ captionSide: "top" }}>
          Total Entries - {loans && loans.length}
        </caption>
        <thead>
          <tr>
            <th style={{ width: "10%" }}></th>
            <th style={{ width: "20%" }}>Customer</th>
            <th style={{ width: "20%" }}>Loan No.</th>
            <th style={{ width: "20%" }}>Outstanding Amount</th>
            <th style={{ width: "10%" }}>Emp Id</th>
            <th style={{ width: "10%" }}>Status</th>
            <th style={{ width: "10%" }}>Department</th>
            <th style={{ width: "10%" }}></th>
          </tr>
        </thead>
        <tbody>
          {loans &&
            loans.map((loan, idx) => (
              <tr key={loan._id}>
                <td>{idx + 1}</td>
                <td>{loan.name}</td>
                <td>{loan.loan_no}</td>
                <td>{loan.outstanding_amount}</td>
                <td>{loan.emp_id}</td>
                <td>
                  {loan.status === "Completed" ? (
                    loan.status
                  ) : (
                    <PendingModal loan={loan}></PendingModal>
                  )}
                </td>
                <td>{loan.department}</td>
                <td style={{ whiteSpace: "nowrap" }}>
                  {
                    ((roleChecker.isAdminAccess(user.role)) && (
                      <button
                        onClick={() => deleteLoan(loan._id)}
                        className="btn btn-sm btn-danger"
                        disabled={loan.isDeleting}
                      >
                        {loan.isDeleting ? (
                          <span className="spinner-border spinner-border-sm"></span>
                        ) : (
                          <span>
                            <Delete />
                          </span>
                        )}
                      </button>
                    ))}
                </td>
              </tr>
            ))}
          {!loans && (
            <tr>
              <td colSpan="6" className="text-center">
                <span className="spinner-border spinner-border-lg align-center"></span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Paper>
  );
}
