import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { Button } from "@material-ui/core";
import { employeeService, loanService } from "../../../../_services";

const AddPDSummary = (props) => {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [pd_status,setPDStatus] = React.useState("")
  const [employeeList,setEmployeeList] =React.useState([]);
  const [values, setValues] = React.useState({
    pd_date: "",
    pd_status:  "",
    loan_amount:  "",
    reject_reason:  "",
    pd_emp_id:  ""
  })

  const id = props.customer._id
  let max_date = new Date()
  let dd = max_date.getDate()
  let mm = max_date.getMonth()+1;
  let yy = max_date.getFullYear()
  max_date =yy+ "-" + mm + "-" + dd

  React.useEffect(() => {
    if(open)
    {    
        employeeService.getAll()
        .then((el)=> {
          setEmployeeList(el);
        })
        .catch((err)=> {
          console.log(err);
        });
  }
  }, [open]);


  const handleChange=(e) =>{
    if(e.target.name==="pd_status")
    {
      setPDStatus(e.target.value);
    }
    setValues({...values, [e.target.name]: e.target.value})
  }

  const  watsappMessage=(params)=>{
    console.log('params paramsparams ',params)
      loanService.pdSuccessWatsapp(params)
      .then(res=>console.log(res))
      .catch(err=>console.log(err))
  }

  const handleSubmit = (e) =>{
    e.preventDefault()
    console.log(id,{collection_data:values})
    loanService.update(id, {pd_details:values})
    .then((data)=>{
      console.log('data is',data)
      if(data?.pd_details?.pd_status && data.pd_details.pd_status=="ApprovedNextStage"){
        console.log('yes')
        watsappMessage({application_id:data.application_id})
      }
      alert("Data added Successfully")
      window.location.reload();
    })
    .catch((err)=>{
      console.log(err)   
    })
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "scroll"
  };

  return (
    <div>
      <Button onClick={handleOpen} color="primary" variant="contained">
        Add PD Summary
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h2>PD Summary</h2><br/>
             
            <form onSubmit={(e)=>handleSubmit(e)}>
              <div style={{display:"flex",  justifyContent:"space-between",   }}>
              <label style={{ width: "150px" }}>
                  PD Visit Date
                  <input
                    type={"date"}
                    name="pd_date"
                    max= {max_date}
                    required
                    onChange={(e) => handleChange(e)}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                </label>
                <label style={{ width: "200px" }}>
                  PD Employee:
                  <select
                    name="pd_emp_id"
                    required
                    onChange={(e) => handleChange(e)}
                    >
                      <option value="" disabled selected>Select PD Employee</option>
                      {employeeList && employeeList.map(em=> {
                        return (<option value={em.emp_id}>{em.emp_id+" - "+em.first_name+" "+em.last_name}</option>)
                      })}
                    </select>
                </label>
                </div>
                <div style={{display:"flex",  justifyContent:"space-between",   }}>
              <label style={{ width: "200px" }}>
                  PD Status:
                  <select
                    name="pd_status"
                    required
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="" disabled selected>Select PD Status</option>
                    <option value="ApprovedNextStage">Approved</option>
                    <option value="Rejected">Rejected</option>
                  </select>
                </label>
                {pd_status ==="ApprovedNextStage" && (
                    <label style={{ width: "200px" }}>
                    PD Approved Amount:
                    <input
                      type={"number"}
                      name="loan_amount"
                      min={1}
                      required
                      onChange={(e) => handleChange(e)}
                    />
                    </label>
                )}
                {pd_status==="Rejected" && (
                    <label style={{ width: "200px" }}>
                    PD Rejection Reason:
                    <input
                      type={"text"}
                      name="rejection_reason"
                      required
                      onChange={(e) => handleChange(e)}
                    />
                    </label>
                )}
                </div>
              
                
                <br/>

              <Button color="primary" variant="contained" style={{marginLeft:"45%"}} type="submit" >
                Save Details
              </Button>
            </form>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default AddPDSummary;
