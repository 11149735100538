import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { Button } from "@material-ui/core";
import { loanService } from "../../../../_services";


const AddPaymentDetails = (props) => {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [values, setValues] = React.useState({
    unique_ref_no: "",
    urmn_no:  "",
    bank_customer_name:  "",
    bank_name:  "",
    bank_account_num:  "",
    bank_ifsc_code:  "",
    emi_amount:  "",
    emi_day:  "",
    collection_mode: "",
  })

  const id = props.customer._id
    const data = props.data
  const customer = props.customer
    console.log("data",data)
    console.log('customer',customer)

  const handleChange=(e) =>{
    // console.log('e',e.target.value._id)
    setValues({...values, [e.target.name]: e.target.value})
  }

  const handleCahngeurmn=(e)=>{
    console.log(e.target.value)
    try{
      const selectedObject = JSON.parse(e.target.value);
    
      console.log('selectedObject',selectedObject)
      setValues({
        ...values,
        urmn_no:selectedObject.reference_code,
        unique_ref_no:selectedObject.customer_ref_no,
        bank_customer_name:selectedObject.name,
        bank_name:selectedObject.bank_name,
        bank_account_num:selectedObject.bank_account,
        bank_ifsc_code:selectedObject.ifsc_code,
        emi_amount:customer && customer.loan && customer.loan.emi, 
        emi_day:customer && customer.loan && customer.loan.emi_day
      })
    }
    catch (error) {
      console.error('Error parsing JSON:', error);
      // Handle the error appropriately (e.g., show an error message)
    }
  }
  const handleSubmit = (e) =>{
    e.preventDefault()
    console.log(id,{collection_data:values})
    loanService.update(id, {collection_data:values})
    .then((data)=>{
      alert("Data added Successfully")
      window.location.reload();
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "scroll"
  };

console.log("values",values)

  return (
    <div>
      <Button onClick={handleOpen} color="primary" variant="contained">
        Update Emi Details
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h2>Emi Collection Details</h2><br/>             
            <form onSubmit={(e)=>handleSubmit(e)}>
              <div style={{display:"flex",  justifyContent:"space-between",   }}>
                <label style={{   width:"200px"}}>
                  Collection Mode 
                  {/* <input type="text" value={values.collection_mode} name="collection_mode" onChange={(e)=>handleChange(e)} /> */}
                  <select value={values.collection_mode} onChange={(e)=>setValues({...values,collection_mode:e.target.value})} required>
                  <option value="" disabled>Select Collection Mode</option>
                  <option value={"ICICI ENACH"}>ICICI ENACH</option>
                  <option value={"ICICI NACH"}>ICICI NACH</option>
                  <option value={"AU ENACH"}>AU ENACH</option> 
                  <option value={"AU NACH"}>AU NACH</option>
                  </select>
                </label>
                <label style={{   width:"200px"}}>
                  URMN No
                   {/* <input type="text" name="urmn_no" onChange={(e)=>handleChange(e)} />{" "} */}
                   <select value={values.urmn_no} onChange={(e)=>handleCahngeurmn(e)} required>
                   <option value="" disabled>Select URMN No.</option>
                    {data && data.map((e)=>{
                      console.log(e)
                      const valueString = JSON.stringify(e); // Stringify the entire object
                      if(e && e.enach_status==="Active"){

      return <option key={e.reference_code} value={valueString}>{e.reference_code}</option>;
                      }
                    })}
                   </select>
                </label>
                <label style={{   width:"200px"}}>
                  Unique Ref No <input type="text"  value={values.unique_ref_no} name="unique_ref_no" onChange={(e)=>handleChange(e)}  disabled/>{" "}
                </label>
                
                 
              </div><br/>
              <div style={{display:"flex",  justifyContent:"space-between",   }}>
                <label style={{   width:"150px"}}>
                  Emi Amount <input type="text" value={values.emi_amount} name="emi_amount" onChange={(e)=>handleChange(e)} required/>{" "}
                </label>
                <label style={{   width:"200px"}}>
                  Emi Date <input type="text" value={values.emi_day} name="emi_day" onChange={(e)=>handleChange(e)} required />
                </label>
                <label style={{   width:"200px"}}> 
                  Bank Customer Name <input type="text" value={values.bank_customer_name} name="bank_customer_name" onChange={(e)=>handleChange(e)} disabled/>
                </label>
                 
              </div><br/>
              <div style={{display:"flex",  justifyContent:"space-between",   }}>
                <label style={{   width:"200px"}}>
                  Bank Name <input type="text" value={values.bank_name} name="bank_name" onChange={(e)=>handleChange(e)} disabled/>
                </label>
                 
                <label style={{   width:"200px"}}>
                  Bank Account Num <input type="text" value={values.bank_account_num} name="bank_account_num" onChange={(e)=>handleChange(e)} disabled/>
                </label>
                 
                <label style={{   width:"200px"}}>
                  IFSC Code <input type="text" value={values.bank_ifsc_code} name="bank_ifsc_code" onChange={(e)=>handleChange(e)} disabled/>
                </label>
                 
              </div><br/>

              <Button color="primary" variant="contained" style={{marginLeft:"45%"}} type="submit" >
                Save Details
              </Button>
            </form>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default AddPaymentDetails;
