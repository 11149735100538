import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Fab from "@material-ui/core/Fab";
import SaveIcon from "@material-ui/icons/Save";

import { Button } from "@material-ui/core";
import { misService } from "../../../_services";

// import PaymentSheet from "./PaymentSheet/PaymentSheet"
import ColenderPaymentSheet from "./PaymentSheet/ColenderPaymentSheet";
import roleChecker from "../../../_helpers/rolechecker";



const useStyles = makeStyles((theme) => ({
  modal: {
    width: "75%",
    overflow: "scroll",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    minWidth: 650,
  },
}));

export default function ColenderPaymentModal(props) {
  const loan = props.loan;
  const user = props.user;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const initialValues = {
    colender_payments:[],
  };

  const decimalRoundOff = (num) => {
    return Math.round(num * 100) / 100;
  };
  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function onSubmit(fields, { setSubmitting }) {
    
    misService.updateColenderPayments(loan._id, fields).then((res) => {
      alert("Payment Details Updated. Awaiting Verification.");
      window.location.reload();
    });
    setSubmitting(false);
  }

  return (
    <div>
      <Button style={{color:'primary', border: '2px solid #3760F1',width: '100%' }} onClick={handleOpen} color="primary">
        {
          <span
            style={{
              color: loan.bank_payment_details && loan.bank_payment_details.length >0 ? "green" : "red",
            }}
            data-toggle="tooltip"
            data-placement="bottom"
          >
            &nbsp;●
          </span>
        }
        Co-&nbsp;lender&nbsp;Payment
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Co-lender Payment</h2>
            
              <Formik
                // innerRef={formikRef}
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={onSubmit}
                id="transition-modal-description"
              >
                {({ errors, touched, isSubmitting, setFieldValue }) => {
                  return (
                    <Form>
                      <ColenderPaymentSheet setFieldValue={setFieldValue} pd={loan.bank_payment_details} loan={loan} />
                      <hr />
                      {((roleChecker.hasAccountsAccess(user.role) || roleChecker.isHOLevelAccess(user.role) ) && ( 
                      <div className="row justify-content-around">
                        <Fab
                          type="submit"
                          disabled={isSubmitting}
                          color="primary"
                          aria-label="save"
                        >
                          {isSubmitting && (
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                          )}
                          <SaveIcon />
                        </Fab>
                      </div>
                        ))}
                      <hr/>
                      
                    </Form>
                  );
                }}
              </Formik>
            
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
