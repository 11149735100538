import React, { useState, useEffect } from 'react'
import {sblLetter} from "../../../_services/letter.services"
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import datehelper from "../../../_helpers/datehelper"
import { history } from '../../../_helpers';
import { Button } from '@material-ui/core';
import { Edit } from "@material-ui/icons";
import PrintIcon from '@material-ui/icons/Print';

const CamLetterList = () => {

    const [letter, setLetter] = useState()

    const getLetter = () => {
       sblLetter.getCam()
       .then((data)=>{
        setLetter(data)
        console.log(data)
       })
       .catch((err)=>{
        console.log(err)
       })
    }
    console.log(letter)
  
    useEffect(() => {
      getLetter()
    }, [])

    const print =(element)=>{
        history.push(`/home/sblletters/letter/show/camletter`, element )
      }

    const edit = (element)=>{
      history.push(`/home/sblletters/letter/edit/camletter`, {id: element._id})
    }
    


  return (
    <>
      <h2>Cam Letter List</h2>
      <hr></hr>

      <div>
        <Table className="table table-hover">
          <Thead>
            <Tr>
              <Th style={{ width: "25%" }}>Date</Th>
              <Th style={{ width: "25%" }}>Name</Th>
              <Th style={{ width: "25%" }}>Loan No</Th>
              <Th style={{ width: "15%" }}>Sanction Amount</Th>
              <Th style={{width: "10%"}}>Edit</Th>
              <Th style={{width: "10%"}}>Print</Th>
            </Tr>
          </Thead>
          <Tbody>
            {letter && letter.map((element) => {
              return <Tr key={element._id}>
                <Td className='table-light' style={{ width: "25%" }}>{datehelper.displayDate(new Date(element.createdAt))}</Td>
                <Td className="table-light" style={{ width: "25%" }}>{element.applicant}</Td>
                <Td className="table-light" style={{ width: "25%" }}>{element.application_id}</Td>
                <Td className="table-light" style={{ width: "15%" }}>{element.sanction_amount}</Td>
                <Td style={{width: "10%"}}><Button className='btn btn-primary' onClick={()=>edit(element)} ><Edit /> </Button></Td>
                <Td style={{width: "10%"}}><Button className='btn btn-primary' onClick={()=>print(element)} ><PrintIcon /> </Button></Td>

              </Tr>
            })}

          </Tbody>
        </Table>
      </div>
    </>
  )
}

export default CamLetterList