import React from "react";
import { Button } from "@material-ui/core";
import { employeeService, misService } from "../../_services";
import { useState } from "react";

export default function LoanCancelModal(props) {    
  const loan = props.loan;
  const user= employeeService.userValue; 
  const handleClick =  async() =>{
               await misService.auctionLoan(loan._id, {loan_status: 'Cancelled',cancelled_by_emp_id:user.emp_id})  
                    .then(res => {
                        window.alert('Loan Cancelled');
                        window.location.reload();  
                    })
                    .catch( err => {
                      console.log(err);
                      window.alert("Some Error Occured")
                    })          
            }
  return (
    <div>
       <Button style={{ width: "83%", border: '2px solid #3760F1',marginBottom:'8px' }} onClick={()=>handleClick()} color="primary">
        Cancel&nbsp;Loan
      </Button>

        </div> 
        
  )
}