import React from "react";
import { Paper } from "@material-ui/core";
import { collectionService, employeeService } from "../../_services";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FolderIcon from "@material-ui/icons/Folder";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import { red } from "@material-ui/core/colors";
import Avatar from "@material-ui/core/Avatar";
import printJS from "print-js";
import LoadingOverlay from "react-loading-overlay";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function SearchLoans() {
  const [loan_no, setLoan_no] = React.useState(null);
  const [loans, setLoans] = React.useState(null);
  const [amount, setAmount] = React.useState(null);
  const [spinner, setSpinner] = React.useState(false);
  const user = employeeService.userValue;
  const classes = useStyles();

  const printDoc = async (loan) => {
    if (!amount) {
      alert("Amount cannot be empty.");
      return;
    }

    const data = {
      emp_id: user.emp_id.toString(),
      emp_name:
        user.title +
        " " +
        user.first_name +
        " " +
        user.middle_name +
        " " +
        user.last_name,
      cust_name: loan.name,
      loan_no: loan.loan_no,
      payment: amount,
    };

    setSpinner(true);
    const receipt = await collectionService.pdfReceipt(data);
    setSpinner(false);

    printJS(receipt);
  };

  const textInputChange = (val) => {
    setLoan_no(val);
  };

  async function getLoanDetails(value) {
    if (!value) {
      alert("This field cannot be empty.");
      return;
    }

    setSpinner(true);
    var details = "";
    details = await collectionService.getCollectionDetailsbyLoan(value);
    setSpinner(false);

    if (!details || details.length === 0) {
      alert("Entry Not Found");
    } else setLoans(details);
  }

  return (
    <LoadingOverlay active={spinner} spinner text="Loading">
      <div>
        <Paper
          style={{
            padding: 20,
          }}
        >
          <h3>Search Loan</h3>
          <hr></hr>
          <div className="container d-flex justify-content-center">
            <div>
              <form className={classes.root} noValidate autoComplete="off">
                <TextField
                  id="outlined-basic"
                  label="Loan Number"
                  variant="outlined"
                  value={loan_no}
                  onChange={(e) => textInputChange(e.target.value)}
                />
                <br></br>
                <Button
                  onClick={() => getLoanDetails(loan_no)}
                  variant="contained"
                  color="primary"
                >
                  Find
                </Button>
              </form>
            </div>
          </div>
        </Paper>
        <br></br>
        <Paper
          style={{
            padding: 20,
          }}
        >
          {loans &&
            loans.map((loan, idx) => (
              <Card>
                <CardHeader
                  avatar={
                    <Avatar className={classes.avatar}>
                      <FolderIcon />
                    </Avatar>
                  }
                  action={
                    <div className="d-flex justify-content-end p-2">
                      <div className="d-flex" style={{ marginRight: 10 }}>
                        <p style={{ marginTop: 22, marginRight: 5 }}>₹</p>
                        <TextField
                          onChange={(e) => setAmount(e.target.value)}
                          label="Enter Amount"
                        ></TextField>
                      </div>
                      <Button
                        style={{ height: 35, marginTop: 10 }}
                        onClick={() => printDoc(loan)}
                        variant="contained"
                      >
                        Generate Receipt
                      </Button>
                    </div>
                  }
                  title={loan.name}
                  subheader="Loan Details"
                />
                <CardContent className="d-flex justify-content-around p-2">
                  <Typography variant="h6" gutterBottom>
                    Interest Due - ₹{loan.outstanding_amount}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    Total Due - ₹{loan.closure_amount}
                  </Typography>
                </CardContent>
                {/* <Card.Actions style={{alignSelf: 'center'}}>
            <Button
              onPress={() =>
                navigation.navigate('Generate Reciept', {
                  loan,
                })
              }
              color="#b0243c"
              mode="outlined">
              Generate Reciept
            </Button>
          </Card.Actions> */}
              </Card>
            ))}
        </Paper>
      </div>
    </LoadingOverlay>
  );
}
