import { Button, Paper } from "@material-ui/core";
import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { branchService } from "../../_services/branch.service";
import { collectionService, employeeService } from "../../_services";
import { useEffect, useState } from "react";
import { accountService } from "../../_services/accounts.service";
import CircularProgress from "@material-ui/core/CircularProgress";
import datehelper from "../../_helpers/datehelper";
import TextField from '@material-ui/core/TextField';
import roleChecker from "../../_helpers/rolechecker";


const SblCollectionSummary = (props) => {
  const [branches, setBranches] = useState([]);
  const [collectionList,setCollectionList]=useState([])
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [loading, setLoading] = useState(false);
  const [employeeList, setEmployeeList] = useState({});
  let user = employeeService.userValue;

  async function getAllEmployees() {
    await employeeService.getAll().then((res) => {
      let list = {};
      res.map((e) => {
        list[e.emp_id] = e.first_name + " " + e.last_name;
      });
      setEmployeeList(list);
    });
  }

  useEffect(() => {
    branchService
      .getAll()
      .then((x) => {
        let y = x.filter((b) => {
          if (roleChecker.isBranchLevelAccess(user.role)) {
            if (b.branch_id == user.branch_id) {
              return true
            } else {
              return false
            }
          }
          return true
        })
        setBranches(y);
        if (roleChecker.isBranchLevelAccess(user.role)) {
          setSelectedBranch(user.branch_id)
        }
        console.log(x);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getAllEmployees();
  }, []);

  let branch_code;

  const getData = () => {
    setLoading(true);
    branch_code = selectedBranch;
    collectionService
      .branchCollectionSummary({ branch_id: branch_code})
      .then((x) => {
        console.log(x);
        setCollectionList(x);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    if(roleChecker.isBranchLevelAccess(user.role))
    {
       if(selectedBranch==='All')
       {
         return;
       }
    }
    if(selectedBranch!="")
    {
      getData();
    }
    
  }, [selectedBranch]);

  return (
    <div>
      <Paper style={{ textAlign: "center" }}>
        <h2>SBL Collection Summary</h2>
      </Paper>
      <br />

      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "row",
          margin: "auto",
        }}
      >
        <InputLabel id="demo-simple-select-label">Select Branch</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedBranch}
          onChange={(e) => setSelectedBranch(e.target.value)}
          style={{ width: "200px" }}
        >
          {roleChecker.hasAccountsAccess(user.role) ? <MenuItem value="All">
            All Branches
          </MenuItem> : null}

          {branches.map((branch) => (
            <MenuItem key={branch._id} value={branch.branch_id}>
              {branch.branch_id} - {branch.branch_name}
            </MenuItem>
          ))}
        </Select>
        </div>
      <hr style={{ border: "1px solid" }} />

      {loading ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <h2>Collection Pending Cases</h2>
          <hr style={{ border: "1px solid" }} /> <br />
          {collectionList && collectionList.length > 0 ? (
            <table>
              <thead>
                <th>Loan Number</th>
                <th>Customer Name</th>
                <th>Branch Name</th>
                <th>Collection Executive</th>
                <th>Outstanding Amount</th>
                <th>Collection Status</th>
                <th>Amount Collected</th>
                <th>Collection Bucket</th>
                <th>Field Visit count</th>
                <th>Action</th>
              </thead>
              <tbody>
                {collectionList.map((l) => {
                  return (
                    <tr key={l._id}>
                      <td>{l.loan_no}</td>
                      <td>
                        {l.name}
                      </td>
                      <td>{l.branch_id}</td>
                      <td>{l.employees && l.employees.length>0 && ( <spa>
                          {l.employees[0].first_name} {l.employees[0].last_name} 
                      </spa>)}</td>
                      <td>
                        {l.outstanding_amount}
                      </td>
                      <td>
                        {l.status}
                      </td>

                      <td>
                        {l.collected_amount}
                      </td>
                      <td>
                        {l.bucket}
                      </td>
                      <td>
                        {l.field_visit_count}
                      </td>
                      <td>
                      <button style={{ borderRadius: '8px', border: 'none', color: 'white', backgroundColor: 'blue' }} onClick={() => props.history.push(`/home/sblloans/searchloans?loan_account=` + l.loan_no)}>Search Loan</button>
                      </td>
                      
                      
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <h4 style={{ textAlign: "center" }}>NO Data Found</h4>
          )}
        </>
      )}
      <br />

    </div>
  );
};

export default SblCollectionSummary;
