import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import * as Yup from "yup";

import { Formik, Field, Form, ErrorMessage } from "formik";

import Fab from "@material-ui/core/Fab";
import SaveIcon from "@material-ui/icons/Save";
import { Button } from "@material-ui/core";
import {loanService}  from "../../../_services/loan.service";
import {adminservice}  from "../../../_services/admin.services";

import { LoanStages } from "../../../constants/loanstages";
import { employeeService } from "../../../_services";
import { OperationSubStages } from "../../../constants/operationsubstages";
import { RejectSubStages } from "../../../constants/rejectsubstages";
import { SanctionSubStages } from "../../../constants/sanctionsubstages";
import { DisbursalSubStages } from "../../../constants/disbursalsubstages";
import { CreditSubStages } from "../../../constants/creditsubstages";
import workflowHelper from "../../../_helpers/workflowhelper";
import roleChecker from "../../../_helpers/rolechecker";
import LoadingOverlay from "react-loading-overlay";
import { ApprovalRequestFilter } from "../../MakerCheckerFramework/ApprovalRequestFilter";
import { customerService } from "../../../_services";
const useStyles = makeStyles((theme) => ({
  modal: {
    width: "50%",
    overflow: "scroll",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    minWidth: 650,
  },
}));

export default function EditStageModal(props) {
  console.log("props cuts is",props)
  const loanData = props.loan_data;
  let approval_needed;
  { loanData.stage === 'Collection' ? approval_needed = true : approval_needed = false }
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const user = employeeService.userValue;
  const [stage,setStage]=React.useState(loanData.stage);
  const [customer,setCustomer] =React.useState(null);
  const [nextStage, setNextStage] = React.useState("");
  const [employeeList,setEmployeeList] =React.useState([]);
  const [fEmployeeList,setFEmployeeList] =React.useState([]);
  const [stages,setStages] = React.useState([]);
  const [warningMessage,setWarningMessage]=React.useState("");
  const [subStages,setSubStages] = React.useState([]);
  const opsSubStages=[OperationSubStages.FileReceived,OperationSubStages.FileChecked,OperationSubStages.PendencySentToBranch,OperationSubStages.PendencyReceivedFromBranch,OperationSubStages.NonBranchOtherPendency,OperationSubStages.PendencyClear,OperationSubStages.SentToOpsTeam,OperationSubStages.ColendingPending, OperationSubStages.PropertyPapersAtHO];
  const rejectSubStages=[RejectSubStages.KycFraud,RejectSubStages.DocumentNotAvailable,RejectSubStages.PropertyPaperFraud,RejectSubStages.CustomerNotInterested, RejectSubStages.CaseNotSuitable,RejectSubStages.LoginFeesNotReceived,RejectSubStages.Other];
  const disbursalStages=[DisbursalSubStages.DisbursalVerification,DisbursalSubStages.DisbursalPaymentPending, DisbursalSubStages.ColenderPaymentPending];
  const sanctionStages=[SanctionSubStages.SanctionLetterPending,SanctionSubStages.SanctionLetterVerification, SanctionSubStages.SanctionLetterSentToBranch,SanctionSubStages.SanctionLetterIMDPending,SanctionSubStages.AddColender]; 
  const creditStages=[CreditSubStages.CreditFileAssigned, CreditSubStages.ApplicationFormSigningPending,CreditSubStages.CAMPending,CreditSubStages.ChequePending,CreditSubStages.DocumentCollectionPending,CreditSubStages.ENachPending,CreditSubStages.FCUReportPending,CreditSubStages.FIReportPending,CreditSubStages.FileBeingSentToHO,CreditSubStages.GuarantorAdditionPending,CreditSubStages.HOApprovalPending,CreditSubStages.IMDandPropertyPaperPending, CreditSubStages.LegalReportPending,CreditSubStages.ValuationReportPending]; 
  const discardedSubStages=[RejectSubStages.DuplicateFile,RejectSubStages.LoginFeesNotReceived,RejectSubStages.DemoLoan,RejectSubStages.Other];
  const refreshParent=props.refreshParent;
  const [negativePinCode,SetNegativePinCode] = React.useState([])
const [applicantData,setApplicantData]=React.useState()
  const changeSubStages=(stage,customer)=> {
    console.log("changing sub stages changed:"+stage);
    if(customer)
    {
         console.log("customer")
         console.log(customer)
        if(stage==LoanStages.Credit )
        {
          if(workflowHelper.isPDVisited(customer))
          {
            if(workflowHelper.isLoginFeesReceived(customer))
            {
              if(workflowHelper.isKYCVerified(customer))
              {
                setSubStages(creditStages);
              }
              else
              {
                setSubStages([CreditSubStages.KYCPending])
              }
                 
            }
            else
            {
              setSubStages([CreditSubStages.LoginFeesPending])
            }
            
          }
          else
          {
            setSubStages([CreditSubStages.PDVisitPending])
          }
       
     }
     if(stage==LoanStages.Sanction )
     {
       setSubStages(sanctionStages);
     }
     if(stage==LoanStages.Operation)
     {
       setSubStages(opsSubStages);
     }
     if(stage==LoanStages.Disbursal)
     {
       setSubStages(disbursalStages);
     }
     if(stage==LoanStages.Rejected )
     {
       setSubStages(rejectSubStages);
     }
     if(stage==LoanStages.Discarded )
     {
       setSubStages(discardedSubStages);
     }
       let fel=employeeList;
      //  if(stage && (stage===LoanStages.Colending || stage===LoanStages.Disbursal || stage===LoanStages.Collection))
      //  {
      //    fel=fel.filter((e)=> {
      //      if(roleChecker.isHOLevelAccess(user.role))
      //      {
      //        return true;
      //      }
      //      else
      //      {
      //        return false;
      //      }
      //    })
      //  }
       if(stage &&  (stage===LoanStages.Operation  || stage===LoanStages.Disbursal || stage===LoanStages.Collection || stage===LoanStages.Colending ) ){
        fel=fel.filter((e)=> {
          if(  ["COL","COLA","HOO","HOOA","AC","COLM"].includes(e.role))
          {
            return true;
          }
          return false})

       }
       if(stage &&  stage===LoanStages.Sanction  ){
         fel=fel.filter((e)=> {
          console.log("loanData,e",loanData,e)
          if(roleChecker.isHOCreditTeamAccess(e.role) ||  ["COL","COLA","COLM"].includes(e.role))
          {
            return true;
          }
          return false})

       }
       if(stage &&  stage===LoanStages.Credit  )
       {
         fel=fel.filter((e)=> {
          
           if(roleChecker.isHOCreditTeamAccess(e.role) || roleChecker.isCreditTeamAccess(e.role))
           {
             return true;
           }
        //    if (
        //     (loanData.branches[0].branch_id == e?.branch_id || 
        //      loanData.branches[0].branch_area == e?.branch_id || 
        //      loanData.branches[0].branch_region == e?.branch_id || 
        //      loanData.branches[0].branch_zone == e?.branch_id || 
        //      loanData.branches[0].branch_cluster == e?.branch_id) && 
        //      roleChecker.isCreditTeamAccess(e.role)
        // ) 
        //    {
        //        return true;
        //    }
           return false;
         })
       }
       if(stage && stage===LoanStages.Sales)
       {
         fel=fel.filter((e)=> {
           
           if(roleChecker.isSalesTeamAccess(e.role))
           {
               return true;
           }
        //    if (
        //     (loanData.branches[0].branch_id == e?.branch_id || 
        //      loanData.branches[0].branch_area == e?.branch_id || 
        //      loanData.branches[0].branch_region == e?.branch_id || 
        //      loanData.branches[0].branch_zone == e?.branch_id || 
        //      loanData.branches[0].branch_cluster == e?.branch_id) && 
        //      roleChecker.isSalesTeamAccess(e.role)
        // ){
        //   return true;
        // }
           return false;
           
         })
         console.log('fel',fel)
       }
       console.log("employeeList",employeeList);
       console.log(fel);
       if(fel && fel.length>0)
       {
         setFEmployeeList(fel);
       }
       
    }

  } 



  React.useEffect(() => {
    if(open)
    {
      let applicantdata,nagetivepincode;

      customerService.searchCustomer({'customer_id':props.loan_data.applicant.customer_id})
      .then(res=>{console.log('customer data is',res);
      if(res && res.length>0){
        setApplicantData(res[0])
        applicantdata=res[0]
      }
      })     
      .catch(err=>console.log('err',err))

      adminservice.getNegativePinCode()
      .then(res=>{console.log('res',res); SetNegativePinCode(res);nagetivepincode=res})
      .catch(err=>console.log('err',err))
      
      console.log("Is Open called: "+open);   

      employeeService.getAll()
          .then((el)=> {
        setEmployeeList(el);
        setFEmployeeList(el);
      })
      .catch((err)=> {
         console.log("unable to get employee list");
      })
   
console.log(' applicantdata,nagetivepincode', applicantdata,nagetivepincode)
      loanService.getById(loanData._id).then((loanObj)=> 
      {
        setCustomer(loanObj);     
              console.log('loanObj',loanObj)
              if(loanData.stage==LoanStages.Sales )
                {
                  if(workflowHelper.isApplicantTwoKycImageDone(loanObj)){
                      console.log("isTwoKycDoneForApplicant",workflowHelper.isTwoKycDoneForApplicant(loanObj))
                      if(workflowHelper.isTwoKycDoneForApplicant(loanObj)){
                        if(workflowHelper.isOneCoApplicant(loanObj)){
                          if(workflowHelper.isCoapplicantTwoKycImageDone(loanObj)){
                            if(workflowHelper.isTwoKycDoneForcoapplicant(loanObj)){
                              if(workflowHelper.isBmlevelvarification(loanObj)){
                                if(workflowHelper.isLoginFeesReceived(loanObj))
                                {
                                    if(workflowHelper.isOTPVerified(loanObj))
                                    {
                                      if(roleChecker.isGMLevelAccess(user.role) ||  roleChecker.isZSMLevelAccess(user.role)) 
                                      {
                                        setStages([LoanStages.Sales,LoanStages.Credit,LoanStages.Rejected,LoanStages.Discarded,LoanStages.Suspense]);  
                                      }
                                      else
                                      {
                                        setStages([LoanStages.Sales,LoanStages.Credit]);
                                      }
                                      
                                    }
                                    else{
                                      setWarningMessage("OTP not verified");
                                      if(roleChecker.isGMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role))
                                      {
                                        setStages([LoanStages.Sales,LoanStages.Rejected,LoanStages.Discarded,LoanStages.Suspense]);  
                                      }
                                      else
                                      {
                                        setStages([LoanStages.Sales]);
                                      }
                                      
                                    }
                                }
                                else
                                {
                                    if(roleChecker.isGMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role))
                                    {
                                        setStages([LoanStages.Sales,LoanStages.Rejected, LoanStages.Discarded,LoanStages.Suspense])
                                    }
                                    else
                                    {
                                      setStages([LoanStages.Sales])
                                  
                                    }
                                    setWarningMessage("Login fees not received");
                                }
                              }
                              else{
                                if(roleChecker.isGMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role))
                                {
                                  setStages([LoanStages.Sales,LoanStages.Rejected,LoanStages.Discarded,LoanStages.Suspense]);  
                                }
                                else
                                {
                                  setStages([LoanStages.Sales]);
                                }                            
                                setWarningMessage("Please Enter  Loan Amount,Tenure,Emi,Income and Property Value ");
                              }
                            
                             
                          }
                          else{
                            if(roleChecker.isGMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role))
                                      {
                                        setStages([LoanStages.Sales,LoanStages.Rejected,LoanStages.Discarded,LoanStages.Suspense]);  
                                      }
                                      else
                                      {
                                        setStages([LoanStages.Sales]);
                                      }
                                setWarningMessage("Add Either Pan Card Number or Voter Id for atleast One Co-Applicant");
                          }
                        }
                        else{
                          if(roleChecker.isGMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role))
                          {
                              setStages([LoanStages.Sales,LoanStages.Rejected, LoanStages.Discarded,LoanStages.Suspense])
                          }
                          else
                          {
                            setStages([LoanStages.Sales])
                        
                          }
                          setWarningMessage("Minimum Two KYC Images for atleast one Co-Applicant");
                        }
                        }
                        else{
                          if(roleChecker.isGMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role))
                                      {
                                        setStages([LoanStages.Sales,LoanStages.Rejected,LoanStages.Discarded,LoanStages.Suspense]);  
                                      }
                                      else
                                      {
                                        setStages([LoanStages.Sales]);
                                      }
                                setWarningMessage("Add minimum 1 co-applicant");
                        }
                      }
                      else{
                        if(roleChecker.isGMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role))
                                      {
                                        setStages([LoanStages.Sales,LoanStages.Rejected,LoanStages.Discarded,LoanStages.Suspense]);  
                                      }
                                      else
                                      {
                                        setStages([LoanStages.Sales]);
                                      }
                                setWarningMessage("Add Either Pan Number or Voter Id for Applicant");
                      }
                    
                  }
                  else{
                    if(roleChecker.isGMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role))
                    {
                        setStages([LoanStages.Sales,LoanStages.Rejected, LoanStages.Discarded,LoanStages.Suspense])
                    }
                    else
                    {
                      setStages([LoanStages.Sales])
                  
                    }
                    setWarningMessage("Applicant's Minimum Two KYC Images (Front/Back) Required.");
                  }
                  if(roleChecker.isGMLevelAccess(user.role))
                  {
                      setStages([LoanStages.Sales,LoanStages.Credit,LoanStages.Rejected, LoanStages.Discarded,LoanStages.Suspense])
                  }
            }
            if(loanData.stage==LoanStages.Suspense)
            {
              setStages([LoanStages.Suspense]);
            }
            if(loanData.stage==LoanStages.Credit )
            {
              console.log( "wagsdv",nagetivepincode,applicantdata,applicantdata?.personal_details?.permanent_address?.pin_code,nagetivepincode.includes(applicantdata?.personal_details?.permanent_address?.pin_code))

              if(workflowHelper.isKYCVerified(loanObj))
              {
                if(workflowHelper.isEMDReceived(loanObj))
                {
                  if(workflowHelper.isOneFemaleCoApplicant(loanObj))
                  {
                     if(workflowHelper.isOneGuarantor(loanObj))
                     {
                          if(workflowHelper.isCibilChecked(loanObj))
                          {
                                if(workflowHelper.isApplicationFormDone(loanObj))
                                {
                                  if(workflowHelper.isCamLetterDone(loanObj))
                                  {
                                       
                                    if(!nagetivepincode.includes(applicantdata?.personal_details?.permanent_address?.pin_code) || loanObj?.applicant?.general_information?.ispinCodeVerified){
                                      if(workflowHelper.isFileReceivedInHO(loanObj) || true)
                                      {
                                         if(workflowHelper.isLegalValuationDone(loanObj))
                                         {
                                            setStages([LoanStages.Credit,LoanStages.Operation,LoanStages.Sanction,LoanStages.Disbursal,LoanStages.Rejected]);
                                         }
                                         else
                                         {
                                            setWarningMessage("Legal Valuation not done");
                                            setStages([LoanStages.Credit,LoanStages.Sanction,LoanStages.Rejected]);
                                         }
                                      }
                                      else
                                      {
                                        setWarningMessage("File not received at HO");
                                        setStages([LoanStages.Credit,LoanStages.Sanction,LoanStages.Rejected]);
                                      }
                                    }
                                    else{
                                      setWarningMessage("Pin Code Need to verify by GM");
                                      setStages([LoanStages.Credit, LoanStages.Rejected]);
                                    }

                                      

                                  }
                                  else
                                  {
                                    setWarningMessage("CAM letter not created");
                                    setStages([LoanStages.Credit,LoanStages.Rejected]);
                                  }

                                }
                                else
                                {
                                  setWarningMessage("Application Forms not filled");
                                  setStages([LoanStages.Credit,LoanStages.Rejected]);
                                }
                          }
                          else
                          {
                              setWarningMessage("CIBIL not checked.");
                              setStages([LoanStages.Credit,LoanStages.Rejected]);
                          }
                    }
                    else
                    {
                      setWarningMessage("Guarantor not added.");
                      setStages([LoanStages.Credit,LoanStages.Rejected]);
                    }
                 }
                 else
                 {
                    setWarningMessage("Female CoApplicant not added");
                    setStages([LoanStages.Credit,LoanStages.Rejected]);
                 }
                }
                else{
                setWarningMessage("IMD not received");
                  setStages([LoanStages.Credit,LoanStages.Rejected]);
                }
             }
             else
             {
                setWarningMessage("KYC not verified");
                  setStages([LoanStages.Credit,LoanStages.Rejected]);
             }
             if(roleChecker.isGMLevelAccess(user.role))
             {
                 setStages([LoanStages.Sanction,LoanStages.Credit,LoanStages.Rejected, LoanStages.Discarded,LoanStages.Suspense])
             }
            }
            if(loanData.stage==LoanStages.Sanction )
            {
              if(workflowHelper.isFileReceivedInHO(loanObj))
              {
                console.log('workflowHelper.isColenderAdded(loanObj)',workflowHelper.isColenderAdded(loanObj))
                if(workflowHelper.isColenderAdded(loanObj)){
                  setStages([LoanStages.Credit,LoanStages.Operation,LoanStages.Sanction,LoanStages.Disbursal,LoanStages.Rejected]);
                }else{
                  setWarningMessage("Add atleast one colender");
                  setStages([LoanStages.Credit,LoanStages.Sanction,LoanStages.Rejected]);   
                }
              }
              else
              {
                setWarningMessage("File not received at HO");
                setStages([LoanStages.Credit,LoanStages.Sanction,LoanStages.Rejected]);   
              }

            }
            if(loanData.stage==LoanStages.Closed)
            {
              setStages([LoanStages.Closed])
            }
            if(loanData.stage==LoanStages.Operation)
            {
              setStages([LoanStages.Operation,LoanStages.Credit,LoanStages.Sanction, LoanStages.Disbursal,LoanStages.Rejected])
            }
            if(loanData.stage==LoanStages.Collection)
            {
              if(roleChecker.isGMLevelAccess(user.role))
              {
                setStages([LoanStages.Collection,LoanStages.Disbursal,LoanStages.Closed])
              }
              else if(roleChecker.isAccountChecker(user.role))
              {
                setStages([LoanStages.Collection,LoanStages.Closed])
              }
              else
              {
                if(roleChecker.isHOLevelAccess(user.role)){
                  setStages([LoanStages.Collection,LoanStages.Disbursal])
                }
                else
                {
                  setStages([LoanStages.Collection])
                }
              }
              
            }
            if(loanData.stage==LoanStages.Disbursal)
            {
              setStages([LoanStages.Disbursal,LoanStages.Operation])
            }
            if(loanData.stage==LoanStages.Rejected )
            {
              setStages([LoanStages.Rejected,LoanStages.Credit,LoanStages.Operation])
            }
            if(loanData.stage==LoanStages.Discarded )
            {
              setStages([LoanStages.Rejected,LoanStages.Discarded,LoanStages.Credit,LoanStages.Operation])
            }
            console.log(stages);            
           
            changeSubStages(loanData.stage,loanObj);
            setIsLoading(false);
            
          
        })
        .catch((err)=> {
          window.alert("Unable to fetch Loan");
        })
  }

  }, [open]);

  
  React.useEffect(() => {
      changeSubStages(stage,customer);
  }, [stage,employeeList,applicantData]);


  React.useEffect(() => {
    try{
      let fel=employeeList;
      if(stage && (stage===LoanStages.Colending || stage===LoanStages.Disbursal || stage===LoanStages.Collection))
      {
        fel=fel.filter((e)=> {
          if(roleChecker.isHOLevelAccess(user.role))
          {
            return true;
          }
          else
          {
            return false;
          }
        })
      }
      if(stage && (stage===LoanStages.Credit ||  stage===LoanStages.Sanction || stage===LoanStages.Operation))
      {
        fel=fel.filter((e)=> {
          if(roleChecker.isHOLevelAccess(e.role) || roleChecker.isCreditTeamAccess(e.role))
          {
            return true;
          }
          if(customer.branch_id === e.branch_id && roleChecker.isBranchLevelAccess(e.role))
          {
              return true;
          }
          return false;
        })
      }
      if(stage && stage===LoanStages.Sales)
      {
        fel=fel.filter((e)=> {
          
          if(customer.branch_id === e.branch_id && roleChecker.isSalesTeamAccess(e.role))
          {
              return true;
          }
          return false;
          
        })
      }
      if(fel && fel.length>0)
      {
        setFEmployeeList(fel);
      }

    }
    catch(err)
    {
       console.log(err)
    }
}, [customer]);


  const initialValues = {
    next_stage_emp_id: "",
    stage:loanData.stage,
    sub_stage:'',
    comments: "",
  };


  const validationSchema = Yup.object().shape({
    next_stage_emp_id: Yup.string().required("This field is required"),
    stage:Yup.string().required("This field is required"),
  });
  
  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  function handleChange(fieldName,value,setFieldValue)
  {
    if(fieldName==="next_stage_emp_id"){
      let newValue=parseInt(value);
      setFieldValue(fieldName,newValue);
    }
    if(fieldName==="stage")
    {
        setFieldValue(fieldName,value);
        setStage(value);
        let tmpStage=value;
        console.log("tmp stage is "+tmpStage);
        if(tmpStage=== LoanStages.Credit)
        {
           setSubStages(creditStages);
        }
        if(tmpStage === LoanStages.Operation)
        {
           setSubStages(opsSubStages);
        }
        if(tmpStage === LoanStages.Sanction)
        {
           setSubStages(sanctionStages);
        }
    }

  }
   
  
  async function onSubmit(fields, { setSubmitting }) {
    let params={...fields,
                updated_by_emp_id:user.emp_id,
                }
    console.log(params);
     // maker checker framework
     await ApprovalRequestFilter.RequestFilter({
      service: 'loanService',
      api: 'changeStage',
      id: loanData._id,
      task: 'Stage Change',
      updated_fields: ['stage'],
      sensitive_fields: [],
      new_values: params,
      old_values: {
        stage: loanData.stage,
      },
      identification_key: 'Loan Number',
      identification_value: loanData.application_id,
      emp_id: user.emp_id,
      approval_needed: approval_needed,
      approved:false,
      approval_access:'isAccountChecker',
      product: 'sbl'
    })
      .then((res) => {
        console.log("Calling Redis Helper for sending Notifications")
        workflowHelper.isRedisNotifications({emp_id: user.emp_id, message:`Loan Application ID: ${loanData.application_id} - Stage updated by ${user.emp_id} and assigned to you. Please review and take necessary action.`, action_link:`https://samruddhi.kaabilfinance.com/home/sblloans/details/${loanData._id}`, action_taken_by: params.next_stage_emp_id})
        if (approval_needed === true) {
          window.alert('Request sent to concerned authority');
        } else {
          window.alert('Stage changed')
        }
        if (refreshParent) {
          handleClose();
          refreshParent();

        }
        else {
          window.location.reload();
        }
      })
      .catch((err) => {
        window.alert(err)
        console.log(err)
      })
            
    // await loanService.changeStage(loanData._id,params)
    // .then((ns)=> {
    //   console.log("stage changed");
    //   window.alert("Stage changed");
    //   if(refreshParent)
    //   {
    //     handleClose();
    //     refreshParent();
        
    //   }
    //   else
    //   {
    //     window.location.reload();
    //   }
    // })
    // .catch((err)=> {
    //   console.log(err);
    //   window.alert("error changing stage");
    // })
    setSubmitting(false);
  }
console.log('negativePinCode',negativePinCode)
console.log("applicantData",applicantData)
  return (
    <div>
         {loanData.stage==="Rejected" ? <Button

          onClick={() => {
    if (window.confirm("Are you sure! you want to reopen this loan?")) {
      handleOpen();
    }
  }}
          variant="outlined"
          color="primary"
          >
         Reopen
          </Button> : <Button
          onClick={handleOpen}
          variant="outlined"
          color="primary"
          >
          Edit Stage
          </Button>}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
         
        <LoadingOverlay active={isLoading} spinner text="Loading Stages...">
        
          <div className={classes.paper}>
          {!isLoading && (
             <>
            <h2 id="transition-modal-title">Change Loan Status</h2>
      
              <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize={true}
                id="transition-modal-description"
              >
              {({ errors, touched, isSubmitting, setFieldValue,values }) => {
                  return (
                    <Form>
                      <div className="form-row">
                      <div className="form-group col">
                      <p>Loan Stage:</p>
                        <Field
                          as="select"
                          className="form-control" 
                          name="stage"
                          label="Loan Stage"
                          onChange={(e)=> handleChange("stage",e.target.value,setFieldValue)}
                        >
                          <option value="" disabled>Select Loan Stage</option> 
                          { stages && stages.map((stage,idx)=> (
                            <option value={stage} key={idx}>{stage}</option>
                          ))}
                        </Field>
                      </div>
                      </div>
                      <div className="form-row">
    
                {(stage == LoanStages.Credit || stage == LoanStages.Sanction  || stage == LoanStages.Operation || stage == LoanStages.Disbursal )  && subStages && subStages.length >0   && (
                      <div className="form-group col">
                      <p>Select Next Step:</p>
                      <Field
                        as="select"
                        className="form-control" 
                        name="sub_stage"
                        label="Next Stage Step" 
                      >
                        <option value="" disabled>Select Sub stages</option> 
                        {subStages && subStages.map((substage,idx)=> (
                          <option value={substage} key={idx}>{substage}</option>
                        ))}
                      </Field>
                      </div>
                )}
                </div>
                  <div className="form-row">

                        <div className="form-group col">
                          <label>Select Next Stage Owner</label>
                          <Field
                            name="next_stage_emp_id"
                            as="select"
                            onChange={(e)=>handleChange("next_stage_emp_id",e.target.value,setFieldValue)}
                            className={
                              "form-control" +
                              (errors.next_stage_emp_id && touched.next_stage_emp_id ? " is-invalid" : "")
                            }
                          >
                            <option value="" disabled></option>
                            {
                               fEmployeeList && fEmployeeList.map((ei,idx)=> 
                                (
                                  <option value={ei.emp_id} key={idx}> {ei.first_name} {ei.last_name} - {ei.role}-{ei.emp_id}</option>
                                  
                                )
                              )
                            }
                          </Field>
                          <ErrorMessage
                            name="next_stage_emp_id"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        </div>
                        <div className="form-row">
                        <div className="form-group col">
                        <label>Comments</label>
                        <Field
                        name="comments"
                        type="text"
                        className="form-control"
                        ></Field>
                        <ErrorMessage
                            name="comments"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        </div>
                        <div className="row justify-content-around">
                          <p style={{"color":"red"}}>{warningMessage}</p>
                        </div>
                        <hr/>
                        <div className="row justify-content-around">
                        <Fab
                          type="submit"
                          disabled={isSubmitting}
                          color="primary"
                          aria-label="save"
                        >
                          {isSubmitting && (
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                          )}
                          <SaveIcon />
                        </Fab>
                      </div>
                      </Form>
                  );
                 }}
              </Formik>
              </>
              )}
              </div>
            </LoadingOverlay>
        </Fade>
      </Modal>
    </div>
  );
}
