import { fetchWrapper } from "../_helpers/fetch-wrapper";

const apiUrl = process.env.REACT_APP_API_URL;

const baseUrl = `${apiUrl}/termloans`;

function getAll(){
    return fetchWrapper.get(baseUrl)
};

function create(id,params){
    return fetchWrapper.post(`${baseUrl}/createloan`, params)
}

function update(params){
    console.log(params)
    return fetchWrapper.post(`${baseUrl}/updateloan`, params)
}
function deleteDocument(params){
    return fetchWrapper.post(`${baseUrl}/deletedocument`,params)
}
function addDocument(params){
    console.log("Test params",params)
    return fetchWrapper.post(`${baseUrl}/adddocument`,params)
}
function updateLoan(id,params){
    console.log("Test Params",params)
    return fetchWrapper.put(`${baseUrl}/updateloan/${id}`,params)
}
function getLoanById(id) {
    return fetchWrapper.get(`${baseUrl}/getloanbyid/${id}`);
  }
function repaymentScheduleUpload(params){
    console.log("Test params",params)
    return fetchWrapper.post(baseUrl,params)
}

export const termLoans = {
    getAll,
    create,
    update,
    updateLoan,
    getLoanById,
    repaymentScheduleUpload,
    addDocument,
    deleteDocument
  };
