import React from "react";
import { useState, useEffect } from "react";
import roleChecker from "../../_helpers/rolechecker";
import { employeeService, misService } from "../../_services";
import { BarChart, PieChart, Pie, Cell, Bar, CartesianGrid, XAxis, YAxis, Legend, Tooltip, LabelList, ResponsiveContainer, Label } from "recharts";
import { reportsService } from "../../_services/reports.service";
import Product from "../../_helpers/product";
import { loanService } from "../../_services";
import { Button } from "@material-ui/core";
import { history } from "../../_helpers";
import { Paper } from "@material-ui/core";
import { makerCheckerServices } from "../../_services/makerchecker.service";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import CourtCaseServices from "../../_services/courtcase.service";
import datehelper from "../../_helpers/datehelper";
import { ledgerService } from "../../_services/ledger.service";


function GoldDashboard(){
    const user = employeeService.userValue;
    const [ledegerCurrentBalance, setLedgerCurrentBalance] = useState()
    const [chartData, setChartData] = useState({})
    let colors = ['#3C2A21', '#6D67E4', '#5D8AA8', '#46C2CB', '#800000']
    const [loading, setLoading] = useState(true);

    
      useEffect(()=>{
        loadCharts()
        getLedgerCurrectBalance()
      },[])
      const getLedgerCurrectBalance = async () => {
        setLoading(true);
        await ledgerService.getLedgerCurrectBalance()
          .then((res) => setLedgerCurrentBalance(res))
          .catch((err) => console.log(err))
      }
      async function loadCharts() {
        if (roleChecker.isHOLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isHOGoldAccess(user.role)) {
    
          if (roleChecker.isASMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isBCMLevelAccess(user.role)) {
            console.log('User role ---->>> ', user.role, user.branch_id)
            var branch_id = 'area-' + user.branch_id
          }
          else if (roleChecker.isCSMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role)) {
            console.log('User role ---->>> ', user.role, user.branch_id)
            var branch_id = 'cluster-' + user.branch_id
          }
          else if (roleChecker.isRSMLevelAccess(user.role)) {
            console.log('User role ---->>> ', user.role, user.branch_id)
            var branch_id = 'region-' + user.branch_id
          } else if (roleChecker.isZSMLevelAccess(user.role)) {
            console.log('User role ---->>> ', user.role, user.branch_id)
            var branch_id = 'zone-' + user.branch_id
          }
          else {
            var branch_id = user.branch_id
          }
    
          reportsService.getChartsData({ 'chart_key': "all", 'branch_key': branch_id }).
            then((chartListObj) => {
              chartListObj.map((chartDataObj) => {
                console.log('BKey ---->>>', chartDataObj.branch_key, branch_id)
    
                if (chartDataObj.branch_key === branch_id) {
                  let dataArray = JSON.parse(chartDataObj.display_data);
    
                  let data = [];
                  Object.keys(dataArray).map((dataKey) => {
                    let dataItem = dataArray[dataKey];
    
                    console.log('dataitem-----------', dataItem)
    
    
                    if (chartDataObj.key == 'Loan_status_pie_chart' || chartDataObj.key == 'nachs_pie_chart' || chartDataObj.key == 'gold_colender_loans_pie') {
                      data.push({
                        'name': dataItem['name'],
                        'value': dataItem['value'],
    
                      })
                    }
                    else if (chartDataObj.key == 'login_weekly_count' || chartDataObj.key == 'sanction_letter_weekly_count' || chartDataObj.key == 'disburse_weekly_count' || chartDataObj.key == 'colender_weekly_count') {
                      console.log('inside login count data')
                      data.push({
                        'display_x': dataItem['display_x'],
                        'Current Month': dataItem['curr_month'],
                        'Previous Month': dataItem['prev_month'],
    
                      })
                    }
                    else if (chartDataObj.key == 'current_month_bucket_plot' || chartDataObj.key == 'previous_month_bucket_plot') {
                      data.push({
                        'display_x': dataItem['bucket'],
                        'Collected': dataItem['completed'],
                        'Total': dataItem['total']
                      })
                    }
                    else if (chartDataObj.key == 'gold_amount_monthly') {
                      data.push({
                        'display_x': dataItem['display_x'],
                        'amount': dataItem['amount'],
    
                      })
                    }
                    else if (chartDataObj.key == 'bucket_x_monthly') {
                      data.push({
                        'display_x': dataItem['display_x'],
                        'outstanding_amount': dataItem['outstanding_amount'],
                        'collected_amount': dataItem['collected_amount'],
    
                      })
                    }
                    else {
                      data.push({
                        'name': dataItem['display_x'],
                        'value': dataItem['display_y'],
                        'count': dataItem['count']
                      })
                    }
    
                  })
                  // console.log('chartdata -->> ',dataItem)
                  console.log('createing chartdataobj key')
    
                  chartData[chartDataObj.key] = data;
                }
              })
              console.log('the value of gold  chartdata is',chartData);
              setChartData(chartData);
              setLoading(false)
            })
            .catch((err) => {
              console.log(err);
            })
        }
      }
      useEffect(() => {
        loadCharts()
       
      }, [user,chartData])
        return <>
             {(  (roleChecker.isBMLevelAccess(user.role))) &&
              <Paper style={{ width: '350px', borderRadius: '10px', margin: '10px', padding: '25px' }}>
                <h3>Current&nbsp;Ledger&nbsp;Balance</h3>
                <hr style={{ width: '100%' }} />
                <div style={{ width: '170px', height: '100px', backgroundColor: 'red', borderRadius: '10px', color: 'white', margin: 'auto', marginTop: '25px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around' }}>
                  <b>Current Balance</b>
                  <b>{ledegerCurrentBalance && ledegerCurrentBalance.current_balance.toLocaleString("en-IN", { style: 'currency', currency: 'INR' })}</b>
                  <Button size="small" style={{ color: 'white', marginLeft: "25px" }}
                    onClick={() => history.push('/home/cashmanagement/transactionhistory', { ledgerDetails: ledegerCurrentBalance })}>...More</Button>
                </div>
              </Paper>
            }
            {(roleChecker.isHOLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isHOGoldAccess(user.role))   && (
            <div>
              <hr style={{ width: '100%' }} />
              <h2>Analytics</h2><br />

             {loading ? <p>Loading</p> : <div class="row">
                <div class="col-md-6">
                  {(roleChecker.isGMLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isHOGoldAccess(user.role)) && chartData && chartData.gold_fresh_login_monthly_count && (
                    <> {console.log('the value of chartdata is',chartData)}
                      {/* Conditional change */}
                      <b>Fresh Loan Counts </b>
                      <ResponsiveContainer width="90%" height={250}>
                        <BarChart data={chartData["gold_fresh_login_monthly_count"]}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis type="number" domain={[0, 'dataMax + 200']} />
                          <Tooltip />
                          <Legend />
                          <Bar isAnimationActive={false} dataKey="value" fill="#40e0d0">
                            <LabelList dataKey="value" position="top" />


                          </Bar>
                          <Bar isAnimationActive={false} dataKey="count" fill="#8884d8">
                            <LabelList dataKey="count" position="top" />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>


                    </>
                  )}

                </div>
                <div class="col-md-6">
                  {(roleChecker.isGMLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isHOGoldAccess(user.role)) && chartData && chartData.gold_renewed_login_monthly_count && (
                    <>

                      {/* Conditional change */}

                      <b>Renewed Loan Counts</b>
                      <ResponsiveContainer width="90%" height={250}>
                        <BarChart data={chartData["gold_renewed_login_monthly_count"]}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis type="number" domain={[0, 'dataMax + 200']} />
                          <Tooltip />
                          <Legend />
                          <Bar isAnimationActive={false} dataKey="value" fill="#40e0d0">
                            <LabelList dataKey="value" position="top" />


                          </Bar>
                          <Bar isAnimationActive={false} dataKey="count" fill="#8884d8">
                            <LabelList dataKey="count" position="top" />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>


                    </>
                  )}
                </div>
                <div class="col-md-6">
                  {(roleChecker.isGMLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isHOGoldAccess(user.role)) && chartData && chartData.gold_amount_monthly && (
                    <>

                      {/* Conditional change */}

                      <b>Net Monthly Amounts</b>

                      <ResponsiveContainer width="90%" height={250}>
                        <BarChart data={chartData['gold_amount_monthly']}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="display_x" />
                          <YAxis type="number" domain={[dataMin => (dataMin < -100 ? dataMin : dataMin - 50), 'dataMax + 200']} />
                          <Tooltip />
                          <Legend />
                          <Bar isAnimationActive={false} dataKey="amount" label={{ position: 'top', fill: '#000000' }}>
                            {chartData['gold_amount_monthly'].map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry.amount < 0 ? '#F0781B' : '#40e0d0'} />
                            ))}
                            <LabelList dataKey="amount" position="top" fill="#000000" content={({ value }) => value} />
                            {chartData['gold_amount_monthly'].map((entry, index) => (
                              <Label
                                key={`label-${index}`}
                                dataKey="amount"
                                position="insideTop"
                                fill={entry.amount < 0 ? '#F0781B' : '#000000'}
                                content={({ value }) => value}
                              />
                            ))}
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>








                    </>
                  )}
                </div>


                <div class="col-md-6">
                  {roleChecker.isGMLevelAccess(user.role) && chartData && chartData.gold_colender_loans_pie && (
                    <>

                      {/* Conditional change */}

                      <b>Colender Loans</b>
                      <ResponsiveContainer width="90%" height={250}>
                        <PieChart width={400} height={400}>
                          <Pie data={chartData['gold_colender_loans_pie']}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            label={(data) => `${data.value} ${data.name}`}


                            outerRadius={80}
                            fill="#8884d8">
                            {chartData['gold_colender_loans_pie']
                              .map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                              ))}
                          </Pie>
                          <Legend />
                        </PieChart>
                      </ResponsiveContainer>


                    </>
                  )}
                </div>



              </div>}

            </div>
          )}
        </>
}

export default GoldDashboard;