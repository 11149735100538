import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button } from "@material-ui/core";
import SubmissionLetterModal from "./SubmissionDoc/SubmissionLetterModal";
import ReleaseLetterModal from "./ReleaseDoc/ReleaseLetterModal";
import roleChecker from "../../_helpers/rolechecker";
const useStyles = makeStyles((theme) => ({
  modal: {
    width: '75%',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function LetterListModal(props) {
  const classes = useStyles();
  const { path } = props.match;
  const loan = props.loan;
  const user = props.user;
  const [open, setOpen] = React.useState(false);
  const [disbursement, setDisbursement]= useState([])
  const [pf, setPf]=useState([])

  function getPaymentDetails(loan){
    if(loan.payment_details.length>0){
      let dis= loan.payment_details.filter(pd=>{return pd.payment_type==='disbursement'})
      setDisbursement(dis)
      let pf= loan.payment_details.filter(pd=>{return pd.payment_type==='pf'})
      setPf(pf)
    }
  }

  const handleOpen = () => {
    getPaymentDetails(loan)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div>
        <Button onClick={handleOpen}
          color="primary"
          compact="true"
          style={{ border: '2px solid #3760F1',width: '85%',}}
        >Show&nbsp;Letters</Button>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Letter List</h2>
            {(roleChecker.isHOGoldAccess(user.role) ||roleChecker.hasAccountsAccess(user.role) ) && (
                <SubmissionLetterModal
                  user={user}
                  loan={loan}
                  history={props.history}
                />
              )}
            <br />
            <Button
              onClick={() =>
                props.history.push(`${path}/authorityletter`, loan)
              }
              color="primary"
              compact="true"
            >
              Authority Letter
            </Button>

            <br />
            <Button
              disabled={loan.approved ? false : true}
              onClick={() =>
                props.history.push(`${path}/print`, loan)
              }
              color="primary"
              compact="true"
            >
              Sanction Letter
            </Button>
            <br />
            {(roleChecker.isHOGoldAccess(user.role)||roleChecker.hasAccountsAccess(user.role)) && (
                <ReleaseLetterModal
                  user={user}
                  loan={loan}
                  history={props.history}
                />
              )}
            <br />
            {disbursement&&disbursement.length>0&&pf&&pf.length>0?
            <Button
              style={{ height: 40 }}
              disabled={loan.details ? false : true}
              variant="contained"
              color="primary"
              onClick={() => {
                if (loan.bank === "Kaabil")
                  props.history.push("/home/pledge/print", loan);
                else
                  props.history.push("/home/application/print", loan.details);
              }}
            >
              Loan Application
            </Button>:
            <span style={{color:'red'}}>**Fill Disbursement and PF detail to download loan application**</span>
            }
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
