import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import * as Yup from "yup";

import { Formik, Field, Form, ErrorMessage } from "formik";

import Fab from "@material-ui/core/Fab";
import SaveIcon from "@material-ui/icons/Save";
import { Button } from "@material-ui/core";
import {loanService}  from "../../_services/loan.service";
import { employeeService } from "../../_services";
import {ColendingSubStages} from "../../constants/colendingsubstages";
import roleChecker from "../../_helpers/rolechecker";
const useStyles = makeStyles((theme) => ({
  modal: {
    width: "50%",
    overflow: "scroll",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    minWidth: 650,
  },
}));

export default function EditStageModal(props) {
  const application = props.application;
  const colender= props.colender;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const user = employeeService.userValue;
  const [employeeList,setEmployeeList] =React.useState([]);
  const colenderStatusOptions =[
    ColendingSubStages.FilePrepared,
    ColendingSubStages.FileLoggedIn,
    ColendingSubStages.QueryPendingAtColender,
    ColendingSubStages.QueryPendingAtKaabil,
    ColendingSubStages.ApprovedFromCredit,
    ColendingSubStages.ApprovalForDisbursement,
    ColendingSubStages.RepaymentToColender,
    ColendingSubStages.RejectedByColender,
    ColendingSubStages.ColendingCancelled
  ];
  console.log("application==");
  console.log(application);
  React.useEffect(() => {
    if(open){ 
      employeeService.getAll()
      .then((el)=> {
        console.log(el);
        let fel=el.filter((e)=> {
            if(roleChecker.isColendingTeam(e.role))
            {
              return true;
            }
            else
            {
              return false;
            }
          })
        setEmployeeList(fel);
      })
      .catch((err)=> {
        console.log(err);
      });
  }
  }, [open]);

  

  const initialValues = {
    co_lender_stage_emp_id:application.co_lender_data && application.co_lender_data.co_lender_stage_emp_id,
    co_lender_status:application.co_lender_data && application.co_lender_data.co_lender_status,
  };


  const validationSchema = Yup.object().shape({
    co_lender_stage_emp_id: Yup.string().required("This field is required"),
    co_lender_status:Yup.string().required("This field is required"),
  });
  
  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  function handleChange(fieldName,value,setFieldValue)
  {
    if(fieldName==="co_lender_stage_emp_id"){
      let newValue=parseInt(value);
      setFieldValue(fieldName,newValue);
    }
    if(fieldName==="co_lender_status")
    {
        setFieldValue(fieldName,value);
    }

  }

  
  async function onSubmit(fields, { setSubmitting }) {
    let params={...fields,
                updated_by_emp_id:user.emp_id,
                colender:colender,
                }
    console.log(params);            
    await loanService.changeColendingStage(application._id,params)
    .then((ns)=> {
      console.log("colending stage changed");
      window.alert("colending Stage changed");
      window.location.reload();
    })
    .catch((err)=> {
      console.log(err);
      window.alert("error changing stage");
    })
    setSubmitting(false);
  }

  return (
    <div>
     
     <Button
        onClick={handleOpen}
        variant="outlined"
        color="primary"
      >
        Edit Stage
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Change Co-Lending Stage</h2>
      
              <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize={true}
                id="transition-modal-description"
              >
              {({ errors, touched, isSubmitting, setFieldValue,values }) => {
                  return (
                    <Form>
                      <div className="form-row">
                      <div className="form-group col">
                      <p>Colending Stage:</p>
                        <Field
                          as="select"
                          className="form-control" 
                          name="co_lender_status"
                          label="Loan Stage"
                          onChange={(e)=> handleChange("co_lender_status",e.target.value,setFieldValue)}
                        >
                          <option value="" disabled>Select Colender Stage</option> 
                          {colenderStatusOptions && colenderStatusOptions.map((stage,idx)=> (
                            <option value={stage} key={idx}>{stage}</option>
                          ))}
                        </Field>
                      </div>
                      </div>
                      <div className="form-row">
                     
                </div>
                  <div className="form-row">

                        <div className="form-group col">
                          <label>Select Next Stage Owner</label>
                          <Field
                            name="co_lender_stage_emp_id"
                            as="select"
                            onChange={(e)=>handleChange("co_lender_stage_emp_id",e.target.value,setFieldValue)}
                            className={
                              "form-control" +
                              (errors.co_lender_stage_emp_id && touched.co_lender_stage_emp_id ? " is-invalid" : "")
                            }
                          >
                            <option value="" disabled></option>
                            {
                               employeeList && employeeList.map((ei,idx)=> 
                                (
                                  <option value={ei.emp_id} key={idx}>{ei.emp_id} - {ei.first_name} {ei.last_name} </option>
                                  
                                )
                              )
                            }
                          </Field>
                          <ErrorMessage
                            name="co_lender_stage_emp_id"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        </div>
                        <hr/>
                        <div className="row justify-content-around">
                        <Fab
                          type="submit"
                          disabled={isSubmitting}
                          color="primary"
                          aria-label="save"
                        >
                          {isSubmitting && (
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                          )}
                          <SaveIcon />
                        </Fab>
                      </div>
                      </Form>
                  );
                 }}
              </Formik>
          
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
