import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {LoanStages} from "../../../constants/loanstages"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  icon:{
    fill:"green",
  },
  icon_active:{
    fill:"orange",
  },
  text:{
    fill:"white",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Sales Visit", "Credit Analysis", "Sanction", "Loan Disbursal","Co Lending","Closure"];
}
  
export default function AppStatus({ stage }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  React.useEffect(() => {
    if (stage === LoanStages.Sales) setActiveStep(0);
    else if (stage === LoanStages.Credit) setActiveStep(1);
    else if (stage === LoanStages.Sanction) setActiveStep(2);
    else if (stage === LoanStages.Disbursal) setActiveStep(3);
    else if (stage === LoanStages.Colending) setActiveStep(4);
    else if (stage === LoanStages.Closure) setActiveStep(5);
    else setActiveStep(-1);
  }, [stage]);

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
            StepIconProps={{
              classes: {
                  active: classes.icon_active,
                  completed: classes.icon,
                  text:   classes.text,
              }
          }}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
