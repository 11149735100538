import React, { useState,useEffect } from 'react'
import { employeeService } from '../../../../../_services';
import { Button } from '@material-ui/core';
import { TabContext } from '../../TabBar';
import { useContext } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { loanService } from '../../../../../_services';
import { uploadFileToBucket } from '../../../../../_helpers/upload';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import datehelper from '../../../../../_helpers/datehelper';
import urlhelper from  '../../../../../_helpers/urlhelper'
import { Edit } from "@material-ui/icons";
import { adminservice } from '../../../../../_services/admin.services'; 
import roleChecker from '../../../../../_helpers/rolechecker';
import LegalData from './LegalData.modal'
import LegalReport from './LegalReport.modal'
import { Paper } from "@material-ui/core";

const LigalList = (props) => {
  const [openValuation,setOpenValuation]=useState(false)

   let cust = props.cust
  console.log("cust is",cust)
    const user = employeeService.userValue;

    const [employeeList, setEmployeeList] = useState({});
   
    async function getAllEmployees() {
      await employeeService.getAll().then((res) => {
        let list = {};
        res.map((e) => {
          list[e.emp_id] = e.first_name + " " + e.last_name;
        });
        setEmployeeList(list);
      });
    }
    useEffect(()=>{
      getAllEmployees()
    },[])
    const verifyLegalReport=( objId)=>{
      console.log("verified")
      loanService.verifyValuationReport({id:objId,"type":"Legal"})  .then((data) => {
          console.log('legal report data is',data)
          window.location.reload();
          // setLetters(data)
      })
      .catch((err) => {
          console.log(err)
      })
    }

    const resetLegalReport=( objId)=>{
       loanService.resetValuationReport({id:objId,"type":"Legal"}).then((data) => {
          console.log('reset report data is',data)
          window.location.reload();
          // setLetters(data)
      })
      .catch((err) => {
          console.log(err)
      })
    }

    let legal_data =[]
    legal_data = cust?.valuationrculegaldata?.filter((val)=>val.type=="Legal")
  return (
    <div>
     <div>
             {/* <Valuationdata cust={cust}/>

             <Valuationdata cust={cust}/> */}
             <Paper style={{  borderRadius: '10px', margin: '3px', padding: '30px' }}>

              <h1>Legal Report</h1>
              {
   legal_data?.length > 0 ? (
    <Table>
      <Thead>
        <Tr>
          <Td className='table-row-th' style={{textAlign:"center"}}> Name</Td>
          <Td className='table-row-th' style={{textAlign:"center"}}>Request</Td>
          <Td className='table-row-th' style={{textAlign:"center"}}>Requested By</Td>
          <Td className='table-row-th' style={{textAlign:"center"}}>Remark</Td>

          {/* <Td className='table-row-th' style={{textAlign:"center"}}>Valuation Amount</Td> */}

          <Td className='table-row-th' style={{textAlign:"center"}}>Property Type	</Td>

          <Td className='table-row-th' style={{textAlign:"center"}}> Report	</Td>
          <Td className='table-row-th' style={{textAlign:"center"}}>Vetting Report	</Td>

          <Td className='table-row-th' style={{textAlign:"center"}}>Verify	</Td>

          <Td className='table-row-th' style={{textAlign:"center"}}>Edit	</Td>

          

        </Tr>
      </Thead>
      <Tbody>
        {legal_data && legal_data.map((val, ind) => (
          <Tr key={ind}>
            <Td className='table-row-td' style={{textAlign:"center"}}>{val?.legal_report?.provider}</Td>

            <Td className='table-row-td' style={{textAlign:"center"}}>{val?.legal_report?.request_status}</Td>
            <Td className='table-row-td' style={{textAlign:"center"}}>{val?.legal_report?.requested_by && employeeList[val.legal_report.requested_by]}</Td>

            <Td className='table-row-td' style={{textAlign:"center"}}>{val?.legal_report?.remark}</Td>

            {/* <Td className='table-row-td' style={{textAlign:"center"}}>{val.legal_report.legal_amount?( val.legal_report.legal_amount + " ₹"):''} </Td> */}
            <Td className='table-row-td' style={{textAlign:"center"}}>{val.legal_report.property_type}</Td>
            <Td className='table-row-td' style={{textAlign:"center"}}> 
                        {
                                 val.legal_report && val.legal_report.legal_report_doc && val.legal_report.legal_report_doc!== ""  && <a href={`/home/pdfviewer?pdf_url=`+urlhelper.encodeToBase64(val.legal_report.legal_report_doc)+"&download=1"} target='_blank'>View Report</a>
                           } </Td>
                           <Td className='table-row-td' style={{textAlign:"center"}}> 
                        {
                                 val.legal_report && val.legal_report.vetting_legal_report && val.legal_report.vetting_legal_report!== ""  && <a href={`/home/pdfviewer?pdf_url=`+urlhelper.encodeToBase64(val.legal_report.vetting_legal_report)+"&download=1"} target='_blank'>View Report</a>
                           } </Td>
                           <Td className='table-row-td'>{(val && val.legal_report &&  val.legal_report.legal_report_doc &&  (!val.legal_report_verify_details || !val.legal_report_verify_details.is_verified))?<>
                                {roleChecker.isHOCreditApproverAccess(user.role)  ? <Button variant="contained" color="primary" onClick={() => {
                                  if (window.confirm("Are you sure you want to verify?")) {
                                                   verifyLegalReport( val._id);
    }
                                    }}>Verify</Button>:<Button disabled>Pending</Button>}
                            </>:<>
                            {
                                (val.legal_report_verify_details && val.legal_report_verify_details.is_verified) ? <> 
                                <p style={{color:'green'}}>Verified</p> 
                                {roleChecker.isGMLevelAccess(user.role) && <Button style={{ background:"red",color:"white"}} variant='contained'  onClick={() => {
                                  if (window.confirm("Are you sure you want to reset ?")) {
                                                   resetLegalReport( val._id);
    }
                                    }} >Reset</Button>}
                                </>:null
                            }
                               
                            </>} </Td>
                            <Td className='table-row-td'>
                             {(val && (!val.legal_report_verify_details || !val.legal_report_verify_details.is_verified) ) &&  <LegalReport  cust={cust} val={val} objId={val._id} />}
                            </Td>
           </Tr>
        ))}
      </Tbody>
    </Table>
  ) : (
    <p>No Data Available</p>
  )
}


              

</Paper>
        </div>
        
  </div>
  )
}

export default LigalList