import React, { useEffect, useState } from 'react'
import BusinessDetails from "../Applications/ApplicationData/BusinessDetails";
import PropertyDetails from "../Applications/ApplicationData/PropertyDetails";
import LiabilitiesDetails from "../Applications/ApplicationData/LiabilitiesDetails";
import ApplicationFees from "../Applications/ApplicationData/ApplicationFees";
import TextField from "@material-ui/core/TextField";
import SaleAppStatus from "../Applications/SaleAppStatus";
import StageAssignModal from "../Applications/StageAssignModal";
import { employeeService, loanService } from "../../../_services";
import roleChecker from "../../../_helpers/rolechecker";
import workflowhelper from "../../../_helpers/workflowhelper"
import { LoanStages } from "../../../constants/loanstages";
import { Button } from '@material-ui/core';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Paper } from "@material-ui/core";
import { TextareaAutosize } from '@material-ui/core';


export const LeadDetails = (props) => {
    let { cust } = props
    const user = employeeService.userValue
     console.log("cust",props.cust)
    const [referenceValues, setReferenceValues] = useState({
        reference_name: '',
        reference_relation: "",
        reference_address: "",
        reference_contact_no: ""
    })

    const [locationValues, setLocationValues] = useState({
        lat: cust?.property_details?.lat || '',
        long: cust?.property_details?.long || ''
    });

    const setReference = () => {
        if (cust && cust.reference && cust.reference.length > 0) {
            setReferenceValues({ ...cust.reference[0] })
        }
    }

    useEffect(() => {
        setReference();
    }, [cust])

  
    const handleChange = (e) => {
        setReferenceValues({ ...referenceValues, [e.target.name]: e.target.value })
    }

    const handleLocationChange = (e) => {
        setLocationValues({ ...locationValues, [e.target.name]: e.target.value });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
    
        // Validate form fields before submitting
    
        if (referenceValues.reference_contact_no.length > 10) {
            alert("Please enter a correct mobile number");
            setReferenceValues({ ...referenceValues, reference_contact_no: '' });
            return;
        }
    
        // Prepare data to send
        const dataToSend = {
            ...referenceValues,
            lat: locationValues.lat,
            long: locationValues.long,
            application_id: cust.application_id

       
        };
        {console.log(dataToSend,"typetype")}
        // Send data to backend API
        loanService.createReference(dataToSend)
            .then((data) => {
                console.log(data,"sdssssssssss");
                alert("Updated Successfully");
                window.location.reload(); // Reload page after successful submission
            })
            .catch((err) => {
                console.log(err);
                // Handle error
            });
    };
    async function handleSubmitForm(values) {
        console.log("in submit function",values);
        // console.log(fields);
    
         cust.validation_from_bm = values.validation_from_bm;
         console.log('cust',cust)
        await loanService.update(cust._id,{validation_from_bm : values.validation_from_bm}).then((applicant)=> {
            console.log("application",applicant)
          window.alert("Loan File Updated"+applicant.application_id);
          window.location.reload();
        })
        .catch((err)=> {
          window.alert("error updating Loan Application");
          console.log("error"+err)
        })
      }
    // const dataToSend = {
    //     // Other data...
    //     lat: locationValues.lat,
    //     long: locationValues.long
    // };

    // const handleSubmit = (e) => {
    //     e.preventDefault()
    //     if (referenceValues.reference_contact_no.length > 10) {
    //         alert("Please Enter Correct Mobile Number")
    //         setReferenceValues({ ...referenceValues, reference_contact_no: '' })
    //         return;
    //     } else {
    //         console.log('enter here')
          
           
    //         loanService.createReference({ ...referenceValues,  ...dataToSend, application_id: cust.application_id })
    //             .then((data) => {
    //                 console.log(data)
    //                 alert("Updated Successfully")
    //                 window.location.reload()
    //             })
    //             .catch((err) => {
    //                 console.log(err)
    //             })
    //     }
    // }
    // console.log(cust, "jjjjjjjjjjjjj")
    const initialValues = {
        validation_from_bm: {
          loan_amount: cust.validation_from_bm?.loan_amount || '',
          tenure: cust.validation_from_bm?.tenure || '',
          emi: cust.validation_from_bm?.emi || '',
          income: cust.validation_from_bm?.income || '',
          property_value: cust.validation_from_bm?.property_value || '',
          comment:cust.validation_from_bm?.comment || ''
        }
      };
      
      const validationSchema = Yup.object().shape({
        validation_from_bm: Yup.object().shape({
            loan_amount: Yup.number().required('Loan amount is required'),
            tenure: Yup.number().required('Tenure is required'),
            emi: Yup.number().required('Emi is required'),
            income: Yup.number().required('Income is required'),
            property_value: Yup.number().required('Property value is required'),
            comment: Yup.string().required('Comment is required').min(100, 'Comment must be at least 100 characters')


        }),

         
        
      });
    return (
        <>
                <Paper style={{  borderRadius: '10px', margin: '10px', padding: '15px' }}>

           <form onSubmit={(e) => handleSubmit(e)} >
        <div>
            <div style={{ display: 'flex', width: '600px', justifyContent: 'space-between', marginTop: '30px' }}>
                <TextField
                    id="appid"
                    label="Application ID"
                    defaultValue={cust.application_id}
                    variant="outlined"
                    className="input_field"
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    id="branchid"
                    label="Branch ID"
                    defaultValue={cust.branch_id}
                    variant="outlined"
                    className="input_field"
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </div>
            <br></br>
            {cust.application_fees && (
                <ApplicationFees customer={cust}></ApplicationFees>
            )}
            <br />
            <br />
            <div>
                <h5>Property Details</h5>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
                    {cust && cust.property_details &&
                        <>
                            <TextField
                                id="appid"
                                label="Property Type"
                                defaultValue={cust.property_details.type}
                                variant="outlined"
                                className="input_field"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <TextField
                                id="branchid"
                                label="Branch ID"
                                defaultValue={cust.property_details.estimated_cost}
                                variant="outlined"
                                className="input_field"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </>}

                        {cust &&
                            <>
                               <TextField
                            id="lat"
                            name="lat"
                            label="Latitude"
                            value={locationValues.lat}
                            onChange={(e) => handleLocationChange(e)}
                            variant="outlined"
                            className="input_field"
                        />
                        <TextField
                            id="long"
                            name="long"
                            label="Longitude"
                            value={locationValues.long}
                            onChange={(e) => handleLocationChange(e)}
                            variant="outlined"
                            className="input_field"
                        />
                                

                            </>
                        }
                </div>
            </div>
            <br />
            <br />
            

                <div>
                    <h5>Loan From Other Financial Institute</h5>
                    <div style={{ display: 'flex', width: '700px', justifyContent: 'space-between', marginTop: '30px' }}>
                       
{cust && cust.liabilities_details &&
                        <>
                            <TextField
                                id="appid"
                                label="Previous Loan"
                                defaultValue={cust.liabilities_details.previous_loan}
                                variant="outlined"
                                className="input_field"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <TextField
                                id="branchid"
                                label="Company Name"
                                defaultValue={cust.liabilities_details.company}
                                variant="outlined"
                                className="input_field"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </>}
                    </div>
                </div>
                <br /><br />
                {cust && <div>
                    <h5>Reference Details</h5>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
                        <TextField
                            name="reference_name"
                            required
                            label="Reference Name"
                            onChange={(e) => handleChange(e)}
                            value={referenceValues.reference_name}
                            // defaultValue={cust.property_details.type}
                            variant="outlined"
                            className="input_field"
                        // InputProps={{
                        //     readOnly: true,
                        // }}
                        />

                        <TextField
                            name="reference_relation"
                            required
                            onChange={(e) => handleChange(e)}
                            value={referenceValues.reference_relation}
                            label="Reference Relation"
                            // defaultValue={cust.property_details.type}
                            variant="outlined"
                            className="input_field"
                        // InputProps={{
                        //     readOnly: true,
                        // }}
                        />
                        <TextField
                            name="reference_address"
                            required
                            onChange={(e) => handleChange(e)}
                            label="Reference Address"
                            value={referenceValues.reference_address}
                            // defaultValue={cust.property_details.type}
                            variant="outlined"
                            className="input_field"
                        // InputProps={{
                        //     readOnly: true,
                        // }}
                        />

                        <TextField
                            name="reference_contact_no"
                            label="Reference Contact"
                            required
                            value={referenceValues.reference_contact_no}
                            type='number'
                            onChange={(e) => handleChange(e)}
                            // defaultValue={cust.property_details.type}
                            variant="outlined"
                            className="input_field"
                        // InputProps={{
                        //     readOnly: true,
                        // }}
                        />
                    </div><br /><br />

                    <Button style={{ marginLeft: "40%", border: "1px solid blue", backgroundColor: "blue", color: "white" }} type='submit' >Submit</Button>

                </div>}

        
            {/* {cust.business_details && (
                <BusinessDetails data={cust.business_details}></BusinessDetails>
            )} */}
            {/* {cust.employees && cust.employees.length > 0 && (
          <TextField
            disabled
            id="ro_id"
            label="RO/Sales Agent"
            defaultValue={cust.employees[0].first_name + " " + cust.employees[0].last_name + " /ID: " + cust.employees[0].emp_id + " "}
            variant="outlined"
          />)} */}


            {/* <br></br>
            {cust.property_details && (
                <PropertyDetails data={cust.property_details}></PropertyDetails>
            )}
            <br></br> */}

            {/* {cust.liabilities_details && (
                <LiabilitiesDetails data={cust.liabilities_details}></LiabilitiesDetails>
            )} */}
            <br></br>
            <br></br>
            {/* {(workflowhelper.isOTPVerified(cust) && workflowhelper.isLoginFeesReceived(cust)) && roleChecker.isBranchLevelAccess(user.role) && cust.stage === LoanStages.Sales && (

                <div class="row  justify-content-md-center" style={{ "border": "1px solid" }}>
                    <div class="justify-content-md-center">
                        <p style={{ padding: "4px" }}>I have verified all the loan details and this loan is now ready for credit check.</p>
                    </div>
                    <br />
                    <div class="row justify-content-md-left" style={{ margin: "15px" }}>
                        <StageAssignModal loan_data={cust} />
                    </div>
                </div>
            )} */}
            {/* {!workflowhelper.isOTPVerified(cust) && cust.stage === LoanStages.Sales && (

                <div class="row  justify-content-md-center" style={{ "border": "1px solid" }}>
                    <div class="justify-content-md-center">
                        <p style={{ padding: "4px", color: "red" }}>Please Verify OTP to move loan to Credit Stage</p>
                    </div>
                </div>
            )} */}
            {/* {workflowhelper.isOTPVerified(cust) && !workflowhelper.isLoginFeesReceived(cust) && cust.stage === LoanStages.Sales && (

                <div class="row  justify-content-md-center" style={{ "border": "1px solid" }}>
                    <div class="justify-content-md-center">
                        <p style={{ padding: "4px", color: "red" }}>Please take Login Fees to move file to credit</p>
                    </div>
                </div>
            )} */}
        </div>
        </form>
        </Paper>
      {((roleChecker.isBranchLevelAccess(user.role) && user.role!="Sales") || roleChecker.isHOLevelAccess(user.role)) && <Paper style={{ borderRadius: '10px', margin: '10px', padding: '15px' }}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmitForm}
            >
                {({ errors, touched, isSubmitting, setFieldValue, values, initialValues }) => (
                    <Form>
                        <h5>Loan Additional Details</h5>
                        {cust && !cust.validation_from_bm && <p>Data will be un-editable, so validate before submitting</p>}

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px', gap: '1rem' }}>
                            <div>
                                <TextField
                                    type="number"
                                    label="Loan Amount in Lacs"
                                    name="validation_from_bm.loan_amount"
                                    variant="outlined"
                                    value={values.validation_from_bm.loan_amount}
                                    onChange={(e) => setFieldValue('validation_from_bm.loan_amount', e.target.value)}
                                    required
                                    disabled={values?.validation_from_bm?.loan_amount && values.validation_from_bm.loan_amount === initialValues.validation_from_bm.loan_amount}
                                />
                                <ErrorMessage name="validation_from_bm.loan_amount" component="div" />
                            </div>
 
                            <div>
                                <TextField
                                    type="number"
                                    label="Tenure in months"
                                    name="validation_from_bm.tenure"
                                    variant="outlined"
                                    value={values.validation_from_bm.tenure}
                                    onChange={(e) => setFieldValue('validation_from_bm.tenure', e.target.value)}
                                    required
                                    disabled={values?.validation_from_bm?.tenure && values.validation_from_bm.tenure === initialValues.validation_from_bm.tenure}
                                />
                                <ErrorMessage name="validation_from_bm.tenure" component="div" />
                            </div>

                            <div>
                                <TextField
                                 type="number"
                                    label="Obligations (EMI )Per month IN Thousands"
                                    name="validation_from_bm.emi"
                                    variant="outlined"
                                    value={values.validation_from_bm.emi}
                                    onChange={(e) => setFieldValue('validation_from_bm.emi', e.target.value)}
                                    required
                                    disabled={values?.validation_from_bm?.emi && values.validation_from_bm.emi === initialValues.validation_from_bm.emi}
                                /> 
                                <ErrorMessage name="validation_from_bm.emi" component="div" />
                            </div>

                            <div>
                                <TextField
                                 type="number"
                                    label="Income per month	 in Thousands"
                                    name="validation_from_bm.income"
                                    variant="outlined"
                                    value={values.validation_from_bm.income}
                                    onChange={(e) => setFieldValue('validation_from_bm.income', e.target.value)}
                                    required
                                    disabled={values?.validation_from_bm?.income && values.validation_from_bm.income === initialValues.validation_from_bm.income}
                                />
                                <ErrorMessage name="validation_from_bm.income"  component="div" />
                            </div>

                            <div>
                                <TextField
                                    type="number"
                                    label="Property Value in Lacs"
                                    name="validation_from_bm.property_value"
                                    variant="outlined"
                                    value={values.validation_from_bm.property_value}
                                    onChange={(e) => setFieldValue('validation_from_bm.property_value', e.target.value)}
                                    required
                                    disabled={values?.validation_from_bm?.property_value && values.validation_from_bm.property_value === initialValues.validation_from_bm.property_value}
                                />
                                <ErrorMessage name="validation_from_bm.property_value" component="div" />
                            </div>
                        </div>
                        <br />
                        <div>
                        <label>Comments</label>
                            <TextareaAutosize
                                aria-label="minimum height"
                                placeholder="Comment"
                                name="validation_from_bm.comment"
                                value={values.validation_from_bm.comment}
                                onChange={(e) =>
                                    setFieldValue('validation_from_bm.comment', e.target.value)
                                }
                                required
                                style={{
                                    width: '100%',
                                    minHeight: '100px',
                                    padding: '8px',
                                    resize: 'vertical',
                                }}
                                variant="outlined"
                                disabled={values?.validation_from_bm?.comment && values.validation_from_bm.comment === initialValues.validation_from_bm.comment}
                            />
                            <ErrorMessage name="validation_from_bm.comment" component="div" />
                        </div>
                        <br /><br />

                        <Button style={{ marginLeft: "40%", border: "1px solid blue", backgroundColor: "blue", color: "white" }}  type='submit'   disabled={isSubmitting}                          
>{isSubmitting && (
                                        <span className="spinner-border spinner-border-sm mr-1"></span>
                                    )}Submit</Button>
                    </Form>
                )}
            </Formik>
        </Paper>}
            </>
    )
}
