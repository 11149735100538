import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export const PercentageCircle = (props) => {
  const percentage = props.percentage

  return (
    <div style={{ width: 50, height: 50 }}>
      <CircularProgressbar
        value={percentage}
        maxValue={100}
        strokeWidth={50}
        text={`${percentage}%`}
        styles={buildStyles({
          strokeLinecap: "butt",
          textColor: 'white',
          // textSize:'26px',
          pathColor: `#0B8508`,
        })}
      />
    </div>
  )
}