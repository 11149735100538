import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { CheckInForm } from './CheckInForm'
import { CheckOutPrint } from './CheckOutPrint'
import { CheckInRequest } from './CheckInRequest'
import { PacketList } from './PacketList'
import {PacketHistory} from './PacketHistory'
import { CheckOutRequest } from './CheckOutRequest'
import {CheckInAcceptPrint} from './CheckInAcceptPrint'
const GoldPacketIndex = ({match}) => {
    const {path}=match
  return (
    <>
      <Switch>
      <Route path={`${path}/checkin`} component={CheckInRequest}/>
      <Route path={`${path}/checkout`} component={CheckOutRequest}/>
      <Route path={`${path}/checkinform`} component={CheckInForm}/>
      <Route path={`${path}/packetlist`} component={PacketList}/>
      <Route path={`${path}/packethistory`} component={PacketHistory}/>
      <Route path={`${path}/checkoutprint`} component={CheckOutPrint}/>
      <Route path={`${path}/checkinacceptprint`} component={CheckInAcceptPrint}/>
      </Switch>  
    </>
  )
}

export default GoldPacketIndex