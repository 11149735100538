import react from 'react'
import BranchPettyCash from "./BranchPettyCash"
import ViewAllBranchExpense from "./ViewAllBranchExpense"


function BranchExpense(){


    return  (
        <> 
        
        <div>
        <BranchPettyCash/>
        </div>
        
        
          <ViewAllBranchExpense/>
        </>
    );
         
    
}

export default BranchExpense