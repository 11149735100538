import React from "react";
import { Route, Switch } from "react-router-dom";

import { VAAccountList } from "./VAAccountList";
import { VAAddEdit } from "./VAAddEdit";
import VADetailsPrint from "./VADetailsPrint";
import { VAPayments } from "./VAPayments";

function VirtualAccounts({ match }) {
  const { path } = match;

  return (
    <Switch>
      <Route exact path={path} component={VAAccountList} />
      <Route exact path={`${path}/payments`} component={VAPayments} />
      <Route path={`${path}/add`} component={VAAddEdit} />
      <Route path={`${path}/print`} component={VADetailsPrint} />
    </Switch>
  );
}

export { VirtualAccounts };
