import React, { useState } from "react";
import JobSheetColumns from "./JobSheetColumns";
import JobSheetList from "./JobSheetList";
import JobSheetTotals from "./JobSheetTotals";
import EmptyJobSheet from "./EmptyJobSheet";
import { Button, Typography } from "@material-ui/core";

export default function JobSheet({
  setFieldValue,
  totalOrnaments,
  setTotalOrnaments,
}) {
  const assignRef = React.useRef(null);

  const [values, setValues] = useState([]);
  const [totals, setTotals] = useState({});

  const decimalRoundOff = (num) => {
    return Math.round(num * 100) / 100;
  };

  const handleChange = (idx) => (e) => {
    const { name, value } = e.target;

    const newvalues = [...values];
    newvalues[idx][name] = value;
    newvalues[idx]["net_weight"] = decimalRoundOff(
      newvalues[idx]["gross_weight"] - newvalues[idx]["deduction"]
    );
    newvalues[idx]["total_advance"] = decimalRoundOff(
      newvalues[idx]["advance_value"] * newvalues[idx]["net_weight"]
    );
    setFieldValue("gold.ornaments", newvalues);
    setFieldValue("gold.valuation", 0);
    // setFieldValue("rpg", 0);
    setValues(newvalues);

    var grossTotal = 0;
    var netTotal = 0;
    var itemTotal = 0;
    var deductionTotal = 0;
    var advanceTotal = 0;

    values.map((item) => {
      grossTotal += +item.gross_weight;
      netTotal += +item.net_weight;
      itemTotal += +item.quantity;
      deductionTotal += +item.deduction;
      advanceTotal += +item.total_advance;
    });

    setFieldValue("gold.gross_weight", decimalRoundOff(grossTotal));
    setFieldValue("gold.net_weight", decimalRoundOff(netTotal));
    setFieldValue("gold.total_items", itemTotal);
    setFieldValue("gold.deduction", decimalRoundOff(deductionTotal));
    setFieldValue("gold.valuation", Math.round(advanceTotal));

    setTotals({
      gross_weight: decimalRoundOff(grossTotal),
      net_weight: decimalRoundOff(netTotal),
      total_items: itemTotal,
      deduction: decimalRoundOff(deductionTotal),
      valuation: Math.round(advanceTotal),
    });
  };

  const handleAddRow = () => {
    const item = {
      desc: "",
      quantity: "",
      gross_weight: "",
      deduction: "",
      net_weight: "",
      advance_value: "",
      total_advance: "",
    };
    setFieldValue("gold.ornaments", [...values, item]);
    setValues([...values, item]);

    if (setTotalOrnaments && totalOrnaments)
      setTotalOrnaments([
        ...totalOrnaments,
        {
          image: "",
          ref: assignRef,
        },
      ]);
  };

  const handleRemoveSpecificRow = (idx) => () => {
    const newvalues = [...values];

    setFieldValue(
      "gold.gross_weight",
      decimalRoundOff(totals.gross_weight - newvalues[idx].gross_weight)
    );
    setFieldValue(
      "gold.net_weight",
      decimalRoundOff(totals.net_weight - newvalues[idx].net_weight)
    );
    setFieldValue(
      "gold.total_items",
      totals.total_items - newvalues[idx].quantity
    );
    setFieldValue(
      "gold.deduction",
      decimalRoundOff(totals.deduction - newvalues[idx].deduction)
    );
    setFieldValue(
      "gold.valuation",
      totals.valuation - newvalues[idx].total_advance
    );

    setTotals({
      gross_weight: decimalRoundOff(
        totals.gross_weight - newvalues[idx].gross_weight
      ),
      net_weight: decimalRoundOff(
        totals.net_weight - newvalues[idx].net_weight
      ),
      total_items: totals.total_items - newvalues[idx].quantity,
      deduction: decimalRoundOff(totals.deduction - newvalues[idx].deduction),
      valuation: Math.round(totals.valuation - newvalues[idx].total_advance),
    });

    newvalues.splice(idx, 1);
    setFieldValue("gold.ornaments", newvalues);
    setValues(newvalues);

    if (setTotalOrnaments && totalOrnaments) {
      totalOrnaments.pop();
      setTotalOrnaments(totalOrnaments);
    }
  };

  if (values.length > 0) {
    return (
      <div>
        <div className="col-10 mx-auto text-center text-title">
          <Typography variant="h5">Gold Ornaments</Typography>
        </div>
        <div className="row clearfix">
          <div className="col-md-12 column">
            <table className="table table-bordered table-hover" id="tab_logic">
              <thead>
                <JobSheetColumns />
              </thead>
              <JobSheetList
                handleRemoveSpecificRow={handleRemoveSpecificRow}
                handleChange={handleChange}
                values={values}
              />
            </table>
            <JobSheetTotals totals={totals} />
            <div style={{ textAlign: "center", margin: 20 }}>
              <Button variant="outlined" onClick={handleAddRow}>
                Add Item
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <EmptyJobSheet />
        <div style={{ textAlign: "center", margin: 20 }}>
          <Button variant="outlined" onClick={handleAddRow}>
            Add Item
          </Button>
        </div>
      </div>
    );
  }
}
