import React  from 'react'
import { useState } from 'react'


export const PaymentLink =() => {
 

  const [field, setField]= useState({})

   const handleChange=(fieldkey, fieldval)=>{
     field[fieldkey] = fieldval
     setField(field)
    //  console.log(field)
  }

  const handleSubmit=()=>{
    let sms_message = "प्रिय ग्राहक , आपके लोन की "+`${field.due_amount}`+" रूपये  की किश्त बकाया चल रही है।  किश्त को जमा करवाने के लिए नीचे दिए गए लिंक पर जाये और किश्त का भुगतान करे, किश्त सफलतापूर्वक जमा होने पर स्क्रीनशॉट व्हाट्सप्प पर भेजे। लिंक: "+" https://kaabilfinance.com/payment_link.php?amount="+`${field.due_amount}`+"&tr=00002333"+" धन्यवाद! काबिल फाइनेंस टीम।"
    
    
    let encoded_sms_message = encodeURIComponent(sms_message)

    window.open("https://api.whatsapp.com/send?phone=+91-"+`${field.mobile_number}`+"&text="+`${encoded_sms_message}`)

  }


    return (
        <>
           <div className="container my-3">
            <form onSubmit={handleSubmit} >
            <input onChange={(e)=>handleChange('mobile_number', e.target.value)} value={field.mobile_number} name='mobile_number' className="form-control form-control-lg my-3" type="number"  placeholder="Mobile Number" aria-label=".form-control-lg example" required />
             <input onChange={(e)=>handleChange('due_amount', e.target.value)} value={field.due_amount} name='due_amount' className="form-control form-control-lg my-3" type="number"  placeholder="Due Amount" aria-label=".form-control-lg example" required/>
             <button   type='submit' className="btn btn-primary my-3">Go To Whatsapp</button>
            </form>
           </div>
          
        </>
    )
}