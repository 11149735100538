import React from "react";
import AddPaymentSheetItem from "./AddPaymentSheetItem";

export default function AddPaymentDetailsList({
  values,
  handleChange,
  handleRemoveSpecificRow,
}) {
  return (
    <tbody>
      {values.map((item, idx) => {
        return (
          <AddPaymentSheetItem
            key={idx}
            idx={idx}
            item={item}
            value={values}
            handleChange={handleChange}
            handleRemoveSpecificRow={handleRemoveSpecificRow}
            newAddition={(item.payment_amount==0)}
          />
        );
      })}
    </tbody>
  );
}
