import { Table } from '@material-ui/core';
import React from 'react'
import { useRef } from 'react';
import { Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import datehelper from "../../../_helpers/datehelper";
import logo from "../../../assets/kaabil.png";

export default class SibSubmissionLetter extends React.Component {
    render() {
        console.log("test", this.props)
        const data = this.props.dataFill
        const contact = data.contact
        console.log("Check contact", contact)
        console.log("test props", data)
        const date = new Date()
        return (
            <div style={{ fontSize: '18px', marginLeft: "23px", marginRight: "50px" }}>
                <style type="text/css" media="print">
                    {"\
   @page { size: A4; }\
"}
                </style>
                <div style={{
                    width: "290mm",
                    height: "410mm",
                    marginLeft: "auto",
                    marginRight: "auto",
                    backgroundColor: "white",
                    padding: 40,
                }}
                    className="d-flex flex-column"
                >

                    <div style={{ textAlign: "center" }}>
                        <img style={{ width: 350, height: 100, marginTop: "50px" }} src={logo}></img>
                    </div>
                    <p style={{ textAlign: "left" }}>
                        To,<br />
                        Branch Manager<br />
                        SIB
                    </p>
                    <b style={{ textAlign: "right" }}> Date: {contact ? datehelper.displayDate(date) : datehelper.displayDate(new Date(data.current_date))}</b>
                    <br />
                    <b style={{ textAlign: "right" }}>{data.letter_number}</b>
                    <br />
                    <h6 style={{ textAlign: "center", textDecoration: "underLine", fontWeight: "bold" }}>DECLARATION CUM TAKE DELIVERY LETTER</h6>
                    <p>
                        I/ We, hereby declare that there is sufficient enabling clause in the original agreement between the
                        undersigned and the original Pledgor, enabling the undersigned to re-pledge the gold ornaments for
                        availing Cash Credit (CC)/Over Draft (OD) for business purposes from Banks/ FIs
                        <br />
                        <br />
                        I/ We hereby declare and confirm that the KYC of the original Pledgor is completely complied and further
                        undertake to provide copies of the same to the South Indian Bank Ltd., as and when demanded in writing
                        without any objections or demure.
                        <br />
                        <br />
                        I say that the undersigned is seized and possessed of and otherwise well and sufficiently entitled to re-
                        pledge the ornaments pertaining to the Original Pledgor more particularly described in overleaf under
                        “Details of Gold Ornaments” (hereinafter collectively referred to as the “said pledged ornaments”)
                        <br />
                        <br />
                        I/ We understand that the Bank is extending the loan, interalia, based on this declaration which is true and
                        correct to the best of the knowledge of the undersigned.
                        <br />
                        <br />
                        I/ We request to take delivery of the following 22 ct. gold ornaments described overleaf and hold them as
                        collateral security for the Cash Credit -”SIB Gold Power Scheme - REPLEDGE” allowed to me/us in the
                        account of Kaabil finance private limited in pursuance of the Credit Facility Agreement
                        executed by me/us on 20-12-2022 for Rs 2 Cr.
                        <br />
                        <br />
                        I/We hereby declare that I/We has/have absolute right to create a valid re-pledge of the above gold
                        ornaments and that the quality and value stated are true and correct to the best of my/ our knowledge and
                        I/We am/are aware that you have made this Cash Credit facility upon this representation.
                        <br />
                        <br />
                        <br />
                        Yours faithfully,<br /> <br />
                        (________________)
                    </p>
                </div>
                <div style={{
                    width: "290mm",
                    height: "410mm",
                    marginLeft: "auto",
                    marginRight: "auto",
                    backgroundColor: "white",
                    padding: 40,
                }}
                    className="d-flex flex-column"
                >
                    <div style={{ textAlign: "center" }}>
                        <img style={{ width: 350, height: 100, marginBottom: "50px", marginTop: "50px" }} src={logo}></img>
                    </div>
                    <h5 style={{ textAlign: "center", textDecoration: "underLine", marginBottom: "20px", fontWeight: "bold" }}>Details of Gold Ornaments</h5>
                    <Table style={{ width: "100%", marginRight: "60px" }}>
                        <thead>
                            <Tr>
                                <Th style={{ border: "1px solid " }}>Sl.No</Th>
                                <Th style={{ border: "1px solid " }}>Name & address of the original Pledgor</Th>
                                <Th style={{ border: "1px solid " }}>KFPL Loan No.</Th>
                                <Th style={{ border: "1px solid " }}>Description of items</Th>
                                <Th style={{ border: "1px solid " }}>No</Th>
                                <Th style={{ border: "1px solid " }}>Gross Weight</Th>
                                <Th style={{ border: "1px solid " }}>Net Weight</Th>
                                <Th style={{ border: "1px solid " }}>Market value as on date/ gram</Th>
                                <Th style={{ border: "1px solid " }}>Total value</Th>
                                <Th style={{ border: "1px solid " }}>FOR OFFICE USE Date of Release</Th>
                            </Tr>
                        </thead>
                        <Tbody >
                            <Tr>
                                <Td style={{ border: "1px solid " }}>1.</Td>
                                <Td style={{ border: "1px solid " }}>{data.name}</Td>
                                <Td style={{ border: "1px solid " }}>{data.loan_account }</Td>
                                <Td style={{ border: "1px solid " }}>
                                    {data.ornaments.map((item) => (
                                        <p>{item.desc}</p>
                                    ))}
                                </Td>

                                <Td style={{ border: "1px solid " }}>
                                    {data.ornaments.map((item) => (
                                        <p>{item.quantity}</p>
                                    ))}
                                </Td>
                                <Td style={{ border: "1px solid " }}>
                                    <p>{data.gross_weight}</p>
                                </Td>
                                <Td style={{ border: "1px solid " }}>
                                    <p>{data.net_weight} </p>
                                </Td>
                                <Td style={{ border: "1px solid " }}>{ }</Td>
                                <Td style={{ border: "1px solid " }}>{ }</Td>
                                <Td style={{ border: "1px solid " }}>{ }</Td>
                            </Tr>
                        </Tbody>
                        <br />
                        <br />
                        <br />
                    </Table>
                    <p style={{ textAlign: "right" }}>
                        Yours faithfully,
                        <br />
                        <br />
                        (Signature)
                    </p>
                    <p>Endorsement of BM/Officer about receipt of delivery of above gold ornaments.
                        <br />
                        <br />
                        (Signature)
                    </p>
                </div>
            </div>
        )
    }
}
