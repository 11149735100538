import React, {useEffect, useState} from 'react'
import { Button, TextField, Paper } from "@material-ui/core";
import { history } from '../../../_helpers';
import { loanService, employeeService } from '../../../_services';
import {sblLetter} from "../../../_services/letter.services"
import { ColendingSubStages } from '../../../constants/colendingsubstages';
import { ApprovalRequestFilter } from '../../MakerCheckerFramework/ApprovalRequestFilter';
import './LetterForm.css'
import workflowHelper from "../../../_helpers/workflowhelper";
const LetterForm = (props) => {
console.log(props,"propspropsprops")
  const[documents, setDocuments] =useState([])
  const [count, setCount] = useState({})
  const [colenders, setColenders] = useState([]);
  // const [selectedCoLenderStatus, setSelectedCoLenderStatus] = useState("");
  const [item, setItem] = useState([])
  const [itemDocument, setItemDocument] = useState([])
  const user = employeeService.userValue


  const[value, setValue] = useState({
    name: '',
    sonof: '',
    amount: "",
    amt: "",
    stage:"",
    application_id: "",
    letter_loan_id:"",
    address: "",
    contact: "",
    interest: 0,
    ledger_balance: 0,
    interest_outstanding:0,
    bounce_charges:0,
    lpi: 0,
    document_charges: 0,
    foreclosure_charges : 0,
    gst:0,
    daily_basis_interest:0,
  })

  const handleChange = (e) => {
    setValue({...value, [e.target.name]: e.target.value})
    setSelectedValue(e.target.value);
    // if(e.target.name==="colender"){
    //   console.log("ddddddddddddddwwwwwwwwww")
      
    //   const selectedCoLenderData = e.target.value
    //   const selectedCoLenders = colenders.find(
    //     (colender) => colender.co_lender_name === selectedCoLenderData,
    //   );
    //   // Set the corresponding co-lender status
    //   if (selectedCoLenders) {
    //     setSelectedCoLenderStatus(selectedCoLenders.co_lender_status);
    //   }
    // }
  }
  console.log(value,"valuevaluevalue")

  const handleItem = (e, index) => {
    const {name, value} = e.target
    const list =[...item]
    list[index][name] = value
    setItem(list)
  }

  console.log(props)
  
  const id = props.location.state.id;
  let temp = window.location.pathname.split("/")
  temp = temp[4];


  const one = [ "", "One ", "Two ", "Three ", "Four ","Five ", "Six ", "Seven ", "Eight ", "Nine ", "Ten ", "Eleven ", "Twelve ", "Thirteen ", "Fourteen ", "Fifteen ",
                      "Sixteen ", "Seventeen ", "Eighteen ","Nineteen " ];
      
      var ten = [ "", "", "Twenty ", "Thirty ", "Forty ", "Fifty ", "Sixty ", "Seventy ", "Eighty ", "Ninety " ]; 
      
      function numToWords(n, s)
      {
          let str = "";
          // if n is more than 19, divide it
          if (n > 19) {
              str += ten[parseInt(n / 10)] + one[n % 10];
          }
          else {
              str += one[n];
          }
          if (n != 0) {
            str += s;
        }
 
        return str;
       }

       function convertToWords(n)
    {
        // stores word representation of given number n
        let out = "";
 
        // handles digits at ten millions and hundred
        // millions places (if any)
        out += numToWords(parseInt(n / 10000000),
        "Crore ");
 
        // handles digits at hundred thousands and one
        // millions places (if any)
        out += numToWords(parseInt((n / 100000) % 100),
        "Lakh ");
 
        // handles digits at thousands and tens thousands
        // places (if any)
        out += numToWords(parseInt((n / 1000) % 100),
        "Thousand ");
 
        // handles digit at hundreds places (if any)
        out += numToWords(parseInt((n / 100) % 10),
        "Hundred ");
 
        if (n > 100 && n % 100 > 0) {
            out += "and ";
        }
 
        // handles digits at ones and tens places (if any)
        out += numToWords(parseInt(n % 100), "");
 
        return out;
    }

    const date = new Date()
    const dd = date.getDate()
    const mm = date.getMonth()+1
    const yy = date.getFullYear()
console.log(dd,"dddddddd")
    const getDAta = () => {
      loanService.getById(id)
        .then((x) => {
          console.log(x);
          let tmpLetterLoanId = x.application_id;
          if (x.loan && x.loan.letter_loan_id) {
            tmpLetterLoanId = x.loan.letter_loan_id;
          }
          if (x.property_details && x.property_details.document_list) {
            setDocuments(x.property_details.document_list);
          }
    
          setValue({
            ...value,
            name: x.applicant.general_information.name,
            title: x.applicant.general_information.title,
            relation_type: x.applicant.general_information.kyc_relation_type,
            relation: x.applicant.general_information.kyc_relation,
            sonof: x.applicant.general_information.sonof,
            contact: x.applicant.general_information.contact,
            application_id: x.application_id,
            address: x.applicant.general_information.address,
            amount: x.loan.sanction_amount,
            stage: x.stage,
            // colender_status:selectedCoLenderStatus,
            colender:selectedValue
          });
    
          if (x.co_lending) {
            let temp = [];
            x.co_lending.forEach((e) => {
              if (e.co_lender_status !== ColendingSubStages.ColendingCancelled) {
                temp.push(e); // Push the entire co-lender object
                 setValue({...value, colender:selectedValue,name:x.applicant.general_information.name, title: x.applicant.general_information.title, relation_type:x.applicant.general_information.kyc_relation_type, relation:x.applicant.general_information.kyc_relation, sonof:x.applicant.general_information.sonof, contact:x.applicant.general_information.contact, application_id:x.application_id, address:x.applicant.general_information.address, amount:x.loan.sanction_amount,stage: x.stage})
              }
            });
            setColenders(temp);
          }
        })
        .catch((err) => {
          console.log("something went wrong", err);
        });
    };
    

  const getForeclosureData = () => {
    console.log(value.application_id)
    sblLetter.getForeclosureData({application_id:value.application_id})
    .then((values) => {
      let totalLpi = 0
      let totalBounce = 0
      console.log(values[0].emi_schedule,"iiii")
      values[0] && values[0].emi_schedule && values[0].emi_schedule.map((x)=>{
        totalLpi =  x.emi_lpi+totalLpi
        totalBounce = x.bounce_charge + totalBounce      
      })
      let totalGst = Math.round((Number(values[0].forclosure_charges) + Number(values[0].document_ret_charges) )*.18)
     
      setValue({...value, ledger_balance:values[0].pos+values[0].interst_outstanding, 
                document_charges:values[0].document_ret_charges, 
                lpi:values[0].net_lpi,
                interest:values[0].current_interest,
                foreclosure_charges:values[0].forclosure_charges, 
                bounce_charges:values[0].bounce_charges,
                gst:totalGst
              })
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  const getTotal = ()=>{
    let x = "";
    if(temp === "nocform"){
      x = "noc"
    }
    if(temp === "forform"){
      x = "forc"
    }
    if(temp === "docform"){
      x = "doc"
    }
    if(temp === "pphform"){
      x = "pphandover"
    }
    sblLetter.totalCount({type: x})
    .then((data)=>{
       setCount(data)
      console.log(data)
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  let noc = Number(count.noc)+1
  let doc = Number(count.doc)+1
  let forc = Number(count.forc)+1
  let pphandover = Number(count.pphandover)+1
  noc = "KFPL"+"/"+yy+mm+dd+"/" + noc
  doc = "KFPL"+"/LOD"+yy+mm+dd+"/" + doc
  forc = "KFPL"+"/"+yy+mm+dd+"/" + forc
  pphandover = "KFPL"+"/"+yy+mm+dd+"/" + pphandover
console.log(forc,"docdocdoc")
  useEffect(() => {
    getDAta()
    getTotal()
  }, [])

  useEffect(() => {
    if(temp === "forform"){
      getForeclosureData()
    }
  }, [value.application_id])
  

  const nocLetter = () => {
    // if(selectedValue ||selectedValue===""){
      // alert("Select the Co-Lender")
    // }else{
    sblLetter.nocLetter({...value, letter_id: noc ,colender:selectedValue})
    .then((letter) => {
      alert("Data added successfully")
      history.push("/home/letterlist/noc", {...value, letter_id: noc})
    })
    .catch((err) => {
      alert("Please try again")
    })
  // }
  }

  const forLetter = (e) => {
    e.preventDefault();
    ApprovalRequestFilter.RequestFilter({
      service: 'sblLetter',
            api: 'forLetter',
            id: value.application_id,
            task: 'Create ForeClouser Letter',
            updated_fields:['ledger_balance','lpi', 'foreclosure_charges','bounce_charges','document_charges', 'interest' ,'gst'],
            sensitive_fields: [],
            new_values: {...value, letter_id:forc},
            old_values: value,
            identification_key: 'Loan Number',
            identification_value: value.application_id,
            emp_id: user.emp_id,
            approval_needed: true,
            approved: false,
            approval_access: 'isAccountChecker',
            product: 'sbl'
    }).then(async(x) => {
      const acUserData = await loanService.getEmpIdRoleWise({ role: 'AC' }); 
      console.log("AC User Data:", acUserData);
      if (Array.isArray(acUserData) && acUserData.length > 0) { // Ensure acUserData is an array and not empty
        console.log("Calling Redis Helper for sending Notifications");
        await Promise.all(
            acUserData.map((employee) => {
                const emp_id = employee.emp_id; // Extract emp_id for each user
    
                return workflowHelper.isRedisNotifications({
                  emp_id: user.emp_id,
                  message: `You have an ForeClouser Letter request for Loan number ${value.application_id} from ${user.emp_id} ,Please review and take necessary action.`,
                  action_link: `https://samruddhi.kaabilfinance.com/home/approvals/list`,
                  action_taken_by: emp_id
              });
            })
          );
        }
      console.log("test x", x)
      alert("Request Sent to concerned Authority")
      history.push('/home/sblloans')
  })
  .catch((err) => {
      window.alert(err)
      console.log(err)
  })
  }

  const addData = () => {
    setItem([...item, {itemKey:"", itemValue:""}])
  }
  const removeData = (index) => {
    const list = [...item]
    list.splice(index, 1)
    setItem(list)
  }
  const addDocument = () => {
    setItemDocument([...itemDocument, {documentData:"", papertype:""}])
  }
  const handleItemDocument = (e, index) => {
    const {name, value} = e.target
    const list =[...itemDocument]
    list[index][name] = value
    setItemDocument(list)
  }
  const removeDataDocument = (index) => {
    const list = [...itemDocument]
    list.splice(index, 1)
    setItemDocument(list)
  }

  const inwords = convertToWords(value.amount)

  const docLetter = () => {
    console.log({...value,   documents: itemDocument, letter_id: doc},"dddddddddddddddddwww")
    sblLetter.docLetter({...value,   documents: itemDocument, letter_id: doc})
    .then((letter)=> {
      alert("Data added successfully")
      history.push("/home/letterlist/doc", {...value,   documents: itemDocument, letter_id: doc, inwords: inwords})
    })
    .catch((err)=>{
      alert("Please try again")
    })
   
  }

  const  pphandoverLetter = () => {
    console.log({...value,   documents: itemDocument, letter_id: pphandover},"{...value,   documents: itemDocument, letter_id: doc,stage:value.stage}")
    sblLetter.pphandoverLetter({...value,   documents: itemDocument, letter_id: pphandover})
    .then((letter)=> {
      alert("Data added successfully")
      history.push("/home/letterlist/pphandOver", {...value,   documents: itemDocument, letter_id: doc})
    })
    .catch((err)=>{
      alert("Please try again")
    })
   
  }
  const [selectedValue, setSelectedValue] = useState('');

  // Handle input change if needed
 
  // Simulate selecting a value (for example purposes)
  const selectValue = () => {
    // Example: Selecting the first available value
    const firstAvailableColender = colenders.find(colender => colender.co_lender_status !== 'Suspense');
    if (firstAvailableColender) {
      setSelectedValue(firstAvailableColender.co_lender_name);
    }
  };

  // Call selectValue to set initial value
  React.useEffect(() => {
    selectValue();
  }, [colenders]);
console.log(colenders,"ddddddddddddddddd")

  return (
    <>
      <div>
        <h2>Letter Form</h2>
      </div>

      <Paper  style={{padding: 20,}}>
      {console.log(value)}


        {
          temp === "nocform" &&(
            <div>
              <div style={{display: "flex", flexWrap: "wrap"}}>
              <span><label>Name<input type="text"  name='name' value={value.name} onChange={handleChange} disabled/></label></span>
              <span style={{marginLeft: "10px"}}><label>Father Name <input type="text" onChange={handleChange} name='sonof' value={value.sonof}  disabled/></label></span>
              <span style={{marginLeft: "10px"}}><label>Loan Account <input type="text" onChange={handleChange} name='letter_loan_id' value={value.application_id} disabled /></label></span>
              <span style={{marginLeft: "10px"}}><label>Loan Amount<input type="text" onChange={handleChange} name='amount' value={value.amount} disabled/></label></span>
              <span  style={{marginTop: "10px"}}><label>Address <input type="text" name='address' onChange={handleChange} value={value.address} disabled/></label></span>
              <span  style={{marginTop: "10px"}}><label>Colender 
                {/* <select style={{marginTop: "10px", border: '1px solid grey'}}
               onChange={handleChange} 
               required
               name='colender'
               >
                <option disabled selected>Select the Colender</option>
               {colenders
  .filter(colender => colender.co_lender_status !== "Suspense") // Filter out items with status "Suspense"
  .map(colender => (
    <option key={colender._id.$oid} value={colender.co_lender_name}>
      {colender.co_lender_name}
    </option>
  ))
}
 </select> */}
 <input
        type="text"
        name='colender'
        value={selectedValue}
        onChange={handleChange}
        style={{ border: '1px solid grey', width: '100%' }}
        disabled // Make the input field read-only if needed
        placeholder="Selected Colender"
      />

{/* {selectedCoLenderStatus && (<input type="text" name='colender_status'  value={selectedCoLenderStatus} disabled hidden/>)} */}
</label></span>
            </div><br/>
            <Button style={{marginLeft: "50%", border: "1px solid grey"}} onClick={nocLetter}>Create Letter</Button>
            </div>
          )
        }
        {
          temp === "forform" &&(
            <form  onSubmit={(e)=>forLetter(e)}>
            <div style={{display: "flex", flexWrap: "wrap"}}>
              <span><label>Name<input type="text" onChange={handleChange} name='name' value={value.name} disabled/></label></span>
              <span style={{marginLeft: "10px"}}><label>Father Name <input type="text"onChange={handleChange} name='father_name' value={value.sonof} disabled/></label></span>
              <span style={{marginLeft: "10px"}}><label>Loan Account <input type="text" onChange={handleChange} name='letter_loan_id' value={value.application_id} disabled/></label></span>
              <span><label>Address <input type="text" name='address' onChange={handleChange} value={value.address} disabled/></label></span>
            </div>

              <div style={{display: "flex", flexWrap: "wrap", marginTop: "20px"}}>
             
            </div>
            {/* <table style={{border:"1px solid grey", width:"60%", marginLeft:"10%"}}>
              <tbody style={{margin:"auto"}}>
                <tr>
                <td style={{marginTop: "10px"}}> <label style={{ width: "200px" }}>Contact<input type="text" name='contact' value={value.contact}  onChange={handleChange} disabled/></label></td>
                <td style={{marginTop: "10px"}}><label style={{ width: "200px" }}> Ledger Balance <input type="number" name='ledger_balance' step="any" value={value.ledger_balance} onChange={handleChange} /></label></td>
                </tr>
                <tr>
                <td style={{marginTop: "10px"}}><label style={{ width: "200px" }}> Interest <input type="number" name='interest' value={value.interest} onChange={handleChange} /></label></td>
                <td style={{marginTop: "10px"}}><label style={{ width: "200px" }}> Documents Ret.Charges <input type="number" step="any" name='document_charges' value={value.document_charges} onChange={handleChange} /></label></td>
                </tr>
                <tr>
                <td style={{marginTop: "10px"}}><label style={{ width: "200px" }}> Bounce Charges <input type="number" step="any" name='bounce_charges' value={value.bounce_charges} onChange={handleChange} /></label></td>
                <td style={{marginTop: "10px"}}><label style={{ width: "200px" }}> Foreclosure Charges <input type="number" step="any" name='foreclosure_charges' value={value.foreclosure_charges} onChange={handleChange} /></label></td>
                </tr>
                <tr>
                <td style={{marginTop: "10px"}}><label style={{ width: "200px" }}> GST <input type="number" step="any" name='gst' value={value.gst} onChange={handleChange} /></label></td>
                <td style={{marginTop: "10px"}}><label style={{ width: "200px" }}> LPI <input type="number" step="any" name='lpi' value={value.lpi} onChange={handleChange} /></label></td>
                </tr>
                <tr>
                <td style={{marginTop: "10px"}}><label style={{ width: "200px" }}> Daily Basis Interest <input type="number" step="any" name='daily_basis_interest' value={value.daily_basis_interest} onChange={handleChange} /></label></td>
               
                </tr>
                {item && item.map((item, index)=>(
              <tr key={index}>
                <td style={{marginTop: "10px"}}> <label style={{ width: "200px" }}> Field Name<input type='text' name="itemKey" value={item.itemKey}  onChange={(e)=>handleItem(e, index)} /></label></td>
                <td style={{marginTop: "10px"}}> <label style={{ width: "200px" }}> Field Value<input type='text' name='itemValue' value={item.itemValue}  onChange={(e)=>handleItem(e, index)} /></label></td>
                <td> <button type='button' onClick={()=> removeData(index)} >Remove</button></td><br/>
              </tr>
            ))}<br/>
              <Button style={{border:"1px solid grey", marginLeft:"70%"}} onClick={()=> addData()} >Add Item</Button>
              </tbody>
            </table><br/> */}
            <div className="form-container">
      <div className="form-row">
        <label className='label'>Contact
          <input type="text" className='input' name="contact" value={value.contact} onChange={handleChange} disabled />
        </label>
       
      </div>
      <div className="form-row">
        <label className='label'>Principal Outstanding
          <input type="number" className='input' name="ledger_balance" value={value.ledger_balance} onChange={handleChange} />
        </label>
        <label className='label'>Documents Charges
          <input type="number" className='input' step="any" name="document_charges" value={value.document_charges} onChange={handleChange} />
        </label>
        {/* <label className='label'>Pending Interest from Previous EMI
          <input type="number" className='input' step="any" name="interest_outstanding" value={value.interest_outstanding} onChange={handleChange} />
        </label> */}
      </div>
      <div className="form-row">
        <label className='label'>Total Late Payment Interest
          <input type="number" className='input' step="any" name="lpi" value={value.lpi} onChange={handleChange} />
        </label>
        <label className='label'>Broken Period Interest
          <input type="number" className='input' step="any" name="interest" value={value.interest} onChange={handleChange} />
        </label>
      </div>
      <div className="form-row">
        <label className='label'>Foreclosure Charges
          <input type="number" className='input' step="any" name="foreclosure_charges" value={value.foreclosure_charges} onChange={handleChange} />
        </label>
        <label className='label'>Bounce Charges
          <input type="number" className='input' step="any" name="bounce_charges" value={value.bounce_charges} onChange={handleChange} />
        </label>
      </div>
      <div className="form-row">
       
        <label className='label'>GST
          <input type="number" className='input' step="any" name="gst" value={value.gst} onChange={handleChange} />
        </label>
        <label className='label'>Daily Basis Interest
          <input type="number" className='input' step="any" name="daily_basis_interest" value={value.daily_basis_interest} onChange={handleChange} />
        </label>
      </div>
      {item.map((itm, index) => (
        <div className="form-row" key={index}>
          <label className='label'>Field Name
            <input type="text" className='input'  name="itemKey" value={itm.itemKey} onChange={(e) => handleItem(e, index)} />
          </label>
          <label className='label'>Field Value
            <input type="text" className='input' name="itemValue" value={itm.itemValue} onChange={(e) => handleItem(e, index)} />
          </label>
          <button className='button' type="button" onClick={() => removeData(index)}>Remove</button>
        </div>
      ))}
      <Button onClick={() => addData()} style={{ border: "1px solid grey", marginTop: "10px" }}>Add Item</Button>
    </div>
            <Button style={{marginLeft: "40%",marginTop:"1rem", border: "1px solid grey"}} type="submit"  >Create Letter</Button>

            </form>
          )
        }
        {
          temp === "docform" &&(
            <div>
              <div style={{display: "flex", flexWrap: "wrap", marginTop: "20px"}}>
             <span><label>Name<input type="text" onChange={handleChange} name='name' value={value.name} /></label></span>
              <span style={{marginLeft: "10px"}}><label>Father Name <input type="text"onChange={handleChange} name='sonof'value={value.sonof}  /></label></span>
              <span><label>Address <input type="text" name='address' value={value.address} onChange={handleChange}/></label></span>
              </div>
              <div style={{display: "flex", flexWrap: "wrap", marginTop: "20px"}}>
              <span style={{marginLeft: "10px"}}><label>Loan Amount<input type="text" onChange={handleChange} name='amount' value={value.amount} /></label></span>
              <span style={{marginLeft: "10px"}}><label>InWards<input type="text" onChange={handleChange} name='inwords' value={convertToWords(value.amount)} /></label></span>
              <span style={{marginLeft: "10px"}}><label>Loan Account <input type="text" onChange={handleChange} name='application_id' value={value.application_id} /></label></span>
              </div>
              <br/>
                <table>
                  <tr>
                    <th style={{border: "1px solid"}}>
                      Document Type
                    </th>
                    <th style={{border: "1px solid"}}>
                      Original/Photocopy
                    </th>
                  </tr>
                    {documents && documents.map((e)=> {
                      if(e.is_lod === "true"){
                     return <tr>
                      <td style={{border: "1px solid"}}>{e.document_type}</td>
                      <td style={{border: "1px solid"}}> {e.original_type}</td>
                      </tr>
                      } 
                    })}
                </table>
              <br/>
              {itemDocument.map((itm, index) => (
        <div className="form-row" key={index}>
          <label className='label'>Document Type
            <input type="text" className='input'  name="documentData" value={itm.documentData} onChange={(e) => handleItemDocument(e, index)} />
          </label>
          <label className='label'>Original/Photocopy
  <select 
    className='input' 
    name="papertype" 
    style={{padding:"0.8rem",borderRadius:"5px"}}
    value={itm.papertype} 
    onChange={(e) => handleItemDocument(e, index)}
  >
    <option value="">Select type</option>
    <option value="Original">Original</option>
    <option value="Photocopy">Photocopy</option>
  </select>
</label>

          <button className='button' type="button" onClick={() => removeDataDocument(index)}>Remove</button>
        </div>
      ))}
      <Button onClick={() => addDocument()} style={{ border: "1px solid grey", marginTop: "10px" }}>Add Item</Button>
              <br/>
              <Button style={{marginLeft: "50%", border: "1px solid grey"}} onClick={docLetter} >Create Letter</Button>

            </div>
          )
        }

{
          temp === "pphform" &&(
<div className="container mt-4">
  {/* Form Section */}
  <div className="row mb-3">
    <div className="col-md-4">
      <label>Name
        <input type="text" className="form-control" onChange={handleChange} name="name" value={value.name} />
      </label>
    </div>
    <div className="col-md-4">
      <label>Father Name
        <input type="text" className="form-control" onChange={handleChange} name="sonof" value={value.sonof} />
      </label>
    </div>
    <div className="col-md-4">
      <label>Address
        <input type="text" className="form-control" onChange={handleChange} name="address" value={value.address} />
      </label>
    </div>
  </div>

  <div className="row mb-3">
    <div className="col-md-4">
      <label>Loan Amount
        <input type="text" className="form-control" onChange={handleChange} name="amount" value={value.amount} />
      </label>
    </div>
    <div className="col-md-4">
      <label>Stage
        <input type="text" className="form-control" onChange={handleChange} name="stage" value={value.stage} />
      </label>
    </div>
    <div className="col-md-4">
      <label>Loan Account
        <input type="text" className="form-control" onChange={handleChange} name="application_id" value={value.application_id} />
      </label>
    </div>
  </div>

  {/* Documents Table */}
 
  {/* Additional Document Section */}
  {itemDocument.map((itm, index) => (
    <div className="form-row mb-3" key={index}>
      <div className="col-md-5">
        <label>Document Type
          <input
            type="text"
            className="form-control"
            name="documentData"
            value={itm.documentData}
            onChange={(e) => handleItemDocument(e, index)}
          />
        </label>
      </div>
      <div className="col-md-5">
        <label>Original/Photocopy
          <select
            className="form-select"
            name="papertype"
            value={itm.papertype}
            onChange={(e) => handleItemDocument(e, index)}
          >
            <option value="">Select type</option>
            <option value="Original">Original</option>
            <option value="Photocopy">Photocopy</option>
          </select>
        </label>
      </div>
      <div className="col-md-2 d-flex align-items-center">
        <button className="btn btn-danger" type="button" onClick={() => removeDataDocument(index)}>
          Remove
        </button>
      </div>
    </div>
  ))}

  {/* Add Document Button */}
  <div className="d-flex justify-content-center mt-3">
    <button className="btn btn-secondary" onClick={() => addDocument()}>
      Add Item
    </button>
  </div>

  {/* Create Letter Button */}
  <div className="d-flex justify-content-center mt-3">
    <button className="btn btn-primary" onClick={pphandoverLetter}>
      Create Letter
    </button>
  </div>
</div>

          )
        }
      </Paper>
    </>
  )
}

export default LetterForm