import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Button } from "@material-ui/core";
import ContactsIcon from '@material-ui/icons/Contacts';


const ContactModal = (props) =>{

    const [open, setOpen] = useState(false);

    const contact = props.contacts;
    console.log(contact)

    const style = {
        position: "absolute",
        top: "30%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 200,
        height: 300,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
        overflow: "scroll",
        //paddingBottom: '30px',
      };

      const handleOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

    return (
        <div>
            <Button onClick={handleOpen}><ContactsIcon/></Button>
         <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          <h2>Contacts</h2>
          <hr style={{border:"1px solid"}}></hr>
            {contact && contact.map((e, idx)=>{
               return  <table key={idx}>
                    <tr>
                        <td>{e.contact}</td>
                    </tr>
                </table>
            })}

          </Typography>
          </Box>
          </Modal>
        </div>
    )

}

export default ContactModal;