import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import * as Yup from "yup";

import { Formik, Field, Form, ErrorMessage } from "formik";

import Fab from "@material-ui/core/Fab";
import SaveIcon from "@material-ui/icons/Save";
import { Button } from "@material-ui/core";
import {loanService}  from "../../../../_services/loan.service";
import { employeeService } from "../../../../_services";
import FormElement from "../../../uielements/FormElement";

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "75%",
    overflow: "scroll",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    minWidth: 650,
  },
}));

export default function AddApplicantModal(props) {
  const customer = props.customer;
  const applicant_type=props.applicant_type;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const user = employeeService.userValue;
  const genderOptions = ["Male","Female"];
  const titleOptions=["Mr","Mrs","Ms"];
  const relationOptions=["S/o","W/o","D/o","C/o"];
  
  const initialValues = {
    tapplicant:{
      general_information:{
        title:"",
        name: "",
        sonof:"",
        kyc_relation:"",
        kyc_relation_type:"",
        wifeof:"",
        aadhar:"",
        contact:"",
        address:"",
        age:"",
        gender:"",
        pan_no:"",
      }
    }
  };


  const validationSchema = Yup.object().shape({
    tapplicant:Yup.object().shape({
      general_information:Yup.object().shape({
        name: Yup.string().required("This field is required"),
        sonof:Yup.string().required("This field is required"),
        contact:Yup.string().matches(/^[0-9]{10}$/,"Valid number").required("This field is required"),
        aadhar:Yup.string().matches(/^[0-9]{12}$/,"Valid aadhar number").required("aadhar required"),
        pan_no:Yup.string().matches(/^[a-zA-Z0-9]{10}$/,"Valid PAN number"),
        address:Yup.string().required("Address is required"),
        age:Yup.number().required("Age is required"),
        gender:Yup.string().required("Gender is required"),
      }),
    }),
     });
  
  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  function handleChange(fieldName,value,setFieldValue)
  {
      setFieldValue(fieldName,value);
  }

  
  async function onSubmit(fields, { setSubmitting }) {
    let params={};
    console.log(fields);
    let target_field="";
    if(applicant_type==="co_applicant")
    {
      target_field="co_applicants";
      if(!customer.co_applicants)
      {
        params['co_applicants']=[];
      }
      else
      {
        params['co_applicants']=customer.co_applicants;
      }
    }
    if(applicant_type==="guarantor")
    {
      target_field="guarantors";
      if(!customer.guarantors)
      {
        params['guarantors']=[];
      }
      else
      {
        params['guarantors']=customer.guarantors;
      }
    }
    if(target_field==="")
      return;

    let newApplicant={'general_information':{}}
    newApplicant['general_information']['title']=fields['tapplicant']['general_information']['title'];
    newApplicant['general_information']['name']=fields['tapplicant']['general_information']['name'];
    newApplicant['general_information']['sonof']=fields['tapplicant']['general_information']['sonof'];
    newApplicant['general_information']['wifeof']=fields['tapplicant']['general_information']['wifeof'];
    newApplicant['general_information']['kyc_relation']=fields['tapplicant']['general_information']['kyc_relation'];
    newApplicant['general_information']['kyc_relation_type']=fields['tapplicant']['general_information']['kyc_relation_type'];
    
    newApplicant['general_information']['aadhar']=fields['tapplicant']['general_information']['aadhar'];
    newApplicant['general_information']['pan_no']=fields['tapplicant']['general_information']['pan_no'];
    
    newApplicant['general_information']['contact']=fields['tapplicant']['general_information']['contact'];
    newApplicant['general_information']['address']=fields['tapplicant']['general_information']['address'];
    newApplicant['general_information']['age']=fields['tapplicant']['general_information']['age'];
    newApplicant['general_information']['gender']=fields['tapplicant']['general_information']['gender'];
    
    console.log(newApplicant);
    
    params[target_field].push(newApplicant);
    console.log(params); 
    if(applicant_type==="co_applicant")
    {
      console.log("adding coapplicant ")
      console.log(newApplicant)
      //return
      await loanService.addEditCoApplicant(customer._id,newApplicant)
      .then((ns)=> {
        console.log("Applicant added");
        window.alert("Details added");
        window.location.reload();
      })
      .catch((err)=> {
        console.log(err);
        window.alert("error adding details");
      })
    } 
    if(applicant_type==="guarantor")
    { 
      await loanService.addEditGuarantor(customer._id,newApplicant)
      .then((ns)=> {
        console.log("Applicant added");
        window.alert("Details added");
        window.location.reload();
      })
      .catch((err)=> {
        console.log(err);
        window.alert("error adding details");
      })
  }
    setSubmitting(false);
  }

  const updateKycRelation = (value, values, setFieldValue) => {
    // console.log(values.tapplicant.general_information.kyc_relation_type, "oo")
  if(values && values.tapplicant && values.tapplicant.general_information){
    console.log(value, "enter here")
    if(value === "S/o" || value === "D/o"){
      setFieldValue('tapplicant.general_information.kyc_relation_type', value)
      setFieldValue('tapplicant.general_information.kyc_relation', values.tapplicant.general_information.sonof)
    }else if(value === "W/o" ){
      setFieldValue('tapplicant.general_information.kyc_relation_type', value)
      setFieldValue('tapplicant.general_information.kyc_relation', values.tapplicant.general_information.wifeof)
    }else{
      setFieldValue('tapplicant.general_information.kyc_relation_type', value)
      setFieldValue('tapplicant.general_information.kyc_relation', "")
    }
  }
  }

  return (
    <div>
     
     <Button
        onClick={handleOpen}
        variant="contained"
        color="primary"
      >
        {applicant_type==="co_applicant" && (
             "Add Co-Applicant"
        )}
         {applicant_type==="guarantor" && (
             "Add Guarantor"
        )}
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Applicant Details</h2>
      
              <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize={true}
                id="transition-modal-description"
              >
              {({ errors, touched, isSubmitting, setFieldValue,values }) => {
                  return (
                    <Form>
                        <div className="form-row">
                        <div className="form-group col">
                        <label>Gender:</label><br/>
                        <Field
                          as="select"
                          style={{'marginTop':'10px'}}
                          name="tapplicant.general_information.title"
                          label="Title"
                          errors={errors}
                          touched={touched}
                        >
                          <option value="" disabled selected>Select Title</option>
                          {
                            titleOptions.map((g)=> {
                              return <option value={g}>{g}</option>
                            })
                          }
                          </Field>
                        </div>
                        <div className="form-group col">
                        <FormElement
                          type="text"
                          name="tapplicant.general_information.name"
                          label="Name"
                          errors={errors}
                          touched={touched}
                        />
                        </div>
                        <div className="form-group col">
                        <FormElement
                          type="text"
                          name="tapplicant.general_information.sonof"
                          label="Father Name"
                          errors={errors}
                          touched={touched}
                        />
                        </div>
                        <div className="form-group col">
                        <FormElement
                          type="text"
                          name="tapplicant.general_information.wifeof"
                          label="Husband Name"
                          errors={errors}
                          touched={touched}
                        />
                        </div>
                        <div className="form-group col">
                        <FormElement
                          type="text"
                          name="tapplicant.general_information.age"
                          label="Age"
                          errors={errors}
                          touched={touched}
                        />
                        </div>
                        

                       
                      </div>
                      <div className="form-row">
                      <div className="form-group col">
                        <label>Kyc Relation Type</label><br/>
                        <Field
                          as="select"
                          style={{'marginTop':'10px'}}
                          name="tapplicant.general_information.kyc_relation_type"
                          label="KYC relation type"
                          errors={errors}
                          touched={touched}
                          onChange={(e)=>updateKycRelation(e.target.value, values, setFieldValue)}
                        >
                          <option value="" disabled selected>Select Kyc relation type</option>
                          {
                            relationOptions.map((g)=> {
                              return <option value={g}>{g}</option>
                            })
                          }
                          </Field>
                        </div>
                        <div className="form-group col">
                        <FormElement
                          type="text"
                          name="tapplicant.general_information.kyc_relation"
                          label="Kyc Relation"
                          errors={errors}
                          touched={touched}
                        />
                        </div>
                      <div className="form-group col">
                        <FormElement
                          type="number"
                          name="tapplicant.general_information.aadhar"
                          label="Aadhar Number"
                          errors={errors}
                          touched={touched}
                        />
                        </div>
                        <div className="form-group col">
                        <FormElement
                          type="number"
                          name="tapplicant.general_information.contact"
                          label="Contact "
                          errors={errors}
                          touched={touched}
                        />
                        </div>
                        <div className="form-group col">
                        <FormElement
                          type="text"
                          name="tapplicant.general_information.pan_no"
                          label="PAN"
                          errors={errors}
                          touched={touched}
                        />
                        </div>
                       </div>
                      <div className="form-row">
                      <div className="form-group col">
                        <label>Gender:</label><br/>
                        <Field
                          as="select"
                          style={{'marginTop':'10px'}}
                          name="tapplicant.general_information.gender"
                          label="Gender"
                          errors={errors}
                          touched={touched}
                        >
                          <option value="" disabled selected>Select Gender</option>
                          {
                            genderOptions.map((g)=> {
                              return <option value={g}>{g}</option>
                            })
                          }
                          </Field>
                        </div>

                        <div className="form-group col">
                        <FormElement
                          type="text"
                          name="tapplicant.general_information.address"
                          label="Address"
                          errors={errors}
                          touched={touched}
                        />
                        </div>
                       </div>
                  <hr/>
                        <div className="row justify-content-around">
                        <Fab
                          type="submit"
                          disabled={isSubmitting}
                          color="primary"
                          aria-label="save"
                        >
                          {isSubmitting && (
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                          )}
                          <SaveIcon />
                        </Fab>
                      </div>
                      </Form>
                  );
                 }}
              </Formik>         
          </div>
        </Fade>
      </Modal>
    </div>
  );
}