import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { branchService } from "../../../_services/branch.service";
import { Formik,Form, Field } from "formik";
import * as Yup from "yup";
import FormElement from "../../uielements/FormElement";
import { alertService } from "../../../_services/alert.service";
import { adminservice } from "../../../_services/admin.services";
import {loanService} from "../../../_services/loan.service"

const useStyles = makeStyles((theme) => ({
  modal: {
    width:'75%',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function AddEditBranch({history,match}) {
  const classes = useStyles();
  const formikRef = React.useRef();
  const { id } = match.params;
  const isAddMode = !id;
  const [branch,setBranch]=React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [branchState,setBranchState]= React.useState("Rajasthan")
  const [branchDistrict,setBranchDistrict]  = React.useState("");
  const [city,setCity]=React.useState([])
  const [zoneList,setZoneList]= React.useState({})
  const [areaList,setAreaList]= React.useState({})
  const [regionList,setRegionList]= React.useState({})
  const [districtList,setDistrictList] = React.useState([])
  const [clusterList,setClusterList] = React.useState({})

  const stateList={
    'Rajasthan':{
      'Jaipur':['Jaipur','Bassi','Chomu'],
      'Dausa':['Dausa','Bandikui'],
      'Sikar':['Reengus','Laxmanghar','Sikar','Losal'],
      'Rajsamand':['Rajsamand','Bhim'],
      'Jhunjhunu':['Udaipurwati','Nawalgarh','Jhunjhunu'],
      'Alwar':['Gola ka Bas','Thanagazi','Alwar'],
      'Ajmer':['Ajmer','Beawar'],
      'Chittorgarh':['Chittorgarh','Begun'],
      'Udaipur':['Udaipur'],
      'Bhilwara':['Gangapur','Bhilwara'],
      'Pratapgarh':['Pratapgarh'],
      'Sirohi':['Sirohi'],
      'Tonk':['Tonk','Niwai','Uniara']
    }
  }

  React.useEffect(() => {
   
    if (!isAddMode) {
      // get user and set form fields

      branchService.getById(id).then((branch) => {
        const fields = [
          "branch_id",
          "branch_name",
          "branch_state",
          "branch_cluster",
          "branch_district",
          "branch_city",
          "branch_region",
          "branch_area",
          "branch_zone",
          "branch_login_fees"
        ];
        fields.forEach((field) => {
          if (formikRef.current) {
            formikRef.current.setFieldValue(field, branch[field], false);
          }
          if(field=="branch_state")
          {
            setBranchState(branch[field]);
          }
          if(field=="branch_district")
          {
            setBranchDistrict(branch[field]);
          }

        });
        setBranch(branch);
      });
    }
  }, [id, isAddMode, formikRef]);

  const getCityDetails = () => {
    console.log("getting cities list");
    adminservice.getCityDetails()
      .then((res) => {
        console.log("got cities list");
        let tmpZoneList={}
        let tmpAreaList={}
        let tmpRegionList={}
        let tmpDistrictList=[]
        let tmpClusterList={}
        res.map(c=> {
           console.log(c);
           if(!Object.keys(tmpZoneList).includes(c.district))
           {
            if(!c.zone)
            {
              tmpZoneList[c.district]='J03';
            }
            else
            {
              tmpZoneList[c.district]=c.zone;
            }
           }

           if(!Object.keys(tmpRegionList).includes(c.district))
           {
              if(!c.region)
              {
                  tmpRegionList[c.district]='J03';
              }
              else
              {
                  tmpRegionList[c.district]=c.region;
              }
              
           }

           if(!Object.keys(tmpAreaList).includes(c.district))
           {
              tmpAreaList[c.district]=c.area;
           }


           if(!tmpDistrictList.includes(c.district))
           {
              tmpDistrictList.push(c.district);
           }

           if(!Object.keys(tmpClusterList).includes(c.district))
           {
              tmpClusterList[c.district]=c.cluster;
           }

        }) 
        tmpDistrictList.sort()
        setZoneList(tmpZoneList)
        setRegionList(tmpRegionList)
        setAreaList(tmpAreaList)
        setDistrictList(tmpDistrictList)
        setClusterList(tmpClusterList)
        setCity(res)
        console.log(tmpZoneList)
        console.log(tmpAreaList)
        console.log(tmpRegionList)
        
      })
      .catch((err) => console.log(err))
  }
  React.useEffect(() => {
    getCityDetails()
  }, [open])
 console.log("Test city",city)

//console.log(stateList);


  const initialValues = {
    branch_id: "",
    branch_name: "",
    branch_state: "Rajasthan",
    branch_district: "",
    branch_city: "",
    branch_area:"",
    branch_region:"",
    branch_cluster:"",
    branch_zone:"",
    branch_login_fees:""
  };


  const validationSchema = Yup.object().shape({
    branch_id: Yup.string().required("This field is required"),
    branch_name:Yup.string().required("This field is required"),
    branch_state: Yup.string().required("This field is required"),
    branch_district: Yup.string().max(40).required("This field is required"),
    branch_city: Yup.string().required("This field is required"),
  });

  const onBranchStateChanged=(newState,setFieldValue)=> {
    setBranchState(newState)
    setFieldValue("branch_state",newState)
  }

  const onBranchDistrictChanged= (newDistrict,setFieldValue) => {
    setBranchDistrict(newDistrict)
    setFieldValue("branch_district",newDistrict)
  }
  
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function onSubmit(fields, { setSubmitting }) {
    fields['branch_id']=fields['branch_id'].toUpperCase();
    fields['branch_name']=fields['branch_name'].toUpperCase();
    console.log("District is "+fields.branch_district);
    let fieldValues={
        ...fields,
        // branch_zone: zoneList[fields.branch_district],
        // branch_area: areaList[fields.branch_district],
        // branch_region: regionList[fields.branch_district],
    }
    console.log(fieldValues);
    //return;
    if(isAddMode)
    {
      branchService.create(fieldValues).then(()=>{
        console.log("added branch");
        alertService.success("User added successfully", {
          keepAfterRouteChange: true,
        });
        history.push(".");
      })
      .catch((err)=> {
        window.alert("error creating branch");
        console.log(err);
      })
    }
    else if(!isAddMode)
    {
      branchService.update(branch._id,fieldValues).then(()=>{
        console.log("updated branch");
        alertService.success("User updated successfully", {
          keepAfterRouteChange: true,
        });
        history.push("..");
      })
      .catch((err)=> {
        window.alert("error creating branch");
        console.log(err);
      })
    }
    
    setSubmitting(false);
    handleClose();
  }
  const onCityChange=(e,setFieldValue)=>{
    setFieldValue('branch_city',e.city)
    setFieldValue('branch_zone',e.zone||'')
    setFieldValue('branch_cluster',e.cluster||'')
    setFieldValue('branch_region',e.region||'')
    setFieldValue('branch_area',e.area||'')
  }
  return (
          <div>
            {isAddMode && (
              <h2 id="transition-modal-title">Add Branch</h2>
            )}
            {!isAddMode && (
              <h2 id="transition-modal-title">Edit Branch</h2>
            )}

            <Formik
                innerRef={formikRef}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                id="transition-modal-description"
              >
                {({ errors, touched, isSubmitting,  setFieldValue, values }) => {
                  return (<Form>

              <div className="form-row">
                <div className="form-group col">
                <FormElement
                  disabled={!isAddMode}
                  type="text"
                  name="branch_id"
                  label="Branch Code"
                  errors={errors}
                  touched={touched}
                />
                </div>
                <div className="form-group col">
                <FormElement
                  type="text"
                  name="branch_name"
                  label="Branch Name"
                  errors={errors}
                  touched={touched}
                />
                </div>
                <div className="form-group col">
                <p>Branch State:</p>
                <Field
                  style={{marginTop:"-8px"}}
                  as="select"
                  className="form-control" 
                  name="branch_state"
                  onChange={(e)=>onBranchStateChanged(e.target.value,setFieldValue)} 
                  label="Branch State" 
                >
                  <option value="" disabled>Select State</option> 
                {Object.keys(stateList).map((state,idx)=> (
                    <option value={state} key={idx}>{state}</option>
                  ))}
                </Field>
                </div>
              </div>
              <div className="form-row">
                {/* <div className="form-group col">
                <p>Branch State:</p>
                <Field
                  as="select"
                  className="form-control" 
                  name="branch_state"
                  onChange={(e)=>onBranchStateChanged(e.target.value,setFieldValue)} 
                  label="Branch State" 
                >
                  <option value="" disabled>Select State</option> 
                {Object.keys(stateList).map((state,idx)=> (
                    <option value={state} key={idx}>{state}</option>
                  ))}
                </Field>
                </div> */}
                <div className="form-group col">
                <p>Branch District:</p>
                <Field
                 className="form-control"
                 as="select"
                 name="branch_district" 
                 label="Branch District" 
                 onChange={(e)=>onBranchDistrictChanged(e.target.value,setFieldValue)} 
                 required
                >
                    {/* <option value="" disabled>Select District</option>
                    {Object.keys(stateList[branchState]).map((v,idx)=>(
                      <option key={idx} value={v}>{v}</option>
                    ))} */}
                          <option value="" disabled>Select District</option>
                          {districtList.map((dist, idx) => (
                            <option value={dist} key={idx}>{dist}</option>
                          ))}
                </Field>
                </div>
                <div className="form-group col">
                  <p>Branch City:</p>
                <Field 
                as="select"
                required
                className="form-control" 
                name="branch_city" 
                label="Branch City" 
                // onChange={(e) => onCityChange(city.find(c => c.city === e.target.value), setFieldValue)}
                onChange={(e) => {
                  const selectedCity = city.find(c => c.city === e.target.value);
                  onCityChange(selectedCity, setFieldValue);
                }}
                >
                <option value="">Select City</option>
                   {city.filter(x=>x.district===values.branch_district).map((c, idx) => (
                            <option value={c.city} key={idx}>{c.city}</option>
                          ))}        
                </Field>
                </div> 
                <div className="form-group col" style={{marginTop:"10px"}}>
                <FormElement
                  type="text"
                  name="branch_zone" 
                  label="Branch Zone:"
                  disabled
                />
                </div>
              </div>
              <div className="form-row">
              <div className="form-group col">
                <FormElement
                  style={{marginTop:"8px"}}
                  type="text"
                  name="branch_region" 
                  label="Branch Region:"
                  disabled
                />
                </div>
                <div className="form-group col">
                <FormElement
                  type="text"
                  name="branch_area"  
                  label="Branch Area:"
                  disabled
                />
                </div>
                <div className="form-group col">
                <FormElement
                  style={{marginTop:"8px"}}
                  type="text"
                  name="branch_cluster" 
                  label="Branch Cluster:"
                  disabled
                />
                </div>
                <div className="form-group col">
                <FormElement
                  style={{marginTop:"8px"}}
                  type="text"
                  name="branch_login_fees" 
                  label="Branch Login Fees:"
                   
                />
                </div>
              {/* <div className="form-group col">
                <p>Branch Cluster:</p>
                <Field 
                as="select"
                className="form-control" 
                name="branch_cluster"
                value={values.branch_cluster} 
                label="Branch Cluster" 
                >
                <option value="">Select Cluster</option>
                    {Object.keys(clusterList).filter(x=>x===values.branch_district).map((cul, idx) => (
                            <option value={clusterList[cul]} key={idx}>{clusterList[cul]}</option>
                          ))}
                </Field>
                </div>   */}

                  {/* <div className="form-group col">
                  <p>Branch City:</p>
                <Field 
                as="select"
                className="form-control" 
                name="branch_city" 
                label="Branch City" 
                value={values.branch_city}
                >
                <option value="">Select City</option>
                   {city.filter(x=>x.district===values.branch_district).map((c, idx) => (
                            <option value={c.city} key={idx}>{c.city}</option>
                          ))}
                </Field>
                </div>     */}
              </div>
              <hr />
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                Submit
              </button>

                  </Form>)}}
              </Formik>
          </div>
  );
}
