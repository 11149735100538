import { Button } from '@material-ui/core'
import React, { useRef } from 'react'
import { Table, Th, Thead, Td, Tr, Tbody } from 'react-super-responsive-table'
import { useReactToPrint } from 'react-to-print'
import datehelper from '../../_helpers/datehelper'
const ViewCashRequest = (props) => {
  const data=props.location.state
  console.log("test props",data)
  let cashRequestRef = useRef()
  const handleCashRequestprint = useReactToPrint({
    content: () => cashRequestRef.current
  })
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <button onClick={() => handleCashRequestprint()}>
          Print Cash Request
        </button>
      </div>
      <div
        ref={cashRequestRef}
        style={{
          width: "288mm",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: '50px',
          bgcolor: "background.paper",
          padding: 45,
          background: "white"
        }}
      >  
      <h2 style={{ textAlign: "center" }}>Cash Request Slip</h2>
      <br/>
        <Table>
          <Thead style={{fontWeight: 'bold', fontSize: "18px" }}>
            <Tr>
              <Th>Amount</Th>
              <Th>Approved Amount</Th>
              <Th>Branch</Th>
              <Th>Request Creation Date</Th>
              <Th>Requested By</Th>
              <Th>Request Approval Date</Th>
              <Th>Request Approved By</Th>
              <Th>Cheque Number</Th>
            </Tr>
          </Thead>
          <Tbody>
                <Tr style={{ fontSize: "18px" }}>
                  <Td>{data.amount.toLocaleString("en-IN", { style: 'currency', currency: 'INR' })}</Td>
                  <Td>{data.approval_amount && data.approval_amount.toLocaleString("en-IN", { style: 'currency', currency: 'INR' })}</Td>
                  <Td>{data.branch}</Td>
                  <Td>{datehelper.displayDateTime(new Date(data.requested_date))}</Td>
                  <Td>{data.requested_by}</Td>
                  <Td>{data.request_approval_date && datehelper.displayDateTime(new Date(data.request_approval_date))}</Td>
                  <Td>{data.approved_by}</Td>
                  <Td>{data.approved_cheque_no}</Td>
                </Tr>
          </Tbody>
        </Table>
      </div>
    </div>
  )
}

export default ViewCashRequest
