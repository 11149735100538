import React from "react";
import * as Yup from "yup";
import { Formik,Form, Field } from "formik";
import FormElement from "../../../uielements/FormElement";
import { employeeService, loanService } from "../../../../_services";
import {
  withStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@material-ui/core";
import DocumentListInputSheet from "../../InputSheets/DocumentList/InputSheet";
import { Typography } from "antd";
import StageSteps from "./StageSteps";
import roleChecker from "../../../../_helpers/rolechecker";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(key,label,value,editable,type) {
  return { key,label, value,editable,type};
}

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function CollateralData({ customer }) {
  const classes = useStyles();
  const formikRef = React.useRef();
  const [editing,setEditing]= React.useState(false);
  const user = employeeService.userValue;
  
  const id= customer._id;
  React.useEffect(()=> {
    
    if(customer.property_details)
    {
      const fields = [
        'property_details'
      ];
      fields.forEach((field) => {
        if (formikRef.current) {
          formikRef.current.setFieldValue(field, customer[field], false);
        }
      });
    }

  },[]);
  const initialValues = {
    'property_details': {
       'type':"",
       "area":"",
       "owner":"",
       "valuation":"",
       "address":"",
    },
  };
  

  const validationSchema = Yup.object().shape({
    property_details:Yup.object().shape({
      type: Yup.string().required("This field is required"),
      area: Yup.number().required("This field is required"),
      owner: Yup.string().required("This field is required"),
      valuation: Yup.number().required("This field is required"),
      address: Yup.string().required("This field is required"),
      
    }),
  });

  async function onSubmit(fields) {
    console.log("in submit function");
    console.log(fields);
    console.log("submitting form");
    await loanService.update(customer._id,fields).then((applicant)=> {
      window.alert("Loan File Updated"+applicant.application_id);
      window.location.reload(false);
    })
    .catch((err)=> {
      window.alert("error updating Loan Application");
      console.log("error"+err)
    })
  }

  async function saveEdits(fields)
  {
    setEditing(false);
    onSubmit(fields);
  }

  
  const rows=[];
  if(customer.property_details)
  {

    rows.push(createData("property_details.type","Property Type", customer.property_details.type,true,"text"));
    rows.push(createData("property_details.area","Area (Sq Yards)", customer.property_details.area,true,"number"));
    rows.push(createData("property_details.owner","Property Owner", customer.property_details.owner,true,"text"));
    rows.push(createData("property_details.valuation","Market Value", customer.property_details.valuation,true,"number"));    
    rows.push(createData("property_details.address","Property Address", customer.property_details.address,true,"text"));
  }
  else
  {
    rows.push(createData("property_details.type","Property Type", "",true,"text"));
    rows.push(createData("property_details.area","Area (Sq Yards)", "",true,"number"));
    rows.push(createData("property_details.owner","Property Owner", "",true,"text"));
    rows.push(createData("property_details.valuation","Market Value", "",true,"number"));
    rows.push(createData("property_details.address","Property Address", "",true,"text"));    
    
  }
const handleWatsappMessage=(customer)=>{
  

   loanService.docPendingWatsapp({application_id:customer.application_id})
   .then((res)=>{
    
    console.log("response for watsapp is",res)
window.alert(res.message)
   })
   .catch((err)=>console.log('err',err))
}

  return (
    <>
    <div>

    <h2>Collateral Check list</h2>
   <Paper style={{borderRadius: '10px', margin: '3px', padding: '15px' }}>
<h4>Click Below , to Send a Whatsapp Message to Customer for Pending Documents </h4>
    <Button color="primary" variant="outlined" style={{margin:'1rem'}} onClick={()=>{if(window.confirm('Are you sure , you want to send a message for pending documents')){
 handleWatsappMessage(customer)
    }}} > <WhatsAppIcon/>Send Whatsapp Message</Button>
   </Paper>
    </div>
    <StageSteps customer={customer} filterId="Collateral"/>
    <br/>
    <Formik
                innerRef={formikRef}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                id="transition-modal-description"
              >
                {({ errors, touched, isSubmitting,  setFieldValue, values }) => {
                  return (<Form>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Property Details</StyledTableCell>
            <StyledTableCell align="right">
            {!editing && (
                  <a 
                      onClick={()=>setEditing(true)}>Edit</a>
              )}
              {editing && (
                  <a 
                      onClick={()=>saveEdits(values)}>Save</a>
              )}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        

          {rows.map((row) => (
            <StyledTableRow key={row.label}>
              <StyledTableCell component="th" scope="row">
                {row.label}
              </StyledTableCell>
              {!editing &&  (
                <StyledTableCell align="right">{row.value}</StyledTableCell>
              )}
              {editing && row.editable && (row.type=== "text" || row.type==="number") && (
                <StyledTableCell align="right">
                  <FormElement
                  type={row.type}
                  name={row.key}
                  label={row.label}
                  errors={errors}
                  touched={touched}
                />
                </StyledTableCell>
              )}
              {editing && row.editable && row.type=== "select" && (
                <StyledTableCell align="right">
                  <Field
                  as="select"
                  name={row.key}
                  label={row.label}
                  errors={errors}
                  touched={touched}
                />
                </StyledTableCell>
              )}

              {editing && !row.editable && (
                <StyledTableCell align="right">
                  <FormElement
                  type="text"
                  disabled={true}
                  name={row.key}
                  label={row.label}
                  errors={errors}
                  touched={touched}
                />
                </StyledTableCell>
              )}
              
              
            </StyledTableRow>
          ))}

            

        </TableBody>
      </Table>
    </TableContainer>

     
    <br></br>

    <Typography variant="h5">Document List</Typography>
                    <br />
                    <div style={{ border: "1px dotted black", padding: 15 }}>
                      <DocumentListInputSheet
                        setFieldValue={setFieldValue}
                        customer={customer}
                      ></DocumentListInputSheet>
                    </div>
                    <br/>
                    <div style={{ border: "1px dotted black", padding: 15 }}>
                    {roleChecker.isHOLevelAccess(user.role) && (
                    <Typography variant="h5">Application Folder</Typography>
                    )}
                    <br />      
                    {/* {roleChecker.isHOLevelAccess(user.role) && customer.application.folder_scan_url && customer.application.folder_scan_url !=="" && (
                        <a href={`/home/pdfviewer?pdf_url=`+ urlhelper.encodeToBase64(customer.application.folder_scan_url)}>Folder Scan</a>
                    )}
                    {roleChecker.isHOLevelAccess(user.role) && (!customer.application.folder_scan_url || customer.application.folder_scan_url ==="") && (
                        <SBLFolderUpload id={customer._id} application_id={customer.application_id}></SBLFolderUpload>
                    )}  */}
                    </div>                   
    </Form>)}}
    </Formik> 
</>
);
}
