import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { employeeService } from '../../../_services';
import { allcloudtokenServices } from '../../../_services/allcloudtoken.service';

function AllCloudToken() {

    const [employee,setEmployee]=useState([]);



    const initialValues = {
        emp_id: "",
        allcloud_username: "",
        user_token: "",
        secret_token: "",
    };

    const validationSchema = Yup.object().shape({
        emp_id: Yup.string().required("This field is required"),
        allcloud_username: Yup.string().required("This field is required"),
        user_token: Yup.string().required("This field is required"),
        secret_token: Yup.string().required("This field is required"),
    });

    const onSubmit = (values, { setSubmitting }) => {
        // Your submit logic goes here
        allcloudtokenServices.createAllCloudToken(values)
        .then((res)=>{
            window.location.reload()
            console.log('res',res)
        })
        .catch((err)=>{
            window.alert("error creating user");
            console.log(err)})
        console.log(values);
        setSubmitting(false);
    };

   

useEffect(()=>{
   employeeService.getAll()
   .then((res)=>setEmployee(res))
   .catch((err)=>console.log(err))
},[])

console.log(employee)
    return (
        <div>
            <h2 id="transition-modal-title">Add AllCloud Tokens</h2>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ errors, touched, isSubmitting }) => (
                    <Form>
                        <div className="form-row">
                            <div className="form-group col">
                                <label>Emp Id:</label>
                                <Field
                                    as="select"
                                    name="emp_id"
                                    className={
                                        "form-control" +
                                        (errors.emp_id && touched.emp_id ? " is-invalid" : "")
                                    }
                                >
                                    <option value="" disabled>Select Emp Id</option>
                                    {employee &&  employee.map((e) => (
                                        <option key={e.emp_id} value={e.emp_id}>
                                            {e.emp_id}
                                        </option>
                                    ))}
                                </Field>
                                <ErrorMessage
                                    name="emp_id"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>
                       
                       
                            <div className="form-group col">
                                <label>AllCloud Username:</label>
                                <Field
                                    name="allcloud_username"
                                    type="text"
                                    className={
                                        "form-control" +
                                        (errors.allcloud_username && touched.allcloud_username
                                            ? " is-invalid"
                                            : "")
                                    }
                                />
                                <ErrorMessage
                                    name="allcloud_username"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>
                            </div>
                        <div className="form-row">
                            <div className="form-group col">
                                <label>User Token:</label>
                                <Field
                                    name="user_token"
                                    type="text"
                                    className={
                                        "form-control" +
                                        (errors.user_token && touched.user_token ? " is-invalid" : "")
                                    }
                                />
                                <ErrorMessage
                                    name="user_token"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>
                       
                             <div className="form-group col">
                                <label>Secret Token:</label>
                                <Field
                                    name="secret_token"
                                    type="text"
                                    className={
                                        "form-control" +
                                        (errors.secret_token && touched.secret_token
                                            ? " is-invalid"
                                            : "")
                                    }
                                />
                                <ErrorMessage
                                    name="secret_token"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>
                            </div>
                        <hr />
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={isSubmitting}
                        >
                            Submit
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default AllCloudToken;
