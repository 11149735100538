import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import FormElement from "../../../uielements/FormElement";
import { employeeService, loanService, customerService } from "../../../../_services";
import {
    withStyles,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import './style.css'
import { Button } from "@material-ui/core";
import datehelper from "../../../../_helpers/datehelper";


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function createData(key, label, value, editable, type, options = []) {
    return { key, label, value, editable, type, options };
}

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

export default function TrackingData({ customer }) {
    const classes = useStyles();
    const [employeeList, setEmployeeList] = React.useState({});
    React.useEffect(() => {
        employeeService.getAll()
            .then((el) => {
                let mel = {};
                el.map((e) => {
                    mel[e.emp_id] = e.first_name + " " + e.last_name;
                    return e;
                });
                setEmployeeList(mel);
            })
            .catch((err) => {
                console.log(err);
            });

    }, []);


console.log(customer.application_id,"customercustomer")

const [loantrackingResponse, setLoantrackingResponse] = useState([]);
const [errorMessage, setErrorMessage] = useState('');
const loantracking = async () => {
    try {
        // const data = await customerService.loantracking({ application_id: "KR4313232" });
        const data = await customerService.loantracking({ application_id: customer && customer.application_id });
        if (data.status === 'success') {
            setLoantrackingResponse(data?.data?.event_list);
        } else if(data.status === 'failed'){
            setErrorMessage(data.message);
        }else if(data.status === 'error'){
            setErrorMessage(data.message);
        }else{
            console.log(data)
        }
    } catch (err) {
        console.log(err);
    }
};


useEffect(() => {
    loantracking();
}, []);
console.log(loantrackingResponse,"loantrackingResponse")
const splitData = loantrackingResponse.map(item => item.split(','));
    return (
        <div>
            <div style={{ "padding": 10 }}>
                <h2><b>Loan History</b></h2>
            </div>
            <table style={{ width: "100%", border: "1px solid", padding: "10px" }}>
                <tr><th>Change Date</th><th>Stage</th><th>Sub-Stage</th><th>Assigned To</th><th>Assigned By</th> <th>Comments</th></tr>
                {customer.stage_history && customer.stage_history.map((sh) => {
                    return (<tr style={{ border: "1px solid", padding: "10px" }}><td>{datehelper.displayDate(new Date(sh.stage_assign_date))}</td><td style={{ fontWeight: "bold" }}>{sh.stage}</td><td>{sh.sub_stage}</td><td>{employeeList[sh.stage_emp_id]}</td><td>{employeeList[sh.updated_by]}</td> {sh.comments ? <td>{sh.comments}</td> : ""} </tr>)
                })}
            </table>


            <h3><b>Loan Tracking</b></h3>
            {errorMessage &&   <div class="col mt-1">
    <div class="alert alert-info" role="alert">
    {errorMessage}
    </div>
  </div>}
        {/* Render sorted array of items if data status is not 'error' */}
       
            <ul className="tracking_list-unstyled">
                {splitData && splitData.map((item, index) => (
                    <li key={index} className="tracking_history-item">
                        <div className="tracking_flex tracking_flex-align-items-stretch">
                            <div className="tracking_flex tracking_flex-align-items-center">
                                <div className="tracking_circle"></div>
                            </div>
                            <div style={{ paddingTop: "0.5rem" }}>
                                <div className="tracking_note-text">
                                    {item}
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        

        </div>
    );
}


