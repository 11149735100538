import React from "react";
import { useState, useEffect } from "react";
import { Modal, Box, Typography, Button } from "@material-ui/core";
import { AddOutlined } from "@material-ui/icons";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { Paper } from "@material-ui/core";
import { branchService } from "../../../_services/branch.service";
import { employeeService } from "../../../_services";
import { ledgerService } from "../../../_services/ledger.service";
import * as Yup from "yup";

export const AddLedgerResetModal = ({id,name}) => {
    const user=employeeService.userValue
    const [open, setOpen] = useState(false)
    const [branches, setBranches] = useState([])

    const handleOpen = () => setOpen(true)

    const handleClose = () => setOpen(false)
  
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 640,
        height: 370,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'scroll',
        //paddingBottom: '30px',
    };

    const initialValues = {
        opening_balance: null,
    }

    const onSubmit = (values) => {
        console.log("Test value",values)
        ledgerService.resetLedger(id,{values, added_by_emp_id:user.emp_id})
        .then(res=>{
            window.alert('Ledger reset')
            window.location.reload()
        })
        .catch(err=> {
            window.alert('Some error occurred')
            console.log(err)
        })
    }

    const validationSchema = Yup.object().shape({
        
        opening_balance: Yup.number().min(0, "Opening balance can not be negative").required("This field is required")
    })

    function getBranches() {
        branchService.getAll()
            .then(res => setBranches(res))
            .catch(err => console.log(err))
    }

    useEffect(() => {
        getBranches()
    },
        [])

    return (
        <div>
            <Button onClick={handleOpen} variant="outlined" size="small">reset</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <Formik
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            validationSchema={validationSchema}
                        >
                            {({ errors, touched, isSubmitting, values, setFieldValue }) => {
                                return <Form>
                                    <Paper style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '10px', padding: '10px' }}>
                                        <h3>Update  Ledger</h3>
                                    </Paper>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                                    
                                         
                                       
                                        
                                        <div className="form-group col-md-6">
                                            <h6>Opening Balance</h6>
                                            <Field
                                                type="number"
                                                name="opening_balance"
                                                className={
                                                    "form-control" +
                                                    (errors.opening_balance && touched.opening_balance
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name="opening_balance"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button type="submit" variant="contained" color="primary">Submit</Button>
                                    </div>
                                </Form>
                            }}
                        </Formik>
                    </Typography>
                </Box>
            </Modal>
        </div>
    )
}