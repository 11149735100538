
import React, { useEffect, useRef } from "react"
import { useState } from "react"
import { Modal, Box, Typography, Button } from "@material-ui/core"
import { loanService } from "../../_services"
import { employeeService } from "../../_services"
import { ledgerService } from "../../_services/ledger.service"

export const OtpVerificationModal = (props) => {
    let pendingTransactions = props.pendingTransactions
    const [open, setOpen] = useState(false)
    const [otp, setOtp] = useState({ otp1: '', otp2: '', otp3: '', otp4: '' })
    const [otpResponse, setOtpResponse] = useState('')
    const [spinner,setSpinner] = useState(false)
    const formRef=useRef()
    const user = employeeService.userValue
    const emp_contact = user.contact
    const [approvedTransactions, setApprovedTransactions] = useState([])

    const handleOpen = () => {
        let approved_transactions = pendingTransactions.filter(trxn => { return trxn.checked === true })
        setApprovedTransactions(approved_transactions)
        console.log(approved_transactions)
        if (approved_transactions.length > 0) {
            let total = approved_transactions.reduce((acc, curr) => { return acc + curr.cash_summary.total_amount }, 0)
            let confirm = window.confirm(`Are sure you want to approve transactions of worth ${total.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
            })}`)
            if (confirm) {
                setOpen(true)
                sendOtp()
            }
        } else {
            window.alert('Please select at least one transaction')
        }
    }

    const handleClose = () => {
        setOtp({ otp1: '', otp2: '', otp3: '', otp4: '' })
        setOpen(false)
    }

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "680px",
        height: "480px",
        bgcolor: "background.paper",
        border: "2px solid #000",
        // overflow: "scroll",
        boxShadow: 24,
        p: 4,
    };

    const inputFocus = (elmnt) => {
        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
            const next = elmnt.target.tabIndex - 2;
            if (next > -1) {
                elmnt.target.form.elements[next].focus()
            }
        }
        else {
            console.log("next");
            const next = elmnt.target.tabIndex;
            if (next < 4) {
                elmnt.target.form.elements[next].focus()
            }
        }
    }

    const handleChange = (e) => {
        let item = { ...otp }
        item[e.target.name] = e.target.value
        setOtp(item)
    }

    const handleSubmit = (e) => {
        setSpinner(true)
        e.preventDefault()
        let input_otp = otp.otp1 + otp.otp2 + otp.otp3 + otp.otp4
        console.log(approvedTransactions)
        let transactions = [...approvedTransactions]
        if (input_otp.toString() === otpResponse.toString()) {
            ledgerService.approveLedgerTransactions({
                transactions: transactions,
                verification_details: {
                    approver_emp_id: user.emp_id,
                    approver_mobile_no: emp_contact,
                    verification_otp: otpResponse,
                    date: new Date()
                }
            })
                .then(res => {
                    setSpinner(false)
                    window.alert('Transactions Approved')
                    window.location.reload()
                })
                .catch(err => {
                    setSpinner(false)
                    window.alert('Some Error Occurred')
                    console.log(err)
                })
        } else {
            setSpinner(false)
            window.alert('Incorrect Otp')
            setOtp({ otp1: '', otp2: '', otp3: '', otp4: '' })
        }
    }

    // function autoSubmit() {
    //     if (otp.otp1 !== '' && otp.otp2 !== '' && otp.otp3 !== '' && otp.otp4 !== '') {
    //         formRef.current.click()
    //     }
    // }

    // useEffect(()=>{
    //     autoSubmit()
    // })

    const resendOtp = () => {
        setOtp({ otp1: '', otp2: '', otp3: '', otp4: '' })
        sendOtp()
    }

    const sendOtp = () => {
        let random_num = Math.floor(1000 + Math.random() * 9000);
//         console.log("test otp", random_num)
        loanService.sendOtp({ contact: emp_contact, otp: random_num })
            .then((data) => {
                setOtpResponse(data.otp)
                // setSentOtp(true)
            })
            .catch((err) => console.log(err))
    }


    return (
        <div>
            <Button onClick={handleOpen}
                color="primary"
                variant="outlined"
            >
                Approve
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={style}>
                    <Box style={{ fontSize: 'medium' }}>
                        <div
                            style={{
                                border: '1px solid Black',
                                width: '580px',
                                height: '380px',
                                margin: 'auto',
                                marginTop: '10px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                paddingTop: '20px'
                            }}>
                            <h3><u>Verify OTP</u></h3>
                            <div
                                style={{
                                    border: '1px solid Black',
                                    width: '500px',
                                    height: '250px',
                                    marginTop: '5px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-around'
                                }}>
                                <h5>OTP has sent to the mobile number: xxxxxx{emp_contact && emp_contact.toString().slice(-4) && <>{emp_contact.toString().slice(-4)}</>}</h5>
                                <h5>Enter the OTP below</h5>
                                <form
                                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                    id='form'
                                    onSubmit={(e) => handleSubmit(e)}>
                                    <div
                                        style={{
                                            width: '250px',
                                            display: 'flex',
                                            marginBottom: '20px',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <input
                                            style={{ border: '1px solid Black', width: '40px', height: '45px', borderRadius: '5px', textAlign: 'center' }}
                                            autoComplete='off'
                                            name='otp1'
                                            tabIndex='1'
                                            maxLength='1'
                                            type="text"
                                            value={otp.otp1}
                                            onKeyUp={e => inputFocus(e)}
                                            onChange={e => handleChange(e)}
                                            required />
                                        <input
                                            style={{ border: '1px solid Black', width: '40px', height: '45px', borderRadius: '5px', textAlign: 'center' }}
                                            autoComplete='off'
                                            name="otp2"
                                            tabIndex='2'
                                            maxLength='1'
                                            type="text"
                                            value={otp.otp2}
                                            onKeyUp={e => inputFocus(e)}
                                            onChange={e => handleChange(e)}
                                            required />
                                        <input
                                            style={{ border: '1px solid Black', width: '40px', height: '45px', borderRadius: '5px', textAlign: 'center' }}
                                            autoComplete='off'
                                            name='otp3'
                                            tabIndex='3'
                                            maxLength='1'
                                            type="text"
                                            value={otp.otp3}
                                            onKeyUp={e => inputFocus(e)}
                                            onChange={e => handleChange(e)}
                                            required />
                                        <input
                                            style={{ border: '1px solid Black', width: '40px', height: '45px', borderRadius: '5px', textAlign: 'center' }}
                                            autoComplete='off'
                                            name='otp4'
                                            tabIndex='4'
                                            maxLength='1'
                                            type="text"
                                            value={otp.otp4}
                                            onKeyUp={e => inputFocus(e)}
                                            onChange={e => handleChange(e)}
                                            required />
                                    </div>
                                    <div>
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            id="form_button"
                                            ref={formRef}
                                            type="submit"
                                            disabled={spinner}
                                        >
                                           {spinner && <span className="spinner-border spinner-border-lg align-center"></span>} Verify
                                        </Button>
                                        <Button
                                            color="secondary"
                                            variant="outlined"
                                            style={{ marginLeft: '10px' }}
                                            type="button"
                                            onClick={resendOtp}
                                        >
                                            Resend OTP
                                        </Button>
                                    </div>
                                </form>
                            </div>
                            {/* {console.log(otp)} */}
                        </div>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}
