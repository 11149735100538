import React from 'react';
import { useState } from 'react';
import Search from '@material-ui/icons/Search';
import GoldAuctionServices from '../../../_services/goldauction.service';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { useEffect } from 'react';
import { history } from '../../../_helpers';
import { Button } from '@material-ui/core';
import urlhelper from '../../../_helpers/urlhelper';
import datehelper from '../../../_helpers/datehelper';
import { employeeService } from '../../../_services';
import { CircularProgress } from '@material-ui/core';
// import { employeeService } from '../../../_services';
// import ClearOutlined from '@material-ui/icons/ClearOutlined';
// import { uploadFileToBucket } from '../../../_helpers/upload';

export const Publication = (props) => {
    const [loanNo, setLoanNo] = useState('')
    const [value, setValue] = useState([])
    const [employeeList, setEmployeeList] = useState({})
    const [show, setShow] = useState(false)
    // const [publication, setPublication] = useState({
    //     publication: {
    //         publication1: '',
    //         publication2: ''
    //     }
    // })
    // const user = employeeService.userValue;
    // const [temp, setTemp] = useState(false)
    // const [selectedFile, setFileOne] = useState({})
    // const [auctionDetail, setAuctionDetail] = useState({})
    // const [edit, setEdit] = useState(false)
    // const [count, setCount] = useState(true);
    // const [open, setOpen] = React.useState(false);
    // const bucket = process.env.REACT_APP_s3goldBucket;

    const handleChange = (value) => {
        setLoanNo(value);
    }
    const handleSearch = async () => {
        setValue([])
        setShow(false)
        // setAuctionDetail({})
        await GoldAuctionServices.FindByLoan({ loan_no: loanNo })
            .then((res) => {
                // if (res.length > 0 && res[0].notice1 && res[0].notice1.receiving_date && res[0].notice2 && res[0].notice2.receiving_date) {
                if (res.length > 0 && res[0].publication && (res[0].publication.publication1 || res[0].publication.publication2)) {
                    setValue(res)
                };
                setShow(true)
                // else {
                //     setAuctionDetail(res[0]);
                // }
                // res[0] && res[0].publication && setPublication({
                //     ...publication,
                //     publication: {
                //         ...publication.publication,
                //         publication1: res[0].publication.publication1,
                //         publication2: res[0].publication.publication2
                //     }
                // })

                // } else {
                //     window.alert('First fill notice details')
                // }
                // setShow(true)
            })
            .catch((err) => console.log(err))
    }

    async function getAllEmployees() {
        await employeeService.getAll()
            .then((res) => {
                let list = {};
                res.map(e => {
                    list[e.emp_id] = e.first_name + ' ' + e.last_name
                })
                setEmployeeList(list)
            })
    }

    useEffect(() => {
        async function getNoticeDetails() {
            await GoldAuctionServices.getNoticeDetails({ element: 'publication' })
                .then((res) => {
                    setValue(res);
                    setShow(true)
                })
                .catch((err) => console.log(err))
        }
        getNoticeDetails();
        getAllEmployees();
    }, [])

    // const handleAppend = async (e) => {
    //     let file = e.target.files[0];
    //     let name = e.target.name;
    //     let fileName = name + '.pdf';
    //     let link = await uploadFileToBucket(bucket, 'application/pdf', `${bucket}/${auctionDetail.loan_no}/publications/${fileName}`, file);
    //     setPublication({
    //         ...publication,
    //         publication: {
    //             ...publication.publication,
    //             [name]: link
    //         }
    //     })
    // }

    // const discardPublication = (id) => {
    //     if (id === 'one') {
    //         setPublication({ ...publication, publication: { ...publication.publication, publication1: '' } })
    //     } else if (id === 'two') {
    //         setPublication({ ...publication, publication: { ...publication.publication, publication2: '' } })
    //     }
    // }
    // const handleEdit = async (x) => {
    //     setAuctionDetail(x)
    //     setTemp(true)
    //     setPublication({
    //         ...publication,
    //         publication: {
    //             ...x.publication,
    //             publication1: x.publication.publication1,
    //             publication2: x.publication.publication2
    //         }
    //     })
    //     setShow(true)
    //     setCount(false);
    // }
    // const Submit = async () => {
    //     await GoldAuctionServices.UpdateNoticeDetails(auctionDetail._id, publication)
    //         .then(res => {
    //             alert('Publication details saved');
    //             window.location.reload();
    //         })
    //         .catch(err => {
    //             console.log(err);
    //             alert('Something Went Wrong')
    //         })
    // }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <Button onClick={() => props.history.push(`/home/goldloanletters/publicationupload`)} style={{ marginLeft: '80%', color: 'blue' }}> Upload Csv </Button>
            <h1>Publications</h1>
            <span>
                {/* {
                    temp ? "Loan No :-" + auctionDetail.loan_no : */}
                <form style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: '20px' }} >
                    <input type="text" placeholder='Loan Number' style={{ borderRadius: '5px' }} value={loanNo} onChange={(e) => handleChange(e.target.value.toUpperCase())} />
                    <button type='button' style={{ borderRadius: '5px', color: 'white', backgroundColor: '#0066cc', border: 'none', width: "70px", marginLeft: '10px' }} onClick={() => handleSearch()} ><Search /></button>
                </form>
                {/* } */}
            </span>

            {/* {
                show === true && auctionDetail && auctionDetail.notice1 && auctionDetail.notice2 ?
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
                                {publication.publication.publication1 === '' ?
                                    <div style={{ display: 'flex', width: '600px', flexDirection: 'column', marginTop: '40px', alignItems: 'center' }}>
                                        <div className="form-group files">
                                            <h3>First Publication</h3>
                                            <input
                                                accept=".pdf"
                                                type="file"
                                                name="publication1"
                                                className="form-control"
                                                onChange={(e) => handleAppend(e)}
                                            />
                                        </div>
                                    </div> :
                                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px', alignItems: "center" }}>
                                        <h3 style={{ display: "flex", textAlign: "center" }}>First Publication</h3>
                                        <a href={`/home/pdfviewer?pdf_url=` + urlhelper.encodeToBase64(publication.publication.publication1) + "&download=1"}><b>View Publication</b></a>
                                        <button type='button' style={{ border: 'none', width: "300px", height: '60px' }} onClick={() => discardPublication("one")}><ClearOutlined /></button>
                                    </div>
                                }
                                {publication.publication.publication2 === '' ?
                                    <div style={{ display: 'flex', width: '600px', flexDirection: 'column', marginTop: '40px', marginLeft: '20px', alignItems: 'center' }}>
                                        <div className="form-group files">
                                            <h3>Second Publication</h3>
                                            <input
                                                accept=".pdf"
                                                type="file"
                                                name='publication2'
                                                className="form-control"
                                                onChange={(e) => handleAppend(e)}
                                            />
                                        </div>
                                    </div> :
                                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px', marginLeft: '20px', alignItems: 'center' }}>
                                        <h3>Second Publication</h3>
                                        <a href={`/home/pdfviewer?pdf_url=` + urlhelper.encodeToBase64(publication.publication.publication2) + "&download=1"} ><b>View Publication</b></a>
                                        <button type='button' style={{ border: 'none', width: "300px", height: '60px' }} onClick={() => discardPublication("two")}><ClearOutlined /></button>
                                    </div>
                                }
                            </div>
                            <button type='button' style={{ borderRadius: '5px', color: 'white', backgroundColor: '#0066cc', border: 'none', width: "300px", height: '40px', marginTop: '40px' }} onClick={() => Submit()}>Submit</button>
                        </div>
                    </div>
                    : null
            } */}

            {/* {
                value.length > 0 && count && */}
            <Table style={{ marginTop: '20px' }}>
                <Thead>
                    <Tr>
                        <Th style={{width:'20%'}}>Loan No</Th>
                        <Th style={{width:'20%'}}>Date</Th>
                        <Th style={{width:'20%'}}>Employee</Th>
                        <Th style={{width:'20%'}}>First Publication</Th>
                        <Th style={{width:'20%'}}>Second Publication</Th>
                        {/* <Th>Edit Publication</Th> */}
                    </Tr>
                </Thead>
                {show === true&&<Tbody>
                    {
                        value.length > 0 ?
                            value.map((x, idx) => <Tr key={idx}>
                                <Td>{x.loan_no}</Td>
                                <Td>{x && x.publication && x.publication.date && <div>{datehelper.displayDate(new Date(x.publication.date))}</div>}</Td>
                                <Td>{x && x.publication && x.publication.emp_id && <div>{employeeList[x.publication.emp_id]}</div>}</Td>
                                <Td>
                                    {
                                        x.publication.publication1 === '' ? null :
                                            <Button style={{ textDecoration: "none", color: "blue" }}
                                                onClick={() => history.push(`/home/pdfviewer?pdf_url=` + urlhelper.encodeToBase64(x.publication.publication1) + "&download=1")}>
                                                View Publication
                                            </Button>
                                    }
                                </Td>
                                <Td>
                                    {

                                        x.publication.publication2 === '' ? null :
                                            <Button style={{ textDecoration: "none", color: "blue" }}
                                                onClick={() => history.push(`/home/pdfviewer?pdf_url=` + urlhelper.encodeToBase64(x.publication.publication2) + "&download=1")}>
                                                View Publication
                                            </Button>

                                    }
                                </Td>
                                {/* <Td>
                                        <Button style={{ textDecoration: "none", color: "blue" }}
                                            onClick={() => handleEdit(x)}
                                        >
                                            Edit publication
                                        </Button>
                                    </Td> */}
                            </Tr>) :
                            <Tr>
                                <Td align='center' colspan='5'>Data not available</Td>
                            </Tr>
                    }
                </Tbody> 
                }
            </Table>
            {show===false&& <CircularProgress style={{marginTop:'20px'}} />}
            {/* } */}
            {
                console.log(value)
            }
        </div>
    )
}
