import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormElement from "../../uielements/FormElement";
import { alertService } from "../../../_services/alert.service";
import { loanService } from "../../../_services/loan.service";
import { employeeService } from "../../../_services/employee.service";
import { branchService } from "../../../_services/branch.service";
import { LoanStages } from "../../../constants/loanstages";
import { OperationSubStages } from "../../../constants/operationsubstages";
import { DisbursalSubStages } from "../../../constants/disbursalsubstages";
import { SanctionSubStages } from "../../../constants/sanctionsubstages";
import datehelper from "../../../_helpers/datehelper";
import roleChecker from "../../../_helpers/rolechecker";

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "75%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function AddEditApplication({ history, match }) {
  const classes = useStyles();
  const formikRef = React.useRef();
  const { id } = match.params;
  const isAddMode = !id;
  const [open, setOpen] = React.useState(false);
  const user = employeeService.userValue;
  const [branches, setBranches] = React.useState([]);
  const [salesEmployee, setSalesEmployee] = React.useState([]);
  const [salesBranch, setSalesBranch] = React.useState("");
  const [employees, setEmployees] = React.useState([]);
  //console.log(stateList);

  React.useEffect(() => {
    branchService.getAll().then((branches) => {
      branches = branches.filter((b) => {
        if (b.branch_id === "HO001") {
          return false;
        }
        return true;
      });
      setBranches(branches);
    });
  }, []);

  let today = new Date();

  React.useEffect(() => {
    employeeService.getAll().then((employees) => {
      setEmployees(employees);
    });
  }, []);

  React.useEffect(() => {
    console.log("Sales branch changed: " + salesBranch);
    let tempData = [];
    tempData = employees.filter((emp) => {
      if (roleChecker.isSalesTeamAccess(emp.role) && emp.branch_id === salesBranch) {
        return true;
      }
      if (emp.emp_id == 108) {
        return true;
      }
      return false;
    });
    console.log(tempData);
    setSalesEmployee(tempData);
    console.log(salesBranch);
  }, [salesBranch]);

  const initialValues = {
    applicant: {
      general_information: {
        name: "",
        sonof: "",
        contact: "",
        address: "",
        gender: "",
        education: "",
        aadhar: "",
        age: "",
      },
      financial_condition: {
        cibil_score: "",
        is_salaried: "",
        salaried_details: {
          experience_years: "",
          employment_type: "",
        },
        business_details: {
          business_type: "",
          business_years: "",
        },
      },
    },
    branch_id: "",
    co_applicants: [],
    gaurantors: [],
    loan: {
      amount: "",
      tenure: "",
      roi: "",
    },
    application: {
      application_mode: "Manual",
      application_date: new Date(),
    },
    application_fees: {
      payment_amount: 0,
      payment_mode: "NA",
      payment_date: new Date(),
      payment_comment: "",
    },
    stage: "Sales",
    sub_stage: "",
    next_stage_emp_id: "",
    salesAgent: "",
    images: [],
  };

  const validationSchema = Yup.object().shape({
    applicant: Yup.object().shape({
      general_information: Yup.object().shape({
        name: Yup.string().required("This field is required"),
        sonof: Yup.string().required("This field is required"),
        contact: Yup.string()
          .matches(/^[0-9]{10}$/, "Valid number")
          .required("This field is required"),
        aadhar: Yup.string()
          .matches(/^[0-9]{12}$/, "Valid aadhar number")
          .required("aadhar required"),
        address: Yup.string().required("Address required"),
      }),
    }),
    branch_id:Yup.string().required("Branch is required"),
    salesAgent:Yup.string().required("RO name required"),
    loan:Yup.object().shape({
      amount: Yup.number().min(1,"Loan amount should be greater than 0 ").max(2500000,"Max 25 Lakhs loan allowed").required("This field is required")
    }),
    // application_fees:Yup.object().shape({
    //   payment_amount:Yup.number().required("Enter the Amount"),
    //   payment_comment: Yup.string().required("This Field is required"),
    //   payment_date: Yup.date().max(today, "Date should not be greater than today's date").required("This field is required")
    // })
  });

  async function handleChange(key, value) {
    console.log("handle change called: " + key + " " + value);
    if (key === "branch_id") {
      if (formikRef.current) {
        formikRef.current.setFieldValue(key, value);
      }
      setSalesBranch(value);
      //values[key]=value;
    }
  }
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function onSubmit(fields, { setSubmitting }) {
    console.log("in submit function");
    console.log(fields);
    fields["stage"] = "Sales";
    console.log("submitting form");
    await loanService
      .getByAadharId(fields["applicant"]["general_information"]["aadhar"])
      .then((l) => {
        window.alert(
          "Loan already created for customer. Loan Number: " + l.application_id
        );
        setSubmitting(false);
      })
      .catch(async (err) => {
        console.log(err);
        await loanService
          .create({ ...fields })
          .then((applicant) => {
            window.alert(
              "Loan File Logged in. Application # is " +
                applicant.application_id
            );
            history.push(".");
          })
          .catch((err) => {
            window.alert("error adding Loan Application");
            console.log("error" + err);
          });
        setSubmitting(false);
        handleClose();
      });

    // formikRef.current && formikRef.current.values && (
    //             temp = formikRef.current.values.branch_id,
    //             console.log(formikRef.current.values.branch_id),
    //              setSalesBranch(temp) )
  }

  return (
    <div>
      {isAddMode && <h2 id="transition-modal-title">Add SBL Loan</h2>}
      <Formik
        innerRef={formikRef}
        // enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        id="transition-modal-description"
      >
        {({ errors, touched, isSubmitting, setFieldValue, values }) => {
          return (
            <Form>
              <div className="form-row">
                <div className="form-group col">
                  <p>Branch Name:</p>
                  <Field
                    as="select"
                    name="branch_id"
                    label="Branch Code"
                    value={values.branch_id}
                    onChange={(e) => handleChange("branch_id", e.target.value)}
                    className={
                      "form-control" +
                      (errors.branch_id && touched.branch_id
                        ? " is-invalid"
                        : "")
                    }
                  >
                    <option value="" disabled>
                      Select Branch
                    </option>
                    {branches &&
                      branches.map((branch, idx) => (
                        <option value={branch.branch_id} key={idx}>
                          {branch.branch_name} - {branch.branch_id}
                        </option>
                      ))}
                  </Field>
                  <ErrorMessage
                    name="branch_id"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                {/* {formikRef.current && formikRef.current.values && (
                  temp = formikRef.current.values.branch_id,
                  console.log(formikRef.current.values.branch_id),
                   setSalesBranch(temp) )} */}
                <div className="form-group col">
                  <p>RO (Sales):</p>
                  <Field
                    as="select"
                    name="salesAgent"
                    label="RO"
                    className={
                      "form-control" +
                      (errors.salesAgent && touched.salesAgent
                        ? " is-invalid"
                        : "")
                    }
                  >
                    <option value="" disabled selected>
                      Select RO
                    </option>
                    {salesEmployee &&
                      salesEmployee.map((emp, idx) => (
                        <option value={emp._id} key={idx}>
                          {emp.emp_id} - {emp.first_name} {emp.last_name}
                        </option>
                      ))}
                  </Field>
                  <ErrorMessage
                    name="salesAgent"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col">
                  <FormElement
                    type="text"
                    name="applicant.general_information.name"
                    label="Applicant Name"
                    errors={errors}
                    touched={touched}
                  />
                </div>
                <div className="form-group col">
                  <FormElement
                    type="text"
                    name="applicant.general_information.sonof"
                    label="Father Name"
                    errors={errors}
                    touched={touched}
                  />
                </div>
                <div className="form-group col">
                  <FormElement
                    type="number"
                    name="applicant.general_information.aadhar"
                    label="Aadhar Number"
                    errors={errors}
                    touched={touched}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col">
                  <FormElement
                    type="text"
                    name="applicant.general_information.address"
                    label="Address "
                    errors={errors}
                    touched={touched}
                  />
                </div>
                <div className="form-group col">
                  <FormElement
                    type="number"
                    name="applicant.general_information.contact"
                    label="Contact "
                    errors={errors}
                    touched={touched}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col">
                  <FormElement
                    type="number"
                    name="loan.amount"
                    label="Loan Amount"
                    errors={errors}
                    touched={touched}
                  />
                </div>
                <div className="form-group col">
                  <FormElement
                    type="number"
                    name="loan.tenure"
                    label="Loan Tenure"
                  />
                </div>
                <div className="form-group col">
                  <FormElement type="number" name="loan.roi" label="ROI" />
                </div>
              </div>

              {/* <div className="form-row">
                <div className="form-group col">
                  <FormElement
                    type="number"
                    name="application_fees.payment_amount"
                    label="Fees Amount "
                    errors={errors}
                    touched={touched}
                  />
                </div>
                <div className="form-group col">
                  <p>Payment Mode:</p>
                  <Field
                    as="select"
                    className="form-control"
                    name="application_fees.payment_mode"
                    label="Payment Mode"
                    required
                  >
                    <option value=" ">Select Payment Mode</option>
                    <option value="Online">Online</option>
                    <option value="Offline">Cash</option>
                  </Field>
                </div>

                <div className="form-group col">
                  <FormElement
                    type="text"
                    name="application_fees.payment_comment"
                    label=" Payment Reference"
                    errors={errors}
                    touched={touched}
                  />
                </div>
              </div> */}
              {/* <div className="form-group row">
                <div className="form-group col">
                  <FormElement
                    type="date"
                    name="application_fees.payment_date"
                    label="Payment Date"
                    errors={errors}
                    touched={touched}
                  />
                 
                </div>
              </div> */}
              <hr />
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                Submit
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
