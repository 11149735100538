import React, {useState, useEffect} from 'react'
import {cibil} from "../../_services/cibil.service"
import datehelper from "../../_helpers/datehelper"
import Upload from './Upload'
import { employeeService } from "../../_services/employee.service";
import { Input } from "antd";
import urlhelper from '../../_helpers/urlhelper';
import roleChecker from '../../_helpers/rolechecker';
import { CircularProgress } from '@material-ui/core';


const CibilList = () => {
    const user=employeeService.userValue;

    const [data, setData] = useState([])
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)

    const getAll = () => {
      setLoading(true)
        cibil.getAll()
        .then((x)=> {
            console.log(x)
            let y=x;
            if(roleChecker.isHOLevelAccess(user.role))
            {
              y=x;
            }
            else
            {
               y=x.filter((z)=> {
                 console.log(z);
                 if(!z.employees || z.employees.length ==0)
                 {
                  return false;
                 }
                 console.log(z.employees[0]);
                 if(z.employees[0].branch_id===user.branch_id)
                 {
                    return true;
                 }
                 if(z.employees[0].emp_id===user.emp_id)
                 {
                    return true;
                 }
                 if(roleChecker.isBranchLevelAccess(user.role) && user.branch_id===z.employees[0].branch_id){
                  return true;
                 }
                 return false;
               })
            }
            setLoading(false)
            setData(y)
            setList(y)
        })
        .catch((err)=> {
            console.log(err)
            setLoading(false)
        })
    }
    
    const { Search } = Input;

    useEffect(() => {
      getAll();
    }, [])

    const handleFilter = (search) => {
      setLoading(true)
      let pay = list.filter(x => x.name.toLowerCase().includes(search.toLowerCase()) || x.applicants.length && x.applicants[0].application_id.includes(search))
      setLoading(false)
      setData(pay)
    }


  return (
    <>
      <h2>Cibil Request List</h2>
      <hr></hr>
      <Search
          onChange={(e) => handleFilter(e.target.value)}
          placeholder="Search"
          enterButton
          style={{
            position: "sticky",
            top: "0",
            left: "0",
            marginLeft:"80%",
            width: "250px",
            marginTop: "2vh",
          }}
        /><br/><br/>
      <div>
        {loading ? <div style={{textAlign: "center"}}><CircularProgress/> </div> : 
      <table>
        <thead style={{borderBottom: "2px solid black"}}>
            <tr>
                <td style={{width: "10%"}}>Date</td>
                <td style={{width: "10%"}}>Name</td>
                <td style={{width: "10%"}}>Role</td>
                <td style={{width: "10%"}}>AADHAR</td>
                <td style={{width: "10%"}}>PAN</td>
                <td style={{width: "10%"}}>Branch ID</td>
                <td style={{width: "10%"}}>Application ID</td>
                <td style={{width: "10%"}}>Requested _by</td>
                <td style={{width: "10%"}}>Action</td>
            </tr>
        </thead>
        <tbody>
        {data && data.map((e,idx)=> {
          console.log("data is ",data)
            return <tr key={idx}>
                <td>{datehelper.displayDate(new Date(e.date))}</td>
                <td>{e.name}</td>
                <td>{e.role}</td>
                <td>{e.aadhar}</td>
                <td>{e.pan}</td>
                <td>{e.applicants.map(x=>x.branch_id)}</td>
                <td>{e.applicants.map(x=>x.application_id)}</td>
                <td>{e.employees.map(x=>x.first_name)}</td>
                <td>
                  {e.report && e.report !="" && (
                    <a href={`/home/pdfviewer?pdf_url=`+urlhelper.encodeToBase64(e.report)+"&download=1"}>View Report</a>
                  )}
                  {(roleChecker.isHOLevelAccess(user.role)) && (<Upload aadhar={e.aadhar} id={e._id}/>)}
                </td>
            </tr>
        })}
        </tbody>
      </table>
}
      </div>
    </>
  )
}

export default CibilList