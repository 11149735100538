import React from "react";
import ReminderLetter from "./ReminderLetter";
import { useReactToPrint } from "react-to-print";
import { employeeService } from "../../_services";
import GoldAuctionServices from "../../_services/goldauction.service";

export default function GoldReminderPrint(props) {
  const compRef = React.useRef();
  const user =employeeService.userValue
  const data = props.location.state;
  const date =new Date()

  console.log(props.location.state);
  const handleSave=async()=>{
    if(data&&data.notice&&data.notice===1){
      let noticeData ={
        loan_no:data.loan_account,
        customer_name:data.name,
        notice1:{
            emp_name:user.first_name,
            emp_id:user.emp_id,
            dispatch_date:date
        }
      }
       await GoldAuctionServices.CreateNotice(noticeData)
       .then((res)=>console.log(res))
       .then((err)=>console.log(err))
    }else if(data&&data.notice&&data.notice===2){
      let noticeData ={
        notice2:{
            emp_name:user.first_name,
            emp_id:user.emp_id,
            dispatch_date:date
        }
      }
       await GoldAuctionServices.UpdateNoticeDetails(data.id,noticeData)
       .then((res)=>console.log(res))
       .then((err)=>console.log(err))
    }
  }

  const handlePrint = useReactToPrint({
    content: () => compRef.current,
  });

  return (
    <div>
      {
        console.log(user)
      }
      <div style={{ textAlign: "center" }}>
        <button onClick={()=>{handleSave();handlePrint()}}>Print Reminder Letter</button>
      </div>
      <ReminderLetter
        ref={compRef}
        dataFill={props.location.state}
      ></ReminderLetter>
    </div>
  );
}
