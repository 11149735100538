import React from "react";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Collapse
} from "@material-ui/core";
import { ExpandMore,ExpandLess,AccountBalanceWallet,MonetizationOn} from "@material-ui/icons";
import roleChecker from "../../_helpers/rolechecker";

export default function SBLMenuList(props) {
  const [open, setOpen] = React.useState(false);
  const { path } = props.match;
  const user =props.user;

  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  return (
    <React.Fragment>
    {!["Sales"].includes(user.role) && <ListItem button onClick={handleClick}>
    <ListItemIcon>
         <MonetizationOn></MonetizationOn>
    </ListItemIcon>
        <ListItemText primary="SBL Loans" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" style={{paddingLeft:"50px"}}>
      {![20539,20113].includes(user.emp_id) && !["AUDITOR"].includes(user.role)&&  <ListItem
              button
              key="my_loans"
              component="a"
              href={`${path}/sblloans/mytaskslist`}
            >
            <ListItemText primary="My SBL Tasks" />
            </ListItem>}

            <ListItem
            button
            key="loansearch"
            component="a"
            href={`${path}/sblloans/searchloans`}
          >
            <ListItemText primary="Search Loans" />
          </ListItem>
        
          {![20539,20113].includes(user.emp_id)&&<ListItem
              button
              hidden={
                !(roleChecker.isHOLevelAccess(user.role) || roleChecker.isSalesTeamAccess(user.role) || roleChecker.isCreditTeamAccess(user.role)) 
              }
              key="loans"
              component="a"
              href={`${path}/sblloans`}
            >
              <ListItemText primary="SBL Files" />
            </ListItem>}
            {![20539,20113].includes(user.emp_id)&&<ListItem
              hidden={
                !(roleChecker.isHOLevelAccess(user.role) || roleChecker.isSalesTeamAccess(user.role)) 
              }
              button
              key="salesloans"
              component="a"
              href={`${path}/sblloans/saleslist`}
            >
              <ListItemText primary="Sales Stage" />
            </ListItem>}

            {![20539,20113].includes(user.emp_id)&&<ListItem
                hidden={ !(roleChecker.isHOLevelAccess(user.role) || roleChecker.isCreditTeamAccess(user.role)) 
                }
                button
                key="creditlist"
                component="a"
                href={`${path}/sblloans/creditlist`}
                >
                <ListItemText primary="Credit Pending" />
                </ListItem>}
                {![20539,20113].includes(user.emp_id)&& <ListItem
                hidden={
                  !(roleChecker.isHOLevelAccess(user.role))   
                }
              button
              key="sanctionpending"
              component="a"
              href={`${path}/sblloans/operationlist`}
             >
              <ListItemText primary="Operation Pending" />
            </ListItem>}

            {![20539,20113].includes(user.emp_id)&& <ListItem
              hidden={
                !(roleChecker.isHOLevelAccess(user.role))  
              }
              button
              key="sanctionpending"
              component="a"
              href={`${path}/sblloans/sanctionlist`}
             >
              <ListItemText primary="Sanction Pending" />
            </ListItem>}
            
            {/* <ListItem
              hidden={
                !(roleChecker.isHOLevelAccess(user.role))   
              }
              button
              key="disbursalpending"
              component="a"
              href={`${path}/sblloans/disbursallist`}
             >
              <ListItemText primary="Disbursal Pending" />
            </ListItem> */}

            {/* <ListItem
              hidden={
                !(roleChecker.isHOLevelAccess(user.role))   
              }
              button
              key="collectionpending"
              component="a"
              href={`${path}/sblloans/collectionlist`}
             >
              <ListItemText primary="Collection Pending" />
            </ListItem> */}
            </List>
      </Collapse>
      </React.Fragment>
  )
}