import React from "react";
import { collectionService, employeeService } from "../../_services";
import LoadingOverlay from "react-loading-overlay";
import { Paper } from "@material-ui/core";
import { useFormik } from 'formik';
import TextField from '@material-ui/core/TextField';
import sampleSheet from '../../assets/sampleformat.6d75dc81c3cf9a90393b.csv'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

export default function ImportLoans() {
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [spinneractive, setSpinneractive] = React.useState(false);
  const [state, setState] = React.useState(false)
  const user = employeeService.userValue;
  const onChangeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onClickHandler = async () => {
    setSpinneractive(true);

    const data = new FormData();
    data.append("file", selectedFile);

    await collectionService.uploadCsv(data).then((response) => {
      setSpinneractive(false);
      if (!response.ok) {
        alert("An Error Occurred, Please try again");
      } else {
        alert("Data Uploaded");
        window.location.reload();
      }
    });
  };

  const show = () => {
    setState(!state)
  }

  const formik = useFormik({
    initialValues: {
      customer_name: '',
      loan_number: '',
      contact: '',
      outstanding_amount: "",
      outstanding_amount: '',
      emp_id: "",
    },
    onSubmit: values => {
      collectionService.create(values)
        .then((data) => {
          alert("Data Added Successfully")
        })
        .catch((err) => {
          window.alert("Data not added")
        })
      formik.resetForm();
      window.location.reload()
    },
  });

  return (
    <LoadingOverlay active={spinneractive} spinner text="Uploading Data...">
      {
        ((user.role === 'Admin' || user.role === 'GM' || user.role === 'HO' || user.role === 'BM' ) && (

          <Paper
            style={{
              padding: 20,
            }}
          >
            <h3 style={{ textAlign: "center" }}>Import List</h3>
            <div style={{ display: "flex", justifyContent: "left" }}>
              <button onClick={show}>Upload CSV File</button>
            </div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <button onClick={handleOpen}>Add Single Collection</button>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                      <h2>Collection Detail</h2><br/>
                      <form onSubmit={formik.handleSubmit}>
                        <div className='container my-3'>
                          <label>Customer Name</label>
                          <TextField
                            fullWidth
                            id="customer_name"
                            name="name"
                            autoComplete="off"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                          />
                        </div>

                        <div className='container my-3'>
                          <label>Loan Number</label>
                          <TextField
                            fullWidth
                            id="loan_number"
                            name="loan_no"
                            autoComplete="off"
                            value={formik.values.loan_no}
                            onChange={formik.handleChange}
                          />
                        </div>

                        <div className='container my-3'>
                          <label>Mobile Number</label>
                          <TextField
                            fullWidth
                            id="loan_number"
                            name="contact"
                            autoComplete="off"
                            value={formik.values.contact}
                            onChange={formik.handleChange}
                          />
                        </div>

                        <div className='container my-3'>
                          <label>Outstanding Amount</label>
                          <TextField
                            fullWidth
                            id="outstanding_amount"
                            name="outstanding_amount"
                            autoComplete="off"
                            value={formik.values.outstanding_amount}
                            onChange={formik.handleChange}
                          />
                        </div>
                        <div className='container my-3'>
                          <label>Employee Id</label>
                          <TextField
                            fullWidth
                            id="emp_id"
                            name="emp_id"
                            autoComplete="off"
                            value={formik.values.emp_id}
                            onChange={formik.handleChange}
                          />
                        </div>
                        <div>
                          <button className='btn btn-warning my-3' type='submit'>Save Details</button>
                        </div>
                      </form>
                    </Typography>

                  </Box>
                </Modal>
              </div>
            
            <hr></hr>
            {state === true ? <div className="container">
              <div className="offset-md-3 col-md-6">
                <form method="post" action="#" id="#">
                  <div className="form-group files">
                    <label>Upload Your File</label>
                    <input
                      accept=".csv"
                      type="file"
                      className="form-control"
                      onChange={(e) => onChangeHandler(e)}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-success btn-block"
                    onClick={() => onClickHandler()}
                  >
                    Upload
                  </button>
                </form>
                <div >
                  <a href={sampleSheet} download style={{ display: "flex", justifyContent: "right", textDecoration: "none", textStyling: "none", color: "red" }} >Download Sample File</a>
                </div>
              </div>
            </div>
              : null}
          </Paper>))}
    </LoadingOverlay>
  );
}
