import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
 import { Button } from '@material-ui/core';
import { uploadFileToBucket } from "../../../_helpers/upload";
import { v4 as uuidv4 } from 'uuid';
import {bulkUploadService} from '../../../_services/bulkUpload.services'
import BulkUploadCollection from "../../../assets/bulkuploadcollection.csv"
const BulkUpload = () => {
   const [selectedFile, setSelectedFile] = useState(null);
   const [showField,setShowField]=useState(false);
   const [showError,setShowError] = useState(false);
   const [showMessage,setShowMessage] = useState();
const [isSuccess,setIsSuccess]=useState(false)
const [openRemoval,setOpenRemoval]=useState(false)
const [spinner,setSpinner]=useState(false)
const [empId,setEmpId]= useState()
const random_uid= uuidv4();
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };


  const bucket = process.env.REACT_APP_s3sblBucket;

  const handleFileUpload = async( ) => {
     

     let val = selectedFile.name.split('.').pop().toLowerCase();
   console.log("random_uid",random_uid)
   var link;
    if(val==='csv'){
        link = await uploadFileToBucket(bucket, 'application/csv', `${bucket}/bulkupload/${random_uid}.csv`, selectedFile);
      
    }else{
        setShowError(true)
        setTimeout(() => {
            setShowError(false)
          }, 3000);
       
    }
    console.log("var link ",link)
    if(link){
      console.log("link",link)

      bulkUploadService.bulkUploadingDataSheet({link })
    .then((res)=>{
      // window.location.reload();
      if(res && res.status=="success"){
        setIsSuccess(true);
        setTimeout(() => {
            setIsSuccess(false)
          }, 3000);
        setShowMessage(res.message);
      }
      console.log("reponse",res)
    })
    .catch((err)=>console.log("error",err))



     }
  }

  const CollectionRemoval = (empId) => {
    console.log('empId', empId);
    if (empId.length < 5) {
        setShowMessage("Emp Id should be minimum 5 digits");
        return;
    }
    setSpinner(true);  
    bulkUploadService.collectionRemoval({ emp_id: empId })
        .then((res) => {
            if (res && res.status === "success") {
                setIsSuccess(true);
                setShowMessage(res.message);
                setTimeout(() => {
                    setIsSuccess(false);
                    setShowMessage("");  
                    window.location.reload();
                }, 3000);
            } else {
                setShowMessage(res.message);
                setTimeout(() => {
                    setIsSuccess(false);
                    setShowMessage(""); // Clear message after timeout
                    window.location.reload();
                }, 3000);
            }
        })
        .catch((err) => {
            console.log("error", err);
            setShowMessage("Error occurred while removing collection.");
        })
        .finally(() => {
            setSpinner(false); // Set spinner to false after request completes
        });
};
   
  return ( 
    <div>
    <div style={{display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'}}>

      <Button onClick={()=>{setShowField(true);setOpenRemoval(false)}} color='primary' variant='contained' style={{marginBottom:'1rem'}}>Upload CSV File</Button>
      <Button style={{ background:"red",color:"white"}} variant='contained' onClick={()=>{setOpenRemoval(true);setShowField(false)}}>Remove Employee Collection</Button>
    </div>
    <div style={{marginTop:'1rem'}}>
    {showError && <h3 style={{color:"red"}}>please select CSV File Only</h3>}
  {!isSuccess && showMessage && <h3 style={{color:"red",margin:'1rem'}}>{showMessage}</h3>}
    {isSuccess && <h3 style={{color:"green"}}>{showMessage}</h3>}
    
    
    { showField && <div className='offset col-md-6 m-auto' style={{display:'flex',flexDirection:'column',justifyContent:'center',gap:'1rem',marginTop:'1rem'}}>
<div style={{display:'flex',width:'100%',height:'10rem',border:'2px solid #410202',alignItems:'center',justifyContent:'center',borderRadius:'0.5rem',borderStyle:'dashed'}}>

      <input type="file" accept=".csv, .xlsx"  className="form-control" style={{width:'80%',padding:'4px'}} onChange={handleFileChange} />
</div>
      <Button  color='primary' variant='contained' onClick={handleFileUpload} disabled={!selectedFile}>
        Upload
      </Button>
      <div >
                  <a href={BulkUploadCollection} download style={{ display: "flex", justifyContent: "right", textDecoration: "none", textStyling: "none", color: "red" }} >Download Sample File </a>
                </div>
      </div>
}
      

       
    </div>
    <div>
    
    
    {openRemoval && 
    <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "1rem"
    }}>
        <b style={{ fontSize: "x-large" }}>Emp ID</b>
        <input  type='number'
            placeholder='Enter Employee ID'  
            style={{     
                width:'300px', 
                height: '60px', 
                borderRadius:' 5px',
                borderColor: 'brown',
                fontWeight: '800',
                fontSize: 'x-large'
                
            }} 
            onChange={(e) => setEmpId(e.target.value)} 
        />
        {empId && 
            <Button  
                color="primary" 
                variant='contained'  
                onClick={() => CollectionRemoval(empId)} 
                disabled={spinner} 
            >  
                {spinner && <span className="spinner-border spinner-border-sm mr-1"></span>} 
                Submit
            </Button>
        }
    </div>
}
    
    </div>
    </div>
  );
};

export default BulkUpload;
