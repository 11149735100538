import React from 'react';
import { useEffect, useState } from 'react';
import { Table, Tbody, Thead, Tr, Th, Td } from 'react-super-responsive-table';
import { makerCheckerServices } from '../../_services/makerchecker.service';
import datehelper from '../../_helpers/datehelper';
import { ThumbDown, ThumbUp } from '@material-ui/icons';
import { employeeService } from '../../_services';
import { DataModal } from './ApprovalDataModal';
import { Button } from '@material-ui/core';
import { collectionService } from '../../_services';
import { customerService } from '../../_services';
import { Paper } from '@material-ui/core';
import { AllServices } from './AllServices';
import roleChecker from '../../_helpers/rolechecker';
import { CircularProgress } from '@material-ui/core';
 


export const ApprovalList = () => {
    const [requests, setRequests] = useState([])
    const [employeeList, setEmployeeList] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const user = employeeService.userValue

    async function getRequests(fields) {
        setIsLoading(true)
        await makerCheckerServices.getRequests(fields)
            .then(res => {
                let rqst = res.filter(req => {
                    if (req && Number(req.emp_id) !== Number(user.emp_id) && req.approval_access && roleChecker[req.approval_access](user.role)) {
                        return req
                    }
                })
                setRequests(rqst)
            }
            )
            .catch(err => console.log(err))
        setIsLoading(false)
    }

    function getEmployees() {
        employeeService.getAll()
            .then(res => {
                if (res.length > 0) {
                    let list = {}
                    res.map(e => {
                        list[e.emp_id] = e.first_name + ' ' + e.last_name
                    }
                    )
                    setEmployeeList(list)
                }
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        getRequests();
        getEmployees()
    }
        , [])

    const rejectRequest = (id) => {
        makerCheckerServices.deleteRequest(id)
            .then(res => {
                window.alert('Request rejected')
                window.location.reload()
            })
            .catch(err => {
                window.alert('Something went wrong')
                console.log(err)
            })
    }

    const handleApprove = (req) => {
        AllServices[req.service][req.api](req.id, { ...req.new_values, checker_approver_emp_id: user.emp_id })
            .then(res => {
                makerCheckerServices.approveRequest(req._id, { approved: true, approved_by: user.emp_id })
                    .then(res => {
                        window.alert('Request Approved')
                        window.location.reload()
                    })
                    .catch(err => {
                        window.alert('Something went wrong')
                        console.log(err)
                    })
            })
            .catch(err => {
                window.alert('Something error occured')
            })
    }

    return (
        <div>
            <Paper style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px', paddingBottom: '50px', paddingTop: '50px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '80%' }}>
                    <h3><b>Pending Requests</b> : {requests.length}</h3>
                    <label style={{ display: 'flex', alignItems: 'center' }}>
                        <h4>Product : &nbsp;</h4>
                        <select name='product' onChange={(e) => getRequests({ [e.target.name]: e.target.value })} style={{ borderRadius: '5px', width: '150px', height: '35px' }}>
                            <option value="">All</option>
                            <option value="goldloans">Gold</option>
                            <option value="sbl">SBL</option>
                            <option value="accounting">Accounting</option>
                        </select>
                    </label>
                </div>
                <hr style={{ width: '90%' }} />
                <Table style={{ width: '100%', margin: 'auto', textAlign: 'center' }}>
                    <Thead>
                        <Tr>
                            <Th style={{ width: '15%', textAlign: 'center' }}></Th>
                            <Th style={{ width: '10%', textAlign: 'center' }}>Date</Th>
                            <Th style={{ width: '15%', textAlign: 'center' }}>Employee</Th>
                            <Th style={{ width: '15%', textAlign: 'center' }}>Task</Th>
                            <Th style={{ width: '10%', textAlign: 'center' }}>Details</Th>
                             
                            <Th style={{ width: '40%', textAlign: 'center' }}>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {isLoading ?
                            <Tr>
                                <Td align='center' colSpan='6'> <CircularProgress /></Td>
                            </Tr> :
                            <>
                                {
                                    requests && requests.length > 0 ?
                                        requests.map((req) => {
                                            return <Tr key={req._id}>
                                                <Td><b>{req.identification_key}</b><br />
                                                    {req.identification_value}</Td>
                                                <Td>{datehelper.displayDate(new Date(req.date))}</Td>
                                                <Td>{employeeList[req.emp_id]}</Td>
                                                <Td>{req.task}</Td>
                                                <Td><DataModal data={req} /></Td>
                                                
                                                <Td>
                                                    <Button style={{ color: 'white', backgroundColor: 'blue' }} onClick={() => handleApprove(req)}>Approve</Button>
                                                    <Button style={{ color: 'white', backgroundColor: 'red', marginLeft: '5%' }} onClick={() => rejectRequest(req._id)}>Reject</Button>
                                                </Td>
                                            </Tr>
                                        }) :
                                        <Tr>
                                            <Td align='center' colSpan='6'>No request pending on this access level</Td>
                                        </Tr>
                                }
                            </>}
                    </Tbody>
                </Table>
                {
                    console.log(requests)
                }
            </Paper>
        </div>
    )
}
