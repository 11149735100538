import { Button, Modal, Box } from '@material-ui/core'
import { Timeline } from '@material-ui/icons'
import React, { useState } from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import datehelper from '../../../_helpers/datehelper';
const ShowTimelineModal = (props) => {
  const data=props.caseInfo
  console.log("Test data",data)
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "800px",
    height: "750px",
    bgcolor: "#F3F3F3",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
    background: '#E5E5E5'
  };
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <div>
      <Button onClick={handleOpen} style={{ color: '#0C2294', height: '25px', background: '9990FF', float: 'right', textTransform: 'capitalize', }}>Timeline <Timeline style={{ border: "2px solid #0C2294", marginBottom: '5px', height: "20px" }} /></Button>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
          <h1 style={{ textAlign: 'center' }}>Timeline</h1>
          <div>
          <VerticalTimeline lineColor="blue">
            {data && data.case_stages && data.case_stages.map((x)=><VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
              iconStyle={{ display:'flex',justifyContent: 'center', alignItems: 'center' ,height:"30px",border:'3px solid red'}}
              icon={
                <div>
                  <svg viewBox="0 0 24 24" >
                    <path fill="red" d="M12 22l-7-7h14z"/>
                  </svg>
                </div>
              }
            >
              <h5>{x.stage=="other"?x.other_stage:x.stage}</h5>
              {(x.stage=="EMI Notice" || x.stage=="LRN Notice"  || x.stage=="Advocate Legal Notice") && <p style={{color:'red'}}>{x.stage}  has been issued against {x.issued_against} </p>}
              {(x.stage=="Court Hearing" || x.stage=="NI-138-Court Submission") && <p style={{color:'red'}}>A Criminal Complaint under section 138 of the Negotiable
               Instruments Act has been filed against {x.issued_against} </p>}
               {x.stage==="Check Bounce" && <p style={{color:'red'}}>A {x.stage} notice under section 138 has been issued against {x.issued_against}</p>}
             { x.stage !=="Case Closed" &&<h6>Issued Date:{ datehelper.displayDate(new Date(x.issued_date))}</h6>}
             { x.stage !=="Case Closed" ?<h6>Issued By:{x.issued_by}</h6>:<h6>Closing Date:{ datehelper.displayDate(new Date(x.issued_date))}</h6>}
            </VerticalTimelineElement>)}
            </VerticalTimeline>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default ShowTimelineModal
