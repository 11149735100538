import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import * as Yup from "yup";
import { Button } from "@material-ui/core";
import { misService } from "../../../_services";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { authPersonService } from "../../../_services/auth_person.service";
import { useEffect } from "react";
import { submissionServices } from "../../../_services/submissionletter.service";

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "75%",
    overflow: "scroll",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    minWidth: 650,
  },
}));

export default function SubmissionLetterModal(props) {
  const loan = props.loan;
  const user = props.user;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [colendingBank, setColendingBank] = React.useState(null);

  const [authPersonList, setAuthPersonList] = React.useState([]);
  const current_date = new Date();
  const [authPerson, setAuthPerson] = React.useState({
    name: "",
    empId: "",
  });

  React.useEffect(() => {
    authPersonService.getAll().then((x) => {
      setAuthPersonList(x);
    });
  }, []);
  const [letterNumber, setLetterNumber] = React.useState();
  useEffect(() => {
    async function getTotal() {
      await submissionServices.countLetter()
        .then((res) => {
          setLetterNumber(res)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    getTotal()
  })
  const letter_number = `KFPL/GL/${colendingBank}/${current_date.toLocaleString("default", { month: "long" })}/${letterNumber+ 1}`
  const submissionPrint = () => {
   props.history.push("/home/submission", {
      ...loan,
      authPerson,
      // letterNumber,
      colendingBank,
      letter_number
    })
  }; 
  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        onClick={handleOpen}
        color="primary"
        compact="true"
      >
        Submission Letter
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <form onSubmit={(e) => submissionPrint(e)}>
            <div className={classes.paper}>
              <h2 id="transition-modal-title">Submission Letter Details</h2>
              {
                <div>
                  <div className="row justify-content-around">
                    <div>
                      <InputLabel id="demo-simple-select-outlined-label">
                        Auth Person
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={authPerson.name}
                        onChange={(e) =>
                          setAuthPerson({
                            name: e.target.value,
                            empId: e.currentTarget.getAttribute("data-key"),
                          })
                        }
                        label="Auth Person"
                        required
                      >
                        {authPersonList.map((item, idx) => (
                          <MenuItem
                            value={item.name}
                            key={idx}
                            data-key={item.id}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div>
                      <InputLabel id="demo-simple-select-outlined-label">
                        Co-Lending Bank
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={colendingBank}
                        onChange={(e) => setColendingBank(e.target.value)}
                        label="Co-lending Banks"
                        required
                      >
                        <MenuItem
                          value="CSB"
                          key="CSB"
                          data-key="CSB"
                        >
                          CSB
                        </MenuItem>
                        <MenuItem
                          value="Fed"
                          key="Fed"
                          data-key="Fed"
                        >
                          Federal Bank
                        </MenuItem>
                        <MenuItem
                          value="SIB"
                          key="SIB"
                          data-key="SIB"
                        >
                        THE SOUTH INDIAN BANK
                        </MenuItem>

                      </Select>
                    </div>
                    {/* <div>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Letter Number
                    </InputLabel>
                    <TextField
                      value={letterNumber}
                      onChange={(e) => setLetterNumber(e.target.value)}
                      id="outlined-number"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div> */}
                  </div>
                  <br />
                  <br />
                  <button
                    style={{ color: 'white', backgroundColor: '#0066cc', marginLeft: "40px", width: "260px", height: '40px' }}
                  >
                    Open Submission Letter
                  </button>
                </div>
              }
            </div>
          </form>
        </Fade>
      </Modal>
    </div>
  );
}
