import React from "react";
import Modal from "@material-ui/core/Modal";
import { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { loanService } from "../../../../_services/loan.service";
import DisbursementDetailsList from "./DisbursementDetailsList";
import { employeeService } from '../../../../_services';
import { bankAccount } from "../../../../_services/bank_account.service";



const AddDisbursementPayment = (props) => {
  const customer = props.customer;
  const account_data=props.account_data;

  const [open, setOpen] = useState(false);
  const [kfBankList,setKfBankList]=useState(null);
  const [kaabilBankName,setKaabilBankName]=useState("")
  const [kaabilBankAccount,setKaabilBankAccount]=useState("")
  const [preEmi,setPreEmi] = useState(customer.loan.pre_emi_amount);
  
  const user = employeeService.userValue;
const [spinner,setSpinner] = useState(false);
  const prevPreEmi = customer.loan.pre_emi_amount;
  const [values, setValues] = useState({
    kaabil_account_number: "",
    kaabil_bank_name: "",
    disbursed_account_number: "",
    account_holder_name: "",
    disbursed_amount: "",
    payment_type:"",
    cheque_dd_number:"",
    disbursed_date: "",
    disbursed_utr_number: "",
    disbursement_memo_id: "",
  });


  React.useEffect(()=> {
     if(open)
     {
      bankAccount.getBankAccount().then((balist)=> {
        console.log("bank accounts")
        console.log(balist)
        setKfBankList(balist)
      })
     }
      
  },[open])
  
  const editAccount = () =>{
    if(props.isEdit === true){
      setValues(customer.disbursement.disbursement_details[props.idx])
    }else{
      setValues({kaabil_account_number: "",
      kaabil_bank_name: "",
      disbursed_account_number: account_data.customer_acc_no,
      account_holder_name: account_data.customer_name,
      disbursed_amount: account_data.payment_amount,
      disbursed_date: "",
      payment_type:account_data.payment_type,
      cheque_dd_number: account_data.cheque_dd_number,
      disbursed_utr_number: "",
      disbursement_memo_id: account_data._id})
    }
  }

  const style = {
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      maxWidth: '600px',
      backgroundColor: '#fff', // Replace 'background.paper' with a color code
      border: '2px solid #000',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', // Similar to boxShadow: 24
      padding: '16px', // Similar to p: 4 (4 * 8px = 32px)
      paddingLeft:'32px',
      paddingRight:'32px',
      borderRadius: '8px',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      alignItems: 'stretch',
      alignContent: 'space-around',
     
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    
      gap: '8px', // Space between fields in the same row
    },
    label: {
      marginBottom: '4px',
      fontWeight: 'bold',
      fontSize: '14px',
      color: '#333',
    },
    input: {
      padding: '8px 12px',
      fontSize: '14px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      width: '100%',
      boxSizing: 'border-box',
    },
    button: {
      padding: '10px 20px',
      fontSize: '16px',
      fontWeight: 'bold',
      backgroundColor: '#007bff',
      color: '#fff',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      marginTop: '16px',
      alignSelf: 'flex-end',
    },
    para:{
      padding: '0',
    margin: '0',
    fontSize: 'small'
    },
    
  };
  
  // const style = {
  //   position: 'absolute',
  //   top: '50%',
  //   left: '50%',
  //   transform: 'translate(-50%, -50%)',
  //   width: '80%',
  //   maxWidth: '600px',
  //   bgcolor: 'background.paper',
  //   border: '2px solid #000',
  //   boxShadow: 24,
  //   p: 4,
  //   borderRadius: '8px',
  // };

  const handleChange = (e) => {
    setSpinner(true)
    

    if(e.target.name==="kaabil_bank_name")
    {
      if(kfBankList && kfBankList.map((bi)=> {
        if(bi.bank_name===e.target.value)
        {
          console.log("setting account number for "+bi.bank_name);
          let tmpValues=values;
          tmpValues["kaabil_bank_name"]=bi.bank_name
          tmpValues["kaabil_account_number"]=bi.account_number
          console.log(tmpValues); 
          setValues(tmpValues)
          setKaabilBankName(bi.bank_name)
          setKaabilBankAccount(bi.account_number)
        }
      }));
        
    }
    else
    {
        setValues({ ...values, [e.target.name]: e.target.value });
    }
    console.log("here ,",values,{ ...values, [e.target.name]: e.target.value })
    if(e.target.name=="disbursed_date" && customer && (!customer.disbursement || !customer.disbursement.disbursement_memo || (customer.disbursement.disbursement_memo.length==0 || !customer.disbursement.disbursement_memo[0].paid_status) )){
      let updatedValues = { ...values, [e.target.name]: e.target.value };
      preEmiCalculator(e.target.value,updatedValues)
    }
    setSpinner(false)
  };

  const handleOpen = () => {
    setOpen(true);
    editAccount()
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  let totalAmount = 0;
  let firstDate = "";
  const calculate = () =>{
    if(customer.loan.disbursement_date ){
      firstDate = customer.loan.disbursement_date 
    }else if(customer && customer.disbursement && customer.disbursement.disbursement_details && customer.disbursement.disbursement_details.length>0){
      firstDate= customer.disbursement.disbursement_details[0].disbursed_date
    }else{
       firstDate= values.disbursed_date
    }

    if(customer && customer.disbursement && customer.disbursement.disbursement_details && customer.disbursement.disbursement_details.length>0){
      customer.disbursement.disbursement_details.map((e)=>{
        totalAmount = Number(e.disbursed_payment_amount) + Number(totalAmount)
        console.log(e.disbursed_amount)
      })
    }else{
      totalAmount = values.disbursed_payment_amount
    }
  }

  let date = new Date()
  let dd = date.getDate()
  let mm = date.getMonth()+1;
  let yy = date.getFullYear()
  date =yy+ "-" + mm + "-" + dd
  // console.log(date)

  const handleSubmit = (e) => {
    setSpinner(true);
    e.preventDefault(); 

    console.log(values, "here")
    if(values.kaabil_bank_name == "" || values.kaabil_account_number == "")
    {
       window.alert("Invalid kaabil bank name");
       setSpinner(false);
       return;
    }

    let params = [];
    let account_details = [];
    if(customer && customer.disbursement && customer.disbursement.disbursement_accounts){
      account_details = customer.disbursement.disbursement_accounts;
    }else{
      account_details = [];
    }
    if(customer && customer.disbursement && customer.disbursement.disbursement_details ){
    params = customer.disbursement.disbursement_details;
    params.push({ ...values, payment_status:"pending", added_by:user.emp_id });
    }else{
      params = [];
      params.push({ ...values, payment_status:"pending", added_by: user.emp_id});
    }
    let disbursement_memo = [];
    if(customer && customer.disbursement && customer.disbursement.disbursement_memo){
      disbursement_memo = customer.disbursement.disbursement_memo
      disbursement_memo[props.idx]["paid_status"]=true;
    }else{
      disbursement_memo=[];
    }
    calculate();
    console.log(customer.disbursement.disbursement_memo)

    if(Number(totalAmount) > Number(customer.loan.sanction_amount)){
      console.log("test",totalAmount, customer.loan.sanction_amount )
      alert("Amount should be less than or equal to the Sanction Amount")
    }else{
      let deduction=customer.loan.disbursement_deduction;
    if(!deduction)
    {
         deduction=0
    }
    let updateObject = {
      loan :{...customer.loan, disbursed_amount: (totalAmount+deduction) ,disbursed_payment_amount: totalAmount,pre_emi_amount:preEmi  },
      disbursement: {
        disbursement_details: [...params],
        disbursement_accounts: [...account_details],
        disbursement_memo: [...disbursement_memo]
      },
    };
    console.log('updateObject updateObject',values,updateObject);
    loanService
      .updateDisbursement(customer._id, updateObject)
      .then((x) => {
        console.log(x);
        window.alert("Details Added");
        setSpinner(false);
        window.location.reload();
      })
      .catch((err) => {
        setSpinner(false)
        console.log(err);
      });
    }
  };


  const preEmiCalculator = (value,values) => {
    let amount, diff, pre_emi;
    console.log("running", new Date(value))
    if (customer && customer.loan && customer.loan.sanction_amount) {
        amount = Number(customer.loan.sanction_amount)
    } else {
        amount = 0
    }
    if (value !== "") {
         // if (new Date(value).getDate() > 20 || new Date(value).getDate() < 6) {
            let date = new Date(value)
            date.setHours(0,0,0,0)
            date.setDate(5)
            let selectedDate = new Date(value)
            selectedDate.setHours(0,0,0,0)
            if(new Date(value).getDate()<6){
                date.setMonth(new Date(value).getMonth())
            }else{
            date.setMonth(new Date(value).getMonth() + 1)
            }

            diff = new Date(date.getTime() - selectedDate.getTime())
            console.log('date.getTime() - selectedDate.getTime()',date.getTime() , selectedDate.getTime(),diff)
            diff = Math.ceil(diff / (1000 * 3600 * 24))

            if (customer && customer.loan && customer.loan.roi) {

                pre_emi = Math.ceil(amount * customer.loan.roi * diff / 36000)  // 360 days * 100 (roi wala)
                console.log(pre_emi)
                console.log(diff)
                setPreEmi(pre_emi)
            } else {
                pre_emi = 0
                setPreEmi(pre_emi)
            }
            console.log('ghnrt  qewtgj ew8tjew t j4we0',{...values,disbursed_amount:account_data.payment_amount-pre_emi})
            setValues({...values,disbursed_amount:account_data.payment_amount+prevPreEmi-pre_emi});
         
    }
  
}

  const update = (e) => {
    e.preventDefault()
    let params = [];
    let account_details = [];
    if(customer && customer.disbursement && customer.disbursement.disbursement_accounts){
      account_details = customer.disbursement.disbursement_accounts;
    }else{
      account_details = [];
    }
    if(customer && customer.disbursement && customer.disbursement.disbursement_details ){
    params = customer.disbursement.disbursement_details;
    params.splice(props.idx,1)
    params.push({ ...values,payment_status:"paid" });
    }else{
      params = [];
      params.push({ ...values, payment_status:"paid" });
    }
    let disbursement_memo = [];
    if(customer && customer.disbursement && customer.disbursement.disbursement_memo){
      disbursement_memo = customer.disbursement.disbursement_memo
    }else{
      disbursement_memo=[];
    }
    let deduction=customer.loan.disbursement_deduction;
    if(!deduction)
    {
         deduction=0
    }
    let updateObject = {
      loan :{...customer.loan, disbursed_amount: (totalAmount+deduction),disbursed_payment_amount: totalAmount,pre_emi_amount:preEmi },
      disbursement: {
        disbursement_details: [...params],
        disbursement_accounts: [...account_details],
        disbursement_memo: [...disbursement_memo]
      },
    };
    console.log(values);
    loanService
      .updateDisbursement(customer._id, updateObject)
      .then((x) => {
        console.log(x);
        window.alert("Details Added");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
console.log('value is',values)
  return (
    <div>
      <Button onClick={handleOpen} variant="contained" color="primary">
       {props.button}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style.modal}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <form
              style={style.form}
                 onSubmit={(e) => props.isEdit ? update(e) : handleSubmit(e)}
            >
               <div style={ style.row}>
              <div >
              <label style={style.label}>Select Kaabil Bank</label>
                  <select
                   name="kaabil_bank_name"
                  value={kaabilBankName}
                  required
                  style={{
                  width: '100%',
                  padding: '8px',
                  marginTop: '5px',
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                }}
                  onChange={(e) => handleChange(e)}
                 >
                  <option value="" disbled>Select Kaabil Bank Account</option>
                  {kfBankList && kfBankList.map((bi,idx)=> {
                    return <option value={bi.bank_name} key={idx}>{bi.bank_name}</option>
                  })}
                 </select>
                 </div>
                 <div>
                 <label style={style.label} >
                Pre EMI   </label>
                  <input style={style.input}
                    type="number"
                     
                    
                    disabled={true}
                    
                    value={preEmi}
                  />
             
                </div>
              </div>
              <div style={ style.row} className="row">
                <div className="  col">
              <label  style={style.label} >
                 
              Kaabil Account Number </label>
                  <input style={style.input}
                    type={"text"}
                    name="kaabil_account_number"
                    required = {values.payment_type === "Online"}
                    onChange={(e)=> values.payment_type === "Online" ? setValues({...values, kaabil_account_number:kaabilBankAccount}) : setValues({...values, kaabil_account_number:values.payment_type})}
                    value={values.payment_type === "Online" ? kaabilBankAccount : values.payment_type}
                    disbled={true}
                  />
                
               
                </div>
                <div className="  col">
                <label  style={style.label}>
                  Account Holder Name </label>
                  <input style={style.input}
                    type={"text"}
                    name="account_holder_name"
                    value={values.account_holder_name}
                    disabled={true}
                    required
                  />
               
                </div>
              </div>

              <div style={ style.row} className="row">
              <div className="  col">                <label style={style.label}>
                  Disburse Amount </label>
                  <input style={style.input}
                    type="number"
                    name="disbursed_amount"
                    step={"any"}
                    disabled={true}
                    required
                    value={values.disbursed_amount}
                  />
               
                </div>
<div className="  col">
                <label  style={style.label}>
                  Disburse Date </label>
                  <input style={style.input}
                    type={"date"}
                    name="disbursed_date"
                    max= {date}
                    // disabled={props.isEdit}
                    required
                    onChange={(e) => handleChange(e)}
                  />
               
                </div>
              </div>

              <div style={ style.row} className="row">
              <div className="  col">              <label style={style.label} >
                  Payment Type  </label>
                  <input style={style.input}
                    type="text"
                    name="disbursed_account_number"
                    required
                    disabled={true}
                    value={values.payment_type}
                  />
              
                </div>
<div className="  col">
                <label style={style.label} >
                 Cheque/DD Number </label>
                  <input style={style.input}
                    type="text"
                    name="cheque_dd_number"
                    required={values.payment_type === "Cheque" || values.payment_type === "Demand Draft"}
                    value={values.cheque_dd_number}
                    onChange={(e) => setValues({...values, cheque_dd_number:e.target.value})}
                  />
               
              </div>
              </div>

              <div style={ style.row} className="row">
              <div className="  col">
                <label style={style.label}>
                  Customer Bank Account   </label>
                  <input style={style.input}
                    type="text"
                    name="disbursed_account_number"
                    required
                    disabled={true}
                    value={values.disbursed_account_number}
                  />
              
                </div>
                <div className="  col">
                <label style={style.label}>
                  UTR Number </label>
                  <input style={style.input}
                    type="text"
                    name="disbursed_utr_number"
                    pattern=".{3,}"
                    title="Minimum Length Should be 5"
                    required={props.isEdit}
                    value={values.disbursed_utr_number}
                    onChange={(e) => handleChange(e)}
                  />
               
                </div>
              </div>
              <div  >
                 <p style={style.para}  >Net Disbursed Amount : (Memo Amount +  PCPE - ACPE)</p>
                <p style={style.para} >Previous Calculated Pre EMI (PCPE):  {prevPreEmi}</p>
                <p style={style.para} >Acutal Calculated Pre EMI (ACPE):  {preEmi}</p>
              </div>


              {props.isEdit ? <button disabled={spinner} style={style.button}                         className="btn btn-primary"
>Update {  spinner &&  <span className="spinner-border spinner-border-sm"></span>}</button>: <button disabled={spinner} style={style.button}                         className="btn btn-primary"
>Submit {  spinner &&  <span className="spinner-border spinner-border-sm"></span>}</button>}
            </form>
          </Typography>
        </Box>
      </Modal>

    </div>
  );
};

export default AddDisbursementPayment;