import React from 'react'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import { Button } from "@material-ui/core";
import { history } from '../../../_helpers';
import { useReactToPrint } from "react-to-print";
import SanctionLetter from './SanctionLetter';
import { Colenders } from '../../../constants/colenders';
// import KeyFactStatement from './KeyFactStatement';


const Provider = (props) => {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const loan=props.loan;
  let colenderList=[]
  if(loan.co_lending){
     loan.co_lending.map((cl)=> {
      if(!["ColendingCancelled"].includes(cl.co_lender_status)){

        colenderList.push(cl.co_lender_name)
      }
     })
  }
  
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    height: 350,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    //overflow: 'scroll',
    //paddingBottom: '30px',
  };
  // const kfsRef = React.useRef();
  const kfplRef = React.useRef();
  const capitalRef = React.useRef();
  const ugroRef = React.useRef();
  const bfplRef = React.useRef();
  const vistaarRef = React.useRef();
  const homeRef = React.useRef();
  
  // const handleKfsPrint = useReactToPrint({
  //   content: () => kfsRef.current,
  // });

  const handleKfplPrint = useReactToPrint({
    content: () => kfplRef.current,
  });

  const handleCapitalPrint = useReactToPrint({
    content: () => capitalRef.current,
  });

  const handleUgroPrint = useReactToPrint({
    content: () => ugroRef.current,
  })

  const handleBfplPrint = useReactToPrint({
    content: () => bfplRef.current,
  })
  const handleVistaarPrint = useReactToPrint({
    content: () => vistaarRef.current,
  })

  const handleHomePrint = useReactToPrint({
    content: () => homeRef.current,
  })


  return (
    <>
      <Button  color="primary" onClick={handleOpen}>Print</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <Typography id="modal-modal-title" variant="h6" component="h2">
          {/* <button onClick={handleKfsPrint} style={{ padding: "2px" }}>
              Print Key Fact Statement
            </button> */}
            <button onClick={handleKfplPrint} style={{ marginLeft: "10px", marginTop: "20px" }}>
              Print KFPL Letter
            </button>
            {colenderList.includes(Colenders.Ugro) && (
              <button onClick={handleUgroPrint} style={{ marginLeft: "12px", marginTop: "20px" }}>
              Print Ugro Letter
              </button>
            )}
            {colenderList.includes(Colenders.CIFL) && (
            <button onClick={handleCapitalPrint} style={{ marginLeft: "10px", marginTop: "20px" }}>
              Print Capital Letter
            </button>
            )}
            {colenderList.includes(Colenders.BhalaFinance) && (
            <button onClick={handleBfplPrint} style={{ marginLeft: "10px", marginTop: "20px" }}>
              Print BFPL Letter
            </button>
            )}
            {colenderList.includes(Colenders.CIHL) && (
              <button onClick={handleHomePrint} style={{ marginLeft: "10px", marginTop: "20px" }}>
                Capital India Home 
              </button>
            )}
            {colenderList.includes(Colenders.VistaarFinance) && (
              <button onClick={handleVistaarPrint} style={{ marginLeft: "10px", marginTop: "20px" }}>
                Vistaar Finance 
              </button>
            )}
            <div style={{display: "none"}}>
            {/* <KeyFactStatement
                ref={kfsRef}
                dataFill={{ ...props.letter, bank: "Kfs" }}
              ></KeyFactStatement> */}
              <SanctionLetter
                ref={kfplRef}
                dataFill={{ ...props.letter, bank: "KFPL" }}
              ></SanctionLetter>
              <SanctionLetter
                ref={ugroRef}
                dataFill={{ ...props.letter, bank: "ugro" }}
              ></SanctionLetter>
              <SanctionLetter
                ref={capitalRef}
                dataFill={{ ...props.letter, bank: "Capital" }}
              ></SanctionLetter>
              <SanctionLetter
                ref={bfplRef}
                dataFill={{ ...props.letter, bank: "bfpl" }}
              ></SanctionLetter>
                <SanctionLetter
                ref={vistaarRef}
                dataFill={{ ...props.letter, bank: "vistaar" }}
              ></SanctionLetter>
              <SanctionLetter
                ref={homeRef}
                dataFill={{ ...props.letter, bank: "home" }}
              ></SanctionLetter>
            </div>
          </Typography>

        </Box>
      </Modal>
    </>
  )
}

export default Provider