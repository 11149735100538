import React from 'react';
import { useFormik } from 'formik';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import {letter} from "../../../_services/lettertracking.service"


const AddLetter = () => {
  const formik = useFormik({
    initialValues: {
      letter_number: '',
      letter_handler: '',
      letter_type: "",
      letter_comment: '',
    },
    onSubmit: values => {
      letter.create(values)
      .then((letter)=>{
        alert("Data added successfully")
      })
      .catch((err)=> {
        window.alert("Data not added")
      })
    },
  });
  return (
      <> 
      <h2 className='my-3'>Add Letter Deatils</h2>

    <form onSubmit={formik.handleSubmit}>
      <div className='container my-3'>
       <TextField
          fullWidth
          id="letter_number"
          name="letter_number"
          placeholder='Letter Number'
          value={formik.values.letter_number}
          onChange={formik.handleChange}
        />
      </div>
      <div className="container">
        <InputLabel id="demo-simple-select-standard-label">Type</InputLabel>
        <Select
          fullWidth
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          name = "letter_type"
          value={formik.values.letter_type}
          onChange={formik.handleChange}
          label="Age"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="Inward">Inward</MenuItem>
          <MenuItem value="Outward">Outward</MenuItem>
        </Select>
      </div>
      <div className='container my-3'>
      <TextField
          fullWidth
          id="letter_handler"
          name="letter_handler"
          placeholder='Letter Handler'
          value={formik.values.letter_handler}
          onChange={formik.handleChange}
        />
      </div>
      <div className='container my-3'>
      <TextField
          fullWidth
          id="letter_comment"
          name="letter_comment"
          placeholder='Comment'
          value={formik.values.letter_comment}
          onChange={formik.handleChange}
        />
      </div>
      <div>
        <button className='btn btn-primary my-3' type='submit'>Submit</button>
      </div>
    </form>
    </> 
  );
};

export default AddLetter;