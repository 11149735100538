import React, { useState,useEffect, useRef  } from 'react'
import datehelper from '../../../../_helpers/datehelper';
import roleChecker from '../../../../_helpers/rolechecker';
import './audio.css'
// import urlhelper from '../../../../_helpers/urlhelper';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "../../../../css/common.css"
import { employeeService ,loanService} from '../../../../_services';
import {history} from '../../../../_helpers'
import { Button ,Checkbox, FormControlLabel } from '@material-ui/core';
import { Edit ,Delete} from "@material-ui/icons";
import PrintIcon from '@material-ui/icons/Print';
import { TabContext } from '../TabBar';
import { useContext } from "react";
import { sblLetter } from "../../../../_services/letter.services";


const CamLetter = () => {
  const [employeeList, setEmployeeList] = useState({})
  let TabContextValue = useContext(TabContext)
  let cust = TabContextValue.cust
  let update_id = cust?._id
  console.log("cust is",cust)
  console.log("cust is _id",cust._id)
  const [audioExists, setAudioExists] = useState(false);
  const [listenedDuration, setListenedDuration] = useState(0); // Duration listened to in seconds
  const [isConsented, setIsConsented] = useState(false); // Consent checkbox
  const [isSubmitted, setIsSubmitted] = useState(false); // Track form submission
  const audioRef = useRef(null);

  // Check if the audio URL exists
  useEffect(() => {
    if (cust && cust.pd_details && cust.pd_details.pd_audio) {
      setAudioExists(true);
    }
  }, [cust]);

  // Handle the time update while the audio is playing
  const handleTimeUpdate = () => {
    if (audioRef.current) {
      const currentTime = audioRef.current.currentTime;
      setListenedDuration(currentTime); // Update the listened duration
    }
  };

  // Handle consent checkbox change
  const handleConsentChange = (event) => {
    setIsConsented(event.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Ensure consent is provided and at least 3 minutes are listened
    if (isConsented && listenedDuration >= 180) {
      const data = {
        pd_details: {
          consent: isConsented,
        }
      };

      loanService.startpdlocation(update_id, data)
        .then((response) => {
          console.log('Data added successfully:', response);
          alert("Form submitted successfully!");
          window.location.reload(); // Reload the page after success
        })
        .catch((error) => {
          console.log('Error:', error);
          alert('There was an error processing your request.');
        });
    } else {
      alert("You must listen to the audio for at least 3 minutes and provide consent.");
    }
  };

    const user = employeeService.userValue;


    const print = (element) => {
        history.push(`/home/sblletters/letter/show/camletter`, element)
      }
    
      const edit = (element) => {
        history.push(`/home/sblletters/letter/edit/camletter`, { id: element._id })
      }
 const verifyCamLetter =(id)=>{
    console.log("cam letter verified",id)
    sblLetter.verifyCamLetter(id)
    .then((res)=>{
      console.log("res is",res)
      window.location.reload()
    })
    .catch(err=>console.log("err",err))
 }

 const resetCamLetter =(id)=>{
  console.log("cam letter reset",id)
  sblLetter.resetCamLetter(id)
  .then((res)=>{
    console.log("res is",res)
    window.location.reload()
  })
  .catch(err=>console.log("err",err))
}
 async function getAllEmployees() {
  await employeeService.getAll()
      .then((res) => {
          let list = {};
          res.map(e => {
              list[e.emp_id] = e.first_name + ' ' + e.last_name
          })
          setEmployeeList(list)
      })
}
const deleteCamLetter=(id)=>{
  console.log("cam letter delete ",id)
  sblLetter.deleteCamLetter(id)
  .then((res)=>{
    console.log("res is",res)
    window.location.reload()
  })
  .catch(err=>console.log("err",err))
}

useEffect(() => {
   
  getAllEmployees()
}, [])
  return (
    <div>
        <h3>CAM Letter List</h3>
        {( roleChecker.isCreditTeamAccess(user.role) || roleChecker.isHOCreditTeamAccess(user.role) || roleChecker.isGMLevelAccess(user.role)) && (!cust.cam_letters ||  cust.cam_letters.length ==0) && (
       
       <div>
          {audioExists && !cust?.pd_details?.consent ? (
            <div>
            
              
              <audio
                ref={audioRef}
                src={cust.pd_details.pd_audio}
                controls
                onTimeUpdate={handleTimeUpdate} // Track time while audio is playing
                preload="auto" // Make sure audio data is preloaded
              >
                Your browser does not support the audio element.
              </audio>
              <br />
              <p>
                You have listened for {Math.floor(listenedDuration / 60)} minutes and {Math.floor(listenedDuration % 60)} seconds.
                {listenedDuration >= 180 ? (
                  <span style={{ color: 'green' }}> (You have listened for enough time)</span>
                ) : (
                  <span style={{ color: 'red' }}> (Please listen to at least 3 minutes)</span>
                )}
              </p>

              {/* Consent Checkbox */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isConsented}
                    onChange={handleConsentChange}
                    color="primary"
                  />
                }
                label="I consent to the terms"
              />

              {/* Submit Button */}
              <Button
                color="primary"
                disabled={listenedDuration < 180 || !isConsented} // Disable if not enough time or no consent
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          ) : (
            <Button
              color="primary"
              onClick={() => history.push(`/home/sblletters/letter/camletterform`, cust._id)}
            >
              Fill CAM Letter
            </Button>
          )}

          {isSubmitted && <div>Your form has been submitted!</div>}
        </div>
          // <Button
          //   color="primary"
          //   onClick={() =>
          //     history.push(`/home/sblletters/letter/camletterform`, cust._id)
          //   }
          // >
          //   Fill CAM Letter
          // </Button>
        )}
        <br />

        {cust.cam_letters && cust.cam_letters.length > 0 ?
          <div className='table-border'>
            <Table className="table table-hover">
              <Thead>
                <Tr>
                  <Th className='table-row-th' style={{ width: "25%" }}>Date</Th>
                  <Th className='table-row-th' style={{ width: "25%" }}>Name</Th>
                  <Th className='table-row-th' style={{ width: "25%" }}>Loan No</Th>
                  <Th className='table-row-th' style={{ width: "15%" }}>Sanction Amount</Th>
                  <Th className='table-row-th' style={{ width: "10%" }}>Edit</Th>
                  <Th className='table-row-th' style={{ width: "10%" }}>Print</Th>
                  <Th className='table-row-th' style={{ width: "10%" }}>Varify</Th>
                  <Th className='table-row-th' style={{ width: "10%" }}>Verified By</Th>
                  <Th className='table-row-th' style={{ width: "10%" }}>Action</Th>

                </Tr>
              </Thead>
              <Tbody>
                {cust.cam_letters && cust.cam_letters.map((element) => {
                  return ( (!element.status || element.status!="Rejected") &&  <Tr key={element._id}>
                    <Td className='table-row-td' style={{ width: "25%" }}>{datehelper.displayDate(new Date(element.createdAt))}</Td>
                    <Td className='table-row-td' style={{ width: "25%" }}>{element.applicant}</Td>
                    <Td className='table-row-td' style={{ width: "25%" }}>{element.application_id}</Td>
                    <Td className='table-row-td' style={{ width: "15%" }}>{element.sanction_amount}</Td>
                    <Td className='table-row-td' style={{ width: "10%" }}>
                   { (roleChecker.isCreditTeamAccess(user.role) || roleChecker.isHOCreditTeamAccess(user.role) || roleChecker.isGMLevelAccess(user.role) || roleChecker.isColendingTeamAccess(user.role) ) && (!element.verify_details || !element.verify_details.is_verified) &&<Button className='btn btn-primary' onClick={() => edit(element)} ><Edit /> </Button>}
                    </Td>
                    <Td className='table-row-td' style={{ width: "10%" }}><Button className='btn btn-primary' onClick={() => print(element)} >
                    {((!element.verify_details || element.verify_details.is_verified) || (roleChecker.isHOCreditApproverAccess(user.role) )) && <> <PrintIcon /> </>}
                   </Button></Td>
                   <Td className='table-row-td'>{(element.verify_details &&  !element.verify_details.is_verified)?<>
                                {roleChecker.isHOCreditApproverAccess(user.role) && user.emp_id!=element.created_emp_id ? <Button variant="contained" color="primary" onClick={() => {
                                  if (window.confirm("Are you sure you want to verify?")) {
                                                   verifyCamLetter(element._id);
    }
                                    }}>Verify</Button>:<Button disabled>Pending</Button>}
                            </>:<>
                            {
                                (element.verify_details && element.verify_details.is_verified) ? <> <p style={{color:'green'}}>Verified</p>
                                {roleChecker.isGMLevelAccess(user.role) && <Button style={{ background:"red",color:"white"}} variant='contained'  onClick={() => {
                                  if (window.confirm("Are you sure you want to reset ?")) {
                                                   resetCamLetter(element._id);
    }
                                    }} >Reset</Button>}
                                </>:null
                            }
                               
                            </>} </Td>
                            <Td className='table-row-td'>
                                {
                                    (element.verify_details && element.verify_details.is_verified) && <p>{employeeList[element.verify_details.verified_by_emp_id]}</p>
                                }
                            </Td>
                            <Td className='table-row-td'>
                                 {(!element.verify_details  || !element.verify_details.is_verified) && [20638,20675].includes(user.emp_id)  && <Button onClick={()=>{if(window.confirm('Are you sure! you want to delete this cam letter ?')){
                                  deleteCamLetter(element._id)
                                 }}}><Delete/></Button>}
                            </Td>
                  </Tr>)
                })}

              </Tbody>
            </Table>
          </div> : <span style={{ color: "red" }}> No CAM letter generated.</span>
        }
    </div>
  )
}

export default CamLetter