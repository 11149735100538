import React, { useState } from 'react';
import {
  Modal, Button, Typography, Box, TextField, MenuItem, makeStyles
} from '@material-ui/core';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
 import { employeeExpenseService } from "../../../_services/employeeExpense.service";
import { notifyInfo, notifyFailed } from "../../../_helpers/notify";
import { ToastContainer } from "react-toastify";
import { ApprovalRequestFilter } from '../../MakerCheckerFramework/ApprovalRequestFilter';
import { employeeService ,loanService } from "../../../_services/employee.service";
import roleChecker from "../../../_helpers/rolechecker"
import workflowHelper from "../../../_helpers/workflowhelper";
const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '90%',
    maxWidth: 600,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    maxHeight: '80vh',
    overflowY: 'auto',
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const validationSchema = Yup.object({
  status: Yup.string().required('Status is required'),
  comment: Yup.string().required('Comment is required'),
  utr: Yup.string().when('status', {
    is: 'Paid', // Condition: when status is "Paid"
    then: Yup.string().required('UTR is required when status is Paid'), // UTR is required
    otherwise: Yup.string().nullable(), // Otherwise UTR is optional
  }),
});

function AccountVerificationBox(params) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const id = params?.data?._id;
  const statusdata = params?.data?.status;
  const data = params?.data;
  const user = employeeService.userValue;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (values) => {
    console.log(values);
    
      const payload = { status: values.status, comment: values.comment };
      if (values.status === 'Paid') {
        payload.utr = values.utr;
      }
      employeeExpenseService.verifyBranchExpense(id, payload)
        .then(res => {
          if (res && res.status === 'success') {
            notifyInfo(`${res.message}`);
          } else {
            notifyFailed(`${res.message}`);
          }
        })
        .catch(err => {
          console.log('err of verify employee  expense', err);
          notifyFailed(`${err.message}`);
        });
    }


  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleOpen}>
        {statusdata && statusdata === "Verified" ? "Accounts" : "To Pay"}
      </Button>
      <ToastContainer />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="comments-modal-title"
      >
        <div className={classes.paper}>
          <Typography variant="h5" id="comments-modal-title" gutterBottom>
            Choose status with a comment
          </Typography>

          <Formik
            initialValues={{ status: '', comment: '', utr: '' }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ handleChange, handleBlur, values }) => (
              <Form>
                <TextField
                  select
                  name="status"
                  label="Status"
                  value={values.status}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  variant="outlined"
                  className={classes.field}
                >
                  <MenuItem value="" selected>Select</MenuItem>
                  {(roleChecker.isHRDept(user.role) || roleChecker.isZonalLevelAccess(user.role)) &&  <MenuItem value="ReferToAccount">Refer To Account</MenuItem>}

                  {roleChecker.isAccountChecker(user.role) &&  <MenuItem value="Verified">Verified</MenuItem>}
                  {roleChecker.isAccountChecker(user.role) && <MenuItem value="Paid">Paid</MenuItem>}
                </TextField>
                <ErrorMessage name="status" component="div" style={{ color: 'red' }} />

                <TextField
                  name="comment"
                  label="Comment"
                  value={values.comment}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  className={classes.field}
                  required
                />
                <ErrorMessage name="comment" component="div" style={{ color: 'red' }} />

                {values.status === 'Paid' && (
                  <TextField
                    name="utr"
                    label="UTR"
                    value={values.utr}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    variant="outlined"
                    className={classes.field}
                    required
                  />
                )}
                <ErrorMessage name="utr" component="div" style={{ color: 'red' }} />

                <Box display="flex" justifyContent="flex-end">
                  <Button variant="contained" color="primary" type="submit" className={classes.button}>
                    Submit
                  </Button>
                  <Button variant="contained" color="secondary" onClick={handleClose} className={classes.button}>
                    Close
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
}

export default AccountVerificationBox;
