import React from "react";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Collapse
} from "@material-ui/core";
import { ExpandMore,ExpandLess,AccountBalanceWallet,MonetizationOn} from "@material-ui/icons";
import roleChecker from "../../_helpers/rolechecker";

export default function ColendingMenuList(props) {
  const [open, setOpen] = React.useState(false);
  const { path } = props.match;
  const user =props.user;

  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  return (
    <React.Fragment>
    <ListItem button onClick={handleClick}>
    <ListItemIcon>
         <MonetizationOn></MonetizationOn>
    </ListItemIcon>
        <ListItemText primary="CoLending" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" style={{paddingLeft:"50px"}}>
        <ListItem
              button
              key="mytasks"
              component="a"
              href={`${path}/colending/dashboard`}
            >
            <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem
              button
              key="colendingpay"
              component="a"
              href={`${path}/colending/payments`}
            >
            <ListItemText primary="Search Loans" />
            </ListItem>
            
      </List>
      </Collapse>
      </React.Fragment>
  )
}
