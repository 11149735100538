import { fetchWrapper} from "../_helpers";

const apiUrl = process.env.REACT_APP_API_URL;

const baseUrl = `${apiUrl}/employeeexpenses`;

export const employeeExpenseService = {
   getById,
  getAll,
  create,
  update,
  delete: _delete,
  verifyBranchExpense
};

 
function getAll() {
  return fetchWrapper.get(baseUrl);
}
function getById(params) {
  return fetchWrapper.post(`${baseUrl}/getbyid`,params);
}

function create(params) {
  return fetchWrapper.post(baseUrl, params)
}

function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`).then((x) => {
    return x;
  });
}

function verifyBranchExpense(id,params){
  return fetchWrapper.post(`${baseUrl}/verifybranchexpense/${id}`, params)
}

function update(id,params) {
  return fetchWrapper.post(`${baseUrl}/updateemployeeexpense/${id}`,params);
}

