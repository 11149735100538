import React from "react";
import ApplicantsList from "../ApplicantsList";

import { Route, Switch } from "react-router-dom";
import SaleDetails from "./SaleDetails";

export default function Applicants({ match }) {
  const { path } = match;

  return (
    <div style={{ height: "100vh" }} className="bg-light">
      <Switch>
        <Route exact path={path} component={ApplicantsList} />
        <Route path={`${path}/:id`} component={SaleDetails} />
      </Switch>
    </div>
  );
}
