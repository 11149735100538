import React from "react";
import { Grid, Card, Button, CardActions } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { loanService } from "../../_services/loan.service";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { employeeService } from "../../_services";
import { LoanStages } from "../../constants/loanstages";
import roleChecker from "../../_helpers/rolechecker";
import { Role } from "../../_helpers";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexGrow: 1,
  },
  card: {
    minHeight: 250,
    minWidth: 250,
  },
}));

export default function SalesList({ match }) {
  const { path } = match;
  const classes = useStyles();
  const user= employeeService.userValue;
  const [applicants, setApplicants] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    async function getApplicants() {
      var cust = await loanService.getAllByStage(LoanStages.Sales);
      console.log(cust);
      let tempLoans=cust.filter((l)=> l.stage === LoanStages.Sales);
      // cust = cust.filter((entry) => entry.submitted == true);
      if (roleChecker.isHOLevelAccess(user.role))
      { 
           setApplicants(tempLoans);
      }else if(user.role === Role.ZSM  || user.role ===Role.ZCM){
        setApplicants(tempLoans.filter((applicant) => applicant.branches.length>0 && (applicant.branches[0].branch_zone === user.branch_id || (user.operation_branch).includes(applicant.branches[0].branch_zone))));  
      }
      else if(user.role === Role.RSM  || user.role === Role.RCM  )
      {
        setApplicants(tempLoans.filter((applicant) => applicant.branches.length>0 && (applicant.branches[0].branch_region === user.branch_id || (user.operation_branch).includes(applicant.branches[0].branch_region))));        
      }
      else if(user.role === Role.ASM || user.role === Role.ACM )
      {
        setApplicants(tempLoans.filter((applicant) => applicant.branches.length>0 && (applicant.branches[0].branch_area === user.branch_id || (user.operation_branch).includes(applicant.branches[0].branch_area))));        
      }
      else if(user.role === Role.CSM)
      {
        setApplicants(tempLoans.filter((applicant) => applicant.branches.length>0 && (applicant.branches[0].branch_cluster === user.branch_id || (user.operation_branch).includes(applicant.branches[0].branch_cluster))));        
      }
      else if(user.role===Role.BM)
      {
        setApplicants(tempLoans.filter((applicant) => applicant.employees.length>0 && (applicant.employees[0].branch_id === user.branch_id || (user.operation_branch).includes(applicant.branches[0].branch_id))));  
      }    
      else if(user.role === Role.Sales) {
        setApplicants(tempLoans.filter((applicant) => applicant.employees.length>0 && applicant.employees[0].emp_id === user.emp_id));
      }
     
    }

    getApplicants();
  }, []);

  return (
    <div>
      <h1>Choose Applicant</h1>
      <br></br>
      <TableContainer>
      <Table>
          <Thead>
            <Tr>
              <Th style={{ width: "10%" }}>
                  Date
              </Th>
               
              <Th style={{ width: "10%" }}>Application #</Th> 
              <Th style={{ width: "10%" }}>Customer Name</Th>
              <Th style={{ width: "10%" }}>Loan Stage</Th>
              <Th style={{ width: "10%" }}>Loan Amount</Th>
              <Th style={{ width: "10%" }}>Branch Id</Th>
              <Th style={{ width: "10%" }}>RO</Th>
              <Th style={{ width: "10%" }}>Application Fees</Th>
              <Th style={{ width: "10%" }}>Applicant Verification</Th>
              <Th style={{ width: "20%" }}>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {applicants && applicants.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((applicant,idx)=>(
              <Tr key={idx}><Td>{applicant.created.split("T")[0]}</Td><Td>{applicant.application_id}</Td><Td>{applicant.applicant && applicant.applicant.general_information && applicant.applicant.general_information.name}</Td><Td>{applicant.stage}</Td><Td>{applicant.loan.amount}</Td><Td>{applicant.branch_id}</Td><Td>{applicant.employees && applicant.employees.length>0 && (applicant.employees[0].first_name + " "+applicant.employees[0].last_name)}</Td><Td>{applicant.application_fees && (applicant.application_fees.payment_amount)}</Td>
              <Td>{applicant.personal_details && applicant.personal_details.otp_verified && (
                  <p style={{color:"#00FF00"}}>Verified</p>
                )}
                {applicant.personal_details && !applicant.personal_details.otp_verified && (
                  <p style={{color:"#FF0000"}}>Unverified</p>
                )}

                  </Td><Td>
              <Button component="a"
              href={`/home/sblloans/details/${applicant._id}`}
              size="small"
              color="primary"
            >
              View Details</Button>
              </Td></Tr>
            ))}
            {!applicants && (
              <Tr>
                <Td colSpan="9" className="text-center">
                  <span className="spinner-border spinner-border-lg align-center"></span>
                </Td>
              </Tr>
            )}
          </Tbody> 
          </Table>
      </TableContainer>
      {applicants ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={applicants.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        <div />
      )}
    </div>
  );
}
