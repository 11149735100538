import React from 'react'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { useReactToPrint } from "react-to-print";
import { Button } from "@material-ui/core";
import roleChecker from '../../../_helpers/rolechecker';
import { employeeService } from '../../../_services';
import AddPaymentDetailsModal from "./PaymentSheet/AddPaymentDetailsModal";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { virtualAccountService } from '../../../_services/virtualaccount.service';
import { Typography } from '@material-ui/core';
import { history } from '../../../_helpers';
import { FaCalculator } from 'react-icons/fa';
import displayhelper from '../../../_helpers/displayhelper';
export default function PaymentDetailsTab(props) {
  const user = employeeService.userValue;
  let loan = props.loan

  const [va_details, setVADetails] = React.useState(null);
  // const loan = props.loan;

  const RepaymentScheduleRef = React.useRef();
  React.useEffect(() => {

    let fields = { 'loan_account': loan.loan_account }
    virtualAccountService.getByLoanAccount(fields).then((va) => {
      //console.log(va);
      setVADetails(va);
    })
      .catch((err) => {
        console.log(err);
      })

  }, [])

  async function createQRCode() {
    let fields = {
      'loan_account': loan.loan_account,
      'customer_name': loan.name,
      'customer_mobile': loan.contact
    }
    virtualAccountService.create(fields).then((va) => {
      console.log(va);
      setVADetails(va);
    })
      .catch((err) => {
        console.log(err);
      })

  }


  const handleSubmissionPrint = useReactToPrint({
    content: () => RepaymentScheduleRef.current,
    pageStyle: "print"
  });

  let date = new Date(loan.date)
  console.log(date)
  date.setHours(0, 0, 0, 0)
  date.setDate(date.getDate() - 1)


  const options = [];

  for (let i = 1; i <= loan.period; i++) {
    //  date = date + 2592000000
    //  console.log(date)
    options.push(i)
  }

  return (
    <div>
      <div style={{ display: 'flex', float: "right" }}>
        {(roleChecker.hasAccountsAccess(user.role) || ((roleChecker.isHOGoldAccess(user.role) || roleChecker.isBranchLevelAccess(user.role)) && loan.loan_status !== 'Locked' || loan.loan_status === 'Locked')) &&
          <AddPaymentDetailsModal loan={loan} history={props.history} user={user} />}
      </div>
      <h2>Payment Details</h2>
      <div className='ornaments-details'>
        <Table>
          <Thead>
            <Tr>
              <Th className='table-row-th'>SR.NO</Th>
              <Th className='table-row-th'>Payment Date</Th>
              <Th className='table-row-th'>Payment Type</Th>
              <Th className='table-row-th'>Payment Mode</Th>
              <Th className='table-row-th'> Reference Number</Th>
              <Th className='table-row-th'>Payment Amount</Th>
            </Tr>
          </Thead>
          <Tbody>
            {console.log("Test payment", loan.payment_details)}
            {loan.payment_details && loan.payment_details.map((x, idx) => <Tr key={idx}>
              <Td className='table-row-td'>{idx + 1}</Td>
              <Td className='table-row-td'>{x.payment_date}</Td>
              <Td className='table-row-td'>{x.payment_type}</Td>
              <Td className='table-row-td'>{x.payment_mode}</Td>
              <Td className='table-row-td'>{x.payment_ref}</Td>
              <Td className='table-row-td'>₹{x.payment_amount &&displayhelper.displayNumberAccounting(x.payment_amount)}</Td>
            </Tr>)}
          </Tbody>
        </Table>
      </div>
      <div>
        <br />
        <br/>
        <br/>
        <div style={{ textAlign: "end",marginBottom:"-30px" }}>
          {(
            ((loan.loan_status !== 'Cancelled' && roleChecker.hasAccountsAccess(user.role))
              || (loan.loan_status !== 'Cancelled' && roleChecker.isBMLevelAccess(user.role))
              || (loan.loan_status !== 'Cancelled' && roleChecker.isHOGoldAccess(user.role))) &&
            <Button
            style={{color: "#3760F1",border: '2px solid #3760F1',}}
              onClick={() =>
                history.push({ 'pathname': `/home/goldloans/interestcalculatorv2`, 'loan': loan })
              }
              color="primary"
              compact="true"
            ><FaCalculator style={{height:'18px'}}/>&nbsp;Interest Calculator</Button>
          )}
          <button onClick={handleSubmissionPrint} style={{color: "#3760F1",border: '2px solid #3760F1',marginLeft:"30px"}}>Print Copy</button>
        </div>
        <Typography ref={RepaymentScheduleRef} id="modal-modal-title" variant="h6" component="h2">
          <h2>Repayment Schedule List</h2>
          <div className='ornaments-details'>
            <Table >
              <Tr>
                <Td className='table-row-th'>Loan Account:</Td>
                <Td className='table-row-td'>{loan.loan_account}</Td>
                <Td className='table-row-th'>Name</Td>
                <Td className='table-row-td'>{loan.name}</Td>
              </Tr>
              <Tr>
                <Td className='table-row-th'>ROI</Td>
                <Td className='table-row-td'>{loan.roi}%</Td>
                <Td className='table-row-th'>Loan Amount</Td>
                <Td className='table-row-td'>{loan.loan_amount}</Td>
              </Tr>
            </Table>
          </div>
          <br />
          <br/>
          <br/>
          <h2>Interest Table</h2>
          <div className='ornaments-details'>
            <Table style={{ borderBottom: "2px solid black" }} >
              <Thead>
                <Tr>
                  <Th className='table-row-th'>Month</Th>
                  <Th className='table-row-th'>EMI Amount</Th>
                  <Th className='table-row-th'>Date</Th>
                </Tr>
              </Thead>
              <Tbody>
                {options && options.map((element) => {
                  return <React.Fragment>
                    <Tr>
                      <Td className='table-row-td'>Month {element}</Td>
                      <Td className='table-row-td'>{Math.round(loan.loan_amount * loan.roi / 100)}</Td>
                      <Td className='table-row-td'>{new Date(date.setDate(date.getDate() + 30)).toDateString()}</Td>
                    </Tr>
                  </React.Fragment>
                })}
              </Tbody>
            </Table>
          </div>
          <br />
          {va_details && (
            <div>
              <h2>Payment QR Code</h2>
              <div
                style={{ paddingLeft: '50px', paddingTop: '50px' }}
              >
                <p><b>Account Payee:</b> Kaabil Finance Private Ltd</p>
                <p><b>Bank Name:</b> ICICI Bank</p>
                <p><b>Account Number:</b> {va_details && va_details.va_num}</p>
                <p><b>IFSC Code:</b> ICIC0000106</p>
                {
                  va_details && process.env.REACT_APP_QR_CODE_TYPE === "VPA" && va_details.vpa_qrcode_url && (
                    <div><img src={va_details.vpa_qrcode_url}></img></div>
                  )
                }
                {
                  va_details && process.env.REACT_APP_QR_CODE_TYPE !== "VPA" && (
                    <div><img src={va_details.qrcode_url}></img></div>
                  )
                }
              </div>
            </div>
          )}
          {!va_details && (
            <div>
              <br />
              <Button
                color="primary"
                variant='contained'
                style={{ "margin": 10 }}
                onClick={() => createQRCode()}>Create QR Code</Button>
            </div>
          )}
        </Typography>
      </div>
    </div>
  )
}
