import React from "react";
import { Typography } from "@material-ui/core";

export default function EmptyCart() {
  return (
    <div className="container mt-2">
      <div className="row">
        <div className="col-10 mx-auto text-center text-title">
          <Typography style={{ textAlign: "center" }} variant="h5">
            Coapplicants List
          </Typography>
        </div>
      </div>
    </div>
  );
}
