import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse
} from "@material-ui/core";
import { ExpandMore,ExpandLess} from "@material-ui/icons";
import  MonetizationOnIcon  from "@material-ui/icons/MonetizationOn";
import roleChecker from "../../_helpers/rolechecker";

export default function GoldMenuList(props) {
  const [open, setOpen] = React.useState(false);
  const { path } = props.match;
  const user =props.user;

  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  return (
    <React.Fragment>
    <ListItem button onClick={handleClick}>
        <ListItemIcon><MonetizationOnIcon/></ListItemIcon>
        <ListItemText primary="Gold Loans" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" style={{paddingLeft:"50px"}}>
        <ListItem
            hidden={!(roleChecker.hasAccountsAccess(user.role) || roleChecker.isHOGoldAccess(user.role) || roleChecker.isBranchLevelAccess(user.role)  )}
            button
            key="valist"
            component="a"
            href={`${path}/goldloans`}
          >
            <ListItemText primary="Loan List" />
          </ListItem>
          <ListItem
            hidden={!(roleChecker.hasAccountsAccess(user.role) || roleChecker.isHOGoldAccess(user.role) || roleChecker.isBranchLevelAccess(user.role)  )}
            button
            key="valist"
            component="a"
            href={`${path}/goldloans/upcomingemilist`}
          >
            <ListItemText primary="Next day Emi" />
          </ListItem>
          <ListItem
            hidden={!(roleChecker.hasAccountsAccess(user.role) || roleChecker.isHOGoldAccess(user.role) || roleChecker.isBranchLevelAccess(user.role)  )}
            button
            key="valist"
            component="a"
            href={`${path}/goldloans/upcomingweekemilist`}
          >
            <ListItemText primary="Upcoming Week Emi" />
          </ListItem>
          <ListItem
            hidden={!(roleChecker.hasAccountsAccess(user.role) || roleChecker.isHOGoldAccess(user.role) || roleChecker.isBranchLevelAccess(user.role))}
            button
            key="valist"
            component="a"
            href={`${path}/goldloans/dueemilist`}
          >
            <ListItemText primary="Last week Emi" />
          </ListItem>
          <ListItem
            hidden={!(roleChecker.hasAccountsAccess(user.role)  )}
            button
              key="import"
              component="a"
              href={`${path}/goldloans/colenderpayments`}
            >
          
              <ListItemText primary="Co-lender Payments" />
            </ListItem>

          <ListItem
            hidden={!(roleChecker.hasAccountsAccess(user.role) )}
            button
            key="vap"
            component="a"
            href={`${path}/goldloans/paymentlist`}
          >
            <ListItemText primary="Customer Payments" />
          </ListItem>
          <ListItem
            hidden={!(roleChecker.hasAccountsAccess(user.role)  )}
            button
            key="col"
            component="a"
            href={`${path}/goldloans/colendinglist`}
          >
            <ListItemText primary="Co-Lending List" />
          </ListItem>
          <ListItem
            hidden={!(roleChecker.hasAccountsAccess(user.role) || roleChecker.isHOGoldAccess(user.role) || roleChecker.isBranchLevelAccess(user.role))}
            button
            key="bran"
            component="a"
            href={`${path}/goldloans/branchsummary`}
          >
            <ListItemText primary="Gold Branch Summary" />
          </ListItem>

          <ListItem
            hidden={!(roleChecker.isGMLevelAccess(user.role) || roleChecker.isAccountChecker(user.role) || roleChecker.hasAccountsAccess(user.role))}
            button
            key="bran"
            component="a"
            href={`${path}/goldloans/loancloserrequest`}
          >
            <ListItemText primary="Loan Closer Request" />
          </ListItem>
          <ListItem
            hidden={!(roleChecker.isGMLevelAccess(user.role) || roleChecker.isAccountChecker(user.role) || roleChecker.hasAccountsAccess(user.role) || roleChecker.isBranchLevelAccess(user.role))}
            button
            key="bran"
            component="a"
            href={`${path}/goldloans/loanrenewrequest`}
          >
            <ListItemText primary="Loan Renew Request" />
          </ListItem>

        </List>
      </Collapse>
      </React.Fragment>
  )
}
