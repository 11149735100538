import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import './ViewLoanDetails.css';
import { employeeService, misService } from '../../../_services';
import { Avatar } from '@material-ui/core';
import CustomerDetailsTab from './CustomerDetailsTab';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import LoanDetailsTab from './LoanDetailsTab';
import GoldDetailsTab from './GoldDetailsTab';
import ColenderDetailsTab from './ColenderDetailsTab';
import PaymentDetailsTab from './PaymentDetailsTab';
import { FaHandshake } from 'react-icons/fa';
import { AiTwotoneGold } from 'react-icons/ai';
import { MdPayments } from 'react-icons/md';
import { GiPayMoney } from 'react-icons/gi';
import { RiAuctionFill } from 'react-icons/ri';
import { GiTakeMyMoney } from 'react-icons/gi';
import DisbursementDetailsTab from './DisbursementDetailsTab';
import { AuctionDetailsTab } from './AuctionDetailsTab';
import roleChecker from '../../../_helpers/rolechecker';
import displayhelper from '../../../_helpers/displayhelper';
import ActionModal from './ActionModal';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

export default function ViewLoanDetailsTabs(props) {
    console.log("Test props", props)
    const user = employeeService.userValue;
    // const loan = props.location.state
    const data = props.location.state
    const id = data.bank_details
    const loanId=data._id
    const customer_id = data.customer_id
    const [customer, setCustomer] = useState()
    const getBankAndCustomerDetails = async () => {
        await misService.getBankAndCustomerDetails(loanId)
            .then((res) => setCustomer(res))
            .catch((err) => console.log(err))
    }

    const [loan,setLoan]=useState()
    const getLoanById=async()=>{
       await misService.getLoanById(loanId)
       .then((res)=>setLoan(res))
       .catch((err)=>alert(err))
    }
    useEffect(()=>{
      getLoanById()
      getBankAndCustomerDetails()
    },[])
    console.log("test loan ",loan)
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
         <div>
            <AppBar position="static" style={{ background: "#007FFF", color: "white" }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable force tabs example"
                >
                    <Tab style={{color:"white",fontWeigth:"bold"}} label="Customer Details" icon={<Avatar size={37}/>} {...a11yProps(0)} />
                    <Tab style={{color:"white",fontWeigth:"bold"}} label="Gold Details" icon={<AiTwotoneGold size={37} />} {...a11yProps(1)} />
                    <Tab style={{color:"white",fontWeigth:"bold"}} label="Loan Details" icon={<GiTakeMyMoney size={37}/>} {...a11yProps(2)} />
                    <Tab style={{color:"white",fontWeigth:"bold"}} label="Co-lending Details" icon={<FaHandshake size={37}/>} {...a11yProps(3)} />
                    <Tab style={{color:"white",fontWeigth:"bold"}} label="Payments" icon={<MdPayments size={30}/>} {...a11yProps(4)} />
                    <Tab style={{color:"white",fontWeigth:"bold"}} label="Disbursement" icon={<GiPayMoney size={37}/>} {...a11yProps(5)} />
                    <Tab style={{color:"white",fontWeigth:"bold"}} label="Auction" icon={<RiAuctionFill size={37}/>} {...a11yProps(6)} />
                </Tabs>
            </AppBar>
            <br />
            {loan&&<ActionModal loan={loan}/>}
         {loan&&<div className="details-container">
                <br />
                {loan.details && loan.details.customer_pic ? (
                    <img
                        src={loan.details && loan.details.customer_pic}
                        alt="customer pic"
                        className="customer-pic"
                    />
                ) : (
                    <Avatar style={{
                        width: '175px',
                        height: '180px',
                        borderRadius: '50%',
                        objectFit: 'cover',
                        border: '3px solid #3760F1',
                        marginLeft: '30px',
                        float: 'left'
                    }} />
                )}
                <Table className="details-table">
                    <Tr>
                       <Td className="title">Customer Name</Td>
                        <Td className="colon">:</Td>
                        <Td className="value">{loan.name}</Td>
                    </Tr>
                    <Tr>
                        <Td className="title">Loan Account Number</Td>
                        <Td className="colon">:</Td>
                        <Td className="value">{loan.loan_account}</Td>
                    </Tr>
                    <Tr>
                        <Td className="title">Loan Amount</Td>
                        <Td className="colon">:</Td>
                        <Td className="value">
                         ₹{displayhelper.displayNumberAccounting(loan.loan_amount)}
                        </Td>
                    </Tr>
                    <Tr>
                        <Td className="title">Branch</Td>
                        <Td className="colon">:</Td>
                        <Td className="value">{loan.branch_name}</Td>
                    </Tr>
                </Table>
            </div>}
            <br />
            {loan&&<div> 
            <TabPanel value={value} index={0}>
                <CustomerDetailsTab loan={loan} customer={customer} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <GoldDetailsTab loan={loan} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <LoanDetailsTab loan={loan} />
            </TabPanel>
          {roleChecker.hasAccountsAccess(user.role) && <TabPanel value={value} index={3}>
                <ColenderDetailsTab loan={loan} customer={customer}/>
            </TabPanel>}
            <TabPanel value={value} index={4}>
                <PaymentDetailsTab loan={loan} />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <DisbursementDetailsTab loan={loan}/>
            </TabPanel>
            {(roleChecker.isGMLevelAccess(user.role) || roleChecker.isAccountChecker(user.role) ) && (loan.loan_status !== 'Closed') &&(<TabPanel value={value} index={6}>
                <AuctionDetailsTab loan={loan}/>
            </TabPanel>)}
            </div>}
        </div>
    );
}

