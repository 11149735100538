import React from "react";
import { useState, useEffect } from "react";
import roleChecker from "../_helpers/rolechecker";
import { employeeService, misService } from "../_services";
import { BarChart, PieChart ,Pie , Cell ,Bar, CartesianGrid, XAxis, YAxis, Legend, Tooltip, LabelList, ResponsiveContainer } from "recharts";
import { reportsService } from "../_services/reports.service";
import Product from "../_helpers/product";
import { Button } from "@material-ui/core";
import { history } from "../_helpers";
import { Paper } from "@material-ui/core";
import displayhelper from "../_helpers/displayhelper";
import datehelper from "../_helpers/datehelper";
function TeamDashboardTab() {
  const user = employeeService.userValue;
  const [metricsData, setMetricsData] = useState({})
  const [time, setTime] = useState(Date.now());
  let todayDate= new Date()
  let currentDate= new Date();
  let todayEnd= datehelper.getDateEnd(todayDate);
  let hours=Math.floor(datehelper.getDateDiffSeconds(todayEnd,currentDate)/3600);
  let minutes=Math.floor((datehelper.getDateDiffSeconds(todayEnd,currentDate)-hours*3600)/60);
  let seconds=Math.floor((datehelper.getDateDiffSeconds(todayEnd,currentDate)-hours*3600-minutes*60));
  
  console.log("hourse left="+hours);
  const [hourLeft,setHourLeft]= useState(hours);
  const [minuteLeft,setMinuteLeft]= useState(minutes);
  const [secondLeft,setSecondLeft]= useState(seconds);
  
  let colors = ['#3C2A21', '#FFA500', '#5D8AA8', '#46C2CB', '#800000','#2D87BB','#64C2A6',"#547ac4","#8a8a5c"]
  
  useEffect(() => {
    loadMetrics()
  }, [])

  async function loadMetrics()
  {
      reportsService.getMetricsData({ 'metric_key': "all" }).
      then((metricListObj) => {
        console.log(metricListObj);
        let tmpMetricsData={}
        metricListObj.map((mo)=> {
          tmpMetricsData[mo.key]=mo.display_data
        })
        console.log(tmpMetricsData);
        setMetricsData(tmpMetricsData);
      })
      .catch((err)=> {
        console.log(err);
      })
  }

function reloadPage()
{
   setTime(new Date());
   console.log("reloading ...");
   window.location.reload(true);
}


function reloadTime()
{
  todayDate= new Date()
  currentDate= new Date();
  todayEnd= datehelper.getDateEnd(todayDate);
  let secondsTotal=datehelper.getDateDiffSeconds(todayEnd,currentDate)
  console.log("total seconds"+secondsTotal);
  hours=Math.floor(secondsTotal/3600);
  let minuteSeconds=secondsTotal-(hours*3600)
  console.log("total seconds in minutes"+minuteSeconds);
  minutes=Math.floor(minuteSeconds/60);
  seconds=Math.floor(secondsTotal-hours*3600-minutes*60);
  setHourLeft(hours)
  setMinuteLeft(minutes)
  setSecondLeft(seconds)
}

useEffect(() => {
  const interval = setInterval(() => reloadPage(), 120000);
  return () => {
    clearInterval(interval);
  };
}, []);

useEffect(() => {
  const interval = setInterval(() => reloadTime(), 1000);
  return () => {
    clearInterval(interval);
  };
}, []);

  const percentageLabelFormatter = (value) => {
    return value + '%';
  };

  let asmData;

  if(metricsData && metricsData.SG26_area_login_count){
      asmData = JSON.parse(metricsData.SG26_area_login_count)
  }

  let regionData;

  if(metricsData && metricsData.region_login_count){
    regionData = JSON.parse(metricsData.region_login_count)
  }



  return (
    <div className="p-4">
        <div className="container" style={{display: 'flex', justifyContent: 'center'}}>

          {metricsData && Object.keys(metricsData).length>0 && (
            <Paper style={{width:'710px', borderRadius: '10px', margin: '3px', padding: '30px' }}>
            <h3 style={{textAlign:'center',fontWeight:'bold'}}>Today's Team Achievements</h3>
            <h4>Time Left: <span style={{color:"red",fontSize:"2rem"}}>{hourLeft} Hours : {minuteLeft} Minutes: {secondLeft} Seconds </span></h4>
            <hr style={{ width: '100%' }} />
            
          <hr style={{ width: '100%' }} />
          <div style={{ display: 'flex',marginBottom:"5px" }}>
                  <div style={{ width: '630px', height: '50px', backgroundColor: colors[7], borderRadius: '10px', color: 'white', margin: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
                      <b style={{fontSize:"30px"}}>Total Logins : {metricsData['today_login_count']}</b>
                    </div>
            </div>
          <div style={{ display: 'flex' }}>
                    <div style={{ width: '150px', height: '60px', backgroundColor: colors[7], borderRadius: '10px', color: 'white', margin: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
                      <b style={{fontSize:'15px'}}>Jaipur</b>
                      { regionData.J03}
                    </div>

                    <div style={{ width: '150px', height: '60px', backgroundColor: colors[7], borderRadius: '10px', color: 'white', margin: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
                      <b style={{fontSize:'15px'}}>Bikaner</b>
                      { regionData.BK31}
                    </div>

                    <div style={{ width: '150px', height: '60px', backgroundColor: colors[7], borderRadius: '10px', color: 'white', margin: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
                      <b style={{fontSize:'15px'}}>Ajmer</b>
                      { regionData.AJ13}
                    </div>

                    <div style={{ width: '150px', height: '60px', backgroundColor: colors[7], borderRadius: '10px', color: 'white', margin: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
                      <b style={{fontSize:'15px'}}>Sriganganagar</b>
                      { regionData.SG26}
                    </div>
            </div>

            <div style={{ display: 'flex' }}>
                    <div style={{ width: '110px', height: '60px', backgroundColor: colors[7], borderRadius: '10px', color: 'white', margin: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
                      <b style={{fontSize:'15px'}}>Bhilwara</b>
                      { regionData.BW42}
                    </div>

                    <div style={{ width: '120px', height: '60px', backgroundColor: colors[7], borderRadius: '10px', color: 'white', margin: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
                      <b style={{fontSize:'15px'}}>Chittorgarh</b>
                      { regionData.CG15}
                    </div>

                    <div style={{ width: '120px', height: '60px', backgroundColor: colors[7], borderRadius: '10px', color: 'white', margin: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
                      <b style={{fontSize:'15px'}}>Jodhpur</b>
                      { regionData["0053"]}
                    </div>

                    <div style={{ width: '120px', height: '60px', backgroundColor: colors[7], borderRadius: '10px', color: 'white', margin: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
                      <b style={{fontSize:'15px'}}>Udaipur</b>
                      { regionData.UP16}
                    </div>
                    <div style={{ width: '115px', height: '60px', backgroundColor: colors[7], borderRadius: '10px', color: 'white', margin: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
                      <b style={{fontSize:'15px'}}>Dausa</b>
                      { regionData.D01}
                    </div>
            </div>

            <div style={{ display: 'flex' }}>
                  <div style={{ width: '300px', height: '100px', backgroundColor: colors[0], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
                      <b style={{fontSize:'20px'}}>Sanction Letter Count</b>
                      {metricsData['sanction_letter_count']}
                    </div>

                    <div style={{ width: '300px', height: '100px', backgroundColor: colors[0], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
                      <b style={{fontSize:'20px'}}>Sanction Letter Amount</b>
                      { displayhelper.displayNumberAccounting(Number(metricsData['sanction_letter_amount']))}
                    </div>

            </div>
            <div style={{ display: 'flex' }}>
                  <div style={{ width: '300px', height: '100px', backgroundColor: colors[3], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
                      <b style={{fontSize:'20px'}}>Disbursement Memo Count</b>
                      {metricsData['disbursement_memo_count']}
                    </div>

                    <div style={{ width: '300px', height: '100px', backgroundColor: colors[3], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
                      <b style={{fontSize:'20px'}}>Disbursement Memo Amount</b>
                      { displayhelper.displayNumberAccounting(Number(metricsData['disbursement_memo_amount']))}
                    </div>


            </div>

            <div style={{ display: 'flex' }}>
                  <div style={{ width: '300px', height: '100px', backgroundColor: colors[1], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
                      <b style={{fontSize:'20px'}}>Colender Receive Count</b>
                      {metricsData['colender_receive_count']}
                    </div>

                    <div style={{ width: '300px', height: '100px', backgroundColor: colors[1], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
                      <b style={{fontSize:'20px'}}>Colender Receive Amount</b>
                      { displayhelper.displayNumberAccounting(Number(metricsData['colender_receive_amount']))}
                    </div>


            </div>


            {/* <div style={{ display: 'flex' }}>
                  <div style={{ width: '300px', height: '100px', backgroundColor: colors[8], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
                      <b style={{fontSize:'20px'}}>Collection Receive Count</b>
                      {metricsData['colender_receive_count']}
                    </div>

                    <div style={{ width: '300px', height: '100px', backgroundColor: colors[8], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',fontSize:'1.5rem',padding:'10px' }}>
                      <b style={{fontSize:'20px'}}>Collection Receive Amount</b>
                      { displayhelper.displayNumberAccounting(Number(metricsData['colender_receive_amount']))}
                    </div>


            </div> */}
            </Paper>
          )} 
          </div>
    </div>
  );
}

export { TeamDashboardTab };