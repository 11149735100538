import React from "react";
import { Route, Switch } from "react-router-dom";

import AddEditCities from "./AddEditCities";
import CityList from "./CityList";

function Cities({ match }) {
  const { path } = match;

  return (
    <Switch>
      <Route exact path={path} component={CityList} />
      <Route path={`${path}/add`} component={AddEditCities} />
      <Route path={`${path}/edit/:id`} component={AddEditCities} />
    </Switch>
  );
}

export { Cities };
