export const CreditSubStages= {
  CreditFileAssigned:"CreditFileAssigned",  
  PDVisitPending:"PDVisitPending",
  PDApprovalMailPending:"PDApprovalMailPending",
  PDCibilPending:"PDCibilPending",
  HOApprovalPending:"HOApprovalPending",
  IMDandPropertyPaperPending:"IMDandPropertyPaperPending",
  LoginFeesPending:"LoginFeesPending",
  SanctionLetterPending:"SanctionLetterPending",
  FIReportPending:"FIReportPending",
  FCUReportPending:"FCUReportPending",
  CAMPending:"CAMPending",
  KYCPending:"KYCPending",
  LegalReportPending:"LegalReportPending",
  ValuationReportPending:"ValuationReportPending",
  FileBeingSentToHO:"FileBeingSentToHO",
  PendencyAtSaleTeam:"PendencyAtSaleTeam",
  ApplicationFormSigningPending:"ApplicationFormSigningPending",
  GuarantorAdditionPending:"GuarantorAdditionPending",
  ENachPending:"ENachPending",
  ChequePending:"ChequePending",
  DocumentCollectionPending:"DocumentCollectionPending",
  };
  