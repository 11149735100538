import React from 'react'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Collapse
} from "@material-ui/core";
import AssignmentIcon from '@material-ui/icons/Assignment';
import { ExpandMore,ExpandLess,AccountBalanceWallet,MonetizationOn} from "@material-ui/icons";
import roleChecker from "../../../_helpers/rolechecker"

const SblLetterMenuList = (props) => {

  const [open, setOpen] = React.useState(false);
  const { path } = props.match;
  const user =props.user;

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <ListItem button onClick={handleClick}>
      <ListItemIcon><AssignmentIcon/></ListItemIcon>
        <ListItemText primary="SBL Letter List" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" style={{paddingLeft:"50px"}}>
        <ListItem
            hidden={!roleChecker.isHOLevelAccess(user.role)}
            button
            key="valist"
            component="a"
            href={`${path}/letterlist/noc`}
          >
            <ListItemText primary="Noc Letter List" />
          </ListItem>

          <ListItem
            hidden={!roleChecker.isHOLevelAccess(user.role)}
            button
              key="import"
              component="a"
              href={`${path}/letterlist/for`}
            >
          
              <ListItemText primary="ForeClose List" />
            </ListItem>

          <ListItem
            hidden={!roleChecker.isHOLevelAccess(user.role)}
            button
            key="vap"
            component="a"
            href={`${path}/letterlist/doc`}
          >
            <ListItemText primary="List Of Document" />
          </ListItem>



          <ListItem
            hidden={!roleChecker.isHOLevelAccess(user.role)}
            button
            key="valist"
            component="a"
            href={`${path}/letterlist/sc`}
          >
            <ListItemText primary="Settlement Certificate" />
          </ListItem>

{/* 
          <ListItem
            button
            key="vap"
            component="a"
            href={`/home/sblloans/cibillist`}
          >
            <ListItemText primary="Cibil Requests" />
          </ListItem> */}

        {/* <ListItem 
        hidden={
          !roleChecker.isHOLevelAccess(user.role)  
        }
        button key="sbl" component="a" href={`/home/sblloans/letterlist`}>
              <ListItemText primary="Sanction Letter" />
        </ListItem> */}
        </List>
      </Collapse>
    </>
  )
}

export default SblLetterMenuList