import React from "react"
const Logo=()=> {
    return (
        <div className="row">
        <img
        alt="Company Logo"
        src={require("../../assets/kaabil.png")}
        className="col-md-6 offset-md-3"
      ></img>
       </div>
    )
}
export default Logo