import React from 'react';
import { useState } from 'react';
import { Modal, Box, Typography, Button, Paper } from '@material-ui/core';
import { HearingForm } from './HearingForm';
import { Delete } from '@material-ui/icons';


export const HearingDetailsModal = (props) => {
    const [open, setOpen] = useState(false)
    let details = props.case;

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }



    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "65vw",
        height: "75vh",
        bgcolor: "background.paper",
        border: "2px solid #000",
        overflow: "scroll",
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Button onClick={handleOpen} style={{ color: 'blue' }} >Hearings</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box id="modal-modal-title" variant="h6" component="h2" style={{ display: 'flex', flexDirection: 'column', alignItem: 'center' }}>
                        <Paper>
                            <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <h4 style={{ margin: 'auto', marginTop: '10px', marginBottom: '10px' }}>Hearings</h4>
                            </Box>
                        </Paper>
                        <HearingForm {...props} />
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}