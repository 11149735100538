import { Add } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";

import { bankAccount } from '../../../_services/bank_account.service';
import { BankAccountModal } from './BankAccountModal';

export const BankList = () => {
    const [list, setList] = useState([])
    const [isLoading,setIsLoading]= useState(false)
    useEffect(() => {
        async function getAllBankAccount() {
            await bankAccount.getBankAccount()
                .then((res) => setList(res))
                .catch((err) => console.log(err))
                setIsLoading(true)
        }
        getAllBankAccount()
    }, [])
    console.log("Test List", list)
    return (
        <div style={{ width: "1000px", marginLeft: "auto", marginRight: "auto", border: "2px", marginTop: '80px', background: "white", borderRadius: '5px' }}>
            <div style={{ margin: "50px" }}>
                <br />
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <h3 style={{ marginRight: "20px", marginBottom: "30px", marginLeft: "330px" }}>Bank Accounts</h3> <BankAccountModal />
                </div>
                <br />
                <Table>
                    <thead>
                        <Th>Account Number</Th>
                        <Th>Bank Name</Th>
                        <Th>IFSC Code</Th>
                    </thead>
                    <Tbody>
                        {
                            list.length > 0 ?
                                list.map((x, index) => <Tr key={index}>
                                    <Td>{x.account_number}</Td>
                                    <Td>{x.bank_name}</Td>
                                    <Td>{x.ifsc_code}</Td>
                                </Tr>) :
                                <div style={{position:'fixed',top:" 50%",left: "50%"}}>
                                { isLoading == false && (
                                        <Tr>
                                          <Td colSpan="9" className="text-center">
                                            <span className="spinner-border spinner-border-lg align-center"></span>
                                          </Td>
                                        </Tr>
                                      )}
                                      {list.length === 0 && (
                                        <Tr>
                                          <Td colSpan="9" className="text-center" >
                                            <span style={{textAlign:"center",color:"red",fontSize:"20px"}}> No bank account added</span>
                                          </Td>
                                        </Tr>)}
                                </div>
                                
                        }
                    </Tbody>
                    </Table>
                <br />
                <br />
            </div>
        </div>
    )
}

