import { ArrowForward} from '@material-ui/icons'
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import React, { useEffect, useState } from 'react'
import CashRequestModal from './CashRequestModal';
import { ledgerService } from '../../_services/ledger.service';
import datehelper from '../../_helpers/datehelper';
import CashRequestListModal from './CashRequestListModal';
import { Button } from '@material-ui/core';
import { history } from '../../_helpers';
import { employeeService } from '../../_services';
import roleChecker from '../../_helpers/rolechecker';
const CashRequestList = () => {
    const user=employeeService.userValue
    const [cashRequestList,setCashRequestList]=useState([])
    const getCashRequestList=()=>{
        ledgerService.getCashRequestList()
        .then((res)=>setCashRequestList(res))
        .catch((err)=>console.log(err))
    }
    const deleteCashRequest=(id)=>{
        ledgerService.deleteCashRequest(id)
        .then((res)=>{
            console.log('Test res',res)
            alert("Cash request Deleted !!")
            window.location.reload()
        })
         .catch((err)=>console.log(err))
    }
    useEffect(()=>{
        getCashRequestList()
    },[])
    console.log("Test ss",cashRequestList)
    return (
        <div style={{ margin: '30px' }}>
            <h2 style={{ marginBottom: "50px" }}>Cash Management <ArrowForward /> Cash Requests </h2>
            {(roleChecker.isBMLevelAccess(user.role) || roleChecker.hasAccountsAccess(user.role)) && <CashRequestModal />}
            {roleChecker.hasAccountsAccess(user.role)&&<CashRequestListModal/>}
            <Table style={{ border: '2px solid blue' }}>
                <Thead style={{ background: "#2949B9", color: 'white', height: '50px' }}>
                    <Tr>
                        <Th>Requested Amount</Th>
                        <Th>Approved Amount</Th>
                        <Th>Payment Mode</Th>
                        <Th>Branch</Th>
                        <Th>Request Creation Date</Th>
                        <Th>Requested By</Th>
                        <Th>Request Approval Date</Th>
                        <Th>Status</Th>
                        <Th colSpan="2" style={{textAlign:'center'}}>Action</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {cashRequestList.length>0 ? cashRequestList.filter(checkbranch=>
                            (checkbranch.branch===user.branch_id) || (user.branch_id==="HO001")).map((x,idx)=>
                    <>
                    <Tr key={idx} style={{background:'#D9DDF5',height:"50px",fontWeight:'bold',fontSize:"18px"}}>
                    <Td>{x.amount.toLocaleString("en-IN", { style: 'currency', currency: 'INR' })}</Td>
                    <Td>{x.approval_amount && x.approval_amount.toLocaleString("en-IN", { style: 'currency', currency: 'INR' })}</Td>
                    <Td>{x.transaction_mode && x.transaction_mode}</Td>                   
                    <Td>{x.branch}</Td>
                    <Td>{datehelper.displayDateTime(new Date(x.requested_date))}</Td>
                    <Td>{x.requested_by}</Td>
                    <Td>{x.request_approval_date && datehelper.displayDateTime(new Date(x.request_approval_date))}</Td>
                    <Td style={{ color: x.status === "Pending" ? "yellow" : (x.status === "Rejected" ? "red" : "green") }}>{x.status}</Td>
                    <Td>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button onClick={() => history.push("cashrequestview", x)} style={{ color: 'blue',marginRight:"30px" }}>View</Button>
                        {(x.status==="Pending" && x.requested_by_emp_id===user.emp_id) &&<Button onClick={() => deleteCashRequest(x._id)} style={{ color: 'red'}}>Delete</Button>}
                    </div>
                    </Td>
                    </Tr>
                    <br/>
                    </>):<Tr>
                        <Td colSpan="6" style={{textAlign:"center",fontWeight:"bold"}}>Data Not Found</Td>
                        </Tr>}
                </Tbody>
            </Table>
        </div>
    )
}
export default CashRequestList
