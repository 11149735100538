import React, { useEffect, useState } from "react";
import { Button, Paper, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import FormElement from "../../uielements/FormElement";
import PropertyList from "./Properties/PropertyList";
import CoapplicantList from "./Coapplicants/CoapplicantList";
import { misService } from "../../../_services";
import GuarantorList from "./Guarantors/GuarantorList";
import { sanction } from "../../../_services/sanctionletter.service"
import { history } from "../../../_helpers";
import {useParams} from 'react-router-dom';
import { loanService } from "../../../_services/loan.service";


export default function SBLSanction(props) {

  const {id}  = useParams();
  console.log(id)
  
  const [values, setValues] = useState({})
   const[guarantors, setGuarantors] = useState([]);
   const[Insurance, setInsurance] = useState([]);
   const[eMI, setEMI] = useState([]);
   const[eMIDate, setEMIDate] = useState([]);
   const [coapplicants, setCoapplicants] = useState([]);
   const [ugro_reference, setUgro_reference] = useState(null);
   const[capital_reference, setCapital_reference] = useState(null)
   const[properties, setProperties] = useState({});


   let date = new Date()

   if(date.getDate()<=20){
     let dd = date.setDate(5)
     let mm = date.setMonth(date.getMonth()+1)
     date = mm;
   }else{
     let dd = date.setDate(5)
     let mm = date.setMonth(date.getMonth()+2)
     date = mm;
   }

  //  console.log(new Date(date))
  

  const initialEditValues = {
    title: values.title,
    applicant:  values.title + " " + values.name + " " + values.relation_type + " " + values.relation,
    sonof: values.sonof,
    address: values.address,
    sanction_amount: values.amount,
    tenure: values.tenure,
    interest_rate: values.roi,
    loan_no: values.loan_no,
    process_fees: values.pf_rate,
    ugro_reference:"",
    start_date: convert(date),
    application_id: values.application_id,
};

  const validationSchema = Yup.object().shape({
    start_date: Yup.date().required("This field is required"),
  });

  let title = '';

  async function getApplicant() {
    const data = await loanService.getById(id);
     console.log(data,"hhhhhhhhhhhhhhhhhhh")
     setGuarantors(data.guarantors)
     setInsurance(data?.loan?.insurance_amount )
     setEMI(data?.loan_repayments[0]?.emi_schedule[0]?.emi_amount)
     setEMIDate(data?.loan_repayments[0]?.emi_schedule[0]?.emi_date)
     setCoapplicants(data?.co_applicants)
     setProperties(data.property_details)
     data && data.co_lending && data.co_lending.map((e) => {
      if(e.co_lender_name === "Ugro"){
        setUgro_reference(e.co_lender_account_num)
      }else if(e.co_lender_name === "CapitalIndia"){
        setCapital_reference(e.co_lender_account_num)
      }
     })
      let application_date=data.created;
      if(data.application)
      {
        application_date=data.application.application_date;
      }
      title = data.applicant.general_information.title

      setValues({...values, name:data.applicant.general_information.name, start_date: new Date(date), title: data.applicant.general_information.title, relation_type: data.applicant.general_information.kyc_relation_type, relation:data.applicant.general_information.kyc_relation,  application_date: application_date, address:data.applicant.general_information.address, sonof:data.applicant.general_information.sonof,  application_id:data.application_id, amount:data.loan.sanction_amount, tenure:data.loan.tenure, roi:data.loan.roi, pf_rate: data.loan.pf_rate, });
    }
      // console.log(guarantors)
      // console.log(coapplicants)
      // console.log(properties)
      // console.log(capital)
      // console.log(ugro_reference)

   useEffect(() => {
        getApplicant()
    }, []);


    function convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    }

  function onSubmit(values) {
    if(values.title === undefined || !values.title){
      alert("Please fill the applicant title")
      return
    }
    misService.increaseLoanNo("SBL");
    sanction.create({...values, guarantors:[...guarantors], coapplicants:[...coapplicants], properties, ugro_reference, capital_reference,insurance_amount:Insurance,emi_amount:eMI, emi_date:eMIDate,verify_details:{is_verified:false}})
      .then((details) => {
        console.log("data isss",details)
        alert("Data Added Successfully")
        history.push(`/home/sblloans/details/${id}`);
      })
      .catch((err) => {
        alert(err, "Please Try Again")
      })

  }


  return (
    <div className="App">
      <header className="App-header">
        <h1>SBL Sanction Letter</h1>
        <Formik
          // innerRef={formikRef}
          initialValues={initialEditValues}
          // validator={() => ({})}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {({ errors, touched, isSubmitting, values, setFieldValue }) => (
            <Form>
              <div className="form-group">
                <div className="d-flex justify-content-between">
                  <Paper
                    className="col-md-8"
                    style={{
                      padding: 20,
                    }}
                  >
                    <Typography variant="h5">New Letter</Typography>
                    <br />
                    <div className="d-flex justify-content-around">
                      <div className="form-group col-md-6">
                        <FormElement
                          disabled
                          label="Applicant"
                          name="applicant"
                          type="text"
                          errors={errors}
                          touched={touched}
                        ></FormElement>
                      </div>
                      <div className="form-group col-md-3">
                        <FormElement
                          disabled
                          label="Sanction Amount (₹)"
                          name="sanction_amount"
                          type="number"
                          errors={errors}
                          touched={touched}
                        ></FormElement>
                      </div>
                    </div>
                    <div className="d-flex justify-content-around">
                      <div className="form-group col-md-9">
                        <FormElement
                          disabled
                          label="Address"
                          name="address"
                          type="text"
                          errors={errors}
                          touched={touched}
                        ></FormElement>
                      </div>
                      <div className="form-group col-md-3">
                        <FormElement
                          disabled
                          label="Tenure (Months)"
                          name="tenure"
                          type="number"
                          errors={errors}
                          touched={touched}
                        ></FormElement>
                      </div>
                    </div>
                    <div className="d-flex justify-content-around">
                      <div className="form-group col-md-3">
                        <FormElement
                          disabled
                          label="Interest Rate (%)"
                          name="interest_rate"
                          type="number"
                          errors={errors}
                          touched={touched}
                        ></FormElement>
                      </div>
                      <div className="form-group col-md-3">
                        <FormElement
                          disabled
                          label="Processing Fees (%)"
                          name="process_fees"
                          type="number"
                          errors={errors}
                          touched={touched}
                        ></FormElement>
                      </div>
                      <div className="form-group col-md-3">
                        <FormElement
                          label="EMI Start Date"
                          name="start_date"
                          type="date"
                          errors={errors}
                          touched={touched}
                        ></FormElement>
                        {}
                      </div>
                    </div>
                    {/* <div className="d-flex justify-content-around">
                      <div className="form-group col-md-9">
                        <FormElement
                          hidden={true}
                          label="Application ID"
                          name="application_id"
                          type="text"
                          errors={errors}
                          touched={touched}
                        ></FormElement>
                      </div>
                      <div className="form-group col-md-3">
                        <FormElement
                          hidden={true}
                          label="Application Date"
                          name="application_date"
                          type="date"
                          errors={errors}
                          touched={touched}
                        ></FormElement>
                      </div>
                    </div> */}
                    <br />
                  </Paper>
                  <Paper
                    style={{
                      width: "32%",
                      padding: 20,
                    }}
                  >
                    <CoapplicantList
                      setFieldValue={setFieldValue}
                       list={coapplicants}
                    ></CoapplicantList>
                  </Paper>
                </div>
                <br />
                <div className="d-flex justify-content-between">
                  <Paper
                    className="col-md-8"
                    style={{
                      padding: 20,
                    }}
                  >
                    <PropertyList setFieldValue={setFieldValue} list={properties}></PropertyList>
                  </Paper>
                  <Paper
                    style={{
                      width: "32%",
                      padding: 20,
                    }}
                  >
                    <GuarantorList
                      setFieldValue={setFieldValue}
                      list={guarantors}
                    ></GuarantorList>
                  </Paper>
                </div>
                <div style={{ textAlign: "center", margin: 20 }}>
                  <br />
                  <br />
                  <Button type="submit" variant="contained" color="primary">
                    Create Letter
                  </Button>
                </div>
              </div>

            </Form>
          )}
        </Formik>
      </header>
    </div>
  );
}
