import { Box, Button, Modal } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Edit, Save } from "@material-ui/icons";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import datehelper from "../../../_helpers/datehelper"
import { legalServices } from '../../../_services/legal_cases.services';
import { employeeService } from '../../../_services';
const AddEditCaseModal = (props) => {
  console.log("Test props", props)
  const user = employeeService.userValue.emp_id;
  let courtCase = props.courtCase
  let customerData = props.customerData
  const btn = props.btn
  // console.log("Test btn", btn) 
  console.log("courtCase", courtCase)
  console.log("customerData", customerData)
  const editCase = props.caseInfo
  console.log("Test Edit case", editCase)
  let id = editCase && editCase._id
  const formikRef = React.useRef();
  let style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: 550,
    background: '#FFFFFF',
    borderRadius: '4px'
  }
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  let initialValues = {}
  if (id) {
    const caseOpenDate = new Date(editCase.case_open_date).toISOString().slice(0, 10)
    initialValues = {
      case_type: editCase.case_type,
      emp_id: user,
      loan_no: customerData && customerData.application_id,
      case_open_date: caseOpenDate,
      claim_amount: editCase.claim_amount,
      branch: editCase.branch,
      raised_by: editCase.raised_by,
      case_no: editCase.case_no,
      jurisdiction_name: editCase.jurisdiction_name,
      loan: customerData && customerData.loan.sanction_amount,
      party_name: editCase.party_name,
      court_name: editCase.court_name,
      party_type: editCase.party_type,
    }
  }
  else {
    initialValues = {
      case_type: btn,
      emp_id: user,
      loan_no: customerData && customerData.application_id,
      case_open_date: '',
      claim_amount: '',
      branch: "",
      raised_by: "",
      case_no: "",
      jurisdiction_name: "",
      loan: customerData && customerData.loan.sanction_amount,
      party_name: '',
      court_name: '',
      party_type: ''
    }
  }

  let validationSchema = Yup.object().shape({
    branch: Yup.string().required("This is Required"),
    case_open_date: Yup.date().required("This is Required"),
    party_name: Yup.string().required("This is Required"),
    case_no: Yup.string().required("This is Required"),
    jurisdiction_name: Yup.string().required("This is Required"),
    raised_by: Yup.string().required("This is Required"),
    loan: Yup.number().required("This is Required"),
    party_type: Yup.string().required("This is Required"),
    court_name: Yup.string().required("This is Required"),
    claim_amount: Yup.number().required("This is Required")
  })
  const addCaseInfo = (values) => {
    console.log("Test values", values)
    legalServices.addNewCase(values)
      .then((res) => {
        alert("Case Created Successfully!!")
        window.location.reload()
      })
      .catch((err) => alert(err))
  }
  const updateCase = (id, values) => {
    legalServices.updateCase(id, values)
      .then((res) => {
        alert("Case Updated !!")
        window.location.reload()
      })
      .catch((err) => alert(err))
  }
  const onSubmit = (values) => {
    if (id) {
      updateCase(id, values)
    }
    else {
      addCaseInfo(values)
    }
  }
  return (
    <div>
      {!id && <Button onClick={handleOpen}
        style={{ display: 'flex', fontWeight: 'bold' }}>Create&nbsp;{props.btn}</Button>}
      {id && <Button onClick={handleOpen}
        style={{ display: 'flex', float: 'right', color: "#3760F1" }}><Edit />Edit</Button>}
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
          <Formik
            innerRef={formikRef}
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ errors, touched, isSubmitting, setFieldValue, values }) => {
              return (
                <Form>
                  <div>
                    <h4 style={{ textAlign: "center", marginTop: '30px', marginBottom: "30px" }}>{id ? "Edit Case Information" : "Add Case Information"}</h4>
                    <div style={{ display: "flex", justifyContent: "space-around", color: "#3760F1" }}>
                      <div style={{ display: "flex", flexDirection: "column", }}>
                        <label> Case Type</label>
                        <Field
                          name='case_type'
                          disabled
                          style={{ width: "180px", height: "35px", border: '2px solid #3760F1', borderRadius: "4px", color: 'black' }}
                        />
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", }}>
                        <label>Loan Number</label>
                        <Field
                          name="loan_no"
                          disabled
                          style={{ width: "180px", height: "35px", border: '2px solid #3760F1', borderRadius: "4px", color: 'black' }}
                        />
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", }}>
                        <label>Branch</label>
                        <Field
                          name="branch"
                          disabled={id}
                          style={{ width: "180px", height: "35px", border: '2px solid #3760F1', borderRadius: "4px" }}
                          className={
                            "form-control" +
                            (errors.branch && touched.branch ? " is-invalid" : "")
                          } />
                        <ErrorMessage
                          name="branch"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", }}>
                        <label>Case Open Date</label>
                        <Field
                          type="date"
                          name="case_open_date"
                          // disabled={id}
                          style={{ width: "180px", height: "35px", border: '2px solid #3760F1', borderRadius: "4px", color: 'black' }}
                          className={
                            "form-control" +
                            (errors.case_open_date && touched.case_open_date ? " is-invalid" : "")
                          }
                        />
                        <ErrorMessage
                          name="case_open_date"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <br />
                    <br />
                    <div style={{ display: "flex", justifyContent: "space-around", color: "#3760F1" }}>
                      <div style={{ display: "flex", flexDirection: "column", }}>
                        <label>Raised By</label>
                        <Field
                          name="raised_by"
                          disabled={id}
                          style={{ width: "180px", height: "35px", border: '2px solid #3760F1', borderRadius: "4px" }}
                          className={
                            "form-control" +
                            (errors.raised_by && touched.raised_by ? " is-invalid" : "")
                          } />
                        <ErrorMessage
                          name="raised_by"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", }}>
                        <label>Party Name</label>
                        <Field
                          name="party_name"
                          as="select"
                          disabled={id}
                          className={
                            "form-control" +
                            (errors.party_name && touched.party_name ? " is-invalid" : "")
                          }
                          style={{ width: "180px", height: "35px", border: '2px solid #3760F1', borderRadius: "4px" }}
                        >
                          <option value="">Choose</option>
                          <option>{customerData.applicant.general_information && customerData.applicant.general_information.name}</option>
                          {customerData.co_applicants && customerData.co_applicants.length>0 && customerData.co_applicants.map((x) =>
                            <option value={x.general_information.name} >{x.general_information.name}</option>)}
                          {customerData.guarantors && customerData.guarantors.length>0 && customerData.guarantors.map((x) =>
                            <option value={x.general_information.name}>{x.general_information.name}</option>)}
                        </Field>
                        <ErrorMessage
                          name="party_name"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", }}>
                        <label>Party Type</label>
                        <Field
                          name="party_type"
                          as="select"
                          disabled={id}
                          className={
                            "form-control" +
                            (errors.party_type && touched.party_type ? " is-invalid" : "")
                          }
                          style={{ width: "180px", height: "35px", border: '2px solid #3760F1', borderRadius: "4px" }}
                        >
                        <option value="">Choose Party</option>
                        <option value="Applicant">Applicant</option>
                        <option value="Co-applicants">Co-Applicant</option>
                        <option value="Guarantor">Guarantor</option>
                          </Field>
                        <ErrorMessage
                          name="party_type"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", }}>
                        <label>Case Number</label>
                        <Field
                          name="case_no"
                          disabled={id}
                          className={
                            "form-control" +
                            (errors.case_no && touched.case_no ? " is-invalid" : "")
                          }
                          style={{ width: "180px", height: "35px", border: '2px solid #3760F1', borderRadius: "4px" }}
                        />
                        <ErrorMessage
                          name="case_no"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <br />
                    <br />
                    <div style={{ display: "flex", justifyContent: "space-around", color: "#3760F1" }}>
                      <div style={{ display: "flex", flexDirection: "column", }}>
                        <label>Jurisdiction Name</label>
                        <Field
                          name="jurisdiction_name"
                          className={
                            "form-control" +
                            (errors.jurisdiction_name && touched.jurisdiction_name ? " is-invalid" : "")
                          }
                          style={{ width: "180px", height: "35px", border: '2px solid #3760F1', borderRadius: "4px" }}
                        />
                        <ErrorMessage
                          name="jurisdiction_name"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", }}>
                        <label>Loan Amount</label>
                        <Field
                          name="loan"
                          type="Number"
                          style={{ width: "180px", height: "35px", border: '2px solid #3760F1', borderRadius: "4px", color: "black" }}
                         
                          className={
                            "form-control" +
                            (errors.loan && touched.loan ? " is-invalid" : "")
                          }
                        />
                        <ErrorMessage
                        name="loan"
                        component="div"
                        className="invalid-feedback"
                      />
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", }}>
                        <label>Claim Amount</label>
                        <Field
                          name="claim_amount"
                          style={{ width: "180px", height: "35px", border: '2px solid #3760F1', borderRadius: "4px", color: 'black' }}
                          className={
                            "form-control" +
                            (errors.claim_amount && touched.claim_amount ? " is-invalid" : "")
                          }
                        />
                        <ErrorMessage
                          name="claim_amount"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", }}>
                        <label>Court Name</label>
                        <Field
                          name="court_name"
                          className={
                            "form-control" +
                            (errors.court_name && touched.court_name ? " is-invalid" : "")
                          }
                          style={{ width: "180px", height: "35px", border: '2px solid #3760F1', borderRadius: "4px" }}
                        />
                        <ErrorMessage
                          name="court_name"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <br />
                    <br />
                    <button
                      type='submit'
                      disabled={isSubmitting}

                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: 'blue', margin: "0 auto", color: 'white', borderRadius: '4px'
                      }}>
                      {isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      <Save />Save</button>
                  </div>
                </Form>)
            }}
          </Formik>
        </Box>
      </Modal>
    </div>
  )
}
export default AddEditCaseModal
