import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  Button,
  CardActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  TableContainer,
  TablePagination
} from "@material-ui/core";
import { Edit, Add } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { loanService } from "../../../_services/loan.service";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { employeeService } from "../../../_services";
import roleChecker from "../../../_helpers/rolechecker";
import { Role } from "../../../_helpers";
import { Input } from "antd";
import EditStageModal from "../Applications/EditStageModal";
import datehelper from "../../../_helpers/datehelper";
import LetterList from "../Letters/SBLLetterList";
import displayhelper from "../../../_helpers/displayhelper";
import { LoanStages } from "../../../constants/loanstages";

const { Search } = Input;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexGrow: 1,
  },
  card: {
    minHeight: 250,
    minWidth: 250,
  },
}));

export default function VendorApplication({ match }) {
  const { path } = match;
  const classes = useStyles();
  const user = employeeService.userValue;
  const [applicants, setApplicants] = useState(null);
  const [spinner, setSpinner] = useState(true);
  const [page, setPage] = useState(0);
  const [searchFilter, setSearchFilter] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [data,setData] = useState()
  const [isVerified,setIsVerified] = useState(false)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getApplicants();
  }, [isVerified]);

  async function getApplicants() {
    setSpinner(true);
    const cust = await loanService.vendorData({"status":isVerified});
    // console.log("vendor data is", cust);
    setApplicants(cust);
    setSpinner(false);
    setData(cust)

  }
  function filterByApplication(){
    let tempLoans=data && data.filter((l)=> {
     
      if(searchFilter!="")
      {
        
        if(l.reference_id &&l.reference_id.toLowerCase().includes(searchFilter.toLowerCase()))
        {
            return true;
        }
  
  
        return false;
      }
      else
      {
        return true;
      }
     
     });
     setApplicants(tempLoans)
   }
  
  useEffect(() => {
    filterByApplication()
   }, [searchFilter])
  return (
    <div>
      <h1>Assigned Loans</h1>

      <div className="d-flex justify-content-between">
        <Search
          onChange={(e) => setSearchFilter(e.target.value)}
          placeholder="Search"
          enterButton
          style={{
            position: "sticky",
            top: "0",
            left: "0",
            width: "200px",
            marginTop: "2vh",
          }}
        /> <Box sx={{ minWidth: 120 }}>
       <FormControl fullWidth>

       <InputLabel id="filter_stage_label">Filter Status</InputLabel>
       <Select
       labelId="filter_status_label"
       name="branchfilter"
       label="Filter Status"
       value={isVerified}
       onChange={(e) => setIsVerified(e.target.value)}
       >
         <MenuItem value="" disabled>Filter Status</MenuItem>
          
             <MenuItem value={false}>Not Verified</MenuItem>
             <MenuItem value={true}>Verified</MenuItem>


       </Select>
       </FormControl>
       </Box>
      </div>
      <br />
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th style={{ width: "20%" }}>Application #</Th>
              <Th style={{ width: "15%" }}>Branch</Th>

              <Th style={{ width: "15%" }}>Report Type</Th>
              <Th style={{ width: "20%" }}>Request Date</Th>
              <Th style={{ width: "20%" }}>Request Status</Th>
              <Th style={{ width: "15%" }}>Status</Th>

               <Th style={{ width: "20%" }}>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {applicants &&
              applicants
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((application, idx) => (
                  <Tr key={idx}>
                    <Td>{application.reference_id}</Td>
                    <Td>{application.branch_city}</Td>

                    <Td>{application.type}</Td>
                    <Td>{application.type =="Valuation" ? datehelper.displayDate(new Date(application.valuation_report.requested_at)) : <>{application.type =="RCU" ? datehelper.displayDate(new Date(application.rcu_report.requested_at)) : <>{application.type=="Legal" ?  datehelper.displayDate(new Date(application.legal_report.requested_at)) : null}</> }</>}</Td>
                    <Td style={{    color: 'green' }}>{application.type =="Valuation" ? application.valuation_report.request_status  : <>{application.type =="RCU" ? application.rcu_report.request_status  : <>{application.type=="Legal" ? application.legal_report.request_status  : null}</> }</>}</Td>

                    <Td>{ isVerified ? <p style={{color:"green"}}>Verified</p> : <p style={{color:"red"}}>Not Verified</p>  }</Td>

                    <Td>
                      <Button
                        component="a"
                        href={`/home/vendorsdata/details/${application._id}`}
                        size="small"
                        color="primary"
                      >
                        View Details
                      </Button>
                      <br />
                    </Td>
                  </Tr>
                ))}
            {(!applicants || spinner) && (
              <Tr>
                <Td colSpan="9" className="text-center">
                  <span className="spinner-border spinner-border-lg align-center"></span>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      {applicants ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={applicants.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : (
        <div>No Data Available</div>
      )}
    </div>
  );
}
