import React, { useState, useEffect } from 'react';
import {
    Modal,
    Backdrop,
    Fade,
    Button,
    TextField,
    MenuItem,
    IconButton,
    Typography,
    Paper,
    Checkbox,

} from '@material-ui/core';
import { Table, Thead, Tbody, Tr, Td, Th } from 'react-super-responsive-table';

import datehelper from "../../../_helpers/datehelper";

import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, FieldArray, Field } from 'formik';
import * as Yup from 'yup';
import { Add, Delete, Edit } from '@material-ui/icons';
import { adminservice } from "../../../_services/admin.services";
import { uploadFileToBucket } from '../../../_helpers/upload';
import { employeeService } from "../../../_services/employee.service";
import EmployeeSelectModal from "./EmployeeSelectModal"
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { employeeExpenseService } from "../../../_services/employeeExpense.service"
import CloseIcon from '@material-ui/icons/Close';
import { ToastContainer } from "react-toastify";
import { notifyInfo, notifyFailed } from "../../../_helpers/notify"

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: '#F2F0EF',
        padding: theme.spacing(2),
        borderRadius: '10px',
        width: '80%',
        // maxWidth: '1000px',
        maxHeight: "600px",
        overflow: 'auto',
    },
    formField: {
        marginBottom: theme.spacing(2),
    },
    formRow: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
        gap: '1rem',
    },
    entityWrapper: {
        marginBottom: theme.spacing(2),
        position: 'relative',
    },
    deleteButton: {
        position: 'absolute',
        top: '0',
        right: '0',
    },
}));



const VerifyEmployeeExpense = (id) => {
    const classes = useStyles();
    const user = employeeService.userValue
    const [open, setOpen] = useState(false);
    const [allCategories, setAllCategories] = useState([])
    const [spinner, setSpinner] = useState(false)

    const [initialValues, setInitialValues] = useState({
        travel: [
            {
                travelby: '',
                traveltype: '',
                startDate: '',
                invoice: '',
                ticket: '',
                amount: '',
                purpose: '',
                status: '',
                approvedAmount: 0
            },
        ],
        meal: [
            {
                mealtype: '',
                date: '',
                invoice: '',
                amount: '',
                purpose: '',
                status: '',
                approvedAmount: 0
            },
        ],
        stay: [
            {
                citytype: '',
                cityname: '',
                startDate: '',
                duration: '',
                invoice: '',
                amount: '',
                purpose: '',
                status: '',
                approvedAmount: 0
            },
        ],
        AddedEmployees: [],
        comment: '',
        status: "",
        totalApprovedAmount :'',
         totalRequestedAmount:''
    })

    const validationSchema = Yup.object().shape({
        travel: Yup.array().of(
            Yup.object().shape({
                status: Yup.string().required('Status is required when Travel By is selected'), // Adjusted the message
            })
        ),

        meal: Yup.array().of(
            Yup.object().shape({
                mealtype: Yup.string().nullable(),
                status: Yup.string().required('Status is required when Meal Type is selected'), // Adjusted the message
            })
        ),

        stay: Yup.array().of(
            Yup.object().shape({
                status: Yup.string().required('Status is required when City Type is selected'), // Adjusted the message
            })
        ),

        comment: Yup.string().required('Comment is required'), // Adjusted the message
    });


    const expenseId = id.id;

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSubmit = (values) => {
        setSpinner(true);

        console.log("edit values are ", expenseId, values);

        let overallStatus = "Verified"; // Assume verified as the default
        let hasRejected = false;
        let hasVerified = false;
        let hasPending = false;
        let totalApprovedAmount=0 ;
        let  totalRequestedAmount=0;
        const updateOverallStatus = (array) => {
            console.log('array is', array)

            if (array && array.length > 0) {
                for (let item of array) {
                    totalRequestedAmount = Number(totalRequestedAmount) + Number(item.amount);
                    console.log('item is', item)
                    if (item.status == "Pending") {
                        hasPending = true;

                    } else if (item.status == "Rejected") {
                        hasRejected = true;
                    } else if (item.status == "Verified") {
                        hasVerified = true;
                        if(item.approvedAmount){

                            totalApprovedAmount= Number(totalApprovedAmount) + Number(item.approvedAmount);
                        }

                    }
                }

            }
        };

        // Check statuses for travel, meal, and stay
        updateOverallStatus(values.travel);
        updateOverallStatus(values.meal);
        updateOverallStatus(values.stay);

        console.log('hasPending', hasPending, hasRejected, hasVerified)
        if (hasPending) {
            overallStatus = "Pending";
        }
        else if (hasVerified && hasRejected) {
            overallStatus = "Verified";
        } else if (hasRejected) {
            overallStatus = "Rejected";
        }
        // Set the final overall status in the values
        values.status = overallStatus;
        values.totalRequestedAmount = Number(totalRequestedAmount);
        values.totalApprovedAmount = Number(totalApprovedAmount);
        console.log("edit values are after ", expenseId, values);
        // Now proceed with the update
        employeeExpenseService.update(expenseId, values)
        .then((res) => {
            if (res && res.status === "success") {
              console.log('Status updated successfully', res);
              notifyInfo(`Status   has been updated successfully`);
            } else if (res && res.status === "failed") {
              console.log('Failed to update employee expense status', res.message);
              notifyFailed(`Failed: ${res.message}`);
              setSpinner(false);
            }
          })
          .catch((err) => {
            console.log(err);
            notifyFailed('An error occurred while updating the employee expense status');
            setSpinner(false);
          });
    };



    useEffect(() => {
        if (expenseId && open) {
            employeeExpenseService.getById({ id: expenseId })
                .then((res) => {
                    console.log('edit employee  expense data', res);

                    setInitialValues({ ...initialValues, ...res })


                })
                .catch((err) => console.log('err of edit expense', err));
        }
    }, [expenseId, open]);

    console.log('gsf rwfewref0,', initialValues)








    return (
        <>
            <Button variant="outlined" color="primary" onClick={handleOpen}>
                Verify
            </Button>

            <Modal
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <Button style={{ float: 'right', }} variant='outlined' onClick={() => handleClose()}> <CloseIcon /></Button>
                        <Formik
                            initialValues={initialValues}
                            enableReinitialize={true}

                            onSubmit={handleSubmit}
                        >
                            {({ values, setFieldValue }) => (
                                <Form>
                                <ToastContainer />
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        gap: '2rem'
                                    }}>
                                        <Typography variant="h6" gutterBottom>
                                            Expense Verification
                                        </Typography>

                                        <div className="form-group row"  >
                                            <Typography variant="h6" gutterBottom>
                                                Employees Added :
                                            </Typography>
                                            {values?.AddedEmployees?.map((emp, idx) => (
                                                <div key={idx}>

                                                    <button
                                                        type="button"
                                                        className="btn btn-danger btn-sm"
                                                        style={{
                                                            marginLeft: '5px', position: 'relative', height: '30px'

                                                        }}
                                                    >
                                                        {emp.emp_id - emp.category}


                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {/* Travel Expenses Table */}
                                   {values?.travel?.length>0 &&  <Paper style={{ margin: '20px', padding: '20px' }}>

                                        <Typography variant="h6" gutterBottom>
                                            Travel Expenses
                                        </Typography>
                                        <Table>
                                            <Thead>
                                                <Tr>

                                                    <Th>Travel Type</Th>
                                                    <Th>Travel By</Th>
                                                    <Th>Start Date</Th>
                                                    <Th>Amount</Th>
                                                    <Th>Purpose</Th>
                                                    <Th>Invoice</Th>
                                                    <Th>Max Cal. Amo.</Th>
                                                    <Th>Approved Amount</Th>

                                                    <Th>Status</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {values?.travel?.map((item, index) => (
                                                    <Tr key={index}>

                                                        <Td>{item.travelby}</Td>
                                                        <Td>{item.traveltype}</Td>
                                                        <Td>{datehelper.displayDate(new Date(item.startDate))}</Td>
                                                        <Td>{item.amount}</Td>
                                                        <Td>{item.purpose}</Td>
                                                        <Td>
                                                            <a href={item.invoice} target="_blank" rel="noopener noreferrer">
                                                                Invoice Link
                                                            </a>
                                                        </Td>
                                                        <Td>{item.maxamount}</Td>
                                                        <Td>
                                                            <Field
                                                                className="form-control"


                                                                type="number"
                                                                name={`travel[${index}].approvedAmount`}



                                                                InputLabelProps={{ shrink: true }}
                                                            />
                                                        </Td>
                                                        <Td>
                                                            <Field
                                                                className="form-control"
                                                                as="select"
                                                                type="text"
                                                                name={`travel[${index}].status`}
                                                                checked={item.status}

                                                            ><option value="" selected>Select</option>
                                                                <option value="Pending">Pending</option>
                                                                <option value="Rejected">Reject</option>
                                                                <option value="Verified">Verify</option>
                                                            </Field>
                                                        </Td>
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </Paper>}
                                    {/* Meal Expenses Table */}
                                   {values?.meal?.length>0 && <Paper style={{ margin: '20px', padding: '20px' }}>
                                        <Typography variant="h6" gutterBottom>
                                            Meal Expenses
                                        </Typography>
                                        <Table>
                                            <Thead>
                                                <Tr>

                                                    <Th>Meal Type</Th>
                                                    <Th>Date</Th>
                                                    <Th>Amount</Th>
                                                    <Th>Purpose</Th>
                                                    <Th>Invoice</Th>
                                                    <Th>Max Cal. Amo.</Th>

                                                    <Th>Approved Amount</Th>
                                                    <Th>Status</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {values.meal.map((item, index) => (
                                                    <Tr key={index}>

                                                        <Td>{item.mealtype}</Td>
                                                        <Td>{datehelper.displayDate(new Date(item.date))}</Td>
                                                        <Td>{item.amount}</Td>
                                                        <Td>{item.purpose}</Td>
                                                        <Td>
                                                            <a href={item.invoice} target="_blank" rel="noopener noreferrer">
                                                                Invoice Link
                                                            </a>
                                                        </Td>
                                                        <Td>{item.maxamount}</Td>

                                                        <Td>
                                                            <Field
                                                                className="form-control"

                                                                type="number"
                                                                name={`meal[${index}].approvedAmount`}



                                                                InputLabelProps={{ shrink: true }}
                                                            />
                                                        </Td>
                                                        <Td>
                                                            <Field
                                                                className="form-control"
                                                                as="select"
                                                                type="text"
                                                                name={`meal[${index}].status`}

                                                            >
                                                                <option value="" selected>Select</option>
                                                                <option value="Pending">Pending</option>
                                                                <option value="Rejected">Reject</option>
                                                                <option value="Verified">Verify</option>
                                                            </Field>
                                                        </Td>
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </Paper>}
                                    {/* Stay Expenses Table */}
                                   {values?.stay?.length>0 &&  <Paper style={{ margin: '20px', padding: '20px' }}>
                                        <Typography variant="h6" gutterBottom>
                                            Stay Expenses
                                        </Typography>
                                        <Table>
                                            <Thead>
                                                <Tr>

                                                    <Th>City Type</Th>
                                                    <Th>City Name</Th>
                                                    <Th>Start Date</Th>
                                                    <Th>Duration</Th>
                                                    <Th>Amount</Th>
                                                    <Th>Purpose</Th>
                                                    <Th>Invoice</Th>
                                                    <Th>Max Cal. Amo.</Th>

                                                    <Th>Approved Amount</Th>

                                                    <Th>Status</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {values.stay.map((item, index) => (
                                                    <Tr key={index}>

                                                        <Td>{item.citytype}</Td>
                                                        <Td>{item.cityname}</Td>
                                                        <Td>{datehelper.displayDate(new Date(item.startDate))}</Td>
                                                        <Td>{item.duration}</Td>
                                                        <Td>{item.amount}</Td>
                                                        <Td>{item.purpose}</Td>
                                                        <Td>
                                                            <a href={item.invoice} target="_blank" rel="noopener noreferrer">
                                                                Invoice Link
                                                            </a>
                                                        </Td>
                                                        <Td>{item.maxamount}</Td>

                                                        <Td>
                                                            <Field
                                                                className="form-control"

                                                                type="number"
                                                                name={`stay[${index}].approvedAmount`}



                                                                InputLabelProps={{ shrink: true }}
                                                            />
                                                        </Td>
                                                        <Td>
                                                            <Field
                                                                className="form-control"
                                                                as="select"
                                                                type="text"
                                                                name={`stay[${index}].status`}
                                                                checked={item.status}

                                                            ><option value="" selected>Select</option>
                                                                <option value="Pending">Pending</option>
                                                                <option value="Rejected">Reject</option>
                                                                <option value="Verified">Verify</option>

                                                            </Field>
                                                        </Td>
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </Paper>}
                                    <TextField
                                        style={{ marginTop: '20px', marginBottom: '10px' }}
                                        label="Comments"
                                        type="text"
                                        required
                                        name="comment"
                                        onChange={e => setFieldValue("comment", e.target.value)}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <Button type="submit" variant="contained" color="primary" disabled={spinner}>
                                        Submit {spinner && <span className="spinner-border spinner-border-sm"></span>}
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Fade>
            </Modal>
        </>
    );
};

export default VerifyEmployeeExpense;
