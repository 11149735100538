import React, { useEffect, useState } from "react";
import { CheckInModal } from "./CheckInModal";
import { ApproveCheckInModal } from "./ApproveCheckInModal";
import { ledgerService } from "../../_services/ledger.service";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import roleChecker from "../../_helpers/rolechecker";
import { employeeService } from "../../_services";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Button } from "@material-ui/core";
import datehelper from "../../_helpers/datehelper"
import Product from "../../_helpers/product";

export const CashCheckInList = () => {
    const [pendingTransactions, setPendingTransactions] = useState([])
    const [approvedTransactions, setApprovedTransactions] = useState([])
    const [employees, setEmployees] = useState({})
    const [ledgers, setLedgers] = useState({})
    const user = employeeService.userValue
    let Heading = 'Cash Management --> Check In'
    console.log("Product is "+employeeService.productValue)

    const deleteLedgerTransaction = (id) => {
        let yes = window.confirm('Are you sure! You want to delete this')
        if (yes) {
            ledgerService.deleteLedgerTransaction(id)
                .then(res => {
                    window.alert('Request deleted')
                    window.location.reload()
                })
                .catch(err => {
                    window.alert('Some error occurred')
                    console.log(err)
                })
        }
    }

    const filterTransactions= (txlist)=> {
        console.log('filter transcation',txlist);
        return  txlist.filter((t)=> {
            // console.log('requester_emp_id',t.verification_details.requester_emp_id)
            console.log('User.emp_id',user.emp_id)
             if(t.verification_details&&t.verification_details.requester_emp_id==user.emp_id || t.verification_details &&t.verification_details.approve_emp_id==user.emp_id)
             {
                return true;
             }
             if(roleChecker.hasAccountsAccess(user.role) ||roleChecker.isHOLevelAccess(user.role) )
             {
                return true;
             }
             return false;
        });
         
         
    }
    const pendingLedgerTransactions = () => {
        console.log('hiiii')
        ledgerService.pendingLedgerTransactions()
            .then(res => {
                 
                setPendingTransactions(filterTransactions(res));
                console.log('pending ledger transcations are',setPendingTransactions(filterTransactions(res)))
            })
            .catch(err => console.log('error',err))
    }

    const approvedLedgerTransactions = () => {
        ledgerService.approvedLedgerTransactions()
            .then(res => setApprovedTransactions(filterTransactions(res)))
    }

    function getLedgers() {
        let list = {}
        ledgerService.getLedgerList().then((ldrs) => {
            if (ldrs.length > 0) {
                ldrs.map(ldr => {
                    list[`${ldr._id}`] = ldr.branch_id
                })
            }
            setLedgers(list)
            console.log('set ledgers areee', setLedgers(list))
        });
    }

    const getEmployees = () => {
        employeeService.getAll()
            .then(res => {
                let list = {}
                if (res.length > 0) {
                    res.map(emp => {
                        list[`${emp.emp_id}`] = emp.first_name + ' ' + emp.middle_name + ' ' + emp.last_name
                    })
                }
                setEmployees(list)
                console.log('get employees ',  list)
            })
    }

    useEffect(() => {
        pendingLedgerTransactions()
        getEmployees()
        getLedgers()
        approvedLedgerTransactions()
    }, [])
    return (
        <div style={{ padding: '20px', padding: 'auto' }}>
            <h2>Cash Management <ArrowForwardIcon />Check In</h2>
            <div style={{ width: '100%', display: 'flex', border: '1px solid black', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                <div style={{ width: '100%', display:'flex', justifyContent:'flex-end' }}>
                    {(roleChecker.isGoldRider(user.role) || roleChecker.isHOLevelAccess(user.role) || (roleChecker.isBMLevelAccess(user.role) && employeeService.productValue==Product.GoldLoan) ) && !roleChecker.hasAccountsAccess(user.role) &&  <CheckInModal credit='hoLedgers' debit='otherLedgers' />}
                    {roleChecker.hasAccountsAccess(user.role) && !roleChecker.isGoldRider(user.role) && (pendingTransactions.length > 0) && <ApproveCheckInModal ledgers={ledgers} employees={employees} pendingTransactions={pendingTransactions.filter(trxn=>{return trxn.verification_details && trxn.verification_details.requester_emp_id!==user.emp_id})} />}
                </div>
                <div style={{ width: '100%', border: '1px solid black', margin: '20px', padding: '20px' }} >
                    <Table style={{ textAlign: 'center' }}>
                        <Thead>
                            <Tr>
                                <Th style={{ width: '20%', textAlign: 'center' }}>Amount</Th>
                                <Th style={{ width: '20%', textAlign: 'center' }}>Branch</Th>
                                <Th style={{ width: '20%', textAlign: 'center' }}>Check-In Date</Th>
                                <Th style={{ width: '20%', textAlign: 'center' }}>Approval Date</Th>
                                <Th style={{ width: '20%', textAlign: 'center' }}>Check-In By</Th>
                                <Th style={{ width: '20%', textAlign: 'center' }}>Status</Th>
                                <Th style={{ width: '20%', textAlign: 'center' }}>Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {(pendingTransactions && pendingTransactions.length > 0) || (approvedTransactions && approvedTransactions.length > 0) ?
                                <React.Fragment>
                                    {pendingTransactions.length > 0 && pendingTransactions.map(transaction => {
                                        return <Tr key={transaction._id}>
                                            <Td>
                                                {transaction.cash_summary &&
                                                    <>{transaction.cash_summary.total_amount.toLocaleString("en-IN", {
                                                        // maximumFractionDigits: 2,
                                                        style: "currency",
                                                        currency: "INR",
                                                    })}</>}
                                            </Td>
                                            <Td>
                                                {ledgers && ledgers[`${transaction.debit_ledger_id}`]}
                                            </Td>
                                            <Td>
                                                {datehelper.displayDateTime(new Date(transaction.createdAt))}
                                            </Td>
                                            <Td>
                                            </Td>
                                            <Td>
                                                {transaction.verification_details && <>{employees[transaction.verification_details.requester_emp_id]}</>}
                                            </Td>
                                            <Td style={{ color: 'rgb(238,206,36)' }}>
                                                Pending
                                            </Td>
                                            <Td style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                <CheckInModal viewOnly={true} transaction={transaction} />
                                                {transaction && transaction.verification_details && (transaction.verification_details.requester_emp_id === user.emp_id) && <Button color="secondary" onClick={() => deleteLedgerTransaction(transaction._id)}>Delete</Button>}
                                            </Td>
                                        </Tr>
                                    })}
                                    {approvedTransactions.length > 0 && approvedTransactions.map(transaction => {
                                        return <Tr key={transaction._id}>
                                            <Td>
                                                {transaction.cash_summary &&
                                                    <>{transaction.cash_summary.total_amount.toLocaleString("en-IN", {
                                                        // maximumFractionDigits: 2,
                                                        style: "currency",
                                                        currency: "INR",
                                                    })}</>}
                                            </Td>
                                            <Td>
                                                {ledgers && ledgers[`${transaction.debit_ledger_id}`]}
                                            </Td>
                                            <Td>
                                                {datehelper.displayDateTime(new Date(transaction.createdAt))}
                                            </Td>
                                            <Td>
                                                {datehelper.displayDateTime(new Date(transaction.updatedAt))}
                                            </Td>
                                            <Td>
                                                {transaction.verification_details && <>{employees[transaction.verification_details.requester_emp_id]}</>}
                                            </Td>
                                            <Td style={{ color: 'green' }}>
                                                Approved
                                            </Td>
                                            <Td style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                <CheckInModal viewOnly={true} transaction={transaction} />
                                            </Td>
                                        </Tr>
                                    })}
                                </React.Fragment>
                                :
                                <Tr>
                                    <Td align='center' colSpan='7'>Data not available</Td>
                                </Tr>
                            }
                        </Tbody>
                    </Table>
                    {console.log('to to to ',pendingTransactions)}
                </div>
            </div>
        </div>
    )
}