import { fetchWrapper} from "../_helpers";

const apiUrl = process.env.REACT_APP_API_URL;

const baseUrl = `${apiUrl}/adminservice`;


 
  function createAllCloudToken(params) {
    // console.log('params is',params)
    return fetchWrapper.post(`${baseUrl}/createallcloudtoken`, params)
  }
  
  export const allcloudtokenServices = {
    createAllCloudToken
    }
