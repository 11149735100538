import {Button, Typography } from "@material-ui/core"
import React from "react"
import {history} from "../../_helpers/history"
import { misService } from "../../_services";
import { employeeService } from "../../_services";


function NewGoldLoan(props) {
  const [loanType,setLoanType]=React.useState("new_loan");
  const [loanNumber,setLoanNumber]=React.useState("");
  const [loandetails,setLoanDetails]=React.useState(null);
  const {path}=props.match;
  const user=employeeService.userValue;
  const onBankSelected=(bank_name)=> {
    console.log("bank name=="+bank_name);
    if(bank_name=="fed_bank"){
      history.push(`/home/pledge`)
    }
    if(bank_name=="csb_bank"){
      history.push(`/home/application`)
    }

  }
  const renewLoan=()=>{
        history.push(`/home/pledge?renew_of=${loanNumber}`)
  };

  const searchLoan=()=>{
    setLoanDetails(null);
    let filterParams={'loan_number':loanNumber}
    misService.getAllFilter(filterParams).then((loanList)=> {
      console.log(loanList);
      if(loanList.length>0)
      {
        let tmpLD=loanList[0];
        console.log(tmpLD);
        console.log(user);
        if((tmpLD.branch_name && tmpLD.branch_name.toUpperCase()==user.branch.branch_name.toUpperCase()) || user.branch_id=="HO001")
        {
          if(tmpLD.loan_status=="Closed")
          {
            alert("CLosed Loan can't be renewed.");
            return;
          }
          setLoanDetails(tmpLD);
        }
        else{
          if(!tmpLD.branch_name)
          {
            if(tmpLD.loan_status=="Closed")
            {
              alert("CLosed Loan can't be renewed.");
              return;
            }
            setLoanDetails(tmpLD);
          }
          else
          { 
            alert("only branch level loans can be renewed");
          }
        }
      }
      else{
        alert("No such loan found");  
      }
    })
    .catch((error)=>{
      console.log(error);
      alert("error getting loan");
    })
  }
  return (
    <div>
       <div  className="row justify-content-center" style={{'margin-bottom':'20px'}}>
          <Typography variant="h5">New Gold Loan Creation</Typography>
       </div>
       <div  className="row d-flex justify-content-center">
             <input
              style={{ marginRight: 5, marginLeft:20 }}
              type="radio"
              id="new_loan"
              name="new_loan"
              value="new_loan"
              checked={loanType === "new_loan"}
              onChange={() => setLoanType("new_loan")}
            />
            <label style={{ marginRight: 10 }} htmlFor="new_loan">
              New Loan
            </label>
            <input
              style={{ marginRight: 5 }}
              type="radio"
              id="renew_loan"
              name="renew_loan"
              value="renew_loan"
              checked={loanType === "renew_loan"}
              onChange={() => setLoanType("renew_loan")}
            />
            <label htmlFor="renew_loan">Renew Loan</label>
           </div>
           <div  className="row d-flex justify-content-center">
             {
               (loanType=="new_loan" && (
                 <div>
                   <label>Select the Co-Lending bank:</label><br/>
                   <select type="select" name="bank_name"
                   onChange={(e)=> onBankSelected(e.target.value)}
                   >
                     <option selected disabled></option>
                     <option value="fed_bank">Federal Bank</option>
                     <option value="csb_bank">CSB Bank</option>
                   </select>
                 </div>
               ))
             }
             {
               (loanType=="renew_loan" && (
                 <div>
                   <label>Search the loan to renew:</label><br/>
                   <div className="row">
                       <input type="text" name="loan_name" length="50"
                       onChange={(e)=>setLoanNumber(e.target.value)}></input> 
                       <Button style={{marginLeft:'10px'}} variant="outlined" color="primary" onClick={()=>searchLoan()}>Search</Button>
                   </div>
                   <div className="row">
                    {(loandetails && (
                      <div style={{marginTop:'10px',backgroundColor:'#EBF4FA',padding:'10px'}}>
                        <p>Name: {loandetails.name}</p>
                        <p>Loan Account: {loandetails.loan_account}</p>
                        <p>Loan Amount: Rs {loandetails.loan_amount}</p>
                        <Button variant="contained" color="primary" onClick={()=>renewLoan()}>Renew Loan</Button>
                      
                      </div>
                    ))}
                    </div>
                 </div>
               ))
             }
          </div>
    </div>
    
  )
}

export default NewGoldLoan
