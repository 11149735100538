import { fetchWrapper} from "../_helpers";

const apiUrl = process.env.REACT_APP_API_URL;

const baseUrl = `${apiUrl}/accounting`;


function branchSummary(params) {
    return fetchWrapper.post(`${baseUrl}/sblbranchsummary`, params);
  }

 function getQRPaymentsByAccountNumber(params) {
    return fetchWrapper.post(`${baseUrl}/individualpayment`, params);
  }


  export const accountService = {branchSummary,
    getQRPaymentsByAccountNumber,
    }

