import React, { useEffect, useState } from 'react'
import { ledgerService } from '../../_services/ledger.service'
import BankDepositModal from './BankDepositModal'
import { ArrowForward } from '@material-ui/icons'
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { employeeService } from '../../_services';
import datehelper from "../../_helpers/datehelper"
import roleChecker from '../../_helpers/rolechecker';
import { Button } from '@material-ui/core';

const CashBankDepositList = () => {
    const [bankDepositList, setBankDepositList] = useState([])
    const [employees, setEmployees] = useState({})
    const getBankDepositList = async () => {
        await ledgerService.getBankDepositList()
            .then((res) => setBankDepositList(res))
            .catch((err) => console.log(err))
    }
    const approveBankDeposit = async (params) => {
        await ledgerService.approveBankDeposit(params)
            .then((res) => { 
                alert("Bank Deposit Request Approved !!") 
                window.location.reload()
        })
            .catch((err) => console.log(err))
    }
    const getEmployees = () => {
        employeeService.getAll()
            .then(res => {
                let list = {}
                if (res.length > 0) {
                    res.map(emp => {
                        list[`${emp.emp_id}`] = emp.first_name + ' ' + emp.middle_name + ' ' + emp.last_name
                    })
                }
                setEmployees(list)
            })
    }
    useEffect(() => {
        getBankDepositList()
        getEmployees()
    }, [])
    const user = employeeService.userValue
    console.log('Test Bank and Ledegers', bankDepositList)
    return (
        <div>
            <div style={{ margin: '30px' }}>
                <h2 style={{ marginBottom: "50px" }}>Cash Management <ArrowForward /> Cash Bank Deposit</h2>
                {(roleChecker.isBMLevelAccess(user.role) || roleChecker.hasAccountsAccess(user.role)) && <BankDepositModal />}
                {/* {roleChecker.hasAccountsAccess(user.role)&&<bankDepositListModal/>} */}
                <Table style={{ border: '2px solid blue' }}>
                    <Thead style={{ background: "#2949B9", color: 'white', height: '50px' }}>
                        <Tr>
                            <Th>Branch</Th>
                            <Th>Account No</Th>
                            <Th>Bank name</Th>
                            <Th>Deposit Amount</Th>
                            <Th>Request Creation Date</Th>
                            <Th>Requested By</Th>
                            <Th>Status</Th>
                            {/* <Th>Approved By</Th> */}
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {bankDepositList.length > 0 ? bankDepositList.filter(checkbranch =>
                            (checkbranch.branch_id === user.branch_id) || (user.branch_id === "HO001")).map((x, idx) =>
                                <>
                                    <Tr key={idx} style={{ background: '#D9DDF5', height: "50px", fontWeight: 'bold', fontSize: "18px" }}>
                                        <Td>{x.branch_id}</Td>
                                        <Td>{x.account_no}</Td>
                                        <Td>{x.bank_name}</Td>
                                        <Td>{x.deposit_amount.toLocaleString("en-IN", { style: 'currency', currency: 'INR' })}</Td>
                                        <Td>{datehelper.displayDateTime(new Date(x.createdAt))}</Td>
                                        <Td>{employees[x.requested_by]}</Td>
                                        <Td style={{ color: x.status === "Pending" ? "yellow" : (x.status === "Rejected" ? "red" : "green") }}>{x.status}</Td>
                                        {(roleChecker.hasAccountsAccess(user.role) && x.status==="Pending")?<Td><Button onClick={() => approveBankDeposit(x)}
                                        style={{background:"green",color:'white'}}>Approve</Button></Td>:<Td>{employees[x.approved_by]}</Td>}
                                    </Tr>
                                    <br />
                                </>) : <Tr>
                            <Td colSpan="6" style={{ textAlign: "center", fontWeight: "bold" }}>Data Not Found</Td>
                        </Tr>}
                    </Tbody>
                </Table>
            </div>
        </div>
    )
}

export default CashBankDepositList
