import React from 'react';
import { useState } from 'react';
import { Modal, Box, Button, Paper, Typography } from '@material-ui/core';
import { Table, Thead, Tbody, Tr, Td, Th } from 'react-super-responsive-table';
import urlhelper from '../../_helpers/urlhelper';
import { history } from '../../_helpers';
import { useFormik } from 'formik';
import Save from '@material-ui/icons/Save';
import { uploadFileToBucket } from '../../_helpers/upload';
import { CircularProgress } from '@material-ui/core'
import { loanService } from '../../_services';
import { Delete } from '@material-ui/icons';

 
export const ColenderDocumentModal = (props) => {
    const [open, setOpen] = useState(false)
    const [show, setShow] = useState(false)
    const bucket = process.env.REACT_APP_s3docsBucket
    console.log('bucket', bucket)
    const colenderPayment = props.colenderPayment
    const formik = useFormik({
        initialValues: {
            application_id: '',
            co_lender_name: '',
            co_lender_account_num: '',
            documents: []
        },
        onSubmit
    })

    const addArray = () => {
        let arr = [...formik.values.documents, { document_type: '', date: new Date(), url: '' }]
        formik.setFieldValue('documents', arr)
    }

    const removeArray = (e, idx) => {
        let arr = [...formik.values.documents]
        // console.log(idx, arr)
        arr.splice(idx, 1)
        formik.setFieldValue('documents', arr)
    }

    const handleImage = async (e, idx) => {
        setShow(true)
        let file = e.target.files[0];
        let name = file.name.split('.')[0];
        let num1 = Math.floor(Math.random() * 1000);
        let num2 = Math.floor(Math.random() * 100);
        let fileName = `${name}_${num1}${num2}.pdf`
        console.log(fileName)
        let link = await uploadFileToBucket(bucket, 'application/pdf', `${bucket}/colenderdocuments/${colenderPayment.application_id}/${fileName}`, file);
        let arr = [...formik.values.documents]
        arr[idx]['url'] = link
        formik.setFieldValue('documents', arr)
        setShow(false)
    }

    const handleEdit = (e, idx) => {
        let arr = [...formik.values.documents]
        arr[idx]['url'] = ''
        formik.setFieldValue('documents', arr)
    }

    async function onSubmit() {
        await loanService.colenderDocuments(formik.values)
            .then(res => {
                window.alert('Details saved successfully')
                window.location.reload()
            })
            .catch(err => {
                window.alert('Some error occurred')
                console.log(err)
            })
    }

    const getColenderDocuments = () => {
        setShow(true)
        formik.setValues({
            ...formik.values,
            application_id: colenderPayment.application_id,
            co_lender_name: colenderPayment.co_lender_data.co_lender_name,
            co_lender_account_num: colenderPayment.co_lender_data.co_lender_account_num,
            documents: [],
        })
        loanService.getColenderDocuments({ application_id: colenderPayment.application_id, co_lender_name: colenderPayment.co_lender_data.co_lender_name })
            .then(res => {
                if (res && res.length > 0) {
                    formik.setValues({
                        ...formik.values,
                        application_id: colenderPayment.application_id,
                        co_lender_name: colenderPayment.co_lender_data.co_lender_name,
                        co_lender_account_num: colenderPayment.co_lender_data.co_lender_account_num,
                        documents: res[0].documents,
                    })
                }
                setShow(false)
            })
            .catch(err => {
                window.alert('Error occurred while fetching data')
                console.log(err)
            })
    }

    const handleOpen = () => {
        getColenderDocuments()
        setOpen(true)
    }

    const handleClose = () => {
        formik.setValues({})
        setOpen(false)
    }

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "600px",
        minHeight: "330px",
        maxHeight: "360px",
        bgcolor: "background.paper",
        border: "2px solid #000",
        overflow: "scroll",
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Button onClick={handleOpen} style={{ color: 'green' }} >Colender Documents</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box id="modal-modal-title" variant="h6" component="h2" style={{ display: 'flex', flexDirection: 'column', alignItem: 'center' }}>
                        <form onSubmit={formik.handleSubmit}>
                            <Paper>
                                <Box style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <span style={{ margin: 'auto', marginTop: '10px', marginBottom: '10px', fontSize: 'medium' }}>
                                        <b>Documents</b>
                                    </span>
                                </Box>
                            </Paper>
                            <Paper style={{ marginTop: '20px', padding: '20px' }}>
                                <Table style={{ fontSize: 'medium', width: '90%', margin: 'auto', textAlign: 'center' }}>
                                    <Thead>
                                        <Tr>
                                            <Th style={{ width: '20%', textAlign: 'center' }}>Sr. No.</Th>
                                            <Th style={{ width: '40%', textAlign: 'center' }}>Document Type</Th>
                                            <Th style={{ width: '40%', textAlign: 'center' }}>File</Th>
                                            {/* <Th style={{ width: '10%', textAlign: 'center' }}></Th> */}
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                            formik.values.documents && formik.values.documents.length > 0 && formik.values.documents.map((document, idx) => {
                                                return <Tr key={document._id ? document._id : document.date}>
                                                    <Td>
                                                        {idx + 1}
                                                    </Td>
                                                    <Td><input
                                                        type="text"
                                                        style={{ width: '90%' }}
                                                        name={`documents[${idx}][document_type]`}
                                                        defaultValue={document.document_type}
                                                        onChange={(e) => formik.handleChange(e, idx)}
                                                        required />
                                                    </Td>
                                                    <Td>
                                                        {document.url === '' ?
                                                            <input
                                                                accept=".pdf"
                                                                type="file"
                                                                style={{ width: '90%' }}
                                                                name="document"
                                                                onChange={(e) => handleImage(e, idx)}
                                                                required />
                                                            :
                                                            <><Button
                                                                color='primary'
                                                                variant='outlined'
                                                                onClick={() => history.push(`/home/pdfviewer?pdf_url=` + urlhelper.encodeToBase64(document.url) + "&download=1")}
                                                            >View</Button>
                                                                <Button
                                                                    color='secondary'
                                                                    variant='outlined'
                                                                    style={{ marginLeft: '10px' }}
                                                                    onClick={(e) => handleEdit(e, idx)}
                                                                >edit</Button></>}
                                                    </Td>
                                                    {/* <Td>
                                                        <Button type='button' onClick={(e)=>removeArray(e,idx)}><Delete/></Button>
                                                    </Td> */}
                                                </Tr>
                                            })
                                        }
                                    </Tbody>
                                </Table>
                            </Paper>
                            {show === false ?
                                <><Paper style={{ width: '63px', margin: 'auto', marginTop: '20px' }}>
                                    <Button type='button' onClick={() => { addArray() }}>
                                        Add
                                    </Button>
                                </Paper>
                                    <hr style={{ width: '100%' }} />
                                    <Paper style={{ width: '63px', height: '63px', margin: 'auto', marginTop: '10px', paddingTop: '12.5px', borderRadius: '70px' }}>
                                        <Button type='submit' style={{ color: 'blue' }}>
                                            <Save />
                                        </Button>
                                    </Paper></> :
                                <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}  ><CircularProgress /></div>
                            }
                        </form>
                    </Box>
                </Box>
            </Modal>
            {
                console.log(formik.values)
            }
        </div>
    )
}