import React from "react";
import Modal from "@material-ui/core/Modal";
import { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { loanService } from "../../../../_services/loan.service";
import DisbursementDetailsList from "./DisbursementDetailsList";
import { employeeService } from '../../../../_services';



const AddDisbursementDetails = (props) => {
  const customer = props.customer;
  const account_data=props.account_data;

  console.log(props, "uuu")

  const [open, setOpen] = useState(false);

  const user = employeeService.userValue;

  
  const [values, setValues] = useState({
    kaabil_account_number: "",
    kaabil_bank_name: "",
    disbursed_account_number: "",
    account_holder_name: "",
    disbursed_amount: "",
    disbursed_date: "",
    disbursed_utr_number: "",
    disbursement_memo_id: "",
  });
  
  const editAccount = () =>{
    if(props.isEdit === true){
      setValues(customer.disbursement.disbursement_details[props.idx])
    }else{
      setValues({kaabil_account_number: account_data.kaabil_account_number,
      kaabil_bank_name: account_data.kaabil_bank_name,
      disbursed_account_number: account_data.customer_acc_no,
      account_holder_name: account_data.customer_name,
      disbursed_amount: account_data.payment_amount,
      disbursed_date: account_data.disbursed_date,
      disbursed_utr_number: "",
      disbursement_memo_id: account_data._id})
    }
  }
  const style = {
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      maxWidth: '600px',
      backgroundColor: '#fff', // Replace 'background.paper' with a color code
      border: '2px solid #000',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', // Similar to boxShadow: 24
      padding: '32px', // Similar to p: 4 (4 * 8px = 32px)
      borderRadius: '8px',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      alignItems: 'stretch',
      alignContent: 'space-around',
      gap: '16px', // Space between elements
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    
      gap: '16px', // Space between fields in the same row
    },
    label: {
      marginBottom: '8px',
      fontWeight: 'bold',
      fontSize: '14px',
      color: '#333',
    },
    input: {
      padding: '8px 12px',
      fontSize: '14px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      width: '100%',
      boxSizing: 'border-box',
    },
    button: {
      padding: '10px 20px',
      fontSize: '16px',
      fontWeight: 'bold',
      backgroundColor: '#007bff',
      color: '#fff',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      marginTop: '16px',
      alignSelf: 'flex-end',
    },
    
  };
  // const style = {
  //   position: "absolute",
  //   top: "300px",
  //   left: "40%",
  //   transform: "translate(-50%, -50%)",
  //   width: 600,
  //   height: 550,
  //   bgcolor: "background.paper",
  //   border: "2px solid #000",
  //   boxShadow: 24,
  //   p: 4,
  //   overflow: "scroll",
  //   //paddingBottom: '30px',
  // };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleOpen = () => {
    setOpen(true);
    editAccount()
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  let totalAmount = 0;
  let firstDate = "";
  const calculate = () =>{
    if(customer.loan.disbursement_date ){
      firstDate = customer.loan.disbursement_date 
    }else if(customer && customer.disbursement && customer.disbursement.disbursement_details && customer.disbursement.disbursement_details.length>0){
      firstDate= customer.disbursement.disbursement_details[0].disbursed_date
    }else{
       firstDate= values.disbursed_date
    }

    if(customer && customer.disbursement && customer.disbursement.disbursement_details && customer.disbursement.disbursement_details.length>0){
      customer.disbursement.disbursement_details.map((e)=>{
        totalAmount = Number(e.disbursed_payment_amount) + Number(totalAmount)
        console.log(e.disbursed_amount)
      })
    }else{
      totalAmount = values.disbursed_payment_amount
    }
  }

  let date = new Date()
  let dd = date.getDate()
  let mm = date.getMonth()+1;
  let yy = date.getFullYear()
  date =yy+ "-" + mm + "-" + dd
  // console.log(date)

  const handleSubmit = (e) => {
    e.preventDefault(); 

    let params = [];
    let account_details = [];
    if(customer && customer.disbursement && customer.disbursement.disbursement_accounts){
      account_details = customer.disbursement.disbursement_accounts;
    }else{
      account_details = [];
    }
    if(customer && customer.disbursement && customer.disbursement.disbursement_details ){
    params = customer.disbursement.disbursement_details;
    params.push({ ...values, payment_status:"pending", added_by:user.emp_id });
    }else{
      params = [];
      params.push({ ...values, payment_status:"pending", added_by:"user.emp_id" });
    }
    let disbursement_memo = [];
    if(customer && customer.disbursement && customer.disbursement.disbursement_memo){
      disbursement_memo = customer.disbursement.disbursement_memo
      disbursement_memo[props.idx]["paid_status"]=true;
    }else{
      disbursement_memo=[];
    }
    calculate();
    console.log(customer.disbursement.disbursement_memo)

    if(Number(totalAmount) > Number(customer.loan.sanction_amount)){
      console.log("test",totalAmount, customer.loan.sanction_amount )
      alert("Amount should be less than or equal to the Sanction Amount")
    }else{
    let deduction=customer.loan.disbursement_deduction;
    if(!deduction)
    {
         deduction=0
    }  
    let updateObject = {
      loan :{...customer.loan, disbursed_amount: (totalAmount+deduction),disbursed_payment_amount :totalAmount},
      disbursement: {
        disbursement_details: [...params],
        disbursement_accounts: [...account_details],
        disbursement_memo: [...disbursement_memo]
      },
    };
    console.log(values);
    loanService
      .updateDisbursement(customer._id, updateObject)
      .then((x) => {
        console.log(x);
        window.alert("Details Added");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };

  const update = (e) => {
    e.preventDefault()
    let params = [];
    let account_details = [];
    if(customer && customer.disbursement && customer.disbursement.disbursement_accounts){
      account_details = customer.disbursement.disbursement_accounts;
    }else{
      account_details = [];
    }
    if(customer && customer.disbursement && customer.disbursement.disbursement_details ){
    params = customer.disbursement.disbursement_details;
    params.splice(props.idx,1)
    params.push({ ...values,payment_status:"paid" });
    }else{
      params = [];
      params.push({ ...values, payment_status:"paid" });
    }
    let disbursement_memo = [];
    if(customer && customer.disbursement && customer.disbursement.disbursement_memo){
      disbursement_memo = customer.disbursement.disbursement_memo
    }else{
      disbursement_memo=[];
    }
    let deduction=customer.loan.disbursement_deduction;
    if(!deduction)
    {
         deduction=0
    }
    let updateObject = {
      loan :{...customer.loan, disbursed_amount: (totalAmount+deduction),disbursed_payment_amount:totalAmount },
      disbursement: {
        disbursement_details: [...params],
        disbursement_accounts: [...account_details],
        disbursement_memo: [...disbursement_memo]
      },
    };
    console.log(values);
    loanService
      .updateDisbursement(customer._id, updateObject)
      .then((x) => {
        console.log(x);
        window.alert("Details Added");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Button onClick={handleOpen} variant="contained" color="primary">
       {props.button}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style.modal}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <form
              style={style.form}
                 onSubmit={(e) => props.isEdit ? update(e) : handleSubmit(e)}
            >
              <div style={style.row}>
              <label style={style.label} >
                  Kaabil Account Number  </label>
                  <input style={style.input}
                    type={"text"}
                    name="kaabil_account_number"
                    required
                    value={values.kaabil_account_number}
                    onChange={(e) => handleChange(e)}
                  />
              
                
              </div>
              <div style={style.row}>
                <div className="form-group">
              <label  style={style.label}>
                  Kaabil Bank Name  </label>
                  <input style={style.input}
                    type={"text"}
                    name="kaabil_bank_name"
                    value={values.kaabil_bank_name}
                    required
                    onChange={(e) => handleChange(e)}
                  />
                  </div>
              <div className="form-group">
                <label style={style.label} >
                  Account Holder Name                </label>
                  <input style={style.input}
                    type={"text"}
                    name="account_holder_name"
                    value={values.account_holder_name}
                    disabled={props.isEdit}
                    required
                    onChange={(e) => handleChange(e)}
                  />
                  </div>
              </div>

              <div style={style.row}>
              <div className="form-group">
                <label style={style.label} >
                  Disburse Amount   </label>
                  <input style={style.input}
                    type="number"
                    name="disbursed_amount"
                    step={"any"}
                    disabled={props.isEdit}
                    required
                    value={values.disbursed_amount}
                    onChange={(e) => handleChange(e)}
                  />
                  </div>
             <div className="form-group">
                <label  style={style.label} >
                  Disburse Date  </label>
                  <input style={style.input}
                    type={"date"}
                    name="disbursed_date"
                    max= {date}
                    // disabled={props.isEdit}
                    required
                    onChange={(e) => handleChange(e)}
                  />
              </div>
              </div>

              <div style={style.row}>
              <div className="form-group">
                <label  style={style.label} >
                  Customer Bank Account </label>
                  <input style={style.input}
                    type="text"
                    name="disbursed_account_number"
                    required
                    disabled={props.isEdit}
                    value={values.disbursed_account_number}
                    onChange={(e) => handleChange(e)}
                  />
               </div>
               <div className="form-group">
                <label  style={style.label}>
                  UTR Number </label>
                  <input style={style.input}
                    type="text"
                    name="disbursed_utr_number"
                    pattern=".{3,}"
                    title="Minimum Length Should be 5"
                    required={props.isEdit}
                    value={values.disbursed_utr_number}
                    onChange={(e) => handleChange(e)}
                  />
               
                </div>
              </div>
              {props.isEdit ? <button  style={style.button}  >Update</button>: <button  style={style.button} >Submit</button>}
            </form>
          </Typography>
        </Box>
      </Modal>

    </div>
  );
};

export default AddDisbursementDetails;