import React from 'react'
import { Route, Switch } from "react-router-dom";
import NocLetter from './NocLetter';
import ForClosureLetter from './ForClosureLetter';
import DocumentListLetter from './DocumentListLetter';
import LetterForm from "./LetterForm"
import CamLetter from './CamLetter';
import EditLetter from './EditLetter';
import WelcomeLetter from './WelcomeLetter';
import CamLetterForm from './CamLetterForm';
import PPHandOverDocument from './PPHandOverDocument'
const Index = ({match}) => {

    const {path} = match;

  return (
    <>
     <Switch>
      <Route path={`${path}/letter/nocform`} component={LetterForm}/>
      <Route path={`${path}/letter/forform`} component={LetterForm}/>
      <Route path={`${path}/letter/docform`} component={LetterForm}/>
      <Route path={`${path}/letter/pphform`} component={LetterForm}/>
      {/* <Route path={`${path}/letter/edit/nocform`} component={EditLetter}/> */}
      <Route path={`${path}/letter/show/nocletter`} component={NocLetter}/>
      <Route path={`${path}/letter/show/forletter`} component={ForClosureLetter}/>
      <Route path={`${path}/letter/show/docletter`} component={DocumentListLetter}/>
      <Route path={`${path}/letter/show/PPHandOverDocument`} component={PPHandOverDocument}/>
      {/* <Route path={`${path}/letter/edit/docform`} component={EditLetter}/> */}
      {/* <Route path={`${path}/letter/edit/forform`} component={EditLetter}/> */}
      <Route path={`${path}/letter/camletterform`} component={CamLetterForm}/>
      <Route path={`${path}/letter/edit/camletter`} component={CamLetterForm}/>
      <Route path={`${path}/letter/welcomeletter`} component={WelcomeLetter}/>
      <Route path={`${path}/letter/show/camletter`} component={CamLetter}/>
    </Switch>
    </>
  )
}

export default Index