import React from 'react'
import { useState, useEffect } from 'react'
import CerSaiUpload from '../../Applications/CerSaiUpload'
import { TabContext } from '../TabBar';
import { useContext } from "react";
import {useParams,useHistory} from 'react-router-dom'
const CersaiReport = () => {
  let TabContextValue = useContext(TabContext);
  let cust = TabContextValue.cust;
  const [stageCollection, setStageCollection] = useState(false);
  const [isHovered, setIsHovered] = React.useState(false);
  const history = useHistory();
  console.log('cust::::::::::::',cust)
  const owner_name = cust.cam_letters[cust.cam_letters.length - 1]?.owner_name;
  const property_owner = cust.cam_letters[cust.cam_letters.length - 1]?.property_owner;
  const coapplicants = cust.cam_letters[cust.cam_letters.length - 1]?.coapplicants;

  console.log('owner_name, property_owner, coapplicants:', owner_name, property_owner, coapplicants);

  let IsApplicatispropertyOwner = true; // Default value
  let matchingCoapplicantName = ''; // To store the matching coapplicant's name

  // Normalize the owner_name
  const normalizedOwnerName = owner_name?.toLowerCase().replace(/\s+/g, '') || '';
  console.log('normalizedOwnerName:::',normalizedOwnerName)
  // Check if property_owner is 'No'
  if (property_owner && property_owner === 'No') {
    // Check if any coapplicant's name matches the normalized owner name
    if (coapplicants && Array.isArray(coapplicants)) {
      const matchingCoapplicant = coapplicants.find(coapplicant => {
        const normalizedCoapplicantName = coapplicant.name.toLowerCase().replace(/\s+/g, '');
        console.log('normalizedCoapplicantName:::',normalizedCoapplicantName)
        return normalizedCoapplicantName === normalizedOwnerName;
      });

      // If a matching coapplicant is found, update the flag and name
      if (matchingCoapplicant) {
        IsApplicatispropertyOwner = false; // Match found
        matchingCoapplicantName = matchingCoapplicant.name; // Store the matching name
      }
    }
  }

  // Output the results
  console.log('IsApplicatispropertyOwner:', IsApplicatispropertyOwner);
  if (IsApplicatispropertyOwner) {
    console.log('Matching Coapplicant Name:', matchingCoapplicantName);
  }


  const cersaiData={
    'application_id':cust.application_id,
    'applicant_name':cust.applicant.general_information.name,
    'applicant_sonof':cust.applicant.general_information.sonof,
    'gender':cust.applicant.general_information.gender,
    'sanction_amount':cust.loan.sanction_amount,
    'disbursement_date':cust.loan.disbursement_date,
    'cersai_reports':cust.property_details.cersai_reports,
    'property_area':cust.property_details.area,
    'co_lending': cust.co_lending[cust.co_lending.length - 1]?.co_lender_name,
    'property_address': cust.cam_letters[cust.cam_letters.length - 1]?.property_address,
    'owner_name': cust.cam_letters[cust.cam_letters.length - 1]?.owner_name,
    'property_owner': cust.cam_letters[cust.cam_letters.length - 1]?.property_owner,
    'application_form': cust.application_form,
    'valuationrculegaldata': cust.valuationrculegaldata,
    'stage': cust.stage,
    'IsApplicatispropertyOwner':IsApplicatispropertyOwner,
    'matchingPropertyOwner':matchingCoapplicantName
    
  }
  // Effect to update stageCollection based on cust.stage
  useEffect(() => {
    if (['Operation',"Collection","Closed","Disbursal"].includes(cust.stage)) {
      setStageCollection(true);
    } else {
      setStageCollection(false);
    }
  }, [cust]);

  console.log('cersaiData:::::',cersaiData)
  
  // Button styles
  const buttonStyle = {
    backgroundColor: 'blue',
    border: 'none',
    color: 'white',
    padding: '15px 32px',
    textDecoration: 'none',
    fontSize: '16px',
    fontWeight: 'bold',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'background-color 0.4s, color 0.4s, border 0.4s',
  };

  const buttonHoverStyle = {
    backgroundColor: 'white',
    color: 'blue',
    border: '2px solid #4CAF50',
  };
  // Container styles
  const containerStyle = {
    backgroundColor: '#f0f0f0', // Light gray background
    padding: '20px',
    borderRadius: '10px',
    marginTop: '20px',
  };

  // const headingStyle = {
  //   textAlign: 'center',
  //   marginBottom: '20px',
  // };

  const buttonContainerStyle = {
    textAlign: 'left', // Aligns the button to the left
    marginBottom: '20px',
  };

  return (
    <div style={containerStyle}>
      {/* <h2 style={headingStyle}>CERSAI Report</h2> */}
      <div style={buttonContainerStyle}>
        {stageCollection ? (
          <button
            style={{
              ...buttonStyle,
              ...(isHovered ? buttonHoverStyle : {}),
              cursor: 'pointer',
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => history.push('/home/cersai', { cersaiData })}
          >
            SEARCH CERSAI
          </button>
        ) : (
          <button
            style={{
              ...buttonStyle,
              cursor: 'not-allowed',
            }}
            disabled
          >
            SEARCH CERSAI
          </button>
        )}

        {!stageCollection && (
          <p>Alert: Please try after reaching the Collection stage.</p>
        )}
      </div>

      <CerSaiUpload application_id={cust.application_id} cust={cust} />
    </div>
  );
};

export default CersaiReport;
