import { Box, Button, Modal } from '@material-ui/core'
import { Add } from '@material-ui/icons';
import React, { useEffect, useState } from 'react'
import { collectionService, employeeService } from '../../../_services';
import { history } from '../../../_helpers';
import { Table, Th, Thead, Td, Tr, Tbody } from 'react-super-responsive-table'
import { CircularProgress } from '@material-ui/core'
import roleChecker from '../../../_helpers/rolechecker';
import Product from "../../../_helpers/product"
export const CreateDepositSlipModal = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 900,
        height: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        borderRadius: '5px',
        overflow: "scroll"
    };
    const date = new Date()
    let user = employeeService.userValue
    let branchName = employeeService.userValue.branch_id
    const [open, setOpen] = useState(false)
    const [depositList, setDepositList] = useState([])
    const [fromDate, setFromDate] = useState(new Date())
    const [selected, setSelected] = useState([])
    const [countSlip, setCountSlip] = useState()
    const [toDate, setToDate] = useState(new Date())
    // new Date(date.setDate(date.getDate() + 1))
    const [showLoading, setShowLoading] = useState(false)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    useEffect(() => {
        countDepositSlip();
        LoadSlip()
    }, [])
    const getBranchDepositedList = async () => {
        await collectionService.getBranchTransactions({ branch: branchName, fromDate: dateFormat(fromDate), toDate: toDate })
            .then((res) => {
                setDepositList(res)
                setShowLoading(false)
            })
            .catch((err) => console.log(err))
    }
    const LoadSlip = () => {
        setShowLoading(true)
        getBranchDepositedList()
    }
    console.log("Test list", depositList)
    const dateFormat = (value) => {
        let max_date = new Date(value)
        let dd = ("00" + max_date.getDate()).slice(-2)
        let mm = ("00" + (max_date.getMonth() + 1)).slice(-2);
        let yy = max_date.getFullYear();
        max_date = yy + "-" + mm + "-" + dd;
        return max_date
    }
    // let slip_number = `KFPL/GL/DS/${date.toLocaleString("default", { month: "long" })}/${countSlip + 1}`
    let slip_number = `KFPL/GL/DS/${date.toLocaleString("default", { month: "2-digit" })}/${countSlip + 1}`;
    const GenerateSlip = () => {
        history.push("generatedepositslip", {slip_data:selected, slip_number:slip_number })
    }
    const saveDepositSlip = async () => {
        collectionService.saveDepositSlip({ selectedDeposit: selected, slip_number: slip_number, branch_id: branchName })
            .then((res) => {
                alert("Generate Deposit Memo")
                GenerateSlip()
            })
            .catch((err) => console.log(err))
    }
    console.log("Test count slip", countSlip)
    const countDepositSlip = async () => {
        await collectionService.countSlip()
            .then((res) => setCountSlip(res))
            .catch((err) => console.log(err))
    }
    const handleCheckboxChange = (event) => {
        const selectedId = event.target.value;
        let packet = depositList.find(accept => accept._id === selectedId)
        if (event.target.checked) {
            setSelected([...selected, packet])
        } else {
            setSelected(selected.filter(acceptId => acceptId !== packet))
        }
    }
    let totalAmount = selected.reduce((curr, acc) => curr + acc.payment, 0)
    return (
        <div>
           {employeeService.productValue===Product.SBL &&roleChecker.isBMLevelAccess(user.role) && <Button onClick={handleOpen}
                style={{ display: 'flex', float: 'right', background: 'blue', borderRadius: "5px", color: "white" }}>
                <Add /> Create Deposit Memo</Button>}
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <div>
                        <h2 style={{ textAlign: 'center', textDecoration: 'underLine', marginBottom: "50px" }}>CREATE DEPOSIT MEMO</h2>
                        <div style={{ display: "flex", justifyContent: 'space-around' }}>
                            <div style={{ display: "flex", flexDirection: "column", }}>
                                <label>Branch</label>
                                <span
                                    style={{ border: "1px solid black", width: '140px', height: "27px" }}
                                >{user.branch.branch_name}</span>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", }}>
                                <label>From Date</label>
                                <input type='date' value={dateFormat(fromDate)}
                                    onChange={(e) => setFromDate(e.target.value)}
                                    defaultValue={dateFormat(fromDate)} />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", }}>
                                <label>To Date</label>
                                <input type='date' value={dateFormat(toDate)}
                                    onChange={(e) => setToDate(e.target.value)}
                                    defaultValue={dateFormat(toDate)} />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", }}>
                                <br />
                                <Button
                                    style={{ width: '90px', height: "27px", background: "blue", marginTop: '10px', color: "white" }}
                                    onClick={LoadSlip}
                                >Load</Button>
                            </div>
                        </div>
                        <br />
                        <br />
                        { depositList && depositList.length > 0 ? <div>
                            <h3 style={{ fontWeight:'bold'}}>Transaction's List</h3>
                            <Table>
                                <Thead>
                                    <Tr>
                                        <Th>Select</Th>
                                        <Th>Loan Number</Th>
                                        <Th>Name</Th>
                                        <Th>Branch</Th>
                                        <Th>Amount</Th>
                                        <Th>Receipt Number</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {depositList.map((x, idx) => <Tr key={idx}>
                                        <Td>
                                            <span>
                                                <input type='checkbox' style={{ width: "20px", height: "25px" }} value={x._id} onChange={handleCheckboxChange} />
                                            </span>
                                        </Td>
                                        <Td>{x.loan_no}</Td>
                                        <Td>{x.cust_name}</Td>
                                        <Td>{x.customerData[0].branch_id}</Td>
                                        <Td>{x.payment}</Td>
                                        <Td>{x.receipt_no}</Td>
                                    </Tr>)}
                                </Tbody>
                            </Table>
                            <br />
                            <span style={{ display: "inline-block" }}>
                                <h4 style={{ display: "inline", marginRight: "10px" }}>Total Amount:</h4>
                                <h5 style={{ display: "inline", border: "1px solid black", width: "150px" }}>
                                    {totalAmount.toLocaleString("en-IN", { style: 'currency', currency: 'INR' }).split(".")[0]}
                                </h5>
                            </span>
                        </div>:<div>
                              <h5 style={{textAlign:'center'}}>Data Not found</h5>
                            </div>}
                        {showLoading && <div style={{ display: 'flex', justifyContent: 'space-around' }}  ><CircularProgress /></div>}
                        {selected.length > 0 && <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                            <Button onClick={() => saveDepositSlip()}
                                style={{ background: "blue", marginTop: '30px', color: "white" }}>
                                Deposit</Button>
                        </div>}
                    </div>
                </Box>
            </Modal>

        </div>
    )
}
