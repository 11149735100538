import React, {useEffect} from 'react'
import LoadingOverlay from "react-loading-overlay";
import { Paper } from "@material-ui/core";
import { misService } from '../../_services';
import bank_paymentDetail from "../../assets/bank_paymentDetail.csv"

const ColenderPayments = () => {

  const [spinneractive, setSpinneractive] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [state, setState] = React.useState(false)
  const [data, setData] = React.useState([]);

  const arr = []


  const onChangeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const getAllData = () =>{
    misService.getBankPaymentDetails()
    .then((x) => {
      x && x.map((y) => {
        y.bank_payment_details && y.bank_payment_details.length > 0 ? y.bank_payment_details.map((e)=> {
          arr.push(e)
        }): console.log("Data Not Found");
       
      })
      arr.sort(function(a,b){return new Date(b.payment_date).getTime()-new Date(a.payment_date).getTime()})
      setData(arr)      
    })
    .catch((err) => {
      console.log("Data Not Found", err)
    })
  }



  useEffect(() => {
    getAllData()
  }, [])
  

  const onClickHandler = async () => {
    setSpinneractive(true);

    const data = new FormData();
    data.append("file", selectedFile);
 
    for (var key of data.entries()) {
      console.log(key[0] + ', ' + key[1]);
  }  

    await misService.uploadColendingPaymentsCsv(data).then((response) => {
      // setSpinneractive(false);
      if (!response.ok) {
        alert("An Error Occurred, Please try again");
      } else {
        alert("Data Uploaded");
        window.location.reload();
      }
    });
  };


  const show = () => {
    setState(!state)
  }
  
  return (
    <>
        <LoadingOverlay active={spinneractive} spinner text="Uploading Data...">

     <Paper  style={{
              padding: 20,
            }}>
              <h2 style={{textAlign: "center"}}>Import List</h2><hr></hr>
              <div style={{ display: "flex", justifyContent: "left" }}>
              <button onClick={show}>Upload CSV File</button>
            </div>
           { state === true ? <div className="container">
              <div className="offset-md-3 col-md-6">
                <form method="post" action="#" id="#">
                  <div className="form-group files">
                    <label>Upload Your File</label>
                    <input
                    style={{height: "30px"}}
                      accept=".csv"
                      type="file"
                      className="form-control"
                      onChange={(e) => onChangeHandler(e)}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-success btn-block"
                    onClick={() => onClickHandler()}
                  >
                    Upload
                  </button>
                </form>
                <div >
                  <a href={bank_paymentDetail} download style={{ display: "flex", justifyContent: "right", textDecoration: "none", textStyling: "none", color: "red" }} >Download Sample File</a>
                </div>
              </div>
            </div> : null}
     </Paper>
     </LoadingOverlay>
     
      <table>
        <thead>
          <tr>
            <th>Loan Account</th>
            <th>Bank</th>
            <th>Bank Account</th>
            <th>Customer Name</th>
            <th>Payment Date</th>
            <th>Payment Ref</th>
            <th>Payment Amount</th>
          </tr>
        </thead>
        <tbody>
        {data && data.map((element) => {
          return <tr key={element.bank_acc}>
            <td>{element.gold_acc}</td>
            <td>{element.bank&&<>{element.bank}</>}</td>
            <td>{element.bank_account&&<>{element.bank_account}</>}</td>
            <td>{element.customer_name}</td>
            <td>{element.payment_date}</td>
            <td>{element.payment_ref}</td>
            <td>{element.payment_amount}</td>
          </tr>
        })}
        </tbody>
      </table>
     {
      console.log(data)
     }
    </>
  )
}

export default ColenderPayments