import React from "react"
import { TableContainer } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { adminservice } from "../../../_services/admin.services";
import { Add,Edit } from "@material-ui/icons";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
    root: {
      width: "100%",
    },
    container: {
      maxHeight: 1000,
    },
  });

const ProviderList=({match})=> {
    const { path } = match;
    const [providerList, setProviderList] = React.useState([]);
    React.useEffect(() => {
        adminservice.getProviderDetails().then((x) => {
            setProviderList(x);
      });
  
    }, []);
  
  
    const classes = useStyles();
    // console.log('cities are ',cityList)
    return (
          <div className="justify-content-between">
              <div className="row d-flex" style={{"margin":"10px"}}>
                <h1>
                  Providers &nbsp;
                  <Button variant="outlined" aria-label="increase" href={`${path}/add`}>
                    <Add fontSize="small" />
                  </Button>
                </h1>
              </div>
              <div className="row d-flex" style={{"margin":"10px"}}>
              <TableContainer className={classes.container}>
                <Table>
                <Thead>
                    <Tr>
                    
                     
                    <Th style={{ width: "15%" }}>Provider Name</Th>
                    <Th style={{ width: "10%" }}>Email</Th>
                    <Th style={{ width: "10%" }}>Contact</Th>
                    <Th style={{ width: "10%" }}>Area Code
                    </Th>
                    <Th style={{ width: "10%" }}>Charges
                    </Th>
                    <Th style={{ width: "10%" }}>Type
                    </Th>
                    <Th style={{ width: "10%" }}>Emp ID
                    </Th>
                    <Th style={{ width: "10%" }}>Action
                    </Th>
                    </Tr>
                </Thead> 
                <Tbody>
                {providerList.map((item, idx) => (
                  <tr key={idx}>
                  <td>{item.provider_name}</td>
                  <td>{item.email}</td>
                  
                  <td>{item.contact}</td>
                  <td>{item && item.area && item.area.map((cul,dx)=>(
                    
                  <td>{cul},</td>
                   
                  ))} </td>
                  <td>{item.charges}</td>
                  <td>{item.type}</td>
                  <td>{item.emp_id}</td>
                  <Td style={{ whiteSpace: "nowrap" }}>
                  <Link
                    to={`${path}/edit/${item._id}`}
                    className="btn btn-sm btn-primary mr-1"
                  >
                    <Edit />
                  </Link>
                </Td>
                  </tr>
              ))}
                    </Tbody>
                </Table>
              </TableContainer>
              </div>
        </div>
    );
}

export default ProviderList;