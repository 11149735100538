import { PanoramaFishEyeTwoTone } from "@material-ui/icons";
import { fetchWrapper } from "../_helpers/fetch-wrapper";
const apiUrl = process.env.REACT_APP_API_URL;

const baseUrl = `${apiUrl}/letters`;


const getAll = () => {
    return fetchWrapper.get(baseUrl);
};

const create =(params) => {
    return fetchWrapper.post(`${baseUrl}/create`, params);
}

const saveDetails = (params) => {
    console.log(params);
    return fetchWrapper.post(`${baseUrl}/addletter`, params)
}

const getLrn = () => {
    return fetchWrapper.get(`${baseUrl}/lrn`)
}

const updateLrn = (id, params) => {
    return fetchWrapper.put(`${baseUrl}/lrn/update/${id}`, params)
}

const getSingleLrn = (id) => {
    console.log(id)
    return fetchWrapper.get(`${baseUrl}/singlelrn/${id}`)
}

const createLegalLetter = (params) => {
    console.log(params)
    return fetchWrapper.post(`${baseUrl}/legalletter`, params)
}

const showLegalLetter = () => {
    return fetchWrapper.get(`${baseUrl}/show/legalletter`)
}

const findLegalLetterById = (id) => {
    console.log(id)
    return fetchWrapper.get(`${baseUrl}/findlegalletter/${id}`)
}

const updateLegalLetter = (id, params) => {
    console.log(id)
    return fetchWrapper.put(`${baseUrl}/update/legalletter/${id}`, params)
}

const showEmiLetter = () => {
    return fetchWrapper.get(`${baseUrl}/show/emiletter`)
}

const createEmiLetter = (params) => {
    console.log(params)
    return fetchWrapper.post(`${baseUrl}/emiletter`, params)
}

const singleEmiLetter = (id) => {
    return fetchWrapper.get(`${baseUrl}/single/emiletter/${id}`)
}

const updateEmiLetter = (id, params) => {
    return fetchWrapper.post(`${baseUrl}/emiletter/update/${id}`, params)
}

const saveSettlementDetails = (id, params) => {
    console.log(params);
    return fetchWrapper.post(`${baseUrl}/addsettlement`, params)
}
const getsettlement = () => {
    return fetchWrapper.get(`${baseUrl}/getsettlement`)
}
const getSingleSettlement = (id) => {
    console.log(id)
    return fetchWrapper.get(`${baseUrl}/singleSettlement/${id}`)
}
const updateSettlement = (id, params) => {
    return fetchWrapper.put(`${baseUrl}/settlement/update/${id}`, params)
}
export const letter = {
    getAll,
    create,
    saveDetails,
    getLrn,
    getSingleLrn,
    updateLrn,
    createLegalLetter,
    showLegalLetter,
    findLegalLetterById,
    updateLegalLetter,
    showEmiLetter,
    createEmiLetter,
    singleEmiLetter,
    updateEmiLetter,
    saveSettlementDetails,
    getsettlement,
    getSingleSettlement,
    updateSettlement
}