import React, { useState, useEffect } from 'react'
import {sblLetter} from "../../../_services/letter.services"
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import datehelper from "../../../_helpers/datehelper"
import { history } from '../../../_helpers';
import { Button } from '@material-ui/core';
import { Edit } from "@material-ui/icons";


const PphandOver = () => {
    const [letter, setLetter] = useState()

    const getLetter = async () => {
      const data = await sblLetter.getPphandover()
      setLetter(data)
    }
    console.log(letter)
  
    useEffect(() => {
      getLetter()
  
    }, [])

    const edit =(element)=>{
      // history.push("/home/sblletters/letter/edit/docform", element )
      history.push("/home/sblletters/letter/show/PPHandOverDocument", element )

    }

  return (
    <>
      <h2>List Of Property Paper Hand Over Document</h2>
      <hr></hr>

      <div>
        <Table className="table table-hover">
          <Thead>
            <Tr>
              <Th style={{ width: "25%" }}>Date</Th>
              <Th style={{ width: "25%" }}>Letter Id</Th>
              <Th style={{ width: "25%" }}>Name</Th>
              <Th style={{ width: "15%" }}>Application_id</Th>
              {/* <Th style={{width: "10%"}}>Action</Th> */}
            </Tr>
          </Thead>
          <Tbody>
            {letter && letter.map((element) => {
              return <Tr key={element._id}>
                <Td className='table-light' style={{ width: "25%" }}>{datehelper.displayDate(new Date(element.createdAt))}</Td>
                <Td className="table-light" style={{ width: "25%" }}>{element.letter_id}</Td>
                <Td className="table-light" style={{ width: "25%" }}>{element.name}</Td>
                <Td className="table-light" style={{ width: "15%" }}>{element.application_id}</Td>
                <Td style={{width: "10%"}}><Button className='btn btn-primary' onClick={()=>edit(element)} ><Edit /> </Button></Td>
              </Tr>
            })}

          </Tbody>
        </Table>
      </div>
    </>
  )
}

export default PphandOver