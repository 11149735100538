import { Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Table, Tbody, Td, Th, Tr } from 'react-super-responsive-table'
import { goldPacketServices } from '../../../_services/gold_packet.service'
import SearchIcon from "@material-ui/icons/Search";
import { history } from '../../../_helpers';
import urlhelper from '../../../_helpers/urlhelper';
import datehelper from '../../../_helpers/datehelper';
import { employeeService } from '../../../_services';
export const PacketHistory = () => {
    const [packet, setPacket] = useState([])
    const [field, setField] = useState({});
    const [employeeList, setEmployeeList] = useState({})
    const handleChange = (fieldKey, fieldValue) => {
        field[fieldKey] = fieldValue
        setField(field)
    }
    async function getAllEmployees() {
        await employeeService.getAll()
            .then((res) => {
                let list = {};
                res.map(e => {
                    list[e.emp_id] = e.first_name + ' ' + e.last_name
                })
                setEmployeeList(list)
            })
    }
    useEffect(() => {
        async function getAllPackets() {
            await goldPacketServices.getAllCheckOutPacket()
                .then((res) => setPacket(res))
                .catch((err) => console.log(err))
        }
        getAllPackets()
        getAllEmployees();
    }, [])
    const searchByLoanNoOrPacketNo = () => {
        goldPacketServices.searchPacket(field)
            .then((res) => setPacket(res))
            .catch((err) => console.log(err))
    }
    console.log("Test All Packet", packet)
    return (
        <div style={{ margin: "10px", background: 'white' }}>
            <div style={{ margin: '100px' }}>
                <br />
                <br />
                <h2 style={{ textAlign: "center" }}>All Packet History</h2>
                <br />
                <hr />
                <br />
                <div style={{ marginBottom: "70px" }}>
                    <div style={{ float: "left" }}>
                        <input type="text" placeholder="Search by loan number "
                            style={{ border: "1px solid grey", borderRadius: "5px", height: "30px" }}
                            onChange={(e) => handleChange("loan_no", e.target.value)}
                        />
                        <button style={{ background: "blue", color: "white", border: "none", width: "80px", height: "30px", borderRadius: "5px" }}
                            onClick={searchByLoanNoOrPacketNo}
                        >
                            <SearchIcon />
                        </button>
                    </div>
                    <div style={{ float: "right" }}>
                        <input type="text" placeholder="Search by packet number "
                            style={{ border: "1px solid grey", borderRadius: "5px", height: "30px" }}
                            onChange={(e) => handleChange("packet_no", e.target.value)}
                        />
                        <button style={{ background: "blue", color: "white", border: "none", width: "80px", height: "30px", borderRadius: "5px" }}
                            onClick={searchByLoanNoOrPacketNo}
                        >
                            <SearchIcon />
                        </button>
                    </div>
                </div>
                <hr />
                <br />
                <Table>
                    <thead>
                        <Tr>
                            <Th>S.N</Th>
                            <Th>Loan No</Th>
                            <Th>Name</Th>
                            <Th>Packet No</Th>
                            <Th>Image</Th>
                            <Th>Check In By</Th>
                            <Th>Check In Accepted By</Th>
                            <Th>Check In Date</Th>
                            <Th>Check Out By</Th>
                            <Th>Check Out Accepted By</Th>
                            <Th>Check Out Date</Th>
                        </Tr>
                    </thead>
                    <Tbody>
                        {
                            packet.length > 0 ?
                                packet.map((x, i) => <Tr key={i}>
                                    <Td>{i + 1}</Td>
                                    <Td>{x.loan_no}</Td>
                                    <Td>{x.name}</Td>
                                    <Td>{x.packet_no}</Td>
                                    <Td><Button style={{ color: "blue" }}
                                        onClick={() => history.push(`/home/pdfviewer?pdf_url=` + urlhelper.encodeToBase64(x.photo) + "&download=1")}>
                                        View
                                    </Button></Td>
                                    <Td>{employeeList[x.checkIn_details.checkin_by_emp_id]}</Td>
                                    <Td>{employeeList[x.checkIn_details.accept_by_emp_id]}</Td>
                                    <Td>{datehelper.displayDate(new Date(x.checkIn_details.date))}</Td>
                                    <Td>{employeeList[x.checkOut_details.checkout_requested_by]}</Td>
                                    <Td>{employeeList[x.checkOut_details.approved_by_emp_id]}</Td>
                                    <Td>{datehelper.displayDate(new Date(x.checkOut_details.date))}</Td>
                                </Tr>)
                                : <Tr>
                                    <Td></Td>
                                    <Td></Td>
                                    <Td></Td>
                                    <Td></Td>
                                    <Td style={{ color: "red" }}><b>Data not found</b></Td>
                                    <Td></Td>
                                    <Td></Td>
                                    <Td></Td>
                                    <Td></Td>
                                </Tr>
                        }
                    </Tbody>
                </Table>
                <br />
                <br />
            </div>
        </div>
    )
}
