import React from "react";
import * as Yup from "yup";
import { Formik,Form, Field } from "formik";
import FormElement from "../../../uielements/FormElement";
import { loanService } from "../../../../_services";
import {
  withStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import maskhelper from "../../../../_helpers/maskhelper";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(key,label,value,editable,type,options=[]) {
  return { key,label, value,editable,type,options};
}

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function ApplicantData({ customer }) {
  const classes = useStyles();
  const formikRef = React.useRef();
  const [editing,setEditing]= React.useState(false);
  React.useEffect(()=> {
    
    if(customer.applicant)
    {
      const fields = [
        'applicant'
      ];
      fields.forEach((field) => {
        if (formikRef.current) {
          formikRef.current.setFieldValue(field, customer[field], false);
        }
      });
    }
    if(!customer.applicant)
    {
      const fields = [
        'applicant.general_information'
      ];
      fields.forEach((field) => {
        if (formikRef.current) {
          formikRef.current.setFieldValue(field, customer["personal_details"], false);
        }
     });
    }

  },[]);
  const initialValues = {
    'applicant': {
      'general_information':{
        'title':'',
        'name':'',
        'sonof':'',
        'wifeof':'',
        'kyc_relation_type':'',
        'kyc_relation':'',
        'contact':'',
        'gender':'',
        'education':'',
        'aadhar':'',
        'masked_aadhar':'',
        'pan_no':'',
        'age':'',
      },
      'financial_condition':{
        'cibil_score':'',
        'is_salaried':'',
        'salaried_details':{
          'employment_years':'',
          'employment_type':'',
        },
        'business_details':{
          'business_type':'',
          'business_years':'',
        },
      }
    },
  };


  const validationSchema = Yup.object().shape({
    applicant:Yup.object().shape({
      general_information:Yup.object().shape({
      name: Yup.string().required("This field is required"),
      sonof:Yup.string().required("This field is required"),
      contact:Yup.string().matches(/^[0-9]{10}$/,"Valid number").required("This field is required"),
      aadhar:Yup.string().matches(/^[0-9]{12}$/,"Valid aadhar number").required("aadhar required"),
      pan_no:Yup.string().matches(/^[a-zA-Z0-9]{10}$/,"Valid PAN number"),
      address:Yup.string().required("Address is required"),
      }),
    }),
  });

  async function onSubmit(fields) {
    console.log("in submit function");
    console.log(fields);
    console.log("submitting form");
    if(!fields['applicant']['general_information']['masked_aadhar'])
    {
      fields['applicant']['general_information']['masked_aadhar']=maskhelper.maskAadhar(fields['applicant']['general_information']['aadhar']);
    }
    //return;
    await loanService.update(customer._id,fields).then((applicant)=> {
      window.alert("Loan File Updated"+applicant.application_id);
      window.location.reload(false);
    })
    .catch((err)=> {
      window.alert("error updating Loan Application");
      console.log("error"+err)
    })
  }

  async function saveEdits(fields)
  {
    setEditing(false);
    onSubmit(fields);
  }

  const handleRelation = (values, setFieldValue, value) => {
    if(values && values.applicant && values.applicant.general_information){
      console.log(value, "enter here")
      if(value === "S/o" || value === "D/o"){
        setFieldValue('applicant.general_information.kyc_relation_type', value)
        setFieldValue('applicant.general_information.kyc_relation', values.applicant.general_information.sonof)
      }else if(value === "W/o" ){
        setFieldValue('applicant.general_information.kyc_relation_type', value)
        setFieldValue('applicant.general_information.kyc_relation', values.applicant.general_information.wifeof)
      }else{
        setFieldValue('applicant.general_information.kyc_relation_type', value)
        setFieldValue('applicant.general_information.kyc_relation', "")
      }
    }
  }

  const handleChange = (key, setFieldValue, value) => {
    setFieldValue(`${key}`, value)
  }



  const rows=[];
  if(customer.applicant)
  {
    rows.push(createData("applicant.customer_id","CIF Id", customer.applicant.customer_id,false,"text"));
    rows.push(createData("applicant.general_information.title","Title", customer.applicant.general_information.title,true,"select",["Mr","Mrs","Ms"]));
    
    rows.push(createData("applicant.general_information.name","Applicant Name", customer.applicant.general_information.name,true,"text"));
    rows.push(createData("applicant.general_information.sonof","Father Name", customer.applicant.general_information.sonof,true,"text"));
    rows.push(createData("applicant.general_information.wifeof","Husband Name", customer.applicant.general_information.wifeof,true,"text"));
    rows.push(createData("applicant.general_information.kyc_relation_type","KYC relation type", customer.applicant.general_information.kyc_relation_type,true,"select",["S/o","W/o","D/o","C/o"]));
    
    rows.push(createData("applicant.general_information.kyc_relation","KYC Relation", customer.applicant.general_information.kyc_relation,true,"text"));
    
    rows.push(createData("applicant.general_information.age","Applicant Age", customer.applicant.general_information.age,true,"number"));
    rows.push(createData("applicant.general_information.address","Address", customer.applicant.general_information.address,true,"text"));
    rows.push(createData("applicant.general_information.gender","Gender", customer.applicant.general_information.gender,true,"select",["Male","Female"]));
    rows.push(createData("applicant.general_information.education","Education", customer.applicant.general_information.education,true,"select",['No_Education','10th','12th','Graduate']));
    if(customer.personal_details && customer.personal_details.otp_verified)
    {
      rows.push(createData("applicant.general_information.contact","Contact", customer.applicant.general_information.contact,false,"number"));
    
    }
    else
    {
      rows.push(createData("applicant.general_information.contact","Contact", customer.applicant.general_information.contact,true,"number"));
    
    }
    rows.push(createData("applicant.general_information.aadhar","Aadhar Number", customer.applicant.general_information.aadhar,false,"hidden"));

    if(customer.applicant.general_information.masked_aadhar)
    {
      rows.push(createData("applicant.general_information.masked_aadhar","Masked Aadhar Number", customer.applicant.general_information.masked_aadhar,false,"text"));
    }
    else
    {
      rows.push(createData("applicant.general_information.masked_aadhar","Masked Aadhar Number", maskhelper.maskAadhar(customer.applicant.general_information.aadhar),false,"text"));   
    }
    rows.push(createData("applicant.general_information.pan_no","PAN", customer.applicant.general_information.pan_no,true,"text"));
    if(customer.applicant.financial_condition)
    {
      rows.push(createData("applicant.financial_condition.is_salaried","Is Salaried", customer.applicant.financial_condition.is_salaried,true,"select",['Yes','No']));
      rows.push(createData("applicant.financial_condition.cibil_score","CIBIL Score", customer.applicant.financial_condition.cibil_score,true,"number"));
    
      if(customer.applicant.financial_condition.is_salaried=="Yes" && customer.applicant.financial_condition.salaried_details)
      {
        rows.push(createData("applicant.financial_condition.salaried_details.employment_type","Employment Type",customer.applicant.financial_condition.salaried_details.employment_type,true,"select",['GovernmentJob','PrivateJob']));
        rows.push(createData("applicant.financial_condition.salaried_details.employment_years","Year Employment",customer.applicant.financial_condition.salaried_details.employment_years,true,"number"));
      }
      else if(customer.applicant.financial_condition.business_details) {
        rows.push(createData("applicant.financial_condition.business_details.business_type","Business Type",customer.applicant.financial_condition.business_details.business_type,true,"select",['Manufacturer','Trader','Agency','Supplier','Services','Other']));
        rows.push(createData("applicant.financial_condition.business_details.business_years","Business Years",customer.applicant.financial_condition.business_details.business_years,true,"number"));
        
      }
    }
    else
    {
      rows.push(createData("applicant.financial_condition.is_salaried","Is Salaried", "",true,"select",['Yes','No']));
      rows.push(createData("applicant.financial_condition.cibil_score","CIBIL Score", 0 ,true,"number"));
    

    }
    
  }
  else if(customer.personal_details)
  {
    console.log(customer.personal_details);
    rows.push(createData("applicant.general_information.name","Applicant Name", customer.personal_details.name,true,"text"));
    rows.push(createData("applicant.general_information.sonof","Father Name", customer.personal_details.sonof,true,"text"));
    rows.push(createData("applicant.general_information.age","Applicant Age", customer.personal_details.age,false,"number"));
    rows.push(createData("applicant.general_information.address","Address", customer.personal_details.address,true,"text"));
    rows.push(createData("applicant.general_information.gender","Gender", customer.personal_details.gender,true,"select",["Male","Female"]));
    
  }

  return (
    <Formik
                innerRef={formikRef}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                id="transition-modal-description"
              >
                {({ errors, touched, isSubmitting,  setFieldValue, values }) => {
                  return (<Form>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Applicant Details</StyledTableCell>
            <StyledTableCell align="right">
            {!editing && (
                  <a 
                      onClick={()=>setEditing(true)}>Edit</a>
              )}
              {editing && (
                  <a 
                      onClick={()=>saveEdits(values)}>Save</a>
              )}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        

          {rows.map((row) => (
            row.type !='hidden' && (<StyledTableRow key={row.label}>
              <StyledTableCell component="th" scope="row">
                {row.label}
              </StyledTableCell>
              {!editing &&  (
                    <StyledTableCell align="right">{row.value}</StyledTableCell>
              )}
              {editing && row.editable && (row.type=== "text" || row.type==="number") && (
                <StyledTableCell align="right">
                  <FormElement
                  type={row.type}
                  name={row.key}
                  label={row.label}
                  errors={errors}
                  touched={touched}
                />
                </StyledTableCell>
              )}
              {editing && row.editable && row.type=== "select" && (
                <StyledTableCell align="right">
                  <Field
                  as="select"
                  name={row.key}
                  label={row.label}
                  errors={errors}
                  touched={touched}
                  onChange={(e) => row.key === 'applicant.general_information.kyc_relation_type' ? handleRelation(values, setFieldValue, e.target.value) : handleChange(row.key, setFieldValue, e.target.value)}
                >
                <option value='' selected disabled>select option</option>  
                {row.options && row.options.map((o)=> {
                  return <option value={o}>{o}</option>
                })}
                </Field>
                </StyledTableCell>
              )}

              {editing && !row.editable && (
                <StyledTableCell align="right">
                  <FormElement
                  type="text"
                  disabled={true}
                  name={row.key}
                  label={row.label}
                  errors={errors}
                  touched={touched}
                />
                </StyledTableCell>
              )}
              
              
            </StyledTableRow>
        )))}

            

        </TableBody>
      </Table>
    </TableContainer>
    </Form>)}}
    </Formik>
  );
}
