import React from "react";
import PledgeLetter from "./PledgeLetter";
import { useReactToPrint } from "react-to-print";

export default function PledgePrint(props) {
  const compRef = React.useRef();
  const custRef = React.useRef();

  console.log(props.location.state);

  const handleCompanyPrint = useReactToPrint({
    content: () => custRef.current,
  });

  const handleCustomerPrint = useReactToPrint({
    content: () => compRef.current,
  });

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <button onClick={handleCompanyPrint}>Print Branch Copy</button>
        <button onClick={handleCustomerPrint} style={{ marginLeft: 10 }}>
          Print Customer Copy
        </button>
      </div>
      <PledgeLetter
        ref={custRef}
        owner="Branch Copy"
        dataFill={props.location.state}
      ></PledgeLetter>
      <PledgeLetter
        ref={compRef}
        owner="Customer Copy"
        dataFill={props.location.state}
      ></PledgeLetter>
    </div>
  );
}
