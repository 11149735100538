import React from "react";
import { Delete } from "@material-ui/icons";

export default function JobSheetItem({
  item,
  idx,
  handleChange,
  handleRemoveSpecificRow,
}) {
  const {
    desc,
    quantity,
    gross_weight,
    deduction,
    net_weight,
    advance_value,
    total_advance,
  } = item;

  return (
    <tr id="addr0" key={idx}>
      <td style={{ textAlign: "center" }}>{idx}</td>
      <td>
        <input
          type="text"
          name="desc"
          value={desc}
          onChange={handleChange(idx)}
          className="form-control"
        />
      </td>
      <td>
        <input
          type="number"
          min="0"
          name="quantity"
          value={quantity}
          onChange={handleChange(idx)}
          className="form-control"
        />
      </td>
      <td>
        <input
          type="number"
          min="0"
          name="gross_weight"
          step="any"
          value={gross_weight}
          onChange={handleChange(idx)}
          className="form-control"
        />
      </td>
      <td>
        <input
          type="number"
          min="0"
          name="deduction"
          step="any"
          value={deduction}
          onChange={handleChange(idx)}
          className="form-control"
        />
      </td>
      <td>
        <input
          disabled
          type="number"
          min="0"
          step="any"
          name="net_weight"
          value={net_weight}
          className="form-control"
        />
      </td>
      <td>
        <input
          type="number"
          min="0"
          step="any"
          name="advance_value"
          value={advance_value}
          onChange={handleChange(idx)}
          className="form-control"
        />
      </td>
      <td>
        <input
          disabled
          type="number"
          min="0"
          step="any"
          name="total_advance"
          value={total_advance}
          className="form-control"
        />
      </td>
      <td>
        <a className="btn btn-sm" onClick={handleRemoveSpecificRow(idx)}>
          <Delete />
        </a>
      </td>
    </tr>
  );
}
