import { fetchWrapper } from "../_helpers";

const apiUrl = process.env.REACT_APP_API_URL;

const baseUrl = `${apiUrl}/goldloan`;
export const goldLoanServices = {
    getUpcomingEmi
}
function getUpcomingEmi() {
    return fetchWrapper.get(baseUrl)
}
