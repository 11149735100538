import React  from 'react'
import { useState } from 'react'
import {uploadSmsData} from '../../../_services/sms.service'


export const SingleSms =() => {
 

  const [field, setField]= useState({})

  const handleSubmit = async (e) => {
    e.preventDefault()

    {
      field.mobile_number.length === 10 ?await uploadSmsData(field).then(() => {
        window.alert('message sent');
        window.location.reload();
      })
      .catch(error => error ? alert('something went wrong') : null ) : alert('Mobile number should be of 10 digit ')
    }
  }
  
   
   
   const handleChange=(fieldkey, fieldval)=>{
     field[fieldkey] = fieldval
     setField(field)
    //  console.log(field)
   }


    return (
        <>
          <h2>Single Sms</h2>
           <div className="container my-3">
            <form action=""  >
            <input onChange={(e)=>handleChange('mobile_number', e.target.value)} value={field.mobile_number} name='mobile_number' className="form-control form-control-lg my-3" type="number"  placeholder="Mobile Number" aria-label=".form-control-lg example" required />
             <input onChange={(e)=>handleChange('loan_number', e.target.value)} value={field.loan_number} name='loan_number' className="form-control form-control-lg my-3" type="text"  placeholder="Loan Number" aria-label=".form-control-lg example" required/>
             <button type="submit" onClick={handleSubmit} className="btn btn-primary my-3">Send Sms</button>
            </form>
           </div>
          
        </>
    )
}