import React, { useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { Table } from "@material-ui/core";
import { Tbody, Td, Th, Tr } from "react-super-responsive-table";
import datehelper from "../../_helpers/datehelper";
import logo from "../../assets/kaabil.png"
import { ledgerService } from "../../_services/ledger.service";
const DailyReportPrintVersion2 = (props) => {
    console.log("Test dfaat ", props)
    const data = props.history.location.state
    console.log("data", data)
    const branchDetails = props.history.location.state.branchDetails
    const payment = props.history.location.state.payment;
    const dailyReportId = props.history.location.state.dailyReportId
    let debitLedeger = props.history.location.state.ledegerData.debitLedger
    let creditLedeger = props.history.location.state.ledegerData.creditLedger
    const [ledegerCurrentBalance,setLedgerCurrentBalance]=useState()
    const getLedgerCurrectBalance=async()=>{
        await ledgerService.getLedgerCurrectBalance()
        .then((res)=>setLedgerCurrentBalance(res))
        .catch((err)=>console.log(err))
      }
    useEffect(()=>{
        getLedgerCurrectBalance()
    },[])
    console.log("Test debit and credit Ledeger", debitLedeger,creditLedeger)
    let cas = 0;
    let onlin = 0;
    let disbOnline = 0;
    let disbCash = 0
    let creditSum = 0
    let debitSum = 0
    let i = 0;
    let branchOnlineReceived = 0
    let branchOnlinePaid = 0
    const date = new Date();
    const dailyReportRef = React.useRef();
    const handleDailyReportPrint = useReactToPrint({
        content: () => dailyReportRef.current,
    });
    return (
        <div>
            <div style={{ textAlign: "center" }}>
                <button onClick={() => { handleDailyReportPrint() }}>
                    Print Branch Summary
                </button>
            </div>
            <div
                ref={dailyReportRef}
                style={{
                    width: "248mm",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: '50px',
                    bgcolor: "background.paper",
                    padding: 45,
                }}
            >
                <div>
                    <div style={{ textAlign: "center" }}>
                        <img style={{ width: 350, height: 100 }} src={logo}></img>
                    </div>
                    <br />
                    <h5 style={{ fontSize: "25px", textAlign: "center" }}>Daily Report</h5>
                    <br />
                    <p><b style={{ fontSize: "20px", marginRight: "40px" }}>{branchDetails.branch.branch_name}</b> BRANCH CODE :{branchDetails.branch_id}</p>
                    <b style={{ float: "right" }}>{dailyReportId}</b>
                    <b style={{ float: 'left' }}>{datehelper.displayDate(date)}</b>
                    <br />
                    <br />
                    {payment || debitLedeger || creditLedeger ? (
                        <div>
                            <Table>
                                <thead>
                                    <Tr>
                                        <Th style={{ border: "1px solid " }}>S.N</Th>
                                        <Th style={{ border: "1px solid " }}>Opening Balance</Th>
                                        <Th style={{ border: "1px solid " }}>Loan No</Th>
                                        <Th style={{ border: "1px solid " }}>Online Receive</Th>
                                        <Th style={{ border: "1px solid " }}>Online Paid</Th>
                                        <Th style={{ border: "1px solid " }}>Cash receive</Th>
                                        <Th style={{ border: "1px solid " }}>Cash Paid</Th>
                                        <Th style={{ border: "1px solid " }}>Closing Balance</Th>
                                        <Th style={{ border: "1px solid " }}>Cheque</Th>
                                        <Th style={{ border: "1px solid " }}>Remark</Th>
                                    </Tr>
                                </thead>
                                <Tbody>
                                    {
                                        debitLedeger ?
                                            debitLedeger.map((x, idx) => {
                                                debitSum = debitSum + x.cash_summary.total_amount
                                                i = i + 1
                                                return (
                                                    <Tr key={idx}>
                                                        <Td style={{ border: "1px solid " }}>{i}</Td>
                                                        <Td style={{ border: "1px solid " }}>{`Cash Paid to ${x.debit_ledger.length > 0 && x.debit_ledger[0].branch_id}`}</Td>
                                                        <Td style={{ border: "1px solid " }}>{ }</Td>
                                                        <Td style={{ border: "1px solid " }}>{ }</Td>
                                                        <Td style={{ border: "1px solid " }}>{ }</Td>
                                                        <Td style={{ border: "1px solid " }}>{ }</Td>
                                                        <Td style={{ border: "1px solid " }}>{x.cash_summary.total_amount}</Td>
                                                        <Td style={{ border: "1px solid " }}>{ }</Td>
                                                        <Td style={{ border: "1px solid " }}>{ }</Td>
                                                        <Td style={{ border: "1px solid " }}>{x.remark}</Td>
                                                    </Tr>)
                                            }) : null
                                    }
                                    {
                                        creditLedeger ?
                                            creditLedeger.map((x, idx) => {
                                                creditSum = creditSum + x.cash_summary.total_amount
                                                console.log("Test credit sum data", creditSum)
                                                i = i + 1
                                                return (
                                                    <Tr key={idx}>
                                                        <Td style={{ border: "1px solid " }}>{i}</Td>
                                                        <Td style={{ border: "1px solid " }}>{`Cash RECEIVE FROM ${x.credit_ledger.length > 0 && x.credit_ledger[0].branch_id}`}</Td>
                                                        <Td style={{ border: "1px solid " }}>{ }</Td>
                                                        <Td style={{ border: "1px solid " }}>{ }</Td>
                                                        <Td style={{ border: "1px solid " }}>{ }</Td>
                                                        <Td style={{ border: "1px solid " }}>{x.cash_summary.total_amount}</Td>
                                                        <Td style={{ border: "1px solid " }}>{ }</Td>
                                                        <Td style={{ border: "1px solid " }}>{ }</Td>
                                                        <Td style={{ border: "1px solid " }}>{ }</Td>
                                                        <Td style={{ border: "1px solid " }}>{x.remark}</Td>
                                                    </Tr>)
                                            }) : null
                                    }
                                    {payment && payment.cash_payments && payment.cash_payments.length > 0 ?
                                        payment.cash_payments.map((cash, index) => {
                                            cas = cas + cash.payment;
                                            i = i + 1
                                            return (
                                                <Tr key={index}>
                                                    <Td style={{ border: "1px solid " }}>{i}</Td>
                                                    <Td style={{ border: "1px solid " }}>{cash.cust_name}</Td>
                                                    <Td style={{ border: "1px solid " }}>{cash.loan_no}</Td>
                                                    <Td style={{ border: "1px solid " }}>{ }</Td>
                                                    <Td style={{ border: "1px solid " }}>{ }</Td>
                                                    <Td style={{ border: "1px solid " }}> {cash.payment}</Td>
                                                    <Td style={{ border: "1px solid " }}>{ }</Td>
                                                    <Td style={{ border: "1px solid " }}>{ }</Td>
                                                    <Td style={{ border: "1px solid " }}>{ }</Td>
                                                    <Td style={{ border: "1px solid " }}>{cash.comment}</Td>
                                                </Tr>
                                            );
                                        })
                                        : null}
                                    {payment && payment.online_payments && payment.online_payments.length > 0 ?
                                        payment.online_payments.map((online, index) => {
                                            onlin = onlin + Number(online.txn_amt);
                                            i = i + 1
                                            return (
                                                <Tr key={index}>
                                                    <Td style={{ border: "1px solid " }}> {i}</Td>
                                                    <Td style={{ border: "1px solid " }}>{online.remitter_name} </Td>
                                                    <Td style={{ border: "1px solid " }}>{online.vaccountnum} </Td>
                                                    <Td style={{ border: "1px solid " }}>{online.txn_amt} </Td>
                                                    <Td style={{ border: "1px solid " }}>{ }</Td>
                                                    <Td style={{ border: "1px solid " }}>{ }</Td>
                                                    <Td style={{ border: "1px solid " }}>{ }</Td>
                                                    <Td style={{ border: "1px solid " }}>{ }</Td>
                                                    <Td style={{ border: "1px solid " }}>{ }</Td>
                                                    <Td style={{ border: "1px solid " }}>{online.sender_to_receiver_info}</Td>
                                                </Tr>
                                            );
                                        })
                                        : null}
                                    {payment && payment.disbursement_details && payment.disbursement_details.length > 0 ?
                                        payment.disbursement_details.filter(x=>x.payment_mode!=="Adjustment").map(
                                            (disburse, index) => {
                                                disburse.payment_mode === "online" ? (disbOnline = disbOnline + disburse.payment_amount) : (disbCash = disbCash + disburse.payment_amount)
                                                i = i + 1
                                                return (
                                                    <Tr key={index}>
                                                        {console.log("test disburse paymet", disburse.disbursement_details)}
                                                        <Td style={{ border: "1px solid " }}> {i} </Td>
                                                        <Td style={{ border: "1px solid " }}>{disburse.name}</Td>
                                                        <Td style={{ border: "1px solid " }}>{disburse.loan_account}</Td>
                                                        <Td style={{ border: "1px solid " }}>{ }</Td>
                                                        <Td style={{ border: "1px solid " }}>{disburse.payment_mode === "online" ? disburse.payment_amount : null}</Td>
                                                        <Td style={{ border: "1px solid " }}>{ }</Td>
                                                        <Td style={{ border: "1px solid " }}>{disburse.payment_mode === "cash" ? disburse.payment_amount : null}</Td>
                                                        <Td style={{ border: "1px solid " }}>{ }</Td>
                                                        <Td style={{ border: "1px solid " }}>{ }</Td>
                                                        <Td style={{ border: "1px solid " }}>{disburse.payment_ref}</Td>
                                                    </Tr>
                                                );
                                            }
                                        )
                                        : null}
                                </Tbody>
                                <Tr style={{ border: "1px solid " }}>
                                    <Td colSpan="2" style={{ textAlign: "center", fontWeight: "bold" }}> Total</Td>
                                    <Td style={{ fontWeight: "bold", border: "1px solid " }} >{ }</Td>
                                    <Td style={{ border: "1px solid " }}>{onlin + branchOnlineReceived}</Td>
                                    <Td style={{ border: "1px solid " }}>{disbOnline + branchOnlinePaid}</Td>
                                    <Td style={{ border: "1px solid " }}>{cas +creditSum }</Td>
                                    <Td style={{ border: "1px solid" }}>{disbCash +debitSum}</Td>
                                    <Td style={{ border: "1px solid" }}>{(cas +creditSum) - (disbCash +debitSum)}</Td>
                                    <Td style={{ border: "1px solid" }}>{ }</Td>
                                    <Td style={{ border: "1px solid " }}>{}</Td>
                                </Tr>
                                <Tr >
                                <Td colSpan="10" style={{ border: "1px solid ",fontWeight:"bold" ,textAlign:"center",height:"28px"}}>Current ledger balance:{ledegerCurrentBalance && ledegerCurrentBalance.current_balance.toLocaleString("en-IN", { style: 'currency', currency: 'INR' })},
                                &nbsp; &nbsp;&nbsp;&nbsp;{ datehelper.displayDateTime(new Date())}</Td>
                            </Tr>
                            </Table>
                           
                        </div>
                    ) : (
                        <Tr>
                            <Td></Td>
                            <Td>Daily Report not available</Td>
                            <Td></Td>
                        </Tr>
                    )}
                </div>
            </div>
            {console.log("test credit an debit sum ", creditSum, debitSum)}
        </div>
    )

}


export default DailyReportPrintVersion2
