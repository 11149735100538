import React, { useEffect, useState } from 'react'
import './ViewLoanDetails.css';
import { Formik, Field, Form } from "formik";
import roleChecker from '../../../_helpers/rolechecker';
import PersonalDetailsEditModal from './PersonalDetailsEditModal';
import { employeeService } from '../../../_services';
export default function CustomerDetailsTab(props) {
    const user = employeeService.userValue;
    console.log("Test loan details", props)
    const loan = props.loan
    const customer=props.customer
    console.log("Test getBankAndCustomerDetails", customer)
    return (
        <div>
            {customer && customer.customer_details ? <div>
                <h3 style={{ float: 'left', marginBottom: "20px" }}><b>&nbsp;&nbsp;Personal Details</b></h3>
                {(roleChecker.hasAccountsAccess(user.role) || roleChecker.isHOLevelAccess(user.role))
                    && <PersonalDetailsEditModal loan={loan} customer={customer} />}
                <br />
                <br />
                <div className='personal-details'>
                    <Formik>
                        <Form className='form'>
                            <div className="form-row">
                                <div className="form-group-col">
                                    <label >Customer Name</label>
                                    <Field
                                        value={customer.customer_details && customer.customer_details.general_details && customer.customer_details.general_details.customer_firstname}
                                        className='input-field'
                                        disabled
                                    />
                                </div>
                                <div className="form-group-col">
                                    <label >CIF ID</label>
                                    <Field
                                        value={loan.customer_id}
                                        className='input-field'
                                        disabled
                                    />
                                </div>
                                <div className="form-group-col">
                                    <label >Date Of Birth</label>
                                    <Field
                                        value={customer.customer_details && customer.customer_details.general_details && customer.customer_details.general_details.customer_dob}
                                        className='input-field'
                                        disabled
                                    />
                                </div>
                                <div className="form-group-col">
                                    <label >Father's Name</label>
                                    <Field
                                        value={customer.customer_details && customer.customer_details.general_details && customer.customer_details.general_details.fathername}
                                        className='input-field'
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group-col">
                                    <label >Mother's Name</label>
                                    <Field
                                        value={customer.customer_details && customer.customer_details.general_details && customer.customer_details.general_details.mothername}
                                        className='input-field'
                                        disabled
                                    />
                                </div>
                                <div className="form-group-col">
                                    <label >Address</label>
                                    <Field
                                        value={customer.customer_details && customer.customer_details.personal_details && customer.customer_details.personal_details.permanent_address.address_line_1}
                                        className='input-field'
                                        disabled
                                    />
                                </div>
                                <div className="form-group-col">
                                    <label >City</label>
                                    <Field
                                        value={customer.customer_details && customer.customer_details.personal_details && customer.customer_details.personal_details.permanent_address.city}
                                        className='input-field'
                                        disabled
                                    />
                                </div>
                                <div className="form-group-col">
                                    <label >State</label>
                                    <Field
                                        value={customer.customer_details && customer.customer_details.personal_details && customer.customer_details.personal_details.permanent_address.state}
                                        className='input-field'
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group-col">
                                    <label >District</label>
                                    <Field
                                        value={customer.customer_details && customer.customer_details.personal_details && customer.customer_details.personal_details.permanent_address.district}
                                        className='input-field'
                                        disabled
                                    />
                                </div>
                                <div className="form-group-col">
                                    <label>Pin Code </label>
                                    <Field
                                        value={customer.customer_details && customer.customer_details.personal_details && customer.customer_details.personal_details.permanent_address.pin_code}
                                        className='input-field'
                                        disabled
                                    />
                                </div>
                                <div className="form-group-col">
                                    <label >Contact Number</label>
                                    <Field
                                        value={customer.customer_details && customer.customer_details.general_details && customer.customer_details.general_details.customer_mobile_num}
                                        className='input-field'
                                        disabled
                                    />
                                </div>
                                <div className="form-group-col">
                                    <label >Alternate Contact Number</label>
                                    <Field
                                        value={loan.contact}
                                        className='input-field'
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group-col">
                                    <label >Gender</label>
                                    <Field
                                        value={customer.customer_details && customer.customer_details.general_details && customer.customer_details.general_details.gender}
                                        className='input-field'
                                        disabled
                                    />
                                </div>
                                <div className="form-group-col">
                                    <label >Marital Status</label>
                                    <Field
                                        value={customer.customer_details && customer.customer_details.personal_details && customer.customer_details.personal_details.marital_status}
                                        className='input-field'
                                        disabled
                                    />
                                </div>
                                <div className="form-group-col">
                                    <label >Mother Tongue</label>
                                    <Field
                                        value={customer.customer_details && customer.customer_details.personal_details && customer.customer_details.personal_details.mother_tounge}
                                        className='input-field'
                                        disabled
                                    />
                                </div>
                                <div className="form-group-col">
                                    <label >Citizenship</label>
                                    <Field
                                        value={customer.customer_details && customer.customer_details.general_details && customer.customer_details.general_details.citizenship}
                                        className='input-field'
                                        disabled
                                    />
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </div>
                <br />
                <div>
                    <h3><b>&nbsp;&nbsp;Kyc Images</b></h3>
                    <br />
                    <h3>&nbsp;&nbsp;&bull;&nbsp;Adhar Card(Front and Back)</h3>
                    <br />
                    <div className='kyc-image'>
                        <div>
                            <img
                                src={customer && customer.customer_details &&
                                    customer.customer_details.verification_details.aadhaarFront} alt="adhar_front" />
                            <h3>(Front)</h3>
                        </div>
                        <div className='image-size'>
                            <img src={customer && customer.customer_details &&
                                customer.customer_details.verification_details.aadhaarBack} alt="adhar_back" />
                            <h3>(Back)</h3>
                        </div>
                    </div>
                </div>
                <div>
                    <br />
                    <h3><b>&nbsp;&nbsp;Bank Details</b></h3>
                    <br />
                    <div className='personal-details'>
                        <Formik>
                            <Form className='form'>
                                <div className="form-row">
                                    <div className="form-group-col">
                                        <label >Account Number</label>
                                        <Field
                                            value={customer.customer_details && customer.customer_details.economic_details&&customer.customer_details.economic_details.bank_details&& customer.customer_details.economic_details.bank_details.account_number}
                                            className='input-field'
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group-col">
                                        <label >Bank Name</label>
                                        <Field
                                            value={customer.customer_details && customer.customer_details.economic_details&&customer.customer_details.economic_details.bank_details&& customer.customer_details.economic_details.bank_details.bank_name}
                                            className='input-field'
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group-col">
                                        <label >IFSC Code</label>
                                        <Field
                                            value={customer.customer_details && customer.customer_details.economic_details&&customer.customer_details.economic_details.bank_details&& customer.customer_details.economic_details.bank_details.ifsc_code}
                                            className='input-field'
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group-col">
                                        <label >Account Holder Name</label>
                                        <Field
                                            value={customer.customer_details && customer.customer_details.economic_details&&customer.customer_details.economic_details.bank_details&& customer.customer_details.economic_details.bank_details.account_holder}
                                            className='input-field'
                                            disabled
                                        />
                                    </div>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div>
                <br />
                <div>
                    <h3><b>&nbsp;&nbsp;Economic Details</b></h3>
                    <br />
                    <div className='personal-details'>
                        <Formik>
                            <Form className='form'>
                                <div className="form-row">
                                    <div className="form-group-col">
                                        <label >Annual Income</label>
                                        <Field
                                            value={customer.customer_details && customer.customer_details.economic_details && customer.customer_details.economic_details.annual_income}
                                            className='input-field'
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group-col">
                                        <label >Occupation Type</label>
                                        <Field
                                            value={customer.customer_details && customer.customer_details.economic_details && customer.customer_details.economic_details.occupation_type}
                                            className='input-field'
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group-col">
                                        <label >Source Income</label>
                                        <Field
                                            value={customer.customer_details && customer.customer_details.economic_details && customer.customer_details.economic_details.source_of_income}
                                            className='input-field'
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group-col">
                                        <label >Orgnisation</label>
                                        <Field
                                            value={customer.customer_details && customer.customer_details.economic_details && customer.customer_details.economic_details.organisation}
                                            className='input-field'
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group-col">
                                        <label >Designation</label>
                                        <Field
                                            value={customer.customer_details && customer.customer_details.economic_details && customer.customer_details.economic_details.designation}
                                            className='input-field'
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group-col">
                                        <label >Net Worth In Ruppes</label>
                                        <Field
                                            value={customer.customer_details && customer.customer_details.economic_details && customer.customer_details.economic_details.net_worth}
                                            className='input-field'
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group-col">
                                        <label >Form 60?</label>
                                        <Field
                                            value={customer.customer_details && customer.customer_details.economic_details && customer.customer_details.economic_details.form60}
                                            className='input-field'
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group-col">
                                        <label >Other PID</label>
                                        <Field
                                            value={customer.customer_details && customer.customer_details.economic_details && customer.customer_details.economic_details.other_pid}
                                            className='input-field'
                                            disabled
                                        />
                                    </div>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div> :
                <div>
                    {/* <h3><b>&nbsp;&nbsp;Personal Details</b></h3> */}
                    <h3 style={{ float: 'left', marginBottom: "20px" }}><b>&nbsp;&nbsp;Personal Details</b></h3>
                   {(roleChecker.hasAccountsAccess(user.role) || roleChecker.isHOLevelAccess(user.role))&&
                    <PersonalDetailsEditModal loan={loan} />}
                    <br />
                    <br />
                    <div className='personal-details'>
                        <Formik>
                            <Form className='form'>
                                <div className="form-row">
                                    <div className="form-group-col">
                                        <label >Customer Name</label>
                                        <Field
                                            value={loan.name}
                                            className='input-field'
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group-col">
                                        <label >Date Of Birth</label>
                                        <Field
                                            value={loan.details && loan.details.dob}
                                            className='input-field'
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group-col">
                                        <label >Father's Name</label>
                                        <Field
                                            value={loan.details && loan.details.father}
                                            className='input-field'
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group-col">
                                        <label >Mother's Name</label>
                                        <Field
                                            value={loan.details && loan.details.mother}
                                            className='input-field'
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group-col">
                                        <label >Address</label>
                                        <Field
                                            value={loan.details && loan.details.permanent_address && loan.details.permanent_address.address}
                                            className='input-field'
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group-col">
                                        <label >City</label>
                                        <Field
                                            value={loan.details && loan.details.permanent_address && loan.details.permanent_address.city}
                                            className='input-field'
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group-col">
                                        <label >State</label>
                                        <Field
                                            value={loan.details && loan.details.permanent_address && loan.details.permanent_address.state}
                                            className='input-field'
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group-col">
                                        <label >District</label>
                                        <Field
                                            value={loan.details && loan.details.permanent_address && loan.details.permanent_address.district}
                                            className='input-field'
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group-col">
                                        <label>Pin Code </label>
                                        <Field
                                            value={loan.details && loan.details.permanent_address && loan.details.permanent_address.pin_code}
                                            className='input-field'
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group-col">
                                        <label >Contact Number</label>
                                        <Field
                                            value={loan.contact}
                                            className='input-field'
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group-col">
                                        <label >Gender</label>
                                        <Field
                                            value={loan.details && loan.details.gender}
                                            className='input-field'
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group-col">
                                        <label >Marital Status</label>
                                        <Field
                                            value={loan.details && loan.details.marital_status}
                                            className='input-field'
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group-col">
                                        <label >Mother Tongue</label>
                                        <Field
                                            value={loan.details && loan.details.mother_tounge}
                                            className='input-field'
                                            disabled
                                        />
                                    </div>

                                </div>
                            </Form>
                        </Formik>
                    </div>
                    <br />
                </div>}
        </div>
    )
}
