import React, { useState } from "react";
import PropertyListColumns from "./PropertyListColumns";
import JobSheetMinimalList from "./PropertyListRows";
import EmptyJobSheetMinimal from "./EmptyJobSheetMinimal";
import { Button, Typography } from "@material-ui/core";

export default function PropertyList({list, setFieldValue }) {
  const [values, setValues] = useState([]);

  const handleChange = (idx) => (e) => {
    const { name, value } = e.target;

    const newvalues = [...values];
    newvalues[idx][name] = value;
    setFieldValue("properties", newvalues);
    setValues(newvalues);
  };

  const handleAddRow = () => {
    const item = {
      owner: "",
      details: "",
      area: "",
    };
    setFieldValue("properties", [...values, item]);
    setValues([...values, item]);
  };

  const handleRemoveSpecificRow = (idx) => () => {
    const newvalues = [...values];

    newvalues.splice(idx, 1);
    setFieldValue("properties", newvalues);
    setValues(newvalues);
  };

  // if (values.length > 0) {
  //   return (
  //     <div>
  //       <div className="col-10 mx-auto text-center text-title">
  //         <Typography variant="h5">Property List</Typography>
  //       </div>
  //       <div className="row clearfix">
  //         <div className="col-md-12 column">
  //           <table className="table table-bordered table-hover" id="tab_logic">
  //             <thead>
  //               <PropertyListColumns />
  //             </thead>
  //             <JobSheetMinimalList
  //               handleRemoveSpecificRow={handleRemoveSpecificRow}
  //               handleChange={handleChange}
  //               values={values}
  //             />
  //           </table>
  //           <div style={{ textAlign: "center", margin: 20 }}>
  //             <Button variant="outlined" onClick={handleAddRow}>
  //               Add Item
  //             </Button>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // } else {}
    return (
      <div>
        <EmptyJobSheetMinimal />
        <div style={{ textAlign: "center", margin: 20 }}>
        {list ? (
        <table>
            <thead>
              <tr>
                <th style={{width: "20%"}}>Owner</th>
                <th style={{width: "20%"}}>Area</th>
                <th style={{width: "20%"}}>Type</th>
              </tr>
            </thead>
            <tbody>
             <tr>
                <td style={{width: "20%"}}>{list.owner}</td>
                <td style={{width: "20%"}}>{list.area}</td>
                <td style={{width: "20%"}}>{list.type}</td>
              </tr>  
            </tbody>
          </table>
          ): null}

          {/* <Button variant="outlined" onClick={handleAddRow}>
            Add Item
          </Button> */}
        </div>
      </div>
    );
  
}
