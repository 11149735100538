import React, { useState } from "react";
import InputSheetColumns from "./InputSheetColumns";
import InputSheetList from "./InputSheetList";
import EmptyInputSheet from "../EmptyInputSheet";
import { Button, Typography } from "@material-ui/core";
import { customerService, loanService } from "../../../../_services";
import { uploadFileToBucket } from '../../../../_helpers/upload';

export default function DocumentListInputSheet({
  setFieldValue,
  customer
}) {
  const assignRef = React.useRef(null);
  let url;
  const [values, setValues] = useState([]);
  React.useEffect(() => {
    if (customer && customer.property_details && customer.property_details.document_list) {
      setValues(customer.property_details.document_list);
    }
  }, []);
  async function saveDocuments() {
    console.log("saving loan data...");
    console.log(values);
    let property_details = {}
    if (customer.property_details) {
      property_details = customer.property_details
    }
    if (!property_details.document_list) {
      property_details.document_list = []
    }
    property_details.document_list = values;
    let params = {}
    params['property_details'] = property_details;
    loanService.update(customer._id, params).then((res) => {
      window.alert("Document list updated...");
      window.location.reload();
    })
      .catch((err) => {
        console.log("error");
      });
  }

  const bucket = process.env.REACT_APP_s3sblBucket;

  const decimalRoundOff = (num) => {
    return Math.round(num * 100) / 100;
  };

  const loan_number = customer.application_id;


  const handleChange = (idx) => (e) => {

    if (e.target.name === "document_url") {
      let { name, value } = e.target;
      let selectedFile = e.target.files[0]
      const data = new FormData();
      data.append("file", selectedFile);
      let fileName = selectedFile.name.split(".")
      fileName = fileName[0]
      const upload = async () => {
         await uploadFileToBucket(bucket, "application/pdf", `${loan_number}/scanned_document_${fileName}.pdf`, selectedFile)
        .then((data)=>{
          value=data;
          console.log(data)
          const newvalues = [...values];
          newvalues[idx][name] = value;
          setFieldValue("document_list", newvalues);
          setValues(newvalues);
        })
        .catch((err)=>{console.log(err)})         
      }
      upload()
      // const name = 'document_url';
      // const value = url;
    } else {
      const { name, value } = e.target;
      const newvalues = [...values];
      newvalues[idx][name] = value;
      setFieldValue("document_list", newvalues);
      // setFieldValue("rpg", 0);
      setValues(newvalues);
      console.log(newvalues)
    }
  };

  const handleAddRow = () => {
    const item = {
      document_type: "",
      original_type: "Photocopy",
      is_lod:""
    };
    setFieldValue("document_list", [...values, item]);
    setValues([...values, item]);

  };

  const handleRemoveSpecificRow = (idx) => () => {
    const newvalues = [...values];

    setFieldValue(
      "document_type",
      newvalues[idx].document_type
    );
    setFieldValue(
      "original_type", newvalues[idx].origin_type
    );
    setFieldValue(
      "is_lod",
      newvalues[idx].is_lod
    )

    newvalues.splice(idx, 1);
    setFieldValue("document_list", newvalues);
    setValues(newvalues);
  };

  if (values.length > 0) {
    return (
      <div>
        <div className="col-10 mx-auto text-center text-title">
          <Typography variant="h5">Document List</Typography>
          <p style={{color:'red'}}>Note: if you added a wrong file then refresh the page before saving the data</p>
        </div>
        <div className="row clearfix">
          <div className="col-md-12 column">
            <table className="table table-bordered table-hover" id="tab_logic">
              <thead>
                <InputSheetColumns />
              </thead>
              <InputSheetList
                handleRemoveSpecificRow={handleRemoveSpecificRow}
                handleChange={handleChange}
                values={values}
              />
            </table>
            <div style={{ textAlign: "center", margin: 20 }}>
              <Button variant="outlined" onClick={handleAddRow}>
                Add Item
              </Button>
            </div>
            <div style={{ textAlign: "center", margin: 20 }}>
              <Button variant="contained" onClick={() => saveDocuments()}
                color="primary">
                Save Documents
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <EmptyInputSheet />
        <div style={{ textAlign: "center", margin: 20 }}>
          <Button variant="outlined" onClick={handleAddRow}
            color="primary">
            Add Document
          </Button>
        </div>

        <div style={{ textAlign: "center", margin: 20 }}>
          <Button variant="contained" onClick={() => saveDocuments()}
            type="primary">
            Save Documents
          </Button>
        </div>
      </div>
    );
  }
}
