import { Modal, Box, Typography } from "@material-ui/core";
import React from 'react';
import { useState } from "react";
import datehelper from "../../../_helpers/datehelper"
import GoldAuctionServices from "../../../_services/goldauction.service";
import { Button } from '@material-ui/core';

export const NoticeUpdateModal = (props) => {
    const [open, setOpen] = useState(false)
    const auctionDetail = props.auction
    const notice = props.notice
    const ndate =new Date()

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false);
    }

    const [value, setValue] = useState(notice === 1 ? {
        customer_name: auctionDetail.customer_name,
        loan_no: auctionDetail.loan_no,
        notice1: {
            dispatch_date: auctionDetail.notice1.dispatch_date,
            receiving_date: auctionDetail.notice1.receiving_date,
            emp_name: auctionDetail.notice1.emp_name,
            emp_id: auctionDetail.notice1.emp_id,
            received_by: auctionDetail.notice1.received_by,
            registered_no: auctionDetail.notice1.registered_no,
            remarks: auctionDetail.notice1.remarks,
        }
    } : {
        customer_name: auctionDetail.customer_name,
        loan_no: auctionDetail.loan_no,
        notice2: {
            dispatch_date: auctionDetail.notice2.dispatch_date,
            receiving_date: auctionDetail.notice2.receiving_date,
            emp_name: auctionDetail.notice1.emp_name,
            emp_id: auctionDetail.notice1.emp_id,
            received_by: auctionDetail.notice2.received_by,
            registered_no: auctionDetail.notice2.registered_no,
            remarks: auctionDetail.notice2.remarks,
        }
    })

    const handleChange = (fieldKey, fieldValue) => {
        notice === 1 ?
            setValue({ ...value, notice1: { ...value.notice1, [fieldKey]: fieldValue } }) :
            setValue({ ...value, notice2: { ...value.notice2, [fieldKey]: fieldValue } })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        await GoldAuctionServices.UpdateNoticeDetails(auctionDetail._id, value)
            .then((res) => { alert('Details updated successfully'); window.location.reload() })
            .catch((err) => { console.log(err); alert('Something went wrong') })
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        height: 350,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'scroll',
        //paddingBottom: '30px',
    }
    return (
        <div>
            {/* <button onClick={handleOpen} style={{ borderRadius: '5px', color: 'white', backgroundColor: '#0066cc', border: 'none', width: "200px", height: '40px' }} >Update Tracking Details</button> */}
            <Button
            onClick={handleOpen}
            style={{color:"blue"}}
            >
            Update Tracking Details
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography>
                        <h5 style={{ display: 'flex', justifyContent: 'center' }}><b>Notice {notice}</b></h5>
                        <div style={{ display: 'flex', justifyContent: 'center' }}><span>Loan No. : {value.loan_no}</span></div>
                        <form onSubmit={(e) => handleSubmit(e)} >
                            {
                                notice === 1 ?
                                    <Typography>
                                        <Typography style={{ display: "flex", justifyContent: 'space-around', marginTop: '20px' }}>
                                            <label style={{ width: '130px' }}>Dispatch Date
                                                <input type="Date" style={{ borderRadius: "5px" }} name='dispatch_date' onChange={(e) => handleChange([e.target.name], e.target.value)} defaultValue={datehelper.getDateInputString(new Date(value.notice1.dispatch_date))} placeholder="Dispatch Date" disabled required />
                                            </label>
                                            <label style={{ width: '130px', marginLeft: '20px' }}>Receiving Date
                                                {value.notice1.receiving_date ? <input type="Date" style={{ borderRadius: "5px" }} name='receiving_date' max={datehelper.getDateInputString(ndate)} onChange={(e) => handleChange([e.target.name], e.target.value)} defaultValue={datehelper.getDateInputString(new Date(value.notice1.receiving_date))} placeholder="Receiving Date" required />
                                                    : <input type="Date" style={{ borderRadius: "5px" }} name='receiving_date' max={datehelper.getDateInputString(ndate)} onChange={(e) => handleChange([e.target.name], e.target.value)} placeholder="Receiving Date" required />}
                                            </label>
                                            <label style={{ width: '130px', marginLeft: '20px' }}>Received By
                                                <input type="text" style={{ borderRadius: "5px" }} name='received_by' onChange={(e) => handleChange([e.target.name], e.target.value)} defaultValue={value.notice1.received_by} placeholder="Received By" required />
                                            </label>
                                        </Typography>

                                        <Typography style={{ display: "flex", justifyContent: 'space-around' }}>
                                            <label style={{ width: '130px', marginLeft: '20px' }}>Registered No.
                                                <input type="text" style={{ borderRadius: "5px" }} name='registered_no' onChange={(e) => handleChange([e.target.name], e.target.value)} defaultValue={value.notice1.registered_no} placeholder="Registered No." required/>
                                            </label>
                                            <label style={{ width: '130px', marginLeft: '20px' }}>Remarks
                                                <input type="text" style={{ borderRadius: "5px" }} name='remarks' onChange={(e) => handleChange([e.target.name], e.target.value)} defaultValue={value.notice1.remarks} placeholder="Remarks" required />
                                            </label>
                                        </Typography>
                                        <button style={{ borderRadius: '5px', color: 'white', backgroundColor: '#0066cc', border: 'none', width: "150px", height: '40px', marginLeft: '250px', marginTop: '20px' }} >Update Details</button>
                                    </Typography> :
                                    <Typography>
                                        <Typography style={{ display: "flex", justifyContent: 'space-around', marginTop: '20px' }}>
                                            <label style={{ width: '130px' }}>Dispatch Date
                                                <input type="Date" style={{ borderRadius: "5px" }} name='dispatch_date' onChange={(e) => handleChange([e.target.name], e.target.value)} defaultValue={datehelper.getDateInputString(new Date(value.notice2.dispatch_date))} placeholder="Dispatch Date" disabled required />
                                            </label>
                                            <label style={{ width: '130px', marginLeft: '20px' }}>Receiving Date
                                                {value.notice2.receiving_date ? <input type="Date" style={{ borderRadius: "5px" }} name='receiving_date' max={datehelper.getDateInputString(ndate)} onChange={(e) => handleChange([e.target.name], e.target.value)} defaultValue={datehelper.getDateInputString(new Date(value.notice2.receiving_date))} placeholder="Receiving Date" required />
                                                    : <input type="Date" style={{ borderRadius: "5px" }} name='receiving_date' max={datehelper.getDateInputString(ndate)} onChange={(e) => handleChange([e.target.name], e.target.value)} placeholder="Receiving Date" required />}
                                            </label>
                                            <label style={{ width: '130px', marginLeft: '20px' }}>Received By
                                                <input type="text" style={{ borderRadius: "5px" }} name='received_by' onChange={(e) => handleChange([e.target.name], e.target.value)} defaultValue={value.notice2.received_by} placeholder="Received By" required />
                                            </label>
                                        </Typography>

                                        <Typography style={{ display: "flex", justifyContent: 'space-around' }}>
                                            <label style={{ width: '130px', marginLeft: '20px' }}>Registered No.
                                                <input type="text" style={{ borderRadius: "5px" }} name='registered_no' onChange={(e) => handleChange([e.target.name], e.target.value)} defaultValue={value.notice2.registered_no} placeholder="Registered No." required />
                                            </label>
                                            <label style={{ width: '130px', marginLeft: '20px' }}>Remarks
                                                <input type="text" style={{ borderRadius: "5px" }} name='remarks' onChange={(e) => handleChange([e.target.name], e.target.value)} defaultValue={value.notice2.remarks} placeholder="Remarks" required/>
                                            </label>
                                        </Typography>
                                        <button style={{ borderRadius: '5px', color: 'white', backgroundColor: '#0066cc', border: 'none', width: "150px", height: '40px', marginLeft: '250px', marginTop: '20px' }} >Update Details</button>
                                    </Typography>
                            }
                        </form>
                        {
                            console.log(value)
                        }
                    </Typography>
                </Box>
            </Modal>
        </div>
    )
}