import React from 'react';
import { Modal } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { useState } from 'react';
import { Add } from '@material-ui/icons';
import { misService } from '../../_services';

export const ColendingModal = () => {
    const [open, setOpen] = useState(false)
    const [file, setFile] = useState()

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleSelect = (e) => {
        setFile(e.target.files[0])
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData()
        data.append('file', file)


        for (var key of data.entries()) {
            console.log(key[0] + ', ' + key[1]);
        }

        await misService.uploadColendingCsv(data).then((response) => {
            window.alert('Colending details added successfully')
            window.location.reloand();
        })
        .catch((err)=> {
            window.alert('Some error occurred')
            console.log(err)
        })

    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        height: 300,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'scroll',
        //paddingBottom: '30px',
    };

    return (
        <>
            {/* <button style={{ borderRadius: '10px', height: '40px', width: '60px', marginLeft: '20px' }} onClick={handleOpen}><Add /></button> */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <a href="" style={{border:'1px solid blue',borderRadius:'5px', padding:'5px',marginTop: '50px'}} download> Download CSV Template </a>
                        <form id="#" style={{marginTop: '30px',  marginLeft: '30px', display: "flex", flexDirection: 'column', alignItems: 'center' }} onSubmit={handleSubmit} >
                            <input type="file" name='formData' accept='.csv' onChange={handleSelect} required />
                            <input type="submit"  value="Upload File" style={{ width: '300px', marginTop: '30px' }} />
                        </form>
                    </Typography>
                </Box>
            </Modal>
        </>
    )
}

