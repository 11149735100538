import { fetchWrapper } from "../_helpers/fetch-wrapper";
const apiUrl = process.env.REACT_APP_API_URL;

const baseUrl = `${apiUrl}/sanction`;


const getAll = () => {
    return fetchWrapper.get(baseUrl);
};


const create = (params) => {
    console.log("params is ",params)
    return fetchWrapper.post(`${baseUrl}/createsanctionletter`, params);
}

const update = (id, params) => {
    // console.log(id, params, "testtt")
    return fetchWrapper.post(`${baseUrl}/edit/letter/${id}`, params)
}

const getSingle = (id) => {
    return fetchWrapper.get(`${baseUrl}/letter/${id}`)
}

const getLetterByLoan = (params) => {
    return fetchWrapper.post(`${baseUrl}/letter-by-loan`, params)
}

const verifySanctionLetter=(params)=>{
    return fetchWrapper.post(`${baseUrl}/verifysanctionletter`,params)
}
const resetSanctionLetter=(params)=>{
    return fetchWrapper.post(`${baseUrl}/resetsanctionletter`,params)
}
export const sanction = {
    getAll,
    create,
    update,
    getSingle,
    getLetterByLoan,
    verifySanctionLetter,
    resetSanctionLetter
}