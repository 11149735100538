import React, { useState ,useEffect} from 'react';
import {
  Modal, Button, Typography, Grid, Paper, Box, makeStyles, Avatar
} from '@material-ui/core';
import { employeeService } from "../../../_services/employee.service";


const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '90%',
    maxWidth: 600,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    maxHeight: '80vh',
    overflowY: 'auto',
  },
  commentBubble: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1.5),
    borderRadius: 15,
    maxWidth: '80%',
    wordWrap: 'break-word',
    position: 'relative', // This allows the avatar to be positioned absolutely
  },
  userComment: {
    backgroundColor: theme.palette.primary.light,
    marginLeft: 'auto',
  },
  otherComment: {
    backgroundColor: theme.palette.grey[200],
    marginRight: 'auto',
  },
  metadata: {
    fontSize: '0.8em',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
  timestamp: {
    fontSize: '0.8em',
    color: theme.palette.text.secondary,
    position: 'absolute',
    bottom: -18,
    right: 10,
  },
  button: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    position: 'absolute',
    top: -theme.spacing(2), // Adjust this value to control how far outside the box the avatar appears
    left: theme.spacing(2),
    border: `2px solid ${theme.palette.background.paper}`, // Optional: Adds a border to make the avatar stand out
    backgroundColor: theme.palette.primary.main, // Optional: Avatar background color
  },
  employeeName: {
     color: theme.palette.text.primary,
     position: 'absolute',
    top: -theme.spacing(2), // Adjust this value to control how far outside the box the avatar appears
    left: theme.spacing(6),

    
  },
}));

 
function Comments({ data, emp_id }) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const user = employeeService.userValue;
  const [employeeList,setEmployeeList] =React.useState({});

  console.log('comment data',data)

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  async function getAllEmployees() {
    await employeeService.getAll().then((res) => {
      let list = {};
      res.map((e) => {
        list[e.emp_id] = e.first_name + " " + e.last_name;
      });
      setEmployeeList(list);
    });
  }
  useEffect(()=>{
    if(open){

      getAllEmployees()
    }
  },[open])
  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleOpen}>
          Comments
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="comments-modal-title"
      >
        <div className={classes.paper}>
          

          <Grid container spacing={2} direction="column">
            {data.map((comment, index) => (
              <Grid
                item
                key={index}
                container
                justifyContent={comment.emp_id === user.emp_id ? 'flex-end' : 'flex-start'}
              >
                <Paper
                  className={`${classes.commentBubble} ${comment.emp_id === user.emp_id ? classes.userComment : classes.otherComment}`}
                >
                 <Avatar className={classes.avatar}>
                     
                  </Avatar>
                  <Typography variant="body2" className={classes.employeeName}>
                  {employeeList[comment.emp_id] }
                  </Typography>
                  <Typography variant="body2" className={classes.metadata}>
                    Moved from: {comment.moved_from} | Moved to: {comment.moved_to}
                  </Typography>
                  
                  <Typography variant="body1">{comment.comment}</Typography>
                  <Typography className={classes.timestamp}>
                    {new Date(comment.createdAt).toLocaleString()}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>

          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" color="secondary" onClick={handleClose} className={classes.button}>
              Close
            </Button>
          </Box>
        </div>
      </Modal>
    </>
  );
}

export default Comments;
