import React from 'react';
import { useEffect, useState } from 'react';
import { Table, Thead, Tbody, Tr, Td, Th } from 'react-super-responsive-table';
import { AddOutlined } from '@material-ui/icons';
import CourtCaseServices from '../../../_services/courtcase.service';
import { NextStageModal } from './NextStageModal';
import datehelper from '../../../_helpers/datehelper';
import { ArbitrationStageModal } from './ArbitrationStageModal';
import { DocumentUploadModal } from './DocumentUploadModal';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

export const ArbitrationCaseList = (props) => {
    const history = useHistory()

    const [value, setValue] = useState({})

    async function GetAll() {
        await CourtCaseServices.GetAllCase({ case_type: 'arbitration' })
            .then(res => setValue(res))
            .catch(err => console.log(err))
    }

    useEffect(() => {
        GetAll()
    },
        [])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ display: 'flex', marginBottom: '30px', marginTop: '30px' }}>
                <h3>Arbitration</h3>
                {/* <button
                    style={{ border: '1 px solid gray', borderRadius: '5px', width: '50px', height: '30px', marginLeft: '13px' }}
                    onClick={() => props.history.push('/home/courtcases/addnew', { case_type: 'arbitration' })}>
                    <AddOutlined />
                </button> */}
            </div>
            <Table>
                <Thead>
                    <Tr>
                        <Th style={{ width: '10%' }}>Open Date</Th>
                        <Th style={{ width: '15%' }}>Party</Th>
                        <Th style={{ width: '10%' }}>Loan No</Th>
                        <Th style={{ width: '10%' }}>Case No</Th>
                        <Th style={{ width: '10%' }}>Loan</Th>
                        <Th style={{ width: '10%' }}>Claim</Th>
                        <Th style={{ width: '10%' }}></Th>
                        <Th style={{ width: '10%' }}></Th>
                        <Th style={{ width: '15%' }}></Th>
                        <Th style={{ width: '15%' }}></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        value && value.length > 0 ?
                            value.map(val => <Tr key={val._id}>
                                <Td>{datehelper.displayDate(new Date(val.case_open_date))}</Td>
                                <Td>{val.party_name}</Td>
                                <Td>{val.loan_no}</Td>
                                <Td>{val.case_no}</Td>
                                <Td>
                                    {val.loan.toLocaleString("en-IN", {
                                        maximumFractionDigits: 2,
                                        style: "currency",
                                        currency: "INR",
                                    })}
                                </Td>
                                <Td>
                                    {val.claim_amount.toLocaleString("en-IN", {
                                        maximumFractionDigits: 2,
                                        style: "currency",
                                        currency: "INR",
                                    })}
                                </Td>
                                <Td><DocumentUploadModal case={val}/></Td>
                                <Td><ArbitrationStageModal case={val} /></Td>
                                <Td><NextStageModal case={val} /></Td>
                                <Td><Button color='secondary' onClick={()=> history.push('/home/courtcases/addnew',{ case_type: 'arbitration', case:val })}>Edit</Button></Td>
                            </Tr>) :
                            <Tr>
                                <Td align='center' colSpan='8'>Data not available</Td>
                            </Tr>
                    }
                </Tbody>
            </Table>
            {
                console.log(value)
            }
        </div>
    )
}