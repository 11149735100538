
import React, { useState } from "react"
import { Modal} from "@material-ui/core"
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import { customerService } from "../../../../_services"
import { loanService } from '../../../../_services';
const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: "500px",
        maxHeight: "90vh", // Set maximum height
        overflowY: "auto"
    },
}));

export const PanOtpSendModal = (props) => {
    console.log("props is role", props)

    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

   

    const pan_no = props.customer && props.customer.general_information && props.customer.general_information.pan_no
    const name = props.customer && props.customer.general_information && props.customer.general_information.name
  
    const customer = props.customer
    
    const [verificationSuccessMessage, setVerificationSuccessMessage] = useState("");
    const [verificationFailedMessage, setVerificationFailedMessage] = useState("");
   
    function verifyid(itemid, token) {
        var info = {
            name: name,
            number: pan_no,
            fuzzy: "true",
        };
        fetch("https://signzy.tech/api/v2/snoops", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                service: "Identity",
                itemId: itemid,
                accessToken: token,
                task: "verification",
                essentials: info,
            }),
        })
            .then(async (response) => {
                const data = await response.json();
                // if (data) {
                //     kycVerification(response.ok);
                //   }
             
                 if (response.ok) {
                     kycVerification(true); // Call kycVerification with true if response.ok is true
                     setVerificationSuccessMessage('Pan Card Verified Success')
                 } else {
                    setVerificationFailedMessage(`Verification Failed, Check Pan No.`);
                    // alert("Verification Failed, Check Pan No."); 
        
                 }

            })
            .catch((err) => {
                console.log(err);
            });
    }




    const kycVerification = (params) => {
        console.log(params, "pdddddddddrams");
        loanService.kycVerification({ status: params, application_id: props.application_id, _id: customer._id, customer_id: customer.customer_id, role: props.role })
            .then((resp) => {
                console.log(resp, "gggggggggggggggggg");
                alert("Pan Card Verified Success");
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const fetchIdentity = async () => {
        setOpen(true);
        // console.log('process.env.REACT_APP_SIGNZY_API_KEY',process.env.REACT_APP_SIGNZY_API_KEY)
        var idname = "individualPan";
        var imgarr = [];

        fetch(
            "https://signzy.tech/api/v2/patrons/5f432a53a748d43a72a10fed/identities",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization:
                        process.env.REACT_APP_SIGNZY_API_KEY,
                },
                body: JSON.stringify({
                    type: idname,
                    callbackUrl: "https://samruddhi.kaabilfinance.com",
                    email: "dewanshu@kaabilfinance.com",
                    images: imgarr,
                }),
            }
        )
            .then(async (response) => {
                const data = await response.json();
                verifyid(data.id, data.accessToken);
                // const timer = setTimeout(() => {
                //     window.location.reload();
                //   }, 3000);
              
                //   return () => clearTimeout(timer);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    
    return (
        <div>
  {customer.general_information.karza_pan_verified ? 
             <span title="Verified">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-patch-check" viewBox="0 0 16 16" style={{color:"green",paddingLeft:"3px",cursor:"pointer"}}>
             <path fillRule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0"/>
             <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622-.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911z"/>
         </svg>
          </span>
            :
            <span onClick={() => fetchIdentity()}  title="Verify">  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" className="bi bi-patch-plus" viewBox="0 0 16 16" style={{ transform: 'rotate(45deg)',paddingLeft:"3px",cursor:"pointer"}}>
            <path fillRule="evenodd" d="M8 5.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 .5-.5"/>
            <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622-.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911z"/>
        </svg>
        </span>

            }


            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <button type="button" onClick={handleClose} style={{ float: "right", backgroundColor: "black", color: 'white' }}>X</button>
                        <h5 id="transition-modal-title" style={{ textAlign: "center", fontWeight: "bold", color: "blue" }}>Pan Card Verification</h5>
                        <div className="row justify-content-center" style={{ 'margin-top': '20px' }}>
                            <span style={{ color: 'green',fontSize:"20px" }}><b>{verificationSuccessMessage}</b></span>
                            <span style={{ color: 'red',fontSize:"20px"}}><b>{verificationFailedMessage}</b></span>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}
