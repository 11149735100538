import React from 'react'
import { Route, Switch } from "react-router-dom";
import InterestCalculator from './MIS/InterestCalculator';
import InterestCalculatorV2 from './MIS/InterestCalculatorV2';
import ColenderPayments from './ColenderPayments';
import PaymentList from "./PaymentList";
import { BranchSummary } from './BranchSummary';
import { LoanCloserRequestForm } from './LoanCloser/LoanCloserRequestForm';
import { LoanCloserRequest } from './LoanCloser/LoanCloserRequest';
import UpcomingEmiList from './UpcomingEmiList';
import DailyReportPrintVersion2 from './DailyReportPrintVersion2';
import LoanRenewForm from './MIS/LoanRenewForm';
import ViewLoanDetailsTabs from './MIS/ViewLoanDetailsTabs';
import LoanRenewRequest from './MIS/LoanRenewRequest.jsx';
import UpcomingWeekEmiList from './UpcomingWeekEmiList.jsx'
import DueEmiList from './DueEmiList';
const GoldIndex = ({match}) => {
    const {path} = match;
  return (
   
   <Switch>
     <Route path={`${path}/paymentlist`} component={PaymentList} />
     <Route path={`${path}/interestcalculator`} component={InterestCalculator} />
     <Route path={`${path}/interestcalculatorv2`} component={InterestCalculatorV2} />
     <Route path={`${path}/colenderpayments`} component={ColenderPayments}/>
     <Route path={`${path}/branchsummary`} component={BranchSummary}/>
     <Route path={`${path}/closeloan`} component={LoanCloserRequestForm}/>
     <Route path={`${path}/loancloserrequest`} component={LoanCloserRequest}/>
     <Route path={`${path}/upcomingemilist`} component={UpcomingEmiList}/>
     <Route path={`${path}/dailyreportversion2`} component={DailyReportPrintVersion2}/>
     <Route path={`${path}/loanrenewform`} component={LoanRenewForm}/>
     <Route path={`${path}/viewloandetails`} component={ViewLoanDetailsTabs}/>
     <Route path={`${path}/loanrenewrequest`} component={LoanRenewRequest}/>
     <Route path={`${path}/upcomingweekemilist`} component={UpcomingWeekEmiList}/>
     <Route path={`${path}/dueemilist`} component={DueEmiList}/>
    </Switch>
  )
}

export default GoldIndex;