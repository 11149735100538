import React from 'react'
import ApplicantTabBar from './ApplicantTabBar'
import CustomersTabBar from './CustomersTabBar'

export const ApplicantStage = () => {
  return (
    <div>
      <ApplicantTabBar/>
    </div>
  )
}
