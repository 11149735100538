import React from 'react'
import { letter } from "../../../_services/lettertracking.service";
import { useState, useEffect } from 'react';
import { Button } from "@material-ui/core";
import { Add, Edit } from "@material-ui/icons";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Link } from "react-router-dom";


const SettlementCretificate = () => {

  const [lrn, setLrn] = useState(null)



  const getsettlement = async () => {
    const data = await letter.getsettlement()
    setLrn(data)
  }

  useEffect(() => {
    getsettlement()

  }, [])

  return (

    <>
      <h2 style={{textAlign:"center"}}>Settlement Certificate Letters</h2><br />
      <Button style={{ marginLeft: "30px" }} variant="outlined" aria-label="increase" href={`/home/letters/settlement`}>
        <Add fontSize="small" />  Generate Settlement Certificate Letter
      </Button>
      <hr></hr><br />
      <div>
        <Table className="table table-striped">
          <Thead>
            <Tr>
              <Th style={{ width: "20%" }}>Name</Th>
              <Th style={{ width: "20%" }}>Account Number</Th>
              <Th style={{ width: "20%" }}>Sanction Amount</Th>
              <Th style={{ width: "30%" }}>Action</Th>
              
            </Tr>
          </Thead>
        </Table>
        {lrn && lrn.map((element) => {
          return <div key={element.account_number}>

<Table className="table table-hover">
          <Tbody>
           <Tr>
             <Td className="table-light" style={{width: "20%"}}>{element.name}</Td>
             <Td className="table-light" style={{width: "20%"}}>{element.account_number}</Td>
             <Td className="table-light" style={{width: "20%"}}>{element.deposite_amount}</Td>
             {/* <Td className="table-light" style={{width: "10%"}}><button className='btn btn-warning' onClick={handleOpen}>Details</button></Td> */}
             <Td className="table-light" style={{width: "30%"}}><Link to={`/home/letters/SettlementLetterPrint/${element._id}`}><button className='btn btn-success' style={{marginLeft:"1rem"}} >Print Letter</button></Link> <Link to={`/home/letters/SettlementEdit/${element._id}`} className="btn btn-sm btn-primary mr-4"><Edit /> </Link></Td>

            </Tr>
          </Tbody>
        </Table>
         
          </div>
        })}

      </div>


    </>
  )
}

export default SettlementCretificate