import React from "react";
import { Grid, Card, Button, CardActions, Select, MenuItem, FormControl,InputLabel,Box } from "@material-ui/core";
import { Edit,Add } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { loanService } from "../../_services/loan.service";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { employeeService } from "../../_services";
import roleChecker from "../../_helpers/rolechecker";
import { Role } from "../../_helpers";
import { Input } from "antd";
import EditStageModal from "./Applications/EditStageModal";
import datehelper from "../../_helpers/datehelper";
import LetterList from "./Letters/SBLLetterList";
import displayhelper from "../../_helpers/displayhelper";
import {LoanStages} from "../../constants/loanstages"

const { Search } = Input;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexGrow: 1,
  },
  card: {
    minHeight: 250,
    minWidth: 250,
  },
}));

export default function ApplicantsList({ match }) {
  const { path } = match;
  const classes = useStyles();
  const user= employeeService.userValue;
  const [applicants, setApplicants] = React.useState(null);
  const [spinner,setSpinner]= React.useState(true);
  const [page, setPage] = React.useState(0);
  const [searchFilter,setSearchFilter]=React.useState("");
  const [contactFilter,setContactFilter]=React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [stageList,setStageList]= React.useState([]);
  const [filterStage,setFilterStage]= React.useState(null);
  const [branchList,setBranchList]= React.useState([]);
  const [filterBranch,setFilterBranch]= React.useState(null);
  const [empList,setEmpList]= React.useState([]);
  const [filterEmp,setFilterEmp]= React.useState(null);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  React.useEffect(() => {
    getApplicants();
  }, [searchFilter,contactFilter,filterStage,filterBranch,filterEmp]);


  async function getApplicants() {
    setSpinner(true);
    var cust = await loanService.getAllPendingSummary();
    console.log(cust);
    let tmpStageList=[];
    let tmpBranchList=[];
    let tmpEmpList={};
    let tempLoans=cust.filter((l)=> {
      if(l.stage === "Discarded" || !l.stage)
      {
        return false;
      }
      if(!tmpStageList.includes(l.stage))
      {
        tmpStageList.push(l.stage);
      }
      if(!tmpBranchList.includes(l.branch_id))
      {
        tmpBranchList.push(l.branch_id);
      }
      if(l.next_stage_emp_id && !Object.keys(tmpEmpList).includes(l.next_stage_emp_id))
      {
        tmpEmpList[l.next_stage_emp_id]={'name':l.next_stage_employee,'emp_id':l.next_stage_emp_id};
      }

      if(searchFilter!="")
      {
        if(l.applicant_name && l.applicant_name.toLowerCase().includes(searchFilter.toLowerCase()))
        {
            return true;
        }
        if(l.application_id === searchFilter || l.application_id === parseInt(searchFilter) )
        {
            return true;
        }
        return false;
      }
      else if(contactFilter!="")
      {
        
        if(l.applicant_contact && (l.applicant_contact === contactFilter || l.applicant_contact === parseInt(contactFilter)) )
        {
            return true;
        }
        if(l.applicant_aadhar && (l.applicant_aadhar === contactFilter || l.applicant_aadhar === parseInt(contactFilter) ) )
        {
            return true;
        }
        return false;
      }
      else
      {
        return true;
      }
     });
     if(filterStage)
     {
      tempLoans=tempLoans.filter((l)=> {
        if(l.stage===filterStage)
        {
          return true;
        }
        return false;
      });
     }

     if(filterBranch)
     {
      tempLoans=tempLoans.filter((l)=> {
        if(l.branch_id===filterBranch)
        {
          return true;
        }
        return false;
      });
     }
     if(filterEmp)
     {
      tempLoans=tempLoans.filter((l)=> {
        if(l.next_stage_emp_id===filterEmp)
        {
          return true;
        }
        return false;
      });
     }


    // cust = cust.filter((entry) => entry.submitted == true);
    if (roleChecker.isHOLevelAccess(user.role))
    { 
         tempLoans=tempLoans;
    }
    else if(roleChecker.isRegionLevelAccess(user.role))
    {
         tempLoans=tempLoans.filter((applicant) => applicant.application_branch && (applicant.application_branch.branch_region === user.branch_id || (user.operation_branch).includes(applicant.application_branch.branch_region)));  
    
    }
    else if(roleChecker.isAreaLevelAccess(user.role))
    {
         tempLoans=tempLoans.filter((applicant) => applicant.application_branch && (applicant.application_branch.branch_area === user.branch_id || (user.operation_branch).includes(applicant.application_branch.branch_area)));  
    
    }
    else if(roleChecker.isClusterLevelAccess(user.role))
    {
         tempLoans=tempLoans.filter((applicant) => applicant.application_branch && (applicant.application_branch.branch_cluster === user.branch_id  || (user.operation_branch).includes(applicant.application_branch.branch_cluster)));  
    
    }
    else if(roleChecker.isBranchLevelAccess(user.role))
    {
        tempLoans=tempLoans.filter((applicant) => applicant.application_branch && (applicant.application_branch.branch_id === user.branch_id || (user.operation_branch).includes(applicant.application_branch.branch_id)));  
        console.log("applicant tempLoans ",tempLoans,user)
    }    
    else if(user.role === Role.Sales) {
        tempLoans=tempLoans.filter((applicant) => applicant.application_employee && applicant.application_employee.emp_id === user.emp_id);
    }
    else{
        tempLoans=null;
    }
    setApplicants(tempLoans);
    setSpinner(false);
    console.log(tmpStageList);
    setStageList(tmpStageList);
    setBranchList(tmpBranchList);
    setEmpList(tmpEmpList);
   
  }
  const refreshPage=()=> {
    getApplicants();
  }

  return (
    <div>
      {(roleChecker.isGMLevelAccess(user.role)) && (
          <h1>SBL Loans <Button variant="outlined" aria-label="increase" href={`${path}/add`}>
          <Add fontSize="small" /> New Loan
          </Button></h1> 
      )}
      <div className="d-flex justify-content-between">
      
       
       <Search
        onChange={(e) => setSearchFilter(e.target.value)}
        placeholder="Name/Application Id"
        enterButton
        style={{
          position: "sticky",
          top: "0",
          left: "0",
          width: "200px",
          marginTop: "2vh",
        }}
      />

<Search
        onChange={(e) => setContactFilter(e.target.value)}
        placeholder="Phone"
        enterButton
        style={{
          position: "sticky",
          top: "0",
          left: "0",
          width: "200px",
          marginTop: "2vh",
        }}
      />


<Box sx={{ minWidth: 120 }}>
       <FormControl fullWidth>

       <InputLabel id="filter_emp_label">Filter Emp</InputLabel>
       <Select
       labelId="filter_emp_label"
       name="empfilter"
       label="Filter Emp"
       value={filterEmp}
       onChange={(e) => setFilterEmp(e.target.value)}
       >
         <MenuItem value="" disabled>Filter Emp</MenuItem>
         {empList && Object.keys(empList).map((s)=> {
           return <MenuItem value={empList[s].emp_id}>{empList[s].emp_id+" : "+empList[s].name}</MenuItem>
         })}

       </Select>
       </FormControl>
       </Box>

<Box sx={{ minWidth: 120 }}>
       <FormControl fullWidth>

       <InputLabel id="filter_stage_label">Filter Branch</InputLabel>
       <Select
       labelId="filter_branch_label"
       name="branchfilter"
       label="Filter Branches"
       value={filterBranch}
       onChange={(e) => setFilterBranch(e.target.value)}
       >
         <MenuItem value="" disabled>Filter Branch</MenuItem>
         {branchList && branchList.map((s)=> {
           return <MenuItem value={s}>{s}</MenuItem>
         })}

       </Select>
       </FormControl>
       </Box>


<Box sx={{ minWidth: 120 }}>
       <FormControl fullWidth>
       <InputLabel id="filter_stage_label">Filter Stage</InputLabel>
       <Select
       labelId="filter_stage_label"
       name="stagefilter"
       label="Filter Stages"
       value={filterStage}
       onChange={(e) => setFilterStage(e.target.value)}
       >
         <MenuItem value="" disabled>Filter Stage</MenuItem>
         {stageList && stageList.map((s)=> {
           return <MenuItem value={s}>{s}</MenuItem>
         })}

       </Select>
       </FormControl>
       </Box>

      </div>
      <br></br>
      <TableContainer>
      <Table>
          <Thead>
            <Tr>
              <Th style={{ width: "5%" }}>
                  Date
              </Th>
               
              <Th style={{ width: "10%" }}>Application #</Th> 
              <Th style={{ width: "15%" }}>Customer Name</Th>
              <Th style={{ width: "15%" }}>Sanction Amount</Th>
              <Th style={{ width: "10%" }}>Branch Id</Th>
              <Th style={{ width: "10%" }}>Loan Stage</Th>
              <Th style={{ width: "10%" }}>Next Step</Th>
              <Th style={{ width: "10%" }}>Assigned To</Th>
              <Th style={{ width: "15%" }}>Assigned Since</Th>
              <Th style={{ width: "10%" }}>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {applicants && applicants.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((application,idx)=>(
              <Tr key={idx}><Td>{application.application_date.split("T")[0]}</Td><Td>{application.application_id} <br/>
                    
                  </Td>
              <Td>{application.applicant_name}
              </Td>
             <Td>
                {application.sanction_amount && displayhelper.displayNumberAccounting(application.sanction_amount)}
             </Td>
             <Td>{application.branch_id}</Td>
             <Td>{application.stage} 
              </Td>
              <Td>{application.sub_stage}</Td>
              <Td>{application.next_stage_employee}</Td>
              <Td>{datehelper.displayDate(new Date(application.stage_assign_date))}</Td>
              
              <Td>
             <Button component="a"
              href={`/home/sblloans/details/${application._id}`}
              size="small"
              color="primary"
            >
              View Details</Button>
             
              <br/>
              <LetterList id={application._id} stage={application.stage} />
              <br/>
              
             
              </Td></Tr>
            ))}
            {(!applicants || spinner) && (
              <Tr>
                <Td colSpan="9" className="text-center">
                  <span className="spinner-border spinner-border-lg align-center"></span>
                </Td>
              </Tr>
            )}
          </Tbody> 
          </Table>
      </TableContainer>
      {applicants ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={applicants.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        <div />
      )}
    </div>
  );
}
