import React from "react";

export default function JobSheetColumns() {
  return (
    <tr> 
      <th style={{ width: "5%" }} className="text-center">
        # 
      </th>
      <th style={{ width: "20%" }} className="text-center">
        Description of gold Ornaments 
      </th>
      <th style={{ width: "10%" }} className="text-center">
        No. of items
      </th> 
      <th style={{ width: "10%" }} className="text-center">
        Gross weight(Grams)
      </th>
      <th style={{ width: "10%" }} className="text-center">
        Deduction(Grams)
      </th>
      <th style={{ width: "10%" }} className="text-center">
        Net weight(Grams)
      </th>
      <th style={{ width: "10%" }} className="text-center">
        Advance Value Per Gram(₹)
      </th>
      <th style={{ width: "10%" }} className="text-center">
        Total Advance Value(₹)
      </th>
      <th style={{ width: "5%" }} />
    </tr>
  );
}
