import React, { useState,useEffect, useRef } from 'react'
import { employeeService } from '../../../../../_services';
import { Button } from '@material-ui/core';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { loanService } from '../../../../../_services';
import { adminservice } from '../../../../../_services/admin.services'; 
import roleChecker from '../../../../../_helpers/rolechecker';
import { Modal, Box, Typography } from "@material-ui/core";
import { Paper } from "@material-ui/core";
const LegalData = (props) => {
  const [openValuation,setOpenValuation]=useState(false)
const [valuators,setValuators]=useState([])
const formikRef=useRef()
const user = employeeService.userValue
 
   let cust = props.cust
    const handleOpen = () => {setOpenValuation(true); }
    const handleClose = () => setOpenValuation(false)
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        height: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000', 
        boxShadow: 24,
        p: 4,
        overflow: 'scroll',
    };
    useEffect(() => {
        const documentList = cust?.property_details?.document_list;
        console.log('documentList', documentList, formikRef);

        if (formikRef.current && documentList) {
            console.log('formikRef.current', formikRef.current);

            // Initialize propertyPaper as an array if it doesn't exist
            const existingPropertyPapers = formikRef.current.values.propertyPaper || [];

            // Collect all URLs of Property Paper documents
            const propertyPaperUrls = documentList
                .filter(element => element.document_type === "Property Paper" && element.document_url)
                .map(element => element.document_url);

            // Set the field value to the combined array of existing and new URLs
            formikRef.current.setFieldValue('propertyPaper', [...existingPropertyPapers, ...propertyPaperUrls]);
        }
    }, [formikRef.current, cust]);
    
    useEffect(()=>{
    if(openValuation){
       adminservice.getProviderDetails()
       .then(res=>{console.log("res",res);setValuators(res)})
       .catch(err=>console.log('err',err))
}},[openValuation])

const onSubmit=(values, actions) => {
    console.log('Form values:', values);
    loanService.initiateValuation(values)
    .then(res=>{console.log(res)
 
    window.alert(res.message)
 window.location.reload()

    })
    .catch(err=>console.log(err))
}

    console.log("valuators",valuators)
    const selectLegal = valuators.filter(val => val.type === "Legal");
  return (
    
     <div>
            {(roleChecker.isCreditTeamAccess(user.role) || roleChecker.isHOCreditTeamAccess(user.role) || roleChecker.isAdminAccess(user.role) ) &&<Button onClick={()=>{setOpenValuation(true)}} variant="outlined" color='secondary'>Initiate Legal</Button>}
            <Modal
                open={openValuation}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                    <Formik
                      innerRef={formikRef}
     initialValues={{
        applicationId:cust?.application_id,
        applicantName: cust?.applicant?.general_information?.name,
        applicantMobileNumber:  cust?.applicant?.general_information?.contact,
        applicantAddress:  cust?.applicant?.general_information?.address,
        providerName: '',
        providerEmail: '',
        reportType: '',
        propertyPaper: [],
        branch: cust?.branches?.length > 0 ? cust.branches[0].branch_name : '',
        valuationCharge:''
     }}
   

    onSubmit={onSubmit}
    validationSchema={yup.object().shape({
        applicationId: yup.string().required('Application ID is required'),
        applicantName: yup.string().required('Applicant name is required'),
        applicantMobileNumber: yup.string().required('Applicant mobile number is required'),
        applicantAddress: yup.string().required('Applicant address is required'),
        providerName: yup.string().required('Provider name is required'),
        providerEmail: yup.string().email('Invalid email format').required('Provider email is required'),
        reportType: yup.string().required('Report type is required'),
        propertyPaper: yup
  .array()
  .of(
    yup.string().url('Invalid URL format').required('Property paper link is required')
  )
  .required('Property paper links are required'),

      })}
>
    {({ errors, touched, isSubmitting, setFieldValue,values}) => {
        return (
            <Form>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around',gap: '1.5rem',margin: '1rem',padding: '1rem' }}>
                    <div style={{ width: '48%' }}>
                        <h6>Application ID</h6>
                        <Field
                            type="text"
                            name="applicationId"
                            className={`form-control${errors.applicationId && touched.applicationId ? ' is-invalid' : ''}`}
                            disabled
                        />
                        <ErrorMessage name="applicationId" component="div" className="invalid-feedback" />
                    </div>
                    <div style={{ width: '48%' }}>
                        <h6>Applicant Name</h6>
                        <Field
                            type="text"
                            name="applicantName"
                            className={`form-control${errors.applicantName && touched.applicantName ? ' is-invalid' : ''}`}
                            disabled
                        />
                        <ErrorMessage name="applicantName" component="div" className="invalid-feedback" />
                    </div>
                    <div style={{ width: '48%' }}>
                        <h6>Applicant Mobile Number</h6>
                        <Field
                            type="text"
                            name="applicantMobileNumber"
                            className={`form-control${errors.applicantMobileNumber && touched.applicantMobileNumber ? ' is-invalid' : ''}`}
                            disabled
                        />
                        <ErrorMessage name="applicantMobileNumber" component="div" className="invalid-feedback" />
                    </div>
                    <div style={{ width: '48%' }}>
                        <h6>Applicant Address</h6>
                        <Field
                            type="text"
                            name="applicantAddress"
                            className={`form-control${errors.applicantAddress && touched.applicantAddress ? ' is-invalid' : ''}`}
                            disabled
                        />
                        <ErrorMessage name="applicantAddress" component="div" className="invalid-feedback" />
                    </div>
                    <div style={{ width: '48%' }}>
                        <h6>Provider Name</h6>
                        <select
                              name="providerName"
                            className={`form-control${errors.providerName && touched.providerName ? ' is-invalid' : ''}`}
                             onChange={(e) => {
        const selectedValue = e.target.value.split('|');
     
        setFieldValue( 'providerName',selectedValue[0])
        setFieldValue( 'providerEmail',selectedValue[1])
        setFieldValue( 'reportType',selectedValue[2])
        setFieldValue( 'valuationCharge',selectedValue[3])
        setFieldValue('providerID',selectedValue[4])
         }}
                        >
                            <option value="">Select</option>
                            {selectLegal && selectLegal.map((val,ind)=>{
                                console.log('val',val)
                                return <option key={ind} value={`${val.provider_name}|${val.email}|${val.type}|${val.charges}|${val._id}`}>{val.provider_name}</option>
                            }) }
                        </select>
                        <ErrorMessage name="providerName" component="div" className="invalid-feedback" />
                    </div>
                    <div style={{ width: '48%' }}>
                        <h6>Provider Email</h6>
                        <Field
                            type="email"
                            name="providerEmail"
                            className={`form-control${errors.providerEmail && touched.providerEmail ? ' is-invalid' : ''}`}
                            disabled
                        />
                        <ErrorMessage name="providerEmail" component="div" className="invalid-feedback" />
                    </div>
                    <div style={{ width: '48%' }}>
                        <h6>Report Type</h6>
                        <Field
                            type="text"
                            name="reportType"
                            className={`form-control${errors.reportType && touched.reportType ? ' is-invalid' : ''}`}
                            disabled
                        />
                        <ErrorMessage name="reportType" component="div" className="invalid-feedback" />
                    </div>
                    <div style={{ width: '48%' }}>
                        <h6>Property Paper</h6>
                        {values.propertyPaper && values.propertyPaper.length > 0 ? (
                            values.propertyPaper.map((url, index) => (
                                <div key={index}>
                                    <Field
                                        as="a"
                                        href={url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={`form-control${errors.propertyPaper && touched.propertyPaper ? ' is-invalid' : ''}`}
                                    >
                                        Property Paper Link {index + 1} (View Here)
                                    </Field>
                                </div>
                            ))
                        ) : (
                            <Field
                                         type="text"
                            name="propertyPaper"
                                        
                                        className={`form-control${errors.propertyPaper && touched.propertyPaper ? ' is-invalid' : ''}`}
                                        disabled
                                    />
                        )}
                        <ErrorMessage name="propertyPaper" component="div" className="invalid-feedback" />
                    </div>

                   
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                    <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                        Submit
                    </Button>
                </div>
            </Form>
        );
    }}
</Formik>

                    </Typography>
                </Box>
            </Modal>

       
        
  </div>
  )
}

export default LegalData