import React, { useState } from 'react';
import {useParams,useHistory} from 'react-router-dom'
import { useLocation } from 'react-router-dom';
// Mock API functions
const searchPanApi = async (panDetails) => {
  console.log("PAN API Called with details:", panDetails);
  return { status: 'success', data: "PAN Search Response" };
};

const searchAddressApi = async (addressDetails) => {
  console.log("Address API Called with details:", addressDetails);
  return { status: 'success', data: "Address Search Response" };
};

const CersaiIndex = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [searchType, setSearchType] = useState('');
  const [userType, setUserType] = useState('');
  const [formData, setFormData] = useState({});
  const history = useHistory();
  const location = useLocation();
  const cersaiData = location.state?.cersaiData; // Using optional chaining
  

const hasRequiredData = cersaiData.valuationrculegaldata &&
                        cersaiData.sanction_amount &&
                        cersaiData.co_lending &&
                        cersaiData.valuationrculegaldata.some(data =>
                          data.valuation_report &&
                          data.type === "Valuation" &&
                          data.valuation_report.request_status === "Received"
                        );
const cersaiReports = cersaiData.cersai_reports;

let message = '';
let disableButton = false;

if (cersaiReports && cersaiReports.length > 0) {
  const lastReport = cersaiReports[cersaiReports.length - 1];
  message = `CERSAI SI is already created for this loan with the Asset ID ${lastReport.assetId} and Transaction ID ${lastReport.transactionId}.`;

  if (cersaiReports.length > 0) {
    disableButton = true; // Disable the button if there's more than one report
  }
}
  console.log(hasRequiredData,'CERSAI DATA:::', cersaiData);
  // Function to handle form submission
  const handleSubmit = async () => {
    let response;
    if (searchType === 'pan') {
      response = await searchPanApi(formData);
    } else if (searchType === 'address') {
      response = await searchAddressApi(formData);
    }
    console.log("API Response:", response);
  };

  // Function to handle dropdown and input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
    <div style={styles.container}>
      <button onClick={() => setShowModal(true)} style={styles.searchButton}>
        Search Existing Cersai Data
      </button>

      {showModal && (
        <div style={styles.modal}>
          <div style={styles.modalContent}>
            <h2 style={styles.modalHeader}>Search CERSAI Data</h2>
            
            {/* Search Type Dropdown */}
            <label style={styles.label}>Search Type:</label>
            <select value={searchType} onChange={(e) => setSearchType(e.target.value)} style={styles.dropdown}>
              <option value="">Select Search Type</option>
              <option value="pan">Search with USER PAN CARD</option>
              <option value="address">Search with Property ADDRESS</option>
            </select>

            {/* User Type Dropdown */}
            <label style={styles.label}>User Type:</label>
            <select value={userType} onChange={(e) => setUserType(e.target.value)} style={styles.dropdown}>
              <option value="">Select User Type</option>
              <option value="applicant">Applicant</option>
              <option value="co-applicant">Co-Applicant</option>
              <option value="guarantor">Guarantor</option>
            </select>

            {/* Conditional Form Fields based on Search Type */}
            {searchType === 'pan' && (
              <div style={styles.formGroup}>
                <h3 style={styles.subHeader}>Enter PAN Card Details</h3>
                <label style={styles.label}>Username:</label>
                <input type="text" name="username" onChange={handleChange} style={styles.input} />

                <label style={styles.label}>PAN Card Number:</label>
                <input type="text" name="panNumber" onChange={handleChange} style={styles.input} />
              </div>
            )}

            {searchType === 'address' && (
              <div style={styles.formGroup}>
                <h3 style={styles.subHeader}>Property Details</h3>
                <div style={styles.flexContainer}>
                  <div style={styles.flexItem}>
                    <label style={styles.label}>Plot Number:</label>
                    <input type="text" name="plotNumber" onChange={handleChange} style={styles.input} />

                    <label style={styles.label}>Area:</label>
                    <input type="text" name="area" onChange={handleChange} style={styles.input} />

                    <label style={styles.label}>City:</label>
                    <input type="text" name="city" onChange={handleChange} style={styles.input} />

                    <label style={styles.label}>Address:</label>
                    <input type="text" name="address" onChange={handleChange} style={styles.input} />
                  </div>
                  <div style={styles.flexItem}>
                    <label style={styles.label}>Landline:</label>
                    <input type="text" name="landline" onChange={handleChange} style={styles.input} />

                    <label style={styles.label}>Pincode:</label>
                    <input type="text" name="pincode" onChange={handleChange} style={styles.input} />

                    <label style={styles.label}>Tehsil:</label>
                    <input type="text" name="tehsil" onChange={handleChange} style={styles.input} />

                    <label style={styles.label}>District:</label>
                    <input type="text" name="district" onChange={handleChange} style={styles.input} />

                    <label style={styles.label}>State:</label>
                    <input type="text" name="state" onChange={handleChange} style={styles.input} />
                  </div>
                </div>
              </div>
            )}

            {/* Submit Button */}
            <button onClick={handleSubmit} style={styles.submitButton}>SEND REQUEST</button>

            {/* Close Modal */}
            <button onClick={() => setShowModal(false)} style={styles.closeButton}>Close</button>
          </div>
        </div>
      )}
    </div>
        {/* <div style={styles.container}>
        <button onClick={() => history.push('/home/cersaisiregistration',{cersaiData})} style={styles.searchButton}>
        Create SI Registration
        </button>
        </div> */}
        {/* <div style={styles.container}>
            <button 
              onClick={() => {
                // if (hasRequiredData && cersaiData) { // Check if cersaiData is valid
                  history.push('/home/cersaisiregistration', { cersaiData });
                // }
              }} 
              style={styles.searchButton} 
              // disabled={!hasRequiredData}
            >
              Create SI Registration
            </button>

            {!hasRequiredData && (
              <div style={{ color: 'red', marginTop: '10px' }}>
                <h3>Note: Before creating the CERSAI charge, please check the Valuation Report and Co-Lender details.</h3>
              </div>
            )}
          </div> */}
        <div style={styles.container}>
          <button 
            onClick={() => {
              // if (hasRequiredData && cersaiData) { // Check if cersaiData is valid
                history.push('/home/cersaisiregistration', { cersaiData });
              // }
            }} 
            style={styles.searchButton} 
            disabled={disableButton} // Disable the button based on the condition
          >
            Create SI Registration
          </button>

          {!disableButton && !hasRequiredData && (
            <div style={{ color: 'red', marginTop: '10px' }}>
              <h3>Note: Before creating the CERSAI charge, please check the Valuation Report and Co-Lender details.</h3>
            </div>
          )}


          {message && (
            <div style={{ color: 'blue', marginTop: '10px' }}>
              <h3>Note:{message}</h3>
            </div>
          )}
        </div>


    </>
  );
};

// Inline Styles for Better UI/UX
const styles = {
  container: {
    textAlign: 'center',
    marginTop: '50px',
  },
  searchButton: {
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    fontSize: '18px',
    fontWeight: 'bold',
    borderRadius: '5px',
    cursor: 'pointer',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'background-color 0.3s ease',
  },
  modal: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    width: '800px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
    position: 'relative',
  },
  modalHeader: {
    textAlign: 'center',
    marginBottom: '20px',
    color: '#333',
    fontSize: '24px',
  },
  subHeader: {
    color: '#4CAF50',
    marginBottom: '10px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    color: '#333',
    fontWeight: 'bold',
    textAlign:'left'
  },
  dropdown: {
    width: '100%',
    padding: '10px',
    marginBottom: '20px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    fontSize: '16px',
  },
  input: {
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    fontSize: '16px',
  },
  formGroup: {
    marginBottom: '20px',
  },
  submitButton: {
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    fontWeight: 'bold',
    cursor: 'pointer',
    width: '100%',
  },
  closeButton: {
    backgroundColor: 'red',
    color: 'white',
    padding: '5px 10px',
    border: 'none',
    borderRadius: '5px',
    fontWeight: 'bold',
    cursor: 'pointer',
    marginTop: '10px',
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',
  },
  flexItem: {
    flex: '1',
  },
};

export default CersaiIndex;
