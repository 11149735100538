import { Box, Button, Modal } from '@material-ui/core'
import React, { useState } from 'react'
import { history } from '../../../_helpers';
import { employeeService, loanService } from '../../../_services'
import { goldPacketServices } from '../../../_services/gold_packet.service';

export const OtpVerificationModal = (props) => {
    const style = {
        position: "absolute",
        top: "45%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 500,
        height: 250,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
        margin: "30px",
    };
    const [otpResponse, setOtpResponse] = useState({})
    const [sentOtp, setSentOtp] = useState(false)
    const [open, setOpen] = useState(false)
    const [OtpValue, setOtpValue] = React.useState({})
    const emp_contact = employeeService.userValue.contact
    const date = Date.now()
    const user = employeeService.userValue.emp_id
    let approvePacket = props.approvePacket
    let acceptedPacket = props.acceptPackets
    console.log("Test aprrove packet", approvePacket)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    let otp = Math.floor(1000 + Math.random() * 9000);
    const handleChangeOtp = (e) => {
        OtpValue[e.target.name] = e.target.value
        setOtpValue(OtpValue)
    }
    const sendOtp = () => {
        // console.log("test otp",otp)
        loanService.sendOtp({ contact: emp_contact, otp: otp })
            .then((data) => {
                setOtpResponse(data.otp)
                setSentOtp(true)
            })
            .catch((err) => console.log(err))
    } 

    const acceptCheckIn = async () => {
        await goldPacketServices.acceptCheckInPacket({
            accept: {
                ids: props.selectPacket,
                checkIn_details: {accept_by_emp_id: user, accepter_mobile_no: emp_contact, otp: otpResponse, date: date, checkin_status: "Accepted" }
            }
        })
            .then((res) => {
                alert("Check In Request Accepted")
                checkInAcceptPacketPrint()
                // window.location.reload()
            })
            .catch((err) => alert(err))
    }
    const saveCheckOutData = async () => {
        const approveId = props.approve
        const checkOutData = approvePacket.map(data => {
            return {
                name: data.name, loan_no: data.loan_no,
                packet_no: data.packet_no, photo: data.photo,
                packet_state: data.packet_state,
                checkIn_details: {
                    checkin_by_emp_id: data.checkIn_details.checkin_by_emp_id,
                    accept_by_emp_id: data.checkIn_details.accept_by_emp_id,
                    accepter_mobile_no: data.checkIn_details.accepter_mobile_no,
                    otp: data.checkIn_details.otp,
                    checkin_status: data.checkIn_details.checkin_status,
                    date: data.checkIn_details.date
                },
                checkOut_details: {
                    checkout_requested_by: data.checkout_requested_by,
                    approved_by_emp_id: user,
                    check_out_requested_remark:data.check_out_requested_remark,
                    approver_mobile_no: emp_contact,
                    otp: otpResponse,
                    checkout_status: "Approved",
                    date: date
                }
            }
        })
        await goldPacketServices.saveCheckOutData({ checkOutData, approveId })
            .then((res) => {
                alert("Selected Packet Check Out")
                checkoutPacketPrint()
            })
            .catch((err) => alert(err))
    }
    const checkoutPacketPrint = () => {
        history.push("/home/goldpacket/checkoutprint", {
            approvePacket,
            emp_contact,
            otpResponse
        })
    }
    const checkInAcceptPacketPrint = () => {
        history.push("/home/goldpacket/checkinacceptprint", {
            acceptedPacket,
            emp_contact,
            otpResponse
        })
    }
    const verifyOtp = () => {
        if (OtpValue.otp == otpResponse) {
            // alert("Otp verified")
            setSentOtp(false)
            props.selectPacket && acceptCheckIn()
            props.approvePacket && saveCheckOutData()
        } else {
            alert("Incorrect Otp")
        }
    }
    return (
        <div>
            <Button style={{ textAlign: "center", color: "white", background: "blue", marginTop: "30px", marginLeft: '255px', width: "100px" }} onClick={() => (emp_contact ? (sendOtp(), handleOpen())
                : alert("Mobile Number Not Valid"))}>Submit</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{ marginLeft: "20px" }}>
                        <h5 style={{ textAlign: "center" }}>Verify Otp</h5>
                        <hr />
                        <b style={{ fontSize: "18px" }}>Otp sent to Mobile No:{emp_contact}</b>
                        {
                            sentOtp === true ? <div>
                                <input type='number' name='otp' onChange={handleChangeOtp} placeholder="Enter the Otp"
                                    style={{ width: "140px", borderRadius: "5px", marginTop: "30px" }} />
                                <Button style={{ marginLeft: "10px", width: "70px", height: "27px", backgroundColor: "blue", color: "white", border: "none", borderRadius: "5px" }} onClick={verifyOtp}>Submit</Button>
                                <Button style={{ marginLeft: "10px", width: "120px", height: "27px", backgroundColor: "red", color: "white", border: "none", borderRadius: "5px" }} onClick={sendOtp}>Resend Otp</Button>
                            </div>
                                : null
                        }
                        <br />
                    </div>
                </Box>
            </Modal>
        </div>
    )
}
