import React from "react"
import { useEffect, useState } from "react"
import { ledgerService } from "../../../_services/ledger.service"
import { Table, Tbody, Thead, Tr, Td, Th } from "react-super-responsive-table"
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import {Button} from '@material-ui/core'
export default function BankLedgerTransactionHistory(props) {

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  
  console.log("test ledger", props)
  const ledgerDetails = props.location.state.ledgerDetails
  console.log('ledger details is', ledgerDetails)
  const ledger_id = ledgerDetails._id
  const [ledgerTransaction, setLedgerTransaction] = useState([])
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [spinner, setSpinner] = useState(false);

  const getBankLedgerTransactions = (ledger_id) => {
    setSpinner(true)
    ledgerService.getBankLedgerTransactions(ledger_id)
      .then((res) => {
        setLedgerTransaction(res)
        console.log(res)
        setSpinner(false)
      })
      .catch((err) => {console.log(err); setSpinner(false)})
  }

const searchByDateForStatement=( )=>{
  setSpinner(true)
 console.log('{startDate,endDate,ledger_id}',{startDate,endDate,ledger_id})
  ledgerService.searchByDateForStatement({startDate,endDate,ledger_id})
  .then(res=>{setLedgerTransaction(res);console.log('search by date ',res);  setSpinner(false)
  })
  .catch(err=>{console.log(err); setSpinner(false)})
 

}

  useEffect(() => {
    if (ledger_id) {
      getBankLedgerTransactions(ledger_id)
    }
  }, [])

  console.log(startDate,endDate)
  return (
    <div style={{ margin: '40px' }}>
      <Table style={{ width: '400px', margin: 'auto', marginTop: '20px', marginBottom: '40px' }}>
        <Thead>
          <Tr>
            <Th style={{ border: '1px solid black' }}>Ledger Name</Th>
            <Th style={{ border: '1px solid black' }}>Branch Id</Th>
            <Th style={{ border: '1px solid black' }}>Current ledeger Balance</Th>

          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td style={{ border: '1px solid black' }}>{ledgerDetails.ledger_name}</Td>
            <Td style={{ border: '1px solid black' }}>{ledgerDetails.branch_id}</Td>
            <Td style={{ border: '1px solid black' }}>{ledgerDetails.current_balance.toLocaleString("en-IN", {
              // maximumFractionDigits: 2,
              style: "currency",
              currency: "INR",
            })}</Td>
          </Tr>
        </Tbody>
      </Table>
      <>
      <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>


                        <h5>Transactions</h5>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center', gap: '2rem'
                        }} >
                            <div>

                                <label htmlFor="startDate">Start Date: &nbsp;</label>
                                <input
                                    type="date"
                                    id="startDate"
                                    value={startDate ? startDate.toISOString().substr(0, 10) : ''}
                                    onChange={(e) => setStartDate(new Date(e.target.value))}
                                    style={{
                                        border: '2px solid navy',
                                        fontFamily: 'inherit',
                                        fontVariant: 'all-petite-caps',
                                    }}
                                />
                            </div>
                            <div>

                                <label htmlFor="endDate">End Date: &nbsp;</label>
                                <input
                                    type="date"
                                    id="endDate"
                                    value={endDate ? endDate.toISOString().substr(0, 10) : ''}
                                    onChange={(e) => setEndDate(new Date(e.target.value))}
                                    
                                    disabled={!startDate} // Disable the end date input until a start date is selected
                                    style={{
                                        border: '2px solid navy',
                                        fontFamily: 'inherit',
                                        fontVariant: 'all-petite-caps',
                                    }}
                                />
                            </div>
                            {startDate && endDate && <div>

                            <Button color='primary' variant='outlined' onClick={()=>searchByDateForStatement( )}>Search</Button>
                            </div>}
                        </div>
                    </div>
      </>
       
     
      
      <TableContainer>
      <Table style={{ textAlign: 'center' }}>
        <Thead>
          <Tr>
            <Th style={{ textAlign: 'center', width:"10%"}}>Sr.No.</Th>
            <Th style={{ textAlign: 'center' ,width:"10%"}}>Date</Th>
            <Th style={{ textAlign: 'center', width:"10%"}}>Status</Th>
            <Th style={{ textAlign: 'center' ,width:"10%"}}>Amount</Th>
            <Th style={{ textAlign: 'center',width: '40%' }}>Remark</Th>
          </Tr>
        </Thead>
        {!spinner ? <Tbody>
          {ledgerTransaction.length > 0 && ledgerTransaction.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((x, idx) => <Tr key={idx}>
            <Td>{idx + 1}</Td>
            <Td>{new Date(x.date).toLocaleString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true, timeZone: 'UTC' }).replace(',', '').replace(/\//g, '-')}</Td>
            <Td>{x.credit_ledger_id ? <p style={{ color: 'green' }}>Credit</p> : <p style={{ color: 'red' }}>Debit</p>}</Td>
            <Td>{x.transaction_amount.toLocaleString("en-IN", { style: "currency", currency: "INR",minimumFractionDigits: 0 })}</Td>
            <Td>{x.remark}</Td>
          </Tr>)}
        </Tbody> :   <span className="spinner-border spinner-border-sm"></span> 
        }
      </Table>
      </TableContainer>
      {ledgerTransaction ? (
        <TablePagination
          rowsPerPageOptions={[100, 200, 500]}
          component="div"
          count={ledgerTransaction.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        <div />
      )}

    </div>
  )
}
