import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { Table, Th, Thead, Td, Tr, Tbody } from 'react-super-responsive-table'
import datehelper from '../../../_helpers/datehelper'

const DepositSlip = (props) => {
    console.log("Test props", props)
    let data = props.location.state.slip_data
    let slip_number = props.location.state.slip_number
    let slipDate=props.location.state.createdAt
    let depositSlipRef = useRef()
    const handleDepositprint = useReactToPrint({
        content: () => depositSlipRef.current
    })
    let totalAmount=data.reduce((curr, acc) => curr + acc.payment, 0)
    return (
        <div>
            <div style={{ textAlign: "center" }}>
                <button onClick={() => handleDepositprint()}>
                    Print Deposit Memo
                </button>
            </div>
            <div
                ref={depositSlipRef}
                style={{
                    width: "278mm",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: '50px',
                    bgcolor: "background.paper",
                    padding: 45,
                    background: "white"
                }}
            >
                <h3 style={{ textAlign: 'center', textDecoration: "underLine" }}>Deposit Memo</h3>
                <h5 style={{ display: 'flex', float: "right" }}>Slip Number:{slip_number}</h5>
                <h3>Transaction</h3>
                <h5>Date:{slipDate?datehelper.displayDate(new Date(slipDate)):datehelper.displayDate(new Date())}</h5>
                <br />
                <div>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th style={{ border: "1px solid " }}>Sr.No</Th>
                                <Th style={{ border: "1px solid " }}>Loan No</Th>
                                <Th style={{ border: "1px solid " }}>Name</Th>
                                <Th style={{ border: "1px solid " }}>Branch</Th>
                                <Th style={{ border: "1px solid " }}>Amount</Th>
                                <Th style={{ border: "1px solid " }}>Receipt No</Th>
                                <Th style={{ border: "1px solid " }}>Transaction Date</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {data.length > 0 && data.map((x, idx) => <Tr>
                                <Td style={{ border: "1px solid " }}>{idx + 1}</Td>
                                <Td style={{ border: "1px solid " }}>{x.loan_no}</Td>
                                <Td style={{ border: "1px solid " }}>{x.cust_name}</Td>
                                <Td style={{ border: "1px solid " }}>{x.customerData[0].branch_id}</Td>
                                <Td style={{ border: "1px solid " }}>{x.payment}</Td>
                                <Td style={{ border: "1px solid " }}>{x.receipt_no}</Td>
                                <Td style={{ border: "1px solid " }}>{datehelper.displayDate(new Date(x.date))}</Td>
                            </Tr>)}
                        </Tbody>
                        <Tr style={{ border: "1px solid " }}>
                            <Td colspan="4" style={{ textAlign: "center", fontWeight: "bold", border: "1px solid " }}>Total Amount</Td>
                            <Td style={{ border: "1px solid ",fontWeight: "bold" }}>{totalAmount}</Td>
                        </Tr>
                    </Table>
                </div>
            </div>
        </div>
    )
}

export default DepositSlip
