import React, { useEffect, useState } from 'react'
import { Table, Tbody, Th, Tr, Td, Thead } from 'react-super-responsive-table'
import CourtCaseServices from '../../../_services/courtcase.service';
import datehelper from '../../../_helpers/datehelper';
import { useHistory } from 'react-router-dom';
import ViewExecutionDocument from './ViewExecutionDocument';
const ExecutionCaseList = (props) => {
    const history = useHistory()
    const [value, setValue] = useState({})
    async function GetAll() {
        await CourtCaseServices.GetAllCase({ case_type: 'arbitration' })
            .then(res => setValue(res))
            .catch(err => console.log(err))
    }
    useEffect(() => {
        GetAll()
    },
        [])
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',margin:'40px' }}>
            <div style={{ display: 'flex', marginBottom: '30px', marginTop: '30px' }}>
                <h3>Execution</h3>
            </div>
            <Table>
                <Thead>
                    <Tr>
                        <Th style={{ width: '10%' }}>Open Date</Th>
                        <Th style={{ width: '15%' }}>Party</Th>
                        <Th style={{ width: '10%' }}>Loan No</Th>
                        <Th style={{ width: '10%' }}>Case No</Th>
                        <Th style={{ width: '10%' }}>Loan</Th>
                        <Th style={{ width: '10%' }}>Claim</Th>
                        <Th style={{ width: '10%' }}>Document</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        value && value.length > 0 ?
                            value.filter(x => x.stages.length > 0 && x.stages[x.stages.length - 1].stage === "Execution").map(val => <Tr key={val._id}>
                                <Td>{datehelper.displayDate(new Date(val.case_open_date))}</Td>
                                <Td>{val.party_name}</Td>
                                <Td>{val.loan_no}</Td>
                                <Td>{val.case_no}</Td>
                                <Td>
                                    {val.loan.toLocaleString("en-IN", {
                                        maximumFractionDigits: 2,
                                        style: "currency",
                                        currency: "INR",
                                    })}
                                </Td>
                                <Td>
                                    {val.claim_amount.toLocaleString("en-IN", {
                                        maximumFractionDigits: 2,
                                        style: "currency",
                                        currency: "INR",
                                    })}
                                </Td>
                                <Td>
                                    <ViewExecutionDocument documents={val.documents} />
                                </Td>
                            </Tr>) :
                            <Tr>
                                <Td align='center' colSpan='8'>Data not available</Td>
                            </Tr>
                    }
                </Tbody>
            </Table>
            {
                console.log("test value", value)
            }
        </div>
    )
}

export default ExecutionCaseList
