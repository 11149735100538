import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Typography, Paper, Button } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import datehelper from '../../../_helpers/datehelper';
import Save from '@material-ui/icons/Save';
import CourtCaseServices from '../../../_services/courtcase.service';

export const HearingForm = (props) => {
    let CourtCase = props.case;
    let hearings = CourtCase.hearings
    let ndate = new Date()


    const [newHearing, setNewHearing] = useState([...hearings])

    const addArray = () => {
        let arr = [...newHearing, { hearing_date: new Date(), lawyer_name: '', judge_name: '', party_lawyer: '', case_manager_name: '', penal_codes: '', judgement: '' }]
        setNewHearing(arr)
    }

    const removeArray = (e, idx) => {
        let arr = [...newHearing]
        console.log(idx, arr)
        arr.splice(idx, 1)
        setNewHearing(arr)
    }

    const handleChange = (e, idx) => {
        newHearing[idx][e.target.name] = e.target.value
        setNewHearing(newHearing)
    }

    const HearingDetailsUpdation = async (e) => {
        e.preventDefault();
        await CourtCaseServices.UpdateCase(CourtCase._id, { hearings: newHearing })
            .then(res => {
                window.alert('Details saved successfully')
                window.location.reload()
            })
            .catch(err => {
                window.alert('Some error occurred')
                console.log(err)
            })
    }

    return (
        <div>
            <form onSubmit={(e) => { e.stopPropagation(); HearingDetailsUpdation(e) }} id='HearingForm'>
                {
                    newHearing && newHearing.length > 0 ?
                        newHearing.map((m, idx) => {
                            return <Paper style={{ marginTop: '20px' }} key={m._id ? m._id : m.hearing_date}>
                                <span style={{ marginLeft: '5px', fontSize: 'large' }}>{idx + 1}.</span>
                                <Typography style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }} key={m.hearing_date} >
                                    <label style={{ width: '200px', display: 'flex', flexDirection: 'column' }}>
                                        Hearing Date
                                        <input type="date" name='hearing_date' style={{ border: '.25px solid grey' }} max={datehelper.getDateInputString(ndate)} onChange={(e) => handleChange(e, idx)} defaultValue={datehelper.getDateInputString(new Date(m.hearing_date))} required />
                                    </label>

                                    <label style={{ width: '200px', display: 'flex', flexDirection: 'column' }}>
                                        Lawyer
                                        <input type="text" name='lawyer_name' style={{ border: '.25px solid grey' }} onChange={(e) => handleChange(e, idx)} defaultValue={m.lawyer_name} required />
                                    </label>

                                    <label style={{ width: '200px', display: 'flex', flexDirection: 'column' }}>
                                        Judge
                                        <input type="text" name='judge_name' style={{ border: '.25px solid grey' }} defaultValue={m.judge_name} onChange={(e) => handleChange(e, idx)} required />
                                    </label>

                                    <label style={{ width: '200px', display: 'flex', flexDirection: 'column' }}>
                                        Party Lawyer
                                        <input type="text" name='party_lawyer' style={{ border: '.25px solid grey' }} defaultValue={m.party_lawyer} onChange={(e) => handleChange(e, idx)} />
                                    </label>

                                    <label style={{ width: '200px', display: 'flex', flexDirection: 'column' }}>
                                        Case Manager
                                        <input type="text" name='case_manager_name' style={{ border: '.25px solid grey' }} defaultValue={m.case_manager_name} onChange={(e) => handleChange(e, idx)} required />
                                    </label>

                                    <label style={{ width: '200px', display: 'flex', flexDirection: 'column' }}>
                                        Penal codes
                                        <input type="text" name='penal_codes' style={{ border: '.25px solid grey' }} defaultValue={m.penal_codes} onChange={(e) => handleChange(e, idx)} />
                                    </label>

                                    <label style={{ width: '200px', display: 'flex', flexDirection: 'column' }}>
                                        Judgement
                                        <input type="text" name='judgement' style={{ border: '.25px solid grey' }} defaultValue={m.judgement} onChange={(e) => handleChange(e, idx)} required />
                                    </label>
                                </Typography>
                                <Typography style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button type='button' onClick={(e) => removeArray(e, idx)}><Delete /></Button>
                                </Typography>
                            </Paper>
                        }) :
                        null
                }
                <Paper style={{ width: '63px', margin: 'auto', marginTop: '20px' }}>
                    <Button type='button' onClick={() => { addArray() }}>
                        Add
                    </Button>
                </Paper>
                <hr style={{ border: '1px solid grey' }} />
                <Paper style={{ width: '63px', height: '63px', margin: 'auto', marginTop: '10px', paddingTop: '12.5px', borderRadius: '70px' }}>
                    <Button type='submit' style={{ color: 'blue' }}>
                        <Save />
                    </Button>
                </Paper>
            </form>
            {
                console.log(newHearing)
            }
        </div>
    )

}