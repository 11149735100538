import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Notice1 } from './Notice1';
import { Notice2 } from './Notice2';
import { Publication } from './Publication';
import { AuctionList } from './AuctionList';
import ReminderLetterPreview from './ReminderLetterPreview';
import { BulkSubmissionList } from './BulkSubmissionList';
import { PublicationCsvUpload } from './PublicationCsvUpload';
import { BulkReleaseList } from './BulkReleaseList';
import { SubmissionList } from './SubmissionList';
import { ReleaseLetterList } from './ReleaseLetterList';
const GoldLetterIndex =({match})=>{
    const {path}=match
    return(
        <>
        <Switch>
            <Route path={`${path}/notice1`} component={Notice1} />
            <Route path={`${path}/notice2`} component={Notice2} />
            <Route path={`${path}/publication`} component={Publication} />
            <Route path={`${path}/auctionlist`} component={AuctionList} />
            <Route path={`${path}/reminderletterpreview`} component={ReminderLetterPreview} />
            <Route path={`${path}/bulksubmissionletter`} component={BulkSubmissionList}/>
            <Route path={`${path}/publicationupload`} component={PublicationCsvUpload} />
            <Route path={`${path}/bulkreleaseletter`} component={BulkReleaseList} />
            <Route path={`${path}/submissionletter`} component={SubmissionList} />
            <Route path={`${path}/releaseletter`} component={ReleaseLetterList} />
        </Switch>
        </>
    )
}

export default GoldLetterIndex;