import React from "react";
import roleChecker from "../../_helpers/rolechecker";
import { employeeService } from "../../_services";
import "./Collection.css";
import CollectionList from "./CollectionList";
import ImportLoans from "./ImportLoans";
import SearchLoans from "./SearchLoans";

export default function Collection() {
  const user = employeeService.userValue;

  return (
    <div>
      {!roleChecker.isCollectionTeam(user.role) ? (
        <div>
          {roleChecker.isAdminAccess(user.role) && (
            <ImportLoans></ImportLoans>
          )}
          <br></br>
          <CollectionList></CollectionList>
        </div>
      ) : (
        <SearchLoans></SearchLoans>
      )}
    </div>
  );
}
