import React from 'react'
import { employeeService } from '../../../../_services'
import roleChecker from '../../../../_helpers/rolechecker'
import InsuranceDetails from './InsuranceDetails';



const InsuranceDetailTab = (props) => {

    const user = employeeService.userValue;
    const cust = props.cust

  return (
    <div>
        <InsuranceDetails cust={cust} />
    </div>
  )
}

export default InsuranceDetailTab