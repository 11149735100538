import React from 'react'

const Alerts = (props) => {
  return (
<div className="alert alert-danger alert-dismissible fade show" role="alert">
  <strong>Error!!</strong> {props.message}
  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>
  )
}

export default Alerts