import React from "react";
import { Route, Switch } from "react-router-dom";
import {BankAccountModal} from "./BankAccountModal";
import { BankList } from "./BankList";

export const BankIndex = ({ match }) => {
    const { path } = match
    return (
        <div>
            <Switch>
                <Route exact path={path} component={BankList} />
                <Route path={`${path}/bankaccountmodal`} component={BankAccountModal} />
            </Switch>
        </div>
    )
}