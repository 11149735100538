import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import FormElement from "../../uielements/FormElement";
import * as Yup from "yup";

import { Formik, Field, Form, ErrorMessage } from "formik";

import { Button } from "@material-ui/core";
import { enachService } from "../../../_services/enach.service";

Date.prototype.yyyymmdd = function () {
  var mm = this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate();

  return [
    this.getFullYear(),
    (mm > 9 ? "" : "0") + mm,
    (dd > 9 ? "" : "0") + dd,
  ].join("");
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    // width:"75%",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function AddEnachModal({ enachData, setEnachData,customer }) {
  const classes=useStyles();
  const [open, setOpen] = React.useState(false);
const data = customer;
console.log('data of au bank is',data)
  var date = new Date();
  const bankList={
    'AUBL':'AU Bank',
    'SBIN':'State Bank of India',
    'ICIC':'ICICI Bank',
    'HDFC':'HDFC Bank',
    'BARB':'Bank of Baroda',
    'CNRB':'Canara bank',
    'CBIN':'Central Bank of India',
    'BKID':'Bank of India',
    'YESB':'Yes bank',
    'UTIB':'Axis Bank',
    'UBIN':'Union Bank of India',
    'RATN':'RBL Bank',
    'PUNB':'Punjab National Bank',
    'MAHB':'Bank of Maharasthra',
    'KKBK':'Kotak Mahindra Bank',
    'INDB':'IndusInd Bank',
    'IDFB':'IDFC First Bank',
    'FDRL':'Federal bank',
    'ESFB':'Equitas Small Fin Bank',
    'DLXB':'Dhanalaxmi Bank',
    'DCBL':'DCB Bank',
    'CSBK':'The catholic syrian bank',
    'USFB':'Ujjivan Small Fin bank',
    'BDBL':'Bandhan Bank',

  }

  async function onSubmit(fields, { setSubmitting }) {
    let fieldValues={
        ...fields,
        bank_name: bankList[fields.bank_code],
        enach_provider:'AU',
        enach_status:'Pending',
    }
    enachService.addEnach(fieldValues)
    .then((enachitem)=> {
      // Create a new window or tab
      console.log("ENach Item after Processing is: ", enachitem)
      if( enachitem.status === 'Success'){
        const newWindow = window.open('', '_blank');
        const htmlContent = enachitem.success_html
        if (newWindow) {
          newWindow.document.write(htmlContent);
          newWindow.document.close(); //Close the document to finish writing
        } else {
          window.alert('Popup blocked. Please allow popups for this website.');
        }
      } else {
        window.alert("Enach Request creared with id "+enachitem.reference_code);
      }
      window.location.reload()
      //enachData = enachData || [];
      //enachData=[enachitem,...enachData];
      //setEnachData(enachData);
    })
    .catch((err)=> {
      console.log(err);
      window.alert("Error while making Enach");
    })
    setSubmitting(false);
    handleClose();
  }

  const initialValues = {
    emi_amount: 99999,
    loan_number: data?.application_id ? data.application_id : "",
    name: "",
    contact: "",
    email:"",
    bank_account: "",
    bank_account_type: "SB",
    frequency_code: "MNTH",
    frequency_type: "RCUR",
    payment_type: "NetBanking",
    date_from: date.yyyymmdd(),
  };

  const validationSchema = Yup.object().shape({
    emi_amount: Yup.number().required("This field is required"),
    loan_number: Yup.string().required("Please enter valid Loan Number").matches(/^[a-zA-Z0-9]+$/,"Need valid Loan Number"),
    name: Yup.string().max(40).required("This field is required"),
    contact: Yup.string().required("This field is required")
    .min(9,"minimum 9 digits required"),
    email:Yup.string().email().required("Email is required"),
    bank_account: Yup.string().required("This field is required"),
    bank_account_type: Yup.string().required("This field is required"),
    bank_code:Yup.string().required("Bank Name is required"),
    frequency_code: Yup.string().required("This field is required"),
    frequency_type: Yup.string().required("This field is required"),
    payment_type: Yup.string().required("This field is required"),
    start_date: Yup.date().required("This field is required"),
    end_date: Yup.date().min(Yup.ref("start_date"),"End date has to be more than start date"),
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleOpen} variant="contained" color="primary">
        Add eNACH By AU
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Form Details</h2>
            <hr />
            <div className="row justify-content-around">
              <Formik
                // innerRef={formikRef}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                id="transition-modal-description"
              >
                {({ errors, touched, isSubmitting, setFieldValue }) => {
                  return (
                    <Form>
                      <div className="form-row">
                        <div className="form-group col">
                          <FormElement
                            label="Loan Number"
                            name="loan_number"
                            type="text"
                            errors={errors}
                            touched={touched}
                          ></FormElement>
                          <ErrorMessage
                            name="loan_number"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group col">
                          <FormElement
                            label="EMI Amount"
                            name="emi_amount"
                            type="number"
                            errors={errors}
                            touched={touched}
                          ></FormElement>
                          <ErrorMessage
                            name="emi_amount"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group col">
                          <FormElement
                            label="First EMI Date"
                            name="start_date"
                            type="date"
                            errors={errors}
                            touched={touched}
                          ></FormElement>
                          <ErrorMessage
                            name="start_date"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group col">
                          <FormElement
                            label="Last EMI Date"
                            name="end_date"
                            type="date"
                            errors={errors}
                            touched={touched}
                          ></FormElement>
                        </div>
                        
                        
                        </div>
                      <div className="form-row">
                      <div className="form-group col">
                          <FormElement
                            label="Customer Name"
                            name="name"
                            type="text"
                            errors={errors}
                            touched={touched}
                          ></FormElement>
                        </div>
                        
                        <div className="form-group col">
                          <FormElement
                            label="Customer Mobile Number"
                            name="contact"
                            type="number"
                            errors={errors}
                            touched={touched}
                          ></FormElement>

                         <ErrorMessage
                            name="contact"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group col">
                          <FormElement
                            label="Mandate Email Address"
                            name="email"
                            type="text"
                            errors={errors}
                            touched={touched}
                          ></FormElement>

                           <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="form-row">
                      <div className="form-group col">
                          <label>Payment Type</label>
                          <Field
                            name="payment_type"
                            as="select"
                            className={
                              "form-control" +
                              (errors.payment_type && touched.payment_type
                                ? " is-invalid"
                                : "")
                            }
                          >
                            <option value="DebitCard">DebitCard</option>
                            <option value="NetBanking">NetBanking</option>
                          </Field>
                          <ErrorMessage
                            name="payment_type"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group col">
                          <label>Bank Name</label>
                          <Field
                            name="bank_code"
                            as="select"
                            className={
                              "form-control" +
                              (errors.bankAccountType && touched.bankAccountType
                                ? " is-invalid"
                                : "")
                            }
                          >
                            <option value="" selected disabled></option>
                            {
                              Object.keys(bankList).map((bcode,idx)=>{
                                return (
                                  <option value={bcode}>{bankList[bcode]}</option>
                                )
                              })
                            }
                          </Field>
                          <ErrorMessage
                            name="bank_code"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group col">
                          <FormElement
                            label="Account Number"
                            name="bank_account"
                            errors={errors}
                            touched={touched}
                          ></FormElement>
                        </div>
                        <div className="form-group col">
                          <label>Account Type</label>
                          <Field
                            name="bank_account_type"
                            as="select"
                            className={
                              "form-control" +
                              (errors.bankAccountType && touched.bankAccountType
                                ? " is-invalid"
                                : "")
                            }
                          >
                            <option value="CA">CA</option>
                            <option value="SB">SB</option>
                          </Field>
                          <ErrorMessage
                            name="bank_account_type"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="form-row">

                      <div className="form-group col">
                          <label>Frequency</label>
                          <Field
                            name="frequency_code"
                            as="select"
                            className={
                              "form-control" +
                              (errors.frequencyCode && touched.frequencyCode
                                ? " is-invalid"
                                : "")
                            }
                          >
                            <option value="MNTH">MNTH</option>
                            <option value="BIMN">BIMN</option>
                            <option value="QURT">QURT</option>
                            <option value="MIAN">MIAN</option>
                            <option value="MNTH">MNTH</option>
                            <option value="WEEK">WEEK</option>
                            <option value="DAIL">DAIL</option>
                            <option value="ADHO">ADHO</option>
                          </Field>
                          <ErrorMessage
                            name="frequency_code"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group col">
                          <label>Frequency Type</label>
                          <Field
                            name="frequency_type"
                            as="select"
                            className={
                              "form-control" +
                              (errors.frequencyType && touched.frequencyType
                                ? " is-invalid"
                                : "")
                            }
                          >
                            <option value="RCUR">RCUR</option>
                            <option value="OOFF">OOFF</option>
                          </Field>
                          <ErrorMessage
                            name="frequency_type"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        
                      </div>
                      <div className="row justify-content-around">
                        <Button
                          type="submit"
                          disabled={isSubmitting}
                          variant="contained"
                          color="secondary"
                        >
                          Save Details
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
