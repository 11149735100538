import React from "react";
import { Paper, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { employeeService, misService } from "../../_services";
import { authPersonService } from "../../_services/auth_person.service";
import AuthPersonModal from "./AuthPersonModal";
import roleChecker from "../../_helpers/rolechecker";

function DashboardTab() {
  const user = employeeService.userValue;
  const [authPersonList, setAuthPersonList] = React.useState([]);

  React.useEffect(() => {
  
    authPersonService.getAll().then((x) => {
      setAuthPersonList(x);
    });
  }, []);

 

  const deleteAuthPerson = (key) => {
    authPersonService.delete(authPersonList[key]._id);
    var newList = [...authPersonList];
    newList.splice(key, 1);
    setAuthPersonList(newList);
  };

  return (
    <div className="p-4">
      <div className="container">
        <h1>Hi {user.first_name}!</h1>
        <p>Welcome to the Kaabil Finance Admin Settings!</p>
        {roleChecker.isITAccess(user.role) ? (
          <div className="row justify-content-around">
            <Paper
              style={{
                padding: 10,
              }}
              className="col-md-4"
            >

<div className="d-flex justify-content-around">
                <Typography variant="button" display="block" gutterBottom>
                  AUTH. PERSONS
                </Typography>
              </div>
              <hr />
              {authPersonList.map((item, idx) => (
                <div key={idx} className="d-flex justify-content-between">
                  <Typography
                    style={{ marginTop: "3%" }}
                    variant="overline"
                    display="block"
                    gutterBottom
                  >
                    {item.name}
                  </Typography>
                  <IconButton
                    onClick={() => deleteAuthPerson(idx)}
                    aria-label="delete"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </div>
              ))}
              <AuthPersonModal />

            </Paper>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

export { DashboardTab };
