import React, { useState, useEffect } from 'react';
import { useReactToPrint } from "react-to-print";
import logo from "../../../assets/kaabil.png"
import signature from "../../../assets/LrnSingnature.png"
import datehelper from "../../../_helpers/datehelper"
import {useParams} from 'react-router-dom'
import { letter } from "../../../_services/lettertracking.service";
const EmiReminderLetter = (props) => {
  const {id}  = useParams();
  const date = new Date();

 

  const EmiReminderLetterRef = React.useRef();

  const handleSubmissionPrint = useReactToPrint({
    content: () => EmiReminderLetterRef.current,
    pageStyle: "print"
  });
  const [formData, setFormData] = useState("")
  const getLetter = async () => {
    const details = await letter.singleEmiLetter(id);
    setFormData(details);
  }
  
  useEffect(() => {
    if(id){
      getLetter()
    }
  }, [])
  let datetimeObj = new Date(formData.createdDate);
  let year = datetimeObj.getUTCFullYear();
let month = String(datetimeObj.getUTCMonth() + 1).padStart(2, '0');
let day = String(datetimeObj.getUTCDate()).padStart(2, '0');

let formattedDate = `${year}${month}${day}`
const numberToWords = (num) => {
  // const units = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
  // const teens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
  // const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];


  const units = ['', 'एक', 'दो', 'तीन', 'चार', 'पांच', 'छह', 'सात', 'आठ', 'नौ'];
  const teens = ['दस', 'ग्यारह', 'बारह', 'तेरह', 'चौदह', 'पंद्रह', 'सोलह', 'सत्रह', 'अठारह', 'उन्नीस'];
  const tens = ['', '', 'बीस', 'तीस', 'चालीस', 'पचास', 'साठ', 'सत्तर', 'अस्सी', 'नब्बे'];
 
  if (num === 0) return 'zero';

  let words = '';

  if (num > 9999999) {
      words += numberToWords(Math.floor(num / 10000000)) + ' करोड़ ';
      num %= 10000000;
  }

  if (num > 99999) {
      words += numberToWords(Math.floor(num / 100000)) + ' लाख ';
      num %= 100000;
  }

  if (num > 999) {
      words += numberToWords(Math.floor(num / 1000)) + ' हज़ार ';
      num %= 1000;
  }

  if (num > 99) {
      words += units[Math.floor(num / 100)] + ' सौ ';
      num %= 100;
  }

  if (num > 19) {
      words += tens[Math.floor(num / 10)] + ' ';
      num %= 10;
  }

  if (num > 0) {
      words += (num < 10 ? units[num] : teens[num - 10]);
  }

  return words.trim();
};

const WordSanctionAmount = numberToWords(formData?.sanction_amount);
const WordAddChargeAmount = numberToWords(formData?.addCharge);
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <button onClick={handleSubmissionPrint}>Print Letter</button>
      </div>
      <link rel='stylesheet' media='print' href='/LegalLetter.css' />
      <div ref={EmiReminderLetterRef} className='letter' style={{ fontSize: '20px' }}>
        <img src={logo} alt="KFPL Logo" style={{width: "40vw", marginLeft:"25%"}}/>
        <div style={{ marginTop: "110px", display:"flex", flexDirection:"row" }}>
          <div>क्रमांक:- KFPL{formattedDate}LEGAL</div><br />
          <span style={{marginLeft:"570px"}}>दिनांक:-{datehelper.displayDate(date)}</span>
        </div><br/>
        <div style={{marginTop:"30px"}}>
        <div>
      <div className='content'>
        1 ) {formData?.title_name} {formData?.name} {formData?.relation} {formData?.title_father} {formData?.father_name} (मुख्य आवेदक)
      </div>
      <div className='content'>
        पता:- {formData?.address} , मो:- {formData?.contact}
      </div><br/>
    </div>
        {formData &&  formData?.coapplicant_guarantor.map((item, i) => (
    <div key={i}>
      <div className='content'>
        {i + 2}) {item?.title_name} {item?.name} {item?.relation} {item?.title_father} {item?.father_name} {item?.cosigner}
      </div>
      <div className='content'>
        पता:- {item?.address} , मो:- {item?.contact}
      </div><br/>
    </div>
  ))}
        </div><br />
        <div>
          महोदय,
        </div>
        <div>
          विषय - ऋण खाता संख्या <b>{formData.account_number}</b> में अवधि पार किस्तों की अदायगी बाबत -  ऋण -रु.<b>{formData.sanction_amount}</b> (रु.{WordSanctionAmount})
        </div><br />
        <div style={{ textAlign: "justify" }}>
          उपरोक्त विषय में लेख है कि आपकी आवश्यकता की पूर्ति हेतू,  आपके द्वारा आवेदन  करने पर रु.<b>{formData.sanction_amount}</b> का ऋण काबिल फाइनेंस द्वारा स्वीकृत किया गया।
          इस ऋण का भुगतान आपके द्वारा रु <b>{formData.emi_amount}</b> की <b>{formData.tenure}</b> मासिक किस्तों में किया जाना तय हुआ । उक्त ऋण  खाते के अवलोकन पर पता चला की गत
          <b>{formData.pending_emi}</b> किस्तों का भुगतान आज दिवस तक नहीं हुआ है, जो की आपके द्वारा सम्पादित ऋण आवेदन एवं ऋण संभन्दित प्रपत्रों के अनुसार अनुचित
          एवं दंडनीय है ।  अत आपसे  निवेदन है की आज दिवस तक बकाया राशि एवं दंडनीय ब्याज अवं अन्य खर्चे  लगभग  रु. <b>{formData.addCharge}</b> /-का भुगतान
          अधिकतम इस सूचना की प्राप्ति के <b>7</b> दिवस में जमा करवाने का कष्ट करें ।साथ में आपको हिदायत दी जाती है कि भविष्य में मासिक क़िस्त का भुगतान नियत तारीख को हो
          जाना सुनिश्चित करें । वाद मियाद गुजरने पर आपके विरुद्ध सम्पूर्ण बकाया राशि वसूलने की कार्यवाई  सक्षम न्यायालय में दायर की जावेगी, जिसके तमाम हर्ज़े  एवम  खर्चे की
          ज़िम्मेदारी आपकी होगी ।
          <div> <br />
            आपसे पुन: अपेक्षा कि जाती है कि बकाया ऋण राशि रु. <b>{formData.addCharge}</b> /- (रु.{WordAddChargeAmount}) का भुगतान कर अन्य कानूनी कार्यवाहियों से बचने का प्रयास करेंगे।
          </div>
        </div><br />
        <div>
          भवदीय
        </div><br />
        <div>
          काबिल फाइनेंस प्राइवेट लिमिटेड
        </div><br />
        <div>
          <img src={signature} alt="Signature"  style={{ height: "130px", marginLeft: "233px", transform: "rotate(-85deg)"}} />
        </div>
        <div>
          अधिकारी लीगल
        </div><br />
        <div style={{ textAlign: "center" }}>
          <p>***This is computer generated letter.***</p>
        </div>
      </div>

    </>
  )
}

export default EmiReminderLetter