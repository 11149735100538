import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import AddIcon from "@material-ui/icons/Add";
import { Button, Fab } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { bankAccount } from "../../../_services/bank_account.service";
import { employeeService } from "../../../_services";
const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: "600px",
        height: "450px"
    },
}));

export function BankAccountModal() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const user = employeeService.userValue.emp_id;
    const formik = useFormik({
        initialValues: { bank_name: "", ifsc_code: "", account_number: "", emp_id: user },
        validationSchema: Yup.object({
            bank_name: Yup.string().required("This field is required"),
            ifsc_code: Yup.string().required("This field is required"),
            account_number: Yup.string().required("This field is required")
        }), onSubmit: (values) => {
            bankAccount.addBankAccount(values)
            .then(res => {
                window.alert('Account Added')
                window.location.reload()
            })
            .catch(err => {
                window.alert('Some error occurred')
                console.log(err)
            })
        }
    });
    return (
        <div>
            <div
                style={{ marginTop: "3%" }}
                className="d-flex justify-content-around"
                onClick={handleOpen}
            >
                <Button style={{ background: "blue", width: "30px", color: "white" }}><AddIcon /></Button>
            </div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">Add New Bank Account</h2>
                        <form id="transition-modal-description" onSubmit={formik.handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="bank_name">Bank Name</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="bank_name"
                                    {...formik.getFieldProps("bank_name")}
                                />
                                {formik.errors.bank_name && formik.touched ? (
                                    <span style={{ color: "red", marginBottom: '100px' }}>{formik.errors.bank_name}</span>
                                ) : null}
                            </div>
                            <div className="form-group">
                                <label htmlFor="ifsc_code">IFSC Code</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="ifsc_code"
                                    {...formik.getFieldProps("ifsc_code")}
                                />
                                {formik.errors.ifsc_code && formik.touched ? (
                                    <span style={{ color: "red" }}>{formik.errors.ifsc_code}</span>
                                ) : null}
                            </div>
                            <div className="form-group">
                                <label htmlFor="account_number">Account Number</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="account_number"
                                    {...formik.getFieldProps("account_number")}
                                />
                                {formik.errors.account_number && formik.touched ? (
                                    <span style={{ color: "red" }}>{formik.errors.account_number}</span>
                                ) : null}
                            </div>
                            <hr />
                            <button
                                type="submit"
                                className="btn btn-primary"
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                </Fade>
            </Modal>

        </div>
    );
}
