import React, { useState, useEffect } from 'react'
import { allcloudService } from '../../../../_services/allcloud.service'
import { employeeService } from '../../../../_services/employee.service'
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
 import { Button } from "@material-ui/core";
import { Paper } from '@material-ui/core'
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import AllCloudPpreviewModal from './AllCloudPreviewModal'
import roleChecker from '../../../../_helpers/rolechecker';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';


const AllCloudLoan = (props) => {
  const cust = props.cust
  console.log("fbgnfhmgjfgfh is", cust)

  const [employeeList, setEmployeeList] = useState({})
  const user = employeeService.userValue
  const [spinner, setSpinner] = React.useState(false);
const [isFailed,setIsFailed] = useState(false);


  const allCloudData = (application_id) => {
    setSpinner(true)
    allcloudService.allCloudProcessingRequest({ loan_number: application_id })
      .then(res => {
        setSpinner(false)
        console.log("allcloud response", res.status)
        if(res.status!=="failed"){

          window.location.reload();
        }else{
          setIsFailed(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      
      })
      .catch(err => console.log("err", err))
  }

  const requestAllCloud = (application_id) => {
    console.log('application_id', application_id)
    allcloudService.requestAllCloud(application_id)
      .then(res => {
        console.log("requestAllCloud", res);
        window.location.reload()
      })
      .catch(err => console.log("err", err))
  }

  const verifyAllCloudloan = (application_id) => {
    console.log('application_id', application_id)
    allcloudService.verifyAllCloudloan(application_id)
      .then(res => {
        console.log("verifyAllCloud", res);
        window.location.reload()
      })
      .catch(err => console.log("err", err))
  }
  async function getAllEmployees() {
    await employeeService.getAll()
      .then((res) => {
        let list = {};
        res.map(e => {
          list[e.emp_id] = e.first_name + ' ' + e.last_name
        })
        setEmployeeList(list)
      })
  }

  useEffect(() => {
    // getLetters()
    getAllEmployees()
  }, [])
  return (
    <div>
      <div  >

        <h2
          style={{
            color: "wheat",
            backgroundColor: "black",
            textAlign: "center",
          }}
        >
          AllCloud Loan Creation
        </h2>
      </div>
      <div>


        <Table className="table table-striped">
          <Thead>
            <Tr>
              <Th style={{ width: "8%" }}>Loan No.</Th>
              <Th style={{ width: "12%" }}>Applicant Name</Th>
              <Th style={{ width: "11%" }}>Preview </Th>
              <Th style={{ width: "11%" }}>Request</Th>
              <Th style={{ width: "12%" }}>Requested By</Th>
              <Th style={{ width: "12%" }}>Verify</Th>
              <Th style={{ width: "12%" }}>Verified By</Th>
              <Th style={{ width: "12%" }}>Create Loan</Th>
            </Tr>
          </Thead>
          <Tbody>


            <Tr  >
              <td>{cust.application_id}</td>

              <td>{cust && cust.applicant && cust.applicant.general_information && cust.applicant.general_information.name}</td>

              <td><AllCloudPpreviewModal cust={cust} /></td>

              <td>

                { ["Operation"].includes(cust.stage) && (  cust && (!cust.allcloud_datas || cust.allcloud_datas.length === 0 || !cust.allcloud_datas[0].application_id ) ? (roleChecker.isHOOpsTeamAccess(user.role) && <Button onClick={() => {
                  if (window.confirm("have you  check all nesessary conditions ?")) {
                    requestAllCloud(cust.application_id);
                  }
                }} color='primary' variant='outlined'>Request</Button>) : <span style={{ fontSize: "20px", fontWeight: "bold", color: "green" }}> <DoneOutlineIcon style={{ fontSize: 'xx-large' }} /></span>)}
              </td>


              <td>{(cust && cust.allcloud_datas && cust.allcloud_datas[0] && cust.allcloud_datas[0].request_details) && <p> {employeeList[cust.allcloud_datas[0].request_details.requested_by_emp_id]}</p>}</td>



              <td>{( cust.allcloud_datas && cust.allcloud_datas.length !== 0 && cust.allcloud_datas[0].request_details) && (!cust.allcloud_datas[0].verify_details ? <>{roleChecker.isHOOpsApproverAccess(user.role) && <Button onClick={() => {
                if (window.confirm("have you  check all nesessary conditions ?")) {
                  verifyAllCloudloan(cust.application_id);
                }
              }} color='primary' variant='outlined'>verify</Button>} </>: <span style={{ fontSize: "20px", fontWeight: "bold", color: "green" }}><VerifiedUserIcon />Verified</span>
              )}</td>
 

 
              <td>{(cust && cust.allcloud_datas && cust.allcloud_datas[0] && cust.allcloud_datas[0].verify_details) && <p> {employeeList[cust.allcloud_datas[0].verify_details.verified_by_emp_id]}</p>}</td>

              {
               ["Operation"].includes(cust.stage) ? ( spinner ? <td>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                    flexDirection: 'column',
                    marginTop: '-12px'
                  }}>
                    <CircularProgress style={{ color: "#ff00ff", width: '2rem' }} />
                    <p style={{ fontSize: 'small', fontWeight: '600' }}>Loan Processing ...</p>
                  </div> </td> :
                  <td>  {(cust && cust.allcloud_datas && cust.allcloud_datas[0] && cust.allcloud_datas[0].request_details && cust.allcloud_datas[0].verify_details) && (  cust.allcloudcreateddata && cust.allcloudcreateddata.length === 0  ? ( isFailed ? <p style={{fontSize:'20px',fontWeight:'800',color:'red'}}>Failed</p> :<> {cust.allcloud_datas[0].request_details.requested_by_emp_id ===user.emp_id  && <Button onClick={() => allCloudData(cust.application_id)} color="primary" variant="contained">Create</Button>}</>) : <span style={{ fontSize: "20px", fontWeight: "bold", color: "green" }}><CheckCircleIcon />Created</span>)}</td>) 
                  :
                  <td> {cust && cust.allcloudcreateddata && cust.allcloudcreateddata.length === 0 ?  <span style={{ fontSize: "20px", fontWeight: "bold", color: "green" }}> Already Exist</span> :  <span style={{ fontSize: "20px", fontWeight: "bold", color: "green" }}><CheckCircleIcon />Created</span>}</td>
              }
            </Tr>


          </Tbody>
        </Table>

      </div>
    </div>
  )
}

export default AllCloudLoan