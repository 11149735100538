
import React , {useState} from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Product from "../_helpers/product"
import Alerts from "../_components/uielements/Alerts"
import { employeeService, alertService , loanService} from "../_services";
import "../css/login.css"
function Login({ history, location }) {
  const [showError, setShowError] = React.useState(false);
  const [registerednumber, setRegisterednumber] = React.useState();
  const [otpResponse, setOtpResponse] = useState('')
  const [otp, setOtp] = useState({ otp1: '', otp2: '', otp3: '', otp4: '' })
  const [showemp_id, setShowemp_id] = useState('');
  const [showPassword,setShowPassword]=useState('')
const [nextPage,setNextPage] = useState(false)
const [isExist,setIsExist] = useState()
const [spinner,setSpinner]=useState(false)
  const initialValues = {
    empid: "",
    password: "",
  };
  const initialValues1 = {
    otp:''
  };
  const validationSchema = Yup.object().shape({
    empid: Yup.string().required("Empid is required"),
    password: Yup.string().required("Password is required"),
  });

function checkLogin(empid,password,setSubmitting){
  employeeService
  .login(empid, password)
  .then((user) => {
    console.log(user)
    if (user.blocked === true) {
      showError(true)
    }

    history.push("/intro");
  })
  .catch((error) => {
    setShowError(true);
    setSubmitting(false);
    alertService.error(error);
  });
}

function checkMobileNumber(empid,password,setSubmitting){
  employeeService
      .resetPasswordbyOtp(empid)
      .then((user) => {
        console.log('response is', user)
        setRegisterednumber(user.contact);
        setIsExist(user.otp)
        if (user.blocked === false) {
          loginOTP(user.contact);
           
            setNextPage(true)
         
        }})
      .catch((error) => {

        setShowError(true);
        setSubmitting(false);
        alertService.error(error);
      });
      
}

  function onSubmit({ empid, password }, { setSubmitting }) {
    alertService.clear();
    setShowError(false)
    const storedOptIn = localStorage.getItem('optIn');
    const storedDate = localStorage.getItem('optInDate');
    const employeeId = localStorage.getItem('empid');

    console.log('storedOptIn','storedDate','employeeId',storedOptIn,storedDate,employeeId,empid,employeeId==empid)
    if (employeeId==empid && storedOptIn && new Date(storedDate) > new Date(new Date() - 7 * 24 * 60 * 60 * 1000)) {
      checkLogin(empid,password,setSubmitting)
    }else{
      employeeService
        .login(showemp_id, showPassword)
        .then((user) => {
          console.log(user)
          if (user.blocked === true) {
            showError(true)
          }
          checkMobileNumber(empid,password,setSubmitting)
        })
        .catch((error) => {
          setShowError(true);
            setSubmitting(false);
          setSpinner(false)
          alertService.error(error);
        });
      
    }
  }
  
  const loginOTP = (emp_contact) => {
    alertService.clear();
    setShowError(false)
    let random_num = Math.floor(1000 + Math.random() * 9000);
    // console.log("test otp", random_num)
    loanService.loginOtp({  contact: emp_contact, otp: random_num })
      .then((data) => {
         
        setOtpResponse(data.otp)
      
        // console.log('data is', data);
        
      })
      .catch((err) => console.log(err))
  }
  const inputFocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
        const next = elmnt.target.tabIndex - 2;
        if (next > -1) {
            elmnt.target.form.elements[next].focus()
        }
    }
    else {
        // console.log("next");
        const next = elmnt.target.tabIndex;
        if (next < 4) {
            elmnt.target.form.elements[next].focus()
        }
    }
}

const handleChange = (e) => {
    let item = { ...otp }
    item[e.target.name] = e.target.value
    setOtp(item)
}

  const varifyOtp = ({setSubmitting}) => {
    setSpinner(true)
    let input_otp = otp.otp1 + otp.otp2 + otp.otp3 + otp.otp4;
      console.log("isExist?.toString() === otpResponse.toString()",isExist?.toString() , input_otp.toString(),isExist?.toString() === input_otp.toString())
     if ((input_otp.toString() === otpResponse.toString() ) || (isExist?.toString() === input_otp.toString())) {
       employeeService
        .login(showemp_id, showPassword)
        .then((user) => {
          console.log(user)
          if (user.blocked === true) {
            showError(true)
          }
          localStorage.setItem('optIn', true);
          localStorage.setItem('empid',showemp_id)
          localStorage.setItem('optInDate', new Date());
          history.push("/intro");
        })
        .catch((error) => {
          setShowError(true);
          //  setSubmitting(false);
          setSpinner(false)
          alertService.error(error);
        });
      console.log("true")
    } else {
      window.alert('Incorrect Otp')
              // setSubmitting(false);
              setSpinner(false)

       setOtp({ otp1: '', otp2: '', otp3: '', otp4: '' })
    }
  }

  return (
    <div className="loginpage">
    <div className="gradentImage">
    <h3 className="welcomeline">WELCOME TO KAABIL FINANCE!!</h3>
    <img
         className="loginvector"
          alt="Company Logo"
          src={require("../assets/Screenshot_2023-06-06_121427__1_-removebg-preview.png")}
         
        ></img>
    </div>
    <div className="loginside">
    <img
         className="loginimage"
          alt="Company Logo"
          src={require("../assets/kaabil.png")}
         
        ></img>
        <div   className="formvalue">

       {!nextPage && <Formik 
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, isSubmitting,setFieldValue }) => (
        <Form>
          <h2 style={{ textAlign: "center" }} className="card-header">
            Log In
          </h2>
          {(showError && (<Alerts message="Unable to Login" />))}
          <div className="card-body">
            <div className="form-group">
              <label>Employee ID</label>
              <Field
                name="empid"
                type="text"
                className={
                  "form-control" +
                  (errors.empid && touched.empid ? " is-invalid" : "")
                }
                onChange={(e)=>
                 {console.log(e.target.value);
                  setFieldValue("empid",e.target.value)
                  setShowemp_id(e.target.value)
                 }
                }
              />
              <ErrorMessage
                name="empid"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <Field
                name="password"
                type="password"
                className={
                  "form-control" +
                  (errors.password && touched.password ? " is-invalid" : "")
                }
                onChange={(e)=>
                 {console.log(e.target.value);
                  setFieldValue("password",e.target.value)
                  setShowPassword(e.target.value)
                 }
                }
              />
              <ErrorMessage
                name="password"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="form-group">
          <a href="/employee/forgetpassword">Forgot Password?</a>
        </div>
            <div className="form-row">
              <div className="form-group col d-flex justify-content-center">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary"
                >
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Log In
                </button>
              </div>
            </div>
          </div> 
        </Form>
      )}
    </Formik>}
 { nextPage &&  <Formik
            initialValues={initialValues1}
            // validationSchema={validationSchema}
            onSubmit={varifyOtp}
          >
            {({ errors, touched, isSubmitting,values }) => (
              <Form>
                <h2 style={{ textAlign: "center" }} className="card-header">
OTP                </h2>
                {/* {(showError && (<Alerts message="Unable to Reset" />))} */}
                <div className="card-body">
                  <h6>A 4 digit code has been sent to the mobile number : xxxxxx{registerednumber && registerednumber.toString().slice(-4) && <>{registerednumber.toString().slice(-4)}</>} registered with emp id {showemp_id}</h6>
                
                  <div style={{ display:'flex',alignContent:'center',justifyContent:'center',marginTop:'2rem'}} className="form-group">
                  <div
                                        style={{
                                            width: '250px',
                                            display: 'flex',
                                            marginBottom: '20px',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <input
                                            style={{ border: '1px solid Black', width: '40px', height: '45px', borderRadius: '5px', textAlign: 'center' }}
                                            autoComplete='off'
                                            name='otp1'
                                            tabIndex='1'
                                            maxLength='1'
                                            type="text"
                                            value={otp.otp1}
                                            onKeyUp={e => inputFocus(e)}
                                            onChange={e => handleChange(e)}
                                            required />
                                        <input
                                            style={{ border: '1px solid Black', width: '40px', height: '45px', borderRadius: '5px', textAlign: 'center' }}
                                            autoComplete='off'
                                            name="otp2"
                                            tabIndex='2'
                                            maxLength='1'
                                            type="text"
                                            value={otp.otp2}
                                            onKeyUp={e => inputFocus(e)}
                                            onChange={e => handleChange(e)}
                                            required />
                                        <input
                                            style={{ border: '1px solid Black', width: '40px', height: '45px', borderRadius: '5px', textAlign: 'center' }}
                                            autoComplete='off'
                                            name='otp3'
                                            tabIndex='3'
                                            maxLength='1'
                                            type="text"
                                            value={otp.otp3}
                                            onKeyUp={e => inputFocus(e)}
                                            onChange={e => handleChange(e)}
                                            required />
                                        <input
                                            style={{ border: '1px solid Black', width: '40px', height: '45px', borderRadius: '5px', textAlign: 'center' }}
                                            autoComplete='off'
                                            name='otp4'
                                            tabIndex='4'
                                            maxLength='1'
                                            type="text"
                                            value={otp.otp4}
                                            onKeyUp={e => inputFocus(e)}
                                            onChange={e => handleChange(e)}
                                            required />
                                    </div>
                  </div>

                 
                  <div className="form-row">
                    <div className="form-group col d-flex justify-content-center">
                      <button
                        type="submit"
                        disabled={spinner}
                        className="btn btn-primary"
                      >
                        {spinner && (
                          <span className="spinner-border spinner-border-sm mr-1"></span>
                        )}
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>}
        </div>
    </div>
      
    </div>
    
  );
}

export { Login };
