import React from "react";
import { Route, Switch } from "react-router-dom";

import { List } from "./List";
import { AddEdit } from "./AddEdit";
import  ChangePassword  from "./ChangePassword"

function Users({ match }) {
  const { path } = match;

  return (
    <Switch>
      <Route exact path={path} component={List} />
      <Route path={`${path}/add`} component={AddEdit} />
      <Route path={`${path}/edit/:id`} component={AddEdit} />
      <Route path={`${path}/update/password`} component={ChangePassword} />
    </Switch>
  );
}

export { Users };
