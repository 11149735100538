import { Box, Button, Checkbox, Modal, Table } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Tbody, Td, Th, Tr } from 'react-super-responsive-table';
import { employeeService, loanService } from '../../../_services';
import { OtpVerificationModal } from './OtpVerificationModal';
export const CheckoutApproveModal = (props) => {
    const style = {
        position: "absolute",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width:800,
        height: 500,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
        margin: "30px",
        overflow: 'scroll',
    };
    console.log("Test props", props)
    const data = props.packet
    const [open, setOpen] = useState(false)
    const [selectPacket, setSelectPacket] = useState([])
    const [employeeList, setEmployeeList] = useState({})
    const [approvePacket, setApprovePacket] = useState([])
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    async function getAllEmployees() {
        await employeeService.getAll()
            .then((res) => {
                let list = {};
                res.map(e => {
                    list[e.emp_id] = e.first_name + ' ' + e.last_name
                })
                setEmployeeList(list)
            })
    }
    useEffect(() => {
        getAllEmployees();
    }, []);
    console.log("Test Packet ", selectPacket)
    console.log("Test approve packet", approvePacket)
    const handleCheckboxChange = (event) => {
        const selectedId = event.target.value;
        const approve = data.find(selected => selected._id === selectedId);
        if (event.target.checked) {
            setSelectPacket([...selectPacket, selectedId]);
            setApprovePacket([...approvePacket, approve])
        } else {
            setSelectPacket(selectPacket.filter(id => id !== selectedId));
            setApprovePacket(approvePacket.filter(packetId => packetId !== approve));
        }
    }

    return (
        <div>
            <Button onClick={handleOpen} style={{ color: "white", background: "green", width: "200px", }}>
                Approve Check Out
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h5 style={{ display: "flex", justifyContent: "center", marginBottom: "40px" }}>
                        <b>Approve Check Out Requests</b>
                    </h5>
                    <Table>
                        <thead>
                            <Tr>
                                <Th>S.N</Th>
                                <Th>Name</Th>
                                <Th>Loan No</Th>
                                <Th>Packet No</Th>
                                <Th>Check Out Requestd By</Th>
                                <Th>Remark</Th>
                                <Th>Accept</Th>
                            </Tr>
                        </thead>
                        <Tbody>
                            {
                                data.length > 0 ?
                                    data.filter(checkOut => checkOut.packet_state === "Out").map((x, i) => <Tr key={i}>
                                        <Td>{i + 1}</Td>
                                        <Td>{x.name}</Td>
                                        <Td>{x.loan_no}</Td>
                                        <Td>{x.packet_no}</Td>
                                        <Td>{employeeList[x.checkout_requested_by]}</Td>
                                        <Td>{x.check_out_requested_remark}</Td>
                                        <Td>
                                            <label>
                                                <input type="checkbox" style={{ width: "20px", height: "25px" }} value={x._id} onChange={handleCheckboxChange} />
                                            </label>
                                        </Td>
                                    </Tr>) : null
                            }
                        </Tbody>
                    </Table>
                    <br />
                    <br />
                    {selectPacket.length > 0 ? <OtpVerificationModal approve={selectPacket} approvePacket={approvePacket} /> : null}
                </Box>
            </Modal>
        </div>
    )
}
