import React, { useState, useEffect } from 'react'
import { sanction } from "../../../_services/sanctionletter.service"
import Provider from "./Print"
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Link } from "react-router-dom";
import { Edit } from "@material-ui/icons";
import datehelper from "../../../_helpers/datehelper"
import AnnexureList from '../SanctionAnnexure/AnnexureList';
import { Button } from '@material-ui/core';
import { history } from '../../../_helpers';

const SanctionLetterList = () => {

    const [letter, setLetter] = useState()

    const getLetter = async () => {
        const data = await sanction.getAll()
        setLetter(data)
    }
    console.log(letter)

    useEffect(() => {
        getLetter()

    }, [])

    return (
        <>
            <h2>Sanction Letters</h2><br />

            {/* <Link to={`/home/sbl`} className="btn btn-sm btn-primary mr-1">Create New Letter </Link> */}
            <hr></hr>

            <div>
                <Table className="table table-hover">
                    <Thead>
                        <Tr>
                            <Th style={{ width: "20%" }}>Date</Th>
                            <Th style={{ width: "20%" }}>Application ID</Th>
                            <Th style={{ width: "20%" }}>Name</Th>
                            <Th style={{ width: "20%" }}>Sanction Amount</Th>
                            <Th style={{ width: "20%" }}>Print</Th>
                            <Th style={{ width: "20%" }}>Annexure</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {letter && letter.map((element) => {
                            return <Tr key={element._id}>
                                <Td className='table-light' style={{ width: "20%" }}>{datehelper.displayDate(new Date(element.createdAt))}</Td>
                                <Td className="table-light" style={{ width: "20%" }}>{element.application_id}</Td>
                                <Td className="table-light" style={{ width: "20%" }}>{element.applicant}</Td>
                                <Td className="table-light" style={{ width: "20%" }}>{element.sanction_amount}</Td>
                                <Td className="table-light" style={{ width: "20%" }}>{<Provider letter = {element}/>} </Td>
                                <Td className="table-light" style={{ width: "20%" }}><Button color='primary' onClick={()=>history.push(`/home/sblloans/annexure/list`, element)}>Print</Button></Td>

                            </Tr>
                        })}

                    </Tbody>
                </Table>
            </div>
        </>
    )
}

export default SanctionLetterList