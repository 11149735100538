import { fetchWrapper } from "../_helpers";
const apiUrl = process.env.REACT_APP_API_URL;
const baseUrl = `${apiUrl}/bankaccount`;

const addBankAccount = (params)=>{
    return fetchWrapper.post(`${baseUrl}/addaccount`,params)
}
const getBankAccount=()=>{
    return fetchWrapper.get(`${baseUrl}/getbankaccount`)
}
export const bankAccount = {
    addBankAccount,
    getBankAccount,
}

  