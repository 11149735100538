import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import * as Yup from "yup";

import { Formik, Field, Form, ErrorMessage } from "formik";

import Fab from "@material-ui/core/Fab";
import SaveIcon from "@material-ui/icons/Save";
import { Button } from "@material-ui/core";
import { loanService } from "../../../../_services/loan.service";
import { employeeService } from "../../../../_services";
import FormElement from "../../../uielements/FormElement";
import datehelper from "../../../../_helpers/datehelper";
import { uploadFileToBucket } from "../../../../_helpers/upload";

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "75%",
    overflow: "scroll",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    minWidth: 650,
  },
}));

export default function AddApplicationFeesModal(props) {
  const customer = props.customer;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [cashPayments, setCashPayments] = React.useState([])
  const [onlinePayments, setOnlinePayments] = React.useState([])
  const user = employeeService.userValue;
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [paymentProof, setPaymentProof] = React.useState("");
  const [paymentRefId, setPaymentRefId] = React.useState("");
  const [receiptEmpId, setReceiptEmpId] = React.useState(user.emp_id);
  const [totalAmount, setTotalAmount] = React.useState([])

  const [exist, setExist] = React.useState(false)


  const formikRef = React.useRef();

  const data = new FormData();
  data.append("file", selectedFile);


  let  initialValues = {}
  if(customer && customer.application_fees && customer.application_fees.payment_amount){
    initialValues = {
      application_fees: {
        payment_date: datehelper.getDateInputString(new Date(Date.now())),
        payment_mode: "",
        payment_comment: "",
        payment_amount: customer.application_fees.payment_amount,
        payment_proof_url: "",
        payment_ref_id: "",
      }
    }
  }else{
    initialValues = {
      application_fees: {
        payment_date: datehelper.getDateInputString(new Date(Date.now())),
        payment_mode: "",
        payment_comment: "",
        payment_amount: "",
        payment_proof_url: "",
        payment_ref_id: "",
      }
    }
  }


  console.log(initialValues);

  const validationSchema = Yup.object().shape({

    application_fees: Yup.object().shape({
      payment_date: Yup.date().max(new Date(), "Date can't be in future").required("This field is required"),
      payment_mode: Yup.string().required("This field is required"),
      payment_amount: Yup.number().required("This field is required"),
    }),
  });

  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const userPayments = async () => {
    await loanService.individualPaymentDetails({ application_id: customer.application_id })
      .then((data) => {
        let cash_payments = data.cash_payments.filter(pd => {
          if (pd.payment <= 3000) {
            return true;
          }
          else {
            return false;
          }
        })
        setCashPayments(cash_payments)
        let online_payments = data.online_payments.filter(pd => {
          if (pd.txn_amt <= 3000) {
            return true;
          }
          else {
            return false;
          }
        })
        setOnlinePayments(online_payments)
        console.log(data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    userPayments();
  }, [])



  function handleChange(fieldName, value, setFieldValue) {
    setFieldValue(fieldName, value);
  }

  const onFileUploadChangeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };


  async function onSubmit(fields, { setSubmitting }) {
   if(fields.application_fees.payment_amount === 1200 || fields.application_fees.payment_amount === 2500){

    let newApplicant = { 'application_fees': {} }
    newApplicant['application_fees']['payment_date'] = fields['application_fees']['payment_date'];
    newApplicant['application_fees']['payment_mode'] = fields['application_fees']['payment_mode'];
    newApplicant['application_fees']['payment_amount'] = fields['application_fees']['payment_amount'];
    newApplicant['application_fees']['payment_comment'] = fields['application_fees']['payment_comment'];
    newApplicant['application_fees']['payment_proof_url'] = paymentProof;
    newApplicant['application_fees']['payment_received_employee_id'] = receiptEmpId;
    newApplicant['application_fees']['payment_ref_id'] = paymentRefId;

    console.log(newApplicant);
    await loanService.addApplicationFees(customer._id, newApplicant)
      .then((ns) => {
        console.log("Applicant added");
        window.alert("Details added");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        window.alert("error adding details");
      })
    setSubmitting(false);

   }else{
    alert('Amount Should be equal to 1200 or 2500')
   }
  }


  const handleValue = (data) => {

    let temp = (JSON.parse(data))
    console.log(temp)

    totalAmount && totalAmount.length>0 && totalAmount.map((x)=>{
      if(x.id === temp.utr_num || x.id === temp.receipt_no){
        setExist(true)
      }else{
        setExist(false)
      }
    })

    let tempArr = []
    let sum;

    if(customer && customer.application_fees && customer.application_fees.payment_amount){
      sum = Number(customer.application_fees.payment_amount);
    }else{
      sum = 0;
    }

      if (temp.payment_mode === "U") {
        tempArr = ([...totalAmount, { amount: temp.txn_amt, mode: "Online",id: temp.utr_num }])
        setTotalAmount([...tempArr])
        if (formikRef.current) {
          tempArr && tempArr.length > 0 && tempArr.map((x) => {
            sum = sum + Number(x.amount)
            console.log(sum, 'sss')
          })
          formikRef.current.setFieldValue("application_fees.payment_amount", sum);
          formikRef.current.setFieldValue("application_fees.payment_mode", "Online");
          formikRef.current.setFieldValue("application_fees.payment_date", temp.updated.split("T")[0]);
          formikRef.current.setFieldValue("application_fees.payment_comment", temp.utr_num + "/" + temp.sender_to_receiver_info);
        }
      } else {
        tempArr = ([...totalAmount, { amount: temp.payment, mode: temp.mode, id:temp.receipt_no }])
        setTotalAmount([...tempArr])
        if (formikRef.current) {
          tempArr && tempArr.length > 0 && tempArr.map((x) => {
            sum = sum + Number(x.amount)
            console.log(sum, 'sss')
          })
          formikRef.current.setFieldValue("application_fees.payment_amount", sum);
          formikRef.current.setFieldValue("application_fees.payment_mode", temp.mode);
          formikRef.current.setFieldValue("application_fees.payment_date", temp.date.split("T")[0]);
          formikRef.current.setFieldValue("application_fees.payment_comment", temp.comment);
          setReceiptEmpId(temp.emp_id);
        }
      }
      setPaymentRefId(temp._id);
    }

  // const bucket = process.env.REACT_APP_s3docsBucket

  //   const fileUploadHandler = async () => {
  //        const report = await uploadFileToBucket(bucket, "application/pdf", `application_fees/${customer.application_id}_payment_proof.pdf`, selectedFile);
  //        setPaymentProof(report);
  //   }

  const removePayment = (idx) => {
    console.log(idx)
    let temp = [...totalAmount]
    temp.splice(idx, 1)
    setTotalAmount(temp)
    let sum ;
    if(customer && customer.application_fees && customer.application_fees.payment_amount){
      sum = Number(customer.application_fees.payment_amount);
    }else{
      sum = 0;
    }
    temp.map((x) => {
      sum = sum + Number(x.amount )
    })
    formikRef.current.setFieldValue("application_fees.payment_amount", sum);
  }

  return (
    <div style={{ "margin": "10px" }}>
      <br />
      <Button
        onClick={handleOpen}
        variant="contained"
        color="primary"
      > Add Login Fees
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Login Fees Details</h2>

            {totalAmount && totalAmount.length > 0 && (
              <table>
                <tr>
                  <th>Amount</th>
                  <th>Mode</th>
                  <th>Action</th>
                </tr>
                {totalAmount.length > 0 && totalAmount.map((x, idx) => {
                  return <tr key={idx}>
                    <td>{x.amount}</td>
                    <td>{x.mode}</td>
                    <td><Button onClick={()=>removePayment(idx)} style={{color:'red'}} >Remove</Button></td>
                  </tr>
                })}
              </table>
            )}

            <Formik
              innerRef={formikRef}
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={onSubmit}
              enableReinitialize={true}
              id="transition-modal-description"
            >
              {({ errors, touched, isSubmitting, setFieldValue, values }) => {
                return (
                  <Form>
                    <div className="form-row">
                      <div className="form-group col">
                        <label style={{ marginTop: "25px", marginBottom: "-13px" }}>Select Payment</label><br />
                        <Field
                          as="select"
                          style={{ 'marginTop': '10px' }}
                          onChange={(e) => handleValue(e.target.value)}
                          name="payment_selection"
                          errors={errors}
                          touched={touched}
                        >
                          <option>Select Payment</option>
                          {cashPayments && cashPayments.map((x) => {
                            return <option value={JSON.stringify(x)} >{x.mode} - {x.payment} - {datehelper.displayDate(new Date(x.date))}</option>

                          })}
                          {onlinePayments && onlinePayments.map((x) => {
                            return <option value={JSON.stringify(x)}>{"Online"} - {x.txn_amt} -  {datehelper.displayDate(new Date(x.updated))}</option>
                          })}
                        </Field>
                      </div>
                    </div>
                    <div className="form-row">

                      <div className="form-group col">
                        <FormElement
                          type="number"
                          name="application_fees.payment_amount"
                          value={values.application_fees.payment_amount}
                          label="Amount"
                          disabled
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                      <div className="form-group col">
                        <FormElement
                          type="text"
                          name="application_fees.payment_mode"
                          label="Payment Mode"
                          disabled
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col">
                        <FormElement
                          type="date"
                          disabled
                          name="application_fees.payment_date"
                          label="Payment Date"
                          errors={errors}
                          touched={touched}
                        />

                      </div>
                      <div className="form-group col">
                        <FormElement
                          type="text"
                          disabled
                          name="application_fees.payment_comment"
                          label="Payment Reference"
                          errors={errors}
                          touched={touched}
                        />
                      </div>

                      {/* <div className="form-group col">
                                    <label>Upload Your File</label>
                                    <input
                                        accept=".pdf"
                                        type="file"
                                        className="form-control"
                                         onChange={(e) => onFileUploadChangeHandler(e)}
                                    />
                                    <button
                                    type="button"
                                    className="btn btn-success btn-block"
                                    onClick={() => fileUploadHandler()}
                                >
                                    Upload
                                </button>
                                </div> */}

                    </div>
                    <hr />
                    <div className="row justify-content-around">
                      <Fab
                        type="submit"
                        disabled={isSubmitting}
                        color="primary"
                        aria-label="save"
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-1"></span>
                        )}
                        <SaveIcon />
                      </Fab>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}