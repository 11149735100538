import React from "react";
import { useReactToPrint } from "react-to-print";
import { misService } from "../../_services";
import ApplicationDoc from "./ApplicationDoc";

export default function ApplicationPrint(props) {
  console.log(props)
  const compRef = React.useRef();
  const custRef = React.useRef();
  const [ckyc, setCkyc] = React.useState("");

  React.useEffect(async () => {
    const kyclink = await misService.getCkyc({
      customer_pic: props.location.state.customer_pic,
      loan_account: props.location.state.loan_account,
    });
    setCkyc(kyclink);
  }, []);

  function openCkyc() {
    window.open(ckyc);
  }

  const handleCompanyPrint = useReactToPrint({
    content: () => custRef.current,
  });

  const handleCustomerPrint = useReactToPrint({
    content: () => compRef.current,
  });

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <button onClick={handleCompanyPrint}>Print Branch Copy</button>
        <button onClick={handleCustomerPrint} style={{ marginLeft: 10 }}>
          Print Customer Copy
        </button>
        <button
          disabled={ckyc ? false : true}
          onClick={() => openCkyc()}
          style={{ marginLeft: 10 }}
        >
          Open CKYC
        </button>
      </div>
      <ApplicationDoc
        ref={custRef}
        owner="Branch Copy"
        dataFill={props.location.state}
      ></ApplicationDoc>
      <ApplicationDoc
        ref={compRef}
        owner="Customer Copy"
        dataFill={props.location.state}
      ></ApplicationDoc>
    </div>
  );
}
