
const one = ["", "One ", "Two ", "Three ", "Four ", "Five ", "Six ", "Seven ", "Eight ", "Nine ", "Ten ", "Eleven ", "Twelve ", "Thirteen ", "Fourteen ", "Fifteen ",
        "Sixteen ", "Seventeen ", "Eighteen ", "Nineteen "];

    var ten = ["", "", "Twenty ", "Thirty ", "Forty ", "Fifty ", "Sixty ", "Seventy ", "Eighty ", "Ninety "];

    function numToWords(n, s) {
        let str = "";
        // if n is more than 19, divide it
        if (n > 19) {
            str += ten[parseInt(n / 10)] + one[n % 10];
        }
        else {
            str += one[n];
        }
        if (n != 0) {
            str += s;
        }

        return str;
    }

    function convertToWords(n) {
        // stores word representation of given number n
        let out = "";
        
        // handles digits at ten millions and hundred
        // millions places (if any)
        out += numToWords(Math.floor(n / 10000000),
        "Crore ");
        
        // handles digits at hundred thousands and one
        // millions places (if any)
        out += numToWords(parseInt((n / 100000) % 100),
            "Lakh ");

        // handles digits at thousands and tens thousands
        // places (if any)
        out += numToWords(parseInt((n / 1000) % 100),
            "Thousand ");

        // handles digit at hundreds places (if any)
        out += numToWords(parseInt((n / 100) % 10),
            "Hundred ");

        if (n > 100 && n % 100 > 0) {
            out += "and ";
        }

        // handles digits at ones and tens places (if any)
        out += numToWords(parseInt(n % 100), "");

        return out;
    }


    export default convertToWords;