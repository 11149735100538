import React, { useState, useEffect } from 'react';
import { branchExpenseService } from '../../../_services/branchExpense.service';
import datehelper from "../../../_helpers/datehelper";
import OwnerData from "./OwnerData"
import Comments from "./Comments"
import { Button, TextField, Box , Select, MenuItem, InputLabel, FormControl} from '@material-ui/core';
import AccountVerificationBox from "./AccountVerificationBox"
import BranchPettyCash from "./BranchPettyCash"
import roleChecker from "../../../_helpers/rolechecker"
import { employeeService } from "../../../_services/employee.service";
import {branchService} from "../../../_services/branch.service"


function ViewAllBranchExpense() {
  const [allExpenses, setAllExpenses] = useState([]);
  const [rentExp, setRentExp] = useState(true);
  const [pettyCashExp, setPettyCashExp] = useState(false);
  const [electricBillExp, setElectricBillExp] = useState(false);
  const [branchIdFilter, setBranchIdFilter] = useState('');
  const [allBranches,setAllBranches] = useState([]);
const user = employeeService.userValue
  useEffect(() => {
    branchExpenseService.getAll()
      .then(res => {
        setAllExpenses(res);
        console.log('res of view branch expense is', res);
      })
      .catch(err => console.log('err is', err));

      branchService.getAll()
      .then(res=>{
        console.log('resp of branch is',res);
        setAllBranches(res);
      })
  }, []);

  const filterByBranchId = (expenses) => {
    return branchIdFilter ? expenses.filter(expense => expense?.branch_id?.includes(branchIdFilter)) : expenses;
  };

  const handleRentClick = () => {
    setRentExp(true);
    setPettyCashExp(false);
    setElectricBillExp(false);
  };

  const handlePettyCashClick = () => {
    setRentExp(false);
    setPettyCashExp(true);
    setElectricBillExp(false);
  };

  const handleElectricBillClick = () => {
    setRentExp(false);
    setPettyCashExp(false);
    setElectricBillExp(true);
  };

  // Filter expenses by category
  const rentExpenses = filterByBranchId(allExpenses.filter(expense => expense.category === 'Rent'));
  const pettyCashExpenses = filterByBranchId(allExpenses.filter(expense => expense.category === 'Petty Cash'));
  const electricBillExpenses = filterByBranchId(allExpenses.filter(expense => expense.category === 'Electricity Bills'));


  const dayDifference = (date) => {
    const today = new Date(); 
    const givenDate = new Date(date); 
  
     const timeDiff = today - givenDate;
  
     const diffInDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  
     if (diffInDays > 7) {
      return 'red';
    } else if (diffInDays > 3) {
      return 'yellow';
    } else {
      return 'white';
    }
  };
  

  return (
    <>
      <div>
        

        {/* Branch ID Filter */}
       

        {/* Category Buttons */}
        <Box display="flex" justifyContent="flex-start"  marginBottom="20px" alignItems="center" style={{gap:"2rem"}}>
          <Button
            variant={rentExp ? "contained" : "outlined"}
            color="primary"
            onClick={handleRentClick}
          >
            Rent Expenses
          </Button>
          <Button
            variant={pettyCashExp ? "contained" : "outlined"}
            color="primary"
            onClick={handlePettyCashClick}
          >
            Petty Cash Expenses
          </Button>
          <Button
            variant={electricBillExp ? "contained" : "outlined"}
            color="primary"
            onClick={handleElectricBillClick}
          >
            Electricity Bill Expenses
          </Button>
        { roleChecker.isHOLevelAccess(user.role) &&  <FormControl variant="outlined" style={{ marginBottom: '20px', minWidth: 200 }}>
      <InputLabel id="branch-select-label">Filter by Branch ID</InputLabel>
      <Select
        labelId="branch-select-label"
        id="branch-select"
        value={branchIdFilter}
        onChange={(e) => setBranchIdFilter(e.target.value)}
        label="Filter by Branch ID"
      >
        {allBranches?.map((branch) => (
          <MenuItem key={branch.branch_id} value={branch.branch_id}>
          {branch.branch_id}-{branch.branch_name} {/* Assuming branch_name is the name of the branch */}
          </MenuItem>
        ))}
      </Select>
    </FormControl>}
        </Box>

        {/* Rent Expenses Table */}
        {rentExp && (
          <div>
            <h2>Rent Expenses</h2>
            <table className="table">
              <thead>
                <tr>
                  <th>Branch Id</th>
                  <th>Initiated Date</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Owner Reference</th>
                  <th>status</th>

                  <th>Amount</th>
                  <th>Comments</th>
                   <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {rentExpenses.map((expense, index) => {
                  const statusColor =  expense?.createdAt && dayDifference(  expense.createdAt);
                  return (

                  <tr key={index} >
                    <td>{expense.branch_id}</td>
                    <td style={{background:statusColor}}>{expense.createdAt && datehelper.displayDate(new Date(expense.createdAt))}</td>
                    <td>{expense.startDate && datehelper.displayDate(new Date(expense.startDate))}</td>
                    <td>{expense.endDate && datehelper.displayDate(new Date(expense.endDate))}</td>
                    <td><OwnerData data={expense.owner_details} /></td>
                    <td>{expense.status} {expense && (["Refer To Accounts","Verified"].includes(expense.status)  ) && roleChecker.hasAccountsAccess(user.role) &&<AccountVerificationBox data={expense} />}</td>
                    <td>{expense.amount}</td>
                    <td><Comments data={expense.comments} /></td>
                      
                      <td>{expense.status=="Pending" && <><BranchPettyCash id={expense._id}/></>}</td>
                       
                                      </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}

        {/* Petty Cash Expenses Table */}
        {pettyCashExp && (
          <div>
            <h2>Petty Cash Expenses</h2>
            <table className="table">
              <thead>
                <tr>
                  <th>Branch Id</th>
                  <th>Sub Category</th>
                  <th>Initiated Date</th>
                  <th>Invoice Date</th>
                  <th>Amount</th>
                  <th>status</th>
                  <th>Invoices</th>
                  <th>Comments</th>
               
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {pettyCashExpenses.map((expense, index) => {
                  const statusColor =  expense?.createdAt && dayDifference(  expense.createdAt);
                  return (
                  <tr key={index}  >
                    <td>{expense.branch_id}</td>
                    <td>{expense.subCategory}</td>
                    <td style={{background:statusColor}}>{expense.createdAt && datehelper.displayDate(new Date(expense.createdAt))}</td>
                    <td>{expense.invoiceDate && datehelper.displayDate(new Date(expense.invoiceDate))}</td>
                    <td>{expense.amount}</td>
                    <td>{expense.status}{expense && (["Refer To Accounts","Verified"].includes(expense.status)  ) && roleChecker.hasAccountsAccess(user.role) &&<AccountVerificationBox data={expense} />}</td>
                    <td>
  {expense?.invoices && (
    <ol>
      {expense.invoices.map((val, ind) => (
        <li key={ind}>
          <a href={val.url} target="_blank" >View Invoice</a>
        </li>
      ))}
    </ol>
  )}
</td>
                    <td><Comments data={expense.comments} /></td>
                         
                    <td>{expense.status=="Pending" && <><BranchPettyCash id={expense._id}/></>}</td>
                         </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}

        {/* Electric Bill Expenses Table */}
        {electricBillExp && (
          <div>
            <h2>Electricity Bill Expenses</h2>
            <table className="table">
              <thead>
                <tr>
                  <th>Branch Id</th>
                  <th>Initiated Date</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Previous Unit</th>
                  <th>Current Unit</th>
                  <th>Owner Data</th>
                  <th>status</th>
                  <th>Amount</th>
                  <th>Is Submittering</th>
                  <th>Comments</th>
                
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {electricBillExpenses.map((expense, index) => {
                  const statusColor =  expense?.createdAt && dayDifference(  expense.createdAt);
                  return (
                  <tr key={index}  >
                    <td>{expense.branch_id}</td>
                    <td style={{background:statusColor}}>{expense.createdAt && datehelper.displayDate(new Date(expense.createdAt))}</td>
                    <td>{expense.startDate && datehelper.displayDate(new Date(expense.startDate))}</td>
                    <td>{expense.endDate && datehelper.displayDate(new Date(expense.endDate))}</td>
                    <td>{expense.prevUnit}</td>
                    <td>{expense.currentUnit}</td>
                    <td><OwnerData data={expense.owner_details} isSubmittering={expense.isSubmittering} /></td>
                    <td><>

                  {expense.status}{expense && (["Refer To Accounts","Verified"].includes(expense.status)  ) && roleChecker.hasAccountsAccess(user.role) &&<AccountVerificationBox data={expense} />}
                  </>
                    </td>
                    <td>{expense.amount}</td>
                    <td>{expense.isSubmittering ? 'Yes' : 'No'}</td>
                    <td><Comments data={expense.comments} /></td>
                   
                    <td>{expense.status=="Pending" && <><BranchPettyCash id={expense._id}/></>}</td>
                  </tr>
                )})}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
}

export default ViewAllBranchExpense;
