import React from 'react'
import CreditTabBar from './CreditTabBar'

const CreditStage = () => {
  return (
    <div>
        <CreditTabBar />
    </div>
  )
}

export default CreditStage