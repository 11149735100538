import { Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Table, Tbody, Td, Th, Tr } from 'react-super-responsive-table'
import { goldPacketServices } from '../../../_services/gold_packet.service'
import { history } from '../../../_helpers';
import urlhelper from '../../../_helpers/urlhelper';
import { employeeService } from '../../../_services'
import datehelper from '../../../_helpers/datehelper';
import displayhelper from '../../../_helpers/displayhelper';

export const PacketList = () => {
    const [packet, setPacket] = useState([])
    const [countPacket, setCountPacket] = useState()
    const [employeeList, setEmployeeList] = useState({})
    const countExitsPacket = () => {
        goldPacketServices.countExistsPacket()
            .then((res) => setCountPacket(res))
            .catch((err) => alert(err))
    }
    async function getAllEmployees() {
        await employeeService.getAll()
            .then((res) => {
                let list = {};
                res.map(e => {
                    list[e.emp_id] = e.first_name + ' ' + e.last_name
                })
                setEmployeeList(list)
            })
    }
    useEffect(() => {
        async function getAllPackets() {
            await goldPacketServices.getAllPackets()
                .then((res) => setPacket(res))
                .catch((err) => console.log(err))
        }
        getAllEmployees()
        getAllPackets()
        countExitsPacket()
    }, [])
    console.log("Test All Packet", packet)
  const toatlGoldAmount=packet.reduce((pkt,amt)=>pkt+amt.loanDetails.loan_amount,0)
  console.log("Test total amount",toatlGoldAmount)
    return (
        <div style={{ margin: "10px", background: 'white' }}>
            <div style={{ margin: '10px' }}>
                <br />
                <br />
                <h2 style={{ textAlign: "center" }}>All Packets List</h2>
                <br />
                <hr />
                <b style={{ fontWeight: 'bold', fontSize: "20px" }}>Total Amount of Available  Gold Packets:₹{displayhelper.displayNumberAccounting(toatlGoldAmount)}</b>
                <br/>
                <br/>
                <b style={{ fontWeight: 'bold', fontSize: "20px" }}>Total Available Gold Packets:{countPacket}</b>
                <br/>
                <br />
                <Table>
                    <thead style={{fontSize:"20px",fontWeight:'bold' }}>
                        <Tr>
                            <Th style={{ border: "1px solid " }}>S.N</Th>
                            <Th style={{ border: "1px solid " }}>Loan No</Th>
                            <Th style={{ border: "1px solid " }}>Name</Th>
                            <Th style={{ border: "1px solid " }}>Loan Creation Date</Th>
                            <Th style={{ border: "1px solid " }}>Packet Number</Th>
                            <Th style={{ border: "1px solid " }}>Loan Amount</Th>
                            <Th style={{ border: "1px solid " }}>Status</Th>
                            <Th style={{ border: "1px solid " }}>Image</Th>
                            <Th style={{ border: "1px solid " }}>Check In By</Th>
                            <Th style={{ border: "1px solid " }}>Check In Accepted By</Th>
                            <Th style={{ border: "1px solid " }}>Check In Date</Th>
                            <Th style={{ border: "1px solid " }}>Check In Time</Th>
                        </Tr>
                    </thead>
                    <Tbody>
                        {
                            packet.length > 0 ?
                                packet.map((x, i) => <Tr key={i} style={{ fontWeight: 'bold', fontSize: "15px" }}>
                                    <Td style={{ border: "1px solid " }}>{i + 1}</Td>
                                    <Td style={{ border: "1px solid " }}>{x.loan_no}</Td>
                                    <Td style={{ border: "1px solid " }}>{x.name}</Td>
                                    <Td style={{ border: "1px solid " }}>{datehelper.displayDate(new Date(x.loanDetails&&x.loanDetails.date))}</Td>
                                    <Td style={{ border: "1px solid " }}>{x.packet_no}</Td>
                                    <Td style={{ border: "1px solid " }}>{x.loanDetails&&x.loanDetails.loan_amount.toLocaleString("en-IN", { style: 'currency', currency: 'INR' })}</Td>
                                    <Td style={{ border: "1px solid " }}>{x.packet_state}</Td>
                                    <Td style={{ border: "1px solid " }}><Button style={{ color: "blue" }}
                                        onClick={() => history.push(`/home/pdfviewer?pdf_url=` + urlhelper.encodeToBase64(x.photo) + "&download=1")}>
                                        View
                                    </Button></Td>
                                    <Td style={{ border: "1px solid " }}>{x.checkIn_details && employeeList[x.checkIn_details.checkin_by_emp_id]}</Td>
                                    <Td style={{ border: "1px solid " }}>{x.checkIn_details && employeeList[x.checkIn_details.accept_by_emp_id]}</Td>
                                    <Td style={{ border: "1px solid " }}>{datehelper.displayDate(new Date(x.createdAt))}</Td>
                                    <Td style={{ border: "1px solid " }}>{new Date(x.createdAt).toLocaleTimeString()}</Td>
                                </Tr>)
                                : <Tr>
                                    <Td style={{textAlign:"center",fontSize:"26px" }} colSpan="12"><b>Data not found</b></Td>
                                </Tr>
                        }
                    </Tbody>
                </Table>
                <br />
                <br />
            </div>
        </div>
    )
}

