import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import kaabillogo from "../../../assets/kaabil.png";
import kaabilfooter from "../../../assets/kaabil_footer2.png";
import { loanService } from "../../../_services/loan.service";
import datehelper from "../../../_helpers/datehelper";
import EmailIcon from '@material-ui/icons/Email';
import CallIcon from '@material-ui/icons/Call';
import LanguageIcon from '@material-ui/icons/Language';
const WelcomeLetter = (props) => {
  const [data, setData] = useState("");

  const id = props.location.state.id;

  const WelcomeLetterRef = React.useRef();

  const handleSubmissionPrint = useReactToPrint({
    content: () => WelcomeLetterRef.current,
    pageStyle: "print",
  });

  const getData = () => {
    loanService
      .getById(id)
      .then((x) => {
        setData(x);
        console.log(x);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let colender_name, agreement_value = 0;

  if (data && data.co_lending && data.co_lending.length>0) {
    data.co_lending.map((e) => {
      if (
        e.co_lender_status === "FilePrepared" ||
        e.co_lender_status === "AmountReceivedFromColender"
      ) {
        colender_name = e.co_lender_name;
      } else {
        colender_name = "-----";
      }
    });
  }

/*
  if(data && data.loan &&  data.loan.repayment_schedule ){
    data.loan.repayment_schedule.map((e)=>{
      agreement_value += e.payment_amount
      console.log(agreement_value)
    })
  }
*/
  useEffect(() => {
    getData();
  }, []);

  let date = new Date();
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  let yy = date.getFullYear();
  date = dd + "-" + mm + "-" + yy;

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <button onClick={handleSubmissionPrint}>Print Letter</button>
      </div>
      {data && data.applicant && (
        <div ref={WelcomeLetterRef}>
          <img
            src={kaabillogo}
            style={{ height: "150px", marginLeft: "30%" }}
            alt="KFPL Logo"
          />
          <div
            style={{
              height: "400mm",
              width: "290mm",
              fontSize: "22px",
              marginTop: "20%",
              textAlign: "justify",
              padding: "auto",
            }}
          >
            <h5 style={{ textAlign: "center" }}>
              निमंत्रण पत्र/Welcome Letter
            </h5>
            <br />
            <br />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>लोन नंबर: {data.application_id} </span>
              <span>दिनांक: {date}</span>
            </div>
            <br />
            <div>
              {data.applicant.general_information.title}.{data.applicant.general_information.name}S/o&nbsp;
              {data.applicant.general_information.sonof}
              <br />
              Address:- {data.applicant.general_information.address}
              <br />
              Mobile:- {data.applicant.general_information.contact}
            </div>
            <br />
            <span>Reference Loan Amount - {data.loan.sanction_amount}/- </span>
            <br />
            <div>
              <br />
              <span> मान्यवर, </span>
              <br />
              <span>
                आपने ऋण सुविधा हेतु काबिल फाइनेंस प्राइवेट लिमिटेड को चुना इसके
                लिए हम आपके आभारी है । हम इसे एक अच्छे
                <br />
                सुदृढ़ रिश्ते की शुरुआत मानते हुए यह आश्वस्त करते है कि आने वाले
                समय में आपकी हर तरह की सुविधाएं जैसे <br />
                ऑटो फाइनेंस, कैश लोन, मॉर्गेज लोन, गोल्ड लोन व अन्य सुविधाओं के
                लिए हमेशा तत्पर रहेंगे। भविष्य में आप अपनी किसी <br />
                भी आवश्यकता हेतु अपना लोन नंबर जिसका उल्लेख ऊपर है का प्रयोग
                करें। यह ऋण हमारे सह-ऋणदाता ({colender_name}) द्वारा वितरित किया
                गया है|
                <br />
              </span>
              <br />
              <span>
                हम इस पत्र के साथ आपको शिडयूल भिजवा रहे है जिसमे किश्त राशि में
                मूल एवं ब्याज का विवरण है तथा हर माह की किश्त
                <br />
                दिनांक अंकित है आपसे अनुरोध है कि आप अपने बैंक खाते में पर्याप्त
                धन राशि: उक्त दिनांक से पूर्व जमा करवाएं। यदि <br />
                आपके बैंक खाते में से किश्त नहीं निकलती है तो आप हमारे कार्यालय
                में संपर्क करें। यदि आप अपना घर टेलीफोन/मोबाइल <br />
                नंबर या अन्य विवरण में परिवर्तन करते है तो कृपया हमें उपरोक्त
                लिखित टेलीफोन नंबर पर सूचित करें जिससे हम आपको <br />
                बेहतरीन सुविधाएं प्रदान कर सकें।
                <br />
                हम आपको अपनी बेहतरीन सेवाएं हर समय देने का वादा करते है और
                भविष्य में लम्बे एवं बेहतरीन रिश्ते की कामना करते है।
                <br />
              </span>
              <br />
              <span>धन्यवाद,</span>
              <br />
              <br />
              <span>
                वास्ते काबिल फाइनेंस प्राइवेट लिमिटेड यह एक कंप्यूटर जनित पत्र
                है, इसलिए हस्ताक्षर की आवश्यकता नहीं है
              </span>
            </div>
            <div className="header-infos">
     <div className="header-top">
       <div>
         <span>
           <strong><EmailIcon/>  care@kaabilfinance.com</strong>
         </span>
       </div>
       <div>
         <span>
           <strong><CallIcon/> 0141-4005012</strong>
         </span>
       </div>
       <div>
         <strong><LanguageIcon/> www.kaabilfinance.com</strong>
       </div>
     </div>
     <div className="header-bottoms" style={{fontSize:"18px"}}>
      
         <p> <strong>Registered & Corporate Office </strong> :- Kaabil Towers, Plot No. 1, Scheme No. 1, Income Tax Colony, Model Town, Jagatpura, Jaipur Rajasthan 302017 CIN No. U65929RJ2017PTC058138 ,GSTIN : 08AAGCK6104N1ZA</p>
         
     </div>
     {/* Fixed bottom footer */}
     
   </div>
            {/* <img
              src={kaabilfooter}
              style={{
                position: "absolute",
                width: "300mm",
                marginTop: "200px",
              }}
              alt="KFPL Footer"
            /> */}
          </div>

          <div style={{ width: "290mm", height: "400mm" }}>
            <img
              src={kaabillogo}
              style={{ height: "150px", marginLeft: "30%" }}
              alt="KFPL Logo"
            />
            <h6 style={{ marginTop: "50px", textAlign: "center" }}>
              Installment Plan for {data.applicant.general_information.name} S/o
              &nbsp;
              {data.applicant.general_information.sonof}
              &nbsp; &nbsp; [Loan No- {data.application_id}]
            </h6>
            <br />
            <h5 style={{ textAlign: "center", marginTop: "30px" }}>
              CUSTOMER DETAILS
            </h5>
            <table
              style={{ width: "60%", textAlign: "center", marginLeft: "20%" }}
            >
              <tr style={{ border: "1px solid" }}>
                <td style={{ border: "1px solid" }}>
                  Loan Date:<br/>
                  {datehelper.displayDate(
                    new Date(data.application.application_date)
                  )}
                </td>
                <td style={{ border: "1px solid" }}>
                  Res.Address:- {data.applicant.general_information.address}
                </td>
                <td style={{ border: "1px solid" }}>Occupation</td>
              </tr>
              <tr>
                <td style={{ border: "1px solid" }}>
                  Expiry Date:<br/>
                  {datehelper.displayDate(new Date(data.loan.last_emi_date))}
                </td>
                <td style={{ border: "1px solid" }}>
                  Phone number:-<br/> {data.applicant.general_information.contact}
                </td>
                <td style={{ border: "1px solid" }}></td>
              </tr>
            </table>
            <br />
            <h5 style={{ textAlign: "center", marginTop: "30px" }}>
              CO-LENDER DETAILS
            </h5>
            <table
              style={{ width: "60%", textAlign: "center", marginLeft: "20%" }}
            >
              <tr style={{ border: "1px solid" }}>
                <td style={{ border: "1px solid", width: "30%" }}>Colender</td>
                <td style={{ border: "1px solid", width: "30%" }}>
                  {colender_name}
                </td>
              </tr>
            </table>
            <br />
            <h5 style={{ textAlign: "center", marginTop: "30px" }}>
              ACCOUNT DETAILS
            </h5>
            {data && data.loan && (
              <table
                style={{ width: "60%", textAlign: "center", marginLeft: "20%" }}
              >
                <tr style={{ border: "1px solid" }}>
                  <td style={{ border: "1px solid" }}>
                    Amount Financed (Rs.):- {data.loan.sanction_amount}/-
                  </td>
                  <td style={{ border: "1px solid" }}>
                    Tenure(Months):- {data.loan.tenure} Months
                  </td>
                  <td style={{ border: "1px solid" }}>
                    Rate of Interest (Annually):-
                    {data.loan.roi}%
                  </td>
                </tr>
                <tr style={{ border: "1px solid" }}>
                  <td style={{ border: "1px solid", width: "30%" }}>
                    Emi Amount (Rs.):- <br /> {data.loan.emi}/-
                  </td>
                  {/* <td style={{ border: "1px solid" }}>
                    Interest Payable (Rs.):- {values.interest_paid}/-
                  </td> */}
                  <td style={{ border: "1px solid" }}>
                    Cheque/ECS Bouncing Penalty:- <br /> Rs.500/- + GST
                  </td>
                  <td style={{ border: "1px solid" }}>
                    Collection Plan:- <br /> 5th of Every Month
                  </td>
                </tr>
                <tr style={{ border: "1px solid" }}>
                  <td style={{ border: "1px solid" }}>
                      
                    Agreement Value (Rs.):- <br />
                    {agreement_value}/-
                  </td>
                  <td style={{ border: "1px solid" }}>
                    Emi Start Date:- <br />
                    {datehelper.displayDate(new Date(data.loan.first_emi_date))}
                  </td>
                  <td style={{ border: "1px solid" }}>
                    Loan Type :- <br />
                    SBL
                  </td>
                </tr>
              </table>
            )}
            <br />
            <h5 style={{ textAlign: "center", marginTop: "30px" }}>
              CO-APPLICANT DETAILS
            </h5>
            <table
              style={{ width: "60%", textAlign: "center", marginLeft: "20%" }}
            >
              <tr style={{ border: "1px solid" }}>
                <td style={{ border: "1px solid" }}>Name</td>
                <td style={{ border: "1px solid" }}>Address</td>
                <td style={{ border: "1px solid" }}>Mobile</td>
              </tr>
              {data && data.co_applicants &&
                data.co_applicants.map((e) => {
                  return (
                    <tr style={{ border: "1px solid" }}>
                      <td style={{ border: "1px solid" }}>
                        {e.general_information.name}
                      </td>
                      <td style={{ border: "1px solid" }}>
                        {e.general_information.address}
                      </td>
                      <td style={{ border: "1px solid" }}>
                        {e.general_information.contact}
                      </td>
                    </tr>
                  );
                })}
            </table>
          </div>
          <div style={{ width: "290mm", height: "400mm" }}>
            <img
              src={kaabillogo}
              style={{ height: "150px", marginLeft: "30%" }}
              alt="KFPL Logo"
            />
            <h2 style={{marginTop:"20px", textAlign:"center"}}>Repayment Schedule</h2>
            
            <table style={{textAlign:"center", marginLeft:"10px", marginTop:"20px"}}>
              <thead>
                <tr style={{border:"1px solid"}}>
                  <th style={{border:"1px solid", textAlign:"center"}}>Payment Number</th>
                  <th style={{border:"1px solid", textAlign:"center"}}>Date</th>
                  <th style={{border:"1px solid", textAlign:"center"}}>Start Balance</th>
                  <th style={{border:"1px solid", textAlign:"center"}}>Payment Amount</th>
                  <th style={{border:"1px solid", textAlign:"center"}}>Capital Paid</th>
                  <th style={{border:"1px solid", textAlign:"center"}}>Interest Paid</th>
                  <th style={{border:"1px solid", textAlign:"center"}}>Remaining Balance</th>
                </tr>
              </thead>
              <tbody>
                
                {/*data && data.loan && data.loan.repayment_schedule && data.loan.repayment_schedule.map((e, idx)=>{
                  return <tr style={{border:"1px solid", textAlign:"center"}} key={idx}>
                  <td style={{border:"1px solid", textAlign:"center"}}>{idx + 1}</td>
                  <td style={{border:"1px solid", textAlign:"center"}}>{datehelper.displayDate(new Date(e.due_date))}</td>
                  <td style={{border:"1px solid", textAlign:"center"}}>{e.start_balance}</td>
                  <td style={{border:"1px solid", textAlign:"center"}}>{e.payment_amount}</td>
                  <td style={{border:"1px solid", textAlign:"center"}}>{e.capital_paid}</td>
                  <td style={{border:"1px solid", textAlign:"center"}}>{e.interest_paid}</td>
                  <td style={{border:"1px solid", textAlign:"center"}}>{e.balance_remaining}</td>
                </tr>
                })*/}
                
                
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default WelcomeLetter;
