import React from "react";
import * as Yup from "yup";
import { Formik, Form, Field, useFormikContext } from "formik";
import FormElement from "../../../uielements/FormElement";
import { employeeService,loanService,customerService } from "../../../../_services";
import roleChecker from "../../../../_helpers/rolechecker";
import dateHelper from "../../../../_helpers/datehelper";
import { Tr, Td, Th } from 'react-super-responsive-table';
import {
  withStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Box,
  Typography,
  TextField
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import datehelper from "../../../../_helpers/datehelper";
import SanctionLetterDetails from "./SanctionLetterDetails";
import { ApprovalRequestFilter } from "../../../MakerCheckerFramework/ApprovalRequestFilter";
import swal from "sweetalert"
import workflowHelper from "../../../../_helpers/workflowhelper";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(key, label, value, editable, type, options = []) {
  return { key, label, value, editable, type, options };
}

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "1250px",
  height: "350px",
  bgcolor: "#F3F3F3",
  border: "3px solid #000",
  overflow: "scroll",
  boxShadow: 24,
  p: 4,
};
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "1250px",
  height: "450px",
  bgcolor: "#F3F3F3",
  border: "3px solid #000",
  overflow: "scroll",
  boxShadow: 24,
  p: 4,
};
const style3 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "1250px",
  height: "650px",
  bgcolor: "#F3F3F3",
  border: "3px solid #000",
  overflow: "scroll",
  boxShadow: 24,
  p: 4,
};
export default function LoanData({ customer}) {
  console.log(customer.application_id,"customercustomercustomercustomer")
  const classes = useStyles();
  const formikRef = React.useRef();
  const [editing, setEditing] = React.useState(false);
  const [showBt, setShowBt] = React.useState(customer.loan.loan_type === "BT" ? true : false)
  const [btType, setBtType] = React.useState(customer && customer.loan && customer.loan.bt_type && customer.loan.bt_type)
  const [existBtLoan, setExistBtLoan] = useState('')
  const [warn, setWarn] = useState("")
  const [showTopUp, setShowTopUp] = useState(customer.loan.loan_type === "TopUp" ? true : false)
  const [stampDutyPerson,setStampDutyPerson]=useState('select');
  const user = employeeService.userValue;
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [openModel, setOpenModel] = useState(false)
  const [showMessage,setShowMessage] = useState();
  const [usePDR,setPRD] = useState();
  const [usePDRS,setPRDS] = useState();
  const [isSuccess,setIsSuccess]=useState(false)
  const [insured_person_dob,setInsured_person_dob]= useState('')
  const [insured_person_gender,setInsured_person_gender]= useState('')
  const [occupation_type,setOccupation_type]= useState('')
  
  React.useEffect(() => {
    if (customer.loan) {
      const fields = [
        'loan'
      ];
      fields.forEach((field) => {
        if (formikRef.current) {
          formikRef.current.setFieldValue(field, customer[field], false);
        }
      });
    }

  }, []);

  const initialValues = {
    'loan': {
      'amount': "",
      'sanction_amount': '',
      'insurance_amount': '',
      'insured_applicant': '',
      'processing_fees': '',
      "tenure": "",
      "roi": "",
      "pf_rate": "",
      'stamp_duty': "",
      'imd_deduction': "",
      'internal_bt_deduction': "",
      'other_charges': "",
      'other_charges_comment': "",
      'loan_type': "Fresh",
      'bt_type': "",
      'bt_loan_no': "",
      "topup_amount": "",
      "cheque_amount": "",
      "allcloud_file_num": "",
    },
  };

  const maxDate = dateHelper.getMaxPastDate("yyyy-mm-dd");
  console.log("Max date is " + maxDate);
  const validationSchema = Yup.object().shape({
    loan: Yup.object().shape({
      amount: Yup.string().required("This field is required"),
      insurance_amount: Yup.number().required("This field is required"),
      bt_loan_no: Yup.string().required("This field is required"),
      bt_type: Yup.string().required("This is required")
    }),
  });
 
  async function onSubmit(fields) {
    console.log(fields);
    console.log("submitting form");
    await loanService.update(customer._id, fields).then((applicant) => {
      window.alert("Loan File Updated" + applicant.application_id);
      window.location.reload(false);
    })
      .catch((err) => {
        window.alert("error updating Loan Application");
        console.log("error" + err)
      })
  }

  async function saveEdits(fields) {
    if (fields.loan.loan_type === "BT" && fields.loan.bt_type === '') {
      alert("Select BT Loan Type")
      return
    }
    if (fields.loan.bt_type === "Internal" && fields.loan.bt_loan_no === "") {
      alert("Enter BT Loan Number")
      return
    }
    if (fields.loan.bt_type === "External" && !fields.loan.cheque_amount || fields.loan.cheque_amount === "") {
      alert("Enter Cheque Amount")
      return
    }
    if (fields.loan.loan_type === "TopUp") {
      if (!fields.loan.topup_amount || fields.loan.topup_amount === '') {
        alert("Enter the TopUp amount")
        return
      }
    }
    setEditing(false);
    onSubmit(fields);
  }

  async function startEditing(values, setFieldValue) {
    setEditing(true);
    calculations(values, setFieldValue)
  }

  const calculateStampDuty = (values, setFieldValue) => {
    console.log("2nd", values)
    let guar_charges = 0, gaur_length = 0;
    try {
      if (values.loan.sanction_amount && values.loan.sanction_amount > 0) {
        console.log("3rd", values)
        let amount = Number(values.loan.sanction_amount) * 0.3 / 100
        if (customer && customer.guarantors && customer.guarantors.length > 0) {
          guar_charges = customer.guarantors.length * 500
          gaur_length = customer.guarantors.length
        }

        let charges = Number(amount) + guar_charges + 300
        let percentage = charges * 30 / 100
        charges = charges + percentage;
        let guar_stamp = (gaur_length * 5) + 20
        let total = Math.ceil(charges);
        if (gaur_length > 0) {
          total = Math.ceil(charges + guar_stamp)
        }
        if (total && total > 0) {
          console.log("setting stamp duty=" + total);
          setFieldValue('loan.stamp_duty', total)
        }
        else {
          console.log("unable to get stamp duty");
        }

      }
    }
    catch (e) {
      console.log("error calculating stamp duty");
      console.log(e);
    }
  }

  const calculateProcesingFee = (values, setFieldValue) => {
    try {
      if (values.loan.sanction_amount && values.loan.sanction_amount > 0 && values.loan.pf_rate && values.loan.pf_rate > 0) {
        let fee = Math.ceil(values.loan.sanction_amount * values.loan.pf_rate / 100)
        setFieldValue('loan.processing_fees', fee)
      } else {
        setFieldValue('loan.processing_fees', 0)
      }
    }
    catch (e) {
      console.log("error calculating pf");
      console.log(e)
    }
  }

  const calculations = (values, setFieldValue) => {
    console.log("calculating values");
    calculateProcesingFee(values, setFieldValue)
    // calculateStampDuty(values, setFieldValue);
  }

  const checkInternalBtLoan = async (loan) => {
    await loanService.getBySearch({ application_id: loan })
      .then((data) => {
        if (data && data.length > 0) {
          if (data[0].application_id === customer.application_id) {
            setWarn("Loan Number can not be the same")
            setExistBtLoan("")
          } else {
            setExistBtLoan(data[0].applicant.general_information.name)
            setWarn("")

          }
        } else {
          setWarn("Loan not exist")
          setExistBtLoan("")
        }
      })
  }


  const setBtLoan = (values, setFieldValue, value) => {

    if (value === "TopUp") {
      setShowTopUp(true)
    } else {
      setShowTopUp(false)
    }

    if (value === "BT") {
      setShowBt(true)
    } else {
      setShowBt(false)
      setFieldValue('loan.bt_loan_no', "")
      setFieldValue('loan.bt_type', "")
      setFieldValue('loan.loan_type', value)
    }
    setFieldValue('loan.loan_type', value)

  }

  const setType = (values, setFieldValue, value) => {
    if (value === "External" || value === "Internal") {
      setBtType(value)
      setFieldValue('loan.bt_type', value)
    }
  }

  const addToField = (setFieldValue, loan, setOpen) => {
    setFieldValue('loan.bt_loan_no', loan)
    setOpen(false)
    alert("Loan Added")
  }

  const rows = [];
 
  const handleOpen = () => {

    if(!customer.loan.sanction_amount && !customer.loan.roi && !customer.loan.tenure){
      swal(
        "Error","Add The sanction amount, ROI & Tenure to Calculate the Insurance. Use 'Edit' button below.","error"
      )
      return }
      handleClickAge();
  
  }

  
  const handleCloseModel = () => {
    setOpenModel(false)
  }
  const handleClose = () => {
    setOpen(false);
  }
  
  const handleClose1 = () => {
    setOpen1(false);
  }
  const handleClose3 = () => {
    setOpen3(false);
  }

  
  
  // if(customer?.loan){
  //   if(customer.loan && customer.loan.stamp_duty!=0){
  //     setStampDutyPerson('Company')
  //   }else{
  //     setStampDutyPerson('Customer')
  //   }
  // }

  if (customer.loan) {
    
    rows.push(createData("loan.letter_loan_id", "Letter Loan Number", customer.loan.letter_loan_id, true, "text"));
    rows.push(createData("loan.amount", "Loan Request Amount", customer.loan.amount, false, "number"));
    rows.push(createData("loan.sanction_amount", "Sanction Amount", customer.loan.sanction_amount, true, "number"));
    rows.push(createData("loan.roi", "ROI", customer.loan.roi, true, "number"));
    rows.push(createData("loan.pf_rate", "PF (%)", customer.loan.pf_rate, true, "number"));
    rows.push(createData("loan.tenure", "Tenure", customer.loan.tenure, true, "number"));
    rows.push(createData("loan.insurance_amount", "Insurance Amount", customer.loan.insurance_amount, true, "number"));
    rows.push(createData("loan.insured_applicant", "Insured Applicant", customer.loan.insured_applicant, true, "text"));
    rows.push(createData("loan.stamp_duty", "Stamp Duty", customer.loan.stamp_duty, false, "number"));
    rows.push(createData("loan.processing_fees", "Processing Fees", customer.loan.processing_fees, false, "number"));
    rows.push(createData("loan.imd_deduction", "IMD Deduction", customer.loan.imd_deduction, true, "number"))
    rows.push(createData("loan.internal_bt_deduction", "Internal BT Deduction", customer.loan.internal_bt_deduction, true, "number"))
    rows.push(createData("loan.other_charges", "Other charges", customer.loan.other_charges, true, "number"))
    rows.push(createData("loan.other_charges_comment", "Other Charge Comment", customer.loan.other_charges_comment, true, "text"))
    rows.push(createData("loan.loan_type", "Loan Type", customer.loan.loan_type, true, "select", ["Fresh", "Renew", "TopUp", "BT"]))
    if (showBt) {
      rows.push(createData("loan.bt_type", "BT Type", customer.loan.bt_type, true, "select", ["Internal", "External"]))
      if (btType === "Internal") {
        rows.push(createData("loan.bt_loan_no", "BT Loan Number", customer.loan.bt_loan_no, false, "text"))
      }
      if (btType === "External") {
        rows.push(createData("loan.cheque_amount", "Cheque Amount", customer.loan.cheque_amount, true, "number"))
      }
    }

    if (showTopUp) {
      rows.push(createData("loan.topup_amount", "TopUp Amount", customer.loan.topup_amount, true, "text"))
    }

    if (roleChecker.isAdminAccess(user.role)) {
      rows.push(createData("loan.allcloud_file_num", "All cloud File Number", customer.loan.allcloud_file_num, true, "number"));
    }
    else {
      rows.push(createData("loan.allcloud_file_num", "All cloud File Number", customer.loan.allcloud_file_num, false, "number"));
    }
  }
  else {

    rows.push(createData("loan.letter_loan_id", "Letter Loan Number", "", true, "text"));
    rows.push(createData("loan.amount", "Loan Request Amount", "", false, "number"));
    rows.push(createData("loan.sanction_amount", "Sanction Amount", "", true, "number"));
    rows.push(createData("loan.roi", "ROI", "", true, "number"));
    rows.push(createData("loan.pf_rate", "PF (%)", "", true, "number"));

    rows.push(createData("loan.tenure", "Tenure", "", true, "number"));
    rows.push(createData("loan.insurance_amount", "Insurance Amount", "", true, "number"));
    // rows.push(createData("loan.insurance_amount", "Insurance Amount", "", false, "number"));
    rows.push(createData("loan.insured_applicant", "Insured Applicant", "", true, "text"));
    rows.push(createData("loan.stamp_duty", "Stamp Duty", "", false, "number"));
    rows.push(createData("loan.processing_fees", "Processing Fees", "", false, "number"));
    rows.push(createData("loan.imd_deduction", "IMD Deduction", "", true, "number"))
    rows.push(createData("loan.internal_bt_deduction", "Internal BT Deduction", "", true, "number"))
    rows.push(createData("loan.other_charges", "Other charges", "", true, "number"))
    rows.push(createData("loan.other_charges_comment", "Other Charge Comment", "", true, "text"))
    rows.push(createData("loan.loan_type", "Loan Type", "", true, "select"))
    if (showBt) {
      rows.push(createData("loan.bt_type", "BT Type", "", false, "select"))
      if (btType === "Internal") {
        rows.push(createData("loan.bt_loan_no", "BT Loan Number", "", true, "text"))
      }
      if (btType === "External") {
        rows.push(createData("loan.cheque_amount", "Cheque Amount", "", true, "number"))
      }
    }
    if (showTopUp) {
      rows.push(createData("loan.topup_amount", "TopUp Amount", "", true, "number"))
    }
    rows.push(createData("loan.allcloud_file_num", "Allcloud File Num", "", false, "number"));

  }
  const setStampDutyOption = (values,option, setFieldValue) => {
    console.log("option",option)
    setStampDutyPerson(option)
    if(option==='Customer'){

      setFieldValue("loan.stamp_duty", 0);
    }else{
        calculateStampDuty(values,setFieldValue);
    }
  };

  const setInsuranceAmount = (value,setFieldValue) => {
      setFieldValue("loan.insurance_amount", value);
  
  };

  const handleClickAge = async () => {
  
    const requestData = { loanNo: customer.application_id};
    await loanService.getInsAmount(requestData)
      .then((data) => {
        console.log('daaaaaaaaaaaaaaaaaaa',data)
      
        if(data && data.status=="success" && data.data.tag==="Automated"){
          setPRD(data)
          setOpen(true);
          console.log("ddddddddddddddddddddddd",data.data.customer_id)
          setSelectFinal_customer_id(data.data.customer_id)
          customerService.searchCustomer({ customer_id: data.data.customer_id})
          .then((data) => {
           setInsured_person_dob(data[0].general_details.customer_dob)
           setInsured_person_gender(data[0].general_details.gender)
           setOccupation_type(data[0].financial_condition.is_salaried==="No"?"self_employed":data[0].financial_condition.is_salaried==="Yes"?"salaried":"")
          })
        }
        else if(data && data.status=="failed" && data.message==="Manual"){
          setPRDS(data)
          console.log(data,"datadatadatadatadatadatadatadata")
          setOpen1(true);
          console.log("ddddddddddddddddddddddd",data.data.customer_id)
          // customerService.searchCustomer({ customer_id: data.data.customer_id})
          // .then((data) => {
          //  setInsured_person_dob(data[0].general_details.customer_dob)
          //  setInsured_person_gender(data[0].general_details.gender)
          //  setOccupation_type(data[0].financial_condition.is_salaried==="No"?"self_employed":data[0].financial_condition.is_salaried==="Yes"?"salaried":"")
          // })
        }
        else if(data && data.status=="success" && data.data.tag==="Special"){
          setPRD(data)
          setOpen3(true);
          console.log("ddddddddddddddddddddddd",data.data.customer_id)
          setSelectFinal_customer_id(data.data.customer_id)
          customerService.searchCustomer({ customer_id: data.data.customer_id})
          .then((data) => {
           setInsured_person_dob(data[0].general_details.customer_dob)
           setInsured_person_gender(data[0].general_details.gender)
           setOccupation_type(data[0].financial_condition.is_salaried==="No"?"self_employed":data[0].financial_condition.is_salaried==="Yes"?"salaried":"")
          })
        }
        else{
          setShowMessage(data.message);
          setIsSuccess(false)
        }
     
      })

  }



const getApprovalInsAmt = async (id,application_id,tag,policy_institute_data) => {
  console.log(policy_institute_data,"policy_institute_data")
  if (!nomineeName || !nomineeRelationship || !annualIncome) {
    alert('Please Enter Nominee Name, Nominee Relationship ,Annual Income');
    return; // Stop execution if any required field is empty
}
if (nomineeName === customer?.applicant?.general_information?.name ) {
  if(nomineeaadhar===false){
    alert(`Please verify ${nomineeName} Aadhar first before submitting this form`);
    return; // Stop execution if any required field is empty
  }
 
}

  try {
      const requestData = {id,application_id,tag,policy_institute_data};
      console.log('requestData',requestData)
      // const data = await loanService.saveInsAmount(requestData);
      const data =  await ApprovalRequestFilter.RequestFilter({
        service: 'loanService',
        api: 'saveInsAmount',
        id: id,
        task: 'Insurance Amount',
        updated_fields: ["application_id","selectFinal_customer_id","premium_amount" , "customer_name","insured_person_dob","insured_person_gender","PDR","nominee_name","nominee_relationship","annual_income","nominee_gender","nominee_dob","tenure","colender_name","amount_insured","occupation_type","calcuate_tag",'insurances'],
        sensitive_fields: [],
        // new_values: [{debitLedgerId:debitLedgerId,creditLedgerId:creditLedgerId,transactionType:transactionType,totalAmount:totalAmount,user:user}]
        new_values: {id:id,application_id:application_id,selectFinal_customer_id:selectFinal_customer_id,premium_amount:selectInsurance_Amount,customer_name:selectcustomer_name,insured_person_dob:insured_person_dob,insured_person_gender:insured_person_gender,PDR:selectFinal_PDR ,nominee_name: nomineeName,nominee_relationship: nomineeRelationship,annual_income:annualIncome ,nominee_gender:nomineeGender,nominee_dob:nomineeDob,tenure:selectFinal_tenure,colender_name:customer?.co_lending[0]?.co_lender_name,amount_insured: customer?.loan?.sanction_amount, occupation_type:occupation_type,calcuate_tag:tag,policy_institute:policy_institute_data},
        old_values: {},
        identification_key: 'Insurance Amount',
        identification_value:  application_id,
        emp_id: user.emp_id,
        approval_needed: true,
        approved: false,
      approval_access: 'isGMLevelAccess',
        product:'sbl',
     })

      alert("Request Sent to concerned Authority")
      // window.location.reload();
      setOpen1(false)
      console.log('call jjjjj daaaaaaaaaaaaaaaddddddddddddddddddddd', data);
      if (data && data.status === "success") {
          const acUserData = await loanService.getEmpIdRoleWise({ role: 'AC' }); 
          console.log("AC User Data:", acUserData);
          if (Array.isArray(acUserData) && acUserData.length > 0) { // Ensure acUserData is an array and not empty
            console.log("Calling Redis Helper for sending Notifications");
            await Promise.all(
                acUserData.map((employee) => {
                    const emp_id = employee.emp_id; // Extract emp_id for each user
        
                    return workflowHelper.isRedisNotifications({
                      emp_id: user.emp_id,
                      message: `You have an Insurance approval request from ${user.emp_id} ,Please review and take necessary action.`,
                      action_link: `https://samruddhi.kaabilfinance.com/home/approvals/list`,
                      action_taken_by: emp_id
                  });
                })
              );
            }
          console.log('call ddhh daaaaaaaaaaaaaaawwwwwwwwwwwwwwwwww', data);
          alert(data.message)
      }
  } catch (error) {
      console.error('Error in saving insurance amount:', error);
      // Handle error here
  }
}
  
  console.log("rowsrows", rows)
  console.log("rowsr????????????????????????????????///ows", customer)




  const [nomineeName, setNomineeName] = useState('');
  const [nomineeRelationship, setNomineeRelationship] = useState('');
  const [annualIncome, setAnnualIncome] = useState('');
  const [nomineeGender, setNomineeGender] = useState('');
  const [nomineeDob, setNomineeDob] = useState('');
  const [nomineeaadhar, setNomineeaadhar] = useState('');
  
const handleNomineeNameChange = (event) => {
    const selectedNomineeId = event.target.value.split('-')[0];
    const selectedNomineeObject = allNames.find(item => item.name === selectedNomineeId);
    setNomineeGender(selectedNomineeObject.gender);
    setNomineeName(event.target.value);
    // fetchInsuredPerson()

    
    customerService.searchCustomer({ customer_id: selectedNomineeObject.customer_id })
    .then((data) => {
      setNomineeDob(data[0].general_details.customer_dob)
    })
    .catch((err) => {
        console.log(err)
    })



    const ddddd = allNames.find(item => item.name === customer.applicant.general_information.name)
    console.log(ddddd.customer_id,"ssdddddddddddd")
    customerService.searchCustomer({ customer_id: ddddd.customer_id })
    .then((data) => {
      setNomineeaadhar(data[0].verification_details.karza_aadhar_verified)
    })
    .catch((err) => {
        console.log(err)
    })
};

const handleNomineeRelationshipChange = (event) => {
    setNomineeRelationship(event.target.value);
}

const handleAnnualIncomeChange = (event) => {
    setAnnualIncome(event.target.value);
}
  






let tempArr = [];
let arr = [];

if (customer && customer.applicant && customer.applicant.general_information) {
  tempArr.push({
    name: customer.applicant.general_information.name,
    customer_id: customer.applicant.customer_id,
    gender: customer.applicant.general_information.gender
  });
}

if (customer && customer.co_applicants && customer.co_applicants.length > 0) {
  customer.co_applicants.map((x) => {
    arr.push({
      name: x.general_information.name,
      customer_id: x.customer_id,
      gender: x.general_information.gender
    });
  });
}
const allNames = [...arr, ...tempArr];

console.log(allNames,"allNamesallNamesallNamesallNamesallNamesallNames")

// const totalPremiumAmount = data.instype.reduce((total, item) => total + item.Premium_Amount, 0);
const checkboxStyle = {
  width: '25px',  // set the width
  height: '25px'  // set the height
};
const checkboxStyle1 = {
  width: '20px',  // set the width
  height: '20px'  // set the height
};
console.log(insured_person_dob, "insured_person_gender",insured_person_gender)
const saveInsuranceAmt = async (id,application_id,customer_name,premium_amount,tag,pdr,policy_institute_data,tenure) => {
// const saveInsuranceAmt = async (insured_person_name,insured_person_dob,insured_person_gender,premium_amount,tag,pdr) => {
  if (!nomineeName || !nomineeRelationship || !annualIncome) {
    alert('Please Enter Nominee Name, Nominee Relationship ,Annual Income');
    return; // Stop execution if any required field is empty
}
if (nomineeName === customer?.applicant?.general_information?.name ) {
  if(nomineeaadhar===false){
    alert(`Please verify ${nomineeName} Aadhar first before submitting this form`);
    return; // Stop execution if any required field is empty
  }
 
}


  try {
      // const requestData = {insured_person_name,insured_person_dob,insured_person_gender,nominee_name:nomineeName,nominee_gender:nomineeGender,nominee_dob:nomineeDob,nominee_relationship:nomineeRelationship,policy_institute:[...policy_institute_data],amount_insured: customer?.loan?.sanction_amount,premium_amount,tag,pdr,occupation_type:occupation_type,colender_name:customer?.co_lending[0]?.co_lender_name,annual_income:annualIncome};
      const requestData = {id,application_id,selectFinal_customer_id:selectFinal_customer_id,customer_name,insured_person_dob:insured_person_dob, insured_person_gender:insured_person_gender,nominee_name:nomineeName,nominee_gender:nomineeGender,nominee_dob:nomineeDob,nominee_relationship:nomineeRelationship,policy_institute:policy_institute_data,amount_insured: customer?.loan?.sanction_amount,premium_amount,calcuate_tag:tag,PDR:pdr,occupation_type:occupation_type,colender_name:customer?.co_lending[0]?.co_lender_name,annual_income:annualIncome,tenure};
      console.log(requestData,"requestDatarequestDatarequestData")
      const data = await loanService.saveInsAmount(id ,requestData);
      console.log('call gg daaaaaaaaaaaaaaa', data);
      if (data && data.status === "success") {
          console.log('call ddd daaaaaaaaaaaaaaa', data);
          alert(data.message)
          window.location.reload();
          setNomineeName('')
          setNomineeGender('')
          setNomineeDob('')
          setNomineeRelationship('')
          setAnnualIncome('')
          setOpen3(false);
          setOpen(false);
      }
  } catch (error) {
      console.error('Error in saving insurance amount:', error);
      // Handle error here
  }
}

const [selectcustomer_name, setSelectcustomer_name] = useState("");
const [selectInsurance_Amount, setSelectInsurance_Amount] = useState("");
const [selectFinal_PDR, setSelectFinal_PDR] = useState("");
const [selectFinal_tenure, setSelectFinal_tenure] = useState("");
const [selectFinal_customer_id, setSelectFinal_customer_id] = useState("");
  const handleRowSelect = (key, data) => {
    console.log("Selected item:", { key, ...data });
    setSelectInsurance_Amount(data?.final_ins_amt)
    setSelectFinal_PDR(data?.final_pdr)
    setSelectcustomer_name(data?.customer_name)
    setSelectFinal_tenure(data?.tenure)
    setSelectFinal_customer_id(key)
    customerService.searchCustomer({ customer_id: key})
    .then((data) => {
     setInsured_person_dob(data[0].general_details.customer_dob)
     setInsured_person_gender(data[0].general_details.gender)
     setOccupation_type(data[0].financial_condition.is_salaried==="No"?"self_employed":data[0].financial_condition.is_salaried==="Yes"?"salaried":"")
    })
  };

  const [datarecalculate, setDataRecalculate] = useState({});
  const handleRecalculate = async () => {
    setOpenModel(true)
    const requestData = { loanNo: customer.application_id};
    console.log(requestData,"requestDatarequestData")
    await loanService.getRecalculate(requestData)
      .then((data) => {
        setDataRecalculate(data)
        console.log('daaaaaaaaaeeeeaaaaaaaaaa',data)
      })
  }
  
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [selectedInsAmt, setSelectedInsAmt] = useState({});
  const [insuredPersonName, setInsuredPersonName] = useState("");
  const [insuredPersonDob, setInsuredPersonDob] = useState("");
  const [insuredPersonGender, setInsuredPersonGender] = useState("");
  const [insuredPersonOccupationType, setInsuredPersonOccupationType] = useState("");
  const handleCustomerSelection = (customerId, customer_name) => {
    // If there is a previously selected customer
    if (selectedCustomerId !== null) {
      // Clear the insurance amounts of the previous customer
      setSelectedInsAmt(prev => {
        const newInsAmt = { ...prev };
        Object.keys(prev).forEach(key => {
          if (key.startsWith(`${selectedCustomerId}-`)) {
            delete newInsAmt[key];
          }
        });
        return newInsAmt;
      });
    }
  
    // Set the new selected customer ID
    setSelectedCustomerId(customerId);
  
    // Fetch the new customer's details and update the insured person information
    customerService.searchCustomer({ customer_id: customerId })
      .then((data) => {
        setInsuredPersonName(customer_name);
        setInsuredPersonDob(data[0].general_details.customer_dob);
        setInsuredPersonGender(data[0].general_details.gender);
        setInsuredPersonOccupationType(data[0].financial_condition.is_salaried === "No" ? "self_employed" : "salaried");
      });
  };
  const handleRadioChange = (customerId, partner, tenureKey, insAmt, pdr, insuranceType) => {
    setSelectedInsAmt(prev => ({
      ...prev,
      [`${customerId}-${partner}`]: { tenure: tenureKey, insAmt, pdr, insuranceType, partnerName: partner },
    }));
  };
  console.log(selectedCustomerId, "selectedCustomerId");
  console.log(selectedInsAmt, "selectedInsAmt");
  let totalInsAmt = 0;
let totalPdr = 0;
let maxTenure = 0;
const insurances = Object.values(selectedInsAmt).map(item => {
  totalInsAmt += item.insAmt;
  totalPdr += item.pdr;
  const tenure = parseInt(item.tenure, 10);
  if (tenure > maxTenure) {
    maxTenure = tenure;
  }
  return {
    insurance_partner: item.partnerName,
    insurance_type: item.insuranceType,
    tenure: parseInt(item.tenure, 10),
    total_ins_amt: item.insAmt,
    total_pdr: item.pdr,
  };
});


console.log('Total Insurance Amount:', totalInsAmt);
console.log('Total PDR:', totalPdr);
console.log(maxTenure,"tenure")

let updatedInsuranceDetails = {
  ...customer.insurance_details,
  insured_person_name:insuredPersonName,
  insured_person_dob:insuredPersonDob,
  insured_person_gender:insuredPersonGender,
  occupation_type:insuredPersonOccupationType,
  PDR: totalPdr,
  policy_term:maxTenure,
  premium_amount: totalInsAmt,
  insurances: insurances,
  nominee_name:nomineeName,
  nominee_gender:nomineeGender,
  nominee_dob:nomineeDob,
  nominee_relationship:nomineeRelationship,
  annual_income:annualIncome,
  amount_insured: customer?.loan?.sanction_amount,
  colender_name:customer?.co_lending[0]?.co_lender_name
};
let updatedLoanDetails = {
  ...customer.loan,
  letter_loan_id:customer.application_id,
  insurance_amount: totalInsAmt,
  insured_applicant: insuredPersonName,
};
let obj = {
  insurance_details: updatedInsuranceDetails,
  loan: updatedLoanDetails,
};


const savereinsurance = async () => {
  if (!nomineeName || !nomineeRelationship || !annualIncome) {
    alert('Please Enter Nominee Name, Nominee Relationship ,Annual Income');
    return; // Stop execution if any required field is empty
}
  try {
    const data = await loanService.update(customer._id, obj);
    console.log(data, "Response from update");
    alert("Data Added Successfully");
    window.location.reload(); // Uncomment if you need to reload the page
  } catch (err) {
    console.log(err);
    alert("Something went wrong!! Please try again");
  }
  
  console.log(obj);
};

const hasInsuranceDetailsData = customer?.insurance_details && 
(Object.keys(customer.insurance_details).length > 0);
  return (
    <>
     <SanctionLetterDetails customer={customer} />
        <br />
        <div>
            {/* create calculate automatically  */}
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style1}>
            <Box id="modal-modal-title" variant="h4" component="h2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              
            <h3>Your insurance has been automatically calculated successfully.</h3>

            <div className="form-row">
<div className="form-group col">
      <label className="required" style={{ color: "rgb(41,73,185)", fontSize: "20px" }}>Nominee Name</label>

     {/* <select style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)", fontSize: "20px" }} name="nominee_name" value={nomineeName} onChange={handleNomineeNameChange} required>
    <option>Choose Name</option>

    {allNames && allNames.map((x) => {
        return <option value={`${x.name}`}>{x.name}</option>
        })}
</select> */}
<select
    style={{
        width: "200px",
        height: "35px",
        color: "rgb(41,73,185)",
        borderColor: "rgb(41,73,185)",
        fontSize: "20px"
    }}
    name="nominee_name"
    value={nomineeName}
    onChange={handleNomineeNameChange}
    required
>
    <option>Choose Name</option>
    {allNames && allNames
        .filter(nameObj => nameObj.name !== usePDR?.data?.customer_name) // Filter out insuredPersonName
        .map((x) => (
            <option key={x.name} value={x.name}>{x.name}</option>
        ))
    }
</select>
    </div>

              

    <div className="form-group col">
                <label className="required" style={{ color: "rgb(41,73,185)", fontSize: "20px" }}>Nominee Gender</label>
                <input style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)", fontSize: "20px" }} name="gender" type="text" value={nomineeGender} readOnly />
            </div>

            <div className="form-group col">
                <label className="required" style={{ color: "rgb(41,73,185)", fontSize: "20px" }}>Nominee DOB</label>
                <input style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)", fontSize: "20px" }} name="gender" type="date" value={nomineeDob}  />
            </div>
            <div className="form-group col">
                    <label className="required" style={{ color: "rgb(41,73,185)", fontSize: "20px" }}>Nominee Relationship</label>
                   
                <select  style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)", fontSize: "20px" }} name="nominee_relationship" value={nomineeRelationship} onChange={handleNomineeRelationshipChange} required>
                <option>Select Nominee Relationship</option>
                <option value="Father">Father</option>
                <option value="Mother">Mother</option>
                <option value="Brother">Brother</option>
                <option value="Sister">Sister</option>
                  <option value="Husband">Husband</option>
                  <option value="Wife">Wife</option>
                  <option value="Daughter">Daughter</option>
                  <option value="Son">Son</option>	
                </select>
                </div>

                <div className="form-group col">
                    <label className="required" style={{ color: "rgb(41,73,185)", fontSize: "20px" }}>Annual Income</label>
                    <input style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)", fontSize: "20px" }} name="annual_income" type="number" value={annualIncome} onChange={handleAnnualIncomeChange} required/>
                </div>
            </div>


            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <Button onClick={() => saveInsuranceAmt(customer._id,customer.application_id,usePDR.data.customer_name,usePDR.data.final_insurance,usePDR.data.tag,usePDR.data.final_pdr,usePDR.data,usePDR.data.tenure)} color='primary' variant='contained' style={{ marginLeft: '10px' }}>Save Insurance</Button>
            </div>
            </Box>
        </Box>
    </Modal>
</div>


        <div>
          {/* create calculate Manual  */}
        <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <Box id="modal-modal-title" variant="h6" component="h2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h4 style={{color:'blue'}}>Please select one Action Button to Get Approval</h4>
              
<table style={{ borderCollapse: 'collapse', border: '2px solid black', width: '100%' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontSize: '1.2rem' }}>Customer ID</th>
            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontSize: '1.2rem' }}>Name</th>
            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontSize: '1.2rem' }}>PDR</th>
            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontSize: '1.2rem' }}>Premium Amount</th>
            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontSize: '1.2rem' }}>Tenure</th>
            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontSize: '1.2rem' }}>Action</th>
          </tr>
        </thead>
        <tbody>
        
        {usePDRS && Object.keys(usePDRS?.data)?.map((key) => {
          const data = usePDRS?.data[key];
          const { final_ins_amt, final_pdr, customer_name,tenure } = data?.find(
            (item) => item.hasOwnProperty('final_ins_amt')
          );

          return (
            <tr key={key}>
              <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontSize: '1.2rem' }}>{key}</td>
              <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontSize: '1.2rem' }}>{customer_name}</td>
              <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontSize: '1.2rem' }}>{final_pdr}</td>
              <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontSize: '1.2rem' }}>{final_ins_amt}</td>
              <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontSize: '1.2rem' }}>{tenure}</td>
              <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontSize: '1.2rem' }}>
                <input
                  type="radio"
                  name="option"
                  style={checkboxStyle}
                  onChange={() => handleRowSelect(key, { final_ins_amt, final_pdr, customer_name ,tenure})}
                />
              </td>
            </tr>
          );
        })}
     
        </tbody>
      </table>
      <div className="form-row">
<div className="form-group col">
      <label className="required" style={{ color: "rgb(41,73,185)", fontSize: "20px" }}>Nominee Name</label>

     {/* <select style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)", fontSize: "20px" }} name="nominee_name" value={nomineeName} onChange={handleNomineeNameChange} required>
    <option>Choose Name</option>

    {allNames && allNames.map((x) => {
        return <option value={`${x.name}`}>{x.name}</option>
        })}
</select> */}
<select
    style={{
        width: "200px",
        height: "35px",
        color: "rgb(41,73,185)",
        borderColor: "rgb(41,73,185)",
        fontSize: "20px"
    }}
    name="nominee_name"
    value={nomineeName}
    onChange={handleNomineeNameChange}
    required
>
    <option>Choose Name</option>
    {allNames && allNames
        .filter(nameObj => nameObj.name !== selectcustomer_name) // Filter out insuredPersonName
        .map((x) => (
            <option key={x.name} value={x.name}>{x.name}</option>
        ))
    }
</select>
    </div>

              

    <div className="form-group col">
                <label className="required" style={{ color: "rgb(41,73,185)", fontSize: "20px" }}>Nominee Gender</label>
                <input style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)", fontSize: "20px" }} name="gender" type="text" value={nomineeGender} readOnly />
            </div>

            <div className="form-group col">
                <label className="required" style={{ color: "rgb(41,73,185)", fontSize: "20px" }}>Nominee DOB</label>
                <input style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)", fontSize: "20px" }} name="gender" type="date" value={nomineeDob}  />
            </div>
            <div className="form-group col">
                    <label className="required" style={{ color: "rgb(41,73,185)", fontSize: "20px" }}>Nominee Relationship</label>
                    {/* <input style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)", fontSize: "20px" }} name="nominee_relationship" type="text" value={nomineeRelationship} onChange={handleNomineeRelationshipChange} required/> */}
                <select  style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)", fontSize: "20px" }} name="nominee_relationship" value={nomineeRelationship} onChange={handleNomineeRelationshipChange} required>
                <option>Select Nominee Relationship</option>
                <option value="Father">Father</option>
                <option value="Mother">Mother</option>
                <option value="Brother">Brother</option>
                <option value="Sister">Sister</option>
                  <option value="Husband">Husband</option>
                  <option value="Wife">Wife</option>
                  <option value="Daughter">Daughter</option>
                  <option value="Son">Son</option>	
                </select>
                </div>

                <div className="form-group col">
                    <label className="required" style={{ color: "rgb(41,73,185)", fontSize: "20px" }}>Annual Income</label>
                    <input style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)", fontSize: "20px" }} name="annual_income" type="number" value={annualIncome} onChange={handleAnnualIncomeChange} required/>
                </div>
            </div>


            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', width: '100%' }}>
              <Button onClick={() => getApprovalInsAmt(customer._id,customer.application_id,usePDRS.message,usePDRS.data)} color='primary' variant='contained' style={{ marginLeft: '10px' }}>Get Approval</Button>
              <Button onClick={handleClose1} color='primary' variant='contained' style={{ marginLeft: '10px' }}>Re-Calculate Insurance</Button>
            </div>
            </Box>
          </Box>
        </Modal>
      </div>

      <div>
        {/* create calculate Special */}
    <Modal
        open={open3}
        onClose={handleClose3}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style1}>
            <Box id="modal-modal-title" variant="h4" component="h2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              
            <h3>Your insurance has been automatically calculated successfully.</h3>

            <div className="form-row">
<div className="form-group col">
      <label className="required" style={{ color: "rgb(41,73,185)", fontSize: "20px" }}>Nominee Name</label>

     {/* <select style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)", fontSize: "20px" }} name="nominee_name" value={nomineeName} onChange={handleNomineeNameChange} required>
    <option>Choose Name</option>

    {allNames && allNames.map((x) => {
        return <option value={`${x.name}`}>{x.name}</option>
        })}
</select> */}
<select
    style={{
        width: "200px",
        height: "35px",
        color: "rgb(41,73,185)",
        borderColor: "rgb(41,73,185)",
        fontSize: "20px"
    }}
    name="nominee_name"
    value={nomineeName}
    onChange={handleNomineeNameChange}
    required
>
    <option>Choose Name</option>
    {allNames && allNames
        .filter(nameObj => nameObj.name !== usePDR?.data?.customer_name) // Filter out insuredPersonName
        .map((x) => (
            <option key={x.name} value={x.name}>{x.name}</option>
        ))
    }
</select>
    </div>

              

    <div className="form-group col">
                <label className="required" style={{ color: "rgb(41,73,185)", fontSize: "20px" }}>Nominee Gender</label>
                <input style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)", fontSize: "20px" }} name="gender" type="text" value={nomineeGender} readOnly />
            </div>

            <div className="form-group col">
                <label className="required" style={{ color: "rgb(41,73,185)", fontSize: "20px" }}>Nominee DOB</label>
                <input style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)", fontSize: "20px" }} name="gender" type="date" value={nomineeDob}  />
            </div>
            <div className="form-group col">
                    <label className="required" style={{ color: "rgb(41,73,185)", fontSize: "20px" }}>Nominee Relationship</label>
                    {/* <input style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)", fontSize: "20px" }} name="nominee_relationship" type="text" value={nomineeRelationship} onChange={handleNomineeRelationshipChange} required/> */}
                <select  style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)", fontSize: "20px" }} name="nominee_relationship" value={nomineeRelationship} onChange={handleNomineeRelationshipChange} required>
                <option>Select Nominee Relationship</option>
                <option value="Father">Father</option>
                <option value="Mother">Mother</option>
                <option value="Brother">Brother</option>
                <option value="Sister">Sister</option>
                  <option value="Husband">Husband</option>
                  <option value="Wife">Wife</option>
                  <option value="Daughter">Daughter</option>
                  <option value="Son">Son</option>	
                </select>
                </div>

                <div className="form-group col">
                    <label className="required" style={{ color: "rgb(41,73,185)", fontSize: "20px" }}>Annual Income</label>
                    <input style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)", fontSize: "20px" }} name="annual_income" type="number" value={annualIncome} onChange={handleAnnualIncomeChange} required/>
                </div>
            </div>


            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Button onClick={() => saveInsuranceAmt(customer._id,customer.application_id,usePDR.data.customer_name,usePDR.data.final_insurance,usePDR.data.tag,usePDR.data.final_pdr,usePDR.data,usePDR.data.tenure)} color='primary' variant='contained' style={{ marginLeft: '10px' }}>Save Insurance</Button>
            </div>
            </Box>
        </Box>
    </Modal>

{/* create Recalculate Insurance */}
    <Modal
        open={openModel}
        onClose={handleCloseModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style3}>
            <Box id="modal-modal-title" variant="h4" component="h2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              
            <h3>Recalculate Insurance </h3>
            <p style={{fontSize:"18px",color:"bule"}}>Insured Person : <b>{insuredPersonName}</b>, Insurance Amount : <b>{totalInsAmt}</b>,PDR : <b>{totalPdr}</b>, Tenure : <b>{maxTenure}</b></p>
            <table style={{ borderCollapse: 'collapse', border: '2px solid black', width: '100%' }}>
      <thead>
        <tr>
          <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontSize: '1.2rem' }}>Customer Name</th>
          <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontSize: '1.2rem' }}>Age</th>
          <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontSize: '1.2rem' }}>Insurance Type</th>
          <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontSize: '1.2rem' }}>Tenure/Insurance Amount/PDR</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(datarecalculate).map((customerId) => {
          const customer = datarecalculate[customerId];
          const insurancePartners = customer.insurance_partner;

          return (
            <React.Fragment key={customerId}>
              {Object.keys(insurancePartners).map((partner, partnerIndex) => {
                const partnerData = insurancePartners[partner];
                const tenures = partnerData.tenure;
                const tenureKeys = Object.keys(tenures);

                return (
                  <tr key={`${customerId}-${partner}`}>
                    {partnerIndex === 0 && (
                      <>
                        <td rowSpan={Object.keys(insurancePartners).length} style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontSize: '1.2rem' }}>
                          <label>
                            <input
                              type="radio"
                              name="customerSelection"
                              checked={selectedCustomerId === customerId}
                              onChange={() => handleCustomerSelection(customerId,customer.customer_name)}
                            />
                            {customer.customer_name}
                          </label>
                        </td>
                        <td rowSpan={Object.keys(insurancePartners).length} style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontSize: '1.2rem' }}>
                          {customer.customer_age}
                        </td>
                        {/* {totalInsAmt && <td rowSpan={Object.keys(insurancePartners).length} style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontSize: '1.2rem' }}>
                          {totalInsAmt}
                        </td>}
                        {totalPdr && <td rowSpan={Object.keys(insurancePartners).length} style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontSize: '1.2rem' }}>
                          {totalPdr}
                        </td>} */}
                      </>
                    )}
                    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontSize: '1.2rem' }}>
                      {partnerData.insurance_type}
                    </td>
                    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontSize: '1.2rem' }}>
                      <div style={{ display: 'grid', gridTemplateColumns: `repeat(${tenureKeys.length + 1}, 1fr)`, gap: '1px' }}>
                        {/* Tenure Header */}
                        <div style={{ border: '1px solid black', padding: '3px', fontWeight: 'bold' }}>Tenure</div>
                        {tenureKeys.map((tenure) => (
                          <div key={`${customerId}-${partner}-${tenure}-tenure`} style={{ border: '1px solid black', padding: '3px' }}>
                            {tenure}
                          </div>
                        ))}

                        {/* Insurance Amount Header */}
                        <div style={{ border: '1px solid black', padding: '3px', fontWeight: 'bold' }}>Ins. Amt</div>
                        {tenureKeys.map((tenure) => (
                          <div key={`${customerId}-${partner}-${tenure}-amt`} style={{ border: '1px solid black', padding: '3px' }}>
                            {tenures[tenure].ins_amt}
                          </div>
                        ))}

                        {/* PDR Header */}
                        <div style={{ border: '1px solid black', padding: '3px', fontWeight: 'bold' }}>Select</div>
                        {tenureKeys.map((tenure) => (
                          <div key={`${customerId}-${partner}-${tenure}-radio`} style={{ border: '1px solid black', padding: '3px' }}>
                            {selectedCustomerId === customerId && (
                              // <input
                              //   type="radio"
                              //   style={checkboxStyle1}
                              //   name={`${customerId}-${partner}`}
                              //   checked={selectedInsAmt[`${customerId}-${partner}`]?.tenure === tenure}
                              //   onChange={() => handleRadioChange(customerId, partner, tenure, tenures[tenure].ins_amt, tenures[tenure].pdr)}
                              // />
                              <input
                              type="radio"
                              style={checkboxStyle1}
                              name={`${customerId}-${partner}`}
                              checked={selectedInsAmt[`${customerId}-${partner}`]?.tenure === tenure}
                              onChange={() => handleRadioChange(customerId, partner, tenure, tenures[tenure].ins_amt, tenures[tenure].pdr, partnerData.insurance_type)}
                            />
                            )}
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
    <div className="form-row">
<div className="form-group col">
      <label className="required" style={{ color: "rgb(41,73,185)", fontSize: "20px" }}>Nominee Name</label>

     {/* <select style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)", fontSize: "20px" }} name="nominee_name" value={nomineeName} onChange={handleNomineeNameChange} required>
    <option>Choose Name</option>
    {allNames && allNames.map((x) => {
        return <option value={`${x.name}`}>{x.name}</option>
        })}
</select> */}
<select
    style={{
        width: "200px",
        height: "35px",
        color: "rgb(41,73,185)",
        borderColor: "rgb(41,73,185)",
        fontSize: "20px"
    }}
    name="nominee_name"
    value={nomineeName}
    onChange={handleNomineeNameChange}
    required
>
    <option>Choose Name</option>
    {allNames && allNames
        .filter(nameObj => nameObj.name !== insuredPersonName) // Filter out insuredPersonName
        .map((x) => (
            <option key={x.name} value={x.name}>{x.name}</option>
        ))
    }
</select>

    </div>
    <div className="form-group col">
                <label className="required" style={{ color: "rgb(41,73,185)", fontSize: "20px" }}>Nominee Gender</label>
                <input style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)", fontSize: "20px" }} name="gender" type="text" value={nomineeGender} readOnly />
            </div>

            <div className="form-group col">
                <label className="required" style={{ color: "rgb(41,73,185)", fontSize: "20px" }}>Nominee DOB</label>
                <input style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)", fontSize: "20px" }} name="gender" type="date" value={nomineeDob}  />
            </div>
            <div className="form-group col">
                    <label className="required" style={{ color: "rgb(41,73,185)", fontSize: "20px" }}>Nominee Relationship</label>
                   
                <select  style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)", fontSize: "20px" }} name="nominee_relationship" value={nomineeRelationship} onChange={handleNomineeRelationshipChange} required>
                <option>Select Nominee Relationship</option>
                <option value="Father">Father</option>
                <option value="Mother">Mother</option>
                <option value="Brother">Brother</option>
                <option value="Sister">Sister</option>
                  <option value="Husband">Husband</option>
                  <option value="Wife">Wife</option>
                  <option value="Daughter">Daughter</option>
                  <option value="Son">Son</option>	
                </select>
                </div>

                <div className="form-group col">
                    <label className="required" style={{ color: "rgb(41,73,185)", fontSize: "20px" }}>Annual Income</label>
                    <input style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)", fontSize: "20px" }} name="annual_income" type="number" value={annualIncome} onChange={handleAnnualIncomeChange} required/>
                </div>
            </div>
      <div style={{marginTop:"1rem", display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Button  onClick={() => savereinsurance()} color='primary' variant='contained' style={{ marginLeft: '10px' }}>Save Insurance</Button>
            </div>
            </Box>
        </Box>
    </Modal>
</div>
          

      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        id="transition-modal-description"
      >
        {({ errors, touched, isSubmitting, setFieldValue, values }) => {
          return (<Form>
          {/* {customer.stage==="Credit" || customer.stage==="Sales"?"": roleChecker.isHOLevelAccess(user.role) && <Button onClick={handleOpen} style={{ marginTop: "2%", marginBottom: "2%",color: 'black', border: "2px solid black", height: '50px', background: '#04AA6D' }}>
                            {isInsAmount ? "Re-Calculate Insurance" : "Calculate Insurance"}
            </Button>} */}
             {customer.stage==="Credit" || customer.stage==="Sales"?"": roleChecker.isHOLevelAccess(user.role) && <Button onClick={handleOpen} style={{ marginTop: "2%", marginBottom: "2%",color: 'White',fontWeight:"bold", height: '50px', background: '#04AA6D' }}>
             Calculate Insurance
            </Button>}
            {hasInsuranceDetailsData && (roleChecker.isGMLevelAccess(user.role) || [20859].includes(user.emp_id)) && ( <><Button  onClick={handleRecalculate}  style={{marginLeft:"2rem", marginTop: "2%", marginBottom: "2%",color: 'White',  height: '50px', fontWeight:"bold",background: '#3f51b5' }}>Recalculate Insurance</Button><span style={{marginLeft:"0.5rem",color:"red",fontSize:"14px"}}>*If the customer disagrees with the insurance amount, click here to recalculate.</span></>  )}
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Loan Details</StyledTableCell>
                    <StyledTableCell align="right">
                      {!editing && (
                        <a
                          onClick={() => startEditing(values, setFieldValue)}>Edit</a>
                      )}
                      {editing && (
                        <a
                          onClick={() => saveEdits(values)}>Save</a>
                      )}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
 
                  {rows.map((row) => (
                    <StyledTableRow key={row.label}>
                      <StyledTableCell component="th" scope="row">
                      {row.label}
                      </StyledTableCell>
                      {!editing && (
                        row.type === "date" ? <StyledTableCell align="right">{datehelper.displayDate(new Date(row.value))}</StyledTableCell> :
                         ( row.label===
                          "Stamp Duty"? <StyledTableCell align="right" > {row.value===0?"Customer":"Kaabil Finance"}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {row.value}</StyledTableCell> : <StyledTableCell align="right">{row.value}</StyledTableCell> )
                      )}
                      {/* {editing && row.editable && (row.type === "text" || row.type === "number" ) && (row.label !== "Insurance Amount") && ( */}
                      {editing && row.editable && (row.type === "text" || row.type === "number" )&& (
                        <>
                          <StyledTableCell align="right">
                            <FormElement
                              type={row.type}
                              name={row.key}
                              label={row.label}
                              errors={errors}
                              touched={touched}
                              onKeyUp={() => calculations(values, setFieldValue)}
                            />
                          </StyledTableCell>
                        </>
                      )}

                      {editing && row.editable && row.type === "date" && (
                        <StyledTableCell align="right">
                          <FormElement
                            type={row.type}
                            name={row.key}
                            label={row.label}
                            errors={errors}
                            touched={touched}
                          />
                        </StyledTableCell>
                      )}
                      {editing && row.editable && row.type === "select" && (
                        <StyledTableCell align="right">
                          <Field
                            as="select"
                            required={row.key === "loan.bt_type"}
                            name={row.key}
                            label={row.label}
                            errors={errors}
                            touched={touched}
                            onChange={(e) => row.key === "loan.loan_type" ? setBtLoan(values, setFieldValue, e.target.value) : setType(values, setFieldValue, e.target.value)}
                          >
                            <option disabled selected>select option</option>
                            {(row.options && row.options.map((o) => {
                              return <option value={o}>{o}</option>
                            }))}

                          </Field>
                          {btType === "Internal" && row.key === "loan.bt_type" && (<FindBtLoanNumber checkInternalBtLoan={checkInternalBtLoan} existBtLoan={existBtLoan} setExistBtLoan={setExistBtLoan} setFieldValue={setFieldValue} warn={warn} addToField={addToField} />)}
                        </StyledTableCell>
                      )}

                      {editing && !row.editable && row.label !== "Stamp Duty" && (
                        <StyledTableCell align="right">
                          <FormElement
                            type="text"
                            disabled={true}
                            name={row.key}
                            label={row.label}
                            errors={errors}
                            touched={touched}
                          />
                        </StyledTableCell>
                      )}
                      {editing && !row.editable && row.key === "loan.stamp_duty" && (
                        <StyledTableCell align="right">
                          <Field
                            as="select"
                            name={row.key}
                            label={row.label}
                            errors={errors}
                            touched={touched}
                            value={stampDutyPerson}
                            onChange={(e) =>{ console.log("Selected Value:", e.target.value); setStampDutyOption(values,e.target.value, setFieldValue)}}
                          ><option   value="">Select</option>
                            <option value="Customer">Customer</option>
                            <option value="Company">Company</option>
                          </Field>
                          <FormElement
                            type="number"
                            disabled={true}
                            name={row.key}
                            label={row.label}
                            errors={errors}
                            touched={touched}
                          />
                        </StyledTableCell>
                      )} 

          

                      </StyledTableRow>
                  ))}

                </TableBody>
              </Table>
            </TableContainer>
          </Form>)
        }}
      </Formik>
    </>
  );
}

function FindBtLoanNumber({ checkInternalBtLoan, existBtLoan, setExistBtLoan, setFieldValue, warn, addToField }) {

  const [open, setOpen] = useState(false)
  const [loan, setLoan] = useState('')


  const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    height: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    //overflow: 'scroll',
    //paddingBottom: '30px',
  };

  const handleOpen = () => {
    setOpen(true)

  }
  const handleClose = () => {
    setOpen(false)
    setExistBtLoan('')
  }


  return <div>
    <Button style={{ marginTop: "3%", backgroundColor: "#5C5CFF" }} onClick={handleOpen}>Add Loan Number</Button>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styles}>
        <h4 style={{ textAlign: "center" }}>Search Loan Number</h4>
        <form>
          <TextField style={{ marginTop: "10%" }} placeholder="Enter Loan Number" type={"text"} onChange={(e) => setLoan(e.target.value)} />
          <Button style={{ marginTop: "10%", marginLeft: "30%", backgroundColor: "#5C5CFF", color: "white" }} onClick={(e) => checkInternalBtLoan(loan)}>Search</Button>
        </form>

        {warn && warn !== "" && (<p style={{ color: "red", marginTop: "10%", textAlign: "center" }}>{warn}</p>)}

        {existBtLoan && existBtLoan !== "" && (<p style={{ marginTop: "10%" }}>Name:- {existBtLoan}<br /><br /> <Button style={{ color: "white", backgroundColor: "green" }} onClick={() => addToField(setFieldValue, loan, setOpen)}>Add to field</Button> </p>)}

      </Box>
    </Modal>
  </div>
}