import React from "react"
import { TableContainer } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { adminservice } from "../../../_services/admin.services";
import {Edit, Add } from "@material-ui/icons";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
    root: {
      width: "100%",
    },
    container: {
      maxHeight: 1000,
    },
  });

const CityList=({match})=> {
    const { path } = match;
    const [cityList, setCityList] = React.useState([]);
    React.useEffect(() => {
        adminservice.getCityDetails().then((x) => {
            setCityList(x);
      });
  
    }, []);
  
  
    const classes = useStyles();
    // console.log('cities are ',cityList)
    return (
          <div className="justify-content-between">
              <div className="row d-flex" style={{"margin":"10px"}}>
                <h1>
                  Cities &nbsp;
                  <Button variant="outlined" aria-label="increase" href={`${path}/add`}>
                    <Add fontSize="small" />
                  </Button>
                </h1>
              </div>
              <div className="row d-flex" style={{"margin":"10px"}}>
              <TableContainer className={classes.container}>
                <Table>
                <Thead>
                    <Tr>
                    
                     
                    <Th style={{ width: "15%" }}>City</Th>
                    <Th style={{ width: "10%" }}>Cluster</Th>
                    <Th style={{ width: "10%" }}>Area code</Th>
                    <Th style={{ width: "10%" }}>District</Th>
                    <Th style={{ width: "10%" }}>State
                    </Th>
                    <Th style={{ width: "10%" }}>Action</Th>
                    
                    </Tr>
                </Thead>
                <Tbody>
                {cityList.map((item, idx) => (
                  <tr key={idx}>
                  <td>{item.city}</td>
                  <td>{item.cluster}</td>
                  <td>{item.area}</td>
                  <td>{item.district}</td>
                  <td>{item.state}</td>
                  <td><Link
                    to={`${path}/edit/${item._id}`}
                    className="btn btn-sm btn-primary mr-1"
                  >
                    <Edit />
                  </Link></td>
                  </tr>
              ))}
                    </Tbody>
                </Table>
              </TableContainer>
              </div>
        </div>
    );
}

export default CityList;