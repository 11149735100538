import { Box, Modal, Table } from '@material-ui/core'
import { Button } from 'antd'
import React from 'react'
import { useState } from 'react'
import { Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table'
import datehelper from '../../../_helpers/datehelper'
export const NonReconcilePaymentModal = (props) => {
  console.log("test closer Loans", props.loanData)
  const data = props.loanData
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  } 
  const handleClose = () => {
    setOpen(false)
  }
  let sumofOnlinePayment = data.online_payments.reduce((a, b) => a + parseInt(b.txn_amt), 0)
  let sumofCashPayment = data.cash_payments.reduce((a, b) => a + parseInt(b.payment), 0)

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflow: 'scroll',
    paddingBottom: '30px',
  }

  return (
    <div>
      <Button onClick={handleOpen} style={{ color: "blue" }}>
        VIEW PAYMENT
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
      >
        <Box sx={style}>
          <div style={{ margin: "40px", marginLeft: "40px" }}>
            <h3 style={{ textAlign: 'center', color: "black" }}>Non Reconcile Payment Details</h3>
            <br />
            <div style={{display:'flex',justifyContent:'space-around'}}>
            <div>
              <br/>
              <h5>Loan No:{data.loan_account}</h5>
              <br/>
              <h5>Customer Name:{data.name}</h5>
              <br/>
              <h5>Mobile No:{data.verification_details.contact}</h5>
            </div>
            <div >
              <img src={data.verification_details.photo}
              alt="Customer pic"
              style={{border:'1px solid blue',borderRadius:'4px',height:'215px',width:"300px"}}
              />
             </div>
            </div>
            <hr/>
            {data.online_payments.length > 0 &&
              <div>
                <h5 style={{ color: "black" }}>Online Payment</h5>
                <Table style={{ width: "100%", marginBottom: '40px' }}>
                  <thead>
                    <Tr>
                      <Th style={{ border: "1px solid " }}>S.N</Th>
                      <Th style={{ border: "1px solid " }}>Date</Th>
                      <Th style={{ border: "1px solid " }}>Utr No</Th>
                      <Th style={{ border: "1px solid " }}>Amount</Th>
                    </Tr>
                  </thead>
                  <Tbody >
                    {
                      data.online_payments.map((x, i) => <Tr key={i}>
                        <Td style={{ border: "1px solid " }}>{i + 1}</Td>
                        <Td style={{ border: "1px solid " }}>{datehelper.displayDate(new Date(x.updated))}</Td>
                        <Td style={{ border: "1px solid " }}>{x.utr_num}</Td>
                        <Td style={{ border: "1px solid " }}>{x.txn_amt}</Td>
                      </Tr>)
                    }
                  </Tbody>
                  <Tr style={{ border: "1px solid " }}>
                    <Td colspan="3" style={{ textAlign: "center", fontWeight: "bold" }}>Total</Td>
                    <Td style={{ fontWeight: "bold", border: "1px solid " }}>{sumofOnlinePayment}</Td>
                  </Tr>
                </Table>
              </div>
            }
            {data.cash_payments.length > 0 &&
              <div>
                <hr />
                <h5 style={{ color: "black" }}>Cash Payment</h5>
                <Table style={{ width: "100%", marginBottom: '40px' }}>
                  <thead>
                    <Tr>
                      <Th style={{ border: "1px solid " }}>S.N</Th>
                      <Th style={{ border: "1px solid " }}>Date</Th>
                      <Th style={{ border: "1px solid " }}>Receipt No</Th>
                      <Th style={{ border: "1px solid " }}>Amount</Th>
                    </Tr>
                  </thead>
                  <Tbody >
                    {
                      data.cash_payments.map((x, i) => <Tr key={i}>
                        <Td style={{ border: "1px solid " }}>{i + 1}</Td>
                        <Td style={{ border: "1px solid " }}>{datehelper.displayDate(new Date(x.date))}</Td>
                        <Td style={{ border: "1px solid " }}>{x.receipt_no}</Td>
                        <Td style={{ border: "1px solid " }}>{x.payment}</Td>
                      </Tr>)
                    }
                  </Tbody>
                  <Tr style={{ border: "1px solid " }}>
                    <Td colspan="3" style={{ textAlign: "center", fontWeight: "bold" }}>Total</Td>
                    <Td style={{ fontWeight: "bold", border: "1px solid " }}>{sumofCashPayment}</Td>
                  </Tr>
                </Table>
              </div>
            }
            <hr />
            <h5>Interest Calculator Due Amount:₹{data.interest_due_amount}/-</h5>
            <hr />
            <h5>Total Non Reconcile Payment:₹{data.total_non_reconcile_payment}/-</h5>
            <hr />
            <h5>Net Due Amount:₹{data.net_due_amount}/-</h5>
            <hr />
          </div>
        </Box>
      </Modal>
    </div>
  )
}
