import React from "react";
// import logo from "../assets/kaabil.png";
// import AuthorityDoc from "./AuthorityDoc";
import "./submission.css";
import logo from "../../../assets/kaabil.png";
import datehelper from "../../../_helpers/datehelper";
function reformatDate(dateStr) {
  var dArr = dateStr.split("T")[0].split("-");
  return dArr[2] + "/" + dArr[1] + "/" + dArr[0];
}

export default class SubmissionDoc extends React.Component {
  render() {
    console.log("props", this.props)
    const date = new Date()
    const data = this.props.dataFill;
    const contact = data.contact
    // function getAge(DOB) {
    //   var today = new Date();
    //   var birthDate = new Date(DOB);
    //   var age = today.getFullYear() - birthDate.getFullYear();
    //   var m = today.getMonth() - birthDate.getMonth();
    //   if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    //     age = age - 1;
    //   }

    //   return age;
    // }

    return (
      <div className="submission">
        <style type="text/css" media="print">
          {"\
   @page { size: A4; }\
"}
        </style>
        <br />
        <div className="main">
          <img src={logo} alt="KFPL Logo" /> <br />
          {/* <b>
            Date: {date.getDate()}-
            {date.toLocaleString("default", { month: "long" })}-
            {date.getFullYear()}
          </b>{" "} */}
          <b>
            Date: {contact ? datehelper.displayDate(date) : datehelper.displayDate(new Date(data.current_date))}
          </b>
          <br />
          <br />
          <div className="right">
            <b>
              {/* {contact ?
                `KFPL/GL/${data.colendingBank.toUpperCase()}/
              ${date.toLocaleString("default", { month: "long" })}/${data.letterNumber + 1}` :
                data.letter_number} */}
              {data.letter_number}
            </b>
          </div>
          <p>
            To, <br />
            The Branch Manager, <br />
            {data.colendingBank === "Fed" && "FEDRAL BANK"}
            {data.colendingBank === "CSB" && "CATHOLIC SYRIAN BANK"}
            {data.colendingBank === "SIB" && "THE SOUTH INDIAN BANK LTD"}
            <br />
            {data.colendingBank === "Fed" && "Gopalbari, Jaipur"}
            {data.colendingBank === "CSB" && "Motilal Atal Road , Jaipur"}
            {data.colendingBank === "SIB" && "Jaipur"}
            <br />
            <br />
            <b>Subject:</b> GOLD PACKETS SUBMISSION. <br />
            <br />
            We have handed over the below mentioned Gold Packets to Mr. &nbsp;
            {data.authPerson.name} ({data.authPerson.empId}) whose signatures
            are attested below for delivery to your Branch for safe keeping of
            the jewelry. Kindly verify that gold packets are received in sealed
            condition and the details mentioned below are tallied and
            acknowledge the receipt of the gold packets. <br />
          </p>
          <table style={{ width: "100%" }}>
            <tr>
              <th>S. No</th>
              <th>Name</th>
              <th>KFPL Loan No.</th>
              <th>Pkt. No.</th>
              <th>Loan Amount</th>
              <th>Loan Date</th>
              <th >Description of items</th>
              <th >No</th>
              <th>Gross Weight</th>
              <th>Net Weight</th>
            </tr>
            <tr>
              <td>1.</td>
              <td>{data.name}</td>
              <td>{data.loan_account}</td>
              <td>{data.gold_packet}</td>
              <td>{data.loan_amount}</td>
              <td>{reformatDate(data.date)}</td>
              <td>
                {data.ornaments.map((item) => (
                  <p>{item.desc}</p>
                ))}
              </td>
              <td>
                {data.ornaments.map((item) => (
                  <p>{item.quantity}</p>
                ))}
              </td>
              <td>{data.gross_weight}</td>
              <td>{data.net_weight}</td>
            </tr>
          </table>
          <br />
          <b>Handed Over to:</b> <br />
          {data.authPerson.name} ({data.authPerson.empId}) <br />
          <br />
          <b>Signature</b> <span className="right">OIC</span> <br />
          Sign <br />
          <br />
          <b>Time of Handover:______</b> <span classname="right">MD & CEO</span>{" "}
          <br />
          Sign <br />
          <br />
          <br />
          <br />
          <b>ACKNOWLEDGEMENT</b> <br />
          <br />
          We acknowledge the receipt of the Gold Packets as per the details
          mentioned above (difference if any is mentioned above) and the packets
          were received in sealed condition. <br />
          <br />
          Received by {data.colendingBank === "Fed" ? "(Federal Bank)" : <>({data.authPerson.name} & {data.authPerson.empId}){" "}</>}
          <span className="right">Stamp</span> <br />
          <br />
          Signature
          <br />
          <br />
          <br />
          <br />
        </div>
        <div className="main">
          <h3>
            {data.bank === "Kaabil" ? "DIGI BIZ- " : ""}STATEMENT OF RECEIVABLES
          </h3>
          <br />
          <div>Date:</div>
          <br />
          <p>
            I /We hereby declare that as on this date, I / we have the following
            list of borrowers who have pledged gold ornaments / coins with us to
            the amount as listed herein.
          </p>
          <table style={{ width: "100%" }}>
            <tr>
              <th>S. No</th>
              <th>Name of the Borrower</th>
              <th >Description of items</th>
              <th >No</th>
              <th>Gross Weight</th>
              <th>Net Weight</th>
              <th>Total Amount Lent</th>
            </tr>
            <tr>
              <td>1.</td>
              <td>{data.name}</td>
              <td>
                {data.ornaments.map((item) => (
                  <p>{item.desc}</p>

                ))}
              </td>
              <td>
                {data.ornaments.map((item) => (
                  <p>{item.quantity}</p>
                ))}
              </td>
              <td>{data.gross_weight}</td>
              <td>{data.net_weight}</td>
              <td>{data.loan_amount}</td>
            </tr>
          </table>
          <br />
          <p>
            The gold items having total weight as described above are available
            as continuing security toward the overdraft facility granted by the
            bank.
          </p>
          <br />
          <br />
          Name & Signature of the Applicant
          <br />
          <br />
        </div>
      </div>
    );
  }
}
