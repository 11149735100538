import React, { useEffect, useState } from 'react'
import { sblLetter } from '../../../_services/letter.services'
import { Table, Thead,Th,Td,Tr,Tbody } from 'react-super-responsive-table'
import datehelper from '../../../_helpers/datehelper'
import { Button } from '@material-ui/core'

const DisbursementList = () => {
    const [disbursementlist,setDisbursementlist]=useState([])
    const getDisbursementlist=async()=>{
        sblLetter.getDisbursementMemoList()
        .then((res)=>setDisbursementlist(res))
        .catch((err)=>console.log(err))
    }
    useEffect(()=>{
        getDisbursementlist()
    },[])
    console.log("test disburseMent",disbursementlist)
  return (
    <div style={{ margin: "30px" }}>
      <h2 style={{ marginBottom: "50px", textAlign: 'center' }}> Disburse Payment Pending</h2>
      <Table>
        <Thead>
          <Tr style={{ background: "#2949B9", color: 'white', height: '50px' }}>
            <Th>Loan Number</Th>
            <Th>Branch</Th>
            <Th>Customer Name</Th>
            <Th>Customer Account Number</Th>
            <Th>Payment Amount</Th>
            <Th>Payment Type</Th>
            <Th>Payment Date</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {disbursementlist.length > 0 ? (
            disbursementlist.map((x, idx) => (
              <React.Fragment key={idx}>
                {x.disbursement.disbursement_memo.map((memo, id) => {
                  const detail = x.disbursement.disbursement_details.find(detail => detail.disbursement_memo_id === memo._id && !detail.disbursed_utr_number);
                  console.log("Test details",detail)
                  if ((memo.paid_status === false && memo.verification===true)|| (memo.paid_status === true && detail && memo.verification===true)) {
                    return (
                      <Tr key={id} style={{ background: '#D9DDF5', height: '50px', fontSize: '18px' }}>
                        <Td>{x.application_id}</Td>
                        <Td>{x.branch[0].branch_name}</Td>
                        <Td>{memo.customer_name}</Td>
                        <Td>{memo.customer_acc_no}</Td>
                        <Td>{memo.payment_amount && memo.payment_amount.toLocaleString("en-IN", { style: 'currency', currency: 'INR' })}</Td>
                        <Td>{memo.payment_type}</Td>
                        <Td>{datehelper.displayDate(new Date(memo.payment_date))}</Td>
                        <Td>
                          <Button component="a"
                            href={`/home/sblloans/details/${x._id}`}
                            size="small"
                            color="primary"
                          >
                         View Details</Button>
                       </Td>
                      </Tr>
                    );
                  } 
                  return null;
                })}
              </React.Fragment>
            ))
          ) : (
            <Tr>
              <Td colSpan={6} align="center">
                Data Not Found
              </Td>
            </Tr>
          )}
          
        </Tbody>
      </Table>
    </div>
  )
}
export default DisbursementList
