import React, {useEffect, useState} from 'react'
import { loanService } from '../../../_services'
import TabBar from './TabBar'
import ApplicationDetails from '../Applications/ApplicationDetails'
import { useLocation } from 'react-router-dom'

const BridgeComponent = ({match}) => {

    const [cust, setCust] = useState(null)
    const [viewType,setViewType]=useState("")

    const location = useLocation()
    const query = new URLSearchParams(location.search);
    const old_view_value = query.get('isold_view')
    const new_view_value = query.get('isnew_view')

    const {id} = match.params

    console.log(id)
    console.log(old_view_value,new_view_value)
    
    let isOldView=false
    if(old_view_value==1)
    {
        isOldView=true
    }

    let isNewView=false
    if(new_view_value==1)
    {
        console.log("Setting new View")
        isNewView=true
    }
    console.log("is old view",isOldView)
    console.log("is new view",isNewView)

  const getApplicant = async () => {
   try {
    const data = await loanService.getById(id)
    setCust(data)
    if(isOldView===true)
    {
        setViewType("OLD");
    }
    else if(isNewView===true)
    {
      setViewType("NEW")
    }
    else{
        if(["Sales","Credit"].includes(data.stage)){
            setViewType("NEW")
        }
        else{
            setViewType("OLD")
        }
    }
   
    console.log(data)
   } catch (error) {
    console.log(error)
   }
  }

  useEffect(() => {
    getApplicant()
  }, [id])
  

  return (
    <>
    {
      console.log(id,"id here",viewType)
    }
    {viewType==="NEW" && (
       <TabBar id={id}/>
     )}
     {viewType ==="OLD" && (
         <ApplicationDetails id={id}/>
     )}
    </>
   
  )
}

export default BridgeComponent