import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
} from "@material-ui/core";
import VisitPhotos from "./VisitPhotos";
import { LeadDetails } from "./LeadDetails";
import AppStatus from "../Legacy/AppStatus";
import { loanService } from "../../../_services/loan.service";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SalesTabBar({ cust}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static"  >
      {cust && (
        <>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          style={{backgroundColor:"#D4F1F4",color:"black"}}
        >
        <Tab label="Lead Details" {...a11yProps(0)} style={{ minWidth: "50%"}} />
        <Tab label="Visit Photos" {...a11yProps(1)} style={{ minWidth: "50%" }} />
        </Tabs>
        </>
     
      )}
         </AppBar>
      <TabPanel value={value} index={0}>
        {cust && <LeadDetails cust={cust}></LeadDetails>}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {cust && <VisitPhotos images={cust.images}></VisitPhotos>}
      </TabPanel>
    </div>
  );
}