import React from 'react';
import {useEffect} from 'react';
import {
    useFormikContext,
    Formik,
    Field,
    Form,
    ErrorMessage,
    getIn,
    useField,
} from "formik";
import { Button, Paper, Typography } from "@material-ui/core";
import Webcam from "react-webcam";
import { uploadFile } from '../../_helpers/upload';
import FormElement from '../uielements/FormElement';
import { useParams } from "react-router-dom";
import { branchService } from '../../_services/branch.service';
import { kaabilService } from '../../_services/kaabil.service';
import { customerService } from "../../_services";
import { employeeService } from '../../_services';
import JobSheet from '../GoldLoan/JobSheetCSB/JobSheet';
import { misService } from '../../_services';
import * as Yup from "yup";

const Loan = (props) => {

    const formikRef = React.useRef();
    const user = employeeService.userValue;
    const [branches, setBranches] = React.useState([]);
    const [totalOrnaments, setTotalOrnaments] = React.useState([]);
    const [loanno, setLoanno] = React.useState("");
    // const [value, setValue] = React.useState()
    const custcamRef = React.useRef(null);
    const goldcamRef = React.useRef(null);
    const itemsRef = React.useRef([]);
    const query = new URLSearchParams(props.location.search);
    const renewOf=(query.get("renew_of")?query.get("renew_of"):"");
    const {id} = useParams();
    let value=props.location.state;
    
    // async function getCustomers() {
    //     console.log(id);
    //     console.log('hii');
    // await customerService
    //           .findCustomerData({ _id:id })
    //           .then((x) => {
    //             console.log(x);
    //             setValue(x[0]);
    //           })
         
    //         } 

    // useEffect(() => {
    //     getCustomers();
    //   }, []);
    useEffect(() => {
        misService.getLoanNo(user.branch_id).then((result) => {
          setLoanno(result.loan_no);
        });
        branchService.getAll().then((branches) => {
          setBranches(branches);
        });
      }, [user]);

    useEffect(() => {
        if (formikRef.current) {
          formikRef.current.setFieldValue(
            "loan_account",
            "KFPLGL" +
            user.branch_id.slice(0, -3) +
            loanno.toString().padStart(5, 0)
          );
    
        }
      }, [loanno, user]);
     
    const handleUpdate=async(fields)=>{
        let id=value._id
        let new_loan={loan_type:'Gold',loan_account:fields.loan_account}
        let linked_loan=value.linked_loan
        linked_loan.push(new_loan)

       await customerService.updateCustomer(id,{linked_loan:linked_loan})
    }  
    
    
      
    let initialValues = {
        date: new Date(),
        name: {
            title: value.general_details.customer_title,
            first_name:value.general_details.customer_firstname,
            middle_name:value.general_details.cutomer_middlename,
            last_name:value.general_details.customer_lastname,
        },
        gender: value.general_details.gender,
        father: value.general_details.fathername,
        mother:value.general_details.mothername,
        dob:value.general_details.customer_dob,
        citizenship:value.general_details.citizenship,
        birth_place: value.general_details.birthplace,
        adhaar_no:value.verification_details.aadhaar_no,
        pan_no: value.personal_details.pan_no,
        form60:value.economic_details.form60,
        other_pid:value.economic_details.other_pid,
        kyc: "aadhaar",
        marital_status:value.personal_details.marital_status,
        spouse: value.personal_details.name_of_spouce,
        wedding_date: value.personal_details.date_of_marriage,
        mother_tounge: value.personal_details.mother_tounge,
        communication_lang:value.personal_details.communication_lang,
        permanent_address: {
            address_type: value.personal_details.permanent_address.address_type,
            address:value.personal_details.permanent_address.address_line_1,
            city: value.personal_details.permanent_address.city,
            district: value.personal_details.permanent_address.district,
            state: value.personal_details.permanent_address.state,
            pin_code: value.personal_details.permanent_address.pin_code,
        },
        correspondence_address: {
            address_type: value.personal_details.correspondence_address.address_type,
            address:value.personal_details.correspondence_address.address_line_1,
            city: value.personal_details.correspondence_address.city,
            district: value.personal_details.correspondence_address.district,
            state: value.personal_details.correspondence_address.state,
            pin_code: value.personal_details.correspondence_address.pin_code,
        },
        contact: {
            mobile:value.general_details.customer_mobile_num,
            phone: "",
            email: value.general_details.email,
        },
        religion:value.personal_details.religion,
        category: value.personal_details.category,
        education: value.personal_details.educational_qualification,
        physically_challenged: value.personal_details.physically_challenged,
        mentally_challenged: value.personal_details.mentally_challenged,
        annual_income: value.economic_details.annual_income,
        occupation_type: value.economic_details.occupation_type,
        income_source: value.economic_details.source_of_income,
        organisation: value.economic_details.organisation,
        designation: value.economic_details.designation,
        net_worth: value.economic_details.net_worth,
        assets: {
            land: "",
            machinery: "",
            investment: "",
            stock: "",
            gold: "",
            income: "",
            term_deposits: "",
            other_assets: "",
            total_assets: "",
        },
        liabilities: {
            csb_borrowings: "",
            other_borrowings: "",
            other_liabilities: "",
            total_liabilities: "",
            total_net_worth: "",
        },
        branch_name: user.branch.branch_name,
        branch_code: user.branch_id,
        loan_account: "",
        client_id: "",
        product: "Gold Loan",
        period: "",
        roi: "",
        penal_roi: "",
        disbursement_mode: "Cash",
        purpose: "",
        agri_purpose: "",
        bank_account: {
            bank_name: value.economic_details.bank_details.bank_name,
            account_no:value.economic_details.bank_details.account_number,
            ifsc_code: value.economic_details.bank_details.ifsc_code,
            acc_holder: value.economic_details.bank_details.account_holder,
        },
        gold: {
            ornaments: [],
            gross_weight: "",
            net_weight: "",
            total_items: "",
            deduction: "",
            valuation: "",
            eligible_advance: "",
            rpg: "",
        },
        gold_pics: [],
        customer_pic: "",
        gold_pic: "",
        loan_request: "",
        loan_amount: "",
        external_loans: "",
        external_appraisal: "",
        gold_packet: "",
        source_code: "",
        approved: false,

        // Extras
        pf_charge: 0.5,
        pf: 0,
        stamp_duty: 0,
        auction_date: "",
        closure_due_date: "",
        emi: "",
        emi_due_date:'',
        // emi_due_date: new Date().addDays(29).toISOString().substring(0, 10),
        payment_frequency: "EVERY_30_DAYS",
        disburse_amount: "",
        renew_of: renewOf,
        bank: "CSB",
    };
    const validationSchema = () => {
       return Yup.object().shape({
            branch_name: Yup.string().required("Required"),
            branch_code: Yup.string().required("Required"),
            disbursement_mode: Yup.string().required("Required"),
            client_id: Yup.string(),
            product: Yup.string(),
            period: Yup.number().required("Required"),
            roi: Yup.number().required("Required"),
            gold: Yup.object().shape({
                rpg: Yup.number().required("Required"),
            }),
            emi: Yup.number().required("Required"),
            penal_roi: Yup.number().required("Required"),
            purpose: Yup.string().required("Required"),
            loan_request: Yup.number().required("Required"),
            loan_amount: Yup.number().required("Required"),
            external_loans: Yup.string(),
            gold_packet: Yup.string().required("Required")
            .min(8, "minimum 8 digit needed")
            .max(9, "maximum 9 digit allowed"),
            emi_due_date: Yup.date(),
            closure_due_date: Yup.date(),
            auction_date: Yup.date(),
            pf: Yup.number().required("This field is required"),
            payment_frequency: Yup.string().required("This field is required"),
            disburse_amount: Yup.number().required("This field is required")})
    }

    const SetValuationDependent = () => {
        const { values, setFieldValue } = useFormikContext();
        React.useEffect(() => {
          setFieldValue("loan_amount", values.gold.valuation);
          setFieldValue("loan_request", values.gold.valuation);
          setCharges(values, setFieldValue);
        }, [values.gold.valuation]);
        return null;
      };

      
  const SetSanctionDependent = () => {
    const { values, setFieldValue } = useFormikContext();
    React.useEffect(() => {
      updateDisburse(values, setFieldValue);
    }, [values, setFieldValue]);
    return null;
  };

  function setAuctionClosure(vals, setFieldValue) {
    setFieldValue(
      "auction_date",
      new Date(new Date().setMonth(new Date().getMonth() + vals.period))
        .toISOString()
        .substring(0, 10)
    );
    setFieldValue(
      "closure_due_date",
      new Date(new Date().setMonth(new Date().getMonth() + vals.period))
        .toISOString()
        .substring(0, 10)
    );
  }

  function updateDisburse(vals, setFieldValue) {
    let pf=Math.ceil(vals.loan_amount*.006)
    setFieldValue('pf',pf )
    setFieldValue("disburse_amount", Math.round(vals.loan_amount - vals.pf));
    let emi = Math.round(vals.loan_amount*vals.roi/100);
    setFieldValue('emi',emi);
  }

  function setCharges(vals, setFieldValue) {
    const pf = Math.ceil(vals.gold.valuation / 50000) * 250;
    setFieldValue("pf", pf);

    setFieldValue("disburse_amount", Math.round(vals.gold.valuation - pf));
  }

  const videoConstraints = {
    width: 300,
    height: 300,
    facingMode: "user",
  };

  const capture = React.useCallback(
    async (setFieldValue, target, targetcam, loan_no) => {
      var imageSrc;

      if (!loan_no) {
        alert("Please Enter Loan Number");
        return;
      }

      if (targetcam === "cust") imageSrc = custcamRef.current.getScreenshot();
      else imageSrc = goldcamRef.current.getScreenshot();

      const link = await uploadFile(`${loan_no}/${target}.jpg`, imageSrc);

      setFieldValue(target, link);
    },
    [custcamRef, goldcamRef]
  );

  const captureGold = async (setFieldValue, idx, loan_no) => {
    if (!loan_no) {
      alert("Please Enter Loan Number");
      return;
    }

    var imageSrc = itemsRef.current[idx].getScreenshot();

    const link = await uploadFile(`${loan_no}/ornament${idx}.jpg`, imageSrc);

    var newtotal = [...totalOrnaments];

    newtotal[idx].image = link;

    setTotalOrnaments(newtotal);
    setFieldValue("gold_pics", newtotal);
  };

  const discardGold = (setFieldValue, idx) => {
    var newtotal = [...totalOrnaments];
    newtotal[idx].image = "";
    setTotalOrnaments(newtotal);
    setFieldValue("gold_pics", newtotal);
  };

  const discard = React.useCallback((setFieldValue, target) => {
    setFieldValue(target, "");
  }, []);


    async function onSubmit(fields, { setSubmitting }) {

        if (fields.gold.ornaments.length === 0) alert("Please Add Gold Items");
        else if (!fields.customer_pic) alert("Please Upload Customer Picture");
        else if (!fields.gold_pic) alert("Please Upload Ornaments Picture");
        else if (fields.gold.ornaments.length !== fields.gold_pics.length)
          alert("Please Capture all Ornaments");
        else {
          await kaabilService
            .create({
              ...fields,
              total_items: fields.gold.total_items,
              valuation: fields.gold.valuation,
              net_weight: fields.gold.net_weight,
              deduction: fields.gold.deduction,
              ornaments: fields.gold.ornaments,
              rpg: fields.gold.rpg,
              kyc:'aadhaar',
              contact: value.general_details.customer_mobile_num,
              name:
                value.general_details.customer_firstname +
                " " +
                value.general_details.customer_middlename +
                " " +
                value.general_details.customer_lastname,
              address: value.personal_details.correspondence_address.address_line_1,
              gross_weight: fields.gold.gross_weight,
              sonof: value.general_details.fathername,
              details: fields,
              loan_status: "Disbursed",
            })
            .then(()=>handleUpdate(fields))
            .then(() => props.history.push("/home/application/print", fields))
            .catch((err) => {
              console.log(err);
              alert("An Error Occured. Please Try Again.");
            });
          setSubmitting(false);
        }
      } 
    

return (
    <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
    >
        {({ errors, touched, isSubmitting, values, setFieldValue }) => (
            <Form>
                <SetValuationDependent />
                <SetSanctionDependent />
                {/* <SetPermanentStateDependent />
          <SetCorrespondenceStateDependent /> */}
                <div>
                    <div className="form-group col-md-12">
                        <Typography variant="h4">
                            Gold Loan Application cum Ledger Form{" "}
                        </Typography>
                        <br />
                        <Paper
                            style={{
                                padding: 20,
                            }}
                        >
                            <Typography variant="h5">Loan Details</Typography>
                            <br />
                            <div className="d-flex">
                                <div style={{ width: "80%" }}>
                                    <div className="d-flex">
                                        <div className="form-group col-md-6">
                                            <label>Branch Name</label>
                                            <Field
                                                name="branch_name"
                                                as="select"
                                                className={
                                                    "form-control" +
                                                    (errors.branch_name && touched.branch_name
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            >
                                                {branches.map((branch) => (
                                                    <option
                                                        key={branch.branch_id}
                                                        value={branch.branch_name}
                                                    >
                                                        {branch.branch_name}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage
                                                name="branch_name"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label>Branch Code</label>
                                            <Field
                                                name="branch_code"
                                                as="select"
                                                className={
                                                    "form-control" +
                                                    (errors.branch_code && touched.branch_code
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            >
                                                {branches.map((branch) => (
                                                    <option
                                                        key={branch.branch_id}
                                                        value={branch.branch_id}
                                                    >
                                                        {branch.branch_id}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage
                                                name="branch_code"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="form-group col-md-4">
                                            <FormElement
                                                label="Client ID"
                                                name="client_id"
                                                type="text"
                                                errors={errors}
                                                touched={touched}
                                            ></FormElement>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <FormElement
                                                label="Product"
                                                name="product"
                                                type="text"
                                                errors={errors}
                                                touched={touched}
                                            ></FormElement>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <div>
                                                <label>Tenure (Months)</label>
                                                <Field
                                                    name="period"
                                                    as='select'
                                                    className={
                                                        "form-control" +
                                                        (getIn(errors, "period") &&
                                                            getIn(touched, "period")
                                                            ? " is-invalid"
                                                            : "")
                                                    }
                                                    onKeyUp={() =>
                                                        setAuctionClosure(values, setFieldValue)
                                                    }
                                                >
                                                <option value=''>
                                                </option>
                                                <option value='3'>
                                                3
                                                </option>
                                                <option value='6'>
                                                6
                                                </option>
                                                <option value='9'>
                                                9
                                                </option>
                                                <option value='12'>
                                                12
                                                </option>
                                                </Field>
                                                <ErrorMessage
                                                    name="period"
                                                    component="div"
                                                    className="invalid-feedback"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="form-group col-md-4">
                                            <FormElement
                                                label="Interest Rate (%)"
                                                name="roi"
                                                type="number"
                                                errors={errors}
                                                touched={touched}
                                            ></FormElement>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <FormElement
                                                label="Penal Interest Rate (%)"
                                                name="penal_roi"
                                                type="number"
                                                errors={errors}
                                                touched={touched}
                                            ></FormElement>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>Disbursement Mode</label>
                                            <Field
                                                name="disbursement_mode"
                                                as="select"
                                                className={"form-control"}
                                            >
                                                <option value="Cash">Cash</option>
                                                <option value="Cheque">Cheque</option>
                                                <option value="NEFT">NEFT</option>
                                                <option value="RTGS">RTGS</option>
                                                <option value="IMPS">IMPS</option>
                                                <option value="Other">Other</option>
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="form-group col-md-6">
                                            <FormElement
                                                label="Purpose of the Loan"
                                                name="purpose"
                                                type="text"
                                                errors={errors}
                                                touched={touched}
                                            ></FormElement>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>
                                                In case of Agri Loans, Details of activity
                                                undertaken
                                            </label>
                                            <Field
                                                name="agri_purpose"
                                                as="select"
                                                className={"form-control"}
                                            >
                                                <option value=""></option>
                                                <option value="Cultivation of Crops">
                                                    Cultivation of Crops
                                                </option>
                                                <option value="Allied Activity">
                                                    Allied Activity
                                                </option>
                                                <option value="Processing Unit">
                                                    Processing Unit
                                                </option>
                                                <option value="Others">Others</option>
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginLeft: 40 }}>
                                    <Typography
                                        style={{ textAlign: "center", fontSize: 20 }}
                                        variant="caption"
                                        display="block"
                                        gutterBottom
                                    >
                                        Customer
                                    </Typography>
                                    <div className="d-flex justify-content-center">
                                        {values.customer_pic === "" ? (
                                            <Webcam
                                                height={300}
                                                audio={false}
                                                ref={custcamRef}
                                                screenshotFormat="image/jpeg"
                                                width={300}
                                                videoConstraints={videoConstraints}
                                            />
                                        ) : (
                                            <img
                                                src={values.customer_pic}
                                                height="300"
                                                width="300"
                                                alt=""
                                            ></img>
                                        )}
                                    </div>
                                    <br />
                                    <div className="d-flex justify-content-around">
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            disabled={values.customer_pic ? true : false}
                                            type="button"
                                            onClick={() =>
                                                capture(
                                                    setFieldValue,
                                                    "customer_pic",
                                                    "cust",
                                                    values.loan_account
                                                )
                                            }
                                        >
                                            Capture Photo
                                        </Button>
                                        <Button
                                            color="secondary"
                                            type="button"
                                            onClick={() =>
                                                discard(setFieldValue, "customer_pic")
                                            }
                                        >
                                            Discard
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Paper>
                        
                        <Paper
                            style={{
                                padding: 20,
                                marginTop: 30,
                            }}
                        >
                            <Typography variant="h5">Gold Details</Typography>
                            <br />
                            <div style={{ border: "1px dotted black", padding: 15 }}>
                                <JobSheet
                                    totalOrnaments={totalOrnaments}
                                    setTotalOrnaments={setTotalOrnaments}
                                    setFieldValue={setFieldValue}
                                ></JobSheet>
                            </div>
                            <br />
                            <br />
                            <br />
                            <br />
                            <div className="d-flex">
                                <div style={{ width: "80%" }}>
                                    <br />
                                    <div className="d-flex">
                                        <div className="form-group col-md-4">
                                            <FormElement
                                                label="Sanctioned Loan Amount(₹)"
                                                name="loan_amount"
                                                type="number"
                                                errors={errors}
                                                touched={touched}
                                            ></FormElement>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <FormElement
                                                label="Eligible Value of Advance (In case of Agri, eligibility varies)(₹)"
                                                name="gold.eligible_advance"
                                                type="number"
                                                errors={errors}
                                                touched={touched}
                                            ></FormElement>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <FormElement
                                                label="Existing Loans with CSB (Under Different Products)"
                                                name="external_loans"
                                                type="text"
                                                errors={errors}
                                                touched={touched}
                                            ></FormElement>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="form-group col-md-6">
                                            <FormElement
                                                label="External appraisal done by: (Name and Code)"
                                                name="external_appraisal"
                                                type="text"
                                                errors={errors}
                                                touched={touched}
                                            ></FormElement>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <FormElement
                                                label="Packet Details"
                                                name="gold_packet"
                                                type="text"
                                                errors={errors}
                                                touched={touched}
                                            ></FormElement>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="form-group col-md-6">
                                            <FormElement
                                                label="Rate Per Gram (₹)"
                                                name="gold.rpg"
                                                type="number"
                                                errors={errors}
                                                touched={touched}
                                            ></FormElement>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <FormElement
                                                label="Source Code/Name"
                                                name="source_code"
                                                type="text"
                                                errors={errors}
                                                touched={touched}
                                            ></FormElement>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginLeft: 40 }}>
                                    <Typography
                                        style={{ textAlign: "center", fontSize: 20 }}
                                        variant="caption"
                                        display="block"
                                        gutterBottom
                                    >
                                        Gold Ornaments
                                    </Typography>
                                    <div className="d-flex justify-content-center">
                                        {values.gold_pic === "" ? (
                                            <Webcam
                                                height={300}
                                                audio={false}
                                                ref={goldcamRef}
                                                screenshotFormat="image/jpeg"
                                                width={300}
                                                videoConstraints={videoConstraints}
                                            />
                                        ) : (
                                            <img
                                                src={values.gold_pic}
                                                height="300"
                                                width="300"
                                                alt=""
                                            ></img>
                                        )}
                                    </div>
                                    <br />
                                    <div className="d-flex justify-content-around">
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            disabled={values.gold_pic ? true : false}
                                            type="button"
                                            onClick={() =>
                                                capture(
                                                    setFieldValue,
                                                    "gold_pic",
                                                    "gold",
                                                    values.loan_account
                                                )
                                            }
                                        >
                                            Capture Photo
                                        </Button>
                                        <Button
                                            color="secondary"
                                            type="button"
                                            onClick={() => discard(setFieldValue, "gold_pic")}
                                        >
                                            Discard
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Paper>
                        <Paper
                            style={{
                                padding: 20,
                                marginTop: 20,
                            }}
                        >
                            <div>
                                <Typography variant="h5">
                                    Disbursement Details
                                    {/* <div
                          className="d-flex justify-content-end"
                          style={{ float: "right", width: "50%" }}
                        >
                          <div style={{ width: 150 }} className="d-flex">
                            <label style={{ fontSize: 15 }}>PF Charge(%)</label>
                            <div>
                              <Field
                                name="pf_charge"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.pf_charge && touched.pf_charge
                                    ? " is-invalid"
                                    : "")
                                }
                                onKeyUp={() =>
                                  handleCharges(values, setFieldValue)
                                }
                              />
                            </div>
                          </div>
                        </div> */}
                                </Typography>
                            </div>
                            <br />
                            <div className="d-flex justify-content-around">
                                <div className="form-group col-md-3">
                                    <label>Interest Payment Frequency</label>
                                    <Field
                                        name="payment_frequency"
                                        type="text"
                                        className={
                                            "form-control" +
                                            (errors.payment_frequency &&
                                                touched.payment_frequency
                                                ? " is-invalid"
                                                : "")
                                        }
                                    />
                                    <ErrorMessage
                                        name="payment_frequency"
                                        component="div"
                                        className="invalid-feedback"
                                    />
                                </div>
                                <div className="form-group col-md-3">
                                    <label>Interest/EMI Amount (₹)</label>
                                    <Field
                                        name="emi"
                                        type="text"
                                        className={
                                            "form-control" +
                                            (errors.emi && touched.emi ? " is-invalid" : "")
                                        }
                                    />
                                    <ErrorMessage
                                        name="emi"
                                        component="div"
                                        className="invalid-feedback"
                                    />
                                </div>
                                <div className="form-group col-md-3">
                                    <label>Total PF (₹)</label>
                                    <Field
                                        name="pf"
                                        type="text"
                                        className={
                                            "form-control" +
                                            (errors.pf && touched.pf ? " is-invalid" : "")
                                        }
                                        onKeyUp={() => updateDisburse(values, setFieldValue)}
                                    />
                                    <ErrorMessage
                                        name="pf"
                                        component="div"
                                        className="invalid-feedback"
                                    />
                                </div>
                                <div className="form-group col-md-3">
                                    <label>Disbursement Amount (₹)</label>
                                    <Field
                                        name="disburse_amount"
                                        type="text"
                                        className={
                                            "form-control" +
                                            (errors.disburse_amount && touched.disburse_amount
                                                ? " is-invalid"
                                                : "")
                                        }
                                    />
                                    <ErrorMessage
                                        name="disburse_amount"
                                        component="div"
                                        className="invalid-feedback"
                                    />
                                </div>
                            </div>
                            {/* <div className="d-flex justify-content-around">
                                <div className="form-group col-md-3">
                                    <label>Auction Date</label>
                                    <Field
                                        name="auction_date"
                                        type="date"
                                        className={
                                            "form-control" +
                                            (errors.auction_date && touched.auction_date
                                                ? " is-invalid"
                                                : "")
                                        }
                                    />
                                    <ErrorMessage
                                        name="auction_date"
                                        component="div"
                                        className="invalid-feedback"
                                    />
                                </div>
                                <div className="form-group col-md-3">
                                    <label>EMI/Interest Due date</label>
                                    <Field
                                        name="emi_due_date"
                                        type="date"
                                        className={
                                            "form-control" +
                                            (errors.emi_due_date && touched.emi_due_date
                                                ? " is-invalid"
                                                : "")
                                        }
                                    />
                                    <ErrorMessage
                                        name="emi_due_date"
                                        component="div"
                                        className="invalid-feedback"
                                    />
                                </div>
                                <div className="form-group col-md-3">
                                    <label>Closure Due Date</label>
                                    <Field
                                        name="closure_due_date"
                                        type="date"
                                        className={
                                            "form-control" +
                                            (errors.closure_due_date && touched.closure_due_date
                                                ? " is-invalid"
                                                : "")
                                        }
                                    />
                                    <ErrorMessage
                                        name="closure_due_date"
                                        component="div"
                                        className="invalid-feedback"
                                    />
                                </div>
                            </div> */}
                        </Paper>
                        <Paper
                            style={{
                                padding: 20,
                                marginTop: 30,
                            }}
                        >
                            <Typography variant="h5">Capture Ornaments</Typography>
                            <br />
                            <div className="row justify-content-md-center">
                                {totalOrnaments.map((ornament, idx) => (
                                    <div style={{ margin: 10 }} key={idx}>
                                        <div className="d-flex">
                                            {ornament.image === "" ? (
                                                <Webcam
                                                    height={300}
                                                    audio={false}
                                                    ref={(el) => (itemsRef.current[idx] = el)}
                                                    screenshotFormat="image/jpeg"
                                                    width={300}
                                                    videoConstraints={videoConstraints}
                                                />
                                            ) : (
                                                <img
                                                    src={ornament.image}
                                                    height="300"
                                                    width="300"
                                                    alt=""
                                                ></img>
                                            )}
                                        </div>
                                        <br />
                                        <div className="d-flex justify-content-around">
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                disabled={ornament.image}
                                                type="button"
                                                onClick={() => {
                                                    captureGold(
                                                        setFieldValue,
                                                        idx,
                                                        values.loan_account
                                                    );
                                                }}
                                            >
                                                Capture Photo
                                            </Button>
                                            <Button
                                                color="secondary"
                                                type="button"
                                                onClick={() => discardGold(setFieldValue, idx)}
                                            >
                                                Discard
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Paper>
                    </div>
                   \ <div className="form-row">
                        <div className="form-group col d-flex justify-content-center">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className="btn btn-primary"
                            >
                                {isSubmitting && (
                                    <span className="spinner-border spinner-border-sm mr-1"></span>
                                )}
                                Submit
                            </button>
                        </div>
                        {console.log(initialValues)}
                    </div>
                </div>
            </Form>)}
    </Formik>
)
                                }


export default Loan
