import React from 'react'
import { useReactToPrint } from "react-to-print";
import { Paper } from "@material-ui/core";
import kaabillogo from "../../../assets/kaabil.png"


const CamLetter = (props) => {

    const values = props.location.state
    const guarantors = props.location.state.guarantors
    const coapplicants = props.location.state.coapplicants
    const item = props.location.state.credit_history
    console.log(coapplicants, guarantors)

    const CamLetterRef = React.useRef();

    const handleSubmissionPrint = useReactToPrint({
        content: () => CamLetterRef.current,
        pageStyle: "print"
    });

    function dateOrganizer(dateObj)
{
    // return dateObj.getFullYear()+"/"+("0"+(dateObj.getMonth()+1)).slice(-2)+"-"+("0"+dateObj.getDate()).slice(-2);
    return ("0"+dateObj.getDate()).slice(-2)+"/"+("0"+(dateObj.getMonth()+1)).slice(-2)+"/"+dateObj.getFullYear();
}


    const getEMI = (p, r, n) => {
        var rate = r / 1200;
        console.log(p, rate, n);
        var numerator = p * rate * Math.pow(1 + rate, n);
        var denominator = Math.pow(1 + rate, n) - 1;

        return numerator / denominator;
    }


    let relation_total, total_income, netIncome, surplus, ltv, foir;

    const calculation = () => {
        relation_total = Number(values.relation_income1) + Number(values.relation_income2);
        total_income = Number(relation_total) + Number(values.customer_income);
        netIncome = (Number(total_income) - Number(values.living_expenses)) - Number(values.other_expenses);
        surplus = Number(total_income) - Number(values.living_expenses);
        ltv = Number(values.sanction_amount) / Number(values.security_valuation) * 100
        let temp = Math.round(getEMI(values.sanction_amount, values.roi, values.tenure)) + Number(values.other_expenses)
        foir = temp / surplus * 100
    }

    setTimeout(calculation(), 5000)

    console.log(ltv)

    return (
        <>
            <div style={{ textAlign: "center" }}>
                <button onClick={handleSubmissionPrint}>Print Letter</button>
            </div>
            <div ref={CamLetterRef} style={{ fontSize: "18px" }}>
                <div
                    style={{
                        width: "290mm",
                        height: "410mm",
                        backgroundColor: "white",
                        padding: 40,
                        position: "relative",
                    }}
                >
                    <img src={kaabillogo} style={{ height: "150px", marginLeft: "30%" }} alt="KFPL Logo" />

                    <div style={{ marginTop: "80px" }}>
                        <h2 style={{ textAlign: "center" }}>
                            Credit Assessment
                        </h2>
                    </div><br />

                    <table style={{ width: "80%" }}>
                        <tr style={{ border: "1px solid grey", textAlign: "center" }}>
                            <td style={{ border: "1px solid grey", textAlign: "center" }}>Name of Applicant</td>
                            <td style={{ border: "1px solid grey", textAlign: "center" }}>{values.applicant}</td>
                            <td style={{ border: "1px solid grey", textAlign: "center" }}>{values.financial_status}</td>
                        </tr>

                        {coapplicants && coapplicants.map((e) => {
                            return <tr><td style={{ border: "1px solid grey", textAlign: "center" }}>Co-applicant</td>
                                <td style={{ border: "1px solid grey", textAlign: "center" }}>{e.name}</td>
                                <td style={{ border: "1px solid grey", textAlign: "center" }}>{e.financial_status}</td>
                            </tr>
                        })}

                        {guarantors && guarantors.map((e) => {
                            return <tr><td style={{ border: "1px solid grey", textAlign: "center" }}>Guarantor</td>
                                <td style={{ border: "1px solid grey", textAlign: "center" }}>{e.name}</td>
                                <td style={{ border: "1px solid grey", textAlign: "center" }}>{e.financial_status}</td>
                            </tr>
                        })}
                        <tr style={{ border: "1px solid grey", textAlign: "center" }}>
                            <td style={{ border: "1px solid grey", textAlign: "center" }}>Amount Sanction</td>
                            <td style={{ border: "1px solid grey", textAlign: "center" }}>{values.sanction_amount}</td>
                        </tr>
                        <tr style={{ border: "1px solid grey", textAlign: "center" }}>
                            <td style={{ border: "1px solid grey", textAlign: "center" }}>ROI</td>
                            <td style={{ border: "1px solid grey", textAlign: "center" }}>{values.roi}</td>
                        </tr>
                        <tr style={{ border: "1px solid grey", textAlign: "center" }}>
                            <td style={{ border: "1px solid grey", textAlign: "center" }}>Tenure</td>
                            <td style={{ border: "1px solid grey", textAlign: "center" }}>{values.tenure}</td>
                        </tr>
                        <tr style={{ border: "1px solid grey", textAlign: "center" }}>
                            <td style={{ border: "1px solid grey", textAlign: "center" }}>EMI amount (Rs.)</td>
                            <td style={{ border: "1px solid grey", textAlign: "center" }}>{Math.round(getEMI(values.sanction_amount, values.roi, values.tenure))}</td>
                        </tr>
                        <tr style={{ border: "1px solid grey", textAlign: "center" }}>
                            <td style={{ border: "1px solid grey", textAlign: "center" }}>Security Valuation (INR Lakhs)</td>
                            <td style={{ border: "1px solid grey", textAlign: "center" }}>{values.security_valuation}</td>
                        </tr>
                        <tr style={{ border: "1px solid grey", textAlign: "center" }}>
                            <td style={{ border: "1px solid grey", textAlign: "center" }}>LTV</td>
                            <td style={{ border: "1px solid grey", textAlign: "center" }}>{Math.round(ltv * 100) / 100}</td>
                        </tr>
                        <tr style={{ border: "1px solid grey", textAlign: "center" }}>
                            <td style={{ border: "1px solid grey", textAlign: "center" }}>Surplus Amount</td>
                            <td style={{ border: "1px solid grey", textAlign: "center" }}>{Math.round(surplus * 100) / 100}</td>
                        </tr>
                        <tr style={{ border: "1px solid grey", textAlign: "center" }}>
                            <td style={{ border: "1px solid grey", textAlign: "center" }}>FOIR</td>
                            <td style={{ border: "1px solid grey", textAlign: "center" }}>{Math.round(foir * 100) / 100}</td>
                        </tr>
                        <tr style={{ border: "1px solid grey", textAlign: "center" }}>
                            <td style={{ border: "1px solid grey", textAlign: "center" }}>Loan category (Whether PSL / NonPSL)</td>
                            <td style={{ border: "1px solid grey", textAlign: "center" }}>{values.loan_category}</td>
                        </tr>
                        <tr style={{ border: "1px solid grey", textAlign: "center" }}>
                            <td style={{ border: "1px solid grey", textAlign: "center" }}>Type of loan</td>
                            <td style={{ border: "1px solid grey", textAlign: "center" }}>{values.loan_type}</td>
                        </tr>
                        <tr style={{ border: "1px solid grey", textAlign: "center" }}>
                            <td style={{ border: "1px solid grey", textAlign: "center" }}>Purpose of loan</td>
                            <td style={{ border: "1px solid grey", textAlign: "center" }}>{values.loan_purpose}</td>
                        </tr>
                    </table><br />
                    <Paper
                        style={{
                            padding: 20,
                            width: "80%"
                        }}
                    >
                        <b>Brief Description:</b><br />
                        <b>{values.customer_description}</b><br /><br />
                        <b>{values.relationship1_description}</b><br /><br />
                        <b>{values.relationship2_description}</b><br /><br />
                        <b>Total Income- {total_income}</b><br />
                        <b>Expenses- {values.living_expenses}</b><br />
                        <b>Existing Emi- {values.other_expenses}</b> <br />
                        <b>Net Income- {netIncome}</b><br/>

                    </Paper>
                    {/* <img
                        src={kaabilFooter}
                        style={{
                            position: "absolute",
                            width: "270mm",
                            bottom: "40mm",
                        }}
                        alt="KFPL Footer"
                    /> */}
                </div>
                <div
                    style={{
                        width: "290mm",
                        minHeight:"410mm",
                        // height: "410mm",
                        backgroundColor: "white",
                        padding: 40,
                        position: "relative",
                    }}
                >
                    <img src={kaabillogo} style={{ height: "150px", marginLeft: "30%" }} alt="KFPL Logo" />
                    <div style={{ marginTop: "80px" }}>
                        <h2 style={{ textAlign: "center" }}>
                            Credit Assessment
                        </h2> <br />
                        {values.credit_buerau_analysis ?  <b>Credit bureau Analysis: {values.credit_buerau_analysis}</b> : ( <>
                            <b>Credit History ( All Live Loan Detail to be Captured)</b>
                        <table>
                            <thead>
                                <tr style={{ border: "1px solid grey"}}>
                                    <th style={{ border: "1px solid grey", textAlign: "center" }}>Financier Name</th>
                                    <th style={{ border: "1px solid grey", textAlign: "center" }}>Product</th>
                                    <th style={{ border: "1px solid grey", textAlign: "center" }}>Loan Start Date</th>
                                    <th style={{ border: "1px solid grey", textAlign: "center" }}>Loan Amount</th>
                                    <th style={{ border: "1px solid grey", textAlign: "center" }}>Tenure</th>
                                    <th style={{ border: "1px solid grey", textAlign: "center" }}>EMI</th>
                                    <th style={{ border: "1px solid grey", textAlign: "center" }}>Outstanding Amt(In Lacs)</th>
                                    <th style={{ border: "1px solid grey", textAlign: "center" }}>DPD Status - Average DPD and Peak DPD</th>
                                    <th style={{ border: "1px solid grey", textAlign: "center" }}>Obligation Considered - Yes/ No</th>
                                </tr>
                            </thead>
                            <tbody>
                                {item && item.map((e)=>{
                                    return <tr style={{ border: "1px solid grey"}}>
                                        <td style={{ border: "1px solid grey", textAlign: "center" }}>{e.financier_name}</td>
                                        <td style={{ border: "1px solid grey", textAlign: "center" }}>{e.product}</td>
                                        <td style={{ border: "1px solid grey", textAlign: "center" }}>{dateOrganizer(new Date(e.loan_start_date))}</td>
                                        <td style={{ border: "1px solid grey", textAlign: "center" }}>{e.loan_amount}</td>
                                        <td style={{ border: "1px solid grey", textAlign: "center" }}>{e.tenure}</td>
                                        <td style={{ border: "1px solid grey", textAlign: "center" }}>{e.emi}</td>
                                        <td style={{ border: "1px solid grey", textAlign: "center" }}>{e.outstanding_amount}</td>
                                        <td style={{ border: "1px solid grey", textAlign: "center" }}>{e.dpd_status}</td>
                                        <td style={{ border: "1px solid grey", textAlign: "center" }}>{e.obligation_considerd}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table></>)}
                       <br/><br/>
                        <table style={{ width: "80%", marginTop:"10px" }}>
                            <thead>
                                <tr style={{ border: "1px solid" }}>
                                    <th style={{ border: "1px solid grey", textAlign: "center" }}>Borrowers</th>
                                    <th style={{ border: "1px solid grey", textAlign: "center" }}>Name</th>
                                    <th style={{ border: "1px solid grey", textAlign: "center" }}>Age(Years)</th>
                                    <th style={{ border: "1px solid grey", textAlign: "center" }}>Relationship</th>
                                    <th style={{ border: "1px solid grey", textAlign: "center" }}>Cibil Score</th>
                                    <th style={{ border: "1px solid grey", textAlign: "center" }}>Income Considerd</th>
                                    <th style={{ border: "1px solid grey", textAlign: "center" }}>Property Owner</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{ border: "1px solid grey" }}>
                                    <td style={{ border: "1px solid grey", textAlign: "center" }}>Applicant</td>
                                    <td style={{ border: "1px solid grey", textAlign: "center" }}>{values.applicant}</td>
                                    <td style={{ border: "1px solid grey", textAlign: "center" }}>{values.age}</td>
                                    <td style={{ border: "1px solid grey", textAlign: "center" }}>{values.relationship}</td>
                                    <td style={{ border: "1px solid grey", textAlign: "center" }}>{values.cibil_score}</td>
                                    <td style={{ border: "1px solid grey", textAlign: "center" }}>{values.income_consider}</td>
                                    <td style={{ border: "1px solid grey", textAlign: "center" }}>{values.property_owner}</td>


                                </tr>
                                {coapplicants && coapplicants.map((e) => {
                                    return <tr style={{ border: "1px solid grey" }}>
                                        <td style={{ border: "1px solid grey", textAlign: "center" }}>Co-applicant</td>
                                        <td style={{ border: "1px solid grey", textAlign: "center" }}>{e.name}</td>
                                        <td style={{ border: "1px solid grey", textAlign: "center" }}>{e.age}</td>
                                        <td style={{ border: "1px solid grey", textAlign: "center" }}>{e.relationship}</td>
                                        <td style={{ border: "1px solid grey", textAlign: "center" }}>{e.cibil_score}</td>
                                        <td style={{ border: "1px solid grey", textAlign: "center" }}>{e.income_consider}</td>
                                        <td style={{ border: "1px solid grey", textAlign: "center" }}>{e.property_owner}</td>

                                    </tr>
                                })}
                                {guarantors && guarantors.map((e) => {
                                    return <tr style={{ border: "1px solid grey" }}>
                                        <td style={{ border: "1px solid grey", textAlign: "center" }}>Guarantor</td>
                                        <td style={{ border: "1px solid grey", textAlign: "center" }}>{e.name}</td>
                                        <td style={{ border: "1px solid grey", textAlign: "center" }}>{e.age}</td>
                                        <td style={{ border: "1px solid grey", textAlign: "center" }}>{e.relationship}</td>
                                        <td style={{ border: "1px solid grey", textAlign: "center" }}>{e.cibil_score}</td>
                                        <td style={{ border: "1px solid grey", textAlign: "center" }}>{e.income_consider}</td>
                                        <td style={{ border: "1px solid grey", textAlign: "center" }}>{e.property_owner}</td>

                                    </tr>
                                })}
                            </tbody>
                        </table><br />
                        

                    </div>
                    {/* <img
                        src={kaabilFooter}
                        style={{
                            position: "absolute",
                            width: "270mm",
                            bottom: "0mm",
                        }}
                        alt="KFPL Footer"
                    /> */}

                </div>

                <div
                    style={{
                        width: "290mm",
                        height: "410mm",
                        backgroundColor: "white",
                        padding: 40,
                        position: "relative",
                    }}
                >
                    <img src={kaabillogo} style={{ height: "150px", marginLeft: "30%" }} alt="KFPL Logo" />
                    <div style={{ marginTop: "80px" }}>
                    <b>Income Assessment:</b><br />
                        <table style={{ width: "80%" }}>
                            <tr style={{ border: "1px solid grey" }}>
                                <th style={{ border: "1px solid grey", width: "400px" }}>CASH FLOW ANALYSIS (TO BE OBTAINED THROUGH CUSTOMER INTERVIEW)</th>
                            </tr>
                            <tr style={{ border: "1px solid grey" }}>
                                <td style={{ border: "1px solid grey", textAlign: "center" }}>Name</td>
                                <td style={{ border: "1px solid grey", textAlign: "center", width: "200px" }}>{values.applicant}</td>
                                <td style={{ border: "1px solid grey", textAlign: "center", width: "150px" }}>Amount Per Month</td>
                                <td style={{ border: "1px solid grey", textAlign: "center", width: "300px" }}>Description</td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid grey", height: "100px" }}>
                                    A) Total gross sales or receipts per month (INR)
                                </td>
                                <td style={{ border: "1px solid grey", textAlign: "center" }}></td>
                                <td style={{ border: "1px solid grey", textAlign: "center", width: "150px" }}>{values.customer_income}</td>
                                <td style={{ border: "1px solid grey", textAlign: "center", width: "300px" }}>{values.customer_description}</td>
                            </tr>
                        </table>
                        <table style={{ width: "80%" }}>
                            <tr>
                                <td style={{ border: "1px solid grey", height: "100px", width: "300px" }}>
                                    B) Clubbing of Income (only where the family member or the business associate is part of the loan as a co-applicant) Net income post business expenses only
                                </td>
                            </tr>
                        </table>
                        <table style={{ width: "80%" }}>
                            <tr>
                                <td style={{ border: "1px solid grey", textAlign: "center" }}><b>Relationship</b></td>
                                <td style={{ border: "1px solid grey", textAlign: "center" }}><b>Source(salary/business/others)</b></td>
                                {/* <td style={{ border: "1px solid grey", textAlign: "center", width: "15%" }}><b>Verified thru(Dox/CPV/TRC)</b></td> */}
                                <td style={{ border: "1px solid grey", textAlign: "center" }}><b>Amount(INR)</b></td>
                                <td style={{ border: "1px solid grey", textAlign: "center" }}></td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid grey", textAlign: "center" }}>{values.relation_name1}</td>
                                <td style={{ border: "1px solid grey", textAlign: "center" }}>{values.relation_bussiness_name1}</td>
                                {/* <td style={{ border: "1px solid grey", textAlign: "center", width: "15%" }}>-</td> */}
                                <td style={{ border: "1px solid grey", textAlign: "center" }}>{values.relation_income1}</td>
                                <td style={{ border: "1px solid grey", textAlign: "center" }}>{values.relationship1_description}</td>
                            </tr>

                            <tr>
                                <td style={{ border: "1px solid grey", textAlign: "center", width: "15%" }}>{values.relation_name2}</td>
                                <td style={{ border: "1px solid grey", textAlign: "center", width: "15%" }}>{values.relation_bussiness_name2}</td>
                                {/* <td style={{ border: "1px solid grey", textAlign: "center", width: "15%" }}>-</td> */}
                                <td style={{ border: "1px solid grey", textAlign: "center", width: "15%" }}>{values.relation_income2}</td>
                                <td style={{ border: "1px solid grey", textAlign: "center", width: "40%" }}>{values.relationship2_description}</td>
                            </tr>
                        </table>
                        <table style={{ width: "52.5%" }}>
                            <tr>
                                <td style={{ border: "1px solid grey", width: "455px" }}>Total of (B)</td>
                                <td style={{ border: "1px solid grey", width: "100px" }}>{relation_total}</td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid grey" }}>C) Total Income(A+B)</td>
                                <td style={{ border: "1px solid grey" }}>{total_income}</td>
                            </tr>
                        </table>
                        <table style={{ width: "52.5%" }}>
                            <tr>
                                <td style={{ border: "1px solid grey", width: "455px" }}>D) BUSINESS EXPENSES</td>
                                <td style={{ border: "1px solid grey", width: "100px", textAlign: "center" }}>-</td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid grey" }}>Material Cost</td>
                                <td style={{ border: "1px solid grey", width: "100px", textAlign: "center" }}>-</td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid grey" }}>Salary expenses</td>
                                <td style={{ border: "1px solid grey", width: "100px", textAlign: "center" }}>-</td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid grey" }}>Admin expenses</td>
                                <td style={{ border: "1px solid grey", width: "100px", textAlign: "center" }}>-</td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid grey" }}>Operating expenses</td>
                                <td style={{ border: "1px solid grey", width: "100px", textAlign: "center" }}>-</td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid grey" }}>Rent (Business premises)</td>
                                <td style={{ border: "1px solid grey", width: "100px", textAlign: "center" }}>-</td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid grey" }}>Others</td>
                                <td style={{ border: "1px solid grey", width: "100px", textAlign: "center" }}>-</td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid grey" }}>Total Business Expenses</td>
                                <td style={{ border: "1px solid grey", width: "100px", textAlign: "center" }}>-</td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid grey" }}>E) NET INCOME (C-D)</td>
                                <td style={{ border: "1px solid grey", width: "100px", textAlign: "center" }}>-</td>
                            </tr>

                        </table>

                    </div>
                    <br />
                    {/* <img
                        src={kaabilFooter}
                        style={{
                            position: "absolute",
                            width: "270mm",
                            bottom: "40mm",
                        }}
                        alt="KFPL Footer"
                    /> */}
                </div>
                <div
                    style={{
                        width: "290mm",
                        height: "410mm",
                        backgroundColor: "white",
                        padding: 40,
                        position: "relative",
                    }}
                >
                    <img src={kaabillogo} style={{ height: "150px", marginLeft: "30%", marginTop: "10px" }} alt="KFPL Logo" />
                    <div style={{ marginTop: "80px" }}>

                        <table style={{ width: "52.5%" }}>
                            <tr>
                                <td style={{ border: "1px solid grey", width: "455px" }}>F) PERSONAL EXPENSES (Of applicant and coapplicant and their dependents)</td>
                                <td style={{ border: "1px solid grey", width: "100px", textAlign: "center" }}>-</td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid grey" }}>Living expenses</td>
                                <td style={{ border: "1px solid grey", textAlign: "center" }}>{values.living_expenses}</td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid grey" }}>Education</td>
                                <td style={{ border: "1px solid grey", textAlign: "center" }}>-</td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid grey" }}>Conveyance</td>
                                <td style={{ border: "1px solid grey", textAlign: "center" }}>-</td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid grey" }}>Rent (Residential premises)</td>
                                <td style={{ border: "1px solid grey", textAlign: "center" }}>-</td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid grey" }}>Others (Including Existing EMI)</td>
                                <td style={{ border: "1px solid grey", textAlign: "center" }}>{values.other_expenses}</td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid grey" }}>Total Personal Expenses</td>
                                <td style={{ border: "1px solid grey", textAlign: "center" }}>-</td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid grey", height: "80px" }}>G) Net Surplus Income as per Cash Flow Analysis (E-F)-Assessed Income</td>
                                <td style={{ border: "1px solid grey", textAlign: "center" }}>{netIncome}</td>
                            </tr>
                        </table><br />

                        <h3>Security Valuation:</h3><br />
                        <table style={{ width: "80%" }}>
                            <tr style={{ border: "1px solid grey" }}>
                                <th style={{ border: "1px solid grey", width: "200px", textAlign: "center" }}>Property Address</th>
                                <th style={{ border: "1px solid grey", width: "200px", textAlign: "center" }}>Owner Name</th>
                                <th style={{ border: "1px solid grey", width: "200px", textAlign: "center" }}>MV (INR)</th>
                                <th style={{ border: "1px solid grey", width: "200px", textAlign: "center" }}>Property Type</th>
                            </tr>
                            <tr style={{ border: "1px solid grey" }}>
                                <td style={{ border: "1px solid grey" }}>{values.property_address}</td>
                                <td style={{ border: "1px solid grey" }}>{values.owner_name}</td>
                                <td style={{ border: "1px solid grey" }}>{values.security_valuation}</td>
                                <td style={{ border: "1px solid grey" }}>{values.property_type}</td>
                            </tr>
                        </table>
                    </div>
                    <br />
                    {/* <img
                        src={kaabilFooter}
                        style={{
                            position: "absolute",
                            width: "270mm",
                            bottom: "40mm",
                        }}
                        alt="KFPL Footer"
                    /> */}
                </div>

            </div>
        </>
    )
}
export default CamLetter;