import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import { Button, TextField } from "@material-ui/core";
import { cibil } from "../../_services/cibil.service"
import { uploadFileToBucket } from "../../_helpers/upload";

const Upload = (props) => {

    const [open, setOpen] = useState(false);
    const [selectedFile, setSelectedFile] = React.useState(null);
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false);

    const aadhar = props.aadhar
    const id = props.id


    const onChangeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
      };

    const data = new FormData();
    data.append("file", selectedFile);


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        height: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        // overflow: 'scroll',
        // paddingBottom: '30px',
    };

    const bucket = process.env.REACT_APP_s3docsBucket

    const onClickHandler = async () => {
         const report = await uploadFileToBucket(bucket, "application/pdf", `cibil_reports/${aadhar}_cibil.pdf`, selectedFile);
         console.log(report)
         cibil.upload({id, report})
         .then((data)=> {
            alert("Data added successfully")
            window.location.reload();
         })
         .catch((err)=> {
            console.log(err)
         })
    }

    return (

        <div>
            <Button color='primary' onClick={handleOpen}>Upload File</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <Typography style={{ margin: "auto", }}>
                        <div className="offset-md-3 col-md-6">
                            <form method="post" action="#" id="#">
                                <div className="form-group files">
                                    <label>Upload Your File</label>
                                    <input
                                        accept=".pdf"
                                        type="file"
                                        className="form-control"
                                         onChange={(e) => onChangeHandler(e)}
                                    />
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-success btn-block"
                                    onClick={() => onClickHandler()}
                                >
                                    Upload
                                </button>
                            </form>
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </div>
    )
}

export default Upload