import React from "react";
import LoanData from "./ApplicationData/LoanData";

import { employeeService } from "../../../_services";

export default function LoanDetails({ cust }) {
  const user = employeeService.userValue;
  console.log(cust);
  return (
    <div>
      <br></br>
      {cust && (
      <LoanData customer={cust}></LoanData>
      )}

    </div>
  );
}
