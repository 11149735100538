export const ColendingSubStages= {
  FilePrepared: "FilePrepared",
  FileLoggedIn: "FileLoggedIn",
  QueryPendingAtColender: "QueryPendingAtColender",
  QueryPendingAtKaabil: "QueryPendingAtKaabil",
  ApprovalForDisbursement:"ApprovalForDisbursement",
  AmountReceivedFromColender:"AmountReceivedFromColender",
  RepaymentToColender:"RepaymentToColender",
  ColenderLoanClosed:"ColenderLoanClosed",
  RejectedByColender:"RejectedByColender",
  ColendingCancelled:"ColendingCancelled",
  ApprovedFromCredit: "ApprovedFromCredit",
  };
    