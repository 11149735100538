import { fetchWrapper } from "../_helpers";
const apiUrl = process.env.REACT_APP_API_URL;
const baseUrl = `${apiUrl}/submissionletter`;

const saveSubmissionData = async (params)=>{
    return fetchWrapper.post(`${baseUrl}/savedata`,params)
}

async function countLetter(){
      return fetchWrapper.get(`${baseUrl}/countletter`)
}
 
async function getLetter(){
    return fetchWrapper.get(`${baseUrl}/getletter`)
}
async function searchByLetterNumber(params) {
    return fetchWrapper.post(`${baseUrl}/searchletter`, params)
  }

export const  submissionServices ={
    saveSubmissionData,
    countLetter,
    getLetter,
    searchByLetterNumber
}