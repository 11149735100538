import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import { Button } from "@material-ui/core";
import { misService } from "../../_services";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function LoanModal(props) {
  const loan = props.loan;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  async function openCkyc() {
    const kyclink = await misService.getCkyc({
      customer_pic: loan.details.customer_pic,
      loan_account: loan.loan_account,
    });
    window.open(kyclink);
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button style={{ width: "100%" }} onClick={handleOpen} color="primary">
        KYC Details
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Loan Details</h2>
            <p id="transition-modal-description">
              <b>Date:&nbsp;</b>
              {loan.date.split("T")[0]}
              <br />
              <b>Name:&nbsp;</b>
              {loan.name}
              <br />
              <b>Father/Mother:&nbsp;</b>
              {loan.sonof}
              <br />
              <b>Contact:&nbsp;</b>
              {loan.contact}
              <br />
              <b>Address:&nbsp;</b>
              {loan.address}
              <br />
              <b>Loan Amount:&nbsp;</b>₹{loan.loan_amount}
              <br />
              <b>Loan Account:&nbsp;</b>
              {loan.loan_account}
              <br />
              <b>Period:&nbsp;</b>
              {loan.period} Months
              <br />
              <b>Rate of Interest:&nbsp;</b>
              {loan.roi} %
              <br />
              <b>Gold Packet:&nbsp;</b>
              {loan.gold_packet}
              <br />
              <b>EMI:&nbsp;</b>₹{loan.emi}
              <br />
              <b>PF Charge:&nbsp;</b>
              Rs.{loan.pf}
              <br />
              <b>RPG:&nbsp;</b>
              Rs.{loan.rpg}
              <br />
              {loan.details.bank_account ? (
                <React.Fragment>
                  <b>Bank Details&nbsp;</b>
                  <br />
                  Account Holder - {loan.details.bank_account.acc_holder}
                  <br />
                  Account Number - {loan.details.bank_account.account_no}
                  <br />
                  Bank Name - {loan.details.bank_account.bank_name}
                  <br />
                  IFSC Code - {loan.details.bank_account.ifsc_code}
                </React.Fragment>
              ) : null}
              <br />
              <b>Aadhaar Number:&nbsp;</b>
              {loan.details.adhaar_no}
              <br />
              <b>PAN Number:&nbsp;</b>
              {loan.details.pan_no}
            </p>
            <hr />
            <div className="row justify-content-around">
              {/* <Button
                style={{ height: 40 }}
                disabled={loan.details ? false : true}
                variant="contained"
                color="primary"
                onClick={() => {
                  if (loan.bank === "Kaabil")
                    props.history.push("/home/pledge/print", loan);
                  else
                    props.history.push("/home/application/print", loan.details);
                }}
              >
                Loan Application
              </Button> */}
              <Button
                style={{ height: 40, marginLeft:20 }}
                disabled={loan.bank === "CSB" ? false : true}
                variant="contained"
                color="primary"
                onClick={() => openCkyc()}
              >
                CKYC
              </Button>
              {/* <Button
                style={{ height: 40, marginLeft:20 }}
                variant="contained"
                color="primary"
                onClick={()=>props.history.push('/home/goldloans/authorityletter', loan)}
              >
                Authority Letter
              </Button> */}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
