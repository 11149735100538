import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import AddIcon from "@material-ui/icons/Add";

import { Fab } from "@material-ui/core";
import { authPersonService } from "../../_services/auth_person.service";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function AuthPersonModal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [name, setName] = React.useState("");
  const [id, setId] = React.useState("");
  const [contact, setContact] = React.useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addBranch = () => {
    console.log(name);
    authPersonService.create({
      name, id, contact
    });
  };

  return (
    <div>
      <div
        style={{ marginTop: "3%" }}
        className="d-flex justify-content-around"
        onClick={handleOpen}
      >
        <Fab size="small" color="primary" aria-label="add">
          <AddIcon />
        </Fab>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Add Auth Person</h2>
            <form id="transition-modal-description">
              <div className="form-group">
                <label htmlFor="branch_id">Name</label>
                <input
                  style={{width:"500px"}}
                  type="text"
                  className="form-control"
                  id="name"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="branch_id">ID</label>
                <input
                  style={{width:"500px"}}
                  type="text"
                  className="form-control"
                  id="id"
                  onChange={(e) => setId(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="branch_id">Contact</label>
                <input
                  style={{width:"500px"}}
                  type="text"
                  className="form-control"
                  id="contact"
                  onChange={(e) => setContact(e.target.value)}
                />
              </div>
              <hr />
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => addBranch()}
              >
                Submit
              </button>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
